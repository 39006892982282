// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divTextEllipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
}


html {
    --scrollbarBG: hsl(0, 0%, 100%);
    --thumbBG: #90a4ae;
  }
  :-webkit-scrollbar {
    width: 6px;
  }
  .List {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .List::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 6px;
  }
  .List::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }

  .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    margin-left: 0px !important;
    border-left: 1px solid var(--mainTheme) !important;
  }`, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/DynamicForms/DynamicForms.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,kCAAkC;EAClC,sBAAsB;AACxB;;;AAGA;IACI,+BAA+B;IAC/B,kBAAkB;EACpB;EACA;IACE,UAAU;EACZ;EACA;IACE,qBAAqB;IACrB,kDAAkD;EACpD;EACA;IACE,8BAA8B;IAC9B,kBAAkB;EACpB;EACA;IACE,gCAAgC;IAChC,kBAAkB;IAClB,oCAAoC;EACtC;;EAEA;IACE,2BAA2B;IAC3B,kDAAkD;EACpD","sourcesContent":[".divTextEllipsis {\r\n  overflow: hidden !important;\r\n  text-overflow: ellipsis !important;\r\n  width: 100% !important;\r\n}\r\n\r\n\r\nhtml {\r\n    --scrollbarBG: hsl(0, 0%, 100%);\r\n    --thumbBG: #90a4ae;\r\n  }\r\n  :-webkit-scrollbar {\r\n    width: 6px;\r\n  }\r\n  .List {\r\n    scrollbar-width: thin;\r\n    scrollbar-color: var(--thumbBG) var(--scrollbarBG);\r\n  }\r\n  .List::-webkit-scrollbar-track {\r\n    background: var(--scrollbarBG);\r\n    border-radius: 6px;\r\n  }\r\n  .List::-webkit-scrollbar-thumb {\r\n    background-color: var(--thumbBG);\r\n    border-radius: 6px;\r\n    border: 3px solid var(--scrollbarBG);\r\n  }\r\n\r\n  .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {\r\n    margin-left: 0px !important;\r\n    border-left: 1px solid var(--mainTheme) !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
