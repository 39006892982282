// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
}

.graph-dashboard-container {
  background-color: #e3edf4;
}

.graph-header-container {
  display: flex;
  justify-content: space-between;
}

.graph-header-icon {
  align-content: center;
}

.graph-header-item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  text-align: center;
}

.graph-header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/Graphs/adminGraphs.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":["* {\n  padding: 0;\n}\n\n.graph-dashboard-container {\n  background-color: #e3edf4;\n}\n\n.graph-header-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.graph-header-icon {\n  align-content: center;\n}\n\n.graph-header-item {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n  text-align: center;\n}\n\n.graph-header-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
