import {
  getObjectData,
  setObjectData,
} from "../../../../utils/helpers.DynamicForm";

// [ react_state_variable,db_json_path,]

const homeSafety_hashmap = [
  ["home_fallenNoneVal", "fallen.none"],
  ["home_fallenOnceVal", "fallen.once"],
  [
    "home_fallenOnceWryFallVal",
    "fallen.onceSubAnswers.worryAboutFalling.isWorryAboutFalling",
  ],
  [
    "home_fallenOnceWryFallComment",
    "fallen.onceSubAnswers.worryAboutFalling.comment",
  ],
  [
    "home_fallenOnceWrisFallVal",
    "fallen.onceSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
  ],
  [
    "home_fallenOnceWrisFallComment",
    "fallen.onceSubAnswers.worriesAboutFalling.comment",
  ],
  ["home_fallenOnceFracVal", "fallen.onceSubAnswers.fracture.isFracture"],
  ["home_fallenOnceFracComment", "fallen.onceSubAnswers.fracture.comment"],
  [
    "home_fallenOnceFracByFallVal",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
  ],
  [
    "home_fallenOnceFracByFallComment",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
  ],
  [
    "home_fallenOnceOstMedVal",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
  ],
  [
    "home_fallenOnceOstMedComment",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
  ],

  ["home_fallenTwiceVal", "fallen.twice"],
  [
    "home_fallenTwiceWryFallVal",
    "fallen.twoceSubAnswers.worryAboutFalling.isWorryAboutFalling",
  ],
  [
    "home_fallenTwiceWryFallComment",
    "fallen.twoceSubAnswers.worryAboutFalling.comment",
  ],
  [
    "home_fallenTwiceWrisFallVal",
    "fallen.twoceSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
  ],
  [
    "home_fallenTwiceWrisFallComment",
    "fallen.twoceSubAnswers.worriesAboutFalling.comment",
  ],
  ["home_fallenTwiceFracVal", "fallen.twoceSubAnswers.fracture.isFracture"],
  ["home_fallenTwiceFracComment", "fallen.twoceSubAnswers.fracture.comment"],
  [
    "home_fallenTwiceFracByFallVal",
    "fallen.twoceSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
  ],
  [
    "home_fallenTwiceFracByFallComment",
    "fallen.twoceSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
  ],
  [
    "home_fallenTwiceOstMedVal",
    "fallen.twoceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
  ],
  [
    "home_fallenTwiceOstMedComment",
    "fallen.twoceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
  ],

  ["home_fallenThreeTimesVal", "fallen.threeTimes"],
  [
    "home_fallenThreeWryFallVal",
    "fallen.threeTimesSubAnswers.worryAboutFalling.isWorryAboutFalling",
  ],
  [
    "home_fallenThreeWryFallComment",
    "fallen.threeTimesSubAnswers.worryAboutFalling.comment",
  ],
  [
    "home_fallenThreeWrisFallVal",
    "fallen.threeTimesSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
  ],
  [
    "home_fallenThreeWrisFallComment",
    "fallen.threeTimesSubAnswers.worriesAboutFalling.comment",
  ],
  [
    "home_fallenThreeFracVal",
    "fallen.threeTimesSubAnswers.fracture.isFracture",
  ],
  [
    "home_fallenThreeFracComment",
    "fallen.threeTimesSubAnswers.fracture.comment",
  ],
  [
    "home_fallenThreeFracByFallVal",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
  ],
  [
    "home_fallenThreeFracByFallComment",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
  ],
  [
    "home_fallenThreeOstMedVal",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
  ],
  [
    "home_fallenThreeOstMedComment",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
  ],

  ["home_fallenMoreThreeVal", "fallen.MoreThanthreeTimes"],
  [
    "home_fallenMoreThreeWryFallVal",
    "fallen.MoreThanthreeTimesSubAnswers.worryAboutFalling.isWorryAboutFalling",
  ],
  [
    "home_fallenMoreThreeWryFallComment",
    "fallen.MoreThanthreeTimesSubAnswers.worryAboutFalling.comment",
  ],
  [
    "home_fallenMoreThreeWrisFallVal",
    "fallen.MoreThanthreeTimesSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
  ],
  [
    "home_fallenMoreThreeWrisFallComment",
    "fallen.MoreThanthreeTimesSubAnswers.worriesAboutFalling.comment",
  ],
  [
    "home_fallenMoreThreeFracVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFracture",
  ],
  [
    "home_fallenMoreThreeFracComment",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.comment",
  ],
  [
    "home_fallenMoreThreeFracByFallVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
  ],
  [
    "home_fallenMoreThreeFracByFallComment",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
  ],
  [
    "home_fallenMoreThreeOstMedVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
  ],
  [
    "home_fallenMoreThreeOstMedComment",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
  ],

  ["home_obstInHouseVal", "homeSafety.obstaclesInHouse.isObstaclesInHouse"],
  ["home_obstInHouseComment", "homeSafety.obstaclesInHouse.comment"],
  ["home_elecCordsVal", "homeSafety.electricalCords.isElectricalCords"],
  ["home_elecCordsComment", "homeSafety.electricalCords.comment"],
  ["home_noMatsVal", "homeSafety.noSlipMats.isNoSlipMats"],
  ["home_noMatsComment", "homeSafety.noSlipMats.comment"],
  ["home_adeLightVal", "homeSafety.adequateLighting.isAdequateLighting"],
  ["home_adeLightComment", "homeSafety.adequateLighting.comment"],
  [
    "home_handRailVal",
    "homeSafety.handrailsOnStaircases.isHandrailsOnStaircases",
  ],
  ["home_handRailComment", "homeSafety.handrailsOnStaircases.comment"],
  ["home_hotWaterVal", "homeSafety.hotWaterHeater.isHotWaterHeater"],
  ["home_hotWaterComment", "homeSafety.hotWaterHeater.comment"],
  ["home_smokeDetVal", "homeSafety.smokeDetectors.isSmokeDetectors"],
  ["home_smokeDetComment", "homeSafety.smokeDetectors.comment"],
  [
    "home_co2DetVal",
    "homeSafety.carbonMonoxideDetectors.isCarbonMonoxideDetectors",
  ],
  ["home_co2DetComment", "homeSafety.carbonMonoxideDetectors.comment"],
  [
    "home_escRouteVal",
    "homeSafety.establishedEscapeRoute.isEstablishedEscapeRoute",
  ],
  ["home_escRouteComment", "homeSafety.establishedEscapeRoute.comment"],

  ["home_wishToChangeVal", "wishTochangeOrimprove.wishTochangeOrimprove"],
  ["home_wishToChangeComment", "wishTochangeOrimprove.comment"],
  ["home_qualityOfLifeVal", "qualityOfLife.qualityOfLife"],
  ["home_qualityOfLifeComment", "qualityOfLife.comment"],
  ["home_abusedBySomeoneVal", "abusedBySomeone.isAbusedBySomeone"],
  ["home_abusedBySomeoneComment", "abusedBySomeone.comment"],
  ["home_feelingLikeHarmingVal", "feelingLikeHarming.isFeelingLikeHarming"],
  [
    "home_feelingLikeHarmingSubAnsWhoVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.whoDoYouFeel",
  ],
  [
    "home_feelingLikeHarmingSubAnsAtVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.atThisMomen",
  ],
  [
    "home_feelingLikeHarmingSubAnsSafeVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.safePlace",
  ],
  [
    "home_feelingLikeHarmingSubAnsAssistVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.assistYou",
  ],
  ["home_feelingLikeHarmingComment", "feelingLikeHarming.comment"],
  ["home_afraidOfAnyOneVal", "afraidOfAnyone.isafraidOfAnyone"],
  [
    "home_afraidOfAnyOneSubAnsWhoVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.whoAreYouAfraidOf",
  ],
  [
    "home_afraidOfAnyOneSubAnsAtVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.whoIsHurtingYou",
  ],
  [
    "home_afraidOfAnyOneSubAnsSafeVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.safePlace",
  ],
  [
    "home_afraidOfAnyOneSubAnsAssistVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.assistYou",
  ],
  ["home_afraidOfAnyOneComment", "afraidOfAnyone.comment"],
];

const homeSafety_optionsTreeMapObject = {
  home_fallenNoneVal: "none",
  home_fallenOnceVal: "once",
  home_fallenTwiceVal: "twice",
  home_fallenThreeTimesVal: "threeTimes",
  home_fallenMoreThreeVal: "moreThanThreeTimes",
};

const homeSafetyOptionsTreeWrappers = [
  [
    "home_fallen",
    [
      "home_fallenNoneVal",
      "home_fallenOnceVal",
      "home_fallenTwiceVal",
      "home_fallenThreeTimesVal",
      "home_fallenMoreThreeVal",
    ],
  ],
];

export const getHomeSafetyData = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  homeSafety_hashmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  homeSafetyOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  const home_obstInHouse =
    tempDbData["home_obstInHouseVal"] === "1" ? "yes" : "no";
  const home_elecCords = tempDbData["home_elecCordsVal"] === "1" ? "yes" : "no";
  const home_noMats = tempDbData["home_noMatsVal"] === "1" ? "yes" : "no";
  const home_adeLight = tempDbData["home_adeLightVal"] === "1" ? "yes" : "no";
  const home_handRail = tempDbData["home_handRailVal"] === "1" ? "yes" : "no";
  const home_hotWater = tempDbData["home_hotWaterVal"] === "1" ? "yes" : "no";
  const home_smokeDet = tempDbData["home_smokeDetVal"] === "1" ? "yes" : "no";
  const home_co2Det = tempDbData["home_co2DetVal"] === "1" ? "yes" : "no";
  const home_escRoute = tempDbData["home_escRouteVal"] === "1" ? "yes" : "no";

  const home_wishToChange =
    tempDbData["home_wishToChangeVal"] === "1" ? "yes" : "no";
  const home_qualityOfLife =
    tempDbData["home_qualityOfLifeVal"] === "1" ? "yes" : "no";
  const home_abusedBySomeone =
    tempDbData["home_abusedBySomeoneVal"] === "1" ? "yes" : "no";
  const home_feelingLikeHarming =
    tempDbData["home_feelingLikeHarmingVal"] === "1" ? "yes" : "no";
  const home_feelingLikeHarmingSubAnsWho =
    tempDbData["home_feelingLikeHarmingSubAnsWhoVal"];
  const home_feelingLikeHarmingSubAnsAt =
    tempDbData["home_feelingLikeHarmingSubAnsAtVal"];
  const home_feelingLikeHarmingSubAnsSafe =
    tempDbData["home_feelingLikeHarmingSubAnsSafeVal"];
  const home_feelingLikeHarmingSubAnsAssist =
    tempDbData["home_feelingLikeHarmingSubAnsAssistVal"];
  const home_afraidOfAnyOne =
    tempDbData["home_afraidOfAnyOneVal"] === "1" ? "yes" : "no";
  const home_obstInHouseComment = tempDbData["home_obstInHouseComment"];
  const home_elecCordsComment = tempDbData["home_elecCordsComment"];
  const home_noMatsComment = tempDbData["home_noMatsComment"];
  const home_adeLightComment = tempDbData["home_adeLightComment"];
  const home_handRailComment = tempDbData["home_handRailComment"];
  const home_hotWaterComment = tempDbData["home_hotWaterComment"];
  const home_smokeDetComment = tempDbData["home_smokeDetComment"];
  const home_co2DetComment = tempDbData["home_co2DetComment"];
  const home_escRouteComment = tempDbData["home_escRouteComment"];
  const home_wishToChangeComment = tempDbData["home_wishToChangeComment"];
  const home_qualityOfLifeComment = tempDbData["home_qualityOfLifeComment"];
  const home_abusedBySomeoneComment = tempDbData["home_abusedBySomeoneComment"];
  const home_feelingLikeHarmingComment =
    tempDbData["home_feelingLikeHarmingComment"];
  const home_afraidOfAnyOneComment = tempDbData["home_afraidOfAnyOneComment"];
  const home_fallenOnceWryFall =
    tempDbData["home_fallenOnceWryFallVal"] === "1" ? "yes" : "no";
  const home_fallenOnceWryFallComment =
    tempDbData["home_fallenOnceWryFallComment"];
  const home_fallenOnceWrisFall =
    tempDbData["home_fallenOnceWrisFallVal"] === "1" ? "yes" : "no";
  const home_fallenOnceWrisFallComment =
    tempDbData["home_fallenOnceWrisFallComment"];
  const home_fallenOnceFrac =
    tempDbData["home_fallenOnceFracVal"] === "1" ? "yes" : "no";
  const home_fallenOnceFracComment = tempDbData["home_fallenOnceFracComment"];
  const home_fallenOnceFracByFall =
    tempDbData["home_fallenOnceFracByFallVal"] === "1" ? "yes" : "no";
  const home_fallenOnceFracByFallComment =
    tempDbData["home_fallenOnceFracByFallComment"];
  const home_fallenOnceOstMed =
    tempDbData["home_fallenOnceOstMedVal"] === "1" ? "yes" : "no";
  const home_fallenOnceOstMedComment =
    tempDbData["home_fallenOnceOstMedComment"];

  const home_fallenTwiceWryFall =
    tempDbData["home_fallenTwiceWryFallVal"] === "1" ? "yes" : "no";
  const home_fallenTwiceWryFallComment =
    tempDbData["home_fallenTwiceWryFallComment"];
  const home_fallenTwiceWrisFall =
    tempDbData["home_fallenTwiceWrisFallVal"] === "1" ? "yes" : "no";
  const home_fallenTwiceWrisFallComment =
    tempDbData["home_fallenTwiceWrisFallComment"];
  const home_fallenTwiceFrac =
    tempDbData["home_fallenTwiceFracVal"] === "1" ? "yes" : "no";
  const home_fallenTwiceFracComment = tempDbData["home_fallenTwiceFracComment"];
  const home_fallenTwiceFracByFall =
    tempDbData["home_fallenTwiceFracByFallVal"] === "1" ? "yes" : "no";
  const home_fallenTwiceFracByFallComment =
    tempDbData["home_fallenTwiceFracByFallComment"];
  const home_fallenTwiceOstMed =
    tempDbData["home_fallenTwiceOstMedVal"] === "1" ? "yes" : "no";
  const home_fallenTwiceOstMedComment =
    tempDbData["home_fallenTwiceOstMedComment"];

  const home_fallenThreeWryFall =
    tempDbData["home_fallenThreeWryFallVal"] === "1" ? "yes" : "no";
  const home_fallenThreeWryFallComment =
    tempDbData["home_fallenThreeWryFallComment"];
  const home_fallenThreeWrisFall =
    tempDbData["home_fallenThreeWrisFallVal"] === "1" ? "yes" : "no";
  const home_fallenThreeWrisFallComment =
    tempDbData["home_fallenThreeWrisFallComment"];
  const home_fallenThreeFrac =
    tempDbData["home_fallenThreeFracVal"] === "1" ? "yes" : "no";
  const home_fallenThreeFracComment = tempDbData["home_fallenThreeFracComment"];
  const home_fallenThreeFracByFall =
    tempDbData["home_fallenThreeFracByFallVal"] === "1" ? "yes" : "no";
  const home_fallenThreeFracByFallComment =
    tempDbData["home_fallenThreeFracByFallComment"];
  const home_fallenThreeOstMed =
    tempDbData["home_fallenThreeOstMedVal"] === "1" ? "yes" : "no";
  const home_fallenThreeOstMedComment =
    tempDbData["home_fallenThreeOstMedComment"];

  const home_fallenMoreThreeWryFall =
    tempDbData["home_fallenMoreThreeWryFallVal"] === "1" ? "yes" : "no";
  const home_fallenMoreThreeWryFallComment =
    tempDbData["home_fallenMoreThreeWryFallComment"];
  const home_fallenMoreThreeWrisFall =
    tempDbData["home_fallenMoreThreeWrisFallVal"] === "1" ? "yes" : "no";
  const home_fallenMoreThreeWrisFallComment =
    tempDbData["home_fallenMoreThreeWrisFallComment"];
  const home_fallenMoreThreeFrac =
    tempDbData["home_fallenMoreThreeFracVal"] === "1" ? "yes" : "no";
  const home_fallenMoreThreeFracComment =
    tempDbData["home_fallenMoreThreeFracComment"];
  const home_fallenMoreThreeFracByFall =
    tempDbData["home_fallenMoreThreeFracByFallVal"] === "1" ? "yes" : "no";
  const home_fallenMoreThreeFracByFallComment =
    tempDbData["home_fallenMoreThreeFracByFallComment"];
  const home_fallenMoreThreeOstMed =
    tempDbData["home_fallenMoreThreeOstMedVal"] === "1" ? "yes" : "no";
  const home_fallenMoreThreeOstMedComment =
    tempDbData["home_fallenMoreThreeOstMedComment"];

  const home_afraidOfAnyOneSubAnsWho =
    tempDbData["home_afraidOfAnyOneSubAnsWhoVal"];
  const home_afraidOfAnyOneSubAnsAt =
    tempDbData["home_afraidOfAnyOneSubAnsAtVal"];
  const home_afraidOfAnyOneSubAnsSafe =
    tempDbData["home_afraidOfAnyOneSubAnsSafeVal"];
  const home_afraidOfAnyOneSubAnsAssist =
    tempDbData["home_afraidOfAnyOneSubAnsAssistVal"];

  tempDbData = {
    ...tempData,
    home_fallenOnceWryFall,
    home_fallenOnceWryFallComment,
    home_fallenOnceWrisFall,
    home_fallenOnceWrisFallComment,
    home_fallenOnceFrac,
    home_fallenOnceFracComment,
    home_fallenOnceFracByFall,
    home_fallenOnceFracByFallComment,
    home_fallenOnceOstMed,
    home_fallenOnceOstMedComment,
    home_fallenTwiceWryFall,
    home_fallenTwiceWryFallComment,
    home_fallenTwiceWrisFall,
    home_fallenTwiceWrisFallComment,
    home_fallenTwiceFrac,
    home_fallenTwiceFracComment,
    home_fallenTwiceFracByFall,
    home_fallenTwiceFracByFallComment,
    home_fallenTwiceOstMed,
    home_fallenTwiceOstMedComment,
    home_fallenThreeWryFall,
    home_fallenThreeWryFallComment,
    home_fallenThreeWrisFall,
    home_fallenThreeWrisFallComment,
    home_fallenThreeFrac,
    home_fallenThreeFracComment,
    home_fallenThreeFracByFall,
    home_fallenThreeFracByFallComment,
    home_fallenThreeOstMed,
    home_fallenThreeOstMedComment,
    home_fallenMoreThreeWryFall,
    home_fallenMoreThreeWryFallComment,
    home_fallenMoreThreeWrisFall,
    home_fallenMoreThreeWrisFallComment,
    home_fallenMoreThreeFrac,
    home_fallenMoreThreeFracComment,
    home_fallenMoreThreeFracByFall,
    home_fallenMoreThreeFracByFallComment,
    home_fallenMoreThreeOstMed,
    home_fallenMoreThreeOstMedComment,
    home_obstInHouse,
    home_wishToChange,
    home_qualityOfLife,
    home_abusedBySomeone,
    home_feelingLikeHarming,
    home_feelingLikeHarmingSubAnsWho,
    home_feelingLikeHarmingSubAnsAt,
    home_feelingLikeHarmingSubAnsSafe,
    home_feelingLikeHarmingSubAnsAssist,
    home_afraidOfAnyOne,
    home_elecCords,
    home_noMats,
    home_adeLight,
    home_handRail,
    home_hotWater,
    home_smokeDet,
    home_co2Det,
    home_escRoute,
    home_obstInHouseComment,
    home_elecCordsComment,
    home_noMatsComment,
    home_adeLightComment,
    home_handRailComment,
    home_hotWaterComment,
    home_smokeDetComment,
    home_co2DetComment,
    home_escRouteComment,
    home_wishToChangeComment,
    home_qualityOfLifeComment,
    home_abusedBySomeoneComment,
    home_feelingLikeHarmingComment,
    home_afraidOfAnyOneComment,
    home_afraidOfAnyOneSubAnsWho,
    home_afraidOfAnyOneSubAnsAt,
    home_afraidOfAnyOneSubAnsSafe,
    home_afraidOfAnyOneSubAnsAssist,
  };

  // console.log(tempDbData, tempData, "home");
  return tempDbData;
};

export const setHomeSafetyData = (formData) => {
  let tempData = {};

  homeSafetyOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1]),
    };
  });

  const home_obstInHouseVal =
    formData["home_obstInHouse"] === "yes" ? "1" : "2";
  const home_elecCordsVal = formData["home_elecCords"] === "yes" ? "1" : "2";
  const home_noMatsVal = formData["home_noMats"] === "yes" ? "1" : "2";
  const home_adeLightVal = formData["home_adeLight"] === "yes" ? "1" : "2";
  const home_handRailVal = formData["home_handRail"] === "yes" ? "1" : "2";
  const home_hotWaterVal = formData["home_hotWater"] === "yes" ? "1" : "2";
  const home_smokeDetVal = formData["home_smokeDet"] === "yes" ? "1" : "2";
  const home_co2DetVal = formData["home_co2Det"] === "yes" ? "1" : "2";
  const home_escRouteVal = formData["home_escRoute"] === "yes" ? "1" : "2";
  const home_obstInHouseComment = formData["home_obstInHouseComment"];
  const home_elecCordsComment = formData["home_elecCordsComment"];
  const home_noMatsComment = formData["home_noMatsComment"];
  const home_adeLightComment = formData["home_adeLightComment"];
  const home_handRailComment = formData["home_handRailComment"];
  const home_hotWaterComment = formData["home_hotWaterComment"];
  const home_smokeDetComment = formData["home_smokeDetComment"];
  const home_co2DetComment = formData["home_co2DetComment"];
  const home_escRouteComment = formData["home_escRouteComment"];
  const home_wishToChangeComment = formData["home_wishToChangeComment"];
  const home_qualityOfLifeComment = formData["home_qualityOfLifeComment"];
  const home_abusedBySomeoneComment = formData["home_abusedBySomeoneComment"];
  const home_feelingLikeHarmingComment =
    formData["home_feelingLikeHarmingComment"];
  const home_afraidOfAnyOneComment = formData["home_afraidOfAnyOneComment"];
  const home_wishToChangeVal =
    formData["home_wishToChange"] === "yes" ? "1" : "2";
  const home_qualityOfLifeVal =
    formData["home_qualityOfLife"] === "yes" ? "1" : "2";
  const home_abusedBySomeoneVal =
    formData["home_abusedBySomeone"] === "yes" ? "1" : "2";
  const home_feelingLikeHarmingVal =
    formData["home_feelingLikeHarming"] === "yes" ? "1" : "2";
  const home_feelingLikeHarmingSubAnsWhoVal =
    formData["home_feelingLikeHarmingSubAnsWho"];
  const home_feelingLikeHarmingSubAnsAtVal =
    formData["home_feelingLikeHarmingSubAnsAt"];
  const home_feelingLikeHarmingSubAnsSafeVal =
    formData["home_feelingLikeHarmingSubAnsSafe"];
  const home_feelingLikeHarmingSubAnsAssistVal =
    formData["home_feelingLikeHarmingSubAnsAssist"];
  const home_afraidOfAnyOneVal =
    formData["home_afraidOfAnyOne"] === "yes" ? "1" : "2";
  const home_fallenOnceWryFallVal =
    formData["home_fallenOnceWryFall"] === "yes" ? "1" : "2";
  const home_fallenOnceWryFallComment =
    formData["home_fallenOnceWryFallComment"];
  const home_fallenOnceWrisFallVal =
    formData["home_fallenOnceWrisFall"] === "yes" ? "1" : "2";
  const home_fallenOnceWrisFallComment =
    formData["home_fallenOnceWrisFallComment"];
  const home_fallenOnceFracVal =
    formData["home_fallenOnceFrac"] === "yes" ? "1" : "2";
  const home_fallenOnceFracComment = formData["home_fallenOnceFracComment"];
  const home_fallenOnceFracByFallVal =
    formData["home_fallenOnceFracByFall"] === "yes" ? "1" : "2";
  const home_fallenOnceFracByFallComment =
    formData["home_fallenOnceFracByFallComment"];
  const home_fallenOnceOstMedVal =
    formData["home_fallenOnceOstMed"] === "yes" ? "1" : "2";
  const home_fallenOnceOstMedComment = formData["home_fallenOnceOstMedComment"];

  const home_fallenTwiceWryFallVal =
    formData["home_fallenTwiceWryFall"] === "yes" ? "1" : "2";
  const home_fallenTwiceWryFallComment =
    formData["home_fallenTwiceWryFallComment"];
  const home_fallenTwiceWrisFallVal =
    formData["home_fallenTwiceWrisFall"] === "yes" ? "1" : "2";
  const home_fallenTwiceWrisFallComment =
    formData["home_fallenTwiceWrisFallComment"];
  const home_fallenTwiceFracVal =
    formData["home_fallenTwiceFrac"] === "yes" ? "1" : "2";
  const home_fallenTwiceFracComment = formData["home_fallenTwiceFracComment"];
  const home_fallenTwiceFracByFallVal =
    formData["home_fallenTwiceFracByFall"] === "yes" ? "1" : "2";
  const home_fallenTwiceFracByFallComment =
    formData["home_fallenTwiceFracByFallComment"];
  const home_fallenTwiceOstMedVal =
    formData["home_fallenTwiceOstMed"] === "yes" ? "1" : "2";
  const home_fallenTwiceOstMedComment =
    formData["home_fallenTwiceOstMedComment"];

  const home_fallenThreeWryFallVal =
    formData["home_fallenThreeWryFall"] === "yes" ? "1" : "2";
  const home_fallenThreeWryFallComment =
    formData["home_fallenThreeWryFallComment"];
  const home_fallenThreeWrisFallVal =
    formData["home_fallenThreeWrisFall"] === "yes" ? "1" : "2";
  const home_fallenThreeWrisFallComment =
    formData["home_fallenThreeWrisFallComment"];
  const home_fallenThreeFracVal =
    formData["home_fallenThreeFrac"] === "yes" ? "1" : "2";
  const home_fallenThreeFracComment = formData["home_fallenThreeFracComment"];
  const home_fallenThreeFracByFallVal =
    formData["home_fallenThreeFracByFall"] === "yes" ? "1" : "2";
  const home_fallenThreeFracByFallComment =
    formData["home_fallenThreeFracByFallComment"];
  const home_fallenThreeOstMedVal =
    formData["home_fallenThreeOstMed"] === "yes" ? "1" : "2";
  const home_fallenThreeOstMedComment =
    formData["home_fallenThreeOstMedComment"];

  const home_fallenMoreThreeWryFallVal =
    formData["home_fallenMoreThreeWryFall"] === "yes" ? "1" : "2";
  const home_fallenMoreThreeWryFallComment =
    formData["home_fallenMoreThreeWryFallComment"];
  const home_fallenMoreThreeWrisFallVal =
    formData["home_fallenMoreThreeWrisFall"] === "yes" ? "1" : "2";
  const home_fallenMoreThreeWrisFallComment =
    formData["home_fallenMoreThreeWrisFallComment"];
  const home_fallenMoreThreeFracVal =
    formData["home_fallenMoreThreeFrac"] === "yes" ? "1" : "2";
  const home_fallenMoreThreeFracComment =
    formData["home_fallenMoreThreeFracComment"];
  const home_fallenMoreThreeFracByFallVal =
    formData["home_fallenMoreThreeFracByFall"] === "yes" ? "1" : "2";
  const home_fallenMoreThreeFracByFallComment =
    formData["home_fallenMoreThreeFracByFallComment"];
  const home_fallenMoreThreeOstMedVal =
    formData["home_fallenMoreThreeOstMed"] === "yes" ? "1" : "2";
  const home_fallenMoreThreeOstMedComment =
    formData["home_fallenMoreThreeOstMedComment"];

  const home_afraidOfAnyOneSubAnsWhoVal =
    formData["home_afraidOfAnyOneSubAnsWho"];
  const home_afraidOfAnyOneSubAnsAtVal =
    formData["home_afraidOfAnyOneSubAnsAt"];
  const home_afraidOfAnyOneSubAnsSafeVal =
    formData["home_afraidOfAnyOneSubAnsSafe"];
  const home_afraidOfAnyOneSubAnsAssistVal =
    formData["home_afraidOfAnyOneSubAnsAssist"];

  tempData = {
    ...tempData,
    home_obstInHouseVal,
    home_elecCordsVal,
    home_noMatsVal,
    home_adeLightVal,
    home_handRailVal,
    home_hotWaterVal,
    home_smokeDetVal,
    home_co2DetVal,
    home_escRouteVal,
    home_obstInHouseComment,
    home_elecCordsComment,
    home_noMatsComment,
    home_adeLightComment,
    home_handRailComment,
    home_hotWaterComment,
    home_smokeDetComment,
    home_co2DetComment,
    home_escRouteComment,
    home_wishToChangeComment,
    home_qualityOfLifeComment,
    home_abusedBySomeoneComment,
    home_feelingLikeHarmingComment,
    home_afraidOfAnyOneComment,
    home_wishToChangeVal,
    home_qualityOfLifeVal,
    home_abusedBySomeoneVal,
    home_feelingLikeHarmingVal,
    home_feelingLikeHarmingSubAnsWhoVal,
    home_feelingLikeHarmingSubAnsAtVal,
    home_feelingLikeHarmingSubAnsSafeVal,
    home_feelingLikeHarmingSubAnsAssistVal,
    home_afraidOfAnyOneVal,
    home_fallenOnceWryFallVal,
    home_fallenOnceWryFallComment,
    home_fallenOnceWrisFallVal,
    home_fallenOnceWrisFallComment,
    home_fallenOnceFracVal,
    home_fallenOnceFracComment,
    home_fallenOnceFracByFallVal,
    home_fallenOnceFracByFallComment,
    home_fallenOnceOstMedVal,
    home_fallenOnceOstMedComment,
    home_fallenTwiceWryFallVal,
    home_fallenTwiceWryFallComment,
    home_fallenTwiceWrisFallVal,
    home_fallenTwiceWrisFallComment,
    home_fallenTwiceFracVal,
    home_fallenTwiceFracComment,
    home_fallenTwiceFracByFallVal,
    home_fallenTwiceFracByFallComment,
    home_fallenTwiceOstMedVal,
    home_fallenTwiceOstMedComment,
    home_fallenThreeWryFallVal,
    home_fallenThreeWryFallComment,
    home_fallenThreeWrisFallVal,
    home_fallenThreeWrisFallComment,
    home_fallenThreeFracVal,
    home_fallenThreeFracComment,
    home_fallenThreeFracByFallVal,
    home_fallenThreeFracByFallComment,
    home_fallenThreeOstMedVal,
    home_fallenThreeOstMedComment,
    home_fallenMoreThreeWryFallVal,
    home_fallenMoreThreeWryFallComment,
    home_fallenMoreThreeWrisFallVal,
    home_fallenMoreThreeWrisFallComment,
    home_fallenMoreThreeFracVal,
    home_fallenMoreThreeFracComment,
    home_fallenMoreThreeFracByFallVal,
    home_fallenMoreThreeFracByFallComment,
    home_fallenMoreThreeOstMedVal,
    home_fallenMoreThreeOstMedComment,
    home_afraidOfAnyOneSubAnsWhoVal,
    home_afraidOfAnyOneSubAnsAtVal,
    home_afraidOfAnyOneSubAnsSafeVal,
    home_afraidOfAnyOneSubAnsAssistVal,
  };

  let payloadData = {};

  homeSafety_hashmap.forEach((questionPair) => {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
  });

  return payloadData;
};

const getWrapper = (tempData, keyArray) => {
  let temp = getOptionsSelected(tempData, keyArray);
  return temp;
};

const setWrapper = (tempData, keyArray) => {
  let temp = setOptionsSelected(tempData, keyArray);
  return temp;
};

const getOptionsSelected = (optionObject, optionArray, multiSelect = false) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption;
  if (multiSelect) {
    selectedOption = selectedVariable.map((variable) => {
      return homeSafety_optionsTreeMapObject[variable];
    });
  } else {
    // console.log(homeSafety_optionsTreeMapObject[selectedVariable[0]], "exam");
    selectedOption = homeSafety_optionsTreeMapObject[selectedVariable[0]];
  }
  return selectedOption;
};

const setOptionsSelected = (
  optionsSelected,
  optionArray,
  multiSelect = false,
) => {
  let temp = {};

  if (multiSelect) {
    optionArray.forEach((option) => {
      temp[option] = optionsSelected.includes(
        homeSafety_optionsTreeMapObject[option],
      )
        ? "1"
        : "2";
    });
  } else {
    optionArray.forEach((option) => {
      // console.log(option,optionsSelected,homeSafety_optionsTreeMapObject[option],homeSafety_optionsTreeMapObject[option]===optionsSelected,);
      temp[option] =
        homeSafety_optionsTreeMapObject[option] === optionsSelected ? "1" : "2";
    });
  }
  return temp;
};
