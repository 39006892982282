import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import Button from "../button/button";
import { Textarea } from "@mui/joy";

const AppointmentModal = ({
  open,
  handleClose,
  onSave,
  onExit,
  setCancleStatus,
  setStatusComment,
  statusComment,
  cancleStatus,
}) => {
  const [cancelReason, setCancelReason] = useState("");
  const [cancelComment, setCancelComment] = useState("");
  const [disableTextField, setDisableTextField] = useState();
  const isOtherOptionSelected = cancelReason === "Other";

  const handleSave = () => {
    onSave(cancelComment);
    handleClose();
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          width: "30%",
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "var(--mainTheme)",
          fontSize: "20px",
          color: "white",
          fontFamily: "Inter,sans-serif",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
        id="alert-dialog-title"
      >
        Cancel Appointment
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <MuiRadioGroup
            aria-label="cancel-reason"
            name="cancelReason"
            value={cancelReason}
            onChange={(event) => {
              if (event.target.value == "Patient Cancellation") {
                setCancleStatus("1");
                setDisableTextField(true);
              } else if (event.target.value == "Provider Cancellation") {
                setCancleStatus("2");
                setDisableTextField(false);
              }
              setCancelReason(event.target.value);
            }}
            sx={{
              paddingTop: "22px",
            }}
          >
            <FormControlLabel
              value="Patient Cancellation"
              control={<Radio />}
              label="Patient Cancellation"
              sx={{
                fontSize: "22px",
              }}
            />
            <FormControlLabel
              value="Provider Cancellation"
              control={<Radio />}
              label="Provider Cancellation"
              sx={{
                fontSize: "22px",
                marginTop: "12px",
              }}
            />
          </MuiRadioGroup>
        </FormControl>
        <Textarea
          label="Comment"
          value={cancelComment}
          sx={{
            width: "100%",
            border: "1px solid var(--mainTheme)",
            marginTop: "20px",
          }}
          onChange={(event) => {
            setCancelComment(event.target.value);
            setStatusComment(event.target.value);
          }}
          disabled={disableTextField}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Button
          cursor="pointer"
          width="20%"
          height="34px"
          fontSize="16px"
          onClick={handleSave}
        >
          Save
        </Button>

        <Button
          cursor="pointer"
          width="20%"
          height="34px"
          fontSize="16px"
          onClick={onExit}
        >
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentModal;
