import React from 'react';
import Typography from '@mui/material/Typography';
import '../Label/label.css'

const Label = ({ text }) => {
  return (
    <div>
      <Typography variant="body1" className='typo-text'>{text}</Typography>
    </div>
  );
};

export default Label;

