const ValidationMessage = {
  Account_Locked:
    "Account is locked. Please reset your password or contact Administrator.",
  Add_User_Error: "Error while saving user: ",
  Add_User_Success: "User added successfully.",
  Email_already_exists: "Email already exists.",
  Email_Not_Found: "Email not found.",
  Invalid_EmailId: "Invalid Email Id.",
  Invalid_OTP: "Invalid OTP.",
  Invalid_Password: "Invalid password.",
  OTP_Expired: "OTP expired. Please resend it.",
  OTP_Generate_Error: "OTP cannot be generated. ",
  OTP_Sent_Error: "Error occurred during sending OTP mail to receiver. ",
  OTP_Sent_Success: "OTP sent successfully! Please check your email.",
  Password_Expired: "Your password has expired. Please reset your password.",
  Password_Validation_PI: "Password cannot contain personal information.",
  Reset_Password: "Please reset your password before logging in.",
  Same_Password_Error: "Cannot use the same password as the current one.",
  Update_Error:
    "Something went wrong during DB Update. Please contact Administrator.",
  Password_Reset_Success: "Password reset successfully.",
  Password_Change_Success: "Password changed successfully. Please Login again.",
  Username_Required: "Username is required",
  Password_Required: "Password is required",
  Login_Success: "Login successful.",
  Login_Attempt_1: "You Reached ",
  Login_Attempt_2: " out of 3 login attempts.",
  Email_Required: "Email is required",
  Npi_Required: "NPI is required",
  Npi_Format: "NPI must have numericals only ",
  Npi_Length: "NPI must have  10 digits only ",
  Firstname_Required: "First Name is required",
  Firstname_Format: "First Name must have alphabets only",
  Lastname_Required: "Last Name is required",
  Lastname_Format: "Last Name must have alphabets only",
  County_Required: "County is required",
  ZipCodeExclusion_Required: "ZipCode Exclusion is required",
  ZipCodeExclusion_Format:
    " ZipCode Exclusion must have 5 digit numericals only ",
  County_Format: "County must have alphabets only",
  Zipcode_Required: "Zipcode is required",
  Zipcode_Format: "Zipcode must have numericals only",
  PhoneNumber_Required: "Phone Number is required",
  PhoneNumber_Format: "Phone Number is invalid",
  HomeNumber_Required: "Home Number is required",
  HomeNumber_Format: "Home Number is invalid",
  EmerContactNumber_Required: "Emergency Contact Number is required",
  EmerContactNumber_Format: "Emergency Contact Number is invalid",
  EmerContactRelation_Required: "Emergency Contact Relation is required",
  EmerContactRelation_Format: "Emergency Contact Relation is invalid",
  State_Required: "State is required",
  State_Format: "State must have alphabets only",
  City_Required: "City is required",
  City_Format: "City must have alphabets only",
  EmerContactName_Required: "Emergency Contact Name is required",
  EmerContactName_Format: "Emergency Contact Name must have alphabets only",
  Email_Format_Invalid: "Email format is invalid",
  OTP_Verified: "OTP verified successfully.",
  Password_Length: "Password must have a minimum of 8 characters",
  Password_Format:
    "Password must include at least one uppercase letter, lowercase letter, numeric and special character",
  Confirm_Password_Required: "Confirm Password is required",
  Confirm_Password_Length:
    "Confirm Password must have a minimum of 8 characters",
  Confirm_Password_Mismatch:
    "Confirm password does not match with new password",
  OTP_Session_Expired: "OTP Session Expired",
  Reset_Password_Session_Expired: "Reset Password Session Expired",
  Footer_Copyright: "Copyrights @ 2023 HRA2.0, All Rights Reserved.",
  Old_Password: "Old password and new password cannot be the same",
  Verify_Change_Password: "Invalid old password",
  No_Userrole_Found: " Role not defined in the system.",
  Logout_Success: "User logged out successfully.",
  Failed_To_Add_Email: "Failed to add email to Login table.",
  Failed_To_Add_ClientInfo: "Failed to add client information.",
  Client_added_successfully: "Client added successfully",
  Client_data_already_present: "Client data already present",
  No_Client_Data_to_Add: "No Client Data to Add",
  User_Session_Expired: "User Session Expired",
  Client_Updated_Successfully: "Client Updated successfully",
  Client_Not_Found: "Client Not Found",
  Client_already_updated: "Client already updated",
  Year_already_present: "Year already present in the system",
  Year_added_successfully: "Year added successfully",
};

export default ValidationMessage;
