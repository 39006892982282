const json2023 = {
  sectionId: 3,
  sectionName: "Covid Screening",
  isEditable: false,
  questions: [
    {
      id: 1,
      name: "lastFourteenDays",
      value: "lastFourteenDays",
      type: "label",
      label: "In the last 14 days, have you:",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },
    {
      id: 2,
      name: "covid_travelInternational",
      value: "travelInternational",
      type: "options_tree",
      label: "Traveled Internationally?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "no",
          type: "toggle_button",
        },
      ],
      questionId: 2,
      editToggle: false,
    },
    {
      id: 3,
      name: "covid_dignosedWithCorona",
      value: "dignosedWithCorona",
      type: "options_tree",
      label:
        "Had known exposure to anyone diagnosed with Corona Virus (COVID-19)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 3,
      editToggle: false,
    },
    {
      id: 4,
      name: "covid_closeContact",
      value: "closeContact",
      type: "options_tree",
      label:
        "Had close contact with someone who has traveled to a high risk area?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 4,
      editToggle: false,
    },
    {
      id: 5,
      name: "covid_developFever",
      value: "developFever",
      type: "options_tree",
      label: "Developed Fever?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 5,
      editToggle: false,
    },
    {
      id: 6,
      name: "covid_developCough",
      value: "developCough",
      type: "options_tree",
      label: "Developed Cough?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 6,
      editToggle: false,
    },
    {
      id: 7,
      name: "covid_developSymptoms",
      value: "developSymptoms",
      type: "options_tree",
      label: "Developed Flu like symptoms?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 7,
      editToggle: false,
    },
    {
      id: 8,
      name: "covid_developBreath",
      value: "developBreath",
      type: "options_tree",
      label: "Developed Shortness of breath?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 8,
      editToggle: false,
    },
  ],
};

const json2024 = {
  sectionId: 3,
  sectionName: "Covid Screening",
  isEditable: false,
  questions: [
    {
      id: 1,
      name: "lastFourteenDays",
      value: "lastFourteenDays",
      type: "label",
      label: "In the last 7 days,",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },

    {
      id: 2,
      name: "covid_dignosedWithCorona",
      value: "coronaVirus",
      type: "options_tree",
      label:
        "Have you had known exposure to anyone diagnosed with Corona virus (COVID-19)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 2,
      editToggle: false,
    },
    {
      id: 3,
      name: "covid_highRisk",
      value: "highRiskTravel",
      type: "options_tree",
      label:
        "Had close contact with someone who has traveled to a high-risk area?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "Yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "No",
          type: "toggle_button",
        },
      ],
      questionId: 3,
      editToggle: false,
    },
    {
      id: 4,
      name: "covid_symptoms",
      value: "symptoms",
      type: "options_tree",
      label:
        "Have you recently or do you currently have any of the following symptoms:",
      isExclusive: false,
      fullWidth: true,
      required: true,
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Fever or Chills",
          value: "feverOrChills",
          name: "feverOrChills",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Cough",
          value: "cough",
          name: "cough",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Shortness of Breath or Difficulty Breathing",
          value: "shortnessOfBreath",
          name: "shortnessOfBreath",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Fatigue",
          value: "fatigue",
          name: "fatigue",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Muscle or Body Aches",
          value: "muscleOrBodyAches",
          name: "muscleOrBodyAches",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Headache",
          value: "headache",
          name: "headache",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "New loss of taste or Smell",
          value: "lossOfTaste",
          name: "lossOfTaste",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 8,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Sore Throat",
          value: "soreThroat",
          name: "soreThroat",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Congestion or Runny Nose",
          value: "congestion",
          name: "congestion",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 10,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Nausea or Vomiting",
          value: "nausea",
          name: "nausea",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 11,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Diarrhea",
          value: "diarrhea",
          name: "diarrhea",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 12,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Flu like symptoms",
          value: "flu",
          name: "flu",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 13,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "None",
          value: "none",
          name: "none",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 4,
      editToggle: false,
      depth: 1,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
