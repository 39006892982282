import React from "react";
import "./theme.scss";
import { useUserContext } from "../../contexts/UserContext";
import { Send2GA_TriggerEvent } from "../../utils/googleAnalytics";

export default function Index() {
  const { setCurrentColor } = useUserContext();

  const themeColor = (value) => {
    Send2GA_TriggerEvent("button click", "User changed Theme Color");
    const root = document.documentElement;
    root?.style.setProperty("--mainTheme", value);
    setCurrentColor(value);
    localStorage.setItem("themeColor", value);
  };

  return (
    <>
      <div className="card">
        <div
          className="platte-color"
          onClick={() => themeColor("#1076BC")}
          style={{ background: "#1076BC" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#9762B8")}
          style={{ background: "#9762B8" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#00BAC7")}
          style={{ background: "#00BAC7" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#008A5E")}
          style={{ background: "#008A5E" }}
        ></div>
      </div>
      <div className="card">
        <div
          className="platte-color"
          onClick={() => themeColor("#DEA01E")}
          style={{ background: "#DEA01E" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#B45074")}
          style={{ background: "#B45074" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#BE4F25")}
          style={{ background: "#BE4F25" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#2A625D")}
          style={{ background: "#2A625D" }}
        ></div>
      </div>
      <div className="card">
        <div
          className="platte-color"
          onClick={() => themeColor("#461959")}
          style={{ background: "#461959" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#F11A7B")}
          style={{ background: "#F11A7B" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#0D1282")}
          style={{ background: "#0D1282" }}
        ></div>
        <div
          className="platte-color"
          onClick={() => themeColor("#9336B4")}
          style={{ background: "#9336B4" }}
        ></div>
      </div>
    </>
  );
}
