import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTableComponent from "../../components/Dashboard/DataTable/DataTable";
import LeadCoderListHeader from "./LeadCoderListHeader";
import { SelectDropdown } from "../../components/select/select";
import { HraStatusSelectDropdown } from "../../components/select/hraSelectDropDown";
import MuiInput from "../../components/muiinput/MuiInput";
import TooltipEllipsisCell from "../../components/TooltipEllipsisCell/TooltipEllipsisCell";
import "./coderList.css";
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import { getApi, postApi, putApi } from "../../utils/axiosApiWrapper";
import { useUserContext } from "../../contexts/UserContext";
import Loader from "../../components/Loader/Loader";
import VphpDialog from "../../components/Dashboard/MemberList/vphp/vphp";
import Alert from "../../components/Alert/Alert";
import Toaster from "../../components/toaster/toaster";
import Labels from "../../resource_files/Labels.resx";
import AppointmentModal from "../../components/AppointmentModal/AppointmentModal";
import { Checkbox } from "@mui/material";
import _, { filter } from "lodash";
import AlertContext from "../../contexts/AlertContext";
import moment from "moment";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import CoderGraphs from "./Graphs/CoderGraphs";
import { getClientsData, getSchedulersData } from "../../api/filterApi";

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Coderlist = () => {
  const [assignedClinicianValues, setAssignedClinicianValues] = useState([]);
  const [assignedCodingValues, setAssignedCodingValues] = useState([]);
  const [assignedQaValues, setAssignedQaValues] = useState([]);
  const [assignedTermDateValues, setAssignedTermDateValues] = useState([]);
  const [assignedScheduleDateValues, setAssignedScheduleDateValues] = useState(
    [],
  );
  const [visitTypeValues, setVisitTypeValues] = useState([]);
  const [scheduleDateValues, setScheduleDateValues] = useState({});
  const [termDateValues, setTermDateValues] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    clientData,
    isDialogOpen,
    setClientData,
    apiResponseData,
    setApiResponseData,
    selectedTimeZoneUtc,
  } = useUserContext();
  const [hraStatusOptions, setHraStatusOptions] = useState([]);
  const [hraStatusOptionForFilter, setHraStatusOptionForFilter] = useState([]);
  const [visitTypeOptions, setVisitTypeOptions] = useState([]);
  const [clinicianData, setClinicianData] = useState([]);
  const { updateAssessmentData, getAssessment } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowCompletedCheckboxes, setSelectedRowCompletedCheckboxes] =
    useState({});
  const [
    selectAllCompletedHeaderCheckbox,
    setSelectAllCompletedHeaderCheckbox,
  ] = useState(false);

  const [selectedRowUploadCheckboxes, setSelectedRowUploadCheckboxes] =
    useState({});
  const [selectAllUploadHeaderCheckbox, setSelectAllUploadHeaderCheckbox] =
    useState(false);
  const [selectedRowCheckboxes, setSelectedRowCheckboxes] = useState({});
  const [, setAreCheckboxesSelected] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [isActivateAlertOpen, setIsActivateAlertOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({
    memberId: "",
    assessmentId: "",
    Name: "",
    plan: "",
  });
  const [hraStatus, setHraStatus] = useState([]);
  const [isVisitTypeAlertOpen, setIsVisitTypeAlertOpen] = useState(false);
  const [isAssignedClinicianAlertOpen, setIsAssignedClinicianAlertOpen] =
    useState(false);
  const [isAssignedCodingAlertOpen, setIsAssignedCodingAlertOpen] =
    useState(false);
  const [isAssignedQaAlertOpen, setIsAssignedQaAlertOpen] = useState(false);
  const [isAssignedTermDateAlertOpen, setIsAssignedTermDateAlertOpen] =
    useState(false);
  const [isAssignedScheduleDateAlertOpen, setIsAssignedScheduleDateAlertOpen] =
    useState(false);
  const [selectedVisitTypeChange, setSelectedVisitTypeChange] = useState(null);
  const [selectedHraStatusTypeChange, setSelectedHraStatusTypeChange] =
    useState(null);
  const [selectedAssignedClinicianChange, setSelectedAssignedClinicianChange] =
    useState(null);
  const [selectedAssignedCodingChange, setSelectedAssignedCodingChange] =
    useState(null);
  const [selectedAssignedTermDateChange, setSelectedAssignedTermDateChange] =
    useState(null);
  const [
    selectedAssignedScheduleDateChange,
    setSelectedAssignedScheduleDateChange,
  ] = useState(null);
  const [selectedAssignedQaChange, setSelectedAssignedQaChange] =
    useState(null);

  const [visitTypeData, setVisitTypeData] = useState([]);
  const [isCancelAppointmentAlertOpen, setIsCancelAppointmentAlertOpen] =
    useState(false);
  const [clinicianOptions, setClinicianOptions] = useState([]);
  const [coderOptions, setCoderOptions] = useState([]);
  const [qaOptions, setQaOptions] = useState([]);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedCoderRowIndex, setSelectedCoderRowIndex] = useState(null);
  const [selectedTermDateRowIndex, setSelectedTermDateRowIndex] =
    useState(null);
  const [selectedScheduleDateRowIndex, setSelectedScheduleDateRowIndex] =
    useState(null);
  const [selectedQaRowIndex, setSelectedQaRowIndex] = useState(null);
  const [usersByProgramRoleId, setUsersByProgramRoleId] = useState([]);
  const alertContext = useContext(AlertContext);

  const navigate = useNavigate();
  const storedPlan = localStorage.getItem("clientPlan"); //|| clientData?.plan;
  const storedYear = localStorage.getItem("clientYear"); //|| clientData?.year;
  const storedScreeningTypes = localStorage.getItem("storedScreeningTypes");

  const handleVphpSelection = (responseData) => {
    setResponseData(responseData);
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("defaultColumnName");
  const [tabId, setTabId] = useState(1);

  const handleTabChange = (newValue) => {
    let updatedTabId;

    if (newValue === "Ready For Coding") {
      updatedTabId = 1;
    } else if (newValue === "Clinician Review") {
      updatedTabId = 2;
    } else if (newValue === "Ready For Upload") {
      updatedTabId = 3;
    } else if (newValue === "Completed") {
      updatedTabId = 4;
    } else {
      updatedTabId = null;
    }

    setTabId(updatedTabId);
    setSelectedDropdownValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tabId !== null && storedPlan && storedYear) {
        await fetchMemberData(storedPlan, storedYear, tabId);
      }
    };

    fetchData();
  }, [tabId, storedPlan, storedYear]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (storedPlan && storedYear) {
      fetchMemberData(storedPlan, storedYear);
    }
  }, [storedPlan, storedYear, page, rowsPerPage]);

  useEffect(() => {
    if (!_.isNull(alertContext?.filterDialogData)) {
      handleFilter();
    } else {
      if (storedPlan && storedYear) fetchMemberData(storedPlan, storedYear);
    }
  }, [alertContext?.filterDialogData]);

  const handleFilter = async () => {
    try {
      const filterData = alertContext?.filterDialogData;
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: storedYear,
        planId: storedPlan,
        tabId: tabId,
      };
      if (!_.isEmpty(filterData?.program)) {
        params.program = filterData?.program;
      }
      if (!_.isEmpty(filterData?.lob)) {
        params.lob = filterData?.lob;
      }
      if (!_.isEmpty(filterData?.visitType)) {
        params.visitType = filterData?.visitType;
      }
      if (!_.isEmpty(filterData?.scheduleDateFrom)) {
        params.scheduleDateFrom = moment(filterData?.scheduleDateFrom).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.scheduleDateTo)) {
        params.scheduleDateTo = moment(filterData?.scheduleDateTo).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.nurseId)) {
        params.nurseId = filterData?.nurseId;
      }

      if (!_.isEmpty(filterData?.coderId)) {
        params.coderId = filterData?.coderId;
      }

      if (!_.isEmpty(filterData?.qaId)) {
        params.qaId = filterData?.qaId;
      }

      if (!_.isEmpty(filterData?.subStatus)) {
        params.subStatus = filterData?.subStatus;
      }

      const response = await getApi("/coder/getMembersForFilters", { params });
      const totalCount = response?.data?.data[0]?.count ?? 0;
      setTotalRowCount(totalCount);
      handleVphpSelection(response?.data ?? []);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const fetchMemberData = async (
    plan,
    date,
    filter,
    searchTerm,
    defaultSortBy,
    defaultOrder,
  ) => {
    setLoading(true);
    try {
      var DaySorting = "";
      if (defaultSortBy == "Days") {
        if (tabId == 1) {
          var DaySorting = "readyForCoderDate";
        } else if (tabId == 2) {
          var DaySorting = "inProgressDate";
        } else if (tabId == 3) {
          var DaySorting = "readyForUploadDate";
        } else if (tabId == 4) {
          var DaySorting = "completedDate";
        } else {
          var DaySorting = "";
        }
      }

      const columnMappings = {
        ID: "assessmentData.memberId",
        Name: "assessmentData.demographics.data.firstName",
        Program: "program",
        LOB: "assessmentData.demographics.data.lob",
        City: "assessmentData.demographics.data.city",
        HRA_Status: "currentStatus.hrastatusName",
        Assigned_Clinician: "clinicanUserData.firstName",
        Schedule_Date: "scheduleDate",
        Visit_Type: "visitType",
        Term_date: "termDate",
        Qa_Analyst: "qaUserData.firstName",
        Coding_Analyst: "coderUserData.firstName",
        Days: DaySorting,
      };
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: date,
        planId: plan,
        searchTerm,
        fieldName:
          filter === "First name"
            ? "assessmentData.demographics.data.firstName"
            : filter === "Identification#"
            ? "memberId"
            : filter === "Last name"
            ? "assessmentData.demographics.data.lastName"
            : null,
        sortValue: defaultOrder,
        sortBy: columnMappings[defaultSortBy],
        tabId: tabId,
      };
      if (searchTerm && searchTerm !== "null" && searchTerm !== "") {
        params.searchTerm = searchTerm;
      }
      //http://localhost:3001/provider/getMembers?page=1&limit=100&year=2023&planId=VPHP&tabId=4
      const response = await getApi("/coder/getMembersForCoder", { params });

      const totalCount = response?.data?.data[0]?.count ?? 0;
      setTotalRowCount(totalCount);
      handleVphpSelection(response?.data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSorting = (defaultSortBy, defaultOrder, filter) => {
    const isAsc = order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    const sortBy = !_.isUndefined(defaultSortBy) ? defaultSortBy : "memberId";

    fetchMemberData(
      storedPlan,
      storedYear,
      filter,
      null,
      sortBy,
      newOrder === "asc" ? 1 : -1,
    );

    setOrder(newOrder);
    setOrderBy(defaultSortBy);
  };

  const handleSearch = (filter, searchTerm) => {
    if (!_.isEmpty(searchTerm)) {
      fetchMemberData(storedPlan, storedYear, filter, searchTerm);
    } else {
      if (storedPlan && storedYear) {
        fetchMemberData(storedPlan, storedYear);
      }
    }
  };

  const fetchHRAStatusOptions = async () => {
    try {
      const response = await getApi("/hra-status");
      const data = await response.data.data;
      setData(data);
      const activeHraStatus = ["Submitted - Ready for Upload"];
      const hraOptions = data.map((status) => status.statusName);
      setHraStatusOptionForFilter(hraOptions);
      const hraOptionsObj = hraOptions?.map((option) => {
        if (
          activeHraStatus.includes(option) &&
          tabId !== 2 &&
          tabId !== 3 &&
          tabId !== 4
        ) {
          return {
            option: option,
            disable: false,
          };
        } else {
          return {
            option: option,
            disable: true,
          };
        }
      });
      setHraStatusOptions(hraOptionsObj);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchHRAStatusOptions();
  }, [tabId]);

  //visit type api
  const fetchVisitTypeOptions = async () => {
    try {
      const response = await getApi("/visitTypes/get-visit-types");
      const data = await response.data.data;
      setVisitTypeData(data);
      const visitOptions = data.map((visit) => visit.visitType);
      setVisitTypeOptions(visitOptions);
      //hraVisitTypeUpdate(responseData);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchVisitTypeOptions();
  }, []);

  useEffect(() => {
    const isAllSelected = localStorage.getItem("isAllSelected");

    if (storedPlan && storedYear && storedScreeningTypes && isAllSelected) {
      const newValue = {
        plan: storedPlan,
        year: storedYear,
        screeningTypes: storedScreeningTypes,
        isAllSelected: JSON.parse(isAllSelected),
      };

      setClientData(newValue);
    }
  }, []);

  // Function to handle updating visit type (Needs to update for Nurse dashboard)
  const handleVisitTypeUpdate = async () => {
    const {
      memberId,
      memberIdTwo,
      planId,
      clientId,
      year,
      visitType,
      assessmentId,
    } = selectedRowData;

    const visitTypeString = String(visitType);

    const selectedVisitType = visitTypeData.find(
      (visit) => visit.visitType === visitTypeString,
    );

    if (selectedVisitType) {
      const { id: visitTypeId } = selectedVisitType;

      const requestBody = {
        data: {
          _id: assessmentId,
          visitType: visitTypeId,
          // type: "visit",
          // memberId,
          // memberIdTwo,
          // plan: planId,
          // clientId,
          // year,
        },
      };

      try {
        const response = await putApi(
          "/visitTypes/updateVisiteType",
          requestBody,
        );

        if (response.status === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsVisitTypeAlertOpen(false);
        setSelectedVisitTypeChange(null);
      }
    } else {
      return;
    }
  };

  const handleVisitTypeCancel = () => {
    setVisitTypeValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedVisitTypeChange.rowIndex] =
        selectedVisitTypeChange.originalValue;
      return updatedValues;
    });

    setIsVisitTypeAlertOpen(false);

    setSelectedVisitTypeChange(null);
  };

  //hra status
  const handleHraStatus = (event, rowIndex) => {
    const { value } = event.target;

    // Check if the selected hraStatus is "Appointment Cancelled"
    if (value === "Appointment Cancelled") {
      setIsCancelAppointmentAlertOpen(true);
    } else {
      setIsActivateAlertOpen(true);
    }

    setHraStatus((prevValues) => {
      const updatedHraStatus = [...prevValues];
      updatedHraStatus[rowIndex] = value;

      // Store memberId and _id from the selected row

      const selectedRowData = responseData?.data[rowIndex];
      const memberId = selectedRowData?.memberId || "";
      const memberIdTwo = selectedRowData?.memberIdTwo || "";
      //const plan = selectedRowData?.assessmentData?.[0]?.plan || "";
      const plan = selectedRowData?.planId;
      const clientId = selectedRowData?.clientId || "";
      const year = selectedRowData?.year || "";
      const assessmentId = selectedRowData?._id || "";
      const _id = selectedRowData?._id || "";
      const Name =
        `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
        "";
      setSelectedRowData({
        memberId,
        assessmentId,
        memberIdTwo,
        plan,
        clientId,
        year,
        _id,
        selectedHraStatus: value,
        Name,
      });

      setSelectedHraStatusTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });

      return updatedHraStatus;
    });
  };

  const handleCancelAppointmentAlertYesClick = async (cancelComment) => {
    const { _id, selectedHraStatus } = selectedRowData;

    const selectedStatus = data.find(
      (status) => status.statusName === selectedHraStatus,
    );

    if (selectedStatus) {
      const { id: hraStatusId } = selectedStatus;

      const requestBody = {
        data: {
          // memberId,
          // memberIdTwo,
          // plan,
          // clientId,
          // year,
          _id,
          hraStatus: {
            hraStatus: hraStatusId,
            subStatus: "",
            cancelStatus: "",
            cancelText: cancelComment,
          },
        },
      };
      try {
        const response = await putApi(
          "/hra-status/updateHraStatus",
          requestBody,
        );

        if (response.data.code === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }

      setIsCancelAppointmentAlertOpen(false);
    } else {
      return;
    }
  };

  const handleCancelAppointmentAlertExitClick = () => {
    setHraStatus((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedHraStatusTypeChange.rowIndex] =
        selectedHraStatusTypeChange.originalValue;
      return updatedValues;
    });
    setIsCancelAppointmentAlertOpen(false);
    setSelectedHraStatusTypeChange(null);
  };

  // Visit Type
  const handleVisitTypeChange = (event, rowIndex) => {
    const { value } = event.target;

    setVisitTypeValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      const selectedRow = responseData?.data[rowIndex];
      const memberId = selectedRow?.memberId || "";
      const memberIdTwo = selectedRow?.memberIdTwo || "";
      const planId = selectedRow?.planId || "";
      const year = selectedRow?.year || "";
      const clientId = selectedRow?.clientId || "";

      const Name =
        `${selectedRow?.assessmentData?.demographics?.data?.firstName} ${selectedRow?.assessmentData?.demographics?.data?.middleName} ${selectedRow?.assessmentData?.demographics?.data?.lastName}` ||
        "";
      setSelectedRowData({
        ...selectedRowData,
        memberId,
        Name,
        memberIdTwo,
        planId,
        clientId,
        year,
        visitType: value,
      });

      setIsVisitTypeAlertOpen(true);

      setSelectedVisitTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });
      return updatedValues;
    });
  };

  useEffect(() => {
    hraVisitTypeUpdate(responseData);
  }, [responseData]);

  useEffect(() => {
    hraDropdownUpdate(responseData);
  }, [responseData]);

  const hraVisitTypeUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedVisitTypeValues = responseData.data.map((item) => {
        const visitTypeId = item.visitType;

        const selectedVisitType = visitTypeData.find(
          (visit) => visit.id === visitTypeId,
        );

        if (selectedVisitType) {
          return selectedVisitType.visitType;
        } else {
          return "";
        }
      });

      setVisitTypeValues(updatedVisitTypeValues);
    }
  };

  const hraDropdownUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedHraStatusValues = responseData.data.map((item) => {
        const hraStatusId = Number(item.hraStatus.hraStatus);

        const selectedStatus = data.find((status) => status.id === hraStatusId);

        if (selectedStatus) {
          return selectedStatus.statusName;
        } else {
          return "";
        }
      });
      setHraStatus(updatedHraStatusValues);
    }
  };

  //Scheduled Date
  const handleAssignedScheduleDateTypeUpdate = async () => {
    if (selectedScheduleDateRowIndex !== null) {
      const selectedRowData = responseData.data[selectedScheduleDateRowIndex];
      const memberObjIds = [selectedRowData._id];
      const scheduleDate =
        assignedScheduleDateValues[selectedScheduleDateRowIndex];

      try {
        const requestBody = {
          memberObjIds,
          scheduleDate,
        };

        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate)
            .toISOString()
            .split("T")[0];
          requestBody.scheduleDate = formattedDate;
        }

        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );

        setIsAssignedScheduleDateAlertOpen(false);

        setToasterMessage(response.data.message);
        setToasterSeverity(Labels.SUCCESS);
        setToasterOpen(true);
        if (storedPlan && storedYear) {
          fetchMemberData(storedPlan, storedYear);
        }
      } catch (error) {
        return;
      } finally {
        setLoading(false);
      }
    }
  };

  const scheduleDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedScheduleDateValues = responseData.data.map((item) => {
        const scheduleDate = item.scheduleDate || "";

        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate)
            .toISOString()
            .split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });

      setAssignedScheduleDateValues(updatedScheduleDateValues);
    }
  };

  useEffect(() => {
    scheduleDateUpdate(responseData);
  }, [responseData]);

  //Term Date
  const handleScheduleDateChange = (event, rowIndex) => {
    const { value } = event.target;

    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedScheduleDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
          "";
        const scheduleDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          scheduleDate,
        });

        setIsAssignedScheduleDateAlertOpen(true);

        setSelectedAssignedScheduleDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (scheduleDate && scheduleDate.trim() !== "") {
          updatedValues[rowIndex] = scheduleDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleAssignedScheduleDateTypeCancel = () => {
    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedScheduleDateChange.rowIndex] =
        selectedAssignedScheduleDateChange.originalValue;
      return updatedValues;
    });

    setIsAssignedScheduleDateAlertOpen(false);
    setSelectedAssignedScheduleDateChange(null);
  };

  const termDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedTermDateValues = responseData.data.map((item) => {
        const termDate = item.termDate || "";

        if (termDate && termDate.trim() !== "") {
          const formattedDate = new Date(termDate).toISOString().split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });

      setAssignedTermDateValues(updatedTermDateValues);
    }
  };

  useEffect(() => {
    termDateUpdate(responseData);
  }, [responseData]);

  //Term Date
  const handleTermDateChange = (event, rowIndex) => {
    const { value } = event.target;

    setAssignedTermDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedTermDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
          "";
        const termDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          termDate,
        });

        setIsAssignedTermDateAlertOpen(true);

        setSelectedAssignedTermDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (termDate && termDate.trim() !== "") {
          updatedValues[rowIndex] = termDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleCheckboxSelection = (isChecked) => {
    setAreCheckboxesSelected(isChecked);
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const handleFillHRAClick = async (
    assessmentId,
    memberIdForQueryForm,
    finalDate,
    evaluationDate,
    patientName,
    screenIha,
    memberType,
  ) => {
    sessionStorage.setItem("patientName", patientName);
    sessionStorage.setItem("screenIha", screenIha);
    sessionStorage.setItem("assessmentId", assessmentId);
    try {
      setIsLoading(true);
      if (responseData.data.length > 0) {
        updateAssessmentData(responseData);
        //const assessmentId = responseData.data[0].uniquMemberId; //responseData.data[0].assessmentData[0]?._id;
        if (assessmentId) {
          const secondApiResponse = await getApi(
            `/hra/get-assessment?id=${assessmentId}`,
          );

          getAssessment(secondApiResponse, memberIdForQueryForm);
          sessionStorage.setItem("finalDate", finalDate);
          sessionStorage.setItem("evaluationDate", evaluationDate);
          // navigate(`/AdminDashboard/FillHRAForm`);
          navigate(`/CoderDashboard/FillHRAForm`, {
            state: { patientName, screenIha, tabId },
          });
        } else {
          return;
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowCheckboxChange = (checked, rowIndex) => {
    setSelectedRowCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));

    const allRowCheckboxesChecked = Object.values({
      ...selectedRowCheckboxes,
      [rowIndex]: checked,
    }).every((isChecked) => isChecked);

    setSelectAllCompletedHeaderCheckbox(allRowCheckboxesChecked);
    setSelectedCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));
  };

  const handleAlertYesClick = async () => {
    const { _id, selectedHraStatus } = selectedRowData;

    const selectedStatus = data.find(
      (status) => status.statusName === selectedHraStatus,
    );

    if (selectedStatus) {
      const { id: hraStatusId } = selectedStatus;

      const requestBody = {
        data: {
          // memberId,
          // memberIdTwo,
          // plan,
          // clientId,
          // year,
          _id,
          hraStatus: {
            hraStatus: hraStatusId,
            subStatus: "",
            cancelStatus: "",
            cancelText: "",
          },
        },
      };

      try {
        const response = await putApi(
          "/hra-status/updateHraStatus",
          requestBody,
        );

        if (response.status === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsActivateAlertOpen(false);
        setSelectedHraStatusTypeChange(null);
      }
    } else {
      return;
    }
  };

  const handleHraStatusCancel = () => {
    setHraStatus((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedHraStatusTypeChange.rowIndex] =
        selectedHraStatusTypeChange.originalValue;
      return updatedValues;
    });

    setIsActivateAlertOpen(false);

    setSelectedHraStatusTypeChange(null);
  };

  const [tableHeaders, setTableHeaders] = useState([
    { field: "Action", headerName: "Action", hidesortable: true },
    { field: "Program", headerName: "Program" },
    { field: "LOB", headerName: "LOB" },
    // { field: "Plan", headerName: "Plan" },
    {
      field: "ID",
      headerName: "ID#",
      type: "number",
    },
    {
      field: "Name",
      headerName: "Name",
    },
    {
      field: "Contact",
      headerName: "Contact",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
    { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
    //{ field: "Schedule_Date", headerName: "Schedule_Date" },
    { field: "Visit_Type", headerName: "Visit_Type" },
    { field: "QA_Analyst", headerName: "QA_Analyst" },
    { field: "HRA_Status", headerName: "HRA_Status" },

    //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
    { field: "Sub_Status", headerName: "Sub_Status" },
    { field: "Days", headerName: "Days" },
    // { field: "Scheduler_Name", headerName: "Scheduler_Name" },
  ]);
  const roleId = sessionStorage.getItem("roleId");
  const handleDropdownChange = (newValue) => {
    setSelectedDropdownValue(newValue);
    handleTabChange(newValue);

    let dynamicHeaders = [];

    if (newValue === "Ready For Coding" && roleId != 8) {
      dynamicHeaders.push(
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "Contact",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        //{ field: "Schedule_Date", headerName: "Schedule_Date" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Ready For Coding" && roleId == 8) {
      dynamicHeaders.push(
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "Contact",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        //{ field: "Schedule_Date", headerName: "Schedule_Date" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        {
          field: "Coding_Analyst",
          headerName: "Coding_Analyst",
        },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Clinician Review" && roleId != 8) {
      dynamicHeaders.push(
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "Contact",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        //{ field: "Schedule_Date", headerName: "Schedule_Date" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Clinician Review" && roleId == 8) {
      dynamicHeaders.push(
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "Contact",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        //{ field: "Schedule_Date", headerName: "Schedule_Date" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        {
          field: "Coding_Analyst",
          headerName: "Coding_Analyst",
        },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Ready For Upload") {
      dynamicHeaders.push(
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "Contact",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        //{ field: "Schedule_Date", headerName: "Schedule_Date" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        {
          field: "Coding_Analyst",
          headerName: "Coding_Analyst",
        },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Completed") {
      dynamicHeaders.push(
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "Contact",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        //{ field: "Schedule_Date", headerName: "Schedule_Date" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        {
          field: "Coding_Analyst",
          headerName: "Coding_Analyst",
        },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
        // { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    }
    setTableHeaders(dynamicHeaders);
  };
  useEffect(() => {
    if (roleId == 8) {
      setTableHeaders([
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "Contact",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        //{ field: "Schedule_Date", headerName: "Schedule_Date" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        {
          field: "Coding_Analyst",
          headerName: "Coding_Analyst",
        },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //{ field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      ]);
    }
  }, [roleId]);

  useEffect(() => {
    if (tabId !== null && storedPlan && storedYear && tabId !== 5) {
      fetchMemberData(storedPlan, storedYear, tabId);
    }
  }, [tabId]);
  const fetchData = async () => {
    try {
      if (apiResponseData) {
        const clinicianUsers =
          apiResponseData.find((obj) => obj.clinician)?.clinician || [];
        const coderUsers =
          apiResponseData.find((obj) => obj.coder)?.coder || [];
        const qaUsers = apiResponseData.find((obj) => obj.qa)?.qa || [];

        setClinicianOptions(clinicianUsers);
        setCoderOptions(coderUsers);
        setQaOptions(qaUsers);
      } else {
        setClinicianOptions([]);
        setCoderOptions([]);
        setQaOptions([]);
      }

      const planId = localStorage.getItem("selectedClientId") || "";
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: planId,
      });

      if (response.status === 404) {
        return;
      }

      if (response.data && response.data.data) {
        const clinicianUsers =
          response.data.data.find((obj) => obj.clinician)?.clinician || [];
        const coderUsers =
          response.data.data.find((obj) => obj.coder)?.coder || [];
        const qaUsers = response.data.data.find((obj) => obj.qa)?.qa || [];

        setClinicianOptions(clinicianUsers);
        setCoderOptions(coderUsers);
        setQaOptions(qaUsers);
      } else {
        setClinicianOptions([]);
        setCoderOptions([]);
        setQaOptions([]);
      }
    } catch (error) {
      setClinicianOptions([]);
      setCoderOptions([]);
      setQaOptions([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    apiResponseData,
    clientData?.plan,
    setClinicianOptions,
    setCoderOptions,
    setQaOptions,
  ]);

  // Assigned Coding Analyst
  const handleAssignedCodingTypeUpdate = async () => {
    if (selectedCoderRowIndex !== null) {
      const selectedRowData = responseData.data[selectedCoderRowIndex];
      const memberObjId = selectedRowData._id;
      const clientId = selectedRowData.clientId;
      const planId = localStorage.getItem("selectedClientId") || "";

      const selectedCoder = coderOptions.find(
        (coder) =>
          `${coder.firstName} ${coder.lastName}` ===
          assignedCodingValues[selectedCoderRowIndex],
      );

      if (selectedCoder) {
        const selectedUserId = selectedCoder.userid;

        try {
          const response = await putApi(
            "/assignClinician/updateAssigneeBasedOnRole",
            {
              members: [
                {
                  memberObjId,
                  clientId,
                  userRoleId: "9",
                  userid: selectedUserId,
                  planId,
                },
              ],
            },
          );

          if (response.status === 200) {
            setToasterOpen(true);
            setToasterSeverity(Labels.SUCCESS);
            setToasterMessage("Coding Analyst Updated Successfully");
            if (storedPlan && storedYear) {
              fetchMemberData(storedPlan, storedYear);
            }
          }
        } catch (error) {
          return;
        } finally {
          setIsAssignedCodingAlertOpen(false);
        }
      }
    }
  };

  const updateAssignedCodingValues = (responseData) => {
    if (responseData?.data) {
      const updatedCodingValues = responseData.data.map((item) => {
        const coderId = item.coderId || "";

        const selectedCoder = coderOptions.find(
          (coder) => coder.userid === coderId,
        );

        if (selectedCoder) {
          const fullName = `${selectedCoder.firstName} ${selectedCoder.lastName}`;
          return fullName;
        } else {
          return "";
        }
      });
      setAssignedCodingValues(updatedCodingValues);
    }
  };
  useEffect(() => {
    updateAssignedCodingValues(responseData);
  }, [responseData, coderOptions]);
  const handleAssignedCodingChange = async (event, rowIndex) => {
    const { value } = event.target;

    setAssignedCodingValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedCoderRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
          "";

        setSelectedRowData({
          memberId,
          Name,
        });

        setIsAssignedCodingAlertOpen(true);

        setSelectedAssignedCodingChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };
  const handleAssignedCodingTypeCancel = () => {
    setAssignedCodingValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedCodingChange.rowIndex] =
        selectedAssignedCodingChange.originalValue;
      return updatedValues;
    });

    setIsAssignedCodingAlertOpen(false);
    setSelectedAssignedCodingChange(null);
  };
  const renderCodingAnalystField = (newValue, index, data1) => {
    return (
      <SelectDropdown
        label="Coding Analyst"
        value={data1.Coding_Analyst || ""}
        onChange={(event) => handleAssignedCodingChange(event, index)}
        options={coderOptions.map(
          (coder) => `${coder.firstName} ${coder.lastName}`,
        )}
      />
    );
  };
  const updateAssignedQaValues = (responseData) => {
    if (responseData?.data) {
      const updatedQaValues = responseData.data.map((item) => {
        const qaId = item.qaId || "";

        const selectedQa = qaOptions.find((qa) => qa.userid === qaId);

        if (selectedQa) {
          const fullName = `${selectedQa.firstName} ${selectedQa.lastName}`;
          return fullName;
        } else {
          return "";
        }
      });

      setAssignedQaValues(updatedQaValues);
    }
  };
  useEffect(() => {
    updateAssignedQaValues(responseData);
  }, [responseData, qaOptions]);

  const renderQaAnalystField = (newValue, index, data1) => {
    return <p>{data1.Qa_Analyst || ""}</p>;
  };
  const calculateDaysFromGivenDate = (item) => {
    let givenDate; //new Date(givenDateString);
    const currentDate = new Date();
    if (tabId == 2 || tabId == 3 || tabId == 4 || tabId == 1) {
      //completed
      // givenDate = new Date(item?.scheduleDate);
      if (item?.hraStatus?.hraStatus == 10) {
        givenDate = item?.readyForUploadDate
          ? new Date(item?.readyForUploadDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 7) {
        givenDate = item?.readyForCoderDate
          ? new Date(item?.readyForCoderDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 6) {
        givenDate = item?.readyForQaDate
          ? new Date(item?.readyForQaDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 8) {
        givenDate = item?.qaReviewDate ? new Date(item?.qaReviewDate) : null;
      } else if (item?.hraStatus?.hraStatus == 9) {
        givenDate = item?.coderReviewDate
          ? new Date(item?.coderReviewDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 11) {
        givenDate = item?.completedDate ? new Date(item?.completedDate) : null;
      } else if (item?.hraStatus?.hraStatus == 2) {
        givenDate = item?.inProgressDate
          ? new Date(item?.inProgressDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 3) {
        givenDate = item?.appointmentCancelledDate
          ? new Date(item?.appointmentCancelledDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 12) {
        givenDate = item?.assessmentCalcelledDate
          ? new Date(item?.assessmentCalcelledDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 4) {
        givenDate = item?.patientRefusalDate
          ? new Date(item?.patientRefusalDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 17) {
        givenDate = item?.otherDate ? new Date(item?.otherDate) : null;
      } else if (item?.hraStatus?.hraStatus == 16) {
        givenDate = item?.hospiceDate ? new Date(item?.hospiceDate) : null;
      } else if (item?.hraStatus?.hraStatus == 18) {
        givenDate = item?.termExpiredDate
          ? new Date(item?.termExpiredDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 19) {
        givenDate = item?.noShowDate ? new Date(item?.noShowDate) : null;
      } else if (item?.hraStatus?.hraStatus == 15) {
        givenDate = item?.deceasedDate ? new Date(item?.deceasedDate) : null;
      }
    }
    if (givenDate) {
      const differenceInMilliseconds = currentDate - givenDate;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      return Math.floor(differenceInDays); // Return the integer part
    } else {
      return "";
    }
  };
  const formatEvaluationDate = (_date) => {
    if (_date) {
      const date = new Date(_date);
      // date.setHours(date.getHours() + 5);
      // date.setMinutes(date.getMinutes() + 30);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      // Get the time parts in 12-hour format
      const hours24 = date.getHours();
      const amPm = hours24 >= 12 ? "PM" : "AM";
      const hours = hours24 % 12 || 12;
      const minutes = date.getMinutes();

      // Format the date and time as strings // MM/DD/yyyy
      // const dateStr = `${year}/${month.toString().padStart(2, "0")}/${day
      //   .toString()
      //   .padStart(2, "0")}`;
      const dateStr = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      const timeStr = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")} ${amPm}`;
      return `${dateStr}  ${timeStr}`;
    } else {
      return "";
    }
  };
  const getSubStatus = (item) => {
    if (item?.coderReviewCount == 1) {
      return "1st Review";
    } else if (item?.coderReviewCount == 2) {
      return "2nd Review";
    } else if (item?.coderReviewCount == 3) {
      return "3rd Review";
    } else if (item?.coderReviewCount >= 4) {
      return `${item?.coderReviewCount}th Review`;
    } else if (
      item?.coderReviewCount == 0 ||
      !Boolean(item?.coderReviewCount)
    ) {
      return "New";
    }
  };
  useEffect(() => {
    if (tabId == 1 || tabId == 2) {
      sessionStorage.setItem("showQueryFormBtn", true);
    } else {
      sessionStorage.setItem("showQueryFormBtn", false);
    }
  }, [tabId]);
  function calculateAge(dob) {
    if (dob != "undefined" && Boolean(dob)) {
      //const dateOfBirth = excelSerialToDate(JSON.parse(JSON.stringify(dob)));
      // const dateOfBirth = dob;
      const dateOfBirth = JSON.parse(JSON?.stringify(dob));
      var birthDateObj = new Date(dateOfBirth);
      // dateOfBirth?.includes("-")
      //   ? new Date(dateOfBirth)
      //   : new Date(excelSerialToDate(dateOfBirth));
      var currentDate = new Date();
      var timeDifference = currentDate?.getTime() - birthDateObj?.getTime();
      var age = Math?.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
      return age;
    } else {
      return 1000;
    }
  }
  const dataMemberList =
    (Array.isArray(responseData.data) ? responseData.data : []).map(
      (item, index) => {
        const demographics = item.assessmentData?.demographics?.data;
        const firstName = demographics?.firstName || "";
        const middleName = demographics?.middleName || "";
        const lastName = demographics?.lastName || "";
        const address = demographics?.address || "";
        const primary = demographics?.primary || "";
        const _id = item?._id || "";
        const screenIha = demographics?.screenIha === "2" ? true : false || "";
        const memberType = item?.memberType;
        const dateOfBirth = demographics?.dob;
        const pedsFormPatientName = demographics?.name;
        return {
          Edit: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              Edit
            </Button>
          ),
          Plan: item.planId,
          Program: item.program,
          LOB: item.assessmentData?.lob || "",
          ID: item.memberId,
          Name:
            pedsFormPatientName?.length > 0
              ? pedsFormPatientName
              : `${firstName} ${middleName} ${lastName}`,
          Contact: `${address} ${primary}`,
          Scheduler_Name: item.schedulerName,
          Schedule_Date: item.scheduleDate || "",
          Visit_Type: visitTypeValues[index] || "",
          Coding_Analyst: assignedCodingValues[index] || "",
          //Qa_Analyst: assignedQaValues[index] || "",
          Qa_Analyst: `${item?.qaUserData?.firstName ?? ""} ${
            item?.qaUserData?.lastName ?? ""
          }`,
          Assigned_Clinician: `${item?.clinicanUserData?.firstName ?? ""} ${
            item?.clinicanUserData?.lastName ?? ""
          }`,
          //assignedClinicianValues[index] || "",
          //   Term_date: item.termDate || "",
          HRA_Status: hraStatus[index] || "",
          _id: _id,
          Action: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              //background="var(--mainTheme)"
              background={
                screenIha
                  ? "var(--colorDarkBlue)"
                  : calculateAge(dateOfBirth) >= 18
                  ? "var(--mainTheme)"
                  : "#ed8e22"
              }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={() => {
                // handleFillHRAClick(item?.assessmentData?._id, item._id)
                sessionStorage.setItem(
                  "selectedClinicianName",
                  `${item?.clinicanUserData?.firstName ?? ""} ${
                    item?.clinicanUserData?.lastName ?? ""
                  }`,
                );
                if (screenIha) {
                  handleFillHRAClick(
                    item?.uniquMemberId,
                    item._id,
                    item?.finalDate,
                    item?.evaluationDate,
                    `${firstName} ${middleName} ${lastName}`,
                    "Screen Only",
                  );
                } else {
                  sessionStorage.setItem("memberType", memberType);
                  sessionStorage.setItem("dob", dateOfBirth);
                  handleFillHRAClick(
                    item?.uniquMemberId,
                    item._id,
                    item?.finalDate,
                    item?.evaluationDate,
                    pedsFormPatientName?.length > 0
                      ? pedsFormPatientName
                      : `${firstName} ${middleName} ${lastName}`,
                    undefined,
                    memberType,
                  );
                }
              }}
            >
              {screenIha
                ? "Screen Only"
                : calculateAge(dateOfBirth) >= 18
                ? "View HRA"
                : "Fill PEDS"}
              {/* {screenIha ? "Screen Only" : "View HRA"} */}
            </Button>
          ),
          Days: calculateDaysFromGivenDate(item),
          Sub_Status: getSubStatus(item),
          Evaluation_Date: formatEvaluationDate(
            item?.finalDate || item?.evaluationDate,
          ),
        };
      },
    ) || [];
  const dataMemberListWithDropdown = dataMemberList.filter((data) => {
    // Filter rows based on the selected HRA_Status
    // if (selectedDropdownValue === "In Progress") {
    //   return data.HRA_Status === "In Progress";
    // } else if (selectedDropdownValue === "Unassigned") {
    //   return data.HRA_Status === "Unscheduled";
    // } else if (selectedDropdownValue === "My Schedule") {
    //   return data.HRA_Status === "Scheduled";
    // } else if (selectedDropdownValue === "QA") {
    //   return data.HRA_Status === "Submitted - Ready for QA";
    // } else if (selectedDropdownValue === "Coding") {
    //   return data.HRA_Status === "Submitted - Ready for Coding";
    // } else if (selectedDropdownValue === "Clinician Review") {
    //   return data.HRA_Status === "Clinician Review - QA";
    // } else if (selectedDropdownValue === "Ready for Upload") {
    //   return data.HRA_Status === "Submitted - Ready for Upload";
    // } else if (selectedDropdownValue === "Cancelled / No Show") {
    //   return (
    //     data.HRA_Status === "Appointment Cancelled" ||
    //     data.HRA_Status === "No Show"
    //   );
    // } else if (selectedDropdownValue === "Term Expired") {
    //   return data.HRA_Status === "Term Expired";
    // } else if (selectedDropdownValue === "Completed") {
    //   return data.HRA_Status === "Completed";
    // } else {
    //   return true;
    // }
    return true;
  });
  hraDropdownUpdate(dataMemberListWithDropdown);
  hraVisitTypeUpdate(dataMemberListWithDropdown);
  const mappingData = useMemo(() => {
    return dataMemberListWithDropdown.map((data1, index) => {
      return {
        ...data1,
        //   Assigned_Clinician: (
        //     <SelectDropdown
        //       label="Assigned Clinician"
        //       value={data1.Assigned_Clinician || ""}
        //       onChange={(event) => handleAssignedClinicianChange(event, index)}
        //       options={clinicianOptions.map(
        //         (clinician) => `${clinician.firstName} ${clinician.lastName}`,
        //       )}
        //     />
        //   ),
        Coding_Analyst: renderCodingAnalystField(
          selectedDropdownValue,
          index,
          data1,
        ),
        QA_Analyst: renderQaAnalystField(selectedDropdownValue, index, data1),
        Assigned_Clinician: <p>{data1.Assigned_Clinician || ""}</p>,
        Visit_Type: <p>{data1.Visit_Type}</p>,
        // tabId !== 3 && tabId !== 4 ? (
        //   <SelectDropdown
        //     label="Visit Type"
        //     value={data1.Visit_Type || ""}
        //     onChange={(event) => handleVisitTypeChange(event, index)}
        //     options={visitTypeOptions}
        //   />
        // ) : (
        //   <p>{data1.Visit_Type}</p>
        // ),
        Schedule_Date: (
          // <MuiInput
          //   type="date"
          //   value={assignedScheduleDateValues[index] || ""}
          //   onChange={(event) => handleScheduleDateChange(event, index)}
          // />
          <p>
            {!_.isEmpty(assignedScheduleDateValues[index])
              ? dayjs(assignedScheduleDateValues[index] || "")
                  .tz(selectedTimeZoneUtc)
                  .format("MM-DD-YYYY hh:mm:ss A")
              : ""}
          </p>
        ),
        Term_date: (
          <MuiInput
            type="date"
            value={
              assignedTermDateValues[index]
                ? dayjs(
                    dayjs(assignedTermDateValues[index] || "")
                      .tz(selectedTimeZoneUtc)
                      .format("lll"),
                  )
                : null
            }
            onChange={(event) => handleTermDateChange(event, index)}
          />
        ),
        HRA_Status: (
          <HraStatusSelectDropdown
            label="HRA status"
            value={data1.HRA_Status || ""}
            onChange={(event) => handleHraStatus(event, index)}
            options={hraStatusOptions}
          />
        ),
        Contact: <TooltipEllipsisCell value={data1.Contact} maxWidth={100} />,
        Scheduler_Name: (
          <TooltipEllipsisCell value={data1.Scheduler_Name} maxWidth={100} />
        ),
        Evaluation_Date: (
          <p>
            {!_.isEmpty(data1?.Evaluation_Date)
              ? dayjs(data1?.Evaluation_Date || "")
                  .tz(selectedTimeZoneUtc)
                  .format("MM-DD-YYYY hh:mm:ss A")
              : ""}
          </p>
        ),
        Offline: (
          <Checkbox
            type="checkbox"
            // value={scheduleDateValues[index] || ""}
            //onChange={(event) => handleScheduleDateChange(event, index)}
          />
        ),
        Days: data1?.Days, //calculateDaysFromGivenDate(item),
        Sub_Status: data1?.Sub_Status, //getSubStatus(item),
      };
    });
  }, [selectedTimeZoneUtc, dataMemberListWithDropdown]);

  const [isAnalyticsActive, setIsAnalyticsActive] = useState(false);

  const [graphFilter, setGraphFilter] = useState(false);

  const handleCloseFilter = () => {
    console.log("closing filter...");
    setGraphFilter(false);
  };

  const handleOpenFilter = () => {
    console.log("Opening filter...");
    setGraphFilter(true);
  };

  const [Appliedfilter, setAppliedFilter] = useState();
  const [coder, setCoder] = useState("");

  const onFilterChange = (filters, coderId, isReset) => {
    // Handle the filter changes here
    if (isReset) setGraphFilter(true);
    else {
      setAppliedFilter(filters);
      setCoder(coderId);
      setGraphFilter(false);
    }
  };

  const storedPlanId = localStorage.getItem("clientPlan");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  useEffect(() => {
    const cData = async () => {
      const cliDt = await getClientsData();
      const currPlanId =
        cliDt.data.data.find((item) => item.plan === storedPlanId)?._id || null;
      const codResponse = await getSchedulersData({
        planId: currPlanId.trim(),
        roleId: "9",
      });
      // Check and set Scheduler data
      const cdData = codResponse?.data?.data || [];
      if (Array.isArray(cdData)) {
        const c = cdData
          .filter((user) => user.userid && user.firstName && user.lastName) // Filter out empty values
          .map((user) => ({
            id: user.userid,
            name: `${user.firstName} ${user.lastName}`,
          }));
        const t = c?.filter((user) => user.name === firstName + " " + lastName);
        setCoder(t[0]?.id);
      }
    };
    if (roleId == 9) cData();
  }, [roleId, storedPlanId, firstName, lastName]);
  return (
    <div className="right-content-coderList">
      <div>
        {/* <DashboardComponent> */}
        {!isDialogOpen && (
          <LeadCoderListHeader
            onDropdownChange={handleDropdownChange}
            onVphpSelection={handleVphpSelection}
            hraStatusOptions={hraStatusOptionForFilter}
            visitTypeOptions={visitTypeOptions}
            clinicianData={clinicianData}
            selectedCheckboxes={selectedCheckboxes}
            setSelectedCheckboxes={setSelectedCheckboxes}
            onSearch={handleSearch}
            tabId={tabId}
            coder={coder}
            setCoder={setCoder}
            onFilterChange={onFilterChange}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            handleOpenFilter={handleOpenFilter}
            handleCloseFilter={handleCloseFilter}
            isAnalyticsActive={isAnalyticsActive}
            setIsAnalyticsActive={setIsAnalyticsActive}
          />
        )}
        {/* </DashboardComponent> */}
      </div>

      {isDialogOpen === true ? (
        <div style={{ marginTop: "10%" }}>
          <VphpDialog
            onSelection={handleVphpSelection}
            fetchMemberData={fetchMemberData}
            setLoading={setLoading}
            routedFrom="coderList"
          />
        </div>
      ) : (
        <div className="container">
          {
            // clientData &&
            // clientData.isAllSelected === true
            (clientData?.isAllSelected === true ||
              localStorage.getItem("isAllSelected") === "true") &&
            (clientData?.plan !== null || storedPlan) &&
            (clientData?.screeningTypes !== null || storedScreeningTypes) &&
            (clientData?.year !== null || storedYear) ? (
              <div className="datatable-memberlist">
                {loading ? (
                  <Loader />
                ) : isAnalyticsActive ? (
                  <CoderGraphs
                    filter={Appliedfilter}
                    coder={coder}
                    setGraphFilter={setGraphFilter}
                  />
                ) : (
                  <DataTableComponent
                    samp="a"
                    data={mappingData}
                    handleSorting={handleSorting}
                    selectedHeader={orderBy}
                    selectedSorting={order}
                    //showCheckboxHeader={showCheckboxHeader}
                    header={tableHeaders}
                    selectedDropdownValue={selectedDropdownValue}
                    selectAllCompletedHeaderCheckboxState={
                      selectAllCompletedHeaderCheckbox
                    }
                    selectAllUploadHeaderCheckboxState={
                      selectAllUploadHeaderCheckbox
                    }
                    selectedRowCompletedCheckboxes={
                      selectedRowCompletedCheckboxes
                    }
                    selectedRowUploadCheckboxes={selectedRowUploadCheckboxes}
                    selectedRowCheckboxes={selectedRowCheckboxes}
                    // handleReadyForUploadHeaderCheckboxChange={
                    //   handleReadyForUploadHeaderCheckboxChange
                    // }
                    // handleCompletedHeaderCheckboxChange={
                    //   handleCompletedHeaderCheckboxChange
                    // }
                    //handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                    handleRowCheckboxChange={handleRowCheckboxChange}
                    handleCheckboxSelection={handleCheckboxSelection}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </div>
            ) : (
              <VphpDialog
                onSelection={handleVphpSelection}
                routedFrom="NurseList"
                fetchMemberData={fetchMemberData}
                setLoading={setLoading}
              />
            )
          }
          {hraStatus && isActivateAlertOpen && (
            <Alert
              open={isActivateAlertOpen}
              handleClose={() => setIsActivateAlertOpen(false)}
              title="Do you want to change HRA Status"
              okText="Yes"
              cancelText="No"
              onAddClick={handleAlertYesClick}
              onNoClick={handleHraStatusCancel}
            />
          )}
          {visitTypeValues && isVisitTypeAlertOpen && (
            <Alert
              open={isVisitTypeAlertOpen}
              handleClose={() => setIsVisitTypeAlertOpen(false)}
              title={`Do you want to change Visit Type of ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleVisitTypeUpdate}
              onNoClick={handleVisitTypeCancel}
            />
          )}
          {/* {assignedClinicianValues && isAssignedClinicianAlertOpen && (
            <Alert
              open={isAssignedClinicianAlertOpen}
              handleClose={() => setIsAssignedClinicianAlertOpen(false)}
              title={`Do you want to assign ${assignedClinicianValues[selectedRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedClinicianTypeUpdate}
              onNoClick={handleAssignedClinicianTypeCancel}
            />
          )} */}
          {assignedCodingValues && isAssignedCodingAlertOpen && (
            <Alert
              open={isAssignedCodingAlertOpen}
              handleClose={() => setIsAssignedCodingAlertOpen(false)}
              title={`Do you want to assign ${assignedCodingValues[selectedCoderRowIndex]} to ${selectedRowData?.Name} ${selectedRowData?.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedCodingTypeUpdate}
              onNoClick={handleAssignedCodingTypeCancel}
            />
          )}
          {/* {assignedQaValues && isAssignedQaAlertOpen && (
            <Alert
              open={isAssignedQaAlertOpen}
              handleClose={() => setIsAssignedQaAlertOpen(false)}
              title={`Do you want to assign ${assignedQaValues[selectedQaRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedQaTypeUpdate}
              onNoClick={handleAssignedQaTypeCancel}
            />
          )} */}
          {/* {assignedTermDateValues && isAssignedTermDateAlertOpen && (
            <Alert
              open={isAssignedTermDateAlertOpen}
              handleClose={() => setIsAssignedTermDateAlertOpen(false)}
              title={`Are you sure you want to change the term date for ${selectedRowData.Name}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedTermDateTypeUpdate}
              onNoClick={handleAssignedTermDateTypeCancel}
            />
          )} */}
          {assignedScheduleDateValues && isAssignedScheduleDateAlertOpen && (
            <Alert
              open={isAssignedScheduleDateAlertOpen}
              handleClose={() => setIsAssignedScheduleDateAlertOpen(false)}
              title={`Do you want to schedule the selected date?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedScheduleDateTypeUpdate}
              onNoClick={handleAssignedScheduleDateTypeCancel}
            />
          )}
          {isCancelAppointmentAlertOpen && (
            <AppointmentModal
              open={isCancelAppointmentAlertOpen}
              handleClose={() => setIsCancelAppointmentAlertOpen(false)}
              onSave={(cancelComment) =>
                handleCancelAppointmentAlertYesClick(cancelComment)
              }
              onExit={handleCancelAppointmentAlertExitClick}
            />
          )}
          <Toaster
            open={toasterOpen}
            onClose={onCloseToaster}
            severity={toasterSeverity}
            message={toasterMessage}
          />
        </div>
      )}

      {isLoading && <Loader />}
    </div>
  );
};

export default Coderlist;
