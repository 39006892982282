import React from "react";
// import { Route, Routes } from "react-router-dom";

import MemberListPage from "../pages/AdminDashboard/MemberListPage/MemberListPage";
import MemberProfile from "../components/AdminDashboard/memberProfile";
// import { InputProvider } from "../contexts/InputContext";
// import { RedirectToLogin } from "./redirectPageRouting";

// import AddMembersPage from "../pages/AdminDashboard/AddMembersPage/AddMembersPage";
// import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
// import DragNDrop from "../pages/AdminDashboard/DragNDrop/DragNDrop";
// import DynamicForms from "../pages/AdminDashboard/DynamicForms/DynamicForms";
// import Reports from "../pages/AdminDashboard/Reports/Reports";
// import Scheduler from "../pages/AdminDashboard/Scheduler/Scheduler";
// import ViewAdministrators from "../pages/AdminDashboard/ViewAdministrators/ViewAdministrators";
// import ViewClients from "../pages/AdminDashboard/ViewClients/ViewClients";
// import ViewClincians from "../pages/AdminDashboard/ViewClinicians/ViewClinicians";
// import ViewReports from "../pages/AdminDashboard/ViewReports/ViewReports";
// import Coder from "../pages/CoderDashboard";
// import CoderDashboardList from "../pages/CoderDashboard/coderlist";
// import ChangePassword from "../pages/Login/ChangePassword/ChangePassword";
// import ForgotPassword from "../pages/Login/ForgotPassword/ForgotPassword";
// import ResetPassword from "../pages/Login/ResetPassword/ResetPassword";
// import Login from "../pages/Login/login";
// import Nurse from "../pages/NurseDashboard";
// import AddNewNurse from "../pages/NurseDashboard/AddNewNurse/AddNewNurse";
// import Calendar from "../pages/NurseDashboard/Calendar";
// import NurseDashboard from "../pages/NurseDashboard/NurseDashboard";
// import OTPScreen from "../pages/OTP/OTPScreen";
// import QA from "../pages/QaDashboard";
// import QADashboardList from "../pages/QaDashboard/qalist";
// import SchedulerDashboard from "../pages/SchedulerDashboard";
// import SchedulerListPage from "../pages/SchedulerDashboard/scheduler";
// import FillHraForm from "../pages/AdminDashboard/MemberListPage/FillHraForm";
// import NotFoundTrap from "../pages/NotFoundTrap/NotFoundTrap";

const AddMembersPage = React.lazy(() =>
  import("../pages/AdminDashboard/AddMembersPage/AddMembersPage"),
);
// const AdminDashboard = React.lazy(() =>
//   import("../pages/AdminDashboard/AdminDashboard")
// );

const DragNDrop = React.lazy(() =>
  import("../pages/AdminDashboard/DragNDrop/DragNDrop"),
);
const DynamicForms = React.lazy(() =>
  import("../pages/AdminDashboard/DynamicForms/DynamicForms"),
);
const Reports = React.lazy(() =>
  import("../pages/AdminDashboard/Reports/Reports"),
);
const Scheduler = React.lazy(() =>
  import("../pages/AdminDashboard/Scheduler/Scheduler"),
);
const ViewAdministrators = React.lazy(() =>
  import("../pages/AdminDashboard/ViewAdministrators/ViewAdministrators"),
);
const ViewClients = React.lazy(() =>
  import("../pages/AdminDashboard/ViewClients/ViewClients"),
);
const ViewClincians = React.lazy(() =>
  import("../pages/AdminDashboard/ViewClinicians/ViewClinicians"),
);
const ViewReports = React.lazy(() =>
  import("../pages/AdminDashboard/ViewReports/ViewReports"),
);
const Coder = React.lazy(() => import("../pages/CoderDashboard"));
const CoderDashboardList = React.lazy(() =>
  import("../pages/CoderDashboard/coderlist"),
);
const ChangePassword = React.lazy(() =>
  import("../pages/Login/ChangePassword/ChangePassword"),
);
const ForgotPassword = React.lazy(() =>
  import("../pages/Login/ForgotPassword/ForgotPassword"),
);
const ResetPassword = React.lazy(() =>
  import("../pages/Login/ResetPassword/ResetPassword"),
);
const Nurse = React.lazy(() => import("../pages/NurseDashboard"));
const AddNewNurse = React.lazy(() =>
  import("../pages/NurseDashboard/AddNewNurse/AddNewNurse"),
);
const Calendar = React.lazy(() => import("../pages/NurseDashboard/Calendar"));
const NurseDashboard = React.lazy(() =>
  import("../pages/NurseDashboard/NurseDashboard"),
);
const OTPScreen = React.lazy(() => import("../pages/OTP/OTPScreen"));
const QA = React.lazy(() => import("../pages/QaDashboard"));
const QADashboardList = React.lazy(() => import("../pages/QaDashboard/qalist"));
const SchedulerDashboard = React.lazy(() =>
  import("../pages/SchedulerDashboard"),
);
const SchedulerListPage = React.lazy(() =>
  import("../pages/SchedulerDashboard/scheduler"),
);
const FillHraForm = React.lazy(() =>
  import("../pages/AdminDashboard/MemberListPage/FillHraForm"),
);

export const ProtectedRoutes = [
  { path: "/", title: "", component: <MemberListPage /> },
  { path: `/Account/ForgotPassword`, title: "", component: <ForgotPassword /> },
  { path: `/Account/Otp`, title: "", component: <OTPScreen /> },
  { path: `/Account/ChangePassword`, title: "", component: <ChangePassword /> },
  { path: `/Account/ResetPassword`, title: "", component: <ResetPassword /> },
  // { path: `/AdminDashboard/*`, title: "", component: <AdminDashboard /> },
  {
    path: "/MemberList",
    title: "",
    component: <MemberListPage />,
  },
  {
    path: "/AdminDashboard/AddNewMember",
    title: "",
    component: <AddMembersPage />,
  },
  {
    path: "/AdminDashboard/ViewClinicians",
    title: "",
    component: <ViewClincians />,
  },
  {
    path: "/AdminDashboard/ViewClients",
    title: "",
    component: <ViewClients />,
  },
  {
    path: "/AdminDashboard/ViewAdministrators",
    component: <ViewAdministrators />,
  },
  { path: "/AdminDashboard/Reports", title: "", component: <Reports /> },
  { path: "/AdminDashboard/Scheduler", title: "", component: <Scheduler /> },
  {
    path: "/AdminDashboard/ImportFiles",
    title: "",
    component: <ViewReports />,
  },
  {
    path: "/AdminDashboard/CreateHRAForm",
    title: "",
    component: <DragNDrop />,
  },
  {
    path: "/AdminDashboard/PreviewHRAForm",
    title: "",
    component: <DynamicForms />,
  },
  {
    path: "/AdminDashboard/FillHRAForm",
    title: "",
    component: <FillHraForm />,
  },
  {
    path: "/AdminDashboard/memberProfile",
    title: "",
    component: <MemberProfile />,
  },
  {
    path: `/SchedulerDashboard/*`,
    title: "",
    component: <SchedulerDashboard />,
  },
  { path: "SchedulerList", title: "", component: <SchedulerListPage /> },
  { path: `/QADashboard/*`, title: "", component: <QA /> },
  { path: "qalist", title: "", component: <QADashboardList /> },
  { path: `/CoderDashboard/*`, title: "", component: <Coder /> },
  { path: "Coderlist", title: "", component: <CoderDashboardList /> },
  { path: `/NurseDashboard/*`, title: "", component: <Nurse /> },
  { path: "Nurselist", title: "", component: <NurseDashboard /> },
  { path: "Calendar", title: "", component: <Calendar /> },
];

// // export default function ProtectedRoutes() {
// //   return (
// //     <>
// //       <Route path={`/Account/ForgotPassword`} element={<ForgotPassword />} />
// //       <Route path={`/Account/Otp`} element={<OTPScreen />} />
// //       <Route path={`/Account/ChangePassword`} element={<ChangePassword />} />
// //       <Route path={`/Account/ResetPassword`} element={<ResetPassword />} />

// //       <Route path={`/AdminDashboard/*`} element={<RedirectToLogin><AdminDashboard /></RedirectToLogin>}>
// //         <Route
// //           path="MemberList"
// //           element={
// //             // <RedirectToLogin>
// //               <MemberListPage />
// //             // </RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="AddNewMember"
// //           element={
// // //            <RedirectToLogin>
// //               <AddMembersPage />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="ViewClinicians"
// //           element={
// // //            <RedirectToLogin>
// //               <ViewClincians />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="ViewClients"
// //           element={
// // //            <RedirectToLogin>
// //               <ViewClients />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="ViewAdministrators"
// //           element={
// // //            <RedirectToLogin>
// //               <ViewAdministrators />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="Reports"
// //           element={
// // //            <RedirectToLogin>
// //               <Reports />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="Scheduler"
// //           element={
// // //            <RedirectToLogin>
// //               <Scheduler />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="ImportFiles"
// //           element={
// // //            <RedirectToLogin>
// //               <ViewReports />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="CreateHRAForm"
// //           element={
// // //            <RedirectToLogin>
// //               <DragNDrop />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="PreviewHRAForm"
// //           element={
// // //            <RedirectToLogin>
// //               <DynamicForms />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route
// //           path="FillHRAForm"
// //           element={
// // //            <RedirectToLogin>
// //               <FillHraForm />
// //             //</RedirectToLogin>
// //           }
// //         />
// //         <Route path="*" element={<h1> PAGE NOT fFOUND</h1>} />
// //       </Route>
// //       <Route path={`/SchedulerDashboard/*`} element={<SchedulerDashboard />}>
// //         <Route path="SchedulerList" element={<SchedulerListPage />} />
// //       </Route>
// //       <Route path={`/QADashboard/*`} element={<QA />}>
// //         <Route path="qalist" element={<QADashboardList />} />
// //       </Route>
// //       <Route path={`/CoderDashboard/*`} element={<Coder />}>
// //         <Route path="Coderlist" element={<CoderDashboardList />} />
// //       </Route>
// //       <Route path={`/NurseDashboard/*`} element={<Nurse />}>
// //         <Route path="Nurselist" element={<NurseDashboard />} />
// //         <Route path="Calendar" element={<Calendar />} />
// //         <Route
// //           path="Addmember"
// //           element={
// //             <InputProvider>
// //               <AddNewNurse />
// //             </InputProvider>
// //           }
// //         />
// //       </Route>
// //     </>
// //   );
// // }
