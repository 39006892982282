import lodash from "lodash";

import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

const vital_flatmap = [
  [
    "vital_diastolicbpVal",
    "vitalSigns.bloodPressure.diastolicbp",
    ["2023", "2024"],
  ],
  [
    "vital_systolicbpVal",
    "vitalSigns.bloodPressure.systolicbp",
    ["2023", "2024"],
  ],
  ["vital_vitalSignsCommentVal", "vitalSigns.comment", ["2023", "2024"]],

  ["vital_respiratoryRateVal", "vitalSigns.respiratoryRate", ["2023", "2024"]],
  ["vital_pulseVal", "vitalSigns.pulse", ["2023", "2024"]],
  ["vital_tempVal", "vitalSigns.temp", ["2023", "2024"]],
  ["vital_pulseOximetryVal", "vitalSigns.pulseOximetry", ["2023", "2024"]],
  ["vital_painScaleVal", "vitalSigns.painScale", ["2023", "2024"]],

  ["vital_feetVal", "bmi.patientsHeight.feet", ["2023", "2024"]],
  ["vital_inchVal", "bmi.patientsHeight.inch", ["2023", "2024"]],
  ["vital_patientsWeightVal", "bmi.patientsWeight", ["2023", "2024"]],
  ["vital_bmiiVal", "bmi.bmi", ["2023", "2024"]],
  ["vital_bmiLabelCommentVal", "bmi.comment", ["2023", "2024"]],

  ["vital_bmiObesityVal", "bmi.obesity", ["2023", "2024"]],
  ["vital_bmiModerateObesityVal", "bmi.moderateObesity", ["2023", "2024"]],
  ["vital_bmiMorbidObesityVal", "bmi.morbidObesity", ["2023", "2024"]],
  ["vital_bmiMalnutritionVal", "bmi.malnutrition", ["2023", "2024"]],
  ["vital_bmiUnderWeightVal", "bmi.underWeight", ["2024"]],
  ["vital_bmiNormalWeightVal", "bmi.normalWeight", ["2024"]],
  ["vital_bmiOverWeightVal", "bmi.overWeight", ["2024"]],

  [
    "vital_bmiMalnutritionSubDescribeActiveVal",
    "bmi.malnutritionSubAnswer.describe.active",
    ["2023", "2024"],
  ],
  [
    "vital_bmiMalnutritionSubDescribeHistoryOfVal",
    "bmi.malnutritionSubAnswer.describe.historyOf",
    ["2023", "2024"],
  ],
  [
    "vital_bmiMalnutritionSubDescribeRuleOutVal",
    "bmi.malnutritionSubAnswer.describe.ruleOut",
    ["2023", "2024"],
  ],
  [
    "vital_bmiMalnutritionSubDescribeCommentVal",
    "bmi.malnutritionSubAnswer.describe.comment",
    ["2023", "2024"],
  ],

  [
    "vital_bmiMalnutritionSubMalnutritionVal",
    "bmi.malnutritionSubAnswer.malnutrition.isMalnutrition",
    ["2023", "2024"],
  ],
  [
    "vital_bmiMalnutritionSubMalnutritionCommentVal",
    "bmi.malnutritionSubAnswer.malnutrition.comment",
    ["2023", "2024"],
  ],

  [
    "vital_bmiMalnutritionSubSupportedByAlbuminVal",
    "bmi.malnutritionSubAnswer.supportedBy.albumin",
    ["2023", "2024"],
  ],
  [
    "vital_bmiMalnutritionSubSupportedByMuscleWaitingVal",
    "bmi.malnutritionSubAnswer.supportedBy.muscleWaiting",
    ["2023", "2024"],
  ],
  [
    "vital_bmiMalnutritionSubSupportedByHistoryOfSevereWtLossVal",
    "bmi.malnutritionSubAnswer.supportedBy.historyOfSevereWtLoss",
    ["2023", "2024"],
  ],
  [
    "vital_bmiMalnutritionSubSupportedByCommentVal",
    "bmi.malnutritionSubAnswer.supportedBy.comment",
    ["2023", "2024"],
  ],

  [
    "vital_specialDietHeartHealthyDietVal",
    "specialDiet.heartHealthyDiet",
    ["2023", "2024"],
  ],
  [
    "vital_specialDietDiabeticDietVal",
    "specialDiet.diabeticDiet",
    ["2023", "2024"],
  ],
  ["vital_specialDietRenalDietVal", "specialDiet.renalDiet", ["2023", "2024"]],
  [
    "vital_specialDietVegetarianVal",
    "specialDiet.vegetarian",
    ["2023", "2024"],
  ],
  ["vital_specialDietVeganVal", "specialDiet.vegan", ["2023", "2024"]],
  [
    "vital_specialDietGlutenFreeVal",
    "specialDiet.glutenFree",
    ["2023", "2024"],
  ],
  ["vital_specialDietKetoVal", "specialDiet.keto", ["2023", "2024"]],
  [
    "vital_specialDietPescatarianVal",
    "specialDiet.pescatarian",
    ["2023", "2024"],
  ],
  ["vital_specialDietSubOtherVal", "specialDiet.other", ["2023", "2024"]],
  ["vital_specialDietTubeFeedingVal", "specialDiet.tubeFeeding", ["2024"]],
  ["vital_specialDietRegularDietVal", "specialDiet.regularDiet", ["2024"]],
  ["vital_specialDietNoneVal", "specialDiet.none", ["2024"]],

  ["vital_specialDietCommentVal", "specialDiet.comment", ["2023", "2024"]],
  [
    "vital_specialDietSubOtherSubDescVal",
    "specialDiet.otherSubAnswer.describe",
    ["2023", "2024"],
  ],

  ["vital_lostWeightNoneVal", "lostWeight.none", ["2023", "2024"]],
  ["vital_lostWeightFiveLbsVal", "lostWeight.fiveLbs", ["2023", "2024"]],
  ["vital_lostWeightTenLbsVal", "lostWeight.tenLbs", ["2023", "2024"]],
  ["vital_lostWeightFifteenLbsVal", "lostWeight.fifteenLbs", ["2023", "2024"]],
  [
    "vital_lostWeightMoreThanFifteenLbsVal",
    "lostWeight.moreThanFifteenLbs",
    ["2023", "2024"],
  ],
  [
    "vital_lostWeightTenPercentYourWeightVal",
    "lostWeight.tenPercentYourWeight",
    ["2023", "2024"],
  ],
  ["vital_lostWeightCommentVal", "lostWeight.comment", ["2023", "2024"]],

  [
    "vital_preventingChangesEducationNeededVal",
    "barriersToChange.educationNeeded",
    ["2024"],
  ],
  [
    "vital_preventingChangesSocialSupportVal",
    "barriersToChange.socialSupport",
    ["2024"],
  ],
  [
    "vital_preventingChangesEnvironmentalFactorsVal",
    "barriersToChange.environmentalFactors",
    ["2024"],
  ],
  ["vital_preventingChangesStressVal", "barriersToChange.stress", ["2024"]],
  [
    "vital_preventingChangesSelfConfidenceVal",
    "barriersToChange.selfConfidence",
    ["2024"],
  ],
  [
    "vital_preventingChangesLackOfMotivationVal",
    "barriersToChange.lackOfMotivation",
    ["2024"],
  ],
  [
    "vital_preventingChangesNotPriorityVal",
    "barriersToChange.notPriority",
    ["2024"],
  ],
  ["vital_preventingChangesNoneVal", "barriersToChange.none", ["2024"]],
  ["vital_preventingChangesSubOtherVal", "barriersToChange.other", ["2024"]],

  [
    "vital_preventingChangesSubOtherSubDescVal",
    "barriersToChange.otherSubAnswer.describe",
    ["2024"],
  ],

  ["vital_preventingChangesCommentVal", "barriersToChange.comment", ["2024"]],

  ["vital_physicalActivityLevelVal", "physicalActivityLevel", ["2024"]],
  [
    "vital_physicalActivityLevelCommentVal",
    "physicalActivityLevelComment",
    ["2024"],
  ],

  [
    "vital_nutritionWeightManagementInterestVal",
    "nutritionWeightManagementInterest",
    ["2024"],
  ],
  [
    "vital_nutritionWeightManagementInterestCommentVal",
    "nutritionWeightManagementInterestComment",
    ["2024"],
  ],

  ["vital_reportedVal", "reported", ["2024"]],

  [
    "vital_nutriVal",
    "recommendations.recommendationsSubAnswers[0].nutritionManagement",
    ["2023", "2024"],
  ],
  [
    "vital_RecommendationsNutriCommentVal",
    "recommendations.recommendationsSubAnswers[0].comment",
    ["2023", "2024"],
  ],

  [
    "vital_caseManVal",
    "recommendations.recommendationsSubAnswers[1].caseManagementReferral",
    ["2024"],
  ],
  [
    "vital_RecommendationsCaseManCommentVal",
    "recommendations.recommendationsSubAnswers[1].comment",
    ["2024"],
  ],

  [
    "vital_followUpVal",
    "recommendations.recommendationsSubAnswers[2].followUpWithClinicianSpecialist",
    ["2024"],
  ],
  [
    "vital_RecommendationsFollowUpCommentVal",
    "recommendations.recommendationsSubAnswers[2].comment",
    ["2024"],
  ],
  [
    "vital_memberToVal",
    "recommendations.recommendationsSubAnswers[3].memberToUseDME",
    ["2024"],
  ],
  [
    "vital_RecommendationsMemberToCommentVal",
    "recommendations.recommendationsSubAnswers[3].comment",
    ["2024"],
  ],

  [
    "vital_otherVal",
    "recommendations.recommendationsSubAnswers[4].other",
    ["2024"],
  ],
  [
    "vital_RecommendationsOtherCommentVal",
    "recommendations.recommendationsSubAnswers[4].comment",
    ["2024"],
  ],

  [
    "vital_otherVal",
    "recommendations.recommendationsSubAnswers[1].other",
    ["2023"],
  ],
  [
    "vital_RecommendationsOtherCommentVal",
    "recommendations.recommendationsSubAnswers[1].comment",
    ["2023"],
  ],
];

const vital_optionsTreeMapObject2023 = {
  vital_bmiObesityVal: "obesity",
  vital_bmiModerateObesityVal: "moderateObesity",
  vital_bmiMorbidObesityVal: "morbidObesity",
  vital_bmiMalnutritionVal: "malnutrition",

  vital_bmiMalnutritionSubDescribeActiveVal: "active",
  vital_bmiMalnutritionSubDescribeHistoryOfVal: "historyOf",
  vital_bmiMalnutritionSubDescribeRuleOutVal: "ruleOut",

  vital_bmiMalnutritionSubSupportedByAlbuminVal: "albumin",
  vital_bmiMalnutritionSubSupportedByMuscleWaitingVal: "muscleWaiting",
  vital_bmiMalnutritionSubSupportedByHistoryOfSevereWtLossVal:
    "historyOfSevereWtLoss",

  vital_specialDietHeartHealthyDietVal: "heartHealthyDiet",
  vital_specialDietDiabeticDietVal: "diabeticDiet",
  vital_specialDietRenalDietVal: "renalDiet",
  vital_specialDietVegetarianVal: "vegetarian",
  vital_specialDietVeganVal: "vegan",
  vital_specialDietGlutenFreeVal: "glutenFree",
  vital_specialDietKetoVal: "keto",
  vital_specialDietPescatarianVal: "pescatarian",
  vital_specialDietSubOtherVal: "other",

  vital_lostWeightNoneVal: "none",
  vital_lostWeightFiveLbsVal: "fiveLbs",
  vital_lostWeightTenLbsVal: "tenLbs",
  vital_lostWeightFifteenLbsVal: "fifteenLbs",
  vital_lostWeightMoreThanFifteenLbsVal: "moreThanFifteenLbs",
  vital_lostWeightTenPercentYourWeightVal: "tenPercentYourWeight",
};

const vital_optionsTreeMapObject2024 = {
  vital_bmiObesityVal: "obesity",
  vital_bmiModerateObesityVal: "moderateObesity",
  vital_bmiMorbidObesityVal: "morbidObesity",
  vital_bmiMalnutritionVal: "malnutrition",
  vital_bmiUnderWeightVal: "underWeight",
  vital_bmiNormalWeightVal: "normalWeight",
  vital_bmiOverWeightVal: "overWeight",

  vital_bmiMalnutritionSubDescribeActiveVal: "active",
  vital_bmiMalnutritionSubDescribeHistoryOfVal: "historyOf",
  vital_bmiMalnutritionSubDescribeRuleOutVal: "ruleOut",

  vital_bmiMalnutritionSubSupportedByAlbuminVal: "albumin",
  vital_bmiMalnutritionSubSupportedByMuscleWaitingVal: "muscleWaiting",
  vital_bmiMalnutritionSubSupportedByHistoryOfSevereWtLossVal:
    "historyOfSevereWtLoss",

  vital_specialDietHeartHealthyDietVal: "heartHealthyDiet",
  vital_specialDietDiabeticDietVal: "diabeticDiet",
  vital_specialDietRenalDietVal: "renalDiet",
  vital_specialDietVegetarianVal: "vegetarian",
  vital_specialDietVeganVal: "vegan",
  vital_specialDietGlutenFreeVal: "glutenFree",
  vital_specialDietKetoVal: "keto",
  vital_specialDietPescatarianVal: "pescatarian",
  vital_specialDietSubOtherVal: "other",
  vital_specialDietTubeFeedingVal: "tubeFeeding",
  vital_specialDietRegularDietVal: "regularDiet",
  vital_specialDietNoneVal: "none",

  vital_lostWeightNoneVal: "none",
  vital_lostWeightFiveLbsVal: "fiveLbs",
  vital_lostWeightTenLbsVal: "tenLbs",
  vital_lostWeightFifteenLbsVal: "fifteenLbs",
  vital_lostWeightMoreThanFifteenLbsVal: "moreThanFifteenLbs",
  vital_lostWeightTenPercentYourWeightVal: "tenPercentYourWeight",

  vital_preventingChangesEducationNeededVal: "educationNeeded",
  vital_preventingChangesSocialSupportVal: "socialSupport",
  vital_preventingChangesEnvironmentalFactorsVal: "environmentalFactors",
  vital_preventingChangesStressVal: "stress",
  vital_preventingChangesSelfConfidenceVal: "selfConfidence",
  vital_preventingChangesLackOfMotivationVal: "lackOfMotivation",
  vital_preventingChangesNotPriorityVal: "notPriority",
  vital_preventingChangesNoneVal: "none",
  vital_preventingChangesSubOtherVal: "other",
};

const vitalOptionsTreeWrappers2023 = [
  [
    "vital_bmi",
    [
      "vital_bmiObesityVal",
      "vital_bmiModerateObesityVal",
      "vital_bmiMorbidObesityVal",
      "vital_bmiMalnutritionVal",
    ],
  ],
  [
    "vital_bmiMalnutritionSubDescribe",
    [
      "vital_bmiMalnutritionSubDescribeActiveVal",
      "vital_bmiMalnutritionSubDescribeHistoryOfVal",
      "vital_bmiMalnutritionSubDescribeRuleOutVal",
    ],
  ],
  [
    "vital_bmiMalnutritionSubSupportedBy",
    [
      "vital_bmiMalnutritionSubSupportedByAlbuminVal",
      "vital_bmiMalnutritionSubSupportedByMuscleWaitingVal",
      "vital_bmiMalnutritionSubSupportedByHistoryOfSevereWtLossVal",
    ],
  ],
  [
    "vital_specialDiet",
    [
      "vital_specialDietHeartHealthyDietVal",
      "vital_specialDietDiabeticDietVal",
      "vital_specialDietRenalDietVal",
      "vital_specialDietVegetarianVal",
      "vital_specialDietVeganVal",
      "vital_specialDietGlutenFreeVal",
      "vital_specialDietKetoVal",
      "vital_specialDietPescatarianVal",
      "vital_specialDietSubOtherVal",
    ],
  ],
  [
    "vital_lostWeight",
    [
      "vital_lostWeightNoneVal",
      "vital_lostWeightFiveLbsVal",
      "vital_lostWeightTenLbsVal",
      "vital_lostWeightFifteenLbsVal",
      "vital_lostWeightMoreThanFifteenLbsVal",
      "vital_lostWeightTenPercentYourWeightVal",
    ],
  ],
];

const vitalOptionsTreeWrappers2024 = [
  [
    "vital_bmi",
    [
      "vital_bmiObesityVal",
      "vital_bmiModerateObesityVal",
      "vital_bmiMorbidObesityVal",
      "vital_bmiMalnutritionVal",
      "vital_bmiUnderWeightVal",
      "vital_bmiNormalWeightVal",
      "vital_bmiOverWeightVal",
    ],
  ],
  [
    "vital_bmiMalnutritionSubDescribe",
    [
      "vital_bmiMalnutritionSubDescribeActiveVal",
      "vital_bmiMalnutritionSubDescribeHistoryOfVal",
      "vital_bmiMalnutritionSubDescribeRuleOutVal",
    ],
  ],
  [
    "vital_bmiMalnutritionSubSupportedBy",
    [
      "vital_bmiMalnutritionSubSupportedByAlbuminVal",
      "vital_bmiMalnutritionSubSupportedByMuscleWaitingVal",
      "vital_bmiMalnutritionSubSupportedByHistoryOfSevereWtLossVal",
    ],
  ],
  [
    "vital_specialDiet",
    [
      "vital_specialDietHeartHealthyDietVal",
      "vital_specialDietDiabeticDietVal",
      "vital_specialDietRenalDietVal",
      "vital_specialDietVegetarianVal",
      "vital_specialDietVeganVal",
      "vital_specialDietGlutenFreeVal",
      "vital_specialDietKetoVal",
      "vital_specialDietPescatarianVal",
      "vital_specialDietSubOtherVal",
      "vital_specialDietTubeFeedingVal",
      "vital_specialDietRegularDietVal",
      "vital_specialDietNoneVal",
    ],
  ],
  [
    "vital_lostWeight",
    [
      "vital_lostWeightNoneVal",
      "vital_lostWeightFiveLbsVal",
      "vital_lostWeightTenLbsVal",
      "vital_lostWeightFifteenLbsVal",
      "vital_lostWeightMoreThanFifteenLbsVal",
      "vital_lostWeightTenPercentYourWeightVal",
    ],
  ],
  [
    "vital_preventingChanges",
    [
      "vital_preventingChangesEducationNeededVal",
      "vital_preventingChangesSocialSupportVal",
      "vital_preventingChangesEnvironmentalFactorsVal",
      "vital_preventingChangesStressVal",
      "vital_preventingChangesSelfConfidenceVal",
      "vital_preventingChangesLackOfMotivationVal",
      "vital_preventingChangesNotPriorityVal",
      "vital_preventingChangesNoneVal",
      "vital_preventingChangesSubOtherVal",
    ],
  ],
];

export const getVitalData = (apiData, year) => {
  let nestedChildsData = {};
  let flattenData = {};

  let flatmap = [];

  vital_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    nestedChildsData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedChildsData, pointerLabels[1], year) || "";
  });

  const vital_diastolicbp = nestedChildsData["vital_diastolicbpVal"];
  const vital_vitalSignsComment =
    nestedChildsData["vital_vitalSignsCommentVal"];
  const vital_systolicbp = nestedChildsData["vital_systolicbpVal"];
  const vital_respiratoryRate = nestedChildsData["vital_respiratoryRateVal"];
  const vital_pulse = nestedChildsData["vital_pulseVal"];
  const vital_temp = nestedChildsData["vital_tempVal"];
  const vital_pulseOximetry = nestedChildsData["vital_pulseOximetryVal"];
  const vital_painScale = nestedChildsData["vital_painScaleVal"];

  const vital_feet = nestedChildsData["vital_feetVal"];
  const vital_inch = nestedChildsData["vital_inchVal"];
  const vital_patientsWeight = nestedChildsData["vital_patientsWeightVal"];
  const vital_bmii = nestedChildsData["vital_bmiiVal"];
  const vital_bmiLabelComment = nestedChildsData["vital_bmiLabelCommentVal"];
  const vital_bmiMalnutritionSubDescribeComment =
    nestedChildsData["vital_bmiMalnutritionSubDescribeCommentVal"];
  const vital_bmiMalnutritionSubMalnutrition = getOptionsSelectedYesNo(
    nestedChildsData["vital_bmiMalnutritionSubMalnutritionVal"],
  );
  const vital_bmiMalnutritionSubMalnutritionComment =
    nestedChildsData["vital_bmiMalnutritionSubMalnutritionCommentVal"];

  const vital_bmiMalnutritionSubSupportedByComment =
    nestedChildsData["vital_bmiMalnutritionSubSupportedByCommentVal"];
  const vital_specialDietComment =
    nestedChildsData["vital_specialDietCommentVal"];

  const vital_lostWeightComment =
    nestedChildsData["vital_lostWeightCommentVal"];

  const vital_specialDietSubOtherSubDesc =
    nestedChildsData["vital_specialDietSubOtherSubDescVal"];

  flattenData = {
    ...flattenData,
    vital_diastolicbp: vital_diastolicbp || "",
    vital_vitalSignsComment: vital_vitalSignsComment || "",
    vital_systolicbp: vital_systolicbp || "",
    vital_respiratoryRate: vital_respiratoryRate || "",
    vital_pulse: vital_pulse || "",
    vital_temp: vital_temp || "",
    vital_pulseOximetry: vital_pulseOximetry || "",
    vital_painScale: vital_painScale || "",
    vital_feet: vital_feet || "",
    vital_inch: vital_inch || "",
    vital_patientsWeight: vital_patientsWeight || "",
    vital_bmii: vital_bmii || "",
    vital_bmiLabelComment: vital_bmiLabelComment || "",
    vital_bmiMalnutritionSubDescribeComment:
      vital_bmiMalnutritionSubDescribeComment || "",
    vital_bmiMalnutritionSubMalnutrition:
      vital_bmiMalnutritionSubMalnutrition || "",
    vital_bmiMalnutritionSubMalnutritionComment:
      vital_bmiMalnutritionSubMalnutritionComment || "",
    vital_bmiMalnutritionSubSupportedByComment:
      vital_bmiMalnutritionSubSupportedByComment || "",
    vital_specialDietComment: vital_specialDietComment || "",
    vital_lostWeightComment: vital_lostWeightComment || "",
    vital_specialDietSubOtherSubDesc: vital_specialDietSubOtherSubDesc || "",
    reportedData: apiData.reported,
  };

  /*

    common fields for all years

    tempdb

    if(2023){

      tempdb
    }else if(2024){

      tempdb
    }


    */

  if (year === "2023") {
    const vital_Recommendations = getSelectedCheckboxes(
      [nestedChildsData["vital_nutriVal"], nestedChildsData["vital_otherVal"]],
      ["nutritionManagement", "other"],
    );

    const vital_RecommendationsNutriComment =
      nestedChildsData["vital_RecommendationsNutriCommentVal"];

    const vital_RecommendationsOtherComment =
      nestedChildsData["vital_RecommendationsOtherCommentVal"];

    flattenData = {
      ...flattenData,

      vital_Recommendations: vital_Recommendations || "",
      vital_RecommendationsNutriComment:
        vital_RecommendationsNutriComment || "",
      vital_RecommendationsOtherComment:
        vital_RecommendationsOtherComment || "",
    };
  } else if (year === "2024") {
    const vital_preventingChangesComment =
      nestedChildsData["vital_preventingChangesCommentVal"];
    const vital_preventingChangesSubOtherSubDesc =
      nestedChildsData["vital_preventingChangesSubOtherSubDescVal"];

    const vital_physicalActivityLevel =
      nestedChildsData["vital_physicalActivityLevelVal"];
    const vital_physicalActivityLevelComment =
      nestedChildsData["vital_physicalActivityLevelCommentVal"];

    const vital_nutritionWeightManagementInterest =
      nestedChildsData["vital_nutritionWeightManagementInterestVal"];
    const vital_nutritionWeightManagementInterestComment =
      nestedChildsData["vital_nutritionWeightManagementInterestCommentVal"];

    const vital_reported = nestedChildsData["vital_reportedVal"];

    const vital_Recommendations = getSelectedCheckboxes(
      [
        nestedChildsData["vital_nutriVal"],
        nestedChildsData["vital_caseManVal"],
        nestedChildsData["vital_followUpVal"],
        nestedChildsData["vital_memberToVal"],
        nestedChildsData["vital_otherVal"],
      ],
      [
        "nutritionManagement",
        "caseManagementReferral",
        "followUpWithClinicianSpecialist",
        "memberToUseDME",
        "other",
      ],
    );

    const vital_RecommendationsNutriComment =
      nestedChildsData["vital_RecommendationsNutriCommentVal"];

    const vital_RecommendationsCaseManComment =
      nestedChildsData["vital_RecommendationsCaseManCommentVal"];

    const vital_RecommendationsFollowUpComment =
      nestedChildsData["vital_RecommendationsFollowUpCommentVal"];

    const vital_RecommendationsMemberToComment =
      nestedChildsData["vital_RecommendationsMemberToCommentVal"];

    const vital_RecommendationsOtherComment =
      nestedChildsData["vital_RecommendationsOtherCommentVal"];

    flattenData = {
      ...flattenData,
      vital_preventingChangesComment: vital_preventingChangesComment || "",
      vital_preventingChangesSubOtherSubDesc:
        vital_preventingChangesSubOtherSubDesc || "",
      vital_physicalActivityLevel: vital_physicalActivityLevel || "",
      vital_physicalActivityLevelComment:
        vital_physicalActivityLevelComment || "",
      vital_nutritionWeightManagementInterest:
        vital_nutritionWeightManagementInterest || "",
      vital_nutritionWeightManagementInterestComment:
        vital_nutritionWeightManagementInterestComment || "",
      vital_reported: vital_reported || "",

      vital_Recommendations: vital_Recommendations || "",
      vital_RecommendationsNutriComment:
        vital_RecommendationsNutriComment || "",
      vital_RecommendationsCaseManComment:
        vital_RecommendationsCaseManComment || "",
      vital_RecommendationsFollowUpComment:
        vital_RecommendationsFollowUpComment || "",
      vital_RecommendationsMemberToComment:
        vital_RecommendationsMemberToComment || "",
      vital_RecommendationsOtherComment:
        vital_RecommendationsOtherComment || "",
    };
  }

  return flattenData;
};

export const setVitalData = (formData, year) => {
  let flattenData = {};

  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  let payloadData = {};

  const vital_diastolicbpVal = formData["vital_diastolicbp"];
  const vital_vitalSignsCommentVal = formData["vital_vitalSignsComment"];
  const vital_systolicbpVal = formData["vital_systolicbp"];
  const vital_respiratoryRateVal = formData["vital_respiratoryRate"];
  const vital_pulseVal = formData["vital_pulse"];
  const vital_tempVal = formData["vital_temp"];
  const vital_pulseOximetryVal = formData["vital_pulseOximetry"];
  const vital_painScaleVal = formData["vital_painScale"];

  const vital_feetVal = formData["vital_feet"];
  const vital_inchVal = formData["vital_inch"];
  const vital_patientsWeightVal = formData["vital_patientsWeight"];
  const vital_bmiiVal = formData["vital_bmii"];
  const vital_bmiLabelCommentVal = formData["vital_bmiLabelComment"];
  const vital_bmiMalnutritionSubDescribeCommentVal =
    formData["vital_bmiMalnutritionSubDescribeComment"];
  const vital_bmiMalnutritionSubMalnutritionVal = setOptionsSelectedYesNo(
    formData["vital_bmiMalnutritionSubMalnutrition"],
  );
  const vital_bmiMalnutritionSubMalnutritionCommentVal =
    formData["vital_bmiMalnutritionSubMalnutritionComment"];
  const vital_bmiMalnutritionSubSupportedByCommentVal =
    formData["vital_bmiMalnutritionSubSupportedByComment"];
  const vital_specialDietCommentVal = formData["vital_specialDietComment"];
  const vital_lostWeightCommentVal = formData["vital_lostWeightComment"];
  const vital_specialDietSubOtherSubDescVal =
    formData["vital_specialDietSubOtherSubDesc"];

  flattenData = {
    ...flattenData,
    vital_diastolicbpVal: vital_diastolicbpVal || "",
    vital_vitalSignsCommentVal,
    vital_systolicbpVal,
    vital_respiratoryRateVal,
    vital_pulseVal,
    vital_tempVal,
    vital_pulseOximetryVal,
    vital_painScaleVal,
    vital_feetVal,
    vital_inchVal,
    vital_patientsWeightVal,
    vital_bmiiVal,
    vital_bmiLabelCommentVal,
    vital_bmiMalnutritionSubDescribeCommentVal,
    vital_bmiMalnutritionSubMalnutritionVal,
    vital_bmiMalnutritionSubMalnutritionCommentVal,
    vital_bmiMalnutritionSubSupportedByCommentVal,
    vital_specialDietCommentVal,
    vital_lostWeightCommentVal,
    vital_specialDietSubOtherSubDescVal,
  };

  if (year === "2023") {
    const vital_RecommendationSelected = formData["vital_Recommendations"];

    const vital_RecommendationsVal = setSelectedCheckboxes(
      vital_RecommendationSelected,
      ["vital_nutriVal", "vital_otherVal"],
      ["nutritionManagement", "other"],
    );

    const vital_RecommendationsNutriCommentVal =
      formData["vital_RecommendationsNutriComment"];

    const vital_RecommendationsOtherCommentVal =
      formData["vital_RecommendationsOtherComment"];

    flattenData = {
      ...flattenData,

      ...vital_RecommendationsVal,
      vital_RecommendationsNutriCommentVal:
        vital_RecommendationsNutriCommentVal || "",
      vital_RecommendationsOtherCommentVal:
        vital_RecommendationsOtherCommentVal || "",
    };
  } else if (year === "2024") {
    const vital_preventingChangesCommentVal =
      formData["vital_preventingChangesComment"];

    const vital_preventingChangesSubOtherSubDescVal =
      formData["vital_preventingChangesSubOtherSubDesc"];

    const vital_physicalActivityLevelVal =
      formData["vital_physicalActivityLevel"];
    const vital_physicalActivityLevelCommentVal =
      formData["vital_physicalActivityLevelComment"];

    const vital_nutritionWeightManagementInterestVal =
      formData["vital_nutritionWeightManagementInterest"];
    const vital_nutritionWeightManagementInterestCommentVal =
      formData["vital_nutritionWeightManagementInterestComment"];
    const vital_reportedVal = formData["vital_reported"];

    const vital_RecommendationSelected = formData["vital_Recommendations"];

    const vital_RecommendationsVal = setSelectedCheckboxes(
      vital_RecommendationSelected,
      [
        "vital_nutriVal",
        "vital_caseManVal",
        "vital_followUpVal",
        "vital_memberToVal",
        "vital_otherVal",
      ],
      [
        "nutritionManagement",
        "caseManagementReferral",
        "followUpWithClinicianSpecialist",
        "memberToUseDME",
        "other",
      ],
    );

    const vital_RecommendationsNutriCommentVal =
      formData["vital_RecommendationsNutriComment"];

    const vital_RecommendationsCaseManCommentVal =
      formData["vital_RecommendationsCaseManComment"];

    const vital_RecommendationsFollowUpCommentVal =
      formData["vital_RecommendationsFollowUpComment"];

    const vital_RecommendationsMemberToCommentVal =
      formData["vital_RecommendationsMemberToComment"];

    const vital_RecommendationsOtherCommentVal =
      formData["vital_RecommendationsOtherComment"];

    flattenData = {
      ...flattenData,

      vital_preventingChangesCommentVal,
      vital_preventingChangesSubOtherSubDescVal,
      vital_physicalActivityLevelVal,
      vital_physicalActivityLevelCommentVal,

      vital_nutritionWeightManagementInterestVal,
      vital_nutritionWeightManagementInterestCommentVal,
      vital_reportedVal,
      ...vital_RecommendationsVal,
      vital_RecommendationsNutriCommentVal:
        vital_RecommendationsNutriCommentVal || "",
      vital_RecommendationsCaseManCommentVal:
        vital_RecommendationsCaseManCommentVal || "",
      vital_RecommendationsFollowUpCommentVal:
        vital_RecommendationsFollowUpCommentVal || "",
      vital_RecommendationsMemberToCommentVal:
        vital_RecommendationsMemberToCommentVal || "",
      vital_RecommendationsOtherCommentVal:
        vital_RecommendationsOtherCommentVal || "",
    };
  }

  let flatmap = [];

  vital_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    // if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  if (year === "2023") {
    temp = { ...vital_optionsTreeMapObject2023 };
  } else if (year === "2024") {
    temp = { ...vital_optionsTreeMapObject2024 };
  }

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  if (year === "2023") {
    temp = [...vitalOptionsTreeWrappers2023];
  } else if (year === "2024") {
    temp = [...vitalOptionsTreeWrappers2024];
  }

  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let temp = getOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let temp = setOptionsSelected(tempData, keyArray, year);
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getOptionsSelected = (optionObject, optionArray, year) => {
  let treeMappers = getTreeMapperByYear(year);

  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable.map((variable) => {
    return treeMappers[variable];
  });

  return selectedOption;
};

export const setOptionsSelected = (optionsSelected, optionArray, year) => {
  let temp = {};
  let treeMappers = getTreeMapperByYear(year);

  optionArray.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(treeMappers[option])
        ? "1"
        : "";
  });
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let temp;
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeMapperByYear(year);

  const tempIndex = lodash.findIndex(treeWrappers, function (optTree) {
    return optTree[0] === optionName;
  });

  const tempTreeNodes = treeWrappers?.[tempIndex]
    ? treeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (treeMappers[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";

  return temp;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      delete formData[node];
    }
  });
  return formData;
};

const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

const setSelectedCheckboxes = (optionArr, optionKeyArr, optionValueArr) => {
  let temp = {};

  optionValueArr?.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};
