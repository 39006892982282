export const QueryFormIcon = () => {
  return (
    <>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1376 6.81283H15.146M18.2626 6.81283H18.271M21.9168 6.58678C21.9168 9.33887 19.5845 11.5701 16.7085 11.5701C16.3696 11.5701 16.0338 11.5389 15.7012 11.4764C15.4616 11.4316 15.3418 11.4097 15.2585 11.4222C15.1751 11.4347 15.0564 11.4972 14.821 11.6232C14.147 11.9825 13.3713 12.1033 12.6199 11.9659C12.9071 11.6111 13.1018 11.1906 13.1866 10.742C13.2387 10.466 13.1095 10.1982 12.9158 10.0024C12.0118 9.0952 11.5029 7.86749 11.5001 6.58678C11.5001 3.83574 13.8324 1.60449 16.7085 1.60449C19.5845 1.60449 21.9168 3.83574 21.9168 6.58678ZM6.81472 21.3962H3.91472C3.57826 21.3962 3.23972 21.3482 2.93034 21.2159C1.92409 20.7837 1.41264 20.2118 1.17514 19.8545C1.11068 19.7555 1.07901 19.6388 1.08462 19.5208C1.09023 19.4028 1.13283 19.2896 1.20639 19.1972C2.37305 17.6472 5.08139 16.7118 6.81993 16.7118C8.55743 16.7118 11.2616 17.6472 12.4283 19.1972C12.5751 19.392 12.5949 19.6514 12.4595 19.8545C12.221 20.2118 11.7095 20.7837 10.7033 21.2159C10.3906 21.3417 10.0558 21.403 9.71889 21.3962H6.81472ZM9.71472 11.2805C9.71417 11.6603 9.63883 12.0362 9.49301 12.3868C9.34718 12.7374 9.13373 13.0559 8.86482 13.324C8.59592 13.5922 8.27684 13.8047 7.9258 13.9495C7.57475 14.0943 7.19862 14.1686 6.81889 14.168C6.05233 14.1686 5.31692 13.8647 4.77429 13.3233C4.23167 12.7818 3.92624 12.0471 3.92514 11.2805C3.92555 10.9008 4.00075 10.5249 4.14645 10.1742C4.29214 9.8235 4.50549 9.50496 4.77429 9.23673C5.0431 8.96851 5.36211 8.75585 5.7131 8.61091C6.06409 8.46597 6.44019 8.39158 6.81993 8.39199C7.19967 8.39158 7.57577 8.46597 7.92676 8.61091C8.27775 8.75585 8.59675 8.96851 8.86556 9.23673C9.13437 9.50496 9.34771 9.8235 9.49341 10.1742C9.63911 10.5249 9.71431 10.9008 9.71472 11.2805Z"
          stroke="white"
          stroke-width="1.5625"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
