import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import lodash from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation } from "react-router-dom";
import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getPainData,
  getSubTree,
  setPainData,
} from "./painMapping";
import PropTypes from "prop-types";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import {
  DynamicDepressionSeverity,
  TableComponentStatic,
} from "../../DFInputs/DFDataTable";
import { staticData } from "../../../../DynamicFormMUI/indexData";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./painFormData";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const Pain = forwardRef((props, _ref) => {
  const {
    // examReviewFormData,
    // setExamReviewFormData,
    // showComment,
    // setShowComment,
    getSectionInputsData,
    getDisableFlag,

    // getStateValue,
    // setStateValue,
  } = useFillHraContext();
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();
  const [loading, setLoading] = useState(undefined);
  const [painFormData, setPainFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);

  const sectionId = props.currSection || 11;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");

  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      //const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      let mashedData =
        dbData?.data?.pain?.data &&
        Object.keys(dbData?.data?.pain?.data).length > 0
          ? getPainData(dbData?.data?.pain?.data, clientYear)
          : {};

      setLoading(false);
      setSideBarSectionDisable(false);
      const commentVisibility = calcCommentVisibility(mashedData);
      mashedData = {
        ...mashedData,
        ...commentVisibility,
      };
      setPainFormData(mashedData);
    }

    getDbData();

    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...painFormData };
    updatedFormData[name] = value;

    setPainFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...painFormData };
    updatedFormData[name] = value;

    setPainFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...painFormData };
    updatedFormData[name] = value;

    setPainFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...painFormData };
    updatedFormData[name] = value;

    setPainFormData(updatedFormData);
  };

  const handleDeleteSubTree = (objectData, name, deletedValue) => {
    const deleteRegex = getSubTree(name, deletedValue);
    if (deleteRegex !== "") {
      let updatedObjectData = deleteSubTree(objectData, deleteRegex);
      return updatedObjectData;
    }
    return objectData;
  };

  const [deselectedNestedQuestions, setDeselectedNestedQuestions] = useState(
    {},
  ); // Define deselectedNestedQuestions state

  const handleOptionsTreeChange = (name, value, isMulti) => {
    const updatedFormData = { ...painFormData };
    let newSelection = value;
    // let oldSelection = getStateValue(13, name);
    let oldSelection = updatedFormData[name];
    let deletedValue =
      isMulti === true
        ? lodash?.difference(oldSelection, newSelection)[0] || ""
        : oldSelection || "";

    const isPatientExperiencePainDeselected =
      name === "pain_patientExperiencePainSubOpioidMedication" && !value;

    if (
      name === "pain_patientExperiencePainSubOpioidMedication" &&
      value === "yes"
    ) {
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubTolerance =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubWithdrawal =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubExcessTime =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubDrugsTake =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPatientAdvised =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubNaloAvail =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubNaloAvailComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubToleranceComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubToleranceComment_toggle = false;
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubWithdrawalComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubExcessTimeComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemComment =
        "";
    }

    if (isPatientExperiencePainDeselected) {
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubTolerance =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubWithdrawal =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubExcessTime =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubDrugsTake =
        "";
    }

    const isPainChronicPainDeselected =
      name === "pain_patientExperiencePainSubPainChronic" && !value;

    if (
      name === "pain_patientExperiencePainSubPainChronic" &&
      value === "yes"
    ) {
      updatedFormData.pain_patientExperiencePainSubPainChronicSubDescribe = "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubWhere = "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubRateYourPain =
        "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubFrequency = "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubDescribeComment =
        "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubFrequencyComment =
        "";
    }

    if (isPainChronicPainDeselected) {
      updatedFormData.pain_patientExperiencePainSubPainChronicSubDescribe = "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubWhere = "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubRateYourPain =
        "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubFrequency = "";
    }

    const isPatientExpPainDeselected =
      name === "pain_patientExperiencePain" && !value;

    if (name === "pain_patientExperiencePain" && value === "yes") {
      updatedFormData.pain_patientExperiencePainSubPainAcute = "";
      updatedFormData.pain_patientExperiencePainSubPainChronic = "";
      updatedFormData.pain_patientExperiencePainSubPainManagementTiming = "";
      updatedFormData.pain_patientExperiencePainSubOpioidMedication = "";
      updatedFormData.pain_patientExperiencePainSubPatientAdvised = "";

      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubToleranceComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubWithdrawalComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubExcessTimeComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemComment =
        "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubFrequency = "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubDescribeComment =
        "";
      updatedFormData.pain_patientExperiencePainSubPainChronicSubFrequencyComment =
        "";
      updatedFormData.pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingComment =
        "";
      updatedFormData.pain_patientExperiencePainSubPainAcuteComment = "";
      updatedFormData.pain_patientExperiencePainSubPainChronicComment = "";
      updatedFormData.pain_patientExperiencePainSubPainManagementTimingComment =
        "";
      updatedFormData.pain_patientExperiencePainSubOpioidMedicationComment = "";
      updatedFormData.pain_patientExperiencePainSubPatientAdvisedComment = "";
    }

    if (isPatientExpPainDeselected) {
      updatedFormData.pain_patientExperiencePainSubPainAcute = "";
      updatedFormData.pain_patientExperiencePainSubPainChronic = "";
      updatedFormData.pain_patientExperiencePainSubPainManagementTiming = "";
      updatedFormData.pain_patientExperiencePainSubOpioidMedication = "";
      updatedFormData.pain_patientExperiencePainSubPatientAdvised = "";
    }

    const isPatientUnergoDeselected =
      name === "pain_patientExperiencePainSubPainManagementTiming" && !value;

    if (
      name === "pain_patientExperiencePainSubPainManagementTiming" &&
      value === "yes"
    ) {
      updatedFormData.pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding =
        "";
      updatedFormData.pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingComment =
        "";
    }

    if (isPatientUnergoDeselected) {
      updatedFormData.pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding =
        "";
    }

    setPainFormData(updatedFormData);

    if ((value === "yes" || value === "no") && oldSelection === "yes") {
      const deleteRegex = `${name}Sub`;

      updatedFormData[name] = value;
      let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
      setPainFormData(subTreeDeletedData);
      return null;
    } else if (deletedValue !== "") {
      updatedFormData[name] = value;
      const deleteRegex = getSubTree(name, deletedValue);

      if (deleteRegex != "") {
        let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
        setPainFormData(subTreeDeletedData);
        return null;
      } else {
        updatedFormData[name] = value;
        setPainFormData(updatedFormData);
      }

      return null;
    } else {
      updatedFormData[name] = value;
      setPainFormData(updatedFormData);
    }
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...painFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setPainFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      const test = setPainData(painFormData, clientYear);
      return test;
    },
  }));

  useEffect(() => {
    props.setAutoSavePainData({ ...painFormData });
  }, [painFormData]);

  const getFieldValues = useCallback(
    (name) => {
      return painFormData[name];
    },
    [painFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setPainFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
          width: "76vw",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = painFormData[name] || "";
            const commentValue = `${
              painFormData?.[`${name}Comment`]
                ? `${painFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = painFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  // formData={covidScreeningFormData}
                  formData={painFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  // setFormData={setCovidScreeningFormData}
                  setFormData={setPainFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default Pain;

const dontReRender = (oldProps, newProps) => {
  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

const DFTextMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, disabled, pattern } = inputProps;

    const inputPropsCalc = {
      readOnly: disableFlag || disabled,
    };
    if (pattern && pattern !== "") {
      inputPropsCalc.pattern = pattern;
    }

    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <InputLabel
                sx={{
                  color: "var(--mainTheme)",
                  fontWeight: "600",
                  whiteSpace: "normal",
                  overflow: "visible",
                }}
              >
                {label || ""}
              </InputLabel>
            </div>
            <TextField
              value={value}
              multiline
              sx={{
                borderRadius: "5px",
                border: "1.5px solid var(--mainTheme) !important",
                "& .MuiFormLabel-root": {
                  backgroundColor: "white",
                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                },
              }}
              inputProps={{ ...inputPropsCalc }}
              fullWidth
              onChange={(event) => {
                handleTextChange(event.target.name, event.target.value);
              }}
              name={name}
              InputLabelProps={{ shrink: true }}
              /* 
            InputProps={DFCommentAdornment(
             inputProps.isCommentEnabled,
             quesindex,
             section,
             showComment,
             setShowComment
            )}
            disabled={routedFrom==="previewHRA"}
            */
            />
          </Grid>
          {isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              setFieldValues={setFieldValues}
              getFieldValues={getFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFTextMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

const DFRadioMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleRadioChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;

    return (
      <>
        <InputLabel
          sx={{
            color: "var(--mainTheme)",
            fontWeight: "600",
            width: "100%",
          }}
        >
          {label}
        </InputLabel>
        <Stack direction="row">
          <Grid
            item
            xs={inputProps.isCommentEnabled ? 11 : 12}
            component="fieldset"
            style={{
              borderWidth: 0.5,
              borderColor: "var(--mainTheme850)",
              borderStyle: "solid",
              borderRadius: "5px",
            }}
          >
            <FormControl className="inputStyle" fullWidth>
              <RadioGroup
                className="inputStyle"
                variant="outlined"
                sx={{ marginLeft: "10px" }}
                row
                name={inputProps.name}
                value={value}
                onChange={(event) =>
                  handleRadioChange(event.target.name, event.target.value)
                }
              >
                {inputProps.options.map((option, index) => (
                  <FormControlLabel
                    key={option[1]}
                    {...(disableFlag && { disabled: true })}
                    control={<Radio />}
                    label={option[0]}
                    value={option[1]}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFRadioMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleRadioChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFDateMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleDateChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, variant, disableFutureFlag } =
      inputProps;
    const dateValue = value && value !== "" ? dayjs(value) : {};

    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <InputLabel
              sx={{
                color: "var(--mainTheme)",
                fontWeight: "600",
              }}
            >
              {label || ""}
            </InputLabel>
            <FormControl fullWidth>
              {(() => {
                if (variant === "1") {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        {...(disableFutureFlag && { disableFuture: true })}
                        name={name}
                        value={dateValue}
                        sx={{
                          border: "1.5px solid var(--mainTheme) !important",
                          "& .MuiFormLabel-root": {
                            backgroundColor: "white",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                          },
                          borderRadius: "5px",
                        }}
                        onChange={(newValue) =>
                          handleDateChange(name, newValue)
                        }
                        {...(disableFlag && { disabled: true })}
                        views={["year", "month", "day"]}
                        slotProps={{
                          textField: {
                            readOnly: true,
                            InputLabelProps: { shrink: true },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }
              })()}
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFDateMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  commentValue: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFSelectMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleSelectChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;

    const selectedValue = value;

    return (
      <>
        <InputLabel
          sx={{
            color: "var(--mainTheme)",
            fontWeight: "600",
            display: "flex",
          }}
        >
          {label}
        </InputLabel>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <FormControl variant="outlined" fullWidth>
              <Select
                label={label}
                style={{ borderRadius: "5px" }}
                sx={{
                  border: "1.5px solid var(--mainTheme) !important",
                  "& .MuiFormLabel-root": {
                    backgroundColor: "white",
                    color: "rgba(0,0,0,0.38)!important",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "white",
                    color: "rgba(0,0,0,0.38)!important",
                  },
                }}
                name={name}
                value={selectedValue || "Select"}
                onChange={(event) => {
                  handleSelectChange(event.target.name, event.target.value);
                }}
                {...(disableFlag && { disabled: true })}
                fullWidth
              >
                <MenuItem
                  /* disabled */
                  value="Select"
                >
                  Select
                </MenuItem>
                {inputProps.options.map((item, index) => (
                  <MenuItem key={item[1]} value={item[1]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {inputProps.isCommentEnabled &&
          isCommentVisibleMemo(getFieldValues, name) && (
            <DFCommentTextBoxMemo
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
      </>
    );
  },
  dontReRender,
);

DFSelectMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleSelectChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFLabelMemo = React.memo(
  ({
    inputProps,
    commentValue,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;
    const text =
      "Members should wear their regular footwear and can use a walking aid, if needed. Observe the patient’s postural stability, gait, stride length, and sway. Begin by having the patient sit back in a standard armchair and identify a line 3 meters or 10 feet away, on the floor.";
    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <FormControl
              className="inputStyle"
              component="fieldset"
              variant="outlined"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "3px", fontWeight: "600" }}
                >
                  {label}
                  {name === "home_directions" && (
                    <Tooltip
                      title={
                        <div style={{ fontSize: "16px", width: "250px" }}>
                          {text}
                        </div>
                      }
                      placement="top"
                      arrow
                    >
                      <InfoIcon
                        variant="outlined"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                </FormLabel>
              </div>
            </FormControl>
          </Grid>

          {isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFLabelMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  commentValue: PropTypes.string,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFCheckboxMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    quesSlug,
    sectionId,
    rosFormData,
    allergiesFormData,
    handleCheckboxChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, variant } = inputProps;
    const inputValue = value && value?.length > 0 ? value : [];

    if (
      (sectionId == 10 && name === "gait_Recommendations") ||
      inputProps.name === "respiratory_Recommendations" ||
      inputProps.name === "integument_Recommendations" ||
      inputProps.name === "musko_Recommendations" ||
      inputProps.name === "hema_Recommendations" ||
      inputProps.name === "ros_cardiovascularRecommendations" ||
      inputProps.name === "ros_gastrorecommendations" ||
      inputProps.name === "ros_genitoRecommendations" ||
      inputProps.name === "ros_endocrineIsEndocrineRecommendations" ||
      inputProps.name === "ros_diagnosisOfCancerRecommendations" ||
      inputProps.name === "ros_eyeENTNeckRecommendations"
    ) {
      return (
        <Grid key={quesSlug} item>
          <Grid key={quesSlug} container alignItems="center">
            <Grid item xs={12}>
              <FormControl
                className="inputStyle"
                component="fieldset"
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "15px" }}
                  >
                    {label}
                  </FormLabel>
                </div>
                {inputProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="inputStyle"
                              variant="outlined"
                              name={name}
                              checked={inputValue?.includes(option[1])}
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event.target.name,
                                  option[1],
                                  event.target.checked,
                                )
                              }
                              {...(disableFlag && { disabled: true })}
                            />
                          }
                          label={option[0]}
                        />
                        {isCommentEnabled &&
                          isCommentVisibleMemo(
                            getFieldValues,
                            `${name}${option[1]}`,
                          ) && (
                            <DFCommentTextBoxMemo
                              label={label}
                              handleTextChange={handleTextChange}
                              name={`${name}${option[1]}`}
                              value={rosFormData[`${name}${option[1]}Comment`]}
                              disableFlag={disableFlag}
                              routedFrom={"checkbox"}
                            />
                          )}
                      </div>
                      {inputProps.isCommentEnabled === true && (
                        <DFCommentIconMemo
                          name={name}
                          value={commentValue}
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          disableFlag={disableFlag}
                        />
                      )}
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (
      sectionId == 9 &&
      (name === "allergies_Recommendations2" ||
        name === "allergies_Recommendations")
    ) {
      return (
        <Grid key={quesSlug} item>
          <Grid key={quesSlug} container alignItems="center">
            <Grid item xs={12}>
              <FormControl
                className="inputStyle"
                component="fieldset"
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "15px" }}
                  >
                    {label}
                  </FormLabel>
                </div>
                {inputProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="inputStyle"
                              variant="outlined"
                              name={name}
                              checked={inputValue?.includes(option[1])}
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event.target.name,
                                  option[1],
                                  event.target.checked,
                                )
                              }
                              {...(disableFlag && { disabled: true })}
                            />
                          }
                          label={option[0]}
                        />
                        {isCommentEnabled &&
                          isCommentVisibleMemo(
                            getFieldValues,
                            `${name}${option[1]}`,
                          ) && (
                            <DFCommentTextBoxMemo
                              label={label}
                              handleTextChange={handleTextChange}
                              name={`${name}${option[1]}`}
                              value={
                                allergiesFormData[`${name}${option[1]}Comment`]
                              }
                              disableFlag={disableFlag}
                              routedFrom={"checkbox"}
                            />
                          )}
                      </div>
                      {inputProps.isCommentEnabled === true && (
                        <DFCommentIconMemo
                          name={name}
                          value={commentValue}
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          disableFlag={disableFlag}
                        />
                      )}
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    /*
  to-do

  variant-1
    group of checkbox under single label

  variant-2
    single checkbox with no label
  */
    if (variant === 1) {
      return (
        <>
          <Stack direction="row">
            <Grid
              item
              xs={isCommentEnabled ? 11 : 12}
              component="fieldset"
              style={{
                borderWidth: 0.5,
                borderColor: "var(--mainTheme850)",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              <br />
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={quesSlug}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="inputStyle"
                          variant="outlined"
                          name={name}
                          checked={inputValue?.includes(option[1])}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.name,
                              option[1],
                              event.target.checked,
                            )
                          }
                          {...(disableFlag && { disabled: true })}
                        />
                      }
                      label={option[0]}
                    />
                  </div>
                ))}
              </FormGroup>
            </Grid>
            {inputProps.isCommentEnabled === true && (
              <DFCommentIconMemo
                name={name}
                value={commentValue}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                disableFlag={disableFlag}
              />
            )}
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBoxMemo
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    } else if (variant === 2) {
      return (
        <>
          <Stack direction="row">
            <Grid item xs={isCommentEnabled ? 11 : 12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              <br />
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={quesSlug}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="inputStyle"
                          variant="outlined"
                          name={name}
                          checked={value?.includes(option[1])}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.name,
                              option[1],
                              event.target.checked,
                            )
                          }
                          {...(disableFlag && { disabled: true })}
                        />
                      }
                      label={option[0]}
                    />
                  </div>
                ))}
              </FormGroup>
            </Grid>
            {inputProps.isCommentEnabled === true && (
              <DFCommentIconMemo
                name={name}
                value={commentValue}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                disableFlag={disableFlag}
              />
            )}
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBoxMemo
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    } else {
      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputLabel
              sx={{
                color: "var(--mainTheme)",
                fontWeight: "600",
              }}
            >
              {label || ""}
            </InputLabel>
          </div>
          <Stack direction="row">
            <Grid
              item
              // xs={isCommentEnabled ? 11 : 12}
              xs={12}
              // component="fieldset"
              // style={{
              //   borderWidth: 0.5,
              //   borderColor: "var(--mainTheme850)",
              //   borderStyle: "solid",
              //   borderRadius: "5px",
              // }}
            >
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={`${quesSlug}_${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="inputStyle"
                            variant="outlined"
                            name={name}
                            checked={inputValue?.includes(option[1])}
                            onChange={(event) =>
                              handleCheckboxChange(
                                event.target.name,
                                option[1],
                                event.target.checked,
                              )
                            }
                            {...(disableFlag && { disabled: true })}
                          />
                        }
                        label={option[0]}
                      />
                    </div>
                    {inputProps.isCommentEnabled === true && (
                      <DFCommentIconMemo
                        name={name}
                        value={commentValue}
                        getFieldValues={getFieldValues}
                        setFieldValues={setFieldValues}
                        disableFlag={disableFlag}
                      />
                    )}
                  </div>
                ))}
              </FormGroup>
            </Grid>
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBoxMemo
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    }
  },
  dontReRender,
);

DFCheckboxMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  commentValue: PropTypes.any.isRequired,
  quesSlug: PropTypes.any.isRequired,
  sectionId: PropTypes.any.isRequired,
  rosFormData: PropTypes.object,
  allergiesFormData: PropTypes.object,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFHeadingMemo = React.memo(({ inputProps }) => {
  return (
    <Stack direction="row">
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "var(--mainTheme850)",
          // textTransform: "uppercase",
          // borderRadius: "5px 5px 0 0",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "10px",
            color: "white",
            // textTransform: "uppercase",
            fontWeight: "500",
          }}
        >
          {inputProps.label}
        </div>
      </Grid>
    </Stack>
  );
}, dontReRender);

DFHeadingMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
};

export const DFRulerMemo = () => {
  return (
    <Grid xs={12} item>
      <Divider orientation="horizontal" style={{ backgroundColor: "black" }} />
    </Grid>
  );
};

DFRulerMemo.propTypes = {};

export const ToggleTree = ({
  depth,
  sectionId,
  quesIdSlug,
  quesNameSlug,
  inputProps,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  getFieldValues,
  setFieldValues,
  disableFlag,
  getNestingValues,
  getFieldVisibility,
}) => {
  const { isCommentEnabled, name, label, xs, isMulti, disabled } = inputProps;
  const isButtonDisabled = disableFlag || disabled;
  // const { getStateValue, setStateValue } = useFillHraContext();

  const selectedValue = formData?.[name] || [];
  const commentValue = formData ? formData[name + "Comment"] : "";

  const shouldShowIcon = !(
    (name === "ros_eyes" ||
      name === "ros_ears" ||
      name === "ros_nose" ||
      name === "ros_mouth" ||
      name === "ros_neck" ||
      name === "ros_neuroPsych" ||
      name === "ros_musculoskeletal" ||
      name === "ros_respiratory" ||
      name === "ros_integument" ||
      name === "ros_cardio" ||
      name === "ros_gastro" ||
      name === "ros_genito" ||
      name === "ros_endocrineIsEndocrine" ||
      name === "ros_hematology" ||
      name === "ros_diagnosisOfCancer") &&
    Array.isArray(selectedValue) &&
    selectedValue.includes("yes")
  );

  return (
    <Grid
      container
      style={{
        marginLeft: depth < 2 ? "0px" : "15px",
      }}
      fullWidth
    >
      <Grid
        item
        key={quesIdSlug}
        style={{ display: "grid", flexBasis: "100%", maxWidth: "100%" }}
        xs={xs || 12}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {depth > 1 && name !== "ros_musculoskeletalMuskoHadAnAmputation" ? (
              <MdOutlineSubdirectoryArrowRight
                style={{
                  color: "var(--mainTheme)",
                  marginRight: "10px",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              <div style={{ margin: "0px !important" }}></div>
            )}

            <InputLabel
              style={{
                color: "var(--mainTheme)",
                fontWeight: "700",
              }}
            >
              {inputProps.label}
            </InputLabel>
          </div>
          {isCommentEnabled === true && shouldShowIcon && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </div>
        <ToggleButtonGroup
          {...(!isMulti && { exclusive: true })}
          fullWidth
          role="group"
          size="large"
          onChange={(e, newValues) => {
            handleOptionsTreeChange(name, newValues, isMulti);
          }}
          selected={selectedValue}
          name={inputProps.name}
          value={selectedValue}
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {inputProps?.subQuestions?.map((subQuestion, index) => {
            return (
              <ToggleButton
                key={`${quesIdSlug}_${index}`}
                value={subQuestion.value}
                aria-pressed
                disableRipple
                name={subQuestion.name}
                role="button"
                aria-label={subQuestion.label}
                className="toggleButtonGroup"
                {...(isButtonDisabled && { disabled: true })}
                sx={{
                  overflow: "hidden",
                  display: "inline-block",
                  minHeight: "60px",
                  margin: "12px",
                  width: "230px",
                  textAlign: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  color: "black",
                  borderStyle: "none",
                  textTransform: "none",
                  border: `1px solid var(--mainTheme) !important`,
                  flexWrap: "wrap",
                  padding: "5px",
                  fontSize: "0.85rem",
                  "&.Mui-selected ": {
                    backgroundColor: "var(--mainTheme900)",
                    color: "white",
                    border: "1px solid var(--mainTheme) !important",
                    fontWeight: "600",
                    fontSize: "0.85rem",
                  },
                  "&.Mui-disabled ": {
                    color: "black",
                    border: "1px solid var(--mainTheme) !important",
                    fontWeight: "600",
                    fontSize: "0.85rem",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "var(--mainTheme750)",
                    color: "white",
                    border: "1px solid var(--mainTheme) !important",
                  },
                  "&.MuiToggleButtonGroup-grouped": {
                    borderRadius: "5px !important",
                  },
                  ":hover": {
                    backgroundColor: "var(--mainTheme400)",
                    border: "1px solid var(--mainTheme) !important",
                  },
                }}
              >
                {subQuestion.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        {isCommentEnabled &&
          shouldShowIcon &&
          isCommentVisibleMemo(getFieldValues, name) && (
            <DFCommentTextBoxMemo
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
        {selectedValue &&
          inputProps?.subQuestions?.map((subQuestion) => {
            const isValueSelected = selectedValue?.includes(subQuestion.value);
            const isLinkedFieldSelected =
              getFieldVisibility !== undefined
                ? getFieldVisibility(name)
                : false;

            const canCallRecursive =
              subQuestion.hasSubQuestions === true &&
              (isLinkedFieldSelected || isValueSelected);
            if (canCallRecursive) {
              const newQuesSlug = `${quesIdSlug}_${subQuestion.subQuestionId}`;
              // const nestedTreeValues = getNestingValues(name);
              return (
                <NestedTree
                  key={newQuesSlug}
                  depth={depth + 1}
                  quesIdSlug={newQuesSlug}
                  quesNameSlug={`${quesNameSlug}_${
                    subQuestion.name || subQuestion.value || ""
                  }`}
                  sectionId={sectionId}
                  subQuestions={subQuestion.subQuestions}
                  formData={formData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  getNestingValues={getNestingValues}
                  selectedValue={selectedValue}
                  disableFlag={disableFlag}
                />
              );
            }
            return null;
          })}
      </Grid>
    </Grid>
  );
};

ToggleTree.propTypes = {
  depth: PropTypes.number,
  sectionId: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  inputProps: PropTypes.object,
  formData: PropTypes.object,
  familyRowData: PropTypes.object,
  setFamilyRowData: PropTypes.func,
  medicalRowData: PropTypes.object,
  setMedicalRowData: PropTypes.func,
  allergiesRowData: PropTypes.object,
  setAllergiesRowData: PropTypes.func,
  medicationsRowData: PropTypes.object,
  setMedicationsRowData: PropTypes.func,
  counterMedicationsRowData: PropTypes.object,
  setCounterMedicationsRowData: PropTypes.func,
  tableName: PropTypes.string,
  data: PropTypes.object,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  getFieldValues: PropTypes.func,
  setFieldValues: PropTypes.func,
  getNestingValues: PropTypes.func,
  getFieldVisibility: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const NestedTree = ({
  depth,
  quesIdSlug,
  quesNameSlug,
  sectionId,
  subQuestions,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  getFieldValues,
  setFieldValues,
  getNestingValues,
  disableFlag,
}) => {
  // const { getStateValue, setStateValue } = useFillHraContext();

  return (
    <div style={{ marginLeft: "15px" }}>
      <Grid container gap={1} style={{ marginLeft: "0px" }}>
        {subQuestions?.map((subQuestion) => {
          const { subQuestionId, name, xs, type, isSingleButton } = subQuestion;
          const inputValue = formData?.[name] || "";
          const commentValue = formData?.[name + "Comment"]
            ? formData[name + "Comment"]
            : "";
          const quesIdSlugVal = `${quesIdSlug}_${subQuestionId}`;
          const quesNameSlugVal = `${quesNameSlug}_${
            subQuestion.name || subQuestion.value || ""
          }`;
          if (
            type === "text" ||
            type === "number" ||
            type === "email" ||
            type === "password"
          ) {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {depth > 2 ? (
                      <MdOutlineSubdirectoryArrowRight
                        style={{
                          color: "var(--mainTheme)",
                          marginRight: "10px",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    ) : (
                      <div style={{ margin: "0px !important" }}></div>
                    )}
                  </div>
                  <div style={{ width: "90%" }}>
                    <DFTextMemo
                      inputProps={subQuestion}
                      value={inputValue}
                      commentValue={commentValue}
                      handleTextChange={handleTextChange}
                      getFieldValues={getFieldValues}
                      setFieldValues={setFieldValues}
                      disableFlag={disableFlag}
                    />
                  </div>
                </div>
              </Grid>
            );
          } else if (type === "radio") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFRadioMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  handleRadioChange={handleRadioChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "date") {
            const dateValue = formData[name] || {};
            // getFieldValues(name) === "" ? {} : getFieldValues(name);
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFDateMemo
                  inputProps={subQuestion}
                  value={dateValue}
                  commentValue={commentValue}
                  handleDateChange={handleDateChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "select") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFSelectMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  handleSelectChange={handleSelectChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "options_tree") {
            return (
              <ToggleTree
                key={quesIdSlugVal}
                inputProps={subQuestion}
                formData={formData}
                handleOptionsTreeChange={handleOptionsTreeChange}
                handleTextChange={handleTextChange}
                handleRadioChange={handleRadioChange}
                handleDateChange={handleDateChange}
                handleSelectChange={handleSelectChange}
                handleButtonClick={handleButtonClick}
                handleCheckboxChange={handleCheckboxChange}
                // setFormData={setFormData}
                setFieldValues={setFieldValues}
                getFieldValues={getFieldValues}
                getNestingValues={getNestingValues}
                commentValue={commentValue}
                disableFlag={disableFlag}
                selectedValue={inputValue}
                depth={depth + 1}
                quesIdSlug={quesIdSlugVal}
                sectionId={sectionId}
                quesNameSlug={quesNameSlugVal}
              />
            );
          } else if (type === "label") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFLabelMemo
                  inputProps={subQuestion}
                  commentValue={commentValue}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "button") {
            return (
              <Grid key={quesIdSlugVal} xs={isSingleButton ? 12 : 0} item>
                <DFButton
                  inputProps={subQuestion}
                  handleButtonClick={handleButtonClick}
                />
              </Grid>
            );
          } else if (type === "heading") {
            return (
              <Grid key={quesIdSlugVal} xs={12} sm={12} item>
                <DFHeadingMemo inputProps={subQuestion} />
              </Grid>
            );
          } else if (type === "checkbox") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFCheckboxMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  handleCheckboxChange={handleCheckboxChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "image") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFImage inputProps={subQuestion} />
              </Grid>
            );
          } else if (type === "datatable") {
            if (name === "stagesOfKidneyDisease") {
              return (
                <Grid xs={2} item>
                  <TableComponentStatic
                    tableName="Stages Of Kidney Disease"
                    data={staticData[5].column_data[0].rows}
                  />
                </Grid>
              );
            } else if (
              name === "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverity"
            ) {
              let formTableData = {
                ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMini:
                  formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMini"
                  ] || "",
                ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMild:
                  formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMild"
                  ] || "",
                ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerate:
                  formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerate"
                  ] || "",
                ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevere:
                  formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevere"
                  ] || "",
                ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevere:
                  formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevere"
                  ] || "",
              };
              return (
                <DynamicDepressionSeverity
                  tableName={name}
                  tableData={formTableData}
                  setFieldValues={setFieldValues}
                />
              );
            }
            return null;
          }
        })}
      </Grid>
    </div>
  );
};

NestedTree.propTypes = {
  depth: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  sectionId: PropTypes.number,
  subQuestions: PropTypes.array,
  formData: PropTypes.object,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  getFieldValues: PropTypes.func,
  setFieldValues: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const DFOptionsTreeMemo = React.memo(
  ({
    inputProps,
    formData,
    quesSlug,
    sectionId,
    handleOptionsTreeChange,
    handleTextChange,
    handleRadioChange,
    handleDateChange,
    handleSelectChange,
    handleButtonClick,
    handleCheckboxChange,
    getFieldValues,
    setFieldValues,
    getFieldVisibility,
    getNestingValues,
    disableFlag,
    familyRowData,
    setFamilyRowData,
    medicalRowData,
    setMedicalRowData,
    allergiesRowData,
    setAllergiesRowData,
    medicationsRowData,
    setMedicationsRowData,
    counterMedicationsRowData,
    setCounterMedicationsRowData,
    tableName,
    data,
  }) => {
    const { name, xs } = inputProps;
    // const { getStateValue, setStateValue } = useFillHraContext();

    return (
      <Grid
        item
        key={quesSlug}
        xs={xs || 12}
        style={{ marginLeft: "0px" }}
        zeroMinWidth
      >
        <ToggleTree
          depth={1}
          sectionId={sectionId}
          quesIdSlug={`${quesSlug}`}
          quesNameSlug={`${name}`}
          inputProps={inputProps}
          formData={formData}
          familyRowData={familyRowData}
          setFamilyRowData={setFamilyRowData}
          medicalRowData={medicalRowData}
          setMedicalRowData={setMedicalRowData}
          allergiesRowData={allergiesRowData}
          setAllergiesRowData={setAllergiesRowData}
          medicationsRowData={medicationsRowData}
          setMedicationsRowData={setMedicationsRowData}
          counterMedicationsRowData={counterMedicationsRowData}
          setCounterMedicationsRowData={setCounterMedicationsRowData}
          tableName={tableName}
          data={data}
          handleOptionsTreeChange={handleOptionsTreeChange}
          handleTextChange={handleTextChange}
          handleRadioChange={handleRadioChange}
          handleDateChange={handleDateChange}
          handleSelectChange={handleSelectChange}
          handleButtonClick={handleButtonClick}
          handleCheckboxChange={handleCheckboxChange}
          getFieldValues={getFieldValues}
          setFieldValues={setFieldValues}
          getNestingValues={getNestingValues}
          getFieldVisibility={getFieldVisibility}
          disableFlag={disableFlag}
        />
      </Grid>
    );
  },
  dontReRender,
);

DFOptionsTreeMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  handleOptionsTreeChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  getFieldVisibility: PropTypes.func.isRequired,
  getNestingValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  familyRowData: PropTypes.object,
  setFamilyRowData: PropTypes.func,
  medicalRowData: PropTypes.object,
  setMedicalRowData: PropTypes.func,
  allergiesRowData: PropTypes.object,
  setAllergiesRowData: PropTypes.func,
  medicationsRowData: PropTypes.object,
  setMedicationsRowData: PropTypes.func,
  counterMedicationsRowData: PropTypes.object,
  setCounterMedicationsRowData: PropTypes.func,
  tableName: PropTypes.string,
  data: PropTypes.object,
};

export const toggleCommentBoxMemo = (getFieldValues, setFieldValues, name) => {
  let toggleSlug = `${name}Comment_toggle`;
  let newFlag = !getFieldValues(toggleSlug);

  setFieldValues(toggleSlug, newFlag);
};

export const isCommentVisibleMemo = (getFieldValues, name) => {
  let toggleSlug = `${name}Comment_toggle`;
  let toggleValue = getFieldValues(toggleSlug);

  return toggleValue;
};

export const DFCommentIconMemo = React.memo(
  ({ getFieldValues, setFieldValues, name, value, disableFlag }) => {
    return (
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <ChatBubbleIcon
          style={{
            cursor: "pointer",
            color: "var(--mainTheme)",
          }}
          onClick={() => {
            if (disableFlag) {
              return null;
            }
            toggleCommentBoxMemo(getFieldValues, setFieldValues, name);
          }}
        />
      </Grid>
    );
  },
);

DFCommentIconMemo.propTypes = {
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
};

export const DFCommentTextBoxMemo = React.memo(
  ({
    label,
    handleTextChange,
    name,
    value,
    disableFlag,
    style,
    routedFrom,
    // getFieldValues,
    // setFieldValues,
  }) => {
    const inputPropsCalc = {
      readOnly: disableFlag,
    };

    let commentLabel = "";
    if (label === "35. Allergies") {
      commentLabel = "Comments - Allergies";
    } else if (label === "36. Over the Counter Medications / Supplements") {
      commentLabel = "Comments - Over the Counter Medications / Supplements";
    } else {
      commentLabel = "Comments" + (label ? " - " + label : "");
    }
    let commentPlaceholder = "";
    if (label === "35. Allergies") {
      commentPlaceholder = "for Allergies";
    } else if (label === "36. Over the Counter Medications / Supplements") {
      commentPlaceholder = "for Over the Counter Medications / Supplements";
    } else {
      commentPlaceholder = label ? "for " + label : "";
    }

    return (
      <Grid item xs={11}>
        <span style={{ display: "flex" }}>
          <TextField
            label={commentLabel}
            placeholder={commentPlaceholder}
            onChange={(event) =>
              handleTextChange(`${name}Comment`, event.target.value)
            }
            inputProps={{ ...inputPropsCalc }}
            multiline
            maxRows={4}
            fullWidth
            value={value || ""}
            variant="outlined"
            className="inputStyle"
            sx={{
              ...style,
              "& .MuiFormLabel-root": {
                color: "var(--mainTheme)",
              },
              border: "1.5px solid var(--mainTheme) !important",
              borderRadius: "5px",
              "& .MuiFormLabel-root.Mui-focused": {
                color: "var(--mainTheme)",
              },
              marginTop: "20px",
              marginLeft: "10px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArrowForwardIcon
                    sx={{
                      size: "medium",
                      color: "var(--mainTheme)",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </span>
      </Grid>
    );
  },
);

DFCommentTextBoxMemo.propTypes = {
  label: PropTypes.string,
  handleTextChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  disableFlag: PropTypes.bool,
};
