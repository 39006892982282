// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
}

.graph-dashboard-container {
  background-color: #e3edf4;
}

.graph-header-container {
  display: flex;
  justify-content: space-between;
}

.graph-header-icon {
  align-content: center;
}

.graph-header-item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  text-align: center;
}

.graph-header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.graph-chart-item {
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.graph-hra-status {
  /* Additional styles for HRA Status chart */
  border-radius: 8px;
  height: 100%;
}

.status-days h6,
.top-review-count h6,
.assigned-status h6,
.top-mem-agn h6 {
  background-color: #d0e6f5;
  text-align: left;
  padding: 1%;
  border-radius: 8px 8px 0px 0px;
  font-weight: 700;
  font-size: 14px;
}

.status-days,
.top-review-count,
.assigned-status,
.top-mem-agn {
  border-radius: 8px;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/QaDashboard/Graphs/qaGraphs.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,wCAAwC;AAC1C;;AAEA;EACE,2CAA2C;EAC3C,kBAAkB;EAClB,YAAY;AACd;;AAEA;;;;EAIE,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;EACX,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;;;EAIE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["* {\n  padding: 0;\n}\n\n.graph-dashboard-container {\n  background-color: #e3edf4;\n}\n\n.graph-header-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.graph-header-icon {\n  align-content: center;\n}\n\n.graph-header-item {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n  text-align: center;\n}\n\n.graph-header-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 8px;\n}\n\n.graph-chart-item {\n  padding: 16px;\n  background-color: #fff;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.graph-hra-status {\n  /* Additional styles for HRA Status chart */\n  border-radius: 8px;\n  height: 100%;\n}\n\n.status-days h6,\n.top-review-count h6,\n.assigned-status h6,\n.top-mem-agn h6 {\n  background-color: #d0e6f5;\n  text-align: left;\n  padding: 1%;\n  border-radius: 8px 8px 0px 0px;\n  font-weight: 700;\n  font-size: 14px;\n}\n\n.status-days,\n.top-review-count,\n.assigned-status,\n.top-mem-agn {\n  border-radius: 8px;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
