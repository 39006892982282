// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  padding: 16px;
  background-color: #ffffff;
  /* border-radius: 0px 0px 8px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.chart-container canvas {
  height: 100%;
  width: 100% !important;
}

.status-item {
  display: inline-block;
  margin-right: 20px;
}

.status-container::-webkit-scrollbar,
.status-container1::-webkit-scrollbar {
  display: none;
}

.status-container:hover::-webkit-scrollbar,
.status-container1:hover::-webkit-scrollbar {
  display: inline;
}

.status-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 10px;
  border-top: 1px solid #ccc; /* Optional: Adds a border under the statuses */
  padding-top: 10px;
  font-size: 11px;
}
/* .status-container {
    width: 100%;
    position: relative;
  } */

.status-wrapper {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChartsGraphs/Admin/pieChart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB;+CAC6C;AAC/C;;AAEA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B,EAAE,+CAA+C;EAC3E,iBAAiB;EACjB,eAAe;AACjB;AACA;;;KAGK;;AAEL;EACE,qBAAqB;AACvB","sourcesContent":[".chart-container {\n  padding: 16px;\n  background-color: #ffffff;\n  /* border-radius: 0px 0px 8px 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n}\n\n.chart-container canvas {\n  height: 100%;\n  width: 100% !important;\n}\n\n.status-item {\n  display: inline-block;\n  margin-right: 20px;\n}\n\n.status-container::-webkit-scrollbar,\n.status-container1::-webkit-scrollbar {\n  display: none;\n}\n\n.status-container:hover::-webkit-scrollbar,\n.status-container1:hover::-webkit-scrollbar {\n  display: inline;\n}\n\n.status-container {\n  display: flex;\n  overflow-x: auto;\n  white-space: nowrap;\n  margin-top: 10px;\n  border-top: 1px solid #ccc; /* Optional: Adds a border under the statuses */\n  padding-top: 10px;\n  font-size: 11px;\n}\n/* .status-container {\n    width: 100%;\n    position: relative;\n  } */\n\n.status-wrapper {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
