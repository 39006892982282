import { createContext,useContext, useState } from "react";

const InputContext = createContext()

export const InputProvider = (props) => {
    const initialValue_AddMembers = {
        firstname:'',
        middlename:'',
        lastname:'',
        dateofbirth:'',
        age:'',
        maritalstatus:'',
        gender:'',
        address:'',
        city:'',
        state:'',
        zip:'',
        phonenumber:'',
        cellnumber:'',
        email:'',
        memberidentificationnumber:'',
        hicn:'',
        program:'',
        clientyearlist:'',
        emergencycontact:'',
        emergencyphonenumber:'',
        primarycarephysician:'',
        pcpphonenumber:'',
        pcpaddress:'',
        pcpcity:'',
        pcpstate:'',
        pcpzip:'',
        pcpcountry1:'',
        pcpcountry2:'',
        pcpcountry3:''
    }
    const [addMembers, setAddMembers] = useState(initialValue_AddMembers)
    return (
        <InputContext.Provider value={{addMembers, setAddMembers}}>{props.children}</InputContext.Provider>
    )
}
export const useInputContext = () => {
    return useContext(InputContext);
  };

export default InputContext