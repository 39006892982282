const json2023 = {
  sectionId: 2,
  sectionName: "Previously Documented Conditions",
  isEditable: false,
  questions: [
    {
      id: 8,
      name: "previously_documented_conditions",
      type: "button",
      label: "Previously documented Conditions",
      editToggle: false,
      xs: 3,
      isSingleButton: true,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },
    {
      id: 11,
      name: "previously_documented_conditions",
      label: "Previously Documented Conditions",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],

        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],

        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },
  ],
};

const json2024 = {
  sectionId: 2,
  sectionName: "Previously Documented Conditions",
  isEditable: false,
  questions: [
    {
      id: 8,
      name: "previously_documented_conditions",
      type: "button",
      label: "Previously documented Conditions",
      editToggle: false,
      xs: 3,
      isSingleButton: true,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },
    {
      id: 11,
      name: "previously_documented_conditions",
      label: "Previously Documented Conditions",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],

        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],

        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },
    {
      id: 11,
      name: "suspect_codes",
      label: "Suspect Codes",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],

        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],

        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
        [
          {
            id: 9,
            label: "suspect_codes",
            name: "HRAForm_Suspect_Codes",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },

              {
                label: "Diagnosis Code",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
        [
          {
            id: 10,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 13,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }
  return formLayoutData;
};
