import { Grid, Typography } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";

import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  DynamicClockDraw,
  DynamicTotalScore,
  DynamicWordRecall,
} from "../../../../DynamicFormMUI/Tables";
import { staticData } from "../../../../DynamicFormMUI/indexData";
import {
  TableComponent,
  TableComponentMiniCheck,
} from "../../../../DynamicFormMUI/staticForm";
import { getMinicogData, setMinicogData } from "./minicogMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./minicogFormData";
import { initialWordList } from "../../../../DynamicFormMUI/staticForm";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";

import "../responsiveFillHra.css";

const rowsData = [
  {
    id: 1,
    label: "Allergies",
    name: "HRAForm_Allergies",
    columns: [
      {
        label: "Substance",
        type: "text",
      },
      {
        label: "Reaction",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 7,
    label: "Previously Documented Conditions",
    name: "HRAForm_Previously_Documented_Conditions",
    columns: [
      {
        label: "Diagnosis",
        type: "text",
      },
      {
        label: "Prior HCC",
        type: "text",
      },
      {
        label: "Diagnosis Code",
        type: "text",
      },
      {
        label: "Date of Service",
        type: "date",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 4,
    label: "Medical History Specialists",
    name: "HRAForm_Medical_History_Speciality",
    columns: [
      {
        label: "Member Specialist",
        type: "select",
      },
      {
        label: "Speciality",
        type: "text",
      },
      {
        label: "For",
        type: "text",
      },
    ],
    isReadOnly: false,
  },

  {
    id: 4,
    label: "Alcohol Use - Current",
    name: "currentSubAnswers",
    columns: [
      {
        label: "How Many Drinks",
        name: "howManyDrinks",
        type: "text",
      },
      {
        label: "How Often",
        name: "howOften",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 6,
    label: "Preventive Care",
    name: "HRAForm_Preventive_Care",
    columns: [
      {
        label: "Screen",
        type: "text",
      },
      {
        label: "Answer",
        type: "select",
      },
      {
        label: "Date",
        type: "text",
      },
      {
        label: "Method",
        type: "text",
      },
      {
        label: "Recommendation",
        type: "checkbox",
      },
      {
        label: "Education Completed",
        type: "checkbox",
      },
    ],
    isReadOnly: true,
  },
  {
    id: 5,
    label: "Medications",
    name: "HRAForm_Medications",
    columns: [
      {
        label: "Diagnoses",
        type: "text",
      },
      {
        label: "label Name",
        type: "select",
      },
      {
        label: "Dose Per Units",
        type: "text",
      },
      {
        label: "Route",
        type: "select",
      },
      {
        label: "Frequency",
        type: "select",
      },
      {
        label: "Prescribing Physician",
        type: "text",
      },
      {
        label: "Status",
        type: "togglebutton",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 3,
    label: "Counter Medications",
    name: "HRAForm_Counter_Medications",
    columns: [
      {
        label: "Date",
        type: "date",
      },
      {
        label: "Description",
        type: "text",
      },
      {
        label: "Dose Per Units",
        type: "text",
      },
      {
        label: "Route",
        type: "select",
      },
      {
        label: "Frequency",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 3,
    label: "Word Recall",
    name: "step3ThreeWordRecall",
    columns: [
      {
        label: "Word Recall",
        type: "text",
      },
      {
        label: "Points",
        type: "select",
      },
      {
        label: "Description",
        type: "text",
      },
    ],
  },

  {
    id: 3,
    label: "Clock Draw",
    name: "clockDrawPoints",
    columns: [
      {
        label: "Clock Draw",
        type: "text",
      },
      {
        label: "Points",
        type: "select",
      },
      {
        label: "Description",
        type: "text",
      },
    ],
  },
  {
    id: 3,
    label: "Total Score",
    name: "totalScorePoints",
    columns: [
      {
        label: "Total Score",
        type: "text",
      },
      {
        label: "Points",
        type: "text",
      },
      {
        label: "Description",
        type: "text",
      },
    ],
  },
  {
    id: 12,
    label: "Family History",
    name: "family_history",
    columns: [
      {
        label: "Family Member",
        type: "text",
      },
      {
        label: "Medical Condition",
        type: "text",
      },
      {
        label: "Cause of Death",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
];

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const Minicog = forwardRef((props, _ref) => {
  const {
    // DemographicsFormData,
    // setDemographicsFormData,
    // showComment,
    // setShowComment,
    getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();
  const [loading, setLoading] = useState(undefined);
  const [minicogFormData, setMinicogFormData] = useState({});
  const [wordRecallRowData, setWordRecallRowData] = useState(rowsData);
  const [clockDrawRowData, setClockDrawRowData] = useState(rowsData);
  const [totalScoreRowData, setTotalScoreRowData] = useState(rowsData);
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);

  const sectionId = props.currSection || 15;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);
  const location = useLocation();
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);

      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      );
      const dbData = secondApiResponse[`data`];
      const mashedData =
        dbData?.data?.mini?.data &&
        Object.keys(dbData?.data?.mini?.data).length > 0
          ? getMinicogData(dbData?.data?.mini?.data, clientYear)
          : {};

      if (
        clientYear === "2024" &&
        typeof dbData?.threeWordRecall?.wordList === "string"
      ) {
        dbData.threeWordRecall.wordList = { ...initialWordList };
      }

      const wordRecallData = {
        ...wordRecallRowData,
        points: mashedData?.["minicog_wordRecall"] || "0",
      };
      const clockDrawData = {
        ...wordRecallRowData,
        points: mashedData?.["minicog_clockDraw"] || "0",
      };

      const wordRecallPoints =
        isNaN(parseInt(wordRecallData.points)) || wordRecallData.points === "-"
          ? 0
          : parseInt(wordRecallData.points);
      const clockDrawPoints =
        isNaN(parseInt(clockDrawData.points)) || clockDrawData.points === "-"
          ? 0
          : parseInt(clockDrawData.points);

      const totalScoreData = {
        ...wordRecallRowData,
        points: (wordRecallPoints + clockDrawPoints).toString(),
      };
      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };
      setMinicogFormData(mashedCalcData);
      setWordRecallRowData(wordRecallData);
      setClockDrawRowData(clockDrawData);
      setTotalScoreRowData(totalScoreData);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  useEffect(() => {
    const wordPoints =
      wordRecallRowData?.points === "-"
        ? 0
        : parseInt(wordRecallRowData?.points || 0);
    const clockPoints =
      clockDrawRowData?.points === "-"
        ? 0
        : parseInt(clockDrawRowData?.points || 0);

    const totalPoints = wordPoints + clockPoints;

    setTotalScoreRowData((prevData) => ({
      ...prevData,
      points: totalPoints.toString(),
    }));
  }, [wordRecallRowData, clockDrawRowData]);

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      const minicog_wordRecall = wordRecallRowData?.points || "0";
      const minicog_clockDraw = clockDrawRowData?.points || "0";
      let minicog_totalScore = totalScoreRowData?.points || "0";

      if (minicog_wordRecall === "0" && minicog_clockDraw === "0") {
        setTotalScoreRowData((prevData) => ({
          ...prevData,
          points: "0",
        }));
      }

      const subAnswers = minicogFormData.minicog_wordListVer;

      if (
        clientYear === "2024" &&
        subAnswers &&
        subAnswers["0"] !== undefined
      ) {
        delete subAnswers["0"];
      }

      let tempFormData = {
        ...minicogFormData,
        minicog_wordRecall,
        minicog_clockDraw,
        minicog_totalScore,
      };

      return setMinicogData(tempFormData, clientYear);
    },
  }));

  useEffect(() => {
    const minicog_wordRecall = wordRecallRowData?.points || "0";
    const minicog_clockDraw = clockDrawRowData?.points || "0";
    let minicog_totalScore = totalScoreRowData?.points || "0";

    const subAnswers = minicogFormData?.minicog_wordListVer;

    if (
      clientYear === "2024" &&
      typeof subAnswers === "object" &&
      subAnswers?.["0"] !== undefined
    ) {
      delete subAnswers["0"];
    }

    let tempFormData = {
      ...minicogFormData,
      minicog_wordRecall,
      minicog_clockDraw,
      minicog_totalScore,
    };

    props.setAutoSaveMiniCogData({ ...tempFormData });
  }, [minicogFormData, wordRecallRowData, clockDrawRowData, totalScoreRowData]);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...minicogFormData };
    updatedFormData[name] = value;

    setMinicogFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...minicogFormData };
    updatedFormData[name] = value;

    setMinicogFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...minicogFormData };
    updatedFormData[name] = value;

    setMinicogFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...minicogFormData };
    updatedFormData[name] = value;

    setMinicogFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    /* togglebutton gruop type -> scenario -> actual behaviour -> our pattern

    <- multi ->
        pass values -> array -> array
        if value selected -> array -> array

    <- single -> 

        pass values -> string -> always pass as array
        if value selected -> string -> always change to array and set back to state

    */
    const updatedFormData = { ...minicogFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (isMulti) {
      newSelection = value;
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection.push(value);
      }
    }

    updatedFormData[name] = newSelection;
    setMinicogFormData(updatedFormData);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...minicogFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setMinicogFormData(updatedFormData);
  };

  // useImperativeHandle(_ref, () => ({
  //   getPayloadData: () => {
  //     const minicog_wordRecall = wordRecallRowData?.points || 0;
  //     const minicog_clockDraw = clockDrawRowData?.points || 0;
  //     const minicog_totalScore = `${
  //       totalScoreRowData?.points || minicog_wordRecall + minicog_clockDraw
  //     }`;

  //     if (minicog_wordRecall == 0 &&
  //        minicog_clockDraw == 0) {
  //       // If both are 0, set totalScoreRowData points to 0
  //       setTotalScoreRowData((prevData) => ({
  //         ...prevData,
  //         points: 0,
  //       }));
  //     }
  //     let tempFormData = {
  //       ...minicogFormData,
  //       minicog_wordRecall,
  //       minicog_clockDraw,
  //       minicog_totalScore,
  //     };

  //     return setMinicogData(tempFormData);
  //   },
  // }));

  const getFieldValues = useCallback(
    (name) => {
      return minicogFormData[name];
    },
    [minicogFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setMinicogFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        // style={{
        //   margin: "10px 20px",
        // }}
        className="mini-form-container"
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const {
              questionId,
              name,
              xs,
              type,
              isSingleButton,
              variant,
              label,
            } = inputProps;
            const inputValue = minicogFormData[name] || "";
            const commentValue = `${
              minicogFormData?.[`${name}Comment`]
                ? `${minicogFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "datatable") {
              const selectedTable = inputProps.tablelist.filter(
                (table) => table && table.length > 0 && table[1] === true,
              );
              return (
                <Grid key={quesSlug} xs={inputProps.xs} item>
                  <div style={{ textAlign: "center", overflow: "auto" }}>
                    {selectedTable &&
                      selectedTable.length > 0 &&
                      selectedTable.map((table, index) => {
                        if (table[0].label === "Mini Cog Versions") {
                          // return (
                          //   <div>
                          //     <TableComponent
                          //       tableName="Mini Cog Versions"
                          //       data={staticData}
                          //     />
                          //     <TableComponentMiniCheck
                          //     tableName="Mini Cog Versions"
                          //     data={staticData}
                          //     />
                          //   </div>
                          // );
                          return clientYear === "2024" ? (
                            <TableComponentMiniCheck
                              tableName="Mini Cog Versions"
                              data={staticData}
                              minicogFormData={minicogFormData}
                              setMinicogFormData={setMinicogFormData}
                              disableFlag={
                                disableFlag || (roleId == 5 && tabId == 3)
                              }
                            />
                          ) : (
                            <TableComponent
                              tableName="Mini Cog Versions"
                              data={staticData}
                              disableFlag={
                                disableFlag || (roleId == 5 && tabId == 3)
                              }
                            />
                          );
                        } else if (table[0].label === "Word Recall") {
                          return (
                            <div
                              style={{
                                border: "1px solid var(--mainTheme)",
                                padding: "10px",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderRadius: "5px",
                              }}
                            >
                              <DynamicWordRecall
                                wordRecallRowData={wordRecallRowData}
                                setWordRecallRowData={setWordRecallRowData}
                                disableFlag={
                                  disableFlag || (roleId == 5 && tabId == 3)
                                }
                              />
                              <DynamicClockDraw
                                clockDrawRowData={clockDrawRowData}
                                setClockDrawRowData={setClockDrawRowData}
                                disableFlag={
                                  disableFlag || (roleId == 5 && tabId == 3)
                                }
                              />
                              <DynamicTotalScore
                                totalScoreRowData={totalScoreRowData}
                                setTotalScoreRowData={setTotalScoreRowData}
                                disableFlag={
                                  disableFlag || (roleId == 5 && tabId == 3)
                                }
                              />
                            </div>
                          );
                        }
                      })}
                  </div>
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = minicogFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue || ""}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  formData={minicogFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  setFormData={setMinicogFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              if (variant === "1") {
                return (
                  <div>
                    <Typography variant="body1" className="typo-text">
                      {label}
                    </Typography>
                  </div>
                );
              } else if (variant === "2") {
                return (
                  <div>
                    <Typography variant="body1" className="typo-texts">
                      {label}
                    </Typography>
                  </div>
                );
              } else {
                return (
                  <Grid key={quesSlug} xs={xs} item>
                    <DFLabel
                      inputProps={inputProps}
                      handleTextChange={handleTextChange} // added for comment box event handling
                      showComment={showComment}
                      setShowComment={setShowComment}
                      quesSlug={`${questionId}`}
                      sectionId={sectionId}
                      commentValue={commentValue}
                      needMemo={true}
                      getFieldValues={getFieldValues}
                      setFieldValues={setFieldValues}
                      disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    />
                  </Grid>
                );
              }
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)} //- check & implement
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={quesSlug}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={
                      disableFlag ||
                      (roleId == 5 && tabId == 3) ||
                      roleId == 6 ||
                      roleId == 8 ||
                      roleId == 7 ||
                      roleId == 9
                    }
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "line") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default Minicog;
