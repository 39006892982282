// import React from "react";
import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
} from "@mui/material";
import { DynamicTable } from "./Tables";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker } from "@mui/x-date-pickers";
import format from "date-fns/format";
import moment from "moment";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const customStyles = {
  menu: {},
  listItem: {
    width: "320px",
  },
};

export const CustomSubToggleButtonGroup = ({
  language,
  subQuestion,
  formData,
  handleSubQuestionChange,
  commentIndex,
  setCommentIndex,
  quesindex,
  section,
  inputProps,
}) => {
  const toggleComment = () => {
    if (commentIndex[quesindex + section.sectionId] !== undefined) {
      setCommentIndex({
        ...commentIndex,
        [quesindex + section.sectionId]:
          !commentIndex[quesindex + section.sectionId],
      });
    } else {
      setCommentIndex({
        ...commentIndex,
        [quesindex + section.sectionId]: true,
      });
    }
  };

  return (
    <ToggleButtonGroup
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
      fullWidth
      exclusive
      key={language.subQuestionId}
      aria-label={language.label}
      role="group"
      size="large"
      onChange={(e, value) => {
        handleSubQuestionChange(e, language, subQuestion.name, value);
      }}
      value={formData[subQuestion.name] || ""}
    >
      <ToggleButton
        sx={{
          minWidth: "230px",
          overflow: "hidden",
          display: "inline-block",
          whiteSpace: "normal",
          minHeight: "58px",
          margin: "12px",
          width: "230px",
          textAlign: "center",
          justifyContent: "center",
        }}
        key={language.subQuestionId}
        value={language.value}
        ///onClick={() => console.log("Clicked with value:", language.value)}
        aria-pressed
        aria-label={language.label}
      >
        {language.label}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export const CustomSubSelect = ({
  subQuestion,
  formData,
  handleSubQuestionChange,
}) => {
  return (
    <Grid key={subQuestion.id} xs={12} item fullWidth>
      <div
        style={{
          marginBottom: "2%",
          marginTop: "2%",
          marginLeft: "2%",
        }}
      >
        <Grid item xs={12} sm={12}>
          <FormControl variant="outlined" {...subQuestion}>
            <InputLabel
              style={{
                color: "var(--mainTheme) !important",
              }}
            >
              {subQuestion.label}
            </InputLabel>
            <Select
              //disabled
              className="inputStyle"
              label={subQuestion.label}
              variant="outlined"
              name={subQuestion.name}
              value={formData[subQuestion.name] || subQuestion.value}
              onChange={(e) =>
                handleSubQuestionChange(e, subQuestion, subQuestion.name)
              }
              sx={{
                width: "330px",
                height: "48px",
              }}
              MenuProps={{
                PaperProps: {
                  style: customStyles.listItem,
                },
              }}
              autoWidth
            >
              {subQuestion.options.map((item, index) => (
                <MenuItem key={index} value={item[1]}>
                  {item[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </div>
    </Grid>
  );
};

export const CustomSubTextField = ({ index, value, onChange, subQ }) => {
  return (
    <TextField
      //disabled
      key={index}
      value={value}
      onChange={onChange}
      {...{
        label: subQ.label,
      }}
      multiline
      maxRows={4}
      fullWidth
      variant="outlined"
      className="inputStyle"
      sx={{
        "& .MuiFormLabel-root": {
          color: "var(--mainTheme)",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "var(--mainTheme)",
        },
        marginTop: "20px",
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ArrowForwardIcon
              sx={{
                size: "medium",
                color: "var(--mainTheme)",
              }}
            />
          </InputAdornment>
        ),
      }}
      style={{
        width: "100%",
      }}
    />
  );
};

export const CustomSubTable = ({
  subQuestion,
  rowsRenderData,
  tableRowData,
  handleTableDataChange,
  // handleLabelNameChange,
  addTableRow,
  deleteTableRow,
}) => {
  return (
    subQuestion.type === "datatable" && (
      <Grid
        key={subQuestion.id}
        xs={6}
        item
        fullWidth
        style={{
          alignItems: "left",
          textAlign: "left",
          justifyContent: "left",
          marginBottom: "2%",
          marginTop: "2%",
          marginLeft: "2%",
        }}
      >
        <div
          style={{
            alignItems: "left",
            textAlign: "left",
            justifyContent: "left",
            marginBottom: "2%",
            marginTop: "2%",
            marginLeft: "2%",
          }}
        >
          {subQuestion.tablelist &&
            subQuestion.tablelist.map((table, index) => {
              if (table && table[1] === true) {
                if (table[0].label === "Medical History Specialists") {
                  return (
                    <DynamicTable
                      key={index}
                      id={index}
                      columns={table[0].columns}
                      rowsRenderData={rowsRenderData}
                      rowData={tableRowData}
                      handleTableDataChange={handleTableDataChange}
                      // handleLabelNameChange={handleLabelNameChange}
                      addTableRow={addTableRow}
                      deleteTableRow={deleteTableRow}
                    />
                  );
                } else if (table[0].label === "Family History") {
                  return (
                    <DynamicTable
                      key={index}
                      id={index}
                      columns={table[0].columns}
                      rowsRenderData={rowsRenderData}
                      rowData={tableRowData}
                      handleTableDataChange={handleTableDataChange}
                      // handleLabelNameChange={handleLabelNameChange}
                      addTableRow={addTableRow}
                      deleteTableRow={deleteTableRow}
                    />
                  );
                } else if (table[0].label === "Allergies") {
                  return (
                    <DynamicTable
                      key={index}
                      id={index}
                      columns={table[0].columns}
                      rowsRenderData={rowsRenderData}
                      rowData={tableRowData}
                      handleTableDataChange={handleTableDataChange}
                      // handleLabelNameChange={handleLabelNameChange}
                      addTableRow={addTableRow}
                      deleteTableRow={deleteTableRow}
                    />
                  );
                } else if (table[0].label === "Alcohol Usage") {
                  return (
                    <DynamicTable
                      key={index}
                      id={index}
                      columns={table[0].columns}
                      rowsRenderData={rowsRenderData}
                      rowData={tableRowData}
                      handleTableDataChange={handleTableDataChange}
                      // handleLabelNameChange={handleLabelNameChange}
                      addTableRow={addTableRow}
                      deleteTableRow={deleteTableRow}
                    />
                  );
                }
              }
            })}
        </div>
      </Grid>
    )
  );
};

export const SubNumberField = ({
  subQuestion,
  inputProps,
  handleInputChange,
}) => {
  return (
    <Stack direction="row">
      <Grid
        item
        xs={inputProps.isCommentEnabled ? 11 : 12}
        sm={inputProps.isCommentEnabled ? 11 : 12}
      >
        <div style={{ width: "100% !important" }}>
          <TextField
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            type="number"
            //disabled
            label={subQuestion.label}
            multiline
            maxRows={4}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="outlined"
            className="inputStyle"
            sx={{
              "& .MuiFormLabel-root": {
                color: "var(--mainTheme)",
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "var(--mainTheme)",
              },
              marginTop: "20px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArrowForwardIcon
                    sx={{
                      size: "medium",
                      color: "var(--mainTheme)",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onChange={handleInputChange}
          />
        </div>
      </Grid>
    </Stack>
  );
};

export const CustomSubText = ({ label, onChange, value, formData }) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      multiline
      fullWidth
      variant="outlined"
      maxRows={4}
      className="inputStyle"
      sx={{
        "& .MuiFormLabel-root": {
          color: "var(--mainTheme)",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "var(--mainTheme)",
        },
        marginTop: "20px",
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ArrowForwardIcon
              sx={{
                size: "medium",
                color: "var(--mainTheme)",
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SubSubToggleButtonGroup = ({
  option,
  subQ,
  formData,
  handleSubSubQuestionChange,
}) => {
  return (
    <ToggleButtonGroup
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
      fullWidth
      //exclusive={true}
      exclusive
      key={option.subQuestionId}
      aria-label={option.label}
      role="group"
      size="large"
      onChange={(e) => handleSubSubQuestionChange(e, subQ, subQ.name)}
      name={option.name}
      selected={formData[subQ.name] || ""}
      value={formData[subQ.name] || ""}
    >
      <ToggleButton
        exclusive
        sx={{
          minWidth: "230px",
          overflow: "hidden",
          display: "inline-block",
          whiteSpace: "normal",
          minHeight: "58px",
          margin: "12px",
          width: "230px",
          textAlign: "center",
          justifyContent: "center",
        }}
        key={option.subQuestionId}
        value={option.value}
        aria-pressed
        name={option.name}
        aria-label={option.label}
      >
        {option.label}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export function DatePickerCompo({
  subQuestion,
  inputProps,
  formData,
  setFormData,
  handleSubQuestionDateChange,
}) {
  const [selectedDate, setSelectedDate] = useState(
    dayjs(formData[subQuestion.name] || null),
  );

  const handleChange = (newDate) => {
    const formattedDate = newDate.format("MM/DD/YYYY");

    setSelectedDate(newDate);

    handleSubQuestionDateChange(
      formattedDate,
      subQuestion,
      inputProps.parentQuestionName,
      inputProps,
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      [subQuestion.name]: formattedDate,
    }));
  };

  return (
    <Grid item>
      <div
        style={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            alignItems: "left",
            textAlign: "left",
            justifyContent: "left",
            marginBottom: "2%",
            color: "var(--mainTheme)",
            marginTop: "2%",
          }}
        ></div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              label={subQuestion.label}
              value={selectedDate}
              onChange={handleChange}
              slotProps={{
                textField: {
                  readOnly: true,
                },
              }}
              sx={{
                width: "330px",
                border:
                  inputProps.isConfigured === true
                    ? "1.5px solid var(--mainTheme) !important"
                    : "1.5px solid rgba(255, 45, 49, 0.7) !important",
                "& .MuiFormLabel-root": {
                  backgroundColor: "white",
                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                },
                marginLeft: "10px",
                marginTop: "15px",
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </Grid>
  );
}

export const DatePickerComponent = ({
  subQuestion,
  inputProps,
  formData,
  handleSubQuestionDateChange,
}) => {
  const formattedDate = format(new Date(formData.examdateM), "yyyy-MM-dd");
  return (
    <Grid item>
      <div
        style={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            alignItems: "left",
            textAlign: "left",
            justifyContent: "left",
            marginBottom: "2%",
            color: "var(--mainTheme)",
            marginTop: "2%",
          }}
        >
          {subQuestion.label}
        </div>

        <DatePicker
          fullWidth
          name={inputProps.name}
          format={(value) => (value ? format(value, "yyyy-MM-dd") : "")}
          label={inputProps.label}
          variant="outlined"
          sx={{
            height: "48px",
            width: "330px",
            border:
              inputProps.isConfigured === true
                ? "1.5px solid var(--mainTheme) !important"
                : "1.5px solid rgba(255, 45, 49, 0.7) !important",
            "& .MuiFormLabel-root": {
              backgroundColor: "white",
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "white",
            },
            marginLeft: "10px",
            marginTop: "15px",
          }}
          adapter={AdapterDateFns}
          // value={formData[subQuestion.name] || {}}
          value={formattedDate}
          onChange={(newDate) =>
            handleSubQuestionDateChange(
              {
                target: {
                  value: newDate,
                },
              },
              subQuestion,
              subQuestion.name,
              inputProps,
              subQuestion,
            )
          }
        />
      </div>
    </Grid>
  );
};
