import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Route, Routes, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Toaster } from "react-hot-toast";

import NotFoundTrap from "./pages/NotFoundTrap/NotFoundTrap";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import MemberListPage from "./pages/AdminDashboard/MemberListPage/MemberListPage";
import ViewClinicians from "./pages/AdminDashboard/ViewClinicians/ViewClinicians";
import ViewReports from "./pages/AdminDashboard/ViewReports/ViewReports";
import Coder from "./pages/CoderDashboard";
import CoderDashboardList from "./pages/CoderDashboard/coderlist";
import Scheduler from "./pages/AdminDashboard/Scheduler/Scheduler";
import ChangePassword from "./pages/Login/ChangePassword/ChangePassword";
import ForgotPassword from "./pages/Login/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword/ResetPassword";
import Login from "./pages/Login/login";
import Nurse from "./pages/NurseDashboard";
import AddNewNurse from "./pages/NurseDashboard/AddNewNurse/AddNewNurse";
import Calendar from "./pages/NurseDashboard/Calendar";
import NurseDashboard from "./pages/NurseDashboard/NurseDashboard";
import NurseListPage from "./pages/NurseDashboard/NurseDashboard";
import OTPScreen from "./pages/OTP/OTPScreen";
import QA from "./pages/QaDashboard";
import QADashboardList from "./pages/QaDashboard/qalist";
import SchedulerDashboard from "./pages/SchedulerDashboard";
import { useUserContext } from "./contexts/UserContext";
import { DragNDropProvider } from "./contexts/DragNDropContext";
import { SessionProvider } from "./contexts/SessionContext";
import { InputProvider } from "./contexts/InputContext";
import { FillHraFormProvider } from "./contexts/fillHraFormContext";
import { SpeechProvider } from "./contexts/SpeechContext";
import { getApi } from "./utils/axiosApiWrapper";
import AppRoutes from "./routes/routesIndex";
import { RedirectToLogin } from "./routes/redirectPageRouting";

import "./App.scss";

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }

  console.error = console.warn = () => {};

  const { hasLoggedIn, setHasLoggedIn } = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    async function checkUserStatus() {
      const jwtToken = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
      if (jwtToken) {
        const response = await getApi("/users/getMe");
        if (response.status === 200) {
          // navigate(response?.data?.data?.[0].homePage);
          setHasLoggedIn(true);
          return null;
        }
      }
      setHasLoggedIn(false);
    }

    checkUserStatus();
  }, []);

  const redirectToPageNotFound = () => {
    const splitURLToText = window.location.pathname.split("/");
    //titleHandler(splitURLToText.pop());

    const homePage = sessionStorage.getItem("homePage");
    if (
      splitURLToText[1] &&
      homePage &&
      !splitURLToText.includes(homePage.split("/")[1])
    ) {
      navigate("*");
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
  };
  useEffect(() => {
    redirectToPageNotFound();
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SpeechProvider>
          <SessionProvider>
            <FillHraFormProvider>
              <DragNDropProvider>
                <div className="App">
                  {/* <AdminDashboard/> */}
                  {hasLoggedIn !== null && hasLoggedIn !== undefined && (
                    <Routes>
                      <Route path={`/`} element={<Login />} />
                      <Route
                        path={`/Account/ForgotPassword`}
                        element={<ForgotPassword />}
                      />
                      <Route path={`/Account/Otp`} element={<OTPScreen />} />
                      <Route
                        path={`/Account/ChangePassword`}
                        element={<ChangePassword />}
                      />
                      <Route
                        path={`/Account/ResetPassword`}
                        element={<ResetPassword />}
                      />

                      <Route
                        path={`/AdminDashboard/*`}
                        element={<AdminDashboard />}
                      />
                      {/* to-do : refactor the routes with Dashboard wise, then use React Router - Protected Routes*/}
                      <Route
                        path={`/SchedulerDashboard/*`}
                        //element={<SchedulerDashboard />}
                        element={
                          <RedirectToLogin moveToLogin={hasLoggedIn}>
                            <SchedulerDashboard />
                          </RedirectToLogin>
                        }
                      >
                        <Route
                          path="Scheduler"
                          element={
                            <RedirectToLogin moveToLogin={hasLoggedIn}>
                              <Scheduler />
                            </RedirectToLogin>
                          }
                        />
                      </Route>
                      <Route
                        path={`/QADashboard/*`}
                        element={
                          <RedirectToLogin moveToLogin={hasLoggedIn}>
                            <QA />
                          </RedirectToLogin>
                        }
                      >
                        <Route
                          path="QAList"
                          element={
                            <RedirectToLogin moveToLogin={hasLoggedIn}>
                              <QADashboardList />
                            </RedirectToLogin>
                          }
                        />
                      </Route>
                      <Route
                        path={`/CoderDashboard/*`}
                        element={
                          <RedirectToLogin moveToLogin={hasLoggedIn}>
                            <Coder />
                          </RedirectToLogin>
                        }
                      >
                        <Route
                          path="Coderlist"
                          element={
                            <RedirectToLogin moveToLogin={hasLoggedIn}>
                              <CoderDashboardList />
                            </RedirectToLogin>
                          }
                        />
                      </Route>
                      <Route
                        path={`/NurseDashboard/*`}
                        element={
                          <RedirectToLogin moveToLogin={hasLoggedIn}>
                            <Nurse />
                          </RedirectToLogin>
                        }
                      >
                        {/* <Route path="Nurselist" element={<NurseDashboard />} />{" "} */}

                        <Route
                          path="Nurselist"
                          element={
                            <RedirectToLogin moveToLogin={hasLoggedIn}>
                              <NurseListPage />
                            </RedirectToLogin>
                          }
                        />
                        <Route
                          path="Calendar"
                          element={
                            <RedirectToLogin moveToLogin={hasLoggedIn}>
                              <Calendar />
                            </RedirectToLogin>
                          }
                        />
                        <Route
                          path="Addmember"
                          element={
                            <RedirectToLogin moveToLogin={hasLoggedIn}>
                              <InputProvider>
                                <AddNewNurse />
                              </InputProvider>
                            </RedirectToLogin>
                          }
                        />
                      </Route>
                      <Route path="*" element={<NotFoundTrap />} />
                    </Routes>
                  )}

                  <Toaster
                    position="top-right"
                    reverseOrder={false}
                    gutter={8}
                    toastOptions={{
                      style: {
                        fontFamily: "Inter sans-serif !important",
                        border: "0.2px solid black",
                      },
                      success: {
                        duration: 3000,
                        style: {
                          border: "1px solid green",
                          // background:"#363636",
                          color: "green",
                          // fontWeight:"bold",
                        },
                      },
                      error: {
                        duration: 5000,
                        style: {
                          border: "1px solid tomato",
                          // background:"#363636",
                          color: "tomato",
                        },
                      },
                    }}
                  />
                  {/* <AppRoutes /> */}
                </div>
              </DragNDropProvider>
            </FillHraFormProvider>
          </SessionProvider>
        </SpeechProvider>
      </LocalizationProvider>
    </DndProvider>
  );
}

export default App;

RedirectToLogin.propTypes = {
  moveToLogin: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
