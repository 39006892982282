import React from "react";

import Login from "../pages/Login/login";
const NotFoundTrap = React.lazy(() =>
  import("../pages/NotFoundTrap/NotFoundTrap"),
);

export const PublicRoutes = [
  {
    path: "/Account/Login",
    component: <Login />,
    title: "Login Page",
  },
  {
    path: "*",
    component: <NotFoundTrap />,
    title: "Not Found",
  },
];
