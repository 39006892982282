import React from "react";
import { Bar, Scatter } from "react-chartjs-2";

const TopUsers = ({ data }) => {
  if (data?.length === 0) return <h3>No Available Data</h3>;

  const qaMapping = data?.reduce((acc, qa, index) => {
    acc[qa.name] = index + 1;
    return acc;
  }, {});

  const barChartData = {
    labels: data?.map((qa) => qa.name),
    datasets: [
      {
        data: data?.map((qa) => parseInt(qa.count)),
        backgroundColor: "rgba(0, 123, 255, 1)",
        borderWidth: 0,
        barThickness: 20,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Top QA by Members Assigned",
      },
    },
  };
  return <Bar data={barChartData} options={options} />;
};

export default TopUsers;
