// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width-table {
  width: 100%;
}

.half-width-table {
  width: 50%;
}

.wrapperss {
  display: flex;
  justify-content: center;
  margin-top: -8px;
}

.itemssss {
  /* width: 100px; */
  height: 50px;
  margin: 0px;
  position: relative;
}

.arrowBtn {
  border: 1px solid transparent;
  background: transparent;
  /* width: 100%; */
  padding: 15px;
  border-radius: 3px;
  color: blue;
  transition: 0.5s ease;
  display: flex;
}
.arrow1 {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
  /* font-size: 16px; */
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/DynamicFormMUI/Tables.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,qBAAqB;EACrB,aAAa;AACf;AACA;EACE,yCAAyC;EACzC,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE;;IAEE,0BAA0B;EAC5B;;EAEA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".full-width-table {\r\n  width: 100%;\r\n}\r\n\r\n.half-width-table {\r\n  width: 50%;\r\n}\r\n\r\n.wrapperss {\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-top: -8px;\r\n}\r\n\r\n.itemssss {\r\n  /* width: 100px; */\r\n  height: 50px;\r\n  margin: 0px;\r\n  position: relative;\r\n}\r\n\r\n.arrowBtn {\r\n  border: 1px solid transparent;\r\n  background: transparent;\r\n  /* width: 100%; */\r\n  padding: 15px;\r\n  border-radius: 3px;\r\n  color: blue;\r\n  transition: 0.5s ease;\r\n  display: flex;\r\n}\r\n.arrow1 {\r\n  animation: slide1 1s ease-in-out infinite;\r\n  margin-left: 9px;\r\n  /* font-size: 16px; */\r\n}\r\n\r\n@keyframes slide1 {\r\n  0%,\r\n  100% {\r\n    transform: translate(0, 0);\r\n  }\r\n\r\n  50% {\r\n    transform: translate(10px, 0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
