// // src/ProfilePhotoUpload.js
// import { Fade } from "@mui/material";
// import React, { useState, useEffect } from "react";
// //import './ProfilePhotoUpload.css'; // Import the CSS file
// import toast from "react-hot-toast";
// const ProfilePhotoUpload = ({ profilePic, setProfilePic, disabled }) => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [previewUrl, setPreviewUrl] = useState("");

//   //   const handleFileChange = (event) => {
//   //     const file = event.target.files[0];
//   //     if (file) {
//   //       setSelectedFile(file);
//   //       const fileUrl = URL.createObjectURL(file);
//   //       setPreviewUrl(fileUrl);
//   //       setProfilePic(fileUrl);
//   //     }
//   //   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     console.log("file", file);
//     console.log("hello");
//     setSelectedFile(file);
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         //setPreviewImage(reader.result);
//         setPreviewUrl(reader.result);
//         setProfilePic(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//     //  else {
//     //   setPreviewUrl(null);
//     // }
//   };

//   useEffect(() => {
//     console.log("profilePic", profilePic);
//     setPreviewUrl(profilePic);
//   }, []);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     toast.success("Profile Photo Uploaded Successfully", {});
//     // Handle the upload process here, e.g., send the file to a server
//     console.log(selectedFile);
//   };

//   return (
//     <div>
//       <form
//         onSubmit={handleSubmit}
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           backgroundColor: "#8790f5",
//           width: "250px",
//         }}
//       >
//         <input
//           type="file"
//           accept="image/*"
//           disabled={disabled}
//           onChange={handleFileChange}
//           style={{ display: "none" }}
//           id="file-input"
//         />
//         <label
//           htmlFor="file-input"
//           className="upload-circle"
//           style={{
//             width: "200px",
//             height: "200px",
//             borderRadius: "50%",
//             border: "2px dashed #ccc",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             cursor: "pointer",
//             overflow: "hidden",
//             // backgroundColor:'red',
//             //backgroundImage:url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMSgQGyTWI52ORnXhYGpUEVfJR3aQa8kadLQ&s'),
//             marginBottom: "10px",
//             marginTop: "10px",
//           }}
//         >
//           {previewUrl ? (
//             <img
//               src={previewUrl}
//               alt="Profile Preview"
//               className="profile-image"
//               style={{ width: "100%", height: "100%", objectFit: "cover" }}
//             />
//           ) : (
//             <div
//               className="placeholder"
//               style={{
//                 width: "100%",
//                 height: " 100%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 fontSize: "16px",
//                 color: "#aaa",
//               }}
//             >
//               <img
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtH87ezXJb8GjssZrlGGI9kbAL8QxJ5MUEDQ&s"
//                 alt="Profile Preview"
//                 className="profile-image"
//                 style={{ width: "100%", height: "100%", objectFit: "cover" }}
//               />
//               {/* <span>Upload Image</span> */}
//             </div>
//           )}
//         </label>
//       </form>
//       <Fade in={!disabled} unmountOnExit>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-end",
//             alignItems: "center",
//           }}
//         >
//           <button
//             type="button"
//             onClick={() => {
//               setSelectedFile(null);
//               setPreviewUrl("");
//             }}
//             style={{ marginInline: "10px" }}
//           >
//             Cancel
//           </button>
//           <button
//             disabled={!Boolean(selectedFile)}
//             type="button"
//             onClick={(e) => {
//               handleSubmit(e);
//             }}
//           >
//             Upload
//           </button>
//         </div>
//       </Fade>
//     </div>
//   );
// };

// export default ProfilePhotoUpload;

// src/ProfilePhotoUpload.js
// import { Fade } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import toast from "react-hot-toast";

// const ProfilePhotoUpload = ({ profilePic, setProfilePic, disabled }) => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [previewUrl, setPreviewUrl] = useState("");
//   const [crop, setCrop] = useState({ aspect: 1 });
//   const [completedCrop, setCompletedCrop] = useState(null);
//   const [imageRef, setImageRef] = useState(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviewUrl(reader.result);
//         setProfilePic(reader.result); // Set initial profile picture
//       };
//       reader.readAsDataURL(file);
//       setSelectedFile(file);
//     }
//   };

//   useEffect(() => {
//     setPreviewUrl(profilePic);
//   }, [profilePic]);

//   const handleCropComplete = (crop) => {
//     setCompletedCrop(crop);
//   };

//   const handleImageLoaded = (image) => {
//     setImageRef(image);
//   };

//   const getCroppedImg = () => {
//     if (imageRef && completedCrop?.width && completedCrop?.height) {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");
//       canvas.width = completedCrop?.width;
//       canvas.height = completedCrop?.height;

//       ctx.drawImage(
//         imageRef,
//         completedCrop?.x,
//         completedCrop?.y,
//         completedCrop?.width,
//         completedCrop?.height,
//         0,
//         0,
//         completedCrop?.width,
//         completedCrop?.height
//       );

//       return new Promise((resolve) => {
//         canvas.toBlob((blob) => {
//           const fileUrl = URL.createObjectURL(blob);
//           resolve(fileUrl);
//         }, "image/jpeg");
//       });
//     }
//     return null;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const croppedImageUrl = await getCroppedImg();
//     setProfilePic(croppedImageUrl); // Set cropped profile picture
//     toast.success("Profile Photo Uploaded Successfully", {});
//     console.log(selectedFile);
//   };

//   return (
//     <div>
//       <form
//         onSubmit={handleSubmit}
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           backgroundColor: "#8790f5",
//           width: "250px",
//         }}
//       >
//         <input
//           type="file"
//           accept="image/*"
//           disabled={disabled}
//           onChange={handleFileChange}
//           style={{ display: "none" }}
//           id="file-input"
//         />
//         <label
//           htmlFor="file-input"
//           className="upload-circle"
//           style={{
//             width: "200px",
//             height: "200px",
//             borderRadius: "50%",
//             border: "2px dashed #ccc",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             cursor: "pointer",
//             overflow: "hidden",
//             marginBottom: "10px",
//             marginTop: "10px",
//           }}
//         >
//           {previewUrl ? (
//             <ReactCrop
//               src={previewUrl}
//               crop={crop}
//               onImageLoaded={handleImageLoaded}
//               onComplete={handleCropComplete}
//               onChange={(newCrop) => setCrop(newCrop)}
//             />
//           ) : (
//             <div
//               className="placeholder"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 fontSize: "16px",
//                 color: "#aaa",
//               }}
//             >
//               <img
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtH87ezXJb8GjssZrlGGI9kbAL8QxJ5MUEDQ&s"
//                 alt="Profile Preview"
//                 style={{ width: "100%", height: "100%", objectFit: "cover" }}
//               />
//             </div>
//           )}
//         </label>
//       </form>
//       <Fade in={!disabled} unmountOnExit>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-end",
//             alignItems: "center",
//           }}
//         >
//           <button
//             type="button"
//             onClick={() => {
//               setSelectedFile(null);
//               setPreviewUrl("");
//               setProfilePic(""); // Reset profile picture
//             }}
//             style={{ marginInline: "10px" }}
//           >
//             Cancel
//           </button>
//           <button
//             disabled={!Boolean(selectedFile)}
//             type="button"
//             onClick={(e) => {
//               handleSubmit(e);
//             }}
//           >
//             Upload
//           </button>
//         </div>
//       </Fade>
//     </div>
//   );
// };

// export default ProfilePhotoUpload;

import { Fade, Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import toast from "react-hot-toast";
import { useUserContext } from "../../../contexts/UserContext";

const ProfilePhotoUpload = ({ profilePic, setProfilePic, disabled }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [crop, setCrop] = useState({ unit: "%", width: 90 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");

  useEffect(() => {
    setPreviewUrl(profilePic);
  }, [profilePic]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setCroppedImageUrl(""); // Reset cropped image when a new file is selected
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageLoad = () => {
    // Optional: Initialize crop settings here if needed
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (completedCrop && imgRef.current) {
      const canvas = canvasRef.current;
      const image = imgRef.current;

      const crop = completedCrop;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );

      // const blob = await new Promise((resolve) => {
      //   canvas.toBlob(resolve, "image/jpeg");
      // });
      // const url = URL.createObjectURL(blob);

      const url = canvas.toDataURL();
      setCroppedImageUrl(url); // Set the cropped image URL
      setProfilePic(url); // Optionally update the profile picture state
      toast.success("Profile Photo Uploaded Successfully");
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#8790f5",
          width: "250px",
        }}
      >
        <input
          type="file"
          accept="image/*"
          disabled={disabled}
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-input"
        />
        <label
          htmlFor="file-input"
          className="upload-circle"
          style={{
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            border: "2px dashed #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            overflow: "hidden",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          {previewUrl ? (
            <img
              ref={imgRef}
              src={previewUrl}
              alt="Profile Preview"
              className="profile-image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onLoad={handleImageLoad}
            />
          ) : (
            <div
              className="placeholder"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                color: "#aaa",
              }}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtH87ezXJb8GjssZrlGGI9kbAL8QxJ5MUEDQ&s"
                alt="Profile Placeholder"
                className="profile-image"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          )}
        </label>
      </form>
      <Box display="flex">
        <Box
          display="flex"
          style={{ marginTop: "10px", textAlign: "center", width: "50%" }}
        >
          {previewUrl && (
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={(newCrop) => setCompletedCrop(newCrop)}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={previewUrl}
                style={{ maxWidth: "100%" }}
              />
            </ReactCrop>
          )}
        </Box>

        <Box
          display="flex"
          style={{ marginTop: "10px", textAlign: "center", width: "50%" }}
        >
          {croppedImageUrl && (
            <div>
              <h4>Cropped Image Preview:</h4>
              <img
                src={croppedImageUrl}
                alt="Cropped Preview"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </div>
          )}
        </Box>
      </Box>

      <canvas ref={canvasRef} style={{ display: "none" }} />
      <Fade in={!disabled} unmountOnExit>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            type="button"
            onClick={() => {
              setSelectedFile(null);
              setPreviewUrl("");
              setCroppedImageUrl("");
              setCrop({ unit: "%", width: 90 });
              setCompletedCrop(null);
            }}
            style={{ marginInline: "10px" }}
          >
            Cancel
          </button>
          <button
            disabled={!Boolean(selectedFile)}
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Upload
          </button>
        </div>
      </Fade>
    </div>
  );
};

export default ProfilePhotoUpload;
