import {
  getObjectData,
  setObjectData,
} from "../../../../utils/helpers.DynamicForm";

// [ react_state_variable,db_json_path,]

const self_hashmap = [
  ["self_schoolThirdGradeVal", "school.thirdGrade"],
  ["self_schoolEighthGradeVal", "school.eighthGrade"],
  ["self_schoolLessTwelthGradeVal", "school.lessthanTwelthGrade"],
  ["self_schoolCompTwelthGradeVal", "school.completedTwelthGrade"],
  ["self_schoolCommentVal", "school.comment"],

  ["self_writtenInfoVeryDifficultVal", "writtenInfo.veryDifficult"],
  ["self_writtenInfoSomewhatDifficultVal", "writtenInfo.somewhatDifficult"],
  ["self_writtenInfoEasyVal", "writtenInfo.easy"],
  ["self_writtenInfoVeryEasyVal", "writtenInfo.veryeasy"],
  ["self_writtenInfoCommentVal", "writtenInfo.comment"],

  ["self_presciptionBottleVeryDifficultVal", "presciptionBottle.veryDifficult"],
  [
    "self_presciptionBottleSomewhatDifficultVal",
    "presciptionBottle.somewhatDifficult",
  ],
  ["self_presciptionBottleEasyVal", "presciptionBottle.easy"],
  ["self_presciptionBottleVeryEasyVal", "presciptionBottle.veryeasy"],
  ["self_presciptionBottleCommentVal", "presciptionBottle.comment"],

  [
    "self_fillingMedicalFormNotAtAllConfidentVal",
    "fillingMedicalForm.notAtAllConfident",
  ],
  [
    "self_fillingMedicalFormNotVeryConfidentVal",
    "fillingMedicalForm.notVeryConfident",
  ],
  ["self_fillingMedicalFormConfidentVal", "fillingMedicalForm.confident"],
  [
    "self_fillingMedicalFormVeryConfidentVal",
    "fillingMedicalForm.veryConfident",
  ],
  ["self_fillingMedicalFormCommentVal", "fillingMedicalForm.comment"],

  ["self_ratYourHealthExcellentVal", "ratYourHealth.excellent"],
  ["self_ratYourHealthGoodVal", "ratYourHealth.good"],
  ["self_ratYourHealthFairVal", "ratYourHealth.fair"],
  ["self_ratYourHealthPoorVal", "ratYourHealth.poor"],
  ["self_ratYourHealthCommentVal", "ratYourHealth.comment"],

  ["self_socialActivitiesOftenVal", "socialActivities.often"],
  ["self_socialActivitiesSometimesVal", "socialActivities.sometimes"],
  ["self_socialActivitiesAlmostNeverVal", "socialActivities.almostNever"],
  ["self_socialActivitiesNeverVal", "socialActivities.never"],
  ["self_socialActivitiesCommentVal", "socialActivities.comment"],

  ["self_currentLiveHomeVal", "currentLive.home"],
  ["self_currentLiveApartVal", "currentLive.apartment"],
  ["self_currentLiveAssisLiveVal", "currentLive.assistedLiving"],
  ["self_currentLiveNursingVal", "currentLive.nursingHome"],
  ["self_currentLiveHomeLessVal", "currentLive.homeless"],
  ["self_currentLiveOtherVal", "currentLive.other"],
  ["self_currentLiveHomelessDescVal", "currentLive.homelessSubAnswer.descibe"],
  ["self_currentLiveOtherDescVal", "currentLive.otherSubAnswer.descibe"],
  ["self_currentLiveCommentVal", "currentLive.comment"],

  ["self_relyOnVal", "relyOn.replyOn"],
  ["self_relyOnCommentVal", "relyOn.comment"],

  ["self_currentlyLiveAloneVal", "currentlyLivingWith.alone"],
  ["self_currentlyLiveSpouseVal", "currentlyLivingWith.spouse"],
  ["self_currentlyLivePartnerVal", "currentlyLivingWith.partner"],
  ["self_currentlyLiveRelativeVal", "currentlyLivingWith.relative"],
  ["self_currentlyLiveFamilyVal", "currentlyLivingWith.family"],
  ["self_currentlyLiveFriendVal", "currentlyLivingWith.friend"],
  [
    "self_currentlyLivePersCareWorkVal",
    "currentlyLivingWith.personalCareWorker",
  ],
  [
    "self_currentlyLiveAloneDescVal",
    "currentlyLivingWith.aloneSubAnswer.descibe",
  ],
  ["self_currentlyLiveCommentVal", "currentlyLivingWith.comment"],

  ["self_careGiverVal", "careGiver.caregiver"],
  ["self_careGiverDescVal", "careGiver.caregiverSubAnswer.descibe"],
  ["self_careGiverCommentVal", "careGiver.comment"],

  ["self_currentlyEmployVal", "currentlyEmployed.currentlyEmployed"],
  ["self_currentlyEmployCommentVal", "currentlyEmployed.comment"],

  ["self_interestEmployVal", "interestedEmployment.interestedEmployment"],
  ["self_interestEmployCommentVal", "interestedEmployment.comment"],

  ["self_volunCurrentlyVal", "volunteerCurrenly.valvolunteerCurrenlyue"],
  ["self_volunCurrentlyCommentVal", "volunteerCurrenly.comment"],

  ["self_recreaDrugsVal", "recreationalDrugs.recreationaDrugs"],
  [
    "self_recreaDrugsWhichVal",
    "recreationalDrugs.recreationaDrugsSubAnswer.whichDrugs",
  ],
  ["self_recreaDrugsCommentVal", "recreationalDrugs.comment"],

  ["self_healthProxyVal", "healthcareProxy.isHealthcareProxy"],
  [
    "self_healthProxyNameVal",
    "healthcareProxy.isHealthcareProxySubAnswers.name",
  ],
  [
    "self_healthProxyRelationVal",
    "healthcareProxy.isHealthcareProxySubAnswers.relationship",
  ],
  ["self_healthProxyCommentVal", "healthcareProxy.comment"],

  ["self_powerAttnyVal", "powerAttorney.isPowerAttorney"],
  ["self_powerAttnyNameVal", "powerAttorney.isPowerAttorneySubAnswers.name"],
  [
    "self_powerAttnyRelationVal",
    "powerAttorney.isPowerAttorneySubAnswers.relationship",
  ],
  ["self_powerAttnyCommentVal", "powerAttorney.comment"],

  ["self_advDirectVal", "advanceDirective.isAdvanceDirective"],
  [
    "self_advDirectKeptVal",
    "advanceDirective.isAdvanceDirectiveSubAnswer.kept",
  ],
  ["self_advDirectCommentVal", "advanceDirective.comment"],

  [
    "self_eduAdvPlanVal",
    "educatedOnAdvanceCarePlanning.educatedOnAdvanceCarePlanning",
  ],
  ["self_eduAdvPlanCommentVal", "educatedOnAdvanceCarePlanning.comment"],

  ["self_declineDiscVal", "declinesDiscussion.declinesDiscussion"],
  ["self_declineDiscCommentVal", "declinesDiscussion.comment"],

  ["self_foodRunOutOftenTrueVal", "foodWouldRunOut.oftenTrue"],
  ["self_foodRunOutSomeTrueVal", "foodWouldRunOut.sometimesTrue"],
  ["self_foodRunOutNeverTrueVal", "foodWouldRunOut.neverTrue"],
  ["self_foodRunOutCommentVal", "foodWouldRunOut.comment"],

  ["self_foodBoughtOftenTrueVal", "foodWeBought.oftenTrue"],
  ["self_foodBoughtSomeTrueVal", "foodWeBought.sometimesTrue"],
  ["self_foodBoughtNeverTrueVal", "foodWeBought.neverTrue"],
  ["self_foodBoughtCommentVal", "foodWeBought.comment"],

  [
    "self_recoSmokTobaccoVal",
    "recommendations.recommendationSubAnswers[0].smokingOrTobacco",
  ],
  [
    "self_recoSusAbuseVal",
    "recommendations.recommendationSubAnswers[1].substanceAbuse",
  ],
  [
    "self_recoDurPowerVal",
    "recommendations.recommendationSubAnswers[2].durablePower",
  ],
  [
    "self_recoHealthProxyVal",
    "recommendations.recommendationSubAnswers[3].healthcareProxy",
  ],
  [
    "self_recoAdvDirVal",
    "recommendations.recommendationSubAnswers[4].advancedDirective",
  ],
  [
    "self_recoFoodDispVal",
    "recommendations.recommendationSubAnswers[5].foodDisparity",
  ],
  [
    "self_recoLiteracyVal",
    "recommendations.recommendationSubAnswers[6].literacy",
  ],
  [
    "self_recoSocSuprtVal",
    "recommendations.recommendationSubAnswers[7].socialSupport",
  ],

  [
    "self_RecommendationssmokingOrTobaccoCommentVal",
    "recommendations.recommendationSubAnswers[0].comment",
  ],
  [
    "self_RecommendationssubstanceAbuseCommentVal",
    "recommendations.recommendationSubAnswers[1].comment",
  ],
  [
    "self_RecommendationsdurablePowerCommentVal",
    "recommendations.recommendationSubAnswers[2].comment",
  ],
  [
    "self_RecommendationshealthcareProxyCommentVal",
    "recommendations.recommendationSubAnswers[3].comment",
  ],
  [
    "self_RecommendationsadvancedDirectiveCommentVal",
    "recommendations.recommendationSubAnswers[4].comment",
  ],
  [
    "self_RecommendationsfoodDisparityCommentVal",
    "recommendations.recommendationSubAnswers[5].comment",
  ],
  [
    "self_RecommendationsliteracyCommentVal",
    "recommendations.recommendationSubAnswers[6].comment",
  ],
  [
    "self_RecommendationssocialSupportCommentVal",
    "recommendations.recommendationSubAnswers[7].comment",
  ],
];

const self_optionsTreeMapObject = {
  self_schoolThirdGradeVal: "thirdGrade",
  self_schoolEighthGradeVal: "eighthGrade",
  self_schoolLessTwelthGradeVal: "lessthanTwelthGrade",
  self_schoolCompTwelthGradeVal: "completedTwelthGrade",

  self_writtenInfoVeryDifficultVal: "veryDifficult",
  self_writtenInfoSomewhatDifficultVal: "somewhatDifficult",
  self_writtenInfoEasyVal: "easy",
  self_writtenInfoVeryEasyVal: "veryeasy",

  self_presciptionBottleVeryDifficultVal: "veryDifficult",
  self_presciptionBottleSomewhatDifficultVal: "somewhatDifficult",
  self_presciptionBottleEasyVal: "easy",
  self_presciptionBottleVeryEasyVal: "veryeasy",

  self_fillingMedicalFormNotAtAllConfidentVal: "notAtAllConfident",
  self_fillingMedicalFormNotVeryConfidentVal: "notVeryConfident",
  self_fillingMedicalFormConfidentVal: "confident",
  self_fillingMedicalFormVeryConfidentVal: "veryConfident",

  self_ratYourHealthExcellentVal: "excellent",
  self_ratYourHealthGoodVal: "good",
  self_ratYourHealthFairVal: "fair",
  self_ratYourHealthPoorVal: "poor",

  self_socialActivitiesOftenVal: "often",
  self_socialActivitiesSometimesVal: "sometimes",
  self_socialActivitiesAlmostNeverVal: "almostNever",
  self_socialActivitiesNeverVal: "never",

  self_currentLiveHomeVal: "home",
  self_currentLiveApartVal: "apartment",
  self_currentLiveAssisLiveVal: "assistedLiving",
  self_currentLiveNursingVal: "nursingHome",
  self_currentLiveHomeLessVal: "homeless",
  self_currentLiveOtherVal: "other",

  // self_relyOnYesVal:"yes",
  // self_relyOnNoVal:"no",

  self_currentlyLiveAloneVal: "alone",
  self_currentlyLiveSpouseVal: "spouse",
  self_currentlyLivePartnerVal: "partner",
  self_currentlyLiveRelativeVal: "relative",
  self_currentlyLiveFamilyVal: "family",
  self_currentlyLiveFriendVal: "friend",
  self_currentlyLivePersCareWorkVal: "personalCareWorker",

  // self_careGiverYesVal:"yes",
  // self_careGiverNoVal:"no",

  self_foodRunOutOftenTrueVal: "oftenTrue",
  self_foodRunOutSomeTrueVal: "sometimesTrue",
  self_foodRunOutNeverTrueVal: "neverTrue",

  self_foodBoughtOftenTrueVal: "oftenTrue",
  self_foodBoughtSomeTrueVal: "sometimesTrue",
  self_foodBoughtNeverTrueVal: "neverTrue",
};

const selfOptionsTreeWrappers = [
  [
    "self_school",
    [
      "self_schoolThirdGradeVal",
      "self_schoolEighthGradeVal",
      "self_schoolLessTwelthGradeVal",
      "self_schoolCompTwelthGradeVal",
    ],
  ],
  [
    "self_writtenInfo",
    [
      "self_writtenInfoVeryDifficultVal",
      "self_writtenInfoSomewhatDifficultVal",
      "self_writtenInfoEasyVal",
      "self_writtenInfoVeryEasyVal",
    ],
  ],
  [
    "self_presciptionBottle",
    [
      "self_presciptionBottleVeryDifficultVal",
      "self_presciptionBottleSomewhatDifficultVal",
      "self_presciptionBottleEasyVal",
      "self_presciptionBottleVeryEasyVal",
    ],
  ],
  [
    "self_fillingMedicalForm",
    [
      "self_fillingMedicalFormNotAtAllConfidentVal",
      "self_fillingMedicalFormNotVeryConfidentVal",
      "self_fillingMedicalFormConfidentVal",
      "self_fillingMedicalFormVeryConfidentVal",
    ],
  ],
  [
    "self_ratYourHealth",
    [
      "self_ratYourHealthExcellentVal",
      "self_ratYourHealthGoodVal",
      "self_ratYourHealthFairVal",
      "self_ratYourHealthPoorVal",
    ],
  ],
  [
    "self_socialActivities",
    [
      "self_socialActivitiesOftenVal",
      "self_socialActivitiesSometimesVal",
      "self_socialActivitiesAlmostNeverVal",
      "self_socialActivitiesNeverVal",
    ],
  ],
  [
    "self_currentLive",
    [
      "self_currentLiveHomeVal",
      "self_currentLiveApartVal",
      "self_currentLiveAssisLiveVal",
      "self_currentLiveNursingVal",
      "self_currentLiveHomeLessVal",
      "self_currentLiveOtherVal",
    ],
  ],
  [
    "self_currentlyLive",
    [
      "self_currentlyLiveAloneVal",
      "self_currentlyLiveSpouseVal",
      "self_currentlyLivePartnerVal",
      "self_currentlyLiveRelativeVal",
      "self_currentlyLiveFamilyVal",
      "self_currentlyLiveFriendVal",
      "self_currentlyLivePersCareWorkVal",
    ],
  ],
  [
    "self_foodRunOut",
    [
      "self_foodRunOutOftenTrueVal",
      "self_foodRunOutSomeTrueVal",
      "self_foodRunOutNeverTrueVal",
    ],
  ],
  [
    "self_foodBought",
    [
      "self_foodBoughtOftenTrueVal",
      "self_foodBoughtSomeTrueVal",
      "self_foodBoughtNeverTrueVal",
    ],
  ],
];

export const getSelfAssessData = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  self_hashmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  selfOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  const self_schoolComment = tempDbData["self_schoolCommentVal"];
  const self_writtenInfoComment = tempDbData["self_writtenInfoCommentVal"];
  const self_presciptionBottleComment =
    tempDbData["self_presciptionBottleCommentVal"];
  const self_fillingMedicalFormComment =
    tempDbData["self_fillingMedicalFormCommentVal"];
  const self_ratYourHealthComment = tempDbData["self_ratYourHealthCommentVal"];
  const self_socialActivitiesComment =
    tempDbData["self_socialActivitiesCommentVal"];

  const self_currentLiveHomelessDesc =
    tempDbData["self_currentLiveHomelessDescVal"];
  const self_currentLiveOtherDesc = tempDbData["self_currentLiveOtherDescVal"];
  const self_currentLiveComment = tempDbData["self_currentLiveCommentVal"];

  const self_relyOn = getOptionsSelectedYesNo(tempDbData["self_relyOnVal"]);
  const self_relyOnComment = tempDbData["self_relyOnCommentVal"];

  const self_currentlyLiveAloneDesc =
    tempDbData["self_currentlyLiveAloneDescVal"];
  const self_currentlyLiveComment = tempDbData["self_currentlyLiveCommentVal"];

  const self_careGiver = getOptionsSelectedYesNo(
    tempDbData["self_careGiverVal"],
  );
  const self_careGiverDesc = tempDbData["self_careGiverDescVal"];
  const self_careGiverComment = tempDbData["self_careGiverCommentVal"];

  const self_currentlyEmploy = getOptionsSelectedYesNo(
    tempDbData["self_currentlyEmployVal"],
  );
  const self_currentlyEmployComment =
    tempDbData["self_currentlyEmployCommentVal"];

  const self_interestEmploy = getOptionsSelectedYesNo(
    tempDbData["self_interestEmployVal"],
  );
  const self_interestEmployComment =
    tempDbData["self_interestEmployCommentVal"];

  const self_volunCurrently = getOptionsSelectedYesNo(
    tempDbData["self_volunCurrentlyVal"],
  );
  const self_volunCurrentlyComment =
    tempDbData["self_volunCurrentlyCommentVal"];

  const self_recreaDrugs = getOptionsSelectedYesNo(
    tempDbData["self_recreaDrugsVal"],
  );
  const self_recreaDrugsWhich = tempDbData["self_recreaDrugsWhichVal"];
  const self_recreaDrugsComment = tempDbData["self_recreaDrugsCommentVal"];

  const self_healthProxy = getOptionsSelectedYesNoDontKnow(
    tempDbData["self_healthProxyVal"],
  );
  const self_healthProxyName = tempDbData["self_healthProxyNameVal"];
  const self_healthProxyRelation = tempDbData["self_healthProxyRelationVal"];
  const self_healthProxyComment = tempDbData["self_healthProxyCommentVal"];

  const self_powerAttny = getOptionsSelectedYesNoDontKnow(
    tempDbData["self_powerAttnyVal"],
  );
  const self_powerAttnyName = tempDbData["self_powerAttnyNameVal"];
  const self_powerAttnyRelation = tempDbData["self_powerAttnyRelationVal"];
  const self_powerAttnyComment = tempDbData["self_powerAttnyCommentVal"];

  const self_advDirect = getOptionsSelectedYesNoDontKnow(
    tempDbData["self_advDirectVal"],
  );
  const self_advDirectKept = tempDbData["self_advDirectKeptVal"];
  const self_advDirectComment = tempDbData["self_advDirectCommentVal"];

  const self_eduAdvPlan = tempDbData["self_eduAdvPlanVal"];
  const self_eduAdvPlanComment = tempDbData["self_eduAdvPlanCommentVal"];

  const self_declineDisc = tempDbData["self_declineDiscVal"];
  const self_declineDiscComment = tempDbData["self_declineDiscCommentVal"];

  const self_foodRunOutComment = tempDbData["self_foodRunOutCommentVal"];

  const self_foodBoughtComment = tempDbData["self_foodBoughtCommentVal"];

  const self_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["self_recoSmokTobaccoVal"],
      tempDbData["self_recoSusAbuseVal"],
      tempDbData["self_recoDurPowerVal"],
      tempDbData["self_recoHealthProxyVal"],
      tempDbData["self_recoAdvDirVal"],
      tempDbData["self_recoFoodDispVal"],
      tempDbData["self_recoLiteracyVal"],
      tempDbData["self_recoSocSuprtVal"],
    ],
    [
      "smokingOrTobacco",
      "substanceAbuse",
      "durablePower",
      "healthcareProxy",
      "advancedDirective",
      "foodDisparity",
      "literacy",
      "socialSupport",
    ],
  );

  const self_RecommendationssmokingOrTobaccoComment =
    tempDbData["self_RecommendationssmokingOrTobaccoCommentVal"];
  const self_RecommendationssubstanceAbuseComment =
    tempDbData["self_RecommendationssubstanceAbuseCommentVal"];
  const self_RecommendationsdurablePowerComment =
    tempDbData["self_RecommendationsdurablePowerCommentVal"];
  const self_RecommendationshealthcareProxyComment =
    tempDbData["self_RecommendationshealthcareProxyCommentVal"];
  const self_RecommendationsadvancedDirectiveComment =
    tempDbData["self_RecommendationsadvancedDirectiveCommentVal"];
  const self_RecommendationsfoodDisparityComment =
    tempDbData["self_RecommendationsfoodDisparityCommentVal"];
  const self_RecommendationsliteracyComment =
    tempDbData["self_RecommendationsliteracyCommentVal"];
  const self_RecommendationssocialSupportComment =
    tempDbData["self_RecommendationssocialSupportCommentVal"];

  tempDbData = {
    ...tempData,
    self_schoolComment,
    self_writtenInfoComment,
    self_presciptionBottleComment,
    self_fillingMedicalFormComment,
    self_ratYourHealthComment,
    self_socialActivitiesComment,
    self_currentLiveHomelessDesc,
    self_currentLiveOtherDesc,
    self_currentLiveComment,
    self_relyOn,
    self_relyOnComment,
    self_currentlyLiveAloneDesc,
    self_currentlyLiveComment,
    self_careGiver,
    self_careGiverDesc,
    self_careGiverComment,
    self_currentlyEmploy,
    self_currentlyEmployComment,
    self_interestEmploy,
    self_interestEmployComment,
    self_volunCurrently,
    self_volunCurrentlyComment,
    self_recreaDrugs,
    self_recreaDrugsWhich,
    self_recreaDrugsComment,
    self_healthProxy,
    self_healthProxyName,
    self_healthProxyRelation,
    self_healthProxyComment,
    self_powerAttny,
    self_powerAttnyName,
    self_powerAttnyRelation,
    self_powerAttnyComment,
    self_advDirect,
    self_advDirectKept,
    self_advDirectComment,
    self_eduAdvPlan,
    self_eduAdvPlanComment,
    self_declineDisc,
    self_declineDiscComment,
    self_foodRunOutComment,
    self_foodBoughtComment,
    self_Recommendations,
    self_RecommendationssmokingOrTobaccoComment,
    self_RecommendationssubstanceAbuseComment,
    self_RecommendationsdurablePowerComment,
    self_RecommendationshealthcareProxyComment,
    self_RecommendationsadvancedDirectiveComment,
    self_RecommendationsfoodDisparityComment,
    self_RecommendationsliteracyComment,
    self_RecommendationssocialSupportComment,
  };

  // console.log(tempDbData, tempData, "self");
  return tempDbData;
};

export const setSelfAssessData = (formData) => {
  let tempData = {};

  selfOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1]),
    };
  });

  const self_schoolCommentVal = formData["self_schoolComment"];
  const self_writtenInfoCommentVal = formData["self_writtenInfoComment"];
  const self_presciptionBottleCommentVal =
    formData["self_presciptionBottleComment"];
  const self_fillingMedicalFormCommentVal =
    formData["self_fillingMedicalFormComment"];
  const self_ratYourHealthCommentVal = formData["self_ratYourHealthComment"];
  const self_socialActivitiesCommentVal =
    formData["self_socialActivitiesComment"];

  const self_currentLiveHomelessDescVal =
    formData["self_currentLiveHomelessDesc"];
  const self_currentLiveOtherDescVal = formData["self_currentLiveOtherDesc"];
  const self_currentLiveCommentVal = formData["self_currentLiveComment"];

  const self_relyOnVal = setOptionsSelectedYesNo(formData["self_relyOn"]);
  const self_relyOnCommentVal = formData["self_relyOnComment"];

  const self_currentlyLiveAloneDescVal =
    formData["self_currentlyLiveAloneDesc"];
  const self_currentlyLiveCommentVal = formData["self_currentlyLiveComment"];

  const self_careGiverVal = setOptionsSelectedYesNo(formData["self_careGiver"]);
  const self_careGiverDescVal = formData["self_careGiverDesc"];
  const self_careGiverCommentVal = formData["self_careGiverComment"];

  const self_currentlyEmployVal = setOptionsSelectedYesNo(
    formData["self_currentlyEmploy"],
  );
  const self_currentlyEmployCommentVal =
    formData["self_currentlyEmployComment"];

  const self_interestEmployVal = setOptionsSelectedYesNo(
    formData["self_interestEmploy"],
  );
  const self_interestEmployCommentVal = formData["self_interestEmployComment"];

  const self_volunCurrentlyVal = setOptionsSelectedYesNo(
    formData["self_volunCurrently"],
  );
  const self_volunCurrentlyCommentVal = formData["self_volunCurrentlyComment"];

  const self_recreaDrugsVal = setOptionsSelectedYesNo(
    formData["self_recreaDrugs"],
  );
  const self_recreaDrugsWhichVal = formData["self_recreaDrugsWhich"];
  const self_recreaDrugsCommentVal = formData["self_recreaDrugsComment"];

  const self_healthProxyVal = setOptionsSelectedYesNoDontKnow(
    formData["self_healthProxy"],
  );
  const self_healthProxyNameVal = formData["self_healthProxyName"];
  const self_healthProxyRelationVal = formData["self_healthProxyRelation"];
  const self_healthProxyCommentVal = formData["self_healthProxyComment"];

  const self_powerAttnyVal = setOptionsSelectedYesNoDontKnow(
    formData["self_powerAttny"],
  );
  const self_powerAttnyNameVal = formData["self_powerAttnyName"];
  const self_powerAttnyRelationVal = formData["self_powerAttnyRelation"];
  const self_powerAttnyCommentVal = formData["self_powerAttnyComment"];

  const self_advDirectVal = getOptionsSelectedYesNoDontKnow(
    formData["self_advDirect"],
  );
  const self_advDirectKeptVal = formData["self_advDirectKept"];
  const self_advDirectCommentVal = formData["self_advDirectComment"];

  const self_eduAdvPlanVal = formData["self_eduAdvPlan"];
  const self_eduAdvPlanCommentVal = formData["self_eduAdvPlanComment"];

  const self_declineDiscVal = formData["self_declineDisc"];
  const self_declineDiscCommentVal = formData["self_declineDiscComment"];

  const self_foodRunOutCommentVal = formData["self_foodRunOutComment"];
  const self_foodBoughtCommentVal = formData["self_foodBoughtComment"];

  const self_RecommendationSelected = formData["self_Recommendations"];

  const self_RecommendationsVal = setSelectedCheckboxes(
    self_RecommendationSelected,
    [
      "self_recoSmokTobaccoVal",
      "self_recoSusAbuseVal",
      "self_recoDurPowerVal",
      "self_recoHealthProxyVal",
      "self_recoAdvDirVal",
      "self_recoFoodDispVal",
      "self_recoLiteracyVal",
      "self_recoSocSuprtVal",
    ],
    [
      "smokingOrTobacco",
      "substanceAbuse",
      "durablePower",
      "healthcareProxy",
      "advancedDirective",
      "foodDisparity",
      "literacy",
      "socialSupport",
    ],
  );

  const self_RecommendationssmokingOrTobaccoCommentVal =
    formData["self_RecommendationssmokingOrTobaccoComment"];
  const self_RecommendationssubstanceAbuseCommentVal =
    formData["self_RecommendationssubstanceAbuseComment"];
  const self_RecommendationsdurablePowerCommentVal =
    formData["self_RecommendationsdurablePowerComment"];
  const self_RecommendationshealthcareProxyCommentVal =
    formData["self_RecommendationshealthcareProxyComment"];
  const self_RecommendationsadvancedDirectiveCommentVal =
    formData["self_RecommendationsadvancedDirectiveComment"];
  const self_RecommendationsfoodDisparityCommentVal =
    formData["self_RecommendationsfoodDisparityComment"];
  const self_RecommendationsliteracyCommentVal =
    formData["self_RecommendationsliteracyComment"];
  const self_RecommendationssocialSupportCommentVal =
    formData["self_RecommendationssocialSupportComment"];

  tempData = {
    ...tempData,
    self_schoolCommentVal,
    self_writtenInfoCommentVal,
    self_presciptionBottleCommentVal,
    self_fillingMedicalFormCommentVal,
    self_ratYourHealthCommentVal,
    self_socialActivitiesCommentVal,
    self_currentLiveHomelessDescVal,
    self_currentLiveOtherDescVal,
    self_currentLiveCommentVal,
    self_relyOnVal,
    self_relyOnCommentVal,
    self_currentlyLiveAloneDescVal,
    self_currentlyLiveCommentVal,
    self_careGiverVal,
    self_careGiverDescVal,
    self_careGiverCommentVal,
    self_currentlyEmployVal,
    self_currentlyEmployCommentVal,
    self_interestEmployVal,
    self_interestEmployCommentVal,
    self_volunCurrentlyVal,
    self_volunCurrentlyCommentVal,
    self_recreaDrugsVal,
    self_recreaDrugsWhichVal,
    self_recreaDrugsCommentVal,
    self_healthProxyVal,
    self_healthProxyNameVal,
    self_healthProxyRelationVal,
    self_healthProxyCommentVal,
    self_powerAttnyVal,
    self_powerAttnyNameVal,
    self_powerAttnyRelationVal,
    self_powerAttnyCommentVal,
    self_advDirectVal,
    self_advDirectKeptVal,
    self_advDirectCommentVal,
    self_eduAdvPlanVal,
    self_eduAdvPlanCommentVal,
    self_declineDiscVal,
    self_declineDiscCommentVal,
    self_foodRunOutCommentVal,
    self_foodBoughtCommentVal,
    ...self_RecommendationsVal,
    self_RecommendationssmokingOrTobaccoCommentVal,
    self_RecommendationssubstanceAbuseCommentVal,
    self_RecommendationsdurablePowerCommentVal,
    self_RecommendationshealthcareProxyCommentVal,
    self_RecommendationsadvancedDirectiveCommentVal,
    self_RecommendationsfoodDisparityCommentVal,
    self_RecommendationsliteracyCommentVal,
    self_RecommendationssocialSupportCommentVal,
  };

  let payloadData = {};

  self_hashmap.forEach((questionPair) => {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
  });

  // console.log(tempData, payloadData, "self11");
  return payloadData;
};

const getWrapper = (tempData, keyArray) => {
  let temp = getOptionsSelected(tempData, keyArray);
  return temp;
};

const setWrapper = (tempData, keyArray) => {
  let temp = setOptionsSelected(tempData, keyArray);
  return temp;
};

const getOptionsSelected = (optionObject, optionArray, multiSelect = false) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption;
  if (multiSelect) {
    selectedOption = selectedVariable.map((variable) => {
      return self_optionsTreeMapObject[variable];
    });
  } else {
    selectedOption =
      self_optionsTreeMapObject[selectedVariable[0]] !== undefined
        ? self_optionsTreeMapObject[selectedVariable[0]]
        : "";
  }

  return selectedOption;
};

const setOptionsSelected = (
  optionsSelected,
  optionArray,
  multiSelect = false,
) => {
  let temp = {};

  if (multiSelect) {
    optionArray.forEach((option) => {
      temp[option] = optionsSelected.includes(self_optionsTreeMapObject[option])
        ? "1"
        : "2";
    });
  } else {
    optionArray.forEach((option) => {
      // console.log(option,optionsSelected,self_optionsTreeMapObject[option],self_optionsTreeMapObject[option]===optionsSelected,);
      temp[option] =
        self_optionsTreeMapObject[option] === optionsSelected ? "1" : "2";
    });
  }
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else {
    selectedValue = "";
  }
  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoDontKnow = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else if (optionValue === "3") {
    selectedValue = "dontKnow";
  } else {
    selectedValue = "";
  }
  return selectedValue;
};

const setOptionsSelectedYesNoDontKnow = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else if (optionValue === "dontKnow") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

const setSelectedCheckboxes = (optionArr, optionKeyArr, optionValueArr) => {
  let temp = {};

  optionValueArr.forEach((ele, index) => {
    if (optionArr.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};
