import "../../Login/login.css";
import { useEffect, useState } from "react";
import BG from "../../../assets/focuscares_bg.jpg";
import Logo from "../../../assets/focuscares_logo.png";
import EmailIcon from "@mui/icons-material/Email";
import Image from "../../../components/image/image";
import Input from "../../../components/input/input";
import Button from "../../../components/button/button";
import Toaster from "../../../components/toaster/toaster";
import { pink, yellow } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ValidationMessage from "../../../resource_files/ValidationMessage.resx";
import Labels from "../../../resource_files/Labels.resx";
import { postApi } from "../../../utils/axiosApiWrapper";
import { isValidEmail } from "../../../components/utils";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const ForgotPassword = (props) => {
  const [mail, setMail] = useState("");
  const [errors, setErrors] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState("");
  const [toasterMessage, setToasterMessage] = useState("");
  const [isMailId, setIsMailId] = useState(false); //Check reset type is mail id

  const navigate = useNavigate();

  const clearInput = () => {
    setMail("");
    setErrors(null); // Clear any error messages
    setIsValid(false); // Reset validity status
  };

  const mailHandleChange = (event) => {
    if (!event.target.value) {
      setErrors(
        isMailId
          ? ValidationMessage.Email_Required
          : ValidationMessage.Username_Required,
      ); //validation for mail id & username
      setIsValid(false);
    } else {
      setErrors(null);
      setIsValid(true);
    }

    setMail(event.target.value);
  };

  const handleCancelButton = async () => {
    try {
      await axios.get(`/`);

      navigate(`/`);
    } catch (error) {
      return;
    }
  };

  const sendOTP = async () => {
    if (!mail) {
      setErrors(
        isMailId
          ? ValidationMessage.Email_Required
          : ValidationMessage.Username_Required,
      ); //validation for mail id & username
      return;
    } else if (isMailId && !isValidEmail(mail)) {
      ////validation for mail id
      setErrors(ValidationMessage.Email_Format_Invalid);
      return;
    }

    try {
      const response = await postApi("/users/updatePassword/forgot/sendOTP", {
        email: mail, // Use the entered email as receiver's email
      });

      if (response.status === 200) {
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        setToasterOpen(true);
        sessionStorage.setItem("emailId", mail);
        sessionStorage.setItem("otpType", "forgotPassword");
        navigate(`/Account/Otp`, {
          state: { username: mail, fromForgotPassword: true },
        });
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(
          isMailId ? response.response.data.message : "Username doesn't exist",
        );
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isValid) {
      sendOTP();
    }
  };

  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      pinky: palette.augmentColor({ color: pink }),
      summer: palette.augmentColor({ color: yellow }),
    },
  });
  return (
    <div className="login">
      <div className="top-ripple"></div>
      <div className="bottom-ripple"></div>
      <div className="image-container" style={{ position: "relative" }}>
        <Image imgClass="bgImg" imgPath={BG} imgName="Focuscare Background" />
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            backgroundColor: "rgba(237, 142, 34, .8)",
            height: "auto",
            padding: "2px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2 style={{ color: "#fff", fontSize: "2.5rem", fontWeight: 500 }}>
            HEALTHCARE MADE PERSONAL
          </h2>
        </div>
      </div>
      <div className="input-container">
        <Image imgClass="logoImg" imgPath={Logo} imageName="Focuscare Logo" />
        <h2
          style={{
            fontSize: "19px",
            marginTop: "5px",
            marginBottom: "14px",
            color: "#1076BC",
          }}
        >
          FORGOT PASSWORD
        </h2>
        <ThemeProvider theme={theme}></ThemeProvider>
        <form className="form" onSubmit={onSubmit}>
          <div>
            {" "}
            <Input
              inputtype="iconInput"
              name="Mail"
              value={mail}
              inputIcon={isMailId ? <EmailIcon /> : <PersonIcon />} //change icon for username & mail id
              onChangeHandler={mailHandleChange}
              mb="30px"
              placeholder={isMailId ? "Mail Id" : "User Name"} //change placeholder value for username & mail id
              inputWidth="350px"
              width="420px"
              height="55px"
            />
            {errors && (
              <h3 className="errorStyle" style={{ color: "#d32f2f" }}>
                {errors}
              </h3>
            )}
            {/* Addd Radio buttons for choosing mail id or username */}
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              row
              value={isMailId}
              onChange={() => setIsMailId(!isMailId)}
            >
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="User Name"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Mail ID"
              />
            </RadioGroup>
          </div>

          <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
            <Button
              width="200px"
              onClick={handleCancelButton}
              cursor="pointer"
              mb="20px"
              height="40px"
              color="white"
              background="#1076BC"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              CANCEL
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                sendOTP();
              }}
              width="200px"
              cursor="pointer"
              mb="20px"
              height="40px"
              color="white"
              background="#1076BC"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              SUBMIT
            </Button>
          </div>
        </form>
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
};

export default ForgotPassword;
