// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-card {
  border-radius: 20px;
  background: var(--colorWhite);
  padding: 20px;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
}

.full-calendar {
  font-family: Inter, sans-serif;
}
.full-calendar .fc .fc-button-primary {
  height: 40px;
  vertical-align: middle;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: var(--colorWhite);
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 166.667% */
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}
.full-calendar .fc .fc-button-primary.fc-button-active {
  background-color: var(--mainTheme);
}
.full-calendar .fc .fc-toolbar-title {
  font-size: 16px;
}
.full-calendar tr {
  background-color: var(--colorWhite);
  border: 1px solid #cfd3db;
}`, "",{"version":3,"sources":["webpack://./src/pages/NurseDashboard/nurse.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,6BAAA;EACA,aAAA;EACA,qDAAA;AADF;;AAGA;EACE,8BAAA;AAAF;AAEI;EACE,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,oCAAA;EACA,mCAAA;EACA,yBAAA;EACA,kBAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,kDAAA;AAAN;AACM;EACE,kCAAA;AACR;AAEI;EACE,eAAA;AAAN;AAGE;EACE,mCAAA;EACA,yBAAA;AADJ","sourcesContent":["//Author: Sowmiya\r\n//page: calendar page style\r\n.calendar-card {\r\n  border-radius: 20px;\r\n  background: var(--colorWhite);\r\n  padding: 20px;\r\n  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);\r\n}\r\n.full-calendar {\r\n  font-family: Inter, sans-serif;\r\n  .fc {\r\n    .fc-button-primary {\r\n      height: 40px;\r\n      vertical-align: middle;\r\n      border-radius: 20px;\r\n      border: 2px solid rgba(0, 0, 0, 0.3);\r\n      background-color: var(--colorWhite);\r\n      color: rgba(0, 0, 0, 0.7);\r\n      text-align: center;\r\n      font-family: Inter, sans-serif;\r\n      font-size: 12px;\r\n      font-style: normal;\r\n      font-weight: 900;\r\n      line-height: 20px; /* 166.667% */\r\n      box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);\r\n      &.fc-button-active {\r\n        background-color: var(--mainTheme);\r\n      }\r\n    }\r\n    .fc-toolbar-title {\r\n      font-size: 16px;\r\n    }\r\n  }\r\n  tr {\r\n    background-color: var(--colorWhite);\r\n    border: 1px solid #cfd3db;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
