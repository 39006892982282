import React, { useState } from "react";
//import { Calendar, momentLocalizer } from "react-big-calendar";
import BigCalendar from "react-big-calendar";
import moment from "moment";
//import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css';
//import "/react-big-calendar/lib/css/react-big-calendar.css";

//import '../lib/css/react-big-calendar.css'

moment.locale("en-GB");
const localizer = BigCalendar.momentLocalizer(moment);

export default function ReactBigCalendar({
  eventsData,
  setEventsData,
  setMonthlyAvailability,
}) {
  // const [eventsData, setEventsData] = useState([]);

  const handleSelect = ({ start, end }) => {
    console.log(start);
    console.log(end);
    const maxLoad = window.prompt("Max Load Case");
    const miles = window.prompt("Miles Travel Range");
    const comment = window.prompt("Comment");

    const _id = `eventId${Math.random()}`;
    if (comment)
      setEventsData([
        ...eventsData,
        {
          start,
          end,
          comment,
          maxLoad,
          miles,
          _id,
        },
      ]);
    setMonthlyAvailability([
      ...eventsData,
      {
        start,
        end,
        comment,
        maxLoad,
        miles,
        _id,
      },
    ]);
  };
  const handleEventClick = (event) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the event?`,
    );
    if (confirmDelete) {
      setEventsData((prev) => prev.filter((e) => e?._id !== event?._id));
    }
  };
  return (
    <div className="App">
      <BigCalendar
        views={["day", "agenda", "work_week", "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        style={{ height: "100vh" }}
        //onSelectEvent={(event) => alert(event.title)}
        onSelectEvent={(event) => handleEventClick(event)}
        onSelectSlot={handleSelect}
      />
    </div>
  );
}
