import { useState, useContext, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { Typography, Fade, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//import { getApi, postApi } from "../../utils/axiosApiWrapper";
import { postApi } from "../../utils/axiosApiWrapper";
//import "./Dialog.css";
import Button from "../../components/button/button";

export default function DigitalSignatureDialog({
  open,
  onCloseSignatureDialogBox,
}) {
  const [signType, setSignType] = React.useState("Type My Signature");

  const [name, setName] = useState("");
  const [signStyle, setSignStyle] = useState(1);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const canvasImageRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  useEffect(() => {
    const canvas = canvasRef?.current; //document.getElementById("drawSignCanvasTwo"); //
    if (canvas) {
      canvas.width = window.innerWidth * 0.3;
      canvas.height = window.innerHeight * 0.3;

      const context = canvas.getContext("2d");
      context.lineCap = "round";
      context.strokeStyle = "black";
      context.lineWidth = 5;

      contextRef.current = context;
      setDisableSubmitBtn(false);
    }
  }, [signType]);

  const handleMouseDown = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    contextRef?.current?.beginPath();
    contextRef?.current?.moveTo(offsetX, offsetY);
    setDisableSubmitBtn(false);
  };

  const handleMouseMove = (event) => {
    if (event.buttons !== 1) return;

    const { offsetX, offsetY } = event.nativeEvent;
    contextRef?.current?.lineTo(offsetX, offsetY);
    contextRef?.current?.stroke();
    setDisableSubmitBtn(false);
  };

  const eraseCanvas = () => {
    setDisableSubmitBtn(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // Clear the entire canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };
  const handleChange = (event) => {
    setSignType(event.target.value);
  };
  const changeStyle = () => {
    if (signStyle < 3) {
      setSignStyle(signStyle + 1);
    } else {
      setSignStyle(1);
    }
  };
  useEffect(() => {
    const rowsArray = name.split("\n");
    if (name?.length > 0) {
      updateCanvas(rowsArray);
    }
  }, [signStyle]);
  const onChangeSignText = (e) => {
    const name = e.target.value;
    const rowsArray = name.split("\n");

    setName(e.target.value);
    updateCanvas(rowsArray);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace") {
        // Handle backspace press (clear the canvas or update as needed)
        clearCanvas();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [name]);

  const clearCanvas = () => {
    const canvas = document.getElementById("drawSignText");
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const updateCanvas = (rowsArray) => {
    let fontFamily = "";
    if (signStyle == 1) {
      fontFamily = "Damion";
    } else if (signStyle == 2) {
      fontFamily = "Caveat";
    } else if (signStyle == 3) {
      fontFamily = "Lato";
    }
    const canvas = document.getElementById("drawSignText");
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "black";
    context.font = `30px ${fontFamily}`;
    let y = 40;
    let lh = 30;
    let textH = y;
    rowsArray.map((row) => {
      context.fillText(row, 10, textH);
      textH = textH + lh;
    });

    setDisableSubmitBtn(false);
  };
  useEffect(() => {
    const canvas = canvasImageRef?.current;
    const context = canvas?.getContext("2d");
    const image = new Image();

    // Replace 'path/to/your/image.jpg' with the actual path to your image
    image.src = previewImage;

    image.onload = () => {
      // Draw the image on the canvas
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      setDisableSubmitBtn(false);
    };
  }, [previewImage]);
  const submitSign = async () => {
    if (signType === "Type My Signature") {
      const textCanvas = document.getElementById("drawSignText");
      const dataUrl = textCanvas.toDataURL();
      const response = await postApi("/provider/digitalSignature", {
        type: 1,
        name: name,
        signature: dataUrl,
      });
      onCloseSignatureDialogBox();
    } else if (signType === "Use an Image") {
      const textCanvas = document.getElementById("imageCanvas");
      const dataUrl = textCanvas.toDataURL();
      const response = await postApi("/provider/digitalSignature", {
        type: 3,
        name: name,
        signature: dataUrl,
      });
      onCloseSignatureDialogBox();
    } else {
      const textCanvas = document.getElementById("drawSignCanvasTwo");
      const dataUrl = textCanvas.toDataURL();
      const response = await postApi("/provider/digitalSignature", {
        type: 2,
        name: name,
        signature: dataUrl,
      });
      onCloseSignatureDialogBox();
    }
  };
  const onCloseDialogBox = () => {
    setSignType("Type My Signature");
    setDisableSubmitBtn(true);
    setName("");
    setSelectedFile(null);
    setPreviewImage(null);
    onCloseSignatureDialogBox();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCloseDialogBox}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            maxHeight: "500px", // Adjust the maxHeight to decrease the height
            width: "650px",
          },
        }}
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="alert-dialog-title"
        >
          Digital Signature
          <div
            style={{ color: "white", cursor: "pointer", marginTop: "10px" }}
            onClick={onCloseSignatureDialogBox}
          >
            <Close />
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            How would you like to create your signature
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={signType}
              label=""
              onChange={handleChange}
              sx={{
                border: "1px solid var(--mainTheme) !important",
              }}
            >
              <MenuItem value="Type My Signature">Type My Signature</MenuItem>
              <MenuItem value="Draw my Signature">Draw my Signature</MenuItem>
              <MenuItem value="Use an Image">Use an Image</MenuItem>
            </Select>
          </FormControl>
          <Fade in={signType === "Type My Signature"} unmountOnExit>
            <Box>
              <Box paddingY={1} width={0.5}>
                <Typography variant="body2">Enter Your Name:</Typography>
                <TextField
                  fullWidth
                  // onChange={(e) => {
                  //   setName(e.target.value);
                  //   setUrSign(e.target.value);
                  // }}
                  value={name}
                  multiline
                  onChange={onChangeSignText}
                  sx={{
                    border: "1px solid var(--mainTheme) !important",
                  }}
                />
              </Box>
              <Box
                paddingY={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="body2">
                    Review Your Signature:
                  </Typography>
                  <Box
                    sx={{
                      border: "1px solid var(--mainTheme) !important",
                    }}
                  >
                    <canvas
                      style={{
                        border: "1px solid var(--mainTheme) !important",
                      }}
                      width="320"
                      height="100"
                      border="1px solid var(--mainTheme) !important"
                      id="drawSignText"
                    ></canvas>
                  </Box>
                </Box>
                <Box width={0.3}>
                  <Typography sx={{ paddingLeft: "15px" }} variant="body2">
                    Style {signStyle} of 3
                  </Typography>
                  <Box
                    width={1}
                    padding={1}
                    className="filterBoxViewClinicians"
                    onClick={() => changeStyle()}
                  >
                    <p style={{ fontSize: "12px" }}>Change Signature Style</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Fade>
          <Fade in={signType === "Use an Image"} unmountOnExit>
            <Box>
              <Box paddingY={1}>
                <Typography variant="body1">
                  Upload Sign(.jpg, .png, Maximum upload size 100KB):
                </Typography>
              </Box>

              <div>
                <Box paddingY={1}>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg" // Specify the file types allowed
                    onChange={handleFileChange}
                  />
                  <p>
                    Selected File: {selectedFile ? selectedFile.name : "None"}
                  </p>
                </Box>
                <Box paddingY={1}>
                  {Boolean(previewImage) ? (
                    <canvas
                      id="imageCanvas"
                      ref={canvasImageRef}
                      width={350}
                      height={130}
                      style={{ border: "1px solid #ccc" }}
                    />
                  ) : null}
                </Box>
              </div>
            </Box>
          </Fade>

          <Fade in={signType === "Draw my Signature"} unmountOnExit>
            <Box display="flex">
              <Box width={0.7}>
                <div>
                  <p>Draw Your Signature:</p>
                  {/* <canvas
                    ref={canvasRef}
                    width={250}
                    height={250}
                    id="drawSignCanvas"
                    style={{ border: "1px solid #000" }}
                  /> */}
                  <canvas
                    ref={canvasRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    style={{ border: "1px solid #ccc" }}
                    id="drawSignCanvasTwo"
                  />
                </div>
              </Box>
              <Box
                marginTop={4}
                width={0.3}
                display="flex"
                justifyContent="left"
                onClick={eraseCanvas}
                style={{ cursor: "pointer" }}
              >
                <Typography>Clear Signature</Typography>
              </Box>
            </Box>
          </Fade>
          <Box display="flex" justifyContent="flex-end" width={1} paddingY={1}>
            <Button disabled={disableSubmitBtn} onClick={submitSign}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
