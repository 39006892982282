import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MemberUrgentReferralForm from "./MemberUrgentReferralForm";
import MemberNonUrgentReferralForm from "./MemberNonUrgentReferralForm";
import MemberCriticalReferralForm from "./MemberCriticalReferralForm";
import { Box, Divider, TextField } from "@mui/material";
import { postApi } from "../../../../utils/axiosApiWrapper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiPaper-root": {
    width: "80%", // Change this to the desired width
    maxWidth: "none", // Prevents the dialog from shrinking
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ClinicianReviewReferral({
  open,
  onClose,
  selectedID,
  selectedSpecialist,
  patientName,
  referralData,
  assessId,
}) {
  const [selectedValue, setSelectedValue] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsButtonDisabled(textFieldValue.trim() === "");
  }, [textFieldValue]);

  useEffect(() => {
    if (open) {
      setTextFieldValue(""); // Clear text field when dialog opens
    }
  }, [open]);

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const formatDateAndTime = (_date) => {
    if (!_date) return ""; // Return empty string if no date
    const date = new Date(_date);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hours24 = date.getUTCHours();
    const amPm = hours24 >= 12 ? "PM" : "AM";
    const hours = hours24 % 12 || 12;
    const minutes = date.getUTCMinutes();

    const dateStr = `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;
    const timeStr = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;
    return `${dateStr}  ${timeStr}`;
  };

  const handleReSubmit = async () => {
    try {
      //   const assessmentId = sessionStorage.getItem("assessmentId");

      const payload = {
        id: assessId,
        qwery: textFieldValue,
      };
      console.log(payload);
      const response = await postApi("/referral-form/query", payload);
      if (response.status === 200) {
        setTextFieldValue("");
        onClose();
      } else {
        console.error("Error: Failed to fetch PDF data.");

        // toast.error(response?.response?.data?.message || "Error while fetching data");
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "600",
        }}
        onClose={onClose}
      >
        Review Referral Form
      </BootstrapDialogTitle>
      <DialogContent>
        {selectedValue === "URGENT" && <MemberUrgentReferralForm />}
        {selectedValue === "NON-URGENT" && <MemberNonUrgentReferralForm />}
        {selectedValue === "Critical Incident Report" && (
          <MemberCriticalReferralForm />
        )}
      </DialogContent>
      <Box style={{ padding: "20px" }}>
        <Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography>PATIENT ID: {selectedID}</Typography>
              <Typography>PATIENT NAME: {patientName} </Typography>
            </Box>
            <Box style={{ marginRight: "26%" }}>
              <Typography>
                CLINICIAN:
                {/* {assignedClinician} */}
              </Typography>
              <Typography>
                QA ANALYSTdddddddddddd: {selectedSpecialist}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider
          sx={{
            marginTop: "32px",
            marginBottom: "16px",
            borderColor: "#0000004d",
            borderBottomWidth: "thin",
            width: "100%",
          }}
        />

        <Typography style={{ marginTop: "20px" }}>
          Clinician Response :
        </Typography>
        <Box style={{ border: "1px solid black", width: "75vw" }}>
          <TextField
            style={{ width: "100%", height: "200px", overflow: "scroll" }}
            multiline
            placeholder="Enter Your Response Here..."
            value={textFieldValue}
            onChange={handleTextFieldChange}
          ></TextField>
        </Box>

        <Typography style={{ marginTop: "20px" }}>
          {" "}
          Brief statement of needed query:
        </Typography>
        <Box
          style={{
            border: "1px solid black",
            height: "16vw",
            width: "75vw",
            overflowY: "scroll",
          }}
        >
          {referralData && referralData.length > 0 ? (
            referralData.map((item, index) => (
              <Box key={index} style={{ marginBottom: "16px" }}>
                <Typography>From: {item?.userId}</Typography>
                <Typography>Date: {formatDateAndTime(item.date)}</Typography>
                <Typography>{item.qwery || item}</Typography>
                <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
              </Box>
            ))
          ) : (
            <Typography> </Typography>
          )}

          {/* <Typography>Select alliant checkbox: </Typography> */}
        </Box>
      </Box>
      <DialogActions style={{ width: "100%", height: "48px" }}>
        <Button
          sx={{
            backgroundColor: "var(--mainTheme900)",
            color: "white",
            width: "100px",
            padding: "21px 11px",
            "&:hover": {
              backgroundColor: "var(--mainTheme500)",
            },
          }}
          //  autoFocus
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: "var(--mainTheme900)",
            color: "white",
            width: "100px",
            padding: "21px 11px",

            "&:hover": {
              backgroundColor: "var(--mainTheme500)",
            },
            "&.Mui-disabled ": {
              //backgroundColor: "var(--mainTheme900)",
              color: "white",
            },
          }}
          // autoFocus
          onClick={handleReSubmit}
          disabled={isButtonDisabled}
        >
          Re-Submit
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
