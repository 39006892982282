const json2023 = {
  sectionId: 4,
  sectionName: "Screenings Needed",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "microAlbumin",
      type: "options_tree",
      label: "MICROALBUMIN",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 91,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "screen_microAlbuminSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "screen_microAlbuminSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "memberRecentlyCompleted",
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "screen_microAlbuminSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "memberRefused",
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left kit",
                  value: "leftkit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              depth: 3,
              editToggle: false,
            },
            {
              id: 1,
              name: "screenmicroAlbuminTBarcode",
              type: "text",
              label: "Microalbumin Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "screenmicroAlbuminexamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              variant: "1",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "screenmicroAlbuminsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "screenmicroAlbumindiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "screenmicroAlbumincomments",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "Yes",
        },
        {
          subQuestionId: 92,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "No",
        },
      ],
      questionId: 1,
      editToggle: false,
      depth: 1,
      value: "microAlbumin",
    },
    {
      id: 6,
      name: "fobt",
      type: "options_tree",
      label: "FOBT",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "screen_fobtSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "screen_fobtSubStatusmemberRefusedReason",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "screen_fobtSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Other",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left kit",
                  value: "leftKit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfobtTBarcode",
              type: "text",
              label: "FOBT Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "screenfobtexamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfobtResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfobtdiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfobtcomment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 2,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "aOneC",
      type: "options_tree",
      label: "A1C",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "screen_aOneCSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "screenaOneCSubStatus_memberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "screen_aOneCSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left Kit",
                  value: "leftKit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCTBarcode",
              type: "text",
              label: "A1C Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "screenaOneCexamDate",
              label: "Exam date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCdiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCComment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 3,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "ldl",
      type: "options_tree",
      label: "LDL",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ldlSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ldlSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ldlSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left Kit",
                  value: "leftKit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received ",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 1,
              name: "ldlTBarcode",
              type: "text",
              label: "LDL Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "ldlexamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "ldlsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "ldldiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "ldlcomment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 4,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "retinalExam",
      type: "options_tree",
      label: "RETINAL EYE EXAM",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "retinalExamSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "retinalExamSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "retinalExamSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Exam completed",
                  value: "examCompleted",
                  type: "toggle_button",
                  name: "examCompleted",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Environmental issue",
                  value: "environmentalIssue",
                  type: "toggle_button",
                  name: "environmentalIssue",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 4,
              name: "retinalExamExamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 1,
              name: "retinalExamsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "retinalExamdiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "retinalExamComments",
              label: "Comments",
              type: "text",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 5,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "dexa",
      type: "options_tree",
      label: "DEXA",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "dexaExamSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "dexaExamSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "dexaExamSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Exam completed",
                  value: "examCompleted",
                  type: "toggle_button",
                  name: "examCompleted",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Environmental issue",
                  value: "environmentalIssue",
                  type: "toggle_button",
                  name: "environmentalIssue",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 4,
              name: "dexaExamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 1,
              name: "dexasResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "dexadiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "dexaSubStatusComment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 6,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "padVal",
      type: "options_tree",
      label: "PAD",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "padSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "padSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "padSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Exam completed",
                  value: "examCompleted",
                  type: "toggle_button",
                  name: "examCompleted",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Environmental issue",
                  value: "environmentalIssue",
                  type: "toggle_button",
                  name: "environmentalIssue",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 4,
              name: "padExamDate",
              label: "Exam date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 1,
              name: "padExamsResultLeft",
              type: "text",
              label: "PAD Testing Results (left)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 3,
              name: "peripheralResultLeft",
              label: "Results for peripheral arterial disease testing (left)",
              placeholder: "",
              editToggle: false,
              defaultValue: "",
              type: "radio",
              options: [
                ["Normal", "normal"],
                ["Mild", "mild"],
                ["Moderate", "moderate"],
                ["Significant", "siginificant"],
                ["Severe", "severe"],
              ],
              xs: 6,
              required: false,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "padExamsResultRight",
              type: "text",
              label: "PAD Testing Results (right)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 3,
              name: "peripheralResultRight",
              label: "Results for peripheral arterial disease testing (right)",
              placeholder: "",
              editToggle: false,
              defaultValue: "",
              type: "radio",
              options: [
                ["Normal", "normal"],
                ["Mild", "mild"],
                ["Moderate", "moderate"],
                ["Significant", "siginificant"],
                ["Severe", "severe"],
              ],
              xs: 6,
              required: false,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
            {
              id: 1,
              name: "padDiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 7,
              depth: 3,
            },
            {
              id: 1,
              name: "padComments",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 8,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 7,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "educated",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        [
          "Member educated on results,verbalized understanding",
          "membereducatedonresultsverbalizedunderstanding",
        ],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 8,
      depth: 1,
    },
  ],
};

const json2024 = {
  sectionId: 4,
  sectionName: "Screenings Needed",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "microAlbumin",
      type: "options_tree",
      label: "MICROALBUMIN",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 91,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "screen_microAlbuminSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "screen_microAlbuminSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "memberRecentlyCompleted",
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "screen_microAlbuminSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "memberRefused",
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left kit",
                  value: "leftkit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              depth: 3,
              editToggle: false,
            },
            {
              id: 1,
              name: "screenmicroAlbuminTBarcode",
              type: "text",
              label: "Microalbumin Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "screenmicroAlbuminexamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              variant: "1",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "screenmicroAlbuminsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "screenmicroAlbumindiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "screenmicroAlbumincomments",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "Yes",
        },
        {
          subQuestionId: 92,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "No",
        },
      ],
      questionId: 1,
      editToggle: false,
      depth: 1,
      value: "microAlbumin",
    },

    {
      id: 6,
      name: "fit",
      type: "options_tree",
      label: "FOBT",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "screenfitSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "screenfitSubStatusmemberRefusedReason",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "screenfitSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left kit",
                  value: "leftKit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfitTBarcode",
              type: "text",
              label: "FIT Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "screenfitexamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfitResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfitdiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "screenfitcomment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 2,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "aOneC",
      type: "options_tree",
      label: "A1C",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "screen_aOneCSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "screenaOneCSubStatus_memberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "screen_aOneCSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left Kit",
                  value: "leftKit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCTBarcode",
              type: "text",
              label: "A1C Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "screenaOneCexamDate",
              label: "Exam date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCdiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "screenaOneCComment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 3,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "ldl",
      type: "options_tree",
      label: "LDL",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ldlSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ldlSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ldlSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left Kit",
                  value: "leftKit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received ",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 1,
              name: "ldlTBarcode",
              type: "text",
              label: "LDL Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "ldlexamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "ldlsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "ldldiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "ldlcomment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 4,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "retinalExam",
      type: "options_tree",
      label: "RETINAL EYE EXAM",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "retinalExamSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "retinalExamSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "retinalExamSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Exam completed",
                  value: "examCompleted",
                  type: "toggle_button",
                  name: "examCompleted",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Environmental issue",
                  value: "environmentalIssue",
                  type: "toggle_button",
                  name: "environmentalIssue",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "retinalExamSubStatusresultReceivedSubAnswers",
                      type: "options_tree",
                      label: "Image",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Valid Image",
                          value: "validImage",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "validImage",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Invalid Image",
                          value: "invalidImage",
                          type: "toggle_button",
                          name: "invalidImage",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 4,
              name: "retinalExamExamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 1,
              name: "retinalExamsResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "retinalExamdiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "retinalExamComments",
              label: "Comments",
              type: "text",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 5,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "dexa",
      type: "options_tree",
      label: "DEXA",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "dexaExamSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "dexaExamSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "dexaExamSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Exam completed",
                  value: "examCompleted",
                  type: "toggle_button",
                  name: "examCompleted",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Environmental issue",
                  value: "environmentalIssue",
                  type: "toggle_button",
                  name: "environmentalIssue",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 4,
              name: "dexaExamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 1,
              name: "dexasResult",
              type: "text",
              label: "Screening Result",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 1,
              name: "dexadiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "dexaSubStatusComment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 6,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "padVal",
      type: "options_tree",
      label: "PAD",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "padSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "padSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "padSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Exam completed",
                  value: "examCompleted",
                  type: "toggle_button",
                  name: "examCompleted",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Environmental issue",
                  value: "environmentalIssue",
                  type: "toggle_button",
                  name: "environmentalIssue",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 4,
              name: "padExamDate",
              label: "Exam date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 1,
              name: "padExamsResultLeft",
              type: "text",
              label: "PAD Testing Results (left)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },
            {
              id: 3,
              name: "peripheralResultLeft",
              label: "Results for peripheral arterial disease testing (left)",
              placeholder: "",
              editToggle: false,
              defaultValue: "",
              type: "radio",
              options: [
                ["Normal", "normal"],
                ["Mild", "mild"],
                ["Moderate", "moderate"],
                ["Significant", "siginificant"],
                ["Severe", "severe"],
              ],
              xs: 6,
              required: false,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "padExamsResultRight",
              type: "text",
              label: "PAD Testing Results (right)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 3,
              name: "peripheralResultRight",
              label: "Results for peripheral arterial disease testing (right)",
              placeholder: "",
              editToggle: false,
              defaultValue: "",
              type: "radio",
              options: [
                ["Normal", "normal"],
                ["Mild", "mild"],
                ["Moderate", "moderate"],
                ["Significant", "siginificant"],
                ["Severe", "severe"],
              ],
              xs: 6,
              required: false,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
            {
              id: 7,
              name: "Ifsignificant",
              type: "label",
              label:
                "If significant or severe results notify the provider and/or CM referral for follow up",
              editToggle: false,
              xs: 12,
              required: "no",
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: false,
              questionId: 9,
            },
            {
              id: 1,
              name: "padDiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 7,
              depth: 3,
            },
            {
              id: 1,
              name: "padComments",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 8,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
        {
          id: 8,
          name: "padTestingEligibility",
          type: "button",
          label: "PAD Testing Eligibility",
          editToggle: false,
          xs: 3,
          isSingleButton: true,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          questionId: 1,
        },
      ],
      questionId: 7,
      editToggle: false,
      depth: 1,
    },

    {
      id: 6,
      name: "ked",
      type: "options_tree",
      label: "KED",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "screenkedSubStatus",
              type: "options_tree",
              label: "Status options",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "screen_kedSubStatusmemberRefused",
                      type: "options_tree",
                      label: "Reason member refused",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Member recently completed",
                          value: "memberRecentlyCompleted",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "memberRecentlyCompleted",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scheduled to complete",
                          value: "scheduledToComplete",
                          type: "toggle_button",
                          name: "scheduledToComplete",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Member apprehension",
                          value: "memberApprehension",
                          type: "toggle_button",
                          name: "memberApprehension",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Not interested",
                          value: "notInterested",
                          type: "toggle_button",
                          name: "notInterested",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "screen_kedSubStatusmemberRefusedotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Member refused",
                  value: "memberRefused",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "memberRefused",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Left kit",
                  value: "leftKit",
                  type: "toggle_button",
                  name: "leftKit",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Collected Sample",
                  value: "collectedSample",
                  type: "toggle_button",
                  name: "collectedSample",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mail Kit direct to member",
                  value: "mailKitDirectToMember",
                  type: "toggle_button",
                  name: "mailKitDirectToMember",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Result Received",
                  value: "resultReceived",
                  type: "toggle_button",
                  name: "resultReceived",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 1,
              name: "screenkedTBarcode",
              type: "text",
              label: "Test Barcode",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 2,
              depth: 3,
            },
            {
              id: 4,
              name: "screenkedexamDate",
              label: "Exam Date",
              placeholder: "",
              type: "date",
              variant: "1",
              editToggle: false,
              xs: 4,
              defaultValue: "2023-09-11",
              value: "2023-09-11",
              fullWidth: true,
              required: false,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 3,
            },

            {
              id: 1,
              name: "screenkedUrineAlbumin",
              type: "text",
              label: "Urine Albumin (mg/dl)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },

            {
              id: 1,
              name: "screenkedUrineCreatinine",
              type: "text",
              label: "Urine Creatinine (mg/dl)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 1,
              name: "screenkedUrineALB",
              type: "text",
              label: "Urine ALB/CR Ratio (mg/gCR)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 6,
              depth: 3,
            },
            {
              id: 1,
              name: "screenkedCreatinine",
              type: "text",
              label: "Creatinine (mg/dl)",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 7,
              depth: 3,
            },
            {
              id: 1,
              name: "screenkedeGFR",
              type: "text",
              label: (
                <>
                  eGFR (mL/min/1.73 m<sup>2</sup>)
                </>
              ),
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 8,
              depth: 3,
            },

            {
              id: 1,
              name: "screenkeddiagnosis",
              type: "text",
              label: "Diagnosis",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 9,
              depth: 3,
            },
            {
              id: 1,
              name: "screenkedcomment",
              type: "text",
              label: "Comments",
              editToggle: false,
              xs: 4,
              required: false,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 10,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 2,
          name: "no",
        },
      ],
      questionId: 8,
      editToggle: false,
      depth: 1,
    },
    // {
    //   id: 10,
    //   name: "educated",
    //   label: "",
    //   placeholder: "",
    //   editToggle: false,
    //   defaultValue: "",
    //   type: "checkbox",
    //   options: [
    //     [
    //       "Member educated on results,verbalized understanding",
    //       "membereducatedonresultsverbalizedunderstanding",
    //     ],
    //   ],
    //   xs: 12,
    //   required: false,
    //   isCommentEnabled: true,
    //   isConfigured: true,
    //   isModified: false,
    //   questionId: 9,
    //   depth: 1,
    // },

    // {
    //   id: 10,
    //   name: "providerNotified",
    //   label: "",
    //   placeholder: "",
    //   editToggle: false,
    //   defaultValue: "",
    //   type: "checkbox",
    //   options: [["Provider Notified", "providerNotifieds"]],
    //   xs: 12,
    //   required: false,
    //   isCommentEnabled: true,
    //   isConfigured: true,
    //   isModified: false,
    //   questionId: 10,
    //   depth: 1,
    // },

    // {
    //   id: 10,
    //   name: "cMReferral",
    //   label: "",
    //   placeholder: "",
    //   editToggle: false,
    //   defaultValue: "",
    //   type: "checkbox",
    //   options: [["CM referral sent", "cMReferral"]],
    //   xs: 12,
    //   required: false,
    //   isCommentEnabled: true,
    //   isConfigured: true,
    //   isModified: false,
    //   questionId: 11,
    //   depth: 1,
    // },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }
  return formLayoutData;
};
