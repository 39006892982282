import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  TextField,
  createMuiTheme,
  ThemeProvider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUserContext } from "../../../contexts/UserContext";
import { getApi, postApi } from "../../../utils/axiosApiWrapper";
import ConfirmDigitalSignatureDialog from "./Confirm.digital.signature";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const QueryForm = ({ open, setOpen, memberId, formData }) => {
  const [patientInfo, setPatientInfo] = useState({});
  const [queryData, setQueryData] = useState({});
  const [patientName, setPatientName] = useState("");
  const [clinicianName, setClinicianName] = useState("");
  const [patientId, setPatientId] = useState("");
  const [qaName, setQaName] = useState("");
  const [signCanvasImg, setSignCanvasImg] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [dateOfService, setDateOfService] = useState("");
  const getQueryFormData = async () => {
    const response = await getApi("/queryForm/queryFormlist", {
      // _id: memberId,
      params: {
        id: memberId,
      },
    });
    const { data } = response;
    //setPatientInfo(data?.patientInfo);
    if (data) {
      const { queryData } = data?.data?.[0]?.queryFormData;
      setQueryData(queryData);
      const _patientId = data?.data?.[0]?.patientInfo?.memberId;
      const _clinicianName = `${data?.data?.[0]?.nurseData?.[0]?.firstName} ${data?.data?.[0]?.nurseData?.[0]?.lastName}`;
      const _qaName =
        data?.data?.[0]?.qaUserData?.length > 0
          ? `${data?.data?.[0]?.qaUserData?.[0]?.firstName} ${data?.data?.[0]?.qaUserData?.[0]?.lastName}`
          : undefined;
      const _signCanvasImg = data?.data?.[0]?.signature?.[0]?.signature;
      const _speciality = data?.data?.[0]?.nurseData?.[0]?.speciality;
      setPatientId(_patientId);
      setClinicianName(_clinicianName);
      setQaName(_qaName);
      setSignCanvasImg(_signCanvasImg);
      setSpeciality(_speciality);
      setDateOfService(data?.data?.[0]?.patientInfo?.scheduleDate);
    }
  };
  useEffect(() => {
    if (open) {
      const { firstName, lastName, middleName } = formData;
      setPatientName(`${firstName} ${middleName} ${lastName}`);
      getQueryFormData();
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      comments: "",
    },
  });
  const { user } = useUserContext();
  const [openConfirmDigitalSign, setOpenConfirmDigitalSign] = useState(false);
  const [queryDataForClinician, setQueryDataForClinician] = useState();
  const navigate = useNavigate();
  const { roleId } = user;
  const onCloseConfirmDigitalSignatureDialogBox = () => {
    setOpenConfirmDigitalSign(false);
  };
  const onSubmitComment = async (data) => {
    if (roleId == 5) {
      setOpenConfirmDigitalSign(true);
      setQueryDataForClinician(data);
    } else {
      const response = await postApi("/queryForm/add", {
        //   _id: "65799d265b5cf5633f54a7fb",
        id: memberId,
        queryData: [{ date: new Date(), comment: data.comments, files: [] }],
      });
      if (response) {
        reset();
        getQueryFormData();
        setTimeout(() => {
          handleClose();

          //navigate(user.homePage);
        }, [2000]);
      }
    }
  };
  const onSubmitCommentForClinician = async (confirmsignature) => {
    const response = await postApi("/queryForm/add", {
      //   _id: "65799d265b5cf5633f54a7fb",
      id: memberId,
      queryData: [
        {
          date: new Date(),
          comment: queryDataForClinician.comments,
          files: [],
        },
      ],
      SignatureData: {
        date: new Date(),
        signature: confirmsignature,
      },
    });
    if (response) {
      reset();
      getQueryFormData();
      setTimeout(() => {
        handleClose();
        setOpenConfirmDigitalSign(false);
        //navigate(user.homePage);
      }, [2000]);
    }
  };
  const onBack = () => {
    onCloseConfirmDigitalSignatureDialogBox();
    setOpen(true);
  };
  const leftBorderStyle = {
    borderTopLeftRadius: "8px", // Adjust the radius as needed
    borderBottomLeftRadius: "8px",
    // You can adjust the radius as needed
  };
  const rightBorderStyle = {
    borderTopRightRadius: "8px", // Adjust the radius as needed
    borderBottomRightRadius: "8px",
  };
  const formatDateToYYYYMMDD = (_date) => {
    const date = new Date(_date);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add 1 because months are 0-indexed, and pad with leading zero
    const day = date.getDate().toString().padStart(2, "0"); // Pad with leading zero

    return `${year}-${month}-${day}`;
  };

  return (
    <>
      {openConfirmDigitalSign ? (
        <ConfirmDigitalSignatureDialog
          openConfirmDigitalSign={openConfirmDigitalSign || open}
          onCloseConfirmDigitalSignatureDialogBox={
            onCloseConfirmDigitalSignatureDialogBox
          }
          patientInfo={patientInfo}
          onSubmitCommentForClinician={onSubmitCommentForClinician}
          onBack={onBack}
          clinicianName={clinicianName}
          signCanvasImg={signCanvasImg}
          speciality={speciality}
        />
      ) : (
        <Dialog
          // fullScreen
          maxWidth={800}
          fullWidth
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              background: "var(--mainTheme)",
              fontSize: "20px",
              color: "white",
              fontFamily: "Inter,sans-serif",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            id="alert-dialog-title"
          >
            Query Form
            <div
              style={{ color: "white", cursor: "pointer", marginTop: "10px" }}
              onClick={handleClose}
            >
              <Close />
            </div>
          </DialogTitle>
          <DialogContent>
            <Box padding={2}>
              <Grid container spacing={2}>
                {/* First Row */}
                <Grid item xs={2}>
                  <Box display="flex">
                    <Box width={0.6} justifyContent="left">
                      <Typography variant="h6" fontWeight="bolder">
                        Patient Id :
                      </Typography>
                    </Box>
                    <Box
                      width={0.4}
                      justifyContent="right"
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="body1">{patientId || ""}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex">
                    <Box width={0.4} justifyContent="left">
                      <Typography variant="h6" fontWeight="bolder">
                        Clinician :
                      </Typography>
                    </Box>
                    <Box
                      width={0.6}
                      justifyContent="left"
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        {clinicianName || "NA"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex">
                    <Box width={0.5} justifyContent="left">
                      <Typography variant="h6" fontWeight="bolder">
                        QA Analyst :
                      </Typography>
                    </Box>
                    <Box
                      width={0.5}
                      justifyContent="left"
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        {qaName ? qaName : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box display="flex">
                    <Box width={0.4} justifyContent="left">
                      <Typography variant="h6" fontWeight="bolder">
                        Patient Name :
                      </Typography>
                    </Box>
                    <Box
                      width={0.6}
                      justifyContent="left"
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        {patientName ? patientName : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex">
                    <Box width={0.5} justifyContent="left">
                      <Typography variant="h6" fontWeight="bolder">
                        Date Of Service :
                      </Typography>
                    </Box>
                    <Box
                      width={0.5}
                      justifyContent="left"
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        {dateOfService
                          ? formatDateToYYYYMMDD(dateOfService)
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Box>
            <Box padding={2}>
              <Grid container spacing={1}>
                <Grid
                  item
                  sm={8}
                  md={8}
                  xs={12}
                  // style={{ backgroundColor: "#b3dffc", padding: "10px" }}
                  style={leftBorderStyle}
                  sx={{
                    backgroundColor: `var(--mainTheme250) !important`,
                    padding: "10px",
                  }}
                >
                  {queryData?.length > 0 &&
                    queryData?.map((query, cmtIndex) => (
                      <Box
                        key={`cmt_${cmtIndex}_plkjs`}
                        display="flex"
                        marginTop={2}
                      >
                        <Box width={0.1} display="flex" justifyContent="center">
                          <Avatar
                            style={{
                              marginTop: "10px",
                            }}
                            sx={{ background: "var(--mainTheme750)" }}
                            alt={query.name}
                            src="/static/images/avatar/1.jpg"
                          />
                        </Box>
                        <Box width={0.9}>
                          <Paper
                            elevation={7}
                            //style={{ backgroundColor: "aliceblue" }}
                          >
                            <Box padding={1}>
                              <Box display="flex" width={1} padding={1}>
                                <Box
                                  display="flex"
                                  justifyContent="left"
                                  width={0.5}
                                  alignItems="center"
                                >
                                  <Typography
                                    // variant="h4"
                                    fontWeight="bolder"
                                    variant="h6"
                                  >
                                    {query.name}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="right"
                                  width={0.5}
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="body2"
                                    fontWeight="bolder"
                                  >
                                    {moment(new Date(query.date)).format(
                                      "MMM D, YYYY HH:mm",
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box paddingX={1}>
                                <Typography variant="body1">
                                  {query.comment}
                                </Typography>
                              </Box>
                            </Box>
                          </Paper>
                        </Box>
                      </Box>
                    ))}
                </Grid>
                <Grid
                  item
                  sm={4}
                  md={4}
                  xs={12}
                  // style={{ backgroundColor: "#b3dffc" }}
                  style={rightBorderStyle}
                  sx={{
                    backgroundColor: `var(--mainTheme350) !important`,
                  }}
                >
                  {/* below  is using UseForm hooks */}
                  {/* <Box paddingX={2}>
              <Typography>Enter Brief statement of needed query:</Typography>
            </Box> */}
                  <Box padding={2}>
                    <form onSubmit={handleSubmit(onSubmitComment)}>
                      <TextField
                        id="queryFormTextBox"
                        multiline
                        size="lg"
                        minRows={2}
                        style={{ height: "200px" }}
                        inputProps={{
                          style: { fontSize: "1.8rem", height: 350 },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "transparent !important",
                            color: "transparent",
                          },
                        }}
                        label={
                          <Box sx={{ backgroundColor: "transparent" }}>
                            <Typography>Write Your Comments</Typography>
                          </Box>
                        }
                        sx={{
                          border: "1px solid var(--mainTheme) !important",
                          borderRadius: "5px",
                          "& .MuiInputLabel-root": {
                            color: "var(--mainTheme) !important",
                            backgroundColor: "var(--mainTheme350) !important",
                          },
                        }}
                        {...register("comments", {
                          required: {
                            value: true,
                            message: "🤷‍♂️ Enter Your Comments 🤷‍♀️",
                          },
                        })}
                      />
                      {errors.fullName &&
                      errors.fullName.type === "required" ? (
                        <p className="mt-2 mb-2">{errors.fullName.message}</p>
                      ) : (
                        <></>
                      )}
                      <Box paddingY={2} display="flex" justifyContent="right">
                        <Button
                          sx={{
                            width: "131px",
                            height: "32px",
                            flexShrink: "0",
                            borderRadius: "20px",
                            background: "var(--mainTheme)",
                            color: "white",
                            "&:hover": {
                              boxShadow: 8,
                              color: "var(--mainTheme)",
                            },
                          }}
                          disabled={
                            // roleId !== 5 &&
                            // roleId !== 6 &&
                            // roleId !== 7 &&
                            // roleId !== 8 &&
                            // roleId !== 9
                            roleId == 1 || roleId == 2
                          }
                          variant="outlined"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default QueryForm;
