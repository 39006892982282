import {
  AppBar,
  Box,
  Button,
  Card,
  CardHeader,
  Drawer,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import { useInView } from "react-intersection-observer";
import { BrowserRouter, useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import "../../../../components/Dashboard/DynamicFormMUI/Tables.css";
import MicNoneIcon from "@mui/icons-material/MicNone";
import StopIcon from "@mui/icons-material/Stop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import toast from "react-hot-toast";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import {
  CardSections,
  HeaderCard,
} from "../../../../components/Dashboard/DynamicFormMUI/customFields";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";
import { initialWordList } from "../../../../components/Dashboard/DynamicFormMUI/staticForm";
import { useUserContext } from "../../../../contexts/UserContext";
import Labels from "../../../../resource_files/Labels.resx";
import { putApi } from "../../../../utils/axiosApiWrapper";
import { getObjectData } from "../../../../utils/helpers.DynamicForm";
import Toaster from "../../../toaster/toaster";
import QueryForm from "./QueryForm";
import Activities from "./Sections/Activities/Activities";
import Allergies from "./Sections/Allergies/allergies";
import CovidScreening from "./Sections/CovidScreening/CovidScreening";
import Demographics from "./Sections/Demographics/Demographics";
import ExamReview from "./Sections/ExamReview/ExamReview";
import FamilyHistory from "./Sections/FamilyHistory/FamilyHistory";
import HomeSafety from "./Sections/HomeSafety/HomeSafety";
import MedicalHistory from "./Sections/MedicalHistory/medicalHistory";
import MiniCog from "./Sections/Minicog/Mini-cog";
import Pain from "./Sections/Pain/Pain";
import PatientSummary from "./Sections/PatientSummary/PatientSummary";
import ROS from "./Sections/ROS/ROS";
import Screenings from "./Sections/Screenings/Screenings";
import SelfAssess from "./Sections/SelfAssessment/SelfAssessment";
import Vital from "./Sections/Vital/Vital";
import Preventive from "./Sections/preventiveCare/preventiveCare";
import Previously from "./Sections/previouslyDocumented/previouslyDocumented";
import { getInputsData } from "./fillHraFormData";
import {
  getRosData2024,
  setRosData2024,
} from "./Sections/ROS/reviewOfSystemMapping2024";
import { getRosData, setRosData } from "./Sections/ROS/reviewOfSystemMapping";
import { setPainData } from "./Sections/Pain/painMapping";
import { setCovidScreeningData } from "./Sections/CovidScreening/covidScreeningMapping";
import { setSelfAssessData } from "./Sections/SelfAssessment/selfAssessmentMapping";
import { setfamilyData } from "./Sections/FamilyHistory/familyHistoryMapping";
import { setMedicalData } from "./Sections/MedicalHistory/medicalHistoryMapping";
import { setActivitiesData } from "./Sections/Activities/activitiesMapping";
import { setVitalData } from "./Sections/Vital/vitalMapping";
import { setMinicogData } from "./Sections/Minicog/minicogMapping";
import { setScreeningsData } from "./Sections/Screenings/screeningsMapping";
import { setExamReviewData } from "./Sections/ExamReview/examReviewMapping";
import { setHomeSafetyData } from "./Sections/HomeSafety/homeSafetyMapping";
import { setPreventiveData } from "./Sections/preventiveCare/preventiveMapping";
import { setAllergiesData } from "./Sections/Allergies/allergiesMapping";
import { setDemographicsData } from "./Sections/Demographics/demographicsMapping";
import { SaveOutlined } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { QueryFormIcon } from "../../../../assets/Icons/FillHra/QueryFormIcon";
import FillHRASummary, {
  handleDownloadPdf,
} from "./FillHraSummary/FillHraSummary";
import { useSpeech } from "../../../../contexts/SpeechContext";
import {
  generateRTTReport,
  getRTTByAssessmentId,
} from "../../../../api/realTimeScriptsApi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../../../assets/focuscares_logo.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
// import Logo from "../../../../../assets/focuscares_logo.png";
import TableComponentReferral from "../ReferralFillHra";
import CustomAudioPlayer from "./FillHraSummary/CustomAudioPlayer";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import MenuIcon from "@mui/icons-material/Menu";
import "./Sections/responsiveFillHra.css";
import CloseIcon from "@mui/icons-material/Close"; // Import the Close icon
import Draggable from "react-draggable";
import {
  TableComponentPriorAssessment,
  priorAssesmentData,
} from "./priorAssesmentsFillHra";

/* 
const ExamReviewLazy = React.lazy(() =>
  import("./Sections/ExamReview/ExamReview"),
);
const ScreeningsLazy = React.lazy(() =>
  import("./Sections/Screenings/Screenings"),
);
const HomeSafetyLazy = React.lazy(() =>
  import("./Sections/HomeSafety/HomeSafety"),
);
const CovidScreeningLazy = React.lazy(() =>
  import("./Sections/CovidScreening/CovidScreening"),
);
const ActivitiesLazy = React.lazy(() =>
  import("./Sections/Activities/Activities"),
);
const PainLazy = React.lazy(() => import("./Sections/Pain/Pain"));
const VitalLazy = React.lazy(() => import("./Sections/Vital/Vital"));
const PatientSummaryLazy = React.lazy(() =>
  import("./Sections/PatientSummary/PatientSummary"),
);
const SelfAssessLazy = React.lazy(() =>
  import("./Sections/SelfAssessment/SelfAssessment"),
);
const FamilyHistoryLazy = React.lazy(() =>
  import("./Sections/FamilyHistory/FamilyHistory"),
);
const MedicalHistoryLazy = React.lazy(() =>
  import("./Sections/MedicalHistory/medicalHistory"),
);
const AllergiesLazy = React.lazy(() =>
  import("./Sections/Allergies/allergies"),
);
const PreventiveLazy = React.lazy(() =>
  import("./Sections/preventiveCare/preventiveCare"),
);
const MiniCogLazy = React.lazy(() => import("./Sections/Minicog/Mini-cog"));
const ROSLazy = React.lazy(() => import("./Sections/ROS/ROS"));

import "../../../components/Dashboard/DynamicFormMUI/DynamicForms.css";

*/

const sectionMap2023 = {
  1: ["demographics", "Demographics"],
  2: ["previously", "Previously Documented Conditions"],
  3: ["covid", "Covid Screening"],
  4: ["self", "Self-Assessment and Social History"],
  5: ["activities", "Activities of Daily Living"],
  6: ["medical", "Medical History"],
  7: ["family", "Family History"],
  8: ["preventive", "Preventive Care"],
  9: ["allergies", "Allergies / Medications"],
  10: ["review", "Review Of Systems And Diagnoses"],
  11: ["pain", "Pain"],
  12: ["vital", "Vital Signs"],
  13: ["exam", "Exam Review"],
  14: ["screenings", "Screening Needed"],
  15: ["mini", "Mini - Cog"],
  16: ["home", "Home Safety & Personal Goals"],
  17: ["patient", "Patient Summary"],
};

const sectionMap2024 = {
  1: ["demographics", "Demographics"],
  2: ["previously", "Previously Documented Conditions"],
  3: ["covid", "Covid Screening"],
  4: ["self", "Self-Assessment and Social History"],
  5: ["activities", "Activities of Daily Living"],
  6: ["medical", "Medical History"],
  7: ["family", "Family History"],
  8: ["preventive", "Preventive Care"],
  9: ["allergies", "Allergies / Medications"],
  10: ["review", "Assessment Of Diagnoses"],
  11: ["pain", "Pain"],
  12: ["vital", "Vital Signs"],
  13: ["exam", "Exam Review"],
  14: ["screenings", "Screening Needed"],
  15: ["mini", "Mini - Cog"],
  16: ["home", "Home Safety & Personal Goals"],
  17: ["patient", "Patient Summary"],
};

const FillHraHome = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    saveSectionFormData(currSection + 1).then((resp) => {
      const statusCode = resp?.status;
      if (statusCode === 200) {
        const data = resp?.data || {};
        if (data && Object.keys(data).length > 0) {
          const toastMsg = data.message;
          toast.remove();
          if (toastMsg === "Assessment updated successfully.") {
            toast.success(toastMsg, {});
          } else {
            toast.error(toastMsg, {});
          }
        }
      }
    });
    setOpen(true);
  };

  const [sections, setSections] = useState([]);
  // const [currSection, setCurrSection] = useState(0);
  const [prevSelectedSection, setPrevSelectedSection] = useState(-1);
  // const sectionIdRef = useRef(0);
  // let sections = [];
  // let currSection = 0;
  const [isShowSection, setIsShowSection] = useState({});
  // const [toasterMessage, setToasterMessage] = useState("");
  // const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  // const [toasterOpen, setToasterOpen] = useState(false);
  const sectionRefs = sections.map(() => React.createRef());
  const [isLoading, setIsLoading] = useState(false);

  const {
    user,
    storedPlan,
    storedYear,
    autoSaveRosData,
    setAutoSaveRosData,
    autoSavePainData,
    setAutoSavePainData,
    autoSaveCovidScreeningData,
    setAutoSaveCovidScreeningData,
    autoSaveSelfAssessmentData,
    setAutoSaveSelfAssessmentData,
    autoSaveFamilyHistoryData,
    setAutoSaveFamilyHistoryData,
    autoSaveMedicalHistory,
    setAutoSaveMedicalHistory,
    autoSaveActivitiesData,
    setAutoSaveActivitiesData,
    autoSaveVitalData,
    setAutoSaveVitalData,
    autoSaveMinicogData,
    setAutoSaveMiniCogData,
    autoSaveScreeningNeeded,
    setAutoSaveScreeningNeeded,
    autoSaveExamReviewData,
    setAutoSaveExamReviewData,
    autoSaveHomeSafetyData,
    setAutoSaveHomeSafety,
    autoSavePatientData,
    setAutoSavePatientData,
    autoSavePreventiveCareData,
    setAutoSavePreventiveCareData,
    autoSaveAllergiesData,
    setAutoSaveAllergiesData,
    autoSaveDemographicsData,
    setAutoSaveDemographicsData,
    autoSavePrevDocData,
    setAutoSavePrevDocData,
    currSection,
    setCurrSection,
    toasterMessage,
    setToasterMessage,
    toasterSeverity,
    setToasterSeverity,
    toasterOpen,
    setToasterOpen,
    sideBarSectionDisable,
  } = useUserContext();
  const { roleId } = user;
  const { getAssessmentData, _memberIdForQueryForm, getAssessment } =
    useUserContext();
  const [formData, setFormData] = useState({});
  const [currentSection, setCurrentSection] = useState("");

  const location = useLocation();

  const data = [
    { year: "2024" },
    // ,
    //  document: "https://example.com/document1.pdf" },
  ];

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");

  const hraStatus = location?.state?.hraStatus
    ? location?.state?.hraStatus
    : sessionStorage.getItem("hraStatus");
  const patientName = location?.state?.patientName
    ? location?.state?.patientName
    : sessionStorage.getItem("patientName");

  const referralId = location?.state?.referralId
    ? location?.state?.referralId
    : sessionStorage.getItem("referralId");

  const memberNurseId = location?.state?.memberNurseId
    ? location?.state?.memberNurseId
    : sessionStorage.getItem("memberNurseId");

  const nursePrimary = location?.state?.nursePrimary
    ? location?.state?.nursePrimary
    : sessionStorage.getItem("nursePrimary");

  const referralDate = location?.state?.referralDate;
  const primary = location?.state?.primary;

  const refStatusForFillHra = location?.state?.refStatusForFillHra;
  const referralDateSentQaDate = location?.state?.referralDateSentQaDate;
  const referralData = location?.state?.referralData;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };
  // console.log(referralData,"referralData")
  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        // setSignSubmitDialog(false);
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        toast.remove();
        toast.success("Assessment Updated Successfully", {});
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage(response.data.message);
        // fetchMemberData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };

  const handleCardClick = (index) => {
    setIsLoading(true);
    setCurrSection(index);
    setPrevSelectedSection(currSection);
    // sectionIdRef.current = index;
    const sectionRef = sectionRefs[index];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 1500);
    }
  };

  // useEffect(() => {
  //   console.log("prevSelectedSection", prevSelectedSection);
  //   console.log("currSection", currSection);
  //   saveSectionFormData(prevSelectedSection + 1);
  // }, [currSection]);

  useEffect(() => {
    const getData = async () => {
      const data = await getInputsData(clientYear);
      setSections(data);
    };
    getData();
  }, []);

  const showQueryFormBtn = sessionStorage.getItem("showQueryFormBtn");

  // const inViewOptions = {
  //   triggerOnce: true,
  //   rootMargin: "100px",
  // };
  // const { ref: examInViewRef, inView: examInView } = useInView({
  //   ...inViewOptions,
  // });
  const examReviewRef = useRef();
  // const { ref: homeInViewRef, inView: homeInView } = useInView({
  //   ...inViewOptions,
  // });
  const homeSafetyRef = useRef();
  // const { ref: covidInViewRef, inView: covidInView } = useInView({
  //   ...inViewOptions,
  // });
  const covidScreeningRef = useRef();
  // const { ref: activitiesInViewRef, inView: activitiesInView } = useInView({
  //   ...inViewOptions,
  // });
  const activitiesRef = useRef();
  // const { ref: painInViewRef, inView: painInView } = useInView({
  //   ...inViewOptions,
  // });
  const painRef = useRef();
  // const { ref: vitalInViewRef, inView: vitalInView } = useInView({
  //   ...inViewOptions,
  // });
  const vitalRef = useRef();
  // const patientSummaryRef = useRef();
  const demographicsRef = useRef();
  // const { ref: selfInViewRef, inView: selfInView } = useInView({
  //   ...inViewOptions,
  // });
  const selfAssessRef = useRef();
  // const { ref: rosInViewRef, inView: rosInView } = useInView({
  //   ...inViewOptions,
  // });
  const rosRef = useRef();
  // const { ref: miniCogInViewRef, inView: miniCogInView } = useInView({
  //   ...inViewOptions,
  // });
  const minicogRef = useRef();
  // const { ref: familyInViewRef, inView: familyInView } = useInView({
  //   ...inViewOptions,
  // });
  const familyHistoryRef = useRef();
  // const { ref: medicalInViewRef, inView: medicalInView } = useInView({
  //   ...inViewOptions,
  // });
  const medicalRef = useRef();
  // const { ref: allergyInViewRef, inView: allergyInView } = useInView({
  //   ...inViewOptions,
  // });
  const allergiesRef = useRef();
  // const { ref: preventiveInViewRef, inView: preventiveInView } = useInView({
  //   ...inViewOptions,
  // });
  const preventiveRef = useRef();
  // const { ref: screeningsInViewRef, inView: screeningsInView } = useInView({
  //   ...inViewOptions,
  // });
  const screeningsRef = useRef();
  const previouslyRef = useRef();
  // const { ref: patientInViewRef, inView: patientInView } = useInView({
  //   ...inViewOptions,
  // });
  const patientSummaryRef = useRef();

  const handlePatientSave = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });
      let tempData = patientSummaryRef?.current?.getPayloadData();
      if (!Boolean(tempData)) {
        tempData = autoSavePatientData;
      }
      const patientData = {};

      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
        consentedToVideo:
          tempData?.visitTypeSaveBtn == 2
            ? tempData?.consentedToVideoSaveBtn
              ? tempData?.consentedToVideoSaveBtn
              : ""
            : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";
      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
        //toast.success(response?.data?.message, {});
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
        const roleId = sessionStorage.getItem("roleId");
      } else {
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          setToasterOpen(true);
          setToasterSeverity(Labels.ERROR);
          setToasterMessage("Error saving data");
        }
      }
    } catch (error) {
      return;
      // setToasterOpen(true);
      // setToasterSeverity(Labels.ERROR);
      // setToasterMessage("Error saving data");
    }
  };

  const handlePatientSave2024 = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });
      let tempData = patientSummaryRef?.current?.getPayloadData();
      if (!Boolean(tempData)) {
        tempData = autoSavePatientData;
      }
      const patientData = {};
      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";

      patientData.memberConsentedToCompleteVirtualVisit =
        tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          ? tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          : "";

      patientData.membersIdentityConfirmedWithID =
        tempData?.membersIdentityConfirmedWithIDSaveBtn
          ? tempData?.membersIdentityConfirmedWithIDSaveBtn
          : "";

      patientData.comment = tempData?.commentSaveBtn
        ? tempData?.commentSaveBtn
        : "";

      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
        const roleId = sessionStorage.getItem("roleId");
      } else {
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          setToasterOpen(true);
          setToasterSeverity(Labels.ERROR);
          setToasterMessage("Error saving data");
        }
      }
    } catch (error) {
      return;
      // setToasterOpen(true);
      // setToasterSeverity(Labels.ERROR);
      //setToasterMessage("Error saving datass");
    }
  };

  const handleSave = () => {
    if (clientYear === "2024") {
      handlePatientSave2024();
    } else {
      handlePatientSave();
    }
  };
  const cardioRef = useRef(null);
  const gastroRef = useRef(null);
  const endoRef = useRef(null);
  const genitoRef = useRef(null);
  const cancerRef = useRef(null);

  const respiratoryRef = useRef(null);
  const integumentRef = useRef(null);
  const hematologyRef = useRef(null);
  const musculoskeletalRef = useRef(null);

  const eyesRef = useRef(null);
  const earsRef = useRef(null);
  const mouthRef = useRef(null);
  const neckRef = useRef(null);
  const neuroRef = useRef(null);
  const noseRef = useRef(null);

  useEffect(() => {
    const sectionRefs = {
      respiratory: respiratoryRef,
      integument: integumentRef,
      "HEMATOLOGY / IMMUNOLOGY / INFECTIOUS DISEASE": hematologyRef,
      musculoskeletal: musculoskeletalRef,
      cardiovascular: cardioRef,
      gastrointestinal: gastroRef,
      Endocrine: endoRef,
      genitourinary: genitoRef,
      cancer: cancerRef,
      eyes: eyesRef,
      ears: earsRef,
      "mouth and throat": mouthRef,
      neck: neckRef,
      "neuro-psych": neuroRef,
      nose: noseRef,
    };

    sectionRefs[currentSection]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, [currentSection]);

  const onSectionChange = (sectionName) => {
    setAutoSaveRosData({ ...rosFormData });

    setCurrentSection((prevSection) => {
      const newSection = prevSection === sectionName ? null : sectionName;
      return newSection;
    });

    setActiveSection((prevSection) => {
      const newActive = prevSection === sectionName ? null : sectionName;
      return newActive;
    });

    setExpandedSection((prev) => (prev === sectionName ? null : sectionName));

    const sectionRef = ROSrefs[sectionName];
    if (sectionRef.current) {
      setTimeout(() => {
        sectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 50);
    }
  };

  // const onSectionChange = (sectionName) => {
  //   setAutoSaveRosData({ ...rosFormData });
  //   setCurrentSection((prevSection) =>
  //     prevSection === sectionName ? null : sectionName,
  //   );
  //   setActiveSection((prevSection) =>
  //     prevSection === sectionName ? null : sectionName,
  //   );
  //   setExpandedSection((prev) => (prev === sectionName ? null : sectionName));
  //   const sectionRef = ROSrefs[sectionName];
  //   if (sectionRef.current) {
  //     sectionRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //       inline: "nearest",
  //     });
  //   }
  // };

  const [expandedSection, setExpandedSection] = useState(null);
  const [activeSection, setActiveSection] = useState("");
  const [rosFormData, setRosFormData] = useState({});

  const ROSrefs = {
    respiratory: useRef(null),
    integument: useRef(null),
    "HEMATOLOGY / IMMUNOLOGY / INFECTIOUS DISEASE": useRef(null),
    musculoskeletal: useRef(null),
    cardiovascular: useRef(null),
    gastrointestinal: useRef(null),
    Endocrine: useRef(null),
    genitourinary: useRef(null),
    cancer: useRef(null),
    eyes: useRef(null),
    ears: useRef(null),
    "mouth and throat": useRef(null),
    neck: useRef(null),
    "neuro-psych": useRef(null),
    nose: useRef(null),
  };
  const handleToggleSection = (sectionName) => {
    setAutoSaveRosData({ ...rosFormData });
    if (expandedSection === sectionName) {
      setExpandedSection(null);
      setActiveSection(null);
      setCurrentSection(null);
    } else {
      setExpandedSection(sectionName);
      setActiveSection(sectionName);
      setCurrentSection(sectionName);
    }
  };

  const subsectionConfig = {
    integument: {
      dataImport: () =>
        import("../Fill_HRA/Sections/ROS/rosFormDataIntegument"),
    },
    "HEMATOLOGY / IMMUNOLOGY / INFECTIOUS DISEASE": {
      dataImport: () =>
        import("../Fill_HRA/Sections/ROS/rosFormDataHematology"),
    },
    musculoskeletal: {
      dataImport: () =>
        import("../Fill_HRA/Sections/ROS/rosFormDataMusculoskeletal"),
    },
    cardiovascular: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataCardio"),
    },
    respiratory: {
      dataImport: () =>
        import("../Fill_HRA/Sections/ROS/rosFormDataRespiratory"),
    },
    gastrointestinal: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataGastro"),
    },
    Endocrine: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataEndo"),
    },
    genitourinary: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataGenito"),
    },
    cancer: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataCancer"),
    },
    eyes: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataEyes"),
    },
    "mouth and throat": {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataMouth"),
    },
    ears: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataEars"),
    },
    nose: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataNose"),
    },
    neck: {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataNeck"),
    },
    "neuro-psych": {
      dataImport: () => import("../Fill_HRA/Sections/ROS/rosFormDataNeuro"),
    },
  };

  useEffect(() => {
    setAutoSaveRosData({ ...rosFormData });
  }, [rosFormData]);

  const renderSection = (sectionName, Component) => {
    const config = subsectionConfig[sectionName];

    return (
      <div
        id={sectionName}
        ref={ROSrefs[sectionName]}
        style={{
          width: "100%",
          marginTop: "5px",
          // padding: "10px",
          boxSizing: "border-box",
          borderBottom: "3px solid #ddd",
          backgroundColor:
            expandedSection === sectionName ? "#f0f0f0" : "white",
          transition: "background-color 0.3s ease",
        }}
      >
        <div
          onClick={() => handleToggleSection(sectionName)}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "4px 8px",
            borderRadius: "4px",
            backgroundColor:
              expandedSection === sectionName ? "#e0e0e0" : "transparent",
            transition: "background-color 0.3s ease",
          }}
        >
          {expandedSection === sectionName ? (
            <KeyboardControlKeyIcon style={{ marginRight: "8px" }} />
          ) : (
            <ExpandMoreIcon style={{ marginRight: "8px" }} />
          )}

          <div
            style={{
              fontWeight: 700,
              fontSize: "13px",
              textTransform: "uppercase",
              color: "#333",
              marginLeft: "8px",
            }}
          >
            {sectionName}
          </div>
        </div>

        {expandedSection === sectionName && currentSection === sectionName && (
          <Component
            currSection={10}
            getSectionDbData={getSectionDbData}
            setAutoSaveRosData={setAutoSaveRosData}
            dataImport={config.dataImport}
            subsectionName={sectionName}
            rosFormData={rosFormData}
            setRosFormData={setRosFormData}
          />
        )}
      </div>
    );
  };

  const subsectionNames = [
    "eyes",
    "ears",
    "nose",
    "mouth and throat",
    "neck",
    "respiratory",
    "cardiovascular",
    "gastrointestinal",
    "neuro-psych",
    "genitourinary",
    "musculoskeletal",
    "integument",
    "Endocrine",
    "HEMATOLOGY / IMMUNOLOGY / INFECTIOUS DISEASE",
    "cancer",
  ];

  const subSections = subsectionNames.map((name) => ({
    name,
    component: ROS,
  }));

  useEffect(() => {
    if (activeSection) {
      setAutoSaveRosData({ ...rosFormData });
    }
  }, [activeSection, rosFormData]);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      try {
        const secondApiResponse = await getApi(
          `/hra/get-assessment?id=${assessmentId}`,
        );
        getAssessment(secondApiResponse);
        console.log(secondApiResponse, "secondApiResponse");

        const dbData = secondApiResponse[`data`];
        let mashedData;
        if (clientYear === "2024") {
          mashedData =
            dbData?.data?.review?.data &&
            Object.keys(dbData.data.review.data).length > 0
              ? getRosData2024(dbData.data.review.data)
              : {};
        } else {
          mashedData =
            dbData?.data?.review?.data &&
            Object.keys(dbData.data.review.data).length > 0
              ? getRosData(dbData.data.review.data)
              : {};
        }

        setRosFormData(mashedData);

        getSectionDbData(currSection + 1);

        if ((roleId === 5 && tabId !== 3) || roleId === 1 || roleId === 2) {
          if (prevSelectedSection + 1 !== 17 && prevSelectedSection >= 0) {
            const resp = await saveSectionFormData(prevSelectedSection + 1);
            const statusCode = resp?.status;
            if (statusCode === 200) {
              const data = resp?.data || {};
              if (data && Object.keys(data).length > 0) {
                const toastMsg = data.message;
                toast.remove();
                if (toastMsg === "Assessment updated successfully.") {
                  toast.success(toastMsg, {});
                } else {
                  toast.error(toastMsg, {});
                }
              }
            }
          } else if (prevSelectedSection + 1 === 17) {
            handleSave();
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [currSection]);

  const getSectionDbData = async (sectionId) => {
    const assessmentDataFromAPI = await getAssessmentData();
    let sectionDbData = {};

    let sectionMap = {};
    if (clientYear === "2023") {
      sectionMap = { ...sectionMap2023 };
    } else if (clientYear === "2024") {
      sectionMap = { ...sectionMap2024 };
    }

    let sectionData = sectionMap[sectionId];
    let sectionKey = sectionData[0];
    let tempDbData = getObjectData(
      assessmentDataFromAPI,
      `data.data.${sectionKey}.data`,
      {},
    );

    sectionDbData = {
      ...tempDbData,
    };

    return sectionDbData;
  };
  useEffect(() => {
    getSectionDbData(currSection + 1);
  }, [currSection]);

  const saveSectionFormData = async (sectionId) => {
    try {
      let payload = {};
      let assessId = assessmentId;

      let sectionMap = {};
      if (clientYear === "2023") {
        sectionMap = { ...sectionMap2023 };
      } else if (clientYear === "2024") {
        sectionMap = { ...sectionMap2024 };
      }

      let sectionData = sectionMap[sectionId];
      let sectionName = sectionData[1];
      let tempData = {};
      if (sectionId === 1) {
        tempData = demographicsRef?.current?.getPayloadData(); //getSectionFormData(sectionData);
        if (!Boolean(tempData)) {
          tempData = autoSaveDemographicsData;
        }
      } else if (sectionId === 4) {
        tempData = selfAssessRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setSelfAssessData(autoSaveSelfAssessmentData, clientYear);
        }
      } else if (sectionId === 7) {
        tempData = familyHistoryRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setfamilyData(autoSaveFamilyHistoryData, clientYear);
        }
        const subAnswers = tempData?.familyHistory?.familyHistorySubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 6) {
        tempData = medicalRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setMedicalData(autoSaveMedicalHistory, clientYear);
        }
        const subAnswers = tempData?.specialists?.specialistsSubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 10) {
        tempData = rosRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          if (clientYear === "2024") {
            tempData = setRosData2024(autoSaveRosData);
          } else {
            tempData = setRosData(autoSaveRosData);
          }
        }
      } else if (sectionId === 14) {
        tempData = screeningsRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setScreeningsData(autoSaveScreeningNeeded, clientYear);
        }
        if (clientYear === "2024") {
          delete tempData.pad.padTestingEligibility.diabeties;
        }
      } else if (sectionId === 13) {
        tempData = examReviewRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setExamReviewData(autoSaveExamReviewData, clientYear);
        }
      } else if (sectionId === 15) {
        tempData = minicogRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setMinicogData(autoSaveMinicogData, clientYear);
        }
        const subAnswers = tempData?.threeWordRecall;

        if (
          clientYear === "2024" &&
          subAnswers &&
          typeof subAnswers.wordList === "string"
        ) {
          subAnswers.wordList = { ...initialWordList };
          if (subAnswers["0"]) {
            delete subAnswers["0"];
          }
        }

        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: "Mini - Cog",
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 16) {
        tempData = homeSafetyRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setHomeSafetyData(autoSaveHomeSafetyData, clientYear);
        }
      } else if (sectionId === 3) {
        tempData = covidScreeningRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          tempData = setCovidScreeningData(
            autoSaveCovidScreeningData,
            clientYear,
          );
        }
      } else if (sectionId === 5) {
        tempData = activitiesRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          if (clientYear == "2024") {
            tempData = autoSaveActivitiesData;
          } else {
            tempData = setActivitiesData(autoSaveActivitiesData, clientYear);
          }
        }
      } else if (sectionId === 11) {
        tempData = painRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setPainData(autoSavePainData, clientYear);
        }
      } else if (sectionId === 12) {
        tempData = vitalRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setVitalData(autoSaveVitalData, clientYear);
        }
      } else if (sectionId === 9) {
        tempData = allergiesRef?.current?.getPayloadData();
        //setAllergiesData
        if (!Boolean(tempData)) {
          tempData = setAllergiesData(autoSaveAllergiesData, clientYear);
        }
        const subAnswers = tempData?.allergies?.allergiesSubAnswers;
        for (let i = 0; i < subAnswers?.length; i++) {
          delete subAnswers[i]["id"];
        }

        const subAnswersMed = tempData?.medications?.medication;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          delete subAnswersMed[i]["id"];
          if (clientYear === "2024") {
            delete subAnswersMed[i]["prescribingPhysician"];
          }
        }
        const subAnswersCounterMed =
          tempData?.counterMedications?.counterMedicationsSubAnswers;
        for (let i = 0; i < subAnswersCounterMed?.length; i++) {
          delete subAnswersCounterMed[i]["id"];
        }
      } else if (sectionId === 17) {
        handleSave();
      } else if (sectionId === 2) {
        tempData = previouslyRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          tempData = autoSavePrevDocData; //setAllergiesData(autoSaveAllergiesData, clientYear);
        }
        if (clientYear === "2023") {
          delete tempData.suspectCodes;
        }

        const view =
          tempData?.viewSubAnswers?.length > 0
            ? "1"
            : "2" || tempData?.suspectCodes?.length > 0
            ? "1"
            : "2";
        tempData = {
          ...tempData,
          view,
        };
        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: sectionName,
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 8) {
        tempData = preventiveRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setPreventiveData(autoSavePreventiveCareData, clientYear);
        }
        const subAnswersMed =
          tempData?.preventiveCare?.pastThreeYearsSubAnswers;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          if (clientYear === "2024") {
            delete subAnswersMed[i]["educationCompleted"];
          }
        }
        if (clientYear === "2024") {
          delete tempData?.recommendations;
        }
      }

      payload = {
        id: assessId,
        year: clientYear,
        data: {
          name: sectionName,
          data: {
            ...tempData,
          },
        },
      };

      if (sectionId !== 17) {
        const response = await putApi("/hra/assessment", payload);

        if (tabId == 1) {
          updateHraStatusToInProgress();
        }
        return response;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      saveSectionFormData(currSection + 1).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
                toast.success(toastMsg, {});
              }
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    }, 60000);
    return () => {
      clearInterval(id);
    };
  }, [currSection]);

  const userRoleId = sessionStorage.getItem("roleId");
  const isAdm = userRoleId === "1" || userRoleId === "2";
  const isClinician = userRoleId === "5";
  const isSaveBtn =
    hraStatus === "In Progress" ||
    hraStatus === "Scheduled" ||
    hraStatus === "Unscheduled";
  const isQfBtn =
    hraStatus === "Clinician Review - QA" ||
    hraStatus === "Clinician Review - Coding" ||
    hraStatus === "Submitted - Ready for Coding" ||
    hraStatus === "Submitted - Ready for QA";

  // Function to handle saving data
  const handleSaveData = async (sectionId) => {
    try {
      const response = await saveSectionFormData(sectionId);
      //Fix for HRA-1553
      if (response) {
        const { status, data = {} } = response;
        if (status === 200) {
          const toastMsg = data.message || "Unknown error occurred";
          toast.remove();
          if (toastMsg === "Assessment updated successfully.") {
            toast.success(toastMsg);
          } else {
            toast.error(toastMsg);
          }
        } else {
          toast.error("Failed to save data. Please try again.");
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  // Function to call saving data function
  const handleSaveAndClickOpen = async () => {
    const currentSection = sections[currSection];
    await handleSaveData(currentSection.sectionId);
  };

  //Handle Open Query Form
  const handleOpenQueryForm = () => setOpen(true);

  //Managing State for open & close speed dial
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  //Speed dial Options
  const actions = [
    {
      icon: <SaveOutlined />,
      name: "Save Form Data",
      handler: handleSaveAndClickOpen,
      visibility:
        roleId == 1 || roleId == 2 || (roleId == 5 && tabId != 3)
          ? true
          : false,
      // (isAdm && isSaveBtn) ||
      // (isClinician && tabId !== 3) ||
      // (roleId == 5 && tabId != 3),
    },
    {
      icon: <QueryFormIcon />,
      name: "Open Query Form",
      handler: handleOpenQueryForm,
      visibility: showQueryFormBtn === "true" || (isAdm && isQfBtn),
    },
  ];

  //Custom style for Speed Dial
  const StyledSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 16,
    right: 16,
    "& .MuiSpeedDial-fab": {
      backgroundColor: `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: `var(--mainTheme900)`,
      },
    },
  });

  //Filter Visible action alone
  const visibleActions = actions.filter((action) => action.visibility);

  const actionLength = visibleActions.length ? visibleActions.length : 0;

  //AppBar
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //Section Header Styles
  const cardHeaderStyles = {
    backgroundColor: "var(--mainTheme900)",
    color: "white",
    padding: "8px",
    textAlign: "center !important",
    fontWeight: 700,
  };

  const refs = {
    demographicsRef: useRef(), // Demographics
    previouslyRef: useRef(), // Previously
    covidScreeningRef: useRef(), // CovidScreening
    selfAssessRef: useRef(), // SelfAssess
    activitiesRef: useRef(), // Activities
    medicalRef: useRef(), // MedicalHistory
    familyHistoryRef: useRef(), // FamilyHistory
    preventiveRef: useRef(), // Preventive
    allergiesRef: useRef(), // Allergies
    rosRef: useRef(), // ROS
    painRef: useRef(), // Pain
    vitalRef: useRef(), // Vital
    examReviewRef: useRef(), // ExamReview
    screeningsRef: useRef(), // Screenings
    minicogRef: useRef(), // MiniCog
    homeSafetyRef: useRef(), // HomeSafety
    patientSummaryRef: useRef(), // PatientSummary
  };

  return (
    <>
      {actionLength === 1 && (
        <Tooltip title={visibleActions[0].name} arrow>
          <StyledSpeedDial
            ariaLabel={visibleActions[0].name}
            icon={visibleActions[0].icon}
            onClick={visibleActions[0].handler}
            transitionDuration={0}
          />
        </Tooltip>
      )}
      {actionLength === 2 && (
        <StyledSpeedDial
          ariaLabel="Save and Open Query Form"
          icon={<SpeedDialIcon />}
          onClose={handleCloseSpeedDial}
          onOpen={handleOpenSpeedDial}
          open={openSpeedDial}
          transitionDuration={0}
        >
          {visibleActions.map((action) =>
            action.visibility ? (
              <SpeedDialAction
                sx={{
                  bgcolor: `var(--mainTheme900)`,
                  color: "white",
                  "&:hover": {
                    bgcolor: `var(--mainTheme900)`,
                  },
                }}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handler}
              />
            ) : (
              <></>
            ),
          )}
        </StyledSpeedDial>
      )}
      <QueryForm
        open={open}
        setOpen={setOpen}
        memberId={_memberIdForQueryForm}
        formData={formData}
        _patientName={patientName}
      />
      <Grid item xs={10} marginTop="10px" marginBottom={1}>
        <HeaderCard formData={formData} getSectionDbData={getSectionDbData} />
      </Grid>

      <AppBar
        position="fixed"
        sx={{
          display: { xs: "block", sm: "none" },
          backgroundColor: "#1076BC",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Sections
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        <List>
          {sections.map((section, index) => (
            <ListItem
              button
              key={section.sectionId}
              onClick={() => handleCardClick(index)}
              sx={{
                backgroundColor:
                  currSection === index ? "var(--mainTheme300)" : "0ffffff",
              }}
            >
              <ListItemText primary={section.sectionName} />
            </ListItem>
          ))}
          {subSections &&
            subSections.map((section) => (
              <ListItem
                button
                key={section.sectionId}
                onClick={() => onSectionChange(section.sectionName)}
                sx={{
                  pl: 4, // indent for subsections
                  backgroundColor:
                    activeSection === section.sectionId
                      ? "var(--mainTheme200)"
                      : "transparent",
                }}
              >
                <ListItemText primary={section.sectionName} />
              </ListItem>
            ))}
        </List>
      </Drawer>

      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        style={{ fontFamily: "Inter !important" }}
        className={"fillHraForm"}
      >
        <Grid
          item
          xs={12} /* For mobile screens, takes full width */
          sm={2.5} /* For tablets, takes 3/12 grid space */
          md={2} /* For larger screens, takes 2/12 grid space */
          style={{ padding: "3px 0px" }}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <CardSections
            routedFrom="FillHra"
            sections={sections}
            currSection={currSection}
            onSectionChange={onSectionChange}
            activeSection={currentSection}
            setActiveSection={setActiveSection}
            // currSection={sectionIdRef.current}
            handleCardClick={handleCardClick}
            saveSectionFormData={saveSectionFormData}
            sideBarSectionDisable={sideBarSectionDisable}
          />
        </Grid>

        <Grid
          // xs={10}
          // style={{ padding: "2px", textAlign: "center !important" }}

          item
          xs={12} /* Full width on mobile */
          sm={9.5} /* Takes 9/12 grid space on tablet */
          md={10} /* Takes 10/12 grid space on larger screens */
          sx={{
            // padding: { xs: '8px', sm: '16px', md: '24px' },  // Adjust padding for different screen sizes
            // textAlign: { xs: 'center', sm: 'left' },  // Center content on small screens, align left on larger screens
            // marginTop: { xs: '10px', sm: '0' },  // Add margin-top on mobile to separate from the sections
            backgroundColor: { xs: "transparent", sm: "var(--mainTheme100)" }, // Optional: Different background color on mobile
          }}
        >
          <Card
            style={{
              height: "auto",
              minHeight: "76vh",
              maxHeight: "450px",
              overflowY: "auto",
              marginLeft: "10px",
            }}
          >
            <TableComponentPriorAssessment data={priorAssesmentData} />

            <TableComponentReferral
              data={data}
              refStatusForFillHra={refStatusForFillHra}
              referralDateSentQaDate={referralDateSentQaDate}
              referralData={referralData}
            />

            {sections.map((section, index) => {
              return (
                <div
                  key={index}
                  id={section.sectionId}
                  className="fillHra-container"
                >
                  <div ref={sectionRefs[index]} className="fillHra-section">
                    {/* <span className="fillHra-section-header">
                      <CardHeader
                        title={section.sectionName}
                        action={
                          <a href={`#${section.sectionId}`}>
                            <div
                              style={{
                                width: "100% !important",
                                margin: "0 !important",
                                padding: "0 !important",
                              }}
                            >
                              <IconButton
                                style={{
                                  float: "right",
                                  color: "#FFFFFF",
                                }}
                                aria-label="Section minimise"
                                size="small"
                                onClick={() => {
                                  //   if (isShowSection[index] !== undefined) {
                                  //     setIsShowSection({
                                  //       ...isShowSection,
                                  //       [index]: !isShowSection[index],
                                  //     });
                                  //   } else {
                                  //     setIsShowSection({
                                  //       ...isShowSection,
                                  //       [index]: false,
                                  //     });
                                  //   }
                                  // }}

                                  // if (currSection != index) {
                                  //   handleCardClick(index);
                                  // } else {
                                  //   handleCardClick(0);
                                  // }

                                  // if (section?.sectionId !== currSection) {
                                  if (index !== currSection) {
                                    //  setCurrSection(section?.sectionId);
                                    setCurrSection(section?.sectionId - 1);
                                    setPrevSelectedSection(currSection);
                                  } else {
                                    setCurrSection(section?.sectionId);
                                    setPrevSelectedSection(currSection);
                                    //setCurrentSectionId(undefined);
                                    //setCurrSection(undefined);
                                  }
                                }}
                              >
                                {currSection == index ? (
                                  <KeyboardControlKeyIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </div>
                          </a>
                        }
                        sx={{
                          backgroundColor: `var(--mainTheme900)`,
                          color: "white",
                          padding: "8px",
                          textAlign: "center !important",
                          // textTransform: "uppercase",
                          fontWeight: 700,
                        }}
                        titleTypographyProps={{ variant: "outline" }}
                      />
                    </span> */}

                    <span className="fillHra-section-header">
                      <CardHeader
                        title={section.sectionName}
                        action={
                          <a href={`#${section.sectionId}`}>
                            <div className="section-icon-container">
                              <IconButton
                                className="section-icon-button"
                                aria-label="Section minimise"
                                size="small"
                                onClick={() => {
                                  if (index !== currSection) {
                                    setCurrSection(section?.sectionId - 1);
                                    setPrevSelectedSection(currSection);
                                  } else {
                                    setCurrSection(section?.sectionId);
                                    setPrevSelectedSection(currSection);
                                  }
                                }}
                              >
                                {currSection === index ? (
                                  <KeyboardControlKeyIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </div>
                          </a>
                        }
                        sx={cardHeaderStyles}
                        titleTypographyProps={{ variant: "outline" }}
                      />
                    </span>

                    <div className="section-container">
                      <Grid container spacing={2} className="section-grid">
                        {section.sectionId === 10 &&
                        currSection + 1 === section.sectionId
                          ? subSections.map((subSection, subIndex) =>
                              renderSection(
                                subSection.name,
                                subSection.component,
                              ),
                            )
                          : null}
                      </Grid>
                    </div>

                    {/* <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <Suspense fallback={<Loader />}> /}

                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {isShowSection[index] === true ||
                        isShowSection[index] === undefined ? (
                          (() => {
                            if (
                              section.sectionId === 1 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <Demographics
                                  currSection={1}
                                  getSectionDbData={getSectionDbData}
                                  ref={demographicsRef}
                                  setAutoSaveDemographicsData={
                                    setAutoSaveDemographicsData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 2 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <Previously
                                  currSection={2}
                                  getSectionDbData={getSectionDbData}
                                  ref={previouslyRef}
                                  setAutoSavePrevDocData={
                                    setAutoSavePrevDocData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 3 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={covidInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <CovidScreening
                                    currSection={3}
                                    getSectionDbData={getSectionDbData}
                                    ref={covidScreeningRef}
                                    setAutoSaveCovidScreeningData={
                                      setAutoSaveCovidScreeningData
                                    }
                                    // inView={covidInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 4 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={selfInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <SelfAssess
                                    currSection={4}
                                    getSectionDbData={getSectionDbData}
                                    ref={selfAssessRef}
                                    setAutoSaveSelfAssessmentData={
                                      setAutoSaveSelfAssessmentData
                                    }
                                    // inView={selfInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 5 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={activitiesInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Activities
                                    currSection={5}
                                    getSectionDbData={getSectionDbData}
                                    ref={activitiesRef}
                                    //inView={activitiesInView}
                                    assessmentId={assessmentId}
                                    setAutoSaveActivitiesData={
                                      setAutoSaveActivitiesData
                                    }
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 6 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <></>
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={medicalInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <MedicalHistory
                                    currSection={6}
                                    getSectionDbData={getSectionDbData}
                                    ref={medicalRef}
                                    setAutoSaveMedicalHistory={
                                      setAutoSaveMedicalHistory
                                    }
                                    //inView={medicalInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 7 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={familyInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <FamilyHistory
                                    currSection={7}
                                    getSectionDbData={getSectionDbData}
                                    ref={familyHistoryRef}
                                    setAutoSaveFamilyHistoryData={
                                      setAutoSaveFamilyHistoryData
                                    }
                                    // inView={familyInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 8 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <></>
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={preventiveInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Preventive
                                    currSection={8}
                                    getSectionDbData={getSectionDbData}
                                    ref={preventiveRef}
                                    setAutoSavePreventiveCareData={
                                      setAutoSavePreventiveCareData
                                    }
                                    //inView={preventiveInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 9 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={allergyInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Allergies
                                    currSection={9}
                                    getSectionDbData={getSectionDbData}
                                    ref={allergiesRef}
                                    setAutoSaveAllergiesData={
                                      setAutoSaveAllergiesData
                                    }
                                    // inView={allergyInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 10 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={rosInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <ROS
                                    currSection={10}
                                    getSectionDbData={getSectionDbData}
                                    ref={rosRef}
                                    setAutoSaveRosData={setAutoSaveRosData}
                                    // inView={rosInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 11 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={painInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Pain
                                    currSection={11}
                                    getSectionDbData={getSectionDbData}
                                    ref={painRef}
                                    setAutoSavePainData={setAutoSavePainData}
                                    // inView={painInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 12 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={vitalInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Vital
                                    currSection={12}
                                    getSectionDbData={getSectionDbData}
                                    ref={vitalRef}
                                    setAutoSaveVitalData={setAutoSaveVitalData}
                                    // inView={vitalInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 13 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={examInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <ExamReview
                                    currSection={13}
                                    getSectionDbData={getSectionDbData}
                                    ref={examReviewRef}
                                    // inView={examInView}
                                    setIsLoading={setIsLoading}
                                    currentSection={currSection}
                                    isLoading={isLoading}
                                    setAutoSaveExamReviewData={
                                      setAutoSaveExamReviewData
                                    }
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 14 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={screeningsInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Screenings
                                    currSection={14}
                                    getSectionDbData={getSectionDbData}
                                    ref={screeningsRef}
                                    setAutoSaveScreeningNeeded={
                                      setAutoSaveScreeningNeeded
                                    }
                                    // inView={screeningsInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 15 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={miniCogInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <MiniCog
                                    currSection={15}
                                    getSectionDbData={getSectionDbData}
                                    ref={minicogRef}
                                    setAutoSaveMiniCogData={
                                      setAutoSaveMiniCogData
                                    }
                                    // inView={miniCogInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 16 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={homeInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <HomeSafety
                                    currSection={16}
                                    getSectionDbData={getSectionDbData}
                                    ref={homeSafetyRef}
                                    setAutoSaveHomeSafety={
                                      setAutoSaveHomeSafety
                                    }
                                    // inView={homeInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 17 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <PatientSummary
                                  currSection={17}
                                  getSectionDbData={getSectionDbData}
                                  ref={patientSummaryRef}
                                  setAutoSavePatientData={
                                    setAutoSavePatientData
                                  }
                                  referralId={referralId}
                                  memberNurseId={memberNurseId}
                                  referralDate={referralDate}
                                  primary={primary}
                                  nursePrimary={nursePrimary}
                                />
                              );
                            }
                          })()
                        ) : (
                          <></>
                        )}
                      </Grid>
                      {/* </Suspense> /}
                    </div> */}

                    <div className="section-container">
                      <Grid container spacing={2} className="section-grid">
                        {isShowSection[index] === true ||
                        isShowSection[index] === undefined ? (
                          (() => {
                            if (
                              section.sectionId === 1 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <Demographics
                                  currSection={1}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.demographicsRef}
                                  setAutoSaveDemographicsData={
                                    setAutoSaveDemographicsData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 2 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <Previously
                                  currSection={2}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.previouslyRef}
                                  setAutoSavePrevDocData={
                                    setAutoSavePrevDocData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 3 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <div className="section-wrapper">
                                  <CovidScreening
                                    currSection={3}
                                    getSectionDbData={getSectionDbData}
                                    ref={refs.covidScreeningRef}
                                    setAutoSaveCovidScreeningData={
                                      setAutoSaveCovidScreeningData
                                    }
                                  />
                                </div>
                              );
                            } else if (
                              section.sectionId === 4 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                //<div className="section-wrapper">
                                <SelfAssess
                                  currSection={4}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.selfAssessRef}
                                  setAutoSaveSelfAssessmentData={
                                    setAutoSaveSelfAssessmentData
                                  }
                                />
                                //</div>
                              );
                            } else if (
                              section.sectionId === 5 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <div className="section-wrapper">
                                  <Activities
                                    currSection={5}
                                    getSectionDbData={getSectionDbData}
                                    ref={refs.activitiesRef}
                                    assessmentId={assessmentId}
                                    setAutoSaveActivitiesData={
                                      setAutoSaveActivitiesData
                                    }
                                  />
                                </div>
                              );
                            } else if (
                              section.sectionId === 6 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <div className="section-wrapper">
                                  <MedicalHistory
                                    currSection={6}
                                    getSectionDbData={getSectionDbData}
                                    ref={refs.medicalRef}
                                    setAutoSaveMedicalHistory={
                                      setAutoSaveMedicalHistory
                                    }
                                  />
                                </div>
                              );
                            } else if (
                              section.sectionId === 7 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <div className="section-wrapper">
                                  <FamilyHistory
                                    currSection={7}
                                    getSectionDbData={getSectionDbData}
                                    ref={refs.familyHistoryRef}
                                    setAutoSaveFamilyHistoryData={
                                      setAutoSaveFamilyHistoryData
                                    }
                                  />
                                </div>
                              );
                            } else if (
                              section.sectionId === 8 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <div className="section-wrapper">
                                  <Preventive
                                    currSection={8}
                                    getSectionDbData={getSectionDbData}
                                    ref={refs.preventiveRef}
                                    setAutoSavePreventiveCareData={
                                      setAutoSavePreventiveCareData
                                    }
                                  />
                                </div>
                              );
                            } else if (
                              section.sectionId === 9 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                //<div className="section-wrapper">
                                <Allergies
                                  currSection={9}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.allergiesRef}
                                  setAutoSaveAllergiesData={
                                    setAutoSaveAllergiesData
                                  }
                                />
                                //</div>
                              );

                              /* <ROS
                                    currSection={10}
                                    getSectionDbData={getSectionDbData}
                                    ref={rosRef}
                                    setAutoSaveRosData={setAutoSaveRosData}
                                    // inView={rosInView}
                                  /> */

                              // </div>
                              // </div>

                              // </Suspense>
                              // );
                            } else if (
                              section.sectionId === 11 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <div className="section-wrapper">
                                  <Pain
                                    currSection={11}
                                    getSectionDbData={getSectionDbData}
                                    ref={refs.painRef}
                                    setAutoSavePainData={setAutoSavePainData}
                                  />
                                </div>
                              );
                            } else if (
                              section.sectionId === 12 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                //<div className="section-wrapper">
                                <Vital
                                  currSection={12}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.vitalRef}
                                  setAutoSaveVitalData={setAutoSaveVitalData}
                                />
                                //</div>
                              );
                            } else if (
                              section.sectionId === 13 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                //<div className="section-wrapper">
                                <ExamReview
                                  currSection={13}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.examReviewRef}
                                  setIsLoading={setIsLoading}
                                  currentSection={currSection}
                                  isLoading={isLoading}
                                  setAutoSaveExamReviewData={
                                    setAutoSaveExamReviewData
                                  }
                                />
                                //</div>
                              );
                            } else if (
                              section.sectionId === 14 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                //<div className="section-wrapper">
                                <Screenings
                                  currSection={14}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.screeningsRef}
                                  setAutoSaveScreeningNeeded={
                                    setAutoSaveScreeningNeeded
                                  }
                                />
                                //</div>
                              );
                            } else if (
                              section.sectionId === 15 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                //<div className="section-wrapper">
                                <MiniCog
                                  currSection={15}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.minicogRef}
                                  setAutoSaveMiniCogData={
                                    setAutoSaveMiniCogData
                                  }
                                />
                                //</div>
                              );
                            } else if (
                              section.sectionId === 16 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                //<div className="section-wrapper">
                                <HomeSafety
                                  currSection={16}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.homeSafetyRef}
                                  setAutoSaveHomeSafety={setAutoSaveHomeSafety}
                                />
                                //</div>
                              );
                            } else if (
                              section.sectionId === 17 &&
                              currSection + 1 === section.sectionId
                            ) {
                              return (
                                <PatientSummary
                                  currSection={17}
                                  getSectionDbData={getSectionDbData}
                                  ref={refs.patientSummaryRef}
                                  setAutoSavePatientData={
                                    setAutoSavePatientData
                                  }
                                  referralId={referralId}
                                  memberNurseId={memberNurseId}
                                  referralDate={referralDate}
                                  primary={primary}
                                  nursePrimary={nursePrimary}
                                />
                              );
                            }
                          })()
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </div>
                  </div>
                </div>
              );
            })}
          </Card>
        </Grid>
      </Grid>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

const FillHRAWrapper = ({ setTabIdFromIconClick }) => {
  const [tabId, setTabId] = useState(1);
  const [transcript, setTranscript] = useState("");
  const [apiData, setApiData] = useState([]);
  const [uniqueMember, setUniqueMember] = useState(null); // Store uniqueMemberId
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false); // State to toggle the player
  const [isLoading, setIsLoading] = useState(false); // State for loading
  // const [azureBlobUrl, setAzureBlobUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const assessmentId = sessionStorage?.getItem("assessmentId");

  // console.log(transcript, "transcript");

  // Slide Transition for Dialog
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  // Function to handle opening the dialog
  const handleClickOpen = () => {
    setIsDialogOpen(true); // Set dialog open state to true
  };

  const handleAcceptConsent = async () => {
    try {
      const payload = {
        consent: "true",
        assessmentId: assessmentId,
      };
      const response = await postApi("/realTimeScripts/rtt-consent", payload);

      // If the request is successful, close the dialog
      setIsDialogOpen(false);

      // Start the transcription recording
      startTranscriber();

      toast.success(response?.data?.message);

      //toast.error("Failed to give consent");
    } catch (error) {
      console.error("Error sending consent:", error);
      toast.error("An error occurred while sending consent");
    }
  };

  // Function to handle closing the dialog
  const handleClose = () => {
    setIsDialogOpen(false); // Set dialog open state to false when dialog is closed
  };

  useEffect(() => {
    if (location.state?.tabId) {
      setTabId(location.state.tabId);
    }
    if (location.state?.uniqueMember) {
      setUniqueMember(location.state.uniqueMember);
    }
  }, [location.state]);

  const {
    isTranscribe,
    setTranscribedText,
    startTranscriber,
    stopTranscriber,
    pauseTranscriber,
    resumeTranscriber,
    isTranscribePause,
    uploadAudioToAzure,
    generateSasReport,
    azureRequestUrl,
    azureBlobUrl,
    setAzureRequestUrl,
  } = useSpeech();

  // console.log(azureBlobUrl,"azureBlobUrlll")
  const handleTabChange = (newValue) => {
    setTabId(newValue);

    // Simulate a delay for loading (you can replace this with your actual data fetching)
    setTimeout(() => {
      setIsLoading(false); // Stop loading after data fetching or any processing
    }, 1000); // Adjust the time as needed
  };

  useEffect(() => {
    if (azureRequestUrl) {
      sendAudioUrl(); // Trigger API call once azureRequestUrl is updated
    }
  }, [azureRequestUrl]);
  // console.log(azureRequestUrl,"azureRequestUrl")

  const sendAudioUrl = async () => {
    try {
      const payload = {
        assessmentId: assessmentId,
        audioUrls: [azureRequestUrl],
      };
      const response = await postApi("/audio/save", payload);

      // toast.success(response?.data?.message);

      //toast.error("Failed to give consent");
    } catch (error) {
      return;
      // toast.error("An error occurred while sending consent");
    }
  };

  const fillHRASummaryRef = useRef(null);

  // useEffect(() => {
  //   console.log("useEffect triggered with", {
  //     transcript,
  //     assessmentId,
  //     isTranscribe,
  //   });

  //   // Only run when isTranscribe is false and required data is available
  //   if (!isTranscribe && assessmentId && transcript) {
  //     console.log("Processing RTT data...");

  //     // Start by generating the RTT report
  //     generateRTTReport({ assessmentId, textTranscript: transcript })
  //       .then((response) => {
  //         toast.success("RTT data generated successfully");

  //         // Once RTT data is successfully generated, run the other functions in parallel
  //         return Promise.all([generateSasReport(), uploadAudioToAzure()]);
  //       })
  //       .then(() => {
  //         // After both reports are successfully uploaded, call sendAudioUrl()
  //         sendAudioUrl();
  //       })
  //       .catch((error) => {
  //         // Handle any errors that occur during the process
  //         toast.error("An error occurred while processing reports");
  //         console.error("Error:", error);
  //       });
  //   }
  // }, [transcript, assessmentId, isTranscribe]);

  useEffect(() => {
    console.log("useEffect triggered with", {
      transcript,
      assessmentId,
      isTranscribe,
    });

    // Only run when isTranscribe is false and required data is available
    if (!isTranscribe && assessmentId && transcript) {
      console.log("Processing RTT data...");

      // Start by generating the RTT report
      generateRTTReport({ assessmentId, textTranscript: transcript })
        .then((response) => {
          toast.success("RTT data generated successfully");

          // Generate SAS report first
          return generateSasReport();
        })
        .then((sasToken) => {
          // Now that the SAS token is available, upload the audio
          return uploadAudioToAzure(sasToken);
        })
        // .then(() => {
        //   // After the audio is uploaded, send the audio URL
        //   return sendAudioUrl();
        // })
        .catch((error) => {
          // Handle any errors that occur during the process
          toast.error("An error occurred while processing reports");
          console.error("Error:", error);
        });
    }
  }, [transcript, assessmentId, isTranscribe]);

  const tabPanelStyle = { padding: "0px", margin: "10px 0px" };

  const RecordSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 16,
    // top: 50,
    right: 80,
    borderRadius: "30%",
    "& .MuiSpeedDial-fab": {
      backgroundColor: isTranscribe ? "tomato" : `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: isTranscribe ? "tomato" : `var(--mainTheme900)`,
      },
    },
  });
  const PauseSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 16,
    right: 144,
    "& .MuiSpeedDial-fab": {
      backgroundColor: `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: `var(--mainTheme900)`,
      },
    },
  });
  if (setTabIdFromIconClick) {
    setTabIdFromIconClick(setTabId);
  }
  const memberName = sessionStorage.getItem("Name");
  const dateOfBirth = sessionStorage.getItem("dob");
  const evaluatorDate =
    sessionStorage.getItem("evaluationDate") === "undefined" ||
    sessionStorage.getItem("evaluationDate") == "null"
      ? new Date()
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          })
          .replace(",", "")
      : sessionStorage.getItem("evaluationDate");
  const patientName = sessionStorage.getItem("selectedClinicianName");
  const memberId = sessionStorage.getItem("memberId");
  const planName = localStorage.getItem("clientPlan");

  const name = location?.state?.name
    ? location?.state?.name
    : sessionStorage.getItem("name");

  const clinician = location?.state?.clinician
    ? location?.state?.clinician
    : sessionStorage.getItem("clinician");

  const nurseName = location?.state?.nurseName
    ? location?.state?.nurseName
    : sessionStorage.getItem("patientName");

  const evaluationDateFromStorage = sessionStorage.getItem("evaluationDate");

  const evaluationDate =
    location?.state?.evaluationDate === "undefined" ||
    sessionStorage.getItem("evaluationDate") === "undefined"
      ? new Date()
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          })
          .replace(",", "")
      : new Date(evaluationDateFromStorage)
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          })
          .replace(",", "");

  const handleDownloadPdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    let yPosition = 20; // Starting Y position for the first element, reduced for A4 size

    const addFooter = (currentPage) => {
      // Draw horizontal line above the footer
      doc.setDrawColor(0, 0, 0, 3); // Set color to #00000024 with 24% opacity
      doc.setLineWidth(0.2); // Line thickness
      doc.line(10, pageHeight - 20, pageWidth - 10, pageHeight - 20); // Draw the line

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.setTextColor(0, 0, 0); // Set text color to black

      // Add page number
      doc.text(`Page ${currentPage}`, pageWidth - 20, pageHeight - 10, {
        align: "right",
      });

      // Add logo
      doc.addImage(Logo, "PNG", 10, pageHeight - 15, 30, 10); // Adjust position and size as needed
    };

    // Add a title to the PDF
    doc.setFontSize(14); // Reduced font size
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0); // Set text color to black
    doc.text("Member Risk Assessment Summary", pageWidth / 2, yPosition, {
      align: "center",
    });
    yPosition += 15; // Reduced spacing after the heading

    // Add additional information (Health Plan, Member Name, etc.)
    doc.setFontSize(12); // Set font size for additional information
    doc.setFont("helvetica", "normal");
    doc.text(`Health Plan: ${planName}`, 10, yPosition);
    doc.text(
      `Member Name: ${nurseName ? nurseName : memberName ? memberName : name}`,

      pageWidth / 2,
      yPosition,
    ); // Center aligned for Member Name
    yPosition += 7; // Spacing between lines

    doc.text(
      `Evaluator Name: ${patientName ? patientName : "" || clinician}`,
      10,
      yPosition,
    );
    doc.text(
      `Evaluation Date: ${evaluatorDate || evaluationDate}`,
      pageWidth / 2,
      yPosition,
    ); // Center aligned for Evaluation Date
    yPosition += 15; // Adjust Y position for the next section

    apiData.forEach((section, index) => {
      const { category, content } = section;

      // Check if the content needs a new page
      if (yPosition + 50 > pageHeight) {
        // Reserve 50mm for footer space
        addFooter(doc.internal.getNumberOfPages());
        doc.addPage();
        yPosition = 20; // Reset Y position for new page, reduced for A4 size
      }

      // Add a background color and text color for each category
      doc.setFillColor(189, 193, 198); // Background color #bdc1c6
      doc.setTextColor(0, 0, 0); // Text color black
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.rect(10, yPosition, pageWidth - 20, 10, "F"); // Draw a filled rectangle for the category background
      doc.text(category, 15, yPosition + 7); // Add category text
      yPosition += 15;

      // Draw another rectangle for the content
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.setTextColor(60, 60, 60); // Text color for content
      const lines = doc.splitTextToSize(content, pageWidth - 30); // Split content into lines that fit within the page
      doc.rect(10, yPosition, pageWidth - 20, lines.length * 6 + 3); // Adjust rectangle height based on content length
      doc.text(lines, 15, yPosition + 5); // Add content text
      yPosition += lines.length * 7 + 10; // Adjust yPosition based on content length

      // Add some spacing after each section
      yPosition += 5;

      // Add footer on each page
      if (index === apiData.length - 1) {
        addFooter(doc.internal.getNumberOfPages());
      }
    });

    // Add footer to the last page
    addFooter(doc.internal.getNumberOfPages());

    doc.save(`Member Risk Assessment_Summary_${memberId}.pdf`);
  };

  const miniButtonStyle = {
    margin: "8px",
    padding: "12px",
    borderRadius: "5px",
    background: "var(--mainTheme250)",
    color: "#514E4E",
    display: "flex",
    alignItems: "center",
  };

  const handleTogglePlayer = () => {
    setOpenModal(!openModal); // Toggle the modal visibility
  };

  return (
    <Box sx={{ marginTop: "70px", marginLeft: "10px" }}>
      {tabId !== 2 && (
        <RecordSpeedDial
          ariaLabel={"Audio Recorder Speed Dial"}
          icon={isTranscribe ? <StopIcon /> : <MicNoneIcon />}
          transitionDuration={0}
          onClick={() => {
            if (!isTranscribe) {
              // Open the dialog only when starting the recording
              handleClickOpen();
            } else {
              // Stop the transcriber without opening the dialog
              stopTranscriber();
              setTranscribedText(setTranscript);
            }
          }}
          // onClick={
          //   isTranscribe
          //     ? () => {
          //         stopTranscriber();
          //         setTranscribedText(setTranscript);

          //       }
          //     : () => {
          //         startTranscriber();
          //       }
          // }
        />
      )}

      {/* Dialog Implementation */}
      <Dialog
        open={isDialogOpen}
        // TransitionComponent={Transition}
        // keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ alignSelf: "center", fontSize: "24px" }}>
          {"User Consent"}
        </DialogTitle>
        <DialogContent style={{ display: "flex", gap: "36px" }}>
          <div>
            <ReportProblemIcon
              style={{ cursor: "pointer", color: "#365e79", fontSize: "48px" }}
            />
          </div>
          <DialogContentText
            style={{ fontSize: "20px", marginTop: "7px" }}
            id="alert-dialog-slide-description"
          >
            Please confirm your consent for Recording.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignSelf: "center" }}>
          <Button
            sx={{
              backgroundColor: "var(--mainTheme900)",
              color: "white",
              width: "100px",
              "&:hover": {
                backgroundColor: "var(--mainTheme500)",
              },
            }}
            onClick={handleAcceptConsent}
          >
            Accept
          </Button>
          <Button
            sx={{
              backgroundColor: "var(--mainTheme900)",
              color: "white",
              width: "100px",
              "&:hover": {
                backgroundColor: "var(--mainTheme500)",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {tabId !== 2 && isTranscribe && (
        <PauseSpeedDial
          ariaLabel={"Audio Pause/Resume Speed Dial"}
          icon={isTranscribePause ? <PlayArrowIcon /> : <PauseIcon />}
          transitionDuration={0}
          onClick={
            isTranscribePause
              ? () => {
                  resumeTranscriber();
                }
              : () => {
                  pauseTranscriber();
                  setTranscribedText(setTranscript);
                }
          }
        />
      )}

      <TabContext value={tabId}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TabList
              className="custom-tabs"
              value={tabId}
              onChange={(e, newValue) => handleTabChange(newValue)}
              aria-label="fill hra tabs"
              style={{ zIndex: "1" }}
              // style={{display: "flex", justifyContent: "space-between"}}
            >
              <Tab label="Fill HRA Form" value={1} />
              <Tab label="Summary" value={2} />
            </TabList>
            {apiData.length > 0 && azureBlobUrl && tabId == 2 ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <div className="wrapperss">
            <div className="itemssss">
              <button className="arrowBtn">
                <p style={{ width: "70%", fontSize: "16px" }}>
                  Click the icon to listen the audio
                </p>
                <TrendingFlatIcon
                  style={{ fontSize: "40px" }}
                  className="arrow1"
                />
              </button>
            </div>
          </div> */}

                {apiData.length > 0 && azureBlobUrl && tabId == 2 ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "24px",
                        // position: "fixed",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid transparent",
                          borderRadius: "10px",
                          // width: "80px",
                          backgroundColor: "#aad8dd",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          marginLeft: "57px",
                          padding: "3px",
                        }}
                      >
                        {/* <PlayCircleFilledIcon
                     onClick={handleTogglePlayer}
                     style={{ fontSize: "40px", cursor: "pointer" }}
                   /> */}
                        <AudioFileIcon
                          onClick={handleTogglePlayer}
                          style={{ fontSize: "36px", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    {/* Modal for audio player */}
                    {/* <Draggable> */}
                    <Dialog
                      open={openModal}
                      onClose={handleTogglePlayer} // Close modal on overlay click
                      style={{ width: "100%" }} // Optional: set max width for the modal
                      // Optional: make the modal full width
                      // PaperProps={{
                      //   component: Draggable, // Make the Paper component draggable
                      //   onDrag: (e, data) => {
                      //     // Handle any drag events here if needed
                      //   },
                      // }}
                    >
                      <DialogTitle
                        style={{ backgroundColor: "#1976d2", width: "370px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            color: "#fff",
                          }}
                        >
                          Audio Player{" "}
                        </div>
                        <IconButton
                          onClick={handleTogglePlayer} // Close the dialog when clicked
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "0px",
                            color: "#fff", // Change color as needed
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>

                      <DialogContent
                        style={{
                          position: "relative",
                          marginTop: "17px", // Set position to relative to place the close button
                        }}
                      >
                        {tabId === 2 && (
                          <CustomAudioPlayer
                            azureRequestUrl={azureRequestUrl}
                            setAzureRequestUrl={setAzureRequestUrl}
                            azureBlobUrl={azureBlobUrl}
                          />
                        )}
                      </DialogContent>
                    </Dialog>
                    {/* </Draggable> */}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {tabId == 2 && apiData.length > 0 ? (
            <div style={miniButtonStyle}>
              <DownloadForOfflineRoundedIcon
                sx={{ fontSize: 30, cursor: "pointer" }}
                color="#d9d9d9"
                onClick={handleDownloadPdf}
              />
            </div>
          ) : null}
        </Box>

        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <TabPanel value={1} style={tabPanelStyle}>
              <FillHraHome />
            </TabPanel>
            <TabPanel value={2} style={tabPanelStyle}>
              <FillHRASummary
                // ref={fillHRASummaryRef}
                setApiData={setApiData}
                apiData={apiData}
                uniqueMember={uniqueMember}
              />
            </TabPanel>
          </>
        )}
      </TabContext>

      {/* //audio */}
      {/* Arrow Animation */}
      {/* {apiData.length > 0 && azureBlobUrl && tabId == 2 ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="wrapperss">
            <div className="itemssss">
              <button className="arrowBtn">
                <p style={{ width: "70%", fontSize: "16px" }}>
                  Click the icon to listen the audio
                </p>
                <TrendingFlatIcon
                  style={{ fontSize: "40px" }}
                  className="arrow1"
                />
              </button>
            </div>
          </div>

          {apiData.length > 0 && azureBlobUrl && tabId == 2 ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "24px",
                  // position: "fixed",
                }}
              >
                <div
                  style={{
                    border: "1px solid transparent",
                    borderRadius: "42px",
                    width: "80px",
                    backgroundColor: "#aad8dd",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    marginLeft: "90px",
                  }}
                >
                  <PlayCircleFilledIcon
                     onClick={handleTogglePlayer}
                     style={{ fontSize: "40px", cursor: "pointer" }}
                   />
                  <AudioFileIcon
                    onClick={handleTogglePlayer}
                    style={{ fontSize: "40px", cursor: "pointer" }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "24px",
                  marginTop: "15px",
                }}
              >
                {isPlayerVisible && tabId == 2 && (
                  <CustomAudioPlayer
                    azureRequestUrl={azureRequestUrl}
                    setAzureRequestUrl={setAzureRequestUrl}
                    azureBlobUrl={azureBlobUrl}
                  />
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : null} */}
    </Box>
  );
};
export default FillHRAWrapper;
