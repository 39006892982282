import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { Checkbox } from "@mui/material";

export const initialWordList = {
  Version1: {
    isVersion1: "",
    isBanana: "",
    isSunrise: "",
    isChair: "",
  },
  Version2: {
    isVersion2: "",
    isLeader: "",
    isSeason: "",
    isTable: "",
  },
  Version3: {
    isVersion3: "",
    isVillage: "",
    isKitchen: "",
    isBaby: "",
  },
  Version4: {
    isVersion4: "",
    isRiver: "",
    isNation: "",
    isFinger: "",
  },
  Version5: {
    isVersion5: "",
    isCaptain: "",
    isGarden: "",
    isPicture: "",
  },
  Version6: {
    isVersion6: "",
    isDaughter: "",
    isHeaven: "",
    isMountain: "",
  },
};
export const TableComponentMiniCheck = ({
  data,
  minicogFormData,
  setMinicogFormData,
  versionNumber,
  disableFlag,
}) => {
  const initialWordList = useMemo(() => {
    const wordList = {
      Version1: {
        isVersion1: "",
        isBanana: "",
        isSunrise: "",
        isChair: "",
      },
      Version2: {
        isVersion2: "",
        isLeader: "",
        isSeason: "",
        isTable: "",
      },
      Version3: {
        isVersion3: "",
        isVillage: "",
        isKitchen: "",
        isBaby: "",
      },
      Version4: {
        isVersion4: "",
        isRiver: "",
        isNation: "",
        isFinger: "",
      },
      Version5: {
        isVersion5: "",
        isCaptain: "",
        isGarden: "",
        isPicture: "",
      },
      Version6: {
        isVersion6: "",
        isDaughter: "",
        isHeaven: "",
        isMountain: "",
      },
    };

    return wordList;
  }, []);

  const [wordListData, setWordListData] = useState(initialWordList);
  const [keysWithValueOne, setKeysWithValueOne] = useState([]);

  useEffect(() => {
    const mergedWordList = {
      ...initialWordList,
      ...minicogFormData.minicog_wordListVer,
    };
    setWordListData(mergedWordList);
  }, [minicogFormData, initialWordList]);

  useEffect(() => {
    if (minicogFormData && minicogFormData.minicog_wordListVer) {
      const keysWithValueOne = [];
      const wordListVer = minicogFormData.minicog_wordListVer;
      Object.keys(wordListVer).forEach((versionKey) => {
        const versionData = wordListVer[versionKey];
        Object.keys(versionData).forEach((key) => {
          if (versionData[key] === "1") {
            keysWithValueOne.push(key);
          }
        });
      });
      setKeysWithValueOne(keysWithValueOne);
    }
  }, [minicogFormData]);

  const selectedTable = data.find(
    (table) => table.table_name === "Mini Cog Versions",
  );

  if (!selectedTable) {
    return <div>Table not found</div>;
  }

  const handleTableCheckboxChange = (columnName, row, isChecked, rowIndex) => {
    const versionMatch = columnName.match(/\d+/);
    const versionNumber = versionMatch ? `Version${versionMatch[0]}` : "";
    const updatedWordListData = { ...(wordListData || {}) };

    if (row) {
      const versionKey = versionNumber || "";
      updatedWordListData[versionKey] = updatedWordListData[versionKey] || {};
      updatedWordListData[versionKey][`is${row}`] = isChecked ? "1" : "";
    }

    if (!row) {
      const versionKey = versionNumber || "";
      updatedWordListData[versionKey] = updatedWordListData[versionKey] || {};
      updatedWordListData[versionKey][`is${columnName.replace(/\s+/g, "")}`] =
        isChecked ? "1" : "";
    }

    setWordListData(updatedWordListData);

    setMinicogFormData({
      ...minicogFormData,
      minicog_wordListVer: updatedWordListData,
    });
  };

  return (
    <div
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px",
      }}
    >
      <table
        style={{ margin: "0 auto", borderCollapse: "collapse", width: "100%" }}
      >
        <thead>
          <tr>
            {selectedTable.column_data.map((column, colIndex) => (
              <th key={`header-${colIndex}`} style={{ padding: "8px" }}>
                <CheckboxHeader
                  columnName={column.name}
                  formattedColumnName={`is${column.name.replace(/\s+/g, "")}`}
                  isChecked={
                    wordListData &&
                    wordListData[`Version${versionNumber}`] &&
                    wordListData[`Version${versionNumber}`][
                      `is${column.name.replace(/\s+/g, "")}`
                    ] === "1"
                  }
                  handleTableCheckboxChange={handleTableCheckboxChange}
                  versionNumber={versionNumber}
                  wordListData={wordListData}
                  keysWithValueOne={keysWithValueOne}
                  disableFlag={disableFlag}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[0, 1, 2].map((rowIndex) => (
            <tr key={`row-${rowIndex}`}>
              {selectedTable.column_data.map((column, colIndex) => (
                <td
                  key={`cell-${rowIndex}-${colIndex}`}
                  style={{ padding: "8px" }}
                >
                  <CheckboxRow
                    row={column.rows[rowIndex]}
                    rowIndex={rowIndex}
                    columnName={column.name}
                    versionNumber={versionNumber}
                    isChecked={
                      wordListData[`Version${versionNumber}`] &&
                      wordListData[`Version${versionNumber}`][
                        `is${column.rows[rowIndex]}`
                      ] === "1"
                    }
                    handleTableCheckboxChange={(columnName, row, isChecked) =>
                      handleTableCheckboxChange(columnName, row, isChecked)
                    }
                    keysWithValueOne={keysWithValueOne}
                    disableFlag={disableFlag}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CheckboxHeader = ({
  columnName,
  formattedColumnName,
  handleTableCheckboxChange,
  versionNumber,
  wordListData,
  keysWithValueOne,
  disableFlag,
}) => {
  const isChecked =
    keysWithValueOne && keysWithValueOne?.includes(formattedColumnName);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    handleTableCheckboxChange(columnName, null, isChecked);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        type="checkbox"
        id={`checkbox-header-${formattedColumnName}`}
        onChange={handleChange}
        checked={isChecked}
        size="small"
        style={{ marginRight: "5px", padding: 0 }}
        disabled={disableFlag}
      />
      <label
        htmlFor={`checkbox-header-${formattedColumnName}`}
        style={{ margin: 0 }}
      >
        {columnName}
      </label>
    </div>
  );
};

const CheckboxRow = ({
  row,
  rowIndex,
  columnName,
  versionNumber,
  wordListData,
  handleTableCheckboxChange,
  keysWithValueOne,
  disableFlag,
}) => {
  const formattedRow = `is${row}`;

  const isChecked =
    keysWithValueOne && keysWithValueOne?.includes(formattedRow);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    handleTableCheckboxChange(columnName, row, isChecked);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        type="checkbox"
        id={`checkbox-${rowIndex}-${columnName}`}
        checked={isChecked}
        onChange={handleChange}
        size="small"
        style={{ marginRight: "5px", padding: 0 }}
        disabled={disableFlag}
      />
      <label
        htmlFor={`checkbox-${rowIndex}-${columnName}`}
        style={{ margin: 0 }}
      >
        {row}
      </label>
    </div>
  );
};

export const TableComponent = ({ tableName, data, disableFlag }) => {
  const selectedTable = data.find((table) => table.table_name === tableName);

  if (!selectedTable) {
    return <div>Table not found</div>;
  }

  return (
    <div
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px",
      }}
    >
      <table
        style={{
          margin: "0 auto",
          borderCollapse: "collapse",
          border: "1px solid var(--mainTheme)",
        }}
      >
        <thead>
          <tr>
            {selectedTable.column_data.map((column) => (
              <th
                key={column.id}
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedTable.column_data.length > 0 &&
            selectedTable.column_data[0].rows.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                {selectedTable.column_data.map((column, colIndex) => (
                  <td
                    key={`cell-${rowIndex}-${colIndex}`}
                    style={{
                      border: "1px solid var(--mainTheme)",
                      padding: "8px",
                    }}
                  >
                    {column.rows[rowIndex]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export const TableComponentStatic = ({ tableName, data }) => {
  if (tableName === "BMI Index Chart") {
    return (
      <Grid
      // style={{
      //   textAlign: "center",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   margin: "30px",
      // }}
      >
        <table
          style={{
            margin: "0 auto",
            borderCollapse: "collapse",
            border: "1px solid var(--mainTheme)",
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>BMI Index Chart</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <td
                  key={`cell-${rowIndex}`}
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {row}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    );
  }

  if (tableName === "BMI Index Chart 2024") {
    return (
      <Grid>
        <table
          style={{
            margin: "0 auto",
            borderCollapse: "collapse",
            border: "1px solid var(--mainTheme)",
            width: "30vw",
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>BMI Index Chart</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <td
                  key={`cell-${rowIndex}`}
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {row}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    );
  }

  if (tableName === "Activities Chart") {
    return (
      <Grid>
        <table
          style={{
            margin: "0 auto",
            borderCollapse: "collapse",
            border: "1px solid var(--mainTheme)",
          }}
        >
          <thead>
            <tr>
              {/* <th style={{ textAlign: "center" }}>BMI Index Chart</th> */}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <td
                  key={`cell-${rowIndex}`}
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                    width: "30vw",
                  }}
                >
                  {row}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    );
  }

  return <div>Table not found</div>;
};
