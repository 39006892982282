const json2023 = {
  sectionId: 15,
  sectionName: "Mini-Cog",
  isEditable: false,
  questions: [
    {
      id: 7,
      name: "miniCogSeeAttachedSheet",
      type: "label",
      label: "Mini- Cog (see attached sheet)",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },
    {
      id: 7,
      name: "step1ThreeWordRegistration",
      type: "label",
      label: "Step 1: Three Word Registration",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },
    {
      id: 7,
      name: "lookDirectlyAtPersonAndSayPlea",
      type: "label",
      variant: "1",
      label:
        "Look directly at person and say, “Please listen carefully. I am going to say three words that I want you to repeat back to me now and try to remember. The words are [select a list of words from the versions below]. Please say them for me now.” If the person is unable to repeat the words after three attempts, move on to Step 2 (clock drawing).\n\nThe following and other word lists have been used in one or more clinical studies.1-3 For repeated administrations, use of an alternative word list is recommended.",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
    },
    {
      id: 11,
      name: "dataTable",
      label: "Data Table",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          true,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            label: "Depression Severity",
            name: "Depression_Severity",
            columns: [
              {
                label: "Score",
                type: "number",
              },
              {
                label: "Depression Severity",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 4,
    },
    {
      id: 7,
      name: "step2ClockDrawing",
      type: "label",
      label: "Step 2: Clock Drawing",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 5,
    },
    {
      id: 7,
      name: "sayNextIWantYouToDrawAClockFor",
      type: "label",
      variant: "1",
      label:
        "Say: “Next, I want you to draw a clock for me. First, put in all of the numbers where they go.” When that is completed, say: “Now, set the hands to 10 past 11.”\n\nUse preprinted circle (see next page) for this exercise. Repeat instructions as needed as this is not a memory test.\n\nMove to Step 3 if the clock is not complete within three minutes.",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 6,
    },
    {
      id: 7,
      name: "step3ThreeWordRecall",
      type: "label",
      label: "Step 3: Three Word Recall",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 7,
    },
    {
      id: 7,
      name: "askThePersonToRecallTheThreeWo",
      type: "label",
      variant: "1",
      label:
        "Ask the person to recall the three words you stated in Step 1. Say: “What were the three words I asked you to remember?” Record the word list version number and the person’s answers below.",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 8,
    },
    {
      id: 5,
      name: "minicog_wordList",
      label: "Word List Version",
      editToggle: false,
      defaultValue: "",
      xs: 2,
      fullWidth: true,
      type: "select",
      options: [
        ["1", "1"],
        ["2", "2"],
        ["3", "3"],
        ["4", "4"],
        ["5", "5"],
        ["6", "6"],
      ],
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 9,
    },
    {
      id: 7,
      name: "step3ThreeWordRecall",
      type: "label",
      label: "",
      editToggle: false,
      xs: 5,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 10,
    },
    {
      id: 1,
      name: "minicog_personAnswers",
      type: "text",
      label: "Person's Answers",
      editToggle: false,
      xs: 5,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 11,
    },
    {
      id: 11,
      name: "step3ThreeWordRecall",
      label: "Word Recall",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            name: "step3ThreeWordRecall",
            label: "Word Recall",
            columns: [
              {
                label: "Word Recall",
                type: "text",
              },
              {
                label: "Points",
                type: "select",
              },
              {
                label: "Description",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 12,
    },
    {
      id: 11,
      name: "clockDrawPoints",
      label: "Clock Draw",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            name: "clockDrawPoints",
            label: "Clock Draw",
            columns: [
              {
                label: "Clock Draw",
                type: "text",
              },
              {
                label: "Points",
                type: "select",
              },
              {
                label: "Description",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 13,
    },
    {
      id: 11,
      name: "totalScorePoints",
      label: "Total Score",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            name: "totalScore",
            label: "Total Score",
            columns: [
              {
                label: "Total Score",
                type: "text",
              },
              {
                label: "Points",
                type: "number",
              },
              {
                label: "Description",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 14,
    },
    {
      id: 10,
      name: "minicog_furtherCognitive",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Further cognitive evaluation needed", "furtherCognitive"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 15,
    },
    {
      id: 10,
      name: "minicog_other",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Other", "other"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 16,
    },
  ],
};

const json2024 = {
  sectionId: 15,
  sectionName: "Mini-Cog",
  isEditable: false,
  questions: [
    {
      id: 10,
      name: "minicog_Na",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Not applicable", "notApplicable"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 19,
    },
    {
      id: 10,
      name: "horizontal_line",
      label: "Horizontal Line",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "line",
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: false,
      isModified: false,
    },
    {
      id: 7,
      name: "minicog_attached",
      type: "label",
      label: "Mini- Cog (see attached sheet)",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },
    {
      id: 7,
      name: "step1ThreeWordRegistration",
      type: "label",
      label: "Step 1: Three Word Registration",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },
    {
      id: 7,
      name: "lookDirectlyAtPersonAndSayPlea",
      type: "label",
      variant: "1",
      label:
        "Look directly at person and say, “Please listen carefully. I am going to say three words that I want you to repeat back to me now and try to remember. The words are [select a list of words from the versions below]. Please say them for me now.” If the person is unable to repeat the words after three attempts, move on to Step 2 (clock drawing).",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
    },
    {
      id: 7,
      name: "Thefollowingandotherwordlists",
      type: "label",
      variant: "1",
      label:
        "The following and other word lists have been used in one or more clinical studies.1-3 For repeated administrations, use of an alternative word list is recommended.",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 4,
    },

    {
      id: 11,
      name: "minicog_wordListVer",
      label: "Data Table",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          true,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            label: "Depression Severity",
            name: "Depression_Severity",
            columns: [
              {
                label: "Score",
                type: "number",
              },
              {
                label: "Depression Severity",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 5,
    },

    {
      id: 7,
      name: "step2ClockDrawing",
      type: "label",
      label: "Step 2: Clock Drawing",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 6,
    },
    {
      id: 7,
      name: "sayNextIWantYouToDrawAClockFor",
      type: "label",
      variant: "1",
      label:
        "Say: “Next, I want you to draw a clock for me. First, put in all of the numbers where they go.” When that is completed, say: “Now, set the hands to 10 past 11.”",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 7,
    },
    {
      id: 7,
      name: "repeatInstruction",
      type: "label",
      variant: "1",
      label: "Repeat instructions as needed as this is not a memory test.",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 8,
    },
    {
      id: 7,
      name: "MovetoStep3",
      type: "label",
      variant: "1",
      label: "",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 9,
    },
    {
      id: 7,
      name: "MovetoStep3",
      type: "label",
      variant: "2",
      label:
        "Move to Step 3 if the clock is not complete within three minutes.",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 9,
    },
    {
      id: 7,
      name: "step3ThreeWordRecall",
      type: "label",
      label: "Step 3: Three Word Recall",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 10,
    },
    {
      id: 7,
      name: "askThePersonToRecallTheThreeWo",
      type: "label",
      variant: "1",
      label:
        "Ask the person to recall the three words you stated in Step 1. Say: “What were the three words I asked you to remember?” Record the word list version number and the person’s answers.",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 11,
    },

    {
      id: 7,
      name: "step3ThreeWordRecall",
      type: "label",
      label: "",
      editToggle: false,
      xs: 5,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 12,
    },

    {
      id: 11,
      name: "step3ThreeWordRecall",
      label: "Word Recall",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            name: "step3ThreeWordRecall",
            label: "Word Recall",
            columns: [
              {
                label: "Word Recall",
                type: "text",
              },
              {
                label: "Points",
                type: "select",
              },
              {
                label: "Description",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 13,
    },
    {
      id: 11,
      name: "clockDrawPoints",
      label: "Clock Draw",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            name: "clockDrawPoints",
            label: "Clock Draw",
            columns: [
              {
                label: "Clock Draw",
                type: "text",
              },
              {
                label: "Points",
                type: "select",
              },
              {
                label: "Description",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 14,
    },
    {
      id: 11,
      name: "totalScorePoints",
      label: "Total Score",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            name: "totalScore",
            label: "Total Score",
            columns: [
              {
                label: "Total Score",
                type: "text",
              },
              {
                label: "Points",
                type: "number",
              },
              {
                label: "Description",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 15,
    },
    {
      id: 10,
      name: "minicog_furtherCognitive",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Further cognitive evaluation needed", "furtherCognitive"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 16,
    },
    {
      id: 10,
      name: "minicog_caseManagementReferral",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Case management referral for followup", "casemangerefforfoll"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 17,
    },
    {
      id: 10,
      name: "minicog_followUpWithClinician",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Followup with Clinician/Specialist", "followupClincianSpec"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 18,
    },
    {
      id: 10,
      name: "minicog_memberToUseDME",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Member to use Durable Medical Equipment (DME)", "durableEquip"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 19,
    },
    {
      id: 10,
      name: "minicog_other",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Other", "other"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 20,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
