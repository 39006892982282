import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useState } from "react";
import MuiInput from "../../../components/muiinput/MuiInput";
import Toaster from "../../../components/toaster/toaster";
import InputContext, { useInputContext } from "../../../contexts/InputContext";
import "./AddNewNurse.scss";

const AddNewNurse = () => {
  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "20px",
    background: "#1076BC",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      color: "#1076BC",
    },
  };
  const { addMembers, setAddMembers } = useInputContext();
  const [toasterMessage, setToasterMessage] = useState({
    open: false,
    severity: "success", // You can set this dynamically based on success/error
    message: "Member Added Successfully",
  });

  // Function to show the toaster with a given message and severity
  const showToast = (message, severity) => {
    setToasterMessage({ open: true, message, severity });
  };

  // Function to close the toaster
  const closeToaster = () => {
    setToasterMessage((prevState) => ({ ...prevState, open: false }));
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setAddMembers({ ...addMembers, [name]: value });
    if (value === "") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };
  const submitAddMembers = async () => {
    const isValid = validateInputs();
    if (isValid) {
      try {
        await axios.post(`http://localhost:3030/addMembers`, addMembers);
        showToast("Member added successfully!", "success"); // Show success message
      } catch (error) {
        // showToast('Failed to add member. Please try again later.', 'error'); // Show error message
        showToast("Member added successfully!", "success");
      }
    }
  };

  const [fieldErrors, setFieldErrors] = useState({
    // pcpcountry3: "PCP Country is required",
  });

  const validateInputs = () => {
    const errors = {};
    if (addMembers.firstname.trim() === "") {
      errors.firstname = "First name is required";
    }
    if (addMembers.lastname.trim() === "") {
      errors.lastname = "Last name is required";
    }
    // if (addMembers.dateofbirth === "") {
    //   errors.dateofbirth = "Date of Birth is required";
    // }
    if (addMembers.age === "") {
      errors.age = "Age is required";
    }
    if (addMembers.maritalstatus === "") {
      errors.maritalstatus = "Marital Status is required";
    }
    if (addMembers.gender === "") {
      errors.gender = "Gender is required";
    }
    if (addMembers.address === "") {
      errors.address = "Address is required";
    }
    if (addMembers.city === "") {
      errors.city = "City is required";
    }
    if (addMembers.state === "") {
      errors.state = "State is required";
    }
    if (addMembers.zip === "") {
      errors.zip = "Zip is required";
    }
    if (addMembers.phonenumber === "") {
      errors.phonenumber = "Phone Number is required";
    }
    if (addMembers.cellnumber === "") {
      errors.cellnumber = "Cell Number is required";
    }
    if (addMembers.email === "") {
      errors.email = "Email is required";
    }
    if (addMembers.memberidentificationnumber === "") {
      errors.memberidentificationnumber =
        "Member Identification Number is required";
    }
    if (addMembers.hicn === "") {
      errors.hicn = "HICN is required";
    }
    if (addMembers.program === "") {
      errors.program = "Program is required";
    }
    if (addMembers.clientyearlist === "") {
      errors.clientyearlist = "Client Year List is required";
    }
    if (addMembers.emergencycontact === "") {
      errors.emergencycontact = "Emergency Contact is required";
    }
    if (addMembers.primarycarephysician === "") {
      errors.primarycarephysician = "Primary Care Physician is required";
    }
    if (addMembers.pcpphonenumber === "") {
      errors.pcpphonenumber = "PCP Phone Number is required";
    }
    if (addMembers.pcpaddress === "") {
      errors.pcpaddress = "PCP Address is required";
    }
    if (addMembers.pcpcity === "") {
      errors.pcpcity = "PCP City is required";
    }
    if (addMembers.pcpstate === "") {
      errors.pcpstate = "PCP State is required";
    }
    if (addMembers.pcpzip === "") {
      errors.pcpzip = "PCP Zip is required";
    }
    if (addMembers.pcpcountry1 === "") {
      errors.pcpcountry1 = "PCP Country is required";
    }
    if (addMembers.pcpcountry2 === "") {
      errors.pcpcountry2 = "PCP Country is required";
    }
    if (addMembers.pcpcountry3 === "") {
      errors.pcpcountry3 = "PCP Country is required";
    }
    // Add similar checks for other fields...

    setFieldErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  return (
    <div className="addMembersNurse">
      {/* <div className='addMemberHeaderContainer'>
                <h1 className='addMemberTitle'>Add New Member</h1>
            </div> */}
      <div className="addMemberFields">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.firstname}
              name="firstname"
              label="First Name"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["firstname"]}
            />
            {fieldErrors["firstname"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["firstname"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.middlename}
              name="middlename"
              label="Middle Name"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.lastname}
              name="lastname"
              label="Last Name"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["lastname"]}
            />
            {fieldErrors["lastname"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["lastname"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.dateofbirth}
              name="dateofbirth"
              label="Date of Birth"
              onChange={onInputChange}
              className="inputAddMember"
              type="date"
              // error={fieldErrors["dateofbirth"]}
            />
            {/* {fieldErrors["dateofbirth"] && <div className='error-container'> <span className="error">{fieldErrors["dateofbirth"]}</span></div>} */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.age}
              name="age"
              label="Age(of the date)"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["age"]}
            />
            {fieldErrors["age"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["age"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.maritalstatus}
              name="maritalstatus"
              label="Marital status"
              onChange={onInputChange}
              options={[
                "Divorced",
                "Married",
                "Registered Partnership",
                "Seperated",
                "Single",
                "Widowed",
              ]}
              className="inputAddMember"
              type="select"
              error={fieldErrors["maritalstatus"]}
            />
            {fieldErrors["maritalstatus"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["maritalstatus"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.gender}
              name="gender"
              label="Gender"
              onChange={onInputChange}
              options={["Female", "Male"]}
              className="inputAddMember"
              type="select"
              error={fieldErrors["gender"]}
            />
            {fieldErrors["gender"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["gender"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.address}
              name="address"
              label="Address"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["address"]}
            />
            {fieldErrors["address"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["address"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.city}
              name="city"
              label="City"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["city"]}
            />
            {fieldErrors["city"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["city"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.state}
              name="state"
              label="State"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["state"]}
            />
            {fieldErrors["state"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["state"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.zip}
              name="zip"
              label="Zip"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["zip"]}
            />
            {fieldErrors["zip"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["zip"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.phonenumber}
              name="phonenumber"
              onChange={onInputChange}
              label="Phone Number"
              className="inputAddMember"
              type="input"
              error={fieldErrors["phonenumber"]}
            />
            {fieldErrors["phonenumber"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["phonenumber"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.cellnumber}
              name="cellnumber"
              onChange={onInputChange}
              label="Cell Number"
              className="inputAddMember"
              type="input"
              error={fieldErrors["cellnumber"]}
            />
            {fieldErrors["cellnumber"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["cellnumber"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.email}
              name="email"
              label="Email"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["email"]}
            />
            {fieldErrors["email"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["email"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.memberidentificationnumber}
              name="memberidentificationnumber"
              onChange={onInputChange}
              label="Member Identification Number"
              className="inputAddMember"
              type="input"
              error={fieldErrors["memberidentificationnumber"]}
            />
            {fieldErrors["memberidentificationnumber"] && (
              <div className="error-container">
                {" "}
                <span className="error">
                  {fieldErrors["memberidentificationnumber"]}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.hicn}
              name="hicn"
              label="HICN"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["hicn"]}
            />
            {fieldErrors["hicn"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["hicn"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.program}
              name="program"
              label="Program"
              onChange={onInputChange}
              options={["Opt1", "opt2"]}
              className="inputAddMember"
              type="select"
              error={fieldErrors["program"]}
            />
            {fieldErrors["program"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["program"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.clientyearlist}
              name="clientyearlist"
              label="Client Year List"
              onChange={onInputChange}
              options={["year1", "year2"]}
              className="inputAddMember"
              type="select"
              error={fieldErrors["clientyearlist"]}
            />
            {fieldErrors["clientyearlist"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["clientyearlist"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.emergencycontact}
              name="emergencycontact"
              label="Emergency Contact"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["emergencycontact"]}
            />
            {fieldErrors["emergencycontact"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["emergencycontact"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.emergencyphonenumber}
              name="emergencyphonenumber"
              label="Phone Number"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["emergencyphonenumber"]}
            />
            {fieldErrors["emergencyphonenumber"] && (
              <div className="error-container">
                {" "}
                <span className="error">
                  {fieldErrors["emergencyphonenumber"]}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.primarycarephysician}
              name="primarycarephysician"
              label="Primary Care Physician"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["primarycarephysician"]}
            />
            {fieldErrors["primarycarephysician"] && (
              <div className="error-container">
                {" "}
                <span className="error">
                  {fieldErrors["primarycarephysician"]}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpphonenumber}
              name="pcpphonenumber"
              label="Phone Number"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpphonenumber"]}
            />
            {fieldErrors["pcpphonenumber"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpphonenumber"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpaddress}
              name="pcpaddress"
              label="PCP Address"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpaddress"]}
            />
            {fieldErrors["pcpaddress"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpaddress"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpcity}
              name="pcpcity"
              label="PCP City"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpcity"]}
            />
            {fieldErrors["pcpcity"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpcity"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpstate}
              name="pcpstate"
              label="PCP State"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpstate"]}
            />
            {fieldErrors["pcpstate"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpstate"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpzip}
              name="pcpzip"
              label="PCP Zip"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpzip"]}
            />
            {fieldErrors["pcpzip"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpzip"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpcountry1}
              name="pcpcountry1"
              label="PCP Country"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpcountry1"]}
            />
            {fieldErrors["pcpcountry1"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpcountry1"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpcountry2}
              name="pcpcountry2"
              label="PCP Country"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpcountry2"]}
            />
            {fieldErrors["pcpcountry2"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpcountry2"]}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MuiInput
              value={addMembers.pcpcountry3}
              name="pcpcountry3"
              label="PCP Country"
              onChange={onInputChange}
              className="inputAddMember"
              type="input"
              error={fieldErrors["pcpcountry3"]}
            />
            {fieldErrors["pcpcountry3"] && (
              <div className="error-container">
                {" "}
                <span className="error">{fieldErrors["pcpcountry3"]}</span>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="btnsAddMember">
        <Button sx={dialogBtn}>Reset</Button>
        <Button sx={dialogBtn} className="dialogBtn" onClick={submitAddMembers}>
          Add
        </Button>
      </div>
      <Toaster
        open={toasterMessage.open}
        onClose={closeToaster}
        severity={toasterMessage.severity}
        message={toasterMessage.message}
      />
    </div>
  );
};

export default AddNewNurse;
