import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { SelectDropdown } from "../../select/select";

import AlertContext from "../../../contexts/AlertContext";
import AddDialog from "../../Dialog/Dialog";
import CustomizedDialogs from "../Dialog/Dialog";
import AddClinician from "./AddClinician/AddClinician";

import "./ViewClinicians.css";

import { useContext, useState } from "react";

const ViewClinciansComponent = () => {
  const memberListOptions = [
    "Member List",
    "Unassigned",
    "Scheduled",
    "In Progress",
    "QA",
    "Coding",
    "Clinician Review",
    "Ready for Upload",
    "Completed",
    "Cancelled / No Show",
    "Term Expired",
  ];
  const filterDropdown = ["First name", "Identification#", "Last name"];
  const [componentToShow, setComponentToShow] = useState(""); // 'client', 'admin', or 'clinician'

  const alertContext = useContext(AlertContext);
  const toggleOpen = () => {
    alertContext.setFilterDialog(true);
  };
  const toggleClose = () => {
    alertContext.setFilterDialog(false);
  };

  const toggleOpenAdd = () => {
    setComponentToShow("clinician");
    alertContext.setAddDialog(true);
  };
  const toggleCloseAdd = () => {
    alertContext.setAddDialog(false);
  };
  const onAdd = (formData) => {
    alertContext.setAddDialog(false);
  };

  return (
    <div className="member-list">
      <CustomizedDialogs />
      <AddDialog title="Add Clinician" componentToShow={componentToShow}>
        {componentToShow === "clinician" && <AddClinician onAdd={onAdd} />}
      </AddDialog>
      <div className="member-list-left">
        <Box
          className="filterBoxViewClinicians"
          onClick={() => toggleOpenAdd()}
        >
          <p>Add Clinician</p>
          {/* <FilterAltIcon sx={{ height: '26px', width: '26px' }} /> */}
        </Box>
      </div>
      <div className="member-list-right">
        <Box className="filterBoxViewClinicians" onClick={toggleOpen}>
          <p>Filter</p>
          <FilterAltIcon sx={{ height: "26px", width: "26px" }} />
        </Box>
        <SelectDropdown
          sx={{
            borderRadius: 20,
            width: "154px",
            height: "40px",
            fontSize: "16px",
            fontWeight: 500,
            marginRight: 1,
            marginLeft: 2,
          }}
          title="Identification"
          options={filterDropdown}
          className="dropdownMemberList"
          valueClass="dropdownValueMemberList"
        />
        <Box className="searchBoxML">
          <TextField
            placeholder="Search"
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </Box>
      </div>
    </div>
  );
};

export default ViewClinciansComponent;
