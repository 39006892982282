import axiosWrapper from "./axiosWrapper";

export const addNewClients = (data) =>
  axiosWrapper.post("/clients/add", { data });

export const getAllClients = () => axiosWrapper.get("/clients/list");

export const getAllClientsPagination = (data, params) =>
  axiosWrapper.get("/clients/paginate", { data, params });

export const getClientByID = (data) => axiosWrapper.get("/clients/view", data);

export const updateClientByID = (data) =>
  axiosWrapper.put("/clients/update", data);
