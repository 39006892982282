import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import "./memberProfileHistory.css";
import { postApi } from "../../utils/axiosApiWrapper";
import _ from "lodash";
import Toaster from "../toaster/toaster";
import { getMemberHistoryByYear, getMemberYears } from "../../api/membersApi";
import dayjs from "dayjs";
import { useUserContext } from "../../contexts/UserContext";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CommunicationTrackerDialog from "./AddMembers/CommunicationTrackerDialog";

export default function MemberProfileHistory({ uniqueMemberId, memId }) {
  const [memberData, setMemberData] = useState([]);
  const [memObjId, setMemObjId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [storedHraStatus, setStoredHraStatus] = useState([]);
  const [dynamicSchedulerData, setDynamicSchedulerData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { selectedTimeZoneUtc } = useUserContext();
  const _ = require("lodash");

  const fetchMemberYear = async () => {
    try {
      const response = await getMemberYears(memId);
      const allYears = response?.data?.data;
      const uniqueYearWithId = allYears.reduce((acc, current) => {
        if (!acc.some((item) => item.year === current.year)) {
          acc.push({ year: current.year, id: current.id });
        }
        return acc;
      }, []);
      setMemObjId(uniqueYearWithId);
    } catch (err) {
      return null;
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  const convertToLocalDateTime = (utcDateTimeString) => {
    const utcDate = new Date(utcDateTimeString);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
    );
    return localDate.toLocaleString();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchMemberYear(); // Fetch years first
      } catch (err) {
        console.error("Error fetching member years:", err);
        return;
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchMemberHistory = async () => {
      setIsLoading(true);
      let memdt = [],
        storedhra = [],
        dynsch = [];

      for (const mem of memObjId) {
        try {
          const response = await getMemberHistoryByYear({
            memberUniqueId: mem.id,
            year: mem.year,
          });

          const memData = response?.data?.data?.memberHistoryData[0];
          if (memData) {
            memdt = [...memdt, memData];
            storedhra = [...storedhra, memData?.hraStatus?.hraStatus];
            if (Array.isArray(memData?.schedulerCallLogDetails?.callLog)) {
              dynsch.push(memData.schedulerCallLogDetails.callLog);
            }
          } else {
            console.log(`No data found for year ${mem.year}`);
          }
        } catch (error) {
          console.error(
            `Error fetching member history for year ${mem.year}:`,
            error,
          );
        }
      }

      setMemberData(memdt);
      setStoredHraStatus(storedhra);
      setDynamicSchedulerData(dynsch);
      if (memdt.length > 0) {
        setChecked(memdt[0]?.returnMail);
      }
      setIsLoading(false);
    };

    fetchMemberHistory();
  }, [memObjId]);

  const [ind, setInd] = useState(0);
  const handleReturnMail = async (event) => {
    setChecked(event.target.checked);
    try {
      const response = await postApi("/returnMail/returnMail", {
        memberUniqueId: uniqueMemberId,
        isReturnMail: event?.target?.checked,
      });
      setToasterMessage(response.data.message);
      setToasterOpen(true);
    } catch {
      return null;
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="edit-fields">
            <div className="member-details">
              <ul>
                <li>
                  Member Id:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.memberIdentificationNo ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.memberId}
                  </span>
                </li>
                <li>
                  Address:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.addressOne ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.address}
                  </span>
                </li>
                <li>
                  Mobile Number:{" "}
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.pPhoneNumber ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.pPhone}
                  </span>
                </li>
                <li>
                  Phone Number:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.pPhoneNumber ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.pPhone}
                  </span>
                </li>
                <li>
                  HICN:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data?.hicn}
                  </span>
                </li>
              </ul>
            </div>

            {/* {roleId == 1 && ( */}

            <div style={{ display: "flex" }}>
              <div>
                <Button
                  style={{
                    backgroundColor: "#069d06",
                    color: "white",
                    width: "100%",
                    padding: "26px 11px",
                    // "&:hover": {
                    //   backgroundColor: "var(--mainTheme500)",
                    // },
                  }}
                  onClick={handleDialogOpen}
                >
                  Communication Tracker
                </Button>
              </div>
              {/* Communication Tracker Dialog */}
              <CommunicationTrackerDialog
                open={openDialog}
                onClose={handleDialogClose}
              />

              <div className="return-mail">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onChange={handleReturnMail} />
                    }
                    label="Return Mail"
                  />
                </FormGroup>
              </div>
            </div>
            {/* )} */}
          </div>

          <div className="prior-assesment">
            {/* <h4>Prior Assessments</h4> */}
            <div className="tabsList">
              {memberData.map((mem, index) => (
                <div
                  key={index}
                  className={`tab ${ind === index ? "activeTab" : ""}`}
                  onClick={() => setInd(index)}
                >
                  {mem.year}
                </div>
              ))}
            </div>
            <div className="assesment-card">
              <div className="assement-right">
                <ul className="scheduler-details">
                  <li>
                    Visit Type:
                    <span>{memberData[ind]?.hraStatus?.visitType}</span>
                  </li>
                  <li>
                    Assigned Clinician:
                    <span>
                      {memberData[ind]?.clinicanUserData?.firstName}{" "}
                      {memberData[ind]?.clinicanUserData?.lastName}
                    </span>
                  </li>
                  <li>
                    Assigned QA:
                    <span>
                      {memberData[ind]?.qaUserData?.firstName}{" "}
                      {memberData[ind]?.qaUserData?.lastName}
                    </span>
                  </li>
                  <li>
                    Assigned Coder:{" "}
                    <span>
                      {memberData[ind]?.coderUserData?.firstName}{" "}
                      {memberData[ind]?.coderUserData?.lastName}
                    </span>
                  </li>
                </ul>
                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] != "15" &&
                  storedHraStatus[ind] != "16" &&
                  storedHraStatus[ind] != "17" &&
                  storedHraStatus[ind] != "4" &&
                  storedHraStatus[ind] != "5" &&
                  storedHraStatus[ind] != "14" &&
                  storedHraStatus[ind] != "20" &&
                  storedHraStatus[ind] != "18" && (
                    <ul
                      id="progressbar"
                      className={
                        storedHraStatus[ind] == "12" ||
                        storedHraStatus[ind] == "19" ||
                        storedHraStatus[ind] == "3"
                          ? "more-items"
                          : ""
                      }
                    >
                      <li
                        className={
                          storedHraStatus[ind] == "1" ||
                          storedHraStatus[ind] == "2" ||
                          storedHraStatus[ind] == "6" ||
                          storedHraStatus[ind] == "8" ||
                          storedHraStatus[ind] == "7" ||
                          storedHraStatus[ind] == "9" ||
                          storedHraStatus[ind] == "10" ||
                          storedHraStatus[ind] == "11" ||
                          storedHraStatus[ind] == "12" ||
                          storedHraStatus[ind] == "19" ||
                          storedHraStatus[ind] == "3"
                            ? "active"
                            : ""
                        }
                        id="step1"
                      >
                        <span>Scheduled</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.scheduled,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.scheduled[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.scheduled[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.scheduled[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                      {storedHraStatus[ind] == "12" && (
                        <li className="active" id="step1">
                          <span>Assessment Cancelled</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory
                              .assessmentCalcelled,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                              memberData[ind]?.hraStatusHistory
                                ?.assessmentCalcelled[0]?.date,
                            )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.assessmentCalcelled[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.assessmentCalcelled[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      )}
                      {storedHraStatus[ind] == "3" && (
                        <li className="active" id="step1">
                          <span>Appointment Cancelled</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory
                              .appointmentCancelled,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                              memberData[ind]?.hraStatusHistory
                                ?.appointmentCancelled[0]?.date,
                            )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.assessmentCalcelled[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.appointmentCancelled[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      )}
                      {storedHraStatus[ind] == "19" && (
                        <li className="active" id="step1">
                          <span>No Show</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.noShow,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                              memberData[ind]?.hraStatusHistory?.noShow[0]
                                ?.date,
                            )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory?.noShow[0]
                                    ?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                            </>
                          )}
                        </li>
                      )}
                      <li
                        className={
                          storedHraStatus[ind] == "2" ||
                          storedHraStatus[ind] == "6" ||
                          storedHraStatus[ind] == "8" ||
                          storedHraStatus[ind] == "7" ||
                          storedHraStatus[ind] == "9" ||
                          storedHraStatus[ind] == "10" ||
                          storedHraStatus[ind] == "11"
                            ? "active"
                            : ""
                        }
                        id="step2"
                      >
                        <span>InProgress</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.inProgress,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.inProgress[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.inProgress[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.inProgress[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                      <li
                        className={
                          storedHraStatus[ind] == "6" ||
                          storedHraStatus[ind] == "8" ||
                          storedHraStatus[ind] == "7" ||
                          storedHraStatus[ind] == "9" ||
                          storedHraStatus[ind] == "10" ||
                          storedHraStatus[ind] == "11"
                            ? "active"
                            : ""
                        }
                        id="step3"
                      >
                        <span>Ready for QA</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.readyForQa,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.readyForQa[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.readyForQa[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.readyForQa[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                      <li
                        className={
                          storedHraStatus[ind] == "8" ||
                          storedHraStatus[ind] == "7" ||
                          storedHraStatus[ind] == "9" ||
                          storedHraStatus[ind] == "10" ||
                          storedHraStatus[ind] == "11"
                            ? "active"
                            : ""
                        }
                        id="step4"
                      >
                        <span>Clinician review QA</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.clinicianReviewQa,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.clinicianReviewQa[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory
                                  ?.clinicianReviewQa[0]?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory
                                  ?.clinicianReviewQa[0]?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                      <li
                        className={
                          storedHraStatus[ind] == "7" ||
                          storedHraStatus[ind] == "9" ||
                          storedHraStatus[ind] == "10" ||
                          storedHraStatus[ind] == "11"
                            ? "active"
                            : ""
                        }
                        id="step5"
                      >
                        <span>Ready for coding</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.readyForCoder,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.readyForCoder[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory
                                  ?.readyForCoder[0]?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory
                                  ?.readyForCoder[0]?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                      <li
                        className={
                          storedHraStatus[ind] == "9" ||
                          storedHraStatus[ind] == "10" ||
                          storedHraStatus[ind] == "11"
                            ? "active"
                            : ""
                        }
                        id="step6"
                      >
                        <span>Clinician review coding</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory
                            .clinicianReviewCoder,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.clinicianReviewCoder[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory
                                  ?.clinicianReviewCoder[0]?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory
                                  ?.clinicianReviewCoder[0]?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                      <li
                        className={
                          storedHraStatus[ind] == "10" ||
                          storedHraStatus[ind] == "11"
                            ? "active"
                            : ""
                        }
                        id="step7"
                      >
                        <span>Ready for upload</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.readyForUpload,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.readyForUpload[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory
                                  ?.readyForUpload[0]?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory
                                  ?.readyForUpload[0]?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                      <li
                        className={storedHraStatus[ind] == "11" ? "active" : ""}
                        id="step8"
                      >
                        <span>Completed</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.completed,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.completed[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.completed[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.completed[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] == "14" && (
                    <ul id="progressbar" className="full-width">
                      <li className="active" id="step1">
                        <span>Ineligible</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.inEligible,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.inEligible[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.inEligible[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.inEligible[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] == "4" && (
                    <ul id="progressbar" className="full-width">
                      <li className="active" id="step1">
                        <span>Patient Refusal</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory.patientRefusal,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.patientRefusal[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory
                                  ?.patientRefusal[0]?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory
                                  ?.patientRefusal[0]?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] == "5" && (
                    <ul id="progressbar" className="full-width">
                      <li className="active" id="step1">
                        <span>UTC (Unable to contact)</span>
                        {!_.isEmpty(memberData[ind]?.hraStatusHistory.utc) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory?.utc[0]
                              ?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.utc[0]?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.utc[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] == "15" && (
                    <ul id="progressbar" className="full-width">
                      <li className="active" id="step1">
                        <span>Deceased</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory?.deceased,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory?.deceased[0]
                              ?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.deceased[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.deceased[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] == "16" && (
                    <ul id="progressbar" className="full-width">
                      <li className="active" id="step1">
                        <span>Hospice</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory?.hospice,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory?.hospice[0]
                              ?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.hospice[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.hospice[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] == "17" && (
                    <ul id="progressbar" className="full-width">
                      <li className="active" id="step1">
                        <span>Others</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory?.other,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory?.other[0]
                              ?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory?.other[0]
                                  ?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory?.other[0]
                                  ?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                {!_.isUndefined(storedHraStatus[ind]) &&
                  storedHraStatus[ind] == "18" && (
                    <ul id="progressbar" className="full-width">
                      <li className="active" id="step1">
                        <span>Term Expired</span>
                        {!_.isEmpty(
                          memberData[ind]?.hraStatusHistory?.termExpired,
                        ) && (
                          <>
                            <span className="dateTime">
                              (
                              {/* {convertToLocalDateTime(
                            memberData[ind]?.hraStatusHistory
                              ?.termExpired[0]?.date,
                          )} */}
                              {dayjs(
                                memberData[ind]?.hraStatusHistory
                                  ?.termExpired[0]?.date,
                              )
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                              )
                            </span>
                            <span className="dateTime">
                              {
                                memberData[ind]?.hraStatusHistory
                                  ?.termExpired[0]?.userName
                              }
                            </span>
                          </>
                        )}
                      </li>
                    </ul>
                  )}

                <div className="scheduler-info">
                  <h4>Scheduler Info:</h4>
                  <table className="table">
                    <thead className="memHistoryThead">
                      <tr>
                        <td>Date / Time</td>
                        <td>Scheduler</td>
                        <td>Result</td>
                        <td>Comments</td>
                        <td>Call Type</td>
                      </tr>
                    </thead>
                    <tbody className="memHistoryTBody">
                      {dynamicSchedulerData[ind] &&
                        Array.isArray(dynamicSchedulerData[ind]) &&
                        dynamicSchedulerData[ind]
                          .slice(0)
                          .reverse()
                          .map((data, index) => (
                            <tr key={index}>
                              <td>
                                {dayjs(data.date)
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                              </td>
                              <td>{data.scheduler}</td>
                              <td>{data.result}</td>
                              <td>{data.comment}</td>
                              <td>{data.inBound ? "Inbound" : "Outbound"}</td>
                            </tr>
                          ))}
                      {dynamicSchedulerData[ind]?.length <= 0 && (
                        <tr>
                          <td colSpan="5" align="center">
                            No Data Found...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isLoading && <Loader />}
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
    </>
  );
}
