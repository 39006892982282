export function updateFormData(name, value, updatedFormData) {
  const isRespiratoryDiagnoses = name === "ros_respiratory" && !value;

  if (name === "ros_respiratory" && value === "yes") {
    updatedFormData.ros_respiratoryDiagnoses = [];
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerDescribe = [];
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryAcutePulmonarySubOtherSubDesc = "";

    updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryAcuteUpperSubOtherSubDesc = "";

    updatedFormData.ros_respiratoryAsthmaSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryAsthmaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryAsthmaPatientController = [];
    updatedFormData.ros_respiratoryAsthmaRescueMedications = [];
    updatedFormData.ros_respiratoryAsthmaCurrentExacerbation = [];
    updatedFormData.ros_respiratoryAsthmaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryAsthmaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryAsthmaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaPatientControllerComment = "";
    updatedFormData.ros_respiratoryAsthmaPatientControllerComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaRescueMedicationsComment = "";
    updatedFormData.ros_respiratoryAsthmaRescueMedicationsComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaCurrentExacerbationComment = "";
    updatedFormData.ros_respiratoryAsthmaCurrentExacerbationComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment =
      "";
    updatedFormData.ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaSubOtherSubDesc = "";

    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribe =
      [];
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc = "";

    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerDescribe =
      [];
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc =
      "";

    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryChronicSputumFailureSubOtherSubDesc = "";

    updatedFormData.ros_respiratoryCopdSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryCopdSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryPatientChronicBronchitis = [];
    updatedFormData.ros_respiratoryPatientHaveEmphysema = [];
    updatedFormData.ros_respiratoryPatientBronchodilator = [];
    updatedFormData.ros_respiratoryPatientSteroids = [];
    updatedFormData.ros_respiratoryPatientExacerbation = [];
    updatedFormData.ros_respiratoryCopdSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryCopdSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryCopdSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryPatientChronicBronchitisComment = "";
    updatedFormData.ros_respiratoryPatientChronicBronchitisComment_toggle = false;
    updatedFormData.ros_respiratoryPatientHaveEmphysemaComment = "";
    updatedFormData.ros_respiratoryPatientHaveEmphysemaComment_toggle = false;
    updatedFormData.ros_respiratoryPatientBronchodilatorComment = "";
    updatedFormData.ros_respiratoryPatientBronchodilatorComment_toggle = false;
    updatedFormData.ros_respiratoryPatientSteroidsComment = "";
    updatedFormData.ros_respiratoryPatientSteroidsComment_toggle = false;
    updatedFormData.ros_respiratoryPatientExacerbationComment = "";
    updatedFormData.ros_respiratoryPatientExacerbationComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubPatientBronchodilator = [];
    updatedFormData.ros_respiratoryCopdSubPatientBronchodilatorComment = "";
    updatedFormData.ros_respiratoryCopdSubPatientBronchodilatorComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubPatientSteroids = [];
    updatedFormData.ros_respiratoryCopdSubPatientSteroidsComment = "";
    updatedFormData.ros_respiratoryCopdSubPatientSteroidsComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubPatientExacerbation = [];
    updatedFormData.ros_respiratoryCopdSubPatientExacerbationComment = "";
    updatedFormData.ros_respiratoryCopdSubPatientExacerbationComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubOtherSubDesc = "";

    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryCysticFibrosisSubDesc = "";

    updatedFormData.ros_respiratoryHypoventilationSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryHypoventilationSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryHypoventilationSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryHypoventilationSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryHypoventilationSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryHypoventilationSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryHypoventilationSubDesc = "";

    updatedFormData.ros_respiratoryHypoxemiaSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaSubDesc = "";

    updatedFormData.ros_respiratoryPneumoniaSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryPneumoniaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiology = [];
    updatedFormData.ros_respiratoryHistoryOfLungAbscess = [];
    updatedFormData.ros_respiratoryHistoryOfEmpyema = [];
    updatedFormData.ros_respiratoryPneumoniaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryPneumoniaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryPneumoniaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiologyComment = "";
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_respiratoryHistoryOfLungAbscessComment = "";
    updatedFormData.ros_respiratoryHistoryOfLungAbscessComment_toggle = false;
    updatedFormData.ros_respiratoryHistoryOfEmpyemaComment = "";
    updatedFormData.ros_respiratoryHistoryOfEmpyemaComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaSubDesc = "";

    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubDesc = "";

    updatedFormData.ros_respiratoryPulmonaryEmbolismSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc =
      "";

    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratorDependenceSubDesc = "";

    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratoryArrestSubDesc = "";

    updatedFormData.ros_respiratorySarcoidosisSubAnswerDescribe = [];
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSpecify = [];
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratorySarcoidosisSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratorySarcoidosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSpecifyComment = "";
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSpecifyComment_toggle = false;
    updatedFormData.ros_respiratorySarcoidosisSubDesc = "";

    updatedFormData.ros_respiratorySleepApneaSubAnswerDescribe = [];
    updatedFormData.ros_respiratorySleepApneaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratorySleepApneaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratorySleepApneaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratorySleepApneaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaSubDesc = "";

    updatedFormData.ros_lungTransplantSubOtherTreatmentSubDesc = "";

    updatedFormData.ros_respiratoryRespOtherSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryRespOtherSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryOtherSubAnswerOther = "";
    updatedFormData.ros_respiratoryRespOtherSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryRespOtherSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryRespOtherSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryRespOtherSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryOtherSubDesc = "";
  }

  if (isRespiratoryDiagnoses) {
    updatedFormData.ros_respiratoryDiagnoses = [];
  }

  // if ( name === "ros_respiratoryDiagnoses" ) {
  //   updatedFormData.ros_acutePulmonarySubOtherSubDesc = "";
  //   updatedFormData.ros_acuteUpperSubOtherSubDesc = "";

  // }

  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("acutePulmonary")
  ) {
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerDescribe = [];
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryAcutePulmonarySubAnswerSupportedByComment_toggle = false;
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("acutePulmonary")) ||
    (name === "ros_respiratoryAcutePulmonarySubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryAcutePulmonarySubOtherSubDesc = "";
  }

  if (name === "ros_respiratoryDiagnoses" && !value?.includes("acuteUpper")) {
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryAcuteUpperSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryAcuteUpperActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment_toggle = false;
  }

  if (
    name === "ros_respiratoryAcuteUpperSubAnswerDescribe" &&
    !value?.includes("acuteUpper")
  ) {
    updatedFormData.ros_respiratoryAcuteUpperActiveTreatmentSubAnswer = [];
    //updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribeComment = "";
    //updatedFormData.ros_respiratoryAcuteUpperSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("acuteUpper")) ||
    (name === "ros_respiratoryAcuteUpperSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryAcuteUpperSubOtherSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("acuteUpper")) ||
    (name === "ros_respiratoryAcuteUpperActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc = "";
  }

  if (name === "ros_respiratoryDiagnoses" && !value?.includes("asthma")) {
    updatedFormData.ros_respiratoryAsthmaSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryAsthmaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryAsthmaPatientController = [];
    updatedFormData.ros_respiratoryAsthmaRescueMedications = [];
    updatedFormData.ros_respiratoryAsthmaCurrentExacerbation = [];
    updatedFormData.ros_respiratoryAsthmaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryAsthmaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryAsthmaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaPatientControllerComment = "";
    updatedFormData.ros_respiratoryAsthmaPatientControllerComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaRescueMedicationsComment = "";
    updatedFormData.ros_respiratoryAsthmaRescueMedicationsComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaCurrentExacerbationComment = "";
    updatedFormData.ros_respiratoryAsthmaCurrentExacerbationComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswer = [];
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswerComment = "";
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswerComment_toggle = false;
  }

  if (
    name === "ros_respiratoryAsthmaSubAnswerDescribe" &&
    !value?.includes("asthma")
  ) {
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_respiratoryAsthmaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryAsthmaSubOtherTreatmentSubDesc = "";
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswer = [];
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswerComment = "";
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswerComment_toggle = false;
    //updatedFormData.ros_respiratoryAsthmaSubAnswerDescribeComment = "";
    //updatedFormData.ros_respiratoryAsthmaSubAnswerDescribeComment_toggle = false;
  }

  if (name === "ros_respiratoryAsthmaCurrentExacerbation" && value === "yes") {
    updatedFormData.ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbation =
      [];
    updatedFormData.ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment =
      "";
    updatedFormData.ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment_toggle = false;
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("asthma")) ||
    (name === "ros_respiratoryAsthmaActiveTreatmentSubAnswer" &&
      !value?.includes("medication"))
  ) {
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswer = [];
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswerComment = "";
    updatedFormData.ros_respiratoryAsthmaMedicationSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("asthma")) ||
    (name === "ros_respiratoryAsthmaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryAsthmaSubOtherSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("asthma")) ||
    (name === "ros_respiratoryAsthmaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryAsthmaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("chronicPulmonaryEmbolism")
  ) {
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribe =
      [];
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment_toggle = false;
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("chronicPulmonaryEmbolism")) ||
    (name === "ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc = "";
  }
  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("chronicRespiratoryFailure")
  ) {
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerDescribe =
      [];
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("chronicRespiratoryFailure")) ||
    (name === "ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("chronicRespiratoryFailure")) ||
    (name === "ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribe" &&
    !value?.includes("chronicRespiratoryFailure")
  ) {
    updatedFormData.ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswer =
      [];
    //updatedFormData.ros_respiratoryacuteUpperSubAnswerDescribeComment = "";
    //updatedFormData.ros_respiratoryacuteUpperSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("chronicSputumFailure")
  ) {
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("chronicSputumFailure")) ||
    (name === "ros_respiratoryChronicSputumFailureSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryChronicSputumFailureSubOtherSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("chronicSputumFailure")) ||
    (name === "ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryChronicSputumFailureSubAnswerDescribe" &&
    !value?.includes("chronicSputumFailure")
  ) {
    updatedFormData.ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswer =
      [];
    //updatedFormData.ros_acuteUpperSubAnswerDescribeComment = "";
    //updatedFormData.ros_acuteUpperSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc =
      "";
  }

  if (name === "ros_respiratoryDiagnoses" && !value?.includes("copd")) {
    updatedFormData.ros_respiratoryCopdSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryCopdSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryCatientChronicBronchitis = [];
    updatedFormData.ros_respiratoryCatientHaveEmphysema = [];
    updatedFormData.ros_respiratoryCatientBronchodilator = [];
    updatedFormData.ros_respiratoryCatientSteroids = [];
    updatedFormData.ros_respiratoryCatientExacerbation = [];
    updatedFormData.ros_respiratoryCopdSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryCopdSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryCopdSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryCatientChronicBronchitisComment = "";
    updatedFormData.ros_respiratoryCatientChronicBronchitisComment_toggle = false;
    updatedFormData.ros_respiratoryCatientHaveEmphysemaComment = "";
    updatedFormData.ros_respiratoryCatientHaveEmphysemaComment_toggle = false;
    updatedFormData.ros_respiratoryCatientBronchodilatorComment = "";
    updatedFormData.ros_respiratoryCatientBronchodilatorComment_toggle = false;
    updatedFormData.ros_respiratoryCatientSteroidsComment = "";
    updatedFormData.ros_respiratoryCatientSteroidsComment_toggle = false;
    updatedFormData.ros_respiratoryCatientExacerbationComment = "";
    updatedFormData.ros_respiratoryCatientExacerbationComment_toggle = false;
    updatedFormData.ros_respiratoryCopdTypeSubAnswer = [];
    updatedFormData.ros_respiratoryCopdTypeSubAnswerComment = "";
    updatedFormData.ros_respiratoryCopdTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryCopdActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryCopdActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_respiratoryCopdActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryCopdMedicationSubAnswer = [];
    updatedFormData.ros_respiratoryCopdMedicationSubAnswerComment = "";
    updatedFormData.ros_respiratoryCopdMedicationSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("copd")) ||
    (name === "ros_respiratoryCopdActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryCopdSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratoryCopdSubAnswerDescribe" &&
    !value?.includes("copd")
  ) {
    updatedFormData.ros_respiratoryCopdTypeSubAnswer = [];
    updatedFormData.ros_respiratoryCopdTypeSubAnswerComment = "";
    updatedFormData.ros_respiratoryCopdTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryCopdActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryCopdActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_respiratoryCopdActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryCopdSubOtherTreatmentSubDesc = "";

    updatedFormData.ros_respiratoryCopdMedicationSubAnswer = [];
    updatedFormData.ros_respiratoryCopdMedicationSubAnswerComment = "";
    updatedFormData.ros_respiratoryCopdMedicationSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPatientExacerbation = [];
    updatedFormData.ros_respiratoryPatientExacerbationComment = "";
    updatedFormData.ros_respiratoryPatientExacerbationComment_toggle = false;
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("copd")) ||
    (name === "ros_respiratoryCopdActiveTreatmentSubAnswer" &&
      !value?.includes("medication"))
  ) {
    updatedFormData.ros_respiratoryCopdMedicationSubAnswer = [];
    updatedFormData.ros_respiratoryCopdMedicationSubAnswerComment = "";
    updatedFormData.ros_respiratoryCopdMedicationSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPatientExacerbation = [];
    updatedFormData.ros_respiratoryPatientExacerbationComment = "";
    updatedFormData.ros_respiratoryPatientExacerbationComment_toggle = false;
  }

  if (name === "ros_respiratoryPatientBronchodilator" && value === "yes") {
    updatedFormData.ros_respiratoryCopdSubPatientBronchodilator = [];
    updatedFormData.ros_respiratoryCopdSubPatientBronchodilatorComment = "";
    updatedFormData.ros_respiratoryCopdSubPatientBronchodilatorComment_toggle = false;
  }
  if (name === "ros_respiratoryPatientSteroids" && value === "yes") {
    updatedFormData.ros_respiratoryCopdSubPatientSteroids = [];
    updatedFormData.ros_respiratoryCopdSubPatientSteroidsComment = "";
    updatedFormData.ros_respiratoryCopdSubPatientSteroidsComment_toggle = false;
  }
  if (name === "ros_respiratoryPatientExacerbation" && value === "yes") {
    updatedFormData.ros_respiratoryCopdSubPatientExacerbation = [];
    updatedFormData.ros_respiratoryCopdSubPatientExacerbationComment = "";
    updatedFormData.ros_respiratoryCopdSubPatientExacerbationComment_toggle = false;
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("copd")) ||
    (name === "ros_respiratoryCopdSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryCopdSubOtherSubDesc = "";
  }
  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("cysticFibrosis")
  ) {
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryCysticFibrosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryCysticFibrosisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("cysticFibrosis")) ||
    (name === "ros_respiratoryCysticFibrosisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryCysticFibrosisSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("cysticFibrosis")) ||
    (name === "ros_respiratoryCysticFibrosisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratoryCysticFibrosisSubAnswerDescribe" &&
    !value?.includes("cysticFibrosis")
  ) {
    updatedFormData.ros_respiratoryCysticFibrosisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("hypoventilation")
  ) {
    updatedFormData.ros_respiratoryHypoventilationSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryHypoventilationSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryHypoventilationSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryHypoventilationSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryHypoventilationSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryHypoventilationSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryHypoventilationActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratoryHypoventilationActiveSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryHypoventilationActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryHypoventilationActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryHypoventilationSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("hypoventilation")) ||
    (name === "ros_respiratoryHypoventilationActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryHypoventilationSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratoryHypoventilationSubAnswerDescribe" &&
    !value?.includes("hypoventilation")
  ) {
    updatedFormData.ros_respiratoryHypoventilationActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratoryHypoventilationActiveSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryHypoventilationActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryHypoventilationActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryHypoventilationSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("hypoventilation")) ||
    (name === "ros_respiratoryHypoventilationSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryHypoventilationSubDesc = "";
  }
  if (name === "ros_respiratoryDiagnoses" && !value?.includes("hypoxemia")) {
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryHypoxemiaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratoryHypoxemiaActiveSelectSubAnswerComment = "";
    updatedFormData.ros_respiratoryHypoxemiaActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("hypoxemia")) ||
    (name === "ros_respiratoryHypoxemiaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratoryHypoxemiaSubAnswerDescribe" &&
    !value?.includes("hypoxemia")
  ) {
    updatedFormData.ros_respiratoryHypoxemiaActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratoryHypoxemiaActiveSelectSubAnswerComment = "";
    updatedFormData.ros_respiratoryHypoxemiaActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("hypoxemia")) ||
    (name === "ros_respiratoryHypoxemiaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryHypoxemiaSubDesc = "";
  }
  if (name === "ros_respiratoryDiagnoses" && !value?.includes("pneumonia")) {
    updatedFormData.ros_respiratoryPneumoniaSubAnswerDescribe = [];
    updatedFormData.ros_respiratorypneumoniaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiology = [];
    updatedFormData.ros_respiratoryHistoryOfLungAbscess = [];
    updatedFormData.ros_respiratoryHistoryOfEmpyema = [];
    updatedFormData.ros_respiratoryPneumoniaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryPneumoniaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryPneumoniaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiologyComment = "";
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_respiratoryHistoryOfLungAbscessComment = "";
    updatedFormData.ros_respiratoryHistoryOfLungAbscessComment_toggle = false;
    updatedFormData.ros_respiratoryHistoryOfEmpyemaComment = "";
    updatedFormData.ros_respiratoryHistoryOfEmpyemaComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("pneumonia")) ||
    (name === "ros_respiratoryPneumoniaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryPneumoniaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratoryPneumoniaSubAnswerDescribe" &&
    !value?.includes("pneumonia")
  ) {
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiology = [];
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiologyComment = "";
    updatedFormData.ros_respiratoryPneumoniaSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_respiratoryHistoryOfLungAbscess = [];
    updatedFormData.ros_respiratoryHistoryOfLungAbscessComment = "";
    updatedFormData.ros_respiratoryHistoryOfLungAbscessComment_toggle = false;
    updatedFormData.ros_respiratoryHistoryOfEmpyema = [];
    updatedFormData.ros_respiratoryHistoryOfEmpyemaComment = "";
    updatedFormData.ros_respiratoryHistoryOfEmpyemaComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPneumoniaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("pneumonia")) ||
    (name === "ros_respiratoryPneumoniaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryPneumoniaSubDesc = "";
  }
  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("pulmonaryFibrosis")
  ) {
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("pulmonaryFibrosis")) ||
    (name === "ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryPulmonaryFibrosisSubAnswerDescribe" &&
    !value?.includes("pulmonaryFibrosis")
  ) {
    updatedFormData.ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("pulmonaryFibrosis")) ||
    (name === "ros_respiratoryPulmonaryFibrosisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryPulmonaryFibrosisSubDesc = "";
  }

  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("pulmonaryEmbolism")
  ) {
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("pulmonaryEmbolism")) ||
    (name === "ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("pulmonaryEmbolism")) ||
    (name === "ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryPulmonaryEmbolismSubAnswerDescribe" &&
    !value?.includes("pulmonaryEmbolism")
  ) {
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswer =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc =
      "";
    updatedFormData.ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("respiratorDependence")
  ) {
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerSupportedBy =
      [];
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("respiratorDependence")) ||
    (name === "ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryRespiratorDependenceSubAnswerDescribe" &&
    !value?.includes("respiratorDependence")
  ) {
    updatedFormData.ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("respiratorDependence")) ||
    (name === "ros_respiratoryRespiratorDependenceSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryRespiratorDependenceSubDesc = "";
  }
  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("respiratoryArrest")
  ) {
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerDescribeComment =
      "";
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratoryArrestActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratoryArrestActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_respiratoryArrestActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryArrestSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("respiratoryArrest")) ||
    (name === "ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_respiratoryRespiratoryArrestSubAnswerDescribe" &&
    !value?.includes("respiratoryArrest")
  ) {
    updatedFormData.ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratoryArrestSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" &&
      !value?.includes("respiratoryArrest")) ||
    (name === "ros_respiratoryRespiratoryArrestSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryRespiratoryArrestSubDesc = "";
  }
  if (name === "ros_respiratoryDiagnoses" && !value?.includes("sarcoidosis")) {
    updatedFormData.ros_respiratorySarcoidosisSubAnswerDescribe = [];
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSpecify = [];
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratorySarcoidosisSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratorySarcoidosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSpecifyComment = "";
    updatedFormData.ros_respiratorySarcoidosisSubAnswerSpecifyComment_toggle = false;
    updatedFormData.ros_respiratorySarcoidosisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratorySarcoidosisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("sarcoidosis")) ||
    (name === "ros_respiratorySarcoidosisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratorySarcoidosisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("sarcoidosis")) ||
    (name === "ros_respiratorySarcoidosisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratorySarcoidosisSubDesc = "";
  }
  if (name === "ros_respiratoryDiagnoses" && !value?.includes("sleepApnea")) {
    updatedFormData.ros_respiratorySleepApneaSubAnswerDescribe = [];
    updatedFormData.ros_respiratorySleepApneaSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratorySleepApneaSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratorySleepApneaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratorySleepApneaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratorySleepApneaActiveSelectSubAnswerComment = "";
    updatedFormData.ros_respiratorySleepApneaActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratorySleepApneaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratorySleepApneaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("sleepApnea")) ||
    (name === "ros_respiratorySleepApneaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_respiratorySleepApneaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_respiratorySleepApneaSubAnswerDescribe" &&
    !value?.includes("sleepApnea")
  ) {
    updatedFormData.ros_respiratorySleepApneaActiveSelectSubAnswer = [];
    updatedFormData.ros_respiratorySleepApneaActiveSelectSubAnswerComment = "";
    updatedFormData.ros_respiratorySleepApneaActiveSelectSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_respiratorySleepApneaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_respiratorySleepApneaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_respiratorySleepApneaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("sleepApnea")) ||
    (name === "ros_respiratorySleepApneaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratorySleepApneaSubDesc = "";
  }

  if (
    name === "ros_respiratoryDiagnoses" &&
    !value?.includes("lungTransplant")
  ) {
    updatedFormData.ros_respiratoryLungTransplantSubOtherTreatmentSubDesc = "";
  }

  if (name === "ros_respiratoryDiagnoses" && !value?.includes("other")) {
    updatedFormData.ros_respiratoryRespOtherSubAnswerDescribe = [];
    updatedFormData.ros_respiratoryRespOtherSubAnswerSupportedBy = [];
    updatedFormData.ros_respiratoryOtherSubAnswerOther = "";
    updatedFormData.ros_respiratoryRespOtherSubAnswerDescribeComment = "";
    updatedFormData.ros_respiratoryRespOtherSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_respiratoryRespOtherSubAnswerSupportedByComment = "";
    updatedFormData.ros_respiratoryRespOtherSubAnswerSupportedByComment_toggle = false;
  }

  if (
    (name === "ros_respiratoryDiagnoses" && !value?.includes("other")) ||
    (name === "ros_respiratoryRespOtherSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_respiratoryOtherSubDesc = "";
  }

  if (
    // (name === "ros_respiratoryDiagnoses" && !value?.includes("symptoms")) ||
    name === "ros_respiratorySymptoms" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_respiratorySymptomsSubOtherSubDesc = "";
  }

  //integument
  const isIntegumentDiagnoses = name === "ros_integument" && !value;

  if (name === "ros_integument" && value === "yes") {
    updatedFormData.ros_integumentDiagnoses = [];
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerDescribe = [];
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerDescribeComment =
      "";
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentBasilCellCarcinomaSubOtherSubDesc = "";

    updatedFormData.ros_integumentDermatitisSubAnswerDescribe = [];
    updatedFormData.ros_integumentDermatitisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentDermatitisSubAnswerWhatType = [];
    updatedFormData.ros_integumentDermatitisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentDermatitisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentDermatitisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubAnswerWhatTypeComment = "";
    updatedFormData.ros_integumentDermatitisSubAnswerWhatTypeComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubOtherSubDesc = "";

    updatedFormData.ros_integumentEczemaSubAnswerDescribe = [];
    updatedFormData.ros_integumentEczemaSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentEczemaSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentEczemaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentEczemaSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentEczemaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentEczemaSubOtherSubDesc = "";

    updatedFormData.ros_integumentOnychomycosisSubAnswerDescribe = [];
    updatedFormData.ros_integumentOnychomycosisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentOnychomycosisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentOnychomycosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentOnychomycosisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentOnychomycosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentOnychomycosisSubOtherSubDesc = "";

    updatedFormData.ros_integumentPsoriasisSubAnswerDescribe = [];
    updatedFormData.ros_integumentPsoriasisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritis = [];
    updatedFormData.ros_integumentPsoriasisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentPsoriasisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentPsoriasisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment =
      "";
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisSubOtherSubDesc = "";

    updatedFormData.ros_integumentSkinUlcerSubAnswerDescribe = [];
    updatedFormData.ros_integumentSkinUlcerSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentSkinUlcerSubAnswerEtiology = [];
    updatedFormData.ros_integumentSkinUlcerSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentSkinUlcerSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentSkinUlcerSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubAnswerEtiologyComment = "";
    updatedFormData.ros_integumentSkinUlcerSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubOtherSubDesc = "";

    updatedFormData.ros_integumentTineaPedisSubAnswerDescribe = [];
    updatedFormData.ros_integumentTineaPedisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentTineaPedisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentTineaPedisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentTineaPedisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisSubOtherSubDesc = "";

    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerDescribe = [];
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerType = [];
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerEtiology = "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerDescribeComment =
      "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerTypeComment = "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerTypeComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubOtherSubDesc = "";

    updatedFormData.ros_integumentWoundSubAnswerDescribe = [];
    updatedFormData.ros_integumentWoundSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentWoundSubAnswerEtiology = [];
    updatedFormData.ros_integumentWoundSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentWoundSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentWoundSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentWoundSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentWoundSubAnswerEtiologyComment = "";
    updatedFormData.ros_integumentWoundSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_integumentWoundSubOtherSubDesc = "";

    updatedFormData.ros_integumentOthersSubAnswerDescribe = [];
    updatedFormData.ros_integumentOthersSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentOthersSubAnswerOther = "";
    updatedFormData.ros_integumentOthersSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentOthersSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentOthersSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentOthersSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentOthersSubAnswerSupportedBySymptoms = "";
    updatedFormData.ros_integumentIntegumentOtherSubOtherSubDesc = "";
  }

  if (isIntegumentDiagnoses) {
    updatedFormData.ros_integumentDiagnoses = [];
  }

  if (
    name === "ros_integumentDiagnoses" &&
    !value?.includes("basilCellCarcinoma")
  ) {
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerDescribe = [];
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerDescribeComment =
      "";
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentBasilCellCarcinomaSubLocation = "";
    updatedFormData.ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_integumentDiagnoses" &&
      !value?.includes("basilCellCarcinoma")) ||
    (name === "ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_integumentBasilCellCarcinomaSubAnswerDescribe" &&
    !value?.includes("basilCellCarcinoma")
  ) {
    updatedFormData.ros_integumentBasilCellCarcinomaSubLocation = "";
    updatedFormData.ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_integumentDiagnoses" &&
      !value?.includes("basilCellCarcinoma")) ||
    (name === "ros_integumentBasilCellCarcinomaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentBasilCellCarcinomaSubOtherSubDesc = "";
  }
  if (name === "ros_integumentDiagnoses" && !value?.includes("dermatitis")) {
    updatedFormData.ros_integumentDermatitisSubAnswerDescribe = [];
    updatedFormData.ros_integumentDermatitisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentDermatitisSubAnswerWhatType = [];
    updatedFormData.ros_integumentDermatitisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentDermatitisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentDermatitisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubAnswerWhatTypeComment = "";
    updatedFormData.ros_integumentDermatitisSubAnswerWhatTypeComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubLocation = "";
    updatedFormData.ros_integumentDermatitisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentDermatitisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentDermatitisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("dermatitis")) ||
    (name === "ros_integumentDermatitisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentDermatitisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentDermatitisSubAnswerDescribe" &&
    !value?.includes("dermatitis")
  ) {
    updatedFormData.ros_integumentDermatitisSubLocation = "";
    updatedFormData.ros_integumentDermatitisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentDermatitisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentDermatitisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentDermatitisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("dermatitis")) ||
    (name === "ros_integumentDermatitisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentDermatitisSubOtherSubDesc = "";
  }
  if (name === "ros_integumentDiagnoses" && !value?.includes("eczema")) {
    updatedFormData.ros_integumentEczemaSubAnswerDescribe = [];
    updatedFormData.ros_integumentEczemaSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentEczemaSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentEczemaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentEczemaSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentEczemaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentEczemaSubLocation = "";
    updatedFormData.ros_integumentEczemaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentEczemaActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentEczemaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentEczemaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("eczema")) ||
    (name === "ros_integumentEczemaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentEczemaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentEczemaSubAnswerDescribe" &&
    !value?.includes("eczema")
  ) {
    updatedFormData.ros_integumentEczemaSubLocation = "";
    updatedFormData.ros_integumentEczemaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentEczemaActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentEczemaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentEczemaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("eczema")) ||
    (name === "ros_integumentEczemaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentEczemaSubOtherSubDesc = "";
  }
  if (name === "ros_integumentDiagnoses" && !value?.includes("onychomycosis")) {
    updatedFormData.ros_integumentOnychomycosisSubAnswerDescribe = [];
    updatedFormData.ros_integumentOnychomycosisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentOnychomycosisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentOnychomycosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentOnychomycosisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentOnychomycosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentOnychomycosisSubLocation = "";
    updatedFormData.ros_integumentOnychomycosisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentOnychomycosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentOnychomycosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentOnychomycosisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("onychomycosis")) ||
    (name === "ros_integumentOnychomycosisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentOnychomycosisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentOnychomycosisSubAnswerDescribe" &&
    !value?.includes("onychomycosis")
  ) {
    updatedFormData.ros_integumentOnychomycosisSubLocation = "";
    updatedFormData.ros_integumentOnychomycosisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentOnychomycosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentOnychomycosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentOnychomycosisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("onychomycosis")) ||
    (name === "ros_integumentOnychomycosisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentOnychomycosisSubOtherSubDesc = "";
  }
  if (name === "ros_integumentDiagnoses" && !value?.includes("psoriasis")) {
    updatedFormData.ros_integumentPsoriasisSubAnswerDescribe = [];
    updatedFormData.ros_integumentPsoriasisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritis = [];
    updatedFormData.ros_integumentPsoriasisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentPsoriasisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentPsoriasisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment =
      "";
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentPsoriasisActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentPsoriasisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("psoriasis")) ||
    (name === "ros_integumentPsoriasisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentPsoriasisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentPsoriasisSubAnswerDescribe" &&
    !value?.includes("psoriasis")
  ) {
    updatedFormData.ros_integumentPsoriasisSubLocation = "";
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritis = [];
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment =
      "";
    updatedFormData.ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentPsoriasisActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentPsoriasisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentPsoriasisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("psoriasis")) ||
    (name === "ros_integumentPsoriasisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentPsoriasisSubOtherSubDesc = "";
  }
  if (name === "ros_integumentDiagnoses" && !value?.includes("skinUlcer")) {
    updatedFormData.ros_integumentSkinUlcerSubAnswerDescribe = [];
    updatedFormData.ros_integumentSkinUlcerSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentSkinUlcerSubAnswerEtiology = [];
    updatedFormData.ros_integumentSkinUlcerSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentSkinUlcerSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentSkinUlcerSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubAnswerEtiologyComment = "";
    updatedFormData.ros_integumentSkinUlcerSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubLocation = "";
    updatedFormData.ros_integumentSkinUlcerActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentSkinUlcerActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentSkinUlcerActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("skinUlcer")) ||
    (name === "ros_integumentSkinUlcerActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentSkinUlcerSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentSkinUlcerSubAnswerDescribe" &&
    !value?.includes("skinUlcer")
  ) {
    updatedFormData.ros_integumentSkinUlcerSubLocation = "";
    updatedFormData.ros_integumentSkinUlcerActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentSkinUlcerActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentSkinUlcerActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentSkinUlcerSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentDiagnoses" ||
    name === "ros_integumentSkinUlcerSubAnswerEtiology"
    //  && !value?.includes("pressure")
  ) {
    updatedFormData.ros_integumentSkinUlcerSubEtiologySubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("skinUlcer")) ||
    (name === "ros_integumentSkinUlcerSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentSkinUlcerSubOtherSubDesc = "";
  }

  if (name === "ros_integumentDiagnoses" && !value?.includes("tineaPedis")) {
    updatedFormData.ros_integumentTineaPedisSubAnswerDescribe = [];
    updatedFormData.ros_integumentTineaPedisSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentTineaPedisSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentTineaPedisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentTineaPedisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisSelectTreatmentSubAnswer = [];
    updatedFormData.ros_integumentTineaPedisSelectTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentTineaPedisSelectTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentTineaPedisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentTineaPedisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("tineaPedis")) ||
    (name === "ros_integumentTineaPedisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentTineaPedisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentTineaPedisSubAnswerDescribe" &&
    !value?.includes("tineaPedis")
  ) {
    updatedFormData.ros_integumentTineaPedisSelectTreatmentSubAnswer = [];
    updatedFormData.ros_integumentTineaPedisSelectTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentTineaPedisSelectTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentTineaPedisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentTineaPedisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentTineaPedisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("tineaPedis")) ||
    (name === "ros_integumenTineaPedisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentTineaPedisSubOtherSubDesc = "";
  }
  if (
    name === "ros_integumentDiagnoses" &&
    !value?.includes("urticarialDisease")
  ) {
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerDescribe = [];
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerType = [];
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerEtiology = "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerDescribeComment =
      "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerTypeComment = "";
    updatedFormData.ros_integumentUrticarialDiseaseSubAnswerTypeComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubLocation = "";
    updatedFormData.ros_integumentUrticarialDiseaseActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_integumentDiagnoses" &&
      !value?.includes("urticarialDisease")) ||
    (name === "ros_integumentUrticarialDiseaseActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_integumentUrticarialDiseaseSubAnswerDescribe" &&
    !value?.includes("urticarialDisease")
  ) {
    updatedFormData.ros_integumentUrticarialDiseaseSubLocation = "";
    updatedFormData.ros_integumentUrticarialDiseaseActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_integumentDiagnoses" &&
      !value?.includes("urticarialDisease")) ||
    (name === "ros_integumentUrticarialDiseaseSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentUrticarialDiseaseSubOtherSubDesc = "";
  }
  if (name === "ros_integumentDiagnoses" && !value?.includes("wound")) {
    updatedFormData.ros_integumentWoundSubAnswerDescribe = [];
    updatedFormData.ros_integumentWoundSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentWoundSubAnswerEtiology = [];
    updatedFormData.ros_integumentWoundSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentWoundSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentWoundSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentWoundSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentWoundSubAnswerEtiologyComment = "";
    updatedFormData.ros_integumentWoundSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_integumentWoundSubLocation = "";
    updatedFormData.ros_integumentWoundActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentWoundActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentWoundActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentWoundSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("wound")) ||
    (name === "ros_integumentWoundActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentWoundSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentWoundSubAnswerDescribe" &&
    !value?.includes("wound")
  ) {
    updatedFormData.ros_integumentWoundSubLocation = "";
    updatedFormData.ros_integumentWoundActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentWoundActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentWoundActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentWoundSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("wound")) ||
    (name === "ros_integumentWoundSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentWoundSubOtherSubDesc = "";
  }

  if (name === "ros_integumentDiagnoses" && !value?.includes("other")) {
    updatedFormData.ros_integumentOthersSubAnswerDescribe = [];
    updatedFormData.ros_integumentOthersSubAnswerSupportedBy = [];
    updatedFormData.ros_integumentIntegumentOthersSubAnswerOther = "";
    updatedFormData.ros_integumentOthersSubAnswerDescribeComment = "";
    updatedFormData.ros_integumentOthersSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_integumentOthersSubAnswerSupportedByComment = "";
    updatedFormData.ros_integumentOthersSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_integumentOthersSubAnswerSupportedBySymptoms = "";
    updatedFormData.ros_integumentOthersSubLocation = "";
    updatedFormData.ros_integumentOthersActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentOthersActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentOthersActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentOthersSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("other")) ||
    (name === "ros_integumentOthersActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_integumentOthersSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_integumentOthersSubAnswerDescribe" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_integumentOthersSubLocation = "";
    updatedFormData.ros_integumentOthersActiveTreatmentSubAnswer = [];
    updatedFormData.ros_integumentOthersActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_integumentOthersActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_integumentOthersSubOtherTreatmentSubDesc = "";
    updatedFormData.ros_integumentOthersSubAnswerOther = "";
  }

  if (
    (name === "ros_integumentDiagnoses" && !value?.includes("other")) ||
    (name === "ros_integumentOthersSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_integumentIntegumentOtherSubOtherSubDesc = "";
  }

  //Musko

  const isMuscuDiagnoses = name === "ros_musculoskeletal" && !value;

  if (name === "ros_musculoskeletal" && value === "yes") {
    updatedFormData.ros_musculoskeletalDiagnoses = [];
    updatedFormData.ros_musculoskeletalMuskoHadAnAmputation = [];
    updatedFormData.ros_musculoskeletalMuskoHadAnAmputationComment = "";
    updatedFormData.ros_musculoskeletalMuskoHadAnAmputationComment_toggle = false;
    updatedFormData.ros_musculoskeletalMuskoSymptoms = [];
    updatedFormData.ros_musculoskeletalMuskoSymptomsComment = "";
    updatedFormData.ros_musculoskeletalMuskoSymptomsComment_toggle = false;
    updatedFormData.ros_musculoskeletalMuskoSymptomsSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalDermatomyositisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalDermatomyositisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalDermatomyositisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc =
      "";

    updatedFormData.ros_musculoskeletalCollagenSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalCollagenSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalCollagenSubAnswercollagenDescribe = [];
    updatedFormData.ros_musculoskeletalCollagenSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalCollagenSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalCollagenSubAnswerSupportedByComment = "";
    updatedFormData.ros_musculoskeletalCollagenSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalCollagenSubAnswercollagenDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalCollagenSubAnswercollagenDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalCollagenSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerExtremity = [];
    updatedFormData.ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerExtremityComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerExtremityComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment_toggle = false;

    updatedFormData.ros_musculoskeletalExtremityFractureShoulderSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureArmSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureForearmSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureWristSubAnswer = [];
    updatedFormData.ros_musculoskeletalHandSubAnswer = [];
    updatedFormData.ros_musculoskeletalFemoralShaftSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureTibiaSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureFibulaSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureAnkleSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureFootSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureHipSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureShoulderSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureShoulderSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureArmSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureArmSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureForearmSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureForearmSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureWristSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureWristSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureHandSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureHandSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalFemoralShaftSubAnswerComment = "";
    updatedFormData.ros_musculoskeletalFemoralShaftSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureTibiaSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureTibiaSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureFibulaSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureFibulaSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureAnkleSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureAnkleSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureFootSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureFootSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureHipSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureHipSubAnswerComment_toggle = false;

    updatedFormData.ros_musculoskeletalGoutSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalGoutSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalGoutSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalGoutSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalGoutSubAnswerSupportedByComment = "";
    updatedFormData.ros_musculoskeletalGoutSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalGoutSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalHalluxValgusSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalHammerToesSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints = "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteomyelitisSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoporosisSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerDescribe =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment_toggle = false;
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubDesc = "";

    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalSystemicLupusSubOtherSubDesc = "";

    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerother = "";
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalMuskoSubOtherSubDesc = "";
  }

  if (isMuscuDiagnoses) {
    updatedFormData.ros_musculoskeletalDiagnoses = [];
    // updatedFormData.ros_musculoskeletalMuskoHadAnAmputation = [];
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("dermatomyositis")
  ) {
    updatedFormData.ros_musculoskeletalDermatomyositisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalDermatomyositisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalDermatomyositisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("dermatomyositis")) ||
    (name === "ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalDermatomyositisSubAnswerDescribe" &&
    !value?.includes("dermatomyositis")
  ) {
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc =
      "";
  }

  if (name === "ros_musculoskeletalDiagnoses" && !value?.includes("collagen")) {
    updatedFormData.ros_musculoskeletalCollagenSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalCollagenSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalCollagenSubAnswercollagenDescribe = [];
    updatedFormData.ros_musculoskeletalCollagenSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalCollagenSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalCollagenSubAnswerSupportedByComment = "";
    updatedFormData.ros_musculoskeletalCollagenSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalCollagenSubAnswercollagenDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalCollagenSubAnswercollagenDescribeComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" && !value?.includes("collagen")) ||
    (name === "ros_musculoskeletalCollagenSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalCollagenSubOtherSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("degenerativeDiscDisease")
  ) {
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("degenerativeDiscDisease")) ||
    (name ===
      "ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe" &&
    !value?.includes("degenerativeDiscDisease")
  ) {
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc =
      "";
    // updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder =
    //   [];
    // updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease =
    //   [];
    // updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
    //   "";
    // updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
    //   "";
  }

  if (
    name === "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe" &&
    !value?.includes("degenerativeDiscDisease")
  ) {
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease =
      [];
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment_toggle = false;
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
      "";
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("degenerativeDiscDisease")) ||
    (name ===
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("degenerativeDiscDisease")) ||
    (name ===
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("extremityFracture")
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerExtremity = [];
    updatedFormData.ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerExtremityComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerExtremityComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalExtremityFractureSubAnswerDescribe" &&
    !value?.includes("extremityFracture")
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc =
      "";
  }

  if (name === "ros_musculoskeletalExtremityFractureSubAnswerDescribe") {
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureSubOtherSubDesc = "";
  }
  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("extremityFracture")
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureSubAnswerExtremity = [];
  }
  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("shoulder"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureShoulderSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureShoulderSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureShoulderSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("arm"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureArmSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureArmSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureArmSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("forearm"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureForearmSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureForearmSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureForearmSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("wrist"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureWristSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureWristSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureWristSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("hand"))
  ) {
    updatedFormData.ros_musculoskeletalHandSubAnswer = [];
    updatedFormData.ros_musculoskeletalHandSubAnswerComment = "";
    updatedFormData.ros_musculoskeletalHandSubAnswerComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("femoralShaft"))
  ) {
    updatedFormData.ros_musculoskeletalFemoralShaftSubAnswer = [];
    updatedFormData.ros_musculoskeletalFemoralShaftSubAnswerComment = "";
    updatedFormData.ros_musculoskeletalFemoralShaftSubAnswerComment_toggle = false;
  }
  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("tibia"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureTibiaSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureTibiaSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureTibiaSubAnswerComment_toggle = false;
  }
  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("fibula"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureFibulaSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureFibulaSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureFibulaSubAnswerComment_toggle = false;
  }
  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("ankle"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureAnkleSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureAnkleSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureAnkleSubAnswerComment_toggle = false;
  }
  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("foot"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureFootSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureFootSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureFootSubAnswerComment_toggle = false;
  }
  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("extremityFracture")) ||
    (name === "ros_musculoskeletalExtremityFractureSubAnswerExtremity" &&
      !value?.includes("hip"))
  ) {
    updatedFormData.ros_musculoskeletalExtremityFractureHipSubAnswer = [];
    updatedFormData.ros_musculoskeletalExtremityFractureHipSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalExtremityFractureHipSubAnswerComment_toggle = false;
  }

  if (name === "ros_musculoskeletalDiagnoses" && !value?.includes("gout")) {
    updatedFormData.ros_musculoskeletalGoutSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalGoutSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalGoutSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalGoutSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalGoutSubAnswerSupportedByComment = "";
    updatedFormData.ros_musculoskeletalGoutSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalGoutActiveTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalGoutActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_musculoskeletalGoutActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalGoutSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" && !value?.includes("gout")) ||
    (name === "ros_musculoskeletalGoutActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalGoutSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalGoutSubAnswerDescribe" &&
    !value?.includes("gout")
  ) {
    updatedFormData.ros_musculoskeletalGoutActiveTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalGoutActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_musculoskeletalGoutActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalGoutSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" && !value?.includes("gout")) ||
    (name === "ros_musculoskeletalGoutSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalGoutSubOtherSubDesc = "";
  }
  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("halluxValgus")
  ) {
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalHalluxValgusTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("halluxValgus")) ||
    (name === "ros_musculoskeletalHalluxValgusTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalHalluxValgusSubAnswerDescribe" &&
    !value?.includes("halluxValgus")
  ) {
    updatedFormData.ros_musculoskeletalHalluxValgusTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("halluxValgus")) ||
    (name === "ros_musculoskeletalHalluxValgusSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalHalluxValgusSubOtherSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("hammerToes")
  ) {
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalHammerToesSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesActiveTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("hammerToes")) ||
    (name === "ros_musculoskeletalHammerToesActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalHammerToesSubAnswerDescribe" &&
    !value?.includes("hammerToes")
  ) {
    updatedFormData.ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesActiveTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("hammerToes")) ||
    (name === "ros_musculoskeletalHammerToesSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalHammerToesSubOtherSubDesc = "";
  }
  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("osteoarthritis")
  ) {
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints = "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("osteoarthritis")) ||
    (name === "ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalOsteoarthritisSubAnswerDescribe" &&
    !value?.includes("osteoarthritis")
  ) {
    updatedFormData.ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("osteoarthritis")) ||
    (name === "ros_musculoskeletalOsteoarthritisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalOsteoarthritisSubOtherSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalOsteoarthritisSubAnswerSupportedBy" &&
    !value?.includes("symptoms")
  ) {
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment_toggle = false;
  }
  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("osteomyelitis")
  ) {
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteomyelitisSubLocation = "";
    updatedFormData.ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("osteomyelitis")) ||
    (name === "ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalOsteomyelitisSubAnswerDescribe" &&
    !value?.includes("osteomyelitis")
  ) {
    updatedFormData.ros_musculoskeletalOsteomyelitisSubLocation = "";
    updatedFormData.ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("osteomyelitis")) ||
    (name === "ros_musculoskeletalOsteomyelitisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalOsteomyelitisSubOtherSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("osteoporosis")
  ) {
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("osteoporosis")) ||
    (name === "ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalOsteoporosisSubAnswerDescribe" &&
    !value?.includes("osteoporosis")
  ) {
    updatedFormData.ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("osteoporosis")) ||
    (name === "ros_musculoskeletalOsteoporosisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalOsteoporosisSubOtherSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("pyogenicArthritis")
  ) {
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("pyogenicArthritis")) ||
    (name === "ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalPyogenicArthritisSubAnswerDescribe" &&
    !value?.includes("pyogenicArthritis")
  ) {
    updatedFormData.ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("pyogenicArthritis")) ||
    (name === "ros_musculoskeletalPyogenicArthritisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalPyogenicArthritisSubOtherSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("rheumatoidArthritis")
  ) {
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerDescribe =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedBy =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("rheumatoidArthritis")) ||
    (name ===
      "ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribe" &&
    !value?.includes("rheumatoidArthritis")
  ) {
    updatedFormData.ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("rheumatoidArthritis")) ||
    (name === "ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedBy" &&
    !value?.includes("symptoms")
  ) {
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms =
      [];
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment =
      "";
    updatedFormData.ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment_toggle = false;
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("scleroderma")
  ) {
    updatedFormData.ros_musculoskeletalSclerodermaSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalSclerodermaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("scleroderma")) ||
    (name === "ros_musculoskeletalSclerodermaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalSclerodermaSubAnswerDescribe" &&
    !value?.includes("scleroderma")
  ) {
    updatedFormData.ros_musculoskeletalSclerodermaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("spinalStenosis")
  ) {
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDisease =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("spinalStenosis")) ||
    (name === "ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_musculoskeletalSpinalStenosisSubAnswerDescribe" &&
    !value?.includes("spinalStenosis")
  ) {
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDisease =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc =
      "";
    // updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder =
    //   [];
    // updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
    //   "";
  }

  if (
    name === "ros_musculoskeletalSpinalStenosisSubAnswerDescribe" &&
    !value?.includes("spinalStenosis")
  ) {
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDisease =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder =
      [];
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
      "";
    updatedFormData.ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment_toggle = false;
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("spinalStenosis")) ||
    (name === "ros_musculoskeletalSpinalStenosisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalSpinalStenosisSubOtherSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalDiagnoses" &&
    !value?.includes("systemicLupus")
  ) {
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerDescribeComment =
      "";
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" &&
      !value?.includes("systemicLupus")) ||
    (name === "ros_musculoskeletalSystemicLupusSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalSystemicLupusSubOtherSubDesc = "";
  }

  if (name === "ros_musculoskeletalDiagnoses" && !value?.includes("other")) {
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerDescribe = [];
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerSupportedBy = [];
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerother = "";
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerDescribeComment = "";
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_muskoOtherActiveTreatmentSubAnswer = [];
    updatedFormData.ros_muskoOtherActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_muskoOtherActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_muskoOtherSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" && !value?.includes("other")) ||
    (name === "ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_musculoskeletalMuskoOtherSubAnswerDescribe" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswer = [];
    updatedFormData.ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc = "";
    // updatedFormData.ros_musculoskeletalMuskoOtherSubAnswerother = "";
  }

  if (
    (name === "ros_musculoskeletalDiagnoses" && !value?.includes("other")) ||
    (name === "ros_musculoskeletalMuskoOtherSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_musculoskeletalMuskoSubOtherSubDesc = "";
  }

  if (
    // name === "ros_musculoskeletalDiagnoses" ||
    name === "ros_musculoskeletalMuskoHadAnAmputation" &&
    !value?.includes("hadAnAmputation")
  ) {
    updatedFormData.ros_musculoskeletalHadAnAmputationSubAnswer = "";
  }

  if (
    // (name === "ros_musculoskeletalDiagnoses" && !value?.includes("symptoms")) ||
    name === "ros_musculoskeletalMuskoSymptoms" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_musculoskeletalMuskoSymptomsSubOtherSubDesc = "";
  }

  //hema
  const isHemaDiagnoses = name === "ros_hematology" && !value;

  if (name === "ros_hematology" && value === "yes") {
    updatedFormData.ros_hematologyDiagnoses = [];
    updatedFormData.ros_hematologyAidsSubAnswerDescribe = [];
    updatedFormData.ros_hematologyAidsSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyAidsSubAnswerPatientUnderTreatment = [];
    updatedFormData.ros_hematologyAidsSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyAidsSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyAidsSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyAidsSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyAidsSubAnswerPatientUnderTreatmentComment =
      "";
    updatedFormData.ros_hematologyAidsSubAnswerPatientUnderTreatmentComment_toggle = false;
    updatedFormData.ros_hematologyAidsSubOtherSubDesc = "";

    updatedFormData.ros_hematologyAnemiaSubAnswerDescribe = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiology = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOn = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiologyComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubotherSubDesc = "";

    updatedFormData.ros_hematologyCDifficileSubAnswerDescribe = [];
    updatedFormData.ros_hematologyCDifficileSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyCDifficileSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyCDifficileSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyCDifficileSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyCDifficileSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyCDifficileSubOtherSubDesc = "";

    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerDescribe = [];
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerDescribeComment =
      "";
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyCommunityAcquiredSubOtherSubDesc = "";

    updatedFormData.ros_hematologyHivSubAnswerDescribe = [];
    updatedFormData.ros_hematologyHivSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyHivSubAnswerViralLoad = "";
    updatedFormData.ros_hematologyHivSubAnswerCFour = "";
    updatedFormData.ros_hematologyHivSubAnswerPatientSymptomatic = [];
    updatedFormData.ros_hematologyHivSubAnswerActiveTreatment = [];
    updatedFormData.ros_hematologyHivSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyHivSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyHivSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyHivSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyHivSubAnswerPatientSymptomaticComment = "";
    updatedFormData.ros_hematologyHivSubAnswerPatientSymptomaticComment_toggle = false;
    updatedFormData.ros_hematologyHivSubAnswerActiveTreatmentComment = "";
    updatedFormData.ros_hematologyHivSubAnswerActiveTreatmentComment_toggle = false;
    updatedFormData.ros_hematologyHivSubOtherSubDesc = "";

    updatedFormData.ros_hematologyHerpesZosterSubAnswerDescribe = [];
    updatedFormData.ros_hematologyHerpesZosterSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyHerpesZosterSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyHerpesZosterSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyHerpesZosterSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterSubOtherSubDesc = "";

    updatedFormData.ros_hematologyMrsaInfectionSubAnswerDescribe = [];
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyMrsaInfectionSubOtherSubDesc = "";

    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerDescribe = [];
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerEtiology = "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunistic =
      [];
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment =
      "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencySubOtherSubDesc = "";

    updatedFormData.ros_hematologysepsisSubAnswerDescribe = [];
    updatedFormData.ros_hematologysepsisSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologysepsisSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologysepsisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologysepsisSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologysepsisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologysepsisSubOtherSubDesc = "";

    updatedFormData.ros_hematologysickleCellDiseaseSubAnswerDescribe = [];
    updatedFormData.ros_hematologysickleCellDiseaseSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologysickleCellDiseaseSubAnswerDescribeComment =
      "";
    updatedFormData.ros_hematologysickleCellDiseaseSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologysickleCellDiseaseSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologysickleCellDiseaseSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologysickleCellDiseaseSubDesc = "";

    updatedFormData.ros_hematologysickleCellTraitSubAnswerDescribe = [];
    updatedFormData.ros_hematologysickleCellTraitSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologysickleCellTraitSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologysickleCellTraitSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologysickleCellTraitSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologysickleCellTraitSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologysickleCellTraitSubOtherSubDesc = "";

    updatedFormData.ros_hematologyThalassemiaSubAnswerDescribe = [];
    updatedFormData.ros_hematologyThalassemiaSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyThalassemiaSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyThalassemiaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyThalassemiaSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyThalassemiaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyThalassemiaSubOtherSubDesc = "";

    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerDescribe = [];
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerEtiology = "";
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyThrombocytopeniaSubOtherSubDesc = "";

    updatedFormData.ros_hematologyTuberculosisSubAnswerDescribe = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCG = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosis = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfection = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCGComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCGComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment =
      "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfectionComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfectionComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubOtherSubDesc = "";

    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerDescribe = [];
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerDescribeComment =
      "";
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyVitaminDDeficiencySubOtherSubDesc = "";

    // updatedFormData.ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc =
    //   "";

    updatedFormData.ros_hematologyHemaOtherSubAnswerDescribe = [];
    updatedFormData.ros_hematologyHemaOtherSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyHemaOtherSubAnswerOther = "";
    updatedFormData.ros_hematologyHemaOtherSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyHemaOtherSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyHemaOtherSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyHemaOtherSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyHemaOtherSubOtherSubDesc = "";
  }

  if (isHemaDiagnoses) {
    updatedFormData.ros_hematologyDiagnoses = [];
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("aids")) {
    updatedFormData.ros_hematologyAidsSubAnswerDescribe = [];
    updatedFormData.ros_hematologyAidsSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyAidsSubAnswerPatientUnderTreatment = [];
    updatedFormData.ros_hematologyAidsSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyAidsSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyAidsSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyAidsSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyAidsSubAnswerPatientUnderTreatmentComment =
      "";
    updatedFormData.ros_hematologyAidsSubAnswerPatientUnderTreatmentComment_toggle = false;
    updatedFormData.ros_hematologyAidsActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyAidsActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologyAidsActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyAidsSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("aids")) ||
    (name === "ros_hematologyAidsActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyAidsSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyAidsSubAnswerDescribe" &&
    !value?.includes("aids")
  ) {
    updatedFormData.ros_hematologyAidsActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyAidsActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologyAidsActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyAidsSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("aids")) ||
    (name === "ros_hematologyAidsSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyAidsSubOtherSubDesc = "";
  }
  if (name === "ros_hematologyAidsSubAnswerPatientUnderTreatment") {
    updatedFormData.ros_hematologyAidsSubPatientUnderTreatmentSubWhere = "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("anemia")) {
    updatedFormData.ros_hematologyAnemiaSubAnswerDescribe = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiology = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOn = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiologyComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyAnemiaActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologyAnemiaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("anemia")) ||
    (name === "ros_hematologyAnemiaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyAnemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyAnemiaSubAnswerDescribe" &&
    !value?.includes("anemia")
  ) {
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiology = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiologyComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerEtiologyComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyAnemiaActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologyAnemiaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubOtherTreatmentSubDesc = "";
    updatedFormData.ros_hematologyAnemiaSubEtiologySubOtherSubDesc = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOn = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment_toggle = false;
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("anemia")) ||
    (name === "ros_hematologyAnemiaActiveTreatmentSubAnswer" &&
      !value?.includes("medication"))
  ) {
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOn = [];
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment = "";
    updatedFormData.ros_hematologyAnemiaSubAnswerYesPatientOnComment_toggle = false;
    updatedFormData.ros_hematologyAnemiaSubYesPatientOnSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("anemia")) ||
    (name === "ros_hematologyAnemiaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyAnemiaSubotherSubDesc = "";
  }
  if (name === "ros_hematologyAnemiaSubAnswerEtiology") {
    updatedFormData.ros_hematologyAnemiaSubEtiologySubOtherSubDesc = "";
  }
  if (name === "ros_hematologyAnemiaSubAnswerYesPatientOn") {
    updatedFormData.ros_hematologyAnemiaSubYesPatientOnSubDesc = "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("cDifficile")) {
    updatedFormData.ros_hematologyCDifficileSubAnswerDescribe = [];
    updatedFormData.ros_hematologyCDifficileSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyCDifficileSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyCDifficileSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyCDifficileSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyCDifficileSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyCDifficileActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyCDifficileActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyCDifficileActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyCDifficileSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("cDifficile")) ||
    (name === "ros_hematologyCDifficileActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyCDifficileSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyCDifficileSubAnswerDescribe" &&
    !value?.includes("cDifficile")
  ) {
    updatedFormData.ros_hematologyCDifficileActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyCDifficileActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyCDifficileActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyCDifficileSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("cDifficile")) ||
    (name === "ros_hematologyCDifficileSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyCDifficileSubOtherSubDesc = "";
  }
  if (
    name === "ros_hematologyDiagnoses" &&
    !value?.includes("communityAcquired")
  ) {
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerDescribe = [];
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerDescribeComment =
      "";
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyCommunityAcquiredSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyCommunityAcquiredActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("communityAcquired")) ||
    (name === "ros_hematologyCommunityAcquiredActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_hematologyCommunityAcquiredSubAnswerDescribe" &&
    !value?.includes("communityAcquired")
  ) {
    updatedFormData.ros_hematologyCommunityAcquiredActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("communityAcquired")) ||
    (name === "ros_hematologyCommunityAcquiredSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyCommunityAcquiredSubOtherSubDesc = "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("hiv")) {
    updatedFormData.ros_hematologyHivSubAnswerDescribe = [];
    updatedFormData.ros_hematologyHivSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyHivSubAnswerViralLoad = "";
    updatedFormData.ros_hematologyHivSubAnswerCFour = "";
    updatedFormData.ros_hematologyHivSubAnswerPatientSymptomatic = [];
    updatedFormData.ros_hematologyHivSubAnswerActiveTreatment = [];
    updatedFormData.ros_hematologyHivSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyHivSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyHivSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyHivSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyHivSubAnswerPatientSymptomaticComment = "";
    updatedFormData.ros_hematologyHivSubAnswerPatientSymptomaticComment_toggle = false;
    updatedFormData.ros_hematologyHivSubAnswerActiveTreatmentComment = "";
    updatedFormData.ros_hematologyHivSubAnswerActiveTreatmentComment_toggle = false;
    updatedFormData.ros_hematologyHivActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyHivActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologyHivActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHivSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("hiv")) ||
    (name === "ros_hematologyHivActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyHivSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyHivSubAnswerDescribe" &&
    !value?.includes("hiv")
  ) {
    updatedFormData.ros_hematologyHivActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyHivActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologyHivActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHivSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("hiv")) ||
    (name === "ros_hematologyHivSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyHivSubOtherSubDesc = "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("herpesZoster")) {
    updatedFormData.ros_hematologyHerpesZosterSubAnswerDescribe = [];
    updatedFormData.ros_hematologyHerpesZosterSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyHerpesZosterSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyHerpesZosterSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyHerpesZosterSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterTypeSubAnswer = [];
    updatedFormData.ros_hematologyHerpesZosterTypeSubAnswerComment = "";
    updatedFormData.ros_hematologyHerpesZosterTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterActiveSubOtherSubDesc = "";
    updatedFormData.ros_hematologyHerpesZosterActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterSubOtherTreatmentSubDesc = "";
    updatedFormData.ros_hematologyHerpesZosterHistoryOfTypeSubAnswer = [];
    updatedFormData.ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment =
      "";
    updatedFormData.ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("herpesZoster")) ||
    (name === "ros_hematologyHerpesZosterActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyHerpesZosterSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyHerpesZosterSubAnswerDescribe" &&
    !value?.includes("herpesZoster")
  ) {
    updatedFormData.ros_hematologyHerpesZosterTypeSubAnswer = [];
    updatedFormData.ros_hematologyHerpesZosterTypeSubAnswerComment = "";
    updatedFormData.ros_hematologyHerpesZosterTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterActiveSubOtherSubDesc = "";
    updatedFormData.ros_hematologyHerpesZosterActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterSubOtherTreatmentSubDesc = "";
    updatedFormData.ros_hematologyHerpesZosterHistoryOfTypeSubAnswer = [];
    updatedFormData.ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment =
      "";
    updatedFormData.ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("herpesZoster")) ||
    (name === "ros_hematologyHerpesZosterSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyHerpesZosterSubOtherSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("herpesZoster")) ||
    (name === "ros_hematologyHerpesZosterTypeSubAnswer" &&
      !value?.includes("activeTreatmentSubAnswer"))
  ) {
    updatedFormData.ros_hematologyHerpesZosterActiveSubOtherSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("herpesZoster")) ||
    (name === "ros_hematologyHerpesZosterHistoryOfTypeSubAnswer" &&
      !value?.includes("historyOfTreatmentSubAnswer"))
  ) {
    updatedFormData.ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc = "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("mrsaInfection")) {
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerDescribe = [];
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyMrsaInfectionSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyMrsaInfectionActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("mrsaInfection")) ||
    (name === "ros_hematologyMrsaInfectionSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyMrsaInfectionSubOtherSubDesc = "";
  }

  if (
    name === "ros_hematologyDiagnoses" &&
    !value?.includes("immuneDeficiency")
  ) {
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerDescribe = [];
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerEtiology = "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunistic =
      [];
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment =
      "";
    updatedFormData.ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("immuneDeficiency")) ||
    (name === "ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyImmuneDeficiencySubAnswerDescribe" &&
    !value?.includes("immuneDeficiency")
  ) {
    updatedFormData.ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("immuneDeficiency")) ||
    (name === "ros_hematologyImmuneDeficiencySubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyImmuneDeficiencySubOtherSubDesc = "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("sepsis")) {
    updatedFormData.ros_hematologySepsisSubAnswerDescribe = [];
    updatedFormData.ros_hematologySepsisSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologySepsisSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologySepsisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologySepsisSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologySepsisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologySepsisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologySepsisActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologySepsisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySepsisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("sepsis")) ||
    (name === "ros_hematologySepsisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologySepsisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologySepsisSubAnswerDescribe" &&
    !value?.includes("sepsis")
  ) {
    updatedFormData.ros_hematologySepsisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologySepsisActiveTreatmentSubAnswerComment = "";
    updatedFormData.ros_hematologySepsisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySepsisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("sepsis")) ||
    (name === "ros_hematologySepsisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologySepsisSubOtherSubDesc = "";
  }

  if (
    name === "ros_hematologyDiagnoses" &&
    !value?.includes("sickleCellDisease")
  ) {
    updatedFormData.ros_hematologySickleCellDiseaseSubAnswerDescribe = [];
    updatedFormData.ros_hematologySickleCellDiseaseSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologySickleCellDiseaseSubAnswerDescribeComment =
      "";
    updatedFormData.ros_hematologySickleCellDiseaseSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologySickleCellDiseaseSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologySickleCellDiseaseSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("sickleCellDisease")) ||
    (name === "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_hematologySickleCellDiseaseSubAnswerDescribe" &&
    !value?.includes("sickleCellDisease")
  ) {
    updatedFormData.ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("sickleCellDisease")) ||
    (name === "ros_hematologySickleCellDiseaseSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologySickleCellDiseaseSubDesc = "";
  }

  if (
    name === "ros_hematologyDiagnoses" &&
    !value?.includes("sickleCellTrait")
  ) {
    updatedFormData.ros_hematologySickleCellTraitSubAnswerDescribe = [];
    updatedFormData.ros_hematologySickleCellTraitSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologySickleCellTraitSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologySickleCellTraitSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologySickleCellTraitSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologySickleCellTraitSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologySickleCellTraitActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySickleCellTraitSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("sickleCellTrait")) ||
    (name === "ros_hematologySickleCellTraitActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologySickleCellTraitSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologySickleCellTraitSubAnswerDescribe" &&
    !value?.includes("sickleCellTrait")
  ) {
    updatedFormData.ros_hematologySickleCellTraitActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologySickleCellTraitSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("sickleCellTrait")) ||
    (name === "ros_hematologySickleCellTraitSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologySickleCellTraitSubOtherSubDesc = "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("thalassemia")) {
    updatedFormData.ros_hematologyThalassemiaSubAnswerDescribe = [];
    updatedFormData.ros_hematologyThalassemiaSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyThalassemiaSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyThalassemiaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyThalassemiaSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyThalassemiaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyThalassemiaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyThalassemiaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyThalassemiaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyThalassemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("thalassemia")) ||
    (name === "ros_hematologyThalassemiaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyThalassemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyThalassemiaSubAnswerDescribe" &&
    !value?.includes("thalassemia")
  ) {
    updatedFormData.ros_hematologyThalassemiaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyThalassemiaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyThalassemiaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyThalassemiaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("thalassemia")) ||
    (name === "ros_hematologyThalassemiaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyThalassemiaSubOtherSubDesc = "";
  }

  if (
    name === "ros_hematologyDiagnoses" &&
    !value?.includes("thrombocytopenia")
  ) {
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerDescribe = [];
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerEtiology = "";
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyThrombocytopeniaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("thrombocytopenia")) ||
    (name === "ros_hematologyThrombocytopeniaActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyThrombocytopeniaSubAnswerDescribe" &&
    !value?.includes("thrombocytopenia")
  ) {
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyThrombocytopeniaSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyThrombocytopeniaActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("thrombocytopenia")) ||
    (name === "ros_hematologyThrombocytopeniaSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyThrombocytopeniaSubOtherSubDesc = "";
  }
  if (name === "ros_hematologyDiagnoses" && !value?.includes("tuberculosis")) {
    updatedFormData.ros_hematologyTuberculosisSubAnswerDescribe = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCG = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosis = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfection = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCGComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCGComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment =
      "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfectionComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfectionComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyTuberculosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyTuberculosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("tuberculosis")) ||
    (name === "ros_hematologyTuberculosisActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyTuberculosisSubOtherTreatmentSubDesc = "";
  }

  if (
    name === "ros_hematologyTuberculosisSubAnswerDescribe" &&
    !value?.includes("tuberculosis")
  ) {
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCG = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosis = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfection = [];
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCGComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerGivenBCGComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment =
      "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfectionComment = "";
    updatedFormData.ros_hematologyTuberculosisSubAnswerTbInfectionComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisActiveTreatmentSubAnswer = [];
    updatedFormData.ros_hematologyTuberculosisActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyTuberculosisActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyTuberculosisSubOtherTreatmentSubDesc = "";
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("tuberculosis")) ||
    (name === "ros_hematologyTuberculosisSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyTuberculosisSubOtherSubDesc = "";
  }

  if (
    name === "ros_hematologyDiagnoses" &&
    !value?.includes("vitaminDDeficiency")
  ) {
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerDescribe = [];
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerDescribeComment =
      "";
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment =
      "";
    updatedFormData.ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment_toggle = false;
    updatedFormData.ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("vitaminDDeficiency")) ||
    (name === "ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswer" &&
      !value?.includes("otherTreatment"))
  ) {
    updatedFormData.ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc =
      "";
  }

  if (
    name === "ros_hematologyVitaminDDeficiencySubAnswerDescribe" &&
    !value?.includes("vitaminDDeficiency")
  ) {
    updatedFormData.ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswer =
      [];
    updatedFormData.ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment =
      "";
    updatedFormData.ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment_toggle = false;
    updatedFormData.ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc =
      "";
  }

  if (
    (name === "ros_hematologyDiagnoses" &&
      !value?.includes("vitaminDDeficiency")) ||
    (name === "ros_hematologyVitaminDDeficiencySubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyVitaminDDeficiencySubOtherSubDesc = "";
  }

  if (
    name === "ros_hematologyDiagnoses" &&
    !value?.includes("boneMarrowTransplant")
  ) {
    updatedFormData.ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc =
      "";
  }

  if (name === "ros_hematologyDiagnoses" && !value?.includes("other")) {
    updatedFormData.ros_hematologyHemaOtherSubAnswerDescribe = [];
    updatedFormData.ros_hematologyHemaOtherSubAnswerSupportedBy = [];
    updatedFormData.ros_hematologyHemaOtherSubAnswerOther = "";
    updatedFormData.ros_hematologyHemaOtherSubAnswerDescribeComment = "";
    updatedFormData.ros_hematologyHemaOtherSubAnswerDescribeComment_toggle = false;
    updatedFormData.ros_hematologyHemaOtherSubAnswerSupportedByComment = "";
    updatedFormData.ros_hematologyHemaOtherSubAnswerSupportedByComment_toggle = false;
  }

  if (
    (name === "ros_hematologyDiagnoses" && !value?.includes("other")) ||
    (name === "ros_hematologyHemaOtherSubAnswerSupportedBy" &&
      !value?.includes("other"))
  ) {
    updatedFormData.ros_hematologyHemaOtherSubOtherSubDesc = "";
  }

  if (name === "ros_gastro") {
    updatedFormData.ros_gastroSubDiagnoses = [];
    updatedFormData.ros_gastroSubDiagnosesComment = "";
    updatedFormData.ros_gastroSubDiagnosesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstruction = [];
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSup = [];
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescachexiades = [];
    updatedFormData.ros_gastroSubDiagnosescachexiaSup = [];
    updatedFormData.ros_gastroSubDiagnosescachexiadesComment = "";
    updatedFormData.ros_gastroSubDiagnosescachexiadesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescachexiaSupComment = "";
    updatedFormData.ros_gastroSubDiagnosescachexiaSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesceliacDisease = [];
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSup = [];
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseGlueten = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseGluetenComment = "";
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseGluetenComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosis = [];
    updatedFormData.ros_gastroSubDiagnosescirrhosisSup = [];
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStage = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescirrhosisSupComment = "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageComment = "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescolonPolypsDes = [];
    updatedFormData.ros_gastroSubDiagnosescolonSup = [];
    updatedFormData.ros_gastroSubDiagnosescolonCrDes = [];
    updatedFormData.ros_gastroSubDiagnosescolonPolypsDesComment = "";
    updatedFormData.ros_gastroSubDiagnosescolonPolypsDesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescolonSupComment = "";
    updatedFormData.ros_gastroSubDiagnosescolonSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescolonCrDesComment = "";
    updatedFormData.ros_gastroSubDiagnosescolonCrDesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitis = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSup = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisabscessVal = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisperforationVal = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitishighFiberDietVal = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisabscessValComment = "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisabscessValComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisperforationValComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisperforationValComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderDisease = [];
    updatedFormData.ros_gastroSubDiagnosesgallBladderDiseaseSup = [];
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderDiseaseComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderDiseaseComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgallBladderDiseaseSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesgallBladderDiseaseSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgastroparesisDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesgastroparesisDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesgastroparesisDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgerdDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesgerdDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesgerdDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesglBleedDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesglBleedDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesglBleedDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisDescribe = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisType = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisDess = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosis = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisTypeComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisTypeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisDessComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisDessComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosisComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosisComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment =
      "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBy =
      [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribe =
      [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet =
      [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisDescribe = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodes = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDiet = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment =
      "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDietComment = "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDietComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactria = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesotherDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesotherSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesotherDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesotherDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesotherSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesotherSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesotherOther = "";
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTre =
      [];
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment_toggle = false;
    updatedFormData.ros_gastroSubSymptoms = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelect = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns =
      "";
    updatedFormData.ros_gastroSubSymptomsComment = "";
    updatedFormData.ros_gastroCiroalcoholRelated = [];
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDes = [];
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol = [];
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment_toggle = false;
    updatedFormData.ros_gastroCiroalcoholRelatedComment = "";
    updatedFormData.ros_gastroCiroalcoholRelatedComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice = [];
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBlood = [];
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelect = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment_toggle = false;
    updatedFormData.ros_gastroSubSymptomsComment_toggle = false;
    updatedFormData.ros_gastroSubSymptomsOtherSub = "";
    updatedFormData.ros_gastroDateOfTransplant = "";
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal =
      "";
    updatedFormData.ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal = "";
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal =
      "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal = "";
    updatedFormData.ros_gastroSubDiagnosescolonOtherSubAnswerVal = "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal =
      "";
    updatedFormData.ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesotherOther = "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageSubAnswer = [];
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesulcerDisease = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseComment_toggle = false;
  }

  if (name === "ros_gastroSubSymptoms" && !value?.includes("other")) {
    updatedFormData.ros_gastroSubSymptomsOtherSub = "";
  }
  if (name === "ros_gastroSubDiagnoseshepatitisCirrhosis") {
    updatedFormData.ros_gastroCiroalcoholRelated = "";
  }
  if (
    name === "ros_gastroSubDiagnoses" &&
    !value?.includes("bowelobstruction")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstruction = [];
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSup = [];
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal =
      "";
  }
  if (name === "ros_gastroSubDiagnosesdesbowelObstruction") {
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesbowelObstructionSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("cachexia")) {
    updatedFormData.ros_gastroSubDiagnosescachexiades = [];
    updatedFormData.ros_gastroSubDiagnosescachexiaSup = [];
    updatedFormData.ros_gastroSubDiagnosescachexiadesComment = "";
    updatedFormData.ros_gastroSubDiagnosescachexiadesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescachexiaSupComment = "";
    updatedFormData.ros_gastroSubDiagnosescachexiaSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal = "";
  }
  if (name === "ros_gastroSubDiagnosescachexiades") {
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_gastroSubDiagnosescachexiaSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal = "";
  }
  if (
    name === "ros_gastroSubDiagnosesdescachexiaActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub = "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("celiacdisease")) {
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesceliacDisease = [];
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSup = [];
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseGlueten = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseGluetenComment = "";
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseGluetenComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal =
      "";
  }
  if (name === "ros_gastroSubDiagnosesdesceliacDisease") {
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia = [];
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment_toggle = false;
  }
  if (
    name === "ros_gastroSubDiagnosesceliacDiseaseSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("cirrhosis")) {
    updatedFormData.ros_gastroSubDiagnosesdescirrhosis = [];
    updatedFormData.ros_gastroSubDiagnosescirrhosisSup = [];
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStage = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescirrhosisSupComment = "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageComment = "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageSubAnswer = [];
    updatedFormData.ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelect = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns =
      "";
    updatedFormData.ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal = "";
  }
  if (name === "ros_gastroSubDiagnosesdescirrhosis") {
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelect = [];
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdescirrhosisActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdescirrhosisActiveSubSelect" &&
    value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosescirrhosisSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal = "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("colonpolyps")) {
    updatedFormData.ros_gastroSubDiagnosescolonPolypsDes = [];
    updatedFormData.ros_gastroSubDiagnosescolonSup = [];
    updatedFormData.ros_gastroSubDiagnosescolonCrDes = [];
    updatedFormData.ros_gastroSubDiagnosescolonPolypsDesComment = "";
    updatedFormData.ros_gastroSubDiagnosescolonPolypsDesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescolonSupComment = "";
    updatedFormData.ros_gastroSubDiagnosescolonSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosescolonCrDesComment = "";
    updatedFormData.ros_gastroSubDiagnosescolonCrDesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosescolonOtherSubAnswerVal = "";
  }
  if (name === "ros_gastroSubDiagnosescolonPolypsDes") {
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdescolonPolypsActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_gastroSubDiagnosescolonSup" && !value?.includes("other")) {
    updatedFormData.ros_gastroSubDiagnosescolonOtherSubAnswerVal = "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("diverticultis")) {
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitis = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSup = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisabscessVal = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisperforationVal = [];
    updatedFormData.ros_gastroSubDiagnosesdiverticulitishighFiberDietVal = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisabscessValComment = "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisabscessValComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisperforationValComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelect = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisperforationValComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal =
      "";
  }
  if (name === "ros_gastroSubDiagnosesdesdiverticulitis") {
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelect = [];
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment_toggle = false;
  }
  if (
    name === "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdiverticulitisSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal =
      "";
  }
  if (
    name === "ros_gastroSubDiagnoses" &&
    !value?.includes("gallbladderdisease")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderDisease = [];
    updatedFormData.ros_gastroSubDiagnosesgallBladderDiseaseSup = [];
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderDiseaseComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderDiseaseComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgallBladderDiseaseSupComment = "";
    updatedFormData.ros_gastroSubDiagnosesgallBladderDiseaseSupComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnosesdesgallBladderDisease") {
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesgallBladderDiseaseSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdesgallBladderActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("gastroparesis")) {
    updatedFormData.ros_gastroSubDiagnosesgastroparesisDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesgastroparesisDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesgastroparesisDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe =
      "";
  }

  if (name === "ros_gastroSubDiagnosesgastroparesisDescribe") {
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesgastroparesisSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdesgastroparesisActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("gerd")) {
    updatedFormData.ros_gastroSubDiagnosesgerdDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesgerdDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesgerdDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnosesgerdDescribe") {
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_gastroSubDiagnosesgerdSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdesgerdActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub = "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("gibleed")) {
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesglBleedDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesglBleedDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesglBleedDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBlood = [];
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnosesglBleedDescribe") {
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBlood = [];
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment = "";
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment_toggle = false;
  }
  if (
    name === "ros_gastroSubDiagnosesdesglBleedActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_gastroSubDiagnosesglBleedSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("hepatitis")) {
    updatedFormData.ros_gastroSubDiagnoseshepatitisDescribe = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisType = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisDess = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosis = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisTypeComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisTypeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisDessComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisDessComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosisComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosisComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice = [];
    updatedFormData.ros_gastroCiroalcoholRelated = [];
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment =
      "";
    updatedFormData.ros_gastroCiroalcoholRelatedComment = "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment_toggle = false;
    updatedFormData.ros_gastroCiroalcoholRelatedComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnoseshepatitisDescribe") {
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment = "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisType = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisDess = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosis = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma = [];
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisTypeComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisTypeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisDessComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisDessComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosisComment = "";
    updatedFormData.ros_gastroSubDiagnoseshepatitisCirrhosisComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice = [];
    updatedFormData.ros_gastroCiroalcoholRelated = [];
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment =
      "";
    updatedFormData.ros_gastroCiroalcoholRelatedComment = "";
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment_toggle = false;
    updatedFormData.ros_gastroCiroalcoholRelatedComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment_toggle = false;
  }
  if (
    name === "ros_gastroSubDiagnosesdeshepatitisActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_gastroSubDiagnoseshepatitisSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnoses" &&
    !value?.includes("inflammatoryboweldisease")
  ) {
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBy =
      [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribe =
      [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTre =
      [];
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet =
      [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribe") {
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTre =
      [];
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribe =
      [];
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment_toggle = false;
  }

  if (
    name === "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub =
      "";
  }

  if (name === "ros_gastroSubDiagnoses" && !value?.includes("pancreatitis")) {
    updatedFormData.ros_gastroSubDiagnosespancreatitisDescribe = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodes = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDiet = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment =
      "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDietComment = "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDietComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol = [];
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnosespancreatitisDescribe") {
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodes = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDiet = [];
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment =
      "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDietComment = "";
    updatedFormData.ros_gastroSubDiagnosespancreatitisSpecificDietComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol = [];
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment_toggle = false;
  }
  if (
    name === "ros_gastroSubDiagnosesdespancreatitisActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosespancreatitisSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("ulcerdisease")) {
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactria = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDes = [];
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe =
      "";
    updatedFormData.ros_gastroSubDiagnosesulcerDisease = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_gastroSubDiagnosesulcerDiseaseDescribe") {
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactria = [];
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment = "";
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTre = [];
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDes = [];
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment =
      "";
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment_toggle = false;
  }
  if (
    name === "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesulcerDisease" &&
    value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesulcerDiseaseSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnoses" &&
    !value?.includes("liverTransplant")
  ) {
    updatedFormData.ros_gastroDateOfTransplant = "";
  }
  if (name === "ros_gastroSubDiagnoses" && !value?.includes("other")) {
    updatedFormData.ros_gastroSubDiagnosesotherDescribe = [];
    updatedFormData.ros_gastroSubDiagnosesotherSupportedBy = [];
    updatedFormData.ros_gastroSubDiagnosesotherDescribeComment = "";
    updatedFormData.ros_gastroSubDiagnosesotherDescribeComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesotherSupportedByComment = "";
    updatedFormData.ros_gastroSubDiagnosesotherSupportedByComment_toggle = false;
    updatedFormData.ros_gastroSubDiagnosesotherOther = "";
    updatedFormData.ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (
    name === "ros_gastroSubDiagnosesotherSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe =
      "";
  }
  if (name === "ros_genito") {
    updatedFormData.ros_genitoSubDiagnoses = [];
    updatedFormData.ros_genitoSubDiagnosesComment = "";
    updatedFormData.ros_genitoSubDiagnosesComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailuredes = [];
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSup = [];
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureEtiology = "";
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailuredesComment = "";
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailuredesComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSupComment = "";
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSupComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesbphDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesbphSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesbphDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesbphDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesbphSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesbphSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseDescribe = [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment_toggle = false;
    updatedFormData.stagesOfKidneyDisease = [];
    updatedFormData.ros_genitoSubDiagnoseswhatStage = [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes =
      [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension =
      [];
    updatedFormData.ros_genitoSubDiagnoseswhatStageComment = "";
    updatedFormData.ros_genitoSubDiagnoseswhatStageComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrdDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesesrdSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesesrdDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrdSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysis = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisComment_toggle =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisType = [];
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrddialysisSchedule = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocation = [];
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment_toggle = false;

    updatedFormData.ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet = [];
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionDescribe = [];
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesgynecologicalDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesgynecologicalSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesgynecologicalDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesgynecologicalDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesgynecologicalSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesgynecologicalSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesDescribe = [];
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesType = [];
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesTypeComment = "";
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesTypeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoStress = "";
    updatedFormData.ros_genitostressSub = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceUiDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesotherDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesotherSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesotherDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesotherDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer = "";
    updatedFormData.ros_genitoSubDiagnosesotherOther = "";
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesErectileTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubSymptoms = [];
    updatedFormData.ros_genitoSubSymptomsComment = "";
    updatedFormData.ros_genitoSubSymptomsComment_toggle = false;
    updatedFormData.ros_genitoSubSymptomsOtherSub = "";

    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal = "";
    updatedFormData.ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer = "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer = "";
    updatedFormData.ros_genitoStressComment = "";
    updatedFormData.ros_genitoStressSubComment = "";
    updatedFormData.ros_genitoStressComment_toggle = false;
    updatedFormData.ros_genitoStressSubComment_toggle = false;
    updatedFormData.ros_genitoStressSub = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSec = [];
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSecComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSecComment_toggle = false;
  }
  if (name === "ros_genitoSubSymptoms" && !value?.includes("other")) {
    updatedFormData.ros_genitoSubSymptomsOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnoses" &&
    !value?.includes("acuteRenalFailure")
  ) {
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailuredes = [];
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSup = [];
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureEtiology = "";
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailuredesComment = "";
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailuredesComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSupComment = "";
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSupComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal = "";
  }
  if (name === "ros_genitoSubDiagnosesacuteRenalFailuredes") {
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesdesacuteActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesacuteRenalFailureSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal = "";
  }
  if (name === "ros_genitoSubDiagnoses" && !value?.includes("bphgenito")) {
    updatedFormData.ros_genitoSubDiagnosesbphDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesbphSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesbphDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesbphDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesbphSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesbphSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub = "";
  }

  if (name === "ros_genitoSubDiagnosesbphDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesdesbhpActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub = "";
  }

  if (
    name === "ros_genitoSubDiagnosesbphSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer = "";
  }

  if (
    name === "ros_genitoSubDiagnoses" &&
    !value?.includes("chronicKidneyDisease")
  ) {
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseDescribe = [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment_toggle = false;
    updatedFormData.stagesOfKidneyDisease = [];
    updatedFormData.ros_genitoSubDiagnoseswhatStage = [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes =
      [];
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension =
      [];
    updatedFormData.ros_genitoSubDiagnoseswhatStageComment = "";
    updatedFormData.ros_genitoSubDiagnoseswhatStageComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSec = [];
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSecComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSecComment_toggle = false;
  }

  if (name === "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribe") {
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSec = [];
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSecComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeschronicActiveSubSecComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseswhatStage = [];
    updatedFormData.ros_genitoSubDiagnoseswhatStageComment = "";
    updatedFormData.ros_genitoSubDiagnoseswhatStageComment_toggle = false;
  }

  if (
    name === "ros_genitoSubDiagnosesdeschronicActiveSubSec" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer =
      "";
  }
  if (
    name === "ros_genitoSubDiagnosesdeschronicActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer =
      "";
  }
  if (name === "ros_genitoSubDiagnoses" && !value?.includes("esrd")) {
    updatedFormData.ros_genitoSubDiagnosesesrdDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesesrdSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesesrdDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrdSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysis = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisComment_toggle =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisType = [];
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrddialysisSchedule = "";
    updatedFormData.ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet = [];
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocation = [];
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment_toggle = false;
  }

  if (name === "ros_genitoSubDiagnosesesrdDescribe") {
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysis = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisType = [];
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesesrddialysisSchedule = "";
    updatedFormData.ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet = [];
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesesrdActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocation = [];
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment_toggle = false;
  }

  if (
    name === "ros_genitoSubDiagnosesdesesrdActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnoActiveSubTreOtherSub = "";
  }

  if (
    name === "ros_genitoSubDiagnosesesrdSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer = "";
  }
  if (name === "ros_genitoSubDiagnosesesrdPatientOnDialysis") {
    updatedFormData.ros_genitoSubDiagnosesesrddialysisSchedule = "";
    updatedFormData.ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
      "";
  }

  if (
    name === "ros_genitoSubDiagnoses" &&
    !value?.includes("erectileDysfunction")
  ) {
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionDescribe = [];
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesErectileTreOtherSub = "";
  }

  if (name === "ros_genitoSubDiagnoseserectileDysfunctionDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeserectileActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesErectileTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnoseserectileDysfunctionSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer =
      "";
  }

  if (
    name === "ros_genitoSubDiagnosesdeserectileActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesErectileTreOtherSub = "";
  }
  if (name === "ros_genitoSubDiagnoses" && !value?.includes("frequentUti")) {
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub = "";
  }
  if (name === "ros_genitoSubDiagnosesfrequentUtiDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesfreqsActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesdesfreqsActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesfrequentUtiSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer =
      "";
  }
  if (name === "ros_genitoSubDiagnoses" && !value?.includes("gynecological")) {
    updatedFormData.ros_genitoSubDiagnosesgynecologicalDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesgynecologicalSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesgynecologicalDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesgynecologicalDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesgynecologicalSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesgynecologicalSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub = "";
  }
  if (name === "ros_genitoSubDiagnosesgynecologicalDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesdesgynecoActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesgynecologicalSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer =
      "";
  }
  if (
    name === "ros_genitoSubDiagnoses" &&
    !value?.includes("kidneyStonesgenito")
  ) {
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesDescribe = [];
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesType = [];
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesTypeComment = "";
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesTypeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub = "";
  }

  if (name === "ros_genitoSubDiagnoseskidneyStonesDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesdeskidneyActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub = "";
  }

  if (
    name === "ros_genitoSubDiagnoseskidneyStonesSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer =
      "";
  }

  if (
    name === "ros_genitoSubDiagnoses" &&
    !value?.includes("nephritisOrNephrosis")
  ) {
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub = "";
  }
  if (name === "ros_genitoSubDiagnosesnephritisOrNephrosisDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesdesNephrosisActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer =
      "";
  }

  if (
    name === "ros_genitoSubDiagnoses" &&
    !value?.includes("urinaryIncontinence")
  ) {
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer =
      "";
    updatedFormData.ros_genitoStress = "";
    updatedFormData.ros_genitoStressSub = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceUiDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment =
      "";
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub = "";
    updatedFormData.ros_genitoStressComment = "";
    updatedFormData.ros_genitoStressSubComment = "";
    updatedFormData.ros_genitoStressComment_toggle = false;
    updatedFormData.ros_genitoStressSubComment_toggle = false;
  }
  if (name === "ros_genitoSubDiagnosesurinaryIncontinenceDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub = "";
  }
  if (name === "ros_genitoStress" && !value?.includes("yes")) {
    updatedFormData.ros_genitoStressSub = [];
    updatedFormData.ros_genitoStressSubComment = "";
    updatedFormData.ros_genitoStressSubComment_toggle = false;
  }
  if (
    name === "ros_genitoSubDiagnosesdesUrinaryActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesurinaryIncontinenceSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer =
      "";
  }

  if (name === "ros_genitoSubDiagnoses" && !value?.includes("other")) {
    updatedFormData.ros_genitoSubDiagnosesotherDescribe = [];
    updatedFormData.ros_genitoSubDiagnosesotherSupportedBy = [];
    updatedFormData.ros_genitoSubDiagnosesotherDescribeComment = "";
    updatedFormData.ros_genitoSubDiagnosesotherDescribeComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByComment = "";
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer = "";
    updatedFormData.ros_genitoSubDiagnosesotherOther = "";
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesotherSupportedBy" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer = "";
  }
  if (name === "ros_genitoSubDiagnosesotherDescribe") {
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTre = [];
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreComment = "";
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreComment_toggle = false;
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub = "";
  }
  if (
    name === "ros_genitoSubDiagnosesdesOtherActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub = "";
  }
  if (name === "ros_diagnosisOfCancer") {
    updatedFormData.ros_diagnosisOfCancerSubDesc = [];
    updatedFormData.ros_diagnosisOfCancerSubDescComment = "";
    updatedFormData.ros_diagnosisOfCancerSubDescComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerSubSup = [];
    updatedFormData.ros_diagnosisOfCancerSubSupComment = "";
    updatedFormData.ros_diagnosisOfCancerSubSupComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerSubSupOtherValSubAnswer = "";
    updatedFormData.ros_diagnosisOfCancerSubType = [];
    updatedFormData.ros_diagnosisOfCancerSubTypeOtherValSubAnswer = "";
    updatedFormData.ros_diagnosisOfCancerSubTypeComment = "";
    updatedFormData.ros_diagnosisOfCancerSubTypeComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerspecialTypes = "";
    updatedFormData.ros_diagnosisOfCancerstageSpecificToTheCancer = "";
    updatedFormData.ros_diagnosisOfCancerSubActiveTreat = [];
    updatedFormData.ros_diagnosisOfCancerSubSideEff = [];
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatComment = "";
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerSubSideEffComment = "";
    updatedFormData.ros_diagnosisOfCancerSubSideEffComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerros_activeTreatment = [];
    updatedFormData.ros_diagnosisOfCancerros_activeTreatmentComment = "";
    updatedFormData.ros_diagnosisOfCancerros_activeTreatmentComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer = "";
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer =
      "";
    updatedFormData.ros_diagnosisOfCancerros_Metastasis = [];
    updatedFormData.ros_diagnosisOfCancerros_SeeASpec = [];
    updatedFormData.ros_diagnosisOfCancerros_MetastasisComment = "";
    updatedFormData.ros_diagnosisOfCancerros_MetastasisComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerros_SeeASpecComment = "";
    updatedFormData.ros_diagnosisOfCancerros_SeeASpecComment_toggle = false;
  }
  if (name === "ros_diagnosisOfCancerSubSup" && !value?.includes("other")) {
    updatedFormData.ros_diagnosisOfCancerSubSupOtherValSubAnswer = "";
  }
  if (name === "ros_diagnosisOfCancerSubType" && value?.includes("other")) {
    updatedFormData.ros_diagnosisOfCancerSubTypeOtherValSubAnswer = "";
  }
  if (name === "ros_diagnosisOfCancerSubType" && value?.includes("breast")) {
    updatedFormData.ros_diagnosisOfCancertitl5Years = [];
    updatedFormData.ros_diagnosisOfCancertitl5YearsComment = "";
    updatedFormData.ros_diagnosisOfCancertitl5YearsComment_toggle = false;
  }
  if (
    name === "ros_diagnosisOfCancerSubActiveTreat" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer = "";
  }
  if (name === "ros_diagnosisOfCancerSubSideEff" && value?.includes("other")) {
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer =
      "";
  }
  if (name === "ros_diagnosisOfCancerros_activeTreatment") {
    updatedFormData.ros_diagnosisOfCancerSubActiveTreat = [];
    updatedFormData.ros_diagnosisOfCancerSubSideEff = [];
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatComment = "";
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerSubSideEffComment = "";
    updatedFormData.ros_diagnosisOfCancerSubSideEffComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerros_activeTreatmentComment = "";
    updatedFormData.ros_diagnosisOfCancerros_activeTreatmentComment_toggle = false;
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer = "";
    updatedFormData.ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer =
      "";
  }
  if (name === "ros_diagnosisOfCancerros_Metastasis") {
    updatedFormData.ros_diagnosisOfCancerSubMetastasisLocVal = "";
    updatedFormData.ros_diagnosisOfCancerSubMetastasisCachexiaVal = [];
    updatedFormData.ros_diagnosisOfCancerSubMetastasisCachexiaValComment = "";
    updatedFormData.ros_diagnosisOfCancerSubMetastasisCachexiaValComment_toggle = false;
  }
  if (name === "ros_diagnosisOfCancerros_SeeASpec") {
    updatedFormData.ros_diagnosisOfCancerSubSeeASpecProVal = "";
  }
  if (name === "ros_endocrineIsEndocrine") {
    updatedFormData.ros_endocrineIsEndocrineDiagnose = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseComment = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseComment_toggle = false;

    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment =
      "";
    updatedFormData.ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin =
      [];
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment =
      "";
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment =
      "";
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEti =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubCommeent_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphtha =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre =
      [];

    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelect =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarcts =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdema =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSymp = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatment = [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherOther = "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySub = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtype = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubandDate = "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtypeComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtypeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubType =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarcts =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdema =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSymp = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce =
      [];

    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment_toggle = false;

    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatment = [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySup =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherDescribe = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSup = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherDescribeComment = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupComment = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherOther = "";
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatment = [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSup = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSup = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubNeuroSubSymp" &&
    !value?.includes("pain")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubNeuroSubSymp" &&
    !value?.includes("numbess")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubNeuroSubSymp" &&
    !value?.includes("burning")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubNeuroSubSymp" &&
    !value?.includes("decreasedSensation")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocCommentComment_toggle = false;
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("ckdstDiabetes")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment =
      "";
    updatedFormData.ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("coronaryArteryDiseaseAndDiabet")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin =
      [];
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment =
      "";
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment =
      "";
    updatedFormData.ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("cushingsDisease")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSub") {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
  }

  if (
    name === "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("diabetes")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySub = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphtha =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtype = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysical = [];
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysicalComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysicalComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubandDate = "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStage = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtypeComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtypeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubType =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarcts =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdema =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSymp = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
  }
  if (name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr") {
    updatedFormData.ros_endocrineIsEndocrineNoEdu = "";
    updatedFormData.ros_endocrineIsEndocrineUnknownEdu = "";
  }

  if (name === "ros_endocrineIsEndocrineSubdiabetesSubdescribeSub") {
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelect =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphtha =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtype = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysical = [];
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysicalComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysicalComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubandDate = "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStage = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtypeComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubtypeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubType =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarcts =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdema =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSymp = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre" &&
    value?.includes("medication")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelect =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl" &&
    value?.includes("diabeticCataracts")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarcts =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment_toggle = false;
  }
  if (name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribe") {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment_toggle = false;
  }
  if (
    name ===
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl" &&
    value?.includes("diabeticMacularEdema")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdema =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphtha =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribe"
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphtha =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl" &&
    value?.includes("diabeticRetinopathy")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
      "";
  }
  if (
    name ===
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist"
  ) {
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOften = [];
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOftenComment = "";
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOftenComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl" &&
    value?.includes("peripheralNeuropathy")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubneuroSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSymp = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOften = [];
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOftenComment = "";
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOftenComment_toggle = false;
  }
  if (name === "ros_endocrineIsEndocrineSubneuroSubdescribeSub") {
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSymp = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOften = [];
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOftenComment = "";
    updatedFormData.ros_endocrineIsEndocrineNeuroSubPatientOftenComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubNeuroSubTreat" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
      "";
  }
  if (
    name ===
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist"
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOften =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl" &&
    value?.includes("peripheralVascular")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysical = [];
    updatedFormData.includesros_endocrineIsEndocrineSubVascularSubPhysicalComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysicalComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOften =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment_toggle = false;
  }
  if (name === "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe") {
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysical = [];
    updatedFormData.includesros_endocrineIsEndocrineSubVascularSubPhysicalComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubPhysicalComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubVascularSubTreatComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOften =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubVascularSubTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl" &&
    value?.includes("autonomic")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment_toggle = false;
  }
  if (name === "ros_endocrineIsEndocrineSubautonomicSubdescribeSub") {
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment = "";
    updatedFormData.ros_endocrineIsEndocrineSubAutonomicSubTreatComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubAutonomicSubTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl" &&
    value?.includes("ckdstDiabetes")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStage = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment_toggle = false;
  }
  if (name === "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub") {
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStage = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubStageComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreat = [];
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubTreatComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubchronicKidneySubTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySub" &&
    !value?.includes("symptoms")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("diabeticRetinopathy")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment_toggle = false;
  }
  if (
    name ===
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("secondaryHyperparathyroidism")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEti =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
  }
  if (
    name ===
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSub"
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEti =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
  }

  if (
    name ===
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("hypertensionDiabetes")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment_toggle = false;

    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment_toggle = false;
  }

  if (
    name ===
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("hyperthyroidism")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatment = [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub") {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatment = [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
      "";
  }

  if (
    name === "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
      "";
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("peripheralNeuropathy")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySup =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
      "";
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("peripheralVascular")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSup = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment_toggle = false;
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnoseperipheralVascularSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
      "";
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("hyperparathyroidism")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribe =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSup = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribe") {
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre" &&
    value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherDescribe = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSup = [];
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherDescribeComment = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherDescribeComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupComment = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer = "";
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherOther = "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnoseotherSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer = "";
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("hypertensionDiabetes")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
  }
  if (
    name ===
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
      "";
  }

  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("hyperthyroidism")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineDiagnose" &&
    !value?.includes("hypothyroidism")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySub =
      [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatment = [];
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment_toggle = false;
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
      "";
  }
  if (name === "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSub") {
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymp =
      [];
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment =
      "";
    updatedFormData.ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment_toggle = false;
  }
  if (
    name === "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySub" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
      "";
  }
  if (
    name === "ros_endocrineIsEndocrineSubhypothyroidismSubTreatment" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
      "";
  }
  if (name === "ros_cardio") {
    updatedFormData.ros_cardioSubDiagnoses = [];
    updatedFormData.ros_cardioSubDiagnosesComment = "";
    updatedFormData.ros_cardioSubDiagnosesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSup = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDes =
      [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswer =
      [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmDes = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmSup = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatment = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatmentComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatmentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmType = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmTypeComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaSup = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaSup = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatment =
      [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDes = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationType = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerType =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSup = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatient =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationTypeComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatient =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDes = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreat = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatient =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDes = [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSup = [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardioresTreatOtherTrSub = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDes = [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyType = [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySup = [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyTypeComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySupComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertension =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiompTreatOtherTrSub = "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSpecify = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDes = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSup = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDes = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesComment_toggle = false;

    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDes =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment_toggle = false;

    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDes = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSup = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDes = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionSup = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionadequatelyControlled = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionadequatelyControlledComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionadequatelyControlledComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTre = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDes = [];
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSup = [];
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSymp =
      [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialDes = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialDate = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialSup = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialBetaBlocker = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspateint = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialBetaBlockerComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialBetaBlockerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspateintComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspateintComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDes = [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSup = [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer =
      "";
    updatedFormData.ros_cardiohistoryDiabetesisHistoryDiabetes = [];
    updatedFormData.ros_cardiopvdDescribe = [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDes = [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSup = [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseDes = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSup = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValveReplacement = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement =
      "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation =
      [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment_toggle = false;

    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatment = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers =
      "";
    updatedFormData.ros_cardioSubDiagnosesOtherDes = [];
    updatedFormData.ros_cardioSubDiagnosesOtherSup = [];
    updatedFormData.ros_cardioSubDiagnosesOtherDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesOtherDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesOtherSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesOtherSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesOtherOtherSub = "";
    updatedFormData.ros_cardioSubDiagnosesOtherSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisDes = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisPersistent = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSup = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSub =
      [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreat = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesHeartTransplant = "";
    updatedFormData.ros_cardioSubDiagnosesSymp = [];
    updatedFormData.ros_cardioSubDiagnosesSympComment = "";
    updatedFormData.ros_cardioSubDiagnosesSympComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesSympOtherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwo = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOne = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionSymp = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionSympComment = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionSympotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionSympComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("abnormalCardiacRhythm")
  ) {
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSup = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswer =
      [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwo = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOne = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment_toggle = false;
  }
  if (name === "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe") {
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswer =
      [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer =
      "";
  }
  if (name === "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes") {
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwo = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOne = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment_toggle = false;
  }

  if (name === "ros_cardioSubDiagnosesSymp" && !value?.includes("other")) {
    updatedFormData.ros_cardioSubDiagnosesSympOtherSubAnswer = "";
  }
  if (
    name ===
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswer" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes" &&
    !value?.includes("tachycardia")
  ) {
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwo = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes" &&
    !value?.includes("bradycardia")
  ) {
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOne = [];
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesabnormalCardiacRhythmSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
      "";
  }
  if (name === "ros_cardioSubDiagnoses" && !value?.includes("aneursym")) {
    updatedFormData.ros_cardioSubDiagnosesAneurysmDes = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmSup = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatment = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatmentComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatmentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmType = [];
    updatedFormData.ros_cardioSubDiagnosesAneurysmTypeComment = "";
    updatedFormData.ros_cardioSubDiagnosesAneurysmTypeComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesAneurysmSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesAneurysmSupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnosesAneurysmTreatment" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer =
      "";
  }

  if (name === "ros_cardioSubDiagnoses" && !value?.includes("angina")) {
    updatedFormData.ros_cardioSubDiagnosesAnginaDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaSup = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAnginaCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatment =
      [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment_toggle = false;
  }
  if (name === "ros_cardioSubDiagnosesAnginaDes") {
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatment =
      [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDes = [];
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment_toggle = false;
  }
  if (name === "ros_cardioSubDiagnosesAnginaSup" && !value?.includes("other")) {
    updatedFormData.ros_cardioSubDiagnosesAnginaSupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatment" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("atrialFibrillation")
  ) {
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDes = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationType = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSup = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerType =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationTypeComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatient =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub =
      "";
  }

  if (name === "ros_cardioSubDiagnosesAtrialFibrillationDes") {
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerType =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment_toggle = false;

    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatient =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesAtrialFibrillationSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer =
      "";
  }
  if (
    name ===
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
      "";
  }

  if (name === "ros_cardioSubDiagnoses" && !value?.includes("atrialFlutter")) {
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDes = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreat = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatient =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub =
      "";
  }
  if (name === "ros_cardioSubDiagnosesAtrialFlutterDes") {
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreat = [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatient =
      [];
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("cardioRespiratoryFailureShock")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDes = [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSup = [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardioresTreatOtherTrSub = "";
  }
  if (name === "ros_cardioSubDiagnosesCardiorespiratoryShockDes") {
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardioresTreatOtherTrSub = "";
  }
  if (
    name === "ros_cardioSubDiagnosesCardiorespiratoryShockSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer =
      "";
  }
  if (
    name ===
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCardioresTreatOtherTrSub = "";
  }
  if (name === "ros_cardioSubDiagnoses" && !value?.includes("cardiomyopathy")) {
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDes = [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyType = [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySup = [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyTypeComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySupComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertension =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiompTreatOtherTrSub = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment_toggle = false;
  }
  if (
    name ===
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCardiompTreatOtherTrSub = "";
  }
  if (name === "ros_cardioSubDiagnosesCardiomyopathyDes") {
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertension =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyType = [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyTypeComment = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyTypeComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreat =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCardiompTreatOtherTrSub = "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper =
      [];
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesCardiomyopathySup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("congestiveHeartFailure")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSpecify = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDes = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSup = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDes =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment_toggle = false;

    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment_toggle = false;
  }
  if (name === "ros_cardioSubDiagnosesCongestiveHeartFailureDes") {
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDes =
      [];
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment_toggle = false;

    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesCongestiveHeartFailureSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_cardioSubDiagnoses" && !value?.includes("hyperlipidemia")) {
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDes = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSup = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_cardioSubDiagnosesHyperlipidemiaDes") {
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre = [];
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesHyperlipidemiaSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_cardioSubDiagnoses" && !value?.includes("hypertension")) {
    updatedFormData.ros_cardioSubDiagnosesHypertensionDes = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionSup = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionadequatelyControlled = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionadequatelyControlledComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionadequatelyControlledComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTre = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionSymp = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionSympotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionSympComment = "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionSympComment_toggle = false;
  }
  if (name === "ros_cardioSubDiagnosesHypertensionDes") {
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTre = [];
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesHypertensionSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesHypertensionSupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnosesHypertensionDesActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesHypertensionSymp" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesHypertensionSympotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("ischemicHeartDisease")
  ) {
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDes = [];
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSup = [];
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub =
      "";
  }
  if (name === "ros_cardioSubDiagnosesIschemicHeartDiseaseDes") {
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub =
      "";
  }

  if (
    name === "ros_cardioSubDiagnosesIschemicHeartDiseaseSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("myocardialInfarction")
  ) {
    updatedFormData.ros_cardioSubDiagnosesMyocardialDes = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialDate = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialSup = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialBetaBlocker = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspateint = [];
    updatedFormData.ros_cardioSubDiagnosesMyocardialBetaBlockerComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialBetaBlockerComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspateintComment = "";
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspateintComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesMyocardialIspateint" &&
    value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesMyocardialSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesMyocardialSupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("peripheralVascularDisease")
  ) {
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDes = [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSup = [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer =
      "";
    updatedFormData.ros_cardiohistoryDiabetesisHistoryDiabetes = [];
    updatedFormData.ros_cardiopvdDescribe = [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSymp =
      [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment_toggle = false;
  }
  if (name === "ros_cardioSubDiagnosesPeripheralVascularDiseaseDes") {
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSymp =
      [];
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesPeripheralVascularDiseaseSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer =
      "";
  }

  if (
    name === "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("pulmonaryHypertension")
  ) {
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDes = [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSup = [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDes =
      [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment_toggle = false;
  }

  if (name === "ros_cardioSubDiagnosesPulmonaryHypertensionDes") {
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTre =
      [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDes =
      [];
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesPulmonaryHypertensionSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTre" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub =
      "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("valvularDisease")
  ) {
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseDes = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSup = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValveReplacement = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement =
      "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation =
      [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatment = [];
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment = "";
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers =
      "";
  }
  if (
    name === "ros_cardioSubDiagnosesvalvulardiseaseSup" &&
    !value?.includes("other")
  ) {
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnosesvalvulardiseaseTreatment" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers =
      "";
  }
  if (name === "ros_cardioSubDiagnoses" && !value?.includes("other")) {
    updatedFormData.ros_cardioSubDiagnosesOtherDes = [];
    updatedFormData.ros_cardioSubDiagnosesOtherSup = [];
    updatedFormData.ros_cardioSubDiagnosesOtherDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesOtherDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesOtherSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesOtherSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesOtherOtherSub = "";
    updatedFormData.ros_cardioSubDiagnosesOtherSupotherSubAnswer = "";
  }
  if (name === "ros_cardioSubDiagnosesOtherSup" && !value?.includes("other")) {
    updatedFormData.ros_cardioSubDiagnosesOtherSupotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("deepVeinThrombosis")
  ) {
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisPersistent = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSup = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisDes = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisCrDes = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSub =
      [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreat = [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment = "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer = "";
  }

  if (
    name === "ros_cardioSubDiagnosesDeepVeinThrombosisTreat" &&
    !value?.includes("medication")
  ) {
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLoc =
      [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment_toggle = false;
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAnti =
      [];
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment =
      "";
    updatedFormData.ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment_toggle = false;
  }
  if (
    name === "ros_cardioSubDiagnosesDeepVeinThrombosisTreat" &&
    !value?.includes("otherTreatment")
  ) {
    updatedFormData.ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer = "";
  }
  if (
    name === "ros_cardioSubDiagnoses" &&
    !value?.includes("heartTransplant")
  ) {
    updatedFormData.ros_cardioSubDiagnosesHeartTransplant = "";
  }

  // const commentDisabledFormData = calcCommentVisibility(updatedFormData)
  // return commentDisabledFormData;
  return updatedFormData;
}

// const calcCommentVisibility = (formData) => {
//   let tempData = {};
//   Object.keys(formData).forEach((stateVar) => {
//     if (stateVar.includes("Comment")) {
//       let isValueThere = formData[stateVar] !== "" ? true : false;
//       tempData = {
//         ...tempData,
//         [`${stateVar}_toggle`]: isValueThere,
//       };
//     }
//   });

//   return tempData;
// };
