import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getChangeSuccess,
  getResetSuccess,
  setChangeSuccess,
  setResetSuccess,
} from "../../GlobalFunction";
import BG from "../../assets/focuscares_bg.jpg";
import Logo from "../../assets/focuscares_logo.png";
import Button from "../../components/button/button";
import Image from "../../components/image/image";
import Input from "../../components/input/input";
import Toaster from "../../components/toaster/toaster";
import { useSession } from "../../contexts/SessionContext";
import { useUserContext } from "../../contexts/UserContext";
import Labels from "../../resource_files/Labels.resx";
import ValidationMessage from "../../resource_files/ValidationMessage.resx";
import { getApi, postApi } from "../../utils/axiosApiWrapper";
import "./login.css";
import Loader from "../../components/Loader/Loader";
import axiosWrapper from "../../api/axiosWrapper";

const Login = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(<Visibility />);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterMessage, setToasterMessage] = useState("");
  const resetSuccess = getResetSuccess();
  const changeSuccess = getChangeSuccess();
  const [usernameError, setUsernameError] = useState("");

  const {
    OtpSessionExpired,
    resetSessionExpired,
    changeSessionExpired,
    logoutSuccess,
  } = useSession();
  const { setUser, setHasLoggedIn, updateRoles, setProfileImg } =
    useUserContext();
  const [messageDisplayed, setMessageDisplayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const path = window.location.pathname;
  const pathName = path.substring(path.lastIndexOf("/") + 1);

  useEffect(() => {
    const hasMessageBeenDisplayed = localStorage.getItem("messageDisplayed");

    if (OtpSessionExpired && !messageDisplayed && !hasMessageBeenDisplayed) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(ValidationMessage.OTPSession_Expired);
      setMessageDisplayed(true);
      setToasterOpen(true);

      // Store in local storage to remember the message was displayed
      localStorage.setItem("messageDisplayed", "true");
    } else if (
      resetSessionExpired &&
      !messageDisplayed &&
      !hasMessageBeenDisplayed
    ) {
      // Show the error toaster message for Reset Password Session Expired
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(ValidationMessage.Reset_Password_Session_Expired);
      setMessageDisplayed(true);
      setToasterOpen(true);

      // Store in local storage to remember the message was displayed
      localStorage.setItem("messageDisplayed", "true");
    } else if (logoutSuccess && !messageDisplayed) {
      // Show the error toaster message for User logout
      // setToasterSeverity(Labels.SUCCESS);
      // setToasterMessage(ValidationMessage.Logout_Success);
      // setToasterOpen(true);
    }
  }, [OtpSessionExpired, messageDisplayed, resetSessionExpired, logoutSuccess]);

  // useEffect(() => {
  //     const acctDTO  = [];//accountData;
  //     if(acctDTO.SessionMessage != null)
  //     {
  //         setToasterSeverity(Labels.ERROR);
  //         setToasterMessage(ValidationMessage.User_Session_Expired);
  //         setToasterOpen(true);
  //         navigate(`/`);
  //     }
  //     navigate(`/`);

  // }, []);

  useEffect(() => {
    if (resetSuccess) {
      setToasterOpen(true);
      setToasterSeverity(Labels.SUCCESS);
      setToasterMessage(ValidationMessage.Password_Reset_Success);
      setResetSuccess(false);
    }
    if (changeSuccess) {
      setToasterOpen(true);
      setToasterSeverity(Labels.SUCCESS);
      setToasterMessage(ValidationMessage.Password_Change_Success);
      setChangeSuccess(false);
    }
  }, [resetSuccess, changeSuccess]);

  const onChangeHandler = (event) => {
    if (event.target.name === Labels.Username) {
      setUsername(event.target.value);
      if (!event.target.value.trim()) {
        setError({ ...error, Username: ValidationMessage.Username_Required });
      } else {
        setError({ ...error, Username: "" });
      }
    } else {
      setPassword(event.target.value);
      if (!event.target.value.trim()) {
        setError({ ...error, Password: ValidationMessage.Password_Required });
      } else {
        setError({ ...error, Password: "" });
      }
    }
  };

  const handleForgotPasswordButton = async () => {
    try {
      navigate(`/Account/ForgotPassword`);
    } catch (error) {
      return;
    }
  };

  const handleClientLogin = async () => {
    sessionStorage.setItem("clientLogin", true);
    try {
      navigate(`/Client`);
    } catch (error) {
      return;
    }
  };

  const handleUserLogin = async () => {
    sessionStorage.setItem("clientLogin", false);
    try {
      navigate(`/`);
    } catch (error) {
      return;
    }
  };

  const handleRegistration = async () => {
    try {
      setIsLoading(true);

      const url =
        pathName == "Client"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/client-login`
          : `${process.env.REACT_APP_API_BASE_URL}/users/login`;

      const response = await axios.post(url, {
        email: username,
        password: password,
      });

      if (response.status === 200) {
        const {
          roleId,
          roleName,
          email,
          firstName,
          lastName,
          homePage,
          signatureStatus,
          covidFlag,
          userClients,
          clientName,
          profileImage,
        } = response.data.data;
        setProfileImg(profileImage);
        const jwtToken = response.headers.authorization.split(" ")[1];
        sessionStorage.setItem("firstName", firstName);
        sessionStorage.setItem("roleId", roleId);
        sessionStorage.setItem("lastName", lastName);
        sessionStorage.setItem("token", jwtToken);
        sessionStorage.setItem("homePage", homePage);
        sessionStorage.setItem("emailId", email);
        axiosWrapper.defaults.headers.Authorization = `Bearer ${jwtToken}`;
        if (roleId == 5) {
          const { speciality } = response.data.data;
          const providerSignature =
            response.data.data.providerSignature.signature;
          sessionStorage.setItem("signatureStatus", signatureStatus);
          sessionStorage.setItem("covidFlag", covidFlag);
          sessionStorage.setItem("speciality", speciality);
          sessionStorage.setItem("providerSignature", providerSignature);
          sessionStorage.setItem("userClient", JSON.stringify(userClients));
        }
        if (roleId == 10) {
          sessionStorage.setItem("roleId", roleId);
          sessionStorage.setItem("lastName", clientName);
          sessionStorage.setItem("token", jwtToken);
          sessionStorage.setItem("homePage", homePage);
          sessionStorage.setItem("emailId", email);
        }
        // Password is correct, navigate to OTP screen
        setHasLoggedIn(true);

        setUser((prevUser) => ({
          ...prevUser,
          email,
          lastName,
          firstName,
          roleId,
          roleName,
          homePage,
          signatureStatus,
        }));

        setTimeout(() => {
          // navigate(`/ClientPortal/ClientDashboard`);
          // navigate(`/AdminDashboard/MemberList`);
          navigate(homePage);
        }, 1000);

        const rolesResponse = await getApi("/roles/list");

        if (rolesResponse.status === 200) {
          const rolesData = rolesResponse.data.data;
          updateRoles(rolesData);
        } else {
          return;
        }

        //MFA
        // const response1 = await postApi(
        //   "/users/updatePassword/forgot/sendOTP",
        //   { email: username },
        // );
        // const response1 = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/updatePassword/forgot/sendOTP`, {
        //     email: username,
        // });
        // if (response1.status === 200) {
        //   // Navigate to the OTP screen
        //   setTimeout(() => {
        //     navigate(`Account/Otp`, { state: { username, fromLogin: true } });
        //   }, 1000);
        //   setError({});
        // } else {
        //   setToasterSeverity(Labels.ERROR);
        //   setToasterMessage(response1.data.message);
        //   setToasterOpen(true);
        // }
      } else {
        setToasterSeverity(Labels.ERROR);
        //check user is locked or not
        if (response.data.loginLocked === true) {
          setToasterMessage(response.data.message);
          setToasterOpen(true);
          setTimeout(() => {
            axios
              .get(`/Account/ForgotPassword`)
              .then(() => {
                navigate(`/Account/ForgotPassword`);
              })
              .catch((error) => {
                return;
              });
          }, 3000);
        } else if (
          response.data.message === ValidationMessage.Password_Expired
        ) {
          // Password has expired, show the toast message
          setToasterMessage(ValidationMessage.Password_Expired);
          setToasterOpen(true);
          // Redirect to the forgot password page to reset the password
          setTimeout(() => {
            axios
              .get(`/Account/ForgotPassword`)
              .then(() => {
                navigate(`/Account/ForgotPassword`);
              })
              .catch((error) => {
                return;
              });
          }, 3000);
        } else if (
          response.data.message === ValidationMessage.Invalid_EmailId
        ) {
          //Invalid EmailID
          setToasterMessage(ValidationMessage.Invalid_EmailId);
          setToasterOpen(true);
        } else if (response.data.message === ValidationMessage.Reset_Password) {
          //first time login
          setToasterMessage(ValidationMessage.Reset_Password);
          setToasterOpen(true);
          // Redirect to the reset password page
          setTimeout(() => {
            axios
              .get(`/Account/ResetPassword`)
              .then(() => {
                navigate(`/Account/ResetPassword`, { state: { username } });
              })
              .catch((error) => {
                return;
              });
          }, 3000);
        } else {
          // Handle unsuccessful login
          if (response.data.updateResult === false) {
            setToasterMessage(response.data.message);
            setToasterOpen(true);
          } else {
            if (response.data.loginAttempts < 4) {
              setToasterMessage(
                ValidationMessage.Login_Attempt_1 +
                  response.data.loginAttempts +
                  ValidationMessage.Login_Attempt_2,
              );
              setToasterOpen(true);
            } else {
              setToasterMessage(response.data.message);
              setToasterOpen(true);
              setTimeout(() => {
                axios
                  .get(`/Account/ForgotPassword`)
                  .then(() => {
                    navigate(`/Account/ForgotPassword`);
                  })
                  .catch((error) => {
                    return;
                  });
              }, 3000);
            }
          }
        }
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error?.response?.data?.message);
      setToasterOpen(true);
      setIsLoading(false);
    }
  };

  ////MFA
  const sendOTPLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Account/SendOtpAfterLogin`,
        {
          email: username,
        },
      );

      if (response.data.success) {
        // Navigate to the OTP screen
        setTimeout(() => {
          navigate(`/Account/Otp`, { state: { username, fromLogin: true } });
        }, 1000);
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response.data.message);
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS); // Reset severity for the next toaster
    setToasterMessage("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!username) {
      setError({ ...error, Username: ValidationMessage.Username_Required });
    } else if (!password) {
      setError({ ...error, Password: ValidationMessage.Password_Required });
    } else {
      handleRegistration();
    }
  };

  //const validationCheck = (field, label) => {
  //    if (field.length < 8) {
  //        setError({ ...error, [label]: label + ' should be at least 8 characters' });
  //    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~])[A-Za-z\d!@#$%^&*()_\-+=~]{8,}/
  //    .test(field)) {
  //        setError({
  //            ...error,
  //            [label]: label + ' should contain at least one uppercase letter, one special character and some digits',
  //        });
  //    } else {
  //        setError({ ...error, [label]: '' });
  //    }
  //};

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {}, [username, password, error]);
  const passwordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };
  useEffect(() => {
    if (!passwordVisible) {
      setPasswordIcon(<VisibilityOff />);
    } else {
      setPasswordIcon(<Visibility />);
    }
  }, [passwordVisible]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="login">
      <div className="top-ripple"></div>
      <div className="bottom-ripple"></div>
      <div className="image-container" style={{ position: "relative" }}>
        <Image imgClass="bgImg" imgPath={BG} imgName="Focuscare Background" />
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            backgroundColor: "rgba(237, 142, 34, .8)",
            height: "auto",
            padding: "2px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2 style={{ color: "#fff", fontSize: "2.5rem", fontWeight: 500 }}>
            HEALTHCARE MADE PERSONAL
          </h2>
        </div>
      </div>
      <div className="input-container">
        <Image imgClass="logoImg" imgPath={Logo} imageName="Focuscare Logo" />
        <h2 style={{ color: "#1076BC" }}>WELCOME BACK!</h2>
        <form onSubmit={submitHandler}>
          <Input
            error={error["Username"] || usernameError}
            inputtype="iconInput"
            name="Username"
            dataXpath="login_username"
            value={username}
            inputIcon={<PersonIcon />}
            onChangeHandler={onChangeHandler}
            mb="30px"
            placeholder="Username"
            inputWidth="336px"
            width="400px"
            height="55px"
          />

          <div style={{ width: "300px" }}>
            <Input
              // error={error['Password'] || (error['Password'] === '' && !password ? 'Password is required' : '')}
              type={showPassword ? "text" : "password"}
              inputtype="iconInput"
              name="Password"
              dataXpath="login_password"
              value={password}
              inputIcon={<LockIcon />}
              onChangeHandler={onChangeHandler}
              inputEndIcon={
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              mb="40px"
              placeholder="Password"
              inputWidth="258px"
              width="400px"
              height="55px"
            />
            {/* {error['Password'] || (error['Password'] === '' && !password ? 'Password is required' : '')} */}
            <h3
              className="password"
              style={{
                color: "#d32f2f",
                lineHeight: "14px",
                width: "400px",
                marginTop: "-42px",
              }}
            >
              {error["Password"] ||
                (error["Password"] === "" && !password
                  ? "Password is required"
                  : "")}
            </h3>
          </div>
          <Button
            type="submit"
            onClick={submitHandler}
            width="400px"
            mb="20px"
            height="40px"
            color="white"
            cursor="pointer"
            background="#1076BC"
            fontSize="16px"
            border="none"
            borderRadius="100px"
            fontFamily="Inter,sans-serif"
            dataXpath="login_submit"
          >
            LOGIN
          </Button>
        </form>
        <div className="button-container">
          <Button
            type="button"
            onClick={handleForgotPasswordButton}
            color="black"
            background="transparent"
            fontSize="16px"
            border="none"
            borderRadius="100px"
            fontFamily="Inter,sans-serif"
            dataXpath="login_forgot_password"
          >
            Forgot Password?
          </Button>
        </div>
        <div className="button-container">
          {pathName != "Client" ? (
            <Button
              type="button"
              onClick={handleClientLogin}
              color="black"
              background="transparent"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              marginTop="10px"
              fontFamily="Inter,sans-serif"
            >
              Login as a Client
            </Button>
          ) : (
            <Button
              type="button"
              onClick={handleUserLogin}
              color="black"
              background="transparent"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              marginTop="10px"
              fontFamily="Inter,sans-serif"
            >
              Login as a User
            </Button>
          )}
        </div>
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
};

export default Login;
