import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import "../Alert/Alert.css";
import Button from "../button/button";
import SelectDropdown from "../select/select";

const Alert = ({
  open,
  handleClose,
  title,
  okText,
  cancelText,
  onAddClick,
  onNoClick,
  showSecondDropdownCondition,
  dialogTitle,
}) => {
  const programOptions = ["Exchange", "Medicaid", "Medicare"];
  const YearOptions = ["2019", "2020", "2021", "2022", "2023", "2024"];
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");

  const [schedulerOptions, setSchedulerOptions] = useState([]);

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleAddClick = () => {
    onAddClick(selectedValue);
  };

  return (
    <Dialog className="alert" open={open} onClose={handleClose} maxWidth="xl">
      {dialogTitle && (
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="alert-dialog-title"
        >
          {dialogTitle}
        </DialogTitle>
      )}
      <DialogContent>
        <div className="alert-content">
          <h5 className="alert-title" style={{ textAlign: "center" }}>
            {title}
          </h5>
        </div>

        {showSecondDropdownCondition ? (
          <div className="form1">
            <div>
              <InputLabel htmlFor="select-alternate">Add Year</InputLabel>
              <SelectDropdown
                id="select-alternate"
                title="Select Year"
                options={YearOptions}
                className="alertDialog"
                onChange={handleDropdownChange}
              />
            </div>
          </div>
        ) : null}
      </DialogContent>

      <DialogActions style={{ justifyContent: "center" }}>
        {okText && (
          <Button
            height="34px"
            width="84px"
            fontSize="14px"
            cursor="pointer"
            fontWeight="500"
            onClick={handleAddClick}
          >
            {okText}
          </Button>
        )}

        {cancelText && (
          <Button
            height="34px"
            width="84px"
            fontSize="14px"
            cursor="pointer"
            fontWeight="500"
            onClick={onNoClick}
          >
            {" "}
            {cancelText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Alert;
