const json2023 = {
  sectionId: 6,
  sectionName: "Medical History",
  isEditable: false,
  questions: [
    {
      id: 8,
      name: "medical_assistNeed",
      type: "options_tree",
      label: "Do you use any assistive devices or DME?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "medical_assistNeedSubDevices",
              type: "options_tree",
              label: "If Yes",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cane",
                  value: "cane",
                  type: "toggle_button",
                  name: "cane",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Walker",
                  value: "walker",
                  type: "toggle_button",
                  name: "walker",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Prosthesis",
                  value: "prosthesis",
                  type: "toggle_button",
                  name: "prosthesis",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "medical_assistNeedSubDevicesSubisOxygenSubDesc",
                      type: "options_tree",
                      label: "Oxygen - Describe",
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "PRN",
                          value: "prn",
                          type: "toggle_button",
                          name: "prn",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Continuous",
                          value: "continuous",
                          type: "toggle_button",
                          name: "continuous",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Day",
                          value: "day",
                          type: "toggle_button",
                          name: "day",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Night",
                          value: "night",
                          type: "toggle_button",
                          name: "night",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 1,
                      name: "medical_assistNeedSubDevicesSubisOxygenSubLitres",
                      type: "text",
                      label: "Litres / Min",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Oxygen",
                  value: "oxygen",
                  type: "toggle_button",
                  name: "oxygen",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Wheel Chair",
                  value: "wheelChair",
                  type: "toggle_button",
                  name: "wheelChair",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bedside Commode",
                  value: "besideCommode",
                  type: "toggle_button",
                  name: "besideCommode",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Urinal",
                  value: "urinal",
                  type: "toggle_button",
                  name: "urinal",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bed Pan",
                  value: "bedPan",
                  type: "toggle_button",
                  name: "bedPan",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "CPAP",
                  value: "cpap",
                  type: "toggle_button",
                  name: "cpap",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "medical_assistNeedSubDevicesSubisOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 11,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 1,
    },

    {
      id: 6,
      name: "medical_specialists",
      type: "options_tree",
      label: "Are you currently seeing any specialists?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 80,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 11,
              name: "medical_specialistsSubDatatable",
              label: "Data Table",
              type: "datatable",
              tablelist: [
                [
                  {
                    id: 1,
                    label: "Allergies",
                    name: "HRAForm_Allergies",
                    columns: [
                      {
                        label: "Substance",
                        type: "text",
                      },
                      {
                        label: "Reaction",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 2,
                    label: "BMI Index Chart",
                    name: "HRAForm_BMI_Index_Chart",
                    columns: [
                      {
                        label: "BMI",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 3,
                    label: "Counter Medications",
                    name: "HRAForm_Counter_Medications",
                    columns: [
                      {
                        label: "Date",
                        type: "date",
                      },
                      {
                        label: "Description",
                        type: "text",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 4,
                    label: "Medical History Specialists",
                    name: "HRAForm_Medical_History_Speciality",
                    columns: [
                      {
                        label: "Member Specialist",
                        type: "select",
                      },
                      {
                        label: "Speciality",
                        type: "text",
                      },
                      {
                        label: "For",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  true,
                ],
                [
                  {
                    id: 5,
                    label: "Medications",
                    name: "HRAForm_Medications",
                    columns: [
                      {
                        label: "Diagnoses",
                        type: "text",
                      },
                      {
                        label: "label Name",
                        type: "select",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "select",
                      },
                      {
                        label: "Prescribing Physician",
                        type: "text",
                      },
                      {
                        label: "Status",
                        type: "togglebutton",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 6,
                    label: "Mini Cog Versions",
                    name: "HRAForm_Minicog_Version",
                    columns: [
                      {
                        label: "Version 1",
                        type: "text",
                      },
                      {
                        label: "Version 2",
                        type: "text",
                      },
                      {
                        label: "Version 3",
                        type: "text",
                      },
                      {
                        label: "Version 4",
                        type: "text",
                      },
                      {
                        label: "Version 5",
                        type: "text",
                      },
                      {
                        label: "Version 6",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 7,
                    label: "Preventive Care",
                    name: "HRAForm_Preventive_Care",
                    columns: [
                      {
                        label: "Screen",
                        type: "text",
                      },
                      {
                        label: "Answer",
                        type: "select",
                      },
                      {
                        label: "Date",
                        type: "text",
                      },
                      {
                        label: "Method",
                        type: "text",
                      },
                      {
                        label: "Recommendation",
                        type: "checkbox",
                      },
                      {
                        label: "Education Completed",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 8,
                    label: "Previously Documented Conditions",
                    name: "HRAForm_Previously_Documented_Conditions",
                    columns: [
                      {
                        label: "Diagnosis",
                        type: "text",
                      },
                      {
                        label: "Prior HCC",
                        type: "text",
                      },
                      {
                        label: "Diagnosis Code",
                        type: "text",
                      },
                      {
                        label: "Date of Service",
                        type: "date",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 9,
                    label: "Prior Assessments",
                    name: "HRAForm_Prior_Assessments",
                    columns: [
                      {
                        label: "Date Time",
                        type: "date",
                      },
                      {
                        label: "Document",
                        type: "button",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 10,
                    label: "Stages Of Kidney Disease",
                    name: "HRAForm_Stage_Of_Kidney_Disease",
                    columns: [
                      {
                        label: "Stages",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 11,
                    label: "Alcohol Usage",
                    name: "HRAForm_Alcohol_Usage",
                    columns: [
                      {
                        label: "How many drinks",
                        type: "select",
                      },
                      {
                        label: "How often",
                        type: "select",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 12,
                    name: "Family History",
                    columns: [
                      {
                        label: "Family Member",
                        type: "select",
                      },
                      {
                        label: "Medical Condition",
                        type: "text",
                      },
                      {
                        label: "Cause of Death",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
              ],
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 80,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 81,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 2,
    },
    {
      id: 2,
      name: "pastTwelveMonths",
      type: "label",
      label: "In the past 12 months how many times have you?",
      xs: 12,
      isCommentEnabled: false,
      questionId: 3,
    },
    {
      id: 3,
      name: "medical_past12MonthPcp",
      type: "options_tree",
      label: "A. Seen your PCP",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
        },
      ],
      questionId: 4,
    },
    {
      id: 4,
      name: "medical_past12MonthEmerRoom",
      type: "options_tree",
      label: "B. Visited the Emergency Room",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 5,
    },
    {
      id: 5,
      name: "medical_past12MonthHospOvernight",
      type: "options_tree",
      label: "C. Stayed in the hospital overnight",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 6,
    },
    {
      id: 6,
      name: "medical_past12MonthNursHome",
      type: "options_tree",
      label: "D. Been in a nursing home",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 7,
    },
    {
      id: 7,
      name: "medical_past12MonthHadSurgery",
      type: "options_tree",
      label: "E. Had Surgery",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 8,
    },
    {
      id: 9,
      name: "medical_hospPrior",
      type: "options_tree",
      label: "Have you ever been hospitalized prior to the last 12 months?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_hospPriorSubDesc",
              type: "text",
              label: "Describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 9,
    },
    {
      id: 10,
      name: "healthServices",
      type: "label",
      label:
        "In the past year have you received health services from any of the providers below:",
      xs: 12,
      isCommentEnabled: false,
      questionId: 10,
    },
    {
      id: 11,
      name: "medical_healthSubPhysicalTherapist",
      type: "options_tree",
      label: "Physical Therapist",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 11,
    },
    {
      id: 12,
      name: "medical_healthSubOccupationalTherapist",
      type: "options_tree",
      label: "Occupational Therapist",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 12,
    },
    {
      id: 13,
      name: "medical_healthSubDietician",
      type: "options_tree",
      label: "Dietician",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 13,
    },
    {
      id: 14,
      name: "medical_healthSubSocialWorker",
      type: "options_tree",
      label: "Social Worker",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 14,
    },
    {
      id: 15,
      name: "medical_healthSubPharmacist",
      type: "options_tree",
      label: "Pharmacist",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 15,
    },
    {
      id: 16,
      name: "medical_healthSubSpeechTherapist",
      type: "options_tree",
      label: "Speech Therapist",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 16,
    },
    {
      id: 17,
      name: "medical_healthSubChiropractor",
      type: "options_tree",
      label: "Chiropractor",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 17,
    },
    {
      id: 18,
      name: "medical_healthSubPersonalCareWorker",
      type: "options_tree",
      label: "Personal Care Worker (HHA, CNA, PCA)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 18,
    },
    {
      id: 6,
      name: "medical_healthSubMealsOnWheels",
      type: "options_tree",
      label: "Meals on Wheels",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 19,
    },
    {
      id: 6,
      name: "medical_healthSubAdultDayCare",
      type: "options_tree",
      label: "Adult Day Care",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 20,
    },
    {
      id: 7,
      name: "treatments",
      type: "label",
      label: "In the past year have you received any of the treatments below?",
      xs: 12,
      isCommentEnabled: false,
      questionId: 21,
    },
    {
      id: 6,
      name: "medical_treatmentsSubChemotherapy",
      type: "options_tree",
      label: "Chemotherapy",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Unknown",
          value: "unknown",
          type: "toggle_button",
        },
      ],
      questionId: 22,
    },
    {
      id: 6,
      name: "medical_treatmentsSubCatheterCare",
      type: "options_tree",
      label: "Catheter Care",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Unknown",
          value: "unknown",
          type: "toggle_button",
        },
      ],
      questionId: 23,
    },
    {
      id: 6,
      name: "medical_treatmentsSubOxygen",
      type: "options_tree",
      label: "Oxygen",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Unknown",
          value: "unknown",
          type: "toggle_button",
        },
      ],
      questionId: 24,
    },
    {
      id: 6,
      name: "medical_treatmentsSubWoundCare",
      type: "options_tree",
      label: "Wound Care",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Unknown",
          value: "unknown",
          type: "toggle_button",
        },
      ],
      questionId: 25,
    },
    {
      id: 6,
      name: "medical_treatmentsSubRegularInjections",
      type: "options_tree",
      label: "Regular Injections",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Unknown",
          value: "unknown",
          type: "toggle_button",
        },
      ],
      questionId: 26,
    },
    {
      id: 6,
      name: "medical_treatmentsSubTubeFeedings",
      type: "options_tree",
      label: "Tube Feedings",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Unknown",
          value: "unknown",
          type: "toggle_button",
        },
      ],
      questionId: 27,
    },
  ],
};

const json2024 = {
  sectionId: 6,
  sectionName: "Medical History",
  isEditable: false,
  questions: [
    {
      id: 8,
      name: "medical_assistNeed",
      type: "options_tree",
      label: "Do you use any assistive devices or DME?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "medical_assistNeedSubDevices",
              type: "options_tree",
              label: "If Yes",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cane",
                  value: "cane",
                  type: "toggle_button",
                  name: "cane",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Walker",
                  value: "walker",
                  type: "toggle_button",
                  name: "walker",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Prosthesis",
                  value: "prosthesis",
                  type: "toggle_button",
                  name: "prosthesis",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Oxygen",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
                    },
                    {
                      id: 6,
                      name: "medical_assistNeedSubDevicesSubisOxygenSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "PRN",
                          value: "prn",
                          type: "toggle_button",
                          name: "prn",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Continuous",
                          value: "continuous",
                          type: "toggle_button",
                          name: "continuous",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Day",
                          value: "day",
                          type: "toggle_button",
                          name: "day",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Night",
                          value: "night",
                          type: "toggle_button",
                          name: "night",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 1,
                      name: "medical_assistNeedSubDevicesSubisOxygenSubLitres",
                      type: "text",
                      label: "Litres / Min",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Oxygen",
                  value: "oxygen",
                  type: "toggle_button",
                  name: "oxygen",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Wheel Chair",
                  value: "wheelChair",
                  type: "toggle_button",
                  name: "wheelChair",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bedside Commode",
                  value: "besideCommode",
                  type: "toggle_button",
                  name: "besideCommode",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Urinal",
                  value: "urinal",
                  type: "toggle_button",
                  name: "urinal",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bed Pan",
                  value: "bedPan",
                  type: "toggle_button",
                  name: "bedPan",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "CPAP",
                  value: "cpap",
                  type: "toggle_button",
                  name: "cpap",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "medical_assistNeedSubDevicesSubisOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 11,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 1,
    },
    {
      id: 6,
      name: "medical_specialists",
      type: "options_tree",
      label: "Are you currently seeing any specialists?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 80,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 11,
              name: "medical_specialistsSubDatatable",
              label: "Data Table",
              type: "datatable",
              tablelist: [
                [
                  {
                    id: 1,
                    label: "Allergies",
                    name: "HRAForm_Allergies",
                    columns: [
                      {
                        label: "Substance",
                        type: "text",
                      },
                      {
                        label: "Reaction",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 2,
                    label: "BMI Index Chart",
                    name: "HRAForm_BMI_Index_Chart",
                    columns: [
                      {
                        label: "BMI",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 3,
                    label: "Counter Medications",
                    name: "HRAForm_Counter_Medications",
                    columns: [
                      {
                        label: "Date",
                        type: "date",
                      },
                      {
                        label: "Description",
                        type: "text",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 4,
                    label: "Medical History Specialists",
                    name: "HRAForm_Medical_History_Speciality",
                    columns: [
                      {
                        label: "Member Specialist",
                        type: "select",
                      },
                      {
                        label: "Speciality",
                        type: "text",
                      },
                      {
                        label: "For",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  true,
                ],
                [
                  {
                    id: 5,
                    label: "Medications",
                    name: "HRAForm_Medications",
                    columns: [
                      {
                        label: "Diagnoses",
                        type: "text",
                      },
                      {
                        label: "label Name",
                        type: "select",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "select",
                      },
                      {
                        label: "Prescribing Physician",
                        type: "text",
                      },
                      {
                        label: "Status",
                        type: "togglebutton",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 6,
                    label: "Mini Cog Versions",
                    name: "HRAForm_Minicog_Version",
                    columns: [
                      {
                        label: "Version 1",
                        type: "text",
                      },
                      {
                        label: "Version 2",
                        type: "text",
                      },
                      {
                        label: "Version 3",
                        type: "text",
                      },
                      {
                        label: "Version 4",
                        type: "text",
                      },
                      {
                        label: "Version 5",
                        type: "text",
                      },
                      {
                        label: "Version 6",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 7,
                    label: "Preventive Care",
                    name: "HRAForm_Preventive_Care",
                    columns: [
                      {
                        label: "Screen",
                        type: "text",
                      },
                      {
                        label: "Answer",
                        type: "select",
                      },
                      {
                        label: "Date",
                        type: "text",
                      },
                      {
                        label: "Method",
                        type: "text",
                      },
                      {
                        label: "Recommendation",
                        type: "checkbox",
                      },
                      {
                        label: "Education Completed",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 8,
                    label: "Previously Documented Conditions",
                    name: "HRAForm_Previously_Documented_Conditions",
                    columns: [
                      {
                        label: "Diagnosis",
                        type: "text",
                      },
                      {
                        label: "Prior HCC",
                        type: "text",
                      },
                      {
                        label: "Diagnosis Code",
                        type: "text",
                      },
                      {
                        label: "Date of Service",
                        type: "date",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 9,
                    label: "Prior Assessments",
                    name: "HRAForm_Prior_Assessments",
                    columns: [
                      {
                        label: "Date Time",
                        type: "date",
                      },
                      {
                        label: "Document",
                        type: "button",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 10,
                    label: "Stages Of Kidney Disease",
                    name: "HRAForm_Stage_Of_Kidney_Disease",
                    columns: [
                      {
                        label: "Stages",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 11,
                    label: "Alcohol Usage",
                    name: "HRAForm_Alcohol_Usage",
                    columns: [
                      {
                        label: "How many drinks",
                        type: "select",
                      },
                      {
                        label: "How often",
                        type: "select",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 12,
                    name: "Family History",
                    columns: [
                      {
                        label: "Family Member",
                        type: "select",
                      },
                      {
                        label: "Medical Condition",
                        type: "text",
                      },
                      {
                        label: "Cause of Death",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
              ],
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 80,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 81,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 2,
    },
    {
      id: 2,
      name: "pastTwelveMonths",
      type: "label",
      label: "In the past 12 months how many times have you?",
      xs: 12,
      isCommentEnabled: false,
      questionId: 3,
    },
    {
      id: 3,
      name: "medical_past12MonthPcp",
      type: "options_tree",
      label: "A. Seen your PCP",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
        },
      ],
      questionId: 4,
    },
    {
      id: 4,
      name: "medical_past12MonthEmerRoom",
      type: "options_tree",
      label: "B. Visited the Emergency Room",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 4,
              name: "medical_past12MonthEmerRoomOneSubEduVisit",
              type: "options_tree",
              label:
                "Have you had a follow up visit within 7 Days of the ED visit?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                  name: "unknown",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 4,
              name: "medical_past12MonthEmerRoomTwoSubEduVisit",
              type: "options_tree",
              label:
                "Have you had a follow up visit within 7 Days of the ED visit?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                  name: "unknown",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 4,
              name: "medical_past12MonthEmerRoomThreeSubEduVisit",
              type: "options_tree",
              label:
                "Have you had a follow up visit within 7 Days of the ED visit?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                  name: "unknown",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 4,
              name: "medical_past12MonthEmerRoomFourSubEduVisit",
              type: "options_tree",
              label:
                "Have you had a follow up visit within 7 Days of the ED visit?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                  name: "unknown",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthEmerRoomFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 4,
              name: "medical_past12MonthEmerRoomFiveOrMoreSubEduVisit",
              type: "options_tree",
              label:
                "Have you had a follow up visit within 7 Days of the ED visit?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided",
                      label: "",
                      value: "",
                      type: "checkbox",
                      options: [["Education Provided", "educationProvided"]],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                    {
                      id: 10,
                      name: "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup",
                      label: "Recommendations",
                      value: "",
                      type: "checkbox",
                      options: [
                        [
                          "Follow up as indicated by ED visit.",
                          "followUpEdVisit",
                        ],
                      ],
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                  name: "unknown",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 5,
    },
    {
      id: 5,
      name: "medical_past12MonthHospOvernight",
      type: "options_tree",
      label: "C. Stayed in the hospital overnight",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHospOvernightIsFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 6,
    },
    {
      id: 6,
      name: "medical_past12MonthNursHome",
      type: "options_tree",
      label: "D. Been in a nursing home",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthNursHomeisFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 7,
    },
    {
      id: 7,
      name: "medical_past12MonthHadSurgery",
      type: "options_tree",
      label: "E. Had Surgery",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisOneSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1",
          value: "1",
          type: "toggle_button",
          name: "1",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisTwoSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "2",
          value: "2",
          type: "toggle_button",
          name: "2",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisThreeSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "3",
          value: "3",
          type: "toggle_button",
          name: "3",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisFourSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "4",
          value: "4",
          type: "toggle_button",
          name: "4",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_past12MonthHadSurgeryisFiveOrMoreSubDesc",
              type: "text",
              label: "If one or more, describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "5 or more",
          value: "5",
          type: "toggle_button",
          name: "5OrMore",
        },
      ],
      questionId: 8,
    },
    {
      id: 9,
      name: "medical_hospPrior",
      type: "options_tree",
      label: "Have you ever been hospitalized prior to the last 12 months?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "medical_hospPriorSubDesc",
              type: "text",
              label: "Describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 9,
    },
    {
      id: 6,
      name: "medical_health",
      type: "options_tree",
      label:
        "Please choose the following health services you have received in the last year.",
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          value: "physicalTherapist",
          label: "Physical Therapist",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Occupational Therapist",
          value: "occupationalTherapist",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Dietician",
          value: "dietician",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Social Worker",
          value: "socialWorker",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Pharmacist",
          value: "pharmacist",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Speech Therapist",
          value: "speechTherapist",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Chiropractor",
          value: "chiropractor",
          type: "toggle_button",
        },
        {
          subQuestionId: 8,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Personal Care Worker (HHA,CNA,PCA)",
          value: "personalCareWorker",
          type: "toggle_button",
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Meals on Wheels",
          value: "mealsOnWheels",
          type: "toggle_button",
        },
        {
          subQuestionId: 10,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Adult Day Care",
          value: "adultDayCare",
          type: "toggle_button",
        },
        {
          subQuestionId: 11,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Eye Exam",
          value: "eyeExam",
          type: "toggle_button",
        },
        {
          subQuestionId: 12,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Dental Exam",
          value: "dentalExam",
          type: "toggle_button",
        },
        {
          subQuestionId: 13,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      questionId: 11,
    },
    {
      id: 6,
      name: "medical_treatments",
      type: "options_tree",
      label:
        "Please choose the following treatments you have received in the last year.",
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          value: "chemotherapy",
          label: "Chemotherapy",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Catheter Care",
          value: "catheterCare",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Oxygen",
          value: "oxygen",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Wound Care",
          value: "woundCare",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Regular Injections",
          value: "regularInjections",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Tube Feedings",
          value: "tubeFeedings",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      questionId: 12,
    },
  ],
};

export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
