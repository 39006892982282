import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import lodash from "lodash";

import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  DFCommentTextBoxMemo,
  isCommentVisible,
  isCommentVisibleMemo,
} from "./DFComment";

import "./DynamicForms.css";

const DFRadio = ({
  inputProps,
  value,
  handleRadioChange,
  handleTextChange,
  quesSlug,
  sectionId,
  showComment,
  setShowComment,
  disableFlag,
  commentValue,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  const { isCommentEnabled, name, label, isColumn } = inputProps;

  return (
    <>
      <InputLabel
        sx={{
          color: "var(--mainTheme)",
          fontWeight: "600",
          width: "100%",
        }}
      >
        {label}
      </InputLabel>
      <Stack direction="row">
        <Grid
          item
          xs={inputProps.isCommentEnabled ? 11 : 12}
          component="fieldset"
          style={{
            borderWidth: 0.5,
            borderColor: "var(--mainTheme850)",
            borderStyle: "solid",
            borderRadius: "5px",
          }}
        >
          <FormControl
            className="inputStyle"
            fullWidth
            // disabled={disableFlag==="previewHRA"}
          >
            <RadioGroup
              className="inputStyle"
              variant="outlined"
              sx={{ marginLeft: "10px" }}
              {...(isColumn ? null : { row: true })}
              name={inputProps.name}
              value={value}
              onChange={(event) =>
                handleRadioChange(event.target.name, event.target.value)
              }
            >
              {inputProps.options.map((option, index) => (
                <FormControlLabel
                  key={option[1]}
                  {...(disableFlag && { disabled: true })}
                  control={<Radio />}
                  label={option[0]}
                  value={option[1]}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        {inputProps.isCommentEnabled === true &&
          (needMemo ? (
            <DFCommentIconMemo
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ) : (
            <DFCommentIcon
              showComment={showComment}
              setShowComment={setShowComment}
              sectionId={sectionId}
              quesSlug={quesSlug}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ))}
      </Stack>
      {isCommentEnabled &&
        (needMemo
          ? isCommentVisibleMemo(getFieldValues, name)
          : isCommentVisible(
              showComment,
              sectionId,
              quesSlug,
              commentValue,
            )) && (
          <DFCommentTextBox
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
    </>
  );
};

DFRadio.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  // formData: PropTypes.object.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
  // setFormData: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  commentValue: PropTypes.string,
};

export default DFRadio;

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFRadioMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleRadioChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;

    return (
      <>
        <InputLabel
          sx={{
            color: "var(--mainTheme)",
            fontWeight: "600",
            width: "100%",
          }}
        >
          {label}
        </InputLabel>
        <Stack direction="row">
          <Grid
            item
            xs={inputProps.isCommentEnabled ? 11 : 12}
            component="fieldset"
            style={{
              borderWidth: 0.5,
              borderColor: "var(--mainTheme850)",
              borderStyle: "solid",
              borderRadius: "5px",
            }}
          >
            <FormControl className="inputStyle" fullWidth>
              <RadioGroup
                className="inputStyle"
                variant="outlined"
                sx={{ marginLeft: "10px" }}
                row
                name={inputProps.name}
                value={value}
                onChange={(event) =>
                  handleRadioChange(event.target.name, event.target.value)
                }
              >
                {inputProps.options.map((option, index) => (
                  <FormControlLabel
                    key={option[1]}
                    {...(disableFlag && { disabled: true })}
                    control={<Radio />}
                    label={option[0]}
                    value={option[1]}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              // showComment={showComment}
              // setShowComment={setShowComment}
              // sectionId={sectionId}
              // quesSlug={quesSlug}
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFRadioMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleRadioChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};
