import { useEffect, useState } from "react";
import { getAdminDashboardData } from "../../../api/graphsApi";

export const useAdminData = (param) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!param?.planId || !param?.year) return;
    const fetchAdminData = async () => {
      try {
        setLoading(true);
        const response = await getAdminDashboardData(param);
        const DashData = response?.data?.data;
        if (typeof DashData === "object") {
          //Schedulers Data
          const schHeader = {
            callsCompleted: DashData["Total Call made for the year"],
            scheduled: DashData["Total Scheduled"],
            refused:
              DashData["Grouped By Call Result"].find(
                (result) => result.result === "Refusal",
              )?.count || 0,
            utc: DashData["Total UTC"],
          };

          const schCallCompleted = DashData[
            "Top Schedulers By Call Attempt"
          ].map((sch) => ({
            name: `${sch.firstName}`,
            //name: `${sch.firstName} ${sch.lastName}`,
            count: sch.totalCallLogCount,
          }));

          const colorAssignments = {
            Scheduled: "#00C49F",
            "No VM Setup": "rgb(255,232,185)",
            "Call Back Requested": "rgb(220,252,231)",
            "Left Voicemail": "rgb(233,215,253)",
            "Appointment Cancelled": "rgba(58, 0, 204, 0.5)",
            "Appointment Cancellation": "#FFBB28",
            "Dead Air": "rgb(255,232,185)",
            "F2F Call Back": "rgb(255,204,210)",
            Hospice: "rgb(255,232,185)",
            Ineligible: "rgb(220,252,231)",
            "Line Busy": "rgb(233,215,253)",
            "Mailbox Full": "rgb(255,204,210)",
            "No Show": "#FF8042",
            Rescheduled: "#03a9f4",
            Refusal: "#FFC0CB",
            "Wrong Number": "#ff4500",
            "Caller Disconnected": "#dc143c",
            Deceased: "#4b0082",
            Disconnected: "#a9a9a9",
            "No answer": "#7474c4",
          };

          const schCallResult = DashData["Grouped By Call Result"]
            .filter((result) => result.result)
            .map((result, index) => ({
              name: result.result || "Unknown",
              count: result.count,
              color: colorAssignments[result.result],
            }));

          const scheduled =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Scheduled",
            )?.count || 0;
          const rescheduled =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Rescheduled",
            )?.count || 0;
          const totalCallsMade = DashData["Total Call made for the year"];
          const schedulingSuccessRate =
            ((scheduled + rescheduled) / totalCallsMade) * 100;

          const refusals =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Refusal",
            )?.count || 0;
          const refusalRate = (refusals / totalCallsMade) * 100;

          const schSchedulingRate = [
            {
              name: "scheduled",
              count: scheduled,
              color: "#FFCE56",
            },
            {
              name: "Rescheduled",
              count: rescheduled,
              color: "#ffeb3b", //'#FFCE56',
            },
            {
              name: "Refused",
              count: refusals,
              color: "#FF0000", //"#f44336"
            },
          ];

          const callBack =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Call Back Requested",
            )?.count || 0;
          const noShow =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "No Show",
            )?.count || 0;
          const connectionRate =
            ((scheduled + rescheduled + callBack + refusals + noShow) /
              DashData["Total Targets Received"]) *
            100;

          const schConnectionRate = [
            {
              name: "Scheduled",
              count: scheduled,
              color: "#4caf50",
            },
            {
              name: "Rescheduled",
              count: rescheduled,
              color: "#03a9f4",
            },
            {
              name: "Call Backs Requested",
              count: DashData["Total Call Backs"],
              color: "#ffeb3b",
            },
            {
              name: "Refused",
              count: DashData["Total Refused"],
              color: "rgba(58, 0, 204, 1)",
            },
            {
              name: "No Shows",
              count: DashData["YTD No Show"],
              color: "#ff5722",
            },
          ];

          const schSuccRefData = [
            {
              name: "Success",
              count: Math.ceil(schedulingSuccessRate),
              color: "#304d6d",
            },
            {
              name: "Refusal",
              count: Math.ceil(refusalRate),
              color: "#82a0bc",
            },
          ];

          const schCancelNoshData = [
            {
              name: "Cancellation",
              count: DashData["YTD Cancellation"],
              color: "#FF0000", //"#304d6d"
            },
            {
              name: "No Shows",
              count: DashData["YTD No Show"],
              color: "#FFC0CB", //"#82a0bc"
            },
          ];

          //QAs Data
          const qaHeader = {
            total: DashData["Total Targets Received"],
            notStarted: DashData["YTD Not Started"],
            ineligible:
              DashData[
                "Total Ineligible (Deceased, Hospice, Termed, Ineligible)"
              ],
            completed: DashData["YTD Assessment Completed"],
          };

          const qaByAssigned = DashData["Top QA By QAAssigned"].map((qa) => ({
            name: `${qa.firstName}`, // ${qa.lastName}`,
            count: qa.totalmember,
          }));

          const qaByReview = DashData["Top QA By QAReviewCount"].map((qa) => ({
            name: `${qa.firstName}`, // ${qa.lastName}`,
            count: qa.totalQaReviewCount,
          }));

          const qaAssignedNotStarted = [
            {
              name: "Started",
              count:
                DashData["Total Targets Received"] -
                DashData["YTD Not Started"],
              color: "#ABCE56",
            },
            {
              name: "Not Started",
              count: DashData["YTD Not Started"],
              color: "#FFCE56",
            },
          ];

          //Coders Data
          const coderHeader = {
            total: DashData["Total Targets Received"],
            completed: DashData["YTD Assessment Completed"],
            ineligible:
              DashData[
                "Total Ineligible (Deceased, Hospice, Termed, Ineligible)"
              ],
            uploaded: DashData["YTD Assessment Uploaded"],
          };

          const coderAssessData = [
            {
              name: "Completed",
              count: DashData["YTD Assessment Completed"],
              color: "#ABCE56",
            },
            {
              name: "Uploaded",
              count: DashData["YTD Assessment Uploaded"],
              color: "#FFCE56",
            },
          ];

          const codersByAssigned = DashData["Top Coder By CoderAssigned"].map(
            (coder) => ({
              name: `${coder.firstName}`, // ${coder.lastName}`,
              count: coder.totalmember,
            }),
          );

          const codersByReview = DashData["Top Coder By CoderReviewCount"].map(
            (coder) => ({
              name: `${coder.firstName}`, // ${coder.lastName}`,
              count: coder.totalcoderReviewCount,
            }),
          );

          setData((prev) => ({
            ...prev,
            schHeader,
            schCallCompleted,
            schCallResult,
            schConnectionRate,
            connectionRate,
            schSchedulingRate,
            schSuccRefData,
            schCancelNoshData,
            qaHeader,
            qaByAssigned,
            qaByReview,
            qaAssignedNotStarted,
            coderHeader,
            coderAssessData,
            codersByAssigned,
            codersByReview,
          }));
        }
      } catch (error) {
        console.error("Error fetching Admin DashData:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAdminData();
  }, [param]);
  return { data, loading };
};
