import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import MuiInput from "../../../muiinput/MuiInput";
import Toaster from "../../../toaster/toaster";
import Labels from "../../../../resource_files/Labels.resx";
import "../../../Dashboard/ViewClinicians/AddClinician/AddClinician.css";
import ValidationMessage from "../../../../resource_files/ValidationMessage.resx";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";
import { passwordRegex, roleOptions } from "../../../utils";
import { getStateName } from "../../../../utils/getUSStateName";
import { zcs } from "zcs";
import { capitalize } from "lodash";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import { DesktopDatePicker } from "@mui/x-date-pickers";
const ZCS = zcs();

const initialAdminData = {
  userName: "",
  password: "",
  gender: "",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  phoneNumber: "",
  email: "",
  role: "",
  status: 1,
  planProgramId: "1_1",
};

export default function AddAdmin({ fetchAdminData, onClose }) {
  const [adminData, setAdminData] = useState(initialAdminData);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [userClients, setUserClients] = useState([]);
  const [toasterOpen, setToasterOpen] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const [, setErrors] = useState({});

  const [, setApiData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedClientId] = useState([]);
  const [selectedProgramData] = useState(null);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [, setSelectedPlanProgramsFlat] = useState([]);
  const [selectedClientPrograms, setSelectedClientPrograms] = useState([]);

  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");

  const ValidationError = ({ error }) => {
    return error ? (
      <h3
        className="password"
        style={{
          color: "#d32f2f",
          lineHeight: "14px",
          width: "400px",
          marginTop: "-21px",
        }}
      >
        {error}
      </h3>
    ) : null;
  };

  useEffect(() => {
    const selectedPlanProgramsFlat = selectedPlans.flatMap((plan) => {
      const clientProgramIds = planData
        .filter((item) => item.plan === plan)
        .map(
          (programItem) => `${programItem.clientId}_${programItem.programId}`,
        );

      return clientProgramIds;
    });

    const flatString = selectedPlanProgramsFlat.join(",");

    setSelectedPlanProgramsFlat(flatString);
  }, [selectedPlans, planData]);

  let prevSelectedPrograms = [];

  const handleProgramClick = (clientId, programId) => {
    const clientProgramId = `${clientId}_${programId}`;

    setSelectedPrograms((prevSelectedPrograms) => {
      if (!Array.isArray(prevSelectedPrograms)) {
        prevSelectedPrograms = [];
      }

      const programIndex = prevSelectedPrograms.findIndex(
        (program) =>
          program.clientId === clientId && program.programId === programId,
      );

      if (programIndex !== -1) {
        const newSelectedPrograms = [...prevSelectedPrograms];
        newSelectedPrograms.splice(programIndex, 1);
        return newSelectedPrograms;
      } else {
        return [...prevSelectedPrograms, { clientId, programId }];
      }
    });

    setSelectedClientPrograms((prevSelectedClientPrograms) => {
      if (!Array.isArray(prevSelectedClientPrograms)) {
        prevSelectedClientPrograms = [];
      }

      if (
        prevSelectedPrograms.length > 0 &&
        prevSelectedPrograms.some(
          (program) =>
            program.clientId === clientId && program.programId === programId,
        )
      ) {
        return prevSelectedClientPrograms.filter(
          (cp) => cp !== clientProgramId,
        );
      } else {
        return [...prevSelectedClientPrograms, clientProgramId];
      }
    });
  };

  const handlePlanClick = (plan) => {
    const programsInSelectedPlan = planData
      .filter((item) => item.plan === plan)
      .map((programItem) => ({
        clientId: programItem.clientId,
        programId: programItem.programId,
      }));

    const isAllProgramsSelected = programsInSelectedPlan.every((program) =>
      selectedPrograms.some(
        (selectedProgram) =>
          selectedProgram.clientId === program.clientId &&
          selectedProgram.programId === program.programId,
      ),
    );

    setSelectedPrograms((prevSelectedPrograms) => {
      if (isAllProgramsSelected) {
        return prevSelectedPrograms.filter(
          (program) =>
            !programsInSelectedPlan.some(
              (programInPlan) =>
                programInPlan.clientId === program.clientId &&
                programInPlan.programId === program.programId,
            ),
        );
      } else {
        return [
          ...prevSelectedPrograms,
          ...programsInSelectedPlan.filter(
            (programInPlan) =>
              !prevSelectedPrograms.some(
                (program) =>
                  program.clientId === programInPlan.clientId &&
                  program.programId === programInPlan.programId,
              ),
          ),
        ];
      }
    });

    setSelectedPlans((prevSelectedPlans) => {
      if (prevSelectedPlans.includes(plan)) {
        return prevSelectedPlans.filter((p) => p !== plan);
      } else {
        return [...prevSelectedPlans, plan];
      }
    });

    setSelectedClientPrograms((prevSelectedClientPrograms) => {
      if (isAllProgramsSelected) {
        return prevSelectedClientPrograms.filter(
          (cp) =>
            !programsInSelectedPlan.some(
              (program) => cp === `${program.clientId}_${program.programId}`,
            ),
        );
      } else {
        return [
          ...prevSelectedClientPrograms,
          ...programsInSelectedPlan.map(
            (program) => `${program.clientId}_${program.programId}`,
          ),
        ];
      }
    });
  };

  const fetchClientData = async () => {
    try {
      const response = await getApi("/clients/list");
      const apiData = response.data.data;
      setApiData(apiData);

      if (apiData && apiData.length > 0) {
        const clientProgramData = {};

        apiData.forEach((client) => {
          client.program.forEach((program) => {
            const clientProgramKey = `${client._id}_${program.id}`;
            if (!clientProgramData[clientProgramKey]) {
              clientProgramData[clientProgramKey] = {
                programId: program.id.toString(),
                program: program.name,
                clientId: client._id,
                plan: client.plan,
              };
            }
          });
        });

        setPlanData(Object.values(clientProgramData));

        const programArray = Object.values(clientProgramData);
        programArray.forEach((programs) => {
          const { clientId, programId, program, plan } = programs;
        });
      }
    } catch (error) {
      return;
    }
  };

  const uniquePlans = [...new Set(planData.map((item) => item.plan))];

  const headingStyle = {
    padding: "10px",
    backgroundColor: "var(--mainTheme)",
    textAlign: "center",
    color: "white",
    marginBottom: "auto",
  };

  useEffect(() => {
    fetchClientData();
  }, []);
  const additionalConditions = [
    !postalCodeError,
    !passwordError,
    !emailError,
    !phoneNumberError,
    !dateOfBirthError,
  ];

  const handleAdd = async () => {
    if (adminData.email !== undefined) {
      const uniquePlans = new Set();
      const userClientsData = [];

      selectedPrograms.forEach((selectedProgram) => {
        const plan = selectedProgram.clientId;
        const programId = selectedProgram.programId;

        if (!uniquePlans.has(plan)) {
          uniquePlans.add(plan);
          const userClient = {
            plan,
            programs: [programId.toString()],
          };
          userClientsData.push(userClient);
        } else {
          const existingUserClient = userClientsData.find(
            (client) => client.plan === plan,
          );
          if (existingUserClient) {
            existingUserClient.programs.push(programId.toString());
          }
        }
      });

      setUserClients(userClientsData);

      const updatedAdminData = {
        ...adminData,
        // planProgramId: plansProgramIds.toString(),
        userClients: userClientsData,
      };

      const response = await postApi("/administrators/add", updatedAdminData);

      if (response.status === 201) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        setAdminData(initialAdminData);

        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response.response.data.message);
        setToasterOpen(true);
      }
    }
  };

  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "20px",
    background: "var(--mainTheme)",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      color: "var(--mainTheme)",
    },
  };
  const getLocationInfoByZip = (zipCode) => {
    const parsedZipCode = parseInt(zipCode, 10);
    const locationInfo = ZCS.getByZip(parsedZipCode);
    return locationInfo;
  };

  useEffect(() => {
    if (adminData.zipCode) {
      try {
        const isValidPostalCode =
          /^\d{5}$/.test(adminData.zipCode) ||
          /^(0|[1-9][0-9]*)$/.test(adminData.zipCode);

        if (!isValidPostalCode) {
          setPostalCodeError(ValidationMessage.Zipcode_Format);

          return;
        }

        const locationInfo = getLocationInfoByZip(adminData.zipCode);

        if (locationInfo?.city && locationInfo?.state) {
          const stateFullName = getStateName(locationInfo?.state);
          setAdminData((prevAdminData) => ({
            ...prevAdminData,
            city: locationInfo.city,
            state: stateFullName.toUpperCase(),
          }));
          setPostalCodeError("");
        } else {
          setPostalCodeError("Invalid zip code");
        }
      } catch (error) {
        setPostalCodeError("Error fetching location information");
      }
    }
  }, [adminData.zipCode]);

  useEffect(() => {
    if (adminData.firstName && adminData.lastName) {
      //remove @ from username
      const userName = `${adminData.firstName}${capitalize(
        adminData.lastName,
      )}`;

      setAdminData((prevData) => ({
        ...prevData,
        userName: userName,
      }));
    }
  }, [adminData.firstName, adminData.lastName]);

  const handlePasswordChange = (event) => {
    const inputValue = event.target.value.toString();

    if (!inputValue) {
      setPasswordError(ValidationMessage.Password_Required);
    } else if (inputValue.length < 8) {
      setPasswordError(ValidationMessage.Password_Length);
    } else if (!passwordRegex.test(inputValue)) {
      setPasswordError(ValidationMessage.Password_Format);
    } else {
      setPasswordError("");
    }

    handleChange("password", inputValue);
  };
  const handleInputChange = (fieldName, inputValue) => {
    if (fieldName === "zipCode") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setPostalCodeError(ValidationMessage.Zipcode_Required);
      } else {
        setPostalCodeError("");

        setAdminData((prevAdminData) => ({
          ...prevAdminData,
          zipCode: inputValue,
        }));
      }
    } else if (fieldName === "email") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Required);
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Format_Invalid);
      } else {
        setEmailError("");
      }
    } else if (fieldName === "phoneNumber") {
      if (!inputValue || inputValue === "" || /^\s*$/.test(inputValue)) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Required);
      } else if (!/^\+?[0-9]{10,13}$/.test(inputValue)) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else {
        if (inputValue.length < 10 || inputValue.length > 13) {
          setPhoneNumberError(ValidationMessage.PhoneNumber_Length);
        } else {
          setPhoneNumberError("");
        }
      }
    }

    handleChange(fieldName, inputValue);
  };

  const handleChange = (field, value) => {
    if (field === "role") {
      let options = {
        Admin: 2,
        "Lead QA": 6,
        "Lead Coder": 8,
        QA: 7,
        Coder: 9,
        Scheduler: 4,
        "Lead Scheduler": 3,
      };

      setAdminData({
        ...adminData,
        role: value.toString(),
        roleId: options[value],
      });
    } else if (field === "status") {
      // Access the first element of the array and convert to 1 or 0
      const statusValue = value[0] === "Active" ? 1 : 0;
      setAdminData({
        ...adminData,
        [field]: statusValue,
      });
    } else if (field === "dateOfBirth") {
      const eventTargetValue = value;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(eventTargetValue);
      selectedDate.setHours(0, 0, 0, 0);
      const selectedYear = selectedDate.getFullYear();
      //const minimumDate = new Date(1000, 0, 1);
      const minimumDate = new Date(1900, 0, 1);

      if (
        selectedDate > currentDate ||
        selectedDate.getTime() === currentDate.getTime() ||
        selectedDate < minimumDate ||
        selectedYear < 1000 ||
        selectedYear > new Date().getFullYear() ||
        selectedDate == "Invalid Date"
      ) {
        setDateOfBirthError("Please enter valid date");
      } else {
        setDateOfBirthError("");
        setAdminData({
          ...adminData,
          [field]: eventTargetValue,
        });
      }
    } else if (field === "userName") {
      //remove @ from username
      let t = value[0];
      if (t.includes("@")) {
        t = t.replace(/@/g, "");
      }
      setAdminData({
        ...adminData,
        [field]: t,
      });
    } else {
      setAdminData({
        ...adminData,
        [field]: value.toString(),
      });
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
  };

  const handleReset = () => {
    setAdminData(initialAdminData);
    setSelectedPrograms([]);
    setErrors({});
  };

  const isFormValid = Object.keys(adminData)
    .filter((key) => key !== "userClients" && key !== "status")
    .every((key) => adminData[key] !== "");

  const sortByFirstAndSecondLetter = (a, b) => {
    const firstLetterA = a[0] ? a[0].toLowerCase() : "";
    const firstLetterB = b[0] ? b[0].toLowerCase() : "";

    if (firstLetterA !== firstLetterB) {
      return firstLetterA.localeCompare(firstLetterB);
    }

    const secondLetterA = a[1] ? a[1].toLowerCase() : "";
    const secondLetterB = b[1] ? b[1].toLowerCase() : "";

    return secondLetterA.localeCompare(secondLetterB);
  };

  return (
    <>
      <div className="addAdmin">
        <div className="">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="First Name"
                className="inputAddMembers"
                type="input"
                value={adminData.firstName}
                onChange={(event) =>
                  handleChange("firstName", event.target.value)
                }
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Last Name"
                className="inputAddMembers"
                type="input"
                value={adminData.lastName}
                onChange={(event) =>
                  handleChange("lastName", event.target.value)
                }
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="User Name"
                className="inputAddMembers"
                type="input"
                value={adminData.userName}
                onChange={(event) =>
                  handleChange("userName", event.target.value)
                }
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Email"
                className="inputAddMembers"
                type="input"
                value={adminData.email}
                onChange={(event) =>
                  handleInputChange("email", event.target.value)
                }
                required={true}
              />
              {emailError && (
                <h3
                  className="password"
                  style={{
                    color: "#d32f2f",
                    lineHeight: "14px",
                    width: "400px",
                    marginTop: "-21px",
                  }}
                >
                  {emailError}
                </h3>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Password"
                className="inputAddMembers"
                type="input"
                value={adminData.password}
                onChange={handlePasswordChange}
                required={true}
                // disabled
              />
              {passwordError && (
                <h3
                  className="password"
                  style={{
                    color: "#d32f2f",
                    lineHeight: "14px",
                    width: "400px",
                    marginTop: "-21px",
                  }}
                >
                  {passwordError}
                </h3>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Role"
                options={roleOptions.options}
                className="inputAddMembers"
                type="select"
                value={adminData.role}
                onChange={(event) => handleChange("role", event.target.value)}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Gender"
                options={["Female", "Male"]}
                className="inputAddMembers"
                type="select"
                value={adminData.gender}
                onChange={(event) => handleChange("gender", event.target.value)}
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              {/* <MuiInput
                label="Date of Birth"
                className="inputAddMembers"
                type="date"
                value={adminData.dateOfBirth}
                onChange={(event) =>
                  handleChange("dateOfBirth", event.target.value)
                }
                required={true}
              /> */}
              <Box
                sx={{ "& > :not(style)": { width: "100%", marginX: 1 } }}
                className="inputAddMembers"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker"]}
                    sx={{ width: "100%" }}
                  >
                    <DesktopDatePicker
                      views={["year", "month", "day"]}
                      sx={{ width: "100%" }}
                      // className="dateFieldBorder"
                      variant="outlined"
                      label="Date Of Birth"
                      value={dayjs(adminData?.dateOfBirth)}
                      onChange={(newValue) =>
                        handleChange("dateOfBirth", newValue)
                      }
                      slotProps={{
                        textField: {
                          disabled: true,
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <Box marginTop={1}>
                  <ValidationError error={dateOfBirthError} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Address"
                className="inputAddMembers"
                type="input"
                value={adminData.address}
                onChange={(event) =>
                  handleChange("address", event.target.value)
                }
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Zip Code"
                className="inputAddMembers"
                type="input"
                value={adminData.zipCode}
                onChange={(event) =>
                  handleInputChange("zipCode", event.target.value)
                }
                required={true}
              />
              {postalCodeError && (
                <h3
                  className="password"
                  style={{
                    color: "#d32f2f",
                    lineHeight: "14px",
                    width: "400px",
                    marginTop: "-21px",
                  }}
                >
                  {postalCodeError}
                </h3>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="City"
                className="inputAddMembers"
                type="input"
                value={adminData.city}
                onChange={(event) => handleChange("city", event.target.value)}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="State"
                className="inputAddMembers"
                type="input"
                value={adminData.state}
                onChange={(event) => handleChange("state", event.target.value)}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MuiInput
                label="Phone Number"
                className="inputAddMembers"
                type="input"
                value={adminData.phoneNumber}
                onChange={(event) =>
                  handleInputChange("phoneNumber", event.target.value)
                }
                required={true}
              />
              {phoneNumberError && (
                <h3
                  className="password"
                  style={{
                    color: "#d32f2f",
                    lineHeight: "14px",
                    width: "400px",
                    marginTop: "-21px",
                  }}
                >
                  {phoneNumberError}
                </h3>
              )}
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={3} style={{ marginLeft: "15px" }}>
                <h4 style={headingStyle}>Clients List</h4>
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {uniquePlans.sort(sortByFirstAndSecondLetter).map((plan) => (
                    <div key={plan}>
                      <Button
                        key={plan}
                        style={{
                          backgroundColor: "#1076BC",
                          color: "white",
                          width: "100%",
                          marginTop: "3px",
                        }}
                        onClick={() => handlePlanClick(plan)}
                      >
                        {plan}
                      </Button>
                      {planData
                        .filter((item) => item.plan === plan)
                        .sort((a, b) => a.program.localeCompare(b.program))
                        .map((programItem) => (
                          <div
                            key={`${programItem.clientId}-${programItem.programId}`}
                          >
                            <Button
                              style={{
                                backgroundColor: "#ccc",
                                color: "#000000de",
                                width: "100%",
                                marginTop: "3px",
                              }}
                              onClick={() =>
                                handleProgramClick(
                                  programItem.clientId,
                                  programItem.programId,
                                )
                              }
                            >
                              {programItem.program}
                            </Button>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </Grid>

              <Grid item xs={3} style={{ marginLeft: "15px" }}>
                <h4 style={headingStyle}>Selected clients</h4>

                <div style={{ marginBottom: "10px" }}>
                  {selectedClientId && (
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      {selectedProgramData ? (
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#1076BC",
                              color: "white",
                              width: "100%",
                              marginBottom: "3px",
                            }}
                          >
                            {selectedProgramData.plan}
                          </Button>

                          <div>
                            <Button
                              style={{
                                backgroundColor: "#ccc",
                                color: "#000000de",
                                width: "100%",
                                marginTop: "3px",
                              }}
                            >
                              {selectedProgramData.program}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {selectedPrograms
                        .map((selectedProgram) => {
                          return planData
                            .filter(
                              (item) =>
                                item.clientId === selectedProgram.clientId &&
                                item.programId === selectedProgram.programId,
                            )
                            .map((programItem) => programItem);
                        })
                        .flat()
                        .reduce((accumulator, programItem) => {
                          const existingPlan = accumulator.find(
                            (plan) => plan.plan === programItem.plan,
                          );

                          if (!existingPlan) {
                            accumulator.push({
                              plan: programItem.plan,
                              programs: [programItem.program],
                            });
                          } else {
                            existingPlan.programs.push(programItem.program);
                          }

                          return accumulator;
                        }, [])
                        .sort((a, b) => a.plan.localeCompare(b.plan))
                        .map((uniquePlan) => (
                          <div key={uniquePlan.plan}>
                            <Button
                              style={{
                                backgroundColor: "#1076BC",
                                color: "white",
                                width: "100%",
                                marginTop: "3px",
                              }}
                            >
                              {uniquePlan.plan}
                            </Button>
                            {uniquePlan.programs
                              .sort((a, b) => a.localeCompare(b))
                              .map((program) => (
                                <div
                                  key={`${uniquePlan.plan}-${program}`}
                                  style={{
                                    backgroundColor: "#ccc",
                                    color: "#000000de",
                                    width: "100%",
                                    marginTop: "3px",
                                    textAlign: "center",
                                    padding: "3px",
                                  }}
                                >
                                  {program}
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="btnsAddMember">
          <div style={{ marginRight: "auto" }}>
            <Button sx={dialogBtn} disabled={true} className="disabledButton">
              Unlock
            </Button>
          </div>

          <Button sx={dialogBtn} onClick={handleReset}>
            Reset
          </Button>
          <Button
            sx={dialogBtn}
            className={`dialogBtn ${
              !additionalConditions.every((condition) => condition) ||
              !isFormValid
                ? "disabledButton"
                : ""
            }`}
            disabled={
              !additionalConditions.every((condition) => condition) ||
              !isFormValid ||
              selectedPrograms.length === 0
            }
            onClick={handleAdd}
          >
            Add
          </Button>
        </div>
        <Toaster
          open={toasterOpen}
          onClose={onCloseToaster}
          severity={toasterSeverity}
          message={toasterMessage}
        />
      </div>
    </>
  );
}
