import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";

export const SelectDropdown = (props) => {
  const [value, setValue] = useState(props.value || "");
  useEffect(() => {
    // Update the state if the value prop changes (e.g., when resetting the dialog)
    setValue(props.value || "");
  }, [props.value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        value={value}
        id={props.id}
        onChange={handleChange}
        placeholder={props.title}
        className={props.className}
        renderOption={props.renderOption}
        sx={{ ...props.sx, fontSize: "15px !important" }}
        inputProps={{
          "aria-label": "Without label",
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "#1076BC",
                color: "white",
              },
            },
            PaperProps: {
              style: {
                maxHeight: 250, // Adjust the max height as needed
              },
            },
          },
        }}
      >
        <MenuItem value="" disabled>
          {props?.title}
          {props?.label}
        </MenuItem>
        {props?.options?.map((opt, id) => {
          // const isOptionDisabled = props.disabledOptions && opt !== "Completed" && value !== opt;
          const isOptionDisabled =
            props.disabledOptions &&
            value !== opt &&
            (!props.enableCompletedOnly || opt !== "Completed");
          return (
            <MenuItem
              key={id}
              value={opt}
              id={`${opt}${id}`}
              disabled={isOptionDisabled}
              sx={{ color: isOptionDisabled ? "#000000" : "none" }}
            >
              {opt}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
