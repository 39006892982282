import moment from "moment";
import { zcs } from "zcs";
import _lodash from "lodash";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*()_\-+=~]).{8,30}$/;

export const emailRegex =
  /^([0-9a-zA-Z]([+-_.][0-9a-zA-Z]+)*)+@(([0-9a-zA-Z][-w]*[0-9a-zA-Z]*.)+[a-zA-Z0-9]{2,3})$/;

export const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]$/;

export const nameRegex = /^([a-zA-Z][-'.]).{3,32}$/;

export const isValidEmail = (mail) => {
  return emailRegex.test(mail);
};

export const isValidPassword = (mail) => {
  return passwordRegex.test(mail);
};

export const isValidUrl = (url) => {
  return urlRegex.test(url);
};

export const isValidName = (nameVal) => {
  return nameRegex.test(nameVal);
};

export const minDOB = new moment("1900/01/01");
export const maxDOB = new moment();

export const populateYears = (startYear = 2019) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }
  return years;
};

export const genderOptions = [
  {
    label: "Female",
    value: "1",
  },
  {
    label: "Male",
    value: "2",
  },
  {
    label: "Other",
    value: "3",
  },
];

//1-Single, 2-Married, 3-Divorced, 4-Widowed, 5-Separated - given in postman collection
export const maritalOptions = [
  {
    id: 3,
    value: "Divorced",
  },
  {
    id: 2,
    value: "Married",
  },
  {
    id: 6,
    value: "Registered Partnership",
  },
  {
    id: 5,
    value: "Seperated",
  },
  {
    id: 1,
    value: "Single",
  },
  {
    id: 4,
    value: "Widowed",
  },
];

export const preferredContactMethodOptions = [
  {
    id: 1,
    value: "Phone",
  },
  {
    id: 2,
    value: "Email",
  },
  {
    id: 3,
    value: "Text",
  },
];

export const memberAssessmentOptions = [
  {
    id: 1,
    value: "HRA",
  },
  {
    id: 2,
    value: "Screen Only",
  },
  {
    id: 3,
    value: "Peds",
  },
];

export const membersEligibleOptions = [
  {
    id: 1,
    value: "Yes",
  },
  {
    id: 2,
    value: "No",
  },
];

export const getCityStateFromZip = (zipCode) => {
  let cityStateObj = {};
  try {
    const ZCS = zcs();
    const zipCodeInt = parseInt(zipCode);
    cityStateObj = ZCS.getByZip(zipCodeInt);
  } catch (error) {
    _lodash.set(cityStateObj, "city", "");
    _lodash.set(cityStateObj, "state", "");
  }

  return cityStateObj;
};

export const getDatetimeDayJS = (
  dateVal,
  dateFormat = "MM-DD-YYYY hh:mm:ss A",
) => {
  if (!dateVal || dateVal === "") return "";

  dayjs.extend(utc);
  dayjs.extend(timezone);
  /*
  to-do -> use this selected time zone part when needed in the future - it may come up as an enhancement
  
  const selectedTimeZone = JSON.parse(
    localStorage.getItem("selectedTimeZone"),
  ) || {
    value: "Eastern Standard Time",
    abbr: "EST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    utc: ["America/New_York"],
    isEnabled: true,
  };
  */

  const selectedTimeZone = {
    value: "Eastern Standard Time",
    abbr: "EST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Eastern Time (US &  Canada)",
    utc: ["America/New_York"],
    isEnabled: true,
  };

  const utcCity = selectedTimeZone?.utc?.[0] || "America/New_York";

  return dayjs(dateVal).tz(utcCity).format(dateFormat);
};
