import "../ViewReports/ViewReports.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./ViewReports.css";
import SelectDropdown from "../../../components/select/select";
import Button from "../../../components/button/button";
import Label from "../../../components/Label/label";
import { postApi, getApi } from "../../../utils/axiosApiWrapper";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Toaster from "../../../components/toaster/toaster";
import Labels from "../../../resource_files/Labels.resx";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Loader from "../../../components/Loader/Loader";
import JSZip from "jszip";

const ViewReports = () => {
  const FileTypeOptions = [
    "Claim",
    "Eligibility",
    "Exclusion",
    "Pharmacy",
    "Terms Expired",
  ];

  const fileTypeToApiName = {
    Claim: "MedicalClaim",
    Eligibility: "Eligibility",
    Exclusion: "Exclusion",
    Pharmacy: "Medications",
    "Terms Expired": "Termfile",
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [playingFiles, setPlayingFiles] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [newStatus, setNewStatus] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [clientData, setClientData] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadLoader, setUploadLoader] = useState({
    load: false,
    text: "Ready For Upload",
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchClientData = async () => {
      try {
        const response = await getApi("/clients/list");
        if (response.status === 200) {
          setClientData(response.data.data);

          // Extract plan options from the response and set initial state
          const initialPlanOptions = response.data.data.reduce(
            (acc, client) => {
              return acc.concat(client.plan);
            },
            [],
          );

          setPlanOptions(initialPlanOptions);
        }
      } catch (error) {
        return;
      } finally {
        // Set isLoading to false after fetching data
        setIsLoading(false);
      }
    };
    fetchClientData();
    return () => {
      setUploadLoader({ load: false, text: "Ready For Upload" });
    };
  }, []);

  const getProgramsAndYears = (selectedPlan) => {
    const selectedClient = clientData.find((client) =>
      client.plan.includes(selectedPlan),
    );

    if (selectedClient) {
      const programNames = selectedClient.program.map(
        (program) => program.name,
      );
      // const yearList = selectedClient.yearList;
      return { programNames };
    }

    return { programNames: [] };
  };

  const handlePlanChange = (event) => {
    const selectedPlan = event.target.value;
    setSelectedPlan(selectedPlan);

    setSelectedProgram("");
    setProgramOptions([]);
    setSelectedYear("");
    setYearOptions([]);

    const { programNames } = getProgramsAndYears(selectedPlan);
    setProgramOptions(programNames);
    //setYearOptions(yearList);
    //setSelectedYear([]);
  };

  const handleProgramChange = (event) => {
    const selectedProgram = event.target.value;
    setSelectedProgram(selectedProgram);
    //setYearOptions(yearList);

    const selectedClient = clientData?.find(
      (client) => client?.plan === selectedPlan,
    );

    if (selectedClient) {
      const selectedProgramData = selectedClient?.program?.find(
        (program) => program?.name === selectedProgram,
      );

      if (selectedProgramData) {
        const newYearList = selectedProgramData?.yearList;

        setYearOptions(newYearList);
      } else {
        setYearOptions([]);
        setSelectedYear("");
      }
    } else {
      setYearOptions([]);
      setSelectedYear("");
    }
  };

  const updateButtonText = (status) => {
    switch (status) {
      case 0:
        return "Validation In Progress";
      case 1:
        return "Ready for upload";
      case 2:
        return "In Progress";
      case 3:
        return "Hold";
      case 4:
        return "Completed";
      case 5:
        return "Validation Error";
      default:
        return "";
    }
  };

  useEffect(() => {
    const updatedButtonText = updateButtonText(newStatus);
    setButtonText(updatedButtonText);
  }, [newStatus]);

  const handlePlayPauseClick = async (file) => {
    setUploadLoader({ load: true, text: "In Progress" });

    if (file.status == 5) {
      try {
        const zipUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/Updated_${file.fileName}.zip`;
        const response = await fetch(zipUrl);
        if (response.status === 200) {
          const zipBlob = await response.blob();
          const zip = await JSZip.loadAsync(zipBlob);
          const [xlsxFile] = Object.values(zip.files);
          if (!xlsxFile) {
            throw new Error("No .xlsx file found in the ZIP archive");
          }
          const xlsxBlob = await xlsxFile.async("blob");
          const link = document.createElement("a");
          link.href = URL.createObjectURL(xlsxBlob);
          link.download = xlsxFile.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
          setUploadLoader({ load: false, text: "Validation Error" });
          return;
        }
      } catch (error) {
        console.error("Error downloading or extracting the file:", error);
        setUploadLoader({ load: false, text: "Validation Error" });
      }
    } else {
      try {
        if (Object.values(loadingStates).some((loading) => loading)) {
          return;
        }

        if (loadingStates[file.fileName]) {
          return;
        }

        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [file.fileName]: true,
        }));

        if (file.status !== 4 && file.status !== 5) {
          // get create-members by id

          const fileTypeApiName = fileTypeToApiName[file.type];

          const response = await getApi(
            `/import/create-members?id=${file._id}&type=${file.type}`,
          );
          if (response.status === 200) {
            setToasterOpen(true);
            setToasterSeverity(Labels.SUCCESS);
            setToasterMessage(response.data.message);
          } else {
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [file.fileName]: false,
            }));
          }
        }
      } catch (error) {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [file.fileName]: false,
        }));
      } finally {
        setPlayingFiles((prevPlayingFiles) => {
          if (prevPlayingFiles.includes(file.fileName)) {
            return prevPlayingFiles.filter(
              (playingFile) => playingFile !== file.fileName,
            );
          } else {
            return [...prevPlayingFiles, file.fileName];
          }
        });

        try {
          const response = await getApi(
            `/import/get-file-status?id=${file._id}`,
          );
          if (response.status === 200) {
            const newStatus = response.data.data[0].status;
            setUploadedFiles((prevUploadedFiles) => {
              const updatedFiles = prevUploadedFiles.map((uploadedFile) => {
                if (uploadedFile._id === file._id) {
                  return {
                    ...uploadedFile,
                    status: newStatus,
                  };
                }
                return uploadedFile;
              });
              return updatedFiles;
            });

            if (newStatus !== 1) {
              setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [file.fileName]: false,
              }));
            }
            const updatedButtonText = updateButtonText(newStatus);
          } else {
            return;
          }
        } catch (error) {
          return null;
        }
      }
    }
  };

  //get   get-file-list by id
  useEffect(() => {
    const fetchFileStatus = async (file) => {
      try {
        if (file.status == 4 || !loadingStates[file.fileName]) {
          setTimeout(() => {
            setUploadLoader({ load: false, text: "Completed" });
          }, 3000);

          return;
        }

        const response = await getApi(`/import/get-file-status?id=${file._id}`);
        if (response.status == 200) {
          const newStatus = response.data.data[0].status;
          setButtonText(updateButtonText(newStatus));
        } else {
          setTimeout(() => {
            setUploadLoader({ load: false, text: "Completed" });
          }, 3000);
          return;
        }
      } catch (error) {
        setTimeout(() => {
          setUploadLoader({ load: false, text: "Completed" });
        }, 3000);
        return;
      }
    };

    const fetchStatusForAllFiles = () => {
      uploadedFiles.forEach((file) => {
        fetchFileStatus(file);
      });
    };

    if (Object.values(loadingStates).some((loading) => loading)) {
      fetchStatusForAllFiles();

      // Set up an interval to fetch file status every 10 seconds
      const intervalId = setInterval(() => {
        fetchStatusForAllFiles();
      }, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [uploadedFiles, loadingStates, playingFiles]);

  const fetchUploadedFiles = async () => {
    try {
      //get get-file-list
      const response = await getApi("/import/get-file-list");
      setUploadedFiles(response.data.data || []);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchUploadedFiles();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const handleImportClick = async () => {
    //file selection
    if (
      !selectedFileType ||
      !selectedPlan ||
      !selectedProgram ||
      !selectedYear
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Please select option from dropdowns before importing.",
      );
      return;
    }
    // Check file extension
    const allowedExtensions = ["xlsx"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Invalid file format. Please select a .xlsx file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("plan", selectedPlan);
    formData.append("program", selectedProgram);
    formData.append("year", selectedYear);
    formData.append("type", fileTypeToApiName[selectedFileType]);

    //post Upload files
    try {
      const response = await postApi("/import/upload", formData);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        fetchUploadedFiles();
      } else {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("File upload failed. Please try again.");
      }
    } catch (error) {
      return;
    }
  };

  return (
    <div className="right-content">
      <div>
        <Card
          sx={{
            minWidth: 1360,
            boxShadow:
              "0px 3px 12px 0px rgba(0,0,0,0.2), 0px 0px 4px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)",
            marginLeft: "16px",
          }}
        >
          <CardContent>
            <div className="select-container">
              <div className="custom-labels-container">
                <Label text="File Type" />
                <SelectDropdown
                  id="importFileType"
                  sx={{
                    borderRadius: 20,
                    width: "250px",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: 500,
                    marginRight: 1,
                    marginLeft: 2,
                    textAlign: "left",
                  }}
                  title="Select"
                  onChange={(event) => setSelectedFileType(event.target.value)}
                  options={FileTypeOptions}
                  className="dropdownMemberList"
                  valueClass="dropdownValueMemberList"
                />
              </div>
              <div className="custom-labels-container">
                <Label text="Plan" />
                <SelectDropdown
                  id="importPlan"
                  sx={{
                    borderRadius: 20,
                    width: "250px",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: 500,
                    marginRight: 1,
                    marginLeft: 2,
                    textAlign: "left",
                  }}
                  title="Select"
                  onChange={handlePlanChange}
                  options={planOptions}
                  className="dropdownMemberList"
                  valueClass="dropdownValueMemberList"
                />
              </div>
              <div className="custom-labels-container">
                <Label text="Program" />
                <SelectDropdown
                  id="importProgram"
                  sx={{
                    borderRadius: 20,
                    width: "250px",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: 500,
                    marginRight: 1,
                    marginLeft: 2,
                    textAlign: "left",
                  }}
                  title="Select"
                  onChange={handleProgramChange}
                  //onChange={(event) => setSelectedProgram(event.target.value)}
                  options={programOptions}
                  className="dropdownMemberList"
                  valueClass="dropdownValueMemberList"
                  value={selectedProgram}
                />
              </div>
              <div className="custom-labels-container">
                <Label text="Year" />
                <SelectDropdown
                  id="importYear"
                  sx={{
                    borderRadius: 20,
                    width: "250px",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: 500,
                    marginRight: 1,
                    marginLeft: 2,
                    textAlign: "left",
                  }}
                  title="Select"
                  onChange={(event) => setSelectedYear(event.target.value)}
                  options={yearOptions}
                  className="dropdownMemberList"
                  valueClass="dropdownValueMemberList"
                  value={selectedYear}
                />
              </div>
            </div>
            <div className="upload-container">
              <input
                type="file"
                onChange={handleFileChange}
                id="importUploadFile"
                style={{
                  padding: "10px",
                  backgroundColor: "var(--mainTheme100)",
                }}
              />

              <Button
                width="92px"
                mt="10px"
                height="40px"
                color="white"
                background="var(--mainTheme)"
                cursor="pointer"
                fontSize="16px"
                border="none"
                borderRadius="100px"
                fontFamily="Inter,sans-serif"
                onClick={handleImportClick}
                dataXpath="importUploadButton"
              >
                Import
              </Button>
            </div>
            {/* <div className="download-link">
              <span>
                <a href="" style={{ color: "black" }}>
                  Click here
                </a>{" "}
                to download a sample eligibility file
              </span>
            </div> */}
          </CardContent>
        </Card>
      </div>

      <div
        className="uploaded-files"
        style={{
          marginTop: "21px",
          marginLeft: "17px",
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 ? (
          <Table id="importFilesTable">
            <TableHead id="importFilesTableHeader">
              <TableRow>
                <TableCell>File Type</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Program</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Uploaded File</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedFiles.map((file, index) => (
                <TableRow key={file._id}>
                  {/* <TableCell>{file.type}</TableCell> */}
                  <TableCell>
                    {FileTypeOptions.find(
                      (option) => fileTypeToApiName[option] === file.type,
                    )}
                  </TableCell>
                  <TableCell>{file.plan}</TableCell>
                  <TableCell>{file.program}</TableCell>
                  <TableCell>{file.year}</TableCell>
                  {/* <TableCell>{updateButtonText(file.status) == 'In Progress' && uploadLoader.text == 'In Progress'? 'In Progress': updateButtonText(file.status) == "Completed" 
                   &&
                  uploadLoader.text == "Completed" ? "Completed" : updateButtonText(file.status)}</TableCell> */}
                  <TableCell>{file.fileName}</TableCell>
                  <TableCell id={`statusActionButton${index}`}>
                    {index == uploadedFiles?.length - 1
                      ? file.status == 5
                        ? "Validation Error"
                        : uploadLoader.text
                      : updateButtonText(file.status)}
                  </TableCell>

                  <TableCell>
                    <Button
                      key={file.fileName}
                      dataXpath={`statusActionsButton${index}`}
                      variant="contained"
                      color={
                        playingFiles.includes(file.fileName)
                          ? "secondary"
                          : "primary"
                      }
                      onClick={() => handlePlayPauseClick(file)}
                      cursor="pointer"
                      disabled={
                        file.status === 4 ||
                        // || loadingStates[file.fileName]
                        (uploadLoader.text == "Completed" &&
                          index == uploadedFiles?.length - 1)
                      }
                    >
                      {loadingStates[file.fileName] ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : file.status === 0 ||
                        (uploadLoader.text == "In Progress" &&
                          index == uploadedFiles?.length - 1) ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : file.status === 1 ? (
                        "Start"
                      ) : file.status === 2 &&
                        uploadLoader.text == "In Progress" &&
                        index == uploadedFiles?.length - 1 ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : file.status === 4 ||
                        (uploadLoader.text == "Completed" &&
                          index == uploadedFiles?.length - 1) ? (
                        "Completed"
                      ) : file.status === 5 ||
                        (uploadLoader.text == "Validation Error" &&
                          index == uploadedFiles?.length - 1) ? (
                        "Download"
                      ) : null}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>No files available</div>
        )}
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
};

export default ViewReports;
