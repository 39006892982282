import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useContext,
  useCallback,
} from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getScreeningsData,
  getSubTree,
  setScreeningsData,
} from "./screeningsMapping";
// import { DragNDropContext } from "../../../../../../contexts/DragNDropContext";
// import { Button } from "@mui/material";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogContent";
// import CloseIcon from "@mui/icons-material/Close";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./screeningsFormData";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import dayjs from "dayjs";

import "../responsiveFillHra.css";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const Screenings = forwardRef((props, _ref) => {
  // const { handleButtonAction, padEligibilityDialog, setPadEligibilityDialog } =
  //   useContext(DragNDropContext);
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  const {
    // screeningsFormData,
    // setscreeningsFormData,
    // showComment,
    // setShowComment,
    // getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();
  const [screeningsFormData, setscreeningsFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 13;

  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  // const evaluationDate = sessionStorage.getItem("evaluationDate");
  // const finalDate = sessionStorage.getItem("finalDate");

  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      //const dbData = await props.getSectionDbData(sectionId);
      const mashedData =
        dbData?.data?.screenings?.data &&
        Object.keys(dbData?.data?.screenings?.data).length > 0
          ? getScreeningsData(dbData?.data?.screenings?.data, clientYear)
          : {};

      // const dateFields = [
      //   "dexaExamDate",
      //   "screenmicroAlbuminexamDate",
      //   "screenfobtexamDate",
      //   "screenfitexamDate",
      //   "screenkedexamDate",
      //   "screenaOneCexamDate",
      //   "ldlexamDate",
      //   "retinalExamExamDate",
      //   "padExamDate",
      // ];

      // dateFields.forEach((field) => {
      //   mashedData[field] =
      //     finalDate && finalDate !== "null"
      //       ? finalDate
      //       : evaluationDate && evaluationDate !== "null"
      //       ? evaluationDate
      //       : "";
      // });

      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };

      setscreeningsFormData(mashedCalcData);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...screeningsFormData };
    updatedFormData[name] = value;

    setscreeningsFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...screeningsFormData };
    updatedFormData[name] = value;

    setscreeningsFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...screeningsFormData };
    // updatedFormData[name] = value;
    updatedFormData[name] = dayjs(value).format();

    setscreeningsFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...screeningsFormData };
    updatedFormData[name] = value;

    setscreeningsFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    /* 
    
    togglebutton group type -> scenario -> actual behaviour -> our pattern

      <- multi ->
          pass values -> array -> array
          if value selected -> array -> array

      <- single -> 

          pass values -> string -> always pass as array
          if value selected -> string -> always change to array and set back to state

    */
    const updatedFormData = { ...screeningsFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    const evaluationDate = sessionStorage.getItem("evaluationDate");
    const finalDate = sessionStorage.getItem("finalDate");
    const hraStatus = sessionStorage.getItem("hraStatus");
    if (name === "microAlbumin") {
      updatedFormData.screen_microAlbuminSubStatus = "";
      updatedFormData.screen_microAlbuminSubStatusComment = "";
      updatedFormData.screen_microAlbuminSubStatusmemberRefused = "";
      updatedFormData.screen_microAlbuminSubStatusmemberRefusedComment = "";
      updatedFormData.screen_microAlbuminSubStatusmemberRefusedotherdescribe =
        "";
      updatedFormData.screenmicroAlbuminTBarcode = "";
      updatedFormData.screenmicroAlbuminexamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.screenmicroAlbuminsResult = "";
      updatedFormData.screenmicroAlbumindiagnosis = "";
      updatedFormData.screenmicroAlbumincomments = "";

      updatedFormData.screenmicroAlbuminScreeningPerformedBy = "";
    }
    if (name === "screen_microAlbuminSubStatus") {
      updatedFormData.screen_microAlbuminSubStatusmemberRefused = "";
      updatedFormData.screen_microAlbuminSubStatusmemberRefusedotherdescribe =
        "";
      updatedFormData.screen_microAlbuminSubStatusmemberRefusedComment = "";
    }
    if (name === "screen_microAlbuminSubStatusmemberRefused") {
      updatedFormData.screen_microAlbuminSubStatusmemberRefusedotherdescribe =
        "";
    }

    if (name === "fobt") {
      updatedFormData.screen_fobtSubStatus = "";
      updatedFormData.screen_fobtSubStatusmemberRefusedReason = "";
      updatedFormData.screen_fobtSubStatusComment = "";
      updatedFormData.screen_fobtSubStatusmemberRefusedReasonComment = "";
      updatedFormData.screen_fobtSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.screenfobtTBarcode = "";
      updatedFormData.screenfobtexamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.screenfobtResult = "";
      updatedFormData.screenfobtdiagnosis = "";
      updatedFormData.screenfobtcomment = "";
    }
    if (name === "screen_fobtSubStatus") {
      updatedFormData.screen_fobtSubStatusmemberRefusedReason = "";
      updatedFormData.screen_fobtSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.screen_fobtSubStatusmemberRefusedReasonComment = "";
    }
    if (name === "screen_fobtSubStatusmemberRefusedReason") {
      updatedFormData.screen_fobtSubStatusmemberRefusedotherdescribe = "";
    }

    if (name === "fit") {
      updatedFormData.screenfitSubStatus = "";
      updatedFormData.screenfitSubStatusmemberRefusedReason = "";
      updatedFormData.screenfitSubStatusComment = "";
      updatedFormData.screenfitSubStatusmemberRefusedReasonComment = "";
      updatedFormData.screenfitSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.screenfitTBarcode = "";
      updatedFormData.screenfitexamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.screenfitResult = "";
      updatedFormData.screenfitdiagnosis = "";
      updatedFormData.screenfitcomment = "";

      updatedFormData.screenfitScreeningPerformedBy = "";
    }
    if (name === "screenfitSubStatus") {
      updatedFormData.screenfitSubStatusmemberRefusedReason = "";
      updatedFormData.screenfitSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.screenfitSubStatusmemberRefusedReasonComment = "";
    }
    if (name === "screenfitSubStatusmemberRefusedReason") {
      updatedFormData.screenfitSubStatusmemberRefusedotherdescribe = "";
    }

    if (name === "ked") {
      updatedFormData.screenkedSubStatus = "";
      updatedFormData.screen_kedSubStatusmemberRefused = "";
      updatedFormData.screenkedSubStatusComment = "";
      updatedFormData.screen_kedSubStatusmemberRefusedComment = "";
      updatedFormData.screen_kedSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.screenkedTBarcode = "";
      updatedFormData.screenkedexamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.screenkedUrineAlbumin = "";
      updatedFormData.screenkedUrineCreatinine = "";
      updatedFormData.screenkedUrineALB = "";
      updatedFormData.screenkedCreatinine = "";
      updatedFormData.screenkedeGFR = "";
      updatedFormData.screenkeddiagnosis = "";
      updatedFormData.screenkedcomment = "";

      updatedFormData.screenkedScreeningPerformedBy = "";
    }
    if (name === "screenkedSubStatus") {
      updatedFormData.screen_kedSubStatusmemberRefused = "";
      updatedFormData.screen_kedSubStatusmemberRefusedComment = "";
      updatedFormData.screen_kedSubStatusmemberRefusedotherdescribe = "";
    }
    if (name === "screen_kedSubStatusmemberRefused") {
      updatedFormData.screen_kedSubStatusmemberRefusedotherdescribe = "";
    }

    if (name === "aOneC") {
      updatedFormData.screen_aOneCSubStatus = "";
      updatedFormData.screenaOneCSubStatus_memberRefused = "";
      updatedFormData.screen_aOneCSubStatusComment = "";
      updatedFormData.screenaOneCSubStatus_memberRefusedComment = "";
      updatedFormData.screen_aOneCSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.screenaOneCTBarcode = "";
      updatedFormData.screenaOneCexamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.screenaOneCsResult = "";
      updatedFormData.screenaOneCdiagnosis = "";
      updatedFormData.screenaOneCComment = "";

      updatedFormData.screenaOneCScreeningPerformedBy = "";
    }
    if (name === "screen_aOneCSubStatus") {
      updatedFormData.screenaOneCSubStatus_memberRefused = "";
      updatedFormData.screenaOneCSubStatus_memberRefusedComment = "";
      updatedFormData.screen_aOneCSubStatusmemberRefusedotherdescribe = "";
    }
    if (name === "screenaOneCSubStatus_memberRefused") {
      updatedFormData.screen_aOneCSubStatusmemberRefusedotherdescribe = "";
    }

    if (name === "ldl") {
      updatedFormData.ldlSubStatus = "";
      updatedFormData.ldlSubStatusmemberRefused = "";
      updatedFormData.ldlSubStatusComment = "";
      updatedFormData.ldlSubStatusmemberRefusedComment = "";
      updatedFormData.ldlSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.ldlTBarcode = "";
      updatedFormData.ldlexamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.ldlsResult = "";
      updatedFormData.ldldiagnosis = "";
      updatedFormData.ldlcomment = "";

      updatedFormData.ldlScreeningPerformedBy = "";
    }
    if (name === "ldlSubStatus") {
      updatedFormData.ldlSubStatusmemberRefused = "";
      updatedFormData.ldlSubStatusmemberRefusedComment = "";
      updatedFormData.ldlSubStatusmemberRefusedotherdescribe = "";
    }
    if (name === "ldlSubStatusmemberRefused") {
      updatedFormData.ldlSubStatusmemberRefusedotherdescribe = "";
    }

    if (name === "retinalExam") {
      updatedFormData.retinalExamSubStatus = "";
      updatedFormData.retinalExamSubStatusmemberRefused = "";
      updatedFormData.retinalExamSubStatusComment = "";
      updatedFormData.retinalExamSubStatusmemberRefusedComment = "";
      updatedFormData.retinalExamSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.retinalExamExamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.retinalExamsResult = "";

      updatedFormData.retinalExamsLeResult = "";
      updatedFormData.retinalExamsRgResult = "";

      updatedFormData.retinalExamdiagnosis = "";
      updatedFormData.retinalExamComments = "";

      updatedFormData.retinalExamScreeningPerformedBy = "";
    }
    if (name === "retinalExamSubStatus") {
      updatedFormData.retinalExamSubStatusmemberRefused = "";
      updatedFormData.retinalExamSubStatusmemberRefusedComment = "";
      updatedFormData.retinalExamSubStatusmemberRefusedotherdescribe = "";
    }
    if (name === "retinalExamSubStatusmemberRefused") {
      updatedFormData.retinalExamSubStatusmemberRefusedotherdescribe = "";
    }
    if (name === "dexa") {
      updatedFormData.dexaExamSubStatus = "";
      updatedFormData.dexaExamSubStatusmemberRefused = "";
      updatedFormData.dexaExamSubStatusComment = "";
      updatedFormData.dexaExamSubStatusmemberRefusedComment = "";
      updatedFormData.dexaExamSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.dexaExamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.dexasResult = "";

      updatedFormData.dexasTScore = "";
      updatedFormData.dexasZScore = "";
      updatedFormData.dexasSosResult = "";

      updatedFormData.dexadiagnosis = "";
      updatedFormData.dexaSubStatusComment = "";

      updatedFormData.dexaScreeningPerformedBy = "";
    }
    if (name === "dexaExamSubStatus") {
      updatedFormData.dexaExamSubStatusmemberRefused = "";
      updatedFormData.dexaExamSubStatusmemberRefusedComment = "";
      updatedFormData.dexaExamSubStatusmemberRefusedotherdescribe = "";
    }
    if (name === "dexaExamSubStatusmemberRefused") {
      updatedFormData.dexaExamSubStatusmemberRefusedotherdescribe = "";
    }

    if (name === "padVal") {
      updatedFormData.padSubStatus = "";
      updatedFormData.padSubStatusmemberRefused = "";
      updatedFormData.padSubStatusComment = "";
      updatedFormData.padSubStatusmemberRefusedComment = "";
      updatedFormData.padSubStatusmemberRefusedotherdescribe = "";
      updatedFormData.padExamDate =
        hraStatus === "In Progress" ||
        hraStatus === "Scheduled" ||
        hraStatus === "Unassigned"
          ? ""
          : finalDate !== undefined &&
            finalDate !== null &&
            finalDate !== "null" &&
            finalDate !== "undefined" &&
            finalDate !== ""
          ? finalDate
          : evaluationDate !== undefined &&
            evaluationDate !== null &&
            evaluationDate !== "null" &&
            evaluationDate !== "undefined" &&
            evaluationDate !== ""
          ? evaluationDate
          : "";
      updatedFormData.padExamsResultLeft = "";
      updatedFormData.peripheralResultLeft = "";
      updatedFormData.padExamsResultRight = "";
      updatedFormData.peripheralResultRight = "";
      updatedFormData.padDiagnosis = "";
      updatedFormData.padComments = "";

      updatedFormData.padScreeningPerformedBy = "";
    }
    if (name === "padSubStatus") {
      updatedFormData.padSubStatusmemberRefused = "";
      updatedFormData.padSubStatusmemberRefusedComment = "";
      updatedFormData.padSubStatusmemberRefusedotherdescribe = "";
    }
    if (name === "padSubStatusmemberRefused") {
      updatedFormData.padSubStatusmemberRefusedotherdescribe = "";
    }

    setscreeningsFormData(updatedFormData, clientYear);
    if (oldSelection) {
    }
    if (isMulti) {
      newSelection = value;
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection.push(value);
      }
    }

    let deletedValue =
      isMulti === true
        ? lodash.difference(oldSelection, newSelection)?.[0] || ""
        : oldSelection?.[0] || "";

    if (deletedValue && deletedValue !== "") {
      let subTreeDeletedData = handleDeleteSubTree(
        updatedFormData,
        name,
        deletedValue,
      );
      subTreeDeletedData[name] = newSelection;
      setscreeningsFormData(subTreeDeletedData, clientYear);
      return null;
    } else {
      updatedFormData[name] = newSelection;
      setscreeningsFormData(updatedFormData, clientYear);
    }

    updatedFormData[name] = newSelection;
    setscreeningsFormData(updatedFormData, clientYear);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...screeningsFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setscreeningsFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      return setScreeningsData(screeningsFormData, clientYear);
    },
  }));

  useEffect(() => {
    props?.setAutoSaveScreeningNeeded(screeningsFormData);
  }, [screeningsFormData]);

  const handleDeleteSubTree = (objectData, name, deletedValue) => {
    const deleteRegex = getSubTree(name, deletedValue);
    if (deleteRegex !== "") {
      let updatedObjectData = deleteSubTree(objectData, deleteRegex);
      return updatedObjectData;
    }
    return objectData;
  };

  const getFieldValues = useCallback(
    (name) => {
      return screeningsFormData[name];
    },
    [screeningsFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setscreeningsFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        // style={{
        //   margin: "10px 20px",
        // }}
        className="self-form-container"
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = screeningsFormData[name] || "";
            // const inputValuesRadio = (() => {
            //   if (screeningsFormData[name]) {

            //     if (["normal", "mild", "moderate", "significant", "severe"].includes(name)) {

            //       if (screeningsFormData[name] === "1") {

            //         return name;
            //       }
            //     }

            //     return screeningsFormData[name];
            //   } else {
            //     return "";
            //   }
            // })();

            const commentValue = `${
              screeningsFormData?.[`${name}Comment`]
                ? `${screeningsFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    formData={screeningsFormData}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = screeningsFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  // formData={screeningsFormData}
                  formData={screeningsFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  // setFormData={setscreeningsFormData}
                  setFormData={setscreeningsFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                    // disableFlag={disableFlag} - check & implement
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={quesSlug}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default Screenings;
