import { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { Close } from "@mui/icons-material";
import "./Dialog.css";
import AddClient from "../Dashboard/ViewClients/AddClient/AddClient";
import AddAdmin from "../Dashboard/ViewAdministrators/AddAdmin/AddAdmin";
import AddClinician from "../Dashboard/ViewClinicians/AddClinician/AddClinician";
import AlertContext from "../../contexts/AlertContext";

export default function AddDialog({
  title,
  onClose,
  onAdd,
  componentToShow,
  fetchClientData,
  fetchAdminData,
  fetchClinicianData,
}) {
  const [showErrors, setShowErrors] = useState(false);
  const alertContext = useContext(AlertContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [clientData, setClientData] = useState({
    clientName: "",
    plan: "",
    program: "",
    healthPlanName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
    year: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setClientData((prevData) => ({ ...prevData, [field]: value }));
    handleInputChange(field);
  };

  const handleInputChange = (field) => {
    if (!clientData[field].trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "This field is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };

  const handleAdd = () => {
    if (Object.values(clientData).some((value) => !value)) {
      setShowErrors(true);
    } else {
      onAdd(clientData);
      alertContext.setAddDialog(false);
      setShowSuccessMessage(false);
    }
  };
  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
    onClose();
  };

  const handleClose = () => {
    alertContext.setAddDialog(false);
  };

  return (
    <div>
      <Dialog
        open={alertContext.addDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="alert-dialog-title"
        >
          {title}
          <div
            style={{ color: "white", cursor: "pointer", marginTop: "10px" }}
            onClick={handleClose}
          >
            <Close />
          </div>
        </DialogTitle>
        <DialogContent>
          {componentToShow === "client" && (
            <AddClient
              // onAdd={handleAdd}
              onClose={handleClose}
              fetchClientData={fetchClientData}
              clientData={clientData}
              showErrors={showErrors}
              handleChange={handleChange}
              handleInputChange={handleInputChange}
              showSuccessMessage={showSuccessMessage}
              onCloseSuccessMessage={handleCloseSuccessMessage}
            />
          )}
          {componentToShow === "admin" && (
            <AddAdmin
              fetchAdminData={fetchAdminData}
              onAdd={handleAdd}
              onClose={handleClose}
            />
          )}
          {componentToShow === "clinician" && (
            <AddClinician
              onAdd={handleAdd}
              onClose={handleClose}
              fetchClinicianData={fetchClinicianData}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
