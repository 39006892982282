export const staticData = [
  {
    table_name: "BMI Index Chart",
    column_data: [
      {
        name: "BMI",
        rows: [
          //"Underweight: less than 18.5",
          "Normal weight: 18.5 to 24.9",
          "Overweight: 25 to 29.9",
          "Obese: 30 to 34.9",
          "Moderate obesity 35 to 39.9",
          "Morbid Obesity = or >40",
          "Or if BMI is > 35 and documented comorbid condition",
        ],
      },
    ],
  },
  {
    table_name: "BMI Index Chart 2024",
    column_data: [
      {
        name: "BMI",
        rows: [
          "Underweight: <18.5",
          "Normal weight: 18.5 to 24.9",
          "Overweight: 25 to 29.9",
          "Obese: 30 to 34.9",
          "Moderate obesity 35 to 39.9",
          "Morbid Obesity = or >40",
          "Or if BMI is > 35 and documented comorbid condition",
        ],
      },
    ],
  },
  {
    table_name: "Activities Chart",
    column_data: [
      {
        name: "Score",
        rows: ["1", "2 - 3", "4 or more"],
      },
      {
        name: "Indication",
        rows: [
          "Full Function",
          "Moderate Impairment",
          "Severe Functional Impairment",
        ],
      },
    ],
  },
  {
    table_name: "Mini Cog Versions",
    column_data: [
      {
        name: "Version 1",
        rows: ["Banana", "Sunrise", "Chair"],
      },
      {
        name: "Version 2",
        rows: ["Leader", "Season", "Table"],
      },
      {
        name: "Version 3",
        rows: ["Village", "Kitchen", "Baby"],
      },
      {
        name: "Version 4",
        rows: ["River", "Nation", "Finger"],
      },
      {
        name: "Version 5",
        rows: ["Captain", "Garden", "Picture"],
      },
      {
        name: "Version 6",
        rows: ["Daughter", "Heaven", "Mountain"],
      },
    ],
  },
  {
    table_name: "Depression Severity",
    column_data: [
      {
        name: "Score",
        rows: ["1-4", "5-9", "10-14", "15-19", "20-27"],
      },
      {
        name: "Depression Severity",
        rows: [
          "Minimal Depression",
          "Mild Depression",
          "Moderate Depression",
          "Moderately Severe Depression",
          "Severe Depression",
        ],
      },
    ],
  },
  {
    table_name: "Stages Of Kidney Disease",
    column_data: [
      {
        name: "Stages",
        rows: [
          "Stage 1: eGFR 90 or Greater",
          "Stage 2: eGFR 60-89",
          "Stage 3a: eGFR 45-59",
          "Stage 3b: eGFR 30-44",
          "Stage 4: eGFR 15-29",
          "Stage 5: eGFR Less than 15",
        ],
      },
    ],
  },
];
