// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datatable-memberlist-viewclinician {
    margin-top: 15px;
}

.datatable-memberlist-viewclinician .css-75kgeb-MuiTableCell-root {
    padding: 16px !important;
    font-family: sans-serif;
    border-bottom: unset !important;
}
.datatable-memberlist-viewclinician .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root {
    box-shadow: -5px -8px 8px 0px rgba(255, 255, 255, 0.75), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/ViewClinicians/ViewClinicians.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,+BAA+B;AACnC;AACA;IACI,4FAA4F;AAChG","sourcesContent":[".datatable-memberlist-viewclinician {\r\n    margin-top: 15px;\r\n}\r\n\r\n.datatable-memberlist-viewclinician .css-75kgeb-MuiTableCell-root {\r\n    padding: 16px !important;\r\n    font-family: sans-serif;\r\n    border-bottom: unset !important;\r\n}\r\n.datatable-memberlist-viewclinician .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root {\r\n    box-shadow: -5px -8px 8px 0px rgba(255, 255, 255, 0.75), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
