const json2023 = {
  sectionId: 11,
  sectionName: "Pain",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "pain_patientExperiencePain",
      type: "options_tree",
      label: "Does the patient experience pain?",
      value: "patientExperiencePain",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      questionId: 1,
      editToggle: false,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          required: true,
          editToggle: false,
          isCommentEnabled: true,
          label: "Yes",
          value: "yes",
          name: "yes",
          isConfigured: true,
          type: "toggle_button",
          //name:"painAcute",
          subQuestions: [
            {
              id: 6,
              name: "pain_patientExperiencePainSubPainAcute",
              type: "options_tree",
              label: "Is the Pain Acute?",
              value: "painAcute",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  name: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              //depth: 2,
            },
            {
              id: 6,
              name: "pain_patientExperiencePainSubPainChronic",
              value: "painChronic",
              type: "options_tree",
              //value: "painChronic",
              label: "Is the Pain Chronic?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  name: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 1,
                      name: "pain_patientExperiencePainSubPainChronicSubDescribe",

                      type: "options_tree",
                      label: "Describe",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      value: "describe",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 4,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          name: "active",
                          label: "Active",
                          subQuestions: [],
                          editToggle: false,
                          value: "active",
                          isCommentEnabled: false,
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          name: "historyOf",
                          label: "History Of",
                          value: "historyOf",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          name: "ruleOut",
                          label: "Rule Out",
                          value: "ruleOut",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                    },
                    {
                      id: 4,
                      name: "pain_patientExperiencePainSubPainChronicSubWhere",
                      depth: 4,
                      type: "text",
                      label: "Where",
                      value: "where",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      hasSubQuestions: false,
                    },
                    {
                      id: 4,
                      name: "pain_patientExperiencePainSubPainChronicSubRateYourPain",
                      type: "text",
                      label:
                        "Rate your pain on a scale of 1-10, with 1 being very mild and 10 being severe",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      value: "rateYourPain",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      hasSubQuestions: false,
                      depth: 4,
                    },

                    {
                      id: 4,
                      name: "pain_patientExperiencePainSubPainChronicSubFrequency",
                      type: "options_tree",
                      label: "Frequency of pain",
                      editToggle: false,
                      value: "frequency",
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 12,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          name: "occasional",
                          label: "Occasional",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          value: "occasional",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          name: "oneOrMoreTimes",
                          label: "One or more times a week",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          value: "oneOrMoreTimes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          name: "allOfTheTime",
                          label: "All of the time",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          value: "allOfTheTime",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 2,
              depth: 1,
              editToggle: false,
            },
            {
              id: 6,
              name: "pain_patientExperiencePainSubPainManagementTiming",
              type: "options_tree",
              value: "painManagementTiming",
              label: "Is the Patient Undergoing Pain Management Planning?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding",
                      type: "options_tree",
                      value: "isPatientResponding",
                      label:
                        "Is the Patient Responding to the Pain Management Plan?",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 4,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          id: 2,
                          name: "yes",
                          label: "Yes",
                          fullWidth: true,
                          required: true,
                          xs: 12,
                          isCommentEnabled: true,
                          isConfigured: true,
                          isModified: true,
                          editToggle: false,
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          id: 2,
                          name: "no",
                          label: "No",
                          fullWidth: true,
                          required: true,
                          xs: 12,
                          isCommentEnabled: true,
                          isConfigured: true,
                          isModified: true,
                          editToggle: false,
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  name: "yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 3,
              depth: 1,
              editToggle: false,
            },
            {
              id: 7,
              name: "pain_patientExperiencePainSubOpioidMedication",
              value: "opioidMedication",
              type: "options_tree",
              label: "Is the member taking a narcotic or Opioid Medication?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  name: "yes",
                  hasSubQuestions: true,
                  value: "yes",
                  subQuestions: [
                    {
                      id: 1,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubDrugsTake",
                      type: "text",
                      value: "drugsTake",
                      label: "What drugs do you take for it?",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 4,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubTolerance",
                      value: "tolerance",
                      type: "options_tree",
                      label: "Tolerance?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          value: "no",
                          label: "No",
                          name: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubWithdrawal",
                      value: "withdrawal",
                      type: "options_tree",
                      label: "Withdrawal?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          name: "no",
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage",
                      value: "increasedUsage",
                      type: "options_tree",
                      label:
                        "Increased usage over a longer period that intended?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort",
                      value: "unsuccessEffort",
                      type: "options_tree",
                      label:
                        "Desire or unsuccessful effort to cut down on use?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 5,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubExcessTime",
                      value: "excessTimeSpent",
                      type: "options_tree",
                      label:
                        "Excess time spent in activities to obtain the substance?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 6,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge",
                      value: "patientKnowledge",
                      type: "options_tree",
                      label:
                        "Continued use despite Doctor advice or patient knowledge of habituation?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 7,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem",
                      value: "psychologicalProblem",
                      type: "options_tree",
                      label:
                        "Physical or Psychological Problem related to the substance use?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 8,
                      depth: 4,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 4,
              depth: 1,
              editToggle: false,
            },
            {
              id: 6,
              name: "pain_patientExperiencePainSubPatientAdvised",
              value: "patientAdvised",
              type: "options_tree",
              label:
                "Was the patient advised regarding the potential for dependence?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  name: "yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  name: "no",
                  value: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 5,
              depth: 1,
              editToggle: false,
            },
          ],
        },

        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "no",
          type: "toggle_button",
          depth: 1,
        },
      ],
    },
  ],
};

const json2024 = {
  sectionId: 11,
  sectionName: "Pain",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "pain_patientExperiencePain",
      type: "options_tree",
      label: "Does the patient experience pain?",
      value: "patientExperiencePain",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      questionId: 1,
      editToggle: false,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          required: true,
          editToggle: false,
          isCommentEnabled: true,
          label: "Yes",
          value: "yes",
          name: "yes",
          isConfigured: true,
          type: "toggle_button",
          //name:"painAcute",
          subQuestions: [
            {
              id: 6,
              name: "pain_patientExperiencePainSubPainAcute",
              type: "options_tree",
              label: "Is the Pain Acute?",
              value: "painAcute",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  name: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              //depth: 2,
            },
            {
              id: 6,
              name: "pain_patientExperiencePainSubPainChronic",
              value: "painChronic",
              type: "options_tree",
              //value: "painChronic",
              label: "Is the Pain Chronic?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  name: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 1,
                      name: "pain_patientExperiencePainSubPainChronicSubWhere",
                      depth: 4,
                      type: "text",
                      label: "Where",
                      value: "where",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      hasSubQuestions: false,
                    },
                    {
                      id: 2,
                      name: "pain_patientExperiencePainSubPainChronicSubRateYourPain",
                      type: "text",
                      label:
                        "Rate your pain on a scale of 1-10, with 1 being very mild and 10 being severe",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      value: "rateYourPain",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 2,
                      hasSubQuestions: false,
                      depth: 4,
                    },

                    {
                      id: 3,
                      name: "pain_patientExperiencePainSubPainChronicSubFrequency",
                      type: "options_tree",
                      label: "Frequency of pain",
                      editToggle: false,
                      value: "frequency",
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 12,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          name: "occasional",
                          label: "Occasional",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          value: "occasional",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 5,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          name: "oneOrMoreTimes",
                          label: "One or more times a week",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          value: "oneOrMoreTimes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 5,
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          name: "allOfTheTime",
                          label: "All of the time",
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          value: "allOfTheTime",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 2,
              depth: 1,
              editToggle: false,
            },
            {
              id: 6,
              name: "pain_patientExperiencePainSubPainManagementTiming",
              type: "options_tree",
              value: "painManagementTiming",
              label: "Is the Patient Undergoing Pain Management Planning?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding",
                      type: "options_tree",
                      value: "isPatientResponding",
                      label:
                        "Is the Patient Responding to the Pain Management Plan?",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 4,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          id: 2,
                          name: "yes",
                          label: "Yes",
                          fullWidth: true,
                          required: true,
                          xs: 12,
                          isCommentEnabled: true,
                          isConfigured: true,
                          isModified: true,
                          editToggle: false,
                          value: "yes",
                          type: "toggle_button",
                          subQuestionId: 1,
                        },
                        {
                          id: 2,
                          name: "no",
                          label: "No",
                          fullWidth: true,
                          required: true,
                          xs: 12,
                          isCommentEnabled: true,
                          isConfigured: true,
                          isModified: true,
                          editToggle: false,
                          value: "no",
                          type: "toggle_button",
                          subQuestionId: 2,
                        },
                      ],
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  name: "yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 3,
              depth: 1,
              editToggle: false,
            },
            {
              id: 7,
              name: "pain_patientExperiencePainSubOpioidMedication",
              value: "opioidMedication",
              type: "options_tree",
              label: "Is the member taking a narcotic or Opioid Medication?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  name: "yes",
                  hasSubQuestions: true,
                  value: "yes",
                  subQuestions: [
                    {
                      id: 1,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubDrugsTake",
                      type: "text",
                      value: "drugsTake",
                      label: "What drugs do you take for it?",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 4,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubTolerance",
                      value: "tolerance",
                      type: "options_tree",
                      label: "Tolerance?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          value: "no",
                          label: "No",
                          name: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubWithdrawal",
                      value: "withdrawal",
                      type: "options_tree",
                      label: "Withdrawal?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          name: "no",
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage",
                      value: "increasedUsage",
                      type: "options_tree",
                      label:
                        "Increased usage over a longer period that intended?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort",
                      value: "unsuccessEffort",
                      type: "options_tree",
                      label:
                        "Desire or unsuccessful effort to cut down on use?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 5,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubExcessTime",
                      value: "excessTimeSpent",
                      type: "options_tree",
                      label:
                        "Excess time spent in activities to obtain the substance?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 6,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge",
                      value: "patientKnowledge",
                      type: "options_tree",
                      label:
                        "Continued use despite Doctor advice or patient knowledge of habituation?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 7,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem",
                      value: "psychologicalProblem",
                      type: "options_tree",
                      label:
                        "Physical or Psychological Problem related to the substance use?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 8,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubPatientAdvised",
                      value: "patientAdvised",
                      type: "options_tree",
                      label:
                        "Was the patient advised regarding the potential for dependence?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 9,
                      depth: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "pain_patientExperiencePainSubOpoidMedicationSubNaloAvail",
                      value: "naloxoneAvailability",
                      type: "options_tree",
                      label:
                        "Do you have Naloxone /Narcan available in case of emergency?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 5,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          name: "yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          name: "no",
                          value: "no",
                          type: "toggle_button",
                          depth: 5,
                        },
                      ],
                      subQuestionId: 10,
                      depth: 4,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 4,
              depth: 1,
              editToggle: false,
            },
          ],
        },

        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          name: "no",
          type: "toggle_button",
          depth: 1,
        },
      ],
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
