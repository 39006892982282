// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typo-text {
  text-align: left !important;
  font-size: 16px !important;
  color: var(--mainTheme);
  font-weight: 200 !important;
  margin: 12px 50px 8px 20px !important;
}

.typo-texts {
  font-weight: 200 !important;
  margin: 12px 150px 8px 20px !important;
  color: var(--mainTheme);
  text-align: left !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Label/label.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,uBAAuB;EACvB,2BAA2B;EAC3B,qCAAqC;AACvC;;AAEA;EACE,2BAA2B;EAC3B,sCAAsC;EACtC,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":[".typo-text {\n  text-align: left !important;\n  font-size: 16px !important;\n  color: var(--mainTheme);\n  font-weight: 200 !important;\n  margin: 12px 50px 8px 20px !important;\n}\n\n.typo-texts {\n  font-weight: 200 !important;\n  margin: 12px 150px 8px 20px !important;\n  color: var(--mainTheme);\n  text-align: left !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
