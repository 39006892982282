import { useState, useEffect } from "react";
import NurseComponent from "../../components/NurseDashboard/NurseComponent";
import NurseDashboardComponent from "../../components/NurseDashboard/NurseDashboardComponent/NurseDashboardComponent";
import NurseTableComponent from "../../components/NurseDashboard/NurseTable/NurseTable";
import TooltipEllipsisCell from "../../components/TooltipEllipsisCell/TooltipEllipsisCell";
import Button from "../../components/button/button";
import MuiInput from "../../components/muiinput/MuiInput";
import SelectDropdown from "../../components/select/select";
import "./NurseDashboard.scss";
import NurseListPage from "../../components/NurseDashboard/NurseListPage";

import DigitalSignatureDialog from "./digitalSignature";
import { useUserContext } from "../../contexts/UserContext";
const NurseDashboard = () => {
  const [assignedClinicianValues, setAssignedClinicianValues] = useState({});
  const [visitTypeValues, setVisitTypeValues] = useState({});
  const [scheduleDateValues, setScheduleDateValues] = useState({});
  const [termDateValues, setTermDateValues] = useState({});
  const [selectAllHeaderCheckbox, setSelectAllHeaderCheckbox] = useState(false);
  const [selectedRowCheckboxes, setSelectedRowCheckboxes] = useState({});
  const [openDigitalSignBox, setOpenDigitalSignBox] = useState(false);
  const openDigitalSignatureBox = () => {
    setOpenDigitalSignBox(true);
  };
  const closeDigitalSignatureBox = () => {
    setOpenDigitalSignBox(false);
  };
  const { user } = useUserContext();
  const signatureStatus = sessionStorage.getItem("signatureStatus");
  useEffect(() => {
    if (signatureStatus == 0) openDigitalSignatureBox();
  }, [signatureStatus]);

  const handleHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAllHeaderCheckbox(checked);

    setSelectedRowCheckboxes((prevValues) => {
      const updatedCheckboxes = {};
      for (const rowIndex in prevValues) {
        updatedCheckboxes[rowIndex] = checked;
      }
      return updatedCheckboxes;
    });
  };

  const handleRowCheckboxChange = (checked, rowIndex) => {
    setSelectedRowCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));

    const allRowCheckboxesChecked = Object.values({
      ...selectedRowCheckboxes,
      [rowIndex]: checked,
    }).every((isChecked) => isChecked);

    setSelectAllHeaderCheckbox(allRowCheckboxesChecked);
  };

  const handleVisitTypeChange = (event, rowIndex) => {
    const { value } = event.target;
    setVisitTypeValues((prevValues) => ({
      ...prevValues,
      [rowIndex]: value,
    }));
  };

  const handleScheduleDateChange = (event, rowIndex) => {
    const { value } = event.target;
    setScheduleDateValues((prevValues) => ({
      ...prevValues,
      [rowIndex]: value,
    }));
  };

  const handleTermDateChange = (event, rowIndex) => {
    const { value } = event.target;
    setTermDateValues((prevValues) => ({
      ...prevValues,
      [rowIndex]: value,
    }));
  };

  const tableHeaders = [
    { field: "Plan", headerName: "Plan" },
    { field: "Program", headerName: "Program" },
    { field: "LOB", headerName: "LOB" },
    {
      field: "ID",
      headerName: "ID",
      type: "number",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value.toString()} maxWidth={100} />
      ),
    },
    {
      field: "Name",
      headerName: "Name",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    {
      field: "Contact",
      headerName: "Contact",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    { field: "City", headerName: "City" },
    { field: "Evolution_Date", headerName: "Evolution_Date" },
    { field: "Visit_Type", headerName: "Visit_Type" },
    { field: "Term_date", headerName: "Term_date" },
    { field: "Days", headerName: "Days" },
    { field: "HRA_Status", headerName: "HRA_Status" },
    { field: "Action", headerName: "Action", hidesortable: true },
    { field: "Offline", headerName: "Offline" },
  ];
  const dataMemberList = [
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001111,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001112,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 7110011113,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001114,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001115,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001116,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001117,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001118,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001119,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "DSNP",
      ID: 711001120,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001121,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001122,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001123,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001124,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001125,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001126,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001127,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001128,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001129,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
    {
      Plan: "VPHP",
      Program: "MEDICARE",
      LOB: "VPHP",
      ID: 711001130,
      Name: "John Doe(A)",
      Contact: "Terrace Valey Terrace Valley",
      City: "CHATHAM",
      Evolution_Date: "04-07-2023 01:27 PM",
      Visit_Type: "In Person",
      Term_date: "04-07-2023 01:27 PM",
      Days: "31",
      HRA_Status: "Completed",
      Action: (
        <Button
          type="submit"
          width="70px"
          height="28px"
          cursor="pointer"
          color="white"
          background="#1076BC"
          fontSize="12px"
          border="none"
          borderRadius="100px"
          fontFamily="Inter,sans-serif"
        >
          Fill HRA
        </Button>
      ),
      Offline: "",
    },
  ];

  const dataMemberListWithDropdown = dataMemberList.map((data, index) => ({
    ...data,
    Visit_Type: (
      <SelectDropdown
        label="Visit Type"
        value={visitTypeValues[index] || ""}
        onChange={(event) => handleVisitTypeChange(event, index)}
        options={["In Person", "Virtual", "Telephonic"]}
      />
    ),
    Evolution_Date: (
      <MuiInput
        type="date"
        value={scheduleDateValues[index] || ""}
        onChange={(event) => handleScheduleDateChange(event, index)}
      />
    ),
    Term_date: (
      <MuiInput
        type="date"
        value={termDateValues[index] || ""}
        onChange={(event) => handleTermDateChange(event, index)}
      />
    ),
    Contact: <TooltipEllipsisCell value={data.Contact} maxWidth={100} />,
    Name: <TooltipEllipsisCell value={data.Name} maxWidth={100} />,
    ID: <TooltipEllipsisCell value={data.ID} maxWidth={80} />,
    Offline: (
      <input
        type="checkbox"
        style={{ width: "20px", height: "18px", cursor: "pointer" }}
        checked={selectedRowCheckboxes[index] || false}
        onChange={(event) => handleRowCheckboxChange(event, index)}
      />
    ),
  }));
  return (
    <div
      style={{ marginTop: "35px", padding: "8px" }}
      className="right-content nurse-dashboard"
    >
      {openDigitalSignBox ? (
        <DigitalSignatureDialog
          open={openDigitalSignBox}
          onCloseSignatureDialogBox={closeDigitalSignatureBox}
        />
      ) : (
        <>
          <div className="datatable-nurse">
            <NurseListPage />
          </div>
        </>
      )}
    </div>
  );
};

export default NurseDashboard;
