import React, { useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./CustomAudioPlayer.css"; // for additional custom styles]
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close"; // Import the close icon

const CustomAudioPlayer = ({
  azureRequestUrl,
  setAzureRequestUrl,
  azureBlobUrl,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [audioSrc, setAudioSrc] = useState(""); // Initially empty

  // Set audio source when azureRequestUrl changes
  useEffect(() => {
    if (azureBlobUrl) {
      setAzureRequestUrl(azureBlobUrl);
    }
  }, [azureBlobUrl]);

  const handleClose = () => {
    setIsVisible(false);
  };
  const handlePlay = () => {
    // Here you can use the azureRequestUrl for whatever you need
    console.log("Playing audio from URL:", azureRequestUrl);
    // You can also perform any other actions when the audio is played
  };

  if (!isVisible) return null;
  // console.log(azureBlobUrl,"azureRequestUrl")
  return (
    <Draggable>
      <div className="audio-player-container">
        {/* <div className="close-icon" onClick={handleClose}>
          <CloseIcon />
        </div> */}
        <AudioPlayer
          autoPlay={false}
          //src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-9.mp3"
          src={azureBlobUrl} // Use audioSrc state
          onPlay={handlePlay}
          // Other customization options
          key={azureBlobUrl}
        />
      </div>
    </Draggable>
  );
};

export default CustomAudioPlayer;
