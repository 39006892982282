// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBoxML {
  width: 247px;
  height: 40px;
  border-radius: 50px;
  background: var(--mainTheme200);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 4px 8px 0px rgba(255, 255, 255, 0.75), 4px 2px 8px 0px rgba(0, 0, 0, 0.2) inset;
}`, "",{"version":3,"sources":["webpack://./src/components/search/search.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,+FACE;AAAJ","sourcesContent":[".searchBoxML {\r\n  width: 247px;\r\n  height: 40px;\r\n  border-radius: 50px;\r\n  background: var(--mainTheme200);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  box-shadow:\r\n    4px 4px 8px 0px rgba(255, 255, 255, 0.75),\r\n    4px 2px 8px 0px rgba(0, 0, 0, 0.2) inset;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
