import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { SelectDropdown } from "../../select/select";
import AlertContext from "../../../contexts/AlertContext";
import "../NurseDashboardComponent/NurseDashboardComponent.scss";
import { useContext, useState } from "react";
import Button from "../../button/button";
import VphpDialog from "../../Dashboard/MemberList/vphp/vphp";
import CustomizedDialogs from "../../Dashboard/Dialog/Dialog";

const NurseDashboardComponent = () => {
  const memberListOptions = [
    "Cancelled / No Show",
    "Clinician Review",
    "Coding",
    "Completed",
    "In Progress",
    "Member List",
    "QA",
    "Ready for Upload",
    "Scheduled",
    "Term Expired",
    "Unassigned",
  ];
  const filterDropdown = ["First name", "Identification#", "Last name"];
  const alertContext = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const toggleOpen = () => {
    alertContext.setFilterDialog(true);
  };

  const vphpToggleOpen = () => {
    alertContext.setVphpFilterDialog(true);
  };

  const handleVphpSelection = (plan, date) => {
    setSelectedPlan(plan);
    setSelectedDate(date);
  };

  return (
    <div className="nurse-board">
      <CustomizedDialogs />
      <VphpDialog
        onSelection={handleVphpSelection}
        initialPlan={selectedPlan}
        initialDate={selectedDate}
      />
      <div className="nurse-left">
        <SelectDropdown
          sx={{
            borderRadius: 20,
            width: "154px",
            height: "40px",
            fontSize: "16px",
            fontWeight: 500,
            marginRight: 1,
            marginLeft: 2,
          }}
          //  title={memberListOptions[0]}
          title="Nurse List"
          options={memberListOptions}
          className="dropdownNurse"
          valueClass="dropdownValueReports"
        />
        <Box className="filterBoxML" onClick={vphpToggleOpen}>
          {selectedPlan && selectedDate ? (
            <p>{`${selectedPlan}-${selectedDate}`}</p>
          ) : (
            <p>VPHP-2019</p>
          )}
        </Box>
      </div>
      <div className="nurse-right">
        <Button
          height="34px"
          width="190px"
          mr="20px"
          mt="3px"
          fontSize="14px"
          cursor="pointer"
          fontWeight="500"
        >
          Download Member Data
        </Button>
        <Box className="filterBoxReports" onClick={toggleOpen}>
          <p>Filter</p>
          <FilterAltIcon sx={{ height: "26px", width: "26px" }} />
        </Box>
        <SelectDropdown
          sx={{
            borderRadius: 20,
            width: "154px",
            height: "40px",
            fontSize: "16px",
            fontWeight: 500,
            marginRight: 1,
            marginLeft: 2,
          }}
          title="Identification"
          options={filterDropdown}
          className="dropdownNurse"
          valueClass="dropdownValueReports"
        />
        <Box className="searchBoxNurse">
          <TextField
            placeholder="Search"
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </Box>
      </div>
    </div>
  );
};

export default NurseDashboardComponent;
