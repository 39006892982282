import React from 'react';
import { Tooltip, TableCell } from '@mui/material';
import { styled } from '@mui/system';

const EllipsisTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const TooltipEllipsisCell = ({ value, maxWidth }) => {
  return (
    <Tooltip title={value} arrow placement="top-start" >
      <EllipsisTableCell style={{ maxWidth, borderBottom: 'none' }}>{value}</EllipsisTableCell>
    </Tooltip>
  );
};

export default TooltipEllipsisCell;
