export const rowsRenderData = [
  {
    id: 1,
    label: "Allergies",
    name: "HRAForm_Allergies",
    columns: [
      {
        label: "Substance",
        type: "text",
      },
      {
        label: "Reaction",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 2,
    label: "BMI Index Chart",
    name: "HRAForm_BMI_Index_Chart",
    columns: [
      {
        label: "BMI",
        type: "text",
      },
    ],
    isReadOnly: true,
  },
  {
    id: 4,
    label: "Medical History Specialists",
    name: "HRAForm_Medical_History_Speciality",
    columns: [
      {
        label: "Member Specialist",
        type: "select",
      },
      {
        label: "Speciality",
        type: "text",
      },
      {
        label: "For",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 5,
    label: "Medications",
    name: "HRAForm_Medications",
    columns: [
      {
        label: "Diagnoses",
        type: "text",
      },
      {
        label: "label Name",
        type: "select",
      },
      {
        label: "Dose Per Units",
        type: "text",
      },
      {
        label: "Route",
        type: "select",
      },
      {
        label: "Frequency",
        type: "select",
      },
      {
        label: "Prescribing Physician",
        type: "text",
      },
      {
        label: "Status",
        type: "togglebutton",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 6,
    label: "Mini Cog Versions",
    name: "HRAForm_Minicog_Version",
    columns: [
      {
        label: "Version 1",
        type: "text",
      },
      {
        label: "Version 2",
        type: "text",
      },
      {
        label: "Version 3",
        type: "text",
      },
      {
        label: "Version 4",
        type: "text",
      },
      {
        label: "Version 5",
        type: "text",
      },
      {
        label: "Version 6",
        type: "text",
      },
    ],
    isReadOnly: true,
  },
  {
    id: 7,
    label: "Preventive Care",
    name: "HRAForm_Preventive_Care",
    columns: [
      {
        label: "Screen",
        type: "text",
      },
      {
        label: "Answer",
        type: "select",
      },
      {
        label: "Date",
        type: "text",
      },
      {
        label: "Method",
        type: "text",
      },
      {
        label: "Recommendation",
        type: "checkbox",
      },
      {
        label: "Education Completed",
        type: "checkbox",
      },
    ],
    isReadOnly: true,
  },
  {
    id: 7,
    label: "Alcohol use",
    name: "Alcohol_use",
    columns: [
      {
        label: "How Many Drinks",
        type: "select",
      },
      {
        label: "How Often",
        type: "select",
      },
    ],
    isReadOnly: true,
  },
  {
    id: 8,
    label: "Previously Documented Conditions",
    name: "HRAForm_Previously_Documented_Conditions",
    columns: [
      {
        label: "Diagnosis",
        type: "text",
      },
      {
        label: "Prior HCC",
        type: "text",
      },
      {
        label: "Diagnosis Code",
        type: "text",
      },
      {
        label: "Date of Service",
        type: "date",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 9,
    label: "Prior Assessments",
    name: "HRAForm_Prior_Assessments",
    columns: [
      {
        label: "Date Time",
        type: "date",
      },
      {
        label: "Document",
        type: "button",
      },
    ],
    isReadOnly: true,
  },

  {
    id: 10,
    label: "Activities Chart",
    name: "activities_chart",
    columns: [
      {
        label: "Score",
        type: "text",
      },
      {
        label: "Indication",
        type: "text",
      },
    ],
    isReadOnly: true,
  },
];
