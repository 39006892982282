import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  StepConnector,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

// Custom StepConnector to change color based on the step's status
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "&.MuiStepConnector-alternativeLabel": {
    top: 22,
  },
  "&.MuiStepConnector-active": {
    "& .MuiStepConnector-line": {
      borderColor: "#069d06", // Active step line color (green)
    },
  },
  "&.MuiStepConnector-completed": {
    "& .MuiStepConnector-line": {
      borderColor: "#003300", // Completed step darker color (dark green)
    },
  },
  "& .MuiStepConnector-line": {
    borderColor: "#ccc", // Default line color
    borderTopWidth: 3, // Line thickness
  },
}));

const steps = [
  { label: "Welcome", date: "20-Oct-2024", by: "Scheduler" },
  { label: "PCP Summary", date: "21-Oct-2024", by: "Coordinator" },
  { label: "Member Summary", date: "22-Oct-2024", by: "Scheduler" },
  { label: "PCP Screening Result", date: "23-Oct-2024", by: "Doctor" },
  { label: "Member Screening Result", date: "24-Oct-2024", by: "Nurse" },
];

const CommunicationTrackerDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xxl" fullWidth>
      <DialogTitle style={{ backgroundColor: "#1076bc", color: "#fff" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Communication History Tracker</div>

          <IconButton onClick={onClose} sx={{ color: "#fff" }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "12px",
            marginBottom: "12px",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#069d06",
              color: "white",
              padding: "20px",
            }}
          >
            Export to Excel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ padding: "20px" }}
          >
            View PDF
          </Button>
        </Box>

        {/* Progress Tracker */}
        <Stepper
          alternativeLabel
          activeStep={steps.length - 1}
          connector={<CustomConnector />} // Using the custom connector
          style={{
            backgroundColor: "#cce7f9",
            padding: "16px",
            borderRadius: "18px",
          }}
        >
          {/* <Stepper alternativeLabel activeStep={steps.length - 1} style={{ backgroundColor: "#cce7f9", padding:"16px", borderRadius:"18px" }} connector={<Box sx={{ width: '100%',}} />}> */}
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">{step.label}</Typography>
                  <Typography variant="body2">{step.date}</Typography>
                  <Typography variant="body2">{step.by}</Typography>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Summary Section */}
        {/* <Box sx={{ mt: 3 }}>
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid #ccc',
                padding: '16px',
                borderRadius: '8px',
                mb: 2
              }}
            >
              <Typography variant="h6">{step.label}</Typography>
              <Typography variant="body1">{step.date}</Typography>
              <Typography variant="body1">{step.by}</Typography>
            </Box>
          ))}
        </Box> */}
      </DialogContent>
    </Dialog>
  );
};

export default CommunicationTrackerDialog;
