import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "../../components/Dashboard/Header/Header";
import Sidebar from "../../components/Dashboard/Sidebar/Sidebar";
// import Footer from "../../components/Footer";
import { AlertProvider } from "../../contexts/AlertContext";
import { InputProvider } from "../../contexts/InputContext";
import "./AdminDashboard.css";

// import MemberListPage from "./MemberListPage/MemberListPage";
// import AddMembersPage from "./AddMembersPage/AddMembersPage";
// import DragNDrop from "./DragNDrop/DragNDrop";
// import DynamicForms from "./DynamicForms/DynamicForms";
import { useUserContext } from "../../contexts/UserContext";
// import Reports from "./Reports/Reports";
// import Scheduler from "./Scheduler/Scheduler";
// import ViewAdministrators from "./ViewAdministrators/ViewAdministrators";
// import ViewClients from "./ViewClients/ViewClients";
// import ViewClincians from "./ViewClinicians/ViewClinicians";
// import ViewReports from "./ViewReports/ViewReports";
// import FillHRAForm from "./MemberListPage/FillHraForm";
// import SchedulerDashBoard from "../SchedulerDashboard/index";

import { RedirectToLogin } from "../../routes/redirectPageRouting";
import MemberProfile from "../../components/AdminDashboard/memberProfile";
const AddMembersPage = React.lazy(() =>
  import("../AdminDashboard/AddMembersPage/AddMembersPage"),
);
const MemberListPage = React.lazy(() =>
  import("./MemberListPage/MemberListPage"),
);
const DragNDrop = React.lazy(() =>
  import("../AdminDashboard/DragNDrop/DragNDrop"),
);
const DynamicForms = React.lazy(() =>
  import("../AdminDashboard/DynamicForms/DynamicForms"),
);
const Reports = React.lazy(() => import("../AdminDashboard/Reports/Reports"));
const Scheduler = React.lazy(() =>
  import("../AdminDashboard/Scheduler/Scheduler"),
);
const ViewAdministrators = React.lazy(() =>
  import("../AdminDashboard/ViewAdministrators/ViewAdministrators"),
);
const ViewClients = React.lazy(() =>
  import("../AdminDashboard/ViewClients/ViewClients"),
);
const ViewClincians = React.lazy(() =>
  import("../AdminDashboard/ViewClinicians/ViewClinicians"),
);
const ViewReports = React.lazy(() =>
  import("../AdminDashboard/ViewReports/ViewReports"),
);
// const Coder = React.lazy(() => import("./CoderDashboard"));
// const CoderDashboardList = React.lazy(() =>
//   import("./CoderDashboard/coderlist"),
// );
// const ChangePassword = React.lazy(() =>
//   import("./Login/ChangePassword/ChangePassword"),
// );
// const ForgotPassword = React.lazy(() =>
//   import("./Login/ForgotPassword/ForgotPassword"),
// );
// const ResetPassword = React.lazy(() =>
//   import("./Login/ResetPassword/ResetPassword"),
// );
// const Nurse = React.lazy(() => import("./NurseDashboard"));
// const AddNewNurse = React.lazy(() =>
//   import("../NurseDashboard/AddNewNurse/AddNewNurse"),
// );
// const Calendar = React.lazy(() => import("./NurseDashboard/Calendar"));
// const NurseDashboard = React.lazy(() =>
//   import("../NurseDashboard/NurseDashboard"),
// );
// const OTPScreen = React.lazy(() => import("./OTP/OTPScreen"));
// const QA = React.lazy(() => import("./QaDashboard"));
// const QADashboardList = React.lazy(() => import("./QaDashboard/qalist"));
// const SchedulerDashboard = React.lazy(() =>
//   import("./SchedulerDashboard"),
// );
// const SchedulerListPage = React.lazy(() =>
//   import("./SchedulerDashboard/scheduler"),
// );
const FillHRAForm = React.lazy(() =>
  import("../AdminDashboard/MemberListPage/FillHraForm"),
);

const AdminDashboard = () => {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const navigate = useNavigate();
  const roleId = sessionStorage.getItem("roleId");
  const headerUserName = sessionStorage.getItem("headerUserName");
  const { hasLoggedIn } = useUserContext();

  useEffect(() => {
    if (
      window.location.pathname === `/AdminDashboard` ||
      window.location.pathname === `/AdminDashboard/`
    ) {
      navigate(`/AdminDashboard/MemberList`);
    }
  }, []);
  const pathName = window.location.pathname;
  return (
    <AlertProvider>
      <InputProvider>
        <div className="body">
          {!pathName.includes("/FillHRAForm") ? (
            <div className={!sidebarCollapse ? "sidebar" : "sidebar inactive"}>
              <Sidebar roleId={roleId} />
            </div>
          ) : null}
          <div
            style={
              pathName.includes("/FillHRAForm")
                ? { width: "100%", height: "100vh", marginInline: "0px" }
                : undefined
            }
            className={
              !sidebarCollapse ? "body-content" : "body-content active"
            }
          >
            <Header headerUserName={headerUserName} />
            <Routes>
              {/* to-do : need to refactor the routes with routingIndex file */}
              <Route
                path={`/MemberList`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MemberListPage />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/AddNewMember`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <AddMembersPage />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/ViewClinicians`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <ViewClincians />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/ViewClients`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <ViewClients />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/ViewAdministrators`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <ViewAdministrators />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/Scheduler`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Scheduler />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/Reports`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Reports />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/ImportFiles`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <ViewReports />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/CreateHRAForm`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <DragNDrop />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/PreviewHRAForm`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <DynamicForms />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/FillHRAForm`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <FillHRAForm />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
              <Route
                path={`/memberProfile`}
                element={
                  <RedirectToLogin moveToLogin={hasLoggedIn}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MemberProfile />
                    </Suspense>
                  </RedirectToLogin>
                }
              />
            </Routes>
          </div>
        </div>
      </InputProvider>
    </AlertProvider>
  );
};

export default AdminDashboard;
