import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, InputAdornment, TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "./search.scss";

export default function Index({
  onSearch,
  onReset,
  isSearchEnabled,
  setSearchValue,
  searchValue,
}) {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCancelClick = () => {
    setSearchValue("");
    onReset();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && inputValue.trim() !== "") {
        e.preventDefault();
        if (onSearch) {
          onSearch(inputValue);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputValue, onSearch]);

  return (
    <Box className="searchBoxML">
      <TextField
        placeholder="Search.."
        ref={inputRef}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: "7px" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {inputValue && (
                <CancelOutlinedIcon
                  style={{ marginRight: "5px", cursor: "pointer" }}
                  onClick={handleCancelClick}
                />
              )}
            </InputAdornment>
          ),
        }}
        variant="standard"
        onChange={handleChange}
        inputRef={inputRef}
      />
    </Box>
  );
}
