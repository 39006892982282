import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Dashboard/Sidebar/Sidebar";
import Header from "../../components/Dashboard/Header/Header";
import { AlertProvider } from "../../contexts/AlertContext";
import { Routes, Route, useNavigate } from "react-router-dom";
import SchedulerListPage from "./scheduler";
import Scheduler from "../AdminDashboard/Scheduler/Scheduler";
import Footer from "../../components/Footer";

export default function Index() {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const navigate = useNavigate();
  const roleName = sessionStorage.getItem("roleName");
  const headerUserName = sessionStorage.getItem("headerUserName");
  useEffect(() => {
    if (
      window.location.pathname === "/SchedulerDashboard" ||
      window.location.pathname === "/SchedulerDashboard/"
    ) {
      navigate(`/SchedulerDashboard/Scheduler`);
    }
  }, []);

  return (
    <AlertProvider>
      <div className="body fullWidth-body">
        {/* scheduler Dashboard doesn't contains any menu so removing the sidebar  */}
        {/* <div className={!sidebarCollapse ? "sidebar" : "sidebar inactive"}>
          <Sidebar roleName={roleName} />
        </div> */}
        <div
          className={!sidebarCollapse ? "body-content" : "body-content active"}
        >
          <Header
            sidebarToggle={setSidebarCollapse}
            headerUserName={headerUserName}
          />
          <Routes>
            <Route path={`/Scheduler`} element={<Scheduler />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </AlertProvider>
  );
}
