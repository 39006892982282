import { useEffect, useState } from "react";
import { getQaLeadDashboardData } from "../../../api/graphsApi";

export const useQaData = (param) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!param?.planId || !param?.year) return;
    const fetchQaData = async () => {
      try {
        setLoading(true);
        const response = await getQaLeadDashboardData(param);
        const DashData = response?.data?.data;
        if (typeof DashData === "object") {
          const progData = {
            unaasigned: DashData["Unassigned Members"],
          };
          const headerCardsData = {
            fromClin: DashData["Total Charts Came from Clinician Review"],
            assigned: DashData["Total Charts Assigned"],
            completed: DashData["Total Charts Complete"],
            toClin: DashData["Total Charts Out for Clinician Review"],
          };
          const avgDaysData = [
            {
              name: "Ready for QA",
              count:
                Math.ceil(
                  DashData["Averages for Number of Days In ReadyForQa"]
                    ?.averageDifference,
                ) ?? 0,
              color: "#36A2EB",
            },
            {
              name: "Clinician Review",
              count:
                Math.ceil(
                  DashData["Averages for Number of Days In Clinician Review"]
                    ?.averageDifference,
                ) ?? 0,
              color: "#FF9F40",
            },
          ];

          const assignedNotStarted = [
            {
              name: "Started",
              count:
                DashData["Total Charts Assigned"] -
                DashData["Total Charts Not Started"],
              color: "#4BC0C0",
            },
            {
              name: "Not Started",
              count: DashData["Total Charts Not Started"],
              color: "#FF9F40",
            },
          ];

          const topQaByAssignedMem = DashData["Top QA By QAAssigned"].map(
            (qa) => ({
              name: `${qa.firstName}`,
              //name: `${qa.firstName} ${qa.lastName}`,
              count: qa.totalmember,
            }),
          );
          const topQaByReviewCount = DashData["Top QA By QAReviewCount"].map(
            (qa) => ({
              name: `${qa.firstName}`,
              //name: `${qa.firstName} ${qa.lastName}`,
              count: qa.totalQaReviewCount,
            }),
          );
          setData((prev) => ({
            ...prev,
            progData,
            headerCardsData,
            avgDaysData,
            assignedNotStarted,
            topQaByAssignedMem,
            topQaByReviewCount,
          }));
        }
      } catch (error) {
        console.error("Error fetching QA data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchQaData();
  }, [param]);
  return { data, loading };
};
