import React, { useState, useEffect } from "react";
import { BlobServiceClient } from "@azure/storage-blob";

const Audio = ({ setTabIdFromIconClick }) => {
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  // Azure Storage setup
  const sasToken =
    "sp=racw&st=2024-09-24T05:56:46Z&se=2024-09-24T13:56:46Z&spr=https&sv=2022-11-02&sr=c&sig=G3AhKz1SMZii%2FNvXVreTCdxkGBuSDh53TXJiKRNZS8k%3D"; // Replace with your SAS token
  const containerName = "audiofiles";
  const blobServiceClient = new BlobServiceClient(
    `https://focuscaresrtttesting.blob.core.windows.net?${sasToken}`,
  );

  // Function to start recording
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      const audioChunks = [];
      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        setAudioBlob(audioBlob);
        console.log("Recording stopped and blob created:", audioBlob);
      };

      recorder.start();
      setRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  // Function to stop recording
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  // Function to upload audio blob to Azure
  const uploadAudioToAzure = async () => {
    if (audioBlob) {
      try {
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const blobName = `audio_${new Date().toISOString()}.wav`;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        await blockBlobClient.uploadData(audioBlob, {
          blobHTTPHeaders: { blobContentType: "audio/wav" },
        });

        console.log("Audio uploaded successfully:", blobName);
        alert("Audio uploaded successfully to Azure");
      } catch (error) {
        console.error("Error uploading audio:", error);
        alert("Error uploading audio");
      }
    }
  };

  return (
    <div>
      <h2>Audio Recorder</h2>

      {!recording ? (
        <button onClick={startRecording}>Start Recording</button>
      ) : (
        <button onClick={stopRecording}>Stop Recording</button>
      )}

      {audioBlob && (
        <button onClick={uploadAudioToAzure}>Upload Audio to Azure</button>
      )}
    </div>
  );
};

export default Audio;
