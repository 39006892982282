import React from "react";
import AddMembers from "../../../components/AdminDashboard/AddMembers/AddMembers";

export default function AddMembersPage() {
  return (
    <div className="dashboard">
      <div className="content-dashboard">
        <div className="right-content">
          <AddMembers />
        </div>
      </div>
    </div>
  );
}
