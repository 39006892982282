import Toaster from "../toaster/toaster";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const NurseComponent = (props) => {
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState("success");
  const [toasterMessage, setToasterMessage] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loginSuccessful = queryParams.get("loginSuccessful") === "true";

  useEffect(() => {
    if (loginSuccessful) {
      setToasterOpen(true);
      setToasterSeverity("success");
      setToasterMessage("Login successful!");
    }
  }, [loginSuccessful]);
  return (
    <div className="dashboard-component">
      {props.children}
      {loginSuccessful && (
        <Toaster
          open={toasterOpen}
          onClose={() => setToasterOpen(false)}
          severity={toasterSeverity}
          message={toasterMessage}
        />
      )}
    </div>
  );
};

export default NurseComponent;
