import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Button,
} from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useContext,
} from "react";

import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import CloseIcon from "@mui/icons-material/Close";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  DynamicTablePreviously,
  DynamicTableSuspect,
} from "../../DFInputs/DFDataTable";
import { DragNDropContext } from "../../../../../../contexts/DragNDropContext";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./previouslyFormData";
import diagnosisData1 from "../../DFInputs/diagnosisData.json";
import diagnosisData2 from "../../DFInputs/diagnosisData2.json";

import "../responsiveFillHra.css";

const Previously = forwardRef((props, _ref) => {
  const { handleCloses, handleButtonAction, opens, suspectOpens } =
    useContext(DragNDropContext);
  const {
    // PreviouslyFormData,
    // setPreviouslyFormData,
    // showComment,
    // setShowComment,
    // getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const { storedPlan, storedYear } = useUserContext();

  const [PreviouslyFormData, setPreviouslyFormData] = useState({});

  const [previouslyRowData, setPreviouslyRowData] = useState([]);
  const [suspectRowData, setSuspectRowData] = useState([]);
  //   const [PreviouslyFormData, setPreviouslyFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [formData, setFormData] = useState({});
  const { getAssessmentData } = useUserContext();
  const [memberId, setMemberId] = useState("");

  const sectionId = props.currSection || 2;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);
  // const diagnosisData = clientYear === "2023" ? diagnosisData1 : diagnosisData2;
  const diagnosisData = [...diagnosisData1, ...diagnosisData2];
  useEffect(() => {
    async function fetchData() {
      try {
        const dbData = await props.getSectionDbData(sectionId);
        const tableData1 = dbData?.viewSubAnswers || [];
        setPreviouslyRowData(tableData1);
        setPreviouslyFormData(dbData);
        const dbDataDemo = await props.getSectionDbData(1);
        const suspectCodesString = dbDataDemo?.suspectCodes || "";
        const suspectCodesArray = suspectCodesString.split(",").filter(Boolean);

        const transformedSuspectCodes = suspectCodesArray.map((code) => {
          const diagnosis =
            diagnosisData.find((d) => d.code === code.trim())?.description ||
            "";

          return {
            diagnosisCode: code.trim(),
            diagnosis: diagnosis,
          };
        });

        setSuspectRowData(transformedSuspectCodes);
        setDisableFlag(getDisableFlag());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const assessmentDataFromAPI = await getAssessmentData();

        if (assessmentDataFromAPI?.data) {
          const assessmentDBData = assessmentDataFromAPI.data;

          if (assessmentDBData) {
            const { data } = assessmentDBData;
            setMemberId(data._id);
          }
        }
      } catch (error) {
        return;
      }
    };

    fetchMemberData();
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...PreviouslyFormData };
    updatedFormData[name] = value;

    setPreviouslyFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...PreviouslyFormData };
    updatedFormData[name] = value;

    setPreviouslyFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...PreviouslyFormData };
    updatedFormData[name] = value;

    setPreviouslyFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...PreviouslyFormData };
    updatedFormData[name] = value;

    setPreviouslyFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    const updatedFormData = { ...PreviouslyFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (isMulti) {
      newSelection = value;
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection.push(value);
      }
    }

    updatedFormData[name] = newSelection;
    setPreviouslyFormData(updatedFormData);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...PreviouslyFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setPreviouslyFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      const formData = PreviouslyFormData;
      const tableData1 = previouslyRowData;
      const tableData2 = suspectRowData;

      let combinedData = {
        ...formData,
        viewSubAnswers: tableData1,
        suspectCodes: tableData2,
      };
      return combinedData;
    },
  }));

  useEffect(() => {
    const formData = PreviouslyFormData;
    const tableData1 = previouslyRowData;
    const tableData2 = suspectRowData;

    let combinedData = {
      ...formData,
      viewSubAnswers: tableData1,
      suspectCodes: tableData2,
    };

    props.setAutoSavePrevDocData({ ...combinedData });
  }, [PreviouslyFormData, previouslyRowData, suspectRowData]);

  return (
    <div
      style={{
        margin: "10px 20px",
        width: "100%",
      }}
    >
      <Grid container spacing={2} className="grid-main">
        {inputsJsonData?.questions?.map((inputProps) => {
          const { questionId, name, xs, type } = inputProps;
          const inputValue = PreviouslyFormData[name] || "";
          const commentValue = `${
            PreviouslyFormData?.[`${name}Comment`]
              ? `${PreviouslyFormData[`${name}Comment`]}`
              : ""
          }`;
          const quesSlug = `${sectionId}_${questionId}`;
          if (
            type === "text" ||
            type === "number" ||
            type === "email" ||
            type === "password"
          ) {
            return (
              <Grid
                key={quesSlug}
                xs={xs}
                /* sm={inputProps.sm}*/
                item
              >
                <DFText
                  inputProps={inputProps}
                  value={inputValue}
                  handleTextChange={handleTextChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "radio") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFRadio
                  inputProps={inputProps}
                  value={inputValue}
                  handleRadioChange={handleRadioChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "date") {
            const dateValue = PreviouslyFormData[name] || {};
            return (
              <Grid
                key={quesSlug}
                xs={xs}
                /* sm={sm} */
                item
              >
                <DFDate
                  inputProps={inputProps}
                  value={dateValue}
                  handleDateChange={handleDateChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                  // dtPickProps={{
                  //   disableFuture:true,
                  //   disablePast:true,
                  //   minDate:minDOB,
                  //   maxDate:maxDOB,
                  //   views:["year", "month", "day"]
                  // }}
                />
              </Grid>
            );
          } else if (type === "select") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFSelect
                  inputProps={inputProps}
                  value={inputValue}
                  handleSelectChange={handleSelectChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "options_tree") {
            return (
              <DFOptionsTree
                inputProps={inputProps}
                formData={PreviouslyFormData}
                handleOptionsTreeChange={handleOptionsTreeChange}
                handleTextChange={handleTextChange}
                handleRadioChange={handleRadioChange}
                handleDateChange={handleDateChange}
                handleSelectChange={handleSelectChange}
                handleButtonClick={handleButtonClick}
                handleCheckboxChange={handleCheckboxChange}
                showComment={showComment}
                setShowComment={setShowComment}
                setFormData={setPreviouslyFormData}
                quesSlug={`${questionId}`}
                sectionId={sectionId}
                // commentValue={commentValue}
                disableFlag={disableFlag}
                previouslyRowData={previouslyRowData}
                suspectRowData={suspectRowData}
                setPreviouslyRowData={setPreviouslyRowData}
              />
            );
          } else if (type === "label") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFLabel
                  inputProps={inputProps}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                />
              </Grid>
            );
          } else if (inputProps.type === "button") {
            return (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
                xs={12}
              >
                {clientYear === "2023" && (
                  <Button
                    onClick={() => {
                      handleButtonAction("previously_documented_conditions");
                    }}
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--mainTheme)",
                      color: "white",
                      "&:disabled": {
                        backgroundColor: "var(--mainTheme600)",
                        color: "black",
                      },
                      justifyContent: "center",
                      // width: "30%",
                      // marginLeft: "40px",
                      marginRight: "40px",
                    }}
                  >
                    {inputProps.label}
                  </Button>
                )}
                {clientYear === "2024" && (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                      <Button
                        onClick={() => {
                          handleButtonAction(
                            "previously_documented_conditions",
                          );
                        }}
                        variant="contained"
                        sx={{
                          backgroundColor: "var(--mainTheme)",
                          color: "white",
                          "&:disabled": {
                            backgroundColor: "var(--mainTheme600)",
                            color: "black",
                          },
                          justifyContent: "center",
                          width: "35%",
                          fontSize: { xs: "1.2vw", md: "1vw" },
                        }}
                      >
                        {inputProps.label}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                      <Button
                        onClick={() => {
                          handleButtonAction("suspect_codes");
                        }}
                        variant="contained"
                        sx={{
                          backgroundColor: "var(--mainTheme)",
                          color: "white",
                          "&:disabled": {
                            backgroundColor: "var(--mainTheme600)",
                            color: "black",
                          },
                          justifyContent: "center",
                          width: "35%",
                          fontSize: { xs: "1.2vw", md: "1vw" },
                        }}
                      >
                        {"Suspect Codes"}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            );
          } else if (type === "heading") {
            return (
              <Grid key={quesSlug} xs={12} item>
                <DFHeading inputProps={inputProps} />
              </Grid>
            );
          } else if (type === "checkbox") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFCheckbox
                  inputProps={inputProps}
                  showComment={showComment}
                  handleCheckboxChange={handleCheckboxChange}
                  value={inputValue}
                  handleTextChange={handleTextChange}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "image") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFImage inputProps={inputProps} />
              </Grid>
            );
          } else if (type === "divider") {
            return <DFRuler />;
          } else if (type === "datatable") {
            const selectedTable = inputProps.tablelist.filter(
              (table) => table && table.length > 0 && table[1] === true,
            );
            return (
              <Grid
                key={quesSlug}
                xs={inputProps.xs}
                sm={12}
                md={8}
                lg={6}
                item
              >
                <div style={{ textAlign: "center" }}>
                  {selectedTable &&
                    selectedTable.length > 0 &&
                    selectedTable.map((table, index) => {
                      if (
                        table[0].label === "Previously Documented Conditions"
                      ) {
                        return (
                          <Dialog
                            open={opens}
                            onClose={handleCloses}
                            fullWidth
                            maxWidth="md"
                          >
                            <DialogTitle>
                              <div
                                // style={{
                                //   display: "flex",
                                //   alignItems: "center",
                                //   justifyContent: "space-between",
                                //   color: "var(--mainTheme)",
                                //   fontWeight: 600,
                                // }}
                                className="dialog-header"
                              >
                                {"Previously Documented Conditions"}
                                {/* <Button onClick={handleCloses}> */}
                                <CloseIcon
                                  sx={{
                                    color: "var(--mainTheme) !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleCloses}
                                />
                              </div>
                            </DialogTitle>

                            <DialogContent>
                              <div>
                                <DynamicTablePreviously
                                  previouslyRowData={previouslyRowData}
                                  setPreviouslyRowData={setPreviouslyRowData}
                                  memberId={memberId}
                                  disableFlag={disableFlag}
                                />
                              </div>
                            </DialogContent>
                          </Dialog>
                        );
                      }
                      if (table[0].label === "suspect_codes") {
                        return (
                          <Dialog
                            open={suspectOpens}
                            onClose={handleCloses}
                            fullWidth
                            maxWidth="md"
                          >
                            <DialogTitle>
                              <div className="dialog-header">
                                {"Suspect Codes"}
                                {/* <Button onClick={handleCloses}> */}
                                <CloseIcon
                                  sx={{
                                    color: "var(--mainTheme) !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleCloses}
                                />
                              </div>
                            </DialogTitle>

                            <DialogContent>
                              <div>
                                <DynamicTableSuspect
                                  suspectRowData={suspectRowData}
                                  setSuspectRowData={setSuspectRowData}
                                  memberId={memberId}
                                  disableFlag={disableFlag}
                                />
                              </div>
                            </DialogContent>
                          </Dialog>
                        );
                      }
                    })}
                </div>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </div>
  );
});

export default Previously;
