import {
  Button,
  Card,
  CardHeader,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
// import Button from "../../button/button";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect, useContext, useRef } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../components/Dashboard/DynamicFormMUI/DynamicForms.css";
import ConfirmDigitalSignatureDialog from "./Confirm.digital.signature";
import {
  CustomCheckboxField,
  CustomSelectField,
  GlobalTextField,
  HeaderCard,
  CustomLabelField,
  CustomHeadingField,
  CustomImageField,
  CustomRadioGroupField,
  CardSections,
  CustomToggleButtonGroup,
} from "../../../components/Dashboard/DynamicFormMUI/customFields";

import {
  CustomSubToggleButtonGroup,
  CustomSubSelect,
  CustomSubTable,
  SubNumberField,
  CustomSubText,
  CustomSubTextField,
  DatePickerComponent,
  SubSubToggleButtonGroup,
  DatePickerCompo,
} from "../../../components/Dashboard/DynamicFormMUI/customOptions";

import { FormControl, InputLabel, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { rowsRenderData } from "../../../components/Dashboard/DynamicFormMUI/rowdata";
import "../../../components/Dashboard/DynamicFormMUI/Tables.css";
import { DragNDropContext } from "../../../contexts/DragNDropContext";
import {
  TableComponentStatic,
  TableComponent,
  TableComponentMini,
} from "../../../components/Dashboard/DynamicFormMUI/staticForm";
import { staticData } from "../../../components/Dashboard/DynamicFormMUI/indexData";
import { getFormData } from "../DynamicFormMUI/fillHraFormData";
import {
  DynamicTable,
  DynamicTableFamilyHistory,
  DynamicTablePreviously,
  DynamicTableSpecialists,
  DynamicTablePreventiveCare,
  DynamicTableAllergies,
  DynamicMedicationsTable,
  DynamicTableAlcoholUsage,
  DynamicTableCounterMedications,
  DynamicWordRecall,
  DynamicClockDraw,
  DynamicTotalScore,
} from "../../../components/Dashboard/DynamicFormMUI/Tables";
import { useUserContext } from "../../../contexts/UserContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import QueryForm from "./QueryForm";
import { putApi } from "../../../utils/axiosApiWrapper";
import Labels from "../../../resource_files/Labels.resx";
import Toaster from "../../toaster/toaster";

import RemoveIcon from "@mui/icons-material/Remove";
import MuiInput from "../../muiinput/MuiInput";
import {
  getCurrQuesComment,
  getCurrQuesDescribe,
  getCurrQuesSelected,
  getCurrQuesSelectionAndComment,
  getCurrQuesSubAnswers,
  getObjectData,
} from "../../../utils/helpers.DynamicForm";
import Alert from "../../Alert/Alert";
import _lodash from "lodash";
import {
  getExamReviewData,
  setExamReviewData,
} from "./fillHRA_dataMappers/examReviewMapping";
import {
  getHomeSafetyData,
  setHomeSafetyData,
} from "./fillHRA_dataMappers/homeSafetyMapping";
import {
  getSelfAssessData,
  setSelfAssessData,
} from "./fillHRA_dataMappers/selfAssessMapping";
import {
  getRosData,
  setRosData,
} from "./fillHRA_dataMappers/reviewOfSystemMapping";
//import { getRosData } from "./fillHRA_dataMappers/reviewOfSystemMapping";

let inputid = 0;
const selectedTable1 = "table1";
const selectedTable2 = "table2";
const selectedTable3 = "table3";
const selectedTable4 = "table4";
const selectedTable5 = "table5";

function getReroute() {
  let path;
  const roleId = sessionStorage.getItem("roleId");
  if (roleId === "5") {
    path = "/NurseDashboard/Nurselist";
  } else {
    path = "/AdminDashboard/MemberList";
  }
  return path;
}

function FillHra() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [_providerSignatureHra, setProviderSignatureHra] = useState("");
  useEffect(() => {
    const __providerSignature = sessionStorage.getItem("providerSignatureHra");
    setProviderSignatureHra(__providerSignature);
  });
  const rowsData = [
    {
      id: 1,
      label: "Allergies",
      name: "HRAForm_Allergies",
      columns: [
        {
          label: "Substance",
          type: "text",
        },
        {
          label: "Reaction",
          type: "text",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 7,
      label: "Previously Documented Conditions",
      name: "HRAForm_Previously_Documented_Conditions",
      columns: [
        {
          label: "Diagnosis",
          type: "text",
        },
        {
          label: "Prior HCC",
          type: "text",
        },
        {
          label: "Diagnosis Code",
          type: "text",
        },
        {
          label: "Date of Service",
          type: "date",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 4,
      label: "Medical History Specialists",
      name: "HRAForm_Medical_History_Speciality",
      columns: [
        {
          label: "Member Specialist",
          type: "select",
        },
        {
          label: "Speciality",
          type: "text",
        },
        {
          label: "For",
          type: "text",
        },
      ],
      isReadOnly: false,
    },

    {
      id: 4,
      label: "Alcohol Use - Current",
      name: "currentSubAnswers",
      columns: [
        {
          label: "How Many Drinks",
          name: "howManyDrinks",
          type: "text",
        },
        {
          label: "How Often",
          name: "howOften",
          type: "text",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 6,
      label: "Preventive Care",
      name: "HRAForm_Preventive_Care",
      columns: [
        {
          label: "Screen",
          type: "text",
        },
        {
          label: "Answer",
          type: "select",
        },
        {
          label: "Date",
          type: "text",
        },
        {
          label: "Method",
          type: "text",
        },
        {
          label: "Recommendation",
          type: "checkbox",
        },
        {
          label: "Education Completed",
          type: "checkbox",
        },
      ],
      isReadOnly: true,
    },
    {
      id: 5,
      label: "Medications",
      name: "HRAForm_Medications",
      columns: [
        {
          label: "Diagnoses",
          type: "text",
        },
        {
          label: "label Name",
          type: "select",
        },
        {
          label: "Dose Per Units",
          type: "text",
        },
        {
          label: "Route",
          type: "select",
        },
        {
          label: "Frequency",
          type: "select",
        },
        {
          label: "Prescribing Physician",
          type: "text",
        },
        {
          label: "Status",
          type: "togglebutton",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 3,
      label: "Counter Medications",
      name: "HRAForm_Counter_Medications",
      columns: [
        {
          label: "Date",
          type: "date",
        },
        {
          label: "Description",
          type: "text",
        },
        {
          label: "Dose Per Units",
          type: "text",
        },
        {
          label: "Route",
          type: "select",
        },
        {
          label: "Frequency",
          type: "text",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 3,
      label: "Word Recall",
      name: "step3ThreeWordRecall",
      columns: [
        {
          label: "Word Recall",
          type: "text",
        },
        {
          label: "Points",
          type: "select",
        },
        {
          label: "Description",
          type: "text",
        },
      ],
    },

    {
      id: 3,
      label: "Clock Draw",
      name: "clockDrawPoints",
      columns: [
        {
          label: "Clock Draw",
          type: "text",
        },
        {
          label: "Points",
          type: "select",
        },
        {
          label: "Description",
          type: "text",
        },
      ],
    },
    {
      id: 3,
      label: "Total Score",
      name: "totalScorePoints",
      columns: [
        {
          label: "Total Score",
          type: "text",
        },
        {
          label: "Points",
          type: "text",
        },
        {
          label: "Description",
          type: "text",
        },
      ],
    },
    {
      id: 12,
      label: "Family History",
      name: "family_history",
      columns: [
        {
          label: "Family Member",
          type: "text",
        },
        {
          label: "Medical Condition",
          type: "text",
        },
        {
          label: "Cause of Death",
          type: "text",
        },
      ],
      isReadOnly: false,
    },
  ];

  const {
    handleTableDataChange,
    //handleChangeRadio,
    //handleChangeCheckbox,
    handleInputChange,
    handleButtonAction,
    selectedOption,
    setSelectedOption,
    setToggleIndex,
    selectIndex,
    setSelectIndex,
    SetSubSelectIndex,
    SetSubSubSelectIndex,
    //handleDateChange,
    handleCloses,
    handleSubQuestionDateChange,
    // handleSubQuestionChange,
    // handleSubSubQuestionChange,
    opens,
    commentIndex,
    setCommentIndex,
    setClientData,
    openSignSubmitDialog,
    setSignSubmitDialog,
  } = useContext(DragNDropContext);
  const [loading, setLoading] = useState(true);
  const [dForm, setDForm] = useState([]);
  const [sections, setSections] = useState([]);
  const [currSection, setCurrSection] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [isShowSection, setIsShowSection] = useState({});
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const sectionRefs = sections.map(() => React.createRef());
  const [tableRowData, setTableRowData] = useState(rowsData);
  const [tableData, setTableData] = useState([{}]);
  const [familyRowData, setFamilyRowData] = useState(null);
  const [allergies, setAllergies] = useState(null);
  const [allergiesRowData, setAllergiesRowData] = useState(null);
  const [medicalRowData, setMedicalRowData] = useState(null);
  const [preventive, setPreventive] = useState(null);
  const [counterMedications, setCounterMedications] = useState(null);
  const [counterMedicationsRowData, setCounterMedicationsRowData] =
    useState(null);
  const defaultScreenings = [
    "Breast Cancer Screening",
    "Colorectal Screening",
    "Influenza Vaccine",
    "COVID-19 Vaccine",
    "Pneumococcal Vaccine",
    "Herpes Zoster Vaccine",
    "Diabetes Screening",
    "Diabetic Foot Exam",
    "Cholesterol Screening",
    "Glaucoma Screening",
    "STIs/HIV Screening",
    "Cervical Cancer Screening",
    "Osteoporosis Screening",
    "Prostate Screening",
    "Fall Risk Screening",
  ];

  const initialPreventiveRowData = Array.from({ length: 15 }, (_, index) => ({
    screen: defaultScreenings[index] || "",
    answer: "",
    date: "",
    method: "",
    recommendation: 0,
    educationCompleted: 0,
  }));
  const [preventiveRowData, setPreventiveRowData] = useState(
    initialPreventiveRowData,
  );
  const [wordRecallRowData, setWordRecallRowData] = useState(rowsData);
  const [clockDrawRowData, setClockDrawRowData] = useState(rowsData);
  const [totalScoreRowData, setTotalScoreRowData] = useState(rowsData);
  const [medicationsRowData, setMedicationsRowData] = useState(null);
  const [medications, setMedications] = useState(null);
  const [family, setFamily] = useState(null);
  const [mappedPreventiveRows, setMappedPreventiveRows] = useState([]);
  const [mappedMiniCogRows, setMappedMiniCogRows] = useState([]);
  const { user } = useUserContext();

  const { roleId } = user;
  const [getData, setGetData] = useState("");
  const [famData, setFamData] = useState("");
  const [medical, setMedical] = useState(null);
  const { getAssessmentData, _memberIdForQueryForm } = useUserContext();
  const [demographicsData, setDemographicsData] = useState(null);
  const [assessmentData, setAssessmentData] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [formData, setFormData] = useState({});
  const [previouslyDocumentedConditions, setPreviouslyDocumentedConditions] =
    useState(null);

  const [comment, setComment] = useState("");
  const [subText, setSubText] = useState("");
  const [statusOptions, setStatusOptions] = useState("");

  const [dobDate, setDobDate] = useState(null);
  const [aloneSubAnswerDescibe, setAloneSubAnswerDescibe] = useState("");
  const [homelessSubAnswerDescibe, setHomelessSubAnswerDescibe] = useState("");
  const [otherSubAnswerDescibe, setOtherSubAnswerDescibe] = useState("");
  const [careGiverSubAnswerDescibe, setCareGiverSubAsnwerDescibe] =
    useState("");
  const [recreationaDrugsSubAnswer, setRecreationaDrugsSubAnswer] =
    useState("");
  const [isVisitTypeAlertOpen, setIsVisitTypeAlertOpen] = useState(false);
  const [isEvaluationDateAlertOpen, setIsEvaluationDateAlertOpen] =
    useState(false);
  const [selectedVisitType, setSelectedVisitType] = useState(null);
  const [emptyDateErrorPopup, setEmptyDateErrorPopup] = useState(false);
  const [emptyDateErrorMessg, setEmptyDateErrorMessg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const hraStatus = location.state
    ? location.state.hraStatus
    : sessionStorage.getItem("hraStatus");
  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const updateCommentInFormData = (fieldName, commentValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName + "Comment"]: commentValue,
    }));
  };
  const handleSubQuestionTextChange = (event, subQuestion) => {
    const newValue = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [subQuestion.name]: newValue,
    }));
  };

  const updateSubtextInFormData = (fieldName, subtextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: subtextValue,
    }));
  };

  const getCommentBasedOnInputProps = (inputPropsName) => {
    if (inputPropsName === "lostWeight") {
      return formData.lostWeightComment || "";
    } else if (inputPropsName === "bmi") {
      return formData.bmiComment || "";
    } else if (inputPropsName === "specialDiet") {
      return formData.specialDietComment || "";
    } else if (inputPropsName === "vitalSigns") {
      return formData.vitalSignsComment || "";
    } else if (inputPropsName === "microalbumin") {
      return formData.microalbuminComment || "";
    } else if (inputPropsName === "fobt") {
      return formData.fobtComment || "";
    } else if (inputPropsName === "a1c") {
      return formData.a1cComment || "";
    } else if (inputPropsName === "dexa") {
      return formData.dexaComment || "";
    } else if (inputPropsName === "ldl") {
      return formData.ldlComment || "";
    } else if (inputPropsName === "retinaleyeexam") {
      return formData.retinaleyeexamComment || "";
    } else if (inputPropsName === "pad") {
      return formData.padComment || "";
    } else if (inputPropsName === "patientExperiencePain") {
      return formData.patientExperiencePainComment || "";
    } else if (inputPropsName === "familyHistory") {
      return formData.familyHistoryComment || "";
    } else if (inputPropsName === "areYouCurrentlySeeingAnySpecia") {
      return formData.areYouCurrentlySeeingAnySpeciaComment || "";
    } else if (inputPropsName === "hospitalizedPrior") {
      return formData.hospitalizedPriorComment || "";
    } else if (inputPropsName === "oneTimeScreenForAbdominalAorti") {
      return formData.oneTimeScreenForAbdominalAortiComment;
    } else if (inputPropsName === "oneTimeScreenForHepatitisCIfBo") {
      return formData.oneTimeScreenForHepatitisCIfBoComment;
    } else if (inputPropsName === "aSeenYourPcp") {
      return formData.aSeenYourPcpComment;
    } else if (inputPropsName === "bVisitedTheEmergencyRoom") {
      return formData.bVisitedTheEmergencyRoomComment;
    } else if (inputPropsName === "cStayedInTheHospitalOvernight") {
      return formData.cStayedInTheHospitalOvernightComment;
    } else if (inputPropsName === "dBeenInANursingHome") {
      return formData.dBeenInANursingHomeComment;
    } else if (inputPropsName === "eHadSurgery") {
      return formData.eHadSurgeryComment;
    } else if (inputPropsName === "dyuaadoDME") {
      return formData.dyuaadoDMEComment;
    } else if (inputPropsName === "physicaltherapist") {
      return formData.physicaltherapistComment;
    } else if (inputPropsName === "occupationaltherapist") {
      return formData.occupationaltherapistComment;
    } else if (inputPropsName === "mealsonwheels") {
      return formData.mealsonwheelsComment;
    } else if (inputPropsName === "adultdaycare") {
      return formData.adultdaycareComment;
    } else if (inputPropsName === "socialworker") {
      return formData.socialworkerComment;
    } else if (inputPropsName === "mealsonwheels") {
      return formData.occupationaltherapistComment;
    } else if (inputPropsName === "dietician") {
      return formData.dieticianComment;
    } else if (inputPropsName === "pharmacist") {
      return formData.pharmacistComment;
    } else if (inputPropsName === "speechtherapist") {
      return formData.speechtherapistComment;
    } else if (inputPropsName === "chiropractor") {
      return formData.chiropractorComment;
    } else if (inputPropsName === "chemotherapy") {
      return formData.chemotherapyComment;
    } else if (inputPropsName === "personalcareworker") {
      return formData.personalcareworkerComment;
    } else if (inputPropsName === "oxygen") {
      return formData.oxygenComment;
    } else if (inputPropsName === "tubefeedings") {
      return formData.tubefeedingsComment;
    } else if (inputPropsName === "cathetercare") {
      return formData.cathetercareComment;
    } else if (inputPropsName === "woundcare") {
      return formData.woundcareComment;
    } else if (inputPropsName === "regularinjections") {
      return formData.regularinjectionsComment;
    } else if (inputPropsName === "otcmSupplements") {
      return formData.otcmSupplementsComment;
    } else if (inputPropsName === "allergies") {
      return formData.allergiesComment;
    } else if (inputPropsName === "doyoueverforgettotakeyourmedicine") {
      return formData.doyoueverforgettotakeyourmedicineComment;
    } else if (
      inputPropsName === "doyousometimesnotpayenoughattentiontoyourmedication"
    ) {
      return formData.doyousometimesnotpayenoughattentiontoyourmedicationComment;
    } else if (
      inputPropsName ===
      "doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacist"
    ) {
      return formData.doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacistComment;
    } else if (
      inputPropsName === "whenyoufeelbetterdoyousometimesstoptakingyourmedicine"
    ) {
      return formData.whenyoufeelbetterdoyousometimesstoptakingyourmedicineComment;
    } else if (
      inputPropsName ===
      "sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingit"
    ) {
      return formData.sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingitComment;
    } else if (
      inputPropsName === "doyousometimesforgettorefillyourprescriptionontime"
    ) {
      return formData.doyousometimesforgettorefillyourprescriptionontimeComment;
    } else if (inputPropsName === "nutritionManagement") {
      return formData.nutritionManagementComment || "";
    } else if (inputPropsName === "other") {
      return formData.otherComment || "";
    } else if (inputPropsName === "family_history") {
      return famData.familyHistory.comment || "";
    } else if (inputPropsName === "closeContact") {
      return formData.closeContactComment;
    } else if (inputPropsName === "developBreath") {
      return formData.developBreathComment;
    } else if (inputPropsName === "developCough") {
      return formData.developCoughComment;
    } else if (inputPropsName === "developFever") {
      return formData.developFeverComment;
    } else if (inputPropsName === "developSymptoms") {
      return formData.developSymptomsComment;
    } else if (inputPropsName === "dignosedWithCorona") {
      return formData.dignosedWithCoronaComment;
    } else if (inputPropsName === "travelInternational") {
      return formData.travelInternationalComment;
    } else if (inputPropsName === "bathing") {
      return formData.bathingComment;
    } else if (inputPropsName === "dressing") {
      return formData.dressingComment;
    } else if (inputPropsName === "eating") {
      return formData.eatingComment;
    } else if (inputPropsName === "gettingInOrOutOfBed") {
      return formData.gettingInOrOutOfBedComment;
    } else if (inputPropsName === "gettingInOrOutOfChairs") {
      return formData.gettingInOrOutOfChairsComment;
    } else if (inputPropsName === "toileting") {
      return formData.toiletingComment;
    } else if (inputPropsName === "walking") {
      return formData.walkingComment;
    } else if (inputPropsName === "educationProvidedHepatitis") {
      return formData.educationProvidedHepatitisComment;
    } else if (inputPropsName === "educationProvidedAbdominal") {
      return formData.educationProvidedAbdominalComment;
    } else if (inputPropsName === "recommendationsOthers") {
      return formData.recommendationsOthersComment;
    } else if (inputPropsName === "recommendationsOtherss") {
      return formData.recommendationsOtherssComment;
    } else if (inputPropsName === "educatedonImportance") {
      return formData.educatedonImportanceComment;
    } else if (inputPropsName === "recommendationsDoctor") {
      return formData.recommendationsDoctorComment;
    } else if (inputPropsName === "recommendationsSide") {
      return formData.recommendationsSideComment;
    } else if (inputPropsName === "recommendationsFurther") {
      return formData.recommendationsFurtherComment;
    } else if (inputPropsName === "recommendationsAbdominal") {
      return formData.recommendationsAbdominalComment;
    } else if (inputPropsName === "recommendationsHepatitis") {
      return formData.recommendationsHepatitisComment;
    } else if (inputPropsName === "recommendationsScreenings") {
      return formData.recommendationsScreeningsComment;
    } else if (inputPropsName === "medications") {
      return formData.medicationsComment;
    } else if (inputPropsName === "goingUpOrDownStairs") {
      return formData.goingUpOrDownStairsComment;
    } else if (inputPropsName === "ros_gait") {
      return formData[`${inputPropsName}Comment`] || formData.ros_gaitComment;
    } else if (inputPropsName === "ros_hadAnAmputationSubAnswer") {
      return formData.ros_hadAnAmputationSubAnswerSubText;
    }
    //else if (inputPropsName === "respiratory") {
    //     return formData.respiratoryComment;
    //   } else if (inputPropsName === "integument") {
    //     return formData.integumentComment;
    //   }
    else {
      //return "";
      return formData[`${inputPropsName}Comment`] || "";
    }
  };

  const getCommentsBasedOnInputProps = (name, label) => {
    if (name === "painAcute") {
      return formData.painAcuteComment || "";
    } else if (name === "painChronic") {
      return formData.painChronicComment || "";
    } else if (name === "painManagementTiming") {
      return formData.painManagementTimingComment || "";
    } else if (name === "opioidMedication") {
      return formData.opioidMedicationComment || "";
    } else if (name === "patientAdvised") {
      return formData.patientAdvisedComment || "";
    } else if (name === "isPatientResponding") {
      return formData.isPatientRespondingComment || "";
    } else if (name === "tolerance") {
      return formData.toleranceComment || "";
    } else if (name === "withdrawal") {
      return formData.withdrawalComment || "";
    } else if (name === "increasedUsage") {
      return formData.increasedUsageComment || "";
    } else if (name === "unsuccessEffort") {
      return formData.unsuccessEffortComment || "";
    } else if (name === "excessTimeSpent") {
      return formData.excessTimeSpentComment || "";
    } else if (name === "patientKnowledge") {
      return formData.patientKnowledgeComment || "";
    } else if (name === "psychologicalProblem") {
      return formData.psychologicalProblemComment || "";
    } else if (name === "describe") {
      return formData.describeComment || "";
    } else if (name === "frequency") {
      return formData.frequencyComment || "";
    } else if (name === "malnutrition") {
      return formData.malnutritionComment || "";
    } else if (name === "recommendationsAbdominal") {
      return formData.recommendationsAbdominalComment;
    } else if (name === "recommendationsHepatitis") {
      return formData.recommendationsHepatitisComment;
    } else if (name === "supportedBy") {
      return formData.supportedByComment || "";
    } else if (name === "describe") {
      return formData.describeComment || "";
    } else if (name === "walk_needSomeHelpSubAnswers") {
      return formData.walk_needSomeHelpSubAnswersComment;
    } else if (name === "walk_neededTotalHelpSubAnswer") {
      return formData.walk_neededTotalHelpSubAnswerComment || "";
    } else if (name === "stairs_needSomeHelpSubAnswers") {
      return formData.stairs_needSomeHelpSubAnswersComment;
    } else if (name === "stairs_neededTotalHelpSubAnswer") {
      return formData.stairs_neededTotalHelpSubAnswerComment;
    } else if (name === "oxygenDescribe" && label === "If Yes") {
      return formData.oxygenDescribeComment || "";
    } else if (name === "assistiveSub") {
      return formData.assistiveSubComment;
    } else if (name === "educationProvidedHepatitis") {
      return formData.educationProvidedHepatitisComment;
    } else if (name === "educationProvidedAbdominal") {
      return formData.educationProvidedAbdominalComment;
    } else if (name === "statusoptionsM") {
      return formData.statusoptionsMComment;
    } else if (name === "statusoptionsF") {
      return formData.statusoptionsFComment;
    } else if (name === "statusoptionsA") {
      return formData.statusoptionsAComment;
    } else if (name === "statusoptionsL") {
      return formData.statusoptionsLComment;
    } else if (name === "statusoptionsR") {
      return formData.statusoptionsRComment;
    } else if (name === "statusoptionsD") {
      return formData.statusoptionsDComment;
    } else if (name === "statusoptionsP") {
      return formData.statusoptionsPComment;
    } else if (name === "ros_respiratoryDiagnoses") {
      return formData.ros_respiratoryDiagnosesComment;
    } else if (name === "ros_acutePulmonarySubAnswerDescribe") {
      return formData.ros_acutePulmonarySubAnswerDescribeComment;
    } else if (name === "ros_acutePulmonarySubAnswerSupportedBy") {
      return formData.ros_acutePulmonarySubAnswerSupportedByComment;
    } else if (name === "ros_acuteUpperSubAnswerDescribe") {
      return formData.ros_acuteUpperSubAnswerDescribeComment;
    } else if (name === "ros_acuteUpperSubAnswerSupportedBy") {
      return formData.ros_acuteUpperSubAnswerSupportedByComment;
    } else if (name === "ros_asthmaSubAnswerDescribe") {
      return formData.ros_asthmaSubAnswerDescribeComment;
    } else if (name === "ros_asthmaSubAnswerSupportedBy") {
      return formData.ros_asthmaSubAnswerSupportedByComment;
    } else if (name === "ros_asthmaPatientController") {
      return formData.ros_asthmaPatientControllerComment;
    } else if (name === "ros_asthmaRescueMedications") {
      return formData.aros_sthmaRescueMedicationsComment;
    } else if (name === "ros_asthmaCurrentExacerbation") {
      return formData.ros_asthmaCurrentExacerbationComment;
    } else if (name === "ros_chronicPulmonaryEmbolismSubAnswerDescribe") {
      return formData.ros_chronicPulmonaryEmbolismSubAnswerDescribeComment;
    } else if (name === "ros_chronicPulmonaryEmbolismSubAnswerSupportedBy") {
      return formData.ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment;
    } else if (name === "ros_chronicRespiratoryFailureSubAnswerDescribe") {
      return formData.ros_chronicRespiratoryFailureSubAnswerDescribeComment;
    } else if (name === "ros_chronicRespiratoryFailureSubAnswerSupportedBy") {
      return formData.ros_chronicRespiratoryFailureSubAnswerSupportedByComment;
    } else if (name === "ros_chronicSputumFailureSubAnswerDescribe") {
      return formData.ros_chronicSputumFailureSubAnswerDescribeComment;
    } else if (name === "ros_chronicSputumFailureSubAnswerSupportedBy") {
      return formData.chronicSputumFailureSubAnswerSupportedByComment;
    } else if (name === "ros_copdSubAnswerDescribe") {
      return formData.ros_copdSubAnswerDescribeComment;
    } else if (name === "ros_copdSubAnswerSupportedBy") {
      return formData.ros_copdSubAnswerSupportedByComment;
    } else if (name === "ros_patientChronicBronchitis") {
      return formData.ros_patientChronicBronchitisComment;
    } else if (name === "ros_patientHaveEmphysema") {
      return formData.ros_patientHaveEmphysemaComment;
    } else if (name === "ros_patientBronchodilator") {
      return formData.ros_patientBronchodilatorComment;
    } else if (name === "ros_patientSteroids") {
      return formData.ros_patientSteroidsComment;
    } else if (name === "ros_patientExacerbation") {
      return formData.ros_patientExacerbationComment;
    } else if (name === "ros_cysticFibrosisSubAnswerDescribe") {
      return formData.ros_cysticFibrosisSubAnswerDescribeComment;
    } else if (name === "ros_cysticFibrosisSubAnswerSupportedBy") {
      return formData.ros_cysticFibrosisSubAnswerSupportedByComment;
    } else if (name === "ros_hypoventilationSubAnswerDescribe") {
      return formData.ros_hypoventilationSubAnswerDescribeComment;
    } else if (name === "ros_hypoventilationSubAnswerSupportedBy") {
      return formData.ros_hypoventilationSubAnswerSupportedByComment;
    } else if (name === "ros_hypoxemiaSubAnswerDescribe") {
      return formData.ros_hypoxemiaSubAnswerDescribeComment;
    } else if (name === "ros_hypoxemiaSubAnswerSupportedBy") {
      return formData.ros_hypoxemiaSubAnswerSupportedByComment;
    } else if (name === "ros_pneumoniaSubAnswerDescribe") {
      return formData.ros_pneumoniaSubAnswerDescribeComment;
    } else if (name === "ros_pneumoniaSubAnswerSupportedBy") {
      return formData.ros_pneumoniaSubAnswerSupportedByComment;
    } else if (name === "ros_pneumoniaSubAnswerEtiology") {
      return formData.ros_pneumoniaSubAnswerEtiologyComment;
    } else if (name === "ros_historyOfLungAbscess") {
      return formData.ros_historyOfLungAbscessComment;
    } else if (name === "ros_historyOfEmpyema") {
      return formData.ros_historyOfEmpyemaComment;
    } else if (name === "ros_pulmonaryFibrosisSubAnswerDescribe") {
      return formData.ros_pulmonaryFibrosisSubAnswerDescribeComment;
    } else if (name === "ros_pulmonaryFibrosisSubAnswerSupportedBy") {
      return formData.ros_pulmonaryFibrosisSubAnswerSupportedByComment;
    } else if (name === "ros_respiratorDependenceSubAnswerDescribe") {
      return formData.ros_respiratorDependenceSubAnswerDescribeComment;
    } else if (name === "ros_respiratorDependenceSubAnswerSupportedBy") {
      return formData.ros_respiratorDependenceSubAnswerSupportedByComment;
    } else if (name === "ros_respiratoryArrestSubAnswerDescribe") {
      return formData.ros_respiratoryArrestSubAnswerDescribeComment;
    } else if (name === "ros_respiratoryArrestSubAnswerSupportedBy") {
      return formData.ros_respiratoryArrestSubAnswerSupportedByComment;
    } else if (name === "ros_sarcoidosisSubAnswerDescribe") {
      return formData.ros_sarcoidosisSubAnswerDescribeComment;
    } else if (name === "ros_sarcoidosisSubAnswerSupportedBy") {
      return formData.ros_sarcoidosisSubAnswerSupportedByComment;
    } else if (name === "ros_sarcoidosisSubAnswerSpecify") {
      return formData.ros_sarcoidosisSubAnswerSpecifyComment;
    } else if (name === "ros_sleepApneaSubAnswerDescribe") {
      return formData.ros_sleepApneaSubAnswerDescribeComment;
    } else if (name === "ros_sleepApneaSubAnswerSupportedBy") {
      return formData.ros_sleepApneaSubAnswerSupportedByComment;
    } else if (name === "ros_otherSubAnswerDescribe") {
      return formData.ros_otherSubAnswerDescribeComment;
    } else if (name === "ros_otherSubAnswerSupportedBy") {
      return formData.ros_otherSubAnswerSupportedByComment;
    } else if (name === "ros_integumentDiagnoses") {
      return formData.ros_integumentDiagnosesComment;
    } else if (name === "ros_basilCellCarcinomaSubAnswerDescribe") {
      return formData.ros_basilCellCarcinomaSubAnswerDescribeComment;
    } else if (name === "ros_basilCellCarcinomaSubAnswerSupportedBy") {
      return formData.ros_basilCellCarcinomaSubAnswerSupportedByComment;
    } else if (name === "ros_dermatitisSubAnswerDescribe") {
      return formData.ros_dermatitisSubAnswerDescribeComment;
    } else if (name === "ros_dermatitisSubAnswerSupportedBy") {
      return formData.ros_dermatitisSubAnswerSupportedByComment;
    } else if (name === "ros_dermatitisSubAnswerWhatType") {
      return formData.ros_dermatitisSubAnswerWhatTypeComment;
    } else if (name === "ros_eczemaSubAnswerDescribe") {
      return formData.ros_eczemaSubAnswerDescribeComment;
    } else if (name === "ros_eczemaSubAnswerSupportedBy") {
      return formData.ros_eczemaSubAnswerSupportedByComment;
    } else if (name === "ros_onychomycosisSubAnswerDescribe") {
      return formData.ros_onychomycosisSubAnswerDescribeComment;
    } else if (name === "ros_onychomycosisSubAnswerSupportedBy") {
      return formData.ros_onychomycosisSubAnswerSupportedByComment;
    } else if (name === "ros_psoriasisSubAnswerDescribe") {
      return formData.ros_psoriasisSubAnswerDescribeComment;
    } else if (name === "ros_psoriasisSubAnswerSupportedBy") {
      return formData.ros_psoriasisSubAnswerSupportedByComment;
    } else if (name === "ros_psoriasisSubAnswerPsoriaticArthritis") {
      return formData.ros_psoriasisSubAnswerPsoriaticArthritisComment;
    } else if (name === "ros_skinUlcerSubAnswerDescribe") {
      return formData.ros_skinUlcerSubAnswerDescribeComment;
    } else if (name === "ros_skinUlcerSubAnswerSupportedBy") {
      return formData.ros_skinUlcerSubAnswerSupportedByComment;
    } else if (name === "ros_skinUlcerSubAnswerEtiology") {
      return formData.ros_skinUlcerSubAnswerEtiologyComment;
    } else if (name === "ros_tineaPedisSubAnswerDescribe") {
      return formData.ros_tineaPedisSubAnswerDescribeComment;
    } else if (name === "ros_tineaPedisSubAnswerSupportedBy") {
      return formData.ros_tineaPedisSubAnswerSupportedByComment;
    } else if (name === "ros_urticarialDiseaseSubAnswerDescribe") {
      return formData.ros_urticarialDiseaseSubAnswerDescribeComment;
    } else if (name === "ros_urticarialDiseaseSubAnswerSupportedBy") {
      return formData.ros_urticarialDiseaseSubAnswerSupportedByComment;
    } else if (name === "ros_urticarialDiseaseSubAnswerType") {
      return formData.ros_urticarialDiseaseSubAnswerTypeComment;
    } else if (name === "ros_woundSubAnswerDescribe") {
      return formData.ros_woundSubAnswerDescribeComment;
    } else if (name === "ros_woundSubAnswerSupportedBy") {
      return formData.ros_woundSubAnswerSupportedByComment;
    } else if (name === "ros_woundSubAnswerEtiology") {
      return formData.ros_woundSubAnswerEtiologyComment;
    } else if (name === "ros_othersSubAnswerDescribe") {
      return formData.ros_othersSubAnswerDescribeComment;
    } else if (name === "ros_othersSubAnswerSupportedBy") {
      return formData.ros_othersSubAnswerSupportedByComment;
    } else if (name === "ros_collagenSubAnswerDescribe") {
      return formData.ros_collagenSubAnswerDescribeComment;
    } else if (name === "ros_collagenSubAnswercollagenDescribe") {
      return formData.ros_collagenSubAnswercollagenDescribeComment;
    } else if (name === "ros_degenerativeDiscDiseaseSubAnswerDescribe") {
      return formData.ros_degenerativeDiscDiseaseSubAnswerDescribeComment;
    } else if (name === "ros_degenerativeDiscDiseaseSubAnswerSupportedBy") {
      return formData.ros_degenerativeDiscDiseaseSubAnswerSupportedByComment;
    } else if (name === "ros_degenerativeDiscDiseaseSubAnswerNormalBladder") {
      return formData.ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment;
    } else if (name === "ros_degenerativeDiscDiseaseSubAnswerSiteOfDisease") {
      return formData.ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment;
    } else if (name === "ros_extremityFractureSubAnswerDescribe") {
      return formData.ros_extremityFractureSubAnswerDescribeComment;
    } else if (name === "ros_extremityFractureSubAnswerSupportedBy") {
      return formData.ros_extremityFractureSubAnswerSupportedByComment;
    } else if (name === "ros_extremityFractureSubAnswerExtremity") {
      return formData.ros_extremityFractureSubAnswerExtremityComment;
    } else if (
      name === "ros_extremityFractureCurrentWithinTwelveSubAnswerWeeks"
    ) {
      return formData.ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment;
    } else if (name === "ros_goutSubAnswerDescribe") {
      return formData.ros_goutSubAnswerDescribeComment;
    } else if (name === "ros_goutSubAnswerSupportedBy") {
      return formData.ros_goutSubAnswerSupportedByComment;
    } else if (name === "ros_halluxValgusSubAnswerDescribe") {
      return formData.ros_halluxValgusSubAnswerDescribeComment;
    } else if (name === "ros_halluxValgusSubAnswerSupportedBy") {
      return formData.ros_halluxValgusSubAnswerSupportedByComment;
    } else if (name === "ros_hammerToesSubAnswerDescribe") {
      return formData.ros_hammerToesSubAnswerDescribeComment;
    } else if (name === "ros_hammerToesSubAnswerSupportedBy") {
      return formData.ros_hammerToesSubAnswerSupportedByComment;
    } else if (name === "ros_osteoarthritisSubAnswerDescribe") {
      return formData.ros_osteoarthritisSubAnswerDescribeComment;
    } else if (name === "ros_osteoarthritisSubAnswerSupportedBy") {
      return formData.ros_osteoarthritisSubAnswerSupportedByComment;
    } else if (name === "ros_osteomyelitisSubAnswerDescribe") {
      return formData.ros_osteomyelitisSubAnswerDescribeComment;
    } else if (name === "ros_osteomyelitisSubAnswerSupportedBy") {
      return formData.ros_osteomyelitisSubAnswerSupportedByComment;
    } else if (name === "ros_osteoporosisSubAnswerDescribe") {
      return formData.ros_osteoporosisSubAnswerDescribeComment;
    } else if (name === "ros_osteoporosisSubAnswerSupportedBy") {
      return formData.ros_osteoporosisSubAnswerSupportedByComment;
    } else if (name === "ros_pyogenicArthritisSubAnswerDescribe") {
      return formData.ros_pyogenicArthritisSubAnswerDescribeComment;
    } else if (name === "ros_pyogenicArthritisSubAnswerSupportedBy") {
      return formData.ros_pyogenicArthritisSubAnswerSupportedByComment;
    } else if (name === "ros_rheumatoidArthritisSubAnswerDescribe") {
      return formData.ros_rheumatoidArthritisSubAnswerDescribeComment;
    } else if (name === "ros_rheumatoidArthritisSubAnswerSupportedBy") {
      return formData.ros_rheumatoidArthritisSubAnswerSupportedByComment;
    } else if (name === "ros_spinalStenosisSubAnswerDescribe") {
      return formData.ros_spinalStenosisSubAnswerDescribeComment;
    } else if (name === "ros_spinalStenosisSubAnswerSupportedBy") {
      return formData.ros_spinalStenosisSubAnswerSupportedByComment;
    } else if (name === "ros_spinalStenosisSubAnswerNormalBladder") {
      return formData.ros_spinalStenosisSubAnswerNormalBladderComment;
    } else if (name === "ros_systemicLupusSubAnswerDescribe") {
      return formData.ros_systemicLupusSubAnswerDescribeComment;
    } else if (name === "ros_systemicLupusSubAnswerSupportedBy") {
      return formData.ros_systemicLupusSubAnswerSupportedByComment;
    } else if (name === "ros_muskoOtherSubAnswerDescribe") {
      return formData.ros_muskoOtherSubAnswerDescribeComment;
    } else if (name === "ros_muskoOtherSubAnswerSupportedBy") {
      return formData.ros_muskoOtherSubAnswerSupportedByComment;
    } else if (name === "ros_aidsSubAnswerDescribe") {
      return formData.ros_aidsSubAnswerDescribeComment;
    } else if (name === "ros_aidsSubAnswerSupportedBy") {
      return formData.ros_aidsSubAnswerSupportedByComment;
    } else if (name === "ros_aidsSubAnswerPatientUnderTreatment") {
      return formData.ros_aidsSubAnswerPatientUnderTreatmentComment;
    } else if (name === "ros_anemiaSubAnswerDescribe") {
      return formData.ros_anemiaSubAnswerDescribeComment;
    } else if (name === "ros_anemiaSubAnswerSupportedBy") {
      return formData.ros_anemiaSubAnswerSupportedByComment;
    } else if (name === "ros_anemiaSubAnswerEtiology") {
      return formData.ros_anemiaSubAnswerEtiologyComment;
    } else if (name === "ros_anemiaSubAnswerYesPatientOn") {
      return formData.ros_anemiaSubAnswerYesPatientOnComment;
    } else if (name === "ros_cDifficileSubAnswerDescribe") {
      return formData.ros_cDifficileSubAnswerDescribeComment;
    } else if (name === "ros_cDifficileSubAnswerSupportedBy") {
      return formData.ros_cDifficileSubAnswerSupportedByComment;
    } else if (name === "ros_communityAcquiredSubAnswerDescribe") {
      return formData.ros_communityAcquiredSubAnswerDescribeComment;
    } else if (name === "ros_communityAcquiredSubAnswerSupportedBy") {
      return formData.ros_communityAcquiredSubAnswerSupportedByComment;
    } else if (name === "ros_hivSubAnswerDescribe") {
      return formData.ros_hivSubAnswerDescribeComment;
    } else if (name === "ros_hivSubAnswerSupportedBy") {
      return formData.ros_hivSubAnswerSupportedByComment;
    } else if (name === "ros_hivSubAnswerPatientSymptomatic") {
      return formData.ros_hivSubAnswerPatientSymptomaticComment;
    } else if (name === "ros_hivSubAnswerActiveTreatment") {
      return formData.ros_hivSubAnswerActiveTreatmentComment;
    } else if (name === "ros_herpesZosterSubAnswerDescribe") {
      return formData.ros_herpesZosterSubAnswerDescribeComment;
    } else if (name === "ros_herpesZosterSubAnswerSupportedBy") {
      return formData.ros_herpesZosterSubAnswerSupportedByComment;
    } else if (name === "ros_mrsaInfectionSubAnswerDescribe") {
      return formData.ros_mrsaInfectionSubAnswerDescribeComment;
    } else if (name === "ros_mrsaInfectionSubAnswerSupportedBy") {
      return formData.ros_mrsaInfectionSubAnswerSupportedByComment;
    } else if (name === "ros_immuneDeficiencySubAnswerDescribe") {
      return formData.ros_immuneDeficiencySubAnswerDescribeComment;
    } else if (name === "ros_immuneDeficiencySubAnswerSupportedBy") {
      return formData.ros_immuneDeficiencySubAnswerSupportedByComment;
    } else if (name === "ros_immuneDeficiencySubAnswerHistoryOfOpportunistic") {
      return formData.ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment;
    } else if (name === "ros_sepsisSubAnswerDescribe") {
      return formData.ros_sepsisSubAnswerDescribeComment;
    } else if (name === "ros_sepsisSubAnswerSupportedBy") {
      return formData.ros_sepsisSubAnswerSupportedByComment;
    } else if (name === "ros_sickleCellDiseaseTwoSubAnswerDescribe") {
      return formData.ros_sickleCellDiseaseTwoSubAnswerDescribeComment;
    } else if (name === "ros_sickleCellDiseaseTwoSubAnswerSupportedBy") {
      return formData.ros_sickleCellDiseaseTwoSubAnswerSupportedByComment;
    } else if (name === "ros_sickleCellTraitSubAnswerDescribe") {
      return formData.ros_sickleCellTraitSubAnswerDescribeComment;
    } else if (name === "ros_sickleCellTraitSubAnswerSupportedBy") {
      return formData.ros_sickleCellTraitSubAnswerSupportedByComment;
    } else if (name === "ros_thalassemiaSubAnswerDescribe") {
      return formData.ros_thalassemiaSubAnswerDescribeComment;
    } else if (name === "ros_thalassemiaSubAnswerSupportedBy") {
      return formData.ros_thalassemiaSubAnswerSupportedByComment;
    } else if (name === "ros_thrombocytopeniaSubAnswerDescribe") {
      return formData.ros_thrombocytopeniaSubAnswerDescribeComment;
    } else if (name === "ros_thrombocytopeniaSubAnswerSupportedBy") {
      return formData.ros_thrombocytopeniaSubAnswerSupportedByComment;
    } else if (name === "ros_tuberculosisSubAnswerDescribe") {
      return formData.ros_tuberculosisSubAnswerDescribeComment;
    } else if (name === "ros_tuberculosisSubAnswerSupportedBy") {
      return formData.ros_tuberculosisSubAnswerSupportedByComment;
    } else if (name === "ros_tuberculosisSubAnswerActiveTuberculosis") {
      return formData.ros_tuberculosisSubAnswerActiveTuberculosisComment;
    } else if (name === "ros_tuberculosisSubAnswerTbInfection") {
      return formData.ros_tuberculosisSubAnswerTbInfectionComment;
    } else if (name === "ros_vitaminDDeficiencySubAnswerDescribe") {
      return formData.ros_vitaminDDeficiencySubAnswerDescribeComment;
    } else if (name === "ros_vitaminDDeficiencySubAnswerSupportedBy") {
      return formData.ros_vitaminDDeficiencySubAnswerSupportedByComment;
    } else if (name === "ros_hemaOtherSubAnswerDescribe") {
      return formData.ros_hemaOtherSubAnswerDescribeComment;
    } else if (name === "ros_hemaOtherSubAnswerSupportedBy") {
      return formData.ros_hemaOtherSubAnswerSupportedByComment;
    } else if (name === "ros_musculoskeletalDiagnoses") {
      return formData.ros_musculoskeletalDiagnosesComment;
    } else if (name === "ros_hematologyDiagnoses") {
      return formData.ros_hematologyDiagnosesComment;
    } else if (name === "ros_resultsforperipheralarterialdiseasetestingright") {
      return formData.ros_resultsforperipheralarterialdiseasetestingrightComment;
    } else if (name === "ros_resultsforperipheralarterialdiseasetestingleft") {
      return formData.ros_resultsforperipheralarterialdiseasetestingleftComment;
    } else {
      // return "";
      return formData[`${name}Comment`] || "";
    }
  };

  const updateCommentsInFormData = (fieldName, label, commentValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName + "Comment"]: commentValue,
    }));
  };

  const [previousVisitType, setPreviousVisitType] = useState(null);

  const handleVisitTypeCancel = () => {
    setIsVisitTypeAlertOpen(false);

    if (previousVisitType !== null) {
      setSelectedVisitType(previousVisitType);
    }
  };

  const [showConsentedToVideo, setShowConsentedToVideo] = useState(false);

  useEffect(() => {
    if (formData.visitType === "virtual") {
      setShowConsentedToVideo(true);
    } else {
      setShowConsentedToVideo(false);
    }
  }, [formData.visitType]);

  const handleVisitTypeChange = (e) => {
    const newVisitType = e.target.value;

    const requiresConfirmation =
      newVisitType === "virtual" || newVisitType === "inPerson";

    if (requiresConfirmation) {
      //setPreviousVisitType(selectedVisitType);
      setIsVisitTypeAlertOpen(true);

      setSelectedVisitType(newVisitType);

      return newVisitType;
    } else {
      setSelectedVisitType(newVisitType);
    }
  };

  const handleVisitTypeUpdate = async () => {
    let visitTypeId;

    if (selectedVisitType === "inPerson") {
      visitTypeId = 1;
    } else if (selectedVisitType === "virtual") {
      visitTypeId = 2;
    }

    const _id = _memberIdForQueryForm;

    if (visitTypeId && _id) {
      const requestBody = {
        data: {
          visitType: visitTypeId,
          _id,
        },
      };

      try {
        const response = await putApi(
          "/visitTypes/updateVisiteType",
          requestBody,
        );

        if (response.status === 200) {
          setIsVisitTypeAlertOpen(false);
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          setShowConsentedToVideo(selectedVisitType === "virtual");
          setSelectedVisitType(selectedVisitType);
          const updatedFormData = {
            ...formData,
            visitType: selectedVisitType,
          };
          setFormData(updatedFormData);
          //setSelectedVisitType(selectedVisitType);
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsVisitTypeAlertOpen(false);
        //setSelectedVisitType(null);
      }
    }
  };

  const [clinicianName, setClinicianName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [providerSignature, setProviderSignature] = useState("");

  useEffect(() => {
    if (
      formData.painChronicWhereText !== undefined &&
      formData.painChronicWhereText !== null
    ) {
      setSubText(formData.painChronicWhereText);
    } else if (
      formData.rateYourPainSubtext !== undefined &&
      formData.rateYourPainSubtext !== null
    ) {
      setSubText(formData.rateYourPainSubtext);
    } else if (
      formData.describeSubtext !== undefined &&
      formData.describeSubtext !== null
    ) {
      setSubText(formData.describeSubtext);
    } else {
      setSubText("");
    }
    const roleId = sessionStorage.getItem("roleId");
    if (roleId == 5) {
      const fname = sessionStorage.getItem("firstName");
      const lname = sessionStorage.getItem("lastName");
      const name = `${fname} ${lname}`;
      setClinicianName(name);
      const _speciality = sessionStorage.getItem("speciality");
      setSpeciality(_speciality);
      const providerSignature = sessionStorage.getItem("providerSignature");
      setProviderSignature(providerSignature);
    }
  }, [formData]);

  const handleChangeCheckbox = (e, inputProps) => {
    const { checked, value } = e.target;

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };

      if (inputProps.name === "memberAknowledgement") {
        updatedFormData.memberAknowledgement = checked ? "1" : "";
      } else if (inputProps.name === "acceptDisclosure") {
        updatedFormData.acceptDisclosure = checked ? "1" : "";
      } else if (inputProps.name === "consentedToVideo") {
        updatedFormData.consentedToVideo = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsFurther") {
        updatedFormData.recommendationsFurther = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsOther") {
        updatedFormData.recommendationsOther = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsAbdominal") {
        updatedFormData.recommendationsAbdominal = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsHepatitis") {
        updatedFormData.recommendationsHepatitis = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsSide") {
        updatedFormData.recommendationsSide = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsOtherss") {
        updatedFormData.recommendationsOtherss = checked ? "1" : "";
      } else if (inputProps.name === "educatedonImportance") {
        updatedFormData.educatedonImportance = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsDoctor") {
        updatedFormData.recommendationsDoctor = checked ? "1" : "";
      } else if (inputProps.name === "recommendationsScreenings") {
        updatedFormData.recommendationsScreenings = checked ? "1" : "";
      } else if (inputProps.name === "self_Recommendations") {
        const oldData = updatedFormData?.self_Recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["self_Recommendations"] = newData;
      } else if (inputProps.name === "self_eduAdvPlan") {
        updatedFormData["self_eduAdvPlan"] = checked ? "1" : "";
      } else if (inputProps.name === "self_declineDisc") {
        updatedFormData["self_declineDisc"] = checked ? "1" : "";
      } else if (inputProps.name === "gait_Recommendations") {
        const oldData = updatedFormData?.gait_Recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["gait_Recommendations"] = newData;
      } else if (inputProps.name === "respiratory_Recommendations") {
        const oldData = updatedFormData?.respiratory_Recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["respiratory_Recommendations"] = newData;
      } else if (inputProps.name === "integument_Recommendations") {
        const oldData = updatedFormData?.integument_Recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["integument_Recommendations"] = newData;
      } else if (inputProps.name === "musko_Recommendations") {
        const oldData = updatedFormData?.musko_Recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["musko_Recommendations"] = newData;
      } else if (inputProps.name === "hema_Recommendations") {
        const oldData = updatedFormData?.hema_Recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["hema_Recommendations"] = newData;
      } else if (inputProps.name === "ros_cancerRecommendations") {
        const oldData = updatedFormData?.ros_cancerRecommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["ros_cancerRecommendations"] = newData;
      } else if (inputProps.name === "ros_cardiovascularRecommendations") {
        const oldData =
          updatedFormData?.ros_cardiovascularRecommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["ros_cardiovascularRecommendations"] = newData;
      } else if (inputProps.name === "endocrine_recommendations") {
        const oldData = updatedFormData?.endocrine_recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["endocrine_recommendations"] = newData;
      } else if (inputProps.name === "gastro_recommendations") {
        const oldData = updatedFormData?.gastro_recommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["gastro_recommendations"] = newData;
      } else if (inputProps.name === "ros_genitoRecommendations") {
        const oldData = updatedFormData?.ros_genitoRecommendations || [];
        let newData = [];
        if (oldData.length < 1) {
          newData.push(value);
        } else {
          if (checked) {
            newData = [...oldData];
            newData.push(value);
          } else {
            newData = oldData.filter((ele) => ele !== value);
          }
        }

        updatedFormData["ros_genitoRecommendations"] = newData;
      } else {
        const subAnswerIndex =
          updatedFormData.recommendationsSubAnswers.findIndex((subAnswer) =>
            subAnswer.hasOwnProperty(inputProps.name),
          );

        if (subAnswerIndex === -1) {
          const newSubAnswer = {
            [inputProps.name]: checked ? "1" : "",
          };
          updatedFormData.recommendationsSubAnswers.push(newSubAnswer);
        } else {
          updatedFormData.recommendationsSubAnswers[subAnswerIndex][
            inputProps.name
          ] = checked ? "1" : "";
        }
      }

      return updatedFormData;
    });
  };

  const [examFinishDate, setExamFinishDate] = useState(null);
  const [evaluationDate, setEvaluationDate] = useState(null);
  const [alcoholRowData, setAlcoholRowData] = useState(null);
  const [providerSignAllDashboard, setProviderSignAllDashboard] =
    useState(null);
  const [noShowUpdateHraStatusPopup, setNoShowUpdateHraStatusPopup] =
    useState(false);
  const [
    cancelAssessmentUpdateHraStatusPopup,
    setCancelAssessmentUpdateHraStatusPopup,
  ] = useState(false);

  const fetchData = async () => {
    try {
      const assessmentDataFromAPI = await getAssessmentData();

      if (assessmentDataFromAPI?.data) {
        const assessmentDBData = assessmentDataFromAPI.data;

        let dbFormData = {};

        if (assessmentDBData) {
          const { data } = assessmentDBData;
          setMemberId(data._id);

          const demographicsDBData = data?.demographics?.data;
          const screeningsDBData = data?.screenings?.data;
          const painDBData = data?.pain?.data;
          const vitalDBData = data?.vital?.data;
          const previouslyDBData = data?.previously?.data;
          const familyDBData = data?.family?.data;
          const medicalDBData = data?.medical?.data;
          const covidDBData = data?.covid?.data;
          const patientDBData = data?.patient?.data;
          const selfAssessmentDBData = data?.self?.data;
          const miniDBData = data?.mini?.data;
          const preventiveDBData = data?.preventive?.data;
          const allergiesDBData = data?.allergies?.data;
          const activitiesDBData = data?.activities?.data;
          const examReviewData = data?.exam?.data;

          const homeSafetyData = data?.home?.data;

          const reviewOfSystemDBData = data?.review?.data || {};

          if (
            demographicsDBData &&
            Object.keys(demographicsDBData).length > 0
          ) {
            // demographics section
            const { plan: planFromDemographics, program } =
              assessmentDBData.data;
            const region = demographicsDBData.region;
            const firstName = demographicsDBData.firstName;
            const middleName = demographicsDBData.middleName;
            const lastName = demographicsDBData.lastName;
            const address = demographicsDBData.address;
            const addressTwo = demographicsDBData.addressTwo;
            const mAddressThree = demographicsDBData.mAddressThree;
            const mCity = demographicsDBData.mCity;
            const mState = demographicsDBData.mState;
            const mStatus = demographicsDBData.mStatus;
            const pId = demographicsDBData.pId;
            const pOfcName = demographicsDBData.pOfcName;
            const mZip = demographicsDBData.mZip;
            const memberId = demographicsDBData.memberId;
            const hicn = demographicsDBData.hicn;
            const email = demographicsDBData.email;
            const pPhone = demographicsDBData.pPhone;
            const pAddOne = demographicsDBData.pAddOne;
            const pCity = demographicsDBData.pCity;
            const pState = demographicsDBData.pState;
            const pZip = demographicsDBData.pZip;
            const pcpcounty = demographicsDBData.pcpcounty;
            const ethnicity = demographicsDBData.ethnicity;
            const language = demographicsDBData.language;
            const plan = demographicsDBData.plan;
            const gender = demographicsDBData.gender;
            const dobInDays = demographicsDBData.dob;
            const referenceDate = new Date("1900-01-01");
            const dobDate = dobInDays
              ? new Date(
                  referenceDate.getTime() + dobInDays * 24 * 60 * 60 * 1000,
                )
              : null;

            // setDobDate(dobDate);

            dbFormData = {
              ...dbFormData,
              // demographics
              plan: plan || "",
              program: program || "",
              region: region || "",
              firstName: firstName || "",
              middleName: middleName || "",
              lastName: lastName || "",
              address: address || "",
              addressTwo: addressTwo || "",
              mAddressThree: mAddressThree || "",
              mCity: mCity || "",
              mState: mState || "",
              mZip: mZip || "",
              mStatus: mStatus || "",
              dobDate: dobDate || null,
              memberId: memberId || "",
              hicn: hicn || "",
              email: email || "",
              pPhone: pPhone || "",
              pAddOne: pAddOne || "",
              pCity: pCity || "",
              pState: pState || "",
              pZip: pZip || "",
              pcpcounty: pcpcounty || "",
              pId: pId || "",
              pOfcName: pOfcName || "",
              gender: gender || "",
              ethnicity: ethnicity || "",
              language: language || "",
              plan_option: plan || "",
            };
          }

          if (screeningsDBData && Object.keys(screeningsDBData).length > 0) {
            // screenings needed

            const microAlbumin = screeningsDBData?.microAlbumin?.isMicroAlbumin;
            const fobt = screeningsDBData?.fobt?.isFobt;
            const aOneC = screeningsDBData?.aOneC?.isAOneC;
            const ldl = screeningsDBData?.ldl?.isLdl;
            const retinalExam = screeningsDBData?.retinalExam?.isRetinalExam;
            const dexa = screeningsDBData?.dexa?.isDexa;
            const pad = screeningsDBData?.pad?.isPad;

            const microAlbuminData = screeningsDBData?.microAlbumin?.subAnswers;

            const fobtData = screeningsDBData?.fobt?.subAnswers;
            const aOneCData = screeningsDBData?.aOneC?.subAnswers;
            const ldlData = screeningsDBData?.ldl?.subAnswers;
            const retinalExamData = screeningsDBData?.retinalExam?.subAnswers;
            const dexaData = screeningsDBData?.dexa?.subAnswers;
            const padData = screeningsDBData?.pad?.subAnswers;
            const statusoptionsM = microAlbuminData?.status_options;
            const statusoptionsMComment = microAlbuminData?.subAnswers?.reason;

            const commentsM = microAlbuminData?.subAnswers?.subAnswers?.other;
            const commentsF = fobtData?.subAnswers?.subAnswers?.other;
            const commentsA = aOneCData?.subAnswers?.subAnswers?.other;
            const commentsL = ldlData?.subAnswers?.subAnswers?.other;
            const commentsR = retinalExamData?.subAnswers?.subAnswers?.other;
            const commentsD = dexaData?.subAnswers?.subAnswers?.other;
            const commentsP = padData?.subAnswers?.subAnswers?.other;

            const recommendationsScreeningsComment =
              screeningsDBData?.other?.isMemberEducated?.comment || "";

            const statusoptionsFComment = fobtData?.subAnswers?.reason;
            const statusoptionsAComment = aOneCData?.subAnswers?.reason;
            const statusoptionsLComment = ldlData?.subAnswers?.reason;
            const statusoptionsRComment = retinalExamData?.subAnswers?.reason;
            const statusoptionsDComment = dexaData?.subAnswers?.reason;
            const statusoptionsPComment = padData?.subAnswers?.reason;
            const statusoptionsF = fobtData?.status_options;
            const statusoptionsA = aOneCData?.status_options;
            const a1cComment = aOneCData?.comment;
            const statusoptionsL = ldlData?.status_options;
            const statusoptionsR = retinalExamData?.status_options;
            const statusoptionsD = dexaData?.status_options;
            const statusoptionsP = padData?.status_options;

            const microalbuminComment = microAlbuminData?.comment;
            const ldlComment = ldlData?.comment;
            const retinaleyeexamComment = retinalExamData?.comment;
            const dexaComment = dexaData?.comment;
            const padComment = padData?.comment;
            const examdateL = ldlData?.examDate;
            const screeningresultL = ldlData?.sResult;

            const examdateM = microAlbuminData?.examDate;
            const screeningresultM = microAlbuminData?.sResult;

            const screeningscheckValue =
              screeningsDBData?.other?.isMemberEducated?.value;

            const checkedOptions = {
              membereducatedonresultsverbalizedunderstanding:
                screeningscheckValue || "",
            };

            const fobtComment = fobtData?.comment;
            const examdateF = fobtData?.examDate;
            const examdateA = aOneCData?.examDate;
            const examdateR = retinalExamData?.examDate;
            const examdateD = dexaData?.examDate;
            const examdateP = padData?.examDate;
            const screeningresultF = fobtData?.sResult;

            const resultsforperipheralarterialdiseasetestingleft =
              padData?.peripheralResultsLeft?.peripheralResultsLeft;
            const resultsforperipheralarterialdiseasetestingright =
              padData?.peripheralResultsRight?.peripheralResultsRight;

            const resultsforperipheralarterialdiseasetestingleftComment =
              padData?.peripheralResultsLeft?.comment;

            const resultsforperipheralarterialdiseasetestingrightComment =
              padData?.peripheralResultsRight?.comment;

            const padtestingresultsright = padData?.tResultRight;
            const padtestingresultsleft = padData?.tResultLeft;
            const fobttestbarcode = fobtData?.TBarcode;

            const ldltextbarcode = ldlData?.TBarcode;
            const screeningresultA = aOneCData?.sResult;
            const screeningresultR = retinalExamData?.sResult;
            const screeningresultP = padData?.sResult;
            const screeningresultD = dexaData?.sResult;
            // setStatusOptions(status_optionsM);

            dbFormData = {
              ...dbFormData,
              microalbuminComment,
              ldlComment,
              resultsforperipheralarterialdiseasetestingleftComment,
              resultsforperipheralarterialdiseasetestingrightComment,

              recommendationsScreenings:
                checkedOptions.membereducatedonresultsverbalizedunderstanding ||
                "",
              recommendationsScreeningsComment,
              retinaleyeexam: retinalExam || "",
              resultsforperipheralarterialdiseasetestingleft,
              resultsforperipheralarterialdiseasetestingright,
              retinaleyeexamComment,
              padtestingresultsright,
              padtestingresultsleft,
              padComment,
              dexaComment,
              examdateM,
              examdateL,
              examdateA,
              examdateR,
              examdateD,
              examdateP,
              a1cComment,
              fobtComment,
              fobttestbarcode,
              ldltextbarcode,
              // screening_resultM,
              screeningresultM,
              screeningresultF,
              screeningresultA,
              screeningresultL,
              screeningresultR,
              screeningresultP,
              screeningresultD,
              examdateF,
              commentsM: commentsM || "",
              commentsF: commentsF || "",
              commentsA: commentsA || "",
              commentsD: commentsD || "",
              commentsR: commentsR || "",
              commentsL: commentsL || "",
              commentsP: commentsP || "",

              microalbumin: microAlbumin || "",
              statusoptionsM: statusoptionsM || "",
              statusoptionsF: statusoptionsF || "",
              statusoptionsA: statusoptionsA || "",
              statusoptionsMComment,
              statusoptionsFComment,
              statusoptionsAComment,
              statusoptionsLComment,
              statusoptionsRComment,
              statusoptionsDComment,
              statusoptionsPComment,
              // status_optionsA: status_optionsA || "",
              statusoptionsL: statusoptionsL || "",
              statusoptionsR: statusoptionsR || "",
              statusoptionsD: statusoptionsD || "",
              statusoptionsP: statusoptionsP || "",
              // examdateM: microAlbuminData?.examDate || "",
              microalbumintestbarcode: microAlbuminData?.TBarcode || "",
              // screeningresultM: microAlbuminData?.sResult || "",
              diagnosisM: microAlbuminData?.diagnosis,
              diagnosisF: fobtData?.diagnosis || "",
              // sResultM: microAlbuminData?.sResult || "",
              // diagnosisM: microAlbuminData?.diagnosis || "",
              commentM: microAlbuminData?.comment || "",

              fobttestbarcode: fobtData?.TBarcode || "",
              examDateF: fobtData?.examDate || "",
              sResultF: fobtData?.sResult || "",
              commentF: fobtData?.comment || "",

              a1ctestbarcode: aOneCData?.TBarcode || "",
              examdateA: aOneCData?.examDate || "",
              sResultA: aOneCData?.sResult || "",
              diagnosisA: aOneCData?.diagnosis || "",
              commentA: aOneCData?.comment || "",

              ldltextbarcode: ldlData?.TBarcode || "",
              examdateL: ldlData?.examDate || "",
              sResultL: ldlData?.sResult || "",
              diagnosisL: ldlData?.diagnosis || "",
              commentL: ldlData?.comment || "",

              examdateR: retinalExamData?.examDate || "",
              sResultR: retinalExamData?.sResult || "",
              diagnosisR: retinalExamData?.diagnosis || "",
              commentR: retinalExamData?.comment || "",

              examdateD: dexaData?.examDate || "",
              sResultD: dexaData?.sResult || "",
              diagnosisD: dexaData?.diagnosis || "",
              commentD: dexaData?.comment || "",

              examdateP: padData?.examDate || "",
              tResultLeft: padData?.tResultLeft || "",
              tResultRight: padData?.tResultRight || "",
              peripheralResultsLeft: padData?.peripheralResultsLeft || "",
              peripheralResultsRight: padData?.peripheralResultsRight || "",
              diagnosisP: padData?.diagnosis || "",
              commentP: padData?.comment || "",

              microAlbuminData: {
                microalbumintestbarcode: microAlbuminData?.TBarcode || "",
                examdateM: microAlbuminData?.examDate || "",
                sResultM: microAlbuminData?.sResult || "",
                diagnosisM: microAlbuminData?.diagnosis || "",
                commentM: microAlbuminData?.comment || "",
              },
              fobt: fobt || "",
              fobtData: {
                fobttestbarcode: fobtData?.TBarcode || "",
                examDateF: fobtData?.examDate || "",
                sResultF: fobtData?.sResult || "",
                diagnosisF: fobtData?.diagnosis || "",
                commentF: fobtData?.comment || "",
              },
              a1c: aOneC || "",
              aOneCData: {
                a1ctestbarcode: aOneCData?.TBarcode || "",
                examdateA: aOneCData?.examDate || "",
                sResultA: aOneCData?.sResult || "",
                diagnosisA: aOneCData?.diagnosis || "",
                commentA: aOneCData?.comment || "",
              },
              ldl: ldl || "",
              ldlData: {
                ldltextbarcode: ldlData?.TBarcode || "",
                examDateL: ldlData?.examDate || "",
                sResultL: ldlData?.sResult || "",
                diagnosisL: ldlData?.diagnosis || "",
                commentL: ldlData?.comment || "",
              },
              retinal_eye_exam: retinalExam || "",
              retinalExamData: {
                examDateR: retinalExamData?.examDate || "",
                sResultR: retinalExamData?.sResult || "",
                diagnosisR: retinalExamData?.diagnosis || "",
                commentR: retinalExamData?.comment || "",
              },
              dexa: dexa || "",
              dexaData: {
                examDateD: dexaData?.examDate || "",
                sResultD: dexaData?.sResult || "",
                diagnosisD: dexaData?.diagnosis || "",
                commentD: dexaData?.comment || "",
              },
              pad: pad || "",
              padData: {
                examDateP: padData?.examDate || "",
                tResultLeft: padData?.tResultLeft || "",
                tResultRight: padData?.tResultRight || "",
                peripheralResultsLeft: padData?.peripheralResultsLeft || "",
                peripheralResultsRight: padData?.peripheralResultsRight || "",
                diagnosisP: padData?.diagnosis || "",
                commentP: padData?.comment || "",
              },
            };
          }

          if (previouslyDBData && Object.keys(previouslyDBData).length > 0) {
            const previouslyDocumentedConditions =
              assessmentDataFromAPI.data.data.previously;

            if (previouslyDocumentedConditions) {
              const viewSubAnswers =
                previouslyDocumentedConditions?.data?.viewSubAnswers;

              setPreviouslyDocumentedConditions(viewSubAnswers);

              const tableRowData = viewSubAnswers?.map((subAnswer) => ({
                diagnosisCode: subAnswer?.diagnosisCode || "",
                dateOfService: subAnswer?.dateOfService || "",
                diagnosis: subAnswer?.diagnosis || "",
                priorHcc: subAnswer?.priorHcc || "",
              }));

              setTableRowData(tableRowData);
            }

            let mappedRows;
            const previouslyDocumentedConditionsData = tableRowData;

            let columns;

            const previouslyDocumentedConditionsSection = rowsData?.find(
              (section) => section.label === "Previously Documented Conditions",
            );

            if (previouslyDocumentedConditionsSection) {
              columns = previouslyDocumentedConditionsSection.columns;

              if (columns) {
                mappedRows = previouslyDocumentedConditionsData?.map(
                  (rowData, rowIndex) => {
                    const mappedRow = columns?.map((column) => {
                      const value = rowData[column.label] || "";

                      return {
                        label: column.label,
                        value: value,
                        type: column.type,
                      };
                    });

                    return mappedRow;
                  },
                );
              }
            }

            dbFormData = {
              ...dbFormData,
              previouslyDocumentedConditionsData,
            };
          }

          if (painDBData && Object.keys(painDBData).length > 0) {
            //pain
            const patientExpPainValue =
              painDBData.patientExperiencePain.isPatientExperiencePain;
            const patientExpPainKey =
              patientExpPainValue === "1" ? "Yes" : "No";
            const patientExperiencePain = patientExpPainKey || "";

            const painAcuteValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painAcute.isPainAcute;
            const painAcuteKey = painAcuteValue === "1" ? "Yes" : "No";
            const painAcute = painAcuteKey || "";

            const painChronicValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronic;
            const painChronicKey = painChronicValue === "1" ? "Yes" : "No";
            const painChronic = painChronicKey || "";

            const isPainChronicDescribeSubAnswerKeys = Object.keys(
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronicSubAnswer.describe,
            );

            const selectedDescribeActiveOptionKey =
              isPainChronicDescribeSubAnswerKeys.find((key) => {
                const value =
                  painDBData.patientExperiencePain
                    .isPatientExperiencePainSubAnswer.painChronic
                    .isPainChronicSubAnswer.describe[key];

                return value === "1";
              });

            const pain_describe = selectedDescribeActiveOptionKey;

            const isPainChronicFrequencySubAnswerKeys = Object.keys(
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronicSubAnswer.frequency,
            );

            const selectedFrequencyOptionKey =
              isPainChronicFrequencySubAnswerKeys.find((key) => {
                const value =
                  painDBData.patientExperiencePain
                    .isPatientExperiencePainSubAnswer.painChronic
                    .isPainChronicSubAnswer.frequency[key];

                return value === "1";
              });
            const frequency = selectedFrequencyOptionKey;

            const painManagementValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painManagementTiming.isPainManagementTiming;
            const painManagementKey =
              painManagementValue === "1" ? "Yes" : "No";
            const painManagementTiming = painManagementKey || "";

            const patientRespondingValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painManagementTiming.isPainManagementTimingSubAnswer
                .isPatientResponding.isPatientResponding;
            const patientRespondingKey =
              patientRespondingValue === "1" ? "Yes" : "No";
            const isPatientResponding = patientRespondingKey || "";

            const opioidMedicationValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedication;
            const opioidMedicationKey =
              opioidMedicationValue === "1" ? "Yes" : "No";
            const opioidMedication = opioidMedicationKey || "";

            const opioidMedicationSubAnswerToleranceValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.tolerance
                .isTolerance;
            const opioidMedicationSubAnswerToleranceKey =
              opioidMedicationSubAnswerToleranceValue === "1" ? "Yes" : "No";
            const tolerance = opioidMedicationSubAnswerToleranceKey || "";

            const opioidMedicationSubAnswerWithdrawalValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.withdrawal
                .isWithdrawal;
            const opioidMedicationSubAnswerWithdrawalKey =
              opioidMedicationSubAnswerWithdrawalValue === "1" ? "Yes" : "No";
            const withdrawal = opioidMedicationSubAnswerWithdrawalKey || "";

            const opioidMedicationSubAnswerIncreasedUsageValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.increasedUsage
                .isIncreasedUsage;
            const opioidMedicationSubAnswerIncreasedUsageKey =
              opioidMedicationSubAnswerIncreasedUsageValue === "1"
                ? "Yes"
                : "No";
            const increasedUsage =
              opioidMedicationSubAnswerIncreasedUsageKey || "";

            const opioidMedicationSubAnswerUnsuccessEffortValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.unsuccessEffort
                .isUnsuccessEffort;
            const opioidMedicationSubAnswerUnsuccessEffortKey =
              opioidMedicationSubAnswerUnsuccessEffortValue === "1"
                ? "Yes"
                : "No";
            const unsuccessEffort =
              opioidMedicationSubAnswerUnsuccessEffortKey || "";

            const opioidMedicationSubAnswerExcessTimeSpentValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.excessTimeSpent
                .isExcessTimeSpent;
            const opioidMedicationSubAnswerExcessTimeSpentKey =
              opioidMedicationSubAnswerExcessTimeSpentValue === "1"
                ? "Yes"
                : "No";
            const excessTimeSpent =
              opioidMedicationSubAnswerExcessTimeSpentKey || "";

            const opioidMedicationSubAnswerPatientKnowledgeValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.patientKnowledge
                .isPatientKnowledge;
            const opioidMedicationSubAnswerPatientKnowledgeKey =
              opioidMedicationSubAnswerPatientKnowledgeValue === "1"
                ? "Yes"
                : "No";
            const patientKnowledge =
              opioidMedicationSubAnswerPatientKnowledgeKey || "";

            const opioidMedicationSubAnswerPsychologicalProblemValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer
                .psychologicalProblem.isPsychologicalProblem;
            const opioidMedicationSubAnswerPsychologicalProblemKey =
              opioidMedicationSubAnswerPsychologicalProblemValue === "1"
                ? "Yes"
                : "No";
            const psychologicalProblem =
              opioidMedicationSubAnswerPsychologicalProblemKey || "";

            const patientAdvisedValue =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .patientAdvised.isPatientAdvised;
            const patientAdvisedKey =
              patientAdvisedValue === "1" ? "Yes" : "No";
            const patientAdvised = patientAdvisedKey || "";

            const painChronicWhereText =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronicSubAnswer.where;

            const whereSubtext =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronicSubAnswer.where;
            const rateYourPainSubtext =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronicSubAnswer.rateYourPain;
            const drugsTakeSubtext =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.drugsTake;

            const patientExperiencePainComment =
              painDBData.patientExperiencePain.comment;
            const opioidMedicationComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.comment;
            const painAcuteComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painAcute.comment;
            const painChronicComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.comment;
            const painManagementTimingComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painManagementTiming.comment;
            const patientAdvisedComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .patientAdvised.comment;
            const describeComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronicSubAnswer.describe.comment;
            const frequencyComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painChronic.isPainChronicSubAnswer.frequency.comment;
            const isPatientRespondingComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .painManagementTiming.isPainManagementTimingSubAnswer
                .isPatientResponding.comment;
            const toleranceComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.tolerance.comment;
            const withdrawalComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.withdrawal
                .comment;
            const increasedUsageComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.increasedUsage
                .comment;
            const unsuccessEffortComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.unsuccessEffort
                .comment;
            const excessTimeSpentComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.excessTimeSpent
                .comment;
            const patientKnowledgeComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer.patientKnowledge
                .comment;
            const psychologicalProblemComment =
              painDBData.patientExperiencePain.isPatientExperiencePainSubAnswer
                .opioidMedication.isOpioidMedicationSubAnswer
                .psychologicalProblem.comment;

            dbFormData = {
              ...dbFormData,
              // pain
              painChronicWhereText: painChronicWhereText || "",
              whereSubtext: whereSubtext || "",
              rateYourPainSubtext: rateYourPainSubtext || "",
              drugsTakeSubtext: drugsTakeSubtext || "",
              patientExperiencePainComment: patientExperiencePainComment || "",
              opioidMedicationComment,
              painAcuteComment,
              painChronicComment,
              painManagementTimingComment,
              patientAdvisedComment,
              describeComment,
              frequencyComment,
              isPatientRespondingComment,
              toleranceComment,
              withdrawalComment,
              increasedUsageComment,
              unsuccessEffortComment,
              excessTimeSpentComment,
              patientKnowledgeComment,
              psychologicalProblemComment,
              patientExperiencePain: patientExperiencePain || "",
              painAcute: painAcute || "",
              painChronic: painChronic || "",
              pain_describe,
              frequency: frequency || "",
              painManagementTiming: painManagementTiming || "",
              isPatientResponding: isPatientResponding || "",
              opioidMedication: opioidMedication || "",
              tolerance: tolerance || "",
              withdrawal: withdrawal || "",
              increasedUsage: increasedUsage || "",
              unsuccessEffort: unsuccessEffort || "",
              excessTimeSpent: excessTimeSpent || "",
              patientKnowledge: patientKnowledge || "",
              psychologicalProblem: psychologicalProblem || "",
              patientAdvised: patientAdvised || "",
            };
          }

          if (vitalDBData && Object.keys(vitalDBData).length > 0) {
            // vital signs
            const pulse = vitalDBData.vitalSigns.pulse
              ? vitalDBData.vitalSigns.pulse
              : 1;
            const respiratoryRate = vitalDBData.vitalSigns.respiratoryRate;
            const temp = vitalDBData.vitalSigns.temp;
            const pulseOximetry = vitalDBData.vitalSigns.pulseOximetry;
            const painScale = vitalDBData.vitalSigns.painScale;
            const feet = vitalDBData.bmi.patientsHeight.feet;
            const inch = vitalDBData.bmi.patientsHeight.inch;
            const patientsWeight = vitalDBData.bmi.patientsWeight;
            const bmii = vitalDBData.bmi.bmi;
            const diastolicbp =
              vitalDBData.vitalSigns.bloodPressure.diastolicbp;
            const systolicbp = vitalDBData.vitalSigns.bloodPressure.systolicbp;

            const bmiKeys = Object.keys(vitalDBData.bmi);

            const selectedBmiOptionKey = bmiKeys.find((key) => {
              const value = vitalDBData.bmi[key];

              // Check if the value is "1"
              return value === "1";
            });
            const bmi = selectedBmiOptionKey;

            const specialDietKeys = Object.keys(vitalDBData.specialDiet);

            const selectedSpecialDietOptionKey = specialDietKeys.filter(
              (key) => {
                const value = vitalDBData.specialDiet[key];

                // Include options where the value is "1"
                return value === "1";
              },
            );
            const specialDiet = selectedSpecialDietOptionKey;

            const lostWeightKeys = Object.keys(vitalDBData.lostWeight);

            const selectedLostWeightOptionKey = lostWeightKeys.find((key) => {
              const value = vitalDBData.lostWeight[key];

              // Check if the value is "1"
              return value === "1";
            });
            const lostWeight = selectedLostWeightOptionKey;

            const recommendationsSubAnswers =
              vitalDBData.recommendations.recommendationsSubAnswers;

            // Modify the code to set initial values to '1' for pre-checked checkboxes
            const formDataSubAnswers = recommendationsSubAnswers.map(
              (subAnswer) => {
                const { ...formattedSubAnswer } = subAnswer;

                return formattedSubAnswer;
              },
            );

            const nutritionManagementComment = recommendationsSubAnswers
              .filter(
                (subAnswer) =>
                  subAnswer.nutritionManagement === "1" ||
                  subAnswer.nutritionManagement === "",
              )
              .map((subAnswer) => subAnswer.comment);

            const otherComment = recommendationsSubAnswers
              .filter(
                (subAnswer) =>
                  subAnswer.other === "1" || subAnswer.other === "",
              )
              .map((subAnswer) => subAnswer.comment);

            const malnutritionSubAnswerKeys = Object.keys(
              vitalDBData.bmi.malnutritionSubAnswer.describe,
            );

            const selectedActiveOptionKey = malnutritionSubAnswerKeys.find(
              (key) => {
                const value =
                  vitalDBData.bmi.malnutritionSubAnswer.describe[key];

                return value === "1";
              },
            );

            const describe = selectedActiveOptionKey;

            const malnutritionValue =
              vitalDBData.bmi.malnutritionSubAnswer.malnutrition.isMalnutrition;
            const malnutritionKey = malnutritionValue === "1" ? "Yes" : "No";
            const malnutrition = malnutritionKey || "";
            // const isMalnutrition =
            //   vitalDBData.bmi.malnutritionSubAnswer.malnutrition.isMalnutrition;

            // let malnutritionSubAnswer = "";
            // if (isMalnutrition === "1") {
            //   malnutritionSubAnswer = "Yes";
            // } else if (isMalnutrition === "2") {
            //   malnutritionSubAnswer = "";
            // }

            const supportedByKeys = Object.keys(
              vitalDBData.bmi.malnutritionSubAnswer.supportedBy,
            );

            const selectedSupportedByOptionKey = supportedByKeys.find((key) => {
              const value =
                vitalDBData.bmi.malnutritionSubAnswer.supportedBy[key];

              return value === "1";
            });
            const supportedBy = selectedSupportedByOptionKey;

            const lostWeightComment = vitalDBData.lostWeight.comment;
            const bmiComment = vitalDBData.bmi.comment;
            const specialDietComment = vitalDBData.specialDiet.comment;
            const vitalSignsComment = vitalDBData.vitalSigns.comment;
            const describeComment =
              vitalDBData.bmi.malnutritionSubAnswer.describe.comment;
            const malnutritionComment =
              vitalDBData.bmi.malnutritionSubAnswer.malnutrition.comment;
            const supportedByComment =
              vitalDBData.bmi.malnutritionSubAnswer.supportedBy.comment;

            const describeSubtext =
              vitalDBData?.specialDiet?.otherSubAnswer?.describe;

            dbFormData = {
              // vital signs
              ...dbFormData,
              pulse,
              respiratoryRate,
              temp,
              pulseOximetry,
              painScale,
              feet,
              inch,
              patientsWeight,
              bmi,
              diastolicbp,
              systolicbp,
              bmii,
              describeSubtext: describeSubtext || "",
              describe: describe || "",
              specialDiet: specialDiet || "",
              lostWeight: lostWeight || "",
              recommendationsSubAnswers: formDataSubAnswers,
              malnutrition: malnutrition,
              supportedBy: supportedBy || "",
              lostWeightComment: lostWeightComment || "",
              bmiComment: bmiComment || "",
              specialDietComment: specialDietComment || "",
              vitalSignsComment: vitalSignsComment || "",
              describeComment: describeComment || "",
              malnutritionComment,
              supportedByComment,
              nutritionManagementComment: nutritionManagementComment || "",
              otherComment: otherComment || "",
            };
          }

          if (covidDBData && Object.keys(covidDBData).length > 0) {
            //covid screening

            const travelInternationalValue =
              covidDBData.lastFourteenDays.travelInternational
                .travelInternational;
            const travelInternationalValueKey =
              travelInternationalValue === "1"
                ? "Yes"
                : travelInternationalValue === "2"
                ? "No"
                : "";
            const travelInternational = travelInternationalValue
              ? travelInternationalValueKey
              : "";

            const dignosedWithCoronaValue =
              covidDBData.lastFourteenDays.dignosedWithCorona
                .dignosedWithCorona;
            const dignosedWithCoronaKey =
              dignosedWithCoronaValue === "1"
                ? "Yes"
                : dignosedWithCoronaValue === "2"
                ? "No"
                : "";
            const dignosedWithCorona = dignosedWithCoronaValue
              ? dignosedWithCoronaKey
              : "";

            const closeContactValue =
              covidDBData.lastFourteenDays.closeContact.closeContact;
            const closeContactKey =
              closeContactValue === "1"
                ? "Yes"
                : closeContactValue === "2"
                ? "No"
                : "";
            const closeContact = closeContactValue ? closeContactKey : "";

            const developFeverValue =
              covidDBData.lastFourteenDays.developFever.developFever;
            const developFeverKey =
              developFeverValue === "1"
                ? "Yes"
                : developFeverValue === "2"
                ? "No"
                : "";
            const developFever = developFeverValue ? developFeverKey : "";

            const developCoughValue =
              covidDBData.lastFourteenDays.developCough.developCough;
            const developCoughKey =
              developCoughValue === "1"
                ? "Yes"
                : developCoughValue === "2"
                ? "No"
                : "";
            const developCough = developCoughValue ? developCoughKey : "";

            const developSymptomsValue =
              covidDBData.lastFourteenDays.developSymptoms.developSymptoms;
            const developSymptomsKey =
              developSymptomsValue === "1"
                ? "Yes"
                : developSymptomsValue === "2"
                ? "No"
                : "";
            const developSymptoms = developSymptomsValue
              ? developSymptomsKey
              : "";

            const developBreathValue =
              covidDBData.lastFourteenDays.developBreath.developBreath;
            const developBreathKey =
              developBreathValue === "1"
                ? "Yes"
                : developBreathValue === "2"
                ? "No"
                : "";
            const developBreath = developBreathValue ? developBreathKey : "";

            const closeContactComment =
              covidDBData.lastFourteenDays.closeContact.comment;

            const developBreathComment =
              covidDBData.lastFourteenDays.developBreath.comment;
            const developCoughComment =
              covidDBData.lastFourteenDays.developCough.comment;
            const developFeverComment =
              covidDBData.lastFourteenDays.developFever.comment;
            const developSymptomsComment =
              covidDBData.lastFourteenDays.developSymptoms.comment;
            const dignosedWithCoronaComment =
              covidDBData.lastFourteenDays.dignosedWithCorona.comment;
            const travelInternationalComment =
              covidDBData.lastFourteenDays.travelInternational.comment;

            dbFormData = {
              // covid screening
              ...dbFormData,
              travelInternational,
              dignosedWithCorona,
              closeContact,
              developFever,
              developCough,
              developSymptoms,
              developBreath,
              closeContactComment,
              developBreathComment,
              developCoughComment,
              developFeverComment,
              developSymptomsComment,
              dignosedWithCoronaComment,
              travelInternationalComment,
            };
          }

          if (patientDBData && Object.keys(patientDBData).length > 0) {
            //patient summary
            const acceptDisclosureValue = patientDBData?.acceptDisclosure || "";

            const memberAknowledgementValue =
              patientDBData?.memberAknowledgement || "";
            const consentedToVideoValue =
              patientDBData?.visitType?.consentedToVideo || "";
            const assessorsComments = patientDBData?.assessorsComments || "";

            // const _evaluationDate = patientDBData?.evaluationDate;
            const _examFinishDate = patientDBData?.examFinishDate;
            const finalDate = sessionStorage.getItem("finalDate");
            // if (_evaluationDate?.toString().includes("T")) {
            //   setEvaluationDate(_evaluationDate);
            //   setPreviousEvaluationDateTime(_evaluationDate);
            // }
            //  else
            if (finalDate?.toString()?.includes("T")) {
              setEvaluationDate(finalDate);
              setPreviousEvaluationDateTime(finalDate);
            } else {
              setEvaluationDate(null);
            }
            if (_examFinishDate?.toString().includes("T")) {
              setExamFinishDate(_examFinishDate);
            } else {
              setExamFinishDate(null);
            }
            setProviderSignAllDashboard(data?.patient?.providerSignature);
            // Convert the date strings to Date objects
            // const parsedEvaluationDate = evaluationDate
            //   ? new Date(evaluationDate)
            //   : null;

            // const parsedExamFinishDate = examFinishDate
            //   ? new Date(examFinishDate)
            //   : null;

            const visitTypeData = patientDBData.visitType || {};

            if (visitTypeData.isVisitType === "2") {
              dbFormData = {
                ...dbFormData,
                visitType: "virtual",
              };
            } else if (visitTypeData.isVisitType === "1") {
              dbFormData = {
                ...dbFormData,
                visitType: "inPerson",
              };
            }

            dbFormData = {
              // patient summary
              ...dbFormData,
              acceptDisclosure: acceptDisclosureValue || "",
              memberAknowledgement: memberAknowledgementValue,
              consentedToVideo: consentedToVideoValue,
              assessorsComments: assessorsComments || "",
              evaluationDate: finalDate?.toString().includes("T")
                ? finalDate
                : // : finalDate?.toString()?.includes("T")
                  // ? finalDate
                  null,
              examFinishDate: _examFinishDate?.toString().includes("T")
                ? _examFinishDate
                : null,
            };
          }

          if (activitiesDBData && Object.keys(activitiesDBData).length > 0) {
            //activities of daily living
            const gettingInOrOutOfBedKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.gettingInOrOutOfBed,
            );
            const selectedGettingInOrOutOfBedOptionKey =
              gettingInOrOutOfBedKeys.find((key) => {
                const value =
                  activitiesDBData.difficultyWithActivities.gettingInOrOutOfBed[
                    key
                  ];

                return value === "1";
              });
            const gettingInOrOutOfBed = selectedGettingInOrOutOfBedOptionKey;

            const gettingInOrOutOfChairsKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.gettingInOrOutOfChairs,
            );
            const selectedGettingInOrOutOfChairsOptionKey =
              gettingInOrOutOfChairsKeys.find((key) => {
                const value =
                  activitiesDBData.difficultyWithActivities
                    .gettingInOrOutOfChairs[key];

                return value === "1";
              });
            const gettingInOrOutOfChairs =
              selectedGettingInOrOutOfChairsOptionKey;

            const toiletingKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.toileting,
            );
            const selectedToiletingOptionKey = toiletingKeys.find((key) => {
              const value =
                activitiesDBData.difficultyWithActivities.toileting[key];

              return value === "1";
            });
            const toileting = selectedToiletingOptionKey;

            const bathingKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.bathing,
            );
            const selectedBathingOptionKey = bathingKeys.find((key) => {
              const value =
                activitiesDBData.difficultyWithActivities.bathing[key];

              return value === "1";
            });
            const bathing = selectedBathingOptionKey;

            const dressingKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.dressing,
            );
            const selectedDressingOptionKey = dressingKeys.find((key) => {
              const value =
                activitiesDBData.difficultyWithActivities.dressing[key];

              return value === "1";
            });
            const dressing = selectedDressingOptionKey;

            const eatingKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.eating,
            );
            const selectedEatingOptionKey = eatingKeys.find((key) => {
              const value =
                activitiesDBData.difficultyWithActivities.eating[key];

              return value === "1";
            });
            const eating = selectedEatingOptionKey;

            const walkingKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.walking,
            );
            const selectedWalkingOptionKey = walkingKeys.find((key) => {
              const value =
                activitiesDBData.difficultyWithActivities.walking[key];

              return value === "1";
            });
            const walking = selectedWalkingOptionKey;

            const needSomeHelpSubAnswersKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.walking
                .needSomeHelpSubAnswers,
            );
            const selectedNeedSomeHelpSubAnswersOptionKey =
              needSomeHelpSubAnswersKeys.find((key) => {
                const value =
                  activitiesDBData.difficultyWithActivities.walking
                    .needSomeHelpSubAnswers[key];

                return value === "1";
              });
            const walk_needSomeHelpSubAnswers =
              selectedNeedSomeHelpSubAnswersOptionKey;

            const neededTotalHelpSubAnswerKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.walking
                .neededTotalHelpSubAnswer,
            );
            const selectedNeededTotalHelpSubAnswerOptionKey =
              neededTotalHelpSubAnswerKeys.find((key) => {
                const value =
                  activitiesDBData.difficultyWithActivities.walking
                    .neededTotalHelpSubAnswer[key];

                return value === "1";
              });
            const walk_neededTotalHelpSubAnswer =
              selectedNeededTotalHelpSubAnswerOptionKey;

            const goingUpOrDownStairsKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.goingUpOrDownStairs,
            );
            const selectedGoingUpOrDownStairsOptionKey =
              goingUpOrDownStairsKeys.find((key) => {
                const value =
                  activitiesDBData.difficultyWithActivities.goingUpOrDownStairs[
                    key
                  ];

                return value === "1";
              });
            const goingUpOrDownStairs = selectedGoingUpOrDownStairsOptionKey;

            const stairsNeedSomeHelpSubAnswersKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.goingUpOrDownStairs
                .needSomeHelpSubAnswers,
            );
            const selectedStairsNeedSomeHelpSubAnswersOptionKey =
              stairsNeedSomeHelpSubAnswersKeys.find((key) => {
                const value =
                  activitiesDBData.difficultyWithActivities.goingUpOrDownStairs
                    .needSomeHelpSubAnswers[key];

                return value === "1";
              });
            const stairs_needSomeHelpSubAnswers =
              selectedStairsNeedSomeHelpSubAnswersOptionKey;

            const stairsNeededTotalHelpSubAnswerKeys = Object.keys(
              activitiesDBData.difficultyWithActivities.goingUpOrDownStairs
                .neededTotalHelpSubAnswer,
            );
            const selectedStairsNeededTotalHelpSubAnswerOptionKey =
              stairsNeededTotalHelpSubAnswerKeys.find((key) => {
                const value =
                  activitiesDBData.difficultyWithActivities.goingUpOrDownStairs
                    .neededTotalHelpSubAnswer[key];

                return value === "1";
              });
            const stairs_neededTotalHelpSubAnswer =
              selectedStairsNeededTotalHelpSubAnswerOptionKey;

            const bathingComment =
              activitiesDBData.difficultyWithActivities.bathing.comment;
            const dressingComment =
              activitiesDBData.difficultyWithActivities.dressing.comment;
            const eatingComment =
              activitiesDBData.difficultyWithActivities.eating.comment;
            const gettingInOrOutOfBedComment =
              activitiesDBData.difficultyWithActivities.gettingInOrOutOfBed
                .comment;
            const gettingInOrOutOfChairsComment =
              activitiesDBData.difficultyWithActivities.gettingInOrOutOfChairs
                .comment;
            const toiletingComment =
              activitiesDBData.difficultyWithActivities.toileting.comment;
            const walkingComment =
              activitiesDBData.difficultyWithActivities.walking.comment;

            const walk_needSomeHelpSubAnswersComment =
              activitiesDBData.difficultyWithActivities.walking
                .needSomeHelpSubAnswers.comment;

            const walk_neededTotalHelpSubAnswerComment =
              activitiesDBData.difficultyWithActivities.walking
                .neededTotalHelpSubAnswer.comment;

            const goingUpOrDownStairsComment =
              activitiesDBData.difficultyWithActivities.goingUpOrDownStairs
                .comment;

            const stairs_needSomeHelpSubAnswersComment =
              activitiesDBData.difficultyWithActivities.goingUpOrDownStairs
                .needSomeHelpSubAnswers.comment;
            const stairs_neededTotalHelpSubAnswerComment =
              activitiesDBData.difficultyWithActivities.goingUpOrDownStairs
                .neededTotalHelpSubAnswer.comment;

            function getSelectedOptions(activityType) {
              const needSomeHelpSubAnswersKeys = Object.keys(
                activitiesDBData.difficultyWithActivities[activityType]
                  .needSomeHelpSubAnswers,
              );
              const selectedNeedSomeHelpSubAnswersOptionKey =
                needSomeHelpSubAnswersKeys.find((key) => {
                  const value =
                    activitiesDBData.difficultyWithActivities[activityType]
                      .needSomeHelpSubAnswers[key];
                  return value === "1";
                });

              const neededTotalHelpSubAnswerKeys = Object.keys(
                activitiesDBData.difficultyWithActivities[activityType]
                  .neededTotalHelpSubAnswer,
              );
              const selectedNeededTotalHelpSubAnswerOptionKey =
                neededTotalHelpSubAnswerKeys.find((key) => {
                  const value =
                    activitiesDBData.difficultyWithActivities[activityType]
                      .neededTotalHelpSubAnswer[key];
                  return value === "1";
                });

              return {
                needSomeHelpSubAnswers: selectedNeedSomeHelpSubAnswersOptionKey,
                neededTotalHelpSubAnswer:
                  selectedNeededTotalHelpSubAnswerOptionKey,
              };
            }

            const walkingSelectedOptions = getSelectedOptions("walking");
            const goingUpOrDownStairsSelectedOptions = getSelectedOptions(
              "goingUpOrDownStairs",
            );

            const needSomeHelpSubAnswers =
              walkingSelectedOptions.needSomeHelpSubAnswers ||
              goingUpOrDownStairsSelectedOptions.needSomeHelpSubAnswers;
            const neededTotalHelpSubAnswer =
              walkingSelectedOptions.neededTotalHelpSubAnswer ||
              goingUpOrDownStairsSelectedOptions.neededTotalHelpSubAnswer;

            dbFormData = {
              ...dbFormData,
              stairs_needSomeHelpSubAnswersComment,
              walk_needSomeHelpSubAnswersComment,
              walk_neededTotalHelpSubAnswerComment,
              stairs_neededTotalHelpSubAnswerComment,
              gettingInOrOutOfBed: gettingInOrOutOfBed || "",
              gettingInOrOutOfChairs: gettingInOrOutOfChairs || "",
              toileting: toileting || "",
              bathing: bathing || "",
              dressing: dressing || "",
              eating: eating || "",
              walking: walking || "",
              walk_needSomeHelpSubAnswers,
              walk_neededTotalHelpSubAnswer,
              stairs_needSomeHelpSubAnswers,
              stairs_neededTotalHelpSubAnswer,
              needSomeHelpSubAnswers: needSomeHelpSubAnswers || "",
              neededTotalHelpSubAnswer: neededTotalHelpSubAnswer || "",
              goingUpOrDownStairs: goingUpOrDownStairs || "",
              bathingComment,
              dressingComment,
              eatingComment,
              gettingInOrOutOfBedComment,
              gettingInOrOutOfChairsComment,
              toiletingComment,
              walkingComment,
              goingUpOrDownStairsComment,
            };
          }

          if (familyDBData && Object.keys(familyDBData).length > 0) {
            const familyHistoryValue =
              familyDBData?.familyHistory?.isFamilyHistory;

            const familyHistoryKey = familyHistoryValue == "1" ? "yes" : "no";

            const familyHistory = familyHistoryKey || "";
            const familyHistoryComment = familyDBData?.familyHistory?.comment;

            // const familyHistory = assessmentDataFromAPI.data.data.family;

            // const isFamilyHistory =
            //   familyHistory.data.familyHistory.isFamilyHistory == "1"
            //     ? "yes"
            //     : "";

            const family = assessmentDataFromAPI?.data?.data?.family;

            setFamData(family?.data);

            if (family) {
              const familyHistorySubAnswers =
                family?.data?.familyHistory?.familyHistorySubAnswers;

              setFamily(familyHistorySubAnswers);

              const familyRowData = familyHistorySubAnswers?.map(
                (subAnswer) => ({
                  familyMember: subAnswer?.familyMember || "",
                  medicalCondition: subAnswer?.medicalCondition || "",
                  causeofDeath: subAnswer?.causeofDeath || "",
                }),
              );

              setFamilyRowData(familyRowData);
            }

            let mappedFamilyRows;

            const familyData = familyRowData;

            const familySection = rowsData?.find(
              (section) => section?.name === "family_history",
            );

            if (familySection) {
              const familyColumns = familySection?.columns;

              if (familyColumns) {
                mappedFamilyRows = familyData?.map((rowData, rowIndex) => {
                  const mappedRow = familyColumns?.map((column) => {
                    const value = rowData[column.label] || "";
                    return {
                      label: column?.label,
                      value: value,
                      type: column?.type,
                    };
                  });

                  return mappedRow;
                });
              } else {
              }
            } else {
            }

            dbFormData = {
              ...dbFormData,
              // family
              familyHistory: familyHistory || "",

              familyHistoryComment,
            };
          }

          if (medicalDBData && Object.keys(medicalDBData).length > 0) {
            const medical = assessmentDataFromAPI?.data?.data?.medical;

            const getYesData = medical?.data;
            setGetData(getYesData);

            if (medical && medical?.data?.specialists?.isSpecialists == 1) {
              if (medical?.data?.specialists) {
                const specialistsSubAnswers =
                  medical.data?.specialists?.specialistsSubAnswers;

                setMedical(specialistsSubAnswers);

                const medicalRowData = specialistsSubAnswers?.map(
                  (subAnswer) => ({
                    medicalSp: subAnswer?.medicalSp || "",
                    specialist: subAnswer?.specialist || "",
                    for: subAnswer?.for || "",
                    // id: index.toString(),
                  }),
                );

                setMedicalRowData(medicalRowData);
              } else {
              }
            } else {
            }

            let mappedMedicalRows;
            const medicalData = tableRowData;

            let medicalColumns;

            const medicalSection = rowsData?.find(
              (section) => section?.label === "Medical History Specialists",
            );

            if (medicalSection) {
              medicalColumns = medicalSection?.columns;

              if (medicalColumns) {
                mappedMedicalRows = medicalData?.map((rowData, rowIndex) => {
                  const mappedMedicalRows = medicalColumns?.map((column) => {
                    const value = rowData[column?.label] || "";

                    return {
                      label: column?.label,
                      value: value,
                      type: column?.type,
                    };
                  });

                  return mappedMedicalRows;
                });
              } else {
              }
            } else {
            }

            const isSpecHistoryValue =
              medicalDBData?.specialists?.isSpecialists;
            const medicalHistoryKey = isSpecHistoryValue == "1" ? "yes" : "no";
            const medicalisSpecHistory = medicalHistoryKey || "";
            const areYouCurrentlySeeingAnySpeciaComment =
              medicalDBData?.specialists?.comment;

            const aSeenYourPcpComment =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.pcp
                ?.comment;

            const eHadSurgeryComment =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.hadSurgery?.comment;

            const dBeenInANursingHomeComment =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.nursingHome?.comment;

            const cStayedInTheHospitalOvernightComment =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.hospitalOvernight?.comment;

            const bVisitedTheEmergencyRoomComment =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.emergencyRoom?.comment;

            const dyuaadoDMEComment = medicalDBData?.assistiveDevices?.comment;
            const assistiveSubComment =
              medicalDBData?.assistiveDevices?.subAnswers?.comment;
            const oxygenDescribeComment =
              medicalDBData?.assistiveDevices?.subAnswers?.oxygenSubAnsweres
                ?.describe?.comment;

            //  const assistiveDevicesValue = medicalDBData?.assistiveDevices?.isNone;
            //   const assistiveDevicesKey =
            //     assistiveDevicesValue == "1" ? "yes" : "no";
            //   const assistiveDevicesIsNone = assistiveDevicesKey || "";

            //   const assistiveDevicesSubAnswers =
            //     medicalDBData?.assistiveDevices?.subAnswers || {};

            // const assistiveDevicesData = {
            //   isNone: assistiveDevicesIsNone,
            //   subAnswers: {
            //     isCane: assistiveDevicesSubAnswers?.isCane == 1 ? "cane" : "",
            //     isWalker:
            //       assistiveDevicesSubAnswers?.isWalker == 1 ? "yes" : "no",
            //     isProsthesis:
            //       assistiveDevicesSubAnswers?.isProsthesis == 1 ? "yes" : "no",
            //     isOxygen:
            //       assistiveDevicesSubAnswers?.isOxygen == 1 ? "yes" : "no",
            //     oxygenSubAnswers: {
            //       describe: {
            //         isPRN:
            //           assistiveDevicesSubAnswers?.oxygenSubAnswers?.describe?.isPRN == 1
            //           ? "yes"
            //           : "no",

            //         isContinuous:
            //           assistiveDevicesSubAnswers?.oxygenSubAnswers?.describe?.isContinuous == 1
            //           ? "yes"
            //           : "no",

            //         isDay:
            //           assistiveDevicesSubAnswers?.oxygenSubAnswers?.describe?.isDay == 1
            //           ? "yes"
            //           : "no",

            //         isNight:
            //           assistiveDevicesSubAnswers?.oxygenSubAnswers?.describe?.isNight == 1
            //           ? "yes"
            //           : "no",

            //         comment:
            //           assistiveDevicesSubAnswers?.oxygenSubAnswers?.describe?.comment || "",

            //       },
            //       Litres:
            //         assistiveDevicesSubAnswers?.oxygenSubAnswers?.Litres || "",
            //     },
            //     isWheelChair:
            //       assistiveDevicesSubAnswers?.isWheelChair == 1 ? "yes" : "no",
            //     isBedsideCommode:
            //       assistiveDevicesSubAnswers?.isBedsideCommode == 1
            //         ? "yes"
            //         : "no",
            //     isurinal:
            //       assistiveDevicesSubAnswers?.isurinal == 1 ? "yes" : "no",
            //     isBedPan:
            //       assistiveDevicesSubAnswers?.isBedPan == 1 ? "yes" : "no",
            //     isCPAP: assistiveDevicesSubAnswers?.isCPAP == 1 ? "yes" : "no",
            //     isOther: assistiveDevicesSubAnswers?.isOther == 1 ? "yes" : "no",
            //     otherSubAnswers: {
            //       describe:
            //         assistiveDevicesSubAnswers?.otherSubAnswers?.describe || "",
            //     },
            //     comment: assistiveDevicesSubAnswers?.comment || "",
            //   },
            //   comment: medicalDBData?.assistiveDevices?.comment || "",
            // };

            const assistiveDevicesValue =
              medicalDBData?.assistiveDevices?.isNone;
            const assistiveDevicesKey =
              assistiveDevicesValue == "1" ? "yes" : "no";
            const assistiveDevicesIsNone = assistiveDevicesKey || "";

            const assistiveDevicesSubAnswers =
              medicalDBData?.assistiveDevices?.subAnswers || {};

            const oxySubAnswers =
              medicalDBData?.assistiveDevices?.subAnswers?.oxygenSubAnsweres
                ?.descibe || {};

            const oxySubText =
              medicalDBData?.assistiveDevices?.subAnswers?.oxygenSubAnsweres
                ?.Litres || {};

            const selectedAssistiveValues = [];

            Object.keys(assistiveDevicesSubAnswers).forEach((deviceKey) => {
              if (assistiveDevicesSubAnswers[deviceKey] == "1") {
                selectedAssistiveValues.push(deviceKey);
              }
            });

            const oxygenSubAnswers =
              medicalDBData?.assistiveDevices?.subAnswers?.oxygenSubAnsweres ||
              {};

            const dynamicOxygenSubAnswers = {
              describe: {},
              Litres: oxygenSubAnswers?.Litres || "",
            };

            if (oxygenSubAnswers && oxygenSubAnswers?.describe) {
              Object.keys(oxygenSubAnswers?.describe)?.forEach((key) => {
                const backendValue = oxygenSubAnswers?.describe[key];

                switch (key) {
                  case "isPRN":
                    dynamicOxygenSubAnswers.describe[key] =
                      backendValue == 1 ? "prn" : "";
                    break;
                  case "isContinuous":
                    dynamicOxygenSubAnswers.describe[key] =
                      backendValue == 1 ? "continuous" : "";
                    break;
                  case "isDay":
                    dynamicOxygenSubAnswers.describe[key] =
                      backendValue == 1 ? "day" : "";
                    break;
                  case "isNight":
                    dynamicOxygenSubAnswers.describe[key] =
                      backendValue == 1 ? "night" : "";
                    break;
                  case "comment":
                    dynamicOxygenSubAnswers.describe[key] =
                      oxygenSubAnswers?.describe[key] || "";
                    break;
                  default:
                    break;
                }
              });
            }

            const selectedOxygenKey = Object.keys(
              dynamicOxygenSubAnswers?.describe,
            )?.find((key) => dynamicOxygenSubAnswers?.describe[key]);

            // const pastTwelveMonthsData = medicalDBData.pastTwelveMonths.pastTwelveMonthsSubAnswers || {};

            const pcpData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.pcp || {};
            const stayedHosData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.hospitalOvernight || {};
            const hadSurgeryData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.hadSurgery || {};
            const nursingHomeData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.nursingHome || {};
            const emergencyData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.emergencyRoom || {};

            const pcpKeysWithValueOne = Object.keys(pcpData).filter(
              (key) => pcpData[key] == "1",
            );

            const isPcpNoneValue = pcpData?.isNone == 1 ? "none" : "";

            const isPcpIsOneValue = pcpData?.isOne == 1 ? "1" : "2";
            const isPcpIsTwoValue = pcpData?.isTwo == 1 ? "1" : "2";
            const isPcpIsThreeValue = pcpData?.isThree == 1 ? "1" : "2";
            const isPcpIsFourValue = pcpData?.isFour == 1 ? "1" : "2";
            const isPcpIsFiveOrMoreValue =
              pcpData?.isFiveOrMore == 1 ? "1" : "2";

            const extractValuesForOne = (data) => {
              const keysWithValueOne = Object.keys(data)?.filter(
                (key) => data[key] === "1",
              );
              const valuesWithKeysOne = {};
              keysWithValueOne.forEach((key) => {
                valuesWithKeysOne[key] = data[key];
              });
              return valuesWithKeysOne;
            };

            const pcpValuesWithKeysOne = extractValuesForOne(pcpData);
            const emergencyValuesWithKeysOne =
              extractValuesForOne(emergencyData);

            function getSelectedKey(data, condition) {
              const keys = Object.keys(data);
              for (const key of keys) {
                const value = data[key];
                if (condition(value)) {
                  return key;
                }
              }
              return ""; // Return an empty string if no matching key is found
            }

            // const pcpKey = isPcpValue == "1" ? "yes" : "no";
            // const medicalPcp = pcpKey || "";

            // const pastTwelveMonthsData = {
            //   pcp: {
            //     isNone: pcpData.isNone == 1 ? "1" : "2",
            //     isOne: pcpData.isOne == 1 ? "2" : "2",
            //     isTwo: pcpData.isTwo == 1 ? "1" : "2",
            //     isThree: pcpData.isThree == 1 ? "1" : "2",
            //     isFour: pcpData.isFour == 1 ? "1" : "2",
            //     isFiveOrMore: pcpData.isFiveOrMore ==1 ? "1" : "2",
            //     // comment: pcpData.comment || "",
            //   },
            const pastTwelveMonthsData = {
              pastTwelveMonths: {
                pastTwelveMonthsSubAnswers: {
                  pcp: {
                    isNone: isPcpNoneValue,
                    isOne: isPcpIsOneValue,
                    isTwo: isPcpIsTwoValue,
                    isThree: isPcpIsThreeValue,
                    isFour: isPcpIsFourValue,
                    isFiveOrMore: isPcpIsFiveOrMoreValue,
                    pcpKeysWithValueOne,
                    comment:
                      medicalDBData?.pastTwelveMonths
                        ?.pastTwelveMonthsSubAnswers?.pcp?.comment || "",
                  },
                },
              },
            };

            // ... (your existing code)

            const emergencyRoomData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.emergencyRoom || {};

            const emergencyRoomOneDescription =
              emergencyRoomData?.isOneSubAnswers?.describe || "";

            const emergencyRoomTwoDescription =
              emergencyRoomData?.isTwoSubAnswers?.describe || "";

            const emergencyRoomThreeDescription =
              emergencyRoomData?.isThreeSubAnswers?.describe || "";

            const emergencyRoomFourDescription =
              emergencyRoomData?.isFourSubAnswers?.describe || "";

            const emergencyRoomFiveOrMoreDescription =
              emergencyRoomData?.isFiveOrMoreSubAnswers?.describe || "";

            const overData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.hospitalOvernight || {};

            const overOneDescription =
              overData?.isOneSubAnswers?.describe || "";

            const overTwoDescription =
              overData?.isTwoSubAnswers?.describe || "";

            const overThreeDescription =
              overData?.isThreeSubAnswers?.describe || "";

            const overFourDescription =
              overData?.isFourSubAnswers?.describe || "";

            const overFiveOrMoreDescription =
              overData?.isFiveOrMoreSubAnswers?.describe || "";

            const nurData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.nursingHome || {};

            const nurOneDescription = nurData?.isOneSubAnswers?.describe || "";

            const nurTwoDescription = nurData?.isTwoSubAnswers?.describe || "";

            const nurThreeDescription =
              nurData?.isThreeSubAnswers?.describe || "";

            const nurFourDescription =
              nurData?.isFourSubAnswers?.describe || "";

            const nurFiveOrMoreDescription =
              nurData?.isFiveOrMoreSubAnswers?.describe || "";

            const surData =
              medicalDBData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                ?.hadSurgery || {};

            const surOneDescription = surData?.isOneSubAnswers?.describe || "";

            const surTwoDescription = surData?.isTwoSubAnswers?.describe || "";

            const surThreeDescription =
              surData?.isThreeSubAnswers?.describe || "";

            const surFourDescription =
              surData?.isFourSubAnswers?.describe || "";

            const surFiveOrMoreDescription =
              surData?.isFiveOrMoreSubAnswers?.describe || "";

            const hospitalizedPriorValue =
              medicalDBData?.hospitalizedPrior?.isHospitalizedPrior;
            const hospitalizedPriorKey =
              hospitalizedPriorValue == 1 ? "yes" : "no";
            const isHospitalizedPrior = hospitalizedPriorKey || "";
            const hospitalizedPriorComment =
              medicalDBData?.hospitalizedPrior?.comment;
            const hospitalizedPriorDescribe =
              medicalDBData?.hospitalizedPrior?.hospitalizedPriorSubAnswers
                ?.describe;

            // const selectedTreatmentsKeys = getSelectedKeys(treatmentsData.treatmentsSubAnswers, (value) => value === "1");

            const healthServicesSubAnswers =
              medicalDBData?.healthServices?.healthServicesSubAnswers || [];

            const physicalTherapistEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "physicalTherapist",
            );

            const physValue =
              physicalTherapistEntry?.physicalTherapist == "1" ? "yes" : "no";
            const physComment = physicalTherapistEntry?.comment;

            const occupationalTherapistEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "occupationalTherapist",
            );

            const occValue =
              occupationalTherapistEntry?.occupationalTherapist == "1"
                ? "yes"
                : "no";
            const occupationaltherapistComment =
              occupationalTherapistEntry?.comment;

            const dieticianEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "dietician",
            );

            const dietiValue = dieticianEntry?.dietician == "1" ? "yes" : "no";
            const dieticianComment = dieticianEntry?.comment;

            const socialWorkerEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "socialWorker",
            );

            const socialWorkerValue =
              socialWorkerEntry?.socialWorker == "1" ? "yes" : "no";
            const socialworkerComment = socialWorkerEntry?.comment;

            const pharmacistEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "pharmacist",
            );

            const pharmacistValue =
              pharmacistEntry?.pharmacist == "1" ? "yes" : "no";
            const pharmacistComment = pharmacistEntry?.comment;

            const speechTherapistEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "speechTherapist",
            );

            const speechTherapistValue =
              speechTherapistEntry?.speechTherapist == "1" ? "yes" : "no";
            const speechtherapistComment = speechTherapistEntry?.comment;

            const shiropractorEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "shiropractor",
            );

            const shiropractorValue =
              shiropractorEntry?.shiropractor == "1" ? "yes" : "no";
            const chiropractorComment = shiropractorEntry?.comment;

            const personalCareWorkerEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "personalCareWorker",
            );

            const personalCareWorkerValue =
              personalCareWorkerEntry?.personalCareWorker == "1" ? "yes" : "no";
            const personalcareworkerComment = personalCareWorkerEntry?.comment;

            const mealsOnWheelsEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "mealsOnWheels",
            );

            const mealsOnWheelsValue =
              mealsOnWheelsEntry?.mealsOnWheels == "1" ? "yes" : "no";
            const mealsonwheelsComment = mealsOnWheelsEntry?.comment;

            const adultDayCareEntry = healthServicesSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "adultDayCare",
            );

            const adultDayCareValue =
              adultDayCareEntry?.adultDayCare == "1" ? "yes" : "no";
            const adultdaycareComment = adultDayCareEntry?.comment;

            const treatmentSubAnswers =
              medicalDBData?.treatments?.treatmentsSubAnswers || [];

            const chemotherapyEntry = treatmentSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "chemotherapy",
            );

            const chemoValue =
              chemotherapyEntry?.chemotherapy == "1"
                ? "yes"
                : chemotherapyEntry?.chemotherapy == "2"
                ? "no"
                : "unknown";
            const chemotherapyComment = chemotherapyEntry?.comment;

            const catheterCareEntry = treatmentSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "catheterCare",
            );

            const catheterCareValue =
              catheterCareEntry?.catheterCare == "1"
                ? "yes"
                : catheterCareEntry?.catheterCare == "2"
                ? "no"
                : "unknown";
            const cathetercareComment = catheterCareEntry?.comment;

            const oxygenEntry = treatmentSubAnswers.find(
              (entry) => Object.keys(entry)[0] == "oxygen",
            );

            const oxygenValue =
              oxygenEntry?.oxygen == "1"
                ? "yes"
                : oxygenEntry?.oxygen == "2"
                ? "no"
                : "unknown";
            const oxygenComment = oxygenEntry?.comment;

            const tubefeedingsEntry = treatmentSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "tubeFeedings",
            );

            const tubefeedingsValue =
              tubefeedingsEntry?.tubeFeedings == "1"
                ? "yes"
                : tubefeedingsEntry?.tubeFeedings == "2"
                ? "no"
                : "unknown";

            const tubefeedingsComment = tubefeedingsEntry?.comment;

            const woundCareEntry = treatmentSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "woundCare",
            );

            const woundCareValue =
              woundCareEntry?.woundCare == "1"
                ? "yes"
                : woundCareEntry?.woundCare == "2"
                ? "no"
                : "unknown";
            const woundcareComment = woundCareEntry?.comment;

            const regularInjectionsEntry = treatmentSubAnswers.find(
              (entry) => Object.keys(entry)[0] === "regularInjections",
            );

            const regularInjectionsValue =
              regularInjectionsEntry?.regularInjections == "1"
                ? "yes"
                : regularInjectionsEntry?.regularInjections === "2"
                ? "no"
                : "unknown";
            const regularinjectionsComment = regularInjectionsEntry?.comment;

            const selectedPCPKeys = getSelectedKeys(
              pcpData,
              (value) => value == 1,
            );
            const selectedEmerKeys = getSelectedKeys(
              emergencyData,
              (value) => value == 1,
            );
            const selectedHosStayKeys = getSelectedKeys(
              stayedHosData,
              (value) => value == 1,
            );
            const nursingHomeKeys = getSelectedKeys(
              nursingHomeData,
              (value) => value == 1,
            );
            const hadSurgeryKeys = getSelectedKeys(
              hadSurgeryData,
              (value) => value == 1,
            );

            const selectedPCPKeyValue = getSelectedKeyValues(selectedPCPKeys);
            const selectedEmerKeyValue = getSelectedKeyValues(selectedEmerKeys);
            const selectedHosStayKeyValue =
              getSelectedKeyValues(selectedHosStayKeys);
            const selectedNursingHomeKeyValue =
              getSelectedKeyValues(nursingHomeKeys);
            const hadSurgeryKeyValue = getSelectedKeyValues(hadSurgeryKeys);

            function getSelectedKeys(data, condition) {
              return Object.keys(data).filter((key) => condition(data[key]));
            }

            function getSelectedKeyValues(keys) {
              return keys.map((key) => {
                switch (key) {
                  case "isNone":
                    return "none";
                  case "isOne":
                    return "1";
                  case "isTwo":
                    return "2";
                  case "isThree":
                    return "3";
                  case "isFour":
                    return "4";
                  case "isFiveOrMore":
                    return "5_or_more";
                  default:
                    return "";
                }
              });
            }

            const oxygenDescribes =
              selectedOxygenKey === "isPRN"
                ? "prn"
                : selectedOxygenKey === "isContinuous"
                ? "continuous"
                : selectedOxygenKey === "isDay"
                ? "day"
                : selectedOxygenKey === "isNight"
                ? "night"
                : "";

            const mapAssistiveValues = (key) => {
              switch (key) {
                case "isCane":
                  return "cane";
                case "isOxygen":
                  return "oxygen";
                // Add more cases for other keys if needed
                default:
                  return "";
              }
            };

            // Applying the mapping function to each key in selectedAssistiveValues
            const assistiveSubs =
              selectedAssistiveValues.map(mapAssistiveValues);

            // const cleanedAssistiveValues = selectedAssistiveValues.map(
            //   (value) => value.replace(/'/g, "").trim()
            // );

            // const assistiveSubs = selectedAssistiveValues
            //   .map((value) => {
            //     switch (value) {
            //       case "isCane":
            //         return "cane";
            //       case "isOxygen":
            //         return "oxygen";

            //       default:
            //         return "";
            //     }
            //   })
            //   .join(", ");

            dbFormData = {
              ...dbFormData,
              // medicalHistoryKey,
              dyuaadoDME: assistiveDevicesIsNone,

              areYouCurrentlySeeingAnySpecia: medicalisSpecHistory,
              physicaltherapist: physValue,
              physicaltherapistComment: physComment,
              adultdaycare: adultDayCareValue,

              ifOneOrMoreDescribeEmerOne: emergencyRoomOneDescription,
              ifOneOrMoreDescribeEmerTwo: emergencyRoomTwoDescription,
              ifOneOrMoreDescribeEmerThree: emergencyRoomThreeDescription,
              ifOneOrMoreDescribeEmerFour: emergencyRoomFourDescription,
              ifOneOrMoreDescribeEmerFiveOrMore:
                emergencyRoomFiveOrMoreDescription,

              ifOneOrMoreDescribeOverOne: overOneDescription,
              ifOneOrMoreDescribeOverTwo: overTwoDescription,
              ifOneOrMoreDescribeOverThree: overThreeDescription,
              ifOneOrMoreDescribeOverFour: overFourDescription,
              ifOneOrMoreDescribeOverFiveOrMore: overFiveOrMoreDescription,

              ifOneOrMoreDescribeNurOne: nurOneDescription,
              ifOneOrMoreDescribeNurTwo: nurTwoDescription,
              ifOneOrMoreDescribeNurThree: nurThreeDescription,
              ifOneOrMoreDescribeNurFour: nurFourDescription,
              ifOneOrMoreDescribeNurFiveOrMore: nurFiveOrMoreDescription,

              ifOneOrMoreDescribeSurOne: surOneDescription,
              ifOneOrMoreDescribeSurTwo: surTwoDescription,
              ifOneOrMoreDescribeSurThree: surThreeDescription,
              ifOneOrMoreDescribeSurFour: surFourDescription,
              ifOneOrMoreDescribeSurFiveOrMore: surFiveOrMoreDescription,

              occupationaltherapistComment,
              occupationaltherapist: occValue,
              dieticianComment,
              chemotherapy: chemoValue,
              cathetercareComment,
              chemotherapyComment,
              cathetercare: catheterCareValue,
              oxygen: oxygenValue,
              oxygenComment,
              tubefeedingsComment,
              tubefeedings: tubefeedingsValue,
              woundcare: woundCareValue,
              regularinjections: regularInjectionsValue,
              regularinjectionsComment,
              woundcareComment,
              cathetercareComment,
              dietician: dietiValue,
              socialworkerComment,
              socialworker: socialWorkerValue,
              pharmacistComment,
              pharmacist: pharmacistValue,
              speechtherapistComment,
              speechtherapist: speechTherapistValue,
              chiropractor: shiropractorValue,
              chiropractorComment,
              adultdaycareComment,
              personalcareworker: personalCareWorkerValue,
              mealsonwheelsComment,
              personalcareworkerComment,
              mealsonwheels: mealsOnWheelsValue,

              assistiveSub: assistiveSubs,

              oxygenDescribe: oxygenDescribes,
              litresMin: oxySubText,

              pastTwelveMonths: pastTwelveMonthsData,

              aSeenYourPcp: selectedPCPKeyValue,

              bVisitedTheEmergencyRoom: selectedEmerKeyValue,
              cStayedInTheHospitalOvernight: selectedHosStayKeyValue,
              dBeenInANursingHome: selectedNursingHomeKeyValue,
              eHadSurgery: hadSurgeryKeyValue,
              // pleaseChooseTheFollowingHealth: selectedHealthKeys,
              // pleaseChooseTheFollowingTreatm: selectedTreatmentsKeys,
              hospitalizedPrior: isHospitalizedPrior,
              hospitalizedPriorComment,
              areYouCurrentlySeeingAnySpeciaComment,
              aSeenYourPcpComment,
              eHadSurgeryComment,
              dBeenInANursingHomeComment,
              bVisitedTheEmergencyRoomComment,
              cStayedInTheHospitalOvernightComment,
              dyuaadoDMEComment,
              assistiveSubComment,
              oxygenDescribeComment,
              describeHosPrior: hospitalizedPriorDescribe,

              // doYouUseAnyAssistiveDevicesOrD: selectedAssistiveValues
            };
          }

          if (
            selfAssessmentDBData &&
            Object.keys(selfAssessmentDBData).length > 0
          ) {
            let tempData = getSelfAssessData(selfAssessmentDBData);

            dbFormData = {
              ...dbFormData,
              ...tempData,
            };
          }

          if (miniDBData && Object.keys(miniDBData).length > 0) {
            const personAns = miniDBData?.threeWordRecall?.personsAnswers;

            const wordlistV = miniDBData?.threeWordRecall?.wordList;

            const wordRcall = miniDBData?.threeWordRecall?.wordRecall;
            const clockD = miniDBData?.threeWordRecall?.clockDraw;
            const totalSc = miniDBData?.threeWordRecall?.totalScore;

            const furtherCognitiveValue =
              miniDBData?.recommendations?.furtherCognitive?.furtherCognitive;
            const otherValue = miniDBData?.recommendations?.other?.other;
            const furtherCognitiveComment =
              miniDBData?.recommendations?.furtherCognitive?.comment;
            const otherRecComment = miniDBData?.recommendations?.other?.comment;

            const checkedOptions = {
              furtherCognitive: furtherCognitiveValue || "",
              other: otherValue || "",
            };

            const recommendationsOtherComment = otherRecComment;
            const recommendationsFurtherComment = furtherCognitiveComment;

            // ... (previous code)

            if (miniDBData) {
              const threeWordRecall = miniDBData?.threeWordRecall?.wordRecall;
              const clockDraw = miniDBData?.threeWordRecall?.clockDraw;
              const totalScore = miniDBData?.threeWordRecall?.totalScore;

              setWordRecallRowData({
                points: threeWordRecall || "",
              });
              setClockDrawRowData({
                points: clockDraw || "",
              });
              setTotalScoreRowData({
                points: totalScore || "",
              });
            }

            let mappedMiniCogRows = [];

            const miniCogSection = rowsData?.find(
              (section) => section?.name === "step3ThreeWordRecall",
            );
            const miniCogSections = rowsData?.find(
              (section) => section?.name === "clockDrawPoints",
            );
            const miniCogSectionss = rowsData?.find(
              (section) => section?.name === "totalScorePoints",
            );

            if (miniCogSection) {
              const miniCogColumns = miniCogSection?.columns;

              if (miniCogColumns) {
                const wordRecallValue =
                  miniDBData?.threeWordRecall?.wordRecall || "";

                const columnLabel = miniCogColumns[0]?.label || "";
                const columnType = miniCogColumns[0]?.type || "";

                mappedMiniCogRows = [
                  {
                    label: columnLabel,
                    value: wordRecallValue,
                    type: columnType,
                  },
                ];
              }
            }
            if (miniCogSections) {
              const miniCogColumns = miniCogSection?.columns;

              if (miniCogColumns) {
                const clockDrawValue =
                  miniDBData?.threeWordRecall?.clockDraw || "";

                const columnLabel = miniCogColumns[0]?.label || "";
                const columnType = miniCogColumns[0]?.type || "";

                mappedMiniCogRows = [
                  {
                    label: columnLabel,
                    value: clockDrawValue,
                    type: columnType,
                  },
                ];
              }
            }
            if (miniCogSectionss) {
              const miniCogColumns = miniCogSection?.columns;

              if (miniCogColumns) {
                const totalScoreValue =
                  miniDBData?.threeWordRecall?.totalScore || "";

                const columnLabel = miniCogColumns[0]?.label || "";
                const columnType = miniCogColumns[0]?.type || "";

                mappedMiniCogRows = [
                  {
                    label: columnLabel,
                    value: totalScoreValue,
                    type: columnType,
                  },
                ];
              }
            }

            setMappedMiniCogRows(mappedMiniCogRows);

            // const miniCogData = {
            //   threeWordRecall: {
            //     wordList: miniDBData.data?.threeWordRecall?.wordList || [],
            //     personsAnswers: miniDBData.data?.threeWordRecall?.personsAnswers || "",
            //     wordRecall: miniDBData.data?.threeWordRecall?.wordRecall || "",
            //     clockDraw: miniDBData.data?.threeWordRecall?.clockDraw || "",
            //     totalScore: miniDBData.data?.threeWordRecall?.totalScore || 0,
            //   },
            //   recommendations: {
            //     furtherCognitive: {
            //       furtherCognitive: miniDBData.data?.recommendations?.furtherCognitive?.furtherCognitive || "",
            //       comment: miniDBData.data?.recommendations?.furtherCognitive?.comment || "",
            //     },
            //     other: {
            //       other: miniDBData.data?.recommendations?.other?.other || "",
            //       comment: miniDBData.data?.recommendations?.other?.comment || "",
            //     },
            //   },
            // };

            const step3ThreeWordRecall = mappedMiniCogRows[0]?.value || "";
            const clockDrawPoints = mappedMiniCogRows[0]?.value || "";
            const totalScorePoints = mappedMiniCogRows[0]?.value || "";

            dbFormData = {
              ...dbFormData,

              personsAnswers: personAns || "",
              wordListVersion: wordlistV || "",
              wordRecallPoints: wordRcall || "",

              step3ThreeWordRecall: step3ThreeWordRecall || "",
              clockDrawPoints: clockD || "",
              clockDrawPoints: clockDrawPoints || "",
              totalScorePoints: totalSc || "",
              totalScorePoints: totalScorePoints || "",

              recommendationsFurther: checkedOptions?.furtherCognitive || "",

              recommendationsOther: checkedOptions?.other || "",
              recommendationsOtherComment,
              recommendationsFurtherComment,
            };
          }

          if (preventiveDBData && Object.keys(preventiveDBData).length > 0) {
            const preventive = assessmentDataFromAPI?.data?.data?.preventive;

            if (preventive) {
              const preventiveSubAnswers =
                preventive?.data?.preventiveCare?.pastThreeYearsSubAnswers;

              setPreventive(preventiveSubAnswers);

              const preventiveRowData = preventiveSubAnswers?.map(
                (subAnswer) => ({
                  screen: subAnswer?.screen || "",
                  answer: subAnswer?.answer || "",
                  date: subAnswer?.date || "",
                  method: subAnswer?.method || "",
                  recommendation: subAnswer?.recommendation || "",
                  educationCompleted: subAnswer?.educationCompleted || "",
                }),
              );

              setPreventiveRowData(preventiveRowData);
            } else {
            }

            const preventiveSection = rowsData?.find(
              (section) => section?.label === "Preventive Care",
            );

            if (preventiveSection) {
              const preventiveColumns = preventiveSection.columns;

              if (preventiveColumns) {
                const mappedPreventiveRows = preventiveRowData?.map(
                  (rowData, rowIndex) => {
                    const mappedPRows = preventiveColumns.map((column) => {
                      const value = rowData[column.label] || "";

                      return {
                        label: column?.label,
                        value: value,
                        type: column?.type,
                      };
                    });

                    return mappedPRows;
                  },
                );

                setMappedPreventiveRows(mappedPreventiveRows);
              } else {
              }
            } else {
            }

            const oneTimeScreenForAbdominalData =
              preventive?.oneTimeScreenForAbdominal || {};

            const oneTimeScreenForAbdominalAortiComment =
              preventive.data?.oneTimeScreenForAbdominal?.comment;

            const oneTimeScreenEducationCommentA =
              preventive.data?.oneTimeScreenForAbdominal
                ?.isOneTimeScreenForAbdominalSubAnswer?.comment;

            const oneTimeScreenEducationCommentH =
              preventive.data?.oneTimeScreenForHepatitis
                ?.isoneTimeScreenForHepatitisSubAnswer?.comment;

            const oneTimeScreenForHepatitisCIfBoComment =
              preventive.data?.oneTimeScreenForHepatitis?.comment;

            const oneTimeScreenForHepatitisData =
              preventive?.oneTimeScreenForHepatitis || {};

            const recommendationAbdominalValue =
              preventive?.data?.recommendations?.recommendationsSubAnswers?.[0]
                ?.abnominalAneurysmScreening;

            const recommendationsAbdominalComment =
              preventive?.data?.recommendations?.recommendationsSubAnswers?.[0]
                ?.comment;

            const recommendationHepatitisValue =
              preventive?.data?.recommendations?.recommendationsSubAnswers?.[1]
                ?.hepatitisCScreening;

            const recommendationsHepatitisComment =
              preventive?.data?.recommendations?.recommendationsSubAnswers?.[1]
                ?.comment;

            const checkedOptions = {
              recommendationsAbdominal: recommendationAbdominalValue || "",
              recommendationsHepatitis: recommendationHepatitisValue || "",
            };

            // const oneTimeScreenForAbdominalAortiComment =
            //   oneTimeScreenForAbdominalData.isOneTimeScreenForAbdominalSubAnswer
            //     .comment || "";

            // const oneTimeScreenForHepatitisCIfBoComment =
            //   oneTimeScreenForHepatitisData.isoneTimeScreenForHepatitisSubAnswer
            //     .comment;
            const educationProAbdValue =
              oneTimeScreenForAbdominalData?.isOneTimeScreenForAbdominal
                ?.isOneTimeScreenForAbdominalSubAnswer?.isEducationProvided;

            const educationProAbdKey =
              educationProAbdValue == "1" ? "yes" : "no";
            const educationProvAb = educationProAbdKey || "";
            const recommendationsData = preventive?.recommendations || {};
            const recommendationsSubAnswers =
              recommendationsData?.recommendationsSubAnswers || [];

            const mappedRecommendations = recommendationsSubAnswers.map(
              (subAnswer) => ({
                abnominalAneurysmScreening:
                  subAnswer?.abnominalAneurysmScreening || "",
                comment: subAnswer?.comment || "",
              }),
            );

            const educationProvidedAbdominalComment =
              preventiveDBData?.oneTimeScreenForAbdominal
                ?.isOneTimeScreenForAbdominalSubAnswer?.comment;

            const educationProvidedHepatitisComment =
              preventiveDBData?.oneTimeScreenForHepatitis
                ?.isoneTimeScreenForHepatitisSubAnswer?.comment;

            // setPreventiveRowData(preventive)

            dbFormData = {
              ...dbFormData,
              // preventive,
              // inThePastThreeYearsHaveYouHad:
              // preventiveRowData || [],
              // inThePastThreeYearsHaveYouHad: preventiveRowData || [],
              oneTimeScreenForAbdominalAortiComment,
              oneTimeScreenForHepatitisCIfBoComment,

              oneTimeScreenEducationCommentA,
              recommendationsHepatitisComment,
              recommendationsAbdominalComment,
              // educationprovidedA: educationProvAb,
              oneTimeScreenEducationCommentH,
              recommendationsAbdominal:
                checkedOptions.recommendationsAbdominal || "",

              recommendationsHepatitis:
                checkedOptions.recommendationsHepatitis || "",

              educationProvidedAbdominal:
                preventiveDBData?.oneTimeScreenForAbdominal
                  ?.isOneTimeScreenForAbdominalSubAnswer?.isEducationProvided ==
                "1"
                  ? "yes"
                  : "no",

              educationProvidedAbdominalComment,

              educationProvidedHepatitisComment,

              // ?.isOneTimeScreenForAbdominal
              //   oneTimeScreenForAbdominalData?.isOneTimeScreenForAbdominal
              //     ?.isOneTimeScreenForAbdominalSubAnswer?.isEducationProvided ==

              educationProvidedHepatitis:
                // oneTimeScreenForHepatitisData?.isoneTimeScreenForHepatitis
                //   ?.isoneTimeScreenForHepatitisSubAnswer?.isEducationProvided ==
                // "1"
                //   ? "yes"
                //   : "no",
                preventiveDBData?.oneTimeScreenForHepatitis
                  ?.isoneTimeScreenForHepatitisSubAnswer?.isEducationProvided ==
                "1"
                  ? "yes"
                  : "no",

              oneTimeScreenForAbdominalAorti:
                preventiveDBData?.oneTimeScreenForAbdominal
                  ?.isOneTimeScreenForAbdominal == "1"
                  ? "yes"
                  : "no",
              // oneTimeScreenForAbdominalAorti:
              //   oneTimeScreenForAbdominalData?.isOneTimeScreenForAbdominal ==
              //   "1"
              //     ? "yes"
              //     : "no",
              oneTimeScreenForHepatitisCIfBo:
                preventiveDBData?.oneTimeScreenForHepatitis
                  ?.isoneTimeScreenForHepatitis == "1"
                  ? "yes"
                  : "no",
              // education_providedA:
              // oneTimeScreenForAbdominalData.isOneTimeScreenForAbdominal.isOneTimeScreenForAbdominalSubAnswer.
              // isEducationProvided,
              // education_providedQWE:"yes",
              // yesEDH:"yes"
              // yesEDH:
              // oneTimeScreenForAbdominalData.isOneTimeScreenForAbdominal.
              // isOneTimeScreenForAbdominalSubAnswer.
              // isEducationProvided == "1"?"yes":"no",
              // educationProvidedHepatitis:
              // oneTimeScreenForHepatitisData.isoneTimeScreenForHepatitis.
              // isoneTimeScreenForHepatitisSubAnswer.
              // isEducationProvided == "1"?"yes":"no",

              // ==
              // "1"?"yes":"no",

              oneTimeScreenForAbdominal: {
                isOneTimeScreenForAbdominal:
                  oneTimeScreenForAbdominalData?.isOneTimeScreenForAbdominal ||
                  "",
                isEducationProvided:
                  oneTimeScreenForAbdominalData
                    ?.isOneTimeScreenForAbdominalSubAnswer
                    ?.isEducationProvided || "",

                comment: oneTimeScreenForAbdominalData?.comment || "",
              },
              oneTimeScreenForHepatitis: {
                isoneTimeScreenForHepatitis:
                  oneTimeScreenForHepatitisData?.isoneTimeScreenForHepatitis ||
                  "",
                isEducationProvided:
                  oneTimeScreenForHepatitisData
                    ?.isoneTimeScreenForHepatitisSubAnswer
                    ?.isEducationProvided || "",

                comment: oneTimeScreenForHepatitisData?.comment || "",
              },
              // recommendations: {
              //   recommendationsSubAnswers: mappedRecommendations,
              // },
            };
          } else {
          }
          if (allergiesDBData && Object.keys(allergiesDBData).length > 0) {
            const allergiesValue = allergiesDBData?.allergies?.allergies;

            const allergiesKey = allergiesValue == "1" ? "yes" : "no";

            const allergiesMain = allergiesKey || "";
            const allergiesComment = allergiesDBData?.allergies?.comment;

            const allergies = assessmentDataFromAPI?.data?.data?.allergies;

            if (allergies) {
              const allergiesSubAnswers =
                allergies?.data?.allergies?.allergiesSubAnswers;
              setAllergies(allergiesSubAnswers);

              const allergiesRowData = allergiesSubAnswers?.map(
                (subAnswer) => ({
                  substance: subAnswer?.substance || "",
                  reaction: subAnswer?.reaction || "",
                }),
              );

              setAllergiesRowData(allergiesRowData);
            } else {
              // console.log("allergies not available");
            }

            let mappedAllergyRows;

            const allergyData = allergiesRowData;

            const allergySection = rowsData?.find(
              (section) => section.name === "Allergies/Medication",
            );

            if (allergySection) {
              const allergyColumns = allergySection.columns;

              if (allergyColumns) {
                mappedAllergyRows = allergyData?.map((rowData, rowIndex) => {
                  const mappedRow = allergyColumns?.map((column) => {
                    const value = rowData[column.label] || "";
                    return {
                      label: column?.label,
                      value: value,
                      type: column?.type,
                    };
                  });

                  return mappedRow;
                });
              } else {
                //  console.log("Columns not found in Allergy section");
              }
            } else {
              // console.log("No Allergy section found in rowsData");
            }

            if (allergies?.data?.medications?.medication) {
              const medicationSubAnswers =
                allergies?.data?.medications?.medication;

              setMedications(medicationSubAnswers);

              const medicationsRowData = medicationSubAnswers?.map(
                (subAnswer) => ({
                  diagnosis: subAnswer?.diagnosis || "",
                  labelName: subAnswer?.labelName || "",
                  doseOrUnits: subAnswer?.doseOrUnits || "",
                  route: subAnswer?.route || "",
                  frequency: subAnswer?.frequency || "",
                  prescribingPhysician: subAnswer?.prescribingPhysician || "",

                  status: subAnswer?.status || { taking: "2", notTaking: "2" },
                }),
              );

              setMedicationsRowData(medicationsRowData);
            } else {
              // console.log("No medications data available.");
            }

            let mappedMedicationsRows;
            const medicationsData = tableRowData;

            let medicationsColumns;

            const medicationsSection = rowsData?.find(
              (section) => section.label === "Medications",
            );

            if (medicationsSection) {
              medicationsColumns = medicationsSection.columns;

              if (medicationsColumns) {
                mappedMedicationsRows = medicationsRowData?.map(
                  (rowData, rowIndex) => {
                    const mappedMedicationsRows = medicationsColumns?.map(
                      (column) => {
                        const value = rowData[column?.label] || "";

                        return {
                          label: column?.label,
                          value: value,
                          type: column?.type,
                        };
                      },
                    );

                    return mappedMedicationsRows;
                  },
                );
              } else {
                // console.log("Columns not found in Allergy section");
              }
            } else {
              //console.log("No Allergy section found in rowsData");
            }

            const counterMedicationsValue =
              allergiesDBData?.counterMedications?.counterMedications;

            const counterMedicationsKey =
              counterMedicationsValue == "1" ? "yes" : "no";

            const counterMedicationsValueMain = counterMedicationsKey || "";
            const otcmSupplementsComment =
              allergiesDBData?.counterMedications?.comment;

            const counterMedications =
              assessmentDataFromAPI?.data?.data?.allergies?.data
                ?.counterMedications;

            if (counterMedications) {
              const counterMedicationsSubAnswers =
                counterMedications?.counterMedicationsSubAnswers;
              setCounterMedications(counterMedicationsSubAnswers);

              const counterMedicationsRowData =
                counterMedicationsSubAnswers?.map((subAnswer) => ({
                  date: subAnswer?.date || "",
                  description: subAnswer?.description || "",
                  doseOrUnits: subAnswer?.doseOrUnits || "",
                  route: subAnswer?.route || "",
                  frequency: subAnswer?.frequency || "",
                }));

              setCounterMedicationsRowData(counterMedicationsRowData);
            } else {
              //  console.log("counter Medications not available");
            }

            let mappedCounterMedicationsRows;

            const counterMedicationsData = counterMedicationsRowData;

            const counterMedicationsSection = rowsData?.find(
              (section) => section.name === "Counter Medications",
            );

            if (counterMedicationsSection) {
              const counterMedicationColumns =
                counterMedicationsSection.columns;

              if (counterMedicationColumns) {
                mappedCounterMedicationsRows = counterMedicationsData?.map(
                  (rowData, rowIndex) => {
                    const mappedRow = counterMedicationColumns?.map(
                      (column) => {
                        const value = rowData[column.label] || "";
                        return {
                          label: column?.label,
                          value: value,
                          type: column?.type,
                        };
                      },
                    );

                    return mappedRow;
                  },
                );
              } else {
                // console.log("Columns not found in Allergy section");
              }
            } else {
              // console.log("No Allergy section found in rowsData");
            }
            const medicationComplianceData =
              allergies?.data?.medicationCompliance;

            const medicationsComment = allergiesDBData?.medications?.comment;
            // console.log(medicationsComment, "DGDGH");
            const doyoueverforgettotakeyourmedicineComment =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.takeYourMedicine.comment;

            const doyousometimesnotpayenoughattentiontoyourmedicationComment =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.attentionToYourMedication?.comment || "";

            const doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacistComment =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.longtermBenefit?.comment;

            const whenyoufeelbetterdoyousometimesstoptakingyourmedicineComment =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.stopTakingMedicine?.comment;

            const sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingitComment =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.feelWorse?.comment;
            const doyousometimesforgettorefillyourprescriptionontimeComment =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.forgetToRefill?.comment;

            const takeYourMedicine =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.takeYourMedicine?.takeYourMedicine == "1"
                ? "yes"
                : "no";
            const attentionToYourMedication =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.attentionToYourMedication?.attentionToYourMedication == "1"
                ? "yes"
                : "no";
            const longtermBenefit =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.longtermBenefit?.longtermBenefit == "1"
                ? "yes"
                : "no";
            const stopTakingMedicine =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.stopTakingMedicine?.stopTakingMedicine == "1"
                ? "yes"
                : "no";
            const feelWorse =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.feelWorse?.feelWorse == "1"
                ? "yes"
                : "no";
            const forgetToRefill =
              medicationComplianceData?.medicationComplianceSubAnswers
                ?.forgetToRefill?.forgetToRefill == "1"
                ? "yes"
                : "no";

            const doctorsValue =
              allergiesDBData?.recommendations?.recommendationSubAnswers[0]
                .medicationAdherence;

            const recommendationsDoctorComment =
              allergiesDBData?.recommendations?.recommendationSubAnswers[0]
                .comment;

            const otherssValue =
              allergiesDBData?.recommendations?.recommendationSubAnswers[2]
                .other;

            const recommendationsOtherssComment =
              allergiesDBData?.recommendations?.recommendationSubAnswers[2]
                .comment;

            // console.log(recommendationsOtherssComment, otherssValue, "adsSE");

            const sideValue =
              allergiesDBData?.recommendations.recommendationSubAnswers[1]
                ?.sideEffects;

            const recommendationsSideComment =
              allergiesDBData?.recommendations?.recommendationSubAnswers[1]
                ?.comment;

            const recomEduValue =
              allergiesDBData?.educatedOnImportance?.educatedOnImportance;

            // ?.recommendations
            // ?.educatedOnImportance
            // ?.educatedOnImportance;

            const educatedonImportanceComment =
              allergiesDBData?.educatedOnImportance?.comment;

            // console.log(recomEduValue, educatedonImportanceComment, "SDFSFG");
            const checkedOptions = {
              recomDoctor: doctorsValue || "",
              recomOther: otherssValue || "",
              recomSide: sideValue || "",
            };

            const checkedEOptions = {
              recomEdu: recomEduValue || "",
            };

            // const longTermUseData = allergiesDBData?.data?.longTermUse;

            // if (longTermUseData) {
            //     const longTermUseSubAnswers = longTermUseData.longTermUseSubAnswers;

            // setLongTermUse(longTermUseSubAnswers);

            //   const longTermUseInfo = {
            //       none: longTermUseData.none == "1" ? "yes" : "no",
            //       ASA: longTermUseSubAnswers.ASA == "1" ? "yes" : "no",
            //       steroids: longTermUseSubAnswers.steroids =="1" ? "yes" : "no",
            //       insulin: longTermUseSubAnswers.insulin == "1" ? "yes" : "no",
            //       anticoagulants: longTermUseSubAnswers.anticoagulants == "1" ? "yes" : "no",

            //       comment: longTermUseData.comment || ""
            //   };
            //   const mappedLongTermUseInfo = Object.keys(longTermUseInfo).reduce((result, key) => {
            //     result[key] = longTermUseInfo[key] == "yes" ? "1" : "2";
            //     return result;
            // }, {});

            dbFormData = {
              ...dbFormData,

              allergies: allergiesMain || "",

              allergiesComment,
              otcmSupplementsComment,
              otcmSupplements: counterMedicationsValueMain,
              recommendationsDoctorComment,

              recommendationsOtherssComment,
              recommendationsSideComment,
              educatedonImportanceComment,
              medicationsComment,
              // longtermuseof:mappedLongTermUseInfo,
              recommendationsDoctor: checkedOptions.recomDoctor,
              recommendationsOtherss: checkedOptions.recomOther,
              recommendationsSide: checkedOptions.recomSide,
              educatedonImportance: checkedEOptions.recomEdu,
              doyoueverforgettotakeyourmedicine: takeYourMedicine,
              doyoueverforgettotakeyourmedicineComment,
              doyousometimesnotpayenoughattentiontoyourmedicationComment,
              doyousometimesnotpayenoughattentiontoyourmedication:
                attentionToYourMedication,
              doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacist:
                longtermBenefit,
              whenyoufeelbetterdoyousometimesstoptakingyourmedicine:
                stopTakingMedicine,
              doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacistComment,
              sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingit:
                feelWorse,
              doyousometimesforgettorefillyourprescriptionontime:
                forgetToRefill,

              whenyoufeelbetterdoyousometimesstoptakingyourmedicineComment,

              sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingitComment,

              doyousometimesforgettorefillyourprescriptionontimeComment,
            };
            // }
          }

          if (examReviewData && Object.keys(examReviewData).length > 0) {
            let tempData = getExamReviewData(examReviewData);

            dbFormData = {
              ...dbFormData,
              ...tempData,
            };
          }

          if (homeSafetyData && Object.keys(homeSafetyData).length > 0) {
            let tempData = getHomeSafetyData(homeSafetyData);

            dbFormData = {
              ...dbFormData,
              ...tempData,
            };
          }

          //ros
          if (
            reviewOfSystemDBData &&
            Object.keys(reviewOfSystemDBData).length > 0
          ) {
            let tempData = getRosData(reviewOfSystemDBData);

            dbFormData = {
              ...dbFormData,
              ...tempData,
            };
          }

          setFormData(dbFormData);
        }
      }
    } catch (error) {
      return;
    }
  };
  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage(response.data.message);
        // fetchMemberData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };
  //demographics
  // console.log("tabId", tabId);
  const handleSaveClick = async () => {
    try {
      const assessmentDataFromAPI = await getAssessmentData();
      const demographicsData =
        assessmentDataFromAPI.data.data.demographics.data;

      // if (!demographicsData) {
      //   return;
      // }

      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      Object.keys(formDataCamelCase).forEach((key) => {
        if (demographicsData.hasOwnProperty(key)) {
          demographicsData[key] = formDataCamelCase[key];
        }
      });

      const {
        firstName,
        middleName,
        lastName,
        gender,
        address,
        city,
        state,
        county,
        // "dob": 29082,
        mStatus,
        insh,
        clientId,
        primary,
        secondary,
        email,
        ename,
        eNo,
        language,
        effectiveDate,
        termDate,
        eligibilityDate,
        pName,
        pSpeciality,
        pPhone,
        pAddOne,
        pAddTwo,
        pCity,
        pState,
        pZip,
        pcpcounty,
        pId,
        pOfcName,
        memberId,
        memberIdTwo,
        medicaidId,
        medicareId,
        uniqueId,
        suffix,
        hicn,
        aContact,
        aAddress,
        plan,
        program,
        lob,
        region,
        aligned,
        addressTwo,
        mAddressOne,
        mAddTwo,
        mAddressThree,
        mCity,
        mState,
        mZip,
        mCounty,
        ethnicity,
        pFax,
        screenIha,
        dualEligibility,
        medReview,
        painAsmt,
        funcStatus,
        rankAll,
        careCoordinator,
        suspectCode,
      } = demographicsData;

      const payload = {
        id: assessmentId,
        data: {
          name: "Demographics",
          data: {
            firstName,
            middleName,
            lastName,
            gender,
            address,
            city,
            state,
            county,
            // "dob": 29082,
            mStatus,
            insh,
            clientId,
            primary,
            secondary,
            email,
            ename,
            eNo,
            language,
            effectiveDate,
            termDate,
            eligibilityDate,
            pName,
            pSpeciality,
            pPhone,
            pAddOne,
            pAddTwo,
            pCity,
            pState,
            pZip,
            pcpcounty,
            pId,
            pOfcName,
            memberId,
            memberIdTwo,
            medicaidId,
            medicareId,
            uniqueId,
            suffix,
            hicn,
            aContact,
            aAddress,
            plan,
            program,
            lob,
            region,
            aligned,
            addressTwo,
            mAddressOne,
            mAddTwo,
            mAddressThree,
            mCity,
            mState,
            mZip,
            mCounty,
            ethnicity,
            pFax,
            screenIha,
            dualEligibility,
            medReview,
            painAsmt,
            funcStatus,
            rankAll,
            careCoordinator,
            suspectCode,
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");
        const roleId = sessionStorage.getItem("roleId");
        if (roleId === "5") {
          navigate("/NurseDashboard/Nurselist");
        } else {
          navigate("/AdminDashboard/MemberList");
        }

        //navigate("/AdminDashboard/MemberList");
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleVitalSave = async () => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const vitalData = {
        vitalSigns: {
          bloodPressure: {
            systolicbp: formDataCamelCase.systolicbp || "",
            diastolicbp: formDataCamelCase.diastolicbp || "",
          },
          pulse: formDataCamelCase.pulse || "",
          respiratoryRate: formDataCamelCase.respiratoryRate || "",
          temp: formDataCamelCase.temp || "",
          pulseOximetry: formDataCamelCase.pulseOximetry || "",
          painScale: formDataCamelCase.painScale || "",
          comment: formDataCamelCase.vitalSignsComment || "",
        },
        bmi: {
          patientsHeight: {
            feet: formDataCamelCase.feet || "",
            inch: formDataCamelCase.inch || "",
          },
          patientsWeight: formDataCamelCase.patientsWeight || "",
          bmi: formDataCamelCase.bmii || "",
          obesity: formDataCamelCase.bmi === "obesity" ? "1" : "2" || "",
          moderateObesity:
            formDataCamelCase.bmi === "moderateObesity" ? "1" : "2" || "",
          morbidObesity:
            formDataCamelCase.bmi === "morbidObesity" ? "1" : "2" || "",
          malnutrition:
            formDataCamelCase.bmi === "malnutrition" ? "1" : "2" || "",
          comment: formDataCamelCase.bmiComment || "",
          malnutritionSubAnswer: {
            describe: {
              active: formDataCamelCase.describe === "active" ? "1" : "2",
              historyOf: formDataCamelCase.describe === "historyOf" ? "1" : "2",
              ruleOut: formDataCamelCase.describe === "ruleOut" ? "1" : "2",
              comment: formDataCamelCase.describeComment || "",
            },
            supportedBy: {
              albumin:
                formDataCamelCase.supportedBy === "albumin" ? "1" : "2" || "",
              muscleWaiting:
                formDataCamelCase.supportedBy === "muscleWaiting"
                  ? "1"
                  : "2" || "",
              historyOfSevereWtLoss:
                formDataCamelCase.supportedBy === "historyOfSevereWtLoss"
                  ? "1"
                  : "2" || "",
              comment: formDataCamelCase.supportedByComment || "",
            },
            malnutrition: {
              isMalnutrition:
                formDataCamelCase.malnutrition === "Yes"
                  ? "1"
                  : formDataCamelCase.malnutrition === "No"
                  ? "2"
                  : "",
              comment: formDataCamelCase.malnutritionComment || "",
            },
          },
        },
        lostWeight: {
          fifteenLbs:
            formDataCamelCase.lostWeight === "fifteenLbs" ? "1" : "2" || "",
          fiveLbs: formDataCamelCase.lostWeight === "fiveLbs" ? "1" : "2" || "",
          moreThanFifteenLbs:
            formDataCamelCase.lostWeight === "moreThanFifteenLbs"
              ? "1"
              : "2" || "",
          none: formDataCamelCase.lostWeight === "none" ? "1" : "2" || "",
          tenLbs: formDataCamelCase.lostWeight === "tenLbs" ? "1" : "2" || "",
          tenPercentYourWeight:
            formDataCamelCase.lostWeight === "tenPercentYourWeight"
              ? "1"
              : "2" || "",
          comment: formDataCamelCase.lostWeightComment || "",
        },
        specialDiet: {
          diabeticDiet: formDataCamelCase.specialDiet?.includes("diabeticDiet")
            ? "1"
            : "2",
          glutenFree: formDataCamelCase.specialDiet?.includes("glutenFree")
            ? "1"
            : "2",
          heartHealthyDiet: formDataCamelCase.specialDiet?.includes(
            "heartHealthyDiet",
          )
            ? "1"
            : "2",
          keto: formDataCamelCase.specialDiet?.includes("keto") ? "1" : "2",
          other: formDataCamelCase.specialDiet?.includes("other") ? "1" : "2",
          pescatarian: formDataCamelCase.specialDiet?.includes("pescatarian")
            ? "1"
            : "2",
          renalDiet: formDataCamelCase.specialDiet?.includes("renalDiet")
            ? "1"
            : "2",
          vegan: formDataCamelCase.specialDiet?.includes("vegan") ? "1" : "2",
          vegetarian: formDataCamelCase.specialDiet?.includes("vegetarian")
            ? "1"
            : "2",
          otherSubAnswer: {
            describe: formDataCamelCase.describeSubtext || "",
          },
          comment: formDataCamelCase.specialDietComment || "",
        },
        recommendations: {
          recommendationsSubAnswers:
            formDataCamelCase.recommendationsSubAnswers.map((subAnswer) => {
              let comment = "";
              if ("nutritionManagement" in subAnswer) {
                comment = formData.nutritionManagementComment || "";
              } else if ("other" in subAnswer) {
                comment = formData.otherComment || "";
              }

              if (Array.isArray(comment)) {
                comment = comment.join(", ");
              }

              return {
                ...subAnswer,
                comment: comment,
              };
            }),
        },
      };

      // Build the payload with the updated vitalsData
      const payload = {
        id: assessmentId,
        data: {
          name: "Vital Signs",
          data: {
            ...vitalData,
          },
        },
      };

      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");

        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleSelfSave = async () => {
    try {
      const selfAssessmentData = setSelfAssessData(formData);

      const payload = {
        id: assessmentId,
        data: {
          name: "Self-Assessment and Social History",
          data: {
            ...selfAssessmentData,
          },
        },
      };

      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handlePainSave = async () => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const painData = {
        patientExperiencePain: {
          isPatientExperiencePain:
            formDataCamelCase.patientExperiencePain === "Yes"
              ? "1"
              : formDataCamelCase.patientExperiencePain === "No"
              ? "2"
              : "",
          comment: formDataCamelCase.patientExperiencePainComment,
          isPatientExperiencePainSubAnswer: {
            painAcute: {
              isPainAcute:
                formDataCamelCase.painAcute === "Yes"
                  ? "1"
                  : formDataCamelCase.painAcute === "No"
                  ? "2"
                  : "",
              comment: formDataCamelCase.painAcuteComment,
            },
            painChronic: {
              isPainChronic:
                formDataCamelCase.painChronic === "Yes"
                  ? "1"
                  : formDataCamelCase.painChronic === "No"
                  ? "2"
                  : "",
              comment: formDataCamelCase.painChronicComment,
              isPainChronicSubAnswer: {
                describe: {
                  active:
                    formDataCamelCase.painDescribe === "active" ? "1" : "2",
                  historyOf:
                    formDataCamelCase.painDescribe === "historyOf" ? "1" : "2",
                  ruleOut:
                    formDataCamelCase.painDescribe === "ruleOut" ? "1" : "2",
                  comment: formDataCamelCase.describeComment,
                },
                frequency: {
                  allOfTheTime:
                    formDataCamelCase.frequency === "allOfTheTime" ? "1" : "2",
                  occasional:
                    formDataCamelCase.frequency === "occasional" ? "1" : "2",
                  oneOrMoreTimes:
                    formDataCamelCase.frequency === "oneOrMoreTimes"
                      ? "1"
                      : "2",
                  comment: formDataCamelCase.frequencyComment,
                },
                where: formDataCamelCase.painChronicWhereText,
                rateYourPain: formDataCamelCase.rateYourPainSubtext,
              },
            },
            opioidMedication: {
              isOpioidMedication:
                formDataCamelCase.opioidMedication === "Yes"
                  ? "1"
                  : formDataCamelCase.opioidMedication === "No"
                  ? "2"
                  : "",
              comment: formDataCamelCase.opioidMedicationComment,
              isOpioidMedicationSubAnswer: {
                drugsTake: formDataCamelCase.drugsTakeSubtext,
                excessTimeSpent: {
                  isExcessTimeSpent:
                    formDataCamelCase.excessTimeSpent === "Yes"
                      ? "1"
                      : formDataCamelCase.excessTimeSpent === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.excessTimeSpentComment,
                },
                increasedUsage: {
                  isIncreasedUsage:
                    formDataCamelCase.increasedUsage === "Yes"
                      ? "1"
                      : formDataCamelCase.increasedUsage === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.increasedUsageComment,
                },
                patientKnowledge: {
                  isPatientKnowledge:
                    formDataCamelCase.patientKnowledge === "Yes"
                      ? "1"
                      : formDataCamelCase.patientKnowledge === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.patientKnowledgeComment,
                },
                psychologicalProblem: {
                  isPsychologicalProblem:
                    formDataCamelCase.psychologicalProblem === "Yes"
                      ? "1"
                      : formDataCamelCase.psychologicalProblem === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.psychologicalProblemComment,
                },
                tolerance: {
                  isTolerance:
                    formDataCamelCase.tolerance === "Yes"
                      ? "1"
                      : formDataCamelCase.tolerance === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.toleranceComment,
                },
                unsuccessEffort: {
                  isUnsuccessEffort:
                    formDataCamelCase.unsuccessEffort === "Yes"
                      ? "1"
                      : formDataCamelCase.unsuccessEffort === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.unsuccessEffortComment,
                },
                withdrawal: {
                  isWithdrawal:
                    formDataCamelCase.withdrawal === "Yes"
                      ? "1"
                      : formDataCamelCase.withdrawal === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.withdrawalComment,
                },
              },
            },
            painManagementTiming: {
              isPainManagementTiming:
                formDataCamelCase.painManagementTiming === "Yes"
                  ? "1"
                  : formDataCamelCase.painManagementTiming === "No"
                  ? "2"
                  : "",
              comment: formDataCamelCase.painManagementTimingComment,
              isPainManagementTimingSubAnswer: {
                isPatientResponding: {
                  isPatientResponding:
                    formDataCamelCase.isPatientResponding === "Yes"
                      ? "1"
                      : formDataCamelCase.isPatientResponding === "No"
                      ? "2"
                      : "",
                  comment: formDataCamelCase.isPatientRespondingComment,
                },
              },
            },
            patientAdvised: {
              isPatientAdvised:
                formDataCamelCase.patientAdvised === "Yes"
                  ? "1"
                  : formDataCamelCase.patientAdvised === "No"
                  ? "2"
                  : "",
              comment: formDataCamelCase.patientAdvisedComment,
            },
          },
        },
      };

      const payload = {
        id: assessmentId,
        data: {
          name: "Pain",
          data: {
            ...painData,
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }

      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");

        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleCovidSave = async () => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const covidData = {
        lastFourteenDays: {
          travelInternational: {
            travelInternational:
              formDataCamelCase.travelInternational === "Yes"
                ? "1"
                : formDataCamelCase.travelInternational === "No"
                ? "2"
                : "",
            comment: formDataCamelCase.travelInternationalComment,
          },

          dignosedWithCorona: {
            dignosedWithCorona:
              formDataCamelCase.dignosedWithCorona === "Yes"
                ? "1"
                : formDataCamelCase.dignosedWithCorona === "No"
                ? "2"
                : "",
            comment: formDataCamelCase.dignosedWithCoronaComment,
          },

          closeContact: {
            closeContact:
              formDataCamelCase.closeContact === "Yes"
                ? "1"
                : formDataCamelCase.closeContact === "No"
                ? "2"
                : "",
            comment: formDataCamelCase.closeContactComment,
          },

          developFever: {
            developFever:
              formDataCamelCase.developFever === "Yes"
                ? "1"
                : formDataCamelCase.developFever === "No"
                ? "2"
                : "",
            comment: formDataCamelCase.developFeverComment,
          },

          developCough: {
            developCough:
              formDataCamelCase.developCough === "Yes"
                ? "1"
                : formDataCamelCase.developCough === "No"
                ? "2"
                : "",
            comment: formDataCamelCase.developCoughComment,
          },

          developSymptoms: {
            developSymptoms:
              formDataCamelCase.developSymptoms === "Yes"
                ? "1"
                : formDataCamelCase.developSymptoms === "No"
                ? "2"
                : "",
            comment: formDataCamelCase.developSymptomsComment,
          },

          developBreath: {
            developBreath:
              formDataCamelCase.developBreath === "Yes"
                ? "1"
                : formDataCamelCase.developBreath === "No"
                ? "2"
                : "",
            comment: formDataCamelCase.developBreathComment,
          },
        },
      };

      const payload = {
        id: assessmentId,
        data: {
          name: "Covid Screening",
          data: covidData,
        },
      };

      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleSavesFData = async () => {
    try {
      const assessmentDataFromAPI = await getAssessmentData();

      const familyAssessmentData =
        assessmentDataFromAPI.data?.data?.family?.data;

      if (!familyAssessmentData) {
        return;
      }

      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const familyData = {};
      Object.keys(familyAssessmentData).forEach((field) => {
        if (field === "familyHistory") {
          const familyHistoryData = {
            isFamilyHistory:
              formDataCamelCase.familyHistory === "yes" ? "1" : "2",
            comment: formDataCamelCase.familyHistoryComment,
            familyHistorySubAnswers:
              formDataCamelCase.familyHistory === "yes"
                ? familyRowData.map((row) => ({
                    familyMember: row.familyMember || "",
                    medicalCondition: row.medicalCondition || "",
                    causeofDeath: row.causeofDeath || "",
                  }))
                : familyRowData.map(() => ({
                    familyMember: "",
                    medicalCondition: "",
                    causeofDeath: "",
                  })),
          };

          familyData[field] = {
            ...familyHistoryData,
          };
        }
      });

      const apiEndpoint = "/hra/assessment";

      const requestData = {
        id: memberId,
        data: {
          name: "Family History",
          data: {
            ...familyData,
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi(apiEndpoint, requestData);

      if (response.status === 200) {
        // console.log("Data saved successfully:", response);
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");

        navigate(getReroute());
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Failed to save data");
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Error during data save");
      setToasterOpen(true);
    }
  };

  const handleScreeningsSave = async () => {
    try {
      const assessmentDataFromAPI = await getAssessmentData();

      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      var examdateMs = new Date(formDataCamelCase?.examdateM);

      var month = (examdateMs.getMonth() + 1).toString().padStart(2, "0");
      var day = examdateMs.getDate().toString().padStart(2, "0");
      var year = examdateMs.getFullYear();

      var formattedDate = `${month}-${day}-${year}`;

      const screeningsData = {
        microAlbumin: {
          isMicroAlbumin: formDataCamelCase?.microalbumin === "Y" ? "Y" : "N",
          score: 123,
          weight: 70.5,
          subAnswers: {
            status_options: formDataCamelCase?.statusoptionsM,
            subAnswers: {
              reason: formDataCamelCase?.statusoptionsMComment,
              subAnswers: {
                other: formDataCamelCase?.commentsM,
              },
            },
            TBarcode: formDataCamelCase?.microalbumintestbarcode,
            // examDate: formDataCamelCase?.examdateM,
            examDate: formattedDate,
            sResult: formDataCamelCase?.screeningresultM,
            diagnosis: formDataCamelCase?.diagnosisM,
            comment: formDataCamelCase?.microalbuminComment,
          },
        },
        fobt: {
          isFobt: formDataCamelCase.fobt === "Y" ? "Y" : "N",
          score: 456,
          weight: 65.2,
          subAnswers: {
            status_options: formDataCamelCase?.statusoptionsF,
            subAnswers: {
              reason: formDataCamelCase?.statusoptionsFComment,
              subAnswers: {
                other: formDataCamelCase?.commentsF,
              },
            },
            TBarcode: formDataCamelCase?.fobttestbarcode,
            examDate: formDataCamelCase?.examdateF,
            sResult: formDataCamelCase?.screeningresultF,
            diagnosis: formDataCamelCase?.diagnosisF,
            comment: formDataCamelCase?.fobtComment,
          },
        },
        aOneC: {
          isAOneC: formDataCamelCase?.a1c === "Y" ? "Y" : "N",
          score: 789,
          weight: 80.0,
          subAnswers: {
            status_options: formDataCamelCase?.statusoptionsA,
            subAnswers: {
              reason: formDataCamelCase?.statusoptionsAComment,
              subAnswers: {
                other: formDataCamelCase?.commentsA,
              },
            },
            TBarcode: formDataCamelCase?.a1ctestbarcode,
            examDate: formDataCamelCase?.examdateA,
            sResult: formDataCamelCase?.screeningresultA,
            diagnosis: formDataCamelCase?.diagnosisA,
            comment: formDataCamelCase?.a1cComment,
          },
        },
        dexa: {
          isDexa: formDataCamelCase?.dexa === "Y" ? "Y" : "N",
          score: 321,
          weight: 75.5,
          subAnswers: {
            status_options: formDataCamelCase?.statusoptionsD,
            subAnswers: {
              reason: formDataCamelCase?.statusoptionsDComment,
              subAnswers: {
                other: formDataCamelCase?.commentsD,
              },
            },

            examDate: formDataCamelCase?.examdateD,
            sResult: formDataCamelCase?.screeningresultD,
            diagnosis: formDataCamelCase?.diagnosisD,
            comment: formDataCamelCase?.dexaComment,
          },
        },
        ldl: {
          isLdl: formDataCamelCase?.ldl === "Y" ? "Y" : "N",
          score: 654,
          weight: 72.3,
          subAnswers: {
            status_options: formData?.statusoptionsL,
            subAnswers: {
              reason: formDataCamelCase?.statusoptionsLComment,
              subAnswers: {
                other: formDataCamelCase?.commentsL,
              },
            },

            TBarcode: formDataCamelCase?.ldltextbarcode,
            examDate: formDataCamelCase?.examdateL,
            sResult: formDataCamelCase?.screeningresultL,
            diagnosis: formDataCamelCase?.diagnosisL,
            comment: formDataCamelCase?.ldlComment,
          },
        },
        pad: {
          isPad: formDataCamelCase?.pad === "Y" ? "Y" : "N",
          score: 987,
          weight: 68.7,
          subAnswers: {
            status_options: formDataCamelCase?.statusoptionsP,
            subAnswers: {
              reason: formDataCamelCase?.statusoptionsPComment,
              subAnswers: {
                other: formDataCamelCase?.commentsP,
              },
            },
            examDate: formDataCamelCase?.examdateP,
            tResultLeft: formDataCamelCase?.padtestingresultsleft,
            tResultRight: formDataCamelCase?.padtestingresultsright,
            peripheralResultsLeft: {
              peripheralResultsLeft:
                formDataCamelCase?.resultsforperipheralarterialdiseasetestingleft,
              comment:
                formDataCamelCase?.resultsforperipheralarterialdiseasetestingleftComment,
            },
            peripheralResultsRight: {
              peripheralResultsRight:
                formDataCamelCase?.resultsforperipheralarterialdiseasetestingright,
              comment:
                formDataCamelCase?.resultsforperipheralarterialdiseasetestingrightComment,
            },

            diagnosis: formDataCamelCase?.diagnosisP,
            comment: formDataCamelCase?.padComment,
          },
          isMemberEducated: "1",
        },
        retinalExam: {
          isRetinalExam: formDataCamelCase.retinaleyeexam === "Y" ? "Y" : "N",
          score: 159,
          weight: 66.4,
          subAnswers: {
            status_options: formDataCamelCase?.statusoptionsR,
            subAnswers: {
              reason: formDataCamelCase?.statusoptionsRComment,
              subAnswers: {
                other: formDataCamelCase?.commentsR,
              },
            },
            examDate: formDataCamelCase?.examdateR,
            sResult: formDataCamelCase?.screeningresultR,
            diagnosis: formDataCamelCase?.diagnosisR,
            comment: formDataCamelCase?.retinaleyeexamComment,
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const payload = {
        id: assessmentId,
        data: {
          name: "Screening Needed",
          data: {
            ...screeningsData,
            other: {
              isMemberEducated: {
                type: "checkbox",
                value: formDataCamelCase.recommendationsScreenings || "",
                comment:
                  formDataCamelCase.recommendationsScreeningsComment || "",
              },
            },
          },
        },
      };

      const response = await putApi("/hra/assessment", payload);

      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };
  const updateHraStatus = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 6,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };

  const customUpdateHraStatus = async (hraStatusToBeUpdated) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: hraStatusToBeUpdated, //6,
          subStatus: "",
          cancelStatus: "",
          cancelText: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Hra Status Updated Successfully");
        navigate(getReroute());
      } else {
        setSignSubmitDialog(false);
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Failed to update HRA Status");
      }
    } catch (error) {
      setSignSubmitDialog(false);
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Failed to update HRA Status");
    }
  };

  const handlePatientSave = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const patientData = {
        visitType: {
          isVisitType: formDataCamelCase.visitType === "inPerson" ? "1" : "2",
          consentedToVideo: formDataCamelCase.consentedToVideo,
        },
        assessorsComments: formDataCamelCase.assessorsComments,
        memberAknowledgement: formDataCamelCase.memberAknowledgement,
        acceptDisclosure: formDataCamelCase.acceptDisclosure,
        evaluationDate: formDataCamelCase.evaluationDate,
        examFinishDate: formDataCamelCase.examFinishDate,
      };

      const payload = {
        id: assessmentId,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
          },
          providerSignature: providerSignature,
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setSignSubmitDialog(false);
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");
        const roleId = sessionStorage.getItem("roleId");
        if (roleId === "5") {
          if (providerSignature) {
            await updateHraStatus();
          }
          navigate("/NurseDashboard/Nurselist");
        } else {
          navigate("/AdminDashboard/MemberList");
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleMedicalSave = async () => {
    try {
      const assessmentDataFromAPI = await getAssessmentData();

      const medicalAssessmentData =
        assessmentDataFromAPI.data.data.medical.data;
      if (!medicalAssessmentData) {
        return;
      }

      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const medicalData = {};
      Object.keys(medicalAssessmentData).forEach((field) => {
        if (field === "assistiveDevices") {
          const assistiveDevicesData = {
            isNone: formDataCamelCase?.assistiveSub == "none" ? "1" : "2",
            subAnswers: {
              isCane: formDataCamelCase?.assistiveSub == "cane" ? "1" : "2",
              isWalker: formDataCamelCase?.assistiveSub == "walker" ? "1" : "2",
              isProsthesis:
                formDataCamelCase?.assistiveSub == "prosthesis" ? "1" : "2",
              // isOxygen: formDataCamelCase?.assistiveSub == "oxygen" ? "1" : "2",
              isOxygen: "1",
              oxygenSubAnswers: {
                describe: {
                  isPRN: formDataCamelCase?.oxygenDescribe == "prn" ? "1" : "2",
                  isContinuous:
                    formDataCamelCase?.oxygenDescribe == "continous"
                      ? "1"
                      : "2",
                  isDay: formDataCamelCase?.oxygenDescribe == "day" ? "1" : "2",
                  isNight:
                    formDataCamelCase?.oxygenDescribe == "night" ? "1" : "2",
                  comment: formDataCamelCase?.oxygenDescribeComment || "",
                },
                Litres: "",
                // Litres: formDataCamelCase?.litresMin || "",
              },
              isWheelChair:
                formDataCamelCase?.assistiveDevices == "wheelChair" ? "1" : "2",
              isurinal:
                formDataCamelCase?.assistiveDevices == "urinal" ? "1" : "2",
              isBedPan:
                formDataCamelCase?.assistiveDevices == "bedPan" ? "1" : "2",
              isCPAP: formDataCamelCase?.assistiveDevices == "cpap" ? "1" : "2",
              isOther:
                formDataCamelCase?.assistiveDevices == "other" ? "1" : "2",
              isBedsideCommode:
                formDataCamelCase?.assistiveDevices == "isBedsideCommode"
                  ? "1"
                  : "2",
            },
            comment: formDataCamelCase?.assistiveDevicesComment || "",
          };

          medicalData[field] = assistiveDevicesData;
        }

        if (field === "specialists") {
          const medicalSpecData = {
            isSpecialists:
              formDataCamelCase.areYouCurrentlySeeingAnySpecia == "yes"
                ? "1"
                : "2",

            specialistsSubAnswers:
              formDataCamelCase.areYouCurrentlySeeingAnySpecia == "yes"
                ? medicalRowData.map((row) => ({
                    medicalSp: row.medicalSp || "",
                    specialist: row.specialist || "",
                    for: row.for || "",
                  }))
                : [],
            comment:
              formDataCamelCase.areYouCurrentlySeeingAnySpeciaComment || "",
          };
          medicalData[field] = {
            ...medicalSpecData,
          };
        }

        if (field === "pastTwelveMonths") {
          const pastTwelveMonths = {
            pastTwelveMonthsSubAnswers: {
              pcp: {
                isNone: formDataCamelCase.aSeenYourPcp == "none" ? "1" : "2",
                isOne: formDataCamelCase.aSeenYourPcp == "1" ? "1" : "2",
                isTwo: formDataCamelCase.aSeenYourPcp == "2" ? "1" : "2",
                isThree: formDataCamelCase.aSeenYourPcp == "3" ? "1" : "2",
                isFour: formDataCamelCase.aSeenYourPcp == "4" ? "1" : "2",
                isFiveOrMore:
                  formDataCamelCase.aSeenYourPcp == "5_or_more" ? "1" : "2",

                comment: formDataCamelCase.aSeenYourPcpComment || "",
              },

              emergencyRoom: {
                isNone:
                  formDataCamelCase.bVisitedTheEmergencyRoom == "none"
                    ? "1"
                    : "2",
                isOne:
                  formDataCamelCase.bVisitedTheEmergencyRoom == "1" ? "1" : "2",
                isOneSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeEmerOne,
                },
                isTwo:
                  formDataCamelCase.bVisitedTheEmergencyRoom == "2" ? "1" : "2",
                isTwoSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeEmerTwo,
                },
                isThree:
                  formDataCamelCase.bVisitedTheEmergencyRoom == "3" ? "1" : "2",
                isThreeSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeEmerThree,
                },
                isFour:
                  formDataCamelCase.bVisitedTheEmergencyRoom == "4" ? "1" : "2",
                isFourSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeEmerFour,
                },
                isFiveOrMore:
                  formDataCamelCase.bVisitedTheEmergencyRoom == "5_or_more"
                    ? "1"
                    : "2",
                isFiveOrMoreSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeEmerFiveOrMore,
                },

                comment:
                  formDataCamelCase.bVisitedTheEmergencyRoomComment || "",
              },

              hospitalOvernight: {
                isNone:
                  formDataCamelCase.cStayedInTheHospitalOvernight == "none"
                    ? "1"
                    : "2",
                isOne:
                  formDataCamelCase.cStayedInTheHospitalOvernight == "1"
                    ? "1"
                    : "2",

                isOneSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeOverOne,
                },
                isTwo:
                  formDataCamelCase.cStayedInTheHospitalOvernight == "2"
                    ? "1"
                    : "2",
                isTwoSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeOverTwo,
                },
                isThree:
                  formDataCamelCase.cStayedInTheHospitalOvernight == "3"
                    ? "1"
                    : "2",
                isThreeSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeOverThree,
                },
                isFour:
                  formDataCamelCase.cStayedInTheHospitalOvernight == "4"
                    ? "1"
                    : "2",
                isFourSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeOverFour,
                },
                isFiveOrMore:
                  formDataCamelCase.cStayedInTheHospitalOvernight == "5_or_more"
                    ? "1"
                    : "2",
                isFiveOrMoreSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeOverFiveOrMore,
                },

                comment:
                  formDataCamelCase.cStayedInTheHospitalOvernightComment || "",
              },

              nursingHome: {
                isNone:
                  formDataCamelCase.dBeenInANursingHome == "none" ? "1" : "2",
                isOne: formDataCamelCase.dBeenInANursingHome == "1" ? "1" : "2",
                isOneSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeNurOne,
                },
                isTwo: formDataCamelCase.dBeenInANursingHome == "2" ? "1" : "2",
                isTwoSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeNurTwo,
                },
                isThree:
                  formDataCamelCase.dBeenInANursingHome == "3" ? "1" : "2",
                isThreeSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeNurThree,
                },
                isFour:
                  formDataCamelCase.dBeenInANursingHome == "4" ? "1" : "2",
                isFourSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeNurFour,
                },
                isFiveOrMore:
                  formDataCamelCase.dBeenInANursingHome == "5_or_more"
                    ? "1"
                    : "2",
                isFiveOrMoreSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeNurFiveOrMore,
                },
                comment: formDataCamelCase.dBeenInANursingHomeComment || "",
              },

              hadSurgery: {
                isNone: formDataCamelCase.eHadSurgery == "none" ? "1" : "2",
                isOne: formDataCamelCase.eHadSurgery == "1" ? "1" : "2",
                isOneSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeSurOne,
                },
                isTwo: formDataCamelCase.eHadSurgery == "2" ? "1" : "2",
                isTwoSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeSurTwo,
                },
                isThree: formDataCamelCase.eHadSurgery == "3" ? "1" : "2",
                isThreeSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeSurThree,
                },
                isFour: formDataCamelCase.eHadSurgery == "4" ? "1" : "2",
                isFourSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeSurFour,
                },
                isFiveOrMore:
                  formDataCamelCase.eHadSurgery == "5_or_more" ? "1" : "2",
                isFiveOrMoreSubAnswers: {
                  describe: formDataCamelCase.ifOneOrMoreDescribeSurFiveOrMore,
                },

                comment: formDataCamelCase.eHadSurgeryComment || "",
              },
            },
          };

          medicalData[field] = {
            ...pastTwelveMonths,
          };
        }
        if (field === "hospitalizedPrior") {
          const hospitalizedPriorData = {
            isHospitalizedPrior:
              formDataCamelCase?.hospitalizedPrior == "yes" ? "1" : "2",
            hospitalizedPriorSubAnswers: {
              describe: formDataCamelCase.describeHosPrior || "",
            },
            comment: formDataCamelCase?.hospitalizedPriorComment || "",
          };

          medicalData[field] = {
            ...hospitalizedPriorData,
          };

          // } else if (field === "treatments") {
          // const treatmentsData = {
          //     treatmentsSubAnswers: formDataCamelCase.treatments.map((treatment) => ({
          //         [Object.keys(treatment)[0]]: treatment[Object.keys(treatment)[0]] == "1" ? "yes" : "no",
          //         comment: treatment.comment || "",
          //     })),
          // };

          // medicalData[field] = treatmentsData;
        }

        if (field === "healthServices") {
          const healthServicesData = {
            // healthServices: {
            healthServicesSubAnswers: [
              {
                physicalTherapist:
                  formDataCamelCase?.physicaltherapist === "yes" ? "1" : "2",
                comment: formDataCamelCase?.physicaltherapistComment,
              },
              {
                occupationalTherapist:
                  formDataCamelCase?.occupationaltherapist === "yes"
                    ? "1"
                    : "2",
                comment: formDataCamelCase?.occupationaltherapistComment,
              },
              {
                dietician: formDataCamelCase?.dietician === "yes" ? "1" : "2",
                comment: formDataCamelCase?.dieticianComment,
              },
              {
                socialWorker:
                  formDataCamelCase?.socialworker === "yes" ? "1" : "2",
                comment: formDataCamelCase?.socialworkerComment,
              },
              {
                pharmacist: formDataCamelCase?.pharmacist === "yes" ? "1" : "2",
                comment: formDataCamelCase?.pharmacistComment,
              },
              {
                speechTherapist:
                  formDataCamelCase?.speechtherapist === "yes" ? "1" : "2",
                comment: formDataCamelCase?.speechtherapistComment,
              },
              {
                shiropractor:
                  formDataCamelCase?.chiropractor === "yes" ? "1" : "2",
                comment: formDataCamelCase?.chiropractorComment,
              },
              {
                personalCareWorker:
                  formDataCamelCase?.personalcareworker === "yes" ? "1" : "2",
                comment: formDataCamelCase?.personalcareworkerComment,
              },
              {
                mealsOnWheels:
                  formDataCamelCase?.mealsonwheels === "yes" ? "1" : "2",
                comment: formDataCamelCase?.mealsonwheelsComment,
              },
              {
                adultDayCare:
                  formDataCamelCase?.adultdaycare === "yes" ? "1" : "2",
                comment: formDataCamelCase?.adultdaycareComment,
              },
            ],
            // },
          };

          medicalData[field] = {
            ...healthServicesData,
          };
        }

        if (field === "treatments") {
          const treatmentsData = {
            treatmentsSubAnswers: [
              {
                chemotherapy:
                  formDataCamelCase?.chemotherapy === "yes"
                    ? "1"
                    : formDataCamelCase?.chemotherapy === "no"
                    ? "2"
                    : "3",
                comment: formDataCamelCase?.chemotherapyComment,
              },
              {
                catheterCare:
                  formDataCamelCase?.cathetercare === "yes"
                    ? "1"
                    : formDataCamelCase?.cathetercare === "no"
                    ? "2"
                    : "3",
                comment: formDataCamelCase?.cathetercareComment,
              },
              {
                oxygen:
                  formDataCamelCase?.oxygen === "yes"
                    ? "1"
                    : formDataCamelCase?.oxygen === "no"
                    ? "2"
                    : "3",
                comment: formDataCamelCase?.oxygenComment,
              },
              {
                woundCare:
                  formDataCamelCase?.woundcare === "yes"
                    ? "1"
                    : formDataCamelCase?.woundcare === "no"
                    ? "2"
                    : "3",
                comment: formDataCamelCase?.woundcareComment,
              },
              {
                regularInjections:
                  formDataCamelCase?.regularinjections === "yes"
                    ? "1"
                    : formDataCamelCase?.regularinjections === "no"
                    ? "2"
                    : "3",
                comment: formDataCamelCase?.regularinjectionsComment,
              },
              {
                tubeFeedings:
                  formDataCamelCase?.tubefeedings === "yes"
                    ? "1"
                    : formDataCamelCase?.tubefeedings === "no"
                    ? "2"
                    : "3",
                comment: formDataCamelCase?.tubefeedingsComment,
              },
            ],
          };

          medicalData[field] = {
            ...treatmentsData,
          };
        }
      });

      const apiEndpoint = "/hra/assessment";

      const requestData = {
        id: memberId,
        data: {
          name: "Medical History",
          data: {
            ...medicalData,
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi(apiEndpoint, requestData);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        navigate(getReroute());

        setToasterMessage("Data updated successfully");
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Failed to save data");
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Error during data save");
      setToasterOpen(true);
    }
  };

  const handleActivitiesSave = async () => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const activitiesData = {
        difficultyWithActivities: {
          bathing: {
            no: formDataCamelCase.bathing === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.bathing === "needSomeHelp" ? "1" : "2",
            needTotalHelp:
              formDataCamelCase.bathing === "needTotalHelp" ? "1" : "2",
            comment: formDataCamelCase.bathingComment
              ? formDataCamelCase.bathingComment
              : "",
          },
          dressing: {
            no: formDataCamelCase.dressing === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.dressing === "needSomeHelp" ? "1" : "2",
            needTotalHelp:
              formDataCamelCase.dressing === "needTotalHelp" ? "1" : "2",
            comment: formDataCamelCase.dressingComment
              ? formDataCamelCase.dressingComment
              : "",
          },
          eating: {
            no: formDataCamelCase.eating === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.eating === "needSomeHelp" ? "1" : "2",
            needTotalHelp:
              formDataCamelCase.eating === "needTotalHelp" ? "1" : "2",
            comment: formDataCamelCase.eatingComment
              ? formDataCamelCase.eatingComment
              : "",
          },
          gettingInOrOutOfBed: {
            no: formDataCamelCase.gettingInOrOutOfBed === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.gettingInOrOutOfBed === "needSomeHelp"
                ? "1"
                : "2",
            needTotalHelp:
              formDataCamelCase.gettingInOrOutOfBed === "needTotalHelp"
                ? "1"
                : "2",
            comment: formDataCamelCase.gettingInOrOutOfBedComment
              ? formDataCamelCase.gettingInOrOutOfBedComment
              : "",
          },
          gettingInOrOutOfChairs: {
            no: formDataCamelCase.gettingInOrOutOfChairs === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.gettingInOrOutOfChairs === "needSomeHelp"
                ? "1"
                : "2",
            needTotalHelp:
              formDataCamelCase.gettingInOrOutOfChairs === "needTotalHelp"
                ? "1"
                : "2",
            comment: formDataCamelCase.gettingInOrOutOfChairsComment
              ? formDataCamelCase.gettingInOrOutOfChairsComment
              : "",
          },
          toileting: {
            no: formDataCamelCase.toileting === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.toileting === "needSomeHelp" ? "1" : "2",
            needTotalHelp:
              formDataCamelCase.toileting === "needTotalHelp" ? "1" : "2",
            comment: formDataCamelCase.toiletingComment
              ? formDataCamelCase.toiletingComment
              : "",
          },
          walking: {
            no: formDataCamelCase.walking === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.walking === "needSomeHelp" ? "1" : "2",
            needSomeHelpSubAnswers: {
              householdOnly:
                formDataCamelCase.walkNeedSomeHelpSubAnswers === "householdOnly"
                  ? "1"
                  : "2",
              lessThanOneBlock:
                formDataCamelCase.walkNeedSomeHelpSubAnswers ===
                "lessThanOneBlock"
                  ? "1"
                  : "2",
              oneBlock:
                formDataCamelCase.walkNeedSomeHelpSubAnswers === "oneBlock"
                  ? "1"
                  : "2",
              twoOrMore:
                formDataCamelCase.walkNeedSomeHelpSubAnswers === "twoOrMore"
                  ? "1"
                  : "2",
              nonAmbulatory:
                formDataCamelCase.walkNeedSomeHelpSubAnswers === "nonAmbulatory"
                  ? "1"
                  : "2",
              comment: formDataCamelCase.walkNeedSomeHelpSubAnswersComment
                ? formDataCamelCase.walkNeedSomeHelpSubAnswersComment
                : "",
            },
            neededTotalHelp:
              formDataCamelCase.walking === "neededTotalHelp" ? "1" : "2",
            neededTotalHelpSubAnswer: {
              householdOnly:
                formDataCamelCase.walkNeededTotalHelpSubAnswer ===
                "householdOnly"
                  ? "1"
                  : "2",
              lessThanOneBlock:
                formDataCamelCase.walkNeededTotalHelpSubAnswer ===
                "lessThanOneBlock"
                  ? "1"
                  : "2",
              oneBlock:
                formDataCamelCase.walkNeededTotalHelpSubAnswer === "oneBlock"
                  ? "1"
                  : "2",
              twoOrMore:
                formDataCamelCase.walkNeededTotalHelpSubAnswer === "twoOrMore"
                  ? "1"
                  : "2",
              nonAmbulatory:
                formDataCamelCase.walkNeededTotalHelpSubAnswer ===
                "nonAmbulatory"
                  ? "1"
                  : "2",
              comment: formDataCamelCase.walkNeededTotalHelpSubAnswerComment
                ? formDataCamelCase.walkNeededTotalHelpSubAnswerComment
                : "",
            },
            comment: formDataCamelCase.walkingComment
              ? formDataCamelCase.walkingComment
              : "",
          },
          goingUpOrDownStairs: {
            no: formDataCamelCase.goingUpOrDownStairs === "no" ? "1" : "2",
            needSomeHelp:
              formDataCamelCase.goingUpOrDownStairs === "needSomeHelp"
                ? "1"
                : "2",
            needSomeHelpSubAnswers: {
              none:
                formDataCamelCase.stairsNeedSomeHelpSubAnswers === "none"
                  ? "1"
                  : "2",
              threeToFive:
                formDataCamelCase.stairsNeedSomeHelpSubAnswers === "threeToFive"
                  ? "1"
                  : "2",
              sixToTen:
                formDataCamelCase.stairsNeedSomeHelpSubAnswers === "sixToTen"
                  ? "1"
                  : "2",
              moreThanTen:
                formDataCamelCase.stairsNeedSomeHelpSubAnswers === "moreThanTen"
                  ? "1"
                  : "2",
              comment: formDataCamelCase.stairsNeedSomeHelpSubAnswersComment
                ? formDataCamelCase.stairsNeedSomeHelpSubAnswersComment
                : "",
            },
            neededTotalHelp:
              formDataCamelCase.goingUpOrDownStairs === "neededTotalHelp"
                ? "1"
                : "2",
            neededTotalHelpSubAnswer: {
              none:
                formDataCamelCase.stairsNeededTotalHelpSubAnswer === "none"
                  ? "1"
                  : "2",
              threeToFive:
                formDataCamelCase.stairsNeededTotalHelpSubAnswer ===
                "threeToFive"
                  ? "1"
                  : "2",
              sixToTen:
                formDataCamelCase.stairsNeededTotalHelpSubAnswer === "sixToTen"
                  ? "1"
                  : "2",
              moreThanTen:
                formDataCamelCase.stairsNeededTotalHelpSubAnswer ===
                "moreThanTen"
                  ? "1"
                  : "2",
              comment: formDataCamelCase.stairsNeededTotalHelpSubAnswerComment
                ? formDataCamelCase.stairsNeededTotalHelpSubAnswerComment
                : "",
            },
            comment: formDataCamelCase.goingUpOrDownStairsComment
              ? formDataCamelCase.goingUpOrDownStairsComment
              : "",
          },
        },
      };

      const payload = {
        id: assessmentId,
        data: {
          name: "Activities of Daily Living",
          data: {
            ...activitiesData,
          },
        },
      };

      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Assessment Updated Successfully");
        // console.log(getReroute());
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleMiniSave = async () => {
    try {
      const assessmentDataFromAPI = await getAssessmentData();

      const miniAssessmentData = assessmentDataFromAPI.data.data.mini.data;
      if (!miniAssessmentData || Object.keys(miniAssessmentData).length === 0) {
        return;
      }

      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const miniFormData = {
        threeWordRecall: {
          wordList: formDataCamelCase?.wordListVersion || "",
          personsAnswers: formDataCamelCase?.personsAnswers || "",
          wordRecall: wordRecallRowData?.points || "",
          clockDraw: clockDrawRowData?.points || "",
          totalScore: totalScoreRowData?.points || "",
        },
        recommendations: {
          furtherCognitive: {
            furtherCognitive: formDataCamelCase?.recommendationsFurther || "",
            comment: formDataCamelCase?.recommendationsFurtherComment || "",
          },
          other: {
            other: formDataCamelCase?.recommendationsOther || "",
            comment: formDataCamelCase?.recommendationsOtherComment || "",
          },
        },
      };

      const payload = {
        id: assessmentId,
        data: {
          name: "Mini - Cog",
          data: miniFormData,
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);

      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        // console.log(getReroute());
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  // const handlePreventiveSave = async () => {
  //   try {
  //     const assessmentDataFromAPI = await getAssessmentData();

  //     const preventiveAssessmentData =
  //       assessmentDataFromAPI?.data?.data?.preventive?.data;
  //     if (
  //       !preventiveAssessmentData ||
  //       Object.keys(preventiveAssessmentData).length === 0
  //     ) {
  //       return;
  //     }

  //     const formDataCamelCase = {};
  //     Object.keys(formData).forEach((key) => {
  //       const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
  //       formDataCamelCase[camelCaseKey] = formData[key];
  //     });

  //     const preventiveData = {
  //       preventiveCare: {
  //         pastThreeYearsSubAnswers: preventiveRowData?.map((subAnswer) => ({
  //           screen: subAnswer?.screen || "",
  //           answer: subAnswer?.answer.toString() || "",
  //           date: subAnswer?.date || "",
  //           method: subAnswer?.method || "",
  //           recommendation: subAnswer?.recommendation.toString() || "",
  //           educationCompleted: subAnswer?.educationCompleted || "",
  //         })),
  //       },

  //       oneTimeScreenForAbdominal: {
  //         isOneTimeScreenForAbdominal:
  //           formDataCamelCase?.oneTimeScreenForAbdominalAorti == "yes"
  //             ? "1"
  //             : "2" || "",
  //         isOneTimeScreenForAbdominalSubAnswer: {
  //           isEducationProvided:
  //             formDataCamelCase?.educationProvidedAbdominal == "yes"
  //               ? "1"
  //               : "2",
  //           comment: formDataCamelCase?.educationProvidedAbdominalComment || "",
  //         },
  //         comment: formDataCamelCase?.oneTimeScreenForAbdominalAortiComment,
  //       },
  //       oneTimeScreenForHepatitis: {
  //         isoneTimeScreenForHepatitis:
  //           formDataCamelCase?.oneTimeScreenForHepatitisCIfBo == "yes"
  //             ? "1"
  //             : "2" || "",

  //         isoneTimeScreenForHepatitisSubAnswer: {
  //           isEducationProvided:
  //             formDataCamelCase?.educationProvidedHepatitis == "yes"
  //               ? "1"
  //               : "2" || "",
  //           comment: formDataCamelCase?.educationProvidedHepatitisComment || "",
  //         },
  //         comment:
  //           formDataCamelCase?.oneTimeScreenForHepatitisCIfBoComment || "",
  //       },
  //       recommendations: {
  //         recommendationsSubAnswers: [
  //           {
  //             abnominalAneurysmScreening:
  //               formDataCamelCase?.recommendationsAbdominal || "2",

  //             comment: formDataCamelCase?.recommendationsAbdominalComment,
  //           },
  //           {
  //             hepatitisCScreening:
  //               formDataCamelCase?.recommendationsHepatitis || "2",
  //             comment: formDataCamelCase?.recommendationsHepatitisComment,
  //           },
  //         ],
  //       },
  //     };

  //     const payload = {
  //       id: assessmentId,
  //       data: {
  //         name: "Preventive Care",
  //         data: preventiveData,
  //       },
  //     };
  //     if (tabId == 1) {
  //       updateHraStatusToInProgress();
  //     }
  //     const response = await putApi("/hra/assessment", payload);

  //     if (response.status === 200) {
  //       setToasterOpen(true);
  //       setToasterSeverity(Labels.SUCCESS);
  //       setToasterMessage("success");
  //       // console.log(getReroute());
  //       navigate(getReroute());
  //     } else {
  //       console.error("Error saving data:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error saving data:", error);
  //   }
  // };

  const handleAllergiesSave = async () => {
    // console.log("allergies");
    try {
      const assessmentDataFromAPI = await getAssessmentData();

      const allergiesAssessmentData =
        assessmentDataFromAPI?.data?.data?.allergies?.data;
      if (
        !allergiesAssessmentData ||
        Object.keys(allergiesAssessmentData).length === 0
      ) {
        return;
      }

      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const allergiesData = {
        allergies: {
          allergiesSubAnswers: allergiesRowData?.map((subAnswer) => ({
            substance: subAnswer?.substance || "",
            reaction: subAnswer?.reaction || "",
          })),
          allergies: formDataCamelCase?.allergies === "yes" ? "1" : "2",
          comment: formDataCamelCase?.allergiesComment,
        },

        medicationCompliance: {
          medicationComplianceSubAnswers: {
            takeYourMedicine: {
              takeYourMedicine:
                formDataCamelCase?.doyoueverforgettotakeyourmedicine == "yes"
                  ? "1"
                  : "2",
              comment:
                formDataCamelCase?.doyoueverforgettotakeyourmedicineComment,
            },
            attentionToYourMedication: {
              attentionToYourMedication:
                formDataCamelCase?.doyousometimesnotpayenoughattentiontoyourmedication ==
                "yes"
                  ? "1"
                  : "2",
              comment:
                formDataCamelCase?.doyousometimesnotpayenoughattentiontoyourmedicationComment,
            },
            longtermBenefit: {
              longtermBenefit:
                formDataCamelCase?.doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacist ==
                "yes"
                  ? "1"
                  : "2",
              comment:
                formDataCamelCase?.doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacistComment,
            },
            stopTakingMedicine: {
              stopTakingMedicine:
                formDataCamelCase?.whenyoufeelbetterdoyousometimesstoptakingyourmedicine ==
                "yes"
                  ? "1"
                  : "2",
              comment:
                formDataCamelCase?.whenyoufeelbetterdoyousometimesstoptakingyourmedicineComment,
            },
            feelWorse: {
              feelWorse:
                formDataCamelCase?.sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingit ==
                "yes"
                  ? "1"
                  : "2",
              comment:
                formDataCamelCase?.sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingitComment,
            },
            forgetToRefill: {
              forgetToRefill:
                formDataCamelCase?.doyousometimesforgettorefillyourprescriptionontime ==
                "yes"
                  ? "1"
                  : "2",
              comment:
                formDataCamelCase?.doyousometimesforgettorefillyourprescriptionontime,
            },
          },
        },
        medications: {
          medication: medicationsRowData?.map((subAnswer) => ({
            diagnosis: subAnswer?.diagnosis || "",
            labelName: subAnswer?.labelName || "",
            doseOrUnits: subAnswer?.doseOrUnits || "",
            route: subAnswer?.route || "",
            frequency: subAnswer?.frequency || "",
            prescribingPhysician: subAnswer?.prescribingPhysician || "",

            status: subAnswer?.status || { taking: "2", notTaking: "2" },
          })),

          comment: formDataCamelCase?.medicationsComment,
        },
        counterMedications: {
          counterMedicationsSubAnswers: counterMedicationsRowData?.map(
            (subAnswer) => ({
              date: subAnswer?.date || "",
              description: subAnswer?.description || "",
              doseOrUnits: subAnswer?.doseOrUnits || "",
              route: subAnswer?.route || "",
              frequency: subAnswer?.frequency || "",
            }),
          ),

          counterMedications:
            formDataCamelCase?.otcmSupplements === "yes" ? "1" : "2",
          comment: formDataCamelCase?.otcmSupplementsComment,
        },
        recommendations: {
          recommendationSubAnswers: [
            {
              medicationAdherence:
                formDataCamelCase?.recommendationsDoctor || "2",
              comment: formDataCamelCase?.recommendationsDoctorComment,
            },
            {
              sideEffects: formDataCamelCase?.recommendationsSide || "2",
              comment: formDataCamelCase?.recommendationsSideComment,
            },
            {
              other: formDataCamelCase?.recommendationsOtherss || "2",
              comment: formDataCamelCase?.recommendationsOtherssComment,
            },
          ],
        },
        educatedOnImportance: {
          educatedOnImportance: formDataCamelCase?.educatedonImportance || "2",
          comment: formDataCamelCase?.educatedonImportanceComment,
        },
      };

      const payload = {
        id: assessmentId,
        data: {
          name: "Allergies / Medications",
          data: allergiesData,
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);

      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        // console.log(getReroute());
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleExamReviewSave = async () => {
    try {
      let examReviewData = setExamReviewData(formData);

      const payload = {
        id: assessmentId,
        data: {
          name: "Exam Review",
          data: {
            ...examReviewData,
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        // console.log(getReroute());
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleHomeSafetySave = async () => {
    try {
      let homeSafetyData = setHomeSafetyData(formData);

      const payload = {
        id: assessmentId,
        data: {
          name: "Home Safety & Personal Goals",
          data: {
            ...homeSafetyData,
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  //////////////////////////ros/////////////////////////////
  const handleRosSave = async () => {
    try {
      let rosData = setRosData(formData);

      const payload = {
        id: assessmentId,
        data: {
          name: "Review Of Systems And Diagnoses",
          data: {
            ...rosData,
          },
        },
      };
      const response = await putApi("/hra/assessment", payload);
      // console.log("response:", response);
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        navigate(getReroute());
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const [previousEvaluationDateTime, setPreviousEvaluationDateTime] =
    useState(null);
  const [errorSignSubmitBtn, setErrorSignSubmitBtn] = useState(false);
  const [errorSignSubmitBtnMsg, setErrorSignSubmitBtnMsg] = useState("");
  // console.log("formData", formData);
  const _handleDateChange = (inputProps, date) => {
    const dateString = date; //event.target.value;
    const isValidFormat = true; ///^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(dateString);
    const _isValidDate = !isNaN(Date.parse(dateString));
    const selectedYear = dateString.getFullYear();
    if (!_isValidDate || selectedYear < 1000 || !Boolean(dateString)) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Invalid date format");
      return;
    }
    if (!isValidFormat) {
      return;
    }

    if (isValidFormat && inputProps.name === "examFinishDate") {
      // Check if examFinishDate is greater than evaluationDate
      if (evaluationDate && new Date(dateString) < new Date(evaluationDate)) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(
          "Exam Finish Date can be greater than Evaluation Date",
        );
        return;
      } else if (
        new Date(dateString).getTime() < new Date(evaluationDate).getTime()
      ) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(
          "Exam Finish Date can be greater than Evaluation Date",
        );
        return;
      } else if (selectedYear < 1000 || dateString == "Invalid Date") {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Invalid Date");
        return;
      }

      setExamFinishDate(dateString);

      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        updatedFormData.examFinishDate = dateString;

        return updatedFormData;
      });
    } else if (isValidFormat && inputProps.name === "evaluationDate") {
      //console.log("dateString", dateString);
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        updatedFormData.evaluationDate = dateString;

        const requiresConfirmation = inputProps.name === "evaluationDate";

        if (requiresConfirmation) {
          // setIsEvaluationDateAlertOpen(true);
          // setEvaluationDate(dateString);
        } else {
          setEvaluationDate(dateString);
          setFormData((prevFormData) => ({
            ...prevFormData,
            evaluationDate: dateString,
          }));
        }

        return updatedFormData;
      });

      setPreviousEvaluationDateTime(evaluationDateTime);
      setEvaluationDateTime(dateString);
    } else if (!isValidFormat) {
      return;
    }
  };
  const handleDateChange = (inputProps, date) => {
    const dateString = date;
    if (inputProps.name === "examFinishDate") {
      setExamFinishDate(dateString);
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        updatedFormData.examFinishDate = dateString;

        return updatedFormData;
      });
    }
    if (inputProps.name === "evaluationDate") {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        updatedFormData.evaluationDate = dateString;

        const requiresConfirmation = inputProps.name === "evaluationDate";
        setEvaluationDate(dateString);
        return updatedFormData;
      });
      handleAssignedTermDateTypeUpdate(dateString);
    }
  };
  const clearExamFinishDate = () => {
    setExamFinishDate(null);
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };

      updatedFormData.examFinishDate = null;

      return updatedFormData;
    });
  };
  const [evaluationDateTime, setEvaluationDateTime] = useState(null);
  const [disableSignSubmitButton, setDisableSignSubmitButton] = useState(false);
  const [isDateErrorAlertOpen, setIsDateErrorAlertOpen] = useState(false);
  useEffect(() => {
    if (
      !evaluationDate?.toString().includes("T") ||
      !examFinishDate?.toString().includes("T") ||
      examFinishDate == null ||
      evaluationDate == null
    ) {
      setDisableSignSubmitButton(true);
    } else if (evaluationDate >= examFinishDate) {
      setIsDateErrorAlertOpen(true);
      setDisableSignSubmitButton(true);
    } else if (examFinishDate > evaluationDate) {
      setDisableSignSubmitButton(false);
      setIsDateErrorAlertOpen(false);
    }
  }, [evaluationDate, examFinishDate]);
  const handleAssignedTermDateTypeUpdate = async (evaluationDate) => {
    const memberObjIds = [_memberIdForQueryForm];

    if (evaluationDate) {
      const requestBody = {
        memberObjIds,
        evaluationDate: evaluationDate,
      };

      try {
        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );
        setIsEvaluationDateAlertOpen(false);
        if (response.status === 200) {
          // setToasterOpen(true);
          // setToasterSeverity(Labels.SUCCESS);
          // setToasterMessage(response.data.message);

          setEvaluationDate(evaluationDate);
          setFormData((prevFormData) => ({
            ...prevFormData,
            evaluationDate: evaluationDate,
          }));
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsEvaluationDateAlertOpen(false);
        //setSelectedVisitType(null);
      }
    }
  };

  const handleAssignedTermDateTypeCancel = () => {
    setIsEvaluationDateAlertOpen(false);
    // Revert the selected evaluation date and time back to the previous value
    setEvaluationDateTime(previousEvaluationDateTime);
    setEvaluationDate(previousEvaluationDateTime);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubSubQuestionChange = (event, subQ, parentQuestionName) => {
    const selectedValue = event.target.value;

    const newFormData = { ...formData };

    const isDeselecting = newFormData[subQ.name] === selectedValue;

    // Update formData with the selected value
    newFormData[subQ.name] = isDeselecting ? "" : selectedValue;
    newFormData[parentQuestionName] = isDeselecting ? "" : selectedValue;

    setFormData(newFormData);

    if (parentQuestionName) {
      SetSubSubSelectIndex((prevSubSubSelectIndex) => ({
        ...prevSubSubSelectIndex,
        [subQ.subQuestionId]: isDeselecting ? "" : selectedValue,
      }));
    }
  };

  const handleSubQuestionChange = (
    event,
    language,
    parentQuestionName,
    selectedValue,
    // newValues,
    // inputProps
  ) => {
    event.preventDefault();
    const { value, name } = event.target;
    //console.log(name,"name")

    // let values = inputProps?.isExclusive ? [newValues] : newValues;
    // console.log( newValues, values,"handle change--");

    setFormData((prevFormData) => {
      const updatedFormData = parentQuestionName
        ? {
            ...prevFormData,
            [parentQuestionName]: {
              ...prevFormData[parentQuestionName],
              [selectedValue]: true,
            },
          }
        : {
            ...prevFormData,
            [selectedValue]: true,
          };

      return updatedFormData;
    });

    if (parentQuestionName) {
      const selectedOptions = formData[name] || [];

      const updatedOptions = selectedOptions?.includes(value)
        ? selectedOptions.filter((option) => option !== value)
        : [...selectedOptions, value];

      const newFormData = { ...formData, [parentQuestionName]: selectedValue };
      setFormData(newFormData);

      SetSubSelectIndex((prevSubSelectIndex) => ({
        ...prevSubSelectIndex,
        [language.subQuestionId]: selectedValue,
      }));

      setFormData(newFormData);

      const isPainChronicDeselected =
        parentQuestionName === "painChronic" && !value;

      if (parentQuestionName === "painChronic" && value === "Yes") {
        newFormData.pain_describe = "";
        newFormData.describeComment = "";
        newFormData.whereSubtext = "";
        newFormData.rateYourPainSubtext = "";
        newFormData.frequency = "";
        newFormData.frequencyComment = "";
      }

      if (isPainChronicDeselected) {
        newFormData.pain_describe = "";
        newFormData.describeComment = "";
        newFormData.whereSubtext = "";
        newFormData.rateYourPainSubtext = "";
        newFormData.frequency = "";
        newFormData.frequencyComment = "";
      }

      const isPainManagementTimingDeselected =
        parentQuestionName === "painManagementTiming" && !value;

      if (parentQuestionName === "painManagementTiming" && value === "Yes") {
        newFormData.isPatientResponding = "";
      }

      if (isPainManagementTimingDeselected) {
        newFormData.isPatientResponding = "";
      }

      const isOpoidMedicationDeselected =
        parentQuestionName === "opioidMedication" && !value;

      if (parentQuestionName === "opioidMedication" && value === "Yes") {
        newFormData.tolerance = "";
        newFormData.toleranceComment = "";
        newFormData.withdrawal = "";
        newFormData.withdrawalComment = "";
        newFormData.increasedUsage = "";
        newFormData.increasedUsageComment = "";
        newFormData.unsuccessEffort = "";
        newFormData.unsuccessEffortComment = "";
        newFormData.excessTimeSpent = "";
        newFormData.excessTimeSpentComment = "";
        newFormData.patientKnowledge = "";
        newFormData.patientKnowledgeComment = "";
        newFormData.psychologicalProblem = "";
        newFormData.psychologicalProblemComment = "";
      }

      if (isOpoidMedicationDeselected) {
        newFormData.tolerance = "";
        newFormData.toleranceComment = "";
        newFormData.withdrawal = "";
        newFormData.withdrawalComment = "";
        newFormData.increasedUsage = "";
        newFormData.increasedUsageComment = "";
        newFormData.unsuccessEffort = "";
        newFormData.unsuccessEffortComment = "";
        newFormData.excessTimeSpent = "";
        newFormData.excessTimeSpentComment = "";
        newFormData.patientKnowledge = "";
        newFormData.patientKnowledgeComment = "";
        newFormData.psychologicalProblem = "";
        newFormData.psychologicalProblemComment = "";
      }
    }
  };

  const addTableRow = (e, targettable) => {
    e.preventDefault();
    let newRowData = [
      ...tableRowData,
      {
        Diagnoses: "",
        LabelName: "",
        DosePerUnits: "",
        Route: "",
        Frequency: "",
        PrescribingPhysician: "",
        Status: "",
      },
    ];

    const updatedTableData = { ...tableData };

    if (!updatedTableData[targettable]) {
      updatedTableData[targettable] = [];
    }

    updatedTableData[targettable].push(newRowData);

    setTableRowData(() => newRowData);
    // setTableData(updatedTableData);
  };

  const deleteTableRow = (e, rowid) => {
    // e.preventDefault();
    let newRowData = [...tableRowData];
    newRowData.splice(rowid, 1);
    setTableRowData(() => newRowData);
  };

  const handleCardClick = (index) => {
    setCurrSection(index);
    const sectionRef = sectionRefs[index];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "nearest",
      });
    }
  };

  let isAllQuestionsConfigured = false,
    isAllConfigured = false,
    isAnyModified = false;
  sections.forEach((el) => {
    el.questions.forEach((ele) => {
      isAllConfigured = ele.isConfigured ? true : false;
      isAnyModified = ele.isConfigured ? true : false;
    });
  });

  const handleFieldConfigCheck = (e) => {
    e.preventDefault();
    let isAllQuestionsConfigured = false,
      isAllSectionsConfigured = false,
      isInputPropsOpen = false,
      isAllConfigured = false,
      inputNotSavedLabel = "";

    sections.forEach((el) => {
      isAllSectionsConfigured = el.questions.length === 0 ? true : false;
      el.questions.forEach((ele) => {
        isAllConfigured = ele.isConfigured ? true : false;
      });
    });

    if (formData && Object.keys(formData).length > 0) {
      isInputPropsOpen = true;
      inputNotSavedLabel = formData.label;
    } else {
      isInputPropsOpen = false;
    }

    if (isAllConfigured === true) {
      //handleFormSubmit(e);
      //setToasterOpen(true);
      //setToasterSeverity("success");
      //setToasterMessage(successMsg);
      //redirect to client select dropdown
      // let newValue = localStorage.getItem("clientData")
      //   ? JSON.parse(localStorage.getItem("clientData"))
      //   : null;
      // newValue.isSelected = false;
      setClientData(null);
      // localStorage.setItem("clientData", JSON.stringify(newValue));
      setTimeout(() => {
        navigate(`/AdminDashboard/CreateHRAForm`);
      }, 2000);
    }
    // if (isAllSectionsConfigured === true) {
    //   handleFormSubmit(e);
    //   setToasterOpen(true);
    //   setToasterSeverity("error");
    //   setToasterMessage(secNotConfig);
    // }
    // if (isAllConfigured === false) {
    //   setToasterOpen(true);
    //   setToasterSeverity("error");
    //   setToasterMessage(notConfig);
    // }
    else {
      // console.log(sections, "sections", dForm, "dForm");
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getFormData();
      setApiData(data);
    };

    getData();
  }, []);

  const myTheme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: "black",
            margin: "5px 15px 0 0",
            borderStyle: "none",
            borderRadius: "5px!important",
            textTransform: "none",
            border: `1px solid var(--mainTheme)`,
            width: "100%",
            whiteSpace: "normal",
            display: "flex",
            flexWrap: "wrap",
            padding: "5px",
            "&::before": {
              content: '""',
              textTransform: "uppercase",
            },
            "&.Mui-selected ": {
              backgroundColor: "var(--mainTheme)",
              color: "white",
              border: "1px solid var(--mainTheme) !important",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "var(--mainTheme)",
              color: "white",
              border: "1px solid var(--mainTheme) !important",
            },
          },
        },
      },
    },
  });

  const handleChangeSpecialDiet = (e) => {
    const { name, value } = e.target;

    const selectedOptions = formData[name] || [];

    const updatedOptions = selectedOptions?.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    const isOtherSelected = updatedOptions?.includes("other");

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: updatedOptions,
      isDescribeVisible: isOtherSelected,
    }));
  };
  const handleChange = (e, inputProps, subques) => {
    const { name, value } = e.target;
    // let values = inputProps?.isExclusive ? [newValues] : newValues;
    // console.log(name, inputProps.name, newValues, values,"handle change--");

    const updatedFormData = { ...formData };

    const isMalnutritionDeselected = name === "malnutrition" && !value;

    if (name === "bmi" && value === "malnutrition") {
      updatedFormData.describe = "";
      updatedFormData.describeComment = "";
      updatedFormData.supportedBy = "";
      updatedFormData.supportedByComment = "";
      updatedFormData.malnutrition = "";
      updatedFormData.malnutritionComment = "";
    }

    const isPatientExperiencePainDeselected =
      name === "patientExperiencePain" && !value;

    if (name === "patientExperiencePain" && value === "Yes") {
      updatedFormData.painAcute = "";
      updatedFormData.painAcuteComment = "";
      updatedFormData.painChronic = "";
      updatedFormData.painChronicComment = "";
      updatedFormData.painManagementTiming = "";
      updatedFormData.painManagementTimingComment = "";
      updatedFormData.opioidMedication = "";
      updatedFormData.opioidMedicationComment = "";
      updatedFormData.patientAdvised = "";
      updatedFormData.patientAdvisedComment = "";
    }
    const isWalkingDeselected = name === "walking" && !value;

    if (name === "walking" && value === "needSomeHelp") {
      updatedFormData.walk_needSomeHelpSubAnswers = "";
    }

    const isWalkingneedTotalHelpDeselected = name === "walking" && !value;

    if (name === "walking" && value === "neededTotalHelp") {
      updatedFormData.walk_neededTotalHelpSubAnswer = "";
    }

    const isGoingUpOrDownStairsDeselected = name === "needSomeHelp" && !value;

    if (name === "goingUpOrDownStairs" && value === "needSomeHelp") {
      updatedFormData.stairs_needSomeHelpSubAnswers = "";
    }

    const isGoingUpOrDownStairsNeedTotalHelpDeselected =
      name === "neededTotalHelp" && !value;

    if (name === "goingUpOrDownStairs" && value === "neededTotalHelp") {
      updatedFormData.stairs_neededTotalHelpSubAnswer = "";
    }

    if (name === "feet" || name === "inch" || name === "patientsWeight") {
      // Calculate BMI
      const heightInMeters =
        updatedFormData.feet * 0.3048 + updatedFormData.inch * 0.0254;
      const weightInKilograms = updatedFormData.patientsWeight * 0.453592;
      updatedFormData.bmii = (
        weightInKilograms / Math.pow(heightInMeters, 2)
      ).toFixed(2);
    }

    // if (name === "visitType") {
    //   if (value === "virtual") {
    //     setFormData(updatedFormData);
    //   } else if (value === "inPerson") {
    //   }
    // }

    if (inputProps && inputProps.type === "options_tree") {
      let selectedValue = value;

      if (inputProps.isExclusive) {
        // For first-level questions
        updatedFormData[name] = value;

        if (isMalnutritionDeselected) {
          updatedFormData.malnutrition = "";
          updatedFormData.malnutritionComment = "";
          updatedFormData.describe = "";
          updatedFormData.describeComment = "";
          updatedFormData.supportedBy = "";
          updatedFormData.supportedByComment = "";
        }

        if (isPatientExperiencePainDeselected) {
          updatedFormData.painAcute = "";
          updatedFormData.painAcuteComment = "";
          updatedFormData.painChronic = "";
          updatedFormData.painChronicComment = "";
          updatedFormData.painManagementTiming = "";
          updatedFormData.painManagementTimingComment = "";
          updatedFormData.opioidMedication = "";
          updatedFormData.opioidMedicationComment = "";
          updatedFormData.patientAdvised = "";
          updatedFormData.patientAdvisedComment = "";
        }

        if (isWalkingDeselected) {
          updatedFormData.walk_needSomeHelpSubAnswers = "";
        }

        if (isWalkingneedTotalHelpDeselected) {
          updatedFormData.walk_neededTotalHelpSubAnswer = "";
        }

        if (isGoingUpOrDownStairsDeselected) {
          updatedFormData.stairs_needSomeHelpSubAnswers = "";
        }

        if (isGoingUpOrDownStairsNeedTotalHelpDeselected) {
          updatedFormData.stairs_neededTotalHelpSubAnswer = "";
        }

        if (inputProps.hasSubQuestions && updatedFormData.subOptions) {
          updatedFormData.subOptions = "";
        }
      } else {
        const currentValue = updatedFormData[name];

        if (currentValue === value) {
          selectedValue = "";

          if (inputProps.hasSubQuestions && updatedFormData.subOptions) {
            updatedFormData.subOptions = "";
          }
        }
      }

      if (inputProps.isExclusive) {
        // For first-level questions
        updatedFormData[name] = value;

        if (isMalnutritionDeselected) {
          updatedFormData.malnutrition = "";
          updatedFormData.malnutritionComment = "";
          updatedFormData.describe = "";
          updatedFormData.describeComment = "";
          updatedFormData.supportedBy = "";
          updatedFormData.supportedByComment = "";
        }

        if (isPatientExperiencePainDeselected) {
          updatedFormData.painAcute = "";
          updatedFormData.painAcuteComment = "";
          updatedFormData.painChronic = "";
          updatedFormData.painChronicComment = "";
          updatedFormData.painManagementTiming = "";
          updatedFormData.painManagementTimingComment = "";
          updatedFormData.opioidMedication = "";
          updatedFormData.opioidMedicationComment = "";
          updatedFormData.patientAdvised = "";
          updatedFormData.patientAdvisedComment = "";
        }

        if (isWalkingDeselected) {
          updatedFormData.needSomeHelpSubAnswers = "";
        }

        if (isWalkingneedTotalHelpDeselected) {
          updatedFormData.neededTotalHelpSubAnswer = "";
        }

        if (isGoingUpOrDownStairsDeselected) {
          updatedFormData.needSomeHelpSubAnswers = "";
        }

        if (isGoingUpOrDownStairsNeedTotalHelpDeselected) {
          updatedFormData.neededTotalHelpSubAnswer = "";
        }

        if (inputProps.hasSubQuestions && updatedFormData.subOptions) {
          updatedFormData.subOptions = "";
        }
      } else {
        const currentValue = updatedFormData[name];

        if (currentValue === value) {
          selectedValue = "";

          if (inputProps.hasSubQuestions && updatedFormData.subOptions) {
            updatedFormData.subOptions = "";
          }
        }
      }

      // Store as array or string based on your preference
      updatedFormData[name] =
        inputProps.isExclusive && Array.isArray(selectedValue)
          ? selectedValue.join(", ")
          : selectedValue;

      const newSelectIndex = { ...selectIndex };
      newSelectIndex[inputProps.questionId + selectedValue] = true;

      for (const key in newSelectIndex) {
        if (
          key.startsWith(inputProps.questionId) &&
          key !== inputProps.questionId + selectedValue
        ) {
          newSelectIndex[key] = false;
        }
      }

      setSelectIndex(newSelectIndex);
      setSelectedOption(selectedValue);
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
  };

  const handleChangeRadio = (e, inputProps) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleEditForm = (e) => {
    navigate(`/AdminDashboard/CreateHRAForm`);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px gray",
    backgroundColor: "white",
    margin: "5%",
  };

  useEffect(() => {
    setLoading(() => true);
    const getData = async () => {
      const fullAPIData = await getFormData();
      if (fullAPIData && fullAPIData.length > 0) {
        const currentSectionData = fullAPIData[currSection]
          ? [fullAPIData[currSection]]
          : [];
        const data =
          currentSectionData && currentSectionData.length > 0
            ? currentSectionData[0].questions
            : [];
        /* setApiData(() => fullAPIData);*/
        setSections(() => fullAPIData);
        setDForm(() => data);
        setLoading(() => false);
      }
    };

    getData();
  }, []);
  useEffect(() => {
    setLoading(() => true);
    const getData = async () => {
      if (sections && sections.length > 0) {
        const currentSectionData = sections[currSection]
          ? [sections[currSection]]
          : [];
        const data =
          currentSectionData && currentSectionData.length > 0
            ? currentSectionData[0].questions
            : [];

        setDForm(() => data);
        /* setLoading(() => false);*/
      }
    };

    getData();
  }, [sections, currSection]);

  useEffect(() => {
    let isAllConfigured = false;
    let isAnyModified = false;

    sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.isConfigured) {
          isAllConfigured = true;
        }
        if (question.isModified) {
          isAnyModified = true;
        }
      });
    });

    // if (isAllConfigured === false) {
    //   setToasterOpen(false);
    //   setToasterSeverity("error");
    //   setToasterMessage(addOrModified);
    //   console.log(isAllConfigured, "kl");
    // }

    // if (!isAllConfigured && !isAnyModified) {
    //   setToasterOpen(true);
    //   setToasterSeverity("error");
    //   setToasterMessage(addOrModified);
    // }
  }, [dForm, sections]);

  function isValidUrl(url) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  }
  const showQueryFormBtn = sessionStorage.getItem("showQueryFormBtn");

  const getMaxDate = (inputProps) => {
    if (
      inputProps.name == "evaluationDate" ||
      inputProps.name == "examFinishDate"
    ) {
      const roleId = sessionStorage.getItem("roleId");
      const maxDate = new Date();
      maxDate.setFullYear(new Date().getFullYear() + 1);
      if (roleId == 1 || roleId == 2) {
        return dayjs(maxDate);
      }
      if (roleId == 5 && !evaluationDate) {
        return dayjs(maxDate);
      }
      if (roleId == 5 && evaluationDate) {
        return dayjs(evaluationDate);
      }
      if (
        roleId == 5 &&
        evaluationDate &&
        inputProps.name == "examFinishDate"
      ) {
        return dayjs(evaluationDate);
      }
    }
  };
  const getMinDate = (inputProps) => {
    const minDate = new Date();
    const roleId = sessionStorage.getItem("roleId");
    minDate.setFullYear(new Date().getFullYear() - 1);
    if (
      inputProps.name == "evaluationDate" ||
      inputProps.name == "examFinishDate"
    ) {
      if (roleId == 1 || roleId == 2) {
        return dayjs(minDate);
      }
      if (roleId == 5 && !evaluationDate) {
        return dayjs(new Date());
      }
      if (roleId == 5 && evaluationDate) {
        return dayjs(evaluationDate);
      }
    }
  };
  return (
    <>
      {showQueryFormBtn == "true" ? (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            background: "var(--mainTheme)",
            cursor: "pointer",
            position: "fixed",
            bottom: "55px",
            right: "70px",
            zIndex: "1000",
          }}
        >
          Query Form
        </Button>
      ) : null}

      <QueryForm
        open={open}
        setOpen={setOpen}
        memberId={_memberIdForQueryForm}
        formData={formData}
      />
      <Grid item xs={10} marginTop="80px" marginBottom={1}>
        <HeaderCard formData={formData} />
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        style={{ fontFamily: "Inter !important" }}
      >
        <Grid
          item
          xs={10}
          style={{ padding: "2px", textAlign: "center !important" }}
        >
          <Card
            style={{
              height: "auto",
              minHeight: "76vh",
              maxHeight: "450px",
              overflowY: "auto",
              marginLeft: "10px",
            }}
          >
            {apiData.map((section, index) => (
              <div key={index}>
                <div ref={sectionRefs[index]} className="section">
                  <span
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    <CardHeader
                      title={section.sectionName}
                      action={
                        <div
                          style={{
                            width: "100% !important",
                            margin: "0 !important",
                            padding: "0 !important",
                          }}
                        >
                          <IconButton
                            style={{
                              float: "right",
                              color: "#FFFFFF",
                            }}
                            aria-label="Section minimise"
                            size="small"
                            onClick={() => {
                              if (isShowSection[index] !== undefined) {
                                setIsShowSection({
                                  ...isShowSection,
                                  [index]: !isShowSection[index],
                                });
                              } else {
                                setIsShowSection({
                                  ...isShowSection,
                                  [index]: false,
                                });
                              }
                            }}
                          >
                            {isShowSection[index] === true ||
                            isShowSection[index] === undefined ? (
                              <KeyboardControlKeyIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </div>
                      }
                      sx={{
                        backgroundColor: `var(--mainTheme)`,
                        color: "white",
                        padding: "10px",
                        textAlign: "left !important",
                        fontWeight: 500,
                      }}
                      titleTypographyProps={{ variant: "outline" }}
                    />
                  </span>

                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    style={{
                      marginBottom: "20px",
                      marginTop: "10px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {isShowSection[index] === true ||
                      isShowSection[index] === undefined ? (
                        section?.questions?.map((inputProps, quesindex) => {
                          if (
                            inputProps.type === "text" ||
                            inputProps.type === "number" ||
                            inputProps.type === "email" ||
                            inputProps.type === "password"
                          ) {
                            return (
                              <Grid
                                // key={inputProps.questionId}
                                xs={inputProps.xs}
                                item
                              >
                                <GlobalTextField
                                  demographicsData={demographicsData}
                                  inputProps={inputProps}
                                  isCommentEnabled={inputProps.isCommentEnabled}
                                  formData={formData}
                                  isConfigured={inputProps.isConfigured}
                                  handleChange={handleChange}
                                  commentIndex={commentIndex}
                                  setCommentIndex={setCommentIndex}
                                  quesindex={quesindex}
                                  section={section}
                                  value={formData[inputProps.name]}
                                  setFormData={setFormData}
                                  setComment={setComment}
                                  updateCommentInFormData={
                                    updateCommentInFormData
                                  }
                                  getCommentBasedOnInputProps={
                                    getCommentBasedOnInputProps
                                  }
                                />
                              </Grid>
                            );
                          } else if (inputProps.type === "datatable") {
                            const selectedTable = inputProps.tablelist.filter(
                              (table) =>
                                table && table.length > 0 && table[1] === true,
                            );
                            return (
                              <Grid
                                // key={inputProps.questionId}
                                xs={inputProps.xs}
                                item
                              >
                                <div style={{ textAlign: "center" }}>
                                  {selectedTable &&
                                    selectedTable.length > 0 &&
                                    selectedTable.map((table, index) => {
                                      if (table[0].label === "Medications") {
                                        return (
                                          // <DynamicTable
                                          //   key={table[0].id}
                                          //   id={index}
                                          //   tableId={table[0].id}
                                          //   columns={table[0].columns}
                                          //   handleDateChange={handleDateChange}
                                          //   rowData={tableRowData}
                                          //   rowsRenderData={rowsRenderData}
                                          //   handleTableDataChange={
                                          //     handleTableDataChange
                                          //   }
                                          //   targettable={selectedTable1}
                                          //   addTableRow={addTableRow}
                                          //   /* deleteTableRow={deleteTableRow} */
                                          //   deleteTableRow={(e, rowid) =>
                                          //     deleteTableRow(e, rowid)
                                          //   }
                                          // />
                                          <DynamicMedicationsTable
                                            medicationsRowData={
                                              medicationsRowData
                                            }
                                            setMedicationsRowData={
                                              setMedicationsRowData
                                            }
                                          />
                                        );
                                      } else if (
                                        table[0].label === "Mini Cog Versions"
                                      ) {
                                        return (
                                          <div>
                                            <TableComponent
                                              tableName="Mini Cog Versions"
                                              data={staticData}
                                            />
                                          </div>
                                        );
                                      } else if (
                                        table[0].label === "Word Recall"
                                      ) {
                                        return (
                                          <div
                                            style={{
                                              border:
                                                "1px solid var(--mainTheme)",
                                              padding: "10px",
                                              width: "100%",
                                              borderCollapse: "collapse",
                                            }}
                                          >
                                            <DynamicWordRecall
                                              wordRecallRowData={
                                                wordRecallRowData
                                              }
                                              setWordRecallRowData={
                                                setWordRecallRowData
                                              }
                                            />
                                            <DynamicClockDraw
                                              clockDrawRowData={
                                                clockDrawRowData
                                              }
                                              setClockDrawRowData={
                                                setClockDrawRowData
                                              }
                                            />
                                            <DynamicTotalScore
                                              totalScoreRowData={
                                                totalScoreRowData
                                              }
                                              setTotalScoreRowData={
                                                setTotalScoreRowData
                                              }
                                            />
                                          </div>
                                        );
                                      } else if (
                                        table[0].label === "BMI Index Chart"
                                      ) {
                                        return (
                                          <TableComponentStatic
                                            tableName="BMI Index Chart"
                                            data={
                                              staticData[0].column_data[0].rows
                                            }
                                          />
                                        );
                                      } else if (
                                        table[0].label === "Depression Severity"
                                      ) {
                                        return (
                                          <TableComponent
                                            tableName="Depression Severity"
                                            data={staticData}
                                          />
                                        );
                                      } else if (
                                        table[0].label ===
                                        "Previously Documented Conditions"
                                      ) {
                                        return (
                                          <Dialog
                                            open={opens}
                                            onClose={handleCloses}
                                            fullWidth
                                            maxWidth="md"
                                          >
                                            <DialogTitle>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  color: "var(--mainTheme)",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {
                                                  "Previously Documented Conditions"
                                                }
                                                {/* <Button onClick={handleCloses}> */}
                                                <CloseIcon
                                                  sx={{
                                                    color:
                                                      "var(--mainTheme) !important",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={handleCloses}
                                                />
                                              </div>
                                            </DialogTitle>

                                            <DialogContent>
                                              <div>
                                                <DynamicTablePreviously
                                                  tableRowData={tableRowData}
                                                  setTableRowData={
                                                    setTableRowData
                                                  }
                                                  // memberId={memberId}
                                                />
                                              </div>
                                            </DialogContent>
                                          </Dialog>
                                        );
                                      } else if (
                                        table[0].label === "Prior Assessments"
                                      ) {
                                        return (
                                          <DynamicTable
                                            key={index}
                                            id={index}
                                            tableId={table[0].id}
                                            columns={table[0].columns}
                                            handleDateChange={handleDateChange}
                                            rowData={tableRowData}
                                            rowsRenderData={rowsRenderData}
                                            handleTableDataChange={
                                              handleTableDataChange
                                            }
                                            targettable={selectedTable3}
                                            addTableRow={addTableRow}
                                            deleteTableRow={deleteTableRow}
                                          />
                                        );
                                      } else if (
                                        table[0].label === "Preventive Care"
                                      ) {
                                        return (
                                          // <DynamicTable
                                          //   key={index}
                                          //   id={index}
                                          //   tableId={table[0].id}
                                          //   columns={table[0].columns}
                                          //   handleDateChange={handleDateChange}
                                          //   rowData={tableRowData}
                                          //   rowsRenderData={rowsRenderData}
                                          //   handleTableDataChange={
                                          //     handleTableDataChange
                                          //   }
                                          //   targettable={selectedTable4}
                                          //   addTableRow={addTableRow}
                                          //   deleteTableRow={deleteTableRow}
                                          // />
                                          <DynamicTablePreventiveCare
                                            preventiveRowData={
                                              preventiveRowData
                                            }
                                            setPreventiveRowData={
                                              setPreventiveRowData
                                            }
                                          />
                                        );
                                      }
                                    })}
                                </div>
                              </Grid>
                            );
                          } else if (inputProps.type === "date") {
                            return (
                              <Grid
                                // key={inputProps.questionId}
                                xs={inputProps.xs}
                                /* sm={inputProps.sm} */
                                item
                              >
                                <Stack direction="row">
                                  <Grid item>
                                    <div
                                      sx={{
                                        "& > :not(style)": {
                                          width: "100%",
                                          marginX: 1,
                                        },
                                      }}
                                      className="inputAddMembers"
                                    >
                                      {(inputProps.name === "examFinishDate" ||
                                        inputProps.name ===
                                          "evaluationDate") && (
                                        <Typography
                                          style={{ color: "var(--mainTheme)" }}
                                          variant="body2"
                                        >
                                          {" "}
                                          {inputProps.label}
                                        </Typography>
                                      )}
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={[
                                            "DateTimePicker",
                                            "DateTimePicker",
                                          ]}
                                        >
                                          <DateTimePicker
                                            timeSteps={{ minutes: 1 }}
                                            id="datetime-local"
                                            format="MM/DD/YYYY hh:mm a"
                                            views={[
                                              "year",
                                              "month",
                                              "day",
                                              "hours",
                                              "minutes",
                                            ]}
                                            variant="outlined"
                                            // disableFuture={
                                            //   inputProps.name ===
                                            //   "examFinishDate"
                                            //     ? true
                                            //     : false
                                            // }
                                            // minDate={
                                            //   inputProps.name ===
                                            //     "examFinishDate" &&
                                            //   Boolean(evaluationDate)
                                            //     ? dayjs(evaluationDate)
                                            //     : undefined
                                            // }
                                            // maxDate={
                                            //   inputProps.name ===
                                            //     "evaluationDate" &&
                                            //   Boolean(examFinishDate)
                                            //     ? dayjs(examFinishDate)
                                            //     : undefined
                                            // }
                                            minDate={getMinDate(inputProps)}
                                            maxDate={getMaxDate(inputProps)}
                                            ampm
                                            autoOk={false}
                                            label={
                                              inputProps.name ===
                                                "evaluationDate" ||
                                              inputProps.name ===
                                                "examFinishDate"
                                                ? " "
                                                : inputProps.label
                                            }
                                            value={
                                              inputProps.name ===
                                              "examFinishDate"
                                                ? examFinishDate
                                                  ? dayjs(examFinishDate)
                                                  : null
                                                : inputProps.name ===
                                                  "evaluationDate"
                                                ? evaluationDate
                                                  ? dayjs(evaluationDate)
                                                  : null
                                                : null
                                            }
                                            onChange={(newValue) => {
                                              handleDateChange(
                                                inputProps,
                                                new Date(newValue),
                                              );
                                            }}
                                            onClose={() => {
                                              if (
                                                inputProps.name ===
                                                  "examFinishDate" &&
                                                !evaluationDate &&
                                                examFinishDate
                                              ) {
                                                setEmptyDateErrorPopup(true);
                                                setEmptyDateErrorMessg(
                                                  "Please Select Exam Start Date Before Selecting Exam Finish Date",
                                                );
                                                setExamFinishDate(null);
                                                clearExamFinishDate();
                                              } else if (
                                                inputProps.name ==
                                                  "examFinishDate" &&
                                                Boolean(evaluationDate) &&
                                                Boolean(examFinishDate) &&
                                                new Date(
                                                  evaluationDate,
                                                )?.getTime() >=
                                                  new Date(
                                                    examFinishDate,
                                                  )?.getTime()
                                              ) {
                                                setEmptyDateErrorPopup(true);
                                                setEmptyDateErrorMessg(
                                                  "Exam Finished Time should be greater than Exam Start Time",
                                                );
                                                clearExamFinishDate();
                                              }
                                            }}
                                            slotProps={{
                                              textField: {
                                                disabled: true,
                                              },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </div>
                                  </Grid>
                                </Stack>
                              </Grid>
                            );
                          } else if (inputProps.type === "label") {
                            return (
                              <CustomLabelField
                                formData={formData}
                                isCommentEnabled={inputProps.isCommentEnabled}
                                inputProps={inputProps}
                                isConfigured={inputProps.isConfigured}
                                commentIndex={commentIndex}
                                setCommentIndex={setCommentIndex}
                                section={section}
                                getCommentBasedOnInputProps={
                                  getCommentBasedOnInputProps
                                }
                                setComment={setComment}
                                updateCommentInFormData={
                                  updateCommentInFormData
                                }
                              />
                            );
                          } else if (inputProps.type === "options_tree") {
                            return (
                              <Grid
                                // key={inputProps.questionId}
                                xs={inputProps.xs}
                                /* sm={inputProps.sm} */
                                item
                              >
                                <Stack direction="row">
                                  <Grid
                                    item
                                    /* xs={12} */
                                    // key={inputProps.questionId}
                                    xs={12}
                                    sm={12}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <InputLabel
                                        style={{ color: "var(--mainTheme)" }}
                                      >
                                        {inputProps.label}
                                      </InputLabel>
                                      {inputProps.isCommentEnabled === true && (
                                        //inputProps.name !== "bmi" &&
                                        <ChatBubbleIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "var(--mainTheme)",
                                          }}
                                          onClick={() => {
                                            if (
                                              commentIndex[
                                                quesindex + section.sectionName
                                              ] !== undefined
                                            ) {
                                              setCommentIndex({
                                                ...commentIndex,
                                                [quesindex +
                                                section.sectionName]:
                                                  !commentIndex[
                                                    quesindex +
                                                      section.sectionName
                                                  ],
                                              });
                                            } else {
                                              setCommentIndex({
                                                ...commentIndex,
                                                [quesindex +
                                                section.sectionName]: false,
                                              });
                                            }
                                          }}
                                        />
                                      )}
                                    </div>

                                    <ThemeProvider theme={myTheme}>
                                      <div
                                        key={quesindex}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Grid
                                          // key={inputProps.questionId}
                                          item
                                          xs={12}
                                          sm={12}
                                        >
                                          <CustomToggleButtonGroup
                                            quesindex={quesindex}
                                            inputProps={inputProps}
                                            formData={formData}
                                            handleChange={
                                              inputProps.name ===
                                                "specialDiet" ||
                                              inputProps.name ===
                                                "pleaseChooseTheFollowingTreatm" ||
                                              // inputProps.name === "doYouUseAnyAssistiveDevicesOrD" ||
                                              inputProps.name ===
                                                "pleaseChooseTheFollowingHealth" ||
                                              inputProps.name ===
                                                "long_term_use_of:"
                                                ? handleChangeSpecialDiet
                                                : handleChange
                                            }
                                            setToggleIndex={setToggleIndex}
                                            roleId={roleId}
                                            sectionName={section.sectionName}
                                          />
                                        </Grid>
                                      </div>
                                      {commentIndex[
                                        quesindex + section.sectionName
                                      ] === true ||
                                      commentIndex[
                                        quesindex + section.sectionName
                                      ] === undefined ? (
                                        <></>
                                      ) : (
                                        <span>
                                          <TextField
                                            {...{
                                              label: "Comments",

                                              placeholder: "Comments if any",
                                            }}
                                            multiline
                                            /* rows={2} */
                                            maxRows={4}
                                            value={getCommentBasedOnInputProps(
                                              inputProps.name,
                                            )}
                                            onChange={(event) => {
                                              setComment(event.target.value);
                                              updateCommentInFormData(
                                                inputProps.name,
                                                event.target.value,
                                              );
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            className="inputStyle"
                                            sx={{
                                              "& .MuiFormLabel-root": {
                                                color: "var(--mainTheme)",
                                              },
                                              "& .MuiFormLabel-root.Mui-focused":
                                                {
                                                  color: "var(--mainTheme)",
                                                },
                                              marginTop: "15px",
                                            }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <ArrowForwardIcon
                                                    sx={{
                                                      size: "medium",
                                                      color: "var(--mainTheme)",
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </span>
                                      )}

                                      {
                                        // selectedOption ==="yes" &&

                                        (formData.familyHistory !== undefined ||
                                          formData.allergies !== undefined ||
                                          formData.otcmSupplements !==
                                            undefined ||
                                          formData.areYouCurrentlySeeingAnySpecia !==
                                            undefined) &&
                                          inputProps.hasSubQuestions === true &&
                                          inputProps.subQuestions
                                            .find(
                                              (subQuestion) =>
                                                selectIndex[
                                                  inputProps.questionId +
                                                    subQuestion.value
                                                ] === true &&
                                                subQuestion.hasSubQuestions ===
                                                  true,
                                            )
                                            ?.subQuestions.map(
                                              (subQuestion, index, option) => (
                                                <div
                                                  key={
                                                    subQuestion.subQuestionId
                                                  }
                                                >
                                                  {
                                                    <div>
                                                      {subQuestion.type ===
                                                        "datatable" &&
                                                        inputProps.name ===
                                                          "familyHistory" && (
                                                          <DynamicTableFamilyHistory
                                                            familyRowData={
                                                              familyRowData
                                                            }
                                                            setFamilyRowData={
                                                              setFamilyRowData
                                                            }
                                                            memberId={memberId}
                                                          />
                                                        )}

                                                      {subQuestion.type ===
                                                        "datatable" &&
                                                        (inputProps.name ===
                                                          "alcoholUseDataTableFormer" ||
                                                          inputProps.name ===
                                                            "alcoholUseDataTableCurrent") && (
                                                          <DynamicTableAlcoholUsage
                                                            alcoholRowData={
                                                              alcoholRowData
                                                            }
                                                            setAlcoholRowData={
                                                              setAlcoholRowData
                                                            }
                                                            memberId={memberId}
                                                          />
                                                        )}
                                                      {subQuestion.type ===
                                                        "datatable" &&
                                                        inputProps.name ===
                                                          "allergies" &&
                                                        formData.allergies ===
                                                          "yes" && (
                                                          <DynamicTableAllergies
                                                            allergiesRowData={
                                                              allergiesRowData
                                                            }
                                                            setAllergiesRowData={
                                                              setAllergiesRowData
                                                            }
                                                            memberId={memberId}
                                                          />
                                                        )}

                                                      {subQuestion.type ===
                                                        "datatable" &&
                                                        inputProps.name ===
                                                          "otcmSupplements" &&
                                                        formData.otcmSupplements ===
                                                          "yes" && (
                                                          <DynamicTableCounterMedications
                                                            counterMedicationsRowData={
                                                              counterMedicationsRowData
                                                            }
                                                            setCounterMedicationsRowData={
                                                              setCounterMedicationsRowData
                                                            }
                                                            memberId={memberId}
                                                          />
                                                        )}

                                                      {subQuestion.type ===
                                                        "datatable" &&
                                                        inputProps.name ===
                                                          "areYouCurrentlySeeingAnySpecia" &&
                                                        formData.areYouCurrentlySeeingAnySpecia ===
                                                          "yes" && (
                                                          <DynamicTableSpecialists
                                                            medicalRowData={
                                                              medicalRowData
                                                            }
                                                            setMedicalRowData={
                                                              setMedicalRowData
                                                            }
                                                            memberId={memberId}
                                                          />
                                                        )}

                                                      {subQuestion.type ===
                                                        "select" && (
                                                        <CustomSubSelect
                                                          subQuestion={
                                                            subQuestion
                                                          }
                                                          inputProps={
                                                            inputProps
                                                          }
                                                          formData={formData}
                                                          handleSubQuestionChange={
                                                            handleSubQuestionChange
                                                          }
                                                        />
                                                      )}

                                                      {subQuestion.type ===
                                                        "radio" && (
                                                        <Grid
                                                          container
                                                          item
                                                          xs={12}
                                                          alignItems="center"
                                                          spacing={1}
                                                        >
                                                          <Grid item xs={11}>
                                                            <CustomRadioGroupField
                                                              inputProps={
                                                                subQuestion
                                                              }
                                                              formData={
                                                                formData
                                                              }
                                                              handleChangeRadio={
                                                                handleChange
                                                              }
                                                            />
                                                          </Grid>

                                                          {subQuestion.label &&
                                                            inputProps.isCommentEnabled && (
                                                              <Grid item xs={1}>
                                                                <ChatBubbleIcon
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    color:
                                                                      "var(--mainTheme)",
                                                                    marginLeft:
                                                                      "5px",
                                                                  }}
                                                                  onClick={() => {
                                                                    if (
                                                                      commentIndex[
                                                                        quesindex +
                                                                          section.sectionName +
                                                                          subQuestion.label
                                                                      ] !==
                                                                      undefined
                                                                    ) {
                                                                      setCommentIndex(
                                                                        {
                                                                          ...commentIndex,
                                                                          [quesindex +
                                                                          section.sectionName +
                                                                          subQuestion.label]:
                                                                            !commentIndex[
                                                                              quesindex +
                                                                                section.sectionName +
                                                                                subQuestion.label
                                                                            ],
                                                                        },
                                                                      );
                                                                    } else {
                                                                      setCommentIndex(
                                                                        {
                                                                          ...commentIndex,
                                                                          [quesindex +
                                                                          section.sectionName +
                                                                          subQuestion.label]: false,
                                                                        },
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                              </Grid>
                                                            )}

                                                          {commentIndex[
                                                            quesindex +
                                                              section.sectionName +
                                                              subQuestion.label
                                                          ] === true ||
                                                          commentIndex[
                                                            quesindex +
                                                              section.sectionName +
                                                              subQuestion.label
                                                          ] === undefined ? (
                                                            <></>
                                                          ) : (
                                                            <Grid item xs={11}>
                                                              <TextField
                                                                {...{
                                                                  label:
                                                                    "Comments",
                                                                  placeholder:
                                                                    "Comment if any",
                                                                }}
                                                                multiline
                                                                maxRows={4}
                                                                value={getCommentsBasedOnInputProps(
                                                                  subQuestion.name,
                                                                  subQuestion.label,
                                                                )}
                                                                onChange={(
                                                                  event,
                                                                ) => {
                                                                  setComment(
                                                                    event.target
                                                                      .value,
                                                                  );
                                                                  updateCommentsInFormData(
                                                                    subQuestion.name,
                                                                    subQuestion.label,
                                                                    event.target
                                                                      .value,
                                                                  );
                                                                }}
                                                                fullWidth
                                                                variant="outlined"
                                                                className="inputStyle"
                                                                sx={{
                                                                  "& .MuiFormLabel-root":
                                                                    {
                                                                      color:
                                                                        "var(--mainTheme)",
                                                                    },
                                                                  "& .MuiFormLabel-root.Mui-focused":
                                                                    {
                                                                      color:
                                                                        "var(--mainTheme)",
                                                                    },
                                                                  marginTop:
                                                                    "15px",
                                                                }}
                                                                InputProps={{
                                                                  startAdornment:
                                                                    (
                                                                      <InputAdornment position="start">
                                                                        <ArrowForwardIcon
                                                                          sx={{
                                                                            size: "medium",
                                                                            color:
                                                                              "var(--mainTheme)",
                                                                          }}
                                                                        />
                                                                      </InputAdornment>
                                                                    ),
                                                                }}
                                                              />
                                                            </Grid>
                                                          )}
                                                        </Grid>
                                                      )}

                                                      {/* {subQuestion.type ===
                                                      "radio" && (
                                                        <Grid container item xs={12} alignItems="center" spacing={1}>
                                                      <Grid
                                                        // key={
                                                        //   subQuestion.subQuestionId
                                                        // }
                                                        xs={11}
                                                        item
                                                        fullWidth
                                                      >
                                                        <CustomRadioGroupField
                                                          inputProps={
                                                            subQuestion
                                                          }
                                                          formData={formData}
                                                          handleChangeRadio={
                                                            handleChange
                                                          }
                                                        />
                                                         </Grid>
                                                         {subQuestion.label &&
                                                            inputProps.isCommentEnabled && (
                                                              
                                                               <Grid item xs={1}>
                                                                <ChatBubbleIcon
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    color:
                                                                      "var(--mainTheme)",
                                                                  }}
                                                                  onClick={() => {
                                                                    if (
                                                                      commentIndex[
                                                                        quesindex +
                                                                          section.sectionName +
                                                                          subQuestion.label
                                                                      ] !==
                                                                      undefined
                                                                    ) {
                                                                      setCommentIndex(
                                                                        {
                                                                          ...commentIndex,
                                                                          [quesindex +
                                                                          section.sectionName +
                                                                          subQuestion.label]:
                                                                            !commentIndex[
                                                                              quesindex +
                                                                                section.sectionName +
                                                                                subQuestion.label
                                                                            ],
                                                                        },
                                                                      );
                                                                    } else {
                                                                      setCommentIndex(
                                                                        {
                                                                          ...commentIndex,
                                                                          [quesindex +
                                                                          section.sectionName +
                                                                          subQuestion.label]: false,
                                                                        },
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                                </Grid>
                                                            
                                                            )}

                                                        
                                                         {commentIndex[
                                                              quesindex +
                                                                section.sectionName +
                                                                subQuestion.label
                                                            ] === true ||
                                                            commentIndex[
                                                              quesindex +
                                                                section.sectionName +
                                                                subQuestion.label
                                                            ] === undefined ? (
                                                              <></>
                                                            ) : (
                                                              <span>
                                                                <TextField
                                                                  {...{
                                                                    label:
                                                                      "Comments",
                                                                    placeholder:
                                                                      "Comment if any",
                                                                  }}
                                                                  multiline
                                                                  maxRows={4}
                                                                  value={getCommentsBasedOnInputProps(
                                                                    subQuestion.name,
                                                                    subQuestion.label,
                                                                  )}
                                                                  onChange={(
                                                                    event,
                                                                  ) => {
                                                                    setComment(
                                                                      event
                                                                        .target
                                                                        .value,
                                                                    );
                                                                    updateCommentsInFormData(
                                                                      subQuestion.name,
                                                                      subQuestion.label,
                                                                      event
                                                                        .target
                                                                        .value,
                                                                    );
                                                                  }}
                                                                  fullWidth
                                                                  variant="outlined"
                                                                  className="inputStyle"
                                                                  sx={{
                                                                    "& .MuiFormLabel-root":
                                                                      {
                                                                        color:
                                                                          "var(--mainTheme)",
                                                                      },
                                                                    "& .MuiFormLabel-root.Mui-focused":
                                                                      {
                                                                        color:
                                                                          "var(--mainTheme)",
                                                                      },
                                                                    marginTop:
                                                                      "15px",
                                                                  }}
                                                                  InputProps={{
                                                                    startAdornment:
                                                                      (
                                                                        <InputAdornment position="start">
                                                                          <ArrowForwardIcon
                                                                            sx={{
                                                                              size: "medium",
                                                                              color:
                                                                                "var(--mainTheme)",
                                                                            }}
                                                                          />
                                                                        </InputAdornment>
                                                                      ),
                                                                  }}
                                                                />
                                                              </span>
                                                            )}
                                                     
                                                      </Grid>

                                                      
                                                    )} */}

                                                      {subQuestion.type ===
                                                        "number" && (
                                                        <Grid
                                                          // key={
                                                          //   inputProps.questionId
                                                          // }
                                                          xs={inputProps.xs}
                                                          /* sm={inputProps.sm} */
                                                          item
                                                        >
                                                          <SubNumberField
                                                            subQuestion={
                                                              subQuestion
                                                            }
                                                            inputProps={
                                                              inputProps
                                                            }
                                                            handleInputChange={
                                                              handleInputChange
                                                            }
                                                          />
                                                        </Grid>
                                                      )}

                                                      {subQuestion.type ===
                                                        "date" && (
                                                        <Grid
                                                          // key={
                                                          //   inputProps.questionId
                                                          // }
                                                          xs={inputProps.xs}
                                                          /* sm={inputProps.sm} */
                                                          item
                                                        >
                                                          <Stack direction="row">
                                                            <DatePickerCompo
                                                              subQuestion={
                                                                subQuestion
                                                              }
                                                              value={
                                                                formData[
                                                                  subQuestion
                                                                    .name
                                                                ]
                                                              }
                                                              inputProps={
                                                                inputProps
                                                              }
                                                              formData={
                                                                formData
                                                              }
                                                              setFormData={
                                                                setFormData
                                                              }
                                                              handleSubQuestionDateChange={
                                                                handleSubQuestionDateChange
                                                              }
                                                            />
                                                          </Stack>
                                                        </Grid>
                                                      )}

                                                      {subQuestion.type ===
                                                        "text" && (
                                                        <CustomSubText
                                                          label={
                                                            subQuestion.label
                                                          }
                                                          onChange={(event) =>
                                                            handleSubQuestionTextChange(
                                                              event,
                                                              subQuestion,
                                                            )
                                                          }
                                                          formData={formData}
                                                          setFormData={
                                                            setFormData
                                                          }
                                                          value={
                                                            formData[
                                                              subQuestion.name
                                                            ] || ""
                                                          }
                                                        />
                                                      )}

                                                      {/* {subQuestion.label}  */}

                                                      {subQuestion.type ===
                                                        "options_tree" && (
                                                        <>
                                                          <div
                                                            style={{
                                                              marginTop: "2%",
                                                              color:
                                                                "var(--mainTheme)",
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                              flexDirection:
                                                                "row",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            {subQuestion.label}
                                                            {/* Comment box based on the label */}
                                                            {subQuestion.label &&
                                                              inputProps.isCommentEnabled && (
                                                                <>
                                                                  <ChatBubbleIcon
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      color:
                                                                        "var(--mainTheme)",
                                                                    }}
                                                                    onClick={() => {
                                                                      if (
                                                                        commentIndex[
                                                                          quesindex +
                                                                            section.sectionName +
                                                                            subQuestion.label
                                                                        ] !==
                                                                        undefined
                                                                      ) {
                                                                        setCommentIndex(
                                                                          {
                                                                            ...commentIndex,
                                                                            [quesindex +
                                                                            section.sectionName +
                                                                            subQuestion.label]:
                                                                              !commentIndex[
                                                                                quesindex +
                                                                                  section.sectionName +
                                                                                  subQuestion.label
                                                                              ],
                                                                          },
                                                                        );
                                                                      } else {
                                                                        setCommentIndex(
                                                                          {
                                                                            ...commentIndex,
                                                                            [quesindex +
                                                                            section.sectionName +
                                                                            subQuestion.label]: false,
                                                                          },
                                                                        );
                                                                      }
                                                                    }}
                                                                  />
                                                                </>
                                                              )}
                                                          </div>

                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection:
                                                                "column",
                                                              marginLeft: "1%",
                                                              flexWrap: "wrap",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                marginLeft:
                                                                  "1%",
                                                                flexWrap:
                                                                  "wrap",
                                                              }}
                                                            >
                                                              {subQuestion.hasSubQuestions &&
                                                                subQuestion.subQuestions.map(
                                                                  (
                                                                    language,
                                                                    subQuesIndex,
                                                                  ) => (
                                                                    <Grid
                                                                      item
                                                                      // key={
                                                                      //   language.subQuestionId
                                                                      // }
                                                                      xs={3}
                                                                      sm={3}
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                        flexDirection:
                                                                          "row",
                                                                        alignItems:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {/* ToggleButtonGroup Row */}
                                                                      <ThemeProvider
                                                                        theme={
                                                                          myTheme
                                                                        }
                                                                      >
                                                                        <CustomSubToggleButtonGroup
                                                                          language={
                                                                            language
                                                                          }
                                                                          inputProps={
                                                                            inputProps
                                                                          }
                                                                          isCommentEnabled={
                                                                            inputProps.isCommentEnabled
                                                                          }
                                                                          isConfigured={
                                                                            inputProps.isConfigured
                                                                          }
                                                                          quesindex={
                                                                            quesindex
                                                                          }
                                                                          section={
                                                                            section
                                                                          }
                                                                          setCommentIndex={
                                                                            setCommentIndex
                                                                          }
                                                                          commentIndex={
                                                                            commentIndex
                                                                          }
                                                                          subQuestion={
                                                                            subQuestion
                                                                          }
                                                                          formData={
                                                                            formData
                                                                          }
                                                                          handleSubQuestionChange={
                                                                            handleSubQuestionChange
                                                                          }
                                                                        />
                                                                        {/* <CustomSubToggleButtonGroup
                                                                      language={
                                                                        language
                                                                      }
                                                                      subQuestion={
                                                                        subQuestion
                                                                      }
                                                                      formData={
                                                                        formData
                                                                      }
                                                                      handleSubQuestionChange={
                                                                        handleSubQuestionChange
                                                                      }
                                                                    /> */}
                                                                      </ThemeProvider>
                                                                    </Grid>
                                                                  ),
                                                                )}
                                                            </div>
                                                            <div>
                                                              {commentIndex[
                                                                quesindex +
                                                                  section.sectionName +
                                                                  subQuestion.label
                                                              ] === true ||
                                                              commentIndex[
                                                                quesindex +
                                                                  section.sectionName +
                                                                  subQuestion.label
                                                              ] ===
                                                                undefined ? (
                                                                <></>
                                                              ) : (
                                                                <span>
                                                                  <TextField
                                                                    {...{
                                                                      label:
                                                                        "Comments ",
                                                                      placeholder:
                                                                        "Comments if any",
                                                                    }}
                                                                    multiline
                                                                    maxRows={4}
                                                                    value={getCommentsBasedOnInputProps(
                                                                      subQuestion.name,
                                                                      subQuestion.label,
                                                                    )}
                                                                    onChange={(
                                                                      event,
                                                                    ) => {
                                                                      setComment(
                                                                        event
                                                                          .target
                                                                          .value,
                                                                      );
                                                                      updateCommentsInFormData(
                                                                        subQuestion.name,
                                                                        subQuestion.label,
                                                                        event
                                                                          .target
                                                                          .value,
                                                                      );
                                                                    }}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    className="inputStyle"
                                                                    sx={{
                                                                      "& .MuiFormLabel-root":
                                                                        {
                                                                          color:
                                                                            "var(--mainTheme)",
                                                                        },
                                                                      "& .MuiFormLabel-root.Mui-focused":
                                                                        {
                                                                          color:
                                                                            "var(--mainTheme)",
                                                                        },
                                                                      marginTop:
                                                                        "15px",
                                                                    }}
                                                                    InputProps={{
                                                                      startAdornment:
                                                                        (
                                                                          <InputAdornment position="start">
                                                                            <ArrowForwardIcon
                                                                              sx={{
                                                                                size: "medium",
                                                                                color:
                                                                                  "var(--mainTheme)",
                                                                              }}
                                                                            />
                                                                          </InputAdornment>
                                                                        ),
                                                                    }}
                                                                  />
                                                                </span>
                                                              )}
                                                            </div>
                                                            {subQuestion.subQuestions
                                                              .filter(
                                                                (sq) =>
                                                                  sq.hasSubQuestions,
                                                              )
                                                              .map(
                                                                (sq, index) => {
                                                                  if (
                                                                    formData[
                                                                      subQuestion
                                                                        .name
                                                                    ] ===
                                                                    sq.value
                                                                  ) {
                                                                    return (
                                                                      <Grid
                                                                        item
                                                                        // key={
                                                                        //   index
                                                                        // }
                                                                        xs={12}
                                                                        sm={12}
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                          display:
                                                                            "flex",
                                                                          flexDirection:
                                                                            "column",
                                                                        }}
                                                                      >
                                                                        {sq.subQuestions.map(
                                                                          (
                                                                            subQ,
                                                                            index,
                                                                          ) => {
                                                                            if (
                                                                              subQ.type ===
                                                                              "text"
                                                                            ) {
                                                                              return (
                                                                                <>
                                                                                  <CustomSubTextField
                                                                                    // key={
                                                                                    //   index
                                                                                    // }
                                                                                    index={
                                                                                      1
                                                                                    }
                                                                                    subQ={{
                                                                                      label:
                                                                                        subQ.label,
                                                                                      name: subQ.name,
                                                                                    }}
                                                                                    value={
                                                                                      subQ.label ===
                                                                                      "Where"
                                                                                        ? formData.painChronicWhereText
                                                                                        : subQ.label ===
                                                                                          "Rate your pain on a scale of 1-10, with 1 being very mild and 10 being severe"
                                                                                        ? formData.rateYourPainSubtext
                                                                                        : subQ.label ===
                                                                                          "What drugs do you take for it?"
                                                                                        ? formData.drugsTakeSubtext
                                                                                        : subQ.label ===
                                                                                          "Describe"
                                                                                        ? formData.describeSubtext
                                                                                        : subQ.label ===
                                                                                          "Litres / Min"
                                                                                        ? formData.litresMin
                                                                                        : subQ.name ===
                                                                                          "ros_otherSubAnswerOther"
                                                                                        ? formData.ros_otherSubAnswerOtherSubText
                                                                                        : subQ.name ===
                                                                                          "ros_osteoarthritisSubAnswerWhichJoints"
                                                                                        ? formData.ros_osteoarthritisSubAnswerWhichJointsSubText
                                                                                        : subQ.name ===
                                                                                          "ros_pyogenicArthritisSubAnswerWhichJoints"
                                                                                        ? formData.ros_pyogenicArthritisSubAnswerWhichJointsSubText
                                                                                        : subQ.name ===
                                                                                          "ros_rheumatoidArthritisSubAnswerWhichJoints"
                                                                                        ? formData.ros_rheumatoidArthritisSubAnswerWhichJointsSubText
                                                                                        : subQ.name ===
                                                                                          "ros_muskoOtherSubAnswerother"
                                                                                        ? formData.ros_muskoOtherSubAnswerotherSubText
                                                                                        : subQ.name ===
                                                                                          "ros_hadAnAmputationSubAnswer"
                                                                                        ? formData.ros_hadAnAmputationSubAnswerSubText
                                                                                        : subQ.name ===
                                                                                          "ros_urticarialDiseaseSubAnswerEtiology"
                                                                                        ? formData.ros_urticarialDiseaseSubAnswerEtiologySubText
                                                                                        : subQ.name ===
                                                                                          "ros_integumentOthersSubAnswerOther"
                                                                                        ? formData.ros_integumentOthersSubAnswerOtherSubText
                                                                                        : subQ.name ===
                                                                                          "ros_hivSubAnswerViralLoad"
                                                                                        ? formData.ros_hivSubAnswerViralLoadSubText
                                                                                        : subQ.name ===
                                                                                          "ros_hivSubAnswerCFour"
                                                                                        ? formData.ros_hivSubAnswerCFourSubText
                                                                                        : subQ.name ===
                                                                                          "ros_immuneDeficiencySubAnswerEtiology"
                                                                                        ? formData.ros_immuneDeficiencySubAnswerEtiologySubText
                                                                                        : subQ.name ===
                                                                                          "ros_thrombocytopeniaSubAnswerEtiology"
                                                                                        ? formData.ros_thrombocytopeniaSubAnswerEtiologySubText
                                                                                        : subQ.name ===
                                                                                          "ros_hemaOtherSubAnswerOther"
                                                                                        ? formData.ros_hemaOtherSubAnswerOtherSubText
                                                                                        : subQ.name ===
                                                                                          "ros_diagnosisOfCancerSubMetastasisLocVal"
                                                                                        ? formData.ros_diagnosisOfCancerSubMetastasisLocVal
                                                                                        : subQ.name ===
                                                                                          "ros_diagnosisOfCancerSubSeeASpecProVal"
                                                                                        ? formData.ros_diagnosisOfCancerSubSeeASpecProVal
                                                                                        : ""
                                                                                    }
                                                                                    onChange={(
                                                                                      e,
                                                                                    ) => {
                                                                                      const fieldName =
                                                                                        subQ.label ===
                                                                                        "Where"
                                                                                          ? "painChronicWhereText"
                                                                                          : subQ.label ===
                                                                                            "Rate your pain on a scale of 1-10, with 1 being very mild and 10 being severe"
                                                                                          ? "rateYourPainSubtext"
                                                                                          : subQ.label ===
                                                                                            "What drugs do you take for it?"
                                                                                          ? "drugsTakeSubtext"
                                                                                          : subQ.label ===
                                                                                            "Describe"
                                                                                          ? "describeSubtext"
                                                                                          : subQ.name ===
                                                                                            "ros_otherSubAnswerOther"
                                                                                          ? "ros_otherSubAnswerOtherSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_osteoarthritisSubAnswerWhichJoints"
                                                                                          ? "ros_osteoarthritisSubAnswerWhichJointsSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_pyogenicArthritisSubAnswerWhichJoints"
                                                                                          ? "ros_pyogenicArthritisSubAnswerWhichJointsSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_rheumatoidArthritisSubAnswerWhichJoints"
                                                                                          ? "ros_rheumatoidArthritisSubAnswerWhichJointsSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_muskoOtherSubAnswerother"
                                                                                          ? "ros_muskoOtherSubAnswerotherSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_hadAnAmputationSubAnswer"
                                                                                          ? "ros_hadAnAmputationSubAnswerSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_urticarialDiseaseSubAnswerEtiology"
                                                                                          ? "ros_urticarialDiseaseSubAnswerEtiologySubText"
                                                                                          : subQ.name ===
                                                                                            "ros_integumentOthersSubAnswerOther"
                                                                                          ? "ros_integumentOthersSubAnswerOtherSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_hivSubAnswerViralLoad"
                                                                                          ? "ros_hivSubAnswerViralLoadSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_hivSubAnswerCFour"
                                                                                          ? "ros_hivSubAnswerCFourSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_immuneDeficiencySubAnswerEtiology"
                                                                                          ? "ros_immuneDeficiencySubAnswerEtiologySubText"
                                                                                          : subQ.name ===
                                                                                            "ros_thrombocytopeniaSubAnswerEtiology"
                                                                                          ? "ros_thrombocytopeniaSubAnswerEtiologySubText"
                                                                                          : subQ.name ===
                                                                                            "ros_hemaOtherSubAnswerOther"
                                                                                          ? "ros_hemaOtherSubAnswerOtherSubText"
                                                                                          : subQ.name ===
                                                                                            "ros_diagnosisOfCancerSubMetastasisLocVal"
                                                                                          ? "ros_diagnosisOfCancerSubMetastasisLocVal"
                                                                                          : subQ.name ===
                                                                                            "ros_diagnosisOfCancerSubSeeASpecProVal"
                                                                                          ? "ros_diagnosisOfCancerSubSeeASpecProVal"
                                                                                          : // : subQ.label ===
                                                                                            //   "Litres / Min"
                                                                                            // ? "litresMin"
                                                                                            "";
                                                                                      updateSubtextInFormData(
                                                                                        fieldName,
                                                                                        e
                                                                                          .target
                                                                                          .value,
                                                                                      );
                                                                                    }}
                                                                                    formData={
                                                                                      formData
                                                                                    }
                                                                                  />
                                                                                </>
                                                                              );
                                                                            } else if (
                                                                              subQ.type ===
                                                                              "datatable"
                                                                            ) {
                                                                              return (
                                                                                <TableComponentStatic
                                                                                  tableName="Stages Of Kidney Disease"
                                                                                  data={
                                                                                    staticData[3]
                                                                                      .column_data[0]
                                                                                      .rows
                                                                                  }
                                                                                />
                                                                              );
                                                                            } else if (
                                                                              subQ.type ===
                                                                              "options_tree"
                                                                            ) {
                                                                              return (
                                                                                <>
                                                                                  <div
                                                                                    style={{
                                                                                      marginTop:
                                                                                        "2%",
                                                                                      color:
                                                                                        "var(--mainTheme)",
                                                                                      display:
                                                                                        "flex",
                                                                                      justifyContent:
                                                                                        "space-between",
                                                                                      flexDirection:
                                                                                        "row",
                                                                                      alignItems:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    {
                                                                                      subQ.label
                                                                                    }

                                                                                    {/* Comment box based on the label */}
                                                                                    {subQ.label &&
                                                                                      inputProps.isCommentEnabled && (
                                                                                        <>
                                                                                          <ChatBubbleIcon
                                                                                            style={{
                                                                                              cursor:
                                                                                                "pointer",
                                                                                              color:
                                                                                                "var(--mainTheme)",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                              const key =
                                                                                                quesindex +
                                                                                                section.sectionName +
                                                                                                subQ.label;
                                                                                              if (
                                                                                                commentIndex[
                                                                                                  key
                                                                                                ] !==
                                                                                                undefined
                                                                                              ) {
                                                                                                setCommentIndex(
                                                                                                  {
                                                                                                    ...commentIndex,
                                                                                                    [key]:
                                                                                                      !commentIndex[
                                                                                                        key
                                                                                                      ],
                                                                                                  },
                                                                                                );
                                                                                              } else {
                                                                                                setCommentIndex(
                                                                                                  {
                                                                                                    ...commentIndex,
                                                                                                    [key]: false,
                                                                                                  },
                                                                                                );
                                                                                              }
                                                                                            }}
                                                                                          />
                                                                                        </>
                                                                                      )}
                                                                                  </div>

                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                      flexDirection:
                                                                                        "column",
                                                                                      marginLeft:
                                                                                        "1%",
                                                                                      flexWrap:
                                                                                        "wrap",
                                                                                    }}
                                                                                  >
                                                                                    <div
                                                                                      style={{
                                                                                        display:
                                                                                          "flex",
                                                                                        flexDirection:
                                                                                          "row",
                                                                                        marginLeft:
                                                                                          "1%",
                                                                                        flexWrap:
                                                                                          "wrap",
                                                                                      }}
                                                                                    >
                                                                                      {subQ.subQuestions.map(
                                                                                        (
                                                                                          option,
                                                                                          optionIndex,
                                                                                        ) => (
                                                                                          <Grid
                                                                                            item
                                                                                            // key={
                                                                                            //   option.subQuestionId
                                                                                            // }
                                                                                            xs={
                                                                                              3
                                                                                            }
                                                                                            sm={
                                                                                              3
                                                                                            }
                                                                                            style={{
                                                                                              display:
                                                                                                "flex",
                                                                                              justifyContent:
                                                                                                "space-between",
                                                                                              flexDirection:
                                                                                                "row",
                                                                                              alignItems:
                                                                                                "center",
                                                                                            }}
                                                                                          >
                                                                                            <ThemeProvider
                                                                                              theme={
                                                                                                myTheme
                                                                                              }
                                                                                            >
                                                                                              <SubSubToggleButtonGroup
                                                                                                option={
                                                                                                  option
                                                                                                }
                                                                                                subQ={
                                                                                                  subQ
                                                                                                }
                                                                                                formData={
                                                                                                  formData
                                                                                                }
                                                                                                handleSubSubQuestionChange={
                                                                                                  handleSubSubQuestionChange
                                                                                                }
                                                                                                inputProps={
                                                                                                  inputProps
                                                                                                }
                                                                                                setToggleIndex={
                                                                                                  setToggleIndex
                                                                                                }
                                                                                                quesindex={
                                                                                                  quesindex
                                                                                                }
                                                                                              />
                                                                                            </ThemeProvider>
                                                                                          </Grid>
                                                                                        ),
                                                                                      )}
                                                                                    </div>
                                                                                    <div>
                                                                                      {commentIndex[
                                                                                        quesindex +
                                                                                          section.sectionName +
                                                                                          subQ.label
                                                                                      ] ===
                                                                                        true ||
                                                                                      commentIndex[
                                                                                        quesindex +
                                                                                          section.sectionName +
                                                                                          subQ.label
                                                                                      ] ===
                                                                                        undefined ? (
                                                                                        <>

                                                                                        </>
                                                                                      ) : (
                                                                                        <span>
                                                                                          <TextField
                                                                                            {...{
                                                                                              label:
                                                                                                "Comments",
                                                                                              placeholder:
                                                                                                "Comment if any",
                                                                                            }}
                                                                                            multiline
                                                                                            maxRows={
                                                                                              4
                                                                                            }
                                                                                            value={getCommentsBasedOnInputProps(
                                                                                              subQ.name,
                                                                                              subQuestion.label,
                                                                                            )}
                                                                                            onChange={(
                                                                                              event,
                                                                                            ) => {
                                                                                              setComment(
                                                                                                event
                                                                                                  .target
                                                                                                  .value,
                                                                                              );
                                                                                              updateCommentsInFormData(
                                                                                                subQ.name,
                                                                                                subQuestion.label,
                                                                                                event
                                                                                                  .target
                                                                                                  .value,
                                                                                              );
                                                                                            }}
                                                                                            fullWidth
                                                                                            variant="outlined"
                                                                                            className="inputStyle"
                                                                                            sx={{
                                                                                              "& .MuiFormLabel-root":
                                                                                                {
                                                                                                  color:
                                                                                                    "var(--mainTheme)",
                                                                                                },
                                                                                              "& .MuiFormLabel-root.Mui-focused":
                                                                                                {
                                                                                                  color:
                                                                                                    "var(--mainTheme)",
                                                                                                },
                                                                                              marginTop:
                                                                                                "15px",
                                                                                            }}
                                                                                            InputProps={{
                                                                                              startAdornment:
                                                                                                (
                                                                                                  <InputAdornment position="start">
                                                                                                    <ArrowForwardIcon
                                                                                                      sx={{
                                                                                                        size: "medium",
                                                                                                        color:
                                                                                                          "var(--mainTheme)",
                                                                                                      }}
                                                                                                    />
                                                                                                  </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                          />
                                                                                        </span>
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                </>
                                                                              );
                                                                            }

                                                                            return null;
                                                                          },
                                                                        )}
                                                                      </Grid>
                                                                    );
                                                                  }

                                                                  return null;
                                                                },
                                                              )}
                                                          </div>
                                                        </>
                                                      )}

                                                      {}
                                                    </div>
                                                  }
                                                </div>
                                              ),
                                            )
                                      }
                                    </ThemeProvider>
                                    {/* )})} */}
                                  </Grid>
                                </Stack>
                              </Grid>
                            );
                          } else if (inputProps.type === "heading") {
                            return (
                              <Grid
                                // key={inputProps.questionId}
                                xs={12}
                                sm={12}
                                item
                              >
                                <CustomHeadingField
                                  formData={formData}
                                  inputProps={inputProps}
                                />
                              </Grid>
                            );
                          } else if (inputProps.type === "radio") {
                            return (
                              <Grid
                                // key={inputProps.questionId}
                                xs={inputProps.xs}
                                item
                              >
                                <CustomRadioGroupField
                                  inputProps={inputProps}
                                  formData={formData}
                                  handleChangeRadio={handleChangeRadio}
                                />
                              </Grid>
                            );
                          } else if (inputProps.type === "checkbox") {
                            return (
                              <>
                                <CustomCheckboxField
                                  inputProps={inputProps}
                                  isCommentEnabled={inputProps.isCommentEnabled}
                                  formData={formData}
                                  setFormData={setFormData}
                                  isConfigured={inputProps.isConfigured}
                                  commentIndex={commentIndex}
                                  handleChangeCheckbox={(e) =>
                                    handleChangeCheckbox(e, inputProps)
                                  }
                                  setCommentIndex={setCommentIndex}
                                  quesindex={quesindex}
                                  section={section}
                                  getCommentBasedOnInputProps={
                                    getCommentBasedOnInputProps
                                  }
                                  setComment={setComment}
                                  updateCommentInFormData={
                                    updateCommentInFormData
                                  }
                                  visitType={selectedVisitType}
                                  showConsentedToVideo={showConsentedToVideo} // Pass visibility state
                                />
                              </>
                            );
                          } else if (inputProps.type === "image") {
                            //console.log("inputProps.src", inputProps.src);
                            // inputProps.src = providerSignAllDashboard//data.patient.providerSignature
                            if (
                              !_providerSignatureHra &&
                              !providerSignAllDashboard &&
                              inputProps.src !== ""
                            ) {
                              const isSrcValidUrl = isValidUrl(inputProps.src);

                              if (isSrcValidUrl) {
                                return (
                                  <CustomImageField inputProps={inputProps} />
                                );
                              } else {
                                return (
                                  <div
                                    style={{
                                      width: "50%",
                                      marginTop: "1%",
                                      marginBottom: "1%",
                                      marginLeft: "1%",
                                    }}
                                  >
                                    <p>Invalid src</p>
                                  </div>
                                );
                              }
                            } else if (
                              _providerSignatureHra != undefined &&
                              _providerSignatureHra != "undefined"
                            ) {
                              if (_providerSignatureHra) {
                                return (
                                  // <CustomImageField  inputProps={inputProps} />
                                  <Grid xs={inputProps.xs}>
                                    <div
                                      style={{
                                        width: "fit-content",
                                        marginTop: "2%",
                                        marginBottom: "1%",
                                        marginLeft: "1%",
                                        border: "1px solid grey",
                                      }}
                                    >
                                      <img
                                        src={_providerSignatureHra}
                                        alt={inputProps.alt}
                                        style={
                                          {
                                            // width: "50%",
                                            //height: "100px",
                                          }
                                        }
                                      />
                                    </div>
                                  </Grid>
                                );
                              }
                            } else if (providerSignAllDashboard != undefined) {
                              if (providerSignAllDashboard) {
                                return (
                                  // <CustomImageField  inputProps={inputProps} />
                                  <Grid xs={inputProps.xs}>
                                    <div
                                      style={{
                                        width: "fit-content",
                                        marginTop: "2%",
                                        marginBottom: "1%",
                                        marginLeft: "1%",
                                        border: "1px solid grey",
                                      }}
                                    >
                                      <img
                                        src={providerSignAllDashboard}
                                        alt={inputProps.alt}
                                        style={
                                          {
                                            // width: "100%",
                                            //height: "60px",
                                          }
                                        }
                                      />
                                    </div>
                                  </Grid>
                                );
                              }
                            } else {
                              return (
                                <div
                                  style={{
                                    width: "50%",
                                    marginTop: "1%",
                                    marginBottom: "1%",
                                    marginLeft: "1%",
                                  }}
                                >
                                  <p></p>
                                </div>
                              );
                            }
                          } else if (inputProps.type === "select") {
                            return (
                              <CustomSelectField
                                inputProps={inputProps}
                                xs={inputProps.xs}
                                sm={inputProps.sm}
                                isConfigured={inputProps.isConfigured}
                                formData={formData}
                                handleChange={handleChange}
                                handleVisitTypeChange={handleVisitTypeChange}
                              />
                            );
                          } else if (inputProps.type === "button") {
                            return (
                              <Grid
                                // key={inputProps.questionId}
                                xs={inputProps.isSingleButton ? 12 : 0}
                                item
                              >
                                <FormControl
                                  className="inputStyle"
                                  component="fieldset"
                                  {...{
                                    ...inputProps,
                                    value: formData[inputProps.name],
                                  }}
                                  variant="outlined"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    height: "100%",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    disabled={
                                      (inputProps.name == "no_show" ||
                                        inputProps.name ==
                                          "cancel_assessment") &&
                                      !["In Progress", "Scheduled"].includes(
                                        hraStatus,
                                      )
                                    }
                                    style={{
                                      display:
                                        inputProps.name == "signSubmit" &&
                                        roleId != 5
                                          ? "none"
                                          : true,
                                      "&:disabled": {
                                        BackgroundColor: "var(--mainTheme)",
                                      },
                                    }}
                                    onClick={() => {
                                      //  handleButtonAction("dialogBox")
                                      if (
                                        inputProps.name == "signSubmit" &&
                                        formData.acceptDisclosure == 1 &&
                                        (formData.visitType == "virtual"
                                          ? formData?.consentedToVideo == 1
                                          : true) &&
                                        formData.memberAknowledgement == 1 &&
                                        Boolean(formData?.examFinishDate) &&
                                        Boolean(formData?.evaluationDate) &&
                                        new Date(evaluationDate)?.getTime() <
                                          new Date(examFinishDate)?.getTime()
                                      ) {
                                        handleButtonAction(inputProps.name);
                                      } else if (
                                        inputProps.name == "signSubmit" &&
                                        (formData.consentedToVideo == "" ||
                                          formData.memberAknowledgement == 0)
                                      ) {
                                        setErrorSignSubmitBtn(true);
                                        if (
                                          formData.memberAknowledgement == 0 &&
                                          formData.consentedToVideo != ""
                                        ) {
                                          setErrorSignSubmitBtnMsg(
                                            "Member informed of acknowledgement is required ",
                                          );
                                        }
                                        if (
                                          formData.memberAknowledgement != 0 &&
                                          formData.consentedToVideo == ""
                                        ) {
                                          setErrorSignSubmitBtnMsg(
                                            "Consented to Video chat required",
                                          );
                                        }
                                        if (
                                          formData.consentedToVideo == "" &&
                                          formData.visitType === "virtual" &&
                                          formData.memberAknowledgement == 0
                                        ) {
                                          setErrorSignSubmitBtnMsg(
                                            "Member informed of acknowledgement  and  Consented to Video chat required",
                                          );
                                        }
                                      } else if (
                                        inputProps.name == "signSubmit" &&
                                        (!Boolean(formData.examFinishDate) ||
                                          examFinishDate == null) &&
                                        Boolean(evaluationDate)
                                      ) {
                                        setEmptyDateErrorPopup(true);
                                        setEmptyDateErrorMessg(
                                          "Please select Exam time Finish date",
                                        );
                                      } else if (
                                        inputProps.name == "signSubmit" &&
                                        (!Boolean(formData.evaluationDate) ||
                                          evaluationDate == null) &&
                                        Boolean(examFinishDate)
                                      ) {
                                        setEmptyDateErrorPopup(true);
                                        setEmptyDateErrorMessg(
                                          "Please select Exam Start Time",
                                        );
                                      } else if (
                                        inputProps.name == "signSubmit" &&
                                        Boolean(evaluationDate) &&
                                        Boolean(examFinishDate) &&
                                        new Date(evaluationDate)?.getTime() >=
                                          new Date(examFinishDate)?.getTime()
                                      ) {
                                        setEmptyDateErrorPopup(true);
                                        setEmptyDateErrorMessg(
                                          "Exam Finished Time should be greater than Exam Start Time",
                                        );
                                      } else if (
                                        inputProps.name == "signSubmit" &&
                                        (!Boolean(formData.evaluationDate) ||
                                          evaluationDate == null) &&
                                        (!Boolean(formData.examFinishDate) ||
                                          examFinishDate == null)
                                      ) {
                                        setEmptyDateErrorPopup(true);
                                        setEmptyDateErrorMessg(
                                          "Please select Exam Start Time and Exam time Finish date",
                                        );
                                      } else if (inputProps.name == "no_show") {
                                        setNoShowUpdateHraStatusPopup(true);
                                      } else if (
                                        inputProps.name == "cancel_assessment"
                                      ) {
                                        setCancelAssessmentUpdateHraStatusPopup(
                                          true,
                                        );
                                      } else {
                                        handleButtonAction(
                                          inputProps.name,
                                          assessmentId,
                                          setToasterMessage,
                                          setToasterOpen,
                                          setToasterSeverity,
                                        );
                                      }
                                    }}
                                    variant="contained"
                                    sx={{
                                      width: "auto",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      BackgroundColor: "var(--mainTheme)",
                                      textAlign: "center",
                                      height: "100%",
                                      flexWrap: "wrap",
                                      justifyContent: "center",
                                      "&:disabled": {
                                        BackgroundColor: "var(--mainTheme600)",
                                        color: "black",
                                      },
                                    }}
                                  >
                                    {inputProps.label}
                                  </Button>
                                </FormControl>
                              </Grid>
                            );
                          } else if (inputProps.type === "line") {
                            return (
                              <Grid xs={12} item>
                                <Divider
                                  orientation="horizontal"
                                  style={{ backgroundColor: "black" }}
                                />
                              </Grid>
                            );
                          }

                          return null;
                        })
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </form>
                </div>
              </div>
            ))}
          </Card>
          {noShowUpdateHraStatusPopup && (
            <Alert
              open={noShowUpdateHraStatusPopup}
              dialogTitle={`No Show`}
              handleClose={() => setNoShowUpdateHraStatusPopup(false)}
              title={`Are you sure you would like to change the hra status to No Show ?`}
              okText="Yes"
              cancelText="No"
              onAddClick={() => {
                customUpdateHraStatus(19);
              }}
              onNoClick={() => setNoShowUpdateHraStatusPopup(false)}
            />
          )}
          {cancelAssessmentUpdateHraStatusPopup && (
            <Alert
              open={cancelAssessmentUpdateHraStatusPopup}
              dialogTitle={`Cancel Assessment`}
              handleClose={() => setCancelAssessmentUpdateHraStatusPopup(false)}
              title={`Are you sure you would like to change the hra status to Cancel Assessment ?`}
              okText="Yes"
              cancelText="No"
              onAddClick={() => {
                customUpdateHraStatus(12);
              }}
              onNoClick={() => setCancelAssessmentUpdateHraStatusPopup(false)}
            />
          )}
          {isVisitTypeAlertOpen && (
            <Alert
              open={isVisitTypeAlertOpen}
              handleClose={() => setIsVisitTypeAlertOpen(false)}
              title={`Do you want to change Visit Type ?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleVisitTypeUpdate}
              onNoClick={handleVisitTypeCancel}
            />
          )}
          {openSignSubmitDialog && (
            <ConfirmDigitalSignatureDialog
              openConfirmDigitalSign={openSignSubmitDialog}
              onCloseConfirmDigitalSignatureDialogBox={() => {
                setSignSubmitDialog(false);
              }}
              onSubmitCommentForClinician={(providerSignature) => {
                handlePatientSave(providerSignature);
              }}
              onBack={() => {
                setSignSubmitDialog(false);
              }}
              clinicianName={clinicianName}
              signCanvasImg={providerSignature}
              speciality={speciality}
              fromFillHra={true}
            />
          )}
          {isEvaluationDateAlertOpen && (
            <Alert
              open={isEvaluationDateAlertOpen}
              handleClose={() => setIsEvaluationDateAlertOpen(false)}
              title={`Are you sure you want to change the evaluation date ?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedTermDateTypeUpdate}
              onNoClick={handleAssignedTermDateTypeCancel}
            />
          )}
          {errorSignSubmitBtn && (
            <Alert
              open={errorSignSubmitBtn}
              handleClose={() => setErrorSignSubmitBtn(false)}
              title={errorSignSubmitBtnMsg}
              okText="OK"
              onAddClick={() => {
                setErrorSignSubmitBtn(false);
                setErrorSignSubmitBtnMsg("");
              }}
              //onNoClick={handleAssignedTermDateTypeCancel}
            />
          )}

          {emptyDateErrorPopup && (
            <Alert
              open={emptyDateErrorPopup}
              handleClose={() => setEmptyDateErrorPopup(false)}
              title={emptyDateErrorMessg}
              okText="OK"
              // cancelText="No"
              onAddClick={() => {
                setEmptyDateErrorPopup(false);
                setEmptyDateErrorMessg("");
              }}
              //onNoClick={handleAssignedTermDateTypeCancel}
            />
          )}
        </Grid>
        <Grid item xs={2} style={{ padding: "3px 7px" }}>
          <CardSections
            //toasterOpen={toasterOpen}
            //setToasterOpen={setToasterOpen}
            //toasterSeverity={toasterSeverity}
            //toasterMessage={toasterMessage}
            routedFrom="FillHra"
            handleSaveClick={handleSaveClick}
            handleMiniSave={handleMiniSave}
            // handlePreventiveSave={handlePreventiveSave}
            handlePainSave={handlePainSave}
            handleAllergiesSave={handleAllergiesSave}
            // handleMedicalSave={handleMedicalSave}
            handleScreeningsSave={handleScreeningsSave}
            handleVitalSave={handleVitalSave}
            handleSavesFData={handleSavesFData}
            handleSelfSave={handleSelfSave}
            handleCovidSave={handleCovidSave}
            handlePatientSave={handlePatientSave}
            handleActivitiesSave={handleActivitiesSave}
            handleExamReviewSave={handleExamReviewSave}
            handleHomeSafetySave={handleHomeSafetySave}
            handleRosSave={handleRosSave}
            sections={sections}
            currSection={currSection}
            handleCardClick={handleCardClick}
            handleEditForm={handleEditForm}
            handleFieldConfigCheck={handleFieldConfigCheck}
          />
        </Grid>
      </Grid>

      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
}
export default FillHra;
