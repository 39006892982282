import {
  getObjectData,
  setObjectData,
} from "../../../../utils/helpers.DynamicForm";

const examReview_hashmap = [
  ["exam_genAppearNormal", "constitutional.generalAppearance.normal"],
  ["exam_genAppearAbnormal", "constitutional.generalAppearance.abnormal"],
  ["exam_genAppearComment", "constitutional.generalAppearance.comment"],

  ["exam_headFaceExamNormal", "headAndFace.examination.normal"],
  ["exam_headFaceExamAbnormal", "headAndFace.examination.abnormal"],
  ["exam_headFaceExamComment", "headAndFace.examination.comment"],
  ["exam_headFacePalpNormal", "headAndFace.palpation.normal"],
  ["exam_headFacePalpAbnormal", "headAndFace.palpation.abnormal"],
  ["exam_headFacePalpComment", "headAndFace.palpation.comment"],

  ["exam_eyesInspNormal", "eyes.inspection.normal"],
  ["exam_eyesInspAbnormal", "eyes.inspection.abnormal"],
  ["exam_eyesInspComment", "eyes.inspection.comment"],
  ["exam_eyesExamNormal", "eyes.examination.normal"],
  ["exam_eyesExamAbnormal", "eyes.examination.abnormal"],
  ["exam_eyesExamComment", "eyes.examination.comment"],

  ["exam_entExtInspNormal", "earsNoseMouthThroat.externalInspection.normal"],
  [
    "exam_entExtInspAbnormal",
    "earsNoseMouthThroat.externalInspection.abnormal",
  ],
  ["exam_entExtInspComment", "earsNoseMouthThroat.externalInspection.comment"],
  ["exam_entOtosExamNormal", "earsNoseMouthThroat.otoscopicExamination.normal"],
  [
    "exam_entOtosExamAbnormal",
    "earsNoseMouthThroat.otoscopicExamination.abnormal",
  ],
  [
    "exam_entOtosExamComment",
    "earsNoseMouthThroat.otoscopicExamination.comment",
  ],
  [
    "exam_entAssessHearNormal",
    "earsNoseMouthThroat.assessmentOfHearing.normal",
  ],
  [
    "exam_entAssessHearAbnormal",
    "earsNoseMouthThroat.assessmentOfHearing.abnormal",
  ],
  [
    "exam_entAssessHearComment",
    "earsNoseMouthThroat.assessmentOfHearing.comment",
  ],
  ["exam_entNasalInspNormal", "earsNoseMouthThroat.inspectionOfNasal.normal"],
  [
    "exam_entNasalInspAbnormal",
    "earsNoseMouthThroat.inspectionOfNasal.abnormal",
  ],
  ["exam_entNasalInspComment", "earsNoseMouthThroat.inspectionOfNasal.comment"],
  ["exam_entInspLipsNormal", "earsNoseMouthThroat.inspectionOfLips.normal"],
  ["exam_entInspLipsAbnormal", "earsNoseMouthThroat.inspectionOfLips.abnormal"],
  ["exam_entInspLipsComment", "earsNoseMouthThroat.inspectionOfLips.comment"],
  [
    "exam_entOropExamNormal",
    "earsNoseMouthThroat.examinationOfOropharynx.normal",
  ],
  [
    "exam_entOropExamAbnormal",
    "earsNoseMouthThroat.examinationOfOropharynx.abnormal",
  ],
  [
    "exam_entOropExamComment",
    "earsNoseMouthThroat.examinationOfOropharynx.comment",
  ],

  ["exam_neckExamNormal", "neck.examinationOfNeck.normal"],
  ["exam_neckExamAbnormal", "neck.examinationOfNeck.abnormal"],
  ["exam_neckExamComment", "neck.examinationOfNeck.comment"],
  ["exam_neckExamThyroidNormal", "neck.examinationOfThyroid.normal"],
  ["exam_neckExamThyroidAbnormal", "neck.examinationOfThyroid.abnormal"],
  ["exam_neckExamThyroidComment", "neck.examinationOfThyroid.comment"],

  ["exam_pulmAssessRespNormal", "pulmonary.assessmentOfRespiratory.normal"],
  ["exam_pulmAssessRespAbnormal", "pulmonary.assessmentOfRespiratory.abnormal"],
  ["exam_pulmAssessRespComment", "pulmonary.assessmentOfRespiratory.comment"],
  ["exam_pulmAuscLungsNormal", "pulmonary.auscultationOfLungs.normal"],
  ["exam_pulmAuscLungsAbnormal", "pulmonary.auscultationOfLungs.abnormal"],
  ["exam_pulmAuscLungsComment", "pulmonary.auscultationOfLungs.comment"],

  ["exam_cardioAusHeartNormal", "cardiovascular.auscultationOfHeart.normal"],
  [
    "exam_cardioAusHeartAbnormal",
    "cardiovascular.auscultationOfHeart.abnormal",
  ],
  ["exam_cardioAusHeartComment", "cardiovascular.auscultationOfHeart.comment"],
  [
    "exam_cardioPalpAusNormal",
    "cardiovascular.palpationAndAuscultation.normal",
  ],
  [
    "exam_cardioPalpAusAbnormal",
    "cardiovascular.palpationAndAuscultation.abnormal",
  ],
  [
    "exam_cardioPalpAusComment",
    "cardiovascular.palpationAndAuscultation.comment",
  ],
  ["exam_cardioPedalPulseNormal", "cardiovascular.pedalPulses.normal"],
  ["exam_cardioPedalPulseAbnormal", "cardiovascular.pedalPulses.abnormal"],
  ["exam_cardioPedalPulseComment", "cardiovascular.pedalPulses.comment"],
  ["exam_cardioExamEdemaNormal", "cardiovascular.examinationOfEdema.normal"],
  [
    "exam_cardioExamEdemaAbnormal",
    "cardiovascular.examinationOfEdema.abnormal",
  ],
  ["exam_cardioExamEdemaComment", "cardiovascular.examinationOfEdema.comment"],
  [
    "exam_cardioExamRadialNormal",
    "cardiovascular.examinationOfRadialPulses.normal",
  ],
  [
    "exam_cardioExamRadialAbnormal",
    "cardiovascular.examinationOfRadialPulses.abnormal",
  ],
  [
    "exam_cardioExamRadialComment",
    "cardiovascular.examinationOfRadialPulses.comment",
  ],

  ["exam_lympPalpCerviNormal", "lymphatic.palpationOfCervical.normal"],
  ["exam_lympPalpCerviAbnormal", "lymphatic.palpationOfCervical.abnormal"],
  ["exam_lympPalpCerviComment", "lymphatic.palpationOfCervical.comment"],
  ["exam_lympPalpPreauricNormal", "lymphatic.palpationOfPreauricular.normal"],
  [
    "exam_lympPalpPreauricAbnormal",
    "lymphatic.palpationOfPreauricular.abnormal",
  ],
  ["exam_lympPalpPreauricComment", "lymphatic.palpationOfPreauricular.comment"],
  ["exam_lympExamGaitNormal", "lymphatic.examinationOfGait.normal"],
  ["exam_lympExamGaitAbnormal", "lymphatic.examinationOfGait.abnormal"],
  ["exam_lympExamGaitComment", "lymphatic.examinationOfGait.comment"],
  ["exam_lympPalpSubmandiNormal", "lymphatic.palpationOfSubmandibular.normal"],
  [
    "exam_lympPalpSubmandiAbnormal",
    "lymphatic.palpationOfSubmandibular.abnormal",
  ],
  [
    "exam_lympPalpSubmandiComment",
    "lymphatic.palpationOfSubmandibular.comment",
  ],

  ["exam_muscSkelExamGaitNormal", "musculoskeletal.examinationOfGait.normal"],
  [
    "exam_muscSkelExamGaitAbnormal",
    "musculoskeletal.examinationOfGait.abnormal",
  ],
  ["exam_muscSkelExamGaitComment", "musculoskeletal.examinationOfGait.comment"],
  ["exam_muscSkelInspDigitNormal", "musculoskeletal.inspectionOfDigits.normal"],
  [
    "exam_muscSkelInspDigitAbnormal",
    "musculoskeletal.inspectionOfDigits.abnormal",
  ],
  [
    "exam_muscSkelInspDigitComment",
    "musculoskeletal.inspectionOfDigits.comment",
  ],
  ["exam_muscSkelInspJointNormal", "musculoskeletal.inspectionOfJoints.normal"],
  [
    "exam_muscSkelInspJointAbnormal",
    "musculoskeletal.inspectionOfJoints.abnormal",
  ],
  [
    "exam_muscSkelInspJointComment",
    "musculoskeletal.inspectionOfJoints.comment",
  ],
  [
    "exam_muscSkelAssessRangeNormal",
    "musculoskeletal.assessmentOfRange.normal",
  ],
  [
    "exam_muscSkelAssessRangeAbnormal",
    "musculoskeletal.assessmentOfRange.abnormal",
  ],
  [
    "exam_muscSkelAssessRangeComment",
    "musculoskeletal.assessmentOfRange.comment",
  ],
  [
    "exam_muscSkelAssessStableNormal",
    "musculoskeletal.assessmentOfStability.normal",
  ],
  [
    "exam_muscSkelAssessStableAbnormal",
    "musculoskeletal.assessmentOfStability.abnormal",
  ],
  [
    "exam_muscSkelAssessStableComment",
    "musculoskeletal.assessmentOfStability.comment",
  ],
  [
    "exam_muscSkelAssessMuscleNormal",
    "musculoskeletal.assessmentOfMuscle.normal",
  ],
  [
    "exam_muscSkelAssessMuscleAbnormal",
    "musculoskeletal.assessmentOfMuscle.abnormal",
  ],
  [
    "exam_muscSkelAssessMuscleComment",
    "musculoskeletal.assessmentOfMuscle.comment",
  ],

  ["exam_skinAssessSkinNormal", "skin.assessmentOfStability.normal"],
  ["exam_skinAssessSkinAbnormal", "skin.assessmentOfStability.abnormal"],
  ["exam_skinAssessSkinComment", "skin.assessmentOfStability.comment"],
  ["exam_skinInspSkinNormal", "skin.inspectionOfSkin.normal"],
  ["exam_skinInspSkinAbnormal", "skin.inspectionOfSkin.abnormal"],
  ["exam_skinInspSkinComment", "skin.inspectionOfSkin.comment"],

  ["exam_psychJudgeNormal", "psychiatric.judgementOrInsight.normal"],
  ["exam_psychJudgeAbnormal", "psychiatric.judgementOrInsight.abnormal"],
  ["exam_psychJudgeComment", "psychiatric.judgementOrInsight.comment"],
  ["exam_psychOrientNormal", "psychiatric.orientationOfPerson.normal"],
  ["exam_psychOrientAbnormal", "psychiatric.orientationOfPerson.abnormal"],
  ["exam_psychOrientComment", "psychiatric.orientationOfPerson.comment"],
  ["exam_psychRemoteNormal", "psychiatric.remoteMemory.normal"],
  ["exam_psychRemoteAbnormal", "psychiatric.remoteMemory.abnormal"],
  ["exam_psychRemoteComment", "psychiatric.remoteMemory.comment"],
  ["exam_psychMoodNormal", "psychiatric.moodAndAffect.normal"],
  ["exam_psychMoodAbnormal", "psychiatric.moodAndAffect.abnormal"],
  ["exam_psychMoodComment", "psychiatric.moodAndAffect.comment"],

  ["exam_neuroRombergNormal", "neurologic.rombergTest.normal"],
  ["exam_neuroRombergAbnormal", "neurologic.rombergTest.abnormal"],
  ["exam_neuroRombergComment", "neurologic.rombergTest.comment"],
  ["exam_neuroReflexNormal", "neurologic.examinationOfReflexes.normal"],
  ["exam_neuroReflexAbnormal", "neurologic.examinationOfReflexes.abnormal"],
  ["exam_neuroReflexComment", "neurologic.examinationOfReflexes.comment"],
  ["exam_neuroSenseNormal", "neurologic.examinationOfSensation.normal"],
  ["exam_neuroSenseAbnormal", "neurologic.examinationOfSensation.abnormal"],
  ["exam_neuroSenseComment", "neurologic.examinationOfSensation.comment"],
  ["exam_neuroCoordinateNormal", "neurologic.coordination.normal"],
  ["exam_neuroCoordinateAbnormal", "neurologic.coordination.abnormal"],
  ["exam_neuroCoordinateComment", "neurologic.coordination.comment"],

  ["exam_diabFootNormal", "diabetes.footExam.normal"],
  ["exam_diabFootAbnormal", "diabetes.footExam.abnormal"],
  ["exam_diabFootComment", "diabetes.footExam.comment"],
];

const examReview_optionsTreeMapObject = {
  exam_genAppearNormal: "normal",
  exam_genAppearAbnormal: "abnormal",
  exam_headFaceExamNormal: "normal",
  exam_headFaceExamAbnormal: "abnormal",
  exam_headFacePalpNormal: "normal",
  exam_headFacePalpAbnormal: "abnormal",
  exam_eyesInspNormal: "normal",
  exam_eyesInspAbnormal: "abnormal",
  exam_eyesExamNormal: "normal",
  exam_eyesExamAbnormal: "abnormal",
  exam_neckExamNormal: "normal",
  exam_neckExamAbnormal: "abnormal",
  exam_neckExamThyroidNormal: "normal",
  exam_neckExamThyroidAbnormal: "abnormal",
  exam_entExtInspNormal: "normal",
  exam_entExtInspAbnormal: "abnormal",
  exam_entOtosExamNormal: "normal",
  exam_entOtosExamAbnormal: "abnormal",
  exam_entAssessHearNormal: "normal",
  exam_entAssessHearAbnormal: "abnormal",
  exam_entNasalInspNormal: "normal",
  exam_entNasalInspAbnormal: "abnormal",
  exam_entInspLipsNormal: "normal",
  exam_entInspLipsAbnormal: "abnormal",
  exam_entOropExamNormal: "normal",
  exam_entOropExamAbnormal: "abnormal",
  exam_pulmAssessRespNormal: "normal",
  exam_pulmAssessRespAbnormal: "abnormal",
  exam_pulmAuscLungsNormal: "normal",
  exam_pulmAuscLungsAbnormal: "abnormal",
  exam_cardioAusHeartNormal: "normal",
  exam_cardioAusHeartAbnormal: "abnormal",
  exam_cardioPalpAusNormal: "normal",
  exam_cardioPalpAusAbnormal: "abnormal",
  exam_cardioPedalPulseNormal: "normal",
  exam_cardioPedalPulseAbnormal: "abnormal",
  exam_cardioExamEdemaNormal: "normal",
  exam_cardioExamEdemaAbnormal: "abnormal",
  exam_cardioExamRadialNormal: "normal",
  exam_cardioExamRadialAbnormal: "abnormal",
  exam_lympPalpCerviNormal: "normal",
  exam_lympPalpCerviAbnormal: "abnormal",
  exam_lympPalpPreauricNormal: "normal",
  exam_lympPalpPreauricAbnormal: "abnormal",
  exam_lympExamGaitNormal: "normal",
  exam_lympExamGaitAbnormal: "abnormal",
  exam_lympPalpSubmandiNormal: "normal",
  exam_lympPalpSubmandiAbnormal: "abnormal",
  exam_muscSkelExamGaitNormal: "normal",
  exam_muscSkelExamGaitAbnormal: "abnormal",
  exam_muscSkelInspDigitNormal: "normal",
  exam_muscSkelInspDigitAbnormal: "abnormal",
  exam_muscSkelInspJointNormal: "normal",
  exam_muscSkelInspJointAbnormal: "abnormal",
  exam_muscSkelAssessRangeNormal: "normal",
  exam_muscSkelAssessRangeAbnormal: "abnormal",
  exam_muscSkelAssessStableNormal: "normal",
  exam_muscSkelAssessStableAbnormal: "abnormal",
  exam_muscSkelAssessMuscleNormal: "normal",
  exam_muscSkelAssessMuscleAbnormal: "abnormal",
  exam_skinAssessSkinNormal: "normal",
  exam_skinAssessSkinAbnormal: "abnormal",
  exam_skinInspSkinNormal: "normal",
  exam_skinInspSkinAbnormal: "abnormal",
  exam_psychJudgeNormal: "normal",
  exam_psychJudgeAbnormal: "abnormal",
  exam_psychOrientNormal: "normal",
  exam_psychOrientAbnormal: "abnormal",
  exam_psychRemoteNormal: "normal",
  exam_psychRemoteAbnormal: "abnormal",
  exam_psychMoodNormal: "normal",
  exam_psychMoodAbnormal: "abnormal",
  exam_neuroRombergNormal: "normal",
  exam_neuroRombergAbnormal: "abnormal",
  exam_neuroReflexNormal: "normal",
  exam_neuroReflexAbnormal: "abnormal",
  exam_neuroSenseNormal: "normal",
  exam_neuroSenseAbnormal: "abnormal",
  exam_neuroCoordinateNormal: "normal",
  exam_neuroCoordinateAbnormal: "abnormal",

  exam_diabFootNormal: "normal",
  exam_diabFootAbnormal: "abnormal",
};

const examReviewOptionsTreeWrappers = [
  ["exam_genAppear", ["exam_genAppearNormal", "exam_genAppearAbnormal"]],
  [
    "exam_headFaceExam",
    ["exam_headFaceExamNormal", "exam_headFaceExamAbnormal"],
  ],
  [
    "exam_headFacePalp",
    ["exam_headFacePalpNormal", "exam_headFacePalpAbnormal"],
  ],
  ["exam_eyesInsp", ["exam_eyesInspNormal", "exam_eyesInspAbnormal"]],
  ["exam_eyesExam", ["exam_eyesExamNormal", "exam_eyesExamAbnormal"]],
  ["exam_entExtInsp", ["exam_entExtInspNormal", "exam_entExtInspAbnormal"]],
  ["exam_entOtosExam", ["exam_entOtosExamNormal", "exam_entOtosExamAbnormal"]],
  [
    "exam_entAssessHear",
    ["exam_entAssessHearNormal", "exam_entAssessHearAbnormal"],
  ],
  [
    "exam_entNasalInsp",
    ["exam_entNasalInspNormal", "exam_entNasalInspAbnormal"],
  ],
  ["exam_entInspLips", ["exam_entInspLipsNormal", "exam_entInspLipsAbnormal"]],
  ["exam_entOropExam", ["exam_entOropExamNormal", "exam_entOropExamAbnormal"]],
  ["exam_neckExam", ["exam_neckExamNormal", "exam_neckExamAbnormal"]],
  [
    "exam_neckExamThyroid",
    ["exam_neckExamThyroidNormal", "exam_neckExamThyroidAbnormal"],
  ],
  [
    "exam_pulmAssessResp",
    ["exam_pulmAssessRespNormal", "exam_pulmAssessRespAbnormal"],
  ],
  [
    "exam_pulmAuscLungs",
    ["exam_pulmAuscLungsNormal", "exam_pulmAuscLungsAbnormal"],
  ],
  [
    "exam_cardioAusHeart",
    ["exam_cardioAusHeartNormal", "exam_cardioAusHeartAbnormal"],
  ],
  [
    "exam_cardioPalpAus",
    ["exam_cardioPalpAusNormal", "exam_cardioPalpAusAbnormal"],
  ],
  [
    "exam_cardioPedalPulse",
    ["exam_cardioPedalPulseNormal", "exam_cardioPedalPulseAbnormal"],
  ],
  [
    "exam_cardioExamEdema",
    ["exam_cardioExamEdemaNormal", "exam_cardioExamEdemaAbnormal"],
  ],
  [
    "exam_cardioExamRadial",
    ["exam_cardioExamRadialNormal", "exam_cardioExamRadialAbnormal"],
  ],

  [
    "exam_lympPalpCervi",
    ["exam_lympPalpCerviNormal", "exam_lympPalpCerviAbnormal"],
  ],
  [
    "exam_lympPalpPreauric",
    ["exam_lympPalpPreauricNormal", "exam_lympPalpPreauricAbnormal"],
  ],
  [
    "exam_lympExamGait",
    ["exam_lympExamGaitNormal", "exam_lympExamGaitAbnormal"],
  ],
  [
    "exam_lympPalpSubmandi",
    ["exam_lympPalpSubmandiNormal", "exam_lympPalpSubmandiAbnormal"],
  ],

  [
    "exam_muscSkelExamGait",
    ["exam_muscSkelExamGaitNormal", "exam_muscSkelExamGaitAbnormal"],
  ],
  [
    "exam_muscSkelInspDigit",
    ["exam_muscSkelInspDigitNormal", "exam_muscSkelInspDigitAbnormal"],
  ],
  [
    "exam_muscSkelInspJoint",
    ["exam_muscSkelInspJointNormal", "exam_muscSkelInspJointAbnormal"],
  ],
  [
    "exam_muscSkelAssessRange",
    ["exam_muscSkelAssessRangeNormal", "exam_muscSkelAssessRangeAbnormal"],
  ],
  [
    "exam_muscSkelAssessStable",
    ["exam_muscSkelAssessStableNormal", "exam_muscSkelAssessStableAbnormal"],
  ],
  [
    "exam_muscSkelAssessMuscle",
    ["exam_muscSkelAssessMuscleNormal", "exam_muscSkelAssessMuscleAbnormal"],
  ],
  [
    "exam_skinAssessSkin",
    ["exam_skinAssessSkinNormal", "exam_skinAssessSkinAbnormal"],
  ],
  [
    "exam_skinInspSkin",
    ["exam_skinInspSkinNormal", "exam_skinInspSkinAbnormal"],
  ],

  ["exam_psychJudge", ["exam_psychJudgeNormal", "exam_psychJudgeAbnormal"]],
  ["exam_psychOrient", ["exam_psychOrientNormal", "exam_psychOrientAbnormal"]],
  ["exam_psychRemote", ["exam_psychRemoteNormal", "exam_psychRemoteAbnormal"]],
  ["exam_psychMood", ["exam_psychMoodNormal", "exam_psychMoodAbnormal"]],

  [
    "exam_neuroRomberg",
    ["exam_neuroRombergNormal", "exam_neuroRombergAbnormal"],
  ],
  ["exam_neuroReflex", ["exam_neuroReflexNormal", "exam_neuroReflexAbnormal"]],
  ["exam_neuroSense", ["exam_neuroSenseNormal", "exam_neuroSenseAbnormal"]],
  [
    "exam_neuroCoordinate",
    ["exam_neuroCoordinateNormal", "exam_neuroCoordinateAbnormal"],
  ],
  ["exam_diabFoot", ["exam_diabFootNormal", "exam_diabFootAbnormal"]],
];

export const getExamReviewData = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  examReview_hashmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  examReviewOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  const exam_neuroCranialTest = getObjectData(
    apiData,
    "neurologic.specificCranialNerve",
  );
  const exam_neuroCranialTestIndicate = getObjectData(
    apiData,
    "neurologic.indicateCranialNerveDeficits",
  );
  const exam_diabFootAbnormRfoot = getObjectData(
    apiData,
    "diabetes.footExam.abnormalSubAnswers.rFoot",
  );
  const exam_diabFootAbnormLfoot = getObjectData(
    apiData,
    "diabetes.footExam.abnormalSubAnswers.lFoot",
  );
  const exam_diabFootAbnormBiLateral = getObjectData(
    apiData,
    "diabetes.footExam.abnormalSubAnswers.bilateral",
  );
  const exam_diabFootAbnormComment = getObjectData(
    apiData,
    "diabetes.footExam.abnormalSubAnswers.comment",
  );

  tempDbData = {
    ...tempData,
    exam_neuroCranialTest,
    exam_neuroCranialTestIndicate,
    exam_diabFootAbnormRfoot,
    exam_diabFootAbnormLfoot,
    exam_diabFootAbnormBiLateral,
    exam_diabFootAbnormComment,
  };

  return tempDbData;
};

export const setExamReviewData = (formData) => {
  let tempData = {};

  examReviewOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1]),
    };
  });

  let payloadData = {};

  examReview_hashmap.forEach((questionPair) => {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
  });

  const exam_neuroCranialTest = formData["exam_neuroCranialTest"];
  const exam_neuroCranialTestIndicate =
    formData["exam_neuroCranialTestIndicate"];

  const exam_diabFootAbnormRfoot =
    formData["diabetes.footExam.abnormalSubAnswers.rFoot"];
  const exam_diabFootAbnormLfoot =
    formData["diabetes.footExam.abnormalSubAnswers.lFoot"];
  const exam_diabFootAbnormBiLateral =
    formData["diabetes.footExam.abnormalSubAnswers.bilateral"];
  const exam_diabFootAbnormComment =
    formData["diabetes.footExam.abnormalSubAnswers.comment"];

  setObjectData(
    payloadData,
    "neurologic.specificCranialNerve",
    exam_neuroCranialTest,
  );
  setObjectData(
    payloadData,
    "neurologic.indicateCranialNerveDeficits",
    exam_neuroCranialTestIndicate,
  );
  setObjectData(
    payloadData,
    "diabetes.footExam.abnormalSubAnswers.rFoot",
    exam_diabFootAbnormRfoot,
  );
  setObjectData(
    payloadData,
    "diabetes.footExam.abnormalSubAnswers.lFoot",
    exam_diabFootAbnormLfoot,
  );
  setObjectData(
    payloadData,
    "diabetes.footExam.abnormalSubAnswers.bilateral",
    exam_diabFootAbnormBiLateral,
  );
  setObjectData(
    payloadData,
    "diabetes.footExam.abnormalSubAnswers.comment",
    exam_diabFootAbnormComment,
  );

  return payloadData;
};

const getWrapper = (tempData, keyArray) => {
  let temp = getOptionsSelected(tempData, keyArray);
  return temp;
};

const setWrapper = (tempData, keyArray) => {
  let temp = setOptionsSelected(tempData, keyArray);
  return temp;
};

const getOptionsSelected = (optionObject, optionArray, multiSelect = false) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption;
  if (multiSelect) {
    selectedOption = selectedVariable.map((variable) => {
      return examReview_optionsTreeMapObject[variable];
    });
  } else {
    selectedOption = examReview_optionsTreeMapObject[selectedVariable[0]];
  }
  return selectedOption;
};

const setOptionsSelected = (
  optionsSelected,
  optionArray,
  multiSelect = false,
) => {
  let temp = {};

  if (multiSelect) {
    optionArray.forEach((option) => {
      temp[option] = optionsSelected.includes(
        examReview_optionsTreeMapObject[option],
      )
        ? "1"
        : "2";
    });
  } else {
    optionArray.forEach((option) => {
      temp[option] =
        examReview_optionsTreeMapObject[option] === optionsSelected ? "1" : "2";
    });
  }
  return temp;
};
