import {
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Checkbox,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useState, useContext, useEffect } from "react";
export const frequencyOptions = [
  "AC",
  "AC & HS",
  "BID",
  "HS",
  "PC",
  "Q Month",
  "QAM",
  "QD",
  "QID",
  "QOD",
  "QOW",
  "QPM",
  "QW",
  "TID",
];
export const routeOptions = [
  "G = Tube",
  "IM = Intramuscular",
  "INH = Inhalation",
  "IV = Intravenous",
  "N = Nasal",
  "PO = By Mouth",
  "R = Rectal",
  "S = Sublingual",
  "SQ = Subcutaneous",
];
export const prescriptionStatus = [
  "D = Discontinued",
  "H = Hold",
  "N = New",
  "O = Ongoing",
];
export function PedsDynamicTableCounterMedications({
  counterMedicationsRowData,
  setCounterMedicationsRowData,
  disabled,
}) {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    // whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    //whiteSpace: "nowrap",
  };

  const handleAddRow = () => {
    if (
      Array.isArray(counterMedicationsRowData)
      // &&
      // counterMedicationsRowData.length < 5
    ) {
      const uniqueId = Date.now().toString();
      setCounterMedicationsRowData([
        ...counterMedicationsRowData,
        { id: uniqueId },
      ]);
    } else {
      // console.log("You can add a maximum of 5 rows.");
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...counterMedicationsRowData];
    updatedRowData.splice(index, 1);
    setCounterMedicationsRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...counterMedicationsRowData];
    updatedRowData[index][label] = e.target.value;
    setCounterMedicationsRowData(updatedRowData);
  };

  if (!counterMedicationsRowData || counterMedicationsRowData.length === 0) {
    setCounterMedicationsRowData([{ id: Date.now().toString() }]);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        //width: "106%",
        margin: "auto",
      }}
    >
      <Table
        sx={{ tableLayout: "auto", margin: "10px", overflow: "hidden" }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={{ ...tableCellStyle, width: "250px" }}>
              Prescription
            </TableCell>
            <TableCell style={{ ...tableCellStyle, width: "250px" }}>
              Dose/Units
            </TableCell>
            <TableCell style={{ ...tableCellStyle, width: "250px" }}>
              Route
            </TableCell>
            <TableCell style={{ ...tableCellStyle, width: "250px" }}>
              Frequency
            </TableCell>
            <TableCell style={{ ...tableCellStyle, width: "250px" }}>
              Status
            </TableCell>
            <TableCell style={{ ...tableCellStyle, width: "250px" }}>
              Reasons
            </TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                disabled={disabled}
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={() => {
                  if (disabled) {
                    return null;
                  }
                  handleAddRow();
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {counterMedicationsRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ ...tableCellStyle, width: "250px" }}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "description")
                  }
                  disabled={disabled}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.description || ""}
                />
              </TableCell>
              <TableCell style={{ ...tableCellStyle, width: "250px" }}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "doseOrUnits")
                  }
                  disabled={disabled}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.doseOrUnits || ""}
                />
              </TableCell>
              <TableCell style={{ ...tableCellStyle, width: "250px" }}>
                <Select
                  disabled={disabled}
                  value={row.route || ""}
                  onChange={(e) => handleTableDataChange(e, index, "route")}
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    style: { maxHeight: 300 },
                  }}
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {routeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell style={{ ...tableCellStyle, width: "250px" }}>
                <Select
                  disabled={disabled}
                  value={row.frequency || ""}
                  onChange={(e) => handleTableDataChange(e, index, "frequency")}
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    style: { maxHeight: 300 },
                  }}
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {frequencyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={{ ...tableCellStyle, width: "250px" }}>
                <Select
                  disabled={disabled}
                  value={row.status || ""}
                  onChange={(e) => handleTableDataChange(e, index, "status")}
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    style: { maxHeight: 300 },
                  }}
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {prescriptionStatus.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell style={{ ...tableCellStyle, width: "250px" }}>
                <TextField
                  disabled={disabled}
                  onChange={(e) => handleTableDataChange(e, index, "reasons")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.reasons || ""}
                />
              </TableCell>
              {counterMedicationsRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    disabled={disabled}
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => {
                      if (disabled) {
                        return null;
                      }
                      handleRemoveRow(index);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            marginRight: "20px",
            color: "red",
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          Click on - button to delete respective row.
        </div>
        <div
          style={{
            color: "red",
            display: "flex",
            justifyContent: "flex-start",
            width: "50%",
          }}
        >
          {" "}
          Click on + button to add Prescription.
        </div>
      </div>
    </div>
  );
}
