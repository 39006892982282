import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Fade,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FocusCareBg from "../../../../assets/focuscares_bg.jpg";
import { TbLockCog } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import PropTypes from "prop-types";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";
import ValidationMessage from "../../../../resource_files/ValidationMessage.resx";
import Labels from "../../../../resource_files/Labels.resx";
import Toaster from "../../../toaster/toaster";
import ClinicianProfile from "./ClinicianProfile";

import "./userProfileCard.scss";

export default function UserDropMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState("");
  const [toasterMessage, setToasterMessage] = useState("");
  const [openClinicianProfile, setOpenClinicianProfile] = useState(false);
  const [profilePicImg, setProfilePicImg] = useState(FocusCareBg);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isValidEmail = (mail) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail);
  };
  const handleChangePasswordButton = async () => {
    const mail = sessionStorage.getItem("emailId");

    if (!mail) {
      setErrors(ValidationMessage.Email_Required);
      return;
    } else if (!isValidEmail(mail)) {
      setErrors(ValidationMessage.Email_Format_Invalid);
      return;
    }

    try {
      const response = await postApi("/users/updatePassword/change/sendOTP", {
        email: mail, // Use the entered email as receiver's email
      });
      if (response.status === 200) {
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        setToasterOpen(true);
        sessionStorage.setItem("emailId", mail);
        sessionStorage.setItem("otpType", "changePassword");
        navigate(`/Account/Otp`, {
          state: { username: mail, fromChangePassword: true },
        });
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response.response.data.message);
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const navigate = useNavigate();

  const handleOpenClinicianProfile = () => {
    setOpenClinicianProfile(true);
  };

  const roleId = sessionStorage.getItem("roleId");
  const [clinicianData, setClinicianData] = useState();

  const fetchClinicianDada = async () => {
    // Check if the data is already set
    // if (clinicianData) {
    //   return; // Avoid making the API call if data is already available
    // }

    try {
      const response = await getApi(`/clinicians/mydetails`);
      // Store the data to avoid re-fetching it
      setClinicianData(response?.data?.data);
      setProfilePicImg(clinicianData?.profilePicture);
    } catch (error) {
      console.error("Error fetching clinician data:", error);
    }
  };

 useEffect(() => {
 
    fetchClinicianDada();
  }, []);
  useEffect(() => {
    if (props?.profileImg) {
      setProfilePicImg(props?.profileImg);
    }
  }, [props]);

  return (
    // <div className="userProfileCard">
    //   <div
    //     className="userDetails"
    //     id="userProfileCardClick"
    //     onClick={(e) => handleClick(e)}
    //     style={{
    //       backgroundColor: open ? "var(--mainTheme600)" : "var(--mainTheme400)",
    //       borderRadius: open ? "5px 5px 0px 0px" : "8px",
    //     }}
    //   >
    //     <div className="image-profile">
    //       <img src={FocusCareBg} alt="brand-logo" />
    //     </div>
    //     <div className="name-profile">
    //       <p>Hi, {props.userName}</p>
    //     </div>
    //     <div className="icon-dropdown">
    //       {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    //     </div>
    //   </div>
    //   <Menu
    //     anchorEl={anchorEl}
    //     open={open}
    //     onClose={handleClose}
    //     TransitionComponent={Fade}
    //     sx={{
    //       "& .MuiMenu-paper": {
    //         borderRadius: "0px 0px 5px 5px",
    //         width: "13.8%",
    //       },
    //       "& .MuiMenuItem-root": {
    //         backgroundColor: `var(--mainTheme50) !important`,
    //         padding: "8px",
    //       },
    //       "& .MuiMenuItem-root:hover": {
    //         backgroundColor: `var(--mainTheme150) !important`,
    //         color: "#000000",
    //       },
    //     }}
    //   >
    //     {roleId == 5 ? (
    //       <MenuItem onClick={handleOpenClinicianProfile}>
    //         <ListItemIcon style={{ minWidth: "25px", fontSize: "20px" }}>
    //           <AiOutlineUser />
    //         </ListItemIcon>
    //         <ListItemText>View profile</ListItemText>
    //       </MenuItem>
    //     ) : null}
    //     <MenuItem onClick={handleChangePasswordButton}>
    //       <ListItemIcon style={{ minWidth: "25px", fontSize: "20px" }}>
    //         <TbLockCog />
    //       </ListItemIcon>
    //       <ListItemText>Change Password</ListItemText>
    //     </MenuItem>
    //     <MenuItem id="logout" onClick={props.handleLogout}>
    //       <ListItemIcon style={{ minWidth: "25px", fontSize: "20px" }}>
    //         <FiLogOut />
    //       </ListItemIcon>
    //       <ListItemText>Logout</ListItemText>
    //     </MenuItem>
    //   </Menu>

    //   <ClinicianProfile
    //     open={openClinicianProfile}
    //     handleClose={() => {
    //       setOpenClinicianProfile(false);
    //     }}
    //   />
    // </div>
    <div className="userProfileCard">
      <div
        className={`userDetails ${open ? "userDetailsOpen" : ""}`}
        id="userProfileCardClick"
        onClick={(e) => handleClick(e)}
      >
        <div className="image-profile">
          {roleId == "5" && Boolean(profilePicImg) ? (
            <img
              src={
                // props?.profileImg
                //   ? props?.profileImg
                //   : clinicianData?.profilePicture ?  clinicianData?.profilePicture : FocusCareBg
                profilePicImg
              }
              alt="brand-logo"
            />
          ) : (
            <img src={FocusCareBg} alt="brand-logo" />
          )}
        </div>
        <div className="name-profile">
          <p>Hi, {props.userName}</p>
        </div>
        <div className="icon-dropdown">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="profile-menu"
      >
        {roleId == 5 ? (
          <MenuItem onClick={handleOpenClinicianProfile}>
            <ListItemIcon className="menu-icon">
              <AiOutlineUser />
            </ListItemIcon>
            <ListItemText>View profile</ListItemText>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleChangePasswordButton}>
          <ListItemIcon className="menu-icon">
            <TbLockCog />
          </ListItemIcon>
          <ListItemText>Change Password</ListItemText>
        </MenuItem>
        <MenuItem id="logout" onClick={props.handleLogout}>
          <ListItemIcon className="menu-icon">
            <FiLogOut />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>

      <ClinicianProfile
        open={openClinicianProfile}
        handleClose={() => setOpenClinicianProfile(false)}
      />
    </div>
  );
}

UserDropMenu.propTypes = {
  userName: PropTypes.string,
  handleLogout: PropTypes.func,
};
