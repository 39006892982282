import React, { useState } from "react";
import Sidebar from "../../components/Dashboard/Sidebar/Sidebar";
import Header from "../../components/Dashboard/Header/Header";
import { AlertProvider } from "../../contexts/AlertContext";
import { Routes, Route } from "react-router-dom";
import CoderListPage from "./coderlist";
import Footer from "../../components/Footer";
import FillHRAForm from "../AdminDashboard/MemberListPage/FillHraForm";
export default function Index() {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  return (
    <AlertProvider>
      <div className="body fullWidth-body">
        {/* Coder Dashboard doesn't contains any menu so removing the sidebar  */}
        {/* <div className={!sidebarCollapse ? "sidebar" : "sidebar inactive"}>
          <Sidebar />
        </div> */}
        <div
          className={!sidebarCollapse ? "body-content" : "body-content active"}
        >
          <Header sidebarToggle={setSidebarCollapse} />
          <Routes>
            <Route path="/Coderlist" element={<CoderListPage />} />
            <Route
              path={`/FillHRAForm`}
              element={
                // <RedirectToLogin moveToLogin={hasLoggedIn}>
                <FillHRAForm />
                // </RedirectToLogin>
              }
            />
          </Routes>
          <Footer />
        </div>
      </div>
    </AlertProvider>
  );
}
