const pedsDemographics2024JsonData = [
  {
    title: "CHILD-DEMOGRAPHICS",
    isShowInPdf: true,
    questions: {
      type: "child-demo",
      fields: [
        {
          plan: "Plan",
          program: "Program",
          lob: "LOB",
          region: "Region",
          aligned: "Aligned",
          name: "Name",
          gender: "Gender at birth",
          currentGenderIdentity: "What is your current gender identity ?",
          currentGenderComment: "Current Gender Comment",
          //  gender: ["Male", "Female"],
          addressOne: "Address",
          addressTwo: "Address 2",
          addressThree: "Address 3",
          city: "City",
          state: "State",
          zip: "Zip",
          mAddressOne: "Mailing Address 1",
          mAddressTwo: "Mailing Address 2",
          mAddressThree: "Mailing Address 3",
          mCity: "Mailing City",
          mState: "Mailing State",
          mZip: "Mailing Zip",
          dob: "Date of Birth",
          age: "Age(as of date)",
          maritalStatus: "Marital Status",
          //   maritalStatus: [
          //     "Single",
          //     "Married",
          //     "Separated",
          //     "Divorced",
          //     "Widowed",
          //     "Registered Partnership",
          //   ],
          // insurance: "Insurance",
          memberIdentificationNo: "Member Identification Number",
          uniqueIdentificationNo: "Unique Identification Number",
          medicareId: "Medicare Identification Number",
          medicaidId: "Medicaid Identification Number",
          //socialSecurity: "Social Security *",  "guardianName","relationshipToChild"  "emergencyContact","ePhoneNumber","primaryCarePhysician","pPhoneNumber"
          ///hicn: "HICN",
          email: "Email",
          //  ePhoneNumber: "Phone Number",
          phoneNumber: "Phone Number",
          cellNumber: "Cell Number",
          alternateContactNumber: "Alternate Contact Number",
          emergencyContact: "Emergency Contact",
          ePhoneNumber: "Phone Number",
          primaryCarePhysician: "Primary Care Physician",
          pPhoneNumber: "Phone Number",
          guardianName: "Guardian Name",
          relationshipToChild: "Relationship to Child",
          gPhoneNumber: "Phone Number",
          gCellNumber: "Cell Number",
          gEmail: "Email",
          pcpAddress: "PCP Address",
          pcpCity: "PCP City",
          pcpState: "PCP State",
          pcpZip: "PCP Zip",
          pcpCountry: "PCP County",
          // "pcpfaxnumber" , "pcpnpi","officeId" ,"officeName"
          faxNumber: "Fax Number",
          pcpNpi: "PCP NPI",
          officeId: "Office ID",
          officeName: "Office Name",
          physicianComment: "Physician Comment",
          pFlag: "Were updates made to the PCP details?",
        },
      ],
    },
  },
];

export default pedsDemographics2024JsonData;
