const ros_hashmap_mehak2024 = [
  ["ros_respiratoryVal", "respiratory.isRespiratory"],
  ["ros_respiratoryComment", "respiratory.comment"],
  [
    "ros_respiratoryDiagnosesComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.comment",
  ],

  [
    "ros_respiratoryDiagnosesAcuteUpper",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpper",
  ],
  [
    "ros_respiratoryAcuteUpperActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryAcuteUpperSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryAcuteUpperMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryAcuteUpperOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryAcuteUpperNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryAcuteUpperHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.historyOf",
  ],

  [
    "ros_respiratoryDiagnosesAsthma",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthma",
  ],
  [
    "ros_respiratoryAsthmaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryAsthmaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryAsthmaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryAsthmaMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryAsthmaMedicationInhaled",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.inhaledOrOralSteroids",
  ],
  [
    "ros_respiratoryAsthmaMedicationRescueInhaler",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.rescueInhaler",
  ],
  [
    "ros_respiratoryAsthmaMedicationSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.comment",
  ],
  [
    "ros_respiratoryAsthmaOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryAsthmaSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryAsthmaNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryAsthmaActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryAsthmaCurrentExacerbationVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbation",
  ],
  [
    "ros_respiratoryAsthmaCurrentExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.comment",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.wheezing",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.shortnessOfBreath",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfRescue",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.useOfRescue",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.useOfVentilator",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.comment",
  ],

  [
    "ros_respiratoryDiagnosesChronicRespiratoryFailure",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailure",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureChronicUseOfo2",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.activeSubAnswer.treatment.chronicUseOfo2",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.activeSubAnswer.treatment.useOfVentilator",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_respiratoryDiagnosesChronicSputumFailure",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailure",
  ],
  [
    "ros_respiratoryChronicSputumFailureSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryChronicSputumFailureActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryChronicSputumFailureMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryChronicSputumFailureOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryChronicSputumFailureNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryChronicSputumFailureHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryDiagnosesCopd",
    "respiratory.isRespiratorySubAnswer.diagnoses.copd",
  ],
  [
    "ros_respiratoryCopdActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryCopdSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryCopdHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryCopdChronicBronchitis",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.type.chronicBronchitis",
  ],
  [
    "ros_respiratoryCopdEmphysema",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.type.emphysema",
  ],
  [
    "ros_respiratoryCopdUnknown",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.type.unknown",
  ],
  [
    "ros_respiratoryCopdTypeSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.type.comment",
  ],
  [
    "ros_respiratoryCopdMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryCopdOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryCopdSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryCopdNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryCopdActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryCopdO2Use",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.o2Use",
  ],
  [
    "ros_respiratoryCopdInhaled",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.select.inhaledOrOralSteroids",
  ],
  [
    "ros_respiratoryCopdRescueInhaler",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.select.rescueInhaler",
  ],
  [
    "ros_respiratoryCopdMedicationSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.select.comment",
  ],
  [
    "ros_respiratoryPatientExacerbationVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientExacerbation.isPatientExacerbation",
  ],
  [
    "ros_respiratoryPatientExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientExacerbation.comment",
  ],
  [
    "ros_respiratoryPatientExacerbationWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.wheezing",
  ],
  [
    "ros_respiratoryPatientExacerbationShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.shortnessOfBreath",
  ],
  [
    "ros_respiratoryPatientExacerbationUseOfRescueMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.useOfRescueMedications",
  ],
  [
    "ros_respiratoryPatientExacerbationUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.useOfVentilator",
  ],
  [
    "ros_respiratoryCopdSubPatientExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.comment",
  ],
  [
    "ros_respiratoryDiagnosesCysticFibrosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosis",
  ],
  [
    "ros_respiratoryCysticFibrosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryCysticFibrosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryCysticFibrosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryCysticFibrosisMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryCysticFibrosisOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryCysticFibrosisNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_respiratoryDiagnosesHypoventilation",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilation",
  ],
  [
    "ros_respiratoryHypoventilationActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryHypoventilationSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryHypoventilationHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryHypoventilationMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryHypoventilationOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryHypoventilationSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryHypoventilationNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryHypoventilationO2Use",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.select.o2Use",
  ],
  [
    "ros_respiratoryHypoventilationMorbidObesity",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.select.morbidObesity",
  ],
  [
    "ros_respiratoryHypoventilationActiveSelectSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.activeSubAnswer.select.comment",
  ],

  [
    "ros_respiratoryDiagnosesHypoxemia",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemia",
  ],
  [
    "ros_respiratoryHypoxemiaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryHypoxemiaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryHypoxemiaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryHypoxemiaMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryHypoxemiaOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryHypoxemiaNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryHypoxemiaO2Use",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.activeSubAnswer.select.o2Saturation",
  ],
  [
    "ros_respiratoryHypoxemiaActiveSelectSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.activeSubAnswer.select.comment",
  ],

  [
    "ros_respiratoryDiagnosesPneumonia",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumonia",
  ],
  [
    "ros_respiratoryPneumoniaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryPneumoniaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryPneumoniaViral",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.etiology.viral",
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerEtiologyComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.etiology.comment",
  ],
  [
    "ros_respiratoryPneumoniaPneumococcal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.etiology.pneumococcal",
  ],
  [
    "ros_respiratoryPneumoniaStaph",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.etiology.staph",
  ],
  [
    "ros_respiratoryPneumoniaOtherBacterial",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.etiology.otherBacterial",
  ],
  [
    "ros_respiratoryPneumoniaAspiration",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.etiology.aspiration",
  ],
  [
    "ros_respiratoryHistoryOfLungAbscessVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.historyOfLungAbscess.isHistoryOfLungAbscess",
  ],
  [
    "ros_respiratoryHistoryOfLungAbscessComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.historyOfLungAbscess.comment",
  ],
  [
    "ros_respiratoryHistoryOfEmpyemaVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.historyOfEmpyema.isHistoryOfEmpyema",
  ],
  [
    "ros_respiratoryHistoryOfEmpyemaComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.historyOfEmpyema.comment",
  ],
  [
    "ros_respiratoryPneumoniaMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryPneumoniaOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryPneumoniaSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryPneumoniaNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryDiagnosesPulmonaryFibrosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosis",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryDiagnosesPulmonaryEmbolism",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolism",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismAcute",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.select.acute",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismChronic",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.select.chronic",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.select.comment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfAcute",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.select.acute",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfChronic",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.select.chronic",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.select.comment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryEmbolismSubAnswer.describe.historyOfSubAnswer.treatment.comment",
  ],

  [
    "ros_respiratoryDiagnosesRespiratorDependence",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependence",
  ],
  [
    "ros_respiratoryRespiratorDependenceActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryRespiratorDependenceSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryRespiratorDependenceHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryRespiratorDependenceMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryRespiratorDependenceOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryRespiratorDependenceNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryRespiratorDependenceTracheostomyPresent",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.activeSubAnswer.treatment.tracheostomyPresent",
  ],
  [
    "ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_respiratoryDiagnosesRespiratoryArrest",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrest",
  ],
  [
    "ros_respiratoryRespiratoryArrestSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryRespiratoryArrestActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryRespiratoryArrestHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryRespiratoryArrestMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratoryRespiratoryArrestOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratoryRespiratoryArrestNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratoryRespiratoryArrestUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.activeSubAnswer.treatment.useOfVentilator",
  ],
  [
    "ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryDiagnosesSarcoidosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosis",
  ],
  [
    "ros_respiratorySarcoidosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.active",
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.comment",
  ],
  [
    "ros_respiratorySarcoidosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratorySarcoidosisLung",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.lung",
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerSpecifyComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.comment",
  ],
  [
    "ros_respiratorySarcoidosisLymphNodes",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.lymphNodes",
  ],
  [
    "ros_respiratorySarcoidosisSkin",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.skin",
  ],
  [
    "ros_respiratorySarcoidosisCranialNerves",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.cranialNerves",
  ],
  [
    "ros_respiratorySarcoidosisTestUnKnown",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.unKnown",
  ],
  [
    "ros_respiratorySarcoidosisMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratorySarcoidosisOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratorySarcoidosisSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratorySarcoidosisNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratoryDiagnosesSleepApnea",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApnea",
  ],
  [
    "ros_respiratorySleepApneaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.active",
  ],
  [
    "ros_respiratorySleepApneaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.comment",
  ],
  [
    "ros_respiratorySleepApneaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratorySleepApneaActiveSelectSubAnswerVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.snoring.isSnoring",
  ],
  [
    "ros_respiratorySleepApneaActiveSelectSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.snoring.comment",
  ],
  [
    "ros_respiratorySleepApneaMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_respiratorySleepApneaOtherTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_respiratorySleepApneaSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_respiratorySleepApneaNoTreatment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_respiratorySleepApneaActiveTreatmentSubAnswerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_respiratorySleepApneaCpap",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.activeSubAnswer.treatment.cpap",
  ],
  [
    "ros_respiratoryDiagnosesLungTransplant",
    "respiratory.isRespiratorySubAnswer.diagnoses.lungTransplant",
  ],
  [
    "ros_respiratoryLungTransplantSubOtherTreatmentSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.lungTransplantSubAnswer.dateOfTransplant",
  ],

  [
    "ros_respiratoryDiagnosesOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.other",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerDescribeActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerDescribeHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],

  [
    "ros_respiratoryRespOtherSubAnswerSupportedByBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByPhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryOtherSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedBySymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.wheezing",
  ],

  [
    "ros_respiratoryOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryOtherSubAnswerOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "ros_respiratorySymptomsSob",
    "respiratory.isRespiratorySubAnswer.symptoms.sob",
  ],
  [
    "ros_respiratorySymptomsChronicCough",
    "respiratory.isRespiratorySubAnswer.symptoms.chronicCough",
  ],
  [
    "ros_respiratorySymptomsWheezing",
    "respiratory.isRespiratorySubAnswer.symptoms.wheezing",
  ],
  [
    "ros_respiratorySymptomsOther",
    "respiratory.isRespiratorySubAnswer.symptoms.other",
  ],
  [
    "ros_respiratorySymptomsSubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.symptoms.otherSubAnswer.describe",
  ],
  [
    "ros_respiratorySymptomsNone",
    "respiratory.isRespiratorySubAnswer.symptoms.none",
  ],
  [
    "ros_respiratorySymptomsComment",
    "respiratory.isRespiratorySubAnswer.symptoms.comment",
  ],

  [
    "respiratory_recoTakeMedicationsVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[0].takeMedications",
  ],
  [
    "respiratory_RecommendationstakeMedicationsCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[0].comment",
  ],
  [
    "respiratory_recoCaseManagementVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[1].caseManagement",
  ],
  [
    "respiratory_RecommendationscaseManagementCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[1].comment",
  ],
  [
    "respiratory_recoFollowUpClinicianVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[2].followUpClinician",
  ],
  [
    "respiratory_RecommendationsfollowUpClinicianCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[2].comment",
  ],
  [
    "respiratory_recoDMEVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[3].DME",
  ],
  [
    "respiratory_RecommendationsDMECommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[3].comment",
  ],
  [
    "respiratory_recoOtherVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[4].other",
  ],
  [
    "respiratory_RecommendationsotherCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[4].comment",
  ],

  //integument
  ["ros_integumentVal", "integument.isIntegument"],
  ["ros_integumentComment", "integument.comment"],
  [
    "ros_integumentDiagnosesComment",
    "integument.isIntegumentSubAnswer.diagnoses.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentBasilCellCarcinoma",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinoma",
  ],
  [
    "ros_integumentBasilCellCarcinomaActive",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.active",
  ],
  [
    "ros_integumentBasilCellCarcinomaSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.comment",
  ],
  [
    "ros_integumentBasilCellCarcinomaHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentBasilCellCarcinomaSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentBasilCellCarcinomaMedication",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentBasilCellCarcinomaOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentBasilCellCarcinomaNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentDermatitis",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitis",
  ],
  [
    "ros_integumentDermatitisActive",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.active",
  ],
  [
    "ros_integumentDermatitisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentDermatitisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentDermatitisSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentDermatitisMedication",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentDermatitisOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentDermatitisSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentDermatitisNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentDermatitisActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentDermatitisContact",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.contact",
  ],
  [
    "ros_integumentDermatitisSubAnswerWhatTypeComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.comment",
  ],
  [
    "ros_integumentDermatitisStasis",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.stasis",
  ],
  [
    "ros_integumentDermatitisDrugInduced",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.drugInduced",
  ],
  [
    "ros_integumentDermatitisDiseaseInduced",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.diseaseInduced",
  ],
  [
    "ros_integumentDermatitisUnspecified",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.unspecified",
  ],
  [
    "ros_integumentDiagnosesIntegumentEczema",
    "integument.isIntegumentSubAnswer.diagnoses.eczema",
  ],
  [
    "ros_integumentEczemaActive",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.active",
  ],
  [
    "ros_integumentEczemaSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.comment",
  ],
  [
    "ros_integumentEczemaHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentEczemaSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentEczemaMedication",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentEczemaOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentEczemaSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentEczemaNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentEczemaActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentDiagnosesIntegumentOnychomysosis",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosis",
  ],
  [
    "ros_integumentOnychomycosisActive",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.active",
  ],
  [
    "ros_integumentOnychomycosisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentOnychomycosisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentOnychomycosisSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentOnychomycosisMedication",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentOnychomycosisOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentOnychomycosisSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentOnychomycosisNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentOnychomycosisActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentDiagnosesIntegumentPsoriasis",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasis",
  ],
  [
    "ros_integumentPsoriasisActive",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.active",
  ],
  [
    "ros_integumentPsoriasisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentPsoriasisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentPsoriasisSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentPsoriasisMedication",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentPsoriasisOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentPsoriasisSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentPsoriasisNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentPsoriasisActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.psoriaticArthritis.isPsoriaticArthritis",
  ],
  [
    "ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.activeSubAnswer.psoriaticArthritis.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentSkinUlcer",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcer",
  ],
  [
    "ros_integumentSkinUlcerSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.comment",
  ],
  [
    "ros_integumentSkinUlcerActive",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.active",
  ],
  [
    "ros_integumentSkinUlcerHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentSkinUlcerSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentSkinUlcerMedication",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentSkinUlcerOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentSkinUlcerSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentSkinUlcerNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentSkinUlcerActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentSkinUlcerPressure",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.pressure",
  ],
  [
    "ros_integumentSkinUlcerSubEtiologySubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.pressureSubAnswer.stageAndLocation",
  ],
  [
    "ros_integumentSkinUlcerVenousStatis",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.venousStatis",
  ],
  [
    "ros_integumentSkinUlcerSubAnswerEtiologyComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.comment",
  ],
  [
    "ros_integumentSkinUlcerPeripheralVascular",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.peripheralVascular",
  ],
  [
    "ros_integumentSkinUlcerDiseaseInduced",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diseaseInduced",
  ],
  [
    "ros_integumentSkinUlcerDiabeticVasculitis",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diabeticVasculitis",
  ],
  [
    "ros_integumentSkinUlcerDiabeticNeuropathy",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diabeticNeuropathy",
  ],

  [
    "ros_integumentDiagnosesIntegumentTineaPedis",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedis",
  ],
  [
    "ros_integumentTineaPedisActive",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.active",
  ],
  [
    "ros_integumentTineaPedisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.historyOf",
  ],
  // [
  //   "ros_skinUlcerSubLocation",
  //   "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.activeSubAnswer.location",
  // ],
  [
    "ros_integumentTineaPedisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentTineaPedisMedication",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentTineaPedisOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentTineaPedisSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentTineaPedisNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentTineaPedisActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentTineaPedisRightFoot",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.select.rightFoot",
  ],
  [
    "ros_integumentTineaPedisLeftFoot",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.select.leftFoot",
  ],
  [
    "ros_integumentTineaPedisBilateral",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.select.bilateral",
  ],
  [
    "ros_integumentTineaPedisSelectTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.activeSubAnswer.select.comment",
  ],
  [
    "ros_integumentDiagnosesIntegumentUrticarialDisease",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDisease",
  ],
  [
    "ros_integumentUrticarialDiseaseActive",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_integumentUrticarialDiseaseHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentUrticarialDiseaseMedication",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentUrticarialDiseaseSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentUrticarialDiseaseOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentUrticarialDiseaseNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentUrticarialDiseaseAcute",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.acute",
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerTypeComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.comment",
  ],
  [
    "ros_integumentUrticarialDiseaseChronic",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.chronic",
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerEtiology",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.etiology",
  ],
  [
    "ros_integumentDiagnosesIntegumentWound",
    "integument.isIntegumentSubAnswer.diagnoses.wound",
  ],
  [
    "ros_integumentWoundActive",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.active",
  ],
  [
    "ros_integumentWoundHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentWoundSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.comment",
  ],
  [
    "ros_integumentWoundMedication",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentWoundOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentWoundSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentWoundNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  //validation issue in this field
  [
    "ros_integumentWoundSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentWoundActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentWoundSurgical",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.surgical",
  ],
  [
    "ros_integumentWoundSubAnswerEtiologyComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.comment",
  ],
  [
    "ros_integumentWoundTraumatic",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.traumatic",
  ],
  [
    "ros_integumentWoundBurn",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.burn",
  ],
  [
    "ros_integumentDiagnosesIntegumentOther",
    "integument.isIntegumentSubAnswer.diagnoses.other",
  ],
  [
    "ros_integumentOthersSubAnswerDescribeActive",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_integumentOthersSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_integumentOthersSubAnswerDescribeHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  //validation issue in this field
  [
    "ros_integumentOthersSubLocation",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_integumentOthersOtherTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_integumentOthersMedication",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_integumentOthersSubOtherTreatmentSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_integumentOthersNoTreatment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_integumentOthersActiveTreatmentSubAnswerComment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_integumentOthersSubAnswerOther",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "integument_recoTakeMedicationsAsPescribedVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "integument_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[0].comment",
  ],

  [
    "integument_recoCaseManagementVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[1].caseManagement",
  ],
  [
    "integument_RecommendationscaseManagementCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[1].comment",
  ],
  [
    "integument_recoFollowUpClinicianVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[2].followUpClinician",
  ],
  [
    "integument_RecommendationsfollowUpClinicianCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[2].comment",
  ],
  [
    "integument_recoDMEVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[3].DME",
  ],
  [
    "integument_RecommendationsDMECommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[3].comment",
  ],
  [
    "integument_recoOtherVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[4].other",
  ],
  [
    "integument_RecommendationsotherCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[4].comment",
  ],

  //Musculoskeletal
  ["ros_musculoskeletalVal", "musculoskeletal.isMusculoskeletal"],
  ["ros_musculoskeletalComment", "musculoskeletal.comment"],
  [
    "ros_musculoskeletalDiagnosesComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoDermatomyositis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositis",
  ],
  [
    "ros_musculoskeletalDermatomyositisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalDermatomyositisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalDermatomyositisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalDermatomyositisMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalDermatomyositisOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalDermatomyositisNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.dermatomyositisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoDegenerativeDiscDisease",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDisease",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalSiteOfDiseaseCervical",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.siteOfDisease.cervical",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.siteOfDisease.comment",
  ],
  [
    "ros_musculoskeletalSiteOfDiseaseThoracic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.siteOfDisease.thoracic",
  ],
  [
    "ros_musculoskeletalSiteOfDiseaseLumbar",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.siteOfDisease.lumbar",
  ],
  [
    "ros_musculoskeletalSiteOfDiseaseLumbosacral",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.siteOfDisease.lumbosacral",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.siteOfDisease.other",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.siteOfDisease.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.normalBladder.isNormalBladder",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.normalBladder.comment",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoExtremityFracture",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFracture",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerTraumatic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.type.traumatic",
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerPathological",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.type.pathological",
  ],
  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerTraumatic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOfSubAnswer.type.traumatic",
  ],
  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerPathological",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOfSubAnswer.type.pathological",
  ],

  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.type.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOfSubAnswer.type.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalExtremityFractureOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalExtremityFractureNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityShoulder",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulder",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityArm",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.arm",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityForearm",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearm",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityWrist",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wrist",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityHand",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hand",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityFemoralShaft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaft",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityTibia",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibia",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityFibula",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibula",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityAnkle",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankle",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityFoot",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.foot",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityHip",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hip",
  ],
  [
    "ros_musculoskeletalExtremityFractureShoulderLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureShoulderRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureShoulderSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.comment",
  ],

  [
    "ros_musculoskeletalExtremityFractureArmLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureArmRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureArmSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureForearmLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureForearmRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureForearmSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureWristLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureWristRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureWristSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureHandLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureHandRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.right",
  ],
  [
    "ros_musculoskeletalHandSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureFemoralShaftLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureFemoralShaftRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.right",
  ],
  [
    "ros_musculoskeletalFemoralShaftSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureTibiaLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureTibiaRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureTibiaSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureFibulaLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureFibulaRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureFibulaSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureAnkleLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureAnkleRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureAnkleSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureFootLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureFootRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureFootSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureHipLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.left",
  ],
  [
    "ros_musculoskeletalExtremityFractureHipRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureHipSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.currentWithinTwelveWeeks.isCurrentWithinTwelveWeeks",
  ],
  [
    "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.currentWithinTwelveWeeks.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoGout",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.gout",
  ],
  [
    "ros_musculoskeletalGoutActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalGoutSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalGoutHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalGoutMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalGoutOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalGoutSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalGoutNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalGoutActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoHalluxValgus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgus",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalHalluxValgusMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalHalluxValgusOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalHalluxValgusNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoHammerToes",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToes",
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalHammerToesLeftFoot",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.select.leftFoot",
  ],
  [
    "ros_musculoskeletalHammerToesRightFoot",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.select.rightFoot",
  ],
  [
    "ros_musculoskeletalHammerToesBilateral",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.select.bilateral",
  ],
  [
    "ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.select.comment",
  ],
  [
    "ros_musculoskeletalHammerToesMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalHammerToesOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalHammerToesNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoOsteoarthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritis",
  ],
  [
    "ros_musculoskeletalOsteoarthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalOsteoarthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.whichJoints",
  ],
  [
    "ros_musculoskeletalOsteoarthritisMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalOsteoarthritisOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalOsteoarthritisNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoOsteomyelitis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitis",
  ],
  [
    "ros_musculoskeletalOsteomyelitisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalOsteomyelitisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalOsteomyelitisMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalOsteomyelitisOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalOsteomyelitisNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubLocation",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.activeSubAnswer.location",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoOsteoporosis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosis",
  ],
  [
    "ros_musculoskeletalOsteoporosisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalOsteoporosisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalOsteoporosisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalOsteoporosisMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalOsteoporosisOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalOsteoporosisNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoPyogenicArthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritis",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.whichJoints",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoRheumatoidArthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritis",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.whichJoints",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoScleroderma",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.scleroderma",
  ],
  [
    "ros_musculoskeletalSclerodermaActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalSclerodermaSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalSclerodermaHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalSclerodermaMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalSclerodermaOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalSclerodermaNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.sclerodermaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoSpinalStenosis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosis",
  ],
  [
    "ros_musculoskeletalSpinalStenosisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalSpinalStenosisMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalSpinalStenosisOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalSpinalStenosisNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.normalBladder.isNormalBladder",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.normalBladder.comment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSiteOfDiseaseCervical",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.siteOfDisease.cervical",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.siteOfDisease.comment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSiteOfDiseaseThoracic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.siteOfDisease.thoracic",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSiteOfDiseaseLumbar",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.siteOfDisease.lumbar",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSiteOfDiseaseLumbosacral",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.siteOfDisease.lumbosacral",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.siteOfDisease.other",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.activeSubAnswer.siteOfDisease.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoSystemicLupus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupus",
  ],
  [
    "ros_musculoskeletalSystemicLupusActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalSystemicLupusSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalSystemicLupusHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalSystemicLupusMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalSystemicLupusOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalSystemicLupusNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.treatment.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.other",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalMuskoOtherMedication",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_musculoskeletalMuskoOtherOtherTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalMuskoOtherNoTreatment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerother",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsJointSwelling",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.jointSwelling",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsJointStiffness",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.jointStiffness",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsPain",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.pain",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsLimitedRom",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.limitedRom",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.other",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsNone",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.none",
  ],
  [
    "ros_musculoskeletalMuskoSymptomsComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.symptoms.comment",
  ],

  [
    "ros_musculoskeletalHadAnAmputationSubAnswer",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.isHadAnAmputationSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalMuskoHadAnAmputationVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.isHadAnAmputation",
  ],
  [
    "ros_musculoskeletalMuskoHadAnAmputationComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.comment",
  ],

  [
    "musko_recoDiscussPtOtVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[0].discussPtOt",
  ],
  [
    "musko_RecommendationsdiscussPtOtCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[0].comment",
  ],
  [
    "musko_recoTakeMedicationsAsPescribedVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[1].takeMedicationsAsPescribed",
  ],
  [
    "musko_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[1].comment",
  ],
  [
    "musko_recoCaseManagementVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[2].caseManagement",
  ],
  [
    "musko_RecommendationscaseManagementCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[2].comment",
  ],
  [
    "musko_recoFollowUpClinicianVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[3].followUpClinician",
  ],
  [
    "musko_RecommendationsfollowUpClinicianCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[3].comment",
  ],
  [
    "musko_recoDMEVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[4].DME",
  ],
  [
    "musko_RecommendationsDMECommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[4].comment",
  ],

  [
    "musko_recoOtherVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[5].other",
  ],
  [
    "musko_RecommendationsotherCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[5].comment",
  ],

  //hematology
  [
    "ros_hematologyVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDisease",
  ],
  ["ros_hematologyComment", "hematologyImmunologyInfectiousDisease.comment"],

  [
    "ros_hematologyDiagnosesComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.comment",
  ],
  [
    "ros_hematologyDiagnosesHemaAids",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aids",
  ],
  [
    "ros_hematologyAidsActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.active",
  ],
  [
    "ros_hematologyAidsSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyAidsHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyAidsMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyAidsOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyAidsSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyAidsNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyAidsActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaAnemia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemia",
  ],
  [
    "ros_hematologyAnemiaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.active",
  ],
  [
    "ros_hematologyAnemiaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyAnemiaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyAnemiaIronDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.ironDeficiency",
  ],
  [
    "ros_hematologyAnemiaPernicious",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.pernicious",
  ],
  [
    "ros_hematologyAnemiaSubAnswerEtiologyComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.comment",
  ],
  [
    "ros_hematologyAnemiaKidneyDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.kidneyDisease",
  ],
  [
    "ros_hematologyAnemiaHemolysis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.hemolysis",
  ],
  [
    "ros_hematologyAnemiaAplastic",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.aplastic",
  ],
  [
    "ros_hematologyAnemiaChemotherapy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.chemotherapy",
  ],
  [
    "ros_hematologyAnemiaBloodLoss",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.bloodLoss",
  ],
  [
    "ros_hematologyAnemiaChronicDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.chronicDisease",
  ],
  [
    "ros_hematologyAnemiaFolateDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.folateDeficiency",
  ],
  [
    "ros_hematologyAnemiaSubEtiologySubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.other",
  ],
  [
    "ros_hematologyAnemiaSubEtiologySubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.etiology.otherSubAnswer.describe",
  ],

  [
    "ros_hematologyAnemiaMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyAnemiaOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyAnemiaSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyAnemiaNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyAnemiaTreatmentBloodTransfusion",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.bloodTransfusion",
  ],
  [
    "ros_hematologyAnemiaActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyAnemiaIron",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientOn.iron",
  ],
  [
    "ros_hematologyAnemiaB12",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientOn.b12",
  ],
  [
    "ros_hematologyAnemiaSubYesPatientOnSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientOn.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyAnemiaSubAnswerYesPatientOnComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientOn.comment",
  ],
  [
    "ros_hematologyAnemiaFolicAcid",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientOn.folicAcid",
  ],
  [
    "ros_hematologyAnemiaBloodTransfusion",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientOn.bloodTransfusion",
  ],
  [
    "ros_hematologyAnemiaOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.activeSubAnswer.treatment.medicationSubAnswer.patientOn.other",
  ],

  [
    "ros_hematologyDiagnosesHemaCDifficile",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficile",
  ],
  [
    "ros_hematologyCDifficileActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.active",
  ],
  [
    "ros_hematologyCDifficileSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyCDifficileHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.historyOf",
  ],

  [
    "ros_hematologyCDifficileMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyCDifficileOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyCDifficileSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyCDifficileNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyCDifficileActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaCommunityAcquired",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquired",
  ],
  [
    "ros_hematologyCommunityAcquiredActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.active",
  ],
  [
    "ros_hematologyCommunityAcquiredSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyCommunityAcquiredHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.historyOf",
  ],

  [
    "ros_hematologyCommunityAcquiredMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyCommunityAcquiredOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyCommunityAcquiredNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaHiv",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hiv",
  ],
  [
    "ros_hematologyHivActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.active",
  ],
  [
    "ros_hematologyHivSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyHivHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyHivSubAnswerViralLoad",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.viralLoad",
  ],
  [
    "ros_hematologyHivSubAnswerCFour",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.cFour",
  ],
  [
    "ros_hematologyHivSubAnswerPatientSymptomaticVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.patientSymptomatic.isPatientSymptomatic",
  ],
  [
    "ros_hematologyHivSubAnswerPatientSymptomaticComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.patientSymptomatic.comment",
  ],
  [
    "ros_hematologyHivSubAnswerActiveTreatmentVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.activeTreatment.isActiveTreatment",
  ],
  [
    "ros_hematologyHivSubAnswerActiveTreatmentComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_hematologyHivMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyHivOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyHivSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyHivNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyHivActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaHerpesZoster",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZoster",
  ],
  [
    "ros_hematologyHerpesZosterActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.active",
  ],
  [
    "ros_hematologyHerpesZosterSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyHerpesZosterHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOf",
  ],

  [
    "ros_hematologyHerpesZosterTypeHerpesZoster",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.type.herpesZoster",
  ],
  [
    "ros_hematologyHerpesZosterTypeHerpesSimplex1",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.type.herpesSimplex1",
  ],
  [
    "ros_hematologyHerpesZosterTypeHerpesSimplex2",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.type.herpesSimplex2",
  ],
  [
    "ros_hematologyHerpesZosterTypeOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.type.other",
  ],
  [
    "ros_hematologyHerpesZosterTypeSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.type.comment",
  ],
  [
    "ros_hematologyHerpesZosterActiveSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.type.otherSubAnswer.other",
  ],
  [
    "ros_hematologyHerpesZosterMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyHerpesZosterOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyHerpesZosterSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyHerpesZosterNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyHerpesZosterTypeHistoryOfHerpesZoster",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOfSubAnswer.type.herpesZoster",
  ],
  [
    "ros_hematologyHerpesZosterTypeHistoryOfHerpesSimplex1",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOfSubAnswer.type.herpesSimplex1",
  ],
  [
    "ros_hematologyHerpesZosterTypeHistoryOfHerpesSimplex2",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOfSubAnswer.type.herpesSimplex2",
  ],
  [
    "ros_hematologyHerpesZosterTypeHistoryOfOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOfSubAnswer.type.other",
  ],
  [
    "ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOfSubAnswer.type.comment",
  ],
  [
    "ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOfSubAnswer.type.otherSubAnswer.other",
  ],

  [
    "ros_hematologyDiagnosesHemaMrsaInfection",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfection",
  ],
  [
    "ros_hematologyMrsaInfectionActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.active",
  ],
  [
    "ros_hematologyMrsaInfectionSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyMrsaInfectionHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyMrsaInfectionMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyMrsaInfectionOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyMrsaInfectionNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaImmuneDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiency",
  ],
  [
    "ros_hematologyImmuneDeficiencyActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.active",
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.comment",
  ],

  [
    "ros_hematologyImmuneDeficiencyHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerEtiology",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.etiology",
  ],
  [
    "ros_hematologyImmuneDeficiencyMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyImmuneDeficiencyOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyImmuneDeficiencyNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaSepsis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsis",
  ],
  [
    "ros_hematologySepsisActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.active",
  ],
  [
    "ros_hematologySepsisSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.comment",
  ],
  [
    "ros_hematologySepsisHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologySepsisMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologySepsisOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologySepsisSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologySepsisNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologySepsisActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaSickleCellDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDisease",
  ],
  [
    "ros_hematologySickleCellDiseaseActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_hematologySickleCellDiseaseSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_hematologySickleCellDiseaseHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologySickleCellDiseaseFamilyYes",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.familyHistory.yes",
  ],
  [
    "ros_hematologySickleCellDiseaseFamilyNo",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.familyHistory.no",
  ],
  [
    "ros_hematologySickleCellDiseaseFamilyUnknown",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.familyHistory.unknown",
  ],
  [
    "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.familyHistory.comment",
  ],
  [
    "ros_hematologySickleCellDiseaseMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologySickleCellDiseaseOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologySickleCellDiseaseNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaSickleCellTrait",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTrait",
  ],
  [
    "ros_hematologySickleCellTraitActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.active",
  ],
  [
    "ros_hematologySickleCellTraitSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.comment",
  ],
  [
    "ros_hematologySickleCellTraitHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologySickleCellTraitMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologySickleCellTraitOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologySickleCellTraitSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologySickleCellTraitNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaThalassemia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemia",
  ],
  [
    "ros_hematologyThalassemiaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.active",
  ],
  [
    "ros_hematologyThalassemiaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyThalassemiaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyThalassemiaMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyThalassemiaOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyThalassemiaSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyThalassemiaNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyThalassemiaActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaThrombocytopenia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopenia",
  ],
  [
    "ros_hematologyThrombocytopeniaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.active",
  ],
  [
    "ros_hematologyThrombocytopeniaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyThrombocytopeniaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyThrombocytopeniaSubAnswerEtiology",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.etiology",
  ],
  [
    "ros_hematologyThrombocytopeniaMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyThrombocytopeniaOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyThrombocytopeniaNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaTuberculosis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosis",
  ],
  [
    "ros_hematologyTuberculosisActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.active",
  ],
  [
    "ros_hematologyTuberculosishistoryOfActiveTb",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.historyOfActiveTb",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyTuberculosistbInfection",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.tbInfection",
  ],

  [
    "ros_hematologyTuberculosisGivenBcgYes",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.givenBCG.yes",
  ],
  [
    "ros_hematologyTuberculosisGivenBcgNo",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.givenBCG.no",
  ],
  [
    "ros_hematologyTuberculosisGivenBcgUnknown",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.givenBCG.unknown",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerGivenBCGComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.givenBCG.comment",
  ],

  [
    "ros_hematologyTuberculosisActiveTuberculosisYes",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.activeTuberculosis.yes",
  ],
  [
    "ros_hematologyTuberculosisActiveTuberculosisNo",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.activeTuberculosis.no",
  ],
  [
    "ros_hematologyTuberculosisActiveTuberculosisUnknown",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.activeTuberculosis.unknown",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.activeTuberculosis.comment",
  ],

  [
    "ros_hematologyTuberculosisTbInfectionYes",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.tbInfection.yes",
  ],
  [
    "ros_hematologyTuberculosisTbInfectionNo",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.tbInfection.no",
  ],
  [
    "ros_hematologyTuberculosisTbInfectionUnknown",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.tbInfection.unknown",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerTbInfectionComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.tbInfection.comment",
  ],

  [
    "ros_hematologyTuberculosisMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyTuberculosisOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyTuberculosisSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyTuberculosisNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyTuberculosisActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaVitaminDDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiency",
  ],
  [
    "ros_hematologyVitaminDDeficiencyActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.active",
  ],
  [
    "ros_hematologyVitaminDDeficiencySubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.comment",
  ],
  [
    "ros_hematologyVitaminDDeficiencyHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.historyOf",
  ],

  [
    "ros_hematologyVitaminDDeficiencyMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_hematologyVitaminDDeficiencyOtherTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_hematologyVitaminDDeficiencyNoTreatment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.activeSubAnswer.treatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaBoneMarrowTransplant",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.boneMarrowTransplant",
  ],
  [
    "ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.boneMarrowTransplantSubAnswer.dateOfTransplant",
  ],

  [
    "ros_hematologyHemaDiagnosesOtherSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.other",
  ],
  [
    "ros_hematologyHemaOtherSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyOtherActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_hematologyHemaOtherSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyOtherHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyOtherHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_hematologyOtherSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyOtherPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_hematologyOtherMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyHemaOtherSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyOtherTestResults",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_hematologyOtherImageStudies",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_hematologyOtherBiopsy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_hematologyOtherDme",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_hematologyHemaOtherSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyHemaOtherSubAnswerOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  //recommendation
  [
    "hema_recoTakeMedicationsAsPescribedVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "hema_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[0].comment",
  ],
  [
    "hema_recoReportAbnormalBruisingVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[1].reportAbnormalBruising",
  ],
  [
    "hema_RecommendationsreportAbnormalBruisingCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[1].comment",
  ],
  [
    "hema_recoFollowUpWithDoctorVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[2].followUpWithDoctor",
  ],
  [
    "hema_RecommendationsfollowUpWithDoctorCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[2].comment",
  ],

  [
    "hema_recoCaseManagementVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[3].caseManagement",
  ],
  [
    "hema_RecommendationscaseManagementCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[3].comment",
  ],

  [
    "hema_recoFollowUpClinicianVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[4].followUpClinician",
  ],
  [
    "hema_RecommendationsfollowUpClinicianCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[4].comment",
  ],

  [
    "hema_recoDMEVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[5].DME",
  ],
  [
    "hema_RecommendationsDMECommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[5].comment",
  ],

  [
    "hema_recoOtherVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[6].other",
  ],
  [
    "hema_RecommendationsotherCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[6].comment",
  ],
];

const ros_optionsTreeMapObject_mehak2024 = {
  ros_respiratoryDiagnosesAcuteUpper: "acuteUpper",
  ros_respiratoryAcuteUpperActive: "active",
  ros_respiratoryAcuteUpperHistoryOf: "historyOf",
  ros_respiratoryAcuteUpperMedication: "medication",
  ros_respiratoryAcuteUpperOtherTreatment: "otherTreatment",
  ros_respiratoryAcuteUpperNoTreatment: "noTreatment",

  ros_respiratoryDiagnosesAsthma: "asthma",
  ros_respiratoryAsthmaActive: "active",
  ros_respiratoryAsthmaMedication: "medication",
  ros_respiratoryAsthmaMedicationInhaled: "inhaledOrOralSteroids",
  ros_respiratoryAsthmaMedicationRescueInhaler: "rescueInhaler",
  ros_respiratoryAsthmaOtherTreatment: "otherTreatment",
  ros_respiratoryAsthmaNoTreatment: "noTreatment",
  ros_respiratoryAsthmaHistoryOf: "historyOf",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationWheezing:
    "wheezing",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationShortnessOfBreath:
    "shortnessOfBreath",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfRescue:
    "useOfRescue",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfVentilator:
    "useOfVentilator",

  ros_respiratoryDiagnosesChronicRespiratoryFailure:
    "chronicRespiratoryFailure",
  ros_respiratoryChronicRespiratoryFailureActive: "active",
  ros_respiratoryChronicRespiratoryFailureHistoryOf: "historyOf",
  ros_respiratoryChronicRespiratoryFailureMedication: "medication",
  ros_respiratoryChronicRespiratoryFailureOtherTreatment: "otherTreatment",
  ros_respiratoryChronicRespiratoryFailureNoTreatment: "noTreatment",
  ros_respiratoryChronicRespiratoryFailureChronicUseOfo2: "chronicUseOfo2",
  ros_respiratoryChronicRespiratoryFailureUseOfVentilator: "useOfVentilator",

  ros_respiratoryDiagnosesChronicSputumFailure: "chronicSputumFailure",
  ros_respiratoryChronicSputumFailureActive: "active",
  ros_respiratoryChronicSputumFailureHistoryOf: "historyOf",
  ros_respiratoryChronicSputumFailureMedication: "medication",
  ros_respiratoryChronicSputumFailureOtherTreatment: "otherTreatment",
  ros_respiratoryChronicSputumFailureNoTreatment: "noTreatment",

  ros_respiratoryDiagnosesCopd: "copd",
  ros_respiratoryCopdActive: "active",
  ros_respiratoryCopdHistoryOf: "historyOf",
  ros_respiratoryCopdChronicBronchitis: "chronicBronchitis",
  ros_respiratoryCopdEmphysema: "emphysema",
  ros_respiratoryCopdUnknown: "unknown",
  ros_respiratoryCopdMedication: "medication",
  ros_respiratoryCopdOtherTreatment: "otherTreatment",
  ros_respiratoryCopdNoTreatment: "noTreatment",
  ros_respiratoryCopdO2Use: "o2Use",
  ros_respiratoryCopdInhaled: "inhaledOrOralSteroids",
  ros_respiratoryCopdRescueInhaler: "rescueInhaler",
  ros_respiratoryPatientExacerbationWheezing: "wheezing",
  ros_respiratoryPatientExacerbationShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryPatientExacerbationUseOfRescueMedications:
    "useOfRescueMedications",
  ros_respiratoryPatientExacerbationUseOfVentilator: "useOfVentilator",

  ros_respiratoryDiagnosesCysticFibrosis: "cysticFibrosis",
  ros_respiratoryCysticFibrosisActive: "active",
  ros_respiratoryCysticFibrosisHistoryOf: "historyOf",
  ros_respiratoryCysticFibrosisMedication: "medication",
  ros_respiratoryCysticFibrosisOtherTreatment: "otherTreatment",
  ros_respiratoryCysticFibrosisNoTreatment: "noTreatment",

  ros_respiratoryDiagnosesHypoventilation: "hypoventilation",
  ros_respiratoryHypoventilationActive: "active",
  ros_respiratoryHypoventilationHistoryOf: "historyOf",
  ros_respiratoryHypoventilationMedication: "medication",
  ros_respiratoryHypoventilationOtherTreatment: "otherTreatment",
  ros_respiratoryHypoventilationNoTreatment: "noTreatment",
  ros_respiratoryHypoventilationO2Use: "o2Use",
  ros_respiratoryHypoventilationMorbidObesity: "morbidObesity",

  ros_respiratoryDiagnosesHypoxemia: "hypoxemia",
  ros_respiratoryHypoxemiaActive: "active",
  ros_respiratoryHypoxemiaHistoryOf: "historyOf",
  ros_respiratoryHypoxemiaMedication: "medication",
  ros_respiratoryHypoxemiaOtherTreatment: "otherTreatment",
  ros_respiratoryHypoxemiaNoTreatment: "noTreatment",
  ros_respiratoryHypoxemiaO2Use: "o2Saturation",

  ros_respiratoryDiagnosesPneumonia: "pneumonia",
  ros_respiratoryPneumoniaActive: "active",
  ros_respiratoryPneumoniaHistoryOf: "historyOf",
  ros_respiratoryPneumoniaViral: "viral",
  ros_respiratoryPneumoniaPneumococcal: "pneumococcal",
  ros_respiratoryPneumoniaStaph: "staph",
  ros_respiratoryPneumoniaOtherBacterial: "otherBacterial",
  ros_respiratoryPneumoniaAspiration: "aspiration",
  ros_respiratoryPneumoniaMedication: "medication",
  ros_respiratoryPneumoniaOtherTreatment: "otherTreatment",
  ros_respiratoryPneumoniaNoTreatment: "noTreatment",

  ros_respiratoryDiagnosesPulmonaryFibrosis: "pulmonaryFibrosis",
  ros_respiratoryPulmonaryFibrosisActive: "active",
  ros_respiratoryPulmonaryFibrosisHistoryOf: "historyOf",
  ros_respiratoryPulmonaryFibrosisMedication: "medication",
  ros_respiratoryPulmonaryFibrosisOtherTreatment: "otherTreatment",
  ros_respiratoryPulmonaryFibrosisNoTreatment: "noTreatment",

  ros_respiratoryDiagnosesPulmonaryEmbolism: "pulmonaryEmbolism",
  ros_respiratoryPulmonaryEmbolismActive: "active",
  ros_respiratoryPulmonaryEmbolismHistoryOf: "historyOf",
  ros_respiratoryPulmonaryEmbolismAcute: "acute",
  ros_respiratoryPulmonaryEmbolismChronic: "chronic",
  ros_respiratoryPulmonaryEmbolismMedication: "medication",
  ros_respiratoryPulmonaryEmbolismOtherTreatment: "otherTreatment",
  ros_respiratoryPulmonaryEmbolismNoTreatment: "noTreatment",
  ros_respiratoryPulmonaryEmbolismHistoryOfAcute: "acute",
  ros_respiratoryPulmonaryEmbolismHistoryOfChronic: "chronic",
  ros_respiratoryPulmonaryEmbolismHistoryOfMedication: "medication",
  ros_respiratoryPulmonaryEmbolismHistoryOfOtherTreatment: "otherTreatment",
  ros_respiratoryPulmonaryEmbolismHistoryOfNoTreatment: "noTreatment",

  ros_respiratoryDiagnosesRespiratorDependence: "respiratorDependence",
  ros_respiratoryRespiratorDependenceActive: "active",
  ros_respiratoryRespiratorDependenceHistoryOf: "historyOf",
  ros_respiratoryRespiratorDependenceMedication: "medication",
  ros_respiratoryRespiratorDependenceOtherTreatment: "otherTreatment",
  ros_respiratoryRespiratorDependenceNoTreatment: "noTreatment",
  ros_respiratoryRespiratorDependenceTracheostomyPresent: "tracheostomyPresent",

  ros_respiratoryDiagnosesRespiratoryArrest: "respiratoryArrest",
  ros_respiratoryRespiratoryArrestActive: "active",
  ros_respiratoryRespiratoryArrestHistoryOf: "historyOf",
  ros_respiratoryRespiratoryArrestMedication: "medication",
  ros_respiratoryRespiratoryArrestOtherTreatment: "otherTreatment",
  ros_respiratoryRespiratoryArrestNoTreatment: "noTreatment",
  ros_respiratoryRespiratoryArrestUseOfVentilator: "useOfVentilator",

  ros_respiratoryDiagnosesSarcoidosis: "sarcoidosis",
  ros_respiratorySarcoidosisActive: "active",
  ros_respiratorySarcoidosisHistoryOf: "historyOf",
  ros_respiratorySarcoidosisLung: "lung",
  ros_respiratorySarcoidosisLymphNodes: "lymphNodes",
  ros_respiratorySarcoidosisSkin: "skin",
  ros_respiratorySarcoidosisCranialNerves: "cranialNerves",
  ros_respiratorySarcoidosisTestUnKnown: "unKnown",
  ros_respiratorySarcoidosisMedication: "medication",
  ros_respiratorySarcoidosisOtherTreatment: "otherTreatment",
  ros_respiratorySarcoidosisNoTreatment: "noTreatment",

  ros_respiratoryDiagnosesSleepApnea: "sleepApnea",
  ros_respiratorySleepApneaActive: "active",
  ros_respiratorySleepApneaHistoryOf: "historyOf",
  ros_respiratorySleepApneaMedication: "medication",
  ros_respiratorySleepApneaOtherTreatment: "otherTreatment",
  ros_respiratorySleepApneaNoTreatment: "noTreatment",
  ros_respiratorySleepApneaCpap: "cpap",

  ros_respiratoryDiagnosesLungTransplant: "lungTransplant",

  ros_respiratoryDiagnosesOther: "other",
  ros_respiratoryRespOtherSubAnswerDescribeActive: "active",
  ros_respiratoryRespOtherSubAnswerDescribeHistoryOf: "historyOf",
  ros_respiratoryRespOtherSubAnswerDescribeRuleOut: "ruleOut",
  ros_respiratoryRespOtherSubAnswerSupportedByBiopsy: "biopsy",
  ros_respiratoryRespOtherSubAnswerSupportedByDme: "dme",
  ros_respiratoryRespOtherSubAnswerSupportedByHistory: "history",
  ros_respiratoryRespOtherSubAnswerSupportedByImageStudies: "imageStudies",
  ros_respiratoryRespOtherSubAnswerSupportedByMedications: "medications",
  ros_respiratoryRespOtherSubAnswerSupportedByPhysicalFindings:
    "physicalFindings",
  ros_respiratoryRespOtherSubAnswerSupportedBySymptoms: "symptoms",
  ros_respiratoryRespOtherSubAnswerSupportedByTestResults: "testResults",
  ros_respiratoryRespOtherSubAnswerSupportedByShortnessOfBreath:
    "shortnessOfBreath",
  ros_respiratoryRespOtherSubAnswerSupportedByChronicCough: "chronicCough",
  ros_respiratoryRespOtherSubAnswerSupportedByWheezing: "wheezing",
  ros_respiratoryOtherSubOther: "other",

  ros_respiratorySymptomsSob: "sob",
  ros_respiratorySymptomsChronicCough: "chronicCough",
  ros_respiratorySymptomsWheezing: "wheezing",
  ros_respiratorySymptomsOther: "other",
  ros_respiratorySymptomsNone: "none",

  //integument
  ros_integumentDiagnosesIntegumentBasilCellCarcinoma: "basilCellCarcinoma",
  ros_integumentBasilCellCarcinomaActive: "active",
  ros_integumentBasilCellCarcinomaHistoryOf: "historyOf",
  ros_integumentBasilCellCarcinomaMedication: "medication",
  ros_integumentBasilCellCarcinomaOtherTreatment: "otherTreatment",
  ros_integumentBasilCellCarcinomaNoTreatment: "noTreatment",

  ros_integumentDiagnosesIntegumentDermatitis: "dermatitis",
  ros_integumentDermatitisActive: "active",
  ros_integumentDermatitisHistoryOf: "historyOf",
  ros_integumentDermatitisMedication: "medication",
  ros_integumentDermatitisOtherTreatment: "otherTreatment",
  ros_integumentDermatitisNoTreatment: "noTreatment",
  ros_integumentDermatitisContact: "contact",
  ros_integumentDermatitisStasis: "stasis",
  ros_integumentDermatitisDrugInduced: "drugInduced",
  ros_integumentDermatitisDiseaseInduced: "diseaseInduced",
  ros_integumentDermatitisUnspecified: "unspecified",

  ros_integumentDiagnosesIntegumentEczema: "eczema",
  ros_integumentEczemaActive: "active",
  ros_integumentEczemaHistoryOf: "historyOf",
  ros_integumentEczemaMedication: "medication",
  ros_integumentEczemaOtherTreatment: "otherTreatment",
  ros_integumentEczemaNoTreatment: "noTreatment",

  ros_integumentDiagnosesIntegumentOnychomysosis: "onychomycosis",
  ros_integumentOnychomycosisActive: "active",
  ros_integumentOnychomycosisHistoryOf: "historyOf",
  ros_integumentOnychomycosisMedication: "medication",
  ros_integumentOnychomycosisOtherTreatment: "otherTreatment",
  ros_integumentOnychomycosisNoTreatment: "noTreatment",

  ros_integumentDiagnosesIntegumentPsoriasis: "psoriasis",
  ros_integumentPsoriasisActive: "active",
  ros_integumentPsoriasisHistoryOf: "historyOf",
  ros_integumentPsoriasisMedication: "medication",
  ros_integumentPsoriasisOtherTreatment: "otherTreatment",
  ros_integumentPsoriasisNoTreatment: "noTreatment",

  ros_integumentDiagnosesIntegumentSkinUlcer: "skinUlcer",
  ros_integumentSkinUlcerActive: "active",
  ros_integumentSkinUlcerHistoryOf: "historyOf",
  ros_integumentSkinUlcerMedication: "medication",
  ros_integumentSkinUlcerOtherTreatment: "otherTreatment",
  ros_integumentSkinUlcerNoTreatment: "noTreatment",
  ros_integumentSkinUlcerPressure: "pressure",
  ros_integumentSkinUlcerVenousStatis: "venousStatis",
  ros_integumentSkinUlcerPeripheralVascular: "peripheralVascular",
  ros_integumentSkinUlcerDiseaseInduced: "diseaseInduced",
  ros_integumentSkinUlcerDiabeticVasculitis: "diabeticVasculitis",
  ros_integumentSkinUlcerDiabeticNeuropathy: "diabeticNeuropathy",

  ros_integumentDiagnosesIntegumentTineaPedis: "tineaPedis",
  ros_integumentTineaPedisActive: "active",
  ros_integumentTineaPedisHistoryOf: "historyOf",
  ros_integumentTineaPedisMedication: "medication",
  ros_integumentTineaPedisOtherTreatment: "otherTreatment",
  ros_integumentTineaPedisNoTreatment: "noTreatment",
  ros_integumentTineaPedisRightFoot: "rightFoot",
  ros_integumentTineaPedisLeftFoot: "leftFoot",
  ros_integumentTineaPedisBilateral: "bilateral",

  ros_integumentDiagnosesIntegumentUrticarialDisease: "urticarialDisease",
  ros_integumentUrticarialDiseaseActive: "active",
  ros_integumentUrticarialDiseaseHistoryOf: "historyOf",
  ros_integumentUrticarialDiseaseMedication: "medication",
  ros_integumentUrticarialDiseaseOtherTreatment: "otherTreatment",
  ros_integumentUrticarialDiseaseNoTreatment: "noTreatment",
  ros_integumentUrticarialDiseaseAcute: "acute",
  ros_integumentUrticarialDiseaseChronic: "chronic",

  ros_integumentDiagnosesIntegumentWound: "wound",
  ros_integumentWoundActive: "active",
  ros_integumentWoundHistoryOf: "historyOf",
  ros_integumentWoundMedication: "medication",
  ros_integumentWoundOtherTreatment: "otherTreatment",
  ros_integumentWoundNoTreatment: "noTreatment",
  ros_integumentWoundSurgical: "surgical",
  ros_integumentWoundTraumatic: "traumatic",
  ros_integumentWoundBurn: "burn",

  ros_integumentDiagnosesIntegumentOther: "other",
  ros_integumentOthersSubAnswerDescribeActive: "active",
  ros_integumentOthersSubAnswerDescribeHistoryOf: "historyOf",
  ros_integumentOthersMedication: "medication",
  ros_integumentOthersOtherTreatment: "otherTreatment",
  ros_integumentOthersNoTreatment: "noTreatment",

  //musko
  ros_musculoskeletalDiagnosesMuskoDermatomyositis: "dermatomyositis",
  ros_musculoskeletalDermatomyositisActive: "active",
  ros_musculoskeletalDermatomyositisHistoryOf: "historyOf",
  ros_musculoskeletalDermatomyositisMedication: "medication",
  ros_musculoskeletalDermatomyositisOtherTreatment: "otherTreatment",
  ros_musculoskeletalDermatomyositisNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoDegenerativeDiscDisease:
    "degenerativeDiscDisease",
  ros_musculoskeletalDegenerativeDiscDiseaseActive: "active",
  ros_musculoskeletalDegenerativeDiscDiseaseHistoryOf: "historyOf",
  ros_musculoskeletalSiteOfDiseaseCervical: "cervical",
  ros_musculoskeletalSiteOfDiseaseThoracic: "thoracic",
  ros_musculoskeletalSiteOfDiseaseLumbar: "lumbar",
  ros_musculoskeletalSiteOfDiseaseLumbosacral: "lumbosacral",
  ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther: "other",
  ros_musculoskeletalDegenerativeDiscDiseaseMedication: "medication",
  ros_musculoskeletalDegenerativeDiscDiseaseOtherTreatment: "otherTreatment",
  ros_musculoskeletalDegenerativeDiscDiseaseNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoExtremityFracture: "extremityFracture",
  ros_musculoskeletalExtremityFractureSubAnswerDescribeActive: "active",
  ros_musculoskeletalExtremityFractureSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalExtremityFractureActiveTypeSubAnswerTraumatic: "traumatic",
  ros_musculoskeletalExtremityFractureActiveTypeSubAnswerPathological:
    "pathological",
  ros_musculoskeletalExtremityFractureHistoryOfSubAnswerTraumatic: "traumatic",
  ros_musculoskeletalExtremityFractureHistoryOfSubAnswerPathological:
    "pathological",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityShoulder: "shoulder",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityArm: "arm",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityForearm: "forearm",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityWrist: "wrist",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityHand: "hand",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityFemoralShaft:
    "femoralShaft",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityTibia: "tibia",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityFibula: "fibula",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityAnkle: "ankle",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityFoot: "foot",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityHip: "hip",
  ros_musculoskeletalExtremityFractureShoulderLeft: "left",
  ros_musculoskeletalExtremityFractureShoulderRight: "right",
  ros_musculoskeletalExtremityFractureArmLeft: "left",
  ros_musculoskeletalExtremityFractureArmRight: "right",
  ros_musculoskeletalExtremityFractureForearmLeft: "left",
  ros_musculoskeletalExtremityFractureForearmRight: "right",
  ros_musculoskeletalExtremityFractureWristLeft: "left",
  ros_musculoskeletalExtremityFractureWristRight: "right",
  ros_musculoskeletalExtremityFractureHandLeft: "left",
  ros_musculoskeletalExtremityFractureHandRight: "right",
  ros_musculoskeletalExtremityFractureFemoralShaftLeft: "left",
  ros_musculoskeletalExtremityFractureFemoralShaftRight: "right",
  ros_musculoskeletalExtremityFractureTibiaLeft: "left",
  ros_musculoskeletalExtremityFractureTibiaRight: "right",
  ros_musculoskeletalExtremityFractureFibulaLeft: "left",
  ros_musculoskeletalExtremityFractureFibulaRight: "right",
  ros_musculoskeletalExtremityFractureAnkleLeft: "left",
  ros_musculoskeletalExtremityFractureAnkleRight: "right",
  ros_musculoskeletalExtremityFractureFootLeft: "left",
  ros_musculoskeletalExtremityFractureFootRight: "right",
  ros_musculoskeletalExtremityFractureHipLeft: "left",
  ros_musculoskeletalExtremityFractureHipRight: "right",
  ros_musculoskeletalExtremityFractureMedication: "medication",
  ros_musculoskeletalExtremityFractureOtherTreatment: "otherTreatment",
  ros_musculoskeletalExtremityFractureNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoGout: "gout",
  ros_musculoskeletalGoutActive: "active",
  ros_musculoskeletalGoutHistoryOf: "historyOf",
  ros_musculoskeletalGoutMedication: "medication",
  ros_musculoskeletalGoutOtherTreatment: "otherTreatment",
  ros_musculoskeletalGoutNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoHalluxValgus: "halluxValgus",
  ros_musculoskeletalHalluxValgusSubAnswerDescribeActive: "active",
  ros_musculoskeletalHalluxValgusSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalHalluxValgusMedication: "medication",
  ros_musculoskeletalHalluxValgusOtherTreatment: "otherTreatment",
  ros_musculoskeletalHalluxValgusNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoHammerToes: "hammerToes",
  ros_musculoskeletalHammerToesSubAnswerDescribeActive: "active",
  ros_musculoskeletalHammerToesSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalHammerToesMedication: "medication",
  ros_musculoskeletalHammerToesOtherTreatment: "otherTreatment",
  ros_musculoskeletalHammerToesNoTreatment: "noTreatment",
  ros_musculoskeletalHammerToesLeftFoot: "leftFoot",
  ros_musculoskeletalHammerToesRightFoot: "rightFoot",
  ros_musculoskeletalHammerToesBilateral: "bilateral",

  ros_musculoskeletalDiagnosesMuskoOsteoarthritis: "osteoarthritis",
  ros_musculoskeletalOsteoarthritisActive: "active",
  ros_musculoskeletalOsteoarthritisHistoryOf: "historyOf",
  ros_musculoskeletalOsteoarthritisMedication: "medication",
  ros_musculoskeletalOsteoarthritisOtherTreatment: "otherTreatment",
  ros_musculoskeletalOsteoarthritisNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoOsteomyelitis: "osteomyelitis",
  ros_musculoskeletalOsteomyelitisActive: "active",
  ros_musculoskeletalOsteomyelitisHistoryOf: "historyOf",
  ros_musculoskeletalOsteomyelitisMedication: "medication",
  ros_musculoskeletalOsteomyelitisOtherTreatment: "otherTreatment",
  ros_musculoskeletalOsteomyelitisNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoOsteoporosis: "osteoporosis",
  ros_musculoskeletalOsteoporosisActive: "active",
  ros_musculoskeletalOsteoporosisHistoryOf: "historyOf",
  ros_musculoskeletalOsteoporosisMedication: "medication",
  ros_musculoskeletalOsteoporosisOtherTreatment: "otherTreatment",
  ros_musculoskeletalOsteoporosisNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoPyogenicArthritis: "pyogenicArthritis",
  ros_musculoskeletalPyogenicArthritisActive: "active",
  ros_musculoskeletalPyogenicArthritisHistoryOf: "historyOf",
  ros_musculoskeletalPyogenicArthritisMedication: "medication",
  ros_musculoskeletalPyogenicArthritisOtherTreatment: "otherTreatment",
  ros_musculoskeletalPyogenicArthritisNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoRheumatoidArthritis: "rheumatoidArthritis",
  ros_musculoskeletalRheumatoidArthritisActive: "active",
  ros_musculoskeletalRheumatoidArthritisHistoryOf: "historyOf",
  ros_musculoskeletalRheumatoidArthritisMedication: "medication",
  ros_musculoskeletalRheumatoidArthritisOtherTreatment: "otherTreatment",
  ros_musculoskeletalRheumatoidArthritisNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoScleroderma: "scleroderma",
  ros_musculoskeletalSclerodermaActive: "active",
  ros_musculoskeletalSclerodermaHistoryOf: "historyOf",
  ros_musculoskeletalSclerodermaMedication: "medication",
  ros_musculoskeletalSclerodermaOtherTreatment: "otherTreatment",
  ros_musculoskeletalSclerodermaNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoSpinalStenosis: "spinalStenosis",
  ros_musculoskeletalSpinalStenosisActive: "active",
  ros_musculoskeletalSpinalStenosisHistoryOf: "historyOf",
  ros_musculoskeletalSpinalStenosisMedication: "medication",
  ros_musculoskeletalSpinalStenosisOtherTreatment: "otherTreatment",
  ros_musculoskeletalSpinalStenosisNoTreatment: "noTreatment",
  ros_musculoskeletalSpinalStenosisSiteOfDiseaseCervical: "cervical",
  ros_musculoskeletalSpinalStenosisSiteOfDiseaseThoracic: "thoracic",
  ros_musculoskeletalSpinalStenosisSiteOfDiseaseLumbar: "lumbar",
  ros_musculoskeletalSpinalStenosisSiteOfDiseaseLumbosacral: "lumbosacral",
  ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoSystemicLupus: "systemicLupus",
  ros_musculoskeletalSystemicLupusActive: "active",
  ros_musculoskeletalSystemicLupusHistoryOf: "historyOf",
  ros_musculoskeletalSystemicLupusMedication: "medication",
  ros_musculoskeletalSystemicLupusOtherTreatment: "otherTreatment",
  ros_musculoskeletalSystemicLupusNoTreatment: "noTreatment",

  ros_musculoskeletalDiagnosesMuskoOther: "other",
  ros_musculoskeletalMuskoOtherSubAnswerDescribeActive: "active",
  ros_musculoskeletalMuskoOtherSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalMuskoOtherMedication: "medication",
  ros_musculoskeletalMuskoOtherOtherTreatment: "otherTreatment",
  ros_musculoskeletalMuskoOtherNoTreatment: "noTreatment",

  ros_musculoskeletalMuskoSymptomsJointSwelling: "jointSwelling",
  ros_musculoskeletalMuskoSymptomsJointStiffness: "jointStiffness",
  ros_musculoskeletalMuskoSymptomsPain: "pain",
  ros_musculoskeletalMuskoSymptomsLimitedRom: "limitedRom",
  ros_musculoskeletalMuskoSymptomsOther: "other",
  ros_musculoskeletalMuskoSymptomsNone: "none",

  //hematology
  ros_hematologyDiagnosesHemaAids: "aids",
  ros_hematologyAidsActive: "active",
  ros_hematologyAidsHistoryOf: "historyOf",
  ros_hematologyAidsMedication: "medication",
  ros_hematologyAidsOtherTreatment: "otherTreatment",
  ros_hematologyAidsNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaAnemia: "anemia",
  ros_hematologyAnemiaActive: "active",
  ros_hematologyAnemiaHistoryOf: "historyOf",
  ros_hematologyAnemiaIronDeficiency: "ironDeficiency",
  ros_hematologyAnemiaPernicious: "pernicious",
  ros_hematologyAnemiaKidneyDisease: "kidneyDisease",
  ros_hematologyAnemiaHemolysis: "hemolysis",
  ros_hematologyAnemiaAplastic: "aplastic",
  ros_hematologyAnemiaChemotherapy: "chemotherapy",
  ros_hematologyAnemiaBloodLoss: "bloodLoss",
  ros_hematologyAnemiaChronicDisease: "chronicDisease",
  ros_hematologyAnemiaFolateDeficiency: "folateDeficiency",
  ros_hematologyAnemiaSubEtiologySubOther: "other",
  ros_hematologyAnemiaMedication: "medication",
  ros_hematologyAnemiaOtherTreatment: "otherTreatment",
  ros_hematologyAnemiaNoTreatment: "noTreatment",
  ros_hematologyAnemiaTreatmentBloodTransfusion: "bloodTransfusion",
  ros_hematologyAnemiaIron: "iron",
  ros_hematologyAnemiaB12: "bTwelve",
  ros_hematologyAnemiaFolicAcid: "folicAcid",
  ros_hematologyAnemiaBloodTransfusion: "bloodTransfusion",
  ros_hematologyAnemiaOther: "other",

  ros_hematologyDiagnosesHemaCDifficile: "cDifficile",
  ros_hematologyCDifficileActive: "active",
  ros_hematologyCDifficileHistoryOf: "historyOf",
  ros_hematologyCDifficileMedication: "medication",
  ros_hematologyCDifficileOtherTreatment: "otherTreatment",
  ros_hematologyCDifficileNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaCommunityAcquired: "communityAcquired",
  ros_hematologyCommunityAcquiredActive: "active",
  ros_hematologyCommunityAcquiredHistoryOf: "historyOf",
  ros_hematologyCommunityAcquiredMedication: "medication",
  ros_hematologyCommunityAcquiredOtherTreatment: "otherTreatment",
  ros_hematologyCommunityAcquiredNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaHiv: "hiv",
  ros_hematologyHivActive: "active",
  ros_hematologyHivHistoryOf: "historyOf",
  ros_hematologyHivMedication: "medication",
  ros_hematologyHivOtherTreatment: "otherTreatment",
  ros_hematologyHivNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaHerpesZoster: "herpesZoster",
  ros_hematologyHerpesZosterActive: "active",
  ros_hematologyHerpesZosterHistoryOf: "historyOf",
  ros_hematologyHerpesZosterTypeHerpesZoster: "herpesZoster",
  ros_hematologyHerpesZosterTypeHerpesSimplex1: "herpesSimplex1",
  ros_hematologyHerpesZosterTypeHerpesSimplex2: "herpesSimplex2",
  ros_hematologyHerpesZosterTypeOther: "other",
  ros_hematologyHerpesZosterMedication: "medication",
  ros_hematologyHerpesZosterOtherTreatment: "otherTreatment",
  ros_hematologyHerpesZosterNoTreatment: "noTreatment",
  ros_hematologyHerpesZosterTypeHistoryOfHerpesZoster: "herpesZoster",
  ros_hematologyHerpesZosterTypeHistoryOfHerpesSimplex1: "herpesSimplex1",
  ros_hematologyHerpesZosterTypeHistoryOfHerpesSimplex2: "herpesSimplex2",
  ros_hematologyHerpesZosterTypeHistoryOfOther: "other",

  ros_hematologyDiagnosesHemaMrsaInfection: "mrsaInfection",
  ros_hematologyMrsaInfectionActive: "active",
  ros_hematologyMrsaInfectionHistoryOf: "historyOf",
  ros_hematologyMrsaInfectionMedication: "medication",
  ros_hematologyMrsaInfectionOtherTreatment: "otherTreatment",
  ros_hematologyMrsaInfectionNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaImmuneDeficiency: "immuneDeficiency",
  ros_hematologyImmuneDeficiencyActive: "active",
  ros_hematologyImmuneDeficiencyHistoryOf: "historyOf",
  ros_hematologyImmuneDeficiencyMedication: "medication",
  ros_hematologyImmuneDeficiencyOtherTreatment: "otherTreatment",
  ros_hematologyImmuneDeficiencyNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaSepsis: "sepsis",
  ros_hematologySepsisActive: "active",
  ros_hematologySepsisHistoryOf: "historyOf",
  ros_hematologySepsisMedication: "medication",
  ros_hematologySepsisOtherTreatment: "otherTreatment",
  ros_hematologySepsisNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaSickleCellDisease: "sickleCellDisease",
  ros_hematologySickleCellDiseaseActive: "active",
  ros_hematologySickleCellDiseaseHistoryOf: "historyOf",
  ros_hematologySickleCellDiseaseFamilyYes: "yes",
  ros_hematologySickleCellDiseaseFamilyNo: "no",
  ros_hematologySickleCellDiseaseFamilyUnknown: "unknown",
  ros_hematologySickleCellDiseaseMedication: "medication",
  ros_hematologySickleCellDiseaseOtherTreatment: "otherTreatment",
  ros_hematologySickleCellDiseaseNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaSickleCellTrait: "sickleCellTrait",
  ros_hematologySickleCellTraitActive: "active",
  ros_hematologySickleCellTraitHistoryOf: "historyOf",
  ros_hematologySickleCellTraitMedication: "medication",
  ros_hematologySickleCellTraitOtherTreatment: "otherTreatment",
  ros_hematologySickleCellTraitNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaThalassemia: "thalassemia",
  ros_hematologyThalassemiaActive: "active",
  ros_hematologyThalassemiaHistoryOf: "historyOf",
  ros_hematologyThalassemiaMedication: "medication",
  ros_hematologyThalassemiaOtherTreatment: "otherTreatment",
  ros_hematologyThalassemiaNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaThrombocytopenia: "thrombocytopenia",
  ros_hematologyThrombocytopeniaActive: "active",
  ros_hematologyThrombocytopeniaHistoryOf: "historyOf",
  ros_hematologyThrombocytopeniaMedication: "medication",
  ros_hematologyThrombocytopeniaOtherTreatment: "otherTreatment",
  ros_hematologyThrombocytopeniaNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaTuberculosis: "tuberculosis",
  ros_hematologyTuberculosisActive: "active",
  ros_hematologyTuberculosishistoryOfActiveTb: "historyOfActiveTb",
  ros_hematologyTuberculosistbInfection: "tbInfection",
  ros_hematologyTuberculosisGivenBcgYes: "yes",
  ros_hematologyTuberculosisGivenBcgNo: "no",
  ros_hematologyTuberculosisGivenBcgUnknown: "unknown",
  ros_hematologyTuberculosisActiveTuberculosisYes: "yes",
  ros_hematologyTuberculosisActiveTuberculosisNo: "no",
  ros_hematologyTuberculosisActiveTuberculosisUnknown: "unknown",
  ros_hematologyTuberculosisTbInfectionYes: "yes",
  ros_hematologyTuberculosisTbInfectionNo: "no",
  ros_hematologyTuberculosisTbInfectionUnknown: "unknown",
  ros_hematologyTuberculosisMedication: "medication",
  ros_hematologyTuberculosisOtherTreatment: "otherTreatment",
  ros_hematologyTuberculosisNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaVitaminDDeficiency: "vitaminDDeficiency",
  ros_hematologyVitaminDDeficiencyActive: "active",
  ros_hematologyVitaminDDeficiencyHistoryOf: "historyOf",
  ros_hematologyVitaminDDeficiencyMedication: "medication",
  ros_hematologyVitaminDDeficiencyOtherTreatment: "otherTreatment",
  ros_hematologyVitaminDDeficiencyNoTreatment: "noTreatment",

  ros_hematologyDiagnosesHemaBoneMarrowTransplant: "boneMarrowTransplant",

  ros_hematologyHemaOtherSubOther: "other",
  ros_hematologyHemaDiagnosesOtherSubOther: "other",
  ros_hematologyOtherActive: "active",
  ros_hematologyOtherHistoryOf: "historyOf",
  ros_hematologyOtherHistory: "history",
  ros_hematologyOtherSymptoms: "symptoms",
  ros_hematologyOtherPhysicalFindings: "physicalFindings",
  ros_hematologyOtherMedications: "medications",
  ros_hematologyOtherTestResults: "testResults",
  ros_hematologyOtherImageStudies: "imageStudies",
  ros_hematologyOtherBiopsy: "biopsy",
  ros_hematologyOtherDme: "dme",
};

const ros_OptionsTreeWrappers_mehak2024 = [
  ["ros_respiratory", ["ros_respiratoryYes", "ros_respiratoryNo"]],

  [
    "ros_respiratoryDiagnoses",
    [
      "ros_respiratoryDiagnosesAcuteUpper",
      "ros_respiratoryDiagnosesAsthma",
      "ros_respiratoryDiagnosesChronicRespiratoryFailure",
      "ros_respiratoryDiagnosesChronicSputumFailure",
      "ros_respiratoryDiagnosesCopd",
      "ros_respiratoryDiagnosesCysticFibrosis",
      "ros_respiratoryDiagnosesHypoventilation",
      "ros_respiratoryDiagnosesHypoxemia",
      "ros_respiratoryDiagnosesPneumonia",
      "ros_respiratoryDiagnosesPulmonaryFibrosis",
      "ros_respiratoryDiagnosesPulmonaryEmbolism",
      "ros_respiratoryDiagnosesRespiratorDependence",
      "ros_respiratoryDiagnosesRespiratoryArrest",
      "ros_respiratoryDiagnosesSarcoidosis",
      "ros_respiratoryDiagnosesSleepApnea",
      "ros_respiratoryDiagnosesLungTransplant",
      "ros_respiratoryDiagnosesOther",
    ],
  ],

  [
    "ros_respiratoryAcuteUpperSubAnswerDescribe",
    ["ros_respiratoryAcuteUpperActive", "ros_respiratoryAcuteUpperHistoryOf"],
  ],
  [
    "ros_respiratoryAcuteUpperActiveTreatmentSubAnswer",
    [
      "ros_respiratoryAcuteUpperMedication",
      "ros_respiratoryAcuteUpperOtherTreatment",
      "ros_respiratoryAcuteUpperNoTreatment",
    ],
  ],
  [
    "ros_respiratoryAsthmaSubAnswerDescribe",
    ["ros_respiratoryAsthmaActive", "ros_respiratoryAsthmaHistoryOf"],
  ],
  [
    "ros_respiratoryAsthmaActiveTreatmentSubAnswer",
    [
      "ros_respiratoryAsthmaMedication",
      "ros_respiratoryAsthmaOtherTreatment",
      "ros_respiratoryAsthmaNoTreatment",
    ],
  ],
  [
    "ros_respiratoryAsthmaMedicationSubAnswer",
    [
      "ros_respiratoryAsthmaMedicationInhaled",
      "ros_respiratoryAsthmaMedicationRescueInhaler",
    ],
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbation",
    [
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationWheezing",
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationShortnessOfBreath",
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfRescue",
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfVentilator",
    ],
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribe",
    [
      "ros_respiratoryChronicRespiratoryFailureActive",
      "ros_respiratoryChronicRespiratoryFailureHistoryOf",
    ],
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswer",
    [
      "ros_respiratoryChronicRespiratoryFailureMedication",
      "ros_respiratoryChronicRespiratoryFailureOtherTreatment",
      "ros_respiratoryChronicRespiratoryFailureNoTreatment",
      "ros_respiratoryChronicRespiratoryFailureChronicUseOfo2",
      "ros_respiratoryChronicRespiratoryFailureUseOfVentilator",
    ],
  ],
  [
    "ros_respiratoryChronicSputumFailureSubAnswerDescribe",
    [
      "ros_respiratoryChronicSputumFailureActive",
      "ros_respiratoryChronicSputumFailureHistoryOf",
    ],
  ],
  [
    "ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswer",
    [
      "ros_respiratoryChronicSputumFailureMedication",
      "ros_respiratoryChronicSputumFailureOtherTreatment",
      "ros_respiratoryChronicSputumFailureNoTreatment",
    ],
  ],
  [
    "ros_respiratoryCopdSubAnswerDescribe",
    ["ros_respiratoryCopdActive", "ros_respiratoryCopdHistoryOf"],
  ],
  [
    "ros_respiratoryCopdTypeSubAnswer",
    [
      "ros_respiratoryCopdChronicBronchitis",
      "ros_respiratoryCopdEmphysema",
      "ros_respiratoryCopdUnknown",
    ],
  ],
  [
    "ros_respiratoryCopdMedicationSubAnswer",
    ["ros_respiratoryCopdInhaled", "ros_respiratoryCopdRescueInhaler"],
  ],
  [
    "ros_respiratoryCopdSubPatientExacerbation",
    [
      "ros_respiratoryPatientExacerbationWheezing",
      "ros_respiratoryPatientExacerbationShortnessOfBreath",
      "ros_respiratoryPatientExacerbationUseOfRescueMedications",
      "ros_respiratoryPatientExacerbationUseOfVentilator",
    ],
  ],
  [
    "ros_respiratoryCopdActiveTreatmentSubAnswer",
    [
      "ros_respiratoryCopdMedication",
      "ros_respiratoryCopdOtherTreatment",
      "ros_respiratoryCopdO2Use",
      "ros_respiratoryCopdNoTreatment",
    ],
  ],
  [
    "ros_respiratoryCysticFibrosisSubAnswerDescribe",
    [
      "ros_respiratoryCysticFibrosisActive",
      "ros_respiratoryCysticFibrosisHistoryOf",
    ],
  ],
  [
    "ros_respiratoryCysticFibrosisActiveTreatmentSubAnswer",
    [
      "ros_respiratoryCysticFibrosisMedication",
      "ros_respiratoryCysticFibrosisOtherTreatment",
      "ros_respiratoryCysticFibrosisNoTreatment",
    ],
  ],
  [
    "ros_respiratoryHypoventilationSubAnswerDescribe",
    [
      "ros_respiratoryHypoventilationActive",
      "ros_respiratoryHypoventilationHistoryOf",
    ],
  ],
  [
    "ros_respiratoryHypoventilationActiveTreatmentSubAnswer",
    [
      "ros_respiratoryHypoventilationMedication",
      "ros_respiratoryHypoventilationOtherTreatment",
      "ros_respiratoryHypoventilationNoTreatment",
    ],
  ],
  [
    "ros_respiratoryHypoventilationActiveSelectSubAnswer",
    [
      "ros_respiratoryHypoventilationO2Use",
      "ros_respiratoryHypoventilationMorbidObesity",
    ],
  ],
  [
    "ros_respiratoryHypoxemiaSubAnswerDescribe",
    ["ros_respiratoryHypoxemiaActive", "ros_respiratoryHypoxemiaHistoryOf"],
  ],
  [
    "ros_respiratoryHypoxemiaActiveTreatmentSubAnswer",
    [
      "ros_respiratoryHypoxemiaMedication",
      "ros_respiratoryHypoxemiaOtherTreatment",
      "ros_respiratoryHypoxemiaNoTreatment",
    ],
  ],
  [
    "ros_respiratoryHypoxemiaActiveSelectSubAnswer",
    ["ros_respiratoryHypoxemiaO2Use"],
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerDescribe",
    ["ros_respiratoryPneumoniaActive", "ros_respiratoryPneumoniaHistoryOf"],
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerEtiology",
    [
      "ros_respiratoryPneumoniaViral",
      "ros_respiratoryPneumoniaPneumococcal",
      "ros_respiratoryPneumoniaStaph",
      "ros_respiratoryPneumoniaOtherBacterial",
      "ros_respiratoryPneumoniaAspiration",
    ],
  ],
  [
    "ros_respiratoryPneumoniaActiveTreatmentSubAnswer",
    [
      "ros_respiratoryPneumoniaMedication",
      "ros_respiratoryPneumoniaOtherTreatment",
      "ros_respiratoryPneumoniaNoTreatment",
    ],
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubAnswerDescribe",
    [
      "ros_respiratoryPulmonaryFibrosisActive",
      "ros_respiratoryPulmonaryFibrosisHistoryOf",
    ],
  ],
  [
    "ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswer",
    [
      "ros_respiratoryPulmonaryFibrosisMedication",
      "ros_respiratoryPulmonaryFibrosisOtherTreatment",
      "ros_respiratoryPulmonaryFibrosisNoTreatment",
    ],
  ],
  [
    "ros_respiratoryPulmonaryEmbolismSubAnswerDescribe",
    [
      "ros_respiratoryPulmonaryEmbolismActive",
      "ros_respiratoryPulmonaryEmbolismHistoryOf",
    ],
  ],
  [
    "ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswer",
    [
      "ros_respiratoryPulmonaryEmbolismAcute",
      "ros_respiratoryPulmonaryEmbolismChronic",
    ],
  ],
  [
    "ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswer",
    [
      "ros_respiratoryPulmonaryEmbolismMedication",
      "ros_respiratoryPulmonaryEmbolismOtherTreatment",
      "ros_respiratoryPulmonaryEmbolismNoTreatment",
    ],
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswer",
    [
      "ros_respiratoryPulmonaryEmbolismHistoryOfAcute",
      "ros_respiratoryPulmonaryEmbolismHistoryOfChronic",
    ],
  ],
  [
    "ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswer",
    [
      "ros_respiratoryPulmonaryEmbolismHistoryOfMedication",
      "ros_respiratoryPulmonaryEmbolismHistoryOfOtherTreatment",
      "ros_respiratoryPulmonaryEmbolismHistoryOfNoTreatment",
    ],
  ],
  [
    "ros_respiratoryRespiratorDependenceSubAnswerDescribe",
    [
      "ros_respiratoryRespiratorDependenceActive",
      "ros_respiratoryRespiratorDependenceHistoryOf",
    ],
  ],
  [
    "ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswer",
    [
      "ros_respiratoryRespiratorDependenceMedication",
      "ros_respiratoryRespiratorDependenceOtherTreatment",
      "ros_respiratoryRespiratorDependenceNoTreatment",
      "ros_respiratoryRespiratorDependenceTracheostomyPresent",
    ],
  ],
  [
    "ros_respiratoryRespiratoryArrestSubAnswerDescribe",
    [
      "ros_respiratoryRespiratoryArrestActive",
      "ros_respiratoryRespiratoryArrestHistoryOf",
    ],
  ],
  [
    "ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswer",
    [
      "ros_respiratoryRespiratoryArrestMedication",
      "ros_respiratoryRespiratoryArrestOtherTreatment",
      "ros_respiratoryRespiratoryArrestNoTreatment",
      "ros_respiratoryRespiratoryArrestUseOfVentilator",
    ],
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerDescribe",
    ["ros_respiratorySarcoidosisActive", "ros_respiratorySarcoidosisHistoryOf"],
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerSpecify",
    [
      "ros_respiratorySarcoidosisLung",
      "ros_respiratorySarcoidosisLymphNodes",
      "ros_respiratorySarcoidosisSkin",
      "ros_respiratorySarcoidosisCranialNerves",
      "ros_respiratorySarcoidosisTestUnKnown",
    ],
  ],
  [
    "ros_respiratorySarcoidosisActiveTreatmentSubAnswer",
    [
      "ros_respiratorySarcoidosisMedication",
      "ros_respiratorySarcoidosisOtherTreatment",
      "ros_respiratorySarcoidosisNoTreatment",
    ],
  ],
  [
    "ros_respiratorySleepApneaSubAnswerDescribe",
    ["ros_respiratorySleepApneaActive", "ros_respiratorySleepApneaHistoryOf"],
  ],
  [
    "ros_respiratorySleepApneaActiveTreatmentSubAnswer",
    [
      "ros_respiratorySleepApneaMedication",
      "ros_respiratorySleepApneaOtherTreatment",
      "ros_respiratorySleepApneaNoTreatment",
      "ros_respiratorySleepApneaCpap",
    ],
  ],
  [
    "ros_respiratoryRespOtherSubAnswerDescribe",
    [
      "ros_respiratoryRespOtherSubAnswerDescribeActive",
      "ros_respiratoryRespOtherSubAnswerDescribeHistoryOf",
      "ros_respiratoryRespOtherSubAnswerDescribeRuleOut",
    ],
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedBy",
    [
      "ros_respiratoryRespOtherSubAnswerSupportedByHistory",
      "ros_respiratoryRespOtherSubAnswerSupportedBySymptoms",
      "ros_respiratoryRespOtherSubAnswerSupportedByPhysicalFindings",
      "ros_respiratoryRespOtherSubAnswerSupportedByMedications",
      "ros_respiratoryRespOtherSubAnswerSupportedByTestResults",
      "ros_respiratoryRespOtherSubAnswerSupportedByImageStudies",
      "ros_respiratoryRespOtherSubAnswerSupportedByBiopsy",
      "ros_respiratoryRespOtherSubAnswerSupportedByDme",
      "ros_respiratoryRespOtherSubAnswerSupportedByShortnessOfBreath",
      "ros_respiratoryRespOtherSubAnswerSupportedByChronicCough",
      "ros_respiratoryRespOtherSubAnswerSupportedByWheezing",
      "ros_respiratoryOtherSubOther",
    ],
  ],
  [
    "ros_respiratorySymptoms",
    [
      "ros_respiratorySymptomsSob",
      "ros_respiratorySymptomsChronicCough",
      "ros_respiratorySymptomsWheezing",
      "ros_respiratorySymptomsOther",
      "ros_respiratorySymptomsNone",
    ],
  ],
  //integument
  ["ros_integument", ["ros_integumentYes", "ros_integumentNo"]],

  [
    "ros_integumentDiagnoses",
    [
      "ros_integumentDiagnosesIntegumentBasilCellCarcinoma",
      "ros_integumentDiagnosesIntegumentDermatitis",
      "ros_integumentDiagnosesIntegumentEczema",
      "ros_integumentDiagnosesIntegumentOnychomysosis",
      "ros_integumentDiagnosesIntegumentPsoriasis",
      "ros_integumentDiagnosesIntegumentSkinUlcer",
      "ros_integumentDiagnosesIntegumentTineaPedis",
      "ros_integumentDiagnosesIntegumentUrticarialDisease",
      "ros_integumentDiagnosesIntegumentWound",
      "ros_integumentDiagnosesIntegumentOther",
    ],
  ],
  [
    "ros_integumentBasilCellCarcinomaSubAnswerDescribe",
    [
      "ros_integumentBasilCellCarcinomaActive",
      "ros_integumentBasilCellCarcinomaHistoryOf",
    ],
  ],
  [
    "ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswer",
    [
      "ros_integumentBasilCellCarcinomaMedication",
      "ros_integumentBasilCellCarcinomaOtherTreatment",
      "ros_integumentBasilCellCarcinomaNoTreatment",
    ],
  ],
  [
    "ros_integumentDermatitisSubAnswerDescribe",
    ["ros_integumentDermatitisActive", "ros_integumentDermatitisHistoryOf"],
  ],
  [
    "ros_integumentDermatitisActiveTreatmentSubAnswer",
    [
      "ros_integumentDermatitisMedication",
      "ros_integumentDermatitisOtherTreatment",
      "ros_integumentDermatitisNoTreatment",
    ],
  ],
  [
    "ros_integumentDermatitisSubAnswerWhatType",
    [
      "ros_integumentDermatitisContact",
      "ros_integumentDermatitisStasis",
      "ros_integumentDermatitisDrugInduced",
      "ros_integumentDermatitisDiseaseInduced",
      "ros_integumentDermatitisUnspecified",
    ],
  ],
  [
    "ros_integumentEczemaSubAnswerDescribe",
    ["ros_integumentEczemaActive", "ros_integumentEczemaHistoryOf"],
  ],
  [
    "ros_integumentEczemaActiveTreatmentSubAnswer",
    [
      "ros_integumentEczemaMedication",
      "ros_integumentEczemaOtherTreatment",
      "ros_integumentEczemaNoTreatment",
    ],
  ],
  [
    "ros_integumentOnychomycosisSubAnswerDescribe",
    [
      "ros_integumentOnychomycosisActive",
      "ros_integumentOnychomycosisHistoryOf",
    ],
  ],
  [
    "ros_integumentOnychomycosisActiveTreatmentSubAnswer",
    [
      "ros_integumentOnychomycosisMedication",
      "ros_integumentOnychomycosisOtherTreatment",
      "ros_integumentOnychomycosisNoTreatment",
    ],
  ],
  [
    "ros_integumentPsoriasisSubAnswerDescribe",
    ["ros_integumentPsoriasisActive", "ros_integumentPsoriasisHistoryOf"],
  ],
  [
    "ros_integumentPsoriasisActiveTreatmentSubAnswer",
    [
      "ros_integumentPsoriasisMedication",
      "ros_integumentPsoriasisOtherTreatment",
      "ros_integumentPsoriasisNoTreatment",
    ],
  ],
  [
    "ros_integumentSkinUlcerSubAnswerDescribe",
    ["ros_integumentSkinUlcerActive", "ros_integumentSkinUlcerHistoryOf"],
  ],
  [
    "ros_integumentSkinUlcerActiveTreatmentSubAnswer",
    [
      "ros_integumentSkinUlcerMedication",
      "ros_integumentSkinUlcerOtherTreatment",
      "ros_integumentSkinUlcerNoTreatment",
    ],
  ],
  [
    "ros_integumentSkinUlcerSubAnswerEtiology",
    [
      "ros_integumentSkinUlcerPressure",
      "ros_integumentSkinUlcerVenousStatis",
      "ros_integumentSkinUlcerPeripheralVascular",
      "ros_integumentSkinUlcerDiseaseInduced",
      "ros_integumentSkinUlcerDiabeticVasculitis",
      "ros_integumentSkinUlcerDiabeticNeuropathy",
    ],
  ],
  [
    "ros_integumentTineaPedisSubAnswerDescribe",
    ["ros_integumentTineaPedisActive", "ros_integumentTineaPedisHistoryOf"],
  ],
  [
    "ros_integumentTineaPedisActiveTreatmentSubAnswer",
    [
      "ros_integumentTineaPedisMedication",
      "ros_integumentTineaPedisOtherTreatment",
      "ros_integumentTineaPedisNoTreatment",
    ],
  ],
  [
    "ros_integumentTineaPedisSelectTreatmentSubAnswer",
    [
      "ros_integumentTineaPedisRightFoot",
      "ros_integumentTineaPedisLeftFoot",
      "ros_integumentTineaPedisBilateral",
    ],
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerDescribe",
    [
      "ros_integumentUrticarialDiseaseActive",
      "ros_integumentUrticarialDiseaseHistoryOf",
    ],
  ],
  [
    "ros_integumentUrticarialDiseaseActiveTreatmentSubAnswer",
    [
      "ros_integumentUrticarialDiseaseMedication",
      "ros_integumentUrticarialDiseaseOtherTreatment",
      "ros_integumentUrticarialDiseaseNoTreatment",
    ],
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerType",
    [
      "ros_integumentUrticarialDiseaseAcute",
      "ros_integumentUrticarialDiseaseChronic",
    ],
  ],
  [
    "ros_integumentWoundSubAnswerDescribe",
    ["ros_integumentWoundActive", "ros_integumentWoundHistoryOf"],
  ],
  [
    "ros_integumentWoundActiveTreatmentSubAnswer",
    [
      "ros_integumentWoundMedication",
      "ros_integumentWoundOtherTreatment",
      "ros_integumentWoundNoTreatment",
    ],
  ],
  [
    "ros_integumentWoundSubAnswerEtiology",
    [
      "ros_integumentWoundSurgical",
      "ros_integumentWoundTraumatic",
      "ros_integumentWoundBurn",
    ],
  ],
  [
    "ros_integumentOthersSubAnswerDescribe",
    [
      "ros_integumentOthersSubAnswerDescribeActive",
      "ros_integumentOthersSubAnswerDescribeHistoryOf",
    ],
  ],
  [
    "ros_integumentOthersActiveTreatmentSubAnswer",
    [
      "ros_integumentOthersMedication",
      "ros_integumentOthersOtherTreatment",
      "ros_integumentOthersNoTreatment",
    ],
  ],
  //Musculoskeletal
  [
    "ros_musculoskeletalDiagnoses",
    [
      "ros_musculoskeletalDiagnosesMuskoDermatomyositis",
      "ros_musculoskeletalDiagnosesMuskoDegenerativeDiscDisease",
      "ros_musculoskeletalDiagnosesMuskoExtremityFracture",
      "ros_musculoskeletalDiagnosesMuskoGout",
      "ros_musculoskeletalDiagnosesMuskoHalluxValgus",
      "ros_musculoskeletalDiagnosesMuskoHammerToes",
      "ros_musculoskeletalDiagnosesMuskoOsteoarthritis",
      "ros_musculoskeletalDiagnosesMuskoOsteomyelitis",
      "ros_musculoskeletalDiagnosesMuskoOsteoporosis",
      "ros_musculoskeletalDiagnosesMuskoPyogenicArthritis",
      "ros_musculoskeletalDiagnosesMuskoRheumatoidArthritis",
      "ros_musculoskeletalDiagnosesMuskoScleroderma",
      "ros_musculoskeletalDiagnosesMuskoSpinalStenosis",
      "ros_musculoskeletalDiagnosesMuskoSystemicLupus",
      "ros_musculoskeletalDiagnosesMuskoOther",
    ],
  ],
  [
    "ros_musculoskeletalDermatomyositisSubAnswerDescribe",
    [
      "ros_musculoskeletalDermatomyositisActive",
      "ros_musculoskeletalDermatomyositisHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalDermatomyositisMedication",
      "ros_musculoskeletalDermatomyositisOtherTreatment",
      "ros_musculoskeletalDermatomyositisNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe",
    [
      "ros_musculoskeletalDegenerativeDiscDiseaseActive",
      "ros_musculoskeletalDegenerativeDiscDiseaseHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease",
    [
      "ros_musculoskeletalSiteOfDiseaseCervical",
      "ros_musculoskeletalSiteOfDiseaseThoracic",
      "ros_musculoskeletalSiteOfDiseaseLumbar",
      "ros_musculoskeletalSiteOfDiseaseLumbosacral",
      "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther",
    ],
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalDegenerativeDiscDiseaseMedication",
      "ros_musculoskeletalDegenerativeDiscDiseaseOtherTreatment",
      "ros_musculoskeletalDegenerativeDiscDiseaseNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribe",
    [
      "ros_musculoskeletalExtremityFractureSubAnswerDescribeActive",
      "ros_musculoskeletalExtremityFractureSubAnswerDescribeHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerTraumatic",
      "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerPathological",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerTraumatic",
      "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerPathological",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureMedication",
      "ros_musculoskeletalExtremityFractureNoTreatment",
      "ros_musculoskeletalExtremityFractureOtherTreatment",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer",
    [
      "ros_musculoskeletalCurrentWithinTwelveWeeksYes",
      "ros_musculoskeletalCurrentWithinTwelveWeeksNo",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremity",
    [
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityShoulder",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityArm",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityForearm",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityWrist",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityHand",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityFemoralShaft",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityTibia",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityFibula",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityAnkle",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityFoot",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityHip",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureShoulderSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureShoulderLeft",
      "ros_musculoskeletalExtremityFractureShoulderRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureArmSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureArmLeft",
      "ros_musculoskeletalExtremityFractureArmRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureForearmSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureForearmLeft",
      "ros_musculoskeletalExtremityFractureForearmRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureWristSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureWristLeft",
      "ros_musculoskeletalExtremityFractureWristRight",
    ],
  ],
  [
    "ros_musculoskeletalHandSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureHandLeft",
      "ros_musculoskeletalExtremityFractureHandRight",
    ],
  ],
  [
    "ros_musculoskeletalFemoralShaftSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureFemoralShaftLeft",
      "ros_musculoskeletalExtremityFractureFemoralShaftRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureTibiaSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureTibiaLeft",
      "ros_musculoskeletalExtremityFractureTibiaRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureFibulaSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureFibulaLeft",
      "ros_musculoskeletalExtremityFractureFibulaRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureAnkleSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureAnkleLeft",
      "ros_musculoskeletalExtremityFractureAnkleRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureFootSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureFootLeft",
      "ros_musculoskeletalExtremityFractureFootRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureHipSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureHipLeft",
      "ros_musculoskeletalExtremityFractureHipRight",
    ],
  ],
  [
    "ros_musculoskeletalGoutSubAnswerDescribe",
    ["ros_musculoskeletalGoutActive", "ros_musculoskeletalGoutHistoryOf"],
  ],
  [
    "ros_musculoskeletalGoutActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalGoutMedication",
      "ros_musculoskeletalGoutOtherTreatment",
      "ros_musculoskeletalGoutNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribe",
    [
      "ros_musculoskeletalHalluxValgusSubAnswerDescribeActive",
      "ros_musculoskeletalHalluxValgusSubAnswerDescribeHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalHalluxValgusTreatmentSubAnswer",
    [
      "ros_musculoskeletalHalluxValgusMedication",
      "ros_musculoskeletalHalluxValgusOtherTreatment",
      "ros_musculoskeletalHalluxValgusNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerDescribe",
    [
      "ros_musculoskeletalHammerToesSubAnswerDescribeActive",
      "ros_musculoskeletalHammerToesSubAnswerDescribeHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalHammerToesActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalHammerToesMedication",
      "ros_musculoskeletalHammerToesOtherTreatment",
      "ros_musculoskeletalHammerToesNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswer",
    [
      "ros_musculoskeletalHammerToesLeftFoot",
      "ros_musculoskeletalHammerToesRightFoot",
      "ros_musculoskeletalHammerToesBilateral",
    ],
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerDescribe",
    [
      "ros_musculoskeletalOsteoarthritisActive",
      "ros_musculoskeletalOsteoarthritisHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalOsteoarthritisMedication",
      "ros_musculoskeletalOsteoarthritisOtherTreatment",
      "ros_musculoskeletalOsteoarthritisNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubAnswerDescribe",
    [
      "ros_musculoskeletalOsteomyelitisActive",
      "ros_musculoskeletalOsteomyelitisHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalOsteomyelitisMedication",
      "ros_musculoskeletalOsteomyelitisOtherTreatment",
      "ros_musculoskeletalOsteomyelitisNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalOsteoporosisSubAnswerDescribe",
    [
      "ros_musculoskeletalOsteoporosisActive",
      "ros_musculoskeletalOsteoporosisHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalOsteoporosisMedication",
      "ros_musculoskeletalOsteoporosisOtherTreatment",
      "ros_musculoskeletalOsteoporosisNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerDescribe",
    [
      "ros_musculoskeletalPyogenicArthritisActive",
      "ros_musculoskeletalPyogenicArthritisHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalPyogenicArthritisMedication",
      "ros_musculoskeletalPyogenicArthritisOtherTreatment",
      "ros_musculoskeletalPyogenicArthritisNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribe",
    [
      "ros_musculoskeletalRheumatoidArthritisActive",
      "ros_musculoskeletalRheumatoidArthritisHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalRheumatoidArthritisMedication",
      "ros_musculoskeletalRheumatoidArthritisOtherTreatment",
      "ros_musculoskeletalRheumatoidArthritisNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalSclerodermaSubAnswerDescribe",
    [
      "ros_musculoskeletalSclerodermaActive",
      "ros_musculoskeletalSclerodermaHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalSclerodermaActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalSclerodermaMedication",
      "ros_musculoskeletalSclerodermaOtherTreatment",
      "ros_musculoskeletalSclerodermaNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerDescribe",
    [
      "ros_musculoskeletalSpinalStenosisActive",
      "ros_musculoskeletalSpinalStenosisHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalSpinalStenosisMedication",
      "ros_musculoskeletalSpinalStenosisOtherTreatment",
      "ros_musculoskeletalSpinalStenosisNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDisease",
    [
      "ros_musculoskeletalSpinalStenosisSiteOfDiseaseCervical",
      "ros_musculoskeletalSpinalStenosisSiteOfDiseaseThoracic",
      "ros_musculoskeletalSpinalStenosisSiteOfDiseaseLumbar",
      "ros_musculoskeletalSpinalStenosisSiteOfDiseaseLumbosacral",
      "ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOther",
    ],
  ],
  [
    "ros_musculoskeletalSystemicLupusSubAnswerDescribe",
    [
      "ros_musculoskeletalSystemicLupusActive",
      "ros_musculoskeletalSystemicLupusHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalSystemicLupusMedication",
      "ros_musculoskeletalSystemicLupusOtherTreatment",
      "ros_musculoskeletalSystemicLupusNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribe",
    [
      "ros_musculoskeletalMuskoOtherSubAnswerDescribeActive",
      "ros_musculoskeletalMuskoOtherSubAnswerDescribeHistoryOf",
    ],
  ],
  [
    "ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswer",
    [
      "ros_musculoskeletalMuskoOtherMedication",
      "ros_musculoskeletalMuskoOtherOtherTreatment",
      "ros_musculoskeletalMuskoOtherNoTreatment",
    ],
  ],
  [
    "ros_musculoskeletalMuskoSymptoms",
    [
      "ros_musculoskeletalMuskoSymptomsJointSwelling",
      "ros_musculoskeletalMuskoSymptomsJointStiffness",
      "ros_musculoskeletalMuskoSymptomsPain",
      "ros_musculoskeletalMuskoSymptomsLimitedRom",
      "ros_musculoskeletalMuskoSymptomsOther",
      "ros_musculoskeletalMuskoSymptomsNone",
    ],
  ],

  //hematology
  [
    "ros_hematologyDiagnoses",
    [
      "ros_hematologyDiagnosesHemaAids",
      "ros_hematologyDiagnosesHemaAnemia",
      "ros_hematologyDiagnosesHemaCDifficile",
      "ros_hematologyDiagnosesHemaCommunityAcquired",
      "ros_hematologyDiagnosesHemaHiv",
      "ros_hematologyDiagnosesHemaHerpesZoster",
      "ros_hematologyDiagnosesHemaMrsaInfection",
      "ros_hematologyDiagnosesHemaImmuneDeficiency",
      "ros_hematologyDiagnosesHemaSepsis",
      "ros_hematologyDiagnosesHemaSickleCellDisease",
      "ros_hematologyDiagnosesHemaSickleCellTrait",
      "ros_hematologyDiagnosesHemaThalassemia",
      "ros_hematologyDiagnosesHemaThrombocytopenia",
      "ros_hematologyDiagnosesHemaTuberculosis",
      "ros_hematologyDiagnosesHemaVitaminDDeficiency",
      "ros_hematologyDiagnosesHemaBoneMarrowTransplant",
      "ros_hematologyHemaDiagnosesOtherSubOther",
    ],
  ],
  [
    "ros_hematologyAidsSubAnswerDescribe",
    ["ros_hematologyAidsActive", "ros_hematologyAidsHistoryOf"],
  ],
  [
    "ros_hematologyAidsActiveTreatmentSubAnswer",
    [
      "ros_hematologyAidsMedication",
      "ros_hematologyAidsOtherTreatment",
      "ros_hematologyAidsNoTreatment",
    ],
  ],
  [
    "ros_hematologyAnemiaSubAnswerDescribe",
    ["ros_hematologyAnemiaActive", "ros_hematologyAnemiaHistoryOf"],
  ],
  [
    "ros_hematologyAnemiaSubAnswerEtiology",
    [
      "ros_hematologyAnemiaIronDeficiency",
      "ros_hematologyAnemiaPernicious",
      "ros_hematologyAnemiaKidneyDisease",
      "ros_hematologyAnemiaHemolysis",
      "ros_hematologyAnemiaAplastic",
      "ros_hematologyAnemiaChemotherapy",
      "ros_hematologyAnemiaBloodLoss",
      "ros_hematologyAnemiaChronicDisease",
      "ros_hematologyAnemiaFolateDeficiency",
      "ros_hematologyAnemiaSubEtiologySubOther",
    ],
  ],
  [
    "ros_hematologyAnemiaActiveTreatmentSubAnswer",
    [
      "ros_hematologyAnemiaMedication",
      "ros_hematologyAnemiaOtherTreatment",
      "ros_hematologyAnemiaNoTreatment",
      "ros_hematologyAnemiaTreatmentBloodTransfusion",
    ],
  ],
  [
    "ros_hematologyAnemiaSubAnswerYesPatientOn",
    [
      "ros_hematologyAnemiaIron",
      "ros_hematologyAnemiaB12",
      "ros_hematologyAnemiaFolicAcid",
      "ros_hematologyAnemiaBloodTransfusion",
      "ros_hematologyAnemiaOther",
    ],
  ],
  [
    "ros_hematologyCDifficileSubAnswerDescribe",
    ["ros_hematologyCDifficileActive", "ros_hematologyCDifficileHistoryOf"],
  ],
  [
    "ros_hematologyCDifficileActiveTreatmentSubAnswer",
    [
      "ros_hematologyCDifficileMedication",
      "ros_hematologyCDifficileOtherTreatment",
      "ros_hematologyCDifficileNoTreatment",
    ],
  ],
  [
    "ros_hematologyCommunityAcquiredSubAnswerDescribe",
    [
      "ros_hematologyCommunityAcquiredActive",
      "ros_hematologyCommunityAcquiredHistoryOf",
    ],
  ],
  [
    "ros_hematologyCommunityAcquiredActiveTreatmentSubAnswer",
    [
      "ros_hematologyCommunityAcquiredMedication",
      "ros_hematologyCommunityAcquiredOtherTreatment",
      "ros_hematologyCommunityAcquiredNoTreatment",
    ],
  ],
  [
    "ros_hematologyHivSubAnswerDescribe",
    ["ros_hematologyHivActive", "ros_hematologyHivHistoryOf"],
  ],
  [
    "ros_hematologyHivActiveTreatmentSubAnswer",
    [
      "ros_hematologyHivMedication",
      "ros_hematologyHivOtherTreatment",
      "ros_hematologyHivNoTreatment",
    ],
  ],
  [
    "ros_hematologyHerpesZosterSubAnswerDescribe",
    ["ros_hematologyHerpesZosterActive", "ros_hematologyHerpesZosterHistoryOf"],
  ],
  [
    "ros_hematologyHerpesZosterTypeSubAnswer",
    [
      "ros_hematologyHerpesZosterTypeHerpesZoster",
      "ros_hematologyHerpesZosterTypeHerpesSimplex1",
      "ros_hematologyHerpesZosterTypeHerpesSimplex2",
      "ros_hematologyHerpesZosterTypeOther",
    ],
  ],
  [
    "ros_hematologyHerpesZosterActiveTreatmentSubAnswer",
    [
      "ros_hematologyHerpesZosterMedication",
      "ros_hematologyHerpesZosterOtherTreatment",
      "ros_hematologyHerpesZosterNoTreatment",
    ],
  ],
  [
    "ros_hematologyHerpesZosterHistoryOfTypeSubAnswer",
    [
      "ros_hematologyHerpesZosterTypeHistoryOfHerpesZoster",
      "ros_hematologyHerpesZosterTypeHistoryOfHerpesSimplex1",
      "ros_hematologyHerpesZosterTypeHistoryOfHerpesSimplex2",
      "ros_hematologyHerpesZosterTypeHistoryOfOther",
    ],
  ],
  [
    "ros_hematologyMrsaInfectionSubAnswerDescribe",
    [
      "ros_hematologyMrsaInfectionActive",
      "ros_hematologyMrsaInfectionHistoryOf",
    ],
  ],
  [
    "ros_hematologyMrsaInfectionActiveTreatmentSubAnswer",
    [
      "ros_hematologyMrsaInfectionMedication",
      "ros_hematologyMrsaInfectionOtherTreatment",
      "ros_hematologyMrsaInfectionNoTreatment",
    ],
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerDescribe",
    [
      "ros_hematologyImmuneDeficiencyActive",
      "ros_hematologyImmuneDeficiencyHistoryOf",
    ],
  ],
  [
    "ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswer",
    [
      "ros_hematologyImmuneDeficiencyMedication",
      "ros_hematologyImmuneDeficiencyOtherTreatment",
      "ros_hematologyImmuneDeficiencyNoTreatment",
    ],
  ],
  [
    "ros_hematologySepsisSubAnswerDescribe",
    ["ros_hematologySepsisActive", "ros_hematologySepsisHistoryOf"],
  ],
  [
    "ros_hematologySepsisActiveTreatmentSubAnswer",
    [
      "ros_hematologySepsisMedication",
      "ros_hematologySepsisOtherTreatment",
      "ros_hematologySepsisNoTreatment",
    ],
  ],
  [
    "ros_hematologySickleCellDiseaseSubAnswerDescribe",
    [
      "ros_hematologySickleCellDiseaseActive",
      "ros_hematologySickleCellDiseaseHistoryOf",
    ],
  ],
  [
    "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer",
    [
      "ros_hematologySickleCellDiseaseFamilyYes",
      "ros_hematologySickleCellDiseaseFamilyNo",
      "ros_hematologySickleCellDiseaseFamilyUnknown",
    ],
  ],
  [
    "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer",
    [
      "ros_hematologySickleCellDiseaseMedication",
      "ros_hematologySickleCellDiseaseOtherTreatment",
      "ros_hematologySickleCellDiseaseNoTreatment",
    ],
  ],
  [
    "ros_hematologySickleCellTraitSubAnswerDescribe",
    [
      "ros_hematologySickleCellTraitActive",
      "ros_hematologySickleCellTraitHistoryOf",
    ],
  ],
  [
    "ros_hematologySickleCellTraitActiveTreatmentSubAnswer",
    [
      "ros_hematologySickleCellTraitMedication",
      "ros_hematologySickleCellTraitOtherTreatment",
      "ros_hematologySickleCellTraitNoTreatment",
    ],
  ],
  [
    "ros_hematologyThalassemiaSubAnswerDescribe",
    ["ros_hematologyThalassemiaActive", "ros_hematologyThalassemiaHistoryOf"],
  ],
  [
    "ros_hematologyThalassemiaActiveTreatmentSubAnswer",
    [
      "ros_hematologyThalassemiaMedication",
      "ros_hematologyThalassemiaOtherTreatment",
      "ros_hematologyThalassemiaNoTreatment",
    ],
  ],
  [
    "ros_hematologyThrombocytopeniaSubAnswerDescribe",
    [
      "ros_hematologyThrombocytopeniaActive",
      "ros_hematologyThrombocytopeniaHistoryOf",
    ],
  ],
  [
    "ros_hematologyThrombocytopeniaActiveTreatmentSubAnswer",
    [
      "ros_hematologyThrombocytopeniaMedication",
      "ros_hematologyThrombocytopeniaOtherTreatment",
      "ros_hematologyThrombocytopeniaNoTreatment",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerDescribe",
    [
      "ros_hematologyTuberculosisActive",
      "ros_hematologyTuberculosishistoryOfActiveTb",
      "ros_hematologyTuberculosistbInfection",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerGivenBCG",
    [
      "ros_hematologyTuberculosisGivenBcgYes",
      "ros_hematologyTuberculosisGivenBcgNo",
      "ros_hematologyTuberculosisGivenBcgUnknown",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerActiveTuberculosis",
    [
      "ros_hematologyTuberculosisActiveTuberculosisYes",
      "ros_hematologyTuberculosisActiveTuberculosisNo",
      "ros_hematologyTuberculosisActiveTuberculosisUnknown",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerTbInfection",
    [
      "ros_hematologyTuberculosisTbInfectionYes",
      "ros_hematologyTuberculosisTbInfectionNo",
      "ros_hematologyTuberculosisTbInfectionUnknown",
    ],
  ],
  [
    "ros_hematologyTuberculosisActiveTreatmentSubAnswer",
    [
      "ros_hematologyTuberculosisMedication",
      "ros_hematologyTuberculosisOtherTreatment",
      "ros_hematologyTuberculosisNoTreatment",
    ],
  ],
  [
    "ros_hematologyVitaminDDeficiencySubAnswerDescribe",
    [
      "ros_hematologyVitaminDDeficiencyActive",
      "ros_hematologyVitaminDDeficiencyHistoryOf",
    ],
  ],
  [
    "ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswer",
    [
      "ros_hematologyVitaminDDeficiencyMedication",
      "ros_hematologyVitaminDDeficiencyOtherTreatment",
      "ros_hematologyVitaminDDeficiencyNoTreatment",
    ],
  ],
  [
    "ros_hematologyHemaOtherSubAnswerDescribe",
    ["ros_hematologyOtherActive", "ros_hematologyOtherHistoryOf"],
  ],
  [
    "ros_hematologyHemaOtherSubAnswerSupportedBy",
    [
      "ros_hematologyOtherHistory",
      "ros_hematologyOtherSymptoms",
      "ros_hematologyOtherPhysicalFindings",
      "ros_hematologyOtherMedications",
      "ros_hematologyOtherTestResults",
      "ros_hematologyOtherImageStudies",
      "ros_hematologyOtherBiopsy",
      "ros_hematologyOtherDme",
      "ros_hematologyHemaOtherSubOther",
    ],
  ],
];

const ros_hashmap_srilatha2024 = [
  ["ros_cardioVal", "cardiovascular.isCardiovascular"],
  ["ros_cardioComment", "cardiovascular.comment"],
  [
    "ros_cardioSubDiagnoses",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses",
  ],

  // [
  //   "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe",
  //   "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe",
  // ],

  [
    "ros_cardioSubDiagnosesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.comment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.isAbnormalCardiacRhythm",
  ],
  [
    "ros_cardioSubDiagnosesaneurysmVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.isAneurysm",
  ],
  [
    "ros_cardioSubDiagnosesanginaVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.isAngina",
  ],
  [
    "ros_cardioSubDiagnosesatrialFlutterVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.isAtrialFlutter",
  ],
  [
    "ros_cardioSubDiagnosesatrialFibrillationVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillation",
  ],
  [
    "ros_cardioSubDiagnosescardioRespiratoryShockVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShock",
  ],
  [
    "ros_cardioSubDiagnosescardiomyopathyVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathy",
  ],
  [
    "ros_cardioSubDiagnosescongestiveHeartFailureVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailure",
  ],
  [
    "ros_cardioSubDiagnosesdeepVeinThrombosisVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosis",
  ],
  [
    "ros_cardioSubDiagnoseshyperlipidemiaVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemia",
  ],
  [
    "ros_cardioSubDiagnoseshypertensionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertension",
  ],
  [
    "ros_cardioSubDiagnosesischemicHeartDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDisease",
  ],
  [
    "ros_cardioSubDiagnosesmyocardialInfarctionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarction",
  ],
  [
    "ros_cardioSubDiagnosesperipheralVascularDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDisease",
  ],
  [
    "ros_cardioSubDiagnosespulmonaryHypertensionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertension",
  ],
  [
    "ros_cardioSubDiagnosesvalvularDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDisease",
  ],
  [
    "ros_cardioSubDiagnosesHeartTransplantVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.isHeartTransplant",
  ],
  [
    "ros_cardioSubDiagnosesotherVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.other",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.active",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerMedication",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswernoTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.activeSubAnswers.treatment.implantedDefibrillator",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerspacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.activeSubAnswers.treatment.pacemaker",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.comment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeOne.historyOf",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.isBradicardia",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardiaSubSick",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.bradicardiaSubAnswers.sickSinusSyndrome",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardiaSubUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.bradicardiaSubAnswers.unknown",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.bradicardiaSubAnswers.comment",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.isTachycardia",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardiaSubSupraventricular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.tachycardiaSubAnswers.supraventricular",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardiaSubUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.tachycardiaSubAnswers.unknown",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.tachycardiaSubAnswers.comment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describeTwo.comment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describeOne.active",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describeOne.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describeOne.comment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDesAbdominal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describeTwo.abdominal",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDesThoracic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describeTwo.thoracic",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDesPeripheral",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describeTwo.peripheral",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describeTwo.comment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTyperuptured",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.type.ruptured",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTypeUnruptured",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.type.unRuptured",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTypeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.type.comment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTreatMedication",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.treatment.medication",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTreatOtherTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTreatNoTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmTreatmentComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentmed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentno",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],

  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesStable",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.describe.stable",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesUnstable",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.describe.unStable",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.activeSubAnswers.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.historyOf",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatmed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatno",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatImplant",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.treatment.implantedDefibrillator",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatPace",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.treatment.pacemaker",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientAntico",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.isPatientTaking.anticoagulant",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientRate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.isPatientTaking.rateControllingMedication",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.isPatientTaking.isOther",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.isPatientTaking.other.describe",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.activeSubAnswers.isPatientTaking.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFlutter.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypePar",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.type.paroxysmal",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.type.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeChro",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.type.chronic",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeUnk",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.type.unknown",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientAnti",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.isPatientTaking.anticoagulant",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.isPatientTaking.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientRate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.isPatientTaking.rateControllingMedication",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.isPatientTaking.isOther",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.isPatientTaking.other.describe",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatNoTreat",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTr",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatPace",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.treatment.pacemaker",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatImp",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.activeSubAnswers.treatment.implantedDefibrillator",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCardioresTreatOtherTrSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatNoOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatPace",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.activeSubAnswers.treatment.pacemaker",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatImplant",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.activeSubAnswers.treatment.implantedDefibrillator",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.cardiacArrest.isCardiacArrest",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.cardiacArrest.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeDilated",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.type.dilated",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.type.hypertrophic",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.type.restrictive",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.type.unknown",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.type.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatisMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatNotreat",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherTr",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCardiompTreatOtherTrSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.secondaryHypertension.isSecondaryHypertension",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.activeSubAnswers.secondaryHypertension.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesDia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.describe.diastolic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesSys",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.describe.systolic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesUnkn",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.describe.unknown",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.peripheralEdema.isPeripheralEdema",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.peripheralEdema.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.cardiomegaly.isCardiomegaly",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.cardiomegaly.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreNoTre",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.acuteOnChronic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.acute",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.chronic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.secondaryToHypertension.isSecondaryToHypertension",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.secondaryToHypertension.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedication",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiProphyl",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.anticoagulation.prophylactic",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiThera",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.anticoagulation.therapeutic",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.anticoagulation.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocrightLeg",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.location.rightLeg",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocleftLeg",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.location.leftLeg",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocBilateralUpp",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.location.bilateralUpper",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocBitlaterLow",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.location.bilateralLower",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocRightArm",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.location.rightArm",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocleftArm",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.location.leftArm",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.medicationSubAnswer.location.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatOtherTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatOtherTreatmentSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatNoTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreNoTre",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreDiet",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.activeSubAnswers.treatment.dietControlled",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.patientOnStatin.isPatientOnStatin",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.patientOnStatin.comment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesActiveSubTreMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesActiveSubTreNoTre",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesActiveSubTreOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesActiveSubTreDiet",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.activeSubAnswers.treatment.dietControlled",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSympHeadache",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.symptoms.headaches",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSymplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.symptoms.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSympBlurredvison",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.symptoms.blurredVision",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSympother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.symptoms.otherSubAnswers.isOther",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSympotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.symptoms.otherSubAnswers.other",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSympNone",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.symptoms.none",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSympComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.symptoms.comment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionadequatelyControlled",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.adequatelyControlled.isAdequatelyControlled",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionadequatelyControlledComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.adequatelyControlled.comment",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreNoTre",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreCoronorySent",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.coronaryStent",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreCabg",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.cabg",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreNitrate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.useOfNitrates",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.date",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintAspirin",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.aspirin",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintPlavix",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.plavix",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintNitrate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.nitrate",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.other",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.comment",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialBetaBlocker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientBetaBlocker.isPatientBetaBlocker",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialBetaBlockerComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientBetaBlocker.comment",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympClau",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.symptoms.claudication",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympDimi",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.symptoms.diminished",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympExt",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.symptoms.extremityUlcers",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympUlc",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.symptoms.ulceration",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympGangere",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.symptoms.gangrene",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreNoTre",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesPri",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.describe.primary",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesSec",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.describe.secondary",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesUnk",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.describe.unknown",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreMed",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreNoTre",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeOne.active",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeOne.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeOne.historyOf",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitral",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.mitralStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesAortic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.aorticStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspid",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.tricuspidStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.pulmonaryInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryStenosis",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.pulmonaryStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitralInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.aorticInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesAorticInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.mitralInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspidInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.tricuspidInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describeTwo.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseValveReplacement",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.valveReplacement.isValveReplacement",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.valveReplacement.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.valveTypeOfReplacement",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseTreatmentMedication",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.treatment.isMedcation",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.treatment.isOtherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.treatment.otherTreatmentSubAnswer.otherTreatment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseTreatmentNoTreatment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.treatment.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.patientAnticoagulation.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.patientAnticoagulation.isPatientAnticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],

  [
    "ros_cardioSubDiagnosesOtherOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  [
    "ros_cardioSubDiagnosesHeartTransplant",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.heartTransplantSubAnswer.dateOfTransplant",
  ],
  [
    "ros_cardioSubDiagnosesSympChestPain",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesSympPeripheralEdema",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.peripheralEdema",
  ],
  [
    "ros_cardioSubDiagnosesSympShortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesSympHeartPalpitaions",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.heartPalpitaions",
  ],
  [
    "ros_cardioSubDiagnosesSympClaudication",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.claudication",
  ],
  [
    "ros_cardioSubDiagnosesSympExtremityDiscoloration",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.extremityDiscoloration",
  ],
  [
    "ros_cardioSubDiagnosesSympUnEplained",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.unEplained",
  ],
  [
    "ros_cardioSubDiagnosesSympdizziness",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.dizziness",
  ],
  [
    "ros_cardioSubDiagnosesSympNone",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.none",
  ],
  [
    "ros_cardioSubDiagnosesSympOther",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.isOther",
  ],
  [
    "ros_cardioSubDiagnosesSympOtherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.otherSubAnswers.other",
  ],
  [
    "ros_cardioSubDiagnosesSympComment",
    "cardiovascular.isCardiovascularSubAnswer.symptoms.comment",
  ],
  [
    "ros_takemedicationsasprescribedCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[3].takeMedicationsAsPescribed",
  ],
  [
    "ros_otherCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[7].other",
  ],
  [
    "ros_ThirtyCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[2].exerciseThirtyMinADay",
  ],
  [
    "ros_bloodPreCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[0].bloodPressureChecks",
  ],
  [
    "ros_heartHeCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[1].heartHealthyDiet",
  ],
  [
    "ros_caseManagementCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[4].caseManagement",
  ],
  [
    "ros_followupWithCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[5].followUpWithClinician",
  ],
  [
    "ros_durableMedicalEquipCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[6].memberToUse",
  ],
  [
    "ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[3].comment",
  ],
  [
    "ros_cardiovascularRecommendationsotherComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[7].comment",
  ],
  [
    "ros_cardiovascularRecommendationsexerciseThirtyMinADayComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[2].comment",
  ],
  [
    "ros_cardiovascularRecommendationsbloodPressureChecksComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[0].comment",
  ],
  [
    "ros_cardiovascularRecommendationsheartHealthyDietComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[1].comment",
  ],
  [
    "ros_cardiovascularRecommendationscasemangereferralfollowupComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[4].comment",
  ],
  [
    "ros_cardiovascularRecommendationsFollowupClinicianSpecialistComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[5].comment",
  ],
  [
    "ros_cardiovascularRecommendationsmemberTouseDurableEquipComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[6].comment",
  ],

  ["ros_diagnosisOfCancerVal", "diagnosisOfCancer.isDiagnosisOfCancer"],
  ["ros_diagnosisOfCancerCommentVal", "diagnosisOfCancer.comment"],
  [
    "ros_takemedicationsasprescribedCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "ros_otherCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[4].other",
  ],
  [
    "ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[0].comment",
  ],
  [
    "ros_diagnosisOfCancerRecommendationsCasemanagementfollowupreferralCancerComment",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[1].comment",
  ],
  [
    "ros_casemanagementCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[1].caseManagement",
  ],
  [
    "ros_diagnosisOfCancerRecommendationsotherCancerComment",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[4].comment",
  ],
  [
    "ros_followupWithCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[2].followUp",
  ],
  [
    "ros_diagnosisOfCancerRecommendationsfollowupwithclinispecCancerComment",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[2].comment",
  ],
  [
    "ros_durableMedicalCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[3].memberToUse",
  ],
  [
    "ros_diagnosisOfCancerRecommendationsmembertousedurablemedicalequipCancerComment",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[3].comment",
  ],
  [
    "ros_diagnosisOfCancerSubDescActiveVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.active",
  ],
  [
    "ros_diagnosisOfCancerSubDescHistoryOfVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.historyOf",
  ],
  [
    "ros_diagnosisOfCancerSubDescComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.comment",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBrainVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.brain",
  ],
  [
    "ros_diagnosisOfCancerSubTypeHeadVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.head",
  ],
  [
    "ros_diagnosisOfCancerSubTypeNeckVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.neck",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBreastVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.isBreast",
  ],
  [
    "ros_diagnosisOfCancertitl5Years",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.breastSubAnswers.lastFiveYears",
  ],
  [
    "ros_diagnosisOfCancertitl5YearsComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.breastSubAnswers.comment",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLungVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.lung",
  ],
  [
    "ros_diagnosisOfCancerSubTypeEsophagusVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.esophagus",
  ],
  [
    "ros_diagnosisOfCancerSubTypeStomachVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.stomach",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLiverVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.liver",
  ],
  [
    "ros_diagnosisOfCancerSubTypePancreasVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.pancreas",
  ],
  [
    "ros_diagnosisOfCancerSubTypeColonVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.colon",
  ],
  [
    "ros_diagnosisOfCancerSubTypeRectumVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.rectum",
  ],
  [
    "ros_diagnosisOfCancerSubTypeKidyneyVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.kidney",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBladderVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.bladder",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOvariesVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.ovaries",
  ],
  [
    "ros_diagnosisOfCancerSubTypeUterusVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.uterus",
  ],
  [
    "ros_diagnosisOfCancerSubTypeProstateVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.prostate",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBoneVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.bone",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBloodVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.blood",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLymphNodesVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.lymphNodes",
  ],
  [
    "ros_diagnosisOfCancerSubTypeSkinVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.skin",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.other",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOtherValSubAnswer",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.otherSubAnswer.describe",
  ],
  [
    "ros_diagnosisOfCancerSubTypeComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.comment",
  ],
  [
    "ros_diagnosisOfCancerSubSpecTyVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.specialTypes",
  ],

  [
    "ros_diagnosisOfCancerSubStageSpecCanVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.stageSpecificToTheCancer",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatment",
  ],
  [
    "ros_diagnosisOfCancerros_activeTreatmentComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatChemoVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.chemotherapy",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatRadiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.radiation",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatStemVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.stemCell",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatBoneVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.boneMarrow",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSurgVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.surgery",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatImmuVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.immuneSystem",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.other",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.otherSubAnswer.describe",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.nausea",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.vomiting",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.diarrhea",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.anemia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.neutropenia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.thrombocytopenia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.weakness",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.lossOfAppetite",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.other",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.otherSubAnswer.describe",
  ],

  [
    "ros_diagnosisOfCancerSubSideEffComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.comment",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasis",
  ],

  [
    "ros_diagnosisOfCancerros_MetastasisComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.comment",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisLocVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.location",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisCachexiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.cachexia.isCachexia",
  ],
  [
    "ros_diagnosisOfCancerSubMetastasisCachexiaValComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.cachexia.comment",
  ],

  [
    "ros_diagnosisOfCancerSubSeeASpecVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.isSeeASpecialist",
  ],
  [
    "ros_diagnosisOfCancerSubSeeASpecProVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.isSeeASpecialistSubAnswer.Provider",
  ],
  [
    "ros_diagnosisOfCancerros_SeeASpecComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.comment",
  ],

  ["ros_endocrineIsEndocrine", "endocrine.isEndocrine"],
  ["ros_endocrineIsEndocrineComment", "endocrine.comment"],
  [
    "ros_endocrineIsEndocrineDiagnoseComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnosecushingsDiseaseVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDisease",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympincreasedThirst",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.increasedThirst",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympshakiness",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.shakiness",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympsweating",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.sweating",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympblurredVision",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.blurredVision",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympweightGain",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.weightGain",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympfatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.fatigue",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymphairChanges",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.hairChanges",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympweightLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.weightLoss",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympNone",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.none",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosediabetesVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetes",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeOne",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.type.typeOne",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeonePointFive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.type.typeOnePointFive",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypetwo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.type.typeTwo",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeGe",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.type.gestational",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.type.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOral",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.medicationSubAnswers.oralHypoglycemic",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectInsulin",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.medicationSubAnswers.insulin",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.medicationSubAnswers.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.medicationSubAnswers.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.medicationSubAnswers.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreExer",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.exercise",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreDiet",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.diet",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.recentHbAonecValue",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubandDate",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.andDate",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.haveYouHadEgfr.isEgfr",
  ],
  [
    "ros_endocrineIsEndocrineNoEdu",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.haveYouHadEgfr.noSubAnswer.educationProvided",
  ],
  [
    "ros_endocrineIsEndocrineUnknownEdu",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.haveYouHadEgfr.unknownSubAnswer.educationProvided",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.haveYouHadEgfr.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.metWithNurse.isDietician",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.metWithNurse.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.bloodSugar.isbloodSugar",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.bloodSugar.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticCataracts",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.isDiabeticCataracts",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsRightEye",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.whichEye.right",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsLeftEye",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.whichEye.left",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsBoth",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.whichEye.both",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.whichEye.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsSubTreNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticCataractsSbAnswers.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticMacularEdema",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.isDiabeticMacular",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaLeft",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.whichEye.left",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaRight",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.whichEye.right",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaBoth",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.whichEye.both",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.whichEye.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreLaser",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.treatment.lasterTherapy",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreEye",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.treatment.eyeInjections",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaOccas",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.ophthalmologist.occasionally",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaOnce",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.ophthalmologist.onceAYear",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaTwice",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.ophthalmologist.twiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthagreTwice",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.ophthalmologist.greaterTwiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.activeSubAnswers.ophthalmologist.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticMacularSubAnswers.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticRetinopathy",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.isDiabeticRetinopathy",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubHistory",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubLeft",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.whichEye.left",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubRight",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.whichEye.right",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubBilateral",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.whichEye.bilateral",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.whichEye.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubPro",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.type.proliferative",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubNonPro",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.type.nonProliferative",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubUnknown",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.type.unknown",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.type.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatLaser",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.treatment.lasterTherapy",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatRetinal",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.treatment.eyeInjections",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubOccas",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.ophthalmologist.occasionally",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubOnce",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.ophthalmologist.onceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubTwice",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.ophthalmologist.twiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubgreTwice",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.ophthalmologist.greaterTwiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.diabeticRetinopathySubAnswers.ophthalmologist.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplPeripheralNeuropathy",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.isPeripheralNeuropathy",
  ],
  [
    "ros_endocrineIsEndocrineSubneuroSubdescribeSubActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubneuroSubdescribeSubHistory",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubneuroSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubSympNumb",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.numbness",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubSympPain",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.pain",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubSympBurning",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.burning",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubSympDiseased",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.deceasedSensation",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocU",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.numbnessSubAnswer.upper",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocL",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.numbnessSubAnswer.lower",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.numbnessSubAnswer.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocU",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.painSubAnswer.upper",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocL",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.painSubAnswer.lower",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.painSubAnswer.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocU",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.burningSubAnswer.upper",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocL",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.burningSubAnswer.lower",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.burningSubAnswer.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocU",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.deceasedSubAnswer.upper",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocL",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.deceasedSubAnswer.lower",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.deceasedSubAnswer.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubSympNone",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.none",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubSympComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubTreatMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubTreatBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubTreatBySubno",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubTreatComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.isPodiatrist",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.podiatristSubAnswers.comment",
  ],

  [
    "ros_endocrineIsEndocrineNeuroSubPatientOftenonceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.podiatristSubAnswers.howOften.onceAyear",
  ],
  [
    "ros_endocrineIsEndocrineNeuroSubPatientOftentwiceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.podiatristSubAnswers.howOften.twiceAyear",
  ],
  [
    "ros_endocrineIsEndocrineNeuroSubPatientOftenquarterly",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.podiatristSubAnswers.howOften.quarterlyAyear",
  ],
  [
    "ros_endocrineIsEndocrineNeuroSubPatientOftenComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralNeuropathySubAswers.describe.activeSubAnswers.podiatristSubAnswers.howOften.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplperipheralVascular",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.isPeripheralVascular",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.comment",
  ],

  [
    "ros_endocrineIsEndocrineSubVascularSubPhysicalSkin",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.physical.skinChanges",
  ],
  [
    "ros_endocrineIsEndocrineSubVascularSubPhysicalFoot",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.physical.foot",
  ],
  [
    "ros_endocrineIsEndocrineSubVascularSubPhysicalIntermitten",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.physical.intermittentClaudication",
  ],
  [
    "ros_endocrineIsEndocrineSubVascularSubPhysicalOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.physical.other",
  ],
  [
    "ros_endocrineIsEndocrineSubVascularSubPhysicalNone",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.physical.none",
  ],
  [
    "ros_endocrineIsEndocrineSubVascularSubPhysicalComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.physical.comment",
  ],

  [
    "ros_endocrineIsEndocrineSubVascularSubTreatMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.treatment.medication",
  ],

  [
    "ros_endocrineIsEndocrineSubVascularSubTreatOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubVascularSubTreatNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.treatment.noTreatment",
  ],

  [
    "ros_endocrineIsEndocrineSubVascularSubTreatComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.isPodiatrist",
  ],

  [
    "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenOne",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.podiatristSubAnswers.howOften.onceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenTwice",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.podiatristSubAnswers.howOften.twiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenQuarterly",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.podiatristSubAnswers.howOften.quarterly",
  ],
  [
    "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.podiatristSubAnswers.howOften.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.peripheralVascularSubAnswers.activeSubAnswers.podiatristSubAnswers.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplautonomic",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.isAutonomicNeuropathy",
  ],
  [
    "ros_endocrineIsEndocrineSubautonomicSubdescribeSubActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubautonomicSubdescribeSubHistory",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubAutonomicSubTreatMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineSubAutonomicSubTreatOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubAutonomicSubTreatNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubAutonomicSubTreatComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.autonomicNeuropathySubAnswers.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplckdstDiabetes",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.isChronicKidney",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStage1",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.stage1",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStage2",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.stage2",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStage3a",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.stage3",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStage3b",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.stage3b",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStage4",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.stage4",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStage5",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.stage5",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubUnknown",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.unknown",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStageComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.stage.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubTreatMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubTreatOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubTreatNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubTreatComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.chronicKidneySubAnswers.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplotherComplication",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.otherComplication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplnone",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.none",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.complications.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympincreasedThirst",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.increasedThirst",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympshakiness",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.shakiness",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympsweating",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.sweating",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympblurredVision",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.blurredVision",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympweightGain",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.weightGain",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympfatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.fatigue",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSymphairChanges",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.hairChanges",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympweightLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.weightLoss",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympNone",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.none",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosesecondaryHyperparathyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidism",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiKidney",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.kidneyDisease",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiVitamin",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.vitaminD",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiCeliac",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.celiac",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtimalabs",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.malabsorption",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtibariatric",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.bariatric",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiFractures",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.fractures",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiUnknown",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.unknown",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.etiology.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympincreasedThirst",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.increasedThirst",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympshakiness",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.shakiness",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympsweating",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.sweating",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympblurredVision",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.blurredVision",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympweightGain",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.weightGain",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympfatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.fatigue",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymphairChanges",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.hairChanges",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympweightLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.weightLoss",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympNone",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.none",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperthyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidism",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymprapidHeartBeat",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.symptoms.rapidHeartBeat",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympnervousness",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.symptoms.nervousness",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympweightLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.symptoms.weightLoss",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympheatIntolerance",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.symptoms.heatIntolerance",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympnone",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.symptoms.none",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehypothyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidism",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympweightGain",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.activeSubAnswers.symptoms.weightGain",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympfatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.activeSubAnswers.symptoms.fatigue",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymphairLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.activeSubAnswers.symptoms.hairLoss",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympNone",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.activeSubAnswers.symptoms.none",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidism",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympFrac",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.fractures",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympFatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.fatigue",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympNone",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.none",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.symptoms.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreMed",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreNo",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSuphistory",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupMedication",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupSymptoms",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupphysicalFindings",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSuptestResults",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupimageStudies",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupbiopsy",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupdme",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  [
    "ros_takeMedicationsAsPescribedEndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "ros_caseMangeEndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[2].caseManagement",
  ],
  [
    "ros_followupspecEndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[3].followUp",
  ],
  [
    "ros_durableMedicalEquipEndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[4].memberToUse",
  ],
  [
    "ros_checkBloodSugarEndoEndorineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[1].checkBloodSugar",
  ],
  [
    "ros_otherEndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[6].other",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[0].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[1].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationsotherEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[6].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationsdurablemedicalequipEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[4].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationscasemangfollowEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[2].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationsfollowupwithclinispecEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[3].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationsalbuminRatioEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[5].comment",
  ],
  [
    "ros_eGFREndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[5].egfr",
  ],

  //genito

  ["ros_genito", "genitourinary.isGenitourinary"],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailure",
  ],
  [
    "ros_genitoSubDiagnosesbphVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.isBph",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.isChronicKidneyDisease",
  ],
  [
    "ros_genitoSubDiagnosesesrdVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrd",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunction",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUti",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecological",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStones",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosis",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinence",
  ],
  [
    "ros_genitoSubDiagnosesotherVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.other",
  ],
  [
    "ros_genitoSubDiagnosesComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.comment",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesactive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredeshistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesdesacuteActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesacuteActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesacuteActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesacuteActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupLab",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.findings.labTests",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupCal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.findings.calculatedGFR",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupHos",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.findings.hospitalization",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.findings.other",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.findings.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.findings.comment",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureEtiology",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.acuteRenalFailureSubAnswer.etiology",
  ],
  [
    "ros_genitoSubDiagnosesbphDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesbphDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesbphDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesdesbhpActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesbhpActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesbhpActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesbhpActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.bphSubAnswers.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesdeschronicActiveSubSec",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.activeSubAnswer.secondary.isSecondary",
  ],
  [
    "ros_genitoSubDiagnosesdeschronicActiveSubSecComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.activeSubAnswer.secondary.comment",
  ],
  [
    "ros_genitoSubDiagnosesdeschronicActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdeschronicActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdeschronicActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdeschronicActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageOne",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageOne",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageTwo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageTwo",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageThreeA",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageThreeA",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageThreeB",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageThreeB",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageFour",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageFour",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageFive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageFive",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageUnknown",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.unknown",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.comment",
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.type.hemodialysis",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.type.peritoneal",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.type.comment",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.location.inHome",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.location.dialysisCenter",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.location.comment",
  ],
  [
    "ros_genitoSubDiagnosesesrddialysisSchedule",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.dialysisSchedule",
  ],
  [
    "ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.typeAndLocationOfAccessDevice",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.dialysis.location.comment",
  ],
  [
    "ros_genitoSubDiagnosesdesesrdActiveSubTreDia",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.isDialysis",
  ],
  [
    "ros_genitoSubDiagnosesdesesrdActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesesrdActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnoActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesesrdActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesesrdActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.esrdSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesdeserectileActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdeserectileActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesErectileTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdeserectileActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdeserectileActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.erectileDysfunctionSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesdesfreqsActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesfreqsActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesfreqsActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesfreqsActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.frequentUtiSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesdesgynecoActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesgynecoActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesgynecoActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesgynecoActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.gynecologicalSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesdeskidneyActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdeskidneyActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdeskidneyActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdeskidneyActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeUrate",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.type.urate",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeCalciumOxalate",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.type.calciumOxalate",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeMagnesium",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.type.magnesium",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.type.other",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.kidneyStonesSubAnswer.type.comment",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesdesNephrosisActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesNephrosisActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesNephrosisActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.nephritisOrNephrosisSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.active",
  ],
  [
    "ros_genitoSubDiagnosesdesUrinaryActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesUrinaryActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesUrinaryActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeOne.comment",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceRelatedToStressIsRelatedToStress",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStress",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceRelatedToStressDribbling",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.dribbling",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceRelatedToStressUrgency",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.urgency",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceRelatedToStressOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.other",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeDaily",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeTwo.daily",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeFewTimesAWeek",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeTwo.fewTimesAWeek",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeLessThanOnceAWeek",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeTwo.lessThanOnceAWeek",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.describeTwo.comment",
  ],
  [
    "ros_genitoStress",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStress",
  ],
  [
    "ros_genitoStressSubDribbling",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.dribbling",
  ],
  [
    "ros_genitoStressSubUrgency",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.urgency",
  ],
  [
    "ros_genitoStressSubOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.other",
  ],
  [
    "ros_genitoStressSubComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.comment",
  ],
  [
    "ros_genitoStressComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.comment",
  ],
  [
    "ros_genitoSubDiagnosesotherDescribeActive",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesdesOtherActiveSubTreMed",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.medication",
  ],
  [
    "ros_genitoSubDiagnosesdesOtherActiveSubTreOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesdesOtherActiveSubTreNo",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
  ],
  [
    "ros_genitoSubDiagnosesdesOtherActiveSubTreComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.comment",
  ],
  [
    "ros_genitoSubDiagnosesotherDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesotherDescribeComment",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesotherOther",
    "genitourinary.isGenitourinarySubAnswers.diagnoses.otherSubAnswer.other",
  ],
  ["ros_genitoComment", "genitourinary.comment"],
  [
    "ros_genitoSubSymptomsUrinary",
    "genitourinary.isGenitourinarySubAnswers.symptoms.urinaryFrequency",
  ],
  [
    "ros_genitoSubSymptomsPain",
    "genitourinary.isGenitourinarySubAnswers.symptoms.painOrburning",
  ],
  [
    "ros_genitoSubSymptomsAbdominal",
    "genitourinary.isGenitourinarySubAnswers.symptoms.abnormal",
  ],
  [
    "ros_genitoSubSymptomsOther",
    "genitourinary.isGenitourinarySubAnswers.symptoms.isOther",
  ],
  [
    "ros_genitoSubSymptomsOtherSub",
    "genitourinary.isGenitourinarySubAnswers.symptoms.otherSubAnswers.other",
  ],
  [
    "ros_genitoSubSymptomsNone",
    "genitourinary.isGenitourinarySubAnswers.symptoms.none",
  ],
  [
    "ros_genitoSubSymptomsComment",
    "genitourinary.isGenitourinarySubAnswers.symptoms.comment",
  ],
  [
    "ros_takemedicationsasprescribedGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "ros_casemanagementrefGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].caseManagement",
  ],
  [
    "ros_genitoRecommendationscasemanagementrefGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].comment",
  ],
  [
    "ros_memberDurableGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[3].memberToUse",
  ],
  [
    "ros_genitoRecommendationsmemberDurableGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[3].comment",
  ],
  [
    "ros_followupwithclinispecGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[2].followUp",
  ],
  [
    "ros_genitoRecommendationsfollowupwithclinispecGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[2].comment",
  ],
  [
    "ros_casemanagementrefGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].caseManagement",
  ],
  [
    "ros_genitoRecommendationscasemanagementrefGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].comment",
  ],
  [
    "ros_otherGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[4].other",
  ],
  [
    "ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[0].comment",
  ],
  [
    "ros_genitoRecommendationsotherGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[4].comment",
  ],
  ["ros_gastro", "gastrointestinal.isGastrointestinal"],
  ["ros_gastroComment", "gastrointestinal.comment"],
  [
    "ros_gastroSubDiagnoses",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses",
  ],
  [
    "ros_gastroSubDiagnosesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.comment",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstruction",
  ],
  [
    "ros_gastroSubDiagnosescachexiaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexia",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDisease",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosis",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolyps",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitis",
  ],
  [
    "ros_gastroSubDiagnosesgallBladderDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDisease",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesis",
  ],
  [
    "ros_gastroSubDiagnosesgerdVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerd",
  ],
  [
    "ros_gastroSubDiagnosesglBleedVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleed",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitis",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDisease",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitis",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDisease",
  ],
  [
    "ros_gastroSubDiagnosesliverTransVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.isLiverTransplant",
  ],
  [
    "ros_gastroSubDiagnosesotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.other",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosescachexiadesactive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosescachexiadeshistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosescachexiadesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdescachexiaActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdescachexiaActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdescachexiaActiveSubTreno",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdescachexiaActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreGluten",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.treatment.glutenFreeDiet",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.anemia",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.activeSubAnswers.comment",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreno",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectalcoholVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.select.alcohol",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.select.comment",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectascitesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.select.ascites",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectendStageVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.select.endStage",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.select.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectNoneVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.select.none",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.activeSubAnswers.select.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.active",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.historyOf",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.comment",
  ],
  [
    "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreno",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeOne.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesBengirVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeTwo.benign",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesCarcinomaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeTwo.carcinomaInSitu",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesPolyposisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeTwo.familialPolyposis",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describeTwo.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectabscessVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.select.abscess",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.select.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectperforationVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.select.perforation",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelecthighFiberDietVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.activeSubAnswers.select.bleeding",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesgerdDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesgerdDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesgerdDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesglBleedDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesglBleedDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesglBleedDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubBlood",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.activeSubAnswers.bloodInStool.isBloodInStool",
  ],
  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.activeSubAnswers.bloodInStool.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisTypeA",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.type.isA",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisTypeB",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.type.isB",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisTypeC",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.type.isC",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisTypeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.type.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisAcute",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.hepatitisDescribe.acute",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisChronic",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.hepatitisDescribe.chronic",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDessComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.hepatitisDescribe.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisCirrhosis",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.cirrhosis.isCirrhosis",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisCirrhosisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.cirrhosis.comment",
  ],
  [
    "ros_gastroCiroalcoholRelated",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.cirrhosis.alcoloRelated.isAlcohol",
  ],
  [
    "ros_gastroCiroalcoholRelatedComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.cirrhosis.alcoloRelated.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.hepatocellularCarcinoma.isHepatocellularCarcinoma",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.hepatocellularCarcinoma.comment",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.jaundice.isJaundice",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.activeSubAnswers.jaundice.comment",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeUlcerativeColitis",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.describe.ulcerativeColitis",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeCrohnsDisease",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.describe.crohnsDisease",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.describe.other",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.activeSubAnswers.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisRecurrentEpisodesYes",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.recurrentEpisodes.YesChronic",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisRecurrentEpisodesNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.recurrentEpisodes.no",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.recurrentEpisodes.comment",
  ],

  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.alcolholRelated.isAlcoholRelated",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.activeSubAnswers.alcolholRelated.comment",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreMed",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.treatment.medication",
  ],
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreNo",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.treatment.noTreatment",
  ],
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.treatment.otherTreatmentSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.treatment.comment",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseEsophageal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.describe.esophageal",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseGastric",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.describe.gastric",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseDuodenal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.describe.duodenal",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.describe.isOther",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.describe.otherSubAnswers.describe",
  ],

  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseHeliobactria",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.heliobactria.isHeliobactria",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.activeSubAnswers.heliobactria.comment",
  ],

  [
    "ros_gastroDateOfTransplant",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.liverTransplantSubAnswers.dateOfTransplant",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByHistory",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByPhysicalFindings",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByMedications",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByTestResults",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByImageStudies",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByBiopsy",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByDme",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_gastroSubDiagnosesotherOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  [
    "ros_gastroSubSymptomsHeart",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.heartBurn",
  ],
  [
    "ros_gastroSubSymptomsNausea",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.nausea",
  ],
  [
    "ros_gastroSubSymptomsBlood",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.bloodInStool",
  ],
  [
    "ros_gastroSubSymptomsAbdominal",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.abdominal",
  ],
  [
    "ros_gastroSubSymptomsOther",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.other",
  ],
  [
    "ros_gastroSubSymptomsOtherSub",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.otherSubAnswer.other",
  ],
  [
    "ros_gastroSubSymptomsNone",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.none",
  ],
  [
    "ros_gastroSubSymptomsComment",
    "gastrointestinal.isGastrointestinalSubAnswer.symptoms.comment",
  ],

  [
    "ros_gastroSubDiagnosesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.comment",
  ],

  [
    "ros_gastrorecommendationstakemedicationsasprescribed",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "ros_gastrorecommendationstakemedicationsasprescribedComment",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[0].comment",
  ],
  [
    "ros_gastrorecommendationscaseManagement",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[1].caseManagement",
  ],
  [
    "ros_gastrorecommendationsfollowup",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[2].followUpWithClinician",
  ],
  [
    "ros_gastrorecommendationsuseDme",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[3].memberToUse",
  ],
  [
    "ros_gastrorecommendationscaseManagementComment",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[1].comment",
  ],
  [
    "ros_gastrorecommendationsfollowupComment",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[2].comment",
  ],
  [
    "ros_gastrorecommendationsuseDmeComment",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[3].comment",
  ],

  [
    "ros_gastrorecommendationsother",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[4].other",
  ],

  [
    "ros_gastrorecommendationsotherComment",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[4].comment",
  ],
];

const ros_optionsTreeMapObject_srilatha2024 = {
  ros_cardioSubDiagnosesabnormalCardiacRhythmVal: "abnormalCardiacRhythm",
  ros_cardioSubDiagnosesaneurysmVal: "aneursym",
  ros_cardioSubDiagnosesanginaVal: "angina",
  ros_cardioSubDiagnosesatrialFibrillationVal: "atrialFibrillation",
  ros_cardioSubDiagnosesatrialFlutterVal: "atrialFlutter",
  ros_cardioSubDiagnosescardioRespiratoryShockVal:
    "cardioRespiratoryFailureShock",
  ros_cardioSubDiagnosescardiomyopathyVal: "cardiomyopathy",
  ros_cardioSubDiagnosescongestiveHeartFailureVal: "congestiveHeartFailure",
  ros_cardioSubDiagnosesdeepVeinThrombosisVal: "deepVeinThrombosis",
  ros_cardioSubDiagnoseshyperlipidemiaVal: "hyperlipidemia",
  ros_cardioSubDiagnoseshypertensionVal: "hypertension",
  ros_cardioSubDiagnosesischemicHeartDiseaseVal: "ischemicHeartDisease",
  ros_cardioSubDiagnosesmyocardialInfarctionVal: "myocardialInfarction",
  ros_cardioSubDiagnosesperipheralVascularDiseaseVal:
    "peripheralVascularDisease",
  ros_cardioSubDiagnosespulmonaryHypertensionVal: "pulmonaryHypertension",
  ros_cardioSubDiagnosesvalvularDiseaseVal: "valvularDisease",
  ros_cardioSubDiagnosesHeartTransplantVal: "heartTransplant",
  ros_cardioSubDiagnosesotherVal: "other",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal: "active",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal: "historyOf",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerMedication:
    "medication",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatment:
    "otherTreatment",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswernoTreatment:
    "noTreatment",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerimplantedDefibrillator:
    "implantedDefibrillator",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerspacemaker:
    "pacemaker",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia: "bradycardia",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia: "tachycardia",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardiaSubSick:
    "sickSinusSyndromeSss",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardiaSubUnknown:
    "unknown",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardiaSubSupraventricular:
    "supraventricularTachycardiaSvt",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardiaSubUnknown:
    "unknown",
  ros_cardioSubDiagnosesAneurysmDesActive: "active",
  ros_cardioSubDiagnosesAneurysmDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAneurysmCrDesAbdominal: "abdominal",
  ros_cardioSubDiagnosesAneurysmCrDesThoracic: "thoracic",
  ros_cardioSubDiagnosesAneurysmCrDesPeripheral: "peripheral",
  ros_cardioSubDiagnosesAneurysmTyperuptured: "ruptured",
  ros_cardioSubDiagnosesAneurysmTypeUnruptured: "unruptured",
  ros_cardioSubDiagnosesAneurysmTreatMedication: "medication",
  ros_cardioSubDiagnosesAneurysmTreatOtherTreatment: "otherTreatment",
  ros_cardioSubDiagnosesAneurysmTreatNoTreatment: "noTreatment",
  ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesStable: "stable",
  ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesUnstable: "Unstable",
  ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentmed: "medication",
  ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentother:
    "otherTreatment",
  ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentno: "noTreatment",
  ros_cardioSubDiagnosesAnginaDesActive: "active",
  ros_cardioSubDiagnosesAnginaDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAtrialFibrillationDesActive: "active",
  ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypePar:
    "paroxysmal",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeChro:
    "chronic",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeUnk:
    "unknown",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatMed:
    "medication",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatNoTreat:
    "noTreatment",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTr:
    "otherTreatment",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatPace:
    "pacemaker",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatImp:
    "implantedDefibrillator",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientAnti:
    "anticoagulant",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientRate:
    "rateControllingMedication",
  ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOther:
    "other",
  ros_cardioSubDiagnosesAtrialFlutterDesActive: "active",
  ros_cardioSubDiagnosesAtrialFlutterDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatmed: "medication",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatother: "otherTreatment",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatno: "noTreatment",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatImplant:
    "implantedDefibrillator",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatPace: "pacemaker",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientAntico:
    "anticoagulant",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientRate:
    "rateControllingMedication",
  ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOther: "other",
  ros_cardioSubDiagnosesCardiorespiratoryShockDesActive: "active",
  ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatMed:
    "medication",
  ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatOther:
    "otherTreatment",
  ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatNoOther:
    "noTreatment",
  ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatPace:
    "pacemaker",
  ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatImplant:
    "implantedDefibrillator",
  ros_cardioSubDiagnosesCardiomyopathyDesActive: "active",
  ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCardiomyopathyTypeDilated: "dilated",
  ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic: "hypertrophic",
  ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive: "restrictive",
  ros_cardioSubDiagnosesCardiomyopathyTypeUnknown: "unknown",
  ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatisMed:
    "medication",
  ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatNotreat:
    "noTreatment",
  ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherTr:
    "otherTreatment",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActive: "active",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute: "acute",
  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic: "chronic",
  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic:
    "acuteOnChronic",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesDia: "diastolic",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesSys: "systolic",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesUnkn: "unKnown",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreMed: "medication",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreNoTre:
    "noTreatment",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOther:
    "otherTreatment",
  ros_cardioSubDiagnosesDeepVeinThrombosisDesActive: "active",
  ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedication: "medication",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatOtherTreatment: "otherTreatment",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatNoTreatment: "noTreatment",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiProphyl:
    "prophylactic",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiThera:
    "therapeutic",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocrightLeg:
    "rightLeg",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocleftLeg:
    "leftLeg",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocBilateralUpp:
    "bitlateralUpper",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocBitlaterLow:
    "bilateralLower",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocRightArm:
    "rightArm",
  ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocleftArm:
    "leftArm",
  ros_cardioSubDiagnosesHyperlipidemiaDesActive: "active",
  ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreMed: "medication",
  ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreNoTre: "noTreatment",
  ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOther: "otherTreatment",
  ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreDiet: "dietControlled",
  ros_cardioSubDiagnosesHypertensionDesActive: "active",
  ros_cardioSubDiagnosesHypertensionDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesHypertensionDesActiveSubTreMed: "medication",
  ros_cardioSubDiagnosesHypertensionDesActiveSubTreNoTre: "noTreatment",
  ros_cardioSubDiagnosesHypertensionDesActiveSubTreOther: "otherTreatment",
  ros_cardioSubDiagnosesHypertensionDesActiveSubTreDiet: "dietControlled",
  ros_cardioSubDiagnosesHypertensionSympHeadache: "headAches",
  ros_cardioSubDiagnosesHypertensionSymplightHeadedness: "lightheadedness",
  ros_cardioSubDiagnosesHypertensionSympBlurredvison: "blurredVision",
  ros_cardioSubDiagnosesHypertensionSympother: "other",
  ros_cardioSubDiagnosesHypertensionSympNone: "none",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive: "active",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreMed: "medication",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreNoTre: "noTreatment",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOther:
    "otherTreatment",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreCoronorySent:
    "coronaryStent",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreCabg: "cabg",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreNitrate:
    "useOfNitrates",
  ros_cardioSubDiagnosesMyocardialDesActive: "active",
  ros_cardioSubDiagnosesMyocardialDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesMyocardialIspateintAspirin: "aspirin",
  ros_cardioSubDiagnosesMyocardialIspateintPlavix: "plavix",
  ros_cardioSubDiagnosesMyocardialIspateintNitrate: "nitrate",
  ros_cardioSubDiagnosesMyocardialIspateintOther: "other",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive: "active",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreMed:
    "medication",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreNoTre:
    "noTreatment",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOther:
    "otherTreatment",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympClau:
    "claudication",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympDimi:
    "diminished",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympExt:
    "extremityUlcers",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympUlc:
    "ulceration",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympGangere:
    "gangrene",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesActive: "active",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesPri: "primary",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesSec: "secondary",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesUnk: "unknown",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreMed: "medication",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreNoTre:
    "noTreatment",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOther:
    "otherTreatment",
  ros_cardioSubDiagnosesvalvulardiseaseDesActive: "active",
  ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesMitral: "mitralStenosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesAortic: "aorticStenosisSclerosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspid: "tricuspidStenosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryInsuff:
    "pulmonaryInsufficiency",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryStenosis:
    "pulmonaryStenosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesMitralInsuff: "aroticInsufficiency",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesAorticInsuff:
    "mitralInsufficiencyProlapse",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspidInsuff:
    "tricuspidInsufficiency",
  ros_cardioSubDiagnosesOtherDesActive: "active",
  ros_cardioSubDiagnosesOtherDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesvalvulardiseaseTreatmentMedication: "medication",
  ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatment:
    "otherTreatment",
  ros_cardioSubDiagnosesvalvulardiseaseTreatmentNoTreatment: "notreatment",
  ros_cardioSubDiagnosesSympOther: "other",
  ros_cardioSubDiagnosesSympNone: "none",
  ros_cardioSubDiagnosesSympdizziness: "dizziness",
  ros_cardioSubDiagnosesSympUnEplained: "unexplained/suddenWeight",
  ros_cardioSubDiagnosesSympExtremityDiscoloration: "extremityDiscoloration",
  ros_cardioSubDiagnosesSympClaudication: "claudication",
  ros_cardioSubDiagnosesSympHeartPalpitaions: "heartPalpitations",
  ros_cardioSubDiagnosesSympShortnessOfBreath: "ShortnessOfBreathe",
  ros_cardioSubDiagnosesSympPeripheralEdema: "peripheralEdema",
  ros_cardioSubDiagnosesSympChestPain: "chestPain",

  ros_diagnosisOfCancerSubDescActiveVal: "active",
  ros_diagnosisOfCancerSubDescHistoryOfVal: "historyOf",
  ros_diagnosisOfCancerSubSupPhyFinVal: "physicalFindings",
  ros_diagnosisOfCancerSubSuphospVal: "hospitalization",
  ros_diagnosisOfCancerSubSuptreatVal: "treatments",
  ros_diagnosisOfCancerSubSuplabTestsVal: "labTests",
  ros_diagnosisOfCancerSubSupimagVal: "imagingStudies",
  ros_diagnosisOfCancerSubSupSurVal: "surgery",
  ros_diagnosisOfCancerSubSupBioVal: "biopsy",
  ros_diagnosisOfCancerSubSupOtherVal: "other",
  ros_diagnosisOfCancerSubTypeBrainVal: "brain",
  ros_diagnosisOfCancerSubTypeHeadVal: "head",
  ros_diagnosisOfCancerSubTypeNeckVal: "neck",
  ros_diagnosisOfCancerSubTypeBreastVal: "breast",
  ros_diagnosisOfCancerSubTypeLungVal: "lung",
  ros_diagnosisOfCancerSubTypeEsophagusVal: "esophagus",
  ros_diagnosisOfCancerSubTypeStomachVal: "stomach",
  ros_diagnosisOfCancerSubTypeLiverVal: "liver",
  ros_diagnosisOfCancerSubTypePancreasVal: "pancreas",
  ros_diagnosisOfCancerSubTypeColonVal: "colon",
  ros_diagnosisOfCancerSubTypeRectumVal: "rectum",
  ros_diagnosisOfCancerSubTypeKidyneyVal: "kidney",
  ros_diagnosisOfCancerSubTypeBladderVal: "bladder",
  ros_diagnosisOfCancerSubTypeOvariesVal: "ovaries",
  ros_diagnosisOfCancerSubTypeUterusVal: "uterus",
  ros_diagnosisOfCancerSubTypeProstateVal: "prostate",
  ros_diagnosisOfCancerSubTypeBoneVal: "bone",
  ros_diagnosisOfCancerSubTypeBloodVal: "blood",
  ros_diagnosisOfCancerSubTypeLymphNodesVal: "lymphNodes",
  ros_diagnosisOfCancerSubTypeSkinVal: "skin",
  ros_diagnosisOfCancerSubTypeOtherVal: "other",
  ros_diagnosisOfCancerSubActiveTreatChemoVal: "chemotherapy",
  ros_diagnosisOfCancerSubActiveTreatRadiaVal: "radiation",
  ros_diagnosisOfCancerSubActiveTreatStemVal: "stemCell",
  ros_diagnosisOfCancerSubActiveTreatBoneVal: "boneMarrow",
  ros_diagnosisOfCancerSubActiveTreatSurgVal: "surgery",
  ros_diagnosisOfCancerSubActiveTreatImmuVal: "immuneSystem",
  ros_diagnosisOfCancerSubActiveTreatOtherVal: "other",
  ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal: "nausea",
  ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal: "vomiting",
  ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal: "diarrhea",
  ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal: "anemia",
  ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal: "neutropenia",
  ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal:
    "thrombocytopenia",
  ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal: "weakness",
  ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal: "lossOfAppetite",
  ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal: "other",

  ros_endocrineIsEndocrineDiagnosecushingsDiseaseVal: "cushingsDisease",
  ros_endocrineIsEndocrineDiagnosediabetesVal: "diabetes",
  ros_endocrineIsEndocrineDiagnosediabeticRetinopathyVal: "diabeticRetinopathy",
  ros_endocrineIsEndocrineDiagnosesecondaryHyperparathyroidismVal:
    "secondaryHyperparathyroidism",
  ros_endocrineIsEndocrineDiagnosehypertensionDiabetesVal:
    "hypertensionAndDiabetes",
  ros_endocrineIsEndocrineDiagnosehyperthyroidismVal: "hyperthyroidism",
  ros_endocrineIsEndocrineDiagnosehypothyroidismVal: "hypothyroidism",
  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyVal:
    "peripheralNeuropathySecondaryT",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularVal: "pvdstDiabetes",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismVal: "hyperparathyroidism",
  ros_endocrineIsEndocrineDiagnoseotherVal: "other",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubhistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympincreasedThirst:
    "increasedThirst",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympshakiness:
    "shakiness",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympsweating: "sweating",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympblurredVision:
    "blurredVision",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympweightGain:
    "weightGain",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympfatigue: "fatigue",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymphairChanges:
    "hairChanges",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympweightLoss:
    "weightLoss",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympNone: "none",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreMed: "medication",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOther:
    "otherTreatment",
  ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreNo: "noTreatment",
  ros_endocrineIsEndocrineSubdiabetesSubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubdiabetesSubdescribeSubhistoryOf: "historyOf",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeOne: "type1",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeonePointFive:
    "typeOnepointFive",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypetwo: "type2",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeGe: "gestational",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreMed: "medication",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOral:
    "oralHypoglycemicAgent",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectInsulin: "insulin",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOther: "other",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOther:
    "otherTreatment",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreNo: "noTreatment",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreExer: "exercise",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreDiet: "diet",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticCataracts:
    "diabeticCataracts",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticMacularEdema:
    "diabeticMacularEdema",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticRetinopathy:
    "diabeticRetinopathy",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplPeripheralNeuropathy:
    "peripheralNeuropathy",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplperipheralVascular:
    "peripheralVascular",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplautonomic:
    "autonomic",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplckdstDiabetes:
    "ckdstDiabetes",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplotherComplication:
    "otherComplication",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplnone: "none",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympincreasedThirst:
    "increasedThirst",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympshakiness:
    "shakiness",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympsweating: "sweating",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympblurredVision:
    "blurredVision",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympweightGain:
    "weightGain",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympfatigue: "fatigue",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSymphairChanges:
    "hairChanges",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympweightLoss:
    "weightLoss",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympNone: "none",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsRightEye:
    "rightEye",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsLeftEye:
    "leftEye",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsBoth: "both",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeActive: "active",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeHistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreMed:
    "medication",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOther:
    "otherTreatment",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsSubTreNo:
    "noTreatment",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaLeft: "leftEye",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaRight: "rightEye",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaBoth: "both",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeActive:
    "active",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeHistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreMed: "medication",
  ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreLaser:
    "laserTherapy",
  ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreEye:
    "eyeInjections",
  ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOther:
    "otherTreatment",
  ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreNo: "noTreatment",
  ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaOccas: "ocasionally",
  ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaOnce: "onceAYear",
  ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaTwice: "twiceAyear",
  ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthagreTwice:
    "greaterThan2Ayear",
  ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubActive: "active",
  ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubHistory:
    "historyOf",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubLeft: "leftEye",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubRight: "rightEye",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubBilateral:
    "bilateral",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubPro: "proliferative",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubNonPro:
    "nonProliferative",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubUnknown: "unknown",
  ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatMed: "medication",
  ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatLaser: "laserTherapy",
  ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatRetinal:
    "retinalInjections",
  ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOther: "other",
  ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatNo: "noTreatment",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubOccas:
    "occasionally",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubOnce:
    "onceAYear",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubTwice:
    "twiceAYear",
  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubgreTwice:
    "gtwiceAYear",
  ros_endocrineIsEndocrineSubneuroSubdescribeSubActive: "active",
  ros_endocrineIsEndocrineSubneuroSubdescribeSubHistory: "historyOf",
  ros_endocrineIsEndocrineSubNeuroSubSympNumb: "numbess",
  ros_endocrineIsEndocrineSubNeuroSubSympPain: "pain",
  ros_endocrineIsEndocrineSubNeuroSubSympBurning: "burning",
  ros_endocrineIsEndocrineSubNeuroSubSympDiseased: "decreasedSensation",
  ros_endocrineIsEndocrineSubNeuroSubSympNone: "none",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocU: "upperExtremities",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocL: "lowerExtremities",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocU: "upperExtremities",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocL: "lowerExtremities",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocU: "upperExtremities",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocL: "lowerExtremities",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocU: "upperExtremities",
  ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocL: "lowerExtremities",
  ros_endocrineIsEndocrineSubNeuroSubTreatMed: "medication",
  ros_endocrineIsEndocrineSubNeuroSubTreatBySubother: "otherTreatment",
  ros_endocrineIsEndocrineSubNeuroSubTreatBySubno: "noTreatment",
  ros_endocrineIsEndocrineNeuroSubPatientOftenonceAYear: "onceAYear",
  ros_endocrineIsEndocrineNeuroSubPatientOftentwiceAYear: "twiceAYear",
  ros_endocrineIsEndocrineNeuroSubPatientOftenquarterly: "quarterly",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeActive: "active",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeHistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineSubVascularSubPhysicalSkin: "skinChanges",
  ros_endocrineIsEndocrineSubVascularSubPhysicalFoot: "footdeformity",
  ros_endocrineIsEndocrineSubVascularSubPhysicalIntermitten: "intermittent",
  ros_endocrineIsEndocrineSubVascularSubPhysicalOther: "other",
  ros_endocrineIsEndocrineSubVascularSubPhysicalNone: "none",
  ros_endocrineIsEndocrineSubVascularSubTreatMed: "medication",
  ros_endocrineIsEndocrineSubVascularSubTreatOther: "otherTreatment",
  ros_endocrineIsEndocrineSubVascularSubTreatNo: "noTreatment",
  ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenOne: "onceAYear",
  ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenTwice: "twiceAYear",
  ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenQuarterly: "quarterly",
  ros_endocrineIsEndocrineSubautonomicSubdescribeSubActive: "active",
  ros_endocrineIsEndocrineSubautonomicSubdescribeSubHistory: "historyOf",
  ros_endocrineIsEndocrineSubAutonomicSubTreatMed: "medication",
  ros_endocrineIsEndocrineSubAutonomicSubTreatOther: "otherTreatment",
  ros_endocrineIsEndocrineSubAutonomicSubTreatNo: "noTreatment",
  ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubActive: "active",
  ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubHistoryOf: "historyOf",
  ros_endocrineIsEndocrineSubchronicKidneySubStage1: "stage1",
  ros_endocrineIsEndocrineSubchronicKidneySubStage2: "stage2",
  ros_endocrineIsEndocrineSubchronicKidneySubStage3a: "stage3a",
  ros_endocrineIsEndocrineSubchronicKidneySubStage3b: "stage3b",
  ros_endocrineIsEndocrineSubchronicKidneySubStage4: "stage4",
  ros_endocrineIsEndocrineSubchronicKidneySubStage5: "stage5",
  ros_endocrineIsEndocrineSubchronicKidneySubUnknown: "unknown",
  ros_endocrineIsEndocrineSubchronicKidneySubTreatMed: "medication",
  ros_endocrineIsEndocrineSubchronicKidneySubTreatOther: "otherTreatment",
  ros_endocrineIsEndocrineSubchronicKidneySubTreatNo: "noTreatment",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubactive:
    "active",
  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubhistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiKidney:
    "kidneyDisease",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiVitamin: "vitaminDef",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiCeliac: "celiacDis",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtimalabs:
    "malabsorption",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtibariatric:
    "bariatricSurgery",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiFractures: "fractures",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiOther: "other",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiUnknown: "unknown",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympincreasedThirst:
    "increasedThirst",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympshakiness:
    "shakiness",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympsweating: "sweating",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympblurredVision:
    "blurredVision",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympweightGain:
    "weightGain",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympfatigue: "fatigue",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymphairChanges:
    "hairChanges",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympweightLoss:
    "weightLoss",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympNone: "none",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreMed: "medication",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOther:
    "otherTreatment",
  ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreNo: "noTreatment",
  endocrineSubhypertensionDiabetesSubdescribeSubactive: "active",
  endocrineSubhypertensionDiabetesSubdescribeSubhistoryOf: "historyOf",
  ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubhistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymprapidHeartBeat:
    "rapidHeartBeat",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympnervousness:
    "nervousness",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympweightLoss:
    "weightLoss",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympheatIntolerance:
    "heartIntolerance",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympnone: "none",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreMed: "medication",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOther:
    "otherTreatment",
  ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreNo: "noTreatment",
  ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubhistoryOf: "historyOf",
  ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentMed: "medication",
  ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOther: "otherTreatment",
  ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentNo: "notreatment",
  ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympweightGain:
    "weightGain",
  ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympfatigue:
    "fatigue",
  ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymphairLoss:
    "hairLoss",
  ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympNone: "none",
  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeActive: "active",
  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeHistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeActive: "active",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeHistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympFrac: "fractures",
  ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympFatigue: "fatigue",
  ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympOther: "other",
  ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympNone: "none",
  ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreMed: "medication",
  ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOther:
    "otherTreatment",
  ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreNo: "noTreatment",
  ros_endocrineIsEndocrineDiagnoseotherDescribeActive: "active",
  ros_endocrineIsEndocrineDiagnoseotherDescribeHistoryOf: "historyOf",
  ros_endocrineIsEndocrineDiagnoseotherSuphistory: "history",
  ros_endocrineIsEndocrineDiagnoseotherSupMedication: "medications",
  ros_endocrineIsEndocrineDiagnoseotherSupSymptoms: "symptoms",
  ros_endocrineIsEndocrineDiagnoseotherSupphysicalFindings: "physicalFindings",
  ros_endocrineIsEndocrineDiagnoseotherSuptestResults: "testResults",
  ros_endocrineIsEndocrineDiagnoseotherSupimageStudies: "imageStudies",
  ros_endocrineIsEndocrineDiagnoseotherSupbiopsy: "biopsy",
  ros_endocrineIsEndocrineDiagnoseotherSupdme: "dme",
  ros_endocrineIsEndocrineDiagnoseotherSupOther: "other",
  ros_genitoSubDiagnosesacuteRenalFailuredesactive: "active",
  ros_genitoSubDiagnosesacuteRenalFailuredeshistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesacuteActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesacuteActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesacuteActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnosesacuteRenalFailureSupLab: "labTests",
  ros_genitoSubDiagnosesacuteRenalFailureSupCal: "calculatedGfr",
  ros_genitoSubDiagnosesacuteRenalFailureSupHos: "hospitalization",
  ros_genitoSubDiagnosesacuteRenalFailureSupOther: "other",
  ros_genitoSubDiagnosesbphDescribeActive: "active",
  ros_genitoSubDiagnosesbphDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesbhpActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesbhpActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesbhpActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnosesdeschronicActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdeschronicActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdeschronicActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeActive: "active",
  ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnoseswhatStageStageOne: "stage1",
  ros_genitoSubDiagnoseswhatStageStageTwo: "stage2",
  ros_genitoSubDiagnoseswhatStageStageThreeA: "stage3A",
  ros_genitoSubDiagnoseswhatStageStageThreeB: "stage3B",
  ros_genitoSubDiagnoseswhatStageStageFour: "stage4",
  ros_genitoSubDiagnoseswhatStageStageFive: "stage5",
  ros_genitoSubDiagnoseswhatStageUnknown: "unknown",
  ros_genitoSubDiagnosesesrdDescribeActive: "active",
  ros_genitoSubDiagnosesesrdDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesesrdActiveSubTreDia: "dialysis",
  ros_genitoSubDiagnosesdesesrdActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesesrdActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesesrdActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis: "hemodialysis",
  ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis:
    "peritonealDialysis",
  ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome: "inHome",
  ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter:
    "dialysisCenter",
  ros_genitoSubDiagnoseserectileDysfunctionDescribeActive: "active",
  ros_genitoSubDiagnoseserectileDysfunctionDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdeserectileActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdeserectileActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdeserectileActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnosesfrequentUtiDescribeActive: "active",
  ros_genitoSubDiagnosesfrequentUtiDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesfreqsActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesfreqsActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesfreqsActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnosesgynecologicalDescribeActive: "active",
  ros_genitoSubDiagnosesgynecologicalDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesgynecoActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesgynecoActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesgynecoActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnoseskidneyStonesDescribeActive: "active",
  ros_genitoSubDiagnoseskidneyStonesDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdeskidneyActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdeskidneyActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdeskidneyActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnoseskidneyStonesTypeUrate: "urate",
  ros_genitoSubDiagnoseskidneyStonesTypeCalciumOxalate: "calciumOxalate",
  ros_genitoSubDiagnoseskidneyStonesTypeMagnesium: "magnesium",
  ros_genitoSubDiagnoseskidneyStonesTypeOther: "other",
  ros_genitoSubDiagnosesnephritisOrNephrosisDescribeActive: "active",
  ros_genitoSubDiagnosesnephritisOrNephrosisDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesNephrosisActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesNephrosisActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesNephrosisActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnosesurinaryIncontinenceDescribeActive: "active",
  ros_genitoSubDiagnosesurinaryIncontinenceDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesUrinaryActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesUrinaryActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesUrinaryActiveSubTreNo: "noTreatment",

  ros_genitoStressSubDribbling: "dribbling",
  ros_genitoStressSubUrgency: "urgency",
  ros_genitoStressSubOther: "other",

  ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeDaily: "daily",
  ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeFewTimesAWeek:
    "fewTimesAWeek",
  ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeLessThanOnceAWeek:
    "lessThanOnceAWeek",
  ros_genitoSubDiagnosesotherDescribeActive: "active",
  ros_genitoSubDiagnosesotherDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesdesOtherActiveSubTreMed: "medication",
  ros_genitoSubDiagnosesdesOtherActiveSubTreOther: "otherTreatment",
  ros_genitoSubDiagnosesdesOtherActiveSubTreNo: "noTreatment",
  ros_genitoSubDiagnosesacuteRenalFailureVal: "acuteRenalFailure",
  ros_genitoSubDiagnosesbphVal: "bphgenito",
  ros_genitoSubDiagnoseschronicKidneyDiseaseVal: "chronicKidneyDisease",
  ros_genitoSubDiagnosesesrdVal: "esrd",
  ros_genitoSubDiagnoseserectileDysfunctionVal: "erectileDysfunction",
  ros_genitoSubDiagnosesfrequentUtiVal: "frequentUti",
  ros_genitoSubDiagnosesgynecologicalVal: "gynecological",
  ros_genitoSubDiagnoseskidneyStonesVal: "kidneyStonesgenito",
  ros_genitoSubDiagnosesnephritisOrNephrosisVal: "nephritisOrNephrosis",
  ros_genitoSubDiagnosesurinaryIncontinenceVal: "urinaryIncontinence",
  ros_genitoSubDiagnosesotherVal: "other",
  ros_genitoSubSymptomsUrinary: "urinaryFrequency",
  ros_genitoSubSymptomsPain: "pain/burningwithurination",
  ros_genitoSubSymptomsAbdominal: "abdominalVaginalBleed",
  ros_genitoSubSymptomsOther: "other",
  ros_genitoSubSymptomsNone: "none",
  ros_gastroSubDiagnosesbowelObstructionVal: "bowelobstruction",
  ros_gastroSubDiagnosescachexiaVal: "cachexia",
  ros_gastroSubDiagnosesceliacDiseaseVal: "celiacdisease",
  ros_gastroSubDiagnosescirrhosisVal: "cirrhosis",
  ros_gastroSubDiagnosescolonPolypsVal: "colonpolyps",
  ros_gastroSubDiagnosesdiverticulitisVal: "diverticultis",
  ros_gastroSubDiagnosesgallBladderDiseaseVal: "gallbladderdisease",
  ros_gastroSubDiagnosesgastroparesisVal: "gastroparesis",
  ros_gastroSubDiagnosesgerdVal: "gerd",
  ros_gastroSubDiagnosesglBleedVal: "gibleed",
  ros_gastroSubDiagnoseshepatitisVal: "hepatitis",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal: "inflammatoryboweldisease",
  ros_gastroSubDiagnosespancreatitisVal: "pancreatitis",
  ros_gastroSubDiagnosesulcerDiseaseVal: "ulcerdisease",
  ros_gastroSubDiagnosesliverTransVal: "liverTransplant",
  ros_gastroSubDiagnosesotherVal: "other",
  ros_gastroSubDiagnosesdesbowelObstructionActiveVal: "active",
  ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal: "ruleOut",
  ros_gastroSubDiagnosescachexiadesactive: "active",
  ros_gastroSubDiagnosescachexiadeshistoryOf: "historyOf",
  ros_gastroSubDiagnosescachexiadesruleOut: "ruleOut",
  ros_gastroSubDiagnosesdescachexiaActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdescachexiaActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdescachexiaActiveSubTreno: "noTreatment",
  ros_gastroSubDiagnosescachexiaSupalbuminVal: "albumin<3.5g/dl",
  ros_gastroSubDiagnoscachexiaSupmuscleWastingVal: "muscleWasting",
  ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal:
    "historyofsevereweightloss",
  ros_gastroSubDiagnosescachexiaSupabdominalPainVal: "abdominalpain",
  ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescachexiaSupotherVal: "other",
  ros_gastroSubDiagnosesdesceliacDiseaseActiveVal: "active",
  ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreOther:
    "otherTreatment",
  ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreGluten:
    "glutenFreeDiet",
  ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal: "weightLoss",
  ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal: "anemia",
  ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal:
    "changeinbowelmovements",
  ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal: "osteoporosis",
  ros_gastroSubDiagnosesceliacDiseaseSupDietVal: "diet",
  ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesceliacDiseaseSupotherVal: "other",
  ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal:
    "hospitalization",
  ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal:
    "physicalFindings",
  ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal:
    "nauseandvomiting",
  ros_gastroSubDiagnosesbowelObstructionSupotherVal: "other",
  ros_gastroSubDiagnosesdescirrhosisActiveVal: "active",
  ros_gastroSubDiagnosesdescirrhosisHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOther:
    "otherTreatment",
  ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreno: "noTreatment",
  ros_gastroSubDiagnosesdescirrhosisActiveSubSelectalcoholVal: "alcoholRelated",
  ros_gastroSubDiagnosesdescirrhosisActiveSubSelectascitesVal: "ascites",
  ros_gastroSubDiagnosesdescirrhosisActiveSubSelectendStageVal:
    "endStageLiverDisease",
  ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherVal: "other",
  ros_gastroSubDiagnosesdescirrhosisActiveSubSelectNoneVal: "none",
  ros_gastroSubDiagnosescirrhosisSupsymptomsVal: "symptoms",
  ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal: "physicalFindings",
  ros_gastroSubDiagnosescirrhosisSupLabStudiesVal: "labStudies",
  ros_gastroSubDiagnosescirrhosisSupMriVal: "mri",
  ros_gastroSubDiagnosescirrhosisSupBoipsyVal: "biopsy",
  ros_gastroSubDiagnosescirrhosisSupabdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescirrhosisSupOtherVal: "other",
  ros_gastroSubDiagnosescirrhosisEndStageAscites: "ascites",
  ros_gastroSubDiagnosescirrhosisEndStageVarices: "varices",
  ros_gastroSubDiagnosescirrhosisEndStageEncephalopathy: "encephalopathy",
  ros_gastroSubDiagnosescirrhosisEndStageHepatorenalSyndrome:
    "hepatorenalSyndrome",
  ros_gastroSubDiagnosescirrhosisEndStageOther: "other",
  ros_gastroSubDiagnosescolonPolypsDesActiveVal: "active",
  ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdescolonPolypsActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdescolonPolypsActiveSubTreno: "noTreatment",
  ros_gastroSubDiagnosescolonSuphistoryVal: "history",
  ros_gastroSubDiagnosescolonSupsymptomsVal: "symptoms",
  ros_gastroSubDiagnosescolonSupPhysicalfindingsVal: "physicalFindings",
  ros_gastroSubDiagnosescolonSupmedicationsVal: "medications",
  ros_gastroSubDiagnosescolonSupTestResultsVal: "testResults",
  ros_gastroSubDiagnosescolonSupImageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosescolonSupBiopsyVal: "biopsy",
  ros_gastroSubDiagnosescolonDmeVal: "dme",
  ros_gastroSubDiagnosescolonAbdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosescolonNausaVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescolonOtherVal: "other",
  ros_gastroSubDiagnosescolonCrDesBengirVal: "bengir",
  ros_gastroSubDiagnosescolonCrDesCarcinomaVal: "carcinomainsitu",
  ros_gastroSubDiagnosescolonCrDesPolyposisVal: "fmilialpolyposis",
  ros_gastroSubDiagnosesdesdiverticulitisActiveVal: "active",
  ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectabscessVal: "abscess",
  ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectperforationVal:
    "perforation",
  ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelecthighFiberDietVal:
    "bleeding",
  ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal: "colonoscopy",
  ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal: "symptoms",
  ros_gastroSubDiagnosesdiverticulitisSupDietVal: "diet",
  ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal: "abdominalPain",
  ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesdiverticulitisSupotherVal: "other",
  ros_gastroSubDiagnosesdesgallBladderDiseaseActiveVal: "active",
  ros_gastroSubDiagnosesdesgallBladderDiseaseHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesgallBladderActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesgallBladderActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdesgallBladderActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesgallBladderSupSymptoms: "symptoms",
  ros_gastroSubDiagnosesgallBladderSupPassingOfStones: "passingofstones",
  ros_gastroSubDiagnosesgallBladderSupErcp: "ercp",
  ros_gastroSubDiagnosesgallBladderSupHidaScan: "hidascan",
  ros_gastroSubDiagnosesgallBladderSupMri: "mri",
  ros_gastroSubDiagnosesgallBladderSupTreatmentHistory: "treatmentHistory",
  ros_gastroSubDiagnosesgallBladderSupAbdominalPain: "abdominalPain",
  ros_gastroSubDiagnosesgallBladderSupNauseaAndVomiting: "nauseaandvomiting",
  ros_gastroSubDiagnosesgallBladderSupOther: "other",
  ros_gastroSubDiagnosesgastroparesisDescribeActive: "active",
  ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesdesgastroparesisActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdesgastroparesisActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesgastroparesisSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesgastroparesisSupportedByGastricEmptyingSets:
    "gastricemptyingtest",
  ros_gastroSubDiagnosesgastroparesisSupportedByMedications: "medications",
  ros_gastroSubDiagnosesgastroparesisSupportedByHistory: "history",
  ros_gastroSubDiagnosesgastroparesisSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesgastroparesisSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesgastroparesisSupportedByOther: "other",
  ros_gastroSubDiagnosesgerdDescribeActive: "active",
  ros_gastroSubDiagnosesgerdDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesdesgerdActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesgerdActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdesgerdActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesgerdSupportedByHeartburn: "heartburn",
  ros_gastroSubDiagnosesgerdSupportedByRegurgitation: "regurgitation",
  ros_gastroSubDiagnosesgerdSupportedByMedications: "medications",
  ros_gastroSubDiagnosesgerdSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesgerdSupportedByNauseaAndVomiting: "nauseaandvomiting",
  ros_gastroSubDiagnosesgerdSupportedByOther: "other",
  ros_gastroSubDiagnosesglBleedDescribeActive: "active",
  ros_gastroSubDiagnosesglBleedDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesdesglBleedActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesglBleedActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdesglBleedActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesglBleedSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesglBleedSupportedByBloodInStool: "bloodinstool",
  ros_gastroSubDiagnosesglBleedSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesglBleedSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesglBleedSupportedByOther: "other",
  ros_gastroSubDiagnoseshepatitisDescribeActive: "active",
  ros_gastroSubDiagnoseshepatitisDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesdeshepatitisActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdeshepatitisActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdeshepatitisActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnoseshepatitisSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnoseshepatitisSupportedByPhysicalFindings:
    "physicalfindings",
  ros_gastroSubDiagnoseshepatitisSupportedByLabStudies: "labstudies",
  ros_gastroSubDiagnoseshepatitisSupportedByJuandice: "jaundice",
  ros_gastroSubDiagnoseshepatitisSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnoseshepatitisSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnoseshepatitisSupportedByOther: "other",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeActive: "active",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreMed:
    "medication",
  ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOther:
    "otherTreatment",
  ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreNo:
    "noTreatment",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeUlcerativeColitis:
    "ulcerative",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeCrohnsDisease:
    "crohnsdisease",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeOther: "other",
  ros_gastroSubDiagnosespancreatitisDescribeActive: "active",
  ros_gastroSubDiagnosespancreatitisDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesdespancreatitisActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdespancreatitisActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdespancreatitisActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosespancreatitisRecurrentEpisodesYes: "yes",
  ros_gastroSubDiagnosespancreatitisRecurrentEpisodesNo: "no",
  ros_gastroSubDiagnosespancreatitisSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosespancreatitisSupportedByLabStudies: "labstudies",
  ros_gastroSubDiagnosespancreatitisSupportedByErcp: "ercp",
  ros_gastroSubDiagnosespancreatitisSupportedByAlcoholism: "alcoholism",
  ros_gastroSubDiagnosespancreatitisSupportedByUseOfCertainMedications:
    "useofcertainmedications",
  ros_gastroSubDiagnosespancreatitisSupportedByMri: "mri",
  ros_gastroSubDiagnosespancreatitisSupportedByFamilyHistory: "familyhistory",
  ros_gastroSubDiagnosespancreatitisSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosespancreatitisSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosespancreatitisSupportedByOther: "other",
  ros_gastroSubDiagnosesulcerDiseaseDescribeActive: "active",
  ros_gastroSubDiagnosesulcerDiseaseDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreMed: "medication",
  ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOther: "otherTreatment",
  ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreNo: "noTreatment",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByEndoscopicFindings:
    "endoscopicfindings",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByCulture: "culture",
  ros_gastroSubDiagnosesulcerDiseaseSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByMedications: "medications",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByOther: "other",
  ros_gastroSubDiagnosesulcerDiseaseEsophageal: "esophageal",
  ros_gastroSubDiagnosesulcerDiseaseGastric: "gastric",
  ros_gastroSubDiagnosesulcerDiseaseDuodenal: "duodenal",
  ros_gastroSubDiagnosesulcerDiseaseOther: "describe",
  ros_gastroSubDiagnosesotherDescribeActive: "active",
  ros_gastroSubDiagnosesotherDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesotherDescribeRuleOut: "ruleOut",
  ros_gastroSubDiagnosesotherSupportedByHistory: "history",
  ros_gastroSubDiagnosesotherSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesotherSupportedByPhysicalFindings: "physicalfindings",
  ros_gastroSubDiagnosesotherSupportedByMedications: "medications",
  ros_gastroSubDiagnosesotherSupportedByTestResults: "testresults",
  ros_gastroSubDiagnosesotherSupportedByImageStudies: "imagestudies",
  ros_gastroSubDiagnosesotherSupportedByBiopsy: "biopsy",
  ros_gastroSubDiagnosesotherSupportedByDme: "dme",
  ros_gastroSubDiagnosesotherSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesotherSupportedByNauseaAndVomiting: "nauseaandvomiting",
  ros_gastroSubDiagnosesotherSupportedByOther: "other",
  ros_gastroSubSymptomsHeart: "heartburn/dyspepsia",
  ros_gastroSubSymptomsNausea: "nausea/vomiting",
  ros_gastroSubSymptomsBlood: "bloodinStool",
  ros_gastroSubSymptomsAbdominal: "abdominalPain",
  ros_gastroSubSymptomsOther: "other",
  ros_gastroSubSymptomsNone: "none",
  ros_gastroSubDiagnoseshepatitisTypeA: "a",
  ros_gastroSubDiagnoseshepatitisTypeB: "b",
  ros_gastroSubDiagnoseshepatitisTypeC: "c",
  ros_gastroSubDiagnoseshepatitisAcute: "acute",
  ros_gastroSubDiagnoseshepatitisChronic: "chronic",
};

const ros_OptionsTreeWrappers_srilatha2024 = [
  [
    "ros_cardioSubDiagnoses",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmVal",
      "ros_cardioSubDiagnosesaneurysmVal",
      "ros_cardioSubDiagnosesanginaVal",
      "ros_cardioSubDiagnosesatrialFibrillationVal",
      "ros_cardioSubDiagnosesatrialFlutterVal",
      "ros_cardioSubDiagnosescardioRespiratoryShockVal",
      "ros_cardioSubDiagnosescardiomyopathyVal",
      "ros_cardioSubDiagnosescongestiveHeartFailureVal",
      "ros_cardioSubDiagnosesdeepVeinThrombosisVal",
      "ros_cardioSubDiagnoseshyperlipidemiaVal",
      "ros_cardioSubDiagnoseshypertensionVal",
      "ros_cardioSubDiagnosesischemicHeartDiseaseVal",
      "ros_cardioSubDiagnosesmyocardialInfarctionVal",
      "ros_cardioSubDiagnosesperipheralVascularDiseaseVal",
      "ros_cardioSubDiagnosespulmonaryHypertensionVal",
      "ros_cardioSubDiagnosesvalvularDiseaseVal",
      "ros_cardioSubDiagnosesHeartTransplantVal",
      "ros_cardioSubDiagnosesotherVal",
    ],
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal",
    ],
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSup",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupECG",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSuprateControllingDrug",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupanticoagulation",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupelectrophysiology",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchestpain",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSuplightHeadedness",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedPacemaker",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedDefibrillator",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupshortnessOfBreath",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupwheezing",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchronicCough",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes",

    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia",
    ],
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOne",

    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardiaSubSick",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardiaSubUnknown",
    ],
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwo",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardiaSubSupraventricular",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardiaSubUnknown",
    ],
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswer",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerMedication",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatment",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswernoTreatment",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerimplantedDefibrillator",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerspacemaker",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDes",
    [
      "ros_cardioSubDiagnosesAneurysmDesActive",
      "ros_cardioSubDiagnosesAneurysmDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSup",

    [
      "ros_cardioSubDiagnosesAneurysmSupPhyExam",
      "ros_cardioSubDiagnosesAneurysmSupultra",
      "ros_cardioSubDiagnosesAneurysmSupImage",
      "ros_cardioSubDiagnosesAneurysmSupchestpain",
      "ros_cardioSubDiagnosesAneurysmSuplightHeadedness",
      "ros_cardioSubDiagnosesAneurysmSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAneurysmSupwheezing",
      "ros_cardioSubDiagnosesAneurysmSupchronicCough",
      "ros_cardioSubDiagnosesAneurysmSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDes",
    [
      "ros_cardioSubDiagnosesAneurysmCrDesAbdominal",
      "ros_cardioSubDiagnosesAneurysmCrDesThoracic",
      "ros_cardioSubDiagnosesAneurysmCrDesPeripheral",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAneurysmType",
    [
      "ros_cardioSubDiagnosesAneurysmTyperuptured",
      "ros_cardioSubDiagnosesAneurysmTypeUnruptured",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAneurysmTreatment",
    [
      "ros_cardioSubDiagnosesAneurysmTreatMedication",
      "ros_cardioSubDiagnosesAneurysmTreatOtherTreatment",
      "ros_cardioSubDiagnosesAneurysmTreatNoTreatment",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDes",
    [
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesStable",
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesUnstable",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatment",
    [
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentmed",
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentother",
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentno",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAnginaDes",
    [
      "ros_cardioSubDiagnosesAnginaDesActive",
      "ros_cardioSubDiagnosesAnginaDesHistoryOf",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAnginaSup",
    [
      "ros_cardioSubDiagnosesAnginaSupMedi",
      "ros_cardioSubDiagnosesAnginaSupHitory",
      "ros_cardioSubDiagnosesAnginaSupstressTest",
      "ros_cardioSubDiagnosesAnginaSupchestpain",
      "ros_cardioSubDiagnosesAnginaSuplightHeadedness",
      "ros_cardioSubDiagnosesAnginaSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAnginaSupwheezing",
      "ros_cardioSubDiagnosesAnginaSupchronicCough",
      "ros_cardioSubDiagnosesAnginaSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAnginaCrDes",
    [
      "ros_cardioSubDiagnosesAnginaCrDesStable",
      "ros_cardioSubDiagnosesAnginaCrDesUnstable",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationDes",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationDesActive",
      "ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerType",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypePar",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeChro",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeUnk",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatMed",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatNoTreat",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTr",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatPace",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatImp",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatient",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientAnti",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientRate",
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOther",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFlutterDes",
    [
      "ros_cardioSubDiagnosesAtrialFlutterDesActive",
      "ros_cardioSubDiagnosesAtrialFlutterDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreat",

    [
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatmed",
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatother",
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatno",
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatImplant",
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatPace",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatient",
    [
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientAntico",
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientRate",
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOther",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationType",

    [
      "ros_cardioSubDiagnosesAtrialFibrillationTypeParoxysmal",
      "ros_cardioSubDiagnosesAtrialFibrillationTypeChronic",
      "ros_cardioSubDiagnosesAtrialFibrillationTypeUnknown",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSup",

    [
      "ros_cardioSubDiagnosesAtrialFibrillationSupMedi",
      "ros_cardioSubDiagnosesAtrialFibrillationSupECG",
      "ros_cardioSubDiagnosesAtrialFibrillationSupSymptoms",
      "ros_cardioSubDiagnosesAtrialFibrillationSupHistory",
      "ros_cardioSubDiagnosesAtrialFibrillationSupelectricCardio",
      "ros_cardioSubDiagnosesAtrialFibrillationSupchestpain",
      "ros_cardioSubDiagnosesAtrialFibrillationSuplightHeadedness",
      "ros_cardioSubDiagnosesAtrialFibrillationSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAtrialFibrillationSupwheezing",
      "ros_cardioSubDiagnosesAtrialFibrillationSupchronicCough",
      "ros_cardioSubDiagnosesAtrialFibrillationSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingAnti",
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingRate",
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOther",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDes",
    [
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesActive",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreat",
    [
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatMed",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatOther",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatNoOther",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatPace",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatImplant",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSup",
    [
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupHistory",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupImplant",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupChestPain",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSuplightHeadedness",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupshortnessOfBreath",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupwheezing",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupchronicCough",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyDes",
    [
      "ros_cardioSubDiagnosesCardiomyopathyDesActive",
      "ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyType",
    [
      "ros_cardioSubDiagnosesCardiomyopathyTypeDilated",
      "ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic",
      "ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive",
      "ros_cardioSubDiagnosesCardiomyopathyTypeUnknown",
    ],
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreat",
    [
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatisMed",
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatNotreat",
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherTr",
    ],
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySup",
    [
      "ros_cardioSubDiagnosesCardiomyopathySupEcho",
      "ros_cardioSubDiagnosesCardiomyopathySupCardiacCath",
      "ros_cardioSubDiagnosesCardiomyopathySupChestPain",
      "ros_cardioSubDiagnosesCardiomyopathySupLightHeadedness",
      "ros_cardioSubDiagnosesCardiomyopathySupshortnessOfBreath",
      "ros_cardioSubDiagnosesCardiomyopathySupwheezing",
      "ros_cardioSubDiagnosesCardiomyopathySupchronicCough",
      "ros_cardioSubDiagnosesCardiomyopathySupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDes",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActive",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSup",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupEjection",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupCardio",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupOrtho",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupDOE",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupPnd",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupS3",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupMedications",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupPeripheralEdema",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupChestPain",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupLightHeadedness",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupshortnessOfBreath",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupwheezing",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupchronicCough",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecify",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic",
    ],
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDes",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesDia",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesSys",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesUnkn",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTre",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreMed",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreNoTre",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOther",
    ],
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDes",

    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesDias",
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesSys",
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesUnknown",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDes",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesActive",
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAnti",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiProphyl",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiThera",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLoc",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocrightLeg",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocleftLeg",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocBilateralUpp",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocBitlaterLow",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocRightArm",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocleftArm",
    ],
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisTreat",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedication",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatOtherTreatment",
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatNoTreatment",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaDes",

    [
      "ros_cardioSubDiagnosesHyperlipidemiaDesActive",
      "ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre",
    [
      "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreMed",
      "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreNoTre",
      "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOther",
      "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreDiet",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDes",
    [
      "ros_cardioSubDiagnosesHypertensionDesActive",
      "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHypertensionDesActiveSubTre",
    [
      "ros_cardioSubDiagnosesHypertensionDesActiveSubTreMed",
      "ros_cardioSubDiagnosesHypertensionDesActiveSubTreNoTre",
      "ros_cardioSubDiagnosesHypertensionDesActiveSubTreOther",
      "ros_cardioSubDiagnosesHypertensionDesActiveSubTreDiet",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDes",
    [
      "ros_cardioSubDiagnosesHypertensionDesActive",
      "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHypertensionSymp",
    [
      "ros_cardioSubDiagnosesHypertensionSympHeadache",
      "ros_cardioSubDiagnosesHypertensionSymplightHeadedness",
      "ros_cardioSubDiagnosesHypertensionSympBlurredvison",
      "ros_cardioSubDiagnosesHypertensionSympother",
      "ros_cardioSubDiagnosesHypertensionSympNone",
    ],
  ],

  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDes",
    [
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf",
    ],
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTre",
    [
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreMed",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreNoTre",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOther",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreCoronorySent",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreCabg",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreNitrate",
    ],
  ],

  [
    "ros_cardioSubDiagnosesMyocardialDes",
    [
      "ros_cardioSubDiagnosesMyocardialDesActive",
      "ros_cardioSubDiagnosesMyocardialDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesMyocardialIspateint",
    [
      "ros_cardioSubDiagnosesMyocardialIspateintAspirin",
      "ros_cardioSubDiagnosesMyocardialIspateintPlavix",
      "ros_cardioSubDiagnosesMyocardialIspateintNitrate",
      "ros_cardioSubDiagnosesMyocardialIspateintOther",
    ],
  ],

  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDes",
    [
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf",
    ],
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTre",
    [
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreMed",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreNoTre",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOther",
    ],
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSymp",
    [
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympClau",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympDimi",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympExt",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympUlc",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympGangere",
    ],
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDes",
    [
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActive",
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf",
    ],
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDes",
    [
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesPri",
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesSec",
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesUnk",
    ],
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTre",
    [
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreMed",
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreNoTre",
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOther",
    ],
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDes",
    [
      "ros_cardioSubDiagnosesvalvulardiseaseDesActive",
      "ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf",
    ],
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDes",
    [
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitral",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesAortic",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspid",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryInsuff",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryStenosis",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitralInsuff",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesAorticInsuff",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspidInsuff",
    ],
  ],

  [
    "ros_cardioSubDiagnosesOtherDes",
    [
      "ros_cardioSubDiagnosesOtherDesActive",
      "ros_cardioSubDiagnosesOtherDesHistoryOf",
    ],
  ],
  [
    "ros_cardioSubDiagnosesSymp",
    [
      "ros_cardioSubDiagnosesSympOther",
      "ros_cardioSubDiagnosesSympNone",
      "ros_cardioSubDiagnosesSympdizziness",
      "ros_cardioSubDiagnosesSympUnEplained",
      "ros_cardioSubDiagnosesSympExtremityDiscoloration",
      "ros_cardioSubDiagnosesSympClaudication",
      "ros_cardioSubDiagnosesSympHeartPalpitaions",
      "ros_cardioSubDiagnosesSympShortnessOfBreath",
      "ros_cardioSubDiagnosesSympPeripheralEdema",
      "ros_cardioSubDiagnosesSympChestPain",
    ],
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseTreatment",
    [
      "ros_cardioSubDiagnosesvalvulardiseaseTreatmentMedication",
      "ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatment",
      "ros_cardioSubDiagnosesvalvulardiseaseTreatmentNoTreatment",
    ],
  ],
  [
    "ros_diagnosisOfCancerSubDesc",
    [
      "ros_diagnosisOfCancerSubDescActiveVal",
      "ros_diagnosisOfCancerSubDescHistoryOfVal",
    ],
  ],
  [
    "ros_diagnosisOfCancerSubSup",
    [
      "ros_diagnosisOfCancerSubSupPhyFinVal",
      "ros_diagnosisOfCancerSubSuphospVal",
      "ros_diagnosisOfCancerSubSuptreatVal",
      "ros_diagnosisOfCancerSubSuplabTestsVal",
      "ros_diagnosisOfCancerSubSupimagVal",
      "ros_diagnosisOfCancerSubSupSurVal",
      "ros_diagnosisOfCancerSubSupBioVal",
      "ros_diagnosisOfCancerSubSupOtherVal",
    ],
  ],
  [
    "ros_diagnosisOfCancerSubType",
    [
      "ros_diagnosisOfCancerSubTypeBrainVal",
      "ros_diagnosisOfCancerSubTypeHeadVal",
      "ros_diagnosisOfCancerSubTypeNeckVal",
      "ros_diagnosisOfCancerSubTypeBreastVal",
      "ros_diagnosisOfCancerSubTypeLungVal",
      "ros_diagnosisOfCancerSubTypeEsophagusVal",
      "ros_diagnosisOfCancerSubTypeStomachVal",
      "ros_diagnosisOfCancerSubTypeLiverVal",
      "ros_diagnosisOfCancerSubTypePancreasVal",
      "ros_diagnosisOfCancerSubTypeColonVal",
      "ros_diagnosisOfCancerSubTypeRectumVal",
      "ros_diagnosisOfCancerSubTypeKidyneyVal",
      "ros_diagnosisOfCancerSubTypeBladderVal",
      "ros_diagnosisOfCancerSubTypeOvariesVal",
      "ros_diagnosisOfCancerSubTypeUterusVal",
      "ros_diagnosisOfCancerSubTypeProstateVal",
      "ros_diagnosisOfCancerSubTypeBoneVal",
      "ros_diagnosisOfCancerSubTypeBloodVal",
      "ros_diagnosisOfCancerSubTypeLymphNodesVal",
      "ros_diagnosisOfCancerSubTypeSkinVal",
      "ros_diagnosisOfCancerSubTypeOtherVal",
    ],
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreat",
    [
      "ros_diagnosisOfCancerSubActiveTreatChemoVal",
      "ros_diagnosisOfCancerSubActiveTreatRadiaVal",
      "ros_diagnosisOfCancerSubActiveTreatStemVal",
      "ros_diagnosisOfCancerSubActiveTreatBoneVal",
      "ros_diagnosisOfCancerSubActiveTreatSurgVal",
      "ros_diagnosisOfCancerSubActiveTreatImmuVal",
      "ros_diagnosisOfCancerSubActiveTreatOtherVal",
    ],
  ],

  [
    "ros_diagnosisOfCancerSubSideEff",
    [
      "ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubhistoryOf",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymp",
    [
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympincreasedThirst",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympshakiness",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympsweating",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympblurredVision",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympweightGain",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympfatigue",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymphairChanges",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympweightLoss",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympNone",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSymp",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympincreasedThirst",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympshakiness",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympsweating",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympblurredVision",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympweightGain",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympfatigue",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSymphairChanges",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympweightLoss",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympNone",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTre",
    [
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreMed",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOther",
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreNo",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubhistoryOf",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubType",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeOne",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeonePointFive",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypetwo",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeGe",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreMed",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOther",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreNo",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreExer",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreDiet",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelect",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOral",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectInsulin",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOther",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticCataracts",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticMacularEdema",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompldiabeticRetinopathy",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplPeripheralNeuropathy",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplperipheralVascular",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplautonomic",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplckdstDiabetes",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplotherComplication",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplnone",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarcts",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsRightEye",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsLeftEye",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsBoth",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribe",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeActive",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeHistoryOf",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreMed",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOther",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsSubTreNo",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdema",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaLeft",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaRight",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaBoth",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribe",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeActive",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeHistoryOf",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre",
    [
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreMed",
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreLaser",
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreEye",
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOther",
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreNo",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphtha",
    [
      "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaOccas",
      "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaOnce",
      "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaTwice",
      "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthagreTwice",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubActive",
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubHistory",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSub",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubLeft",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubRight",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubBilateral",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSub",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubPro",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubNonPro",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubUnknown",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatMed",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatLaser",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatRetinal",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOther",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatNo",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSub",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubOccas",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubOnce",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubTwice",
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubgreTwice",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubneuroSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubneuroSubdescribeSubActive",
      "ros_endocrineIsEndocrineSubneuroSubdescribeSubHistory",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubSymp",
    [
      "ros_endocrineIsEndocrineSubNeuroSubSympNumb",
      "ros_endocrineIsEndocrineSubNeuroSubSympPain",
      "ros_endocrineIsEndocrineSubNeuroSubSympBurning",
      "ros_endocrineIsEndocrineSubNeuroSubSympDiseased",
      "ros_endocrineIsEndocrineSubNeuroSubSympNone",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocU",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocL",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocU",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocL",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocU",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocL",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc",
    [
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocU",
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocL",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubNeuroSubTreat",
    [
      "ros_endocrineIsEndocrineSubNeuroSubTreatMed",
      "ros_endocrineIsEndocrineSubNeuroSubTreatBySubother",
      "ros_endocrineIsEndocrineSubNeuroSubTreatBySubno",
    ],
  ],

  [
    "ros_endocrineIsEndocrineNeuroSubPatientOften",
    [
      "ros_endocrineIsEndocrineNeuroSubPatientOftenonceAYear",
      "ros_endocrineIsEndocrineNeuroSubPatientOftentwiceAYear",
      "ros_endocrineIsEndocrineNeuroSubPatientOftenquarterly",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeActive",
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeHistoryOf",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubVascularSubPhysical",
    [
      "ros_endocrineIsEndocrineSubVascularSubPhysicalSkin",
      "ros_endocrineIsEndocrineSubVascularSubPhysicalFoot",
      "ros_endocrineIsEndocrineSubVascularSubPhysicalIntermitten",
      "ros_endocrineIsEndocrineSubVascularSubPhysicalOther",
      "ros_endocrineIsEndocrineSubVascularSubPhysicalNone",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubVascularSubTreat",
    [
      "ros_endocrineIsEndocrineSubVascularSubTreatMed",
      "ros_endocrineIsEndocrineSubVascularSubTreatOther",
      "ros_endocrineIsEndocrineSubVascularSubTreatNo",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOften",
    [
      "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenOne",
      "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenTwice",
      "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenQuarterly",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubautonomicSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubautonomicSubdescribeSubActive",
      "ros_endocrineIsEndocrineSubautonomicSubdescribeSubHistory",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubAutonomicSubTreat",
    [
      "ros_endocrineIsEndocrineSubAutonomicSubTreatMed",
      "ros_endocrineIsEndocrineSubAutonomicSubTreatOther",
      "ros_endocrineIsEndocrineSubAutonomicSubTreatNo",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubActive",
      "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubHistoryOf",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubStage",
    [
      "ros_endocrineIsEndocrineSubchronicKidneySubStage1",
      "ros_endocrineIsEndocrineSubchronicKidneySubStage2",
      "ros_endocrineIsEndocrineSubchronicKidneySubStage3a",
      "ros_endocrineIsEndocrineSubchronicKidneySubStage3b",
      "ros_endocrineIsEndocrineSubchronicKidneySubStage4",
      "ros_endocrineIsEndocrineSubchronicKidneySubStage5",
      "ros_endocrineIsEndocrineSubchronicKidneySubUnknown",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubchronicKidneySubTreat",
    [
      "ros_endocrineIsEndocrineSubchronicKidneySubTreatMed",
      "ros_endocrineIsEndocrineSubchronicKidneySubTreatOther",
      "ros_endocrineIsEndocrineSubchronicKidneySubTreatNo",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubhistoryOf",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEti",
    [
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiKidney",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiVitamin",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiCeliac",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtimalabs",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtibariatric",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiFractures",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiOther",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiUnknown",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymp",
    [
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympincreasedThirst",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympshakiness",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympsweating",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympblurredVision",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympweightGain",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympfatigue",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymphairChanges",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympweightLoss",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympNone",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTre",
    [
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreMed",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOther",
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreNo",
    ],
  ],

  [
    "endocrineSubhypertensionDiabetesSubdescribeSub",
    [
      "endocrineSubhypertensionDiabetesSubdescribeSubactive",
      "endocrineSubhypertensionDiabetesSubdescribeSubhistoryOf",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubhistoryOf",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymp",
    [
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymprapidHeartBeat",
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympnervousness",
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympweightLoss",
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympheatIntolerance",
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympnone",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre",
    [
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreMed",
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOther",
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreNo",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubhistoryOf",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubTreatment",
    [
      "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentMed",
      "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOther",
      "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentNo",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymp",
    [
      "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympweightGain",
      "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympfatigue",
      "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymphairLoss",
      "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympNone",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribe",
    [
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeActive",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeHistoryOf",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSymp",
    [
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympFrac",
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympFatigue",
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympOther",
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympNone",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre",
    [
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreMed",
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOther",
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreNo",
    ],
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribe",
    [
      "ros_endocrineIsEndocrineDiagnoseotherDescribeActive",
      "ros_endocrineIsEndocrineDiagnoseotherDescribeHistoryOf",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSup",
    [
      "ros_endocrineIsEndocrineDiagnoseotherSuphistory",
      "ros_endocrineIsEndocrineDiagnoseotherSupMedication",
      "ros_endocrineIsEndocrineDiagnoseotherSupSymptoms",
      "ros_endocrineIsEndocrineDiagnoseotherSupphysicalFindings",
      "ros_endocrineIsEndocrineDiagnoseotherSuptestResults",
      "ros_endocrineIsEndocrineDiagnoseotherSupimageStudies",
      "ros_endocrineIsEndocrineDiagnoseotherSupbiopsy",
      "ros_endocrineIsEndocrineDiagnoseotherSupdme",
      "ros_endocrineIsEndocrineDiagnoseotherSupOther",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnose",
    [
      "ros_endocrineIsEndocrineDiagnosechronicKidneyVal",
      "ros_endocrineIsEndocrineDiagnosecoronaryArteryVal",
      "ros_endocrineIsEndocrineDiagnosecushingsDiseaseVal",
      "ros_endocrineIsEndocrineDiagnosediabetesVal",
      "ros_endocrineIsEndocrineDiagnosediabeticRetinopathyVal",
      "ros_endocrineIsEndocrineDiagnosesecondaryHyperparathyroidismVal",
      "ros_endocrineIsEndocrineDiagnosehypertensionDiabetesVal",
      "ros_endocrineIsEndocrineDiagnosehyperthyroidismVal",
      "ros_endocrineIsEndocrineDiagnosehypothyroidismVal",
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyVal",
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularVal",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismVal",
      "ros_endocrineIsEndocrineDiagnoseotherVal",
    ],
  ],

  [
    "ros_genitoSubDiagnoses",
    [
      "ros_genitoSubDiagnosesacuteRenalFailureVal",
      "ros_genitoSubDiagnosesbphVal",
      "ros_genitoSubDiagnoseschronicKidneyDiseaseVal",
      "ros_genitoSubDiagnosesesrdVal",
      "ros_genitoSubDiagnoseserectileDysfunctionVal",
      "ros_genitoSubDiagnosesfrequentUtiVal",
      "ros_genitoSubDiagnosesgynecologicalVal",
      "ros_genitoSubDiagnoseskidneyStonesVal",
      "ros_genitoSubDiagnosesnephritisOrNephrosisVal",
      "ros_genitoSubDiagnosesurinaryIncontinenceVal",
      "ros_genitoSubDiagnosesotherVal",
    ],
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredes",
    [
      "ros_genitoSubDiagnosesacuteRenalFailuredesactive",
      "ros_genitoSubDiagnosesacuteRenalFailuredeshistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnosesdesacuteActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesacuteActiveSubTreMed",
      "ros_genitoSubDiagnosesdesacuteActiveSubTreOther",
      "ros_genitoSubDiagnosesdesacuteActiveSubTreNo",
    ],
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureSup",
    [
      "ros_genitoSubDiagnosesacuteRenalFailureSupLab",
      "ros_genitoSubDiagnosesacuteRenalFailureSupCal",
      "ros_genitoSubDiagnosesacuteRenalFailureSupHos",
      "ros_genitoSubDiagnosesacuteRenalFailureSupOther",
    ],
  ],

  [
    "ros_genitoSubDiagnosesbphDescribe",
    [
      "ros_genitoSubDiagnosesbphDescribeActive",
      "ros_genitoSubDiagnosesbphDescribeHistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribe",
    [
      "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeActive",
      "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeHistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnosesdesbhpActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesbhpActiveSubTreMed",
      "ros_genitoSubDiagnosesdesbhpActiveSubTreOther",
      "ros_genitoSubDiagnosesdesbhpActiveSubTreNo",
    ],
  ],
  [
    "ros_genitoSubDiagnosesdeschronicActiveSubTre",
    [
      "ros_genitoSubDiagnosesdeschronicActiveSubTreMed",
      "ros_genitoSubDiagnosesdeschronicActiveSubTreOther",
      "ros_genitoSubDiagnosesdeschronicActiveSubTreNo",
    ],
  ],

  [
    "ros_genitoSubDiagnoseswhatStage",
    [
      "ros_genitoSubDiagnoseswhatStageStageOne",
      "ros_genitoSubDiagnoseswhatStageStageTwo",
      "ros_genitoSubDiagnoseswhatStageStageThreeA",
      "ros_genitoSubDiagnoseswhatStageStageThreeB",
      "ros_genitoSubDiagnoseswhatStageStageFour",
      "ros_genitoSubDiagnoseswhatStageStageFive",
      "ros_genitoSubDiagnoseswhatStageUnknown",
    ],
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribe",
    [
      "ros_genitoSubDiagnosesesrdDescribeActive",
      "ros_genitoSubDiagnosesesrdDescribeHistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisType",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis",
      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis",
    ],
  ],

  [
    "ros_genitoSubDiagnosesdesesrdActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesesrdActiveSubTreDia",
      "ros_genitoSubDiagnosesdesesrdActiveSubTreMed",
      "ros_genitoSubDiagnosesdesesrdActiveSubTreOther",
      "ros_genitoSubDiagnosesdesesrdActiveSubTreNo",
    ],
  ],

  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysis",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome",
      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter",
    ],
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribe",
    [
      "ros_genitoSubDiagnoseserectileDysfunctionDescribeActive",
      "ros_genitoSubDiagnoseserectileDysfunctionDescribeHistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnosesdeserectileActiveSubTre",
    [
      "ros_genitoSubDiagnosesdeserectileActiveSubTreMed",
      "ros_genitoSubDiagnosesdeserectileActiveSubTreOther",
      "ros_genitoSubDiagnosesdeserectileActiveSubTreNo",
    ],
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribe",
    [
      "ros_genitoSubDiagnosesfrequentUtiDescribeActive",
      "ros_genitoSubDiagnosesfrequentUtiDescribeHistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnosesdesfreqsActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesfreqsActiveSubTreMed",
      "ros_genitoSubDiagnosesdesfreqsActiveSubTreOther",
      "ros_genitoSubDiagnosesdesfreqsActiveSubTreNo",
    ],
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribe",
    [
      "ros_genitoSubDiagnosesgynecologicalDescribeActive",
      "ros_genitoSubDiagnosesgynecologicalDescribeHistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnosesdesgynecoActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesgynecoActiveSubTreMed",
      "ros_genitoSubDiagnosesdesgynecoActiveSubTreOther",
      "ros_genitoSubDiagnosesdesgynecoActiveSubTreNo",
    ],
  ],

  [
    "ros_genitoSubDiagnoseskidneyStonesDescribe",
    [
      "ros_genitoSubDiagnoseskidneyStonesDescribeActive",
      "ros_genitoSubDiagnoseskidneyStonesDescribeHistoryOf",
    ],
  ],
  [
    "ros_genitoSubDiagnosesdeskidneyActiveSubTre",
    [
      "ros_genitoSubDiagnosesdeskidneyActiveSubTreMed",
      "ros_genitoSubDiagnosesdeskidneyActiveSubTreOther",
      "ros_genitoSubDiagnosesdeskidneyActiveSubTreNo",
    ],
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesType",
    [
      "ros_genitoSubDiagnoseskidneyStonesTypeUrate",
      "ros_genitoSubDiagnoseskidneyStonesTypeCalciumOxalate",
      "ros_genitoSubDiagnoseskidneyStonesTypeMagnesium",
      "ros_genitoSubDiagnoseskidneyStonesTypeOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribe",
    [
      "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeActive",
      "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeHistoryOf",
    ],
  ],
  [
    "ros_genitoSubDiagnosesdesNephrosisActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesNephrosisActiveSubTreMed",
      "ros_genitoSubDiagnosesdesNephrosisActiveSubTreOther",
      "ros_genitoSubDiagnosesdesNephrosisActiveSubTreNo",
    ],
  ],

  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribe",
    [
      "ros_genitoSubDiagnosesurinaryIncontinenceDescribeActive",
      "ros_genitoSubDiagnosesurinaryIncontinenceDescribeHistoryOf",
    ],
  ],

  [
    "ros_genitoSubDiagnosesdesUrinaryActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesUrinaryActiveSubTreMed",
      "ros_genitoSubDiagnosesdesUrinaryActiveSubTreOther",
      "ros_genitoSubDiagnosesdesUrinaryActiveSubTreNo",
    ],
  ],

  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribe",
    [
      "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeDaily",
      "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeFewTimesAWeek",
      "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeLessThanOnceAWeek",
    ],
  ],

  [
    "ros_genitoStressSub",
    [
      "ros_genitoStressSubDribbling",
      "ros_genitoStressSubUrgency",
      "ros_genitoStressSubOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesotherDescribe",
    [
      "ros_genitoSubDiagnosesotherDescribeActive",
      "ros_genitoSubDiagnosesotherDescribeHistoryOf",
    ],
  ],
  [
    "ros_genitoSubDiagnosesdesOtherActiveSubTre",
    [
      "ros_genitoSubDiagnosesdesOtherActiveSubTreMed",
      "ros_genitoSubDiagnosesdesOtherActiveSubTreOther",
      "ros_genitoSubDiagnosesdesOtherActiveSubTreNo",
    ],
  ],

  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisType",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis",
      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis",
    ],
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocation",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome",
      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter",
    ],
  ],

  [
    "ros_genitoSubSymptoms",
    [
      "ros_genitoSubSymptomsUrinary",
      "ros_genitoSubSymptomsPain",
      "ros_genitoSubSymptomsAbdominal",
      "ros_genitoSubSymptomsOther",
      "ros_genitoSubSymptomsNone",
    ],
  ],

  [
    "ros_gastroSubDiagnoses",
    [
      "ros_gastroSubDiagnoses",
      "ros_gastroSubDiagnosesbowelObstructionVal",
      "ros_gastroSubDiagnosescachexiaVal",
      "ros_gastroSubDiagnosesceliacDiseaseVal",
      "ros_gastroSubDiagnosescirrhosisVal",
      "ros_gastroSubDiagnosescolonPolypsVal",
      "ros_gastroSubDiagnosesdiverticulitisVal",
      "ros_gastroSubDiagnosesgallBladderDiseaseVal",
      "ros_gastroSubDiagnosesgastroparesisVal",
      "ros_gastroSubDiagnosesgerdVal",
      "ros_gastroSubDiagnosesglBleedVal",
      "ros_gastroSubDiagnoseshepatitisVal",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal",
      "ros_gastroSubDiagnosespancreatitisVal",
      "ros_gastroSubDiagnosesulcerDiseaseVal",
      "ros_gastroSubDiagnosesliverTransVal",
      "ros_gastroSubDiagnosesotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesbowelObstruction",
    [
      "ros_gastroSubDiagnosesdesbowelObstructionActiveVal",
      "ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal",
      "ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionSup",
    [
      "ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal",
      "ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal",
      "ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal",
      "ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal",
      "ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesbowelObstructionSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescachexiaSup",
    [
      "ros_gastroSubDiagnosescachexiaSupalbuminVal",
      "ros_gastroSubDiagnoscachexiaSupmuscleWastingVal",
      "ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal",
      "ros_gastroSubDiagnosescachexiaSupabdominalPainVal",
      "ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosescachexiaSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescachexiades",
    [
      "ros_gastroSubDiagnosescachexiadesactive",
      "ros_gastroSubDiagnosescachexiadeshistoryOf",
      "ros_gastroSubDiagnosescachexiadesruleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdescachexiaActiveSubTre",
    [
      "ros_gastroSubDiagnosesdescachexiaActiveSubTreMed",
      "ros_gastroSubDiagnosesdescachexiaActiveSubTreOther",
      "ros_gastroSubDiagnosesdescachexiaActiveSubTreno",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDisease",
    [
      "ros_gastroSubDiagnosesdesceliacDiseaseActiveVal",
      "ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal",
      "ros_gastroSubDiagnosesdesceliacDiseaseRuleOutVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreMed",
      "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreOther",
      "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreNo",
      "ros_gastroSubDiagnosesdesceliacDiseaseSubAnswerActiveSubTreGluten",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreMed",
      "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOther",
      "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreNo",
    ],
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSup",
    [
      "ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal",
      "ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupDietVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdescirrhosis",
    [
      "ros_gastroSubDiagnosesdescirrhosisActiveVal",
      "ros_gastroSubDiagnosesdescirrhosisHistoryOfVal",
      "ros_gastroSubDiagnosesdescirrhosisRuleOutVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubTre",

    [
      "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreMed",
      "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOther",
      "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreno",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdescirrhosisActiveSubSelect",
    [
      "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectalcoholVal",
      "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectascitesVal",
      "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectendStageVal",
      "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherVal",
      "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectNoneVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSup",

    [
      "ros_gastroSubDiagnosescirrhosisSupsymptomsVal",
      "ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal",
      "ros_gastroSubDiagnosescirrhosisSupLabStudiesVal",
      "ros_gastroSubDiagnosescirrhosisSupMriVal",
      "ros_gastroSubDiagnosescirrhosisSupBoipsyVal",
      "ros_gastroSubDiagnosescirrhosisSupabdominalPainVal",
      "ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal",
      "ros_gastroSubDiagnosescirrhosisSupOtherVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDes",
    [
      "ros_gastroSubDiagnosescolonPolypsDesActiveVal",
      "ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal",
      "ros_gastroSubDiagnosescolonPolypsDesRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdescolonPolypsActiveSubTre",
    [
      "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreMed",
      "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOther",
      "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreno",
    ],
  ],

  [
    "ros_gastroSubDiagnosescolonSup",
    [
      "ros_gastroSubDiagnosescolonSuphistoryVal",
      "ros_gastroSubDiagnosescolonSupsymptomsVal",
      "ros_gastroSubDiagnosescolonSupPhysicalfindingsVal",
      "ros_gastroSubDiagnosescolonSupmedicationsVal",
      "ros_gastroSubDiagnosescolonSupTestResultsVal",
      "ros_gastroSubDiagnosescolonSupImageStudiesVal",
      "ros_gastroSubDiagnosescolonSupBiopsyVal",
      "ros_gastroSubDiagnosescolonDmeVal",
      "ros_gastroSubDiagnosescolonAbdominalPainVal",
      "ros_gastroSubDiagnosescolonNausaVomitingVal",
      "ros_gastroSubDiagnosescolonOtherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescolonCrDes",
    [
      "ros_gastroSubDiagnosescolonCrDesBengirVal",
      "ros_gastroSubDiagnosescolonCrDesCarcinomaVal",
      "ros_gastroSubDiagnosescolonCrDesPolyposisVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesdiverticulitis",
    [
      "ros_gastroSubDiagnosesdesdiverticulitisActiveVal",
      "ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal",
      "ros_gastroSubDiagnosesdesdiverticulitisRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreMed",
      "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOther",
      "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreNo",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelect",
    [
      "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectabscessVal",
      "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectperforationVal",
      "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelecthighFiberDietVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSup",
    [
      "ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal",
      "ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal",
      "ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal",
      "ros_gastroSubDiagnosesdiverticulitisSupDietVal",
      "ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal",
      "ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesdiverticulitisSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSup",

    [
      "ros_gastroSubDiagnosesgallBladderSupSymptoms",
      "ros_gastroSubDiagnosesgallBladderSupPassingOfStones",
      "ros_gastroSubDiagnosesgallBladderSupErcp",
      "ros_gastroSubDiagnosesgallBladderSupHidaScan",
      "ros_gastroSubDiagnosesgallBladderSupMri",
      "ros_gastroSubDiagnosesgallBladderSupTreatmentHistory",
      "ros_gastroSubDiagnosesgallBladderSupAbdominalPain",
      "ros_gastroSubDiagnosesgallBladderSupNauseaAndVomiting",
      "ros_gastroSubDiagnosesgallBladderSupOther",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesgallBladderDisease",
    [
      "ros_gastroSubDiagnosesdesgallBladderDiseaseActiveVal",
      "ros_gastroSubDiagnosesdesgallBladderDiseaseHistoryOfVal",
      "ros_gastroSubDiagnosesdesgallBladderDiseaseRuleOutVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesgallBladderActiveSubTreMed",
      "ros_gastroSubDiagnosesdesgallBladderActiveSubTreOther",
      "ros_gastroSubDiagnosesdesgallBladderActiveSubTreNo",
    ],
  ],
  [
    "ros_gastroSubDiagnosescirrhosisEndStageSubAnswer",
    [
      "ros_gastroSubDiagnosescirrhosisEndStageAscites",
      "ros_gastroSubDiagnosescirrhosisEndStageVarices",
      "ros_gastroSubDiagnosescirrhosisEndStageEncephalopathy",
      "ros_gastroSubDiagnosescirrhosisEndStageHepatorenalSyndrome",
      "ros_gastroSubDiagnosescirrhosisEndStageOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisDescribe",
    [
      "ros_gastroSubDiagnosesgastroparesisDescribeActive",
      "ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf",
      "ros_gastroSubDiagnosesgastroparesisDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreMed",
      "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOther",
      "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreNo",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupportedBy",
    [
      "ros_gastroSubDiagnosesgastroparesisSupportedBySymptoms",
      "ros_gastroSubDiagnosesgastroparesisSupportedByGastricEmptyingSets",
      "ros_gastroSubDiagnosesgastroparesisSupportedByMedications",
      "ros_gastroSubDiagnosesgastroparesisSupportedByHistory",
      "ros_gastroSubDiagnosesgastroparesisSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesgastroparesisSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesgastroparesisSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisDescribe",
    [
      "ros_gastroSubDiagnosesgastroparesisDescribeActive",
      "ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf",
      "ros_gastroSubDiagnosesgastroparesisDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupportedBy",
    [
      "ros_gastroSubDiagnosesgastroparesisSupportedBySymptoms",
      "ros_gastroSubDiagnosesgastroparesisSupportedByGastricEmptyingSets",
      "ros_gastroSubDiagnosesgastroparesisSupportedByMedications",
      "ros_gastroSubDiagnosesgastroparesisSupportedByHistory",
      "ros_gastroSubDiagnosesgastroparesisSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesgastroparesisSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesgastroparesisSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgerdDescribe",
    [
      "ros_gastroSubDiagnosesgerdDescribeActive",
      "ros_gastroSubDiagnosesgerdDescribeHistoryOf",
      "ros_gastroSubDiagnosesgerdDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesgerdActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesgerdActiveSubTreMed",
      "ros_gastroSubDiagnosesdesgerdActiveSubTreOther",
      "ros_gastroSubDiagnosesdesgerdActiveSubTreNo",
    ],
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedBy",
    [
      "ros_gastroSubDiagnosesgerdSupportedByHeartburn",
      "ros_gastroSubDiagnosesgerdSupportedByRegurgitation",
      "ros_gastroSubDiagnosesgerdSupportedByMedications",
      "ros_gastroSubDiagnosesgerdSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesgerdSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesgerdSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesglBleedDescribe",
    [
      "ros_gastroSubDiagnosesglBleedDescribeActive",
      "ros_gastroSubDiagnosesglBleedDescribeHistoryOf",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesglBleedActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesglBleedActiveSubTreMed",
      "ros_gastroSubDiagnosesdesglBleedActiveSubTreOther",
      "ros_gastroSubDiagnosesdesglBleedActiveSubTreNo",
    ],
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedBy",
    [
      "ros_gastroSubDiagnosesglBleedSupportedBySymptoms",
      "ros_gastroSubDiagnosesglBleedSupportedByBloodInStool",
      "ros_gastroSubDiagnosesglBleedSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesglBleedSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesglBleedSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDescribe",
    [
      "ros_gastroSubDiagnoseshepatitisDescribeActive",
      "ros_gastroSubDiagnoseshepatitisDescribeHistoryOf",
      "ros_gastroSubDiagnoseshepatitisDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveSubTre",
    [
      "ros_gastroSubDiagnosesdeshepatitisActiveSubTreMed",
      "ros_gastroSubDiagnosesdeshepatitisActiveSubTreOther",
      "ros_gastroSubDiagnosesdeshepatitisActiveSubTreNo",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisSupportedBy",
    [
      "ros_gastroSubDiagnoseshepatitisSupportedBySymptoms",
      "ros_gastroSubDiagnoseshepatitisSupportedByPhysicalFindings",
      "ros_gastroSubDiagnoseshepatitisSupportedByLabStudies",
      "ros_gastroSubDiagnoseshepatitisSupportedByJuandice",
      "ros_gastroSubDiagnoseshepatitisSupportedByAbdominalPain",
      "ros_gastroSubDiagnoseshepatitisSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnoseshepatitisSupportedByOther",
    ],
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribe",
    [
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeActive",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeHistoryOf",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeRuleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBy",
    [
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByColonoscopy",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBySymptoms",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByPhysicalFindings",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByMedications",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribe",
    [
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeUlcerativeColitis",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeCrohnsDisease",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreMed",
      "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOther",
      "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreNo",
    ],
  ],
  [
    "ros_gastroSubDiagnosespancreatitisDescribe",
    [
      "ros_gastroSubDiagnosespancreatitisDescribeActive",
      "ros_gastroSubDiagnosespancreatitisDescribeHistoryOf",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisActiveSubTre",
    [
      "ros_gastroSubDiagnosesdespancreatitisActiveSubTreMed",
      "ros_gastroSubDiagnosesdespancreatitisActiveSubTreOther",
      "ros_gastroSubDiagnosesdespancreatitisActiveSubTreNo",
    ],
  ],
  [
    "ros_gastroSubDiagnosespancreatitisRecurrentEpisodes",
    [
      "ros_gastroSubDiagnosespancreatitisRecurrentEpisodesYes",
      "ros_gastroSubDiagnosespancreatitisRecurrentEpisodesNo",
    ],
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribe",
    [
      "ros_gastroSubDiagnosesulcerDiseaseDescribeActive",
      "ros_gastroSubDiagnosesulcerDiseaseDescribeHistoryOf",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTre",
    [
      "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreMed",
      "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOther",
      "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreNo",
    ],
  ],

  [
    "ros_gastroSubDiagnosesotherDescribe",
    [
      "ros_gastroSubDiagnosesotherDescribeActive",
      "ros_gastroSubDiagnosesotherDescribeHistoryOf",
    ],
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedBy",
    [
      "ros_gastroSubDiagnosesotherSupportedByHistory",
      "ros_gastroSubDiagnosesotherSupportedBySymptoms",
      "ros_gastroSubDiagnosesotherSupportedByPhysicalFindings",
      "ros_gastroSubDiagnosesotherSupportedByMedications",
      "ros_gastroSubDiagnosesotherSupportedByTestResults",
      "ros_gastroSubDiagnosesotherSupportedByImageStudies",
      "ros_gastroSubDiagnosesotherSupportedByBiopsy",
      "ros_gastroSubDiagnosesotherSupportedByDme",
      "ros_gastroSubDiagnosesotherSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesotherSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesotherSupportedByOther",
    ],
  ],

  [
    "ros_gastroSubSymptoms",
    [
      "ros_gastroSubSymptomsHeart",
      "ros_gastroSubSymptomsNausea",
      "ros_gastroSubSymptomsBlood",
      "ros_gastroSubSymptomsAbdominal",
      "ros_gastroSubSymptomsOther",
      "ros_gastroSubSymptomsNone",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisType",
    [
      "ros_gastroSubDiagnoseshepatitisTypeA",
      "ros_gastroSubDiagnoseshepatitisTypeB",
      "ros_gastroSubDiagnoseshepatitisTypeC",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDess",
    [
      "ros_gastroSubDiagnoseshepatitisAcute",
      "ros_gastroSubDiagnoseshepatitisChronic",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDes",
    [
      "ros_gastroSubDiagnosesulcerDiseaseEsophageal",
      "ros_gastroSubDiagnosesulcerDiseaseGastric",
      "ros_gastroSubDiagnosesulcerDiseaseDuodenal",
      "ros_gastroSubDiagnosesulcerDiseaseOther",
    ],
  ],
];

export const ros_hashmap2024 = [
  ...ros_hashmap_mehak2024,
  ...ros_hashmap_srilatha2024,
];

export const ros_optionsTreeMapObject2024 = {
  ...ros_optionsTreeMapObject_mehak2024,
  ...ros_optionsTreeMapObject_srilatha2024,
};

export const ros_OptionsTreeWrappers2024 = [
  ...ros_OptionsTreeWrappers_mehak2024,
  ...ros_OptionsTreeWrappers_srilatha2024,
];
