import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment-timezone";
import PropTypes from "prop-types";

export default function DateTimeDisplay({ DateTimeFormat, selectedTimeZone }) {
  const [dateTime, setDateTime] = useState({ date: "", time: "" });

  useEffect(() => {
    const getCurrentDateTime = () => {
      let currentTime;

      currentTime = moment().tz(selectedTimeZone.utc[0]);

      const [DateFormat, Timeformat] = DateTimeFormat.split(" ");
      const datePart = currentTime.format(DateFormat);
      const timePart = currentTime.format(Timeformat);
      setDateTime({ date: datePart, time: timePart });
    };

    getCurrentDateTime();

    const intervalId = setInterval(() => {
      getCurrentDateTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [DateTimeFormat, selectedTimeZone]);

  useEffect(() => {
    localStorage.setItem("selectedTimeZone", JSON.stringify(selectedTimeZone));
  }, [selectedTimeZone]);

  return (
    <div style={{ width: "175px" }}>
      {dateTime.date} {dateTime.time}{" "}
    </div>
  );
}

DateTimeDisplay.propTypes = {
  DateTimeFormat: PropTypes.string,
  selectedTimeZone: PropTypes.object,
};
