const DemoGraphicsData = [
  {
    sectionId: 1,
    sectionName: "Demographics",
    isEditable: false,
    isConfigured: true,
    isModified: false,
    isLocked: false,
    lockedAuditInfo: {
      user: "",
      lockedAt: "",
    },
    questions: [
      {
        id: 1,
        questionId: 1,
        name: "plan",
        type: "text",
        placeholder: "Plan",
        defaultValue: "",
        label: "Plan",
        variant: "outlined",
        fullWidth: true,
        required: false,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        disabled: true,
      },
      {
        id: 1,
        questionId: 2,
        name: "program",
        type: "text",
        placeholder: "program",
        label: "Program",
        defaultValue: "",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        disabled: true,
      },
      {
        questionId: 3,
        name: "region",
        type: "text",
        placeholder: "region",
        label: "Region",
        defaultValue: "",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 4,
        name: "firstName",
        type: "text",
        placeholder: "Enter First Name",
        label: "First Name",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 4,
        name: "middleName",
        type: "text",
        placeholder: "Enter Middle Name",
        label: "Middle Name",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 5,
        name: "lastName",
        type: "text",
        placeholder: "Enter Last Name",
        label: "Last Name",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 6,
        name: "gender",
        label: "Gender",
        placeholder: "",
        //defaultValue: "male",
        fullWidth: true,
        //required: true,
        type: "radio",
        options: [
          ["Male", "M"],
          ["Female", "F"],
          ["Other", "other"],
        ],
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 7,
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 8,
        name: "addressTwo",
        type: "text",
        placeholder: "Address 2",
        label: "Address 2",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 9,
        name: "mAddressThree",
        type: "text",
        placeholder: "Address 3",
        label: "Address 3",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 10,
        name: "mCity",
        type: "text",
        placeholder: "Mailing City",
        label: "Mailing City",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 11,
        name: "mState",
        type: "text",
        placeholder: "Mailing State",
        label: "Mailing State",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 12,
        name: "mZip",
        type: "text",
        placeholder: "Mailing Zip",
        label: "Mailing Zip",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 13,
        name: "dobDate",
        id: "date",
        type: "date",
        placeholder: "",
        //value:29478,
        //defaultValue: "2023-08-10",
        label: "Date of Birth",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 14,
        name: "age",
        type: "number",
        placeholder: "Age",
        label: "Age",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 15,
        name: "mStatus",
        label: "Marital Status",
        type: "select",
        options: [
          ["Divorced", "divorced"],
          ["Married", "married"],
          ["Registered Partnership", "registeredPartnership"],
          ["Seperated", "seperated"],
          ["Single", "single"],
          ["Widowed", "widowed"],
        ],
        xs: 4,
        sm: 4,
        fullWidth: true,
        required: true,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 16,
        name: "memberId",
        type: "number",
        placeholder: "Member Identification Number",
        label: "Member Identification Number",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 17,
        name: "hicn",
        type: "text",
        placeholder: "HICN",
        label: "HICN",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 18,
        name: "email",
        type: "email",
        placeholder: "Enter  email address",
        label: "Email",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 19,
        name: "pPhone",
        type: "number",
        placeholder: "Enter your number",
        label: "Phone Number",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 19,
        name: "pName",
        type: "number",
        placeholder: "Enter PCP Name",
        label: "Primary Care Physician - Name",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 20,
        name: "pAddOne",
        type: "text",
        placeholder: "Enter PCP Address",
        label: "PCP Address",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 21,
        name: "pCity",
        type: "text",
        placeholder: "Enter PCP City",
        label: "PCP City",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 22,
        name: "pState",
        type: "text",
        placeholder: "Enter PCP State",
        label: "PCP State",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 23,
        name: "pZip",
        type: "text",
        placeholder: "Enter PCP ZIP",
        label: "PCP Zip",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 24,
        name: "pcpcounty",
        type: "text",
        placeholder: "Enter PCP County",
        label: "PCP County",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 25,
        name: "pId",
        type: "number",
        placeholder: "Enter PCP NPI",
        label: "PCP NPI",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 26,
        name: "office_id",
        type: "number",
        placeholder: "Enter Office ID",
        label: "Office ID",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 27,
        name: "pOfcName",
        type: "text",
        placeholder: "Enter Office Name",
        label: "Office Name",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 4,
        sm: 4,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        questionId: 28,
        label: "Race",
        value: "race",
        type: "options_tree",
        xs: 12,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            label: "Caucasian",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "caucasian",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 2,
            label: "African American",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "african_american",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 3,
            label: "Asian",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "asian",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 4,
            label: "Latino",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "latino",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 5,
            label: "Native American",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "native_american",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 6,
            label: "Native Hawaiian",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "native_hawaiian",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 7,
            label: "Alaskan Native",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "alaskan_native",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 8,
            label: "Other",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "other",
            hasSubQuestions: true,
            subQuestions: [
              {
                subQuestionId: 1,
                name: "describe",
                type: "text",
                placeholder: "",
                label: "Describe",
                variant: "outlined",
                fullWidth: true,
                required: true,
                xs: 3,
                sm: 3,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
              },
            ],
          },
        ],
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        fullWidth: true,
        required: true,
        name: "race",
      },
      {
        questionId: 29,
        name: "ethnicity",
        label: "Patient Ethnicity",
        value: "patient ethnicity",
        type: "options_tree",
        xs: 12,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            label: "Caucasian",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "CAUCASIAN",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 2,
            label: "AFRICAN AMERICAN",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "AFRICAN AMERICAN",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 3,
            label: "ASIAN/PACIFIC",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "ASIAN/PACIFIC",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
        ],
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        fullWidth: true,
        required: true,
      },
      {
        questionId: 30,
        name: "language",
        label: "Preferred language",
        value: "preferred language",
        type: "options_tree",
        xs: 12,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            label: "English",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "ENGLISH",
            hasSubQuestions: false,
            subQuestions: [
              {
                editToggle: false,
              },
            ],
          },
          {
            depth: 1,
            subQuestionId: 2,
            label: "Other",
            type: "toggle_button",
            isConfigured: true,
            isModified: false,
            value: "other",
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "if_other",
                type: "options_tree",
                label: "If other",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "African languages",
                    value: "african_languages",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Arabic",
                    value: "arabic",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Chinese",
                    value: "chinese",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "French",
                    value: "french",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "French Creole",
                    value: "french_creole",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "German",
                    value: "german",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Greek",
                    value: "greek",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Gujarati",
                    value: "gujarati",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hebrew",
                    value: "hebrew",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hindi",
                    value: "hindi",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hungarian",
                    value: "hungarian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Italian",
                    value: "italian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Japanese",
                    value: "japanese",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Korean",
                    value: "korean",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Persian",
                    value: "persian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 16,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Polish",
                    value: "polish",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 17,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Potuguese",
                    value: "potuguese",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 18,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Russian",
                    value: "russian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 19,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Scandinavian Languages",
                    value: "scandinavian_languages",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 20,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Serbo-Croatian",
                    value: "serbo_croatian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 21,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Spanish",
                    value: "spanish",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 22,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Tagalog",
                    value: "tagalog",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 23,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Urdu",
                    value: "urdu",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 24,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Vietnamese",
                    value: "vietnamese",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 25,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Yiddish",
                    value: "yiddish",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },
        ],
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        fullWidth: true,
        required: true,
        editToggle: false,
      },
    ],
  },
  {
    sectionId: 2,
    sectionName: "Previously Documented Conditions",
    isEditable: false,
    questions: [
      {
        id: 8,
        name: "previously_documented_conditions",
        type: "button",
        label: "Previously documented Conditions",
        editToggle: false,
        xs: 3,
        isSingleButton: true,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 11,
        name: "previously_documented_conditions",
        label: "Previously Documented Conditions",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],

          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "HRAForm_BMI_Index_Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],

          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            true,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
    ],
  },
  {
    sectionId: 3,
    sectionName: "Covid Screening",
    isEditable: false,
    questions: [
      {
        id: 7,
        name: "lastFourteenDays",
        value: "lastFourteenDays",
        type: "label",
        label: "In the last 14 days,have you:",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 6,
        name: "travelInternational",
        value: "travelInternational",
        type: "options_tree",
        label: "Travelled Internationally?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 6,
        name: "dignosedWithCorona",
        value: "dignosedWithCorona",
        type: "options_tree",
        label:
          "Had known exposure to anyone diagnosed with Corona Virus (COVID-19).?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 6,
        name: "closeContact",
        value: "closeContact",
        type: "options_tree",
        label:
          "Had close contact with someone who has traveled to a high risk area?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            valu: "No",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 6,
        name: "developFever",
        value: "developFever",
        type: "options_tree",
        label: "Developed Fever?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 6,
        name: "developCough",
        value: "developCough",
        type: "options_tree",
        label: "Developed Cough?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 6,
        name: "developSymptoms",
        value: "developSymptoms",
        type: "options_tree",
        label: "Developed Flu like symptoms?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 6,
        name: "developBreath",
        value: "developBreath",
        type: "options_tree",
        label: "Developed Shortness of breath?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
    ],
  },
  {
    sectionId: 4,
    sectionName: "Self Assessment & Social History",
    isEditable: false,
    questions: [
      {
        id: 6,
        name: "self_school",
        type: "options_tree",
        label: "How much school have you completed?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Completed less than 3rd grade",
            name: "thirdGrade",
            value: "thirdGrade",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Completed less than 8th grade",
            name: "eighthGrade",
            value: "eighthGrade",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Completed less than 12th grade",
            name: "lessthanTwelthGrade",
            value: "lessthanTwelthGrade",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            name: "completedTwelthGrade",
            isModified: false,
            label: "Completed  12th grade or attended college",
            value: "completedTwelthGrade",
            type: "toggle_button",
          },
        ],
        questionId: 1,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_writtenInfo",
        type: "options_tree",
        label:
          "When you get written information at a doctor's office would you say it is",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Very difficult",
            value: "veryDifficult",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            name: "somewhatDifficult",
            label: "Somewhat difficult",
            value: "somewhatDifficult",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Easy",
            name: "easy",
            value: "easy",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Very easy to understand",
            name: "veryeasy",
            value: "veryeasy",
            type: "toggle_button",
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_presciptionBottle",
        type: "options_tree",
        label:
          "When you read the instructions on a prescription bottle would you say that it is",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Very difficult",
            value: "veryDifficult",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Somewhat difficult",
            value: "somewhatDifficult",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Easy",
            value: "easy",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Very easy to understand",
            value: "veryeasy",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_fillingMedicalForm",
        type: "options_tree",
        label:
          "How confident are you in filling out medical forms by yourself?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Not at All Confident",
            value: "notAtAllConfident",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Not Very Confident",
            value: "notVeryConfident",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Confident",
            value: "confident",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Very Confident",
            value: "veryConfident",
            type: "toggle_button",
          },
        ],
        questionId: 4,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_ratYourHealth",
        type: "options_tree",
        label:
          "How would you rate your health compared to other persons your age?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Excellent",
            value: "excellent",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Good",
            value: "good",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Fair",
            value: "fair",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Poor",
            value: "poor",
            type: "toggle_button",
          },
        ],
        questionId: 5,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_socialActivities",
        type: "options_tree",
        label:
          "During past 3 months, has your physical and or emotional health limited your social activities with family, friends, neighbours or groups?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Often",
            value: "often",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Sometimes",
            value: "sometimes",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Almost Never",
            value: "almostNever",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Never",
            value: "never",
            type: "toggle_button",
          },
        ],
        questionId: 6,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_currentLive",
        type: "options_tree",
        label: "Where do you currently live?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Home",
            value: "home",
            name: "home",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Apartment",
            value: "apartment",
            name: "apartment",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Assisted Living",
            value: "assistedLiving",
            name: "assistedLiving",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Nursing Home",
            value: "nursingHome",
            name: "nursingHome",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "self_currentLiveHomelessDesc",
                type: "text",
                value: "descibe",
                label: "Describe",
                editToggle: true,
                xs: 4,
                required: false,
                placeholder: "describe",
                defaultValue: "",
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: true,
            isConfigured: true,
            isModified: false,
            label: "Homeless",
            value: "homeless",
            name: "homeless",
            type: "toggle_button",
            depth: 1,
          },

          {
            subQuestionId: 6,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "self_currentLiveOtherDesc",
                type: "text",
                value: "descibe",
                label: "Describe",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                disabled: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Other",
            value: "other",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 7,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_relyOn",
        type: "options_tree",
        label:
          "Do you have someone you can rely on to help if you are sick or have problems you need to discuss?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            name: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            name: "No",
            value: "No",
            type: "toggle_button",
          },
        ],
        questionId: 8,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_currentlyLive",
        type: "options_tree",
        label: "Who do you currently live with?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "self_currentlyLiveAloneDesc",
                value: "descibe",
                type: "text",
                label: "Describe",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Alone",
            value: "alone",
            name: "alone",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Spouse",
            value: "spouse",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Partner",
            value: "partner",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Relative",
            value: "relative",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Family",
            value: "family",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 6,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Friend",
            value: "friend",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 7,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Personal Care Worker",
            value: "personalCareWorker",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 9,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_careGiver",
        type: "options_tree",
        label: "Are you currently a caregiver for someone?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            //value:"caregiverSubAnswer",
            subQuestions: [
              {
                id: 1,
                name: "self_careGiverDesc",
                type: "text",
                label: "Describe",
                editToggle: false,
                value: "describe",
                xs: 4,
                required: "yes",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 10,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_currentlyEmploy",
        type: "options_tree",
        label: "Are you currently employed?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
          },
        ],
        questionId: 11,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_interestEmploy",
        type: "options_tree",
        label: "Are you interested in employment?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
          },
        ],
        questionId: 12,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_volunCurrently",
        type: "options_tree",
        label: "Do you volunteer currently?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
          },
        ],
        questionId: 13,
        editToggle: false,
      },
      {
        id: 6,
        name: "tobaccoUse",
        type: "options_tree",
        label: "Tobacco use",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "tobUseCurrent",
                type: "options_tree",
                label: "Type",
                value: "type",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "tobUseCurrentCigSelect",
                        type: "options_tree",
                        label: "How many",
                        value: "1",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "1-3 a day",
                            value: "OneToThree",
                            name: "OneToThree",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "1/2 a pack",
                            value: "oneToTwo",
                            name: "oneToTwo",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "1 pack",
                            value: "onePack",
                            name: "onePack",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "More than 1 pack",
                            value: "MoreThanOne",
                            name: "MoreThanOne",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "tobUseCurrentCigSelectOtherSub",
                                type: "text",
                                label: "Describe",
                                value: "decribe",
                                editToggle: false,
                                xs: 4,
                                required: false,
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            name: "name",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: true,
                      },
                    ],
                    editToggle: true,
                    isCommentEnabled: true,
                    label: "Cigarettes  ",
                    value: "cigarettes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: true,
                    isCommentEnabled: true,
                    isConfigured: true,
                    isModified: true,
                    label: "Cigars  ",
                    value: "cigars",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Chewing Tobacco  ",
                    value: "chewingTobacco",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Vaping  ",
                    value: "vaping",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 1,
                        name: "describe",
                        type: "text",
                        label: "Describe",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other  ",
                    value: "other__",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
              {
                id: 10,
                name: "smokingCessation",
                label:
                  "Discussed smoking cessation options,member verbalized understanding",
                placeholder: "",
                editToggle: true,
                value: "smokingCessation",
                defaultValue: "",
                type: "checkbox",
                options: [
                  [
                    "Discussed smoking cessation options,member verbalized understanding",
                    "smokingCessation",
                  ],
                ],
                xs: 12,
                required: "yes",
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Current",
            name: "current",
            value: "current",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "when",
                type: "options_tree",
                label: "When",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Stopped within the last year",
                    value: "stopped_within_the_last_year",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Stopped within the last 3 years",
                    value: "stopped_within_the_last_3_years",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Stopped 5 or more years ago",
                    value: "stopped_5_or_more_years_ago",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
              {
                id: 6,
                name: "type",
                type: "options_tree",
                label: "Type",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "how_many",
                        type: "options_tree",
                        label: "How many",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "1-3  a day",
                            value: "1_3__a_day",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "1/2 a pack",
                            value: "1/2_a_pack",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "1 pack",
                            value: "1_pack",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "More than 1 pack",
                            value: "more_than_1_pack",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: false,
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Cigarettes   ",
                    value: "cigarettes___",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cigars   ",
                    value: "cigars___",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Chewing Tobacco   ",
                    value: "chewing_tobacco___",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Vaping   ",
                    value: "vaping___",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 1,
                        name: "describe",
                        type: "text",
                        label: "Describe",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other   ",
                    value: "other___",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 2,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Former",
            value: "former",
            name: "former",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Never",
            value: "never",
            name: "never",
            type: "toggle_button",
          },
        ],
        questionId: 14,
        editToggle: false,
      },
      // {
      //   id: 6,
      //   name: "alcoholUse",
      //   type: "options_tree",
      //   label: "Alcohol Use",
      //   value:"alcoholUse",
      //   fullWidth: true,
      //   required: true,
      //   xs: 12,
      //   isCommentEnabled: true,
      //   isConfigured: true,
      //   isModified: false,
      //   hasSubQuestions: true,
      //   subQuestions: [
      //     {
      //       depth: 1,
      //       subQuestionId: 1,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 11,
      //           name: "currentSubAnswers",
      //           value:"currentSubAnswers",
      //           label: "Data Table",
      //           editToggle: "yes",
      //           type: "datatable",
      //           tablelist: [
      //             [
      //               {
      //                 id: 11,
      //                 label: "Alcohol Usage",
      //                 name: "currentSubAnswers",
      //                 value:"currentSubAnswers",
      //                 columns: [
      //                   {
      //                     label: "How many drinks",
      //                     value:"howManyDrinks",
      //                     name:"howManyDrinks",
      //                     type: "select",
      //                   },
      //                   {
      //                     label: "How often",
      //                     value:"howOften",
      //                     name:"howOften",
      //                     type: "select",
      //                   },
      //                 ],
      //                 isReadOnly: false,
      //               },
      //               ,
      //             ],

      //           ],
      //           xs: 12,
      //           required: "yes",
      //           isCommentEnabled: true,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "Current",
      //       value: "current",
      //       name: "current",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       depth: 1,
      //       subQuestionId: 2,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 11,
      //           name: "formerSubAnswers",
      //           label: "Data Table",
      //           editToggle: false,
      //           type: "datatable",
      //           value:"formerSubAnswers",
      //           tablelist: [
      //             [
      //               {
      //                 id: 11,
      //                 label: "Alcohol Usage",
      //                 name: "formerSubAnswers",
      //                 value:"formerSubAnswers",
      //                 columns: [
      //                   {
      //                     label: "How many drinks",
      //                     type: "select",
      //                     value:"howManyDrinks",
      //                     name:"howManyDrinks",
      //                   },
      //                   {
      //                     label: "How often",
      //                     type: "select",
      //                     value:"howOften",
      //                     value:"howOften"
      //                   },
      //                 ],
      //                 isReadOnly: false,
      //               },

      //             ],
      //           ],
      //           xs: 12,
      //           required: false,
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: false,
      //           subQuestionId: 2,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "Former",
      //       value: "former",
      //       name: "former",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 3,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Never",
      //       value: "never",
      //       name:"never",
      //       type: "toggle_button",
      //     },
      //   ],
      //   questionId: 15,
      //   editToggle: false,
      // },
      {
        id: 6,
        name: "alcoholUse",
        type: "options_tree",
        label: "Alcohol Use",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 11,
                name: "data_table",
                label: "Data Table",
                editToggle: false,
                type: "datatable",
                tablelist: [
                  [
                    {
                      id: 11,
                      label: "Alcohol Usage",
                      name: "HRAForm_Alcohol_Usage",
                      columns: [
                        {
                          label: "How many drinks",
                          type: "select",
                        },
                        {
                          label: "How often",
                          type: "select",
                        },
                      ],
                      isReadOnly: true,
                    },
                    true,
                  ],
                ],
                xs: 12,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Current",
            value: "current",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            depth: 1,
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 11,
                name: "data_table",
                label: "Data Table",
                editToggle: false,
                type: "datatable",
                tablelist: [
                  [
                    {
                      id: 11,
                      label: "Alcohol Usage",
                      name: "HRAForm_Alcohol_Usage",
                      columns: [
                        {
                          label: "How many drinks",
                          type: "select",
                        },
                        {
                          label: "How often",
                          type: "select",
                        },
                      ],
                      isReadOnly: true,
                    },
                    true,
                  ],
                ],
                xs: 12,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                subQuestionId: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Former",
            value: "former",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Never",
            value: "never",
            type: "toggle_button",
          },
        ],
        questionId: 15,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_recreaDrugs",
        type: "options_tree",
        label: "Do you or have you used recreational drugs??",
        value: "recreationalDrugs",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "self_recreaDrugsWhich",
                value: "whichDrugs",
                type: "text",
                label: "Which drugs",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 16,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_healthProxy",
        value: "healthcareProxy",
        type: "options_tree",
        label: "Do you have a Healthcare Proxy?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "self_healthProxyName",
                type: "text",
                label: "Name",
                value: "name",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 2,
              },
              {
                id: 1,
                name: "self_healthProxyRelation",
                type: "text",
                label: "Relationship",
                value: "relationship",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Don't Know",
            value: "dontKnow",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 17,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_powerAttny",
        type: "options_tree",
        value: "powerAttorney",
        label: "Do you have a Durable Power of Attorney?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "self_powerAttnyName",
                type: "text",
                label: "Name",
                value: "name",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 2,
              },
              {
                id: 1,
                name: "self_powerAttnyRelation",
                type: "text",
                label: "Relationship",
                value: "relationship",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Don't Know",
            value: "dontKnow",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 18,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_advDirect",
        type: "options_tree",
        value: "advanceDirective",
        label: "Do you have an Advance Directive?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "kept",
                type: "text",
                value: "kept",
                label: "Where is it kept?",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Don't Know",
            value: "dontKnow",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 19,
        editToggle: false,
      },
      {
        id: 10,
        name: "self_eduAdvPlan",
        value: "educatedOnAdvanceCarePlanning",
        label: " ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Member educated on advance care planning",
            "educatedOnAdvanceCarePlanning",
          ],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 20,
      },
      {
        id: 10,
        name: "self_declineDisc",
        value: "declinesDiscussion",
        label: " ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["Declines discussion at this time", "declinesDiscussion"]],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 20,
      },
      {
        id: 6,
        name: "self_foodRunOut",
        type: "options_tree",
        value: "foodWouldRunOut",
        label:
          "Within the past 12 months we worried whether our food would run out before we got money to buy more. Was that ______ for your household?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Often True",
            value: "oftenTrue",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Sometimes True",
            value: "sometimesTrue",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Never True",
            value: "neverTrue",
            type: "toggle_button",
          },
        ],
        questionId: 21,
        editToggle: false,
      },
      {
        id: 6,
        name: "self_foodBought",
        type: "options_tree",
        value: "foodWeBought",
        label:
          "Within the past 12 months the food we bought just didn't last and we didn't have money to get more. Was that ______ for your household?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Often True",
            value: "oftenTrue",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Sometimes True",
            value: "sometimesTrue",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Never True",
            value: "neverTrue",
            type: "toggle_button",
          },
        ],
        questionId: 22,
        editToggle: false,
      },
      {
        id: 10,
        name: "self_Recommendations",
        label: "Recommendations",
        placeholder: "",
        value: "smokingOrTobacco",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Smoking/Tobacco", "smokingOrTobacco"],
          ["Substance Abuse", "substanceAbuse"],
          ["Durable power of attorney", "durablePower"],
          ["Healthcare Proxy", "healthcareProxy"],
          ["Advance Directive", "advancedDirective"],
          ["Food Disparity", "foodDisparity"],
          ["Literacy", "literacy"],
          ["Social support evaluation", "socialSupport"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 23,
      },
    ],
  },
  {
    sectionId: 5,
    sectionName: "Activities of Daily Living",
    isEditable: false,
    questions: [
      {
        id: 7,
        name: "difficultyWithActivities",
        value: "difficultyWithActivities",
        type: "label",
        label: "Do you have any difficulty with the following activities?",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 6,
        name: "gettingInOrOutOfBed",
        value: "gettingInOrOutOfBed",
        type: "options_tree",
        label: "A. Getting in or out of bed",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some Help",
            value: "needSomeHelp",
            name: "needSomeHelp",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total Help",
            value: "needTotalHelp",
            name: "needTotalHelp",
            type: "toggle_button",
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 6,
        name: "gettingInOrOutOfChairs",
        value: "gettingInOrOutOfChairs",
        type: "options_tree",
        label: "B. Getting in or out of chairs",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some Help",
            value: "needSomeHelp",
            name: "needSomeHelp",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total Help",
            value: "needTotalHelp",
            name: "needTotalHelp",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 6,
        name: "toileting",
        vale: "toileting",
        type: "options_tree",
        label: "C. Toileting",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some Help",
            value: "needSomeHelp",
            name: "needSomeHelp",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total Help",
            value: "needTotalHelp",
            name: "needTotalHelp",
            type: "toggle_button",
          },
        ],
        questionId: 4,
        editToggle: false,
      },
      {
        id: 6,
        name: "bathing",
        value: "bathing",
        type: "options_tree",
        label: "D. Bathing",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some Help",
            value: "needSomeHelp",
            name: "needSomeHelp",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total Help",
            value: "needTotalHelp",
            name: "needTotalHelp",
            type: "toggle_button",
          },
        ],
        questionId: 5,
        editToggle: false,
      },
      {
        id: 6,
        name: "dressing",
        value: "dressing",
        type: "options_tree",
        label: "E. Dressing",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some Help",
            value: "needSomeHelp",
            name: "needSomeHelp",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total Help",
            value: "needTotalHelp",
            name: "needTotalHelp",
            type: "toggle_button",
          },
        ],
        questionId: 6,
        editToggle: false,
      },
      {
        id: 6,
        name: "eating",
        value: "eating",
        type: "options_tree",
        label: "F. Eating",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some Help",
            value: "needSomeHelp",
            name: "needSomeHelp",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total Help",
            value: "needTotalHelp",
            name: "needTotalHelp",
            type: "toggle_button",
          },
        ],
        questionId: 7,
        editToggle: false,
      },
      {
        id: 6,
        name: "walking",
        value: "walking",
        type: "options_tree",
        label: "G. Walking",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "walk_needSomeHelpSubAnswers",
                value: "needSomeHelpSubAnswers",
                type: "options_tree",
                label: "How far can you walk",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Household only",
                    value: "householdOnly",
                    name: "householdOnly",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Less than one block",
                    value: "lessThanOneBlock",
                    name: "lessThanOneBlock",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "One block",
                    value: "oneBlock",
                    name: "oneBlock",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Two or more blocks",
                    value: "twoOrMore",
                    name: "twoOrMore",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Non-ambulatory",
                    value: "nonAmbulatory",
                    name: "nonAmbulatory",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some Help",
            value: "needSomeHelp",
            name: "walk_need_Some_Help",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "walk_neededTotalHelpSubAnswer",
                value: "neededTotalHelpSubAnswer",
                type: "options_tree",
                label: "How far can you walk",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Household only",
                    value: "householdOnly",
                    name: "householdOnly",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Less than one block",
                    value: "lessThanOneBlock",
                    name: "lessThanOneBlock",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "One block",
                    value: "oneBlock",
                    name: "oneBlock",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Two or more blocks",
                    value: "twoOrMore",
                    name: "twoOrMore",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Non-ambulatory",
                    value: "nonAmbulatory",
                    name: "nonAmbulatory",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total Help",
            value: "neededTotalHelp",
            name: "walk_need_Total_Help",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 8,
        editToggle: false,
      },
      {
        id: 6,
        name: "goingUpOrDownStairs",
        value: "goingUpOrDownStairs",
        type: "options_tree",
        label: "H. Going up or down stairs",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            name: "no",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "stairs_needSomeHelpSubAnswers",
                value: "needSomeHelpSubAnswers",
                type: "options_tree",
                label: "How many stairs can you climb",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "None",
                    value: "none",
                    value: "none",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Three to five",
                    value: "threeToFive",
                    name: "threeToFive",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Six to ten",
                    value: "sixToTen",
                    name: "sixToTen",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than ten",
                    value: "moreThanTen",
                    name: "moreThanTen",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Some help",
            value: "needSomeHelp",
            name: "stairs_need_Some_Help",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "stairs_neededTotalHelpSubAnswer",
                value: "neededTotalHelpSubAnswer",
                type: "options_tree",
                label: "How many stairs can you climb",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "None",
                    value: "none",
                    name: "none",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Three to five",
                    value: "threeToFive",
                    name: "threeToFive",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Six to ten",
                    value: "sixToTen",
                    name: "sixToTen",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than ten",
                    value: "moreThanTen",
                    name: "moreThanTen",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Need Total help",
            value: "neededTotalHelp",
            name: "stairs_needed_Total_Help",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 9,
        editToggle: false,
      },
    ],
  },
  {
    sectionId: 6,
    sectionName: "Medical History",
    isEditable: false,
    questions: [
      {
        id: 8,
        name: "dyuaadoDME",
        type: "options_tree",
        label: "Do you use any assistive devices or DME?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            subQuestionId: 10,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "assistiveSub",
                type: "options_tree",
                label: "If Yes",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Cane",
                    value: "cane",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "cane",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Walker",
                    value: "walker",
                    type: "toggle_button",
                    name: "walker",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Prosthesis",
                    value: "prosthesis",
                    type: "toggle_button",
                    name: "prosthesis",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "oxygenDescribe",
                        type: "options_tree",
                        label: "Oxygen -Describe",
                        fullWidth: true,
                        required: true,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "PRN",
                            value: "prn",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "prn",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Day",
                            value: "day",
                            type: "toggle_button",
                            name: "day",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Night",
                            value: "night",
                            type: "toggle_button",
                            name: "night",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Continuous",
                            value: "continuous",
                            type: "toggle_button",
                            name: "continuous",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 1,
                        name: "litresMin",
                        type: "text",
                        label: "Litres / Min",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 2,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Oxygen",
                    value: "oxygen",
                    type: "toggle_button",
                    name: "oxygen",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Wheel Chair",
                    value: "wheelChair",
                    type: "toggle_button",
                    name: "wheelChair",
                    depth: 4,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Beside Commode",
                    value: "besideCommode",
                    type: "toggle_button",
                    name: "besideCommode",
                    depth: 4,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Urinal",
                    value: "urinal",
                    type: "toggle_button",
                    name: "urinal",
                    depth: 4,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Bed Pan",
                    value: "bedPan",
                    type: "toggle_button",
                    name: "bedPan",
                    depth: 4,
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "CPAP",
                    value: "cpap",
                    type: "toggle_button",
                    name: "cpap",
                    depth: 4,
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    name: "other",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Yes",
            value: "yes",
            type: "toggle_button",
            depth: 2,
            name: "yes",
          },
          {
            subQuestionId: 11,
            hasSubQuestions: false,
            subQuestions: [{}],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 7,
        editToggle: false,
        depth: 1,
      },

      {
        id: 6,
        name: "areYouCurrentlySeeingAnySpecia",
        type: "options_tree",
        label: "Are you currently seeing any specialists?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 80,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 11,
                name: "data_table",
                label: "Data Table",
                editToggle: false,
                type: "datatable",
                tablelist: [
                  [
                    {
                      id: 1,
                      label: "Allergies",
                      name: "HRAForm_Allergies",
                      columns: [
                        {
                          label: "Substance",
                          type: "text",
                        },
                        {
                          label: "Reaction",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 2,
                      label: "BMI Index Chart",
                      name: "HRAForm_BMI_Index_Chart",
                      columns: [
                        {
                          label: "BMI",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 3,
                      label: "Counter Medications",
                      name: "HRAForm_Counter_Medications",
                      columns: [
                        {
                          label: "Date",
                          type: "date",
                        },
                        {
                          label: "Description",
                          type: "text",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 4,
                      label: "Medical History Specialists",
                      name: "HRAForm_Medical_History_Speciality",
                      columns: [
                        {
                          label: "Member Specialist",
                          type: "select",
                        },
                        {
                          label: "Speciality",
                          type: "text",
                        },
                        {
                          label: "For",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    true,
                  ],
                  [
                    {
                      id: 5,
                      label: "Medications",
                      name: "HRAForm_Medications",
                      columns: [
                        {
                          label: "Diagnoses",
                          type: "text",
                        },
                        {
                          label: "label Name",
                          type: "select",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "select",
                        },
                        {
                          label: "Prescribing Physician",
                          type: "text",
                        },
                        {
                          label: "Status",
                          type: "togglebutton",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 6,
                      label: "Mini Cog Versions",
                      name: "HRAForm_Minicog_Version",
                      columns: [
                        {
                          label: "Version 1",
                          type: "text",
                        },
                        {
                          label: "Version 2",
                          type: "text",
                        },
                        {
                          label: "Version 3",
                          type: "text",
                        },
                        {
                          label: "Version 4",
                          type: "text",
                        },
                        {
                          label: "Version 5",
                          type: "text",
                        },
                        {
                          label: "Version 6",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 7,
                      label: "Preventive Care",
                      name: "HRAForm_Preventive_Care",
                      columns: [
                        {
                          label: "Screen",
                          type: "text",
                        },
                        {
                          label: "Answer",
                          type: "select",
                        },
                        {
                          label: "Date",
                          type: "text",
                        },
                        {
                          label: "Method",
                          type: "text",
                        },
                        {
                          label: "Recommendation",
                          type: "checkbox",
                        },
                        {
                          label: "Education Completed",
                          type: "checkbox",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 8,
                      label: "Previously Documented Conditions",
                      name: "HRAForm_Previously_Documented_Conditions",
                      columns: [
                        {
                          label: "Diagnosis",
                          type: "text",
                        },
                        {
                          label: "Prior HCC",
                          type: "text",
                        },
                        {
                          label: "Diagnosis Code",
                          type: "text",
                        },
                        {
                          label: "Date of Service",
                          type: "date",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 9,
                      label: "Prior Assessments",
                      name: "HRAForm_Prior_Assessments",
                      columns: [
                        {
                          label: "Date Time",
                          type: "date",
                        },
                        {
                          label: "Document",
                          type: "button",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 10,
                      label: "Stages Of Kidney Disease",
                      name: "HRAForm_Stage_Of_Kidney_Disease",
                      columns: [
                        {
                          label: "Stages",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 11,
                      label: "Alcohol Usage",
                      name: "HRAForm_Alcohol_Usage",
                      columns: [
                        {
                          label: "How many drinks",
                          type: "select",
                        },
                        {
                          label: "How often",
                          type: "select",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 12,
                      name: "Family History",
                      columns: [
                        {
                          label: "Family Member",
                          type: "select",
                        },
                        {
                          label: "Medical Condition",
                          type: "text",
                        },
                        {
                          label: "Cause of Death",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                ],
                xs: 12,
                required: "no",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                subQuestionId: 80,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            depth: 1,
            subQuestionId: 81,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            isConfigured: true,
            type: "toggle_button",
          },
        ],
        questionId: 1,
        editToggle: false,
      },
      {
        id: 2,
        name: "inThePast12MonthsHowManyTimesH",
        type: "label",
        label: "In the past 12 months how many times have you?",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 3,
        name: "aSeenYourPcp",
        type: "options_tree",
        label: "A. Seen your PCP",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "None",
            value: "none",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "1",
            value: "1",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "2",
            value: "2",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "3",
            value: "3",
            type: "toggle_button",
          },
          {
            subQuestionId: 5,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "4",
            value: "4",
            type: "toggle_button",
          },
          {
            subQuestionId: 6,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "5 or more",
            value: "5_or_more",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },

      // {
      //   id: 4,
      //   name: "bVisitedTheEmergencyRoom",
      //   type: "options_tree",
      //   label: "B. Visited the Emergency Room",
      //   fullWidth: true,
      //   required: true,
      //   xs: 12,
      //   isCommentEnabled: true,
      //   isConfigured: true,
      //   isModified: false,
      //   hasSubQuestions: true,
      //   subQuestions: [
      //     {
      //       depth: 1,
      //       subQuestionId: 1,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "None",
      //       value: "none",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 2,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "1",
      //       value: "1",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 3,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "2",
      //       value: "2",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 4,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "3",
      //       value: "3",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 5,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "4",
      //       value: "4",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 6,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "5 or more",
      //       value: "5_or_more",
      //       type: "toggle_button",
      //     },
      //   ],
      //   questionId: 4,
      //   editToggle: false,
      // },
      {
        id: 4,
        name: "bVisitedTheEmergencyRoom",
        type: "options_tree",
        label: "B. Visited the Emergency Room",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "None",
            value: "none",
            isConfigured: true,
            type: "toggle_button",
            name: "none",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeEmerOne",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "1",
            value: "1",
            type: "toggle_button",
            name: "1",
            depth: 2,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeEmerTwo",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "2",
            value: "2",
            type: "toggle_button",
            name: "2",
            depth: 2,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeEmerThree",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "3",
            value: "3",
            type: "toggle_button",
            name: "3",
            depth: 2,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeEmerFour",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "4",
            value: "4",
            type: "toggle_button",
            name: "4",
            depth: 2,
          },
          {
            subQuestionId: 6,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeEmerFiveOrMore",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "5 or more",
            value: "5_or_more",
            type: "toggle_button",
            name: "5OrMore",
            depth: 2,
          },
        ],
        questionId: 4,
        editToggle: false,
        depth: 1,
      },
      // {
      //   id: 5,
      //   name: "cStayedInTheHospitalOvernight",
      //   type: "options_tree",
      //   label: "C. Stayed in the hospital overnight",
      //   fullWidth: true,
      //   required: true,
      //   xs: 12,
      //   isCommentEnabled: true,
      //   isConfigured: true,
      //   isModified: false,
      //   hasSubQuestions: true,
      //   subQuestions: [
      //     {
      //       depth: 1,
      //       subQuestionId: 1,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "None",
      //       value: "none",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 2,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "1",
      //       value: "1",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 3,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "2",
      //       value: "2",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 4,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "3",
      //       value: "3",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 5,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "4",
      //       value: "4",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 6,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "5 or more",
      //       value: "5_or_more",
      //       type: "toggle_button",
      //     },
      //   ],
      //   questionId: 5,
      //   editToggle: false,
      // },
      {
        id: 5,
        name: "cStayedInTheHospitalOvernight",
        type: "options_tree",
        label: "C. Stayed in the hospital overnight",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "None",
            value: "none",
            isConfigured: true,
            type: "toggle_button",
            name: "none",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeOverOne",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "1",
            value: "1",
            type: "toggle_button",
            name: "1",
            depth: 2,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeOverTwo",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "2",
            value: "2",
            type: "toggle_button",
            name: "2",
            depth: 2,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeOverThree",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "3",
            value: "3",
            type: "toggle_button",
            name: "3",
            depth: 2,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeOverFour",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "4",
            value: "4",
            type: "toggle_button",
            name: "4",
            depth: 2,
          },
          {
            subQuestionId: 6,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeOverFiveOrMore",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "5 or more",
            value: "5_or_more",
            type: "toggle_button",
            name: "5OrMore",
            depth: 2,
          },
        ],
        questionId: 5,
        editToggle: false,
        depth: 1,
      },

      // {
      //   id: 6,
      //   name: "dBeenInANursingHome",
      //   type: "options_tree",
      //   label: "D. Been in a nursing home",
      //   fullWidth: true,
      //   required: true,
      //   xs: 12,
      //   isCommentEnabled: true,
      //   isConfigured: true,
      //   isModified: false,
      //   hasSubQuestions: true,
      //   subQuestions: [
      //     {
      //       depth: 1,
      //       subQuestionId: 1,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "None",
      //       value: "none",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 2,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "1",
      //       value: "1",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 3,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "2",
      //       value: "2",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 4,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "3",
      //       value: "3",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 5,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "4",
      //       value: "4",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 6,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "5 or more",
      //       value: "5_or_more",
      //       type: "toggle_button",
      //     },
      //   ],
      //   questionId: 6,
      //   editToggle: false,
      // },
      {
        id: 6,
        name: "dBeenInANursingHome",
        type: "options_tree",
        label: "D. Been in a nursing home",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "None",
            value: "none",
            isConfigured: true,
            type: "toggle_button",
            name: "none",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeNurOne",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "1",
            value: "1",
            type: "toggle_button",
            name: "1",
            depth: 2,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeNurTwo",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "2",
            value: "2",
            type: "toggle_button",
            name: "2",
            depth: 2,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeNurThree",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "3",
            value: "3",
            type: "toggle_button",
            name: "3",
            depth: 2,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeNurFour",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "4",
            value: "4",
            type: "toggle_button",
            name: "4",
            depth: 2,
          },
          {
            subQuestionId: 6,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeNurFiveOrMore",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "5 or more",
            value: "5_or_more",
            type: "toggle_button",
            name: "5OrMore",
            depth: 2,
          },
        ],
        questionId: 6,
        editToggle: false,
        depth: 1,
      },
      // {
      //   id: 7,
      //   name: "eHadSurgery",
      //   type: "options_tree",
      //   label: "E. Had Surgery",
      //   fullWidth: true,
      //   required: true,
      //   xs: 12,
      //   isCommentEnabled: true,
      //   isConfigured: true,
      //   isModified: false,
      //   hasSubQuestions: true,
      //   subQuestions: [
      //     {
      //       depth: 1,
      //       subQuestionId: 1,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "None",
      //       value: "none",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 2,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "1",
      //       value: "1",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 3,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "2",
      //       value: "2",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 4,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "3",
      //       value: "3",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 5,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "4",
      //       value: "4",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 6,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "5 or more",
      //       value: "5_or_more",
      //       type: "toggle_button",
      //     },
      //   ],
      //   questionId: 7,
      //   editToggle: false,
      // },
      {
        id: 7,
        name: "eHadSurgery",
        type: "options_tree",
        label: "E. Had Surgery",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "None",
            value: "none",
            isConfigured: true,
            type: "toggle_button",
            name: "none",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeSurOne",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "1",
            value: "1",
            type: "toggle_button",
            name: "1",
            depth: 2,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeSurTwo",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "2",
            value: "2",
            type: "toggle_button",
            name: "2",
            depth: 2,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeSurThree",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "3",
            value: "3",
            type: "toggle_button",
            name: "3",
            depth: 2,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeSurFour",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "4",
            value: "4",
            type: "toggle_button",
            name: "4",
            depth: 2,
          },
          {
            subQuestionId: 6,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "ifOneOrMoreDescribeSurFiveOrMore",
                type: "text",
                label: "If one or more, describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "5 or more",
            value: "5_or_more",
            type: "toggle_button",
            name: "5OrMore",
            depth: 2,
          },
        ],
        questionId: 7,
        editToggle: false,
        depth: 1,
      },
      {
        id: 9,
        name: "haveYouEverBeenHospitalizedPri",
        type: "options_tree",
        label: "Have you ever been hospitalized prior to the last 12 months?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "describeHosPrior",
                type: "text",
                label: "Describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [{}],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 9,
        editToggle: false,
      },
      {
        id: 10,
        name: "in_the_past_year_have_you_received_health_services_from_any_of_the_providers_below:",
        type: "label",
        label:
          "In the past year have you received health services from any of the providers below:",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 10,
      },
      {
        id: 11,
        name: "physicaltherapist",
        type: "options_tree",
        label: "Physical Therapist",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 11,
        editToggle: false,
      },
      {
        id: 12,
        name: "occupationaltherapist",
        type: "options_tree",
        label: "Occupational Therapist",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 12,
        editToggle: false,
      },
      {
        id: 13,
        name: "dietician",
        type: "options_tree",
        label: "Dietician",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 13,
        editToggle: false,
      },
      {
        id: 14,
        name: "socialworker",
        type: "options_tree",
        label: "Social Worker",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 14,
        editToggle: false,
      },
      {
        id: 15,
        name: "pharmacist",
        type: "options_tree",
        label: "Pharmacist",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 15,
        editToggle: false,
      },
      {
        id: 16,
        name: "speechtherapist",
        type: "options_tree",
        label: "Speech Therapist",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 16,
        editToggle: false,
      },
      {
        id: 17,
        name: "chiropractor",
        type: "options_tree",
        label: "Chiropractor",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 17,
        editToggle: false,
      },
      {
        id: 18,
        name: "personalcareworker",
        type: "options_tree",
        label: "Personal Care Worker (HHA, CNA, PCA)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 18,
        editToggle: false,
      },
      {
        id: 6,
        name: "mealsonwheels",
        type: "options_tree",
        label: "Meals on Wheels",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 19,
        editToggle: false,
      },
      {
        id: 6,
        name: "adultdaycare",
        type: "options_tree",
        label: "Adult Day Care",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 20,
        editToggle: false,
      },
      {
        id: 7,
        name: "in_the_past_year_have_you_received_any_of_the_treatments_below?",
        type: "label",
        label:
          "In the past year have you received any of the treatments below?",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 21,
      },
      {
        id: 6,
        name: "chemotherapy",
        type: "options_tree",
        label: "Chemotherapy",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Unknown",
            value: "unknown",
            type: "toggle_button",
          },
        ],
        questionId: 22,
        editToggle: false,
      },
      {
        id: 6,
        name: "cathetercare",
        type: "options_tree",
        label: "Catheter Care",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Unknown",
            value: "unknown",
            type: "toggle_button",
          },
        ],
        questionId: 23,
        editToggle: false,
      },
      {
        id: 6,
        name: "oxygen",
        type: "options_tree",
        label: "Oxygen",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Unknown",
            value: "unknown",
            type: "toggle_button",
          },
        ],
        questionId: 24,
        editToggle: false,
      },
      {
        id: 6,
        name: "woundcare",
        type: "options_tree",
        label: "Wound Care",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Unknown",
            value: "unknown",
            type: "toggle_button",
          },
        ],
        questionId: 25,
        editToggle: false,
      },
      {
        id: 6,
        name: "regularinjections",
        type: "options_tree",
        label: "Regular Injections",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Unknown",
            value: "unknown",
            type: "toggle_button",
          },
        ],
        questionId: 26,
        editToggle: false,
      },
      {
        id: 6,
        name: "tubefeedings",
        type: "options_tree",
        label: "Tube Feedings",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Unknown",
            value: "unknown",
            type: "toggle_button",
          },
        ],
        questionId: 27,
        editToggle: false,
      },
      // {
      //   id: 6,
      //   name: "pleaseChooseTheFollowingHealth",
      //   type: "options_tree",
      //   label:
      //     "Please choose the following health services you have received in the last year",
      //   fullWidth: true,
      //   required: true,
      //   xs: 12,
      //   isCommentEnabled: true,
      //   isConfigured: true,
      //   isModified: false,
      //   hasSubQuestions: true,
      //   subQuestions: [
      //     {
      //       depth: 1,
      //       subQuestionId: 1,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "Physical Therapist",
      //       value: "physicalTherapist",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 2,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Occupational Therapist",
      //       value: "occupationalTherapist",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 3,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Dietician",
      //       value: "dietician",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 4,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Social Worker",
      //       value: "socialWorker",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 5,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Pharmacist",
      //       value: "pharmacist",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 6,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Speech Therapist",
      //       value: "speechTherapist",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 7,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Chiropractor",
      //       value: "chiropractor",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 8,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Personal Care Worker (HHA, CNA, PCA)",
      //       value: "personalCareWorkerHhaCnaPca",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 9,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Meals on Wheels",
      //       value: "mealsOnWheels",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 10,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Adult Day Care",
      //       value: "adultDayCare",
      //       type: "toggle_button",
      //     },
      //   ],
      //   questionId: 20,
      //   editToggle: false,
      // },
      // {
      //   id: 6,
      //   name: "pleaseChooseTheFollowingTreatm",
      //   type: "options_tree",
      //   label:
      //     "Please choose the following treatments you have received in the last year.",
      //   fullWidth: true,
      //   required: true,
      //   xs: 12,
      //   isCommentEnabled: true,
      //   isConfigured: true,
      //   isModified: false,
      //   hasSubQuestions: true,
      //   subQuestions: [
      //     {
      //       depth: 1,
      //       subQuestionId: 1,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: false,
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       label: "Chemotherapy",
      //       value: "chemotherapy",
      //       isConfigured: true,
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 2,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Catheter Care",
      //       value: "catheterCare",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 3,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Oxygen",
      //       value: "oxygen",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 4,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Wound Care",
      //       value: "woundCare",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 5,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Regular Injections",
      //       value: "regularInjections",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 6,
      //       hasSubQuestions: true,
      //       subQuestions: [
      //         {
      //           id: 1,
      //           name: "",
      //           type: "text",
      //           label: " ",
      //           editToggle: false,
      //           xs: 4,
      //           required: "no",
      //           placeholder: "",
      //           defaultValue: "",
      //           isCommentEnabled: false,
      //           isConfigured: true,
      //           isModified: true,
      //           subQuestionId: 1,
      //         },
      //       ],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "Tube Feedings",
      //       value: "tubeFeedings",
      //       type: "toggle_button",
      //     },
      //     {
      //       subQuestionId: 7,
      //       hasSubQuestions: false,
      //       subQuestions: [],
      //       editToggle: false,
      //       isCommentEnabled: false,
      //       isConfigured: true,
      //       isModified: false,
      //       label: "None",
      //       value: "none",
      //       type: "toggle_button",
      //     },
      //   ],
      //   questionId: 21,
      //   editToggle: false,
      // },
    ],
  },
  {
    sectionId: 7,
    sectionName: "Family History",
    isEditable: false,
    questions: [
      {
        id: 10,
        name: "familyHistory",
        type: "options_tree",
        label: "Family History",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 70,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 11,
                name: "data_table",
                label: "Data Table",
                editToggle: false,
                type: "datatable",
                tablelist: [
                  [
                    {
                      id: 1,
                      label: "Allergies",
                      name: "HRAForm_Allergies",
                      columns: [
                        {
                          label: "Substance",
                          type: "text",
                        },
                        {
                          label: "Reaction",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 2,
                      label: "BMI Index Chart",
                      name: "HRAForm_BMI_Index_Chart",
                      columns: [
                        {
                          label: "BMI",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 3,
                      label: "Counter Medications",
                      name: "HRAForm_Counter_Medications",
                      columns: [
                        {
                          label: "Date",
                          type: "date",
                        },
                        {
                          label: "Description",
                          type: "text",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 4,
                      label: "Medical History Specialists",
                      name: "HRAForm_Medical_History_Speciality",
                      columns: [
                        {
                          label: "Member Specialist",
                          type: "select",
                        },
                        {
                          label: "Speciality",
                          type: "text",
                        },
                        {
                          label: "For",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 5,
                      label: "Medications",
                      name: "HRAForm_Medications",
                      columns: [
                        {
                          label: "Diagnoses",
                          type: "text",
                        },
                        {
                          label: "label Name",
                          type: "select",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "select",
                        },
                        {
                          label: "Prescribing Physician",
                          type: "text",
                        },
                        {
                          label: "Status",
                          type: "togglebutton",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 6,
                      label: "Mini Cog Versions",
                      name: "HRAForm_Minicog_Version",
                      columns: [
                        {
                          label: "Version 1",
                          type: "text",
                        },
                        {
                          label: "Version 2",
                          type: "text",
                        },
                        {
                          label: "Version 3",
                          type: "text",
                        },
                        {
                          label: "Version 4",
                          type: "text",
                        },
                        {
                          label: "Version 5",
                          type: "text",
                        },
                        {
                          label: "Version 6",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 7,
                      label: "Preventive Care",
                      name: "HRAForm_Preventive_Care",
                      columns: [
                        {
                          label: "Screen",
                          type: "text",
                        },
                        {
                          label: "Answer",
                          type: "select",
                        },
                        {
                          label: "Date",
                          type: "text",
                        },
                        {
                          label: "Method",
                          type: "text",
                        },
                        {
                          label: "Recommendation",
                          type: "checkbox",
                        },
                        {
                          label: "Education Completed",
                          type: "checkbox",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 8,
                      label: "Previously Documented Conditions",
                      name: "HRAForm_Previously_Documented_Conditions",
                      columns: [
                        {
                          label: "Diagnosis",
                          type: "text",
                        },
                        {
                          label: "Prior HCC",
                          type: "text",
                        },
                        {
                          label: "Diagnosis Code",
                          type: "text",
                        },
                        {
                          label: "Date of Service",
                          type: "date",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 9,
                      label: "Prior Assessments",
                      name: "HRAForm_Prior_Assessments",
                      columns: [
                        {
                          label: "Date Time",
                          type: "date",
                        },
                        {
                          label: "Document",
                          type: "button",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 10,
                      label: "Stages Of Kidney Disease",
                      name: "HRAForm_Stage_Of_Kidney_Disease",
                      columns: [
                        {
                          label: "Stages",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 11,
                      label: "Alcohol Usage",
                      name: "HRAForm_Alcohol_Usage",
                      columns: [
                        {
                          label: "How many drinks",
                          type: "select",
                        },
                        {
                          label: "How often",
                          type: "select",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 12,
                      name: "family_history",
                      label: "Family History",
                      columns: [
                        {
                          label: "Family Member",
                          type: "text",
                        },
                        {
                          label: "Medical Condition",
                          type: "text",
                        },
                        {
                          label: "Cause of Death",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    true,
                  ],
                ],
                xs: 12,
                required: "no",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                subQuestionId: 70,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            depth: 1,
            subQuestionId: 71,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "No",
            value: "no",
            isConfigured: true,
            type: "toggle_button",
          },
        ],
        questionId: 71,
        editToggle: false,
      },
    ],
  },
  {
    sectionId: 8,
    sectionName: "Preventive Care",
    isEditable: false,
    questions: [
      {
        id: 7,
        name: "inThePastThreeYearsHaveYouHad",
        label: "In the past three years have you had?",
        type: "label",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },

      {
        id: 11,
        name: "inThePastThreeYearsHaveYouHad",
        label: "In the past three years have you had?",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "HRAForm_BMI_Index_Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            true,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },

      {
        id: 6,
        name: "oneTimeScreenForAbdominalAorti",
        type: "options_tree",
        label:
          "One time screen for Abdominal Aortic Aneurysm if male with history of smoking,age 65-75",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "educationProvidedHepatitis",
                type: "options_tree",
                label: "Education Provided",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "NA",
                    value: "na",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "NA",
            value: "na",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 6,
        name: "oneTimeScreenForHepatitisCIfBo",
        type: "options_tree",
        label: "One time screen for Hepatitis C if born between 1945-1965",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "educationProvidedHepatitis",
                type: "options_tree",
                label: "Education Provided",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "NA",
                    value: "na",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "NA",
            value: "na",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 10,
        name: "recommendationsAbdominal",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Abdominal Aneurysm Screening", "abnominalAneurysmScreening"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 16,
      },

      {
        id: 10,
        name: "recommendationsHepatitis",
        label: "",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["Hepatitis C Screening", "hepatitisCScreening"]],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 16,
      },
    ],
  },
  {
    sectionId: 9,
    sectionName: "Allergies/Medication",
    isEditable: false,
    questions: [
      {
        id: 6,
        name: "allergies",
        type: "options_tree",
        label: "35. Allergies",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 11,
                name: "allergiesDatatable",
                label: "Allergies",
                editToggle: false,
                type: "datatable",
                tablelist: [
                  [
                    {
                      id: 1,
                      label: "Allergies",
                      name: "HRAForm_Allergies",
                      columns: [
                        {
                          label: "Substance",
                          type: "text",
                        },
                        {
                          label: "Reaction",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    true,
                  ],
                  [
                    {
                      id: 2,
                      label: "BMI Index Chart",
                      name: "HRAForm_BMI_Index_Chart",
                      columns: [
                        {
                          label: "BMI",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 3,
                      label: "Counter Medications",
                      name: "HRAForm_Counter_Medications",
                      columns: [
                        {
                          label: "Date",
                          type: "date",
                        },
                        {
                          label: "Description",
                          type: "text",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 4,
                      label: "Medical History Specialists",
                      name: "HRAForm_Medical_History_Speciality",
                      columns: [
                        {
                          label: "Member Specialist",
                          type: "select",
                        },
                        {
                          label: "Speciality",
                          type: "text",
                        },
                        {
                          label: "For",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 5,
                      label: "Medications",
                      name: "HRAForm_Medications",
                      columns: [
                        {
                          label: "Diagnoses",
                          type: "text",
                        },
                        {
                          label: "label Name",
                          type: "select",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "select",
                        },
                        {
                          label: "Prescribing Physician",
                          type: "text",
                        },
                        {
                          label: "Status",
                          type: "togglebutton",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 6,
                      label: "Mini Cog Versions",
                      name: "HRAForm_Minicog_Version",
                      columns: [
                        {
                          label: "Version 1",
                          type: "text",
                        },
                        {
                          label: "Version 2",
                          type: "text",
                        },
                        {
                          label: "Version 3",
                          type: "text",
                        },
                        {
                          label: "Version 4",
                          type: "text",
                        },
                        {
                          label: "Version 5",
                          type: "text",
                        },
                        {
                          label: "Version 6",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 7,
                      label: "Preventive Care",
                      name: "HRAForm_Preventive_Care",
                      columns: [
                        {
                          label: "Screen",
                          type: "text",
                        },
                        {
                          label: "Answer",
                          type: "select",
                        },
                        {
                          label: "Date",
                          type: "text",
                        },
                        {
                          label: "Method",
                          type: "text",
                        },
                        {
                          label: "Recommendation",
                          type: "checkbox",
                        },
                        {
                          label: "Education Completed",
                          type: "checkbox",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 8,
                      label: "Previously Documented Conditions",
                      name: "HRAForm_Previously_Documented_Conditions",
                      columns: [
                        {
                          label: "Diagnosis",
                          type: "text",
                        },
                        {
                          label: "Prior HCC",
                          type: "text",
                        },
                        {
                          label: "Diagnosis Code",
                          type: "text",
                        },
                        {
                          label: "Date of Service",
                          type: "date",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 9,
                      label: "Prior Assessments",
                      name: "HRAForm_Prior_Assessments",
                      columns: [
                        {
                          label: "Date Time",
                          type: "date",
                        },
                        {
                          label: "Document",
                          type: "button",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 10,
                      label: "Stages Of Kidney Disease",
                      name: "HRAForm_Stage_Of_Kidney_Disease",
                      columns: [
                        {
                          label: "Stages",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 11,
                      label: "Alcohol Usage",
                      name: "HRAForm_Alcohol_Usage",
                      columns: [
                        {
                          label: "How many drinks",
                          type: "select",
                        },
                        {
                          label: "How often",
                          type: "select",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 12,
                      name: "Family History",
                      columns: [
                        {
                          label: "Family Member",
                          type: "select",
                        },
                        {
                          label: "Medical Condition",
                          type: "text",
                        },
                        {
                          label: "Cause of Death",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                ],
                xs: 12,
                required: "no",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes ",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No ",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 1,
        editToggle: false,
      },
      {
        id: 7,
        name: "medications",
        type: "label",
        label: "Medications",
        editToggle: false,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 11,
        name: "medications",
        label: "Medications",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "HRAForm_BMI_Index_Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            true,
          ],
          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 3,
      },
      {
        id: 6,
        name: "otcmSupplements",
        type: "options_tree",
        label: "36. Over the Counter Medications / Supplements",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 11,
                name: "dataTable",
                label: "Data Table",
                editToggle: false,
                type: "datatable",
                tablelist: [
                  [
                    {
                      id: 1,
                      label: "Allergies",
                      name: "HRAForm_Allergies",
                      columns: [
                        {
                          label: "Substance",
                          type: "text",
                        },
                        {
                          label: "Reaction",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 2,
                      label: "BMI Index Chart",
                      name: "HRAForm_BMI_Index_Chart",
                      columns: [
                        {
                          label: "BMI",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 3,
                      label: "Counter Medications",
                      name: "HRAForm_Counter_Medications",
                      columns: [
                        {
                          label: "Date",
                          type: "date",
                        },
                        {
                          label: "Description",
                          type: "text",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    true,
                  ],
                  [
                    {
                      id: 4,
                      label: "Medical History Specialists",
                      name: "HRAForm_Medical_History_Speciality",
                      columns: [
                        {
                          label: "Member Specialist",
                          type: "select",
                        },
                        {
                          label: "Speciality",
                          type: "text",
                        },
                        {
                          label: "For",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 5,
                      label: "Medications",
                      name: "HRAForm_Medications",
                      columns: [
                        {
                          label: "Diagnoses",
                          type: "text",
                        },
                        {
                          label: "label Name",
                          type: "select",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "select",
                        },
                        {
                          label: "Prescribing Physician",
                          type: "text",
                        },
                        {
                          label: "Status",
                          type: "togglebutton",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 6,
                      label: "Mini Cog Versions",
                      name: "HRAForm_Minicog_Version",
                      columns: [
                        {
                          label: "Version 1",
                          type: "text",
                        },
                        {
                          label: "Version 2",
                          type: "text",
                        },
                        {
                          label: "Version 3",
                          type: "text",
                        },
                        {
                          label: "Version 4",
                          type: "text",
                        },
                        {
                          label: "Version 5",
                          type: "text",
                        },
                        {
                          label: "Version 6",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 7,
                      label: "Preventive Care",
                      name: "HRAForm_Preventive_Care",
                      columns: [
                        {
                          label: "Screen",
                          type: "text",
                        },
                        {
                          label: "Answer",
                          type: "select",
                        },
                        {
                          label: "Date",
                          type: "text",
                        },
                        {
                          label: "Method",
                          type: "text",
                        },
                        {
                          label: "Recommendation",
                          type: "checkbox",
                        },
                        {
                          label: "Education Completed",
                          type: "checkbox",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 8,
                      label: "Previously Documented Conditions",
                      name: "HRAForm_Previously_Documented_Conditions",
                      columns: [
                        {
                          label: "Diagnosis",
                          type: "text",
                        },
                        {
                          label: "Prior HCC",
                          type: "text",
                        },
                        {
                          label: "Diagnosis Code",
                          type: "text",
                        },
                        {
                          label: "Date of Service",
                          type: "date",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 9,
                      label: "Prior Assessments",
                      name: "HRAForm_Prior_Assessments",
                      columns: [
                        {
                          label: "Date Time",
                          type: "date",
                        },
                        {
                          label: "Document",
                          type: "button",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 10,
                      label: "Stages Of Kidney Disease",
                      name: "HRAForm_Stage_Of_Kidney_Disease",
                      columns: [
                        {
                          label: "Stages",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 11,
                      label: "Alcohol Usage",
                      name: "HRAForm_Alcohol_Usage",
                      columns: [
                        {
                          label: "How many drinks",
                          type: "select",
                        },
                        {
                          label: "How often",
                          type: "select",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 12,
                      name: "Family History",
                      columns: [
                        {
                          label: "Family Member",
                          type: "select",
                        },
                        {
                          label: "Medical Condition",
                          type: "text",
                        },
                        {
                          label: "Cause of Death",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 13,
                      label: "Depression Severity",
                      name: "Depression_Severity",
                      columns: [
                        {
                          label: "Score",
                          type: "number",
                        },
                        {
                          label: "Depression Severity",
                          type: "checkbox",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                ],
                xs: 12,
                required: "no",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 2,
        editToggle: false,
        depth: 1,
      },

      {
        id: 6,
        name: "longtermuseof",
        type: "options_tree",
        label: "Long Term Use of:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "ASA",
            value: "asa",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Steroids",
            value: "steroids",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Insulin",
            value: "insulin",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "describe",
                type: "options_tree",
                label: "Describe",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Coumadin",
                    value: "coumadin",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Thrombin Inhibitors(Paradaxa)",
                    value: "thrombin_inhibitors(paradaxa)",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Plavix",
                    value: "plavix",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Factor Xa Inhibitors(Xarelto,Eliquis)",
                    value: "factor_xa_inhibitors(xarelto,eliquis)",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 1,
                        name: "describe",
                        type: "text",
                        label: "Describe",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 4,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Anticoagulants",
            value: "anticoagulants",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Statins",
            value: "statins",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 6,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Biphosphonate",
            value: "biphosphonate",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 7,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "None",
            value: "none",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 4,
        editToggle: false,
      },
      {
        id: 7,
        name: "medication_compliance_and_knowledge_of_use_and_disease",
        type: "label",
        label: "Medication Compliance and Knowledge of Use and Disease",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 5,
      },
      {
        id: 6,
        name: "doyoueverforgettotakeyourmedicine",
        type: "options_tree",
        label: "1. Do you ever forget to take your medicine?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 6,
        editToggle: false,
      },
      {
        id: 6,
        name: "doyousometimesnotpayenoughattentiontoyourmedication",
        type: "options_tree",
        label:
          "2. Do you sometimes not pay enough attention to your medication?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 7,
        editToggle: false,
      },
      {
        id: 6,
        name: "doyouknowthelongtermbenefitoftakingyourmedicineastoldtoyoubythedoctororpharmacist",
        type: "options_tree",
        label:
          "3. Do you know the longterm benefit of taking your medicine as told to you by the doctor or pharmacist?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 8,
        editToggle: false,
      },
      {
        id: 6,
        name: "whenyoufeelbetterdoyousometimesstoptakingyourmedicine",
        type: "options_tree",
        label:
          "4. When you feel better do you sometimes stop taking your medicine?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 9,
        editToggle: false,
      },
      {
        id: 6,
        name: "sometimesifyoufeelworsewhenyoutakeyourmedicinedoyoustoptakingit",
        type: "options_tree",
        label:
          "5. Sometimes if you feel worse when you take your medicine do you stop taking it?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 10,
        editToggle: false,
      },
      {
        id: 6,
        name: "doyousometimesforgettorefillyourprescriptionontime",
        type: "options_tree",
        label:
          "6. Do you sometimes forget to refill your prescription on time?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 11,
        editToggle: false,
      },
      {
        id: 10,
        name: "recommendationsDoctor",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Discuss options with your Doctor and/or pharmacist to improve medication adherance",
            "discuss_options_with_your_doctor_and/or_pharmacist_to_improve_medication_adherance",
          ],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 10,
        name: "recommendationsSide",
        label: "",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Discuss medication side effects with your Doctor",
            "discuss_medication_side_effects_with_your_doctor",
          ],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 10,
        name: "recommendationsOtherss",
        label: "            ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["Other", "other"]],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 10,
        name: "horizontal_line",
        label: "Horizontal Line",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "line",
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: false,
        isModified: false,
      },

      {
        id: 10,
        name: "educatedonImportance",
        label: "            ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Educated on importance of medication compliance, member verbalizes understanding",
            "Educated_on_importance_of_medication_compliance,member_verbalizes_understanding",
          ],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
    ],
  },
  {
    sectionId: 10,
    sectionName: "Review Of Systems And Diagnoses",
    isEditable: false,
    questions: [
      {
        id: 9,
        name: "eyes",
        type: "heading",
        label: "Eyes",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 6,
        name: "ros_eyes",
        type: "options_tree",
        label:
          "Eye Problems (Glaucoma, Cataracts, Macular Degeneration, Blindness, Retinal Detachment, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_eyesDiagnoses",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagCataractSubWhEye",
                        type: "options_tree",
                        label: "Cataracts Which Eye ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both",
                            value: "both",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagCataractSubDesc",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagCataractSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagCataractSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagCataractSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagCataractSubDiabetes",
                        type: "options_tree",
                        label: "Secondary to Diabetes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isExclusive: false,
                    label: "Cataracts",
                    value: "cataracts",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagGlaucomaSubWhEye",
                        type: "options_tree",
                        label: "Glaucoma Which Eye ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye ",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye ",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both ",
                            value: "both_",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagGlaucomaSubDesc",
                        type: "options_tree",
                        label: "Describe ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active ",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out ",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagGlaucomaSubSupp",
                        type: "options_tree",
                        label: "Supported by ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagGlaucomaSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms ",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings ",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications ",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results ",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies ",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy ",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME ",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagGlaucomaSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "other ",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagGlaucomaDiabetes",
                        type: "options_tree",
                        label: "Secondary to Diabetes ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagGlaucomaSubDiabetesSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: false,
                                isModified: false,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    label: "Open Wide Angle",
                                    value: "openWideAngle",
                                    isConfigured: true,
                                    type: "toggle_button",
                                    // isConfigured: false,
                                    // isModified: false,
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Closed Narrow Angle",
                                    value: "closedNarrowAngle",
                                    isConfigured: true,
                                    type: "toggle_button",
                                    // isConfigured: false,
                                    // isModified: false,
                                  },
                                ],
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes ",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Glaucoma",
                    value: "glaucoma",
                    type: "toggle_button",
                    isExclusive: false,
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagHyperopiaSubWhEye",
                        type: "options_tree",
                        label: "Hyperopia Which Eye ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both",
                            value: "both",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagHyperopiaSubDesc",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active  ",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of  ",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out  ",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagHyperopiaSubSupp",
                        type: "options_tree",
                        label: "Supported by  ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagHyperopiaSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings  ",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications  ",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results  ",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies  ",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy  ",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagLegalBlindSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "other  ",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagHyperopiaSubSupp",
                        type: "options_tree",
                        label: "Secondary to Diabetes  ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes  ",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No  ",
                            value: "no",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hyperopia",
                    value: "hyperopia",
                    isExclusive: false,
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagLegalBlindSubWhEye",
                        type: "options_tree",
                        label: "Legally Blind Which Eye ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both",
                            value: "both",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagLegalBlindSubDesc",
                        type: "options_tree",
                        label: "Describe   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active   ",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of   ",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out   ",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagLegalBlindSubSupp",
                        type: "options_tree",
                        label: "Supported by   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History   ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagLegalBlindSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms   ",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings   ",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications   ",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results   ",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies   ",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy   ",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME   ",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagLegalBlindSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "other   ",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "secondary_to_diabetes___",
                        type: "options_tree",
                        label: "Secondary to Diabetes   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes   ",
                            value: "yes___",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No   ",
                            value: "no___",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    isExclusive: false,
                    label: "Legally Blind",
                    value: "legallyBlind",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagMascDegenSubWhEye",
                        type: "options_tree",
                        label: "Macular Degeneration Which Eye ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both",
                            value: "both",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagMascDegenSubDesc",
                        type: "options_tree",
                        label: " Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagMascDegenSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagMascDegenSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " DME",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagMascDegenSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "other",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagMascDegenSubDesc",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Wet",
                            value: "wet",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Dry",
                            value: "dry",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    isExclusive: false,
                    label: "Macular Degeneration",
                    value: "mascularDegeneration",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagMyopiaSubWhEye",
                        type: "options_tree",
                        label: "Myopia Which Eye ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both",
                            value: "both",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagMyopiaSubDesc",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagMyopiaSubSupp",
                        type: "options_tree",
                        label: "  Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagMyopiaSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagMyopiaSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "__secondary_to_diabetes",
                        type: "options_tree",
                        label: "  Secondary to Diabetes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "  Yes",
                            value: "__yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "  No",
                            value: "__no",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Myopia",
                    value: "myopia",
                    isExclusive: false,
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagRetinalSubWhEye",
                        type: "options_tree",
                        label: "Retinal Disease Which Eye ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both",
                            value: "both",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagRetinalSubDesc",
                        type: "options_tree",
                        label: "Describe     ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagRetinalSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagRetinalSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagRetinalSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "other",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagRetinalSubDiabetes",
                        type: "options_tree",
                        label: "Secondary to Diabetes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagRetinalSubDiabetesSubIsVitHemm",
                                type: "options_tree",
                                label: "Vitreous Hemorrhage",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Yes",
                                    value: "yes",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "No",
                                    value: "no",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    isExclusive: false,
                    label: "Retinal Disease",
                    value: "retinalDisease",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eyesDiagOtherSubWhEye",
                        type: "options_tree",
                        label: "Others Which Eye",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right Eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left Eye",
                            value: "leftEye",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Both",
                            value: "both",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagOtherSubDesc",
                        type: "options_tree",
                        label: "    Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagOtherSubSupp",
                        type: "options_tree",
                        label: "    Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_eyesDiagOtherSubSuppSympSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pain",
                                    value: "pain",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Dry Eye",
                                    value: "dryEye",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Tearing",
                                    value: "tearing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Problem Seeing",
                                    value: "problemSeeing",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Floaters",
                                    value: "floaters",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 4,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_eyesDiagOtherSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "other",
                            value: "other",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "_____secondary_to_diabetes",
                        type: "options_tree",
                        label: "     Secondary to Diabetes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eyesDiagOtherSubOther",
                        type: "text",
                        label: "Other",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: false,
                        subQuestions: [],
                        subQuestionId: 5,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Others",
                    value: "others",
                    isExclusive: false,
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            name: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            id: 6,
            type: "toggle_button",
            label: "No",
            fullWidth: true,
            required: true,
            xs: 12,
            isCommentEnabled: true,
            isConfigured: true,
            isModified: true,
            hasSubQuestions: false,
            subQuestions: [{}],
            subQuestionId: 2,
            depth: 4,
            editToggle: false,
            value: "no",
            name: "no",
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 9,
        name: "ears",
        type: "heading",
        label: "Ears",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 3,
      },
      {
        id: 6,
        name: "ros_ears",
        type: "options_tree",
        label: "Ear Problems (Hard of hearing, Deaf, Vertigo, Ear Infections)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_earsDiagnoses",
                type: "options_tree",
                label: "Diagnoses  ",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_earsDiagDiffHearSubDesc",
                        type: "options_tree",
                        label: "Difficulty with Hearing Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_earsDiagDiffHearSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hearing aids",
                            value: "hearingAids",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Reading lips",
                            value: "readingLips",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_earsDiagDiffHearSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Difficulty with hearing",
                    value: "difficultyHearing",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_earsDiagLegalDeafSubDesc",
                        type: "options_tree",
                        label: "Legally Deaf Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_earsDiagLegalDeafSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_earsDiagLegalDeafSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 6,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Legally Deaf",
                    value: "legallyDeaf",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_earsDiagTinnitusSubDesc",
                        type: "options_tree",
                        label: "Tinnitus Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_earsDiagTinnitusSubSupp",
                        type: "options_tree",
                        label: "Supported By",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_earsDiagTinnitusSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Tinnitus",
                    value: "tinnitus",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_earsDiagTinnitusSubDesc",
                        type: "options_tree",
                        label: "Vertigo Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_earsDiagTinnitusSubSupp",
                        type: "options_tree",
                        label: "Supported  By",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_earsDiagVertigoSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_earsDiagVertigoSubLoseBal",
                        type: "options_tree",
                        label: "Do you lose your balance",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Vertigo",
                    value: "vertigo",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_earsDiagOtherSubDesc",
                        type: "options_tree",
                        label: "Other Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_earsDiagOtherSubSupp",
                        type: "options_tree",
                        label: "supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            depth: 5,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_earsDiagOtherSubSuppOtherSubDesc",
                                type: "text",
                                label: "Other",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_earsDiagOtherSubOther",
                        type: "text",
                        label: "Other",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: false,
                        subQuestions: [],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            id: 6,
            type: "toggle_button",
            label: "No",
            fullWidth: true,
            required: true,
            xs: 12,
            isCommentEnabled: true,
            isConfigured: true,
            isModified: true,
            hasSubQuestions: false,
            subQuestions: [{}],
            subQuestionId: 2,
            depth: 4,
            editToggle: false,
            value: "no",
          },
        ],
        questionId: 4,
        editToggle: false,
      },
      {
        id: 9,
        name: "nose",
        type: "heading",
        label: "Nose",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 5,
      },
      {
        id: 6,
        name: "ros_nose",
        type: "options_tree",
        label: "Nose Problems (Nose Bleeds, Sinus infections, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_noseDiagnoses",
                type: "options_tree",
                label: "Diagnoses   ",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_noseDiagAllergySubDesc",
                        type: "options_tree",
                        label: "Allergic Rhinitis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_noseDiagAllergySubSupp",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_noseDiagAllergySubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Allergic Rhinitis",
                    value: "allergicRhinitis",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_noseDiagChronicNasalSubDesc",
                        type: "options_tree",
                        label: "Chronic Post Nasal Drip Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_noseDiagChronicNasalSubSupp",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_noseDiagChronicNasalSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Chronic Post Nasal Drip",
                    value: "chronicNasalDrip",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_noseDiagNoseBleedSub",
                        type: "options_tree",
                        label: "Nose Bleeds Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nose Bleeds",
                    value: "noseBleeds",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_noseDiagSeasonAllergySubDesc",
                        type: "options_tree",
                        label: "Seasonal Allergies Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_noseDiagSeasonAllergySubSupp",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_noseDiagSeasonAllergySubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Seasonal Allergies",
                    value: "seasonalAllergies",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_noseDiagSinusInfectSubDesc",
                        type: "options_tree",
                        label: "Sinus infections Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_noseDiagSinusInfectSubSupp",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_noseDiagSinusInfectSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_noseDiagSinusInfectSubExu",
                        type: "options_tree",
                        label: "Exu Date",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Clear",
                            value: "clear",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Purulent",
                            value: "purulent",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Sinus infections",
                    value: "sinusInfections",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_noseDiagSinustisSubDesc",
                        type: "options_tree",
                        label: "Sinusitis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_noseDiagSinustisSubSupp",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_noseDiagSinustisSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Sinusitis",
                    value: "sinusitis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_noseDiagOtherSubDesc",
                        type: "options_tree",
                        label: "Other Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_noseDiagOtherSubSupp",
                        type: "options_tree",
                        label: " Supported by ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_noseDiagOtherSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "other",
                        type: "text",
                        label: "Other",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            id: 6,
            type: "toggle_button",
            label: "No",
            fullWidth: true,
            required: true,
            xs: 12,
            isCommentEnabled: true,
            isConfigured: true,
            isModified: true,
            hasSubQuestions: false,
            subQuestions: [{}],
            subQuestionId: 2,
            editToggle: false,
            value: "no",
          },
        ],
        questionId: 6,
        editToggle: false,
      },
      {
        id: 9,
        name: "mouth_&_throat",
        type: "heading",
        label: "Mouth & Throat",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 7,
      },
      {
        id: 6,
        name: "ros_mouth",
        type: "options_tree",
        label:
          "Mouth and Throat Problems (Difficulty Chewing, Difficulty Swallowing, Bleeding Gums, Other )",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_mouthDiag",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_mouthDiagBleedGumsSub",
                        type: "options_tree",
                        label: "Bleeding Gums Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Bleeding Gums",
                    value: "bleedingGums",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_mouthDiagDiffChewSubDesc",
                        type: "options_tree",
                        label: "Difficulty Chewing Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_mouthDiagDiffChewSubBcsPain",
                        type: "options_tree",
                        label: "Because of pain",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_mouthDiagDiffChewSubBcsPainSub",
                                type: "options_tree",
                                label: "Have Dentures",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Yes",
                                    value: "yes",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "No",
                                    value: "no",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Difficulty Chewing",
                    value: "difficultyChewing",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_mouthDiagDiffSwallowSubDesc",
                        type: "options_tree",
                        label: "Difficulty Swallowing Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_mouthDiagDiffSwallowSubHadStroke",
                        type: "options_tree",
                        label: "Have you had a stroke",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub",
                                type: "options_tree",
                                label: "Do you eat a special diet",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Yes",
                                    value: "yes",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "No",
                                    value: "no",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Difficulty Swallowing",
                    value: "difficultySwallowing",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_mouthDiagOtherSubDesc",
                        type: "options_tree",
                        label: "Other Describe ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_mouthDiagOtherSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Phsyical Findings",
                            value: "phsyicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_mouthDiagOtherSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "ros_mouthDiagOtherSubOther",
                        type: "text",
                        label: "Describe",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 8,
        editToggle: false,
      },
      {
        id: 9,
        name: "neck",
        type: "heading",
        label: "Neck",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 9,
      },
      {
        id: 6,
        name: "ros_neck",
        type: "options_tree",
        label: "Neck Problems (parotid Disease, Carotid Stenosis, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_neckDiag",
                type: "options_tree",
                label: "Diagnoses        ",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neckDiagCarotidSubDesc",
                        type: "options_tree",
                        label: "Carotid Stenosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neckDiagCarotidSubSupp",
                        type: "options_tree",
                        label: "Supported By",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Bruits",
                            value: "bruits",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hitsory of TIAs",
                            value: "historyOfTia",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Laboratory studies",
                            value: "laboratoryStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neckDiagCarotidSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neckDiagCarotidCsDesc",
                        type: "options_tree",
                        label: "Describe   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right",
                            value: "right",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left",
                            value: "left",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Bilateral",
                            value: "bilateral",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Carotid Stenosis",
                    value: "carotidStenosis",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neckDiagParotidSubDesc",
                        type: "options_tree",
                        label: "Parotid Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neckDiagParotidSubSupp",
                        type: "options_tree",
                        label: "   Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neckDiagParotidSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Parotid Disease",
                    value: "parotidDisease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neckDiagOtherSubDesc",
                        type: "options_tree",
                        label: "   Other Describe   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neckDiagOtherSubSupp",
                        type: "options_tree",
                        label: "    Supported By ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neckDiagOtherSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "ros_neckDiagOtherSubOther",
                        type: "text",
                        label: "Describe",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            id: 6,
            type: "toggle_button",
            label: "No",
            fullWidth: true,
            required: true,
            xs: 12,
            isCommentEnabled: true,
            isConfigured: true,
            isModified: true,
            hasSubQuestions: false,
            subQuestions: [{}],
            subQuestionId: 2,
            editToggle: false,
            value: "no",
          },
        ],
        questionId: 10,
        editToggle: false,
      },
      {
        id: 10,
        name: "ros_eyeENTNeckRecommendations",
        label: "Recommendations",
        editToggle: false,
        type: "checkbox",
        options: [
          ["Hearing Evaluation", "hearingEvaluation"],
          ["Dental Exam", "dentalExam"],
          ["Eye Exam", "eyeExam"],
          ["Swallowing evaluation", "swallowingEvaluation"],
          ["Take medications as prescribed", "takeMedications"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 13,
      },
      {
        id: 9,
        name: "respiratory",
        type: "heading",
        label: "Respiratory",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 11,
      },
      {
        id: 6,
        name: "ros_respiratory",
        type: "options_tree",
        label:
          "Respiratory Problems (COPD, Emphysema, Asthma, Chronic Bronchitis Pneumonia, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            editToggle: false,
            isCommentEnabled: false,
            //name:"respiratoryYes",
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            subQuestions: [
              {
                id: 6,
                name: "ros_respiratoryDiagnoses",
                value: "diagnoses",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    name: "ros_acutePulmonarySubAnswer",
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    label: " Acute Pulmonary Embolism ",
                    value: "acutePulmonary",
                    isConfigured: true,
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_acutePulmonarySubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Acute Pulmonary Embolism   Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active (in past 6 months)",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_acutePulmonarySubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " Hospitalization for Pulmonary Embolism ",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " CT Angiogram ",
                            value: "ctAngiogram",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Venous Doppler ",
                            value: "venousDoppler",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " D-dimer ",
                            value: "dDimer",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " VQ scan ",
                            value: "vqScan",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Use of anticoagulation ",
                            value: "useOfAnticoagulation",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Shortness of breath ",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Wheezing ",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Chronic cough ",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Acute Upper Respiratory Infection ",
                    name: "ros_acuteUpperSubAnswer",
                    value: "acuteUpper",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_acuteUpperSubAnswerDescribe",
                        type: "options_tree",
                        label: "Acute Upper Respiratory Infection   Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_acuteUpperSubAnswerSupportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Fever",
                            value: "fever",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chills",
                            value: "chills",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cough",
                            value: "cough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Decreased Breathe Sounds ",
                            value: "decreasedBreathe",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rales",
                            value: "rales",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest X-ray",
                            value: "chestXRay",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breathe",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Chronic cough ",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Asthma ",
                    value: "asthma",
                    name: "ros_asthmaSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_asthmaSubAnswerDescribe",
                        type: "options_tree",
                        label: "Asthma Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_asthmaSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Wheezing",
                            value: "wheezing",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cyanosis",
                            value: "cyanosis",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of Bronchodialtor",
                            value: "useOfBronchodilator",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Use of Inhaled or oral steroids ",
                            value: "useOfInhaled",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Use of ventilator ",
                            value: "useOfVentilator",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness Of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_asthmaPatientController",
                        type: "options_tree",
                        value: "patientController",
                        label: "Is patient on controller medications",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            name: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            name: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_asthmaRescueMedications",
                        type: "options_tree",
                        value: "rescueMedications",
                        label: "Does patient use rescue medications",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 8,
                        name: "ros_asthmaCurrentExacerbation",
                        type: "options_tree",
                        value: "currentExacerbation",
                        label: "Does patient have current exacerbation",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                name: "ros_supported_bty",

                                type: "options_tree",
                                label: "Supported By",
                                editToggle: false,
                                xs: 4,
                                required: false,
                                value: "supported_bty",
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 4,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "wheezing",
                                    label: "Wheezing",
                                    subQuestions: [],
                                    editToggle: false,
                                    value: "wheezing",
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "shortness_breathe",
                                    label: "Shortness Of Breathe",
                                    value: "shortness_breathe",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "rescue_medications",
                                    label: "Use of rescue medications",
                                    value: "ruleOut",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "use_ventilator",
                                    label: "Use of Ventilator",
                                    value: "ruleOut",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Chronic Pulmonary Embolism ",
                    value: "ros_chronicPulmonaryEmbolism",
                    name: "ros_chronicPulmonaryEmbolismSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_chronicPulmonaryEmbolismSubAnswerDescribe",
                        type: "options_tree",
                        label: "Chronic Pulmonary Embolism Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_chronicPulmonaryEmbolismSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History of Pulmonary Embolism ",
                            value: "historyOfPul",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Insertion of Vena Cava Filter ",
                            value: "insersionOfVena",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Anticoagulation beyond six months ",
                            value: "anticoagulation",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Shortness of breath ",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Chronic Respiratory Failure ",
                    value: "chronicRespiratoryFailure",
                    name: "ros_chronicRespiratoryFailureSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_chronicRespiratoryFailureSubAnswerDescribe",
                        type: "options_tree",
                        label: "Chronic Respiratory Failure Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_chronicRespiratoryFailureSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label:
                              " History of hospitalization with Respiratory Failure ",
                            value: "historyOfHospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Chronic use of O2 at >2L/min ",
                            value: "chronicUseOfO2",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " CO2 Retention ",
                            value: "carbonDioxideRetention",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Shortness of breath ",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of ventilator",
                            value: "useOfVentilator",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Chronic Sputum Production ",
                    value: "chronicSputumFailure",
                    name: "ros_chronicSputumFailureSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_chronicSputumFailureSubAnswerDescribe",
                        type: "options_tree",
                        label: "Chronic Sputum Production Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_chronicSputumFailureSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "shortness of breathe",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " COPD ",
                    value: "copd",
                    name: "ros_copdSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_copdSubAnswerDescribe",
                        type: "options_tree",
                        label: "COPD Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_copdSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "  Use of accessary muscles  ",
                            value: "xuseOfAccessaryMuscles",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Barel Chest",
                            value: "barrelChest",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "XR resules",
                            value: "xrResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Clubbing",
                            value: "clubbing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Decreased or prolonged breath sounds ",
                            value: "decreasedOrProlongedSounds",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Dyspnea on exertion ",
                            value: "dyspneaOnExertion",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "O2 use",
                            value: "oxygenUse",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Bronchodilator medication ",
                            value: "bronchodilatorMedication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breathe",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_patientChronicBronchitis",
                        type: "options_tree",
                        label:
                          "Has patient been told they have Chronic Bronchitis",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            name: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            name: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_patientHaveEmphysema",
                        type: "options_tree",
                        label: "Has patient been told they have Emphysema",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 8,
                        name: "ros_patientBronchodilator",
                        type: "options_tree",
                        label: "Is patient on Bronchodilator",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                name: "copdSubAnswerRouteIs",
                                type: "options_tree",
                                label: "Route is",
                                editToggle: false,
                                xs: 4,
                                required: false,
                                value: "routeIs",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "inhaled",
                                    label: "Inhaled",
                                    subQuestions: [],
                                    editToggle: false,
                                    value: "wheezing",
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    name: "shortness_breathe",
                                    label: "nebulixer",
                                    value: "Nebulizer",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "oral",
                                    label: "Oral",
                                    value: "oral",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 9,
                        name: "ros_patientSteroids",
                        type: "options_tree",
                        label: "Is patient on Steroids",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "",

                                type: "options_tree",
                                label: "Route is",
                                editToggle: false,
                                xs: 4,
                                required: false,
                                value: "supported_bty",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 4,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "inhaled",
                                    label: "Inhaled",
                                    subQuestions: [],
                                    editToggle: false,
                                    value: "wheezing",
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "shortness_breathe",
                                    label: "nebulixer",
                                    value: "Nebulizer",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    name: "oral",
                                    label: "Oral",
                                    value: "ruleOut",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 10,
                        name: "ros_patientExacerbation",
                        type: "options_tree",
                        label: "Does patient have current exacerbation",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "",

                                type: "options_tree",
                                label: "Supported by",
                                editToggle: false,
                                xs: 4,
                                required: false,
                                value: "",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 4,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    label: "use of antibiotics",
                                    subQuestions: [],
                                    editToggle: false,
                                    value: "",
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    label: "Fever",
                                    value: "fever",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    label: "Increased sputum production",
                                    value: "",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 5,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    label: "Adventitious sounds on lung exam",
                                    value: "",
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Cystic Fibrosis ",
                    value: "cysticFibrosis",
                    name: "ros_cysticFibrosisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cysticFibrosisSubAnswerDescribe",
                        type: "options_tree",
                        label: "Cystic Fibrosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cysticFibrosisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "shortness of breathe",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Hypoventilation secondary to Obesity ",
                    value: "hypoventilation",
                    name: "ros_hypoventilationSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_hypoventilationSubAnswerDescribe",
                        type: "options_tree",
                        label: "Hypoventilation secondary to Obesity Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_hypoventilationSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Morbid obesity ",
                            value: "morbidObesity",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of O2",
                            value: "useOfOxygen",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "CO2 Retention",
                            value: "carbonDioxideRetention",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness Of Breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Hypoxemia ",
                    value: "hypoxemia",
                    name: "ros_hypoxemiaSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_hypoxemiaSubAnswerDescribe",
                        type: "options_tree",
                        label: "Hypoxemia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_hypoxemiaSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " O2 saturation of <90% on room air ",
                            value: "oxygenSaturation",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Shortness of breath ",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Pneumonia ",
                    value: "pneumonia",
                    name: "ros_pneumoniaSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_pneumoniaSubAnswerDescribe",
                        type: "options_tree",
                        label: "Pneumonia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_pneumoniaSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab studies",
                            value: "labStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_pneumoniaSubAnswerEtiology",
                        type: "options_tree",
                        label: "Etiology",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Viral",
                            value: "viral",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Pneumococcal",
                            value: "pneumococcal",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Staph",
                            value: "staph",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "other bacterial",
                            value: "otherBacterial",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Aspiration",
                            value: "",
                            name: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_historyOfLungAbscess",
                        type: "options_tree",
                        label: "History / finding of Lung abscess",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 8,
                        name: "ros_historyOfEmpyema",
                        type: "options_tree",
                        label: "History / finding of Empyema",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            hasSubQuestions: false,
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Pulmonary Fibrosis ",
                    value: "pulmonaryFibrosis",
                    name: "ros_pulmonaryFibrosisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_pulmonaryFibrosisSubAnswerDescribe",
                        type: "options_tree",
                        label: "Pulmonary Fibrosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_pulmonaryFibrosisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "X-ray or CT results",
                            value: "xRay",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "PFt",
                            value: "pft",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Respirator Dependence/ Tracheostomy Status ",
                    value: "respiratorDependence",
                    name: "ros_respiratorDependenceSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_respiratorDependenceSubAnswerDescribe",
                        type: "options_tree",
                        label:
                          "Respirator Dependence/ Tracheostomy Status Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_respiratorDependenceSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 13,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 13,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Respiratory Arrest ",
                    value: "respiratoryArrest",
                    name: "ros_respiratoryArrestSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_respiratoryArrestSubAnswerDescribe",
                        type: "options_tree",
                        label: "Respiratory Arrest Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active(in past 3 months)",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_respiratoryArrestSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label:
                              "History of hospitalization with respiratory arrest",
                            value: "historyOfHospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of ventilator",
                            value: "useOfVentilator",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "CO2 Retention",
                            value: "carbonDioxideRetention",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Sarcoidosis ",
                    value: "sarcoidosis",
                    name: "ros_sarcoidosisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_sarcoidosisSubAnswerDescribe",
                        type: "options_tree",
                        label: "Sarcoidosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_sarcoidosisSubAnswerSpecify",
                        type: "options_tree",
                        label: " Specify",
                        value: "specify",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lung",
                            value: "lung",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lymph nodes",
                            value: "lymphNodes",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "skin",
                            value: "skin",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "cranial nerves",
                            value: "cranialNerves",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "unknown",
                            value: "unKnown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_sarcoidosisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Diagnotic results",
                            value: "diagnosticResults",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cutaneous lesions",
                            value: "cutaneousLesions",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 16,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Sleep Apnea ",
                    value: "sleepApnea",
                    name: "ros_sleepApneaSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_sleepApneaSubAnswerDescribe",
                        type: "options_tree",
                        label: "Sleep Apnea Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_sleepApneaSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Use of CPAP",
                            value: "useOfCpap",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Positive sleep studies",
                            value: "positiveSleepStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of sleepiness during the day",
                            value: "historyOfSleepiness",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "heavy snooring/restlessness during sleep",
                            value: "heavySnoring",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 17,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Other ",
                    value: "other",
                    name: "ros_otherSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_otherSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Other Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_otherSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symphtoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 13,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_otherSubAnswerOther",
                        depth: 3,
                        type: "text",
                        label: "Other",
                        value: "other",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            // name:"respiratoryNo",
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 15,
        editToggle: false,
      },
      {
        id: 10,
        name: "respiratory_Recommendations",
        value: "takeMedicationsAsPescribed",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Take medications as prescribed", "takeMedicationsAsPescribed"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 55,
      },
      {
        id: 9,
        name: "cardiovascular",
        type: "heading",
        label: "Cardiovascular",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 14,
        depth: 1,
      },
      {
        id: 6,
        name: "ros_cardio",
        type: "options_tree",
        label:
          "Cardiovascular (Hypertension, Angina, Ischemic Heart Disease(CAD), Myocardial Infarction, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_cardioSubDiagnoses",
                type: "options_tree",
                label: " Diagnoses         ",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesabnormalCardiacRhythmDes",
                        type: "options_tree",
                        label: "Abnormal Cardiac Rhythm   Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesabnormalCardiacRhythmSup",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "ECG",
                            value: "ecg",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "ecg",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of rate controlling drug",
                            value: "useofratecontrollingdrug",
                            type: "toggle_button",
                            name: "useOfRateControllingDrug",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of anticoagulation",
                            value: "useofanticoagulation",
                            type: "toggle_button",
                            name: "useOfAnticoagulation",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Electrophysiology procedure/cardioversion",
                            value: "electrophysiologyprocedure/cardioversion",
                            type: "toggle_button",
                            name: "electrophysiologyProcedureCard",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestpain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightheadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Implanted pacemaker",
                            value: "implantedpacemaker",
                            type: "toggle_button",
                            name: "implantedPacemaker",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Implanted defibrillator",
                            value: "implanteddefibrillator",
                            type: "toggle_button",
                            name: "implantedDefibrillator",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "shortness of breath",
                            value: "shortnessofbreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chroniccough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes",
                        type: "options_tree",
                        label: "Describe   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Bradycardia",
                            value: "bradycardia",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "bradycardia",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Tachycardia",
                            value: "tachycardia",
                            type: "toggle_button",
                            name: "tachycardia",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Regularly irregular",
                            value: "regularlyirregular",
                            type: "toggle_button",
                            name: "regularlyIrregular",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "irregularly Irregular",
                            value: "irregularlyirregular",
                            type: "toggle_button",
                            name: "irregularlyIrregular",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Premature Contractures",
                            value: "prematurecontractures",
                            type: "toggle_button",
                            name: "prematureContractures",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Abnormal Cardiac Rhythm",
                    value: "abnormalCardiacRhythm",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "abnormalCardiacRhythm",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAneurysmDes",
                        type: "options_tree",
                        label: "Aneurysm Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "history",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAneurysmSup",
                        type: "options_tree",
                        label: "Supported By     ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical Exam",
                            value: "physicalExam",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "physicalExam",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Ultra sound,last study date & size",
                            value: "ultrasoundlaststudydate&size",
                            type: "toggle_button",
                            name: "ussdSize",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "shortness of breath",
                            value: "shortnessofbreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic Cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAneurysmCrDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Abdominal",
                            value: "abdominal",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "abdominal",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Thoracic",
                            value: "thoracic",
                            type: "toggle_button",
                            name: "thoracic",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Peripheral",
                            value: "peripheral",
                            type: "toggle_button",
                            name: "peripheral",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Aneursym",
                    value: "aneursym",
                    type: "toggle_button",
                    name: "aneursym",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAnginaDes",
                        type: "options_tree",
                        label: "Angina Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAnginaSup",
                        type: "options_tree",
                        label: " Supported by          ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Medications",
                            value: "medications",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "medications",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History characterizing chest pain",
                            value: "history",
                            type: "toggle_button",
                            name: "historyCharacterizingChestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "stress test",
                            value: "stressTest",
                            type: "toggle_button",
                            name: "stressTest",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfbreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAnginaCrDes",
                        type: "options_tree",
                        label: "  Describe        ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " Stable",
                            value: "stable",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "stable",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unstable",
                            value: "unstable",
                            type: "toggle_button",
                            name: "unstable",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Angina",
                    value: "angina",
                    type: "toggle_button",
                    name: "angina",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAtrialFibrillationDes",
                        type: "options_tree",
                        label: "Atrial Fibrillation Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAtrialFibrillationType",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "paroxysmal",
                            label: "Paroxysmal",
                            value: "paroxysmal",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                            name: "chronic",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAtrialFibrillationSup",
                        type: "options_tree",
                        label: "Supported by ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "medications",
                            label: "Medications",
                            value: "medications",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "ECG",
                            value: "ecg",
                            type: "toggle_button",
                            name: "ecg",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                            name: "history",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Electric cardioversion",
                            value: "electricCardioversion",
                            type: "toggle_button",
                            name: "electricCardioversion",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of  breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking",
                        type: "options_tree",
                        label: "Is patient taking",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "anticoagulant",
                            label: "Anticoagulant",
                            value: "anticoagulant",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rate controlling medication",
                            value: "rateControllingMedication",
                            type: "toggle_button",
                            name: "rateControllingMedication",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Atrial Fibrillation",
                    value: "atrial_fibrillation",
                    type: "toggle_button",
                    name: "atrialFibrillation",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCardiorespiratoryShockDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "activeInThePast6Months",
                            label: "Active(in the past 6 months)",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCardiorespiratoryShockSup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "history",
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Implanted Defibrillator",
                            value: "implantedDefibrillator",
                            type: "toggle_button",
                            name: "implantedDefibrillator",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest",
                        type: "options_tree",
                        label: "Cardiac Arrest",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cardio-Respiratory Failure/Shock",
                    value: "cardio_respiratory_failure/shock",
                    type: "toggle_button",
                    name: "cardioRespiratoryFailureShock",
                    depth: 4,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCardiomyopathyDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCardiomyopathyType",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "dilated",
                            label: "Dilated",
                            value: "dilated",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hypertrophic",
                            value: "hypertrophic",
                            type: "toggle_button",
                            name: "hypertrophic",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Restrictive",
                            value: "restrictive",
                            type: "toggle_button",
                            name: "restrictive",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCardiomyopathySup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "echo",
                            label: "Echo",
                            value: "echo",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cardiac Cath",
                            value: "cardiacCath",
                            type: "toggle_button",
                            name: "cardiacCath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cardiomyopathy",
                    value: "cardiomyopathy",
                    type: "toggle_button",
                    name: "cardiomyopathy",
                    depth: 4,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCongestiveHeartFailureSpecify",
                        type: "options_tree",
                        label: "Specify",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "acute",
                            label: "Acute",
                            value: "acute",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                            name: "chronic",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Acute on Chronic",
                            value: "acuteOnChronic",
                            type: "toggle_button",
                            name: "acuteOnChronic",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCongestiveHeartFailureDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCongestiveHeartFailureSup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "ejectionFraction",
                            label: "Ejection fraction",
                            value: "ejectionFraction",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cardiomegaly",
                            value: "cardiomegaly",
                            type: "toggle_button",
                            name: "cardiomegaly",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Orthopnea",
                            value: "orthopnea",
                            type: "toggle_button",
                            name: "orthopnea",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DOE",
                            value: "doe",
                            type: "toggle_button",
                            name: "doe",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "PND",
                            value: "pnd",
                            type: "toggle_button",
                            name: "pnd",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "S3",
                            value: "s3",
                            type: "toggle_button",
                            name: "s3",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Peripheral edema",
                            value: "peripheralEdema",
                            type: "toggle_button",
                            name: "peripheralEdema",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 13,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 14,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCongestiveHeartFailureCrDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "diastolic",
                            label: "Diastolic",
                            value: "diastolic",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Systolic",
                            value: "systolic",
                            type: "toggle_button",
                            name: "systolic",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension",
                        type: "options_tree",
                        label: "Secondary to Hypertension",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 5,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE",
                        type: "options_tree",
                        label: "Is patient on an ACE or ARB",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 6,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker",
                        type: "options_tree",
                        label: "Is patient on a Beta Blocker",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 7,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Congestive Heart Failure",
                    value: "congestive_heart_failure",
                    type: "toggle_button",
                    name: "congestiveHeartFailure",
                    depth: 4,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesDeepVeinThrombosisCrDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "acute",
                            label: "Acute",
                            value: "acute",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                            name: "chronic",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesDeepVeinThrombosisDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesDeepVeinThrombosisSup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "physicalFindings",
                            label: "Physical findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of anticoagulation",
                            value: "useOfAnticoagulation",
                            type: "toggle_button",
                            name: "useOfAnticoagulation",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Vascular studies",
                            value: "vascularStudies",
                            type: "toggle_button",
                            name: "vascularStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Vena Cava filter",
                            value: "venaCavaFilter",
                            type: "toggle_button",
                            name: "venaCavaFilter",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Edema",
                            value: "edema",
                            type: "toggle_button",
                            name: "edema",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesDeepVeinThrombosisPersistent",
                        type: "options_tree",
                        label: "Persistent for three months or more",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Deep Vein Thrombosis",
                    value: "deep_vein_thrombosis",
                    type: "toggle_button",
                    name: "deepVeinThrombosis",
                    depth: 4,
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesHyperlipidemiaDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesHyperlipidemiaSup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "labResults",
                            label: "Lab results",
                            value: "labResults",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                            name: "medication",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin",
                        type: "options_tree",
                        label: "Is patient on Statin",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hyperlipidemia",
                    value: "hyperlipidemia",
                    type: "toggle_button",
                    name: "hyperlipidemia",
                    depth: 4,
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesHypertensionDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesHypertensionSup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "physicalExam",
                            label: "Physical Exam",
                            value: "physicalExam",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cardioSubDiagnosesHypertensionadequatelyControlled",
                        type: "options_tree",
                        label: "Adequately controlled",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hypertension",
                    value: "hypertension",
                    type: "toggle_button",
                    name: "hypertension",
                    depth: 4,
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "cardiacCath",
                            label: "Cardiac Cath",
                            value: "cardiacCath",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of coronary stent",
                            value: "historyOfCoronaryStent",
                            type: "toggle_button",
                            name: "historyOfCoronaryStent",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diagnosis of angina",
                            value: "diagnosisOfAngina",
                            type: "toggle_button",
                            name: "diagnosisOfAngina",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of CABG",
                            value: "historyOfCabg",
                            type: "toggle_button",
                            name: "historyOfCabg",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "ECG",
                            value: "ecg",
                            type: "toggle_button",
                            name: "ecg",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Ischemic Heart Disease(CAD)",
                    value: "ischemic_heart_disease(cad)",
                    type: "toggle_button",
                    name: "ischemicHeartDiseaseCad",
                    depth: 4,
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "activeInPast28Days",
                            label: "Active (in past 28 days)",
                            value: "activeInPast28Days",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "date",
                        label: "Date",
                        placeholder: "",
                        type: "date",
                        editToggle: false,
                        xs: 4,
                        defaultValue: "2024-03-01",
                        value: "2024-03-01",
                        fullWidth: true,
                        required: "no",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 2,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "ecgChanges",
                            label: "ECG Changes",
                            value: "ecgChanges",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab results",
                            value: "labResults",
                            type: "toggle_button",
                            name: "labResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label:
                              "History of Hospitalization/Procedure for MI",
                            value: "historyOfHospitalizationProced",
                            type: "toggle_button",
                            name: "historyOfHospitalizationProced",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "iptabBlocker",
                        type: "options_tree",
                        label: "Is patient taking a Beta Blocker",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "isPatientTaking",
                        type: "options_tree",
                        label: "Is patient taking",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "aspirin",
                            label: "Aspirin",
                            value: "aspirin",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Plavix",
                            value: "plavix",
                            type: "toggle_button",
                            name: "plavix",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nitrate",
                            value: "nitrate",
                            type: "toggle_button",
                            name: "nitrate",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 5,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Myocardial Infarction",
                    value: "myocardial_infarction",
                    type: "toggle_button",
                    name: "myocardialInfarction",
                    depth: 4,
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "vascularStudies",
                            label: "Vascular studies",
                            value: "vascularStudies",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Claudication",
                            value: "claudication",
                            type: "toggle_button",
                            name: "claudication",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Extemity Ulcers",
                            value: "extemityUlcers",
                            type: "toggle_button",
                            name: "extemityUlcers",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diminished or absent pulses",
                            value: "diminishedOrAbsentPulses",
                            type: "toggle_button",
                            name: "diminishedOrAbsentPulses",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Amputation",
                            value: "amputation",
                            type: "toggle_button",
                            name: "amputation",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "historyDiabetes",
                        type: "options_tree",
                        label: "History Diabetes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "ulceration",
                            label: "Ulceration",
                            value: "ulceration",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Gangrene",
                            value: "gangrene",
                            type: "toggle_button",
                            name: "gangrene",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Peripheral Vascular Disease",
                    value: "peripheral_vascular_disease",
                    type: "toggle_button",
                    name: "peripheralVascularDisease",
                    depth: 4,
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "cardiacCath",
                            label: "Cardiac Cath",
                            value: "cardiacCath",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of O2",
                            value: "useOfO2",
                            type: "toggle_button",
                            name: "useOfO2",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Edema",
                            value: "edema",
                            type: "toggle_button",
                            name: "edema",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                            name: "medication",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Pulmonary Hypertension",
                    value: "pulmonary_hypertension",
                    type: "toggle_button",
                    name: "pulmonaryHypertension",
                    depth: 4,
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "history",
                            label: "History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            name: "dme",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 13,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 14,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "mitralStenosis",
                            label: "Mitral Stenosis",
                            value: "mitralStenosis",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Aortic Stenosis/Sclerosis",
                            value: "aorticStenosisSclerosis",
                            type: "toggle_button",
                            name: "aorticStenosisSclerosis",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Tricuspid Stenosis",
                            value: "tricuspidStenosis",
                            type: "toggle_button",
                            name: "tricuspidStenosis",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Pulmonary Insufficiency",
                            value: "pulmonaryInsufficiency",
                            type: "toggle_button",
                            name: "pulmonaryInsufficiency",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Pulmonary Stenosis",
                            value: "pulmonaryStenosis",
                            type: "toggle_button",
                            name: "pulmonaryStenosis",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Arotic Insufficiency",
                            value: "aroticInsufficiency",
                            type: "toggle_button",
                            name: "aroticInsufficiency",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Mitral Insufficiency/Prolapse",
                            value: "mitralInsufficiencyProlapse",
                            type: "toggle_button",
                            name: "mitralInsufficiencyProlapse",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Tricuspid Insufficiency",
                            value: "tricuspidInsufficiency",
                            type: "toggle_button",
                            name: "tricuspidInsufficiency",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "valueReplacement",
                        type: "options_tree",
                        label: "Value replacement",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "whichValveTypeOfReplacement",
                        type: "text",
                        label: "Which valve, type of replacement",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 5,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "isPatientOnAnticoagulation",
                        type: "options_tree",
                        label: "Is patient on anticoagulation",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 6,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Valvular Disease",
                    value: "valvular_disease",
                    type: "toggle_button",
                    name: "valvularDisease",
                    depth: 4,
                  },
                  {
                    subQuestionId: 16,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "history",
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            name: "dme",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chest pain",
                            value: "chestPain",
                            type: "toggle_button",
                            name: "chestPain",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Light headedness",
                            value: "lightHeadedness",
                            type: "toggle_button",
                            name: "lightHeadedness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Shortness of breath",
                            value: "shortnessOfBreath",
                            type: "toggle_button",
                            name: "shortnessOfBreath",
                            depth: 6,
                          },
                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Wheezing",
                            value: "wheezing",
                            type: "toggle_button",
                            name: "wheezing",
                            depth: 6,
                          },
                          {
                            subQuestionId: 13,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic cough",
                            value: "chronicCough",
                            type: "toggle_button",
                            name: "chronicCough",
                            depth: 6,
                          },
                          {
                            subQuestionId: 14,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "other",
                        type: "text",
                        label: "Other",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    name: "other",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 15,
        editToggle: false,
        depth: 1,
      },
      {
        id: 10,
        name: "ros_cardiovascularRecommendations",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Blood pressure checks", "bloodPressureChecks"],
          ["Heart healthy diet", "heartHealthyDiet"],
          ["Exercise thirty min a day", "exerciseThirtyMinADay"],

          ["Take medications as prescribed", "takeMedicationsAsPescribed"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 16,
        depth: 1,
      },

      {
        id: 9,
        name: "gastrointestinal",
        type: "heading",
        label: "Gastrointestinal",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 17,
      },
      {
        id: 6,
        name: "ros_gastroVal",
        type: "options_tree",
        label:
          "Gastrointestinal Problems (Ulcer, Reflux, Hiatal Hernia, Colitis, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_gastroSubDiagnoses",
                type: "options_tree",
                label: "   Diagnosis   ",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesdesbowelObstruction",
                        type: "options_tree",
                        label: "Bowel Obstruction Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesbowelObstructionSup",
                        type: "options_tree",
                        label: "    Supported by     ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominalPain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nauseandvomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Bowel Obstruction",
                    value: "bowelobstruction",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosescachexiades",
                        type: "options_tree",
                        label: "Cachexia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosescachexiaSup",
                        type: "options_tree",
                        label: "Supported by       ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Albumin <3.5g/dl",
                            value: "albumin<3.5g/dl",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Muscle Wasting",
                            value: "muscleWasting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of severe weight loss",
                            value: "historyofsevereweightloss",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal pain",
                            value: "abdominalpain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nauseaandvomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cachexia",
                    value: "cachexia",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesdesceliacDisease",
                        type: "options_tree",
                        label: "Celiac Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active ",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesceliacDiseaseSup",
                        type: "options_tree",
                        label: " Supported  by ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Weight loss",
                            value: "weightLoss",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Anemia",
                            value: "anemia",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Change in bowel movements",
                            value: "changeinbowelmovements",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Osteoporosis",
                            value: "osteoporosis",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diet",
                            value: "diet",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominalPain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nauseaandvomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesceliacDiseaseGlueten",
                        type: "options_tree",
                        label: "On a gluten free diet",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Celiac Disease",
                    value: "celiacdisease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesdescirrhosis",
                        type: "options_tree",
                        label: "Cirrhosis  Describe ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosescirrhosisSup",
                        type: "options_tree",
                        label: "    Supported by   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab studies",
                            value: "labStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "MRI",
                            value: "mri",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal pain",
                            value: "abdominalPain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nauseaandvomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "other",
                                type: "text",
                                label: "Other",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosescirrhosisEndStage",
                        type: "options_tree",
                        label: "End stage liver disease",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "sbhfOf",
                                type: "options_tree",
                                label: "Supported by history / finding of",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 8,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    name: "ascites",
                                    label: "Ascites",
                                    value: "ascites",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Varices",
                                    value: "varices",
                                    type: "toggle_button",
                                    name: "varices",
                                    depth: 8,
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Encephalopathy",
                                    value: "encephalopathy",
                                    type: "toggle_button",
                                    name: "encephalopathy",
                                    depth: 8,
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Hepatorenal Syndrome",
                                    value: "hepatorenalSyndrome",
                                    type: "toggle_button",
                                    name: "hepatorenalSyndrome",
                                    depth: 8,
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Other",
                                    value: "other",
                                    type: "toggle_button",
                                    name: "other",
                                    depth: 8,
                                  },
                                ],
                                subQuestionId: 1,
                                depth: 7,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cirrhosis",
                    value: "cirrhosis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosescolonPolypsDes",
                        type: "options_tree",
                        label: "Colon Polyps Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosescolonSup",
                        type: "options_tree",
                        label: "Supported by                  ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominalPain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nauseaandvomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosescolonCrDes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Bengir",
                            value: "bengir",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Carcinoma in situ",
                            value: "carcinomainsitu",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Fmilial Polyposis",
                            value: "fmilialpolyposis",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Colon Polyps",
                    value: "colonpolyps",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesdesdiverticulitis",
                        type: "options_tree",
                        label: "Diverticulitis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_gastroSubDiagnosesdiverticulitisSup",
                        type: "options_tree",
                        label: "   Supported  by     ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Colonoscopy",
                            value: "colonoscopy",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diet",
                            value: "diet",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominalPain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nauseaandvomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "abscess",
                        type: "options_tree",
                        label: "Abscess",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "perforation",
                        type: "options_tree",
                        label: "Perforation",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "on_a_high_fiber_diet",
                        type: "options_tree",
                        label: "On a high fiber diet",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Diverticultis",
                    value: "diverticultis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "gall_bladder_disease_describe",
                        type: "options_tree",
                        label: "Gall Bladder Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "__supported_by_____________________________",
                        type: "options_tree",
                        label: "  Supported by                             ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Passing of stones",
                            value: "passing_of_stones",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "ERCP",
                            value: "ercp",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "HIDA Scan",
                            value: "hida_scan",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "MRI",
                            value: "mri",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Treatment history",
                            value: "treatment_history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "other",
                                type: "text",
                                label: "Other",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Gall Bladder Disease",
                    value: "gallbladderdisease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "gastroparesis_describe",
                        type: "options_tree",
                        label: "Gastroparesis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "____supported_by____",
                        type: "options_tree",
                        label: "    Supported by    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Gastric emptying test",
                            value: "gastric_emptying_test",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Gastroparesis",
                    value: "gastroparesis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "gerd_describe",
                        type: "options_tree",
                        label: "GERD Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "_supported__by_",
                        type: "options_tree",
                        label: " Supported  by ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Heartburn/Dyspesia",
                            value: "heartburn/dyspesia",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Regurgitation",
                            value: "regurgitation",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "other",
                                type: "text",
                                label: "Other",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "GERD",
                    value: "gerd",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "gi_bleed_describe",
                        type: "options_tree",
                        label: "GI Bleed Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "___supported__by___",
                        type: "options_tree",
                        label: "   Supported  By   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Blood in stool",
                            value: "blood_in_stool",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "other",
                                type: "text",
                                label: "Other",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "GI Bleed",
                    value: "gibleed",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "hepatitis_describe",
                        type: "options_tree",
                        label: "Hepatitis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "___supported_by________________",
                        type: "options_tree",
                        label: "   Supported by                ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physical_findings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab studies",
                            value: "lab_studies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Jaundice",
                            value: "jaundice",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "other",
                                type: "text",
                                label: "Other",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "___type___",
                        type: "options_tree",
                        label: "   Type   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "A",
                            value: "a",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "B",
                            value: "b",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "C",
                            value: "c",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "__describe__",
                        type: "options_tree",
                        label: "  Describe  ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Acute",
                            value: "acute",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "cirrhosis",
                        type: "options_tree",
                        label: "Cirrhosis",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "hepatocellular_carcinoma",
                        type: "options_tree",
                        label: "Hepatocellular Carcinoma",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 6,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hepatitis",
                    value: "hepatitis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "inflammatory_bowel_disease_describe",
                        type: "options_tree",
                        label: "Inflammatory Bowel Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "____supported__by____",
                        type: "options_tree",
                        label: "    Supported  By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Colonoscopy",
                            value: "colonoscopy",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physical_findings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "__describe__",
                        type: "options_tree",
                        label: "  Describe  ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "UIcerative Colitis",
                            value: "uicerative_colitis",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Croh'ns Disease",
                            value: "croh'ns_disease",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "on_a_specific_diet",
                        type: "options_tree",
                        label: "On a specific diet",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Inflammatory Bowel Disease",
                    value: "inflammatoryboweldisease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "pancreatitis_describe",
                        type: "options_tree",
                        label: "Pancreatitis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "___supported__by_",
                        type: "options_tree",
                        label: "   Supported  by ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab studies",
                            value: "lab_studies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "ERCP",
                            value: "ercp",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Alcoholism",
                            value: "alcoholism",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of certain medications",
                            value: "use_of_certain_medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "MRI",
                            value: "mri",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Family history",
                            value: "family_history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "recurrent_episodes",
                        type: "options_tree",
                        label: "Recurrent episodes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes - Chronic",
                            value: "yes___chronic",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "on_a_specific_diet___",
                        type: "options_tree",
                        label: "on a specific diet   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Pancreatitis",
                    value: "pancreatitis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ulcer_disease_describe",
                        type: "options_tree",
                        label: "Ulcer Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "_supported_by________________",
                        type: "options_tree",
                        label: " Supported by                ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Endoscopic findings",
                            value: "endoscopic_findings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Culture",
                            value: "culture",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal Pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "____describe__",
                        type: "options_tree",
                        label: "    Describe  ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Esophageal",
                            value: "esophageal",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Gastric",
                            value: "gastric",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Duodenal",
                            value: "duodenal",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "positive_culture_for_heliobactria_pylori",
                        type: "options_tree",
                        label: "Positive culture for Heliobactria Pylori",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Ulcer Disease",
                    value: "ulcerdisease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "other_describe________",
                        type: "options_tree",
                        label: "Other Describe        ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supported_by__________________",
                        type: "options_tree",
                        label: "Supported by                  ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physical_findings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "test_results",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "image_studies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abdominal pain",
                            value: "abdominal_pain",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nausea and vomiting",
                            value: "nausea_and_vomiting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 11,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe__",
                                type: "text",
                                label: "Describe  ",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "describe___",
                        type: "text",
                        label: "Describe   ",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 18,
        editToggle: false,
      },
      {
        id: 10,
        name: "gastro_recommendations",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Take medications as prescribed", "takemedicationsasprescribed"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 19,
      },

      {
        id: 6,
        name: "ros_bowelMove",
        type: "options_tree",
        label: "Bowel Movements",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [{}],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_bowelMoveAbnorm",
                type: "options_tree",
                label: "If Abnormal",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_bowelMoveAbnormConst",
                        type: "options_tree",
                        label: "If Constipation",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Acute",
                            value: "acute",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Constipation",
                    value: "constipation",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_bowelMoveAbnormDiarrIsdia",
                        type: "options_tree",
                        label: "If Diarrhea",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Acute",
                            value: "acute",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_bowelMoveAbnormDiarrIshis",
                        type: "options_tree",
                        label:
                          "If Diarrhea, history of C Difficile (Rrefer to C Difficile in the Infectious Disease Section for further documentation)",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Diarrhea",
                    value: "diarrhea",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Bowel Incontinence",
                    value: "bowelIncontinence",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 20,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_abdominalIsabd",
        type: "options_tree",
        label: "Abdominal Openings",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_abdominalIsyes",
                type: "options_tree",
                label: "Describe",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Ileostomy",
                    value: "ileostomy",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Colostomy",
                    value: "colostomy",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Urostomy",
                    value: "urostomy",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "PEG",
                    value: "peg",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cystostomy",
                    value: "cystostomy",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 22,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_rectalIsrec",
        type: "options_tree",
        label: "Rectal Problems",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_rectalIsrecIsyesfem",
                type: "options_tree",
                label: "If Yes (Female)",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Hemorrhoids",
                    value: "hemorrhoids",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Fissure",
                    value: "fissure",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Mass",
                    value: "mass",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
              {
                id: 6,
                name: "ros_rectalIsrecIsyesmal",
                type: "options_tree",
                label: "If Yes (Male)",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Hermorrhoids",
                    value: "hermorrhoids",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Fissure",
                    value: "fissure",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Mass",
                    value: "mass",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "BPH",
                    value: "bph",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Prostate Mass",
                    value: "prostateMass",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 2,
                depth: 2,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 23,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_lastBowel",
        type: "options_tree",
        label: "Last Bowel Movement",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 3,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Today",
            value: "today",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "1-3 days ago",
            value: "oneToThreeDaysAgo",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: ">3 days ago",
            value: "greaterThanThreeDaysAgo",
            type: "toggle_button",
          },
        ],
        questionId: 26,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_neuroPsych",
        type: "options_tree",
        label:
          "Neuro / Psych Problems (Stroke, Parkinson's disease, Seizures Paraplegia, Depression, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_neuroPsychSubDiag",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAlcoholSubDesc",
                        type: "options_tree",
                        label: "Alcohol Dependence Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "history_of",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "rule_out",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAlcoholSubType",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Episodic",
                            value: "episodic",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Continuous",
                            value: "continuous",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Remission",
                            value: "remission",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAlcoholSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Drinking history",
                            value: "drinkingHistory",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalizations",
                            value: "hospitalizations",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab results",
                            value: "labResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAlcoholSubDelirium",
                        type: "options_tree",
                        label: "History of Delirium Tremens",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAlcoholSubPsych",
                        type: "options_tree",
                        label: "History of Psychosis",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Alcohol Dependence",
                    value: "alcoholDependence",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAmyotropSubDesc",
                        type: "options_tree",
                        label: "Amyotrophic Lateral Sclerosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAmyotropSubSupp",
                        type: "options_tree",
                        label: "   Supported  by   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Progressive Weakness",
                            value: "progressiveWeekness",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Slurring of speech",
                            value: "slurringOfSpeech",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abnormal gait",
                            value: "abnormalGait",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Difficulty swallowing",
                            value: "difficultySwallowing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "EMG",
                            value: "emg",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Amyotrophic lateral sclerosis",
                    value: "amyotrophicLateral",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAnxietySubDesc",
                        type: "options_tree",
                        label: "Anxiety Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAnxietySubType",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Generalized Anxiety Disorder",
                            value: "generalizedAnxietyDisorder",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Panic Disorder",
                            value: "panicDisorder",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Obsessive-Compulsive Disorder",
                            value: "obsessiveCompulsiveDisorder",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Post traumatic stress disorder",
                            value: "postTraumaticStressDisorder",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Social phobia",
                            value: "socialPhobia",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagAnxietySubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "GAD 7",
                            value: "gadSeven",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Antianxiety medication",
                            value: "antianxietyMedication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Anxiety",
                    value: "anxiety",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagBipolarSubDesc",
                        type: "options_tree",
                        label: "Bipolar Disorder Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagBipolarSubType",
                        type: "options_tree",
                        label: "Type ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Manic Depression",
                            value: "manicDepression",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Bipolar Depression",
                            value: "bipolarDepression",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Mixed",
                            value: "mixed",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagBipolarSubSupp",
                        type: "options_tree",
                        label: "Supported By",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History of Mood swings",
                            value: "historyOfMoodSwings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Bipolar disorder",
                    value: "bipolarDisorder",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagCerebHemmSubDesc",
                        type: "options_tree",
                        label: "Cerebral Hemorrhage Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagCerebHemmSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image study",
                            value: "imageStudy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSub",
                                type: "options_tree",
                                label: "Supported by",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Arm Paralysis",
                                    value: "rightArmParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Arm Paralysis",
                                    value: "leftArmParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Leg Paralysis",
                                    value: "rightLegParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Leg Paralysis",
                                    value: "leftLegParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Hemiparesis",
                                    value: "rightHemiparesis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 7,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Hemiparesis",
                                    value: "leftHemiparesis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 8,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Aphasia",
                                    value: "aphasia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 9,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Apraxia",
                                    value: "apraxia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 10,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Cranial Nerve Paralysis",
                                    value: "cranialNerveParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 11,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paraplegia",
                                    value: "paraplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 12,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Quadriplegia",
                                    value: "quadriplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 13,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Coma",
                                    value: "coma",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSub",
                                type: "options_tree",
                                label: "Supported by",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Right Arm",
                                    value: "numbnessRightArm",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Right Leg",
                                    value: "numbnessRightLeg",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Left Arm",
                                    value: "numbnessLeftArm",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Left Leg",
                                    value: "numbnessLeftLeg",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Facial Numbness",
                                    value: "facialNumbness",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 7,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paresthesias",
                                    value: "paresthesias",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Sensory findings",
                            value: "sensoryFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cerebral Hemorrhage",
                    value: "cerebral_hemorrhage",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagCerebPalsySubDesc",
                        type: "options_tree",
                        label: "Cerebral Palsy Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagCerebPalsySubSupp",
                        type: "options_tree",
                        label: " Supported by ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical Exam",
                            value: "physicalExam",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Laboratory testing ",
                            value: "laboratoryTesting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cerebral Palsy",
                    value: "cerebral_palsy",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDelusionSubDesc",
                        type: "options_tree",
                        label: "Delusional Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDelusionSubSupp",
                        type: "options_tree",
                        label: "Supported by                    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Affect",
                            value: "affect",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Specific symptoms for 6 months or more",
                            value: "specificSymptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Delusional Disease",
                    value: "delusional_disease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDementiaSubDesc",
                        type: "options_tree",
                        label: "Dementia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Acute",
                            value: "acute",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDementiaSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Behavioral changes",
                            value: "behavioralChanges",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Mental testing",
                            value: "mentalTesting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "MRI",
                            value: "mri",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Functional Changes",
                            value: "functionalChanges",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDementiaSubType",
                        type: "options_tree",
                        label: "Type of Dementia",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagDementiaSubTypeVascSub",
                                type: "options_tree",
                                label: "Sub Type",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "History of Strokes",
                                    value: "historyOfStrokes",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Risk Factors",
                                    value: "riskFactors",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 3,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Vascular",
                            value: "vascular",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Alzheimer's disease",
                            value: "alzheimersDisease",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Etiology Unknown",
                            value: "etiologyUnknown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Dementia",
                    value: "dementia",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDepressSubDesc",
                        type: "options_tree",
                        label: "Depression Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDepressSubSupp",
                        type: "options_tree",
                        label: "Supported by   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "PHQ 2/9",
                            value: "phqTwoByNine",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Use of antidepressant medication",
                            value: "antidepressantMedication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDepressSubMajor",
                        type: "options_tree",
                        label: "Major",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagDepressSubMajorSub",
                                type: "options_tree",
                                label: "Major - Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Phq 9",
                                    value: "phqnine",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Hospitalization",
                                    value: "hospitalization",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Chronic Use Of Anti-Depressant",
                                    value: "chronicUseOfAntipressant",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Use Of ECT",
                                    value: "useOfEct",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDepressSubEpisode",
                        type: "options_tree",
                        label: " Episodes   ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Single",
                            value: "single",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Recurrent",
                            value: "recurrent",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "In Remission",
                            value: "in_remission",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Depression",
                    value: "depression",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDrugsSubDesc",
                        type: "options_tree",
                        label: "Drug Dependence Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDrugsSubType",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Episodic",
                            value: "episodic",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Continuous",
                            value: "continuous",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Remission",
                            value: "remission",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDrugsSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Use of recreational drugs",
                            value: "recreationalDrugs",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic use of pain medication",
                            value: "chronicUseOfPainMedication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of hospitalization",
                            value: "historyOfHospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History outpatient treatment",
                            value: "historyOutpatientTreatment",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Withdrawal symptoms",
                            value: "withdrawalSymptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Abnormal effect",
                            value: "abnormalAffect",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDrugsSubHistoryPsych",
                        type: "options_tree",
                        label: "History of Psychosis",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "ros_neuroPsychSubDiagDrugsSubWhatDrugs",
                        type: "text",
                        label: "Which drugs",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Drug Dependence",
                    value: "drugDependence",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagFibroSubDesc",
                        type: "options_tree",
                        label: "Fibromyalgia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagFibroSubSupp",
                        type: "options_tree",
                        label: " Supported by               ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Fibromyalgia",
                    value: "fibromyalgia",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagGuillSubDesc",
                        type: "options_tree",
                        label: "Guillain-Barre Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagGuillSubSupp",
                        type: "options_tree",
                        label: "Supported by                     ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "EMG/Nerve Conduction studies",
                            value: "emg",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Guillain-Barre Disease",
                    value: "guillainBarreDisease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagHemiSubDesc",
                        type: "options_tree",
                        label: "Hemiparesis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagHemiSubHemiDesc",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Left sided",
                            value: "leftSided",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Right sided",
                            value: "rightSided",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagHemiSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hemiparesis",
                    value: "hemiparesis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagHuntingSubDesc",
                        type: "options_tree",
                        label: "Huntington's Chorea Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagHuntingSubSupp",
                        type: "options_tree",
                        label: "   Supported by                 ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Family history",
                            value: "familyHistory",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chorea Movement",
                            value: "choreaMovement",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Huntington's Chorea",
                    value: "huntingtonsChorea",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagInsomniaSubDesc",
                        type: "options_tree",
                        label: "Insomnia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagInsomniaSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Medications",
                            value: "medications",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Insomnia",
                    value: "insomnia",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 16,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagIntelSubDesc",
                        type: "options_tree",
                        label:
                          "Intellectual and or Developmental Disability Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagIntelSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagIntelSubIntelDesc",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Down's Syndrome",
                            value: "downsSyndrome",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Psychomotor Retardation",
                            value: "phychomotorRetardation",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Intellectual and or Developmental Disability",
                    value: "developmentalDisability",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 17,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagMigraineSubDesc",
                        type: "options_tree",
                        label: "Migraine Headaches Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagMigraineSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Migraine Headaches",
                    value: "migraineHeadaches",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 18,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSclerosisSubDesc",
                        type: "options_tree",
                        label: "Multiple Sclerosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSclerosisSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Laboratory testing",
                            value: "laboratoryTesting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Bowel or bladder dysfunction",
                            value: "bowel",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Multiple Sclerosis",
                    value: "multipleSclerosis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 19,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDystrophySubDesc",
                        type: "options_tree",
                        label: "Muscular Dystrophy Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagDystrophySubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "EMG's",
                            value: "emgs",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of progressive muscle weakness",
                            value: "historyOfProgressiveMuscle",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Family history",
                            value: "familyHistory",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Muscular Dystrophy",
                    value: "muscularDystrophy",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 20,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagGravisSubDesc",
                        type: "options_tree",
                        label: "Myasthenia Gravis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagGravisSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Ptosis",
                            value: "ptosis",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Double vision",
                            value: "doubleVision",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Difficulty chewing",
                            value: "difficultyChewing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Difficulty swallowing",
                            value: "difficultySwallowing",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Tensilon test",
                            value: "tensilonTest",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Myasthenia Gravis",
                    value: "myastheniaGravis",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 21,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagParkinsonSubDesc",
                        type: "options_tree",
                        label: "Parkinson's disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagParkinsonSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Gait",
                            value: "gait",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Dementia",
                            value: "dementia",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Affect",
                            value: "affect",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Parkinson's disease",
                    value: "parkinsonsDisease",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 22,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagPeriNeuroSubDesc",
                        type: "options_tree",
                        label: "Peripheral Neuropathy Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagPeriNeuroSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Phsyical findings ",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "EMG/Nerve conduction studies ",
                            value: "emg",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagPeriNeuroSubDiabetes",
                        type: "options_tree",
                        label: "Secondary to Diabetes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Peripheral Neuropathy",
                    value: "peripheralNeuropathy",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 23,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagRestlessLegSubDesc",
                        type: "options_tree",
                        label: "Restless leg syndrome Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagRestlessLegSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Restless leg syndrome",
                    value: "restlessLegSyndrome",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 24,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSchizoSubDesc",
                        type: "options_tree",
                        label: "Schizophrenia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSchizoSubSupp",
                        type: "options_tree",
                        label: "Supported by                       ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Affect",
                            value: "affect",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Specific symptoms for 6 months or more",
                            value: "specificSymptomsForSixMonths",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Psychosis",
                            value: "psychosis",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Schizophrenia",
                    value: "schizophrenia",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 25,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSeizureSubDesc",
                        type: "options_tree",
                        label: "Seizures Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSeizureSubType",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Seizure Disorder",
                            value: "seizureDisorder",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Seizure Unspecified",
                            value: "seizureUnspecified",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "ros_neuroPsychSubDiagSeizureSubTreatment",
                        type: "text",
                        label: "Treatment",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSeizureSubSupp",
                        type: "options_tree",
                        label: "Supported by                        ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History of recurrent seizures",
                            value: "historyOfRecurrentSeizures",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Laboratory testing",
                            value: "laboratoryTesting",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Seizures",
                    value: "seizures",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 26,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSpinalCordSubDesc",
                        type: "options_tree",
                        label: "Spinal Cord Injury Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active (within  8 months)",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSpinalCordSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paraplegia",
                                    value: "paraplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Quadriplegia",
                                    value: "quadriplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Anterior Cord Syndrome",
                                    value: "anteriorCordSyndrome",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Posterior Cord Syndrome",
                                    value: "posteriorCordSyndrome",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Central Cord Syndrome",
                                    value: "centralCordSyndrome",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Paresis or paralysis",
                            value: "paresis",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSub",
                                type: "options_tree",
                                label: "Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paraplegia",
                                    value: "paraplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Quadriplegia",
                                    value: "quadriplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Anterior Cord Syndrome",
                                    value: "anteriorCordSyndrome",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Posterior Cord Syndrome",
                                    value: "posteriorCordSyndrome",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Central Cord Syndrome",
                                    value: "centralCordSyndrome",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 1,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Loss of sensation",
                            value: "lossOfSensation",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Bowel or bladder dysfunction",
                            value: "bowel",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSpinalCordSubSecTo",
                        type: "options_tree",
                        label: "Secondary to",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Fracture",
                            value: "fracture",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Dislocation",
                            value: "dislocation",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Compressive Lession",
                            value: "compressiveLesion",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Spinal Cord injury",
                    value: "spinal_cord_injury",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 27,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagStrokeSubDesc",
                        type: "options_tree",
                        label: "Stroke Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagStrokeSubSupp",
                        type: "options_tree",
                        label: "Secondary to",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Study",
                            value: "imageStudy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSub",
                                type: "options_tree",
                                label: "Supported by",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Arm Paralysis",
                                    value: "rightArmParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Arm Paralysis",
                                    value: "leftArmParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Leg Paralysis",
                                    value: "rightLegParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Leg Paralysis",
                                    value: "leftLegParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Hemiparesis",
                                    value: "rightHemiparesis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 7,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Hemiparesis",
                                    value: "leftHemiparesis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 8,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Aphasia",
                                    value: "aphasia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 9,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Apraxia",
                                    value: "apraxia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 10,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Cranial Nerve Paralysis",
                                    value: "cranialNerveParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 11,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Functional Quadriplegia",
                                    value: "functionalQuadriplegia",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSub",
                                type: "options_tree",
                                label: "Supported by",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Right Arm",
                                    value: "numbnessRightArm",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Right Leg",
                                    value: "numbnessRightLeg",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Left Arm",
                                    value: "numbnessLeftArm",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Left Leg",
                                    value: "numbnessLeftLeg",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Facial Numbness",
                                    value: "facialNumbness",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 7,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paresthesias",
                                    value: "paresthesias",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Sensory findings",
                            value: "sensoryFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Stroke",
                    value: "stroke",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 28,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSubduralSubDesc",
                        type: "options_tree",
                        label: "Subdural Hematoma Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagSubduralSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image study",
                            value: "imageStudy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSub",
                                type: "options_tree",
                                label: "Supported by",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Arm Paralysis",
                                    value: "rightArmParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Arm Paralysis",
                                    value: "leftArmParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Leg Paralysis",
                                    value: "rightLegParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Leg Paralysis",
                                    value: "leftLegParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Right Hemiparesis",
                                    value: "rightHemiparesis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 7,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Left Hemiparesis",
                                    value: "leftHemiparesis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 8,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Aphasia",
                                    value: "aphasia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 9,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Apraxia",
                                    value: "apraxia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 10,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Cranial Nerve Paralysis",
                                    value: "cranialNerveParalysis",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 11,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paraplegia",
                                    value: "paraplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 11,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Quadriplegia",
                                    value: "quadriplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 11,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Coma",
                                    value: "coma",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSub",
                                type: "options_tree",
                                label: "Supported by",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Right Arm",
                                    value: "numbnessRightArm",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Right Leg",
                                    value: "numbnessRightLeg",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Left Arm",
                                    value: "numbnessLeftArm",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Numbness Left Leg",
                                    value: "numbnessLeftLeg",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Facial Numbness",
                                    value: "facialNumbness",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 7,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paresthesias",
                                    value: "paresthesias",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Sensory findings",
                            value: "sensoryFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Subdural Hematoma",
                    value: "subduralHematoma",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 29,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagTiaSubDesc",
                        type: "options_tree",
                        label: "TIA Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagTiaSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Phsyical Exam",
                            value: "physicalExam",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "TIA",
                    value: "tia",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 30,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagTraumaBrainSubDesc",
                        type: "options_tree",
                        label: "Traumatic Brain Injury Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagTraumaBrainSubSupp",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "image_studies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSub",
                                type: "options_tree",
                                label: "Supported by",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "None",
                                    value: "none",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Coma",
                                    value: "coma",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 3,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Quadriplegia",
                                    value: "quadriplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 4,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Paraplegia",
                                    value: "paraplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Hemiplegia",
                                    value: "hemiplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Monoplegia",
                                    value: "monoplegia",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 5,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "PT",
                                    value: "pt",
                                    type: "toggle_button",
                                  },
                                  {
                                    subQuestionId: 6,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "DME",
                                    value: "dme",
                                    type: "toggle_button",
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Traumatic Brain Injury",
                    value: "traumaticBrainInjury",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 31,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagOtherSubDesc",
                        type: "options_tree",
                        label: "Other Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_neuroPsychSubDiagOtherSubSupp",
                        type: "options_tree",
                        label: "Supported by                               ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "other",
                        type: "text",
                        label: "Other",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 25,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_meetWith",
        type: "options_tree",
        label: "How often do you go out to meet with family or friends",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 3,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Often",
            value: "often",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Sometimes",
            value: "sometimes",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Never",
            value: "never",
            type: "toggle_button",
          },
        ],
        questionId: 27,
        editToggle: false,
      },
      {
        id: 7,
        name: "gpcog_score",
        type: "label",
        label: "GPCOG Score",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 38,
      },
      {
        id: 1,
        name: "ros_gpcogScoIsgpcogs",
        type: "text",
        label: "  GPCOG Score",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 40,
      },
      {
        id: 7,
        name: "or_mmse_score",
        type: "label",
        label: "or MMSE Score",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 39,
      },
      {
        id: 1,
        name: "ros_gpcogScoIsmmsesc",
        type: "text",
        label: "MMSE Score",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 41,
      },
      {
        id: 6,
        name: "if_gpcog_or_mmse_is_not_done,is",
        type: "options_tree",
        label: "If GPCOG or MMSE is not done,is",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_noGpcogMmsePatientOrientPerson",
                type: "options_tree",
                label: "Patient oriented to person",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
              {
                id: 6,
                name: "ros_noGpcogMmsePatientOrientPlace",
                type: "options_tree",
                label: "Patient oriented to place",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 2,
                depth: 2,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_noGpcogMmsePatientOrientTime",
                type: "options_tree",
                label: "Patient oriented to time",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 3,
                depth: 2,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_noGpcogMmseRecall",
                type: "options_tree",
                label: "Recall",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Good",
                    value: "good",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Poor",
                    value: "poor",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 4,
                depth: 2,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_noGpcogMmsePatientDescribeNews",
                type: "options_tree",
                label: "Patient describes recent news event",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Partially",
                    value: "partially",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 5,
                depth: 2,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "If GPCOG or MMSE is not done,is",
            value: "if_gpcog_or_mmse_is_not_done",
            isConfigured: true,
            type: "toggle_button",
          },
        ],
        questionId: 42,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_psychMood",
        type: "options_tree",
        label: "Mood and Affect:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                subQuestionId: 1,
                label: "Abnormal - Describe",
                value: "ros_affectSub",
                type: "options_tree",
                xs: 12,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    label: "Paranoia",
                    type: "toggle_button",
                    isConfigured: true,
                    isModified: false,
                    value: "paranoia",
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    depth: 1,
                    subQuestionId: 2,
                    label: "Delusional",
                    type: "toggle_button",
                    isConfigured: true,
                    isModified: false,
                    value: "delusional",
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    depth: 1,
                    subQuestionId: 3,
                    label: "Disorganised Thought",
                    type: "toggle_button",
                    isConfigured: true,
                    isModified: false,
                    value: "disorganizedThought",
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    depth: 1,
                    subQuestionId: 4,
                    label: "Flat",
                    type: "toggle_button",
                    isConfigured: true,
                    isModified: false,
                    value: "flat",
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    depth: 1,
                    subQuestionId: 5,
                    label: "Maniac",
                    type: "toggle_button",
                    isConfigured: true,
                    isModified: false,
                    value: "manic",
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    depth: 1,
                    subQuestionId: 6,
                    label: "Depressed",
                    type: "toggle_button",
                    isConfigured: true,
                    isModified: false,
                    value: "depressed",
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    depth: 1,
                    subQuestionId: 7,
                    label: "Other",
                    type: "toggle_button",
                    isConfigured: true,
                    isModified: false,
                    value: "other",
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        editToggle: false,
                      },
                    ],
                  },
                ],
                isCommentEnabled: true,
                isConfigured: true,
                isModified: false,
                fullWidth: true,
                required: true,
                name: "ros_affectSub",
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 54,
        editToggle: false,
      },
      {
        id: 7,
        name: "over_the_past_2_weeks,_how_often_have_you_been_bothered_by_any_of_the_following_problems?",
        type: "label",
        label:
          "Over the past 2 weeks, how often have you been bothered by any of the following problems?",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 44,
      },
      {
        id: 6,
        name: "ros_over2WeeksLittleIntrst",
        type: "options_tree",
        label: "Little interest or pleasure in doing things",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Not at all",
            value: "notAtAll",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Several Days",
            value: "severalDays",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "More than half the days",
            value: "moreThanHalfTheDays",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Nearly every day",
            value: "nearlyEveryDay",
            type: "toggle_button",
          },
        ],
        questionId: 45,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_over2WeeksFeelDepressed",
        type: "options_tree",
        label: "Feeling down, depressed or hopeless",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Not at all",
            value: "notAtAll",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Several Days",
            value: "severalDays",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "More than half the days",
            value: "moreThanHalfTheDays",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Nearly every day",
            value: "nearlyEveryDay",
            type: "toggle_button",
          },
        ],
        questionId: 46,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_over2WeeksPhq",
        type: "options_tree",
        label: "PHQ 2 Score",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "<3",
            value: "lessThanThree",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst",
                type: "options_tree",
                label: "Having little interest or pleasure in doing things?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly Every day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress?",
                type: "options_tree",
                label: "Feeling down, depressed or hopeless at times?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly Every Day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 2,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall",
                type: "options_tree",
                label:
                  "Do you have trouble falling or staying asleep, sleeping too much?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly Every day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 3,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired",
                type: "options_tree",
                label: "Do you feeling tired or having little energy?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly Every Day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 9,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite",
                type: "options_tree",
                label: "Do you have a poor appetite or overeating?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly every day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 4,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad",
                type: "options_tree",
                label:
                  "Feeling bad about yourself or that you are a failure or have let yourself or your family down?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "not_at_all",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "more_than_half_the_days",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly everyday",
                    value: "nearly_everyday",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 5,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc",
                type: "options_tree",
                label:
                  "Trouble concentrating on things, such as reading the newspaper or watching TV?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly everyday",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 6,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak",
                type: "options_tree",
                label:
                  "Moving or speaking so slowly that other people have noticed. Or opposite-being fidgety or restless that you have been moving around a lot more than usual?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly Every Day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 7,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself",
                type: "options_tree",
                label:
                  "Thoughts that you would be better off dead, or hurting yourself?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly Every Day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 8,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverity",
                type: "options_tree",
                label:
                  "Thoughts that you would be better off dead, or hurting yourself?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Not at all",
                    value: "notAtAll",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Several",
                    value: "several",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "More than half the days",
                    value: "moreThanHalfTheDays",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nearly Every Day",
                    value: "nearlyEveryDay",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 8,
                editToggle: false,
              },
              {
                id: 2,
                name: "phq_9_score",
                type: "number",
                label: "PHQ 9 Score",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: " ",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 10,
              },
              {
                id: 1,
                name: "comment",
                type: "text",
                label: "Comment",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: " ",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 11,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "3 or more",
            value: "threeOrMore",
            type: "toggle_button",
          },
        ],
        questionId: 47,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_speech",
        type: "options_tree",
        label: "Speech",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Slurred",
            value: "slurred",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Aphasic",
            value: "aphasic",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Apraxia",
            value: "apraxia",
            type: "toggle_button",
          },
        ],
        questionId: 48,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_fingerToNose",
        type: "options_tree",
        label: "Finger to Nose",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_fingerToNoseAbnormalSub",
                type: "options_tree",
                label: "If Abnormal",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Left",
                    value: "left",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Right",
                    value: "right",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Both",
                    value: "both",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 49,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_heelToToe",
        type: "options_tree",
        label: "Heel (Shin) to Toe",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_heelToToeAbnormalSub",
                type: "options_tree",
                label: "If abnormal",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Left",
                    value: "left",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Right",
                    value: "right",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Both",
                    value: "both",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "abnormal",
            value: "abnormal",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 50,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_thumbToFinger",
        type: "options_tree",
        label: "Thumb to Finger Tips",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_thumbToFingerAbnormalSub",
                type: "options_tree",
                label: "If Abnormal  ",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "left",
                    value: "left",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "right",
                    value: "right",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "both",
                    value: "both",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 51,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_sitToStand",
        type: "options_tree",
        label: "Sitting to Standing",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Needs Assistance",
            value: "needsAssistance",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Unable",
            value: "unable",
            type: "toggle_button",
          },
        ],
        questionId: 52,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_extremeMove",
        type: "options_tree",
        label: "Facial /Extremity Movement",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Motor Tic",
            value: "motorTic",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Vocal Tic",
            value: "vocalTic",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Benign (Essential Tremor)",
            value: "benign",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Intention Tremor",
            value: "intentionTremor",
            type: "toggle_button",
          },
          {
            subQuestionId: 5,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Non-Intention (Pill rolling) Tremor",
            value: "nonIntention",
            type: "toggle_button",
          },
          {
            subQuestionId: 6,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Rigidity",
            value: "rigidity",
            type: "toggle_button",
          },
          {
            subQuestionId: 7,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Spasticity",
            value: "spasticity",
            type: "toggle_button",
          },
          {
            subQuestionId: 8,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Chorea Movement",
            value: "choreaMovement",
            type: "toggle_button",
          },
          {
            subQuestionId: 9,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Cog Wheeling",
            value: "cogWheeling",
            type: "toggle_button",
          },
          {
            subQuestionId: 10,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Normal",
            value: "normal",
            type: "toggle_button",
          },
        ],
        questionId: 53,
        editToggle: false,
      },
      {
        id: 6,
        name: "ros_gait",
        // value: "gait",
        type: "options_tree",
        label: "Gait",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            //name: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Limp",
            value: "limp",
            //name: "limp",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Wide based",
            value: "wideBased",
            //name: "wideBased",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abductor lurch",
            value: "abductorLurch",
            //name: "abductorLurch",
            type: "toggle_button",
          },
          {
            subQuestionId: 5,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Paretic",
            value: "paretic",
            //name: "paretic",
            type: "toggle_button",
          },
          {
            subQuestionId: 6,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Shuffling",
            value: "shuffling",
            //name: "shuffling",
            type: "toggle_button",
          },
          {
            subQuestionId: 7,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Ataxic",
            value: "ataxic",
            //name: "ataxic",
            type: "toggle_button",
          },
          {
            subQuestionId: 8,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label:
              "Other (Findings may also apply to Musculoskeletal diagnoses)",
            value: "other",
            //name: "other",
            type: "toggle_button",
          },
        ],
        questionId: 54,
        editToggle: false,
      },
      {
        id: 10,
        name: "gait_Recommendations",
        value: "takeMedicationsAsPescribed",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Take medications as prescribed", "takeMedicationsAsPescribed"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 55,
      },
      {
        id: 9,
        name: "genitourinary",
        type: "heading",
        label: "GENITOURINARY",
        editToggle: false,
        xs: 3,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 70,
        depth: 1,
      },
      {
        id: 6,
        name: "ros_genitoVal",
        type: "options_tree",
        label:
          "Genitourinary Problems (Overactive Bladder, Urinary Incontinence Stress Incontinence, Benign Prostatic Hypertrophy, Others)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_genitoSubDiagnoses",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_genitoSubDiagnosesacuteRenalFailuredes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_genitoSubDiagnosesacuteRenalFailureSup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lab tests",
                            value: "labTests",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "labTests",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Calculated GFR",
                            value: "calculatedGfr",
                            type: "toggle_button",
                            name: "calculatedGfr",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                            name: "hospitalization",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 1,
                        name: "ros_genitoSubDiagnosesacuteRenalFailureEti",
                        type: "text",
                        label: "Etiology",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Acute Renal Failure",
                    value: "acuteRenalFailure",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "acuteRenalFailure",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical exam",
                            value: "physicalExam",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "physicalExam",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab test",
                            value: "labTest",
                            type: "toggle_button",
                            name: "labTest",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                            name: "medication",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                            name: "hospitalization",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "BPH",
                    value: "bph",
                    type: "toggle_button",
                    name: "bph",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lab tests",
                            value: "labTests",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "labTests",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Calculated GFR X 3",
                            value: "calculatedGfrX3",
                            type: "toggle_button",
                            name: "calculatedGfrX3",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 11,
                        name: "stagesOfKidneyDisease",
                        label: "Stages of Kidney Disease",
                        editToggle: false,
                        type: "datatable",
                        tablelist: [
                          [
                            {
                              id: 1,
                              label: "Allergies",
                              name: "HRAForm_Allergies",
                              columns: [
                                {
                                  label: "Substance",
                                  type: "text",
                                },
                                {
                                  label: "Reaction",
                                  type: "text",
                                },
                              ],
                              isReadOnly: false,
                            },
                            false,
                          ],
                          [
                            {
                              id: 2,
                              label: "BMI Index Chart",
                              name: "HRAForm_BMI_Index_Chart",
                              columns: [
                                {
                                  label: "BMI",
                                  type: "text",
                                },
                              ],
                              isReadOnly: true,
                            },
                            false,
                          ],
                          [
                            {
                              id: 3,
                              label: "Counter Medications",
                              name: "HRAForm_Counter_Medications",
                              columns: [
                                {
                                  label: "Date",
                                  type: "date",
                                },
                                {
                                  label: "Description",
                                  type: "text",
                                },
                                {
                                  label: "Dose Per Units",
                                  type: "text",
                                },
                                {
                                  label: "Route",
                                  type: "select",
                                },
                                {
                                  label: "Frequency",
                                  type: "text",
                                },
                              ],
                              isReadOnly: false,
                            },
                            false,
                          ],
                          [
                            {
                              id: 4,
                              label: "Medical History Specialists",
                              name: "HRAForm_Medical_History_Speciality",
                              columns: [
                                {
                                  label: "Member Specialist",
                                  type: "select",
                                },
                                {
                                  label: "Speciality",
                                  type: "text",
                                },
                                {
                                  label: "For",
                                  type: "text",
                                },
                              ],
                              isReadOnly: false,
                            },
                            false,
                          ],
                          [
                            {
                              id: 5,
                              label: "Medications",
                              name: "HRAForm_Medications",
                              columns: [
                                {
                                  label: "Diagnoses",
                                  type: "text",
                                },
                                {
                                  label: "label Name",
                                  type: "select",
                                },
                                {
                                  label: "Dose Per Units",
                                  type: "text",
                                },
                                {
                                  label: "Route",
                                  type: "select",
                                },
                                {
                                  label: "Frequency",
                                  type: "select",
                                },
                                {
                                  label: "Prescribing Physician",
                                  type: "text",
                                },
                                {
                                  label: "Status",
                                  type: "togglebutton",
                                },
                              ],
                              isReadOnly: false,
                            },
                            false,
                          ],
                          [
                            {
                              id: 6,
                              label: "Mini Cog Versions",
                              name: "HRAForm_Minicog_Version",
                              columns: [
                                {
                                  label: "Version 1",
                                  type: "text",
                                },
                                {
                                  label: "Version 2",
                                  type: "text",
                                },
                                {
                                  label: "Version 3",
                                  type: "text",
                                },
                                {
                                  label: "Version 4",
                                  type: "text",
                                },
                                {
                                  label: "Version 5",
                                  type: "text",
                                },
                                {
                                  label: "Version 6",
                                  type: "text",
                                },
                              ],
                              isReadOnly: true,
                            },
                            false,
                          ],
                          [
                            {
                              id: 7,
                              label: "Preventive Care",
                              name: "HRAForm_Preventive_Care",
                              columns: [
                                {
                                  label: "Screen",
                                  type: "text",
                                },
                                {
                                  label: "Answer",
                                  type: "select",
                                },
                                {
                                  label: "Date",
                                  type: "text",
                                },
                                {
                                  label: "Method",
                                  type: "text",
                                },
                                {
                                  label: "Recommendation",
                                  type: "checkbox",
                                },
                                {
                                  label: "Education Completed",
                                  type: "checkbox",
                                },
                              ],
                              isReadOnly: true,
                            },
                            false,
                          ],
                          [
                            {
                              id: 8,
                              label: "Previously Documented Conditions",
                              name: "HRAForm_Previously_Documented_Conditions",
                              columns: [
                                {
                                  label: "Diagnosis",
                                  type: "text",
                                },
                                {
                                  label: "Prior HCC",
                                  type: "text",
                                },
                                {
                                  label: "Diagnosis Code",
                                  type: "text",
                                },
                                {
                                  label: "Date of Service",
                                  type: "date",
                                },
                              ],
                              isReadOnly: false,
                            },
                            false,
                          ],
                          [
                            {
                              id: 9,
                              label: "Prior Assessments",
                              name: "HRAForm_Prior_Assessments",
                              columns: [
                                {
                                  label: "Date Time",
                                  type: "date",
                                },
                                {
                                  label: "Document",
                                  type: "button",
                                },
                              ],
                              isReadOnly: true,
                            },
                            false,
                          ],
                          [
                            {
                              id: 10,
                              label: "Stage of Kidney Disease",
                              name: "HRAForm_Stage_Of_Kidney_Disease",
                              columns: [
                                {
                                  label: "Stages",
                                  type: "text",
                                },
                              ],
                              isReadOnly: true,
                            },
                            true,
                          ],
                          [
                            {
                              id: 11,
                              label: "Alcohol Usage",
                              name: "HRAForm_Alcohol_Usage",
                              columns: [
                                {
                                  label: "How many drinks",
                                  type: "select",
                                },
                                {
                                  label: "How often",
                                  type: "select",
                                },
                              ],
                              isReadOnly: true,
                            },
                            false,
                          ],
                          [
                            {
                              id: 12,
                              name: "Family History",
                              columns: [
                                {
                                  label: "Family Member",
                                  type: "select",
                                },
                                {
                                  label: "Medical Condition",
                                  type: "text",
                                },
                                {
                                  label: "Cause of Death",
                                  type: "text",
                                },
                              ],
                              isReadOnly: false,
                            },
                            false,
                          ],
                          [
                            {
                              id: 13,
                              label: "Depression Severity",
                              name: "Depression_Severity",
                              columns: [
                                {
                                  label: "Score",
                                  type: "number",
                                },
                                {
                                  label: "Depression Severity",
                                  type: "checkbox",
                                },
                              ],
                              isReadOnly: true,
                            },
                            false,
                          ],
                          [
                            {
                              id: 14,
                              label: "Word Recall",
                              name: "Word_Recall",
                              columns: [
                                {
                                  label: "Word Recall",
                                  type: "text",
                                },
                                {
                                  label: "Points",
                                  type: "select",
                                },
                                {
                                  label: "Description",
                                  type: "text",
                                },
                              ],
                              isReadOnly: true,
                            },
                            false,
                          ],
                        ],
                        xs: 12,
                        required: "no",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "whatStage",
                        type: "options_tree",
                        label: "What stage",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Stage 1",
                            value: "stage1",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "stage1",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Stage 2",
                            value: "stage2",
                            type: "toggle_button",
                            name: "stage2",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Satge 3a",
                            value: "satge3A",
                            type: "toggle_button",
                            name: "satge3A",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Stage 3b",
                            value: "stage3B",
                            type: "toggle_button",
                            name: "stage3B",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Stage 4",
                            value: "stage4",
                            type: "toggle_button",
                            name: "stage4",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Satge 5",
                            value: "satge5",
                            type: "toggle_button",
                            name: "satge5",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "secondaryToDiabetes",
                        type: "options_tree",
                        label: "Secondary to Diabetes",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 5,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "secondaryToHypertension",
                        type: "options_tree",
                        label: "Secondary to Hypertension",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 6,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Chronic Kidney Disease",
                    value: "chronicKidneyDisease",
                    type: "toggle_button",
                    name: "chronicKidneyDisease",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lab tests",
                            value: "labTests",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "labTests",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Calculated GFR X 3",
                            value: "calculatedGfrX3",
                            type: "toggle_button",
                            name: "calculatedGfrX3",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "patientOnDialysis",
                        type: "options_tree",
                        label: "Patient on dialysis",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 6,
                                name: "type",
                                type: "options_tree",
                                label: "Type",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 8,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Hemodialysis",
                                    value: "hemodialysis",
                                    isConfigured: true,
                                    type: "toggle_button",
                                    name: "hemodialysis",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Peritoneal dialysis",
                                    value: "peritonealDialysis",
                                    type: "toggle_button",
                                    name: "peritonealDialysis",
                                    depth: 8,
                                  },
                                ],
                                subQuestionId: 1,
                                editToggle: false,
                                depth: 7,
                              },
                              {
                                id: 6,
                                name: "location",
                                type: "options_tree",
                                label: "Location",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 8,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "In Home",
                                    value: "inHome",
                                    isConfigured: true,
                                    type: "toggle_button",
                                    name: "inHome",
                                  },
                                  {
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    subQuestions: [],
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Dialysis Center",
                                    value: "dialysisCenter",
                                    type: "toggle_button",
                                    name: "dialysisCenter",
                                    depth: 8,
                                  },
                                ],
                                subQuestionId: 2,
                                editToggle: false,
                                depth: 7,
                              },
                              {
                                id: 1,
                                name: "dialysisSchedule",
                                type: "text",
                                label: "Dialysis schedule",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 3,
                                depth: 7,
                              },
                              {
                                id: 1,
                                name: "taloaDevice",
                                type: "text",
                                label: "Type and location of access device",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 4,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "onASpecialDiet",
                        type: "options_tree",
                        label: "On a special diet",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "ESRD",
                    value: "esrd",
                    type: "toggle_button",
                    name: "esrd",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "history",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Erectile Dysfunction",
                    value: "erectileDysfunction",
                    type: "toggle_button",
                    name: "erectileDysfunction",
                    depth: 4,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "symptoms",
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cultures",
                            value: "cultures",
                            type: "toggle_button",
                            name: "cultures",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Laboratory results",
                            value: "laboratoryResults",
                            type: "toggle_button",
                            name: "laboratoryResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Frequent UTI",
                    value: "frequentUti",
                    type: "toggle_button",
                    name: "frequentUti",
                    depth: 4,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "symptoms",
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                            name: "history",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diagnostic results",
                            value: "diagnosticResults",
                            type: "toggle_button",
                            name: "diagnosticResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Vaginal Bleeding",
                            value: "vaginalBleeding",
                            type: "toggle_button",
                            name: "vaginalBleeding",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Gynecological",
                    value: "gynecological",
                    type: "toggle_button",
                    name: "gynecological",
                    depth: 4,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "history",
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "type",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "urate",
                            label: "Urate",
                            value: "urate",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Calcium Oxalate",
                            value: "calciumOxalate",
                            type: "toggle_button",
                            name: "calciumOxalate",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Magnesium",
                            value: "magnesium",
                            type: "toggle_button",
                            name: "magnesium",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Kidyney Stones",
                    value: "kidyneyStones",
                    type: "toggle_button",
                    name: "kidyneyStones",
                    depth: 4,
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "medicalHistory",
                            label: "Medical history",
                            value: "medicalHistory",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab tests",
                            value: "labTests",
                            type: "toggle_button",
                            name: "labTests",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Imaging studies",
                            value: "imagingStudies",
                            type: "toggle_button",
                            name: "imagingStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Nephritis or Nephrosis",
                    value: "nephritisOrNephrosis",
                    type: "toggle_button",
                    name: "nephritisOrNephrosis",
                    depth: 4,
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "history",
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            name: "dme",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "relatedToStress",
                        type: "options_tree",
                        label: "Related to stress",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 3,
                                name: "relatedTo",
                                label: "Related to ",
                                placeholder: "",
                                editToggle: false,
                                defaultValue: "",
                                type: "radio",
                                color: "primary",
                                options: [
                                  ["Dribbling", "dribbling"],
                                  ["Urgency", "urgency"],
                                  ["Other", "other"],
                                ],
                                xs: 6,
                                required: "no",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "daily",
                            label: "Daily",
                            value: "daily",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Few times a week",
                            value: "fewTimesAWeek",
                            type: "toggle_button",
                            name: "fewTimesAWeek",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Less than once a week",
                            value: "lessThanOnceAWeek",
                            type: "toggle_button",
                            name: "lessThanOnceAWeek",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Urinary Incontinence",
                    value: "urinaryIncontinence",
                    type: "toggle_button",
                    name: "urinaryIncontinence",
                    depth: 4,
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "history",
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms ",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            name: "dme",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "other",
                        type: "text",
                        label: "Other",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    name: "other",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 71,
        editToggle: false,
        depth: 1,
      },
      {
        id: 10,
        name: "ros_genitoRecommendations",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        color: "primary",
        options: [
          [
            "Take medications as prescribed",
            "takeMedicationsAsPrescribedGenito",
          ],
          ["Other", "otherGenito"],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 72,
        depth: 1,
      },
      {
        id: 10,
        name: "musculoskeletal",
        type: "heading",
        label: "MUSCULOSKELETAL",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 11,
      },
      {
        id: 8,
        name: "ros_musculoskeletal",
        type: "options_tree",
        label:
          "Musculoskeletal Problems (Spinal Stenosis, Rheumatoid Arthritis, Gout, Osteoporosis, Others)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            subQuestions: [
              {
                id: 6,
                name: "ros_musculoskeletalDiagnoses",
                value: "diagnoses",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    name: "",
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Collagen(Connective Tissue disease)",
                    value: "collagen",
                    name: "ros_collagenSubAnswer",
                    isConfigured: true,
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_collagenSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Collagen (Connective) Tissue Disease- Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_collagenSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab Tests",
                            value: "labTests",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_collagenSubAnswercollagenDescribe",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "SLE(Lupus)",
                            value: "sleLupus",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scleroderma",
                            value: "scleroderma",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Dermatomyositis",
                            value: "dermatomyositis",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Degenerative Disc Disease",
                    name: "ros_degenerativeDiscDiseaseSubAnswer",
                    value: "degenerativeDiscDisease",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_degenerativeDiscDiseaseSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Degenerative Disc Disease-  Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_degenerativeDiscDiseaseSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        value: "supportedBy",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_degenerativeDiscDiseaseSubAnswerNormalBladder",
                        value: "normalBladder",
                        type: "options_tree",
                        label: "Normal bladder and bowel function",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "No",
                            value: "no",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_degenerativeDiscDiseaseSubAnswerSiteOfDisease",
                        value: "siteOfDisease",
                        type: "options_tree",
                        label: "Site of disease",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Cervical",
                            value: "cervical",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Thoracic",
                            value: "thoracic",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lumbar",
                            value: "lumbar",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lumbosacral",
                            value: "lumbosacral",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Other",
                            value: "other",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                depth: 2,
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                subQuestions: [],
                                editToggle: false,
                                isCommentEnabled: false,
                                label: "Describe",
                                value: "",
                                isConfigured: true,
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Extremity Fracture",
                    value: "extremityFracture",
                    name: "ros_extremityFractureSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_extremityFractureSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Extremity Fracture Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                depth: 2,
                                hasSubQuestions: true,
                                editToggle: false,
                                isCommentEnabled: false,
                                label: "Type",
                                value: "activeTypeSubAnswer",
                                name: "ros_extremityFractureActiveTypeSubAnswer",
                                isConfigured: true,
                                type: "toggle_button",
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 2,
                                    subQuestionId: 1,
                                    hasSubQuestions: true,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Traumatic",
                                    value: "traumatic",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 2,
                                    subQuestionId: 2,
                                    hasSubQuestions: true,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pathological",
                                    value: "pathological",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            subQuestions: [
                              {
                                depth: 2,
                                hasSubQuestions: true,
                                editToggle: false,
                                isCommentEnabled: false,
                                label: "Type",
                                value: "historyOfSubAnswer",
                                name: "ros_extremityFractureHistoryOfSubAnswer",
                                isConfigured: true,
                                type: "toggle_button",
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 2,
                                    subQuestionId: 1,
                                    hasSubQuestions: true,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Traumatic",
                                    value: "traumatic",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 2,
                                    subQuestionId: 2,
                                    hasSubQuestions: true,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Pathological",
                                    value: "pathological",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_extremityFractureSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: "Supported By",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            subQuestions: [
                              {
                                depth: 1,
                                subQuestionId: 1,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_extremityFractureSubAnswerExtremity",
                        type: "options_tree",
                        value: "extremity",
                        label: "Extremity",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Shoulder ",
                            value: "shoulder",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureShoulderSubAnswer",
                                value: "shoulderSubAnswer",
                                type: "options_tree",
                                label: "Shoulder Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Arm",
                            value: "arm",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureArmSubAnswer",
                                value: "armSubAnswer",
                                type: "options_tree",
                                label: "Arm Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    name: "ros_",
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },

                          {
                            depth: 1,
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Forearm",
                            value: "forearm",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureForearmSubAnswer",
                                value: "forearmSubAnswer",
                                type: "options_tree",
                                label: "Forearm Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Wrist",
                            value: "wrist",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureWristSubAnswer",
                                value: "wristSubAnswer",
                                type: "options_tree",
                                label: "Wrist Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hand",
                            value: "hand",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_handSubAnswer",
                                value: "extremityFractureHandSubAnswer",
                                type: "options_tree",
                                label: "Hand Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Femoral Shaft",
                            value: "femoralShaft",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_femoralShaftSubAnswer",
                                value: "extremityFractureFemoralShaftSubAnswer",
                                type: "options_tree",
                                label: "Femoral Shaft Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Tibia",
                            value: "tibia",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureTibiaSubAnswer",
                                value: "tibiaSubAnswer",
                                type: "options_tree",
                                label: "Tibia Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 8,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Fibula",
                            value: "fibula",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureFibulaSubAnswer",
                                value: "fibulaSubAnswer",
                                type: "options_tree",
                                label: "Fibula Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Ankle",
                            value: "ankle",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureAnkleSubAnswer",
                                value: "ankleSubAnswer",
                                type: "options_tree",
                                label: "Ankle Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 10,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Foot",
                            value: "foot",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureFootSubAnswer",
                                value: "footSubAnswer",
                                type: "options_tree",
                                label: "Foot Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            depth: 1,
                            subQuestionId: 11,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hip",
                            value: "hip",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_extremityFractureHipSubAnswer",
                                value: "hipSubAnswer",
                                type: "options_tree",
                                label: "Hip Laterality",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    depth: 1,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Left",
                                    value: "left",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                  {
                                    depth: 1,
                                    subQuestionId: 2,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    label: "Right",
                                    value: "right",
                                    isConfigured: true,
                                    type: "toggle_button",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 3,
                        name: "ros_extremityFractureCurrentWithinTwelveWeeksSubAnswer",
                        type: "options_tree",
                        value: "currentWithinTwelveWeeks",
                        label: "Current (within 12 weeks)",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Gout",
                    value: "gout",
                    name: "ros_goutSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_goutSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Gout Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_goutSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History of attacks in foot",
                            value: "historyOfAttacks",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab tests",
                            value: "labTests",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                hasSubQuestions: true,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Other",
                                value: "",
                                type: "label",
                                subQuestions: [
                                  {
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Describe",
                                    value: "describe",
                                    type: "text",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hallux Valgus",
                    value: "halluxValgus",
                    name: "ros_halluxValgusSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_halluxValgusSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Hallux Valgus Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_halluxValgusSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hammer Toes",
                    value: "hammerToes",
                    name: "ros_hammerToesSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_hammerToesSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Hammer Toes Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_hammerToesSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Osteoarthritis",
                    value: "osteoarthritis",
                    name: "ros_osteoarthritisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_osteoarthritisSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Osteoarthritis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_osteoarthritisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        value: "supportedBy",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "options_tree",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_osteoarthritisSubAnswerSymptomsSubAnswer",
                                type: "options_tree",
                                value: "symptomsSubAnswer",
                                label: "Symptoms Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    value: "jointSwelling",
                                    type: "toggle_button",
                                    label: "Joint swelling",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                  {
                                    value: "jointStiffness",
                                    type: "toggle_button",
                                    label: "Joint stiffness",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                  {
                                    value: "pain",
                                    type: "toggle_button",
                                    label: "Pain",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                  {
                                    value: "limitedRom",
                                    type: "toggle_button",
                                    label: "Limited ROM",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            name: "ros_osteoarthritisSubAnswerOtherSubAnswer",
                            type: "options_tree",
                            subQuestions: [
                              {
                                value: "describe",
                                type: "text",
                                label: "Other Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_osteoarthritisSubAnswerWhichJoints",
                        depth: 3,
                        type: "text",
                        label: "Which Joints",
                        value: "whichJoints",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Osteomyelitis",
                    value: "osteomyelitis",
                    name: "ros_osteomyelitisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_osteomyelitisSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Osteomyelitis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_osteomyelitisSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " Hospitalization ",
                            value: "hospitalization",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cultures",
                            value: "cultures",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Osteoporosis",
                    value: "osteoporosis",
                    name: "ros_osteoporosisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_osteoporosisSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Osteoporosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_osteoporosisSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " Dexa Scan ",
                            value: "dexaScan",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Imaging Studies",
                            value: "imagingStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Fracture history",
                            value: "fractureHistory",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Pyogenic Arthritis",
                    value: "pyogenicArthritis",
                    name: "ros_pyogenicArthritisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_pyogenicArthritisSubAnswerDescribe",
                        type: "options_tree",
                        label: "Pyogenic Arthritis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_pyogenicArthritisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Cultures",
                            value: "cultures",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                name: "ros_describe",
                                type: "text",
                                label: "Other Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_pyogenicArthritisSubAnswerWhichJoints",
                        depth: 3,
                        type: "text",
                        label: "Which Joint/s",
                        value: "whichJoints",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Rheumatoid Arthritis",
                    value: "rheumatoidArthritis",
                    name: "ros_rheumatoidArthritisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_rheumatoidArthritisSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Rheumatoid Arthritis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_rheumatoidArthritisSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                id: 6,
                                name: "ros_rheumatoidArthritisSubAnswerSymptoms",
                                type: "options_tree",
                                value: "isSymptomsSubAnswer",
                                label: "Symptoms Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                                subQuestions: [
                                  {
                                    value: "jointSwelling",
                                    type: "toggle_button",
                                    label: "Joint swelling",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                  {
                                    value: "jointStiffness",
                                    type: "toggle_button",
                                    label: "Joint stiffness",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                  {
                                    value: "pain",
                                    type: "toggle_button",
                                    label: "Pain",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                  {
                                    value: "limitedRom",
                                    type: "toggle_button",
                                    label: "Limited ROM",
                                    fullWidth: true,
                                    required: true,
                                    xs: 12,
                                    isCommentEnabled: true,
                                    isConfigured: true,
                                    isModified: true,
                                    hasSubQuestions: true,
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab tests",
                            value: "labTests",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                value: "describe",
                                type: "text",
                                label: "Other Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_rheumatoidArthritisSubAnswerWhichJoints",
                        depth: 3,
                        type: "text",
                        label: "Which Joints",
                        value: "whichJoints",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Spinal Stenosis",
                    value: "spinalStenosis",
                    name: "ros_spinalStenosisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_spinalStenosisSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Spinal Stenosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_spinalStenosisSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                name: "ros_spinalStenosisSubAnswerOther",
                                type: "text",
                                value: "describe",
                                label: "Other Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_spinalStenosisSubAnswerNormalBladder",
                        type: "options_tree",
                        value: "normalBladder",
                        label: "Normal bladder and bowel function",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "No",
                            value: "no",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Systemic Lupus Erythematosus",
                    value: "systemicLupus",
                    name: "ros_systemicLupusSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_systemicLupusSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Systemic Lupus Erythematosus Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_systemicLupusSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Labs",
                            value: "labs",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                name: "ros_systemicLupusSubAnswerOther",
                                type: "text",
                                value: "describe",
                                label: "Other Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    name: "ros_otherSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_muskoOtherSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Other Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_muskoOtherSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test Results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                name: "ros_otherSubAnswerOther",
                                type: "text",
                                value: "describe",
                                label: "Other Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_muskoOtherSubAnswerother",
                        depth: 3,
                        type: "text",
                        label: "Other",
                        value: "other",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
              {
                id: 9,
                //questionId: 16,
                hasSubQuestions: true,
                editToggle: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                name: "ros_muskoHadAnAmputation",
                label: "Have you had an amputation?",
                value: "hadAnAmputation",
                type: "options_tree",
                subQuestions: [
                  {
                    //subQuestionId: 1,
                    depth: 1,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Yes",
                    value: "yes",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 4,
                        name: "ros_hadAnAmputationSubAnswer",
                        depth: 3,
                        type: "text",
                        label: "Describe",
                        value: "describe",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                  {
                    //subQuestionId: 2,
                    hasSubQuestions: false,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                  },
                ],
              },
            ],
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 15,
        editToggle: false,
      },

      {
        id: 10,
        name: "musko_Recommendations",
        value: "takeMedicationsAsPescribed",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Discuss PT/OT evaluation with PCP", "discussPtOt"],
          ["Take medications as prescribed", "takeMedicationsAsPescribed"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 55,
      },
      {
        id: 10,
        name: "integument",
        type: "heading",
        label: "Integument",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 11,
      },
      {
        id: 7,
        name: "ros_integument",
        type: "options_tree",
        label:
          "Integument Problems (Eczema, Psoriasis, Dermatitis, Urticaria, Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            subQuestions: [
              {
                id: 6,
                name: "ros_integumentDiagnoses",
                value: "diagnoses",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    name: "ros_basilCellCarcinomaSubAnswer",
                    value: "basilCellCarcinoma",
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    label: " Basil Cell Carcinoma ",
                    isConfigured: true,
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_basilCellCarcinomaSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Basil Cell Carcinoma   Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_basilCellCarcinomaSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Symptoms ",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Physical Findings ",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Medications ",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Test results ",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Image studies ",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: " Biopsy ",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Dermatitis",
                    name: "ros_dermatitisSubAnswer",
                    value: "dermatitis",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_dermatitisSubAnswerDescribe",
                        type: "options_tree",
                        label: "Dermatitis   Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_dermatitisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_dermatitisSubAnswerWhatType",
                        type: "options_tree",
                        value: "whatType",
                        label: "What type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Contact",
                            value: "contact",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Statis",
                            value: "stasis",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Drug induced",
                            value: "drugInduced",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Disease Induced",
                            value: "diseaseInduced",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unspecified",
                            value: "unspecified",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Eczema",
                    value: "eczema",
                    name: "ros_eczemaSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_eczemaSubAnswerDescribe",
                        type: "options_tree",
                        label: "Eczema Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_eczemaSubAnswerSupportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Onychomysosis",
                    value: "onychomycosis",
                    name: "ros_onychomycosisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_onychomycosisSubAnswerDescribe",
                        type: "options_tree",
                        label: "Onychomycosis Describe",
                        value: "describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_onychomycosisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        value: "supportedBy",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Psoriasis",
                    value: "psoriasis",
                    name: "ros_psoriasisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_psoriasisSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Psoriasis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_psoriasisSubAnswerSupportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        value: "supportedBy",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 8,
                        name: "ros_psoriasisSubAnswerPsoriaticArthritis",
                        type: "options_tree",
                        value: "psoriaticArthritis",
                        label: "History of Psoriatic Arthritis",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Skin ulcer",
                    value: "skinUlcer",
                    name: "ros_skinUlcerSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_skinUlcerSubAnswerDescribe",
                        type: "options_tree",
                        label: "Skin ulcer Describe",
                        value: "describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_skinUlcerSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        value: "supportedBy",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_skinUlcerSubAnswerEtiology",
                        type: "options_tree",
                        label: "Etiology",
                        value: "etiology",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Pressure ",
                            value: "pressure",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                //id: 4,
                                name: "ros_skinUlcerSubAnsweEtiologyPressure",
                                depth: 4,
                                type: "text",
                                label: "Stage and Location",
                                value: "other",
                                editToggle: false,
                                xs: 4,
                                required: false,
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                hasSubQuestions: false,
                              },
                            ],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Venous statis",
                            value: "venousStatis",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Peripheral Vascular Disease",
                            value: "peripheralVascular",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Disease induced",
                            value: "diseaseInduced",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diabetic vasculitis",
                            value: "diabeticVasculitis",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diabitic Neuropathy",
                            value: "diabeticNeuropathy",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: " Tinea Pedis",
                    value: "tineaPedisSubAnswer",
                    name: "ros_tineaPedis",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_tineaPedisSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: " Tinea Pedis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_tineaPedisSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Urticarial Disease",
                    value: "urticarialDisease",
                    name: "ros_urticarialDiseaseSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_urticarialDiseaseSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Urticarial Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_urticarialDiseaseSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_urticarialDiseaseSubAnswerType",
                        type: "options_tree",
                        value: "type",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Acute ",
                            value: "acute",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Chronic",
                            value: "chronic",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_urticarialDiseaseSubAnswerEtiology",
                        depth: 3,
                        type: "text",
                        label: "Etiology",
                        value: "etiology",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Wound",
                    value: "wound",
                    name: "ros_woundSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_woundSubAnswerDescribe",
                        type: "options_tree",
                        label: "Wound Describe",
                        value: "describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_woundSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_woundSubAnswerEtiology",
                        type: "options_tree",
                        label: "Etiology",
                        value: "etiology",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Surgical",
                            value: "surgical",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Traumatic",
                            value: "traumatic",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Burn",
                            value: "burn",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    name: "ros_otherSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_othersSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Other Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_othersSubAnswerSupportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        value: "supportedBy",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " History ",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test resules",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 12,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_integumentOthersSubAnswerOther",
                        depth: 3,
                        type: "text",
                        label: "Other",
                        value: "other",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 15,
        editToggle: false,
      },
      {
        id: 10,
        name: "integument_Recommendations",
        value: "takeMedicationsAsPescribed",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Take medications as prescribed", "takeMedicationsAsPescribed"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 55,
      },
      {
        id: 9,
        name: "endocrine",
        type: "heading",
        label: "Endocrine",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 62,
        depth: 1,
      },
      {
        id: 6,
        name: "ros_endoVal",
        type: "options_tree",
        label: "Endocrine Problems",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_endoSubDiagnoses",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_endoSubDiagnosesdeschronicKidneydes",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_endoSubDiagnoseschronicKidneySup",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Decreased GFR",
                            value: "decreasedGfr",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "decreasedGfr",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Albuminuria",
                            value: "albuminuria",
                            type: "toggle_button",
                            name: "albuminuria",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Elevated BUN/Creatinine",
                            value: "elevatedBunCreatinine",
                            type: "toggle_button",
                            name: "elevatedBunCreatinine",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Dialysis",
                            value: "dialysis",
                            type: "toggle_button",
                            name: "dialysis",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_endoSubDiagnoseschronicKidneypatientOnAce",
                        type: "options_tree",
                        label: "Patient on ACE or ARB",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Chronic Kidney Disease secondary to Diabetes",
                    value: "ckdstDiabetes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "ckdstDiabetes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "history",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            name: "dme",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "isPatientOnAnStatin",
                        type: "options_tree",
                        label: "Is patient on an statin",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "isPatientOnAnAspirin",
                        type: "options_tree",
                        label: "Is patient on an aspirin",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Coronary Artery Disease and Diabetes",
                    value: "coronaryArteryDiseaseAndDiabet",
                    type: "toggle_button",
                    name: "coronaryArteryDiseaseAndDiabet",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Physical exam",
                            value: "physicalExam",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "physicalExam",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab tests",
                            value: "labTests",
                            type: "toggle_button",
                            name: "labTests",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Suppression Test",
                            value: "suppressionTest",
                            type: "toggle_button",
                            name: "suppressionTest",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Cushing's Disease",
                    value: "cushingsDisease",
                    type: "toggle_button",
                    name: "cushingsDisease",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "symptoms",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab tests",
                            value: "labTests",
                            type: "toggle_button",
                            name: "labTests",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "type",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Type 1",
                            value: "type1",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "type1",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Type 1.5",
                            value: "type15",
                            type: "toggle_button",
                            name: "type15",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Type 2",
                            value: "type2",
                            type: "toggle_button",
                            name: "type2",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Gestational",
                            value: "gestational",
                            type: "toggle_button",
                            name: "gestational",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 1,
                        name: "mostRecentHbA1CValue",
                        type: "text",
                        label: "Most recent Hb A1C, value",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 4,
                        depth: 5,
                      },
                      {
                        id: 4,
                        name: "andDate",
                        label: "And Date",
                        placeholder: "",
                        type: "date",
                        editToggle: false,
                        xs: 4,
                        defaultValue: "2024-03-01",
                        value: "2024-03-01",
                        fullWidth: true,
                        required: "no",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 5,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "mwanodfdEducation",
                        type: "options_tree",
                        label:
                          "Met with a nurse or dietician for diabetic education",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 6,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "metWithADiabeticEducator",
                        type: "options_tree",
                        label: "Met with a diabetic educator",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 7,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "dytybSugar",
                        type: "options_tree",
                        label: "Do you test your blood sugar",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 8,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Diabetes",
                    value: "diabetes",
                    type: "toggle_button",
                    name: "diabetes",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hitsory of",
                            value: "hitsoryOf",
                            type: "toggle_button",
                            name: "hitsoryOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "type",
                        type: "options_tree",
                        label: "Type",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Proliferative",
                            value: "proliferative",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "proliferative",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Non -proliferative",
                            value: "nonProliferative",
                            type: "toggle_button",
                            name: "nonProliferative",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "specify",
                        type: "options_tree",
                        label: "Specify",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Right eye",
                            value: "rightEye",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "rightEye",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Left eye",
                            value: "leftEye",
                            type: "toggle_button",
                            name: "leftEye",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Bilateral",
                            value: "bilateral",
                            type: "toggle_button",
                            name: "bilateral",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Funduscopic exam",
                            value: "funduscopicExam",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "funduscopicExam",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Vision loss",
                            value: "visionLoss",
                            type: "toggle_button",
                            name: "visionLoss",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Laser Therapy",
                            value: "laserTherapy",
                            type: "toggle_button",
                            name: "laserTherapy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Retinal injections",
                            value: "retinalInjections",
                            type: "toggle_button",
                            name: "retinalInjections",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Surgical procedure",
                            value: "surgicalProcedure",
                            type: "toggle_button",
                            name: "surgicalProcedure",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "patientSeesOphthalmologist",
                        type: "options_tree",
                        label: "Patient sees Ophthalmologist",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Occasionally",
                            value: "occasionally",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "occasionally",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Once a year",
                            value: "onceAYear",
                            type: "toggle_button",
                            name: "onceAYear",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Twice a year",
                            value: "twiceAYear",
                            type: "toggle_button",
                            name: "twiceAYear",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: ">Twice a year",
                            value: "twiceAYear",
                            type: "toggle_button",
                            name: "twiceAYear",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 5,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Diabetic Retinopathy",
                    value: "diabeticRetinopathy",
                    type: "toggle_button",
                    name: "diabeticRetinopathy",
                    depth: 4,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Active",
                            value: "active",
                            type: "toggle_button",
                            name: "active",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History Chronic Kidney Disease",
                            value: "historyChronicKidneyDisease",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "historyChronicKidneyDisease",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History Celiac Disease",
                            value: "historyCeliacDisease",
                            type: "toggle_button",
                            name: "historyCeliacDisease",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Malabsorption",
                            value: "malabsorption",
                            type: "toggle_button",
                            name: "malabsorption",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Bariatric Surgery",
                            value: "bariatricSurgery",
                            type: "toggle_button",
                            name: "bariatricSurgery",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab tests",
                            value: "labTests",
                            type: "toggle_button",
                            name: "labTests",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Histroy of Kidney stones",
                            value: "histroyOfKidneyStones",
                            type: "toggle_button",
                            name: "histroyOfKidneyStones",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of fractures",
                            value: "historyOfFractures",
                            type: "toggle_button",
                            name: "historyOfFractures",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Imaging studies",
                            value: "imagingStudies",
                            type: "toggle_button",
                            name: "imagingStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Fatigue",
                            value: "fatigue",
                            type: "toggle_button",
                            name: "fatigue",
                            depth: 6,
                          },
                          {
                            subQuestionId: 10,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "dueToKidneyDisease",
                        type: "options_tree",
                        label: "Due to kidney disease?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                            name: "unknown",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Secondary Hyperparathyroidism",
                    value: "secondaryHyperparathyroidism",
                    type: "toggle_button",
                    name: "secondaryHyperparathyroidism",
                    depth: 4,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "active",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "history",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Phyiscal Findings",
                            value: "phyiscalFindings",
                            type: "toggle_button",
                            name: "phyiscalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            name: "dme",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ipoaoARB",
                        type: "options_tree",
                        label: "Is patient on Ace or ARB",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hypertension and Diabetes",
                    value: "hypertensionAndDiabetes",
                    type: "toggle_button",
                    name: "hypertensionAndDiabetes",
                    depth: 4,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "rapidHeartBeat",
                            label: "Rapid Heart Beat",
                            value: "rapidHeartBeat",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Nervousness",
                            value: "nervousness",
                            type: "toggle_button",
                            name: "nervousness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Weight Loss",
                            value: "weightLoss",
                            type: "toggle_button",
                            name: "weightLoss",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Heat intolerance",
                            value: "heatIntolerance",
                            type: "toggle_button",
                            name: "heatIntolerance",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Tremor",
                            value: "tremor",
                            type: "toggle_button",
                            name: "tremor",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab Data",
                            value: "labData",
                            type: "toggle_button",
                            name: "labData",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of treatment for Hyperthyroidism",
                            value: "historyOfTreatmentForHyperthyr",
                            type: "toggle_button",
                            name: "historyOfTreatmentForHyperthyr",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hyper Reflexes",
                            value: "hyperReflexes",
                            type: "toggle_button",
                            name: "hyperReflexes",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hyperthyroidism",
                    value: "hyperthyroidism",
                    type: "toggle_button",
                    name: "hyperthyroidism",
                    depth: 4,
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "weightGain",
                            label: "Weight gain",
                            value: "weightGain",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Fatigue",
                            value: "fatigue",
                            type: "toggle_button",
                            name: "fatigue",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hair changes",
                            value: "hairChanges",
                            type: "toggle_button",
                            name: "hairChanges",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Depression",
                            value: "depression",
                            type: "toggle_button",
                            name: "depression",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Treatment for hypothyroidism",
                            value: "treatmentForHypothyroidism",
                            type: "toggle_button",
                            name: "treatmentForHypothyroidism",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab data ",
                            value: "labData",
                            type: "toggle_button",
                            name: "labData",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hypothyroidism",
                    value: "hypothyroidism",
                    type: "toggle_button",
                    name: "hypothyroidism",
                    depth: 4,
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "physicalExam",
                            label: "Physical exam",
                            value: "physicalExam",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                            name: "medication",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "patientSeesPodiatrist",
                        type: "options_tree",
                        label: "Patient sees Podiatrist",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 3,
                                name: "howOften",
                                label: "How often",
                                placeholder: "",
                                editToggle: false,
                                defaultValue: "",
                                type: "radio",
                                color: "primary",
                                options: [
                                  ["Once a year", "onceAYear"],
                                  ["Twice a year", "twiceAYear"],
                                  ["Quarterly", "quarterly"],
                                ],
                                xs: 6,
                                required: "no",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Peripheral Neuropathy secondary to Diabetes",
                    value: "peripheralNeuropathySecondaryT",
                    type: "toggle_button",
                    name: "peripheralNeuropathySecondaryT",
                    depth: 4,
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of ",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "physicalExam",
                            label: "Physical exam",
                            value: "physicalExam",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Vascular studies",
                            value: "vascularStudies",
                            type: "toggle_button",
                            name: "vascularStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Skin lesions",
                            value: "skinLesions",
                            type: "toggle_button",
                            name: "skinLesions",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Foot deformity",
                            value: "footDeformity",
                            type: "toggle_button",
                            name: "footDeformity",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Surgical procedures",
                            value: "surgicalProcedures",
                            type: "toggle_button",
                            name: "surgicalProcedures",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Intermittent claudication",
                            value: "intermittentClaudication",
                            type: "toggle_button",
                            name: "intermittentClaudication",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "patientSeesPodiatrist",
                        type: "options_tree",
                        label: "Patient sees Podiatrist",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 3,
                                name: "howOften",
                                label: "How often",
                                placeholder: "",
                                editToggle: false,
                                defaultValue: "",
                                type: "radio",
                                color: "primary",
                                options: [
                                  ["Once a year", "onceAYear"],
                                  ["Twice a year", "twiceAYear"],
                                  ["Quarterly", "quarterly"],
                                ],
                                xs: 6,
                                required: "no",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "yes",
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Peripheral Vascular Disease secondary to Diabetes",
                    value: "pvdstDiabetes",
                    type: "toggle_button",
                    name: "pvdstDiabetes",
                    depth: 4,
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "labTests",
                            label: "Lab tests",
                            value: "labTests",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of kidney stones",
                            value: "historyOfKidneyStones",
                            type: "toggle_button",
                            name: "historyOfKidneyStones",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of Fractures",
                            value: "historyOfFractures",
                            type: "toggle_button",
                            name: "historyOfFractures",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Imaging studies",
                            value: "imagingStudies",
                            type: "toggle_button",
                            name: "imagingStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Fatigue",
                            value: "fatigue",
                            type: "toggle_button",
                            name: "fatigue",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hyperparathyroidism",
                    value: "hyperparathyroidism",
                    type: "toggle_button",
                    name: "hyperparathyroidism",
                    depth: 4,
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "describe",
                        type: "options_tree",
                        label: "Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "active",
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            name: "historyOf",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [{}],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                            name: "ruleOut",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "supportedBy",
                        type: "options_tree",
                        label: "Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "history",
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                            name: "symptoms",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                            name: "physicalFindings",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                            name: "medications",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test results",
                            value: "testResults",
                            type: "toggle_button",
                            name: "testResults",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                            name: "imageStudies",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                            name: "biopsy",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                            name: "dme",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 5,
                        editToggle: false,
                      },
                      {
                        id: 1,
                        name: "other",
                        type: "text",
                        label: "Other",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 3,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    name: "other",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 63,
        editToggle: false,
        depth: 1,
      },
      {
        id: 10,
        name: "endocrine_recommendations",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Take medications as prescribed", "takeMedicationsAsPescribed"],
          ["Check Blood Sugar", "checkBloodSugar"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 64,
        depth: 1,
      },
      {
        id: 10,
        name: "hematology",
        type: "heading",
        label: "HEMATOLOGY / IMMUNOLOGY / INFECTIOUS DISEASE",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 11,
      },
      {
        id: 8,
        name: "ros_hematology",
        type: "options_tree",
        label:
          "Hematology / Immunology / Infection Disease Problems (Anemia, easy bruising or abnormal bleeding Thrombocytopenia , Other)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            subQuestions: [
              {
                id: 6,
                name: "ros_hematologyDiagnoses",
                value: "diagnoses",
                type: "options_tree",
                label: "Diagnoses",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "AIDS",
                    value: "aids",
                    name: "ros_aidsSubAnswer",
                    isConfigured: true,
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_aidsSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "AIDS- Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_aidsSubAnswerSupportedBy",
                        value: "supportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of opportunistic infections",
                            value: "historyOfOpportunistic",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_aidsSubAnswerPatientUnderTreatment",
                        value: "patientUnderTreatment",
                        type: "options_tree",
                        label: "Is patient currently under treatment",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Anemia",
                    name: "ros_anemiaSubAnswer",
                    value: "anemia",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_anemiaSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        value: "describe",
                        label: "Anemia-  Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_anemiaSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        value: "supportedBy",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lab Tests",
                            value: "labTests",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Symptoms",
                            value: "symptoms",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of blood transfusion",
                            value: "historyOfBloodTransfusion",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_anemiaSubAnswerEtiology",
                        value: "etiology",
                        type: "options_tree",
                        label: "Etiology",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Iron deficiency",
                            value: "ironDeficiency",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Pernicious",
                            value: "pernicious",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Kidney Disease",
                            value: "kidneyDisease",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Hemolysis",
                            value: "hemolysis",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Aplastic",
                            value: "aplastic",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Chemotherapy",
                            value: "chemotherapy",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Blood loss",
                            value: "bloodLoss",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Chronic disease",
                            value: "chronicDisease",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 9,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Folate deficiency",
                            value: "folateDeficiency",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 10,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Other",
                            value: "other",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_anemiaSubAnswerYesPatientOn",
                        value: "yesPatientOn",
                        type: "options_tree",
                        label: "If yes, Patient on",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Iron",
                            value: "iron",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "B 12",
                            value: "bTwelve",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Folic Acid",
                            value: "folicAcid",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Blood Transfusions",
                            value: "bloodTransfusions",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 1,
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Other",
                            value: "other",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [
                              {
                                depth: 2,
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                subQuestions: [],
                                editToggle: false,
                                isCommentEnabled: false,
                                label: "Describe",
                                value: "",
                                isConfigured: true,
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "C. Difficile",
                    value: "cDifficile",
                    name: "ros_cDifficileSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_cDifficileSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "C. Difficile Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                            subQuestions: [],
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                            subQuestions: [],
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_cDifficileSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: "Supported By",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Positive lab tests",
                            value: "positiveLabTest",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medication",
                            value: "medication",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            subQuestions: [
                              {
                                depth: 1,
                                subQuestionId: 1,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Community Acquired MRSA Infection",
                    value: "communityAcquired",
                    name: "ros_communityAcquiredSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_communityAcquiredSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Community Acquired MRSA Infection Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_communityAcquiredSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Cultures",
                            value: "cultures",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                hasSubQuestions: true,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Other",
                                value: "",
                                type: "label",
                                subQuestions: [
                                  {
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "Describe",
                                    value: "describe",
                                    type: "text",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "HIV",
                    value: "hiv",
                    name: "ros_hivSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_hivSubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "HIV Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_hivSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lab Tests",
                            value: "labTests",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_hivSubAnswerViralLoad",
                        depth: 3,
                        type: "text",
                        label: "Viral load",
                        value: "viralLoad",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                      {
                        id: 5,
                        name: "ros_hivSubAnswerCFour",
                        depth: 3,
                        type: "text",
                        label: "C4",
                        value: "cFour",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                      {
                        subQuestionId: 5,
                        hasSubQuestions: true,
                        editToggle: false,
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: false,
                        label: "Patient currently symptomatic",
                        value: "patientSymptomatic",
                        name: "ros_hivSubAnswerPatientSymptomatic",
                        type: "options_tree",
                        subQuestions: [
                          {
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Yes",
                            value: "yes",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                      },
                      {
                        subQuestionId: 6,
                        hasSubQuestions: true,
                        editToggle: false,
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: false,
                        name: "ros_hivSubAnswerActiveTreatment",
                        label: "Is patient currently under active treatment",
                        value: "activeTreatment",
                        type: "options_tree",
                        subQuestions: [
                          {
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Yes",
                            value: "yes",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Herpes Zoster",
                    value: "herpesZoster",
                    name: "ros_herpesZosterSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_herpesZosterSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Herpes Zoster Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_herpesZosterSubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: " Rash ",
                            value: "rash",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hospital Acquired MRSA Infection",
                    value: "mrsaInfection",
                    name: "ros_mrsaInfectionSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_mrsaInfectionSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Hospital Acquired MRSA Infection Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_mrsaInfectionSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Cultures",
                            value: "cultures",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Other",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Immune Deficiency",
                    value: "immuneDeficiency",
                    name: "ros_immuneDeficiencySubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_immuneDeficiencySubAnswerDescribe",
                        type: "options_tree",
                        value: "describe",
                        label: "Immune Deficiency Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 7,
                        name: "ros_immuneDeficiencySubAnswerSupportedBy",
                        type: "options_tree",
                        value: "supportedBy",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image Studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_immuneDeficiencySubAnswerEtiology",
                        depth: 3,
                        type: "text",
                        label: "Etiology",
                        value: "etiology",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },

                      {
                        subQuestionId: 4,
                        hasSubQuestions: true,
                        editToggle: false,
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: false,
                        name: "ros_immuneDeficiencySubAnswerHistoryOfOpportunistic",
                        label: "History of Opportunistic Infection",
                        value: "historyOfOpportunistic",
                        type: "options_tree",
                        subQuestions: [
                          {
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Yes",
                            value: "yes",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                      },
                      {
                        subQuestionId: 6,
                        hasSubQuestions: true,
                        editToggle: false,
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: false,
                        label: "Is patient currently under active treatment",
                        value: "",
                        type: "options_tree",
                        subQuestions: [
                          {
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Yes",
                            value: "yes",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Sepsis",
                    value: "sepsis",
                    name: "ros_sepsisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_sepsisSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Sepsis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_sepsisSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Blood Cultures",
                            value: "bloodCultures",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other cultures",
                            value: "otherCultures",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalization",
                            value: "hospitalization",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unstable vital signs",
                            value: "unstableVitalSigns",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Sickle Cell Disease",
                    value: "sickleCellDisease",
                    name: "ros_sickleCellDiseaseTwoSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_sickleCellDiseaseTwoSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Sickle Cell Disease Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_sickleCellDiseaseTwoSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Family History",
                            value: "familyHistory",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of infections",
                            value: "historyOfInfections",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Hospitalizations",
                            value: "hospitalizations",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Sickle Cell Trait",
                    value: "sickleCellTrait",
                    name: "ros_sickleCellTraitSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_sickleCellTraitSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Sickle Cell Trait Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_sickleCellTraitSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Family History",
                            value: "familyHistory",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab test",
                            value: "labTest",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Thalassemia",
                    value: "thalassemia",
                    name: "ros_thalassemiaSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_thalassemiaSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Thalassemia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_thalassemiaSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Family History",
                            value: "familyHistory",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Lab tests",
                            value: "labTests",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of infections",
                            value: "historyOfInfections",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Thrombocytopenia",
                    value: "thrombocytopenia",
                    name: "ros_thrombocytopeniaSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_thrombocytopeniaSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Thrombocytopenia Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_thrombocytopeniaSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Lab tests",
                            value: "labTests",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical Findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                hasSubQuestions: true,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Other",
                                value: "describe",
                                name: "ros_otherSubAnswer",
                                type: "label",
                                subQuestions: [
                                  {
                                    id: 4,
                                    name: "ros_thrombocytopeniaSubAnswerOther",
                                    depth: 3,
                                    type: "text",
                                    label: "Describe",
                                    value: "describe",
                                    editToggle: false,
                                    xs: 4,
                                    required: false,
                                    placeholder: "",
                                    defaultValue: "",
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: true,
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },

                      {
                        id: 4,
                        name: "ros_thrombocytopeniaSubAnswerEtiology",
                        depth: 3,
                        type: "text",
                        label: "Etiology",
                        value: "etiology",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Tuberculosis",
                    value: "tuberculosis",
                    name: "ros_tuberculosisSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_tuberculosisSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Tuberculosis Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of Active TB",
                            value: "historyOfActiveTb",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "TB infection(positive PPD)",
                            value: "tbInfection",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out Active TB",
                            value: "ruleOutActiveTb",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_tuberculosisSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Imagining study",
                            value: "imaginingStudy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Skin test",
                            value: "skinTest",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Positive culture",
                            value: "positiveCulture",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            name: "ros_otherSubAnswer",
                            type: "options_tree",
                            subQuestions: [
                              {
                                subQuestionId: 1,
                                hasSubQuestions: false,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "describe",
                                value: "describe",
                                type: "text",
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_tuberculosisSubAnswerGivenBCG",
                        value: "givenBCG",
                        type: "options_tree",
                        label: "Has patient been given BCG",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_tuberculosisSubAnswerActiveTuberculosis",
                        value: "activeTuberculosis",
                        type: "options_tree",
                        label:
                          "Has patient been treated for active Tuberculosis",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 3,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_tuberculosisSubAnswerTbInfection",
                        value: "tbInfection",
                        type: "options_tree",
                        label: "Has patient been treated for TB Infection",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Unknown",
                            value: "unknown",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 4,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Vitamin D Deficiency",
                    value: "vitaminDDeficiency",
                    name: "ros_vitaminDDeficiencySubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_vitaminDDeficiencySubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Vitamin D Deficiency Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_vitaminDDeficiencySubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: "Supported By    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Labs",
                            value: "labs",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History",
                            value: "history",
                            type: "toggle_button",
                          },

                          {
                            subQuestionId: 4,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "options_tree",
                            subQuestions: [
                              {
                                hasSubQuestions: true,
                                editToggle: false,
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: false,
                                label: "Other",
                                value: "other",
                                name: "ros_otherSubAnswer",
                                type: "label",
                                subQuestions: [
                                  {
                                    subQuestionId: 1,
                                    hasSubQuestions: false,
                                    editToggle: false,
                                    isCommentEnabled: false,
                                    isConfigured: true,
                                    isModified: false,
                                    label: "",
                                    value: "describe",
                                    type: "text",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                    ],
                  },
                  {
                    subQuestionId: 16,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    name: "ros_otherSubAnswer",
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_hemaOtherSubAnswerDescribe",
                        value: "describe",
                        type: "options_tree",
                        label: "Other Describe",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Active",
                            value: "active",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "History of",
                            value: "historyOf",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Rule out",
                            value: "ruleOut",
                            type: "toggle_button",
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "ros_hemaOtherSubAnswerSupportedBy",
                        value: "supportedBy",
                        type: "options_tree",
                        label: " Supported by",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 1,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "History",
                            value: "history",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Symptoms",
                            value: "symptoms",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Physical findings",
                            value: "physicalFindings",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Medications",
                            value: "medications",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Test Results",
                            value: "testResults",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Image studies",
                            value: "imageStudies",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Biopsy",
                            value: "biopsy",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "DME",
                            value: "dme",
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            name: "ros_otherSubAnswer",
                            type: "options_tree",
                            subQuestions: [
                              {
                                name: "ros_otherSubAnswerOther",
                                type: "text",
                                value: "describe",
                                label: "Other Describe",
                                fullWidth: true,
                                required: true,
                                xs: 12,
                                isCommentEnabled: true,
                                isConfigured: true,
                                isModified: true,
                                hasSubQuestions: true,
                              },
                            ],
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                      },
                      {
                        id: 4,
                        name: "ros_hemaOtherSubAnswerOther",
                        depth: 3,
                        type: "text",
                        label: "Other",
                        value: "other",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                    ],
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 15,
        editToggle: false,
      },
      {
        id: 10,
        name: "hema_Recommendations",
        value: "takeMedicationsAsPescribed",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Take medications as prescribed", "takeMedicationsAsPescribed"],
          ["Report abnormal bruising or bleeding", "reportAbnormalBruising"],
          ["Follow up with doctor for lab work", "followUpWithDoctor"],
          ["Other", "other"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 55,
      },
      {
        id: 9,
        name: "cancer",
        type: "heading",
        label: "Cancer",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 66,
      },
      {
        id: 6,
        name: "ros_diagnosisOfCancer",
        type: "options_tree",
        label: "Diagnosis of Cancer",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "ros_diagnosisOfCancerSubDesc",
                type: "options_tree",
                label: "Describe",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    name: "active",
                    label: "Active",
                    value: "active",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "History of",
                    value: "historyOf",
                    type: "toggle_button",
                    name: "historyOf",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Rule out",
                    value: "ruleOut",
                    type: "toggle_button",
                    name: "ruleOut",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                depth: 3,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_diagnosisOfCancerSubSup",
                type: "options_tree",
                label: "Supported by",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    name: "physicalFindings",
                    label: "Physical findings",
                    value: "physicalFindings",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Hospitalization",
                    value: "hospitalization",
                    type: "toggle_button",
                    name: "hospitalization",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Treatments",
                    value: "treatments",
                    type: "toggle_button",
                    name: "treatments",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Lab tests",
                    value: "labTests",
                    type: "toggle_button",
                    name: "labTests",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Imaging studies",
                    value: "imagingStudies",
                    type: "toggle_button",
                    name: "imagingStudies",
                    depth: 4,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Surgery",
                    value: "surgery",
                    type: "toggle_button",
                    name: "surgery",
                    depth: 4,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Biopsy",
                    value: "biopsy",
                    type: "toggle_button",
                    name: "biopsy",
                    depth: 4,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 1,
                        name: "describe",
                        type: "text",
                        label: "Describe",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    name: "other",
                    depth: 4,
                  },
                ],
                subQuestionId: 2,
                depth: 3,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_diagnosisOfCancerSubType",
                type: "options_tree",
                label: "Type",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    name: "brain",
                    label: "Brain",
                    value: "brain",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Head",
                    value: "head",
                    type: "toggle_button",
                    name: "head",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Neck",
                    value: "neck",
                    type: "toggle_button",
                    name: "neck",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Breast",
                    value: "breast",
                    type: "toggle_button",
                    name: "breast",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Lung",
                    value: "lung",
                    type: "toggle_button",
                    name: "lung",
                    depth: 4,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Esophagus",
                    value: "esophagus",
                    type: "toggle_button",
                    name: "esophagus",
                    depth: 4,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Stomach",
                    value: "stomach",
                    type: "toggle_button",
                    name: "stomach",
                    depth: 4,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Liver",
                    value: "liver",
                    type: "toggle_button",
                    name: "liver",
                    depth: 4,
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Pancreas",
                    value: "pancreas",
                    type: "toggle_button",
                    name: "pancreas",
                    depth: 4,
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Colon",
                    value: "colon",
                    type: "toggle_button",
                    name: "colon",
                    depth: 4,
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Rectum",
                    value: "rectum",
                    type: "toggle_button",
                    name: "rectum",
                    depth: 4,
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Kidney",
                    value: "kidney",
                    type: "toggle_button",
                    name: "kidney",
                    depth: 4,
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Bladder",
                    value: "bladder",
                    type: "toggle_button",
                    name: "bladder",
                    depth: 4,
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Ovaries",
                    value: "ovaries",
                    type: "toggle_button",
                    name: "ovaries",
                    depth: 4,
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Uterus",
                    value: "uterus",
                    type: "toggle_button",
                    name: "uterus",
                    depth: 4,
                  },
                  {
                    subQuestionId: 16,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Prostate",
                    value: "prostate",
                    type: "toggle_button",
                    name: "prostate",
                    depth: 4,
                  },
                  {
                    subQuestionId: 17,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Bone",
                    value: "bone",
                    type: "toggle_button",
                    name: "bone",
                    depth: 4,
                  },
                  {
                    subQuestionId: 18,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Blood",
                    value: "blood",
                    type: "toggle_button",
                    name: "blood",
                    depth: 4,
                  },
                  {
                    subQuestionId: 19,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Lymph Nodes",
                    value: "lymphNodes",
                    type: "toggle_button",
                    name: "lymphNodes",
                    depth: 4,
                  },
                  {
                    subQuestionId: 20,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Skin",
                    value: "skin",
                    type: "toggle_button",
                    name: "skin",
                    depth: 4,
                  },
                  {
                    subQuestionId: 21,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Other",
                    value: "other",
                    type: "toggle_button",
                    name: "other",
                    depth: 4,
                  },
                ],
                subQuestionId: 3,
                depth: 3,
                editToggle: false,
              },
              {
                id: 1,
                name: "specialTypes",
                type: "text",
                label: "Specific type/s",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "stageSpecificToTheCancer",
                type: "text",
                label: "Stage or Classification specific to the cancer",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
              {
                id: 6,
                name: "ros_activeTreatment",
                type: "options_tree",
                label: "Active treatment ",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "ros_diagnosisOfCancerSubActiveTreat",
                        type: "options_tree",
                        label: "Active treatment    ",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "chemotherapy",
                            label: "Chemotherapy",
                            value: "chemotherapy",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Radiation",
                            value: "radiation",
                            type: "toggle_button",
                            name: "radiation",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Stem Cell",
                            value: "stemCell",
                            type: "toggle_button",
                            name: "stemCell",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Bone Marrow",
                            value: "boneMarrow",
                            type: "toggle_button",
                            name: "boneMarrow",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Surgery",
                            value: "surgery",
                            type: "toggle_button",
                            name: "surgery",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Immune System",
                            value: "immuneSystem",
                            type: "toggle_button",
                            name: "immuneSystem",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_diagnosisOfCancerSubSideEff",
                        type: "options_tree",
                        label: "Side effects",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "nausea",
                            label: "Nausea",
                            value: "nausea",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Vomiting",
                            value: "vomiting",
                            type: "toggle_button",
                            name: "vomiting",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Diarrhea",
                            value: "diarrhea",
                            type: "toggle_button",
                            name: "diarrhea",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Anemia",
                            value: "anemia",
                            type: "toggle_button",
                            name: "anemia",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Neutropeniatropenia",
                            value: "neutropeniatropenia",
                            type: "toggle_button",
                            name: "neutropeniatropenia",
                            depth: 6,
                          },
                          {
                            subQuestionId: 6,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Thrombocytopenia",
                            value: "thrombocytopenia",
                            type: "toggle_button",
                            name: "thrombocytopenia",
                            depth: 6,
                          },
                          {
                            subQuestionId: 7,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Weakness",
                            value: "weakness",
                            type: "toggle_button",
                            name: "weakness",
                            depth: 6,
                          },
                          {
                            subQuestionId: 8,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Loss of appetite",
                            value: "lossOfAppetite",
                            type: "toggle_button",
                            name: "lossOfAppetite",
                            depth: 6,
                          },
                          {
                            subQuestionId: 9,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    name: "yes",
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 6,
                depth: 3,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_Metastasis",
                type: "options_tree",
                label: "Is there a current finding of Metastasis?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 1,
                        name: "ros_diagnosisOfCancerSubMetastasisLocVal",
                        type: "text",
                        label: "Location",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "ros_diagnosisOfCancerSubMetastasisCachexiaVal",
                        type: "options_tree",
                        label: "History / finding of Cachexia",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    name: "yes",
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 7,
                depth: 3,
                editToggle: false,
              },
              {
                id: 6,
                name: "ros_SeeASpec",
                type: "options_tree",
                label: "Do you see a specialist?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 1,
                        name: "ros_diagnosisOfCancerSubSeeASpecProVal",
                        type: "text",
                        label: "Provider",
                        editToggle: false,
                        xs: 4,
                        required: "no",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    name: "yes",
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 8,
                depth: 3,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 67,
        editToggle: false,
        depth: 1,
      },
      {
        id: 10,
        name: "ros_cancerRecommendations",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Take medications as prescribed",
            "takemedicationsasprescribedCancer",
          ],
          ["Other", "otherCancer"],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 67,
      },
    ],
  },
  {
    sectionId: 11,
    sectionName: "Pain",
    isEditable: false,
    name: "pain",
    value: "pain",
    type: "toggle_button",
    questions: [
      {
        id: 6,
        name: "patientExperiencePain",
        type: "options_tree",
        label: "Does the patient experience pain?",
        value: "patientExperiencePain",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: true,
        hasSubQuestions: true,
        questionId: 1,
        editToggle: false,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            required: true,
            editToggle: false,
            isCommentEnabled: true,
            label: "Yes",
            value: "Yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
            //name:"painAcute",
            subQuestions: [
              {
                id: 6,
                name: "painAcute",
                type: "options_tree",
                label: "Is the Pain Acute?",
                value: "painAcute",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "Yes",
                    name: "Yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "No",
                    name: "No",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
              {
                id: 6,
                name: "painChronic",
                value: "painChronic",
                type: "options_tree",
                value: "painChronic",
                label: "Is the Pain Chronic?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "Yes",
                    name: "Yes",
                    isConfigured: true,
                    type: "toggle_button",
                    subQuestions: [
                      {
                        id: 1,
                        name: "pain_describe",

                        type: "options_tree",
                        label: "Describe",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        value: "describe",
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 4,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            name: "active",
                            label: "Active",
                            subQuestions: [],
                            editToggle: false,
                            value: "active",
                            isCommentEnabled: false,
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            name: "historyOf",
                            label: "History Of",
                            value: "historyOf",
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            name: "ruleOut",
                            label: "Rule Out",
                            value: "ruleOut",
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            type: "toggle_button",
                          },
                        ],
                      },
                      {
                        id: 4,
                        name: "where",
                        depth: 3,
                        type: "text",
                        label: "Where",
                        value: "where",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },
                      {
                        id: 4,
                        name: "rateYourPain",
                        type: "text",
                        label:
                          "Rate your pain on a scale of 1-10, with 1 being very mild and 10 being severe",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        value: "rateYourPain",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        hasSubQuestions: false,
                      },

                      {
                        id: 4,
                        name: "frequency",
                        type: "options_tree",
                        label: "Frequency Of Pain",
                        editToggle: false,
                        value: "frequency",
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 4,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            name: "occasional",
                            label: "Occasional",
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            value: "occasional",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            name: "oneOrMoreTimes",
                            label: "One or more times a week",
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            value: "oneOrMoreTimes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            name: "allOfTheTime",
                            label: "All of the time",
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            value: "allOfTheTime",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "No",
                    name: "No",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 2,
                depth: 2,
                editToggle: false,
              },
              {
                id: 6,
                name: "painManagementTiming",
                type: "options_tree",
                value: "painManagementTiming",
                label: "Is the Patient Undergoing Pain Management Planning?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 1,
                        name: "isPatientResponding",
                        type: "options_tree",
                        value: "isPatientResponding",
                        label:
                          "Is the Patient Responding to the Pain Management Plan?",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 4,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            id: 2,
                            name: "Yes",
                            label: "Yes",
                            fullWidth: true,
                            required: true,
                            xs: 12,
                            isCommentEnabled: true,
                            isConfigured: true,
                            isModified: true,
                            editToggle: false,
                            value: "Yes",
                            type: "toggle_button",
                          },
                          {
                            id: 2,
                            name: "No",
                            label: "No",
                            fullWidth: true,
                            required: true,
                            xs: 12,
                            isCommentEnabled: true,
                            isConfigured: true,
                            isModified: true,
                            editToggle: false,
                            value: "No",
                            type: "toggle_button",
                          },
                        ],
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    name: "Yes",
                    value: "Yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "No",
                    name: "No",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 3,
                depth: 2,
                editToggle: false,
              },
              {
                id: 7,
                name: "opioidMedication",
                value: "opioidMedication",
                type: "options_tree",
                label: "Is the member taking a narcotic or Opioid Medication?",
                fullWidth: true,
                required: true,
                value: "1",
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    name: "Yes",
                    hasSubQuestions: true,
                    value: "Yes",
                    subQuestions: [
                      {
                        id: 1,
                        name: "drugsTake",
                        type: "text",
                        value: "",
                        label: "What drugs do you take for it?",
                        editToggle: false,
                        xs: 4,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        subQuestionId: 1,
                        depth: 4,
                      },
                      {
                        id: 6,
                        name: "tolerance",
                        value: "tolerance",
                        type: "options_tree",
                        label: "Tolerance?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "Yes",
                            label: "Yes",
                            value: "Yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            value: "No",
                            label: "No",
                            name: "No",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 2,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "withdrawal",
                        value: "withdrawal",
                        type: "options_tree",
                        label: "Withdrawal?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            name: "Yes",
                            value: "Yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            name: "No",
                            label: "No",
                            value: "No",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 3,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "increasedUsage",
                        value: "increasedUsage",
                        type: "options_tree",
                        label:
                          "Increased usuage over a longer period that intended?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            name: "Yes",
                            value: "Yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            name: "No",
                            value: "No",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 4,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "unsuccessEffort",
                        value: "unsuccessEffort",
                        type: "options_tree",
                        label:
                          "Desire or unsuccessful effort to cut down on use?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "Yes",
                            label: "Yes",
                            value: "Yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            name: "No",
                            value: "No",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 5,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "excessTimeSpent",
                        value: "excessTimeSpent",
                        type: "options_tree",
                        label:
                          "Excess time spent in activities to obtain the substance?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            name: "Yes",
                            value: "Yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            name: "No",
                            value: "No",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 6,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "patientKnowledge",
                        value: "patientKnowledge",
                        type: "options_tree",
                        label:
                          "Continued use despite Doctor advice or patient knowledge of habituation?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            name: "Yes",
                            value: "Yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            name: "No",
                            value: "No",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 7,
                        depth: 4,
                        editToggle: false,
                      },
                      {
                        id: 6,
                        name: "psychologicalProblem",
                        value: "psychologicalProblem",
                        type: "options_tree",
                        label:
                          "Physical or Psychological Problem related to the substance use?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 5,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            name: "Yes",
                            value: "Yes",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            name: "No",
                            value: "No",
                            type: "toggle_button",
                            depth: 5,
                          },
                        ],
                        subQuestionId: 8,
                        depth: 4,
                        editToggle: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "No",
                    name: "No",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 4,
                depth: 2,
                editToggle: false,
              },
              {
                id: 6,
                name: "patientAdvised",
                value: "patientAdvised",
                type: "options_tree",
                label:
                  "Was the patient advised regarding the potential for dependence?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    name: "Yes",
                    value: "Yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    name: "No",
                    value: "No",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 5,
                depth: 2,
                editToggle: false,
              },
            ],
          },

          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "No",
            name: "No",
            type: "toggle_button",
            depth: 1,
          },
        ],
      },
    ],
  },
  {
    sectionId: 12,
    sectionName: "Vital Signs",
    isEditable: false,
    isCommentEnabled: true,
    questions: [
      {
        id: 7,
        name: "vitalSigns",
        value: "vitalSigns",
        type: "label",
        label: "Vital Signs",
        editToggle: false,
        xs: 12,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 2,
        name: "diastolicbp",
        value: "diastolicbp",
        type: "number",
        label: "Blood pressure (mmHG)",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 2,
        name: "systolicbp",
        value: "systolicbp",
        type: "number",
        label: "Blood pressure (mmHG)",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 2,
        name: "pulse",
        value: "pulse",
        type: "number",
        label: "Pulse",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 3,
      },
      {
        id: 2,
        name: "respiratoryRate",
        value: "respiratoryRate",
        type: "number",
        label: "Respiratory Rate",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 4,
      },
      {
        id: 2,
        name: "temp",
        value: "temp",
        type: "number",
        label: "Temp",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 5,
      },
      {
        id: 2,
        name: "pulseOximetry",
        value: "pulseOximetry",
        type: "number",
        label: "Pulse Oximetry",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 2,
        name: "painScale",
        value: "painScale",
        type: "number",
        label: "Pain Scale/10",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 7,
      },

      {
        id: 2,
        name: "bmi",
        value: "bmi",
        type: "label",
        label: "BMI",
        editToggle: false,
        xs: 12,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 8,
      },
      {
        id: 11,
        name: "dataTable",
        label: "Data Table",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "BMI Index Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            true,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 13,
              label: "Depression Severity",
              name: "Depression_Severity",
              columns: [
                {
                  label: "Score",
                  type: "number",
                },
                {
                  label: "Depression Severity",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 12,
      },
      {
        id: 2,
        name: "feet",
        value: "feet",
        type: "number",
        label: "Patients Height (Feet)",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 9,
      },
      {
        id: 2,
        name: "inch",
        value: "inch",
        type: "number",
        label: "Patients Height (Inch)",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 9,
      },
      {
        id: 2,
        name: "patientsWeight",
        value: "patientsWeight",
        type: "number",
        label: "Patients Weight",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 10,
      },

      {
        id: 2,
        name: "bmii",
        value: "bmii",
        type: "number",
        label: "BMI",
        editToggle: false,
        xs: 4,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 11,
      },

      {
        id: 6,
        name: "bmi",
        type: "options_tree",
        label: "",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Obesity",
            value: "obesity",
            name: "obesity",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Moderate Obesity",
            value: "moderateObesity",
            name: "moderateObesity",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Morbid Obesity",
            value: "morbidObesity",
            name: "morbidObesity",
            type: "toggle_button",
            depth: 1,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "describe",
                value: "describe",
                type: "options_tree",
                label: "Describe",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Active",
                    value: "active",
                    name: "active",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "History of",
                    value: "historyOf",
                    name: "historyOf",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Rule out",
                    value: "ruleOut",
                    name: "ruleOut",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 2,
              },
              {
                id: 6,
                name: "malnutrition",
                value: "malnutrition",
                type: "options_tree",
                label: "Malnutrition",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    //isCommentEnabled: false,
                    label: "Yes",
                    value: "Yes",
                    name: "Yes",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    //isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "No",
                    name: "No",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 2,
                depth: 2,
                editToggle: false,
              },
              {
                id: 6,
                name: "supportedBy",
                value: "supportedBy",
                type: "options_tree",
                label: "Supported by",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    //isCommentEnabled: false,
                    label: "Albumin <3.5g/dl",
                    value: "albumin",
                    name: "albumin",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    //isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Muscle wasting",
                    value: "muscleWaiting",
                    name: "muscleWaiting",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    //isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "History of severe weight loss",
                    value: "historyOfSevereWtLoss",
                    name: "historyOfSevereWtLoss",
                    type: "toggle_button",
                    depth: 3,
                  },
                ],
                subQuestionId: 3,
                depth: 2,
                editToggle: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Malnutrition",
            value: "malnutrition",
            name: "malnutrition",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 12,
        editToggle: false,
      },

      {
        id: 6,
        name: "specialDiet",
        value: "specialDiet",
        type: "options_tree",
        label: "Are you on a special diet?",
        isExclusive: false,
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Heart Healthy Diet",
            value: "heartHealthyDiet",
            name: "heartHealthyDiet",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Diabetic Diet",
            value: "diabeticDiet",
            name: "diabeticDiet",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Renal Diet",
            value: "renalDiet",
            name: "renalDiet",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Vegetarian",
            value: "vegetarian",
            name: "vegetarian",
            type: "toggle_button",
          },
          {
            subQuestionId: 5,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Vegan",
            value: "vegan",
            name: "vegan",
            type: "toggle_button",
          },
          {
            subQuestionId: 6,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Gluten Free",
            value: "glutenFree",
            name: "glutenFree",
            type: "toggle_button",
          },
          {
            subQuestionId: 7,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Keto",
            value: "keto",
            name: "keto",
            type: "toggle_button",
          },
          {
            subQuestionId: 8,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Pescatarian",
            value: "pescatarian",
            name: "pescatarian",
            type: "toggle_button",
          },
          {
            subQuestionId: 9,
            hasSubQuestions: true,
            subQuestions: [
              {
                subQuestionId: 1,
                name: "describe",
                type: "text",
                placeholder: "",
                label: "Describe",
                variant: "outlined",
                fullWidth: true,
                required: true,
                xs: 3,
                sm: 3,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Other",
            value: "other",
            name: "other",
            type: "toggle_button",
          },
        ],
        questionId: 13,
        editToggle: false,
      },
      {
        id: 6,
        name: "lostWeight",
        type: "options_tree",
        label: "Have you lost weight in the past 6 months?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "None",
            value: "none",
            name: "none",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "5lbs",
            value: "fiveLbs",
            name: "fiveLbs",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "10lbs",
            value: "tenLbs",
            name: "tenLbs",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "15lbs",
            value: "fifteenLbs",
            name: "fifteenLbs",
            type: "toggle_button",
          },
          {
            subQuestionId: 5,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "More than 15 lbs",
            value: "moreThanFifteenLbs",
            name: "moreThanFifteenLbs",
            type: "toggle_button",
          },
          {
            subQuestionId: 6,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "10% of your weight (calculated by assessor)",
            value: "tenPercentYourWeight",
            name: "tenPercentYourWeight",
            type: "toggle_button",
          },
        ],
        questionId: 14,
        editToggle: false,
      },
      {
        id: 10,
        name: "nutritionManagement",
        value: "nutritionManagement",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["Nutrition/weight management", "nutritionManagement"]],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 15,
      },
      {
        id: 10,
        name: "other",
        value: "other",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["Other", "other"]],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 15,
      },
    ],
  },
  {
    sectionId: 13,
    sectionName: "Exam Review",
    isEditable: false,
    questions: [
      {
        id: 9,
        name: "constitutional",
        type: "heading",
        label: "Constitutional",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 6,
        name: "exam_genAppear",
        type: "options_tree",
        label: "General appearance:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 9,
        name: "head_and_face",
        type: "heading",
        label: "Head and Face",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 3,
      },
      {
        id: 6,
        name: "exam_headFaceExam",
        type: "options_tree",
        label: "Examination of head and face:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 4,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_headFacePalp",
        type: "options_tree",
        label: "Palpation of the face and sinuses:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 5,
        editToggle: false,
      },
      {
        id: 9,
        name: "eyes",
        type: "heading",
        label: "Eyes",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 6,
        name: "exam_eyesInsp",
        type: "options_tree",
        label: "Inspection of conjunctiva and lids:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 7,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_eyesExam",
        type: "options_tree",
        label: "Examination of pupils and irises:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 8,
        editToggle: false,
      },
      {
        id: 9,
        name: "ears,_nose,_mouth_and_throat",
        type: "heading",
        label: "Ears, Nose, Mouth and Throat",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 9,
      },
      {
        id: 6,
        name: "exam_entExtInsp",
        type: "options_tree",
        label: "External Inspection of ears and nose:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 11,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_entOtosExam",
        type: "options_tree",
        label: "Otoscopic examination:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 12,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_entAssessHear",
        type: "options_tree",
        label: "Assessment of hearing:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 10,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_entNasalInsp",
        type: "options_tree",
        label: "Inspection of nasal mucosa, septum and trubinates:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 13,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_entInspLips",
        type: "options_tree",
        label: "Inspection of lips, teeth and gums:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 14,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_entOropExam",
        type: "options_tree",
        label: "Examination of oropharynx:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 15,
        editToggle: false,
      },
      {
        id: 9,
        name: "neck",
        type: "heading",
        label: "Neck",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 16,
      },
      {
        id: 6,
        name: "exam_neckExam",
        type: "options_tree",
        label: "Examination of neck:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 17,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_neckExamThyroid",
        type: "options_tree",
        label: "Examination of thyroid:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 18,
        editToggle: false,
      },
      {
        id: 9,
        name: "pulmonary",
        type: "heading",
        label: "Pulmonary",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 19,
      },
      {
        id: 6,
        name: "exam_pulmAssessResp",
        type: "options_tree",
        label: "Assessment of respiratory effort:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 20,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_pulmAuscLungs",
        type: "options_tree",
        label: "Ausculation of lungs:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 21,
        editToggle: false,
      },
      {
        id: 9,
        name: "cardiovascular",
        type: "heading",
        label: "Cardiovascular",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 22,
      },
      {
        id: 6,
        name: "exam_cardioAusHeart",
        type: "options_tree",
        label: "Auscultation of heart:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 23,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_cardioPalpAus",
        type: "options_tree",
        label: "Palpation and auscultation of Carotid Arteries:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 24,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_cardioPedalPulse",
        type: "options_tree",
        label: "Pedal Pulses:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 25,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_cardioExamEdema",
        type: "options_tree",
        label: "Examination of Edema / Varicosities:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 26,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_cardioExamRadial",
        type: "options_tree",
        label: "Examination of Radial Pulses:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 27,
        editToggle: false,
      },
      {
        id: 9,
        name: "lymphatic",
        type: "heading",
        label: "Lymphatic",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 28,
      },
      {
        id: 6,
        name: "exam_lympPalpCervi",
        type: "options_tree",
        label: "Palpation of cervical nodes (neck)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 29,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_lympPalpPreauric",
        type: "options_tree",
        label: "Palpation of preauricular nodes (in front of the ears)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 30,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_lympPalpSubmandi",
        type: "options_tree",
        label: "Palpation of Submandibular nodes (under jaw line/chin)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 32,
        editToggle: false,
      },
      {
        id: 9,
        name: "musculoskeletal",
        type: "heading",
        label: "Musculoskeletal",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 33,
      },
      {
        id: 6,
        name: "exam_muscSkelExamGait",
        type: "options_tree",
        label: "Examination of gait and station:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 34,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_muscSkelInspDigit",
        type: "options_tree",
        label: "Inspection/palpation of digits and nails:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 35,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_muscSkelInspJoint",
        type: "options_tree",
        label: "Inspection/palpation of joints, bones and muscles:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 35,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_muscSkelAssessRange",
        type: "options_tree",
        label: "Assessment of range of motion:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 36,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_muscSkelAssessStable",
        type: "options_tree",
        label: "Assessment of stability:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 37,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_muscSkelAssessMuscle",
        type: "options_tree",
        label: "Assessment of Muscle strength/tone:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 37,
        editToggle: false,
      },
      {
        id: 9,
        name: "skin",
        type: "heading",
        label: "Skin",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 38,
      },
      {
        id: 6,
        name: "exam_skinInspSkin",
        type: "options_tree",
        label: "Inspection of skin and subcutaneous tissue:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 40,
        editToggle: false,
      },
      {
        id: 9,
        name: "neurologic",
        type: "heading",
        label: "Neurologic",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 41,
      },
      {
        id: 1,
        name: "exam_neuroCranialTest",
        type: "text",
        label: "Indicate specific cranial nerve tested",
        editToggle: false,
        xs: 4,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 42,
      },
      {
        id: 1,
        name: "exam_neuroCranialTestIndicate",
        type: "text",
        label: "Indicate cranial nerve deficits found",
        editToggle: false,
        xs: 4,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 43,
      },
      {
        id: 6,
        name: "exam_neuroRomberg",
        type: "options_tree",
        label: "Romberg Test",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 44,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_neuroReflex",
        type: "options_tree",
        label: "Examination of reflexes:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 45,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_neuroSense",
        type: "options_tree",
        label: "Examination of sensation:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 46,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_neuroCoordinate",
        type: "options_tree",
        label: "Coordination:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 47,
        editToggle: false,
      },
      {
        id: 9,
        name: "diabetes",
        type: "heading",
        label: "Diabetes",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 48,
      },
      {
        id: 6,
        name: "exam_diabFoot",
        type: "options_tree",
        label: "Foot Exam",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 49,
        editToggle: false,
      },
      {
        id: 9,
        name: "psychiatric",
        type: "heading",
        label: "Psychiatric",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 51,
      },
      {
        id: 6,
        name: "exam_psychJudge",
        type: "options_tree",
        label: "Description of patient's judgement / insight:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 50,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_psychOrient",
        type: "options_tree",
        label: "Orientation of person, place and time:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 52,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_psychRemote",
        type: "options_tree",
        label: "Recent and remote memory:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 53,
        editToggle: false,
      },
      {
        id: 6,
        name: "exam_psychMood",
        type: "options_tree",
        label: "Mood and Affect:",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Normal",
            value: "normal",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Abnormal",
            value: "abnormal",
            type: "toggle_button",
          },
        ],
        questionId: 54,
        editToggle: false,
      },
    ],
  },
  {
    sectionId: 15,
    sectionName: "Screenings Needed",
    isEditable: false,
    questions: [
      {
        id: 6,
        name: "microalbumin",
        type: "options_tree",
        label: "MICROALBUMIN",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 91,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "statusOptions",
                type: "options_tree",
                label: "Status options",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "reasonMemberRefused",
                        type: "options_tree",
                        label: "Reason member refused",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            name: "memberRecentlyCompleted",
                            label: "Member recently completed",
                            value: "memberRecentlyCompleted",
                            isConfigured: true,
                            type: "toggle_button",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scheduled to complete",
                            value: "scheduledToComplete",
                            type: "toggle_button",
                            name: "scheduledToComplete",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Member apprehension",
                            value: "memberApprehension",
                            type: "toggle_button",
                            name: "memberApprehension",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Not interested",
                            value: "notInterested",
                            type: "toggle_button",
                            name: "notInterested",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    name: "memberRefused",
                    label: "Member refused",
                    value: "member_refused_",
                    isConfigured: true,
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Left kit",
                    value: "left_kit_",
                    type: "toggle_button",
                    name: "leftKit",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Collected Sample",
                    value: "collected_sample_",
                    type: "toggle_button",
                    name: "collectedSample",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Mail Kit direct to member",
                    value: "mail_kit_direct_to_member_",
                    type: "toggle_button",
                    name: "mailKitDirectToMember",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Result Received",
                    value: "result_received_",
                    type: "toggle_button",
                    name: "resultReceived",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                depth: 3,
                editToggle: false,
              },
              {
                id: 1,
                name: "microalbuminTestBarcode",
                type: "text",
                label: "Microalbumin Test Barcode",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 3,
              },
              {
                id: 4,
                name: "examDate",
                label: "Exam Date",
                placeholder: "",
                type: "date",
                editToggle: false,
                xs: 4,
                defaultValue: "2023-09-11",
                value: "2023-09-11",
                fullWidth: true,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 3,
              },
              {
                id: 1,
                name: "screeningResult",
                type: "text",
                label: "Screening Result",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "diagnosis",
                type: "text",
                label: "Diagnosis",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
              {
                id: 1,
                name: "comments",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 6,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Y",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 92,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "N",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 1,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "fobt",
        type: "options_tree",
        label: "FOBT",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "statusOptions",
                type: "options_tree",
                label: "Status options",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "reasonMemberRefused",
                        type: "options_tree",
                        label: "Reason member refused",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Member recently completed",
                            value: "memberRecentlyCompleted",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "memberRecentlyCompleted",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scheduled to complete",
                            value: "scheduledToComplete",
                            type: "toggle_button",
                            name: "scheduledToComplete",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Member apprehension",
                            value: "memberApprehension",
                            type: "toggle_button",
                            name: "memberApprehension",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Not interested",
                            value: "notInterested",
                            type: "toggle_button",
                            name: "notInterested",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "other",
                                type: "text",
                                label: "Other",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Member refused",
                    value: "memberRefused",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "memberRefused",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Left kit",
                    value: "leftKit",
                    type: "toggle_button",
                    name: "leftKit",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Collected Sample",
                    value: "collectedSample",
                    type: "toggle_button",
                    name: "collectedSample",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Mail Kit direct to member",
                    value: "mailKitDirectToMember",
                    type: "toggle_button",
                    name: "mailKitDirectToMember",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 1,
                name: "fobtTestBarcode",
                type: "text",
                label: "FOBT Test Barcode",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 3,
              },
              {
                id: 4,
                name: "examDate",
                label: "Exam Date",
                placeholder: "",
                type: "date",
                editToggle: false,
                xs: 4,
                defaultValue: "2023-09-11",
                value: "2023-09-11",
                fullWidth: true,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 3,
              },
              {
                id: 1,
                name: "screeningResult",
                type: "text",
                label: "Screening Result",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "diagnosis",
                type: "text",
                label: "Diagnosis",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
              {
                id: 1,
                name: "comments",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 6,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Y",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "N",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 2,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "a1c",
        type: "options_tree",
        label: "A1C",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "statusOptions",
                type: "options_tree",
                label: "Status options",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "reasonMemberRefused",
                        type: "options_tree",
                        label: "Reason member refused",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Member recently completed",
                            value: "memberRecentlyCompleted",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "memberRecentlyCompleted",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scheduled to complete",
                            value: "scheduledToComplete",
                            type: "toggle_button",
                            name: "scheduledToComplete",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Member apprehension",
                            value: "memberApprehension",
                            type: "toggle_button",
                            name: "memberApprehension",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Not interested",
                            value: "notInterested",
                            type: "toggle_button",
                            name: "notInterested",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Member refused",
                    value: "memberRefused",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "memberRefused",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Left Kit",
                    value: "leftKit",
                    type: "toggle_button",
                    name: "leftKit",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Collected Sample",
                    value: "collectedSample",
                    type: "toggle_button",
                    name: "collectedSample",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Mail Kit direct to member",
                    value: "mailKitDirectToMember",
                    type: "toggle_button",
                    name: "mailKitDirectToMember",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Result Received",
                    value: "resultReceived",
                    type: "toggle_button",
                    name: "resultReceived",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 1,
                name: "a1CTestBarcode",
                type: "text",
                label: "A1C Test Barcode",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 3,
              },
              {
                id: 4,
                name: "examDate",
                label: "Exam date",
                placeholder: "",
                type: "date",
                editToggle: false,
                xs: 4,
                defaultValue: "2023-09-11",
                value: "2023-09-11",
                fullWidth: true,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 3,
              },
              {
                id: 1,
                name: "screeningResult",
                type: "text",
                label: "Screening Result",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "diagnosis",
                type: "text",
                label: "Diagnosis",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
              {
                id: 1,
                name: "comments",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 6,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Y",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "N",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 3,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "ldl",
        type: "options_tree",
        label: "LDL",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "statusOptions",
                type: "options_tree",
                label: "Status options",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "reasonMemberRefused",
                        type: "options_tree",
                        label: "Reason member refused",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Member recently completed",
                            value: "memberRecentlyCompleted",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "memberRecentlyCompleted",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scheduled to complete",
                            value: "scheduledToComplete",
                            type: "toggle_button",
                            name: "scheduledToComplete",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Member apprehension",
                            value: "memberApprehension",
                            type: "toggle_button",
                            name: "memberApprehension",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Not interested",
                            value: "notInterested",
                            type: "toggle_button",
                            name: "notInterested",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Member refused",
                    value: "memberRefused",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "memberRefused",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Left Kit",
                    value: "leftKit",
                    type: "toggle_button",
                    name: "leftKit",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Collected Sample",
                    value: "collectedSample",
                    type: "toggle_button",
                    name: "collectedSample",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Mail Kit direct to member",
                    value: "mailKitDirectToMember",
                    type: "toggle_button",
                    name: "mailKitDirectToMember",
                    depth: 4,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Result Received ",
                    value: "resultReceived",
                    type: "toggle_button",
                    name: "resultReceived",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 1,
                name: "ldlTextBarcode",
                type: "text",
                label: "LDL Text Barcode",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 3,
              },
              {
                id: 4,
                name: "examDate",
                label: "Exam Date",
                placeholder: "",
                type: "date",
                editToggle: false,
                xs: 4,
                defaultValue: "2023-09-11",
                value: "2023-09-11",
                fullWidth: true,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 3,
              },
              {
                id: 1,
                name: "screeningResult",
                type: "text",
                label: "Screening Result",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "diagnosis",
                type: "text",
                label: "Diagnosis",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
              {
                id: 1,
                name: "comments",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 6,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Y",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "N",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 4,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "retinalEyeExam",
        type: "options_tree",
        label: "RETINAL EYE EXAM",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "statusOptions",
                type: "options_tree",
                label: "Status options",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "reasonMemberRefused",
                        type: "options_tree",
                        label: "Reason member refused",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Member recently completed",
                            value: "memberRecentlyCompleted",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "memberRecentlyCompleted",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scheduled to complete",
                            value: "scheduledToComplete",
                            type: "toggle_button",
                            name: "scheduledToComplete",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Member apprehension",
                            value: "memberApprehension",
                            type: "toggle_button",
                            name: "memberApprehension",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Not interested",
                            value: "notInterested",
                            type: "toggle_button",
                            name: "notInterested",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Member refused",
                    value: "memberRefused",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "memberRefused",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Exam completed",
                    value: "examCompleted",
                    type: "toggle_button",
                    name: "examCompleted",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Environmental issue",
                    value: "environmentalIssue",
                    type: "toggle_button",
                    name: "environmentalIssue",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Result Received",
                    value: "resultReceived",
                    type: "toggle_button",
                    name: "resultReceived",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 4,
                name: "examDate",
                label: "Exam Date",
                placeholder: "",
                type: "date",
                editToggle: false,
                xs: 4,
                defaultValue: "2023-09-11",
                value: "2023-09-11",
                fullWidth: true,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 3,
              },
              {
                id: 1,
                name: "screeningResult",
                type: "text",
                label: "Screening Result",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 3,
              },
              {
                id: 1,
                name: "diagnosis",
                type: "text",
                label: "Diagnosis",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "comments",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Y",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "N",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 5,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "dexa",
        type: "options_tree",
        label: "DEXA",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "statusOptions",
                type: "options_tree",
                label: "Status options",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "reasonMemberRefused",
                        type: "options_tree",
                        label: "Reason member refused",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Member recently completed",
                            value: "memberRecentlyCompleted",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "memberRecentlyCompleted",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scheduled to complete",
                            value: "scheduledToComplete",
                            type: "toggle_button",
                            name: "scheduledToComplete",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Member apprehension",
                            value: "memberApprehension",
                            type: "toggle_button",
                            name: "memberApprehension",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Not interested",
                            value: "notInterested",
                            type: "toggle_button",
                            name: "notInterested",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "member refused",
                    value: "memberRefused",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "memberRefused",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Exam completed",
                    value: "examCompleted",
                    type: "toggle_button",
                    name: "examCompleted",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Environmental issue",
                    value: "environmentalIssue",
                    type: "toggle_button",
                    name: "environmentalIssue",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Result Received",
                    value: "resultReceived",
                    type: "toggle_button",
                    name: "resultReceived",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 4,
                name: "examDate",
                label: "Exam Date",
                placeholder: "",
                type: "date",
                editToggle: false,
                xs: 4,
                defaultValue: "2023-09-11",
                value: "2023-09-11",
                fullWidth: true,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 3,
              },
              {
                id: 1,
                name: "screeningResult",
                type: "text",
                label: "Screening Result",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 3,
              },
              {
                id: 1,
                name: "diagnosis",
                type: "text",
                label: "Diagnosis",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "comments",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Y",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "N",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 6,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "pad",
        type: "options_tree",
        label: "PAD",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "statusOptions",
                type: "options_tree",
                label: "Status options",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "reasonMemberRefused",
                        type: "options_tree",
                        label: "Reason member refused",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Member recently completed",
                            value: "memberRecentlyCompleted",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "memberRecentlyCompleted",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Scheduled to complete",
                            value: "scheduledToComplete",
                            type: "toggle_button",
                            name: "scheduledToComplete",
                            depth: 6,
                          },
                          {
                            subQuestionId: 3,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Member apprehension",
                            value: "memberApprehension",
                            type: "toggle_button",
                            name: "memberApprehension",
                            depth: 6,
                          },
                          {
                            subQuestionId: 4,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Not interested",
                            value: "notInterested",
                            type: "toggle_button",
                            name: "notInterested",
                            depth: 6,
                          },
                          {
                            subQuestionId: 5,
                            hasSubQuestions: true,
                            subQuestions: [
                              {
                                id: 1,
                                name: "describe",
                                type: "text",
                                label: "Describe",
                                editToggle: false,
                                xs: 4,
                                required: "no",
                                placeholder: "",
                                defaultValue: "",
                                isCommentEnabled: false,
                                isConfigured: true,
                                isModified: true,
                                subQuestionId: 1,
                                depth: 7,
                              },
                            ],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "Other",
                            value: "other",
                            type: "toggle_button",
                            name: "other",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Member refused",
                    value: "memberRefused",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "memberRefused",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Exam completed",
                    value: "examCompleted",
                    type: "toggle_button",
                    name: "examCompleted",
                    depth: 4,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Environmental issue",
                    value: "environmentalIssue",
                    type: "toggle_button",
                    name: "environmentalIssue",
                    depth: 4,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Result Received",
                    value: "resultReceived",
                    type: "toggle_button",
                    name: "resultReceived",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 4,
                name: "examDate",
                label: "Exam date",
                placeholder: "",
                type: "date",
                editToggle: false,
                xs: 4,
                defaultValue: "2023-09-11",
                value: "2023-09-11",
                fullWidth: true,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 3,
              },
              {
                id: 1,
                name: "padTestingResultsLeft",
                type: "text",
                label: "PAD Testing Results (left)",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 3,
              },
              {
                id: 3,
                name: "rfpadtleft",
                label: "Results for peripheral arterial disease testing (left)",
                placeholder: "",
                editToggle: false,
                defaultValue: "",
                type: "radio",
                options: [
                  ["Normal", "normal"],
                  ["Mild", "mild"],
                  ["Moderate", "moderate"],
                  ["Significant", "significant"],
                  ["Severe", "severe"],
                ],
                xs: 6,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 3,
              },
              {
                id: 1,
                name: "padTestingResultsRight",
                type: "text",
                label: "PAD Testing Results (right)",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 5,
                depth: 3,
              },
              {
                id: 3,
                name: "rfpadtright",
                label:
                  "Results for peripheral arterial disease testing (right)",
                placeholder: "",
                editToggle: false,
                defaultValue: "",
                type: "radio",
                options: [
                  ["Normal", "normal"],
                  ["Mild", "mild"],
                  ["Moderate", "moderate"],
                  ["Significant", "significant"],
                  ["Severe", "severe"],
                ],
                xs: 6,
                required: false,
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 6,
                depth: 3,
              },
              {
                id: 1,
                name: "diagnosis",
                type: "text",
                label: "Diagnosis",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 7,
                depth: 3,
              },
              {
                id: 1,
                name: "comments",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 8,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "Y",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "N",
            type: "toggle_button",
            depth: 2,
            name: "no",
          },
        ],
        questionId: 7,
        editToggle: false,
        depth: 1,
      },
      {
        id: 10,
        name: "",
        label: "",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Member educated on results,verbalized understanding",
            "membereducatedonresultsverbalizedunderstanding",
          ],
        ],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 8,
        depth: 1,
      },
    ],
  },
  {
    sectionId: 15,
    sectionName: "Mini-Cog",
    isEditable: false,
    questions: [
      {
        id: 7,
        name: "miniCogSeeAttachedSheet",
        type: "label",
        label: "Mini- Cog (see attached sheet)",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 7,
        name: "step1ThreeWordRegistration",
        type: "label",
        label: "Step 1: Three Word Registration",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 7,
        name: "lookDirectlyAtPersonAndSayPlea",
        type: "label",
        label:
          "Look directly at person and say, “Please listen carefully. I am going to say three words that I want you to repeat back to me now and try to remember. The words are [select a list of words from the versions below]. Please say them for me now.” If the person is unable to repeat the words after three attempts, move on to Step 2 (clock drawing).\n\nThe following and other word lists have been used in one or more clinical studies.1-3 For repeated administrations, use of an alternative word list is recommended.",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 3,
      },
      {
        id: 11,
        name: "dataTable",
        label: "Data Table",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "HRAForm_BMI_Index_Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            true,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 13,
              label: "Depression Severity",
              name: "Depression_Severity",
              columns: [
                {
                  label: "Score",
                  type: "number",
                },
                {
                  label: "Depression Severity",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 4,
      },
      {
        id: 7,
        name: "step2ClockDrawing",
        type: "label",
        label: "Step 2: Clock Drawing",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 4,
      },
      {
        id: 7,
        name: "sayNextIWantYouToDrawAClockFor",
        type: "label",
        label:
          "Say: “Next, I want you to draw a clock for me. First, put in all of the numbers where they go.” When that is completed, say: “Now, set the hands to 10 past 11.”\n\nUse preprinted circle (see next page) for this exercise. Repeat instructions as needed as this is not a memory test.\n\nMove to Step 3 if the clock is not complete within three minutes.",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 5,
      },
      {
        id: 7,
        name: "step3ThreeWordRecall",
        type: "label",
        label: "Step 3: Three Word Recall",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 7,
        name: "askThePersonToRecallTheThreeWo",
        type: "label",
        label:
          "Ask the person to recall the three words you stated in Step 1. Say: “What were the three words I asked you to remember?” Record the word list version number and the person’s answers below",
        editToggle: false,
        xs: 12,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 7,
      },
      {
        id: 5,
        name: "wordListVersion",
        label: "Word List Version",
        editToggle: false,
        defaultValue: "",
        xs: 2,
        fullWidth: true,
        type: "select",
        options: [
          ["1", "1"],
          ["2", "2"],
          ["3", "3"],
          ["4", "4"],
          ["5", "5"],
          ["6", "6"],
        ],
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 8,
      },
      {
        id: 7,
        name: "step3ThreeWordRecall",
        type: "label",
        label: "",
        editToggle: false,
        xs: 5,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 1,
        name: "personsAnswers",
        type: "text",
        label: "Person's Answers",
        editToggle: false,
        xs: 5,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 9,
      },

      {
        id: 11,
        name: "step3ThreeWordRecall",
        label: "Word Recall",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "HRAForm_BMI_Index_Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 13,
              name: "step3ThreeWordRecall",
              label: "Word Recall",
              columns: [
                {
                  label: "Word Recall",
                  type: "text",
                },
                {
                  label: "Points",
                  type: "select",
                },
                {
                  label: "Description",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            true,
          ],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },

      {
        id: 11,
        name: "clockDrawPoints",
        label: "Clock Draw",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "HRAForm_BMI_Index_Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 13,
              name: "clockDrawPoints",
              label: "Clock Draw",
              columns: [
                {
                  label: "Clock Draw",
                  type: "text",
                },
                {
                  label: "Points",
                  type: "select",
                },
                {
                  label: "Description",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            true,
          ],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },

      {
        id: 11,
        name: "totalScorePoints",
        label: "Total Score",
        editToggle: false,
        type: "datatable",
        tablelist: [
          [
            {
              id: 1,
              label: "Allergies",
              name: "HRAForm_Allergies",
              columns: [
                {
                  label: "Substance",
                  type: "text",
                },
                {
                  label: "Reaction",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 2,
              label: "BMI Index Chart",
              name: "HRAForm_BMI_Index_Chart",
              columns: [
                {
                  label: "BMI",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 3,
              label: "Counter Medications",
              name: "HRAForm_Counter_Medications",
              columns: [
                {
                  label: "Date",
                  type: "date",
                },
                {
                  label: "Description",
                  type: "text",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 4,
              label: "Medical History Specialists",
              name: "HRAForm_Medical_History_Speciality",
              columns: [
                {
                  label: "Member Specialist",
                  type: "select",
                },
                {
                  label: "Speciality",
                  type: "text",
                },
                {
                  label: "For",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 5,
              label: "Medications",
              name: "HRAForm_Medications",
              columns: [
                {
                  label: "Diagnoses",
                  type: "text",
                },
                {
                  label: "label Name",
                  type: "select",
                },
                {
                  label: "Dose Per Units",
                  type: "text",
                },
                {
                  label: "Route",
                  type: "select",
                },
                {
                  label: "Frequency",
                  type: "select",
                },
                {
                  label: "Prescribing Physician",
                  type: "text",
                },
                {
                  label: "Status",
                  type: "togglebutton",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 6,
              label: "Mini Cog Versions",
              name: "HRAForm_Minicog_Version",
              columns: [
                {
                  label: "Version 1",
                  type: "text",
                },
                {
                  label: "Version 2",
                  type: "text",
                },
                {
                  label: "Version 3",
                  type: "text",
                },
                {
                  label: "Version 4",
                  type: "text",
                },
                {
                  label: "Version 5",
                  type: "text",
                },
                {
                  label: "Version 6",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 7,
              label: "Preventive Care",
              name: "HRAForm_Preventive_Care",
              columns: [
                {
                  label: "Screen",
                  type: "text",
                },
                {
                  label: "Answer",
                  type: "select",
                },
                {
                  label: "Date",
                  type: "text",
                },
                {
                  label: "Method",
                  type: "text",
                },
                {
                  label: "Recommendation",
                  type: "checkbox",
                },
                {
                  label: "Education Completed",
                  type: "checkbox",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 8,
              label: "Previously Documented Conditions",
              name: "HRAForm_Previously_Documented_Conditions",
              columns: [
                {
                  label: "Diagnosis",
                  type: "text",
                },
                {
                  label: "Prior HCC",
                  type: "text",
                },
                {
                  label: "Diagnosis Code",
                  type: "text",
                },
                {
                  label: "Date of Service",
                  type: "date",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 9,
              label: "Prior Assessments",
              name: "HRAForm_Prior_Assessments",
              columns: [
                {
                  label: "Date Time",
                  type: "date",
                },
                {
                  label: "Document",
                  type: "button",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 10,
              label: "Stages Of Kidney Disease",
              name: "HRAForm_Stage_Of_Kidney_Disease",
              columns: [
                {
                  label: "Stages",
                  type: "text",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 11,
              label: "Alcohol Usage",
              name: "HRAForm_Alcohol_Usage",
              columns: [
                {
                  label: "How many drinks",
                  type: "select",
                },
                {
                  label: "How often",
                  type: "select",
                },
              ],
              isReadOnly: true,
            },
            false,
          ],
          [
            {
              id: 12,
              name: "Family History",
              columns: [
                {
                  label: "Family Member",
                  type: "select",
                },
                {
                  label: "Medical Condition",
                  type: "text",
                },
                {
                  label: "Cause of Death",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            false,
          ],
          [
            {
              id: 13,
              name: "totalScore",
              label: "Total Score",
              columns: [
                {
                  label: "Total Score",
                  type: "text",
                },
                {
                  label: "Points",
                  type: "number",
                },
                {
                  label: "Description",
                  type: "text",
                },
              ],
              isReadOnly: false,
            },
            true,
          ],
        ],
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 10,
        name: "recommendationsFurther",
        label: "Recommendations",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["Further Cognitive", "furtherCognitive"]],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 16,
      },

      {
        id: 10,
        name: "recommendationsOther",
        label: "",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["Other", "other"]],
        xs: 12,
        required: false,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 16,
      },
    ],
  },
  {
    sectionId: 16,
    sectionName: "Home Safety & Personal Goals",
    isEditable: false,
    questions: [
      {
        id: 6,
        name: "home_fallen",
        type: "options_tree",
        label: "In the Past year how many times have you Fallen?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "None",
            value: "none",
            isConfigured: true,
            type: "toggle_button",
            name: "none",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "home_fallenOnceWryFall",
                type: "options_tree",
                label:
                  "Do you worry about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenOnceWrisFall",
                type: "options_tree",
                label:
                  "Worries about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 2,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenOnceFrac",
                type: "options_tree",
                label: "Did you have a fracture in the last 6 months?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "home_fallenOnceFracByFall",
                        type: "options_tree",
                        label: "Was it due to any fall?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "home_fallenOnceOstMed",
                        type: "options_tree",
                        label: "Are you on osteoperosis med?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 3,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Once",
            value: "once",
            type: "toggle_button",
            name: "once",
            depth: 2,
          },
          {
            subQuestionId: 3,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "home_fallenTwiceWryFall",
                type: "options_tree",
                label:
                  "Do you worry about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenTwiceWrisFall",
                type: "options_tree",
                label:
                  "Worries about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 2,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenTwiceFrac",
                type: "options_tree",
                label: "Did you have a fracture in the last 6 months?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "home_fallenTwiceFracByFall",
                        type: "options_tree",
                        label: "Was it due to any fall?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "home_fallenTwiceOstMed",
                        type: "options_tree",
                        label: "Are you on osteoperosis med?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 3,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Twice",
            value: "twice",
            type: "toggle_button",
            name: "twice",
            depth: 2,
          },
          {
            subQuestionId: 4,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "home_fallenThreeWryFall",
                type: "options_tree",
                label:
                  "Do you worry about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenThreeWrisFall",
                type: "options_tree",
                label:
                  "Worries about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 2,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenThreeFrac",
                type: "options_tree",
                label: "Did you have a fracture in the last 6 months?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "home_fallenThreeFracByFall",
                        type: "options_tree",
                        label: "Was it due to any fall?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "home_fallenThreeOstMed",
                        type: "options_tree",
                        label: "Are you on osteoperosis med?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 3,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Three Times",
            value: "threeTimes",
            type: "toggle_button",
            name: "threeTimes",
            depth: 2,
          },
          {
            subQuestionId: 5,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "home_fallenMoreThreeWryFall",
                type: "options_tree",
                label:
                  "Do you worry about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenMoreThreeWrisFall",
                type: "options_tree",
                label:
                  "Worries about falling or feeling unsteady when standing or walking.?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 2,
                editToggle: false,
                depth: 3,
              },
              {
                id: 6,
                name: "home_fallenMoreThreeFrac",
                type: "options_tree",
                label: "Did you have a fracture in the last 6 months?",
                fullWidth: true,
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isConfigured: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 4,
                    subQuestionId: 1,
                    hasSubQuestions: true,
                    subQuestions: [
                      {
                        id: 6,
                        name: "home_fallenMoreThreeFracByFall",
                        type: "options_tree",
                        label: "Was it due to any fall?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 1,
                        editToggle: false,
                        depth: 5,
                      },
                      {
                        id: 6,
                        name: "home_fallenMoreThreeOstMed",
                        type: "options_tree",
                        label: "Are you on osteoperosis med?",
                        fullWidth: true,
                        required: true,
                        xs: 12,
                        isCommentEnabled: true,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: true,
                        subQuestions: [
                          {
                            depth: 6,
                            subQuestionId: 1,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            label: "Yes",
                            value: "yes",
                            isConfigured: true,
                            type: "toggle_button",
                            name: "yes",
                          },
                          {
                            subQuestionId: 2,
                            hasSubQuestions: false,
                            subQuestions: [],
                            editToggle: false,
                            isCommentEnabled: false,
                            isConfigured: true,
                            isModified: false,
                            label: "No",
                            value: "no",
                            type: "toggle_button",
                            name: "no",
                            depth: 6,
                          },
                        ],
                        subQuestionId: 2,
                        editToggle: false,
                        depth: 5,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "yes",
                    isConfigured: true,
                    type: "toggle_button",
                    name: "yes",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "no",
                    type: "toggle_button",
                    name: "no",
                    depth: 4,
                  },
                ],
                subQuestionId: 3,
                editToggle: false,
                depth: 3,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "More than Three times",
            value: "moreThanThreeTimes",
            type: "toggle_button",
            name: "moreThanThreeTimes",
            depth: 2,
          },
        ],
        questionId: 7,
        editToggle: false,
        depth: 1,
      },
      {
        id: 7,
        name: "homeSafety",
        type: "label",
        label: "Home Safety",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
        depth: 1,
      },
      {
        id: 6,
        name: "home_obstInHouse",
        type: "options_tree",
        label:
          "Do you have obstacles in the house, loose small rugs or objects on the floor that could cause tripping?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 8,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_elecCords",
        type: "options_tree",
        label:
          "Do you have electrical cords running across floors, in doorways or under a rugs?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 9,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_noMats",
        type: "options_tree",
        label: "Do you have no slip mats on the shower floor or bath tub?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 10,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_adeLight",
        type: "options_tree",
        label: "Do have adequate lighting in hallways and on the stairs?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 11,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_handRail",
        type: "options_tree",
        label: "Do you have handrails on staircases?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 12,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_hotWater",
        type: "options_tree",
        label: "Is your hot water heater set for a maximum of 120 degrees?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 13,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_smokeDet",
        type: "options_tree",
        label:
          "Do you have smoke detectors on each level of the house and in all sleeping a rooms?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 14,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_co2Det",
        type: "options_tree",
        label:
          "Do you have carbon Monoxide detectors on each level of the house?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 15,
        editToggle: false,
        depth: 1,
      },
      {
        id: 6,
        name: "home_escRoute",
        type: "options_tree",
        label: "Have used established an escape route in the event of fire?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 2,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
            name: "yes",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            name: "no",
            depth: 2,
          },
        ],
        questionId: 16,
        editToggle: false,
        depth: 1,
      },
      {
        id: 1,
        name: "home_wishToChange",
        type: "text",
        label:
          "Are there things about yourself you wish you could change or improve?",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 1,
        name: "home_qualityOfLife",
        type: "text",
        label:
          "Is there anything that you could do to improve your quality of life?",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        questionId: 3,
      },
      {
        id: 6,
        name: "home_abusedBySomeone",
        type: "options_tree",
        label: "Have you ever physically or felt emotionally abused by someone",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
          },
        ],
        questionId: 4,
        editToggle: false,
      },
      {
        id: 6,
        name: "home_feelingLikeHarming",
        type: "options_tree",
        label: "Feeling like harming others or yourself",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "home_feelingLikeHarmingSubAnsWho",
                type: "text",
                label: "Who do you feel like harming?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 2,
              },
              {
                id: 1,
                name: "home_feelingLikeHarmingSubAnsAt",
                type: "text",
                label: "Do you feel like this at this moment?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 2,
              },
              {
                id: 1,
                name: "home_feelingLikeHarmingSubAnsSafe",
                type: "text",
                label: "Are you in a safe place?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 2,
              },
              {
                id: 1,
                name: "home_feelingLikeHarmingSubAnsAssist",
                type: "text",
                label: "Would you like me to assist you to call 911?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 5,
        editToggle: false,
      },
      {
        id: 6,
        name: "home_afraidOfAnyOne",
        type: "options_tree",
        label: "Are you afraid of anyone or is anyone hurting you?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "home_afraidOfAnyOneSubAnsWho",
                type: "text",
                label: "Who are you afraid of? Are you afraid at this moment?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 2,
              },
              {
                id: 1,
                name: "home_afraidOfAnyOneSubAnsAt",
                type: "text",
                label: "Who is hurting you?Are you being hurt at this moment?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 2,
                depth: 2,
              },
              {
                id: 1,
                name: "home_afraidOfAnyOneSubAnsSafe",
                type: "text",
                label: "Are you in a safe place?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 3,
                depth: 2,
              },
              {
                id: 1,
                name: "home_afraidOfAnyOneSubAnsAssist",
                type: "text",
                label: "Would you like me to assist you to call 911?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 4,
                depth: 2,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            type: "toggle_button",
            depth: 1,
          },
        ],
        questionId: 6,
        editToggle: false,
      },
    ],
  },

  {
    sectionId: 17,
    sectionName: "Patient Summary",
    isEditable: false,
    questions: [
      {
        id: 7,
        name: "assessors_comments_:",
        type: "label",
        label: "Assessor's Comments:",
        editToggle: false,
        xs: 12,
        required: false, //"no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 1,
        name: "assessorsComments",
        value: "assessorsComments",
        type: "text",
        label: "Assessor's Comments:",
        editToggle: false,
        xs: 12,
        required: false, //"no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 7,
        name: "member_acknowledgment",
        type: "label",
        label: "Member Acknowledgment",
        editToggle: false,
        xs: 6,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 3,
      },
      {
        id: 2,
        name: "total_score",
        type: "number",
        label: "Total Score",
        editToggle: false,
        xs: 6,
        disabled: true,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 4,
      },
      {
        id: 7,
        name: "i_have_been_advised_by_the_evaluator_and_understand_that_the_services_performed_by_the_evaluator_are_limited_to_the_evaluation_performed_today;_the_evaluator_has_no_further_duties_to_me_once_the_evaluation_performed_today_is_completed_;_the_evaluator_is_not_liable_for_abandonment_my_refusing_to_provide_me_treatment_or_continuing_care_to_me_beyond_this_evaluation;_and_i_should_contact_my_primary_care_or_treating_physician_for_all_questions_and_concerns_regarding_medical_care_and_treatment_or,_in_the_event_of_an_emergency,_call_911",
        type: "label",
        label:
          "I have been advised by the evaluator and understand that the services performed by the evaluator are limited to the evaluation performed today; the evaluator has no further duties to me once the evaluation performed today is completed ; the evaluator is not liable for abandonment by refusing to provide me treatment or continuing care to me beyond this evaluation; and I should contact my primary care or treating physician for all questions and concerns regarding medical care and treatment or, in the event of an emergency, call 911",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 5,
      },
      {
        id: 10,
        name: "memberAknowledgement",
        value: "memberAknowledgement",
        label: "       ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          ["Member informed of acknowledgement", "memberAknowledgement"],
        ],
        xs: 6,
        required: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 6,
      },
      {
        id: 10,
        name: "acceptDisclosure",
        value: "acceptDisclosure",
        label: "    ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [["I accept the Disclosure Statement", "acceptDisclosure"]],
        xs: 6,
        required: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 7,
      },
      {
        id: 10,
        name: "",
        value: "",
        label: "    ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Member consented to complete the virtual visit using audio and video, understands the purpose of the visit and their participation.",
            "",
          ],
        ],
        xs: 6,
        required: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 8,
      },
      {
        id: 11,
        name: "",
        value: "",
        label: "    ",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "checkbox",
        options: [
          [
            "Members identify confirmed with Driver's license or valid photo ID",
            "",
          ],
        ],
        xs: 6,
        required: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 9,
      },
      {
        id: 7,
        name: "comments",
        type: "label",
        label: "Comments :",
        editToggle: false,
        xs: 12,
        required: false, //"no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 1,
      },
      {
        id: 1,
        name: "comments",
        value: "",
        type: "text",
        label: "Comments :",
        editToggle: false,
        xs: 12,
        required: false, //"no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 2,
      },
      {
        id: 4,
        name: "evaluationDate",
        value: "evaluationDate",
        label: "Date/Time of Service/Evaluation:",
        placeholder: "",
        type: "date",
        editToggle: false,
        xs: 4,
        defaultValue: "2023-09-11",
        value: "2023-09-11",
        fullWidth: true,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 11,
      },
      {
        id: 4,
        name: "examFinishDate",
        value: "examFinishDate",
        label: "Time exam finished :",
        placeholder: "",
        type: "date",
        editToggle: false,
        xs: 4,
        defaultValue: "2023-09-11",
        value: "2023-09-11",
        fullWidth: true,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 12,
      },
      {
        id: 5,
        name: "visitType",
        value: "visitType",
        label: "Visit Type",
        editToggle: false,
        defaultValue: "",
        type: "select",
        options: [
          ["Select", "Select"],
          ["In Person", "inPerson"],
          ["Virtual:Video & Audio Capability", "virtual"],
        ],
        xs: 4,
        sm: 4,
        required: "no",
        fullWidth: true,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 13,
      },
      {
        id: 8,
        name: "signSubmit",
        type: "button",
        label: "Sign & Submit",
        editToggle: false,
        xs: 4,
        required: "no",
        isSingleButton: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 14,
      },
      {
        id: 8,
        name: "view_full_report",
        type: "button",
        label: "View Full Report",
        editToggle: false,
        xs: 4,
        required: "no",
        isSingleButton: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 11,
      },
      {
        id: 8,
        name: "view_summary",
        type: "button",
        label: "View Summary",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isSingleButton: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 12,
      },
      {
        id: 8,
        name: "member_summary",
        type: "button",
        label: "Member Summary",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isSingleButton: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 13,
      },
      {
        id: 8,
        name: "referral_form",
        type: "button",
        label: "Referral Form",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        isSingleButton: false,
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 14,
      },
      {
        id: 8,
        name: "cancel_assessment",
        type: "button",
        label: "Cancel Assessment",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isSingleButton: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 15,
      },
      {
        id: 8,
        name: "no_show",
        type: "button",
        label: "No show",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isSingleButton: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 16,
      },
      {
        id: 8,
        name: "xml",
        type: "button",
        label: "XML",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isSingleButton: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 17,
      },
      {
        id: 8,
        name: "json",
        type: "button",
        label: "JSON",
        editToggle: false,
        xs: 4,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isSingleButton: false,
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 18,
      },
      {
        id: 12,
        name: "image",
        label: "Image",
        placeholder: "",
        editToggle: false,
        defaultValue: "",
        type: "image",
        src: "",
        alt: "",
        xs: 12,
        required: "no",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 19,
      },
      {
        id: 7,
        name: "disclosure_statement",
        type: "label",
        label: "Disclosure Statement",
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 21,
      },
      {
        id: 7,
        name: "your_health_plan",
        type: "label",
        label: (
          <span>
            "Your health plan, has contracted with Focus Care to conduct a
            health exam on all of its Medicare members, including you. The
            health exam includes questions to help your health plan learn more
            about your current health. The exam may also find things that could
            effect your health. The results of the exam will help your health
            plan and your doctor keep you as healthy as possible. <br /> <br />
            Personal health information, or PHI, is information in your medical
            record that identifies the record as your record. PHI includes
            things like your date of birth, age, address, telephone number, and
            your medical history. <br /> <br />
            Most of the time, Focus Care will not release your personal
            information without your permission. Measures are in place to
            prevent your personal information from being accidentally released
            in writing, including by use of a computer, or orally. You may
            request more information about how your personal information is
            protected.
            <br /> <br />
            There are times when Focus Care is allowed to release your personal
            information without your permission. For example, your medical
            information may be given to other health care providers who take
            care of you. The results of this exam will be sent to your health
            plan and to your doctor.
            <br /> <br />
            Focus Care may release your personal health information to a
            'business associate'. A 'business associate' is another agency that
            Focus Care uses to do things, such as billing. We require our
            'business associates' to have security measures in place to prevent
            your personal information from being accidentally released in
            writing, including by use of a computer, or orally.
            <br /> <br />
            Focus Care may be required to release your personal health
            information, without your permission, by law. including statutes,
            regulations, or valid court orders.
            <br /> <br />
            Focus Care will obtain your permission to use or release your
            personal health information for any other reason.
            <br /> <br />
            Do you have any questions about this information? Would you like to
            receive this information in a different language?
            <br /> <br />
            Your agreement to have this medical exam means you have given your
            permission to Focus Care to release the results of your medical exam
            to your health plan and to your doctor. Do you agree?
            <br /> <br />
            The information obtained today and any applicable lab results (some
            of which may become available after subsequent analysis) may be sent
            to your primary care physician (PCP)."
          </span>
        ),
        editToggle: false,
        xs: 12,
        required: "no",
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
        questionId: 20,
      },
    ],
  },
];

export const getFormData = () => {
  const data = localStorage.getItem("formLayoutData")
    ? JSON.parse(localStorage.getItem("formLayoutData"))
    : DemoGraphicsData;

  return data;
};

export const getFormDataByID = (section_id) => {
  const data = localStorage.getItem("formLayoutData")
    ? JSON.parse(localStorage.getItem("formLayoutData"))
    : DemoGraphicsData;

  return data[section_id] ? [data[section_id]] : [];
};

export const saveFormData = (formData) => {
  localStorage.setItem("formLayoutData", JSON.stringify(formData));
  return true;
};

export const optionsData = [
  {
    questionId: 1,
    name: "trial_options",
    label: "Trial for Options",
    type: "options_tree",
    options: [
      ["Asian", "asian", false],
      ["American", "american", false],
      [
        "Other",
        "other",
        true,
        [
          {
            subQuestionId: 1,
            name: "plan",
            type: "text",
            placeholder: "Plan",
            defaultValue: "",
            label: "Plan",
            variant: "outlined",
            fullWidth: true,
            required: false,
            xs: 6,
            sm: 6,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
        ],
      ],
    ],
    subQuestions: [
      {
        subQuestionId: 1,
        name: "plan",
        type: "text",
        placeholder: "Plan",
        defaultValue: "",
        label: "Plan",
        variant: "outlined",
        fullWidth: true,
        required: false,
        xs: 6,
        sm: 6,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        subQuestionId: 2,
        name: "age",
        type: "number",
        placeholder: "Age",
        label: "Age",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 2,
        sm: 2,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        subQuestionId: 3,
        name: "gender",
        label: "Gender",
        placeholder: "",
        defaultValue: "male",
        type: "radio",
        options: [
          ["Male", "male"],
          ["Female", "female"],
          ["Other", "other"],
        ],
        xs: 4,
        sm: 4,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        subQuestionId: 1,
        name: "options_tree",
        label: "Options Tree",
        type: "options_tree",
        subquestions: [
          {
            subQuestionId: 1,
            name: "firstname",
            type: "text",
            placeholder: "Enter First Name",
            label: "First Name",
            variant: "outlined",
            fullWidth: true,
            required: true,
            xs: 4,
            sm: 4,
            isEnabledClients: [
              ["VPHP", true],
              ["OHP", true],
              ["SWHR", false],
              ["ALLIANT", false],
              ["HA", false],
              ["SHP", false],
            ],
            isEnabledPlans: [
              ["Medicare", true],
              ["MedicAid", true],
            ],
            isAgeGroup: [
              ["Above 65", true],
              ["Below 65", true],
            ],
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
          {
            subQuestionId: 2,
            name: "age",
            type: "number",
            placeholder: "Age",
            label: "Age",
            variant: "outlined",
            fullWidth: true,
            required: true,
            xs: 2,
            sm: 2,
            isEnabledClients: [
              ["VPHP", true],
              ["OHP", true],
              ["SWHR", false],
              ["ALLIANT", false],
              ["HA", false],
              ["SHP", false],
            ],
            isEnabledPlans: [
              ["Medicare", true],
              ["MedicAid", true],
            ],
            isAgeGroup: [
              ["Above 65", true],
              ["Below 65", true],
            ],
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
          {
            subQuestionId: 3,
            name: "gender",
            label: "Gender",
            placeholder: "",
            defaultValue: "male",
            type: "radio",
            options: [
              ["Male", "male"],
              ["Female", "female"],
              ["Other", "other"],
            ],
            xs: 6,
            sm: 6,
            isEnabledClients: [
              ["VPHP", true],
              ["OHP", true],
              ["SWHR", false],
              ["ALLIANT", false],
              ["HA", false],
              ["SHP", false],
            ],
            isEnabledPlans: [
              ["Medicare", true],
              ["MedicAid", true],
            ],
            isAgeGroup: [
              ["Above 65", true],
              ["Below 65", true],
            ],
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
        ],
        required: true,
        xs: 4,
        sm: 4,
        fullWidth: true,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
    ],
    required: true,
    xs: 4,
    sm: 4,
    fullWidth: true,
    isEnabledClients: [
      ["VPHP", true],
      ["OHP", true],
      ["SWHR", false],
      ["ALLIANT", false],
      ["HA", false],
      ["SHP", false],
    ],
    isEnabledPlans: [
      ["Medicare", true],
      ["MedicAid", true],
    ],
    isAgeGroup: [
      ["Above 65", true],
      ["Below 65", true],
    ],
    isCommentEnabled: false,
    isConfigured: true,
    isModified: false,
  },
];
