import { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { Close } from "@mui/icons-material";
import "../Dialog.css";
import AlertContext from "../../../contexts/AlertContext";
import AddAdmin from "../../Dashboard/ViewAdministrators/AddAdmin/AddAdmin";
import EditClient from "../../Dashboard/ViewClients/EditClient/EditClient";
import EditClinician from "../../Dashboard/ViewClinicians/EditClinician/EditClinician";

export default function EditClinicianDialog({
  open,
  title,
  onClose,
  fetchClinicianData,
  onAdd,
  componentToShow,
  fetchClientData,
  editMode,
  setEditMode,
  selectedClientData,
  setSelectedClientData,
  apiData,
  setApiData,
  appendYearToYearsList,
  selectedClinicianData,
  setSelectedClinicianData,
  handleCloseProfilePage,
}) {
  console.log("editProfile");
  const [showErrors, setShowErrors] = useState(false);
  const alertContext = useContext(AlertContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [clientData, setClientData] = useState({
    clientName: "",
    plan: "",
    program: "",
    healthPlanName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
    year: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setClientData((prevData) => ({ ...prevData, [field]: value }));
    handleInputChange(field);
  };

  const handleInputChange = (field) => {
    if (!clientData[field].trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "This field is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };

  const handleAdd = () => {
    if (Object.values(clientData).some((value) => !value)) {
      setShowErrors(true);
    } else {
      onAdd(clientData);
      alertContext.setAddDialog(false);
      setShowSuccessMessage(false);
    }
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
    onClose();
  };

  const handleClose = () => {
    alertContext.setAddDialog(false);
  };

  const handleEditClose = () => {
    alertContext.setEditClinicianDialog(false);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={alertContext.editClinicianDialog || open}
        onClose={handleEditClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="alert-dialog-title"
        >
          {title}
          <div
            style={{ color: "white", cursor: "pointer", marginTop: "10px" }}
            onClick={handleEditClose}
          >
            <Close />
          </div>
        </DialogTitle>
        <DialogContent>
          {componentToShow === "client" && (
            <EditClient
              onClose={handleEditClose}
              fetchClientData={fetchClientData}
              clientData={clientData}
              showErrors={showErrors}
              handleChange={handleChange}
              handleInputChange={handleInputChange}
              showSuccessMessage={showSuccessMessage}
              onCloseSuccessMessage={handleCloseSuccessMessage}
              selectedClientData={selectedClientData}
              setSelectedClientData={setSelectedClientData}
              apiData={apiData}
              setApiData={setApiData}
              appendYearToYearsList={appendYearToYearsList}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}

          {componentToShow === "admin" && <AddAdmin onAdd={handleAdd} />}
          {componentToShow === "clinician" && (
            <EditClinician
              onClose={handleEditClose}
              onAdd={handleAdd}
              selectedClinicianData={selectedClinicianData}
              setSelectedClinicianData={setSelectedClinicianData}
              editMode={editMode}
              setEditMode={setEditMode}
              fetchClinicianData={fetchClinicianData}
              handleCloseProfilePage={handleCloseProfilePage}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
