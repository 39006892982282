import React, { lazy, Suspense, useEffect, useState } from "react";
import "../../Dashboard/Scheduler/Scheduler.css";
import "./newDash.css";
import { Grid, Paper, Typography } from "@mui/material";

import CallIcon from "@mui/icons-material/Call";
import {
  BlockOutlined,
  EventAvailable,
  PhoneMissed,
} from "@mui/icons-material";
import { useSchedulerData } from "./useSchedulerData";
import SuccessRefusalChart from "../../../components/ChartsGraphs/SuccessRefusalChart";
import Loader from "../../../components/Loader/Loader";

import { FormControl, Select, MenuItem } from "@mui/material";

const AdminPieChart = lazy(() =>
  import("../../../components/ChartsGraphs/Admin/AdminPieChart"),
);
const AdminDougnutChart = lazy(() =>
  import("../../../components/ChartsGraphs/Admin/AdminDougnutChart"),
);
const TopUsers = lazy(() =>
  import("../../../components/ChartsGraphs/Admin/TopUsers"),
);
const CallLogsChart = lazy(() =>
  import("../../../components/ChartsGraphs/CallLogsChart"),
);

export default function SchedulerGraphs({ filter, sch, setGraphFilter }) {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [par, setPar] = useState({});
  const [par1, setPar1] = useState({});
  const [plan, setPlan] = useState("");
  const [planId, setPlanId] = useState("");
  const [year, setYear] = useState("");
  const [dayStamp, setDayStamp] = useState("last12Months");

  const roleId = sessionStorage.getItem("roleId");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  useEffect(() => {
    const storedPlanId = localStorage.getItem("clientPlan");
    const storedYear = localStorage.getItem("clientYear");

    setPlanId(storedPlanId || "");
    setYear(storedYear || "");
  }, []);

  const handleSelection = (selectedPlan, selectedYear) => {
    setPlanId();
    setPlan(selectedPlan);
    setYear(selectedYear);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const param = {
      planId: planId || "",
      year: year || "",
      filters: filter || [],
    };
    if (sch !== "" && sch !== undefined) {
      param["schedulerId"] = sch;
    }
    setPar(param);
    setGraphFilter(false);
  }, [filter, sch, setGraphFilter, planId, year]);

  useEffect(() => {
    const callLogParam = {
      planId: planId || "",
      year: year || "",
      filterBy: dayStamp, //[last12Months, last4Weeks, last7Days]
    };
    if (sch !== "" && sch !== undefined) {
      callLogParam["schedulerId"] = sch;
    }
    setPar1(callLogParam);
  }, [planId, year, dayStamp, sch]);

  const { data } = useSchedulerData(par, par1);
  const {
    headerCardsData,
    schedulersCallCompleted,
    schedulersMemberAssigned,
    groupedCallResult,
    noShowsData,
    succRef,
    groupedCallLog,
  } = data ?? {};

  const callStats = [
    {
      label: "Calls Completed History",
      count: headerCardsData?.callsCompleted,
      bgColor: "rgba(52, 168, 83, 0.6)", // Soft green (Positive)
      icon: <CallIcon sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Current Scheduled",
      count: headerCardsData?.scheduled,
      bgColor: "rgba(0, 123, 255, 0.6)", // Soft blue (Positive)
      icon: <EventAvailable sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Current UTC",
      count: headerCardsData?.utc,
      bgColor: "rgba(255, 90, 95, 0.9)", //"rgba(255, 99, 71, 0.8)", // Soft red (Negative)
      icon: <PhoneMissed sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Refused History",
      count: headerCardsData?.refused,
      bgColor: "rgba(58, 0, 204, 0.5)",
      icon: <BlockOutlined sx={{ height: "45px", width: "45px" }} />,
    },
  ];

  const [draggingItem, setDraggingItem] = useState(null);
  const [positions, setPositions] = useState({
    chart1: { order: 1 },
    chart2: { order: 2 },
    chart3: { order: 3 },
    chart4: { order: 4 },
    chart5: { order: 5 },
    chart6: { order: 6 },
  });

  const handleDragStart = (e, chartId) => {
    setDraggingItem(chartId);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (e, targetChartId) => {
    e.preventDefault();

    // Swap the positions of dragged and target charts
    const updatedPositions = { ...positions };
    const draggedItemOrder = updatedPositions[draggingItem].order;
    updatedPositions[draggingItem].order =
      updatedPositions[targetChartId].order;
    updatedPositions[targetChartId].order = draggedItemOrder;

    setPositions(updatedPositions);
  };

  return (
    <>
      {!data ? (
        <Suspense fallback={<h2>Loading...</h2>}>
          <Loader />
        </Suspense>
      ) : (
        <>
          <Suspense falllback={<h2>Loading...</h2>}>
            <Grid
              container
              spacing={2}
              className="graph-dashboard-container"
              sx={{ paddingBottom: "30px" }}
            >
              <Grid item xs={12} className="graph-header-container">
                <Grid container spacing={2}>
                  {callStats.map((stat, index) => (
                    <Grid item xs={3} key={index}>
                      <Paper
                        className="graph-header-item"
                        sx={{
                          bgcolor: stat.bgColor,
                          borderRadius: "8px",
                          color: "white",
                        }}
                      >
                        <span className="graph-header-icon">{stat.icon}</span>
                        <div className="graph-header-content">
                          <Typography
                            variant="h6"
                            sx={{ fontSize: "1.8rem", lineHeight: "0" }}
                          >
                            {stat.count}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "1rem", lineHeight: "0" }}
                          >
                            {stat.label}
                          </Typography>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4.5}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart1")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart1")}
                style={{ order: positions.chart1.order }}
              >
                <Paper
                  className="chart-item graph-hra-status"
                  sx={{ borderRadius: "8px" }}
                >
                  <Typography variant="h6">
                    {" "}
                    {roleId == 3
                      ? schedulersCallCompleted?.length == 1
                        ? `${schedulersCallCompleted[0]?.name}'s Calls Completed History`
                        : "Top Schedulers: Calls Completed History"
                      : `${firstName}'s Calls Completed History`}
                  </Typography>
                  <TopUsers data={schedulersCallCompleted} />
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                md={4.5}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart2")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart2")}
                style={{ order: positions.chart2.order }}
              >
                <Paper
                  className="chart-item graph-call-logs"
                  sx={{ borderRadius: "8px" }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      height: "40px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {roleId == 3
                      ? schedulersCallCompleted?.length == 1
                        ? `${schedulersCallCompleted[0]?.name}'s Call Logs History`
                        : "All Scheduler's Call Logs History"
                      : `${firstName}'s Call Logs History`}
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                      <Select
                        labelId="dropdown-label"
                        value={dayStamp}
                        onChange={(e) => setDayStamp(e.target.value)}
                        label="Options"
                      >
                        <MenuItem value={"last12Months"}>
                          Last One Year
                        </MenuItem>
                        <MenuItem value={"last4Weeks"}>Last One Month</MenuItem>
                        <MenuItem value={"last7Days"}>Last One Week</MenuItem>
                      </Select>
                    </FormControl>
                  </Typography>
                  <CallLogsChart data={groupedCallLog} />
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart3")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart3")}
                style={{ order: positions.chart3.order }}
              >
                <Paper
                  className="chart-item graph-success-rate"
                  sx={{ borderRadius: "8px" }}
                >
                  <Typography variant="h6">
                    {" "}
                    {roleId == 3
                      ? schedulersCallCompleted?.length === 1
                        ? `${schedulersCallCompleted[0]?.name}'s Noshows History`
                        : "All Scheduler's Noshows History"
                      : `${firstName}'s Noshows History`}
                  </Typography>
                  <AdminDougnutChart data={noShowsData} />
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                md={4.5}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart4")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart4")}
                style={{ order: positions.chart4.order }}
              >
                <Paper
                  className="chart-item graph-calls-complete"
                  sx={{ borderRadius: "8px" }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      height: "40px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {roleId == 3
                      ? schedulersCallCompleted?.length <= 1
                        ? `${schedulersCallCompleted[0]?.name}'s Success & Refusal Rate`
                        : "All Scheduler's Success & Refusal Rate"
                      : `${firstName}'s Success & Refusal Rate`}
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                      <Select
                        labelId="dropdown-label"
                        value={dayStamp}
                        onChange={(e) => setDayStamp(e.target.value)}
                        label="Options"
                      >
                        <MenuItem value={"last12Months"}>
                          Last One Year
                        </MenuItem>
                        <MenuItem value={"last4Weeks"}>Last One Month</MenuItem>
                        <MenuItem value={"last7Days"}>Last One Week</MenuItem>
                      </Select>
                    </FormControl>
                  </Typography>
                  <SuccessRefusalChart data={succRef} />
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                md={4.5}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart5")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart5")}
                style={{ order: positions.chart5.order }}
              >
                <Paper
                  className="chart-item graph-hra-status"
                  sx={{ borderRadius: "8px" }}
                >
                  <Typography variant="h6">
                    {" "}
                    {roleId == 3
                      ? schedulersCallCompleted?.length === 1
                        ? `${schedulersCallCompleted[0]?.name}'s Members Assigned`
                        : "Top Schedulers: Members Assigned"
                      : `${firstName}'s Members Assigned`}
                  </Typography>
                  <TopUsers data={schedulersMemberAssigned} />
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart6")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart6")}
                style={{ order: positions.chart6.order }}
              >
                <Paper
                  className="chart-item graph-call-status"
                  sx={{ borderRadius: "8px" }}
                >
                  <Typography variant="h6">
                    {" "}
                    {roleId == 3
                      ? schedulersCallCompleted?.length === 1
                        ? `${schedulersCallCompleted[0]?.name}'s Call Results History`
                        : "All Schedulers: Call Results History"
                      : `${firstName}'s Call Results History`}
                  </Typography>
                  <AdminPieChart data={groupedCallResult} />
                </Paper>
              </Grid>
            </Grid>
          </Suspense>
        </>
      )}
    </>
  );
}
