import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { SelectDropdown } from "../select/select";
import { ScreeningQuestion } from "./screeningQuestion";
import { Modal, Popover, Select, Zoom } from "@mui/material";
//import AlertContext from "../contexts/AlertContext";
//import CustomizedDialogs from "../Dialog/Dialog";
import CustomizedDialogs from "../../components/Dashboard/Dialog/Dialog";
import AlertContext from "../../contexts/AlertContext";
//import "./MemberList.css";
import "./nurseListPage.css";
import { useContext, useState, useEffect } from "react";
//import Button from "../../button/button";
import Button from "../button/button";
//import { useUserContext } from "../contexts/UserContext";
import { useUserContext } from "../../contexts/UserContext";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React from "react";
import Slide from "@mui/material/Slide";
import Toaster from "../toaster/toaster";
import Labels from "../../resource_files/Labels.resx";
import { makeStyles } from "@mui/styles";
import { NurseFilterDialogs } from "../../components/Dashboard/Dialog/Dialog";
import VphpDialog from "../Dashboard/MemberList/vphp/vphp";
import { isEmpty, trim } from "lodash";
const useStyles = makeStyles((theme) => ({
  verticalDivider: {
    height: "100%",
    margin: 1,
    width: "5px",
    backgroundColor: "#1976d2",
  },

  card: {
    paddingX: "20px",
    textAlign: "center",
    width: "100%",
  },
  root: {
    border: "1px solid",
    padding: "10px",
    boxShadow: "5px 10px red",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NurseListHeader = ({
  onDropdownChange,
  hraStatusOptions,
  onSearch,
  visitTypeOptions,
  clinicianData,
  selectedCheckboxes,
  isDialogOpen,
  onResetSearch,
  // selectedDropdownValue,
}) => {
  const classes = useStyles();
  const { clientData, handleBadgeClick } = useUserContext();
  const [openScreeningQuestionDialog, setOpenScreeningQuestionDialog] =
    useState(false);

  const memberListOptions = [
    "My Schedule",
    "In Progress",
    "Completed",
    "Needs Review",
    "Referral",
  ];

  const filterDropdown = ["Identification#", "First name", "Last name"];
  const alertContext = useContext(AlertContext);
  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState("My Schedule");
  const [selectedFilter, setSelectedFilter] = useState(filterDropdown[0]);
  const [isFirstNameSelected, setIsFirstNameSelected] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);

  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedDropdownValue(newValue);
    onDropdownChange(newValue);
  };

  const toggleOpen = () => {
    alertContext.setFilterDialog(true);
  };
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");

  const storedYearList = JSON.parse(
    localStorage.getItem("extractedYearOptions"),
  );

  useEffect(() => {
    setSelectedFilter("Identification#");
    onSearch("Identification#", "");
  }, []);

  useEffect(() => {
    const covidFlag = sessionStorage.getItem("covidFlag");
    if (covidFlag == 1) {
      setOpenScreeningQuestionDialog(true);
    }
  }, []);

  const handleSearch = (e) => {
    if (e.key == "Enter") {
      if (searchTerm.length > 2) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, trim(searchTerm));
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, trim(searchTerm));
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, trim(searchTerm));
        }
      } else {
        setToasterOpen(true);
        setToasterSeverity("error");
        setToasterMessage("Minimum 3 characters required to search!");
      }
    }
    if (e.key === "Backspace" && searchTerm.length < 4) {
      if (!isEmpty(searchTerm) && searchTerm.length != 1) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, searchTerm);
        }
      } else if (searchTerm.length == 1 || isEmpty(searchTerm.length)) {
        setSelectedFilter(filterDropdown[0]);
        setSearchTerm("");
        // onSearch(selectedFilter, "");
        onResetSearch();
      }
    }
  };

  const handleResetSearch = () => {
    //setLoading(true);
    onResetSearch();
    setSelectedFilter(filterDropdown[0]);
    setSearchTerm("");
    // onSearch(selectedFilter, "");
  };

  const handleFilterDropdownChange = (event) => {
    const { value } = event.target;
    setSelectedFilter(value);
    setIsFirstNameSelected(value === "First name");
  };
  const handleSearchOnIconClick = () => {
    if (searchTerm.length > 2) {
      onSearch(selectedFilter, searchTerm);
    } else {
      setToasterOpen(true);
      setToasterSeverity("error");
      setToasterMessage("Minimum 3 characters required to search!");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  // Function to handle popover visibility
  const handlePopoverToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // Function to close the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {/* <ScreeningQuestion
        open={openScreeningQuestionDialog}
        setOpen={setOpenScreeningQuestionDialog}
      /> */}
      {isDialogOpen === false ? (
        <div className="nursemember-list">
          <NurseFilterDialogs
            hraStatusOptions={hraStatusOptions}
            visitTypeOptions={visitTypeOptions}
            clinicianData={clinicianData}
            selectedDropdownValue={selectedDropdownValue}
          />
          <div className="nursemember-list-left">
            {(clientData?.isAllSelected === true ||
              localStorage.getItem("isAllSelected") === "true") &&
            (clientData?.plan !== null || storedPlan) &&
            (clientData?.year !== null || storedYearList) ? (
              <Tooltip
                TransitionComponent={Zoom}
                title={`Selected: ${clientData?.plan || storedPlan}-${
                  clientData?.year || storedYear
                }`}
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <Box
                  className="filterBoxML"
                  onClick={handlePopoverToggle}
                  style={{ borderRadius: "50px" }}
                >
                  <p>{`${clientData?.plan || storedPlan}-${
                    clientData?.year || storedYear
                  }`}</p>
                </Box>
              </Tooltip>
            ) : (
              <></>
            )}
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "fixed",
              }}
              transformOrigin={{
                vertical: -47,
                horizontal: 0,
              }}
              style={{
                position: "fixed",
              }}
            >
              <VphpDialog
                // handleClosePopover={handleClosePopover}
                handlePopoverToggle={handlePopoverToggle}
              />
            </Popover>
          </div>
          <div className="nursemember-list-right">
            <Box className="filterBoxFilterML" onClick={toggleOpen}>
              <p>Filter</p>
              <FilterAltIcon sx={{ height: "26px", width: "26px" }} />
            </Box>
            <SelectDropdown
              sx={{
                borderRadius: 20,
                width: "154px",
                height: "40px",
                fontSize: "16px",
                fontWeight: 500,
                marginRight: 1,
                marginLeft: 2,
                "&:hover": {
                  backgroundColor: "var(--mainTheme)",
                },
              }}
              title="Identification"
              options={filterDropdown}
              className="dropdownMemberList"
              valueClass="dropdownValueMemberList"
              onChange={handleFilterDropdownChange}
            />
            <Box
              className="searchBoxML"
              sx={{ background: "var(--mainTheme200)" }}
            >
              <TextField
                onKeyDown={(e) => handleSearch(e)}
                placeholder="Search"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={(e) => handleSearchOnIconClick()}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTerm && (
                        <CancelOutlinedIcon
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={handleResetSearch} //
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            {/* <Button onClick={handleSearch} >Search</Button> */}
          </div>
        </div>
      ) : null}

      {(clientData?.isAllSelected === true ||
        localStorage.getItem("isAllSelected") === "true") &&
      (clientData?.plan !== null || storedPlan) &&
      (clientData?.year !== null || storedYearList) ? (
        <div style={{ marginTop: "22px" }}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              className="custom-tabs"
              value={selectedDropdownValue}
              onChange={(e, newValue) =>
                handleDropdownChange({ target: { value: newValue } })
              }
              aria-label="wrapped label tabs example"
            >
              {memberListOptions.map((option, index) => (
                <Tab key={index} value={option} label={option} />
              ))}
            </Tabs>
          </Box>
        </div>
      ) : (
        <></>
      )}
      <Toaster
        open={toasterOpen}
        onClose={() => setToasterOpen(false)}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

export default NurseListHeader;
