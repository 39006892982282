import React from "react";
import {
  Box,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export const priorAssesmentData = [
  {
    year: "2024",
    // document: "https://example.com"
    // document: ""
  },
];

export const TableComponentPriorAssessment = ({ data = [] }) => {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  // const clientYear =localStorage.getItem("clientYear")
  return (
    <div>
      <CardHeader
        title={"Prior Assessments"}
        action={
          <a
            style={{
              textDecoration: "none",

              color: "rgba(0, 0, 0, 0.87)",
            }}
            sx={{
              textDecoration: "none",
              color: "var(--mainTheme)",
            }}
            // href={`#${section.sectionId}`}
          >
            <div
              style={{
                width: "100% !important",
                margin: "0 !important",
                padding: "0 !important",
              }}
            ></div>
          </a>
        }
        sx={{
          backgroundColor: `var(--mainTheme900)`,
          color: "white",
          padding: "8px",
          textAlign: "center !important",
          // textTransform: "uppercase",
          fontWeight: 700,
        }}
        titleTypographyProps={{ variant: "outline" }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Table
          sx={{
            tableLayout: "auto",
            margin: "10px",
            overflow: "hidden",
            width: "50vw",
          }}
          size="small"
        >
          <TableHead style={tableHeadStyle}>
            <TableRow>
              <TableCell
                style={{
                  border: "1px solid var(--mainTheme800)",
                  textAlign: "center",
                  color: "#ffff",
                  backgroundColor: "var(--mainTheme800)",
                }}
              >
                Year
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid var(--mainTheme800)",
                  textAlign: "center",
                  color: "#ffff",
                  backgroundColor: "var(--mainTheme800)",
                }}
              >
                Document
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={tableCellStyle}>{item.year}</TableCell>
                  <TableCell style={tableCellStyle}>
                    <a
                      href={item.document}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "var(--mainTheme)" }}
                    >
                      View Document
                    </a>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} style={tableCellStyle}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
};
