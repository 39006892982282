import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import SchedulerHistory from "../../Modal/schedulerHistory";
import "./DataTable.scss";
import { useUserContext } from "../../../contexts/UserContext";
import { setSelectedCheckboxes } from "../../../utils/helpers.DynamicForm";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `var(--mainTheme)`,
    whiteSpace: "nowrap",
    color: theme.palette.common.white,
    // padding: "16px 10px 16px 32px !important",
    textAlign: "center !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: "0px 20px 0px 20px",
    whiteSpace: "nowrap",
    textAlign: "left !important",
    //backgroundColor: "var(--mainTheme100)"
    //  maxWidth: "0px"
  },
}));
const NoDataTableCell = styled(TableCell)({
  fontSize: 13,
  padding: "0px 20px 0px 20px",
  whiteSpace: "nowrap",
  textAlign: "center !important",
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--mainTheme100)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "& .MuiTableSortLabel-root": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-root:hover": {
    color: "white !important",
  },
  "& .Mui-active": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important",
    opacity: 1,
  },
  "& [class*='-MuiButtonBase-root-MuiTableSortLabel-root']:hover": {
    color: "white !important",
  },
}));

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export default function DataTableComponent(props) {
  const [selectAllRows, setSelectAllRows] = useState(false);
  const [selectedRowCheckboxes, setSelectedRowCheckboxes] = useState({});
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const [orderBy, setOrderBy] = useState(props?.selectedHeader);
  const [order, setOrder] = useState(props?.selectedSorting);
  const [isLoadingScheduler, setIsLoadingScheduler] = useState(false);
  const [uniqueMemberId, setUniqueMemberId] = useState("");
  const [memId, setMemId] = useState("");
  const { setSelectedMultipleRowId } = useUserContext();
  const openScheduler = (e, i) => {
    setIsSchedulerOpen(true);
    setUniqueMemberId(e);
    setMemId(i);
  };
  const closeScheduler = () => {
    setIsSchedulerOpen(false);
  };

  const handleRequestSort = (property) => {
    if (
      // property !== "Action" &&
      property !== "Program" &&
      property !== "City"
    ) {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);

      const defaultSortBy = property === "Id" ? "assessment.memberId" : null;

      const defaultOrder = property === "Id" ? "asc" : "desc";

      props.handleSorting(property, newOrder, defaultSortBy, defaultOrder);
    }
  };

  const [selectedRowId, setSelectedRowId] = useState("");
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

  const handleCheckboxChanges = (e) => {
    // Update isCheckboxSelected to true/false based on checkbox status
    setIsCheckboxSelected(e.target.checked);
  };

  const handleSelectAllRows = (event, rowIndex) => {
    const checked = event.target.checked;
    setSelectAllRows(checked);

    setSelectedRowCheckboxes((prevValues) => {
      const updatedCheckboxes = {};
      props.data.forEach((obj, index) => {
        updatedCheckboxes[index] = checked;
      });
      props.handleCheckboxSelection(checked);
      if (props?.handleCompletedHeaderCheckboxChange) {
        props.handleCompletedHeaderCheckboxChange(event);
      }
      if (props?.handleHeaderCheckboxChange) {
        props?.handleHeaderCheckboxChange(event);
      }
      if (props.setSelectedCheckboxes) {
        props.setSelectedCheckboxes((prevValues) => ({
          ...prevValues,
          [rowIndex]: event.target.checked,
        }));
      }
      setIsCheckboxSelected(checked);
      return updatedCheckboxes;
    });
  };
  // const handleRowCheckboxChange = (event, rowIndex) => {
  //   setSelectedRowCheckboxes((prevValues) => ({
  //     ...prevValues,
  //     [rowIndex]: event.target.checked,
  //   }));
  //   const isChecked = Object.values(selectedRowCheckboxes).some(
  //     (isChecked) => isChecked,
  //   );
  //   props.handleCheckboxSelection(!isChecked);
  //   // const isChecked = event.target.checked;
  //   // props.handleCheckboxSelection(isChecked, rowIndex);

  //   props.setSelectedCheckboxes((prevValues) => ({
  //     ...prevValues,
  //     [rowIndex]: event.target.checked,
  //   }));
  // };

  useEffect(() => {
    // Retrieve all selected row IDs
    const selectedRowIds = Object.keys(selectedRowCheckboxes)
      .filter((key) => selectedRowCheckboxes[key])
      .map((key) => {
        return props.data[key];
      });

    setSelectedMultipleRowId(selectedRowIds);
    if (
      Object.values(selectedRowCheckboxes).filter((val) => val)?.length <= 0 &&
      Boolean(props.setSelectedCheckboxes)
    ) {
      props.setSelectedCheckboxes({});
      setSelectAllRows(false);
    }
  }, [selectedRowCheckboxes]);
  const handleRowCheckboxChange = (event, rowIndex) => {
    const checked = event.target.checked;
    props.handleRowCheckboxChange(checked, rowIndex);
    //props.handleReadyForUploadHeaderCheckboxChange(checked,rowIndex);
    props.handleCheckboxSelection(checked, rowIndex); // handleReadyForUploadHeaderCheckboxChange
    setSelectedRowCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));

    const anyCheckboxSelected = Object.values({
      ...selectedRowCheckboxes,
      [rowIndex]: checked,
    }).some((isChecked) => isChecked);
    props.handleCheckboxSelection(anyCheckboxSelected);

    const rowData = props.data[rowIndex];

    if (rowData) {
      if (props.onRowSelect) {
        setSelectedRowId(rowData._id, rowIndex);
        props.onRowSelect(rowData._id, rowIndex);
      }
    } else {
      return;
    }
    // const isChecked = Object.values(selectedRowCheckboxes).some(
    //   (isChecked) => isChecked,
    // );
    // const isChecked = event.target.checked;
    const isChecked = Object.values(selectedRowCheckboxes).some(
      (isChecked) => isChecked,
    );
    //props.handleCheckboxSelection(isChecked);
    //props.handleAllCheckboxSelection(isChecked)

    props.setSelectedCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: event.target.checked,
    }));
  };

  const isSelected = (id) => selectedRowCheckboxes[id] || false;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const rows = props.data.map((obj, index) => ({ ...obj, rowID: index }));
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props?.rowsPerPage || 10);

  // const displayedRows = rows.slice(
  //    page * rowsPerPage,
  //    (page + 1) * rowsPerPage
  // );
  const displayedRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  const isDataAvailable = props.data.length > 0;
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 530,
          "&::-webkit-scrollbar": {
            width: 8,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "white",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D9D9D9",
            borderRadius: 3,
            maxHeight: 2,
          },
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 800 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {/* {props.showCheckboxHeader &&
                props.selectedDropdownValue !== "Monthly Screening Results" &&
                props.selectedDropdownValue !== "PCP Summary" &&
                props.selectedDropdownValue !== "FTP Summary" &&
                props.selectedDropdownValue !== "Screening Report" &&
                props.selectedDropdownValue !== "SDOH Report" &&
                props.selectedDropdownValue !== "Weekly Scheduler Report" && (
                  <StyledTableCell align="center">
                    {props.selectedDropdownValue !== "Covid Report" ? (
                      <input
                        type="checkbox"
                        checked={selectAllRows}
                        onChange={handleSelectAllRows}
                        style={{
                          width: "20px",
                          height: "18px",
                          marginTop: "8px",
                        }}
                      />
                    ) : (
                      "Report"
                    )}
                  </StyledTableCell>
                )} */}
              {props.header.map((header, index) => {
                const headerName =
                  typeof header.headerName === "string"
                    ? header.headerName
                    : "";
                if (!headerName.trim()) {
                  return props.selectedDropdownValue !== "Covid Report" ? (
                    <StyledTableCell align="center" key={index}>
                      <input
                        type="checkbox"
                        checked={selectAllRows}
                        onChange={handleSelectAllRows}
                        style={{
                          width: "20px",
                          height: "18px",
                          marginTop: "8px",
                          marginRight: "20px",
                        }}
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center">Report</StyledTableCell>
                  );
                }
                if (
                  header.field === "checkboxHeader" &&
                  (props.selectedDropdownValue ===
                    "Monthly Screening Results" ||
                    props.selectedDropdownValue === "PCP Summary" ||
                    props.selectedDropdownValue === "SDOH Report" ||
                    props.selectedDropdownValue === "FTP Summary" ||
                    props.selectedDropdownValue === "Screening Report" ||
                    props.selectedDropdownValue === "Weekly Scheduler Report")
                ) {
                  return null;
                } else {
                  return (
                    <StyledTableCell
                      key={index}
                      sx={{ textAlign: "center" }}
                      onClick={() =>
                        isDataAvailable && handleRequestSort(header.field)
                      }
                    >
                      {isDataAvailable &&
                        // header?.field === "Action" ||
                        (header?.field === "Program" ||
                        header?.field === "City" ? (
                          <TableSortLabel
                            hideSortIcon={true}
                            sx={{
                              cursor: "default",
                              "&:hover": {
                                color: "#fff",
                              },
                              "&:focus": {
                                color: "#fff",
                              },
                            }}
                          >
                            {headerName.split("_").join(" ")}
                          </TableSortLabel>
                        ) : (
                          <StyledTableSortLabel
                            active={orderBy === header.field}
                            direction={orderBy === header.field ? order : "asc"}
                            onClick={() => handleRequestSort(header.field)}
                            hideSortIcon={header?.hidesortable}
                          >
                            {headerName.split("_").join(" ")}
                          </StyledTableSortLabel>
                        ))}
                      {!isDataAvailable && headerName.split("_").join(" ")}
                    </StyledTableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.length === 0 || props.noDataFound ? (
              <StyledTableRow>
                <NoDataTableCell colSpan={props.header.length} align="center">
                  <div className="no-data">No data found</div>
                </NoDataTableCell>
              </StyledTableRow>
            ) : (
              displayedRows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex}
                  style={{
                    backgroundColor:
                      row?.HRA_Status?.props?.value == "Term Expired"
                        ? "rgba(255,0,0,.3)"
                        : "",
                  }}
                >
                  {props?.header?.map((header, columnIndex) => {
                    if (
                      header.field === "checkboxHeader"
                      // &&
                      // props.selectedDropdownValue !==
                      //   "Monthly Screening Results"
                    ) {
                      return (
                        <StyledTableCell key={header.field} align="center">
                          <input
                            type="checkbox"
                            style={{
                              width: "20px",
                              height: "18px",
                            }}
                            checked={isSelected(row.rowID)}
                            onChange={(event) =>
                              handleRowCheckboxChange(event, row.rowID)
                            }
                          />
                        </StyledTableCell>
                      );
                    }
                    //  else if (
                    //   props.selectedDropdownValue ===
                    //   "Monthly Screening Results"
                    // ) {
                    //   return null;
                    // }
                    else {
                      return (
                        <StyledTableCell key={columnIndex} align="center">
                          {header?.field === "Name" ? (
                            <div
                              onClick={() => {
                                openScheduler(row?._id, row?.ID);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {row[header?.field]}
                            </div>
                          ) : (
                            row[header?.field]
                          )}
                        </StyledTableCell>
                      );
                    }
                  })}
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.count > 10 ? (
        <TablePagination
          rowsPerPageOptions={props.rowsPerPageOptions}
          component="div"
          count={props.count} // avoid undefined type warnings
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.onPageChange}
          onRowsPerPageChange={props.onRowsPerPageChange}
        />
      ) : null}
      <SchedulerHistory
        open={isSchedulerOpen}
        onClose={closeScheduler}
        isLoading={isLoadingScheduler}
        uniqueMemberId={uniqueMemberId}
        memId={memId}
      />
    </Box>
  );
}
