import axiosWrapper from "./axiosWrapper";

export const addNewMembers = (data) =>
  axiosWrapper.post("/members/add-member", data);

export const getMemberHistoryByYear = (data) =>
  axiosWrapper.post("/memberHistory/memberHistoryByYear", data);

export const getMemberYears = (id) =>
  axiosWrapper.get(`/members/distinct-years?memberId=${id}`);
