import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Paper,
} from "@mui/material";
import { Doughnut, Pie, Bar, Scatter } from "react-chartjs-2";
import "./coderGraphs.css";
import { useCoderData } from "./useCoderData";
import {
  AssignmentTurnedIn,
  CheckCircle,
  MoveToInbox,
  PresentToAll,
} from "@mui/icons-material";
import { getClientsData, getSchedulersData } from "../../../api/filterApi";
const AdminDougnutChart = lazy(() =>
  import("../../../components/ChartsGraphs/Admin/AdminDougnutChart"),
);
const AdminPieChart = lazy(() =>
  import("../../../components/ChartsGraphs/Admin/AdminPieChart"),
);
const Loader = lazy(() => import("../../../components/Loader/Loader"));
const TopUsers = lazy(() =>
  import("../../../components/ChartsGraphs/Admin/TopUsers"),
);

export default function CoderGraphs({ filter, coder, setGraphFilter }) {
  const [par, setPar] = useState({});
  const [plan, setPlan] = useState("");
  const [planId, setPlanId] = useState("");
  const [year, setYear] = useState("");

  const roleId = sessionStorage.getItem("roleId");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");

  const storedPlanId = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  useEffect(() => {
    setPlanId(storedPlanId || "");
    setYear(storedYear || "");
  }, [storedPlanId, storedYear]);

  useEffect(() => {
    const param = {
      planId: planId || "",
      year: year || "",
      filters: filter || [],
    };
    if (coder !== "" && coder !== undefined) {
      param["coderId"] = coder;
    }
    setPar(param);
    setGraphFilter(false);
  }, [planId, year, filter, coder, setGraphFilter]);

  const { data, loading } = useCoderData(par);
  const {
    progData,
    headerCardsData,
    avgDaysData,
    assignedNotStarted,
    topCoderByAssignedMem,
    topCoderByReviewCount,
  } = data ?? {};

  const headerStats = [
    {
      label: "Charts Completed",
      count: headerCardsData?.completed,
      bgColor: "rgba(52, 168, 83, 0.6)", // Soft green (Positive)
      icon: <CheckCircle sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Charts Assigned",
      count: headerCardsData?.assigned,
      bgColor: "rgba(0, 123, 255, 0.6)", // Soft blue (Positive)
      icon: <AssignmentTurnedIn sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Charts from Clinicians",
      count: headerCardsData?.fromClin,
      bgColor: "rgba(255, 90, 95, 0.9)", //"rgba(255, 99, 71, 0.8)", // Soft red (Negative)
      icon: <MoveToInbox sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Charts to Clinicians",
      count: headerCardsData?.toClin,
      bgColor: "rgba(58, 0, 204, 0.5)",
      icon: <PresentToAll sx={{ height: "45px", width: "45px" }} />,
    },
  ];

  const [draggingItem, setDraggingItem] = useState(null);
  const [positions, setPositions] = useState({
    chart1: { order: 1 },
    chart2: { order: 2 },
    chart3: { order: 3 },
    chart4: { order: 4 },
    // chart5: { order: 5 },
  });

  const handleDragStart = (e, chartId) => {
    setDraggingItem(chartId);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (e, targetChartId) => {
    e.preventDefault();

    // Swap the positions of dragged and target charts
    const updatedPositions = { ...positions };
    const draggedItemOrder = updatedPositions[draggingItem].order;
    updatedPositions[draggingItem].order =
      updatedPositions[targetChartId].order;
    updatedPositions[targetChartId].order = draggedItemOrder;

    setPositions(updatedPositions);
  };

  const [filteredCoder, setFilteredCoder] = useState("All Coder");
  useEffect(() => {
    const getCoder = async () => {
      const cliDt = await getClientsData();
      const currPlanId =
        cliDt.data.data.find((item) => item.plan === storedPlanId)?._id || null;
      const schedResponse = await getSchedulersData({
        planId: currPlanId.trim(),
        roleId: "9",
      });
      const schData = schedResponse?.data?.data || [];
      if (Array.isArray(schData)) {
        const t = schData
          .filter((user) => user.userid && user.firstName && user.lastName) // Filter out empty values
          .filter((user) => user.userid === coder)
          .map((user) => ({
            id: user.userid,
            name: `${user.firstName}`,
          }));
        setFilteredCoder(t[0].name);
      }
    };
    getCoder();
  }, [coder, storedPlanId]);

  return (
    <>
      {!data ? (
        <Suspense fallback={<h2>Loading...</h2>}>
          <Loader />
        </Suspense>
      ) : (
        <Suspense fallback={<h2>Loading...</h2>}>
          <Grid
            container
            spacing={2}
            className="graph-dashboard-container"
            sx={{ paddingBottom: "30px", marginTop: 0 }}
          >
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} className="graph-header-container">
                <Grid container spacing={2}>
                  {headerStats.map((stat, index) => (
                    <Grid item xs={3} key={index}>
                      <Paper
                        className="graph-header-item"
                        sx={{
                          bgcolor: stat.bgColor,
                          borderRadius: "8px",
                          color: "white",
                        }}
                      >
                        <span className="graph-header-icon">{stat.icon}</span>
                        <div className="graph-header-content">
                          <Typography
                            variant="h6"
                            sx={{ fontSize: "1.8rem", lineHeight: "0" }}
                          >
                            {stat.count}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "1rem", lineHeight: "0" }}
                          >
                            {stat.label}
                          </Typography>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {roleId == 8 && (
                <Grid
                  item
                  xs={12}
                  md={4.5}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart1")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart1")}
                  style={{ order: positions.chart1.order }}
                >
                  <Paper className="top-mem-agn" sx={{ borderRadius: "8px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {roleId == 9
                        ? `${firstName}'s Assigned Members`
                        : //: coder ? `${filteredCoder}'s Assigned Members`
                          "Top Coders by Assigned Members"}
                    </Typography>
                    <TopUsers data={topCoderByAssignedMem} />
                  </Paper>
                </Grid>
              )}

              {roleId == 8 && (
                <Grid
                  item
                  xs={12}
                  md={4.5}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart4")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart4")}
                  style={{ order: positions.chart4.order }}
                >
                  <Paper
                    className="top-review-count"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {roleId == 9
                        ? `${firstName}'s Review Count`
                        : //: coder ? `${filteredCoder}'s Review Count`
                          "Top Coders by Review Count"}
                    </Typography>
                    <TopUsers data={topCoderByReviewCount} />
                  </Paper>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                md={3}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart2")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart2")}
                style={{ order: positions.chart2.order }}
              >
                <Paper
                  className="chart-item status-days"
                  sx={{ borderRadius: "8px" }}
                >
                  <Typography variant="h6">
                    {" "}
                    {roleId == 9
                      ? `${firstName}'s Charts Status Average Days`
                      : coder
                      ? `${filteredCoder}'s Charts Status Average Days`
                      : "All Coders Charts Status Average Days"}
                  </Typography>
                  <AdminPieChart data={avgDaysData} />
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                draggable
                onDragStart={(e) => handleDragStart(e, "chart3")}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "chart3")}
                style={{ order: positions.chart3.order }}
              >
                <Paper className="assigned-status" sx={{ borderRadius: "8px" }}>
                  <Typography variant="h6">
                    {" "}
                    {roleId == 9
                      ? `${firstName}'s Members Assigned Status`
                      : coder
                      ? `${filteredCoder}'s Members Assigned Status`
                      : "All Coders Members Assigned Status"}
                  </Typography>
                  <AdminDougnutChart data={assignedNotStarted} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Suspense>
      )}
    </>
  );
}
