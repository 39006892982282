import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { PublicRoutes } from "./unprotectedRoutes";
import { ProtectedRoutes } from "./protectedRoutes";
import { useUserContext } from "../contexts/UserContext";
import { RedirectToHome, RedirectToLogin } from "./redirectPageRouting";
import { getApi } from "../utils/axiosApiWrapper";
// import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";

// to-do : use react-router title setting here
// const setPageTitle = (pageTitle) => {
//   document.title = pageTitle;
// };

export default function AppRoutes() {
  const { hasLoggedIn, setHasLoggedIn } = useUserContext();

  useEffect(() => {
    async function checkUserStatus() {
      const jwtToken = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
      if (jwtToken) {
        const response = await getApi("/users/getMe");
        if (response.status === 200) {
          setHasLoggedIn(true);
          return null;
        }
      }
      setHasLoggedIn(false);
    }

    checkUserStatus();
  }, []);
  return (
    <Routes>
      {PublicRoutes.map((e) => {
        return (
          <Route
            key={e.path}
            exact
            path={e.path}
            element={
              <RedirectToHome moveToHome={hasLoggedIn}>
                {e.component}
              </RedirectToHome>
            }
          />
        );
      })}
      {ProtectedRoutes.map((e) => {
        return (
          <Route
            key={e.path}
            path={e.path}
            element={
              <RedirectToLogin moveToLogin={hasLoggedIn}>
                {e.component}
              </RedirectToLogin>
            }
          />
        );
      })}
    </Routes>
  );
}
