import { camelCase } from "lodash";
import moment from "moment";

export const rearrangeItems = (array, oldindex = 1, newindex = 2) => {
  // refactor - add section rearrange, question rearrange
  let oldValue = array.splice(oldindex, 1)[0];
  array.splice(newindex, 0, oldValue);
  let newArray = array.map((el, index) => ({ ...el, questionId: index + 1 }));
  return newArray;
};

export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*()_\-+=~]).{8,30}$/;

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const isValidEmail = (mail) => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail);
};

export const roleOptions = {
  options: [
    "Admin", //2
    "Coder", //9
    "Lead Coder", //8
    "Lead QA", //6
    "Lead Scheduler", //3
    "QA", //7
    "Scheduler", //4
  ],
};

// export const planOptions = {
//   options: [
//     "Alliant Health Plans",
//     "DEMO",
//     "VPHP",
//     "Health Alliance",
//     "OHP-OPTIMA",
//     "SOUTHWESTERN HEALTH",
//     "Samaritan Health",
//   ],
// };

// export const programOptions = {
//   options: ["ACA", "Commercial", "Exchange", "Medicare", "Medicaid", "Test"],
// };

export const changeDateToUtcIsoString = (dateMoment) => {
  if (moment.isMoment(dateMoment)) {
    return dateMoment.utc().toISOString();
  }
};
