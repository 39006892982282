import React, { useEffect, useState, useContext } from "react";
import MaterialTable from "../../components/MaterialTable";
import Search from "../../components/search";
import SelectDropdown from "../../components/select/select";
import "./scheduler.scss";
import SchedulerHistory from "../../components/Modal/schedulerHistory";
import VphpDialog from "../../components/Dashboard/MemberList/vphp/vphp";
import Box from "@mui/material/Box";
import AlertContext from "../../contexts/AlertContext";
import { useUserContext } from "../../contexts/UserContext";

export default function Scheduler() {
  const { clientData } = useUserContext();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const fields = [
    {
      id: "Action",
      numeric: false,
      label: "View HRA",
      type: "action",
    },
    {
      id: "Program",
      numeric: "false",
      label: "Program",
      type: "text",
    },
    {
      id: "LOB",
      numeric: "false",
      label: "LOB",
      type: "text",
    },
    {
      id: "MemberId",
      numeric: "false",
      label: "Member Id",
      type: "text",
    },
    {
      id: "Name",
      numeric: "false",
      label: "Name",
      type: "text",
    },
    {
      id: "Contact",
      numeric: "false",
      label: "Contact",
      type: "text",
    },
    {
      id: "City",
      numeric: "false",
      label: "City",
      type: "text",
    },
    {
      id: "ScheduleDate",
      numeric: "false",
      label: "Schedule Date",
      type: "text",
    },
    {
      id: "HRAStatus",
      numeric: "false",
      label: "HRA Status",
      type: "text",
    },
    {
      id: "Status",
      numeric: "false",
      label: "Status",
      type: "select",
    },
    {
      id: "VisitType",
      numeric: "false",
      label: "Visit Type",
      type: "text",
    },
    {
      id: "EyeExam",
      numeric: "false",
      label: "Eye Exam",
      type: "text",
    },
    {
      id: "TermDate",
      numeric: "false",
      label: "Term Date",
      type: "text",
    },
    {
      id: "NoOfAttempt",
      numeric: "false",
      label: "No Of Attempt",
      type: "text",
    },
    {
      id: "LastAttempt",
      numeric: "false",
      label: "Last Attempt",
      type: "text",
    },
  ];

  const filterDropdown = ["Identification#", "First name", "Last name"];
  const alertContext = useContext(AlertContext);
  useEffect(() => {}, [searchValue]);

  const handleVphpSelection = (plan, date) => {
    setSelectedPlan(plan);
    setSelectedDate(date);
  };
  const vphpToggleOpen = () => {
    alertContext.setVphpFilterDialog(true);
  };

  const VphpToggleClose = () => {
    alertContext.setVphpFilterDialog(false);
  };

  return (
    <div className="right-content">
      <div className="filter-amtenties">
        <div className="left-amentities"></div>
        <div className="right-amentities">
          <Search searchFun={(searchValue) => setSearchValue(searchValue)} />
        </div>
      </div>
      <MaterialTable
        getFieldsApi="/Scripts/React/public/json/scheduler.json"
        TableBodyFields={fields}
        TableSearchValue={searchValue}
        TableHead="schedulerTableHeads"
      />
    </div>
  );
}
