import { useState, useEffect } from "react";
import {
  getCallLogData,
  getSchedulerDashboardData,
} from "../../../api/graphsApi";
import moment from "moment";

export const useSchedulerData = (param, callLogParam) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState({
    schedulerLoading: true,
    callLogLoading: true,
  });

  // Fetch Scheduler Dashboard Data
  useEffect(() => {
    if (!param?.planId || !param?.year) return;

    const fetchSchedulerData = async () => {
      try {
        setLoading((prev) => ({ ...prev, schedulerLoading: true }));
        const response = await getSchedulerDashboardData(param);
        const DashData = response?.data?.data;

        if (typeof DashData === "object") {
          const headerCardsData = {
            scheduled: DashData["YTD Scheduled"],
            callsCompleted: DashData["Total Call made for the year"],
            refused: DashData["Total Refused"],
            utc: DashData["Total UTC"],
          };

          const schedulersCallCompleted = DashData[
            "Top Schedulers By Call Attempt"
          ].map((scheduler) => ({
            name: `${scheduler.firstName}`,
            //name: `${scheduler.firstName} ${scheduler.lastName}`,
            count: scheduler.totalCallLogCount,
          }));

          const schedulersMemberAssigned = DashData[
            "Top Scheduler By ScheudlerAssigned"
          ].map((scheduler) => ({
            name: `${scheduler.firstName}`,
            //name: `${scheduler.firstName} ${scheduler.lastName}`,
            count: scheduler.totalmember,
          }));

          const colorAssignments = {
            Scheduled: "#00C49F",
            "No VM Setup": "rgb(255,232,185)",
            "Call Back Requested": "rgb(220,252,231)",
            "Left Voicemail": "rgb(233,215,253)",
            "Appointment Cancelled": "rgba(58, 0, 204, 0.5)",
            "Appointment Cancellation": "#FFBB28",
            "Dead Air": "rgb(255,232,185)",
            "F2F Call Back": "rgb(255,204,210)",
            Hospice: "rgb(255,232,185)",
            Ineligible: "rgb(220,252,231)",
            "Line Busy": "rgb(233,215,253)",
            "Mailbox Full": "rgb(255,204,210)",
            "No Show": "#FF8042",
            Rescheduled: "#03a9f4",
            Refusal: "#FFC0CB",
            "Wrong Number": "#ff4500",
            "Caller Disconnected": "#dc143c",
            Deceased: "#4b0082",
            Disconnected: "#a9a9a9",
            "No answer": "#7474c4",
          };

          const groupedCallResult = DashData["Grouped By Call Result"]
            .filter((result) => result.result)
            .map((result, index) => ({
              name: result.result || "Unknown",
              count: result.count,
              color: colorAssignments[result.result],
            }));
          const temp = ["F2F", "Virtual"];
          const col = ["#FFBB28", "#FF8042"];
          const noShowsData = DashData["Total No Show/Cancellations"].map(
            (result, index) => ({
              name: temp[index],
              count: result.count,
              color: col[index],
            }),
          );

          setData((prev) => ({
            ...prev,
            headerCardsData,
            schedulersCallCompleted,
            schedulersMemberAssigned,
            groupedCallResult,
            noShowsData,
          }));
        }
      } catch (error) {
        console.error("Error fetching scheduler data:", error);
      } finally {
        setLoading((prev) => ({ ...prev, schedulerLoading: false }));
      }
    };

    fetchSchedulerData();
  }, [param]);

  // Fetch Call Log Data
  useEffect(() => {
    if (!callLogParam) return;

    const fetchCallLogData = async () => {
      try {
        setLoading((prev) => ({ ...prev, callLogLoading: true }));
        const callLogResponse = await getCallLogData(callLogParam);
        const callLogResponseData = callLogResponse?.data;

        if (typeof callLogResponseData === "object") {
          const ytdScheduled = callLogResponseData.data["YTD Scheduled"];
          const totalCallsMade = callLogResponseData.data["Total Call made"];
          const groupedByCallResult =
            callLogResponseData.data["Grouped By Call Result"];
          const filterBy = callLogParam?.filterBy;

          let totalPeriods;
          let periods = [];

          // Determine the total periods and generate the periods array
          if (filterBy === "last7Days") {
            totalPeriods = 7;
            for (let i = 0; i < totalPeriods; i++) {
              periods.push(moment().subtract(i, "days").dayOfYear());
            }
          } else if (filterBy === "last4Weeks") {
            totalPeriods = 4;
            for (let i = 0; i < totalPeriods; i++) {
              periods.push(moment().subtract(i, "weeks").week());
            }
          } else if (filterBy === "last12Months") {
            totalPeriods = 12;
            for (let i = 0; i < totalPeriods; i++) {
              periods.push(
                moment()
                  .subtract(totalPeriods - i - 1, "months")
                  .month() + 1,
              ); // Adjust for 0-indexing
            }
          }

          const successRates = Array(totalPeriods).fill(0);
          const refusalRates = Array(totalPeriods).fill(0);

          // Populate Success and Refusal Rates
          periods.forEach((period, idx) => {
            const scheduledItem = ytdScheduled.find(
              (item) =>
                (filterBy === "last7Days" && item._id.dayOfYear === period) ||
                (filterBy === "last4Weeks" && item._id.week === period) ||
                (filterBy === "last12Months" && item._id.month === period),
            );

            const totalCalls = totalCallsMade.find(
              (call) =>
                (filterBy === "last7Days" && call.dayOfYear === period) ||
                (filterBy === "last4Weeks" && call.week === period) ||
                (filterBy === "last12Months" && call.month === period),
            );

            if (scheduledItem && totalCalls) {
              const successRate = Math.round(
                (scheduledItem.count / totalCalls.count) * 100,
              );
              successRates[idx] = successRate;
              refusalRates[idx] = 100 - successRate;
            }
          });

          // Initialize the Grouped Call Log object
          const groupedCallLog = {
            weekOrDaysOrMonths: periods, // Ensure ascending order
          };

          // Group call log data by result type
          groupedByCallResult.forEach((item) => {
            if (!groupedCallLog[item.result]) {
              groupedCallLog[item.result] = Array(totalPeriods).fill(0); // Initialize array
            }
            const index = periods.indexOf(
              item.dayOfYear || item.week || item.month,
            );
            if (index !== -1) {
              groupedCallLog[item.result][index] = item.count;
            }
          });

          // Example output structure
          const succRef = {
            weekOrDaysOrMonths: periods, // Ensure ascending order
            success: successRates,
            refusal: refusalRates,
          };

          setData((prev) => ({
            ...prev,
            succRef,
            groupedCallLog,
          }));
        }
      } catch (error) {
        console.error("Error fetching call log data:", error);
      } finally {
        setLoading((prev) => ({ ...prev, callLogLoading: false }));
      }
    };

    fetchCallLogData();
  }, [callLogParam]);

  return { data, loading };
};
