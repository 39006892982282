import { Grid, Divider } from "@mui/material";
import React from "react";
import lodash from "lodash";

import "./DynamicForms.css";

export const DFRuler = () => {
  return (
    <Grid xs={12} item>
      <Divider orientation="horizontal" style={{ backgroundColor: "black" }} />
    </Grid>
  );
};

DFRuler.propTypes = {};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFRulerMemo = React.memo(() => {
  return (
    <Grid xs={12} item>
      <Divider orientation="horizontal" style={{ backgroundColor: "black" }} />
    </Grid>
  );
}, dontReRender);

DFRulerMemo.propTypes = {};
