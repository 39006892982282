// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-content {
  width: calc(100% - 70px);
  background-color: #e3edf4;
  margin-left: 70px;
  height: 100vh;
}
.body-content tr {
  background: var(--mainTheme100);
}
.body-content tr:nth-child(even) {
  background: #fff;
  white-space: nowrap !important;
}
.body-content.active {
  width: 100%;
  margin-left: 0;
}
.body-content.active .header {
  width: 100%;
}

.body.fullWidth-body .body-content {
  width: 100%;
  margin-left: 0;
}
.body.fullWidth-body .header {
  width: 100%;
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/MaterialTable/table.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,yBAAA;EACA,iBAAA;EACA,aAAA;AACF;AAAE;EACE,+BAAA;AAEJ;AADI;EACE,gBAAA;EACA,8BAAA;AAGN;AAAE;EACE,WAAA;EACA,cAAA;AAEJ;AADI;EACE,WAAA;AAGN;;AAKI;EACE,WAAA;EACA,cAAA;AAFN;AAII;EACE,WAAA;EACA,cAAA;AAFN","sourcesContent":[".body-content {\r\n  width: calc(100% - 70px);\r\n  background-color: #e3edf4;\r\n  margin-left: 70px;\r\n  height: 100vh;\r\n  tr {\r\n    background: var(--mainTheme100);\r\n    &:nth-child(even) {\r\n      background: #fff;\r\n      white-space: nowrap !important;\r\n    }\r\n  }\r\n  &.active {\r\n    width: 100%;\r\n    margin-left: 0;\r\n    .header {\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n\r\n//full width body style\r\n.body {\r\n  &.fullWidth-body {\r\n    .body-content {\r\n      width: 100%;\r\n      margin-left: 0;\r\n    }\r\n    .header {\r\n      width: 100%;\r\n      margin-left: 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
