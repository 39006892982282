import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setChangeSuccess } from "../../../GlobalFunction";
import BG from "../../../assets/focuscares_bg.jpg";
import Logo from "../../../assets/focuscares_logo.png";
import Button from "../../../components/button/button";
import Image from "../../../components/image/image";
import Input from "../../../components/input/input";
import Toaster from "../../../components/toaster/toaster";
import { passwordRegex } from "../../../components/utils";
import { useSession } from "../../../contexts/SessionContext";
import { useUserContext } from "../../../contexts/UserContext";
import Labels from "../../../resource_files/Labels.resx";
import ValidationMessage from "../../../resource_files/ValidationMessage.resx";
import { postApi } from "../../../utils/axiosApiWrapper";
import "../../OTP/OTPScreen.css";

const NewPassword = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterMessage, setToasterMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setshowNewPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(<Visibility />);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(
    <Visibility />,
  );
  const { setChangeSessionExpired } = useSession();
  const { username } = useUserContext();
  const roleName = sessionStorage.getItem("roleName");

  const passwordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  const confirmPasswordToggle = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleCancelClick = () => {
    let dashboardRoute;

    switch (roleName) {
      case "Admin":
        dashboardRoute = `/AdminDashboard`;
        break;
      case "Scheduler":
        dashboardRoute = `/SchedulerDashboard`;
        break;
    }

    navigate(dashboardRoute);
  };

  useEffect(() => {
    // Function to check for session expiration
    const checkSessionExpiration = () => {
      setChangeSessionExpired(true);
      document.cookie = "pageReloaded=true; path=/"; //set cookie
    };

    // Check session expiration when the component mounts
    checkSessionExpiration();

    const beforeUnloadHandler = (e) => {
      checkSessionExpiration();
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [setChangeSessionExpired]);

  useEffect(() => {
    if (!confirmPasswordVisible) {
      setConfirmPasswordIcon(<VisibilityOff />);
    } else {
      setConfirmPasswordIcon(<Visibility />);
    }
  }, [confirmPasswordVisible]);

  const newPasswordChangeHandler = (e) => {
    const inputValue = e.target.value;
    setnewPassword(inputValue);

    if (!inputValue) {
      setError((prevError) => ({
        ...prevError,
        newPassword: ValidationMessage.Password_Required,
      }));
    } else if (inputValue.length < 8) {
      setError((prevError) => ({
        ...prevError,
        newPassword: ValidationMessage.Password_Length,
      }));
    } else if (!passwordRegex.test(inputValue)) {
      setError((prevError) => ({
        ...prevError,
        newPassword: ValidationMessage.Password_Format,
      }));
    } else {
      setError((prevError) => ({ ...prevError, newPassword: "" }));
    }
  };

  const oldPasswordChangeHandler = (e) => {
    const inputValue = e.target.value;
    setOldPassword(inputValue);

    if (!inputValue) {
      setError((prevError) => ({
        ...prevError,
        oldPassword: ValidationMessage.Password_Required,
      }));
    } else {
      setError((prevError) => ({ ...prevError, oldPassword: "" }));
    }
  };

  const confirmPasswordChangeHandler = (e) => {
    const inputValue = e.target.value;

    setConfirmPassword(inputValue);

    if (!inputValue) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Required,
      }));
    } else if (inputValue.length < 8) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Length,
      }));
    } else if (inputValue !== newPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Mismatch,
      }));
    } else {
      setError((prevError) => ({ ...prevError, confirmPassword: "" }));
    }
  };

  useEffect(() => {
    if (!passwordVisible) {
      setPasswordIcon(<VisibilityOff />);
    } else {
      setPasswordIcon(<Visibility />);
    }
  }, [passwordVisible]);

  const handleSubmit = async () => {
    setError({});

    if (!newPassword) {
      setError((prevError) => ({
        ...prevError,
        newPassword: ValidationMessage.Password_Required,
      }));
      return;
    } else if (newPassword.length < 8) {
      setError((prevError) => ({
        ...prevError,
        newPassword: ValidationMessage.Password_Length,
      }));
      return;
    } else if (!passwordRegex.test(newPassword)) {
      setError((prevError) => ({
        ...prevError,
        newPassword: ValidationMessage.Password_Format,
      }));
      return;
    }

    if (!confirmPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Required,
      }));
      return;
    } else if (confirmPassword.length < 8) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Length,
      }));
      return;
    } else if (newPassword !== confirmPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Mismatch,
      }));
      return;
    }

    if (!oldPassword) {
      setError((prevError) => ({
        ...prevError,
        oldPassword: ValidationMessage.Password_Required,
      }));
      return;
    }
    try {
      const response = await postApi("/users/updatePassword/change", {
        // email: username,
        email: sessionStorage.getItem("emailId"),
        otp: sessionStorage.getItem("OTP"),
        password: oldPassword,
        newPassword: newPassword,
      });
      if (response.status == 200) {
        setToasterSeverity(Labels.SUCCESS);
        setChangeSuccess(true);
        navigate(`/AdminDashboard/MemberList`);
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response.response.data.message);
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS); // Reset severity for the next toaster
    setToasterMessage("");
  };
  const [confirmPasswordShowPassword, setConfirmPasswordShowPassword] =
    useState(false); // Separate state for confirm password field

  const togglePasswordVisibility = () => {
    setshowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="login">
      <div className="top-ripple"></div>
      <div className="bottom-ripple"></div>
      <div className="image-container" style={{ position: "relative" }}>
        <Image imgClass="bgImg" imgPath={BG} imgName="Focuscare Background" />
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            backgroundColor: "rgba(237, 142, 34, .8)",
            height: "auto",
            padding: "2px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2 style={{ color: "#fff", fontSize: "2.5rem", fontWeight: 500 }}>
            HEALTHCARE MADE PERSONAL
          </h2>
        </div>
      </div>
      <div className="input-container">
        <Image imgClass="logoImg" imgPath={Logo} imageName="Focuscare Logo" />
        <div className="main-container">
          <h3 className="password-container">Old Password</h3>
          <div>
            <Input
              type={showOldPassword ? "text" : "password"}
              inputtype="iconInput"
              onChangeHandler={oldPasswordChangeHandler}
              value={oldPassword}
              name="Old Password"
              inputIcon={<LockIcon />}
              inputEndIcon={
                <IconButton onClick={toggleOldPasswordVisibility}>
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleOldPasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              mb="40px"
              placeholder="Old Password"
              inputWidth="235px"
              width="100%"
              height="55px"
              // error={error.password || passwordError}
            />
            <h3
              className="password"
              style={{
                color: "#d32f2f",
                lineHeight: "14px",
                width: "400px",
                marginTop: "-42px",
              }}
            >
              {error.oldPassword || passwordError}
            </h3>
          </div>

          <h3 className="password-container">New Password</h3>
          <div>
            <Input
              type={showNewPassword ? "text" : "password"}
              inputtype="iconInput"
              onChangeHandler={newPasswordChangeHandler}
              value={newPassword}
              name="New Password"
              inputIcon={<LockIcon />}
              inputEndIcon={
                <IconButton onClick={togglePasswordVisibility}>
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              mb="40px"
              placeholder="New Password"
              inputWidth="235px"
              width="100%"
              height="55px"
              // error={error.password || passwordError}
            />
            <h3
              className="password"
              style={{
                color: "#d32f2f",
                lineHeight: "14px",
                width: "400px",
                marginTop: "-42px",
              }}
            >
              {error.newPassword || passwordError}
            </h3>
          </div>
          <h3 className="password-container">Confirm Password</h3>
          <div>
            <Input
              type={confirmPasswordShowPassword ? "text" : "password"}
              onChangeHandler={confirmPasswordChangeHandler}
              inputtype="iconInput"
              value={confirmPassword}
              name="Confirm Password"
              inputIcon={<LockIcon />}
              inputEndIcon={
                <IconButton onClick={toggleConfirmPasswordVisibility}>
                  {confirmPasswordShowPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleConfirmPasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              mb="40px"
              placeholder="Confirm Password"
              inputWidth="235px"
              width="100%"
              height="55px"
              // error={error.confirmPassword}
            />
            <h3
              className="password"
              style={{
                color: "#d32f2f",
                lineHeight: "14px",
                width: "400px",
                marginTop: "-42px",
              }}
            >
              {error.confirmPassword}
            </h3>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <Button
              // type="submit"
              onClick={handleSubmit}
              width="200px"
              mb="20px"
              height="40px"
              color="white"
              background="#1076BC"
              cursor="pointer"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              SUBMIT
            </Button>
            <Button
              width="200px"
              onClick={handleCancelClick}
              cursor="pointer"
              mb="20px"
              height="40px"
              color="white"
              background="#1076BC"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
};

export default NewPassword;
