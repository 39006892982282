import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { isValidUrl } from "../../../../../utils/appConstants";
import "./DynamicForms.css";

export const DFImage = ({ inputProps }) => {
  if (!inputProps?.src) {
    return (
      <div
        style={{
          width: "50%",
          marginTop: "1%",
          marginBottom: "1%",
          marginLeft: "1%",
        }}
      >
        <p>Image URL is not added</p>
      </div>
    );
  }

  const isImageURL = isValidUrl(inputProps.src);
  if (!isImageURL) {
    return (
      <div
        style={{
          width: "50%",
          marginTop: "1%",
          marginBottom: "1%",
          marginLeft: "1%",
        }}
      >
        <p>Invalid src</p>
      </div>
    );
  }

  return (
    <Grid xs={inputProps.xs}>
      <div
        style={{
          width: "50%",
          marginTop: "2%",
          marginBottom: "1%",
          marginLeft: "1%",
        }}
      >
        <img
          src={inputProps.src}
          alt={inputProps.alt}
          style={{
            width: "100%",
            height: "60px",
          }}
        />
      </div>
    </Grid>
  );
};

DFImage.propTypes = {
  inputProps: PropTypes.object.isRequired,
};
