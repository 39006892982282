import {
  Grid,
  Stack,
  InputLabel,
  TextField,
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import Loader from "../../../../Loader/Loader";
import toast from "react-hot-toast";
//import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import focusCaresLoader from "../../../../../loader/focuscares_loader.gif";
import LoadingOverlay from "react-loading-overlay";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
//import { putApi, getApi } from "../../../../utils/axiosApiWrapper";
import { getApi, putApi } from "../../../../../utils/axiosApiWrapper";
import Fade from "@mui/material/Fade";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import Labels from "../../../../../resource_files/Labels.resx";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
//import DynamicTableAllergies from "../Fill_HRA/DFInputs/DFDataTable/DynamicTableAllergies";
import { DynamicTableAllergies } from "../DFInputs/DFDataTable";
import usePedsFormAssessmentInfo from "../peds-form-assessmentInfo.hooks";
//import PedsDynamicTableCounterMedications from "../dynamic.table.pedsform";
import {
  PedsDynamicTableCounterMedications,
  frequencyOptions,
  routeOptions,
  prescriptionStatus,
} from "./dynamic.table.pedsform";
import { useLocation } from "react-router-dom";
import DebounceTextField from "./debounceTextField";
const PedsAssessmentInfoComp = forwardRef((props, ref) => {
  const { assessmentId } = props.assessmentId;

  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  console.log(
    "calculateFlag",
    (roleId == 5 && tabId == 3) ||
      (roleId == 8 && tabId == 4) ||
      roleId == 6 ||
      roleId == 8 ||
      roleId == 7 ||
      roleId == 9,
  );
  const {
    pedsAssessmentInfoJsonData,

    allergiesRowData,
    setAllergiesRowData,
    selectedSubQuestion,
    setSelectedSubQuestions,
    answeredJson,
    setAnsweredJson,

    selectedIdx,
    setSelectedIdx,

    selectedDescribeFirstLevel,
    setSelectedDescribeFirstLevel,
    selectedIdxDescribeFirstLevel,
    selSelectedIdxDescribeFirstLevel,
    firstLevelCommentObj,
    setFirstLevelCommentObj,
    counterMedicationsRowData,
    setCounterMedicationsRowData,
    counterMedicationsRowDataQues15,
    setCounterMedicationsRowDataQues15,
    isLoading,
    setIsLoading,
    selected2ndLevelSubQuestion,
    setSelected2ndLevelSubQuestion,
    secondLevelTextFieldAnswer,
    setSecondLevelTextFieldAnswer,
    secondLevelComment,
    setSecondLevelComment,
    secondLevelCommentObj,
    setSecondLevelCommentObj,
    getAssessmentInfoData,
    storedYear,
    onFirstLevelCommentIconClick,
    createFirstLevelCommentObj,
    updateAnsweredJsonForTextField,
    updateAnsweredJson,
    onSecondLevelCommentIconClick,
    updateSubAnswerForToggleButton,
    createSecondLevelCommentObj,
    updateSubAnswerForTextField,
    update2ndLevelAnsweredJsonForTextField,
  } = usePedsFormAssessmentInfo(assessmentId);

  // _secondLevelComment
  // __createSecondLevelCommentObj(questionName, inputValue)

  // _selectedDescribeFirstLevel = {
  //   ..._selectedDescribeFirstLevel,
  //   [question.questionId - 1]: true,
  // };
  // _selectedIdxDescribeFirstLevel = [
  //   ..._selectedIdxDescribeFirstLevel,
  //   question.questionId - 1,
  // ];
  // setSelectedDescribeFirstLevel({
  //   ...selectedDescribeFirstLevel,
  //   ..._selectedDescribeFirstLevel,
  // });
  // selSelectedIdxDescribeFirstLevel([
  //   ...selectedIdxDescribeFirstLevel,
  //   ..._selectedIdxDescribeFirstLevel,
  // ]);
  // _createFirstLevelCommentObj(question.questionId - 1, answerObj.comment);

  let payload = {};
  const getpayloadByQuestionId = (questionNumber, answerObj) => {
    if (
      questionNumber == 1 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 1)
    ) {
      payload = {
        ...payload,
        legalGuradian: {
          legalGuradianPermission: answerObj?.legalGuradian
            ? answerObj?.legalGuradian
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.legalGuradian == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 2 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 2)
    ) {
      payload = {
        ...payload,
        legalRepresentative: {
          authorizedLegalRepresentative: answerObj?.legalRepresentative
            ? answerObj?.legalRepresentative
            : "",
          //  == "yes"
          //   ? "1"
          //   : answerObj.legalRepresentative == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 3 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 3)
    ) {
      payload = {
        ...payload,
        fosterCare: {
          fosterCareProgram: answerObj?.fosterCare ? answerObj?.fosterCare : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.fosterCare == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 4 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 4)
    ) {
      payload = {
        ...payload,
        preferredLanguage: {
          english: answerObj?.preferredLanguage == "english" ? "1" : "2", //"Y or N",
          other: answerObj?.preferredLanguage == "other" ? "1" : "2", //"Y or N",
          otherSubAnswer: {
            africanLanguages:
              answerObj?.demograph_prefLangOtherSub == "africanLanguages"
                ? "1"
                : "2",
            arabic:
              answerObj?.demograph_prefLangOtherSub == "arabic" ? "1" : "2",
            chinese:
              answerObj?.demograph_prefLangOtherSub == "chinese" ? "1" : "2",
            french:
              answerObj?.demograph_prefLangOtherSub == "french" ? "1" : "2",
            frenchCreole:
              answerObj?.demograph_prefLangOtherSub == "frenchCreole" //"frenchCrelo"
                ? "1"
                : "2",
            german:
              answerObj?.demograph_prefLangOtherSub == "german" ? "1" : "2",
            greek: answerObj?.demograph_prefLangOtherSub == "greek" ? "1" : "2",
            gujarati:
              answerObj?.demograph_prefLangOtherSub == "gujarati" ? "1" : "2",
            hebrew:
              answerObj?.demograph_prefLangOtherSub == "hebrew" ? "1" : "2",
            hindi: answerObj?.demograph_prefLangOtherSub == "hindi" ? "1" : "2",
            hungarian:
              answerObj?.demograph_prefLangOtherSub == "hungarian" ? "1" : "2",
            italian:
              answerObj?.demograph_prefLangOtherSub == "italian" ? "1" : "2",
            japanese:
              answerObj?.demograph_prefLangOtherSub == "japanese" ? "1" : "2",
            korean:
              answerObj?.demograph_prefLangOtherSub == "korean" ? "1" : "2",
            persian:
              answerObj?.demograph_prefLangOtherSub == "persian" ? "1" : "2",
            polish:
              answerObj?.demograph_prefLangOtherSub == "polish" ? "1" : "2",
            portuguese:
              answerObj?.demograph_prefLangOtherSub == "portuguese" ? "1" : "2",
            russian:
              answerObj?.demograph_prefLangOtherSub == "russian" ? "1" : "2",
            scandinavianLanguages:
              answerObj?.demograph_prefLangOtherSub == "scandinavianLanguages"
                ? "1"
                : "2",
            serboCroatian:
              answerObj?.demograph_prefLangOtherSub == "serboCroatian"
                ? "1"
                : "2",
            spanish:
              answerObj?.demograph_prefLangOtherSub == "spanish" ? "1" : "2",
            comment: "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 5 ||
      (firstLevelCommentObj[questionNumber - 1] && questionNumber == 5)
    ) {
      payload = {
        ...payload,
        race: {
          caucasian: answerObj?.race == "caucasian" ? "1" : "2",
          africanAmerican: answerObj?.race == "africanAmerican" ? "1" : "2",
          asian: answerObj?.race == "asian" ? "1" : "2",
          latino: answerObj?.race == "latino" ? "1" : "2",
          nativeAmerican: answerObj?.race == "nativeAmerican" ? "1" : "2",
          nativeHawaiian: answerObj?.race == "nativeHawaiian" ? "1" : "2",
          alaskanNative: answerObj?.race == "alaskanNative" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 7 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 7)
    ) {
      payload = {
        ...payload,
        childExperienced: {
          childWitnessedEvent: answerObj?.childExperienced
            ? answerObj?.childExperienced
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj?.childExperienced == "no"
          //   ? "2"
          //   : "",
          childWitnessedEventYesSubAnswer: {
            event: {
              carAccident:
                answerObj?.Whatwastheevent == "carAccident" ? "1" : "2", //"Y or N",
              fire: answerObj?.Whatwastheevent == "fire" ? "1" : "2", //"Y or N",
              storm: answerObj?.Whatwastheevent == "storm" ? "1" : "2", //"Y or N",
              physicalIllness:
                answerObj?.Whatwastheevent == "physicalIllness" ? "1" : "2", //"Y or N",
              sexualAssualt:
                answerObj?.Whatwastheevent == "sexualAssualt" ? "1" : "2", //"Y or N",
              comment: secondLevelCommentObj.Whatwastheevent
                ? secondLevelCommentObj.Whatwastheevent
                : "",
            },
            describe: answerObj?.describe ? answerObj?.describe : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 8 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 8)
    ) {
      payload = {
        ...payload,
        memberAgeEvent: {
          specialAge: answerObj?.memberAgeEvent
            ? answerObj?.memberAgeEvent
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 9 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 9)
    ) {
      payload = {
        ...payload,
        childReportComplaints: {
          notTrue: answerObj?.childReportComplaints == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childReportComplaints == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childReportComplaints == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue:
            answerObj?.childReportComplaints == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 10 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 10)
    ) {
      payload = {
        ...payload,
        childAvoidsThings: {
          notTrue: answerObj?.childAvoidsThings == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childAvoidsThings == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childAvoidsThings == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childAvoidsThings == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 11 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 11)
    ) {
      payload = {
        ...payload,
        childStartlesEasily: {
          notTrue: answerObj?.childStartlesEasily == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childStartlesEasily == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childStartlesEasily == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childStartlesEasily == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 12 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 12)
    ) {
      payload = {
        ...payload,
        childGetsUpset: {
          notTrue: answerObj?.childGetsUpset == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue: answerObj?.childGetsUpset == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childGetsUpset == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childGetsUpset == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 13 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 13)
    ) {
      payload = {
        ...payload,
        childMedicine: {
          childMedicinePrescribed: answerObj?.childMedicine
            ? answerObj?.childMedicine
            : "", //== "yes" ? "1" : "2",
          childMedicinePrescribedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "1" : "2", //"Y or N",
              comment:
                secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  ? secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 15 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 15)
    ) {
      payload = {
        ...payload,
        counterMedications: {
          supplements: answerObj?.counterMedications
            ? answerObj?.counterMedications
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.counterMedications == "no"
          //   ? "N"
          //   : "", //"Y or N",
          supplementsYesSubAnswer: counterMedicationsRowDataQues15?.map(
            (row) => {
              return {
                prescription: row?.description || "",
                doseUnits: row?.doseOrUnits || "",
                route: row?.route || "",
                reason: row?.reasons || "",
                status: row?.status || "",
                frequency: row?.frequency || "",
                // comment: "",
              };
            },
          ),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 16 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 16)
    ) {
      payload = {
        ...payload,
        childNeedMedicalCare: {
          childMedicalCare: answerObj?.childNeedMedicalCare
            ? answerObj?.childNeedMedicalCare
            : "", //== "yes" ? "Y" : "N",
          childMedicalCareYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.medical_care_condition_that_has_lasted
                  ? secondLevelCommentObj.medical_care_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 17 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 17)
    ) {
      payload = {
        ...payload,
        childAbility: {
          childAbilityLimited: answerObj?.childAbility
            ? answerObj?.childAbility
            : "", // == "yes" ? "Y" : "N",
          childAbilityLimitedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.childAbility_condition_that_has_lasted
                  ? secondLevelCommentObj.childAbility_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 18 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 18)
    ) {
      payload = {
        ...payload,
        childNeed: {
          childNeedTherapy: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          childNeedTherapyYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment: secondLevelCommentObj.childNeed_condition_that_has_lasted
                ? secondLevelCommentObj.childNeed_condition_that_has_lasted
                : "",
            },
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 19 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 19)
    ) {
      payload = {
        ...payload,
        childDevelopment: {
          childDevelopmentCounseling: answerObj?.childDevelopment
            ? answerObj?.childDevelopment
            : "", //== "yes" ? "Y" : "N",
          childDevelopmentCounselingYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 20 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 20)
    ) {
      payload = {
        ...payload,
        childReceiveSupport: {
          nursingCare:
            answerObj?.childReceiveSupport == "nursingCare" ? "1" : "2",
          personalCareAttendant:
            answerObj?.childReceiveSupport == "personalCareAttendant"
              ? "1"
              : "2",
          homeHealthAide:
            answerObj?.childReceiveSupport == "homeHealthAide" ? "1" : "2",
          no: answerObj?.childReceiveSupport == "no" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 21 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 21)
    ) {
      payload = {
        ...payload,
        childMedicalCheckup: {
          childMedicalCheckup: answerObj?.childMedicalCheckup
            ? answerObj?.childMedicalCheckup
            : "",
          //  == "Y"
          //   ? "1"
          //   : answerObj.childMedicalCheckup == "N"
          //   ? "2"
          //   : answerObj.childMedicalCheckup == "DoesnotKnow"
          //   ? "3"
          //   : "", //  "Y or N or DoesnotKnow",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 22 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 22)
    ) {
      payload = {
        ...payload,
        childsHeightWeight: {
          childsHeightWeight: answerObj?.childsHeightWeight
            ? answerObj?.childsHeightWeight
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.childsHeightWeight == "no"
          //   ? "N"
          //   : "", //or N",
          childsHeightWeightYesSubAnswer: {
            childsHeight: {
              feet:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightfeet
                    ? answerObj?.childsHeightfeet
                    : ""
                  : "", //"",
              inch:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightInches
                    ? answerObj?.childsHeightInches
                    : ""
                  : "",
            },
            childsWeight:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.childsWeight
                  ? answerObj?.childsWeight
                  : ""
                : "",
            calculateBmi:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.calculateBmi
                  ? answerObj?.calculateBmi
                  : ""
                : "",
          },
        },
      };
    } else if (
      questionNumber == 23 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 23)
    ) {
      payload = {
        ...payload,
        describeChildsWeight: {
          childsWeight: answerObj?.describeChildsWeight
            ? answerObj?.describeChildsWeight
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 24 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 24)
    ) {
      payload = {
        ...payload,
        femaleChildren: {
          yes: answerObj?.femaleChildren == "yes" ? "1" : "2", // "Y or N",
          no: answerObj?.femaleChildren == "no" ? "1" : "2", //"Y or N",
          doesnotKnow: answerObj?.femaleChildren == "doesnotKnow" ? "1" : "2", //"Y or N","N/A"
          na: answerObj?.femaleChildren == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 25 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 25)
    ) {
      payload = {
        ...payload,
        enoughFoodForFamily: {
          never: answerObj?.enoughFoodForFamily == "never" ? "1" : "2", //"Y or N",
          sometimes: answerObj?.enoughFoodForFamily == "sometimes" ? "1" : "2", //"Y or N",
          always: answerObj?.enoughFoodForFamily == "always" ? "1" : "2", //"Y or N",
          declineToAnswer:
            answerObj?.enoughFoodForFamily == "declineToAnswer" ? "1" : "2",
          // na: answerObj?.enoughFoodForFamily == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 26 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 26)
    ) {
      payload = {
        ...payload,
        communityResources: {
          communityResourcesAvail: answerObj?.communityResources
            ? answerObj?.communityResources
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.communityResources == "no"
          //   ? "N"
          //   : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 27 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 27)
    ) {
      payload = {
        ...payload,
        childHaveAnyConditions: {
          asthma: answerObj?.childHaveAnyConditions?.includes("asthma")
            ? "1"
            : "2", //Y or N",
          asthmaSubAnswer: {
            childSeeASpecialist: answerObj?.childSeeASpecialist
              ? answerObj?.childSeeASpecialist
              : "",
            // == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "", //"Y or N",
            childSeeASpecialistYesSubAnswer: {
              yesName: secondLevelTextFieldAnswer["ifYesName"]
                ? secondLevelTextFieldAnswer["ifYesName"]
                : "", //"",
            },
            comment: secondLevelCommentObj.childSeeASpecialist
              ? secondLevelCommentObj.childSeeASpecialist
              : "", //"",
          },
          diabetes: answerObj?.childHaveAnyConditions?.includes("diabetes")
            ? "1"
            : "2", //"Y or N",
          diabetesSubAnswer: {
            childsBloodSugar: answerObj?.childsBloodSugar
              ? answerObj?.childsBloodSugar
              : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "",
            comment: secondLevelCommentObj?.childsBloodSugar
              ? secondLevelCommentObj?.childsBloodSugar
              : "",
          },
          sickleCellDisease: answerObj?.childHaveAnyConditions?.includes(
            "sickleCellDisease",
          )
            ? "1"
            : "2", //"Y or N",
          sickleCellDiseaseSubAnswer: {
            hgbAonec: answerObj?.hgbAonec ? answerObj?.hgbAonec : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.hgbAonec == "no"
            //   ? "2"
            //   : "", //"Y or N",
            hgbAonecYesSubAnswer: {
              lastHgbAonec: secondLevelTextFieldAnswer["ifYesHgbA1c"]
                ? secondLevelTextFieldAnswer["ifYesHgbA1c"]
                : "", //"",
            },
            comment: secondLevelCommentObj?.hgbAonec
              ? secondLevelCommentObj?.hgbAonec
              : "",
          },
          hemophilia: answerObj?.childHaveAnyConditions?.includes("hemophilia")
            ? "1"
            : "2", //"Y or N",
          hemophiliaSubAnswer: {
            specialDiet: answerObj?.specialDiet ? answerObj?.specialDiet : "",
            specialDietYesSubAnswer: {
              yesDiet: secondLevelTextFieldAnswer["ifYesDiet"]
                ? secondLevelTextFieldAnswer["ifYesDiet"]
                : "", //"",,
            },
            comment: secondLevelCommentObj?.specialDiet
              ? secondLevelCommentObj?.specialDiet
              : "",
          },
          ddAdhd: answerObj?.childHaveAnyConditions?.includes("ddAdhd")
            ? "1"
            : "2", //"Y or N",
          substanceUse: answerObj?.childHaveAnyConditions?.includes(
            "substanceUse",
          )
            ? "1"
            : "2", //"Y or N",
        },
      };
    } else if (
      questionNumber == 28 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 28)
    ) {
      payload = {
        ...payload,
        additionalInformation: {
          additionalInformationChild: answerObj?.additionalInformation
            ? answerObj?.additionalInformation
            : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      storedYear == "2024" &&
      (questionNumber == 29 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 29))
    ) {
      payload = {
        ...payload,
        patientReligiousBelief: {
          isPatientReligiousBelief: answerObj?.patientReligiousBelief
            ? answerObj?.patientReligiousBelief
            : "", //: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          isPatientReligiousBeliefSubAnswer: {
            describe: answerObj?.spiritual_beliefs
              ? answerObj?.spiritual_beliefs
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      questionNumber == 14 &&
      (counterMedicationsRowData?.length >= 1 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 14))
    ) {
      payload = {
        ...payload,
        medication: {
          prescriptionMedicationList: counterMedicationsRowData?.map((row) => {
            return {
              prescription: row?.description || "",
              doseUnits: row?.doseOrUnits || "",
              route: row?.route || "",
              reason: row?.reasons || "",
              status: row?.status || "",
              frequency: row?.frequency || "",
              comment: firstLevelCommentObj[questionNumber]
                ? firstLevelCommentObj[questionNumber]
                : "",
            };
          }),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      (allergiesRowData?.length >= 1 && questionNumber == 6) ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 6)
    ) {
      const childAllergiesYesSubAnswer = allergiesRowData?.map((allergies) => {
        const obj = {
          substance: allergies?.substance ? allergies?.substance : "",
          reaction: allergies?.reaction ? allergies?.reaction : "",
        };
        return obj;
      });
      payload = {
        ...payload,
        allergies: {
          childAllergies: answerObj?.allergies ? answerObj?.allergies : "", //"1",
          childAllergiesYesSubAnswer:
            answerObj?.allergies == "1" ? childAllergiesYesSubAnswer : [],
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
  };
  const onSaveData = async () => {
    //Object.keys(answeredJson).forEach((key) => {

    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29,
    ].forEach((key) => {
      getpayloadByQuestionId(Number(key), answeredJson[key]);
    });
    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Assessment Information",
          data: payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Error saving data", {});
      }
    } catch (error) {}
  };
  // useEffect(() => {
  //   const id = setInterval(() => {
  //  if( (roleId == 5 && tabId != 3) || roleId==1 || roleId == 2){
  //     onSaveData();
  //    }
  //   }, 60000);
  //   return () => {
  //     clearInterval(id);
  //   };
  // }, [
  //   answeredJson,
  //   allergiesRowData,
  //   firstLevelCommentObj,
  //   counterMedicationsRowData,
  //   counterMedicationsRowDataQues15,
  //   secondLevelCommentObj,
  //   secondLevelTextFieldAnswer,
  // ]);

  useImperativeHandle(ref, () => ({
    onSaveData: () => {
      onSaveData();
    },
  }));

  useEffect(() => {
    props?.setAutoSavePedsAssessInfo(answeredJson);
    props?.setAutoSavePedsAllergiesRowData(allergiesRowData);
    props?.setAutoSavePedsFirstLevelCommentObj(firstLevelCommentObj);
    props?.setAutoSavePedsCounterMedicationsRowData(counterMedicationsRowData);
    props?.setAutoSavePedsSecondLevelTextFieldAnswer(
      secondLevelTextFieldAnswer,
    );
    props?.setAutoSavePedsCounterMedicationsRowDataQues15(
      counterMedicationsRowDataQues15,
    );
    props?.setAutoSavePedsSecondLevelCommentObj(secondLevelCommentObj);
  }, [
    answeredJson,
    allergiesRowData,
    firstLevelCommentObj,
    counterMedicationsRowData,
    counterMedicationsRowDataQues15,
    secondLevelCommentObj,
    secondLevelTextFieldAnswer,
  ]);

  const [childsHeightFeet, setChildHeightFeet] = useState();
  const [childsHeightInches, setChildHeightInches] = useState();
  const [childsWeight, setChildsWeight] = useState();
  //setChildHeightFeet,setChildHeightInches,setChildsWeight
  useEffect(() => {
    if (childsHeightFeet && childsHeightInches && childsWeight) {
      const heightInMeters =
        Number(childsHeightFeet) * 0.3048 + Number(childsHeightInches) * 0.0254;
      const weightInKilograms = Number(childsWeight) * 0.453592;
      const bmi = (weightInKilograms / Math.pow(heightInMeters, 2)).toFixed(2);

      const _subAnswerObj = {
        ...answeredJson[22],
        calculateBmi: bmi,
      };
      answeredJson[22] = _subAnswerObj;
      setAnsweredJson({ ...answeredJson, 22: _subAnswerObj });
    }
  }, [childsHeightFeet, childsHeightInches, childsWeight]);

  return (
    <LoadingOverlay
      styles={{
        content: { marginLeft: "40vw", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={isLoading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
    >
      <Box padding={2}>
        {!isLoading &&
          pedsAssessmentInfoJsonData?.[0]?.questions?.map((question, idx) => (
            <>
              <Fade
                in={question?.questionId == 14}
                key={question?.questionId}
                unmountOnExit
              >
                <Box
                  marginTop={2}
                  style={{
                    backgroundColor: "var(--mainTheme850)",
                    borderRadius: "5px",
                  }}
                  paddingX={2}
                >
                  <Typography
                    fontWeight={500}
                    variant="h6"
                    style={{ fontWeight: "700" }}
                  >
                    Medications
                  </Typography>
                </Box>
              </Fade>
              <Box
                display="flex"
                paddingRight={1}
                key={`${question?.label}${question?.questionId}`}
              >
                <Box display="flex" justifyContent="left" width={0.8}>
                  <Typography
                    sx={{ color: "var(--mainTheme)", fontWeight: 700 }}
                  >
                    {question?.label}
                  </Typography>
                </Box>
                <Box
                  width={0.2}
                  display="flex"
                  justifyContent="right"
                  visibility={
                    question?.questionId == 27 || question?.questionId == 22
                      ? "hidden"
                      : "visible"
                  }
                >
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <ChatBubbleIcon
                      disabled={
                        (roleId == 5 && tabId == 3) ||
                        (roleId == 8 && tabId == 4) ||
                        roleId == 6 ||
                        roleId == 8 ||
                        roleId == 7 ||
                        roleId == 9
                      }
                      style={{
                        cursor: "pointer",
                        color: "var(--mainTheme)",
                      }}
                      onClick={() => {
                        // if (disableFlag) {
                        //   return null;
                        // }
                        // toggleCommentBox(
                        //   showComment,
                        //   setShowComment,
                        //   sectionId,
                        //   quesSlug,
                        //   value,
                        // );
                        if (
                          (roleId == 5 && tabId == 3) ||
                          (roleId == 8 && tabId == 4) ||
                          roleId == 6 ||
                          roleId == 8 ||
                          roleId == 7 ||
                          roleId == 9
                        ) {
                          return null;
                        }
                        onFirstLevelCommentIconClick(question?.questionId);
                      }}
                    />
                  </Grid>
                </Box>
              </Box>
              <Box
                style={{ display: "flex", flexWrap: "wrap" }}
                display="flex"
                // justifyContent="space-between"
              >
                <Fade
                  in={question?.type == "datatable"}
                  key={`dataTable${question?.questionId}`}
                  unmountOnExit
                >
                  <Box>
                    <DynamicTableAllergies
                      allergiesRowData={allergiesRowData}
                      setAllergiesRowData={setAllergiesRowData}
                      fromPeds={true}
                      disableFlag={
                        (roleId == 5 && tabId == 3) ||
                        (roleId == 8 && tabId == 4) ||
                        roleId == 6 ||
                        roleId == 8 ||
                        roleId == 7 ||
                        roleId == 9
                      }
                    />
                  </Box>
                </Fade>
                <Fade
                  in={question?.type == "dataTableMedication"}
                  unmountOnExit
                  key={`dataTableLabelMedication${question?.questionId}`}
                >
                  <div>
                    <table
                      style={{
                        margin: "0 auto",
                        borderCollapse: "collapse",
                        border: "1px solid var(--mainTheme)",
                        width: "75vw",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            align="center"
                            style={{
                              border: "1px solid var(--mainTheme)",
                              padding: "8px",
                            }}
                          >
                            Frequency
                          </th>
                          <th
                            align="center"
                            style={{
                              border: "1px solid var(--mainTheme)",
                              padding: "8px",
                            }}
                          >
                            Prescription Status
                          </th>
                          <th
                            align="center"
                            style={{
                              border: "1px solid var(--mainTheme)",
                              padding: "8px",
                            }}
                          >
                            Route
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: "1px solid var(--mainTheme)",
                              padding: "8px",
                              maxWidth: "200px",
                              verticalAlign: "top",
                            }}
                          >
                            <Box
                              width="75%"
                              display="flex"
                              flexWrap={true}
                              sx={{ flexWrap: "wrap" }}
                            >
                              {frequencyOptions?.map((freq) => (
                                <Box paddingX={1}>{freq}</Box>
                              ))}
                            </Box>
                          </td>
                          <td
                            style={{
                              border: "1px solid var(--mainTheme)",
                              padding: "8px",
                              maxWidth: "200px",
                              verticalAlign: "top",
                            }}
                          >
                            <Box width="100%">
                              {prescriptionStatus?.map((status) => (
                                <Box paddingX={1}>{status}</Box>
                              ))}
                            </Box>
                          </td>
                          <td
                            style={{
                              border: "1px solid var(--mainTheme)",
                              padding: "8px",
                              maxWidth: "200px",
                              verticalAlign: "top",
                            }}
                          >
                            <Box width="100%">
                              {routeOptions?.map((option) => (
                                <Box paddingX={1}>{option}</Box>
                              ))}
                            </Box>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Fade>
                <Fade
                  in={question?.type == "dataTableMedication"}
                  unmountOnExit
                  key={`dataTableMedication${question?.questionId}`}
                >
                  <div>
                    <PedsDynamicTableCounterMedications //prescription table questionId 14
                      counterMedicationsRowData={counterMedicationsRowData}
                      setCounterMedicationsRowData={
                        setCounterMedicationsRowData
                      }
                      disabled={
                        (roleId == 5 && tabId == 3) ||
                        (roleId == 8 && tabId == 4) ||
                        roleId == 6 ||
                        roleId == 8 ||
                        roleId == 7 ||
                        roleId == 9
                      }
                    />
                  </div>
                </Fade>
                <Fade in={question?.type == "text"} unmountOnExit>
                  <Box>
                    {/* to display comment above answer field of question number 8 */}
                    <Fade
                      in={
                        selectedDescribeFirstLevel[question?.questionId] &&
                        selectedIdxDescribeFirstLevel.includes(
                          question?.questionId,
                        ) &&
                        [8, 28, 23]?.includes(question?.questionId)
                      }
                      unmountOnExit
                    >
                      <Box display="flex" width={1} margin={1}>
                        <TextField
                          variant="outlined"
                          label="Comment"
                          key={`text${question?.questionId}`}
                          disabled={
                            (roleId == 5 && tabId == 3) ||
                            (roleId == 8 && tabId == 4) ||
                            roleId == 6 ||
                            roleId == 8 ||
                            roleId == 7 ||
                            roleId == 9
                          }
                          // sx={{
                          //   "& .MuiInputLabel-root": {
                          //     backgroundColor: "white",
                          //   },

                          //   marginTop: "20px",
                          //   marginLeft: "10px",
                          // }}
                          sx={{
                            border: "1.5px solid var(--mainTheme) !important",
                            borderRadius: "5px",
                            "& .MuiFormLabel-root": {
                              color: "var(--mainTheme)",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "var(--mainTheme)",
                            },
                            // marginTop: "20px",
                            // marginLeft: "10px",
                          }}
                          value={firstLevelCommentObj[question?.questionId]}
                          onChange={(e) => {
                            createFirstLevelCommentObj(
                              question?.questionId,
                              e?.target?.value,
                            );
                          }}
                          className="inputStyle"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ArrowForwardIcon
                                  sx={{
                                    size: "medium",
                                    color: "var(--mainTheme)",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          maxRows={4}
                          multiline
                          fullWidth
                        />

                        {/* <DebounceTextField
                        disabled={roleId==5 && tabId==3 }
                          onChange={(value) => {
                            createFirstLevelCommentObj(
                              question?.questionId,
                              value,
                            );
                          }}
                          value={firstLevelCommentObj[question?.questionId]}
                          className="inputStyle"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ArrowForwardIcon
                                  sx={{
                                    size: "medium",
                                    color: "var(--mainTheme)",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          maxRows={4}
                          multiline
                        /> */}
                      </Box>
                    </Fade>
                    <Fade
                      in={question?.placeholder}
                      key={`placeholder${question?.placeholder}${question.questionId}`}
                      unmountOnExit
                    >
                      <Box
                        style={{ marginBottom: "-15px", marginLeft: "10px" }}
                      >
                        <Typography variant="body1">
                          {question?.placeholder}
                        </Typography>
                      </Box>
                    </Fade>
                    <Box
                      width="70vw"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      key={`box${question?.questionId}${question?.name}`}
                    >
                      <TextField
                        key={`textfield${question?.questionId}${question?.name}`}
                        variant="outlined"
                        disabled={
                          (roleId == 5 && tabId == 3) ||
                          (roleId == 8 && tabId == 4) ||
                          roleId == 6 ||
                          roleId == 8 ||
                          roleId == 7 ||
                          roleId == 9
                        }
                        fullWidth
                        value={
                          answeredJson[question?.questionId]?.[question?.name]
                        }
                        sx={{
                          border: "1.5px solid var(--mainTheme) !important",
                          borderRadius: "5px",
                          "& .MuiFormLabel-root": {
                            color: "var(--mainTheme)",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "var(--mainTheme)",
                          },
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                        onChange={(e) => {
                          updateAnsweredJsonForTextField(
                            question,
                            //idx,
                            question?.questionId,
                            e.target.value,
                            // value
                          );
                        }}
                      />
                      {/* <DebounceTextField
                      disabled={roleId==5 && tabId==3 }
                        onChange={(value) => {
                          updateAnsweredJsonForTextField(
                            question,
                            question?.questionId,
                            value,
                          );
                        }}
                        value={
                          answeredJson[question?.questionId]?.[question?.name]
                        }
                      /> */}
                    </Box>
                    {question?.id == 8 ? (
                      <Box padding={2} key={question?.id}>
                        <Typography sx={{ color: "var(--mainTheme)" }}>
                          Other Event
                        </Typography>
                        <Typography sx={{ color: "var(--mainTheme)" }}>
                          INSTRUCTIONS: The following is a list of behaviors
                          that describe reactions that children may have
                          following a frightening event. For each item that
                          describes your child NOW or WITHIN THE PAST MONTH,
                          please tell me if it is VERY TRUE or OFTEN TRUE of
                          your child: SOMEWHAT or SOMETIMES TRUE of your child;
                          or NOT TRUE of your child. The term “event” refers to
                          the most stressful experience that you have described
                          above.
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Fade>
                <Box
                  style={{
                    display: "flex",
                    flexBasis:
                      question?.subQuestions?.length <= 2
                        ? "100%"
                        : "undefined",
                    flexWrap:
                      question?.subQuestions?.length >= 4
                        ? "wrap"
                        : "undefined",
                  }}
                >
                  {(question?.type == "options_tree" ||
                    question?.type == "peds-SingleSelectTable_options_tree") &&
                    question?.subQuestions?.map((subQuestion, subQuesIdx) => (
                      <>
                        <Box key={`${subQuesIdx}${subQuestion.value}`}>
                          <ToggleButtonGroup
                            disabled={
                              (roleId == 5 && tabId == 3) ||
                              (roleId == 8 && tabId == 4) ||
                              roleId == 6 ||
                              roleId == 8 ||
                              roleId == 7 ||
                              roleId == 9
                            }
                            fullWidth
                            role="group"
                            size="large"
                            //value={answeredJson[question?.name]}
                            //value={answeredJson[idx]?.answer}

                            value={
                              answeredJson[question?.questionId]?.[
                                question?.name
                              ]
                            }
                            aria-label="text formatting"
                            style={{
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <ToggleButton
                              onClick={() => {
                                updateAnsweredJson(
                                  question,
                                  subQuestion,
                                  //idx,
                                  question?.questionId,
                                  question?.genre,
                                );
                              }}
                              sx={{
                                // minWidth: "230px",
                                overflow: "hidden",
                                display: "inline-block",
                                // whiteSpace: "normal",
                                minHeight: "60px",
                                margin: "12px",
                                width: "230px",
                                textAlign: "center",
                                justifyContent: "center",
                                borderRadius: "5px",
                                fontSize: "15px",
                                color: "black",
                                // margin: "5px 15px 0 0",
                                borderStyle: "none",
                                textTransform: "none",
                                border: `1px solid var(--mainTheme) !important`,
                                flexWrap: "wrap",
                                padding: "5px",
                                fontSize: "0.85rem",
                                "&.Mui-selected ": {
                                  backgroundColor: "var(--mainTheme900)",
                                  color: "white",
                                  border:
                                    "1px solid var(--mainTheme) !important",
                                  fontWeight: "600",
                                  fontSize: "0.85rem",
                                  // textTransform: "uppercase",
                                },
                                "&.Mui-disabled ": {
                                  //backgroundColor: "var(--mainTheme900)",
                                  color: "black",
                                  border:
                                    "1px solid var(--mainTheme) !important",
                                  fontWeight: "600",
                                  fontSize: "0.85rem",
                                  // textTransform: "uppercase",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "var(--mainTheme750)",
                                  color: "white",
                                  border:
                                    "1px solid var(--mainTheme) !important",
                                },
                                "&.MuiToggleButtonGroup-grouped": {
                                  borderRadius: "5px !important",
                                },
                                ":hover": {
                                  backgroundColor: "var(--mainTheme400)",
                                  // textTransform: "uppercase",
                                  // color: "white",
                                  border:
                                    "1px solid var(--mainTheme) !important",
                                },
                              }}
                              value={subQuestion.value}
                              aria-label="bold"
                            >
                              {subQuestion.label}
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </>
                    ))}
                </Box>
                {/* ********Comment for question 17, 18, 19, 20********** */}
                <Fade
                  in={
                    selectedDescribeFirstLevel[question?.questionId] &&
                    selectedIdxDescribeFirstLevel.includes(
                      question?.questionId,
                    ) &&
                    [7, 16, 17, 18, 19, 29, 13]?.includes(question?.questionId)
                  }
                  unmountOnExit
                >
                  <Box
                    key={`${question?.questionId}commentBox`}
                    display="flex"
                    width={1}
                    margin={1}
                  >
                    <TextField
                      key={`${question?.questionId}commentText`}
                      variant="outlined"
                      label="Comment"
                      disabled={
                        (roleId == 5 && tabId == 3) ||
                        (roleId == 8 && tabId == 4) ||
                        roleId == 6 ||
                        roleId == 8 ||
                        roleId == 7 ||
                        roleId == 9
                      }
                      // sx={{
                      //   "& .MuiInputLabel-root": {
                      //     backgroundColor: "white",
                      //   },

                      //   marginTop: "20px",
                      //   marginLeft: "10px",
                      // }}
                      sx={{
                        border: "1.5px solid var(--mainTheme) !important",
                        borderRadius: "5px",
                        "& .MuiFormLabel-root": {
                          color: "var(--mainTheme)",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "var(--mainTheme)",
                        },
                        // marginTop: "20px",
                        // marginLeft: "10px",
                      }}
                      value={firstLevelCommentObj[question?.questionId]}
                      onChange={(e) => {
                        createFirstLevelCommentObj(
                          question?.questionId,
                          e?.target?.value,
                        );
                      }}
                      className="inputStyle"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ArrowForwardIcon
                              sx={{
                                size: "medium",
                                color: "var(--mainTheme)",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      maxRows={4}
                      multiline
                      fullWidth
                    />
                    {/* <DebounceTextField
                    disabled={roleId==5 && tabId==3 }
                      onChange={(value) => {
                        createFirstLevelCommentObj(question?.questionId, value);
                      }}
                      value={firstLevelCommentObj[question?.questionId]}
                      className="inputStyle"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ArrowForwardIcon
                              sx={{
                                size: "medium",
                                color: "var(--mainTheme)",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      maxRows={4}
                      multiline
                    /> */}
                  </Box>
                </Fade>
                {/* ********subquestion********** */}
                <Fade
                  in={
                    selectedSubQuestion[question?.questionId] &&
                    selectedIdx.includes(question?.questionId)
                  }
                  key={`${
                    selectedSubQuestion[question?.questionId]
                  }${selectedIdx.includes(question?.questionId)}`}
                  unmountOnExit
                >
                  <div>
                    {selectedSubQuestion[question?.questionId]?.subQuestions
                      ?.length > 0 &&
                    selectedSubQuestion[question?.questionId]?.name !==
                      "Yesbmi" ? (
                      selectedSubQuestion[
                        question?.questionId
                      ]?.subQuestions?.map((subQuestion, subQuestionIdx) => (
                        <>
                          <Box display="flex">
                            <Box>
                              {!["bmitext", "datatable"].includes(
                                subQuestion?.type,
                              ) && (
                                <MdOutlineSubdirectoryArrowRight
                                  style={{
                                    color: "var(--mainTheme)",
                                    marginRight: "10px",
                                    height: "25px",
                                    width: "25px",
                                  }}
                                />
                              )}
                            </Box>
                            <Box display="flex" paddingRight={1} width="76.5vw">
                              <Box
                                display="flex"
                                justifyContent="left"
                                width={0.8}
                              >
                                <Typography>{subQuestion?.label}</Typography>
                              </Box>
                              {/* //Chat box for condition_that_has_lasted 14,17,18,19,8 */}
                              <Fade
                                in={
                                  subQuestion?.name ==
                                    "condition_that_has_lasted" ||
                                  subQuestion?.name == "Whatwastheevent"
                                }
                                unmountOnExit
                              >
                                <Box
                                  width={0.2}
                                  display="flex"
                                  justifyContent="right"
                                  visibility={
                                    subQuestion?.name ==
                                      "condition_that_has_lasted" ||
                                    subQuestion?.name == "Whatwastheevent"
                                      ? "visible"
                                      : "hidden"
                                  }
                                >
                                  <Grid
                                    item
                                    xs={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <ChatBubbleIcon
                                      disabled={
                                        (roleId == 5 && tabId == 3) ||
                                        (roleId == 8 && tabId == 4) ||
                                        roleId == 6 ||
                                        roleId == 8 ||
                                        roleId == 7 ||
                                        roleId == 9
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "var(--mainTheme)",
                                      }}
                                      onClick={() => {
                                        if (
                                          (roleId == 5 && tabId == 3) ||
                                          (roleId == 8 && tabId == 4) ||
                                          roleId == 6 ||
                                          roleId == 8 ||
                                          roleId == 7 ||
                                          roleId == 9
                                        ) {
                                          return null;
                                        }
                                        onSecondLevelCommentIconClick(
                                          subQuestion?.commentValueHolder,
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Box>
                              </Fade>
                            </Box>
                          </Box>
                          <Box
                            style={{ display: "flex", flexWrap: "wrap" }}
                            display="flex"
                            // justifyContent="space-between"
                          >
                            {subQuestion.type == "options_tree" &&
                              subQuestion?.subQuestions?.map((_question) => (
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <ToggleButtonGroup
                                    disabled={
                                      (roleId == 5 && tabId == 3) ||
                                      (roleId == 8 && tabId == 4) ||
                                      roleId == 6 ||
                                      roleId == 8 ||
                                      roleId == 7 ||
                                      roleId == 9
                                    }
                                    fullWidth
                                    role="group"
                                    size="large"
                                    value={
                                      answeredJson[question?.questionId]
                                        ? answeredJson[question?.questionId][
                                            subQuestion.name
                                          ]
                                        : ""
                                    }
                                    //onChange={handleFormat}
                                    aria-label="text formatting"
                                    style={{
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <ToggleButton
                                      sx={{
                                        // minWidth: "230px",
                                        overflow: "hidden",
                                        display: "inline-block",
                                        // whiteSpace: "normal",
                                        minHeight: "60px",
                                        margin: "12px",
                                        width: "230px",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                        fontSize: "15px",
                                        color: "black",
                                        // margin: "5px 15px 0 0",
                                        borderStyle: "none",
                                        textTransform: "none",
                                        border: `1px solid var(--mainTheme) !important`,
                                        flexWrap: "wrap",
                                        padding: "5px",
                                        fontSize: "0.85rem",
                                        "&.Mui-selected ": {
                                          backgroundColor:
                                            "var(--mainTheme900)",
                                          color: "white",
                                          border:
                                            "1px solid var(--mainTheme) !important",
                                          fontWeight: "600",
                                          fontSize: "0.85rem",
                                          // textTransform: "uppercase",
                                        },
                                        "&.Mui-disabled ": {
                                          //backgroundColor: "var(--mainTheme900)",
                                          color: "black",
                                          border:
                                            "1px solid var(--mainTheme) !important",
                                          fontWeight: "600",
                                          fontSize: "0.85rem",
                                          // textTransform: "uppercase",
                                        },
                                        "&.Mui-selected:hover": {
                                          backgroundColor:
                                            "var(--mainTheme750)",
                                          color: "white",
                                          border:
                                            "1px solid var(--mainTheme) !important",
                                        },
                                        "&.MuiToggleButtonGroup-grouped": {
                                          borderRadius: "5px !important",
                                        },
                                        ":hover": {
                                          backgroundColor:
                                            "var(--mainTheme400)",
                                          // textTransform: "uppercase",
                                          // color: "white",
                                          border:
                                            "1px solid var(--mainTheme) !important",
                                        },
                                      }}
                                      value={_question.value}
                                      aria-label="bold"
                                      onClick={() => {
                                        updateSubAnswerForToggleButton(
                                          _question,
                                          subQuestion,
                                          question?.questionId,
                                        ); // _question is yes/no option and subQuestion is upper level question
                                      }}
                                    >
                                      {_question.label}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                              ))}
                            <Fade
                              in={
                                secondLevelComment.includes(subQuestion.name) ||
                                secondLevelComment.includes(
                                  subQuestion.commentValueHolder,
                                )
                              }
                              unmountOnExit
                            >
                              <Box display="flex" width={1} margin={1}>
                                <TextField
                                  variant="outlined"
                                  label="Comment"
                                  disabled={
                                    (roleId == 5 && tabId == 3) ||
                                    (roleId == 8 && tabId == 4) ||
                                    roleId == 6 ||
                                    roleId == 8 ||
                                    roleId == 7 ||
                                    roleId == 9
                                  }
                                  // sx={{
                                  //   "& .MuiInputLabel-root": {
                                  //     backgroundColor: "white",
                                  //   },

                                  //   marginTop: "20px",
                                  //   marginLeft: "10px",
                                  // }}
                                  sx={{
                                    border:
                                      "1.5px solid var(--mainTheme) !important",
                                    borderRadius: "5px",
                                    "& .MuiFormLabel-root": {
                                      color: "var(--mainTheme)",
                                    },
                                    "& .MuiFormLabel-root.Mui-focused": {
                                      color: "var(--mainTheme)",
                                    },
                                  }}
                                  value={
                                    secondLevelCommentObj[
                                      subQuestion.commentValueHolder
                                    ]
                                  }
                                  onChange={(e) => {
                                    createSecondLevelCommentObj(
                                      subQuestion.commentValueHolder,
                                      e?.target?.value,
                                    );
                                  }}
                                  className="inputStyle"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <ArrowForwardIcon
                                          sx={{
                                            size: "medium",
                                            color: "var(--mainTheme)",
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  maxRows={4}
                                  multiline
                                  fullWidth
                                />
                                {/* <DebounceTextField
                                disabled={roleId==5 && tabId==3 }
                                  onChange={(value) => {
                                    createSecondLevelCommentObj(
                                      subQuestion.commentValueHolder,
                                      value,
                                    );
                                  }}
                                  value={
                                    secondLevelCommentObj[
                                      subQuestion.commentValueHolder
                                    ]
                                  }
                                  className="inputStyle"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <ArrowForwardIcon
                                          sx={{
                                            size: "medium",
                                            color: "var(--mainTheme)",
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  maxRows={4}
                                  multiline
                                /> */}
                              </Box>
                            </Fade>
                            {subQuestion.type == "text" && (
                              <TextField
                                variant="outlined"
                                fullWidth
                                disabled={
                                  (roleId == 5 && tabId == 3) ||
                                  (roleId == 8 && tabId == 4) ||
                                  roleId == 6 ||
                                  roleId == 8 ||
                                  roleId == 7 ||
                                  roleId == 9
                                }
                                value={
                                  answeredJson?.[question?.questionId]?.[
                                    subQuestion.name
                                  ]
                                }
                                sx={{
                                  border:
                                    "1.5px solid var(--mainTheme) !important",
                                  borderRadius: "5px",
                                  "& .MuiFormLabel-root": {
                                    color: "var(--mainTheme)",
                                  },
                                  "& .MuiFormLabel-root.Mui-focused": {
                                    color: "var(--mainTheme)",
                                  },
                                  marginTop: "10px",
                                  marginLeft: "10px",
                                  marginBottom: "10px",
                                }}
                                onChange={(e) => {
                                  updateSubAnswerForTextField(
                                    subQuestion,
                                    question?.questionId,
                                    e?.target?.value,
                                  );
                                }}
                              />

                              // <DebounceTextField
                              // disabled={roleId==5 && tabId==3 }
                              //   onChange={(value) => {
                              //     updateSubAnswerForTextField(
                              //       subQuestion,
                              //       question?.questionId,
                              //       value,
                              //     );
                              //   }}
                              //   value={
                              //     answeredJson?.[question?.questionId]?.[
                              //       subQuestion.name
                              //     ]
                              //   }
                              //   maxRows={4}
                              //   multiline
                              // />
                            )}

                            {subQuestion.type == "datatable" && (
                              <Box>
                                <DynamicTableAllergies
                                  allergiesRowData={allergiesRowData}
                                  setAllergiesRowData={setAllergiesRowData}
                                  fromPeds={true}
                                  disableFlag={roleId == 5 && tabId == 3}
                                />
                              </Box>
                            )}
                          </Box>
                        </>
                      ))
                    ) : selectedSubQuestion[question?.questionId]?.name ==
                      "Yesbmi" ? (
                      <Box display="flex">
                        {" "}
                        {selectedSubQuestion[
                          question?.questionId
                        ]?.subQuestions?.map((subQuestion) => (
                          <Box marginInline={2}>
                            <Box
                              visibility={
                                subQuestion?.label == "Childs Height Inches"
                                  ? "hidden"
                                  : "visible"
                              }
                            >
                              <Typography
                                sx={{
                                  color: "var(--mainTheme)",
                                  fontWeight: 700,
                                }}
                              >
                                {subQuestion?.label}
                              </Typography>
                            </Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              disabled={
                                (roleId == 5 && tabId == 3) ||
                                (roleId == 8 && tabId == 4) ||
                                roleId == 6 ||
                                roleId == 8 ||
                                roleId == 7 ||
                                roleId == 9
                              }
                              value={
                                answeredJson?.[question?.questionId]?.[
                                  subQuestion.name
                                ]
                              }
                              sx={{
                                border:
                                  "1.5px solid var(--mainTheme) !important",
                                borderRadius: "5px",
                                "& .MuiFormLabel-root": {
                                  color: "var(--mainTheme)",
                                },
                                "& .MuiFormLabel-root.Mui-focused": {
                                  color: "var(--mainTheme)",
                                },
                                marginTop: "10px",
                                marginLeft: "10px",
                                // marginBottom:'10px'
                              }}
                              onChange={(e) => {
                                updateSubAnswerForTextField(
                                  subQuestion,
                                  question?.questionId,
                                  e?.target?.value,
                                );
                                if (subQuestion.name == "childsHeightfeet") {
                                  setChildHeightFeet(e?.target?.value);
                                } else if (
                                  subQuestion.name == "childsHeightInches"
                                ) {
                                  setChildHeightInches(e?.target?.value);
                                } else if (subQuestion.name == "childsWeight") {
                                  setChildsWeight(e?.target?.value);
                                }
                              }}
                            />
                            <Box paddingLeft={1.5}>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "var(--mainTheme)",
                                  fontWeight: 200,
                                }}
                              >
                                {subQuestion?.value}
                              </Typography>
                            </Box>
                          </Box>
                        ))}{" "}
                      </Box>
                    ) : selectedSubQuestion[question?.questionId]
                        ?.subQuestionType == // for question number 15 counterMedicationsRowDataQues15,setCounterMedicationsRowDataQues15
                      "data_table" ? (
                      <PedsDynamicTableCounterMedications // counter Medication table questionId  = 15 , question 16
                        counterMedicationsRowData={
                          counterMedicationsRowDataQues15
                        }
                        setCounterMedicationsRowData={
                          setCounterMedicationsRowDataQues15
                        }
                        disabled={
                          (roleId == 5 && tabId == 3) ||
                          (roleId == 8 && tabId == 4) ||
                          roleId == 6 ||
                          roleId == 8 ||
                          roleId == 7 ||
                          roleId == 9
                        }
                      />
                    ) : null}
                  </div>
                </Fade>
                {/* ********Multi-subquestion********** */}

                <Fade
                  in={
                    selectedSubQuestion[question?.questionId] &&
                    selectedIdx.includes(question?.questionId)
                  }
                  key={`${
                    selectedSubQuestion[question?.questionId]
                  }${selectedIdx.includes(question?.questionId)}multi`}
                  unmountOnExit
                >
                  <Box>
                    {selectedSubQuestion[question?.questionId]?.length > 0
                      ? selectedSubQuestion[question?.questionId]?.map(
                          (preSubQuestion) =>
                            preSubQuestion?.subQuestions?.map(
                              (subQuestion, subQuestionIdx) => (
                                <>
                                  <Box display="flex">
                                    <Box>
                                      {subQuestion?.type !== "bmitext" && (
                                        <MdOutlineSubdirectoryArrowRight
                                          style={{
                                            color: "var(--mainTheme)",
                                            marginRight: "10px",
                                            height: "25px",
                                            width: "25px",
                                          }}
                                        />
                                      )}
                                    </Box>
                                    <Box>
                                      <Typography>
                                        {subQuestion?.label}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    style={{ width: "78.5vw" }}
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="left"
                                      width={0.8}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          // flexWrap: "wrap",
                                        }}
                                        display="flex"
                                        // justifyContent="space-between"
                                      >
                                        {subQuestion.type == "options_tree" &&
                                          subQuestion?.subQuestions?.map(
                                            (_question) => (
                                              <>
                                                <Box
                                                  // key={`${_question.value}${question.question?.questionId}${subQuestion.name}`}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                  display="flex"
                                                  justifyContent="space-between"
                                                >
                                                  <ToggleButtonGroup
                                                    disabled={
                                                      (roleId == 5 &&
                                                        tabId == 3) ||
                                                      (roleId == 8 &&
                                                        tabId == 4) ||
                                                      roleId == 6 ||
                                                      roleId == 8 ||
                                                      roleId == 7 ||
                                                      roleId == 9
                                                    }
                                                    fullWidth
                                                    role="group"
                                                    size="large"
                                                    value={
                                                      answeredJson[
                                                        question?.questionId
                                                      ]
                                                        ? answeredJson[
                                                            question?.questionId
                                                          ]?.[subQuestion.name]
                                                        : ""
                                                    }
                                                    //onChange={handleFormat}
                                                    aria-label="text formatting"
                                                    style={{
                                                      flexDirection: "row",
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    <ToggleButton
                                                      sx={{
                                                        // minWidth: "230px",
                                                        overflow: "hidden",
                                                        display: "inline-block",
                                                        // whiteSpace: "normal",
                                                        minHeight: "60px",
                                                        margin: "12px",
                                                        width: "230px",
                                                        textAlign: "center",
                                                        justifyContent:
                                                          "center",
                                                        borderRadius: "5px",
                                                        fontSize: "15px",
                                                        color: "black",
                                                        // margin: "5px 15px 0 0",
                                                        borderStyle: "none",
                                                        textTransform: "none",
                                                        border: `1px solid var(--mainTheme) !important`,
                                                        flexWrap: "wrap",
                                                        padding: "5px",
                                                        fontSize: "0.85rem",
                                                        "&.Mui-selected ": {
                                                          backgroundColor:
                                                            "var(--mainTheme900)",
                                                          color: "white",
                                                          border:
                                                            "1px solid var(--mainTheme) !important",
                                                          fontWeight: "600",
                                                          fontSize: "0.85rem",
                                                          // textTransform: "uppercase",
                                                        },
                                                        "&.Mui-disabled ": {
                                                          //backgroundColor: "var(--mainTheme900)",
                                                          color: "black",
                                                          border:
                                                            "1px solid var(--mainTheme) !important",
                                                          fontWeight: "600",
                                                          fontSize: "0.85rem",
                                                          // textTransform: "uppercase",
                                                        },
                                                        "&.Mui-selected:hover":
                                                          {
                                                            backgroundColor:
                                                              "var(--mainTheme750)",
                                                            color: "white",
                                                            border:
                                                              "1px solid var(--mainTheme) !important",
                                                          },
                                                        "&.MuiToggleButtonGroup-grouped":
                                                          {
                                                            borderRadius:
                                                              "5px !important",
                                                          },
                                                        ":hover": {
                                                          backgroundColor:
                                                            "var(--mainTheme400)",
                                                          // textTransform: "uppercase",
                                                          // color: "white",
                                                          border:
                                                            "1px solid var(--mainTheme) !important",
                                                        },
                                                      }}
                                                      value={_question.value}
                                                      aria-label="bold"
                                                      onClick={() => {
                                                        updateSubAnswerForToggleButton(
                                                          _question,
                                                          subQuestion,
                                                          question?.questionId,
                                                        ); // question is yes/no option and subQuestion is upper level question
                                                      }}
                                                    >
                                                      {_question.label}
                                                    </ToggleButton>
                                                  </ToggleButtonGroup>
                                                </Box>
                                              </>
                                            ),
                                          )}
                                      </Box>
                                    </Box>
                                    {/* //Chat Bubble Icon */}
                                    <Box
                                      width={0.2}
                                      display="flex"
                                      justifyContent="right"
                                    >
                                      <Grid
                                        item
                                        xs={1}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <ChatBubbleIcon
                                          disabled={
                                            (roleId == 5 && tabId == 3) ||
                                            (roleId == 8 && tabId == 4) ||
                                            roleId == 6 ||
                                            roleId == 8 ||
                                            roleId == 7 ||
                                            roleId == 9
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "var(--mainTheme)",
                                          }}
                                          onClick={() => {
                                            if (
                                              (roleId == 5 && tabId == 3) ||
                                              (roleId == 8 && tabId == 4) ||
                                              roleId == 6 ||
                                              roleId == 8 ||
                                              roleId == 7 ||
                                              roleId == 9
                                            ) {
                                              return null;
                                            }
                                            // if (disableFlag) {
                                            //   return null;
                                            // }
                                            // toggleCommentBox(
                                            //   showComment,
                                            //   setShowComment,
                                            //   sectionId,
                                            //   quesSlug,
                                            //   value,
                                            // );
                                            onSecondLevelCommentIconClick(
                                              subQuestion?.name,
                                            );
                                          }}
                                        />
                                      </Grid>
                                    </Box>
                                  </Box>
                                  <Fade
                                    in={secondLevelComment.includes(
                                      subQuestion.name,
                                    )}
                                    unmountOnExit
                                  >
                                    <Box display="flex" width={1} margin={1}>
                                      <TextField
                                        variant="outlined"
                                        label="Comment"
                                        disabled={
                                          (roleId == 5 && tabId == 3) ||
                                          (roleId == 8 && tabId == 4) ||
                                          roleId == 6 ||
                                          roleId == 8 ||
                                          roleId == 7 ||
                                          roleId == 9
                                        }
                                        // sx={{
                                        //   "& .MuiInputLabel-root": {
                                        //     backgroundColor: "white",
                                        //   },

                                        //   marginTop: "20px",
                                        //   marginLeft: "10px",
                                        // }}
                                        sx={{
                                          border:
                                            "1.5px solid var(--mainTheme) !important",
                                          borderRadius: "5px",
                                          "& .MuiFormLabel-root": {
                                            color: "var(--mainTheme)",
                                          },
                                          "& .MuiFormLabel-root.Mui-focused": {
                                            color: "var(--mainTheme)",
                                          },
                                        }}
                                        value={
                                          secondLevelCommentObj[
                                            subQuestion.name
                                          ]
                                        }
                                        onChange={(e) => {
                                          createSecondLevelCommentObj(
                                            subQuestion?.name,
                                            e?.target?.value,
                                          );
                                        }}
                                        className="inputStyle"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <ArrowForwardIcon
                                                sx={{
                                                  size: "medium",
                                                  color: "var(--mainTheme)",
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                        maxRows={4}
                                        multiline
                                        fullWidth
                                      />

                                      {/* <DebounceTextField
                                      disabled={roleId==5 && tabId==3 }
                                        onChange={(value) => {
                                          createSecondLevelCommentObj(
                                            subQuestion?.name,
                                            value,
                                          );
                                        }}
                                        value={
                                          secondLevelCommentObj[
                                            subQuestion.name
                                          ]
                                        }
                                        className="inputStyle"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <ArrowForwardIcon
                                                sx={{
                                                  size: "medium",
                                                  color: "var(--mainTheme)",
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                        maxRows={4}
                                        multiline
                                      /> */}
                                    </Box>
                                  </Fade>

                                  <Fade
                                    in={
                                      selected2ndLevelSubQuestion[
                                        subQuestion.subId
                                      ]?.subQuestions?.length > 0
                                    }
                                    key={`selected2ndLevelSubQuestion`}
                                    unmountOnExit
                                  >
                                    <Box>
                                      {selected2ndLevelSubQuestion[
                                        subQuestion.subId
                                      ]?.subQuestions?.map(
                                        (secondLevelSubQuestion) => (
                                          <>
                                            <Box display="flex" marginLeft={12}>
                                              <Box>
                                                {secondLevelSubQuestion?.type !==
                                                  "bmitext" && (
                                                  <MdOutlineSubdirectoryArrowRight
                                                    style={{
                                                      color: "var(--mainTheme)",
                                                      marginRight: "10px",
                                                      height: "25px",
                                                      width: "25px",
                                                    }}
                                                  />
                                                )}
                                              </Box>
                                              <Box>
                                                <Typography>
                                                  {
                                                    secondLevelSubQuestion?.label
                                                  }
                                                </Typography>
                                              </Box>
                                            </Box>

                                            <Fade
                                              in={
                                                secondLevelSubQuestion.type ==
                                                "text"
                                              }
                                              unmountOnExit
                                            >
                                              <Box
                                                marginLeft={15}
                                                width="70vw"
                                                style={{ marginBottom: "10px" }}
                                              >
                                                <TextField
                                                  variant="outlined"
                                                  fullWidth
                                                  disabled={
                                                    (roleId == 5 &&
                                                      tabId == 3) ||
                                                    (roleId == 8 &&
                                                      tabId == 4) ||
                                                    roleId == 6 ||
                                                    roleId == 8 ||
                                                    roleId == 7 ||
                                                    roleId == 9
                                                  }
                                                  value={
                                                    // answeredJson[idx]?.[
                                                    //   question?.name
                                                    // ]
                                                    secondLevelTextFieldAnswer[
                                                      secondLevelSubQuestion
                                                        .name
                                                    ]
                                                  }
                                                  sx={{
                                                    border:
                                                      "1.5px solid var(--mainTheme) !important",
                                                    borderRadius: "5px",
                                                    "& .MuiFormLabel-root": {
                                                      color: "var(--mainTheme)",
                                                    },
                                                    "& .MuiFormLabel-root.Mui-focused":
                                                      {
                                                        color:
                                                          "var(--mainTheme)",
                                                      },
                                                    marginTop: "10px",
                                                    marginLeft: "10px",
                                                  }}
                                                  onChange={(e) => {
                                                    // updateAnsweredJsonForTextField(
                                                    //   question,
                                                    //   idx,
                                                    //   e.target.value
                                                    // );
                                                    update2ndLevelAnsweredJsonForTextField(
                                                      secondLevelSubQuestion,
                                                      question?.questionId,
                                                      e.target.value,
                                                    );
                                                  }}
                                                />
                                                {/* <DebounceTextField
                                                disabled={roleId==5 && tabId==3 }
                                                  onChange={(value) => {
                                                    update2ndLevelAnsweredJsonForTextField(
                                                      secondLevelSubQuestion,
                                                      question?.questionId,
                                                      value,
                                                    );
                                                  }}
                                                  value={
                                                    secondLevelTextFieldAnswer[
                                                      secondLevelSubQuestion
                                                        .name
                                                    ]
                                                  }
                                                  maxRows={4}
                                                  multiline
                                                /> */}
                                              </Box>
                                            </Fade>
                                          </>
                                        ),
                                      )}
                                    </Box>
                                  </Fade>
                                </>
                              ),
                            ),
                        )
                      : null}
                  </Box>
                </Fade>

                {/* ********Comment********** */}
                <Fade
                  key={`${question.questionId}`}
                  in={
                    selectedDescribeFirstLevel[question?.questionId] &&
                    selectedIdxDescribeFirstLevel.includes(
                      question?.questionId,
                    ) &&
                    ![7, 8, 16, 17, 18, 19, 28, 29, 28, 13, 23]?.includes(
                      question?.questionId,
                    )
                  }
                  unmountOnExit
                >
                  <Box
                    key={`${question.questionId}box`}
                    display="flex"
                    width={1}
                    margin={1}
                  >
                    <TextField
                      variant="outlined"
                      label="Comment"
                      disabled={
                        (roleId == 5 && tabId == 3) ||
                        (roleId == 8 && tabId == 4) ||
                        roleId == 6 ||
                        roleId == 8 ||
                        roleId == 7 ||
                        roleId == 9
                      }
                      // sx={{
                      //   "& .MuiInputLabel-root": {
                      //     backgroundColor: "white",
                      //   },

                      //   marginTop: "20px",
                      //   marginLeft: "10px",
                      // }}
                      sx={{
                        border: "1.5px solid var(--mainTheme) !important",
                        borderRadius: "5px",
                        "& .MuiFormLabel-root": {
                          color: "var(--mainTheme)",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "var(--mainTheme)",
                        },
                        // marginTop: "20px",
                        // marginLeft: "10px",
                      }}
                      value={firstLevelCommentObj[question?.questionId]}
                      onChange={(e) => {
                        createFirstLevelCommentObj(
                          question?.questionId,
                          e?.target?.value,
                        );
                      }}
                      className="inputStyle"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ArrowForwardIcon
                              sx={{
                                size: "medium",
                                color: "var(--mainTheme)",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      maxRows={4}
                      multiline
                      fullWidth
                    />

                    {/* <DebounceTextField
                    disabled={roleId==5 && tabId==3 }
                      onChange={(value) => {
                        createFirstLevelCommentObj(question?.questionId, value);
                      }}
                      value={firstLevelCommentObj[question?.questionId]}
                      className="inputStyle"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ArrowForwardIcon
                              sx={{
                                size: "medium",
                                color: "var(--mainTheme)",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      maxRows={4}
                      multiline
                    /> */}
                  </Box>
                </Fade>
              </Box>
            </>
          ))}
        {/* Below code  is just to create the illusional placeholder   while loading the data for first four question */}
        {isLoading && (
          <Box>
            {pedsAssessmentInfoJsonData?.[0]?.questions
              .slice(0, 4)
              ?.map((question, idx) => (
                <>
                  <Box display="flex" paddingRight={1}>
                    <Box display="flex" justifyContent="left" width={0.8}>
                      <Typography
                        sx={{ color: "var(--mainTheme)", fontWeight: 700 }}
                      >
                        {question?.label}
                      </Typography>
                    </Box>
                    <Box width={0.2} display="flex" justifyContent="right">
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <ChatBubbleIcon
                          style={{
                            cursor: "pointer",
                            color: "var(--mainTheme)",
                          }}
                        />
                      </Grid>
                    </Box>
                  </Box>
                  <Box
                    style={{ display: "flex", flexWrap: "wrap" }}
                    display="flex"
                    // justifyContent="space-between"
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexBasis:
                          question?.subQuestions?.length <= 2
                            ? "100%"
                            : "undefined",
                        flexWrap:
                          question?.subQuestions?.length >= 4
                            ? "wrap"
                            : "undefined",
                      }}
                    >
                      {(question?.type == "options_tree" ||
                        question?.type ==
                          "peds-SingleSelectTable_options_tree") &&
                        question?.subQuestions?.map(
                          (subQuestion, subQuesIdx) => (
                            <>
                              <Box>
                                <ToggleButtonGroup
                                  fullWidth
                                  disabled={roleId == 5 && tabId == 3}
                                  role="group"
                                  size="large"
                                  value="undefined"
                                  aria-label="text formatting"
                                  style={{
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <ToggleButton
                                    disabled={roleId == 5 && tabId == 3}
                                    sx={{
                                      // minWidth: "230px",
                                      overflow: "hidden",
                                      display: "inline-block",
                                      // whiteSpace: "normal",
                                      minHeight: "60px",
                                      margin: "12px",
                                      width: "230px",
                                      textAlign: "center",
                                      justifyContent: "center",
                                      borderRadius: "5px",
                                      fontSize: "15px",
                                      color: "black",
                                      // margin: "5px 15px 0 0",
                                      borderStyle: "none",
                                      textTransform: "none",
                                      border: `1px solid var(--mainTheme) !important`,
                                      flexWrap: "wrap",
                                      padding: "5px",
                                      fontSize: "0.85rem",
                                      "&.Mui-selected ": {
                                        backgroundColor: "var(--mainTheme900)",
                                        color: "white",
                                        border:
                                          "1px solid var(--mainTheme) !important",
                                        fontWeight: "600",
                                        fontSize: "0.85rem",
                                        // textTransform: "uppercase",
                                      },
                                      "&.Mui-disabled ": {
                                        //backgroundColor: "var(--mainTheme900)",
                                        color: "black",
                                        border:
                                          "1px solid var(--mainTheme) !important",
                                        fontWeight: "600",
                                        fontSize: "0.85rem",
                                        // textTransform: "uppercase",
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: "var(--mainTheme750)",
                                        color: "white",
                                        border:
                                          "1px solid var(--mainTheme) !important",
                                      },
                                      "&.MuiToggleButtonGroup-grouped": {
                                        borderRadius: "5px !important",
                                      },
                                      ":hover": {
                                        backgroundColor: "var(--mainTheme400)",
                                        // textTransform: "uppercase",
                                        // color: "white",
                                        border:
                                          "1px solid var(--mainTheme) !important",
                                      },
                                    }}
                                    value=""
                                    aria-label="bold"
                                  >
                                    {subQuestion.label}
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Box>
                            </>
                          ),
                        )}
                    </Box>
                  </Box>
                </>
              ))}
          </Box>
        )}
      </Box>
    </LoadingOverlay>
  );
});

export default PedsAssessmentInfoComp;
