import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--mainTheme)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E3EDF4",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function NurseTableComponent(props) {
  const [selectAllRows, setSelectAllRows] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedVisitTypes, setSelectedVisitTypes] = useState({});
  const [selectAllHeaderCheckboxState, setSelectAllHeaderCheckboxState] =
    useState(false);
  const [selectedRowCheckboxes, setSelectedRowCheckboxes] = useState({});

  const handleSelectAllRows = (event) => {
    const checked = event.target.checked;
    setSelectAllRows(checked);

    setSelectedRowCheckboxes((prevValues) => {
      const updatedCheckboxes = {};
      props.data.forEach((obj, index) => {
        updatedCheckboxes[index] = checked;
      });
      return updatedCheckboxes;
    });
  };

  const handleRowCheckboxChange = (event, rowIndex) => {
    setSelectedRowCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: event.target.checked,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const rows = props.data.map((obj, index) => ({ ...obj, rowID: index }));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedClinicians, setSelectedClinicians] = React.useState({});
  const displayedRows = rows.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 530,
          "&::-webkit-scrollbar": {
            width: 8,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "white",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D9D9D9",
            borderRadius: 3,
            maxHeight: 2,
          },
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 800 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {props.header.map((item, index) => {
                const headerName =
                  typeof item.headerName === "string" ? item.headerName : "";
                if (!headerName.trim()) {
                  return null;
                }
                return (
                  <StyledTableCell key={index} sx={{ textAlign: "center" }}>
                    {headerName.split("_").join(" ")}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {props.header.map((header, columnIndex) => {
                  if (header.field === "Offline") {
                    return (
                      <StyledTableCell key={header.field} align="center">
                        <input
                          type="checkbox"
                          style={{
                            width: "20px",
                            height: "18px",
                            cursor: "pointer",
                          }}
                          checked={selectedRowCheckboxes[row.rowID] || false}
                          onChange={(event) =>
                            handleRowCheckboxChange(event, row.rowID)
                          }
                        />
                      </StyledTableCell>
                    );
                  } else if (header.field === "select") {
                  } else {
                    return (
                      <StyledTableCell key={columnIndex} align="center">
                        {row[header.field]}
                      </StyledTableCell>
                    );
                  }
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
