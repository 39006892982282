import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";

export const HraStatusSelectDropdown = (props) => {
  const [value, setValue] = useState(props.value || "");

  useEffect(() => {
    // Update the state if the value prop changes (e.g., when resetting the dialog)
    setValue(props.value || "");
  }, [props.value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        value={value}
        onChange={handleChange}
        placeholder={props.title}
        className={props.className}
        renderOption={props.renderOption}
        sx={props.sx}
        inputProps={{
          "aria-label": "Without label",
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "#1076BC",
                color: "white",
              },
            },
          },
        }}
      >
        <MenuItem value="" disabled>
          {props?.title}
          {props?.label}
        </MenuItem>
        {props?.options?.map((opt, id) => {
          const isOptionDisabled = opt.disable && value !== opt.option;
          return (
            <MenuItem
              key={id}
              value={opt.option}
              disabled={isOptionDisabled}
              sx={{ color: isOptionDisabled ? "gray" : "none" }}
            >
              {opt.option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default HraStatusSelectDropdown;
