import { InputLabel, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useState, useEffect, useContext } from "react";
import FormGroup from "@mui/material/FormGroup";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import "./modal.scss";
import MuiInput from "../muiinput/MuiInput";
import Toaster from "../toaster/toaster";
import CloseIcon from "@mui/icons-material/Close";
import ValidationMessage from "../../resource_files/ValidationMessage.resx";
import { useUserContext } from "../../contexts/UserContext";
import Grid from "@mui/material/Grid";
import { getApi, postApi, putApi } from "../../utils/axiosApiWrapper";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import moment from "moment";
import Labels from "../../resource_files/Labels.resx";
import Alert from "../Alert/Alert";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import _, { result } from "lodash";
import AppointmentModal from "../AppointmentModal/AppointmentModal";
import AlertContext from "../../contexts/AlertContext";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { red } from "@mui/material/colors";
import CriticalReferralForm from "../Dashboard/MemberList/Fill_HRA/Sections/PatientSummary/ReferralForm/CriticalReferralForm";
import Referral from "../../pages/AdminDashboard/MemberListPage/Referral/Referral";
import ReferralForm from "../Dashboard/MemberList/Fill_HRA/Sections/PatientSummary/ReferralForm/ReferralForm";
//import moment from "moment";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1300,

  boxShadow: 24,
};

const myTheme = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--mainTheme300)",
          color: "#2b2b2b",
          fontWeight: 600,
          marginRight: "5px",
          borderRadius: "40px",
          "&.Mui-selected": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
            fontWeight: 600,
          },
          "&.Mui-disabled": {
            backgroundColor: "var(--mainTheme300)",
            color: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    },
  },
});

// const myThemes = createTheme({
//   components: {
//     MuiToggleButton: {
//       styleOverrides: {
//         root: {
//           backgroundColor: 'var(--mainTheme400)',
//           color: 'black',
//           fontWeight: 600,
//           marginRight: '5px',
//           borderRadius: '50px',
//           // width:"300px",

//           padding: '20px 15px',
//           whiteSpace: 'normal',
//           border: 'none',
//           '&.Mui-selected': {
//             backgroundColor: 'var(--mainTheme)',
//             color: 'white',
//             fontWeight: 600,
//           },

//         },
//       },
//     },
//     MuiToggleButtonGroup: {
//       styleOverrides: {
//         root: {
//           '& .MuiToggleButton-root': {
//             borderRadius: "5px",
//             maxWidth: "400px",
//           },
//         },
//       },
//     },
//   },
// });

const myThemes = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--mainTheme400)",
          color: "black",
          fontWeight: 600,
          marginRight: "5px",
          borderRadius: "50px",
          padding: "20px 15px",
          whiteSpace: "normal",
          border: "none",
          "&.Mui-selected": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
            fontWeight: 600,
          },

          textTransform: "capitalize",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiToggleButton-root": {
            borderRadius: "5px",
            maxWidth: "400px",
            textTransform: "capitalize",
            fontSize: "12px",
            lineHeight: "26px",
            color: "#2b2b2b",
            fontWeight: "400",
            margin: "5px",
          },
        },
      },
    },
  },
});

export default function CallLog({
  ModalClose,
  selectedMemberData,
  // isCallLogOpen,
  _fetchSchedulerMemberData,
  refSchId,
  uniqueMemberId,
  schedulerName,
  dateOfBirth,
  schedulerMemberId,
  schedulerPrimary,
  schedulerDateOfReferral,
}) {
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const { clientData, isDialogOpen, setClientData, selectedTimeZoneUtc } =
    useUserContext();
  const navigate = useNavigate();
  const [resultType, setResultType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isCallLogOpen, setIsCallLogOpen] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Member List");
  const [dataUpdated, setDataUpdated] = useState(false);

  const [callLogStatusOptions, setCallLogStatusOptions] = useState([]);
  const [callLogStatusOptionsData, setCallLogStatusOptionsData] = useState([]);
  const [selectedCallLogStatus, setSelectedCallLogStatus] = useState("");
  const [dynamicData, setDynamicData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noShowUpdateHraStatusPopup, setNoShowUpdateHraStatusPopup] =
    useState(false);
  const [emptyResultTypePopup, setEmptyResultTypePopup] = useState(false);
  const [
    cancelAppointmentUpdateHraStatusPopup,
    setCancelAppointmentUpdateHraStatusPopup,
  ] = useState(false);

  const [referralFormPopup, setReferralFormPopup] = useState(false);

  const handleReferralFormClose = () => {
    setReferralFormPopup(false);
  };

  const handleModalClose = async () => {
    setIsCallLogOpen(false);

    if (typeof ModalClose === "function") {
      ModalClose();
    }
    await fetchSchedulerMemberData(storedPlan, storedYear, 5);
  };
  const customUpdateHraStatus = async () => {
    const requestBody = {
      data: {
        _id: selectedMemberData?._id,
        hraStatus: {
          hraStatus: 19, //6,
          subStatus: "",
          cancelStatus: "",
          cancelText: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Hra Status Updated Successfully");

        const storedPlan =
          localStorage.getItem("clientPlan") || clientData?.plan;
        const storedYear =
          localStorage.getItem("clientYear") || clientData?.year;
        let defaultTabId;

        if (selectedTab === "Member List" || selectedTab === "BothUnchecked") {
          defaultTabId = 5;
        } else if (selectedTab === "Disabled Members") {
          defaultTabId = 4;
        } else if (selectedTab === "AssignedUnUnAssCh") {
          defaultTabId = 2;
        } else if (selectedTab === "BothChecked") {
          defaultTabId = 1;
        } else if (selectedTab === "AssignedChUnAssignedUn") {
          defaultTabId = 3;
        }
        await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);
        handleReload();
        handleModalClose();
      } else {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Failed to update HRA Status");
      }
      return true;
    } catch (error) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      return false;
    }
  };
  const validateForm = () => {
    if (selectedMemberData?.hraStatus?.hraStatus != "18") {
      /* result type as no answer */
      if (resultType == "3" && !formData.noAnswerOption) {
        return false;
      }

      if (
        resultType &&
        ["13", "7", "10", "1", "6", "14"].includes(resultType) &&
        !formData.dateOfAppointment
      ) {
        return false;
      }

      /* result type as scheduled and rescheduled */
      if (
        (resultType == "12" || resultType == "0") &&
        (!formData.visitTypes ||
          !formData.clinician ||
          !formData.dateOfAppointment)
      ) {
        return false;
      }
    }

    /* disable the log button when hra status term expired (18) */
    if (selectedMemberData?.hraStatus?.hraStatus == "18") {
      return true;
    }

    return true;
  };

  const [clinicianOptions, setClinicianOptions] = useState([]);
  const [clinicianData, setClinicianData] = useState("");
  const [selectedClinicianId, setSelectedClinicianId] = useState("");
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  // const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [secNumberError, setSecNumberError] = useState("");
  const [altNumberError, setAltNumberError] = useState("");

  const [postalCodeError, setPostalCodeError] = useState("");

  const roleId = sessionStorage.getItem("roleId");
  const fetchClinicianData = async (clientId) => {
    try {
      const response = await postApi("/users/get-users-by-program-roleId", {
        // userClientsId: "client_001",
        // planId: "6577071ca26c60f43e9281e6",
        planId: clientId,
      });

      if (response.data) {
        let clinicianData;
        if (response.data.data[1] && response.data.data[1].clinician) {
          clinicianData = response.data.data[1].clinician;
        } else if (response.data.data.clinician) {
          clinicianData = response.data.data.clinician;
        } else if (response.data.data[0] && response.data.data[0].clinician) {
          clinicianData = response.data.data[0].clinician;
        } else {
          return;
        }

        if (Array.isArray(clinicianData) && clinicianData.length > 0) {
          const options = clinicianData.map(
            (clinician) => `${clinician.firstName} ${clinician.lastName}`,
          );

          setClinicianOptions(options);
          setClinicianData(clinicianData);
        } else {
        }
      } else {
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const storedClientData = sessionStorage.getItem("clientData");
    const fetchClientData = async () => {
      try {
        const response = await getApi("/clients/list");
        if (response.status === 200) {
          setSelectedPlan(response.data.data);

          const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");
          if (clientPlanFromLocalStorage) {
            const matchingClient = response.data?.data?.find((client) =>
              client.plan.includes(clientPlanFromLocalStorage),
            );

            if (matchingClient) {
              const clientId = matchingClient._id;
              fetchClinicianData(clientId);
              setSelectedClientId(clientId);
            } else {
            }
          }
        }
      } catch (error) {
        return;
      }
    };
    fetchClientData();
  }, []);
  const storedPlan = localStorage.getItem("clientPlan") || clientData?.plan;
  const storedYear = localStorage.getItem("clientYear") || clientData?.year;
  let defaultTabId;
  const fetchSchedulerMemberData = async (plan, date, tabId) => {
    setLoading(true);
    try {
      const response = await getApi("/scheduler/getMembers", {
        params: {
          page: 1,
          limit: 1,
          year: date,
          planId: plan,
          tabId: tabId,
        },
      });
      handleVphpSelection(response?.data);

      setLoading(false);
      setDataUpdated(true);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const clientYearLoc = localStorage.getItem("clientYear");

  let screeningOptions = [
    { name: "Eye Exam", value: "retinalExam" },
    { name: "A1C", value: "aOneC" },
    { name: "URINE", value: "microAlbumin" },
    { name: "FOBT", value: "fobt" },
    { name: "DEXA", value: "dexa" },
    { name: "PAD", value: "pad" },
    { name: "LDL", value: "ldl" },
  ];

  if (clientYearLoc === "2024") {
    screeningOptions = screeningOptions.filter(
      (option) => option.value !== "fobt",
    );
    screeningOptions.push({ name: "KED", value: "ked" });
    screeningOptions.push({ name: "FIT", value: "fit" });
  }
  screeningOptions.sort((a, b) => a.name.localeCompare(b.name));
  // const isScreeningChecked = (screeningType) => {
  //   const screeningsData = selectedMemberData?.assessmentData?.screenings?.data;

  //   if (screeningsData) {
  //     return (
  //       screeningsData[screeningType]?.[
  //         `is${screeningType.charAt(0).toUpperCase()}${screeningType.slice(1)}`
  //       ] === "Y"
  //     );
  //   } else {
  //     return false;
  //   }
  // };
  //aOneC isAOneC dexa isDexa fobt isFobt ldl isLdl microAlbumin isMicroAlbumin
  const [screenSelected, setScreenSelected] = useState([]);
  useEffect(() => {
    const screeningsData = selectedMemberData?.assessmentData?.screenings?.data;
    const screenSelected = Object.keys(screeningsData)?.filter((key) => {
      if (
        screeningsData[key]?.[
          `is${key.charAt(0).toUpperCase()}${key.slice(1)}`
        ] === "1"
      ) {
        return key;
      }
    });
    setScreenSelected([...screenSelected]);
  }, [selectedMemberData]);

  const isScreeningDisabled = (screeningType) => true;

  let noshowCancel = "";

  const handleAddCall = async () => {
    console.log("originalData", originalData);
    console.log("formData", formData);
    console.log("resultType", resultType);
    try {
      if (resultType || noshowCancel) {
        setIsLoading(true);

        const noAnswerValueMap = {
          leftVoicemail: "0",
          mailBoxFull: "1",
          noVmSetup: "2",
        };
        const selectedNoAnswerValue =
          noAnswerValueMap[formData.noAnswerOption] || "";

        let callLogData = [];

        const formatDateTime = (dateTimeString) => {
          const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          };

          const formattedDateTime = new Date(dateTimeString).toLocaleString(
            undefined,
            options,
          );
          return formattedDateTime;
        };

        const resultTypeName = noshowCancel
          ? noshowCancel
          : callLogStatusOptionsData.find((item) => item.id == resultType)
              .statusName;

        //const formattedDateTime = formData.dateOfAppointment; //formatDateTime(formData.dateOfAppointment);
        //const callLogResult = await getApi("/call-log-status");
        //const callLogResultData = callLogResult.data.data;
        // const result = callLogResultData.find(
        //   (item) => item.statusName === resultType,
        // );
        //const dynamicResultId = result ? result.id : "";
        //const hraStatusResult = await getApi("/hra-status");
        //const hraResultData = hraStatusResult.data.data;

        // const hraResult = hraResultData.find(
        //   (item) => item.resultType === resultType,
        // );
        //const dynamicHraResultId = hraResult ? result.id : 0;
        const schedulerFirstName = sessionStorage.getItem("firstName");
        const schedulerLastName = sessionStorage.getItem("lastName");

        const resId = noshowCancel ? -2 : parseInt(resultType); // result id for call log data
        /* if the result type as dead air(1) */
        if (resultType === "1" && !noshowCancel) {
          const currentUtcDate = new Date().toISOString();
          let scheduleDateUtc = "";
          // if (formattedDateTime) {
          //   const parsedDateTime = moment(
          //     formattedDateTime,
          //     "DD/MM/YYYY, h:mm a",
          //   );

          //   if (parsedDateTime.isValid()) {
          //     scheduleDateUtc = parsedDateTime.toISOString();
          //   } else {
          //     return;
          //   }
          // }
          callLogData = {
            callLog: [
              {
                date: currentUtcDate,
                scheduler: schedulerFirstName + " " + schedulerLastName,
                schedulerId: selectedMemberData?.schedulerId || "",
                scheduleDate: "",
                resultId: resId,
                result: resultTypeName,
                noAnswer: "",
                //formData?.comment?.[0],
                comment: Array.isArray(formData.comment)
                  ? formData?.comment.join(" ")
                  : formData?.comment,
                callbackDate: selectedDate
                  ? new Date(selectedDate)?.toISOString()
                  : "",
                inBound: inBoundChecked,
              },
            ],
            utc: false,
          };
        } else if (
          (resultType === "0" || resultType === "12") &&
          !noshowCancel
        ) {
          /* if result type as scheduled(0), rescheduled(12) */
          const currentUtcDate = new Date().toISOString();
          let scheduleDateUtc = "";
          // if (formattedDateTime) {
          //   const parsedDateTime = moment(
          //     formattedDateTime,
          //     "DD/MM/YYYY, h:mm a",
          //   );

          //   if (parsedDateTime.isValid()) {
          //     scheduleDateUtc = parsedDateTime.toISOString();
          //   } else {
          //     return;
          //   }
          // }
          callLogData = {
            callLog: [
              {
                date: currentUtcDate,
                scheduleDate: selectedDate
                  ? new Date(selectedDate)?.toISOString() || " "
                  : " ",
                scheduler: schedulerFirstName + " " + schedulerLastName,
                schedulerId: selectedMemberData?.schedulerId || "",
                resultId: resId,
                result: resultTypeName,
                noAnswer: "",
                comment: Array.isArray(formData?.comment)
                  ? formData?.comment.join(" ")
                  : formData?.comment,
                callbackDate: "",
                inBound: inBoundChecked,
              },
            ],
            utc: false,
          };
        } else if (resultType === "3" && !noshowCancel) {
          const currentUtcDate = new Date().toISOString();

          let scheduleDateUtc = "";
          // if (formattedDateTime) {
          //   const parsedDateTime = moment(
          //     formattedDateTime,
          //     "DD/MM/YYYY, h:mm a",
          //   );

          //   if (parsedDateTime.isValid()) {
          //     scheduleDateUtc = parsedDateTime.toISOString();
          //   } else {
          //     return;
          //   }
          // }
          callLogData = {
            callLog: [
              {
                date: currentUtcDate,
                scheduler: schedulerFirstName + " " + schedulerLastName,
                schedulerId: selectedMemberData?.schedulerId || "",
                scheduleDate: "",
                resultId: resId,
                result: resultTypeName,
                noAnswer: selectedNoAnswerValue,
                comment: Array.isArray(formData.comment)
                  ? formData.comment.join(" ")
                  : formData?.comment,
                callbackDate: selectedDate
                  ? new Date(selectedDate).toISOString() || ""
                  : "",
                inBound: inBoundChecked,
                noAnswerOption: formData?.noAnswerOption,
              },
            ],
            utc: false,
          };
        } else if (resultType === "14" && !noshowCancel) {
          const currentUtcDate = new Date().toISOString();

          let scheduleDateUtc = "";
          // if (formattedDateTime) {
          //   const parsedDateTime = moment(
          //     formattedDateTime,
          //     "DD/MM/YYYY, h:mm a",
          //   );

          //   if (parsedDateTime.isValid()) {
          //     scheduleDateUtc = parsedDateTime.toISOString();
          //   } else {
          //     return;
          //   }
          // }
          callLogData = {
            callLog: [
              {
                // date: selectedDate || "",
                date: currentUtcDate,
                callbackDate: new Date(selectedDate)?.toISOString() || "",
                schedulerID: selectedMemberData?.schedulerId || "",
                scheduler: schedulerFirstName + " " + schedulerLastName,
                scheduleDate: "",
                resultId: resId,
                result: resultTypeName,
                noAnswer: "",
                // comment: formData.comments || "",
                comment: Array.isArray(formData.comment)
                  ? formData.comment.join(" ")
                  : formData?.comment,
                inBound: inBoundChecked,
              },
            ],
            utc: false,
          };
        } else if (resultType === "6" && !noshowCancel) {
          const currentUtcDate = new Date().toISOString();

          let scheduleDateUtc = "";
          // if (formattedDateTime) {
          //   const parsedDateTime = moment(
          //     formattedDateTime,
          //     "DD/MM/YYYY, h:mm a",
          //   );

          //   if (parsedDateTime.isValid()) {
          //     scheduleDateUtc = parsedDateTime.toISOString();
          //   } else {
          //     return;
          //   }
          // }
          callLogData = {
            callLog: [
              {
                date: currentUtcDate,
                callbackDate: new Date(selectedDate)?.toISOString() || "",
                schedulerID: selectedMemberData?.schedulerId || "",
                scheduler: schedulerFirstName + " " + schedulerLastName,
                resultId: resId,
                scheduleDate: "",
                result: resultTypeName,
                noAnswer: "",
                // comment: formData.comments || "",
                comment: Array.isArray(formData.comment)
                  ? formData.comment.join(" ")
                  : formData.comment,
                inBound: inBoundChecked,
              },
            ],
            utc: false,
          };
        } else {
          const currentUtcDate = new Date().toISOString();

          let scheduleDateUtc = "";
          // if (formattedDateTime) {
          //   const parsedDateTime = moment(
          //     formattedDateTime,
          //     "DD/MM/YYYY, h:mm a",
          //   );

          //   if (parsedDateTime.isValid()) {
          //     scheduleDateUtc = parsedDateTime.toISOString();
          //   } else {
          //     return;
          //   }
          // }
          callLogData = {
            callLog: [
              {
                date: currentUtcDate,
                callbackDate: !_.isEmpty(selectedDate)
                  ? new Date(selectedDate)?.toISOString()
                  : "",
                schedulerID: selectedMemberData?.schedulerId || "",
                scheduler: schedulerFirstName + " " + schedulerLastName,
                resultId: resId,
                result: resultTypeName,
                noAnswer: "",
                scheduleDate: "",
                // comment: formData.comments || "",
                comment: Array.isArray(formData.comment)
                  ? formData.comment.join(" ")
                  : formData.comment,
                inBound: inBoundChecked,
              },
            ],
            utc: false,
          };
        }
        const postData = {
          _id: originalData._id,
          memberId: originalData.memberId,
          // memberIdTwo: originalData.memberIdTwo,
          type: "callLog",
          clientId: originalData.clientId,
          planId: originalData.planId,
          year: originalData.year,
          resultId: resId,
          // visitType: "",
          visitType: noshowCancel
            ? ""
            : formData?.visitType?.[0] === "In Person"
            ? 1
            : 2,
          providerId: matchingUserIds,
          comment: originalData.comment,
          callLogData: callLogData,
        };
        //setDynamicData((prevData) => [...prevData, ...callLogData.callLog]);

        const response = await postApi("/scheduler/callLog", postData);
        if (response.status === 201) {
          // handleModalClose();
          setDynamicData((prevData) => [...prevData, ...callLogData.callLog]);
        } else {
        }

        if (selectedTab === "Member List" || selectedTab === "BothUnchecked") {
          defaultTabId = 5;
        } else if (selectedTab === "Disabled Members") {
          defaultTabId = 4;
        } else if (selectedTab === "AssignedUnUnAssCh") {
          defaultTabId = 2;
        } else if (selectedTab === "BothChecked") {
          defaultTabId = 1;
        } else if (selectedTab === "AssignedChUnAssignedUn") {
          defaultTabId = 3;
        }

        handleModalClose();

        await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);
        handleReload();
      } else {
        setEmptyResultTypePopup(true);
      }
    } catch (error) {
      console.log("error", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getApi(`/call-log-status`);
        const data = await response.data.data;
        setCallLogStatusOptionsData(data);
        if (Array.isArray(data)) {
          const statusNames = data.map((status) => status.statusName);
          setCallLogStatusOptions(statusNames);
        } else {
          setCallLogStatusOptions([]);
        }
      } catch (error) {
        setCallLogStatusOptions([]);
      }
    };
    if (
      selectedMemberData?.schedulerCallLogDetails &&
      selectedMemberData?.schedulerCallLogDetails.callLog
    ) {
      const callLogDataFromSelectedMember =
        selectedMemberData?.schedulerCallLogDetails.callLog;

      if (callLogDataFromSelectedMember.length > 0) {
        setDynamicData((prevData) => [
          ...prevData,
          ...callLogDataFromSelectedMember,
        ]);
      }
    }

    fetchData();
    return () => {
      setDynamicData([]);
    };
  }, [isCallLogOpen]);

  const handleReload = () => {
    navigate(".", { replace: true });
  };

  const handleClearField = (fieldName) => {
    setEditFormData({
      ...editFormData,
      [fieldName]: "",
    });
  };

  const handleEditChange = (event, fieldName) => {
    const newValue =
      fieldName === "zip" ||
      fieldName === "add1" ||
      fieldName === "add2" ||
      fieldName === "add3" ||
      fieldName === "email" ||
      fieldName === "pno" ||
      fieldName === "sno" ||
      fieldName === "ano" ||
      fieldName === "city" ||
      fieldName === "state"
        ? String(event.target.value)
        : event.target.value;

    setEditFormData({
      ...editFormData,
      [fieldName]: newValue,
    });

    if (fieldName === "email") {
      if (!newValue || /^\s*$/.test(newValue)) {
        setEmailError(ValidationMessage.Email_Required);
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(newValue)) {
        setEmailError(ValidationMessage.Email_Format_Invalid);
      } else {
        setEmailError("");
      }
    }

    if (fieldName === "zip") {
      if (!newValue || /^\s*$/.test(newValue)) {
        setPostalCodeError(ValidationMessage.Zipcode_Required);
      } else if (!/^\d{5}$/.test(newValue)) {
        setPostalCodeError("Zip Code must be 5 digit numerical");
      } else {
        setPostalCodeError("");
      }
    }
    if (fieldName === "pno") {
      if (!newValue || newValue === "" || /^\s*$/.test(newValue)) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Required);
      } else if (!/^[\d-]{10,13}$/.test(newValue)) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else if (!/^(\d{3}-\d{3}-\d{4}|\d{10})$/.test(newValue)) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else {
        if (newValue.length < 10 || newValue.length > 13) {
          setPhoneNumberError(ValidationMessage.PhoneNumber_Length);
        } else {
          setPhoneNumberError("");
        }
      }
    }

    if (fieldName === "sno") {
      if (!newValue || newValue === "" || /^\s*$/.test(newValue)) {
        setSecNumberError(
          `Secondary ${ValidationMessage.PhoneNumber_Required}`,
        );
      } else if (!/^[\d-]{10,13}$/.test(newValue)) {
        setSecNumberError(`Secondary ${ValidationMessage.PhoneNumber_Format}`);
      } else if (!/^(\d{3}-\d{3}-\d{4}|\d{10})$/.test(newValue)) {
        setSecNumberError(`Secondary ${ValidationMessage.PhoneNumber_Format}`);
      } else {
        if (newValue.length < 10 || newValue.length > 13) {
          setSecNumberError(
            `Secondary ${ValidationMessage.PhoneNumber_Length}`,
          );
        } else {
          setSecNumberError("");
        }
      }
    }
    if (fieldName === "ano") {
      if (!newValue || newValue === "" || /^\s*$/.test(newValue)) {
        setAltNumberError(
          `Alternate ${ValidationMessage.PhoneNumber_Required}`,
        );
      } else if (!/^[\d-]{10,13}$/.test(newValue)) {
        setAltNumberError(`Alternate ${ValidationMessage.PhoneNumber_Format}`);
      } else if (!/^(\d{3}-\d{3}-\d{4}|\d{10})$/.test(newValue)) {
        setAltNumberError(`Alternate ${ValidationMessage.PhoneNumber_Format}`);
      } else {
        if (newValue.length < 10 || newValue.length > 13) {
          setAltNumberError(
            `Alternate ${ValidationMessage.PhoneNumber_Length}`,
          );
        } else {
          setAltNumberError("");
        }
      }
    }

    // setSaveButtonDisabled(!emailError ||!phoneNumberError ||!postalCodeError);
  };

  const updateEditFormData = (newData) => {
    setEditFormData(newData);
  };
  const handleCancelEdit = () => {
    setEditModalOpen(false);
  };
  const handleSaveChanges = async () => {
    try {
      const { add1, add2, add3, city, state, zip, email, pno, sno, ano } =
        editFormData;

      const requestData = {
        _id: editFormData.uniquMemberId,
        add1,
        add2,
        add3,
        city,
        state,
        zip,
        email,
        pno,
        sno,
        ano,
      };

      const response = await putApi("/scheduler/updateDemoData", requestData);

      if (response.data || response.data.code === 200) {
        //const newId = response?.data?.uniquMemberId;
        // console.log('response',response);
        //console.log('newId',newId);
        // updateEditFormData((prevData) => ({ ...prevData, _id: newId }));
        fetchSchedulerMemberData();
        setEditModalOpen(false);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    const storedPlan = localStorage.getItem("clientPlan") || clientData?.plan;
    const storedYear = localStorage.getItem("clientYear") || clientData?.year;
    let defaultTabId;

    if (selectedTab === "Member List" || selectedTab === "BothUnchecked") {
      defaultTabId = 5;
    } else if (selectedTab === "Disabled Members") {
      defaultTabId = 4;
    } else if (selectedTab === "AssignedUnUnAssCh") {
      defaultTabId = 2;
    } else if (selectedTab === "BothChecked") {
      defaultTabId = 1;
    } else if (selectedTab === "AssignedChUnAssignedUn") {
      defaultTabId = 3;
    }

    handleCancelEdit();
    setEditModalOpen(false);
    handleModalClose();

    await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);
    handleReload();
  };

  const [formData, setFormData] = useState({
    dateOfAppointment: "",
    resultType: "",
    clinician: "",
  });
  const [originalData, setOriginalData] = useState({
    _id: "",
    add1: "",
    add2: "",
    add3: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    pno: "",
    sno: "",
    ano: "",
  });

  useEffect(() => {
    if (selectedMemberData) {
      const firstName =
        selectedMemberData.assessmentData.demographics.data.firstName;
      const lastName =
        selectedMemberData.assessmentData.demographics.data.lastName;
      const pedsFormPatientName =
        selectedMemberData?.assessmentData?.demographics?.data?.name;
      const name =
        pedsFormPatientName?.length > 0
          ? pedsFormPatientName
          : `${firstName} ${lastName}`;
      const dob = selectedMemberData?.assessmentData?.demographics?.data?.dob;
      const formattedDob = dob ? dob.split("T")[0] : "";

      const primaryPhone =
        selectedMemberData.assessmentData.demographics.data.primary;
      const secondaryPhone =
        selectedMemberData.assessmentData.demographics.data.secondary;
      const zip = selectedMemberData.assessmentData.demographics.data.zip;
      const email = selectedMemberData.assessmentData.demographics.data.email;
      const add1 = selectedMemberData.assessmentData.demographics.data.address;

      const add2 =
        selectedMemberData.assessmentData.demographics.data.addressTwo;
      const add3 = selectedMemberData.assessmentData.demographics.data.aAddress;
      const ano = selectedMemberData.assessmentData.demographics.data.aContact;

      const city = selectedMemberData.assessmentData.demographics.data.city;

      const state = selectedMemberData.assessmentData.demographics.data.state;

      setOriginalData({
        ...selectedMemberData,
        address: `${selectedMemberData.assessmentData.demographics.data.address} ${selectedMemberData.assessmentData.demographics.data.addressTwo} ${selectedMemberData.assessmentData.demographics.data.city}`,
        hicn: selectedMemberData.assessmentData.demographics.data.hicn,
        name: name,
        // dob:  dob,
        //dob: "23-01-2004",
        dob: formattedDob,
        primaryPhone: primaryPhone,
        secondaryPhone: secondaryPhone,
        pno: primaryPhone?.toString(),
        sno: secondaryPhone?.toString(),
        zip: zip,
        email: email,
        add1: add1,
        ano: ano,
        add2: add2,
        add3: add3,
        city: city,
        state: state,
      });
    }
  }, [selectedMemberData]);

  const [visitTypes, setVisitTypes] = useState([]);
  const [matchingUserIds, setMatchingUserIds] = useState("");

  const [editFormData, setEditFormData] = useState(originalData);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [inBoundChecked, setInBoundChecked] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [ansWOP, setAnsWOP] = useState();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [selectedScreenings, setSelectedScreenings] = useState([]);
  const [disableOnEdit, setDisableOnEdit] = useState(false);
  const [callLogData, setCallLogData] = useState({});
  const handleToggleButtonChange = (newValues) => {
    setSelectedScreenings(newValues);
  };

  const handleVphpSelection = (responseData) => {
    setResponseData(responseData);
  };

  useEffect(() => {
    const fetchVisitTypeOptions = async () => {
      try {
        const response = await getApi("/visitTypes/get-visit-types");
        const data = await response.data.data;

        const visitOptions = data.map((visit) => visit.visitType);

        setVisitTypes(visitOptions);
      } catch (error) {
        return;
      }
    };
    fetchVisitTypeOptions();
  }, []);

  const initializeFormData = (type) => {
    let initialFormData = {
      // dateOfAppointment: "",
      resultType: type,
    };

    if (["rescheduled", "scheduled"].includes(type)) {
      initialFormData = {
        ...initialFormData,
        visitTypes: "",
        clinician: "",
        comments: "",
        dateOfAppointment: "",
      };
    } else if (
      [
        "dead-air",
        "ineligible",
        "wrong-number",
        "deceased",
        "call-back-requested",
        "caller-disconnected",
        "f2f-call-back",
        "line-busy",
        "hospice",
        "refusal",
        "disconnected",
      ].includes(type)
    ) {
      initialFormData = {
        ...initialFormData,
        comments: "",
      };
    } else if (type === "no-answer") {
      initialFormData = {
        ...initialFormData,
        noAnswerOption: "",
        comments: "",
      };
    }

    setFormData(initialFormData);
  };
  const handleNoAnswerOptionChange = (event) => {
    const value = event.target.value;
    console.log("value", value);
    setAnsWOP(value);
    setFormData({
      ...formData,
      noAnswerOption: value,
    });
  };

  //  useEffect (() => {
  //     initializeFormData(resultType);
  //   }, [resultType]);

  const handleChange = (event, name) => {
    const value = event.target.value;
    if (["visitType", "comment"].includes(name)) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "resultType") {
      setResultType(value);
      //setInBoundChecked(inBoundChecked === "In Bound ");
    } else if (name === "clinician") {
      setFormData({
        ...formData,
        [name]: value,
      });

      const names = clinicianData.map(
        (user) => `${user.firstName} ${user.lastName}`,
      );

      const matchingClinicians = value.filter((item) => names.includes(item));

      if (matchingClinicians.length > 0) {
        const userIds = matchingClinicians.map((item) => {
          const matchingClinician = clinicianData.find(
            (user) => `${user.firstName} ${user.lastName}` === item,
          );

          // return matchingClinician.userid;
          const userId = matchingClinician.userid; // Keep it as a string or convert if needed

          setMatchingUserIds(userId);
        });
        // setMatchingUserIds(userIds)
      }
    } else if (name === "inBoundCheckbox" && event.target.checked) {
      setInBoundChecked(true);
      // if (resultType == "0") {
      //   setResultType("");
      // }
      //setResultType("");

      //setResultType("In Bound ")
    } else if (name === "inBoundCheckbox" && !event.target.checked) {
      setInBoundChecked(false);
      // if (resultType == "0") {
      //   setResultType("");
      // }
      //setResultType("In Bound ")
    } else if (name == "comment") {
      setFormData({
        ...formData,
        comment: value,
      });
    } else {
      //setResultType(value);
    }
  };

  const handleDateChange = (event = selectedDate) => {
    const now = new Date();
    //const event = JSON.parse(JSON.stringify(localStorage.getItem('selectedDate')));
    console.log("helloDateChange", event);
    if (event == null || event == "null") {
      const id = setTimeout(() => {
        alert("Please select date and time");
      }, 0);
      return;
    } else if (event < now) {
      const id = setTimeout(() => {
        alert("Please select valid date and time.");
      }, 0);
      return;
    }
    console.log("eventsDate", event);
    setSelectedDate(event);
    setFormData({
      ...formData,
      dateOfAppointment: event,
    });
  };
  const handleAppointmentDateChange = () => {
    const now = new Date();
    const event = JSON.parse(
      JSON.stringify(localStorage.getItem("selectedDate")),
    );
    console.log("helloDateChange", event);
    if (event == null || event == "null") {
      const id = setTimeout(() => {
        alert("Please select date and time");
      }, 0);
      return;
    } else if (new Date(event) < now) {
      const id = setTimeout(() => {
        alert("Please select valid date and time.");
      }, 0);
      return;
    }
    console.log("eventsDate", event);
    setSelectedDate(event);
    setFormData({
      ...formData,
      dateOfAppointment: event,
    });
  };

  const convertToLocalDateTime = (utcDateTimeString) => {
    const utcDate = new Date(utcDateTimeString);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
    );
    return localDate.toLocaleString();
  };

  const handleEditClick = () => {
    setEditFormData(originalData);
    setEditModalOpen(true);
  };

  const additionalConditions = [
    !postalCodeError,
    !emailError,
    !phoneNumberError,
    !secNumberError,
    !altNumberError,
  ];
  const [statusComment, setStatusComment] = useState("");
  const [cancleStatus, setCancleStatus] = useState("");

  const handleCancelAppointmentAlertYesClick = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: selectedMemberData?._id,
        hraStatus: {
          hraStatus: 3,
          subStatus: "",
          cancelStatus: cancleStatus,
          cancelText: statusComment,
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Hra Status Updated Successfully");

        const storedPlan =
          localStorage.getItem("clientPlan") || clientData?.plan;
        const storedYear =
          localStorage.getItem("clientYear") || clientData?.year;
        let defaultTabId;

        if (selectedTab === "Member List" || selectedTab === "BothUnchecked") {
          defaultTabId = 5;
        } else if (selectedTab === "Disabled Members") {
          defaultTabId = 4;
        } else if (selectedTab === "AssignedUnUnAssCh") {
          defaultTabId = 2;
        } else if (selectedTab === "BothChecked") {
          defaultTabId = 1;
        } else if (selectedTab === "AssignedChUnAssignedUn") {
          defaultTabId = 3;
        }
        await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);
        handleReload();
        handleModalClose();
      } else {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Failed to update HRA Status");
      }
      return true;
    } catch (error) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      return false;
    }

    setCancelAppointmentUpdateHraStatusPopup(false);
  };

  const onEditCallLog = (callLogData) => {
    console.log("callLogData", callLogData);
    setCallLogData(callLogData);
    setDisableOnEdit(true);
    setMatchingUserIds(selectedMemberData?.nurseId);
    setResultType(getResultTypeId(callLogData?.result) + "");
    const selectedClinician = clinicianData?.filter(
      (data) => data?.userid == selectedMemberData?.nurseId,
    );
    // formData?.visitTypes?.[0] === "In Person"
    const visitType =
      selectedMemberData?.visitType == 1
        ? ["In Person"]
        : selectedMemberData?.visitType == 2
        ? ["Virtual"]
        : [""];
    const appointmentDate = callLogData?.scheduleDate;
    const comment = callLogData?.comment;
    const inBoundChecked = callLogData?.inBound;
    const noAnswerOption = callLogData?.noAnswerOption;
    setInBoundChecked(inBoundChecked); // call Type
    setSelectedDate(appointmentDate);
    setFormData({
      ...formData,
      visitType: visitType,
      resultType: callLogData?.resultId + "", // result type
      clinician: [
        `${selectedClinician[0]?.firstName} ${selectedClinician[0]?.lastName}`,
      ],
      dateOfAppointment: appointmentDate,
      comment: [comment], // comment
      noAnswerOption: noAnswerOption,
    });
  };
  console.log("visitType", formData?.visitType);
  const getResultTypeName = (id) => {
    return callLogStatusOptionsData.find((item) => item.id == resultType)
      .statusName;
  };

  const getResultTypeId = (name) => {
    return callLogStatusOptionsData.find((item) => item.statusName == name).id;
  };

  const updateCallLog = async () => {
    const payload = {
      _id: selectedMemberData?._id, //callLogData?._id,
      resultId: Number(formData.resultType),
      visitType:
        formData?.visitType?.[0] === "In Person"
          ? "1"
          : formData?.visitType?.[0] === "Virtual"
          ? "2"
          : "", //selectedMemberData?.visitType + "", // or a meaningful value if applicable
      providerId: matchingUserIds, //selectedMemberData?.nurseId, //"65aa4acd50d79dacac2efd70",
      callLogIndex: selectedCallLogIdx, //dynamicData?.length - 1,
      callLogData: {
        callLog: [
          {
            date: new Date(),
            scheduleDate: formData?.dateOfAppointment
              ? new Date(formData?.dateOfAppointment)?.toISOString()
              : null, //"2024-08-17T13:00:00.000Z",
            scheduler: callLogData?.scheduler,
            schedulerId: "",
            resultId: Number(formData.resultType), // can be edited
            result: getResultTypeName(Number(formData.resultType)), //callLogData?.result,    // can be edited
            noAnswer: "",
            // comment: formData?.comment[0], // can be edited
            comment: Array.isArray(formData.comment)
              ? formData.comment.join(" ")
              : formData.comment,
            callbackDate: "",
            inBound: inBoundChecked, // can be edited
          },
        ],
        utc: "true", //"2024-08-13T09:43:04.222Z", // not sure what is this for
      },
    };

    const response = await postApi("/scheduler/update-callLog", payload);
    if (response.status === 200) {
      // handleModalClose();
      // setDynamicData((prevData) => [
      //   ...prevData,
      //   ...payload?.callLogData?.callLog,
      // ]);
      setDynamicData(response?.data?.data);

      // await fetchSchedulerMemberData(storedPlan, storedYear, 5);
      setToasterOpen(true);
      setToasterSeverity(Labels.SUCCESS);
      setToasterMessage("Call Log Updated Successfully");
      setTimeout(async () => {
        handleModalClose();
        await _fetchSchedulerMemberData(storedPlan, storedYear, 5);
      }, 1000);
    } else {
    }
  };
  const [deleteCallLogDialog, setDeleteCallLogDialog] = useState(false);
  const [selectedCallLogIdx, setSelectedCallLogIdx] = useState();
  const onDeleteCallLog = (callLogIndex) => {
    setDeleteCallLogDialog(true);
    setSelectedCallLogIdx(callLogIndex);
  };

  const deleteCallLog = async () => {
    const payload = {
      _id: selectedMemberData?._id,
      callLogIndex: selectedCallLogIdx, //dynamicData?.length - 1,  // selectedCallLogIdx
    };
    const response = await postApi("/scheduler/delete-callLog", payload);
    setDynamicData(response?.data?.data);
    setToasterOpen(true);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("Call Log Deleted Successfully");
    setDeleteCallLogDialog(false);
    setSelectedCallLogIdx(undefined);
  };

  const handleReferralFormOpen = async () => {
    const refId = sessionStorage.getItem("refId");
    // const roleId = sessionStorage.getItem("roleId");

    // const referralIdToUse = roleId == "5" ? refId : referralId;

    try {
      const response = await getApi(
        `/referral-form/?id=${uniqueMemberId}&referralId=${refSchId || refId}`,
      );

      const data = response?.data?.data?.[0];
      //setReferralType(data?.type);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setReferralFormPopup(true);
  };
  return (
    <>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        message={toasterMessage}
        handleClose={() => setToasterOpen(false)}
      />
      <BootstrapDialog
        onClose={async () => {
          ModalClose();
          await _fetchSchedulerMemberData(storedPlan, storedYear, 5);
        }}
        aria-labelledby="customized-dialog-title"
        open={true}
        className="history-dialog"
      >
        <div className="dialog-header">
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Call Log - {originalData?.name} {originalData?.memberId}{" "}
          </DialogTitle>
        </div>
        <DialogContent>
          <div className="screening-needed-section">
            <ThemeProvider theme={myTheme}>
              <FormControl component="fieldset">
                <div>
                  <FormLabel
                    sx={{
                      color: "black",
                      fontWeight: 600,
                      marginRight: "10px",
                      backgroundColor: "white !important",
                    }}
                  >
                    Screenings Needed:
                  </FormLabel>
                  <ToggleButtonGroup
                    //value={screeningOptions.filter(isScreeningChecked)}
                    // value={[screeningOptions[0].value]}
                    value={screenSelected}
                    onChange={(event, newValues) =>
                      handleToggleButtonChange(newValues)
                    }
                    exclusive
                  >
                    {screeningOptions.map((screeningType) => (
                      <ToggleButton
                        key={screeningType}
                        value={screeningType.value}
                        //disabled={isScreeningDisabled(screeningType)}
                        disabled={true}
                        style={{
                          backgroundColor: screenSelected.includes(
                            screeningType.value,
                          ) //isScreeningChecked(screeningType)
                            ? "var(--mainTheme)"
                            : "var(--mainTheme300)",
                          color: screenSelected.includes(screeningType.value) //isScreeningChecked(screeningType)
                            ? "white"
                            : "#2b2b2b",
                          marginRight: "5px",
                          borderRadius: "40px",
                          border: "none",
                          fontSize: "12px",
                          opacity: "1",
                          // fontWeight: isScreeningChecked(screeningType)
                          //   ? 600
                          //   : 600,
                          borderLeft: 0,
                        }}
                      >
                        {screeningType?.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </div>
              </FormControl>
            </ThemeProvider>
          </div>
          <div className="modal-content-body">
            <div className="modal-left-content">
              <div className="edit-fields">
                <div className="member-details">
                  <ul>
                    <li>
                      Date of Birth:{" "}
                      <span>
                        {moment(originalData.dob).format("MM-DD-YYYY") || ""}
                      </span>
                    </li>

                    <li>
                      Address: <span>{originalData.address || ""}</span>
                    </li>
                    <li>
                      Primary Phone:{" "}
                      <span> {originalData.primaryPhone || ""}</span>
                    </li>
                    <li>
                      Secondary Phone:{" "}
                      <span>{originalData.secondaryPhone || ""}</span>
                    </li>
                  </ul>
                </div>
                <div className="edit-mem-details">
                  <IconButton
                    onClick={handleEditClick}
                    className="edit-button"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    <FaRegEdit />
                  </IconButton>
                </div>
              </div>
              <div className="table-section">
                <div style={{ maxHeight: "300px", overflow: "auto" }}>
                  <table
                    className="table"
                    style={{
                      width: "100%",
                      tableLayout: "auto",
                      maxHeight: "180px",
                      overflow: "auto",
                      borderSpacing: 0,
                    }}
                  >
                    <thead style={{ zIndex: 1000 }}>
                      <tr>
                        <td nowrap="nowrap" style={{ width: "200px" }}>
                          Date / Time
                        </td>
                        <td>Scheduler</td>
                        <td>Result</td>
                        <td>Comments</td>
                        <td nowrap="nowrap">Call Type</td>
                        <td
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Edit
                        </td>
                        {["1", "2"].includes(roleId) ? <td>Delete</td> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {dynamicData
                        ?.slice(0)
                        ?.reverse()
                        ?.map((data, index) => (
                          <tr key={index}>
                            <td nowrap="nowrap">
                              {dayjs(data?.date)
                                .tz(selectedTimeZoneUtc)
                                .format("MM-DD-YYYY h:mm A")}
                            </td>
                            {/* <td>{convertToLocalDateTime(data.date)}</td> */}
                            <td nowrap="nowrap">{data.scheduler}</td>
                            <td nowrap="nowrap">{data.result}</td>
                            <td nowrap="nowrap">{data.comment}</td>
                            <td nowrap="nowrap">
                              {data.inBound ? "Inbound" : "Outbound"}
                            </td>
                            <td nowrap="nowrap">
                              <div className="edit-mem-details">
                                <IconButton
                                  disabled={
                                    ["3", "4"].includes(roleId)
                                      ? index != 0
                                      : ["1", "2"].includes(roleId)
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    onEditCallLog(data);
                                    setSelectedCallLogIdx(
                                      dynamicData?.length - (index + 1),
                                    );
                                  }}
                                  className="edit-button"
                                  disableFocusRipple
                                  disableRipple
                                  disableTouchRipple
                                >
                                  <FaRegEdit />
                                </IconButton>
                              </div>
                            </td>
                            {["1", "2"].includes(roleId) ? (
                              <td
                                nowrap="nowrap"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{ marginLeft: "-20px" }}
                                  className="edit-mem-details"
                                >
                                  <IconButton
                                    disabled={["3", "4"].includes(roleId)}
                                    onClick={() => {
                                      console.log("delete");
                                      onDeleteCallLog(
                                        dynamicData?.length - (index + 1),
                                      );
                                      // setSelectedCallLogIdx(dynamicData?.length - (index+1))
                                    }}
                                    className="edit-button"
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                  >
                                    <MdDelete />
                                  </IconButton>
                                </div>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-right-content">
              <div>
                <FormControl>
                  <div
                    style={{
                      backgroundColor: "var(--mainTheme200)",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="result-type-topbar">
                      <div className="result-type-left">
                        <h4>Result Type</h4>
                      </div>
                      <div className="result-type-right">
                        {callLogStatusOptionsData.map((data) =>
                          data?.id == "-1" ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                //  disabled={!resultType}
                                />
                              }
                              label={data?.statusName}
                              value={data?.id}
                              name="inBoundCheckbox"
                              checked={inBoundChecked}
                              onChange={(event) =>
                                handleChange(event, "inBoundCheckbox")
                              }
                              style={{ color: "black", fontWeight: "bold" }}
                            />
                          ) : null,
                        )}
                      </div>
                    </div>
                    <Grid container alignItems="center" justify="space-between">
                      <div className="result-type-options">
                        <ThemeProvider theme={myThemes}>
                          <ToggleButtonGroup
                            value={resultType}
                            exclusive
                            onChange={(event, newResultType) =>
                              handleChange(event, "resultType", newResultType)
                            }
                          >
                            {callLogStatusOptionsData.map((data) =>
                              data?.id != "-1" ? (
                                <ToggleButton
                                  key={data?.id}
                                  value={(data?.id).toString()}
                                >
                                  {data?.statusName}
                                </ToggleButton>
                              ) : null,
                            )}
                          </ToggleButtonGroup>
                        </ThemeProvider>
                      </div>
                    </Grid>
                  </div>
                </FormControl>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  padding: "10px 20px 10px 5px",
                  ...(resultType
                    ? { backgroundColor: "var(--mainTheme200)" }
                    : {}),
                }}
              >
                {/* if result type as scheduled (0), rescheduled (12)*/}
                {resultType && ["0", "12"].includes(resultType) && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={4}>
                      <MuiInput
                        disabled={
                          disableOnEdit &&
                          selectedCallLogIdx !== dynamicData?.length - 1
                        }
                        label="Visit Type"
                        className="inputAddMembers"
                        type="select"
                        name="visitType"
                        value={formData?.visitType ? formData?.visitType : [""]}
                        onChange={(event) => handleChange(event, "visitType")}
                        required={true}
                        options={visitTypes}
                        labelBackgroundColor="transparent !important"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <MuiInput
                        //  disabled={disableOnEdit}
                        disabled={
                          disableOnEdit &&
                          selectedCallLogIdx !== dynamicData?.length - 1
                        }
                        label="Clinician"
                        className="inputAddMembers"
                        type="select"
                        value={formData.clinician || ""}
                        onChange={(event) => handleChange(event, "clinician")}
                        name="clinician"
                        required={true}
                        options={clinicianOptions}
                        labelBackgroundColor="transparent !important"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                      <LocalizationProvider
                        disablePast={true}
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer components={["DateTimePicker"]}>
                          <DateTimePicker
                            //disablePast={true}
                            disabled={
                              disableOnEdit &&
                              selectedCallLogIdx !== dynamicData?.length - 1
                            }
                            minutesStep={1}
                            timeSteps={{ minutes: 1 }}
                            //defaultValue= {dayjs(new Date())}
                            minDate={dayjs(new Date())}
                            label="Appointment Date / Time"
                            id="datetime-local"
                            format="MM/DD/YYYY hh:mm a"
                            views={["year", "month", "day", "hours", "minutes"]}
                            ampm
                            autoOk={false}
                            closeOnSelect={true}
                            onClose={() => {
                              setTimeout(() => {
                                handleAppointmentDateChange();
                              }, 0);
                            }}
                            variant="outlined"
                            // autoOk={false}
                            onChange={(newValue) => {
                              console.log("newValue", newValue);
                              setSelectedDate(
                                newValue == null ? null : new Date(newValue),
                              );
                              localStorage.setItem(
                                "selectedDate",
                                newValue == null ? null : new Date(newValue),
                              );
                              //handleDateChange(newValue == null ? null : new Date(newValue));
                            }}
                            // value={
                            //   selectedDate
                            //     ? dayjs(selectedDate).tz(selectedTimeZoneUtc)
                            //     : dayjs()

                            // }
                            // value={
                            //   selectedDate
                            //     ? dayjs(
                            //         dayjs(selectedDate || "")
                            //           .tz(selectedTimeZoneUtc)
                            //           .format("lll"),
                            //       )
                            //     : null
                            // }
                            slotProps={{
                              textField: {
                                disabled: disableOnEdit,
                                variant: "outlined",
                                value: formData?.dateOfAppointment
                                  ? dayjs(
                                      dayjs(formData?.dateOfAppointment || "")
                                        .tz(selectedTimeZoneUtc)
                                        .format("lll"),
                                    )
                                  : null,
                              },

                              popper: { placement: "auto-start" },
                            }}
                            sx={{
                              "&.MuiTextField-root .MuiFormLabel-root": {
                                background: "var(--mainTheme200) !important",
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid var(--mainTheme)",
                                fontSize: "13px",
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                                padding: "15px 2px !important",
                                fontSize: "13px",
                              },

                              "&.MuiTextField-root": {
                                minWidth: "1px",
                                paddingLeft: "4px",
                              },
                              "& .MuiIconButton-root": {
                                justifyContent: "flex-end",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )}

                {/* if result type as No answer (3)*/}
                {resultType && ["3"].includes(resultType) && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl component="fieldset">
                        <ThemeProvider theme={myThemes}>
                          <ToggleButtonGroup
                            exclusive
                            aria-label="no-answer-options"
                            name="no-answer-options"
                            value={formData.noAnswerOption}
                            onChange={(event, newNoAnswerOption) =>
                              handleNoAnswerOptionChange(
                                event,
                                newNoAnswerOption,
                              )
                            }
                            style={{ width: "100%", marginTop: "15px" }}
                            row
                          >
                            <ToggleButton
                              value="Left Voicemail"
                              aria-label="Left Voicemail"
                              style={{ width: "130px" }}
                            >
                              Left Voicemail
                            </ToggleButton>
                            <ToggleButton
                              value="Mailbox Full"
                              aria-label="Mailbox Full"
                              style={{ width: "130px" }}
                            >
                              Mailbox Full
                            </ToggleButton>
                            <ToggleButton
                              value="No VM Setup"
                              aria-label="No VM Setup"
                              style={{ width: "130px" }}
                            >
                              No VM Setup
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </ThemeProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl required>
                        {/* <MuiInput
                          label="Next Attempt Date/Time"
                          className="inputAddMembers"
                          type="datetime-local"
                          value={selectedDate || ""}
                          onChange={handleDateChange}
                          required={true}
                          style={{ marginLeft: "60px" }}
                          InputLabelProps={{
                            sx: {
                              backgroundColor: "transparent !important ",
                            },
                          }}
                        /> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DateTimePicker", "DateTimePicker"]}
                          >
                            <DateTimePicker
                              label="Next Attempt Date/Time"
                              id="datetime-local"
                              format="MM/DD/YYYY hh:mm a"
                              views={[
                                "year",
                                "month",
                                "day",
                                "hours",
                                "minutes",
                              ]}
                              ampm
                              variant="outlined"
                              autoOk={false}
                              onChange={(newValue) => {
                                handleDateChange(new Date(newValue));
                              }}
                              value={
                                selectedDate
                                  ? dayjs(
                                      dayjs(selectedDate || "")
                                        .tz(selectedTimeZoneUtc)
                                        .format("lll"),
                                    )
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  disabled: true,
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "&.MuiTextField-root .MuiFormLabel-root": {
                                  background: "var(--mainTheme200) !important",
                                  fontSize: "14px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  border: "1px solid var(--mainTheme)",
                                },
                                "&.MuiTextField-root": {
                                  minWidth: "1px",
                                  paddingLeft: "8px",
                                  marginLeft: "50px",
                                },
                                "& .MuiIconButton-root": {
                                  justifyContent: "flex-end",
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider> */}
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {/* result type as caller disconnected(13), line busy(7), disconnected(10), dead air(1) */}
                {/* {resultType && ["13", "7", "10", "1"].includes(resultType) && ( */}
                {/* {resultType && ["1"].includes(resultType) && (
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      {/* <MuiInput
                        label="Next Attempt Date/Time"
                        className="inputAddMembers"
                        type="datetime-local"
                        value={selectedDate || ""}
                        onChange={handleDateChange}
                        required={true}
                        InputLabelProps={{
                          sx: {
                            backgroundColor: "transparent !important ",
                          },
                        }}
                      /> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DateTimePicker", "DateTimePicker"]}
                        >
                          <DateTimePicker
                            label="Next Attempt Date/Time"
                            id="datetime-local"
                            format="MM/DD/YYYY hh:mm a"
                            views={["year", "month", "day", "hours", "minutes"]}
                            ampm
                            variant="outlined"
                            autoOk={false}
                            onChange={(newValue) => {
                              handleDateChange(new Date(newValue));
                            }}
                            value={
                              selectedDate
                                ? dayjs(
                                    dayjs(selectedDate || "")
                                      .tz(selectedTimeZoneUtc)
                                      .format("lll"),
                                  )
                                : null
                            }
                            slotProps={{
                              textField: {
                                disabled: true,
                                variant: "outlined",
                              },
                            }}
                            sx={{
                              "&.MuiTextField-root .MuiFormLabel-root": {
                                background: "var(--mainTheme200) !important",
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid var(--mainTheme)",
                              },
                              "&.MuiTextField-root": {
                                minWidth: "1px",
                                paddingLeft: "8px",
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "14px !important", // Adjust the padding value as needed
                              },
                              "& .MuiIconButton-root": {
                                justifyContent: "flex-end",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )} */}

                {/* result type as call back requested(6), F2F call back(14)*/}
                {resultType && ["6", "14"].includes(resultType) && (
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
                      {/* <MuiInput
                        label="Callback Date/Time"
                        className="inputAddMembers"
                        type="datetime-local"
                        value={selectedDate || ""}
                        onChange={handleDateChange}
                        required={true}
                        InputLabelProps={{
                          sx: {
                            backgroundColor: "transparent !important ",
                          },
                        }}
                      /> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DateTimePicker", "DateTimePicker"]}
                        >
                          <DateTimePicker
                            label="Callback Date/Time"
                            id="datetime-local"
                            format="MM/DD/YYYY hh:mm a"
                            views={["year", "month", "day", "hours", "minutes"]}
                            ampm
                            disablePast={true}
                            variant="outlined"
                            autoOk={false}
                            onChange={(newValue) => {
                              handleDateChange(new Date(newValue));
                            }}
                            value={
                              selectedDate
                                ? dayjs(
                                    dayjs(selectedDate || "")
                                      .tz(selectedTimeZoneUtc)
                                      .format("lll"),
                                  )
                                : null
                            }
                            slotProps={{
                              textField: {
                                disabled: true,
                                variant: "outlined",
                              },
                            }}
                            sx={{
                              "&.MuiTextField-root .MuiFormLabel-root": {
                                background: "var(--mainTheme200) !important",
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid var(--mainTheme)",
                              },
                              "&.MuiTextField-root": {
                                minWidth: "1px",
                                paddingLeft: "8px",
                              },
                              "& .MuiIconButton-root": {
                                justifyContent: "flex-end",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )}

                {(!_.isEmpty(resultType) || inBoundChecked) && (
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: { xs: "1vw", md: "0.5vw" } }}
                  >
                    {/* <MuiInput
                    autoFocus = {true}
                      label="Comments"
                      className="inputAddMembers"
                      type="input"
                      value={formData?.comment}
                      onChange={(event) => handleChange(event, "comment")}
                      //required={true}
                      multiline
                      style={{ minWidth: "100%" }}
                      InputLabelProps={{
                        sx: {
                          backgroundColor: "transparent !important ",
                        },
                      }}
                    /> */}
                    <TextField
                      autoFocus={true}
                      label="Comments"
                      variant="outlined"
                      className="inputAddMembers"
                      type="input"
                      value={formData?.comment ?? ""}
                      onChange={(event) => handleChange(event, "comment")}
                      multiline
                      style={{ minWidth: "100%" }}
                      InputLabelProps={{
                        sx: {
                          backgroundColor: "transparent !important ",
                        },
                      }}
                    />

                    <div>
                      {resultType && ["8", "5"].includes(resultType) && (
                        <Button
                          sx={{
                            backgroundColor: "var(--mainTheme900)",
                            color: "white",
                            width: "30%",
                            padding: "21px 16px",
                            marginRight: "10px",
                            "&:hover": {
                              backgroundColor: "var(--mainTheme500)",
                            },
                          }}
                          //  autoFocus
                          onClick={handleReferralFormOpen} // Handle button click
                          // onClick={handleOpenReviewDialog}
                        >
                          Referral Form
                        </Button>
                      )}
                    </div>
                  </Grid>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="button-containers">
            <div className="left-buttons">
              <button
                className="left-button"
                disabled={
                  selectedMemberData?.hraStatus?.hraStatus == 1 ||
                  selectedMemberData?.hraStatus?.hraStatus == 2
                    ? false
                    : true
                }
                onClick={() => {
                  setResultType("");
                  setNoShowUpdateHraStatusPopup(true);
                }}
                style={{ outline: "none", cursor: "pointer" }}
              >
                No Show
              </button>
              <button
                className="left-button"
                disabled={
                  selectedMemberData?.hraStatus?.hraStatus == 1 ||
                  selectedMemberData?.hraStatus?.hraStatus == 2
                    ? false
                    : true
                }
                onClick={() => {
                  setResultType("");
                  setCancelAppointmentUpdateHraStatusPopup(true);
                }}
                style={{ outline: "none", cursor: "pointer" }}
              >
                Appointment Cancelled
              </button>
            </div>
            <div className="right-buttons">
              <button
                onClick={disableOnEdit ? updateCallLog : handleAddCall}
                className="right-button"
                //disabled={!validateForm()}
                disabled={
                  !disableOnEdit &&
                  ["0", "6", "12", "14"].includes(resultType) &&
                  !selectedDate
                }
                style={{ outline: "none", cursor: "pointer" }}
              >
                {disableOnEdit ? "Update" : "Log"}
              </button>
              <button
                onClick={async () => {
                  handleModalClose();
                  await _fetchSchedulerMemberData(storedPlan, storedYear, 5);
                }}
                className="right-button"
                style={{ outline: "none", cursor: "pointer" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={isEditModalOpen}
        onClose={handleCancelEdit}
        fullWidth
        maxWidth="md"
        alignItems="center"
        textAlign="center"
      >
        <div className="modals">
          <div className="modal-header">
            <h4 style={{ textAlign: "left" }}>
              Edit Fields
              <CloseIcon
                sx={{
                  color: "white",
                  cursor: "pointer",
                  float: "right",
                }}
                onClick={handleCancelEdit}
              />
            </h4>
          </div>
          <div className="modal-body">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Member Address 1:</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.add1}
                    onChange={(e) => handleEditChange(e, "add1")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("add1")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Member Address 2:</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.add2 || ""}
                    onChange={(e) => handleEditChange(e, "add2")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("add2")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Member Address 3:</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.add3}
                    onChange={(e) => handleEditChange(e, "add3")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("add3")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>City</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.city}
                    onChange={(e) => handleEditChange(e, "city")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("city")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>State</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.state}
                    onChange={(e) => handleEditChange(e, "state")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("state")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Zip Code</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.zip}
                    onChange={(e) => handleEditChange(e, "zip")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("zip")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {postalCodeError && (
                    <h3
                      className="password"
                      style={{
                        color: "#d32f2f",
                        lineHeight: "14px",
                        width: "400px",
                        marginTop: "-21px",
                      }}
                    >
                      {postalCodeError}
                    </h3>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Primary Number</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.pno}
                    onChange={(e) => handleEditChange(e, "pno")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("pno")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {phoneNumberError && (
                    <h3
                      className="password"
                      style={{
                        color: "#d32f2f",
                        lineHeight: "14px",
                        width: "400px",
                        marginTop: "-21px",
                      }}
                    >
                      {phoneNumberError}
                    </h3>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Secondary Number</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.sno}
                    onChange={(e) => handleEditChange(e, "sno")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("sno")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {secNumberError && (
                    <h3
                      className="password"
                      style={{
                        color: "#d32f2f",
                        lineHeight: "14px",
                        width: "400px",
                        marginTop: "-21px",
                      }}
                    >
                      {secNumberError}
                    </h3>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Alternate Number</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.ano}
                    onChange={(e) => handleEditChange(e, "ano")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("ano")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {altNumberError && (
                    <h3
                      className="password"
                      style={{
                        color: "#d32f2f",
                        lineHeight: "14px",
                        width: "400px",
                        marginTop: "-21px",
                      }}
                    >
                      {altNumberError}
                    </h3>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Email</InputLabel>
                  <MuiInput
                    className="inputAddMembers"
                    type="input"
                    value={editFormData.email}
                    onChange={(e) => handleEditChange(e, "email")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClearField("email")}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {emailError && (
                    <h3
                      className="password"
                      style={{
                        color: "#d32f2f",
                        lineHeight: "14px",
                        width: "400px",
                        marginTop: "-21px",
                      }}
                    >
                      {emailError}
                    </h3>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "5px",
              }}
            >
              <Button
                aria-hidden={false}
                style={{
                  backgroundColor: "var(--mainTheme)",
                  color: "white",
                  width: "80px",
                  marginLeft: "5px",
                }}
                onClick={handleCancelEdit}
              >
                Cancel
              </Button>
              <Button
                aria-hidden={false}
                style={{
                  backgroundColor: "var(--mainTheme)",
                  color: "white",
                  width: "80px",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  handleSaveChanges();
                  setEditModalOpen(false);
                }}
                // disabled={saveButtonDisabled}
                disabled={!additionalConditions.every((condition) => condition)}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      {emptyResultTypePopup && (
        <Alert
          open={emptyResultTypePopup}
          // dialogTitle={`Result Type`}
          handleClose={() => setEmptyResultTypePopup(false)}
          title={`Please select any Result type?`}
          cancelText="Ok"
          onNoClick={() => setEmptyResultTypePopup(false)}
        />
      )}
      {noShowUpdateHraStatusPopup && (
        <Alert
          open={noShowUpdateHraStatusPopup}
          dialogTitle={`No Show`}
          handleClose={() => setNoShowUpdateHraStatusPopup(false)}
          title={`Are you sure you would like to change the hra status to No Show ?`}
          okText="Yes"
          cancelText="No"
          onAddClick={async () => {
            noshowCancel = "No Show";
            const isStored = await customUpdateHraStatus();
            if (isStored) handleAddCall();
          }}
          onNoClick={() => setNoShowUpdateHraStatusPopup(false)}
        />
      )}
      {deleteCallLogDialog && (
        <Alert
          open={deleteCallLogDialog}
          dialogTitle={`Delete Call Log`}
          handleClose={() => {
            setDeleteCallLogDialog(false);
            setSelectedCallLogIdx(undefined);
          }}
          title={`Are you sure you would like to delete the call Log ?`}
          okText="Yes"
          cancelText="No"
          onAddClick={() => {
            deleteCallLog();
          }}
          onNoClick={() => {
            setDeleteCallLogDialog(false);
            setSelectedCallLogIdx(undefined);
          }}
        />
      )}
      {cancelAppointmentUpdateHraStatusPopup && (
        <AppointmentModal
          open={cancelAppointmentUpdateHraStatusPopup}
          handleClose={() => setCancelAppointmentUpdateHraStatusPopup(false)}
          onSave={async (cancelComment) => {
            noshowCancel = "Appointment Cancelled";
            const isStored =
              await handleCancelAppointmentAlertYesClick(cancelComment);
            if (isStored) handleAddCall();
          }}
          setCancleStatus={setCancleStatus}
          setStatusComment={setStatusComment}
          onExit={() => {
            setCancelAppointmentUpdateHraStatusPopup(false);
            setStatusComment("");
            setCancleStatus("");
          }}
          statusComment={statusComment}
          cancleStatus={cancleStatus}
        />
      )}
      {/* {showForm && (
        <CriticalReferralForm open={showForm} onClose={handleCloseForm} />
      )}{" "} */}
      {referralFormPopup && (
        <ReferralForm
          open={handleReferralFormOpen}
          onClose={handleReferralFormClose}
          refSchId={refSchId}
          uniqueMemberId={uniqueMemberId}
          schedulerName={schedulerName}
          dateOfBirth={dateOfBirth}
          schedulerMemberId={schedulerMemberId}
          schedulerPrimary={schedulerPrimary}
          schedulerDateOfReferral={schedulerDateOfReferral}
          showCriticalOnly={true} // Pass the prop to show only Critical form
        />
      )}
      {/* Conditionally render the form */}
      {isLoading && <Loader />}
    </>
  );
}
