// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-amtenties {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-amtenties .left-amentities {
  width: 40%;
  display: flex;
  justify-content: flex-start;
}
.filter-amtenties .right-amentities {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/SchedulerDashboard/scheduler.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACI,UAAA;EACA,aAAA;EACA,2BAAA;AACR;AAEI;EACI,UAAA;EACA,aAAA;EACA,yBAAA;AAAR","sourcesContent":[".filter-amtenties {\r\n    margin-bottom: 20px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n\r\n    .left-amentities {\r\n        width: 40%;\r\n        display: flex;\r\n        justify-content: flex-start;\r\n    }\r\n\r\n    .right-amentities {\r\n        width: 60%;\r\n        display: flex;\r\n        justify-content: flex-end;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
