import MuiInput from "../../../muiinput/MuiInput";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import "../AddClient/AddClient.css";
import Toaster from "../../../toaster/toaster";
import Labels from "../../../../resource_files/Labels.resx";
import Alert from "../../../Alert/Alert";
import ValidationMessage from "../../../../resource_files/ValidationMessage.resx";
import { getApi, putApi } from "../../../../utils/axiosApiWrapper";
import { getStateName } from "../../../../utils/getUSStateName";
import { zcs } from "zcs";
//import { planOptions, programOptions } from "../../../utils";
import { stateNames } from "../../ReactDND/userCategories";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
const ZCS = zcs();

const initialClientData = {
  clientName: "",
  plan: "",
  healthPlanName: "",
  address: "",
  city: "",
  county: "",
  state: [],
  zipCode: "",
  phoneNumber: "",
  email: "",
  notes: "",
  yearList: [],
  feeSchedule: [],
  visitTypes: [],
  assessmentTypes: [],
  lob: [],
  program: [],
};

const EditClient = ({
  fetchClientData,
  onClose,
  editMode,
  setEditMode,
  selectedClientData,
  setSelectedClientData,
  apiData,
  setApiData,
  appendYearToYearsList,
}) => {
  const [errors, setErrors] = useState({});
  const [toasterMessage, setToasterMessage] = useState("");
  const [clientData, setClientData] = useState(initialClientData);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [showAlternateDropdown, setShowAlternateDropdown] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [programInputValue, setProgramInputValue] = useState("");
  const [clientDatas, setClientDatas] = useState({
    visitTypes: [],
    assessmentTypes: [],
    program: [],
    lob: [],
  });
  const screeningTypeOptions = [
    "A1C",
    "FIT",
    "LDL",
    "PAD",
    "Retinal Eye Exam",
    "DEXA",
    "Urine- Microalbumin",
    "KED",
  ];
  useEffect(() => {
    const fetchVisitTypes = async () => {
      try {
        const response = await getApi("/visitTypes/get-visit-types");
        const responseData = await response.data.data;

        // if (responseData && Array.isArray(responseData)) {
        const visitTypesOptions = responseData.map((visit) => visit.visitType);
        setClientDatas((prevData) => ({
          ...prevData,
          visitTypes: visitTypesOptions,
        }));
      } catch (error) {
        return;
      }
    };

    fetchVisitTypes();
  }, []);

  useEffect(() => {
    const fetchAssessmentTypes = async () => {
      try {
        const response = await getApi("/assessmentTypes/list");

        if (response.status === 200) {
          const responseData = await response.data.data;

          if (responseData && Array.isArray(responseData)) {
            const assessmentTypesOptions = responseData.map(
              (assessmentType) => assessmentType.assessmentName,
            );
            setClientDatas((prevData) => ({
              ...prevData,
              assessmentTypes: assessmentTypesOptions,
            }));
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }
    };

    fetchAssessmentTypes();
  }, []);

  const [programOptions, setProgramOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [responseData, setResponseData] = useState(null);

  // useEffect(() => {
  //   const fetchProgramTypes = async () => {
  //     try {
  //       const response = await getApi("/programTypes/list");

  //       if (response.status === 200) {
  //         const responseData = await response.data.data;

  //         if (responseData && Array.isArray(responseData)) {
  //           const programTypesOptions = responseData.map(
  //             (programType) => programType.programName,
  //           );
  //           setClientDatas((prevData) => ({
  //             ...prevData,
  //             program: programTypesOptions,
  //           }));
  //         } else {
  //           return;
  //         }
  //       } else {
  //         return;
  //       }
  //     } catch (error) {
  //       return;
  //     }
  //   };

  //   fetchProgramTypes();
  // }, []);

  // useEffect(() => {
  //   const filteredProgramOptions = (responseData || [])
  //     .filter((programType) => {
  //       const selectedPlanName = selectedPlan
  //         ? selectedPlan[0]?.toLowerCase()
  //         : "";
  //       return programType.planName.toLowerCase() === selectedPlanName;
  //     })
  //     .map((programType) => programType.programName);
  //        console.log('clientDatas.program',responseData);
  //   setProgramOptions(responseData);

  //   setClientDatas((prevData) => ({
  //     ...prevData,
  //     program: responseData,
  //   }));
  // }, [selectedPlan, responseData]);

  useEffect(() => {
    const fetchProgramTypes = async () => {
      try {
        const response = await getApi("/programTypes/list");

        if (response.status === 200) {
          const responseData = await response?.data?.data;

          if (responseData && Array.isArray(responseData)) {
            const programTypesOptions = responseData.map(
              (programType) => programType.programName,
            );
            console.log("responseDataProg", responseData);
            setClientDatas((prevData) => ({
              ...prevData,
              program: responseData, //programTypesOptions,
            }));
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }
    };

    fetchProgramTypes();
  }, []);

  useEffect(() => {
    const fetchLobTypes = async () => {
      try {
        const response = await getApi("/lobTypes/list");

        if (response.status === 200) {
          const responseData = await response.data.data;

          if (responseData && Array.isArray(responseData)) {
            const lobTypesOptions = responseData.map(
              (lobType) => lobType.lobName,
            );
            setClientDatas((prevData) => ({
              ...prevData,
              lob: responseData,
            }));
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }
    };

    fetchLobTypes();
  }, []);

  const isFormValid = Object.values(clientData).every(
    (value) =>
      (typeof value === "string" && value.trim() !== "") ||
      (typeof value === "object" && value !== null && value !== undefined),
  );
  const additionalConditions = [!zipCodeError, !emailError, !phoneNumberError];
  const ValidationError = ({ error }) => {
    return error ? (
      <h3
        className="password"
        style={{
          color: "#d32f2f",
          lineHeight: "14px",
          width: "400px",
          marginTop: "-21px",
        }}
      >
        {error}
      </h3>
    ) : null;
  };

  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "20px",
    background: "var(--mainTheme)",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      color: "var(--mainTheme)",
    },
  };

  const getLocationInfoByZip = (zipCode) => {
    const parsedZipCode = parseInt(zipCode, 10);
    const locationInfo = ZCS.getByZip(parsedZipCode);
    return locationInfo;
  };

  useEffect(() => {
    if (clientData.zipCode) {
      try {
        if (
          !/^(0|\d+)$/.test(clientData.zipCode) &&
          !/^\d{5}$/.test(clientData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);

          return;
        }
        const locationInfo = getLocationInfoByZip(clientData.zipCode);
        if (locationInfo?.city && locationInfo?.state) {
          const stateFullName = getStateName(locationInfo?.state);
          setClientData((prevClientData) => ({
            ...prevClientData,
            city: locationInfo.city,
            state: [stateFullName.toUpperCase()],
          }));

          setZipCodeError("");
        } else {
          setZipCodeError("Invalid zip code");
        }
      } catch (error) {
        if (
          !/^(0|[1-9][0-9]*)$/.test(clientData.zipCode) &&
          !/^\d{5}$/.test(clientData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);
        } else {
          setZipCodeError("Error fetching location information");
        }
      }
    }
  }, [clientData.zipCode]);

  const handleInputChange = (fieldName, inputValue) => {
    if (fieldName === "zipCode") {
      if (!inputValue) {
        setZipCodeError(ValidationMessage.Zipcode_Required);
      } else {
        setZipCodeError("");
        setClientData((prevClientData) => ({
          ...prevClientData,
          zipCode: inputValue,
        }));
      }
    } else if (fieldName === "phoneNumber") {
      if (!inputValue) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Required);
      } else if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          inputValue,
        )
      ) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else {
        setPhoneNumberError("");
      }
    } else if (fieldName === "email") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Required);
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Format_Invalid);
      } else {
        setEmailError("");
      }
    }

    handleChange(fieldName, inputValue);
  };

  const handleChange = (field, value) => {
    if (
      field === "visitTypes" ||
      field === "feeSchedule" ||
      field === "assessmentTypes" ||
      field === "lob" ||
      field === "state" ||
      field === "yearList" ||
      field === "screeningType"
    ) {
      const sortedValues = Array.isArray(value) ? [...value].sort() : [value];
      setClientData((prevData) => ({
        ...prevData,
        [field]: sortedValues,
      }));
    } else if (field === "program") {
      const structuredProgram = value.map((programItem, index) => {
        if (typeof programItem === "object") {
          return {
            id: programItem.id || index.toString(),
            name: programItem.name || "",
          };
        } else {
          return {
            id: index.toString(),
            name: programItem,
          };
        }
      });

      setClientData((prevData) => ({
        ...prevData,
        [field]: structuredProgram,
      }));
    } else {
      setClientData((prevData) => ({
        ...prevData,
        [field]: value.toString(),
      }));
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
  };

  const handleUpdate = async () => {
    const selectedLob = clientDatas.lob?.filter((lob) =>
      clientData.lob?.includes(lob?._id),
    );
    const structuredProgran = [clientData.program]?.map((prog) => {
      prog = {
        id: "1", //prog?.clientId,
        name: prog?.programName,
        yearList: prog?.createdDateYear?.map((date) => date?.year + ""), //[2023, 2024],
        isActive: true,
        lob: selectedLob?.map((lob, ind) => {
          return {
            id: ind + 1 + "",
            name: lob?.lobName,
            isActive: true,
            yearList:
              lob?.year?.length > 0
                ? lob?.year?.map((year) => year + "")
                : ["2024"],
          };
        }),
      };
      return prog;
    });
    try {
      const updatedApiData = {
        _id: clientData._id,
        clientName: clientData.clientName,
        plan: clientData.plan,
        healthPlanName: clientData.healthPlanName,
        address: clientData.address,
        city: clientData.city,
        county: clientData.county,
        state: clientData.state.map((value) => value.trim()),
        zipCode: clientData.zipCode,
        phoneNumber: clientData.phoneNumber,
        email: clientData.email,
        notes: clientData.notes,
        yearList: clientData.yearList.map((value) => value.trim()),
        feeSchedule: clientData.feeSchedule.map((value) => value.trim()),
        screeningType: clientData.screeningType,
        program: structuredProgran,
        // clientData.program.map((programItem, index) => {
        //   if (typeof programItem === "object") {
        //     return {
        //       id: programItem.id.toString() || index.toString(),
        //       name: programItem.name || "",
        //     };
        //   } else {
        //     return {
        //       id: index.toString(),
        //       name: programItem,
        //     };
        //   }
        // }),
        visitTypes: clientData.visitTypes.map((value) => value.trim()),
        assessmentTypes: clientData.assessmentTypes.map((value) =>
          value.trim(),
        ),
        //lob: clientData.lob.map((value) => value.trim()),
      };

      const response = await putApi(
        `/clients/update?id=${selectedClientData._id}`,
        updatedApiData,
      );

      if (response?.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("Client data updated successfully!");
        setClientData(initialClientData);

        // Call the fetchClientData function passed from ViewClients
        fetchClientData();
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setToasterSeverity(Labels.ERROR);
        if (
          response &&
          response.response &&
          response.response.data &&
          response.response.data.message
        ) {
          setToasterMessage(response.response.data.message);
        } else {
          setToasterMessage("An error occurred.");
        }

        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("An error occurred.");
      setToasterOpen(true);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setClientData({
      ...initialClientData,
      visitTypes: [],
      assessmentTypes: [],
      lob: [],
      feeSchedule: [],
      yearList: [],
      program: [],
      state: [],
    });
    setErrors({});
    // onClose();
  };
  console.log("clientDatas.lob", clientDatas.lob);

  useEffect(() => {
    if (editMode && selectedClientData) {
      const selectedLobList = selectedClientData.program?.map(
        (itm) => itm?.lob,
      );
      console.log("selectedLobList", selectedLobList);
      const _selectedLobList = selectedLobList?.[0]?.map((itm) => itm?.name);

      const _selectedLobListIds = clientDatas?.lob
        ?.filter((itm) => _selectedLobList.includes(itm?.lobName))
        ?.map((itm) => itm?._id);

      const structuredProgram = selectedClientData.program?.map((prog) => {
        prog = {
          id: "1", //prog?.clientId,
          name: prog?.name,
          yearList: prog?.createdDateYear?.map((date) => date?.year), //[2023, 2024],
          isActive: true,
          lob: selectedLobList[0]?.map((lob, ind) => {
            return {
              id: ind + 1 + "",
              name: lob?.lobName,
              isActive: true,
              yearList: lob?.year,
            };
          }),
        };
        return prog;
      });

      const selectedProgram = selectedClientData.program?.map(
        (itm) => itm?.name,
      );

      const selectedProgramObj = clientDatas?.program?.filter((prog) =>
        selectedProgram?.includes(prog.programName),
      );

      console.log("selectedProgramObj", selectedProgramObj);
      console.log("selectedProgram", selectedProgram);
      console.log("ClientDatasProg", clientDatas?.program);
      setClientData({
        clientName: selectedClientData.clientName || "",
        plan: selectedClientData.plan || "",
        healthPlanName: selectedClientData.healthPlanName || "",
        address: selectedClientData.address || "",
        city: selectedClientData.city || "",
        county: selectedClientData.county || "",
        state: selectedClientData.state || [],
        zipCode: selectedClientData.zipCode || "",
        phoneNumber: selectedClientData.phoneNumber || "",
        email: selectedClientData.email || "",
        notes: selectedClientData.notes || "",
        yearList: selectedClientData.yearList || [],
        feeSchedule: selectedClientData.feeSchedule || [],
        program: selectedProgramObj[0], //structuredProgram,
        visitTypes: selectedClientData.visitTypes || [],
        assessmentTypes: selectedClientData.assessmentTypes || [],
        lob: _selectedLobListIds, //selectedClientData.lob || [],_selectedLobListIds
        screeningType: selectedClientData.screeningType || [],
      });
    }
  }, [editMode, selectedClientData, clientDatas]);

  console.log("selectedClientData", selectedClientData);

  const handleAddClickForYear = (selectedValue) => {
    if (selectedClientData._id !== null) {
      const years = Array.isArray(selectedClientData.yearList)
        ? selectedClientData.yearList.map((year) => year.trim())
        : [];

      if (years.includes(selectedValue)) {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(ValidationMessage.Year_already_present);
        setToasterOpen(true);
      } else {
        years.push(selectedValue);
        const updatedYear = years.join(", ");
        setSelectedClientData({
          ...selectedClientData,
          yearList: updatedYear,
        });

        const updatedApiData = apiData.map((client) => {
          if (
            client._id === selectedClientData._id
            //&&
            //client.ProgramId === selectedClientData.ProgramId
          ) {
            return {
              ...client,
              yearList: updatedYear,
            };
          }
          return client;
        });

        setApiData(updatedApiData);
        // Close the modal
        setIsAlertOpen(false);

        // Show success toaster message
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(ValidationMessage.Year_added_successfully);
        setToasterOpen(true);
      }
    }
  };

  const handleAddClickForScheduler = (selectedValue) => {
    onClose();
  };

  const currentYear = new Date().getFullYear().toString();

  const isCurrentYearSelected = clientData.yearList.includes(currentYear);

  const yearOptions = isCurrentYearSelected
    ? [...clientData.yearList]
    : [...clientData.yearList, currentYear];

  console.log("clientData.lob", clientData.lob);
  console.log("clientData.feeSchedule", clientData.feeSchedule);
  console.log("clientDatas.lob", clientDatas.lob);

  console.log("clientDataObj", clientData);

  return (
    <div className="addClient">
      <div className="">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Client Name"
              className="inputAddMember"
              type="input"
              value={clientData.clientName}
              onChange={(event) =>
                handleChange("clientName", event.target.value)
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Plan"
              className="inputAddMember"
              type="input"
              value={clientData.plan}
              onChange={(event) => setSelectedPlan("Plan", event.target.value)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Autocomplete
              multiple={false}
              id="program-autocomplete"
              clearOnBlur
              style={{ marginTop: "7px", marginLeft: "9px" }}
              // options={clientDatas.program}
              // getOptionLabel={(option) => option}
              // getOptionSelected={(option, value) => option === value}
              // value={clientData?.program}
              // onChange={(event, newValue) => {
              //   setProgramInputValue(newValue);
              //   handleChange("program", newValue);
              // }}
              options={clientDatas.program}
              getOptionLabel={(option) => option.programName}
              getOptionSelected={(option, value) => option?._id == value}
              value={clientData.program}
              //inputValue={programInputValue}
              onChange={(event, newValue) => {
                setProgramInputValue(newValue);
                handleChange("program", newValue);
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Program"
                  className="inputAddMember"
                  required={true}
                  onChange={(e) => {
                    setProgramInputValue(e.target.value);
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Health Plan Name"
              className="inputAddMember"
              type="input"
              value={clientData.healthPlanName}
              onChange={(event) =>
                handleChange("healthPlanName", event.target.value)
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Address"
              className="inputAddMember"
              type="input"
              value={clientData.address}
              onChange={(event) => handleChange("address", event.target.value)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="County"
              className="inputAddMember"
              type="input"
              value={clientData.county}
              onChange={(event) => handleChange("county", event.target.value)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Zip Code"
              className="inputAddMember"
              type="input"
              value={clientData.zipCode}
              onChange={(event) =>
                handleInputChange("zipCode", event.target.value.toString())
              }
              required={true}
            />
            <ValidationError error={zipCodeError} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="City"
              className="inputAddMember"
              type="input"
              value={clientData.city}
              onChange={(event) => handleChange("city", event.target.value)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="State"
              // options={stateNames.options}
              options={stateNames.options.map((state) => state.toUpperCase())}
              className="inputAddMember"
              // isMulti={true}
              type="select"
              value={clientData.state}
              onChange={(event) => handleChange("state", event.target.value)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Email"
              className="inputAddMember"
              type="input"
              value={clientData.email}
              onChange={(event) =>
                handleInputChange("email", event.target.value)
              }
              required={true}
            />
            <ValidationError error={emailError} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Phone Number"
              className="inputAddMember"
              type="input"
              value={clientData.phoneNumber}
              onChange={(event) =>
                handleInputChange("phoneNumber", event.target.value)
              }
              required={true}
            />
            <ValidationError error={phoneNumberError} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Year"
              // options={clientData.yearList}
              // options={clientData.yearList.concat(new Date().getFullYear().toString())}

              options={yearOptions}
              className="inputAddMember"
              isMulti={true}
              type="select"
              value={clientData.yearList}
              onChange={(event) => handleChange("yearList", event.target.value)}
              required={true}
              disabledOptions
              // disabledOptions={(option) =>
              //   option !== new Date().getFullYear().toString()}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Visit Types"
              options={clientDatas.visitTypes}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.visitTypes}
              onChange={(event) =>
                handleChange("visitTypes", event.target.value)
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Assessments"
              options={clientDatas.assessmentTypes}
              className="inputAddMember"
              type="select"
              value={clientData.assessmentTypes}
              onChange={(event) =>
                handleChange("assessmentTypes", event.target.value)
              }
              isMulti={true}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {/* <MuiInput
              label="LOB"
              options={clientDatas.lob}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.lob}
              onChange={(event) => handleChange("lob", event.target.value)}
              required={true}
            /> */}

            <Box
              sx={{
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  padding: "0px",
                },
              }}
              className="inputAddMember"
            >
              <FormControl>
                {/* <InputLabel>{props.label}</InputLabel> */}
                <InputLabel
                  sx={{
                    backgroundColor: "transparent",
                    // padding: '8px',
                  }}
                >
                  LOB
                </InputLabel>
                <Select
                  multiple={true}
                  // value={clientData?.lob?.length > 0 ? clientData?.lob : []}
                  value={clientData?.lob}
                  onChange={(e) => {
                    //handleChangeLob(e?.target.value);
                    handleChange("lob", e.target.value);
                  }}
                  label="LOB"
                >
                  {clientDatas.lob?.map((opt, id) => {
                    return (
                      <MenuItem
                        key={id}
                        value={opt?._id}
                        // disabled={isOptionDisabled}
                        sx={{ color: "none" }}
                      >
                        {opt?.lobName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {/* <MuiInput
              label="Fee Schedule"
              //options={clientData.feeSchedule}
              options={["fee schedule 1", "Fee schedule 2"]}
              className="inputAddMember"
              type="select"
              // value={clientData.feeSchedule}
              value={selectedClientData?.feeSchedule}
              onChange={(event) =>
                handleChange("feeSchedule", event.target.value)
              }
              required={true}
            /> */}
            <Box
              sx={{
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  padding: "0px",
                },
              }}
              className="inputAddMember"
            >
              <FormControl>
                <InputLabel>Fee Schedule</InputLabel>
                <Select
                  multiple={true}
                  value={clientData.feeSchedule}
                  onChange={(event) =>
                    handleChange("feeSchedule", event.target.value)
                  }
                  label="Fee Schedule"
                >
                  <MenuItem value="" disabled>
                    Fee Schedule
                  </MenuItem>

                  {["fee schedule 1", "Fee schedule 2"].map((opt, id) => {
                    return (
                      <MenuItem key={id} value={opt}>
                        {opt}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Notes"
              className="inputAddMembers"
              type="input"
              value={clientData.notes}
              onChange={(event) => handleChange("notes", event.target.value)}
              multiline
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Screening Type"
              options={screeningTypeOptions}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.screeningType}
              onChange={(event) =>
                handleChange("screeningType", event.target.value)
              }
              required={true}
            />
          </Grid>
        </Grid>
        <div style={{ marginLeft: "60%", marginTop: "50px" }}>
          {/* <Button sx={dialogBtn} className="dialogBtn" onClick={handleUpdate}>
            Update
          </Button> */}
          <Button
            sx={dialogBtn}
            className={`dialogBtn ${
              !isFormValid ||
              !additionalConditions.every((condition) => condition)
                ? "disabledButton"
                : ""
            }`}
            disabled={
              !isFormValid ||
              !additionalConditions.every((condition) => condition)
            }
            onClick={handleUpdate}
          >
            Update
          </Button>
          {/* <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleEditYearClick}
          >
            Edit Year
          </Button> */}
          <Button sx={dialogBtn} onClick={handleCancel}>
            Reset
          </Button>
        </div>
      </div>
      <Alert
        open={isAlertOpen}
        handleClose={() => {
          setIsAlertOpen(false);
          setShowAlternateDropdown(false);
          //onClose();// Close the dialog in the EditClient component
        }}
        okText="Add"
        cancelText="Cancel"
        showSecondDropdownCondition={true}
        onAddClick={
          showAlternateDropdown
            ? handleAddClickForYear
            : handleAddClickForScheduler
        }
      />

      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
};

export default EditClient;
