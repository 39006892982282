import { Grid, Box } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Stack,
} from "@mui/material";
import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  isCommentVisible,
  isCommentVisibleMemo,
} from "../../DFInputs/DFComment";

import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import { DFText, DFTextBox } from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getVitalData,
  getSubTree,
  setVitalData,
} from "./vitalMapping";
import { TableComponentStatic } from "../../../../DynamicFormMUI/staticForm";
import { staticData } from "../../../../DynamicFormMUI/indexData";
import { getInputsData } from "./vitalFormData";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";

import "../responsiveFillHra.css";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const Vital = forwardRef((props, _ref) => {
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  const {
    // examReviewFormData,
    // setExamReviewFormData,
    // showComment,
    // setShowComment,
    getSectionInputsData,
    getDisableFlag,
    // getStateValue,
    // setStateValue,
  } = useFillHraContext();
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();

  const [vitalFormData, setVitalFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  //const [reportedData, setReportedData] = useState("");
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 12;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      // const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      const mashedData =
        dbData?.data?.vital?.data &&
        Object.keys(dbData?.data?.vital?.data).length > 0
          ? getVitalData(dbData?.data?.vital?.data, clientYear)
          : {};
      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };
      setVitalFormData(mashedCalcData);
      setLoading(false);
      setSideBarSectionDisable(false);
      //setReportedData(mashedData.reportedData);
    }

    getDbData();

    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...vitalFormData };
    updatedFormData[name] = value;

    if (
      name === "vital_feet" ||
      name === "vital_inch" ||
      name === "vital_patientsWeight"
    ) {
      if (
        updatedFormData.vital_feet &&
        updatedFormData.vital_inch &&
        updatedFormData.vital_patientsWeight &&
        updatedFormData.vital_feet != "0" &&
        updatedFormData.vital_inch != "0" &&
        updatedFormData.vital_patientsWeight != "0"
      ) {
        // Calculate BMI
        const heightInMeters =
          updatedFormData.vital_feet * 0.3048 +
          updatedFormData.vital_inch * 0.0254;
        const weightInKilograms =
          updatedFormData.vital_patientsWeight * 0.453592;
        updatedFormData.vital_bmii = (
          weightInKilograms / Math.pow(heightInMeters, 2)
        ).toFixed(2);
      } else {
        updatedFormData.vital_bmii = "";
      }
    }

    if (
      [
        "vital_feet",
        "vital_inch",
        "vital_patientsWeight",
        "vital_diastolicbp",
        "vital_systolicbp",
        "vital_pulse",
        "vital_respiratoryRate",
        "vital_temp",
        "vital_pulseOximetry",
        "vital_painScale",
      ]?.includes(name) &&
      isNaN(value)
    ) {
      return;
    }

    setVitalFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...vitalFormData };
    updatedFormData[name] = value;

    setVitalFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...vitalFormData };
    updatedFormData[name] = value;

    setVitalFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...vitalFormData };
    updatedFormData[name] = value;

    setVitalFormData(updatedFormData);
  };

  const handleDeleteSubTree = (objectData, name, deletedValue) => {
    const deleteRegex = getSubTree(name, deletedValue);
    if (deleteRegex !== "") {
      let updatedObjectData = deleteSubTree(objectData, deleteRegex);
      return updatedObjectData;
    }
    return objectData;
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    const updatedFormData = { ...vitalFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (isMulti) {
      if (value.includes("none")) {
        if (oldSelection?.includes("none")) {
          newSelection = value.filter((item) => item !== "none");
        } else {
          newSelection = ["none"];
        }
      } else {
        newSelection = value;
      }
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection = [value];
      } else {
        newSelection = [];
      }
    }
    // if (value?.includes("none") && isMulti === true) {
    //   if (oldSelection?.includes("none")) {
    //     newSelection = value?.filter((item) => item !== "none");
    //   } else {
    //     newSelection = ["none"];
    //   }
    // } else if (isMulti) {
    //   newSelection = value;
    // } else {
    //   if (!oldSelection?.includes(value)) {
    //     newSelection.push(value);
    //   }
    // }
    let deletedValue =
      isMulti === true
        ? lodash.difference(oldSelection, newSelection)?.[0] || ""
        : oldSelection?.[0] || "";

    if (name === "vital_bmi") {
      updatedFormData.vital_bmiMalnutritionSubDescribe = "";
      updatedFormData.vital_bmiMalnutritionSubMalnutrition = "";
      updatedFormData.vital_bmiMalnutritionSubSupportedBy = "";
      updatedFormData.vital_bmiMalnutritionSubDescribeComment = "";
      updatedFormData.vital_bmiMalnutritionSubMalnutritionComment = "";
      updatedFormData.vital_bmiMalnutritionSubSupportedByComment = "";
    }

    if (name === "vital_specialDiet" && !value?.includes("other")) {
      updatedFormData.vital_specialDietSubOtherSubDesc = "";
    }

    if (name === "vital_preventingChanges" && !value?.includes("other")) {
      updatedFormData.vital_preventingChangesSubOtherSubDesc = "";
    }

    if (value === "yes" || value === "no") {
      const deleteRegex = `${name}Sub`;

      updatedFormData[name] = value;
      let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
      setVitalFormData(subTreeDeletedData);
      return null;
    } else if (deletedValue !== "") {
      updatedFormData[name] = value;
      const deleteRegex = getSubTree(name, deletedValue);

      if (deleteRegex != "") {
        let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
        setVitalFormData(subTreeDeletedData);
        return null;
      } else {
        updatedFormData[name] = newSelection;
        setVitalFormData(updatedFormData);
      }

      return null;
    } else {
      updatedFormData[name] = value;
      setVitalFormData(updatedFormData);
    }
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...vitalFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setVitalFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      return setVitalData(vitalFormData, clientYear);
    },

    //reportedData: reportedData,
  }));
  useEffect(() => {
    props.setAutoSaveVitalData({ ...vitalFormData });
  }, [vitalFormData]);

  const getSuffixBasedOnYear = (option, clientYear) => {
    if (clientYear === "2023") {
      return option[1] === "nutritionManagement"
        ? "Nutri"
        : option[1] === "other"
        ? "Other"
        : "";
    } else if (clientYear === "2024") {
      return option[1] === "nutritionManagement"
        ? "Nutri"
        : option[1] === "other"
        ? "Other"
        : option[1] === "caseManagementReferral"
        ? "CaseMan"
        : option[1] === "followUpWithClinicianSpecialist"
        ? "FollowUp"
        : option[1] === "memberToUseDME"
        ? "MemberTo"
        : "";
    }
  };

  // const handleReportedRadioChange = (event) => {
  //   const selectedValue = event.target.value;

  //   const reportedValue = selectedValue;
  //   setReportedData(reportedValue);
  // };

  const getFieldValues = useCallback(
    (name) => {
      return vitalFormData[name];
    },
    [vitalFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setVitalFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        // style={{
        //   margin: "10px 20px",
        //   width:"68vw"
        //   //width: "76vw",
        // }}
        className="vital-form-container"
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton, label } =
              inputProps;
            const inputValue = vitalFormData[name] || "";
            const commentValue = `${
              vitalFormData?.[`${name}Comment`]
                ? `${vitalFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={
                    [
                      "vital_diastolicbp",
                      "vital_systolicbp",
                      "vital_pulse",
                      "vital_respiratoryRate",
                      "vital_temp",
                      "vital_pulseOximetry",
                      "vital_painScale",
                    ].includes(name)
                      ? 4
                      : xs
                  }
                  sm={
                    [
                      "vital_diastolicbp",
                      "vital_systolicbp",
                      "vital_pulse",
                      "vital_respiratoryRate",
                      "vital_temp",
                      "vital_pulseOximetry",
                      "vital_painScale",
                    ].includes(name)
                      ? 4
                      : undefined
                  }
                  //xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFTextBox
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    sx={{
                      fontSize:
                        inputProps.label === "Patient's Height (Feet)" ||
                        inputProps.label === "Patient's Height (Inch)" ||
                        inputProps.label === "Patient's Weight (lbs)" ||
                        inputProps.label === "BMI"
                          ? "13px"
                          : undefined,
                    }}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  {name == "vital_reported" ? (
                    <div style={{ marginTop: "24px" }}>
                      <DFRadio
                        inputProps={inputProps}
                        value={inputValue}
                        handleRadioChange={handleRadioChange}
                        handleTextChange={handleTextChange} // added for comment box event handling
                        showComment={showComment}
                        setShowComment={setShowComment}
                        quesSlug={`${questionId}`}
                        sectionId={sectionId}
                        commentValue={commentValue}
                        disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                        needMemo={true}
                        getFieldValues={getFieldValues}
                        setFieldValues={setFieldValues}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = vitalFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  {/* <Box display="flex" style={{justifyContent:"flex-start"}}> */}
                  {/* <Box> */}
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  // formData={covidScreeningFormData}
                  formData={vitalFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  // setFormData={setCovidScreeningFormData}
                  setFormData={setVitalFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // handleRadioChange={handleReportedRadioChange}
                    // value={reportedData}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              const selectedValues = vitalFormData[`${name}`];
              //  const  checkboxCommentName=`${name}${option[1] === "nutritionManagement"? "Nutri" : option[1] === "other"?"Other":""}`

              return (
                <Grid key={quesSlug} xs={xs} item>
                  {/* <DFCheckbox
                  inputProps={inputProps}
                  showComment={showComment}
                  handleCheckboxChange={handleCheckboxChange}
                  value={inputValue}
                  handleTextChange={handleTextChange}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  vitalFormData={vitalFormData}
                   disableFlag={disableFlag}
                /> */}

                  <Grid key={quesSlug} container alignItems="center">
                    <Grid item xs={11}>
                      <FormControl
                        className="inputStyle"
                        component="fieldset"
                        variant="outlined"
                        style={{ marginLeft: "28px" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormLabel
                            className="inputStyle"
                            style={{ marginLeft: "15px" }}
                          >
                            {inputProps.label}
                          </FormLabel>
                        </div>
                        {inputProps.options.map((option, index) => {
                          const suffix = getSuffixBasedOnYear(
                            option,
                            clientYear,
                          );
                          const getCommentValue = (name, suffix) => {
                            const commentValue = `${
                              vitalFormData?.[`${name}${suffix}Comment`]
                                ? `${vitalFormData[`${name}${suffix}Comment`]}`
                                : ""
                            }`;

                            return commentValue;
                          };

                          return (
                            <div
                              key={index}
                              style={{ display: "flex", width: "75vw" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  //alignItems: "center",
                                  width: "100%",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div style={{ width: "100%" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className="inputStyle"
                                        variant="outlined"
                                        name={name}
                                        //checked={true}
                                        disabled={roleId == 5 && tabId == 3}
                                        checked={
                                          selectedValues?.includes(
                                            option?.[1],
                                          ) === true
                                        }
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.name,
                                            option[1],
                                            event.target.checked,
                                          )
                                        }
                                        value={option[1]}
                                        {...(disableFlag && { disabled: true })}
                                      />
                                    }
                                    label={option[0]}
                                  />
                                  {inputProps.isCommentEnabled &&
                                    isCommentVisibleMemo(
                                      getFieldValues,
                                      `${name}${suffix}`,
                                    ) && (
                                      <DFCommentTextBox
                                        label={label}
                                        handleTextChange={handleTextChange}
                                        style={{ width: "70vw" }}
                                        name={`${name}${suffix}`}
                                        value={
                                          vitalFormData[
                                            `${name}${suffix}Comment`
                                          ]
                                        }
                                        disableFlag={
                                          disableFlag ||
                                          (roleId == 5 && tabId == 3)
                                        }
                                      />
                                    )}
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginBottom: isCommentVisible(
                                    showComment,
                                    sectionId,
                                    `${sectionId}_${questionId}_${index}`,
                                    commentValue,
                                  )
                                    ? "25px"
                                    : "",
                                }}
                              >
                                {inputProps.isCommentEnabled === true && (
                                  <DFCommentIconMemo
                                    // showComment={showComment}
                                    // setShowComment={setShowComment}
                                    // sectionId={sectionId}
                                    // quesSlug={`${sectionId}_${questionId}_${index}`}
                                    getFieldValues={getFieldValues}
                                    setFieldValues={setFieldValues}
                                    disableFlag={
                                      disableFlag || (roleId == 5 && tabId == 3)
                                    }
                                    name={`${name}${suffix}`}
                                    value={commentValue}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            } else if (type === "datatable") {
              return (
                <Grid xs={2} item>
                  {clientYear === "2024" ? (
                    <TableComponentStatic
                      tableName="BMI Index Chart 2024"
                      data={staticData[1].column_data[0].rows}
                    />
                  ) : (
                    <TableComponentStatic
                      tableName="BMI Index Chart"
                      data={staticData[0].column_data[0].rows}
                    />
                  )}
                </Grid>
              );
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default Vital;
