import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { userCategoriesList } from "../components/Dashboard/ReactDND/userCategories";
import { addMetaData, populateFieldName } from "../utils/helpers.DynamicForm";
import Loader from "../components/Loader/Loader";
import { getApi } from "../utils/axiosApiWrapper";
import { useUserContext } from "./UserContext";
import {
  getFormData,
  saveFormData,
} from "../components/Dashboard/DynamicFormMUI/formData";
import { inputTypesList } from "../components/Dashboard/ReactDND/inputTypes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone";
import toast from "react-hot-toast";
export const DragNDropContext = createContext({
  inputTypes: null,
  userCategories: null,
  loading: null,
  selectedOption: null,
  setLoading: () => null,
  sections: [],
  setSections: () => null,
  currSection: 0,
  setCurrSection: () => null,
  dForm: [],
  handleChangeRadio: () => null,
  setDForm: () => null,
  formData: {},
  setFormData: () => null,
  editFormProps: { isFieldsModified: false, isMinimized: true },
  setEditFormProps: () => null,
  clientData: null,
  setClientData: () => null,
  handleClientDataChange: () => null,
  handleClientDataSubmit: () => null,
  addInputTypeToBoard: () => null,
  handleSectionAdd: () => null,
  handleSectionClick: () => null,
  handleSectionNameEdit: () => null,
  // handleSectionNameChange: () => null,
  handleSectionLock: () => null,
  handleSectionDelete: () => null,
  handleTableDataChange: () => null,
  handleChange: () => null,
  handleClick: () => null,
  handleDelete: () => null,
  handleFormSubmit: () => null,
  handleEditSubmit: () => null,
  handleEditClose: () => null,
  handleAddOption: () => null,
  handleOptionChange: () => null,
  handleDeleteOption: () => null,
  handleQuestionRearrange: () => null,
  handleSectionRearrange: () => null,
  addInputOptions: () => null,
  deleteInputOptions: () => null,
  changeSubQuestions: () => null,
  handleOptionsTreeChange: () => null,
  handleOptionsTreeClick: () => null,
  handleFormPreview: () => null,
  handleClickOpen: () => null,
  handleClose: () => null,
  open: null,
  setOpen: () => null,
  handleInputSubmit: () => null,
  handleDialogBox: () => null,
  addSubQuestions: () => null,
  deleteSubQuestions: () => null,
  userData: [],
  handleSubQuestionDateChange: () => null,
  handleSubSubQuestionChange: () => null,
  handleDateChange: () => null,
  toggleindex: {},
  setToggleIndex: () => null,
  selectIndex: {},
  setSelectIndex: () => null,
  subSelectedValue: null,
  setSubSelectedValue: () => null,
  subSelectIndex: {},
  SetSubSelectIndex: () => null,
  subSubSelectIndex: {},
  SetSubSubSelectIndex: () => null,
  tableRowData: [],
  setTableRowData: () => null,
  selectedToggles: null,
  setSelectedToggles: () => null,
  commentIndex: {},
  setCommentIndex: () => null,
  selectedDate: null,
  setSelectedDate: () => null,
  setSelectedOption: () => null,
  handleSubQuestionChange: () => null,
  opens: null,
  setOpens: () => null,
  suspectOpens: null,
  setSuspectOpens: () => null,
  handleInputChange: () => null,
  handleButtonAction: () => null,
  handleChangeCheckbox: () => null,
  handleCloses: () => null,
  handleToggleOptionChange: () => null,
});

export const DragNDropProvider = ({ children }) => {
  const inputTypes = inputTypesList;
  let userCategories = userCategoriesList;
  let inputid = 0;
  const rowsData = [
    {
      Diagnoses: "",
      LabelName: "",
      DosePerUnits: "",
      Route: "",
      Frequency: "",
      PrescribingPhysician: "",
      Status: "",
    },
  ];

  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [currSection, setCurrSection] = useState(0);
  const [dForm, setDForm] = useState([]);
  const [formData, setFormData] = useState({});
  const [editFormProps, setEditFormProps] = useState({
    isMinimized: true,
    isFieldsModified: false,
  });
  const [toggleindex, setToggleIndex] = useState({});
  const [selectIndex, setSelectIndex] = useState({});
  const [subSelectedValue, setSubSelectedValue] = useState("");
  const [subSelectIndex, SetSubSelectIndex] = useState({});
  const [subSubSelectIndex, SetSubSubSelectIndex] = useState({});
  const [tableRowData, setTableRowData] = useState(rowsData);
  const [selectedToggles, setSelectedToggles] = useState(
    tableRowData.map(() => "taking"),
  );
  const [commentIndex, setCommentIndex] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [opens, setOpens] = useState(false);
  const [suspectOpens, setSuspectOpens] = useState(false);
  const [toasterOpen, setToasterOpen] = useState(true);
  const [toasterSeverity, setToasterSeverity] = useState("success");
  const [toasterMessage, setToasterMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [openSignSubmitDialog, setSignSubmitDialog] = useState(false);
  const { memberIdForFormNames, memberDatasForNames } = useUserContext();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [padEligibilityDialog, setPadEligibilityDialog] = useState(false);

  const handleInputChange = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    event.target.value = numericValue;
  };
  const handleChangeCheckbox = (e, inputProps, optionValue) => {
    const { name, checked } = e.target;
    const selectedOptions = formData[inputProps.name] || [];
    if (checked) {
      selectedOptions.push(optionValue);
    } else {
      const index = selectedOptions.indexOf(optionValue);
      if (index !== -1) {
        selectedOptions.splice(index, 1);
      }
    }
    setFormData(() => ({
      ...formData,
      [name]: selectedOptions,
    }));
  };
  const downloadPdf = async (url, fileName) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        toast.error("Error while generating pdf", {});
        return;
      } //throw new Error("Network response was not ok");
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      toast.error("Error while generating pdf", {});
    }
  };
  const fetchFullReportPdfData = async (assessmentId) => {
    const response = await getApi(
      `/pdf/download-full-report?id=${assessmentId}`,
    );
    if (response.status == 200) {
      const url =
        `${process.env.REACT_APP_API_BASE_URL}` +
        "/full-report/" +
        response?.data?.pdfPath?.split("/")?.[
          response?.data?.pdfPath?.split("/").length - 1
        ];
      const pdf_name = `${memberDatasForNames?.plan}_${
        memberDatasForNames?.lob
      }_${memberDatasForNames?.demographics?.data?.region}_${
        memberDatasForNames?.demographics?.data?.lastName
      }_${memberDatasForNames?.demographics?.data?.memberId}_${dayjs
        .utc(memberDatasForNames?.patient?.data?.evaluationDate)
        .format("MMDDYYYY")}`;
      setTimeout(() => {
        downloadPdf(url, `${pdf_name}.pdf`);
      }, 3000);
    } else {
      toast.error(
        response?.response?.data?.message || "Error while fetching data",
      );
    }
  };

  const fetchViewSummaryPdfData = async (assessmentId) => {
    const response = await getApi(`/pdf/summary?id=${assessmentId}`);
    if (response.status == 200) {
      const url =
        `${process.env.REACT_APP_API_BASE_URL}` +
        "/view-summary/" +
        response?.data?.pdfPath?.split("/")?.[
          response?.data?.pdfPath?.split("/").length - 1
        ];
      setTimeout(() => {
        downloadPdf(url, `Summary_${memberIdForFormNames}.pdf`);
      }, 3000);
    } else {
      toast.error(
        response?.response?.data?.message || "Error while fetching data",
      );
    }
  };

  const fetchMemberSummaryReport = async (assessmentId) => {
    const response = await getApi(`/pdf/member-sumary?id=${assessmentId}`);
    if (response.status == 200) {
      const url =
        `${process.env.REACT_APP_API_BASE_URL}` +
        "/member-summary/" +
        response?.data?.pdfPath?.split("/")?.[
          response?.data?.pdfPath?.split("/").length - 1
        ];
      setTimeout(() => {
        downloadPdf(url, `Member_Summary_${memberIdForFormNames}.pdf`);
      }, 3000);
    } else {
      toast.error(
        response?.response?.data?.message || "Error while fetching data",
      );
    }
  };
  const handleButtonAction = (routedFrom, assessmentId) => {
    if (routedFrom === "previously_documented_conditions") {
      setOpens(true);
    } else {
      setOpens(false);
    }
    if (routedFrom === "suspect_codes") {
      setSuspectOpens(true);
    }

    if (routedFrom === "padTestingEligibility") {
      setPadEligibilityDialog(true);
    }
    if (routedFrom == "signSubmit") {
      setSignSubmitDialog(true);
    }
    if (routedFrom == "view_full_report") {
      fetchFullReportPdfData(assessmentId);
    }
    if (routedFrom == "view_summary") {
      fetchViewSummaryPdfData(assessmentId);
    }
    if (routedFrom == "member_summary") {
      fetchMemberSummaryReport(assessmentId);
    }
  };
  const handleSubQuestionDateChange = (
    date,
    subQuestion,
    parentQuestionName,
    inputProps,
  ) => {
    if (date !== undefined && date !== null) {
      const newFormData = { ...formData };
      const selectedValue = date;

      if (subQuestion.type === "date") {
        const examdateMs = new Date(selectedValue);

        if (isNaN(examdateMs.getTime())) {
          return;
        }

        const year = examdateMs.getFullYear();
        if (year < 1900) {
          return;
        }

        const isDatepickerFormat = selectedValue.includes("-");

        let formattedDate;

        if (isDatepickerFormat) {
          const month = (examdateMs.getMonth() + 1).toString().padStart(2, "0");
          const day = examdateMs.getDate().toString().padStart(2, "0");
          formattedDate = `${month}-${day}-${year}`;
        } else {
          formattedDate = selectedValue;
        }

        setSelectedDate(formattedDate);
        setFormData((prevFormData) => ({
          ...prevFormData,
          [subQuestion.name]: formattedDate,
        }));
      } else {
        newFormData[subQuestion.name] = selectedValue;

        if (selectedValue === "other") {
          setSelectedDate(null);
          newFormData[subQuestion.name] = null;
        }
      }

      setFormData(newFormData, () => {});
    } else {
      // console.log("Invalid date", date);
    }
  };

  // const handleSubQuestionDateChange = (
  //   date,
  //   subQuestion,
  //   parentQuestionName,
  //   inputProps,
  // ) => {
  //   if (date !== undefined && date !== null) {
  //     const newFormData = { ...formData };
  //     const selectedValue = date;

  //     if (subQuestion.type === "date") {
  //       const examdateMs = new Date(selectedValue);

  //       if (isNaN(examdateMs.getTime())) {
  //         console.log("Invalid date format:", selectedValue);
  //         return;
  //       }

  //       const isDatepickerFormat = selectedValue.includes("-");

  //       let formattedDate;

  //       if (isDatepickerFormat) {
  //         const month = (examdateMs.getMonth() + 1).toString().padStart(2, "0");
  //         const day = examdateMs.getDate().toString().padStart(2, "0");
  //         const year = examdateMs.getFullYear();
  //         formattedDate = `${month}-${day}-${year}`;
  //       } else {
  //         formattedDate = selectedValue;
  //       }

  //       setSelectedDate(formattedDate);
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         [subQuestion.name]: formattedDate,
  //       }));
  //     } else {
  //       newFormData[subQuestion.name] = selectedValue;

  //       if (selectedValue === "other") {
  //         setSelectedDate(null);
  //         newFormData[subQuestion.name] = null;
  //       }
  //     }

  //     setFormData(newFormData, () => {});
  //   } else {
  //     console.log("Invalid date", date);
  //   }
  // };

  // const handleSubQuestionDateChange = (
  //   event,
  //   subQuestion,
  //   value,
  //   parentQuestionName,
  //   inputProps,
  // ) => {
  //   const selectedValue = event.target.value;
  //   const newFormData = { ...formData };

  //   // setFormData({ ...formData, [parentQuestionName]: value });

  //   if (subQuestion.type === "date") {
  //     const newDate = event.target.value;

  //     setSelectedDate(newDate);

  //     newFormData[subQuestion.name] = newDate;

  //     newFormData[parentQuestionName] = newDate;
  //   } else {
  //     newFormData[subQuestion.name] = selectedValue;

  //     if (selectedValue === "other") {
  //       setSelectedDate(null);
  //       newFormData[subQuestion.name] = null;
  //     }
  //   }

  //   setFormData(newFormData);
  // };

  const handleSubQuestionChange = (
    event,
    language,
    parentQuestionName,
    subQuestion,
    inputProps,
  ) => {
    const selectedValue = event.target.value;
    const newFormData = { ...formData };
    newFormData[parentQuestionName] = selectedValue;

    SetSubSelectIndex((prevSubSelectIndex) => ({
      ...prevSubSelectIndex,
      [language.subQuestionId]: selectedValue,
    }));

    setFormData(newFormData);
  };

  const handleSubSubQuestionChange = (
    event,
    // language,
    subQ,
    parentQuestionName,
  ) => {
    const selectedValue = event.target.value;
    const newFormData = { ...formData };
    newFormData[parentQuestionName] = selectedValue;
    SetSubSubSelectIndex((prevSubSubSelectIndex) => ({
      ...prevSubSubSelectIndex,
      [subQ.subQuestionId]: selectedValue,
    }));
    setFormData(newFormData);
  };

  const handleDateChange = (newDate, name, inputProps) => {
    setSelectedDate(newDate);
    setFormData({ ...formData, [name]: newDate });
  };

  const handleTableDataChange = (event, rowIndex, fieldName) => {
    const updatedData = [...tableRowData];
    switch (fieldName) {
      case "Toggles":
        setSelectedToggles((prevToggles) => {
          const updatedToggles = [...prevToggles];
          updatedToggles[rowIndex] = event.target.value;
          return updatedToggles;
        });
        break;

      case "diagnoses":
        updatedData[rowIndex].Diagnoses = event.target.value;
        break;

      case "physician":
        updatedData[rowIndex].PrescribingPhysician = event.target.value;
        break;

      case "dose":
        updatedData[rowIndex].Dose = event.target.value;
        break;

      case "labelname":
        updatedData[rowIndex].LabelName = event.target.value;
        break;

      case "route":
        updatedData[rowIndex].Route = event.target.value;
        break;

      case "frequency":
        updatedData[rowIndex].Frequency = event.target.value;
        break;

      default:
        break;
    }

    setTableRowData(updatedData);
  };

  const handleCloses = () => {
    setOpens(false);
    setSuspectOpens(false);
  };

  const handleClickOpen = (e, multiVal) => {
    e.preventDefault();
    // console.log(multiVal, "k", sections && sections.sectionId);
    let isInputPropsOpen = false,
      inputNotSavedLabel = "";

    if (formData && Object.keys(formData).length > 0) {
      isInputPropsOpen = true;
      inputNotSavedLabel = formData.label;
    } else {
      isInputPropsOpen = false;
    }

    isInputPropsOpen === true && editFormProps.isFieldsModified === true
      ? setOpen(() => true)
      : setOpen(() => false);
    // console.log("here", isInputPropsOpen, formData, editFormProps);
  };

  const handleClientDataChange = (e, multiVal = null) => {
    // console.log(e, multiVal);
    if (multiVal !== null) {
      if (multiVal[0] === "dropDownInput") {
        const { value } = e.target;
        if (multiVal[1] === "clients") {
          let newUserCategories = userCategories.clients.map((el) => {
            if (value.includes(el[0])) {
              let temp = {
                ...clientData,
              };
              const newValue = { clientName: value, ...temp };
              // console.log(value, temp, newValue);
              // localStorage.setItem("clientData", JSON.stringify(newValue));
              setClientData(() => newValue);
              // setClientData((prev)=>{
              //   return
              //   {clientName: value,
              //   // ...prev,}
              // });
              return [el[0], true];
            }
            return [el[0], false];
          });
          // console.log(newUserCategories, "client");
          userCategories = newUserCategories;
        } else if (multiVal[1] === "ageGroup") {
          let newUserCategories = userCategories.ageGroup.map((el) => {
            if (value.includes(el[0])) {
              const newValue = {
                ageGroup: value,
                ...clientData,
              };
              // console.log(newValue);
              setClientData(() => newValue);
              // localStorage.setItem("clientData", JSON.stringify(newValue));
              // setClientData({
              //   ageGroup: value,
              //   ...clientData,
              // });
              return [el[0], true];
            }
            return [el[0], false];
          });
          // console.log(newUserCategories, "ageGroup");
          userCategories = newUserCategories;
        }
      }
    }
  };

  const handleClientDataSubmit = () => {
    const client = clientData ? clientData?.clientName : null;
    const age = clientData ? clientData?.ageGroup : null;
    if (client && age) {
      const newValue = {
        clientName: client,
        ageGroup: age,
        isSelected: true,
      };
      setIsLoading(true);
      // console.log(newValue);
      setClientData(() => newValue);
      // localStorage.setItem("clientData", JSON.stringify(newValue));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleFileDownload = ({ fileData, fileName, fileType }) => {
    const downloadFileBlob = new Blob([fileData], { type: fileType });
    const anchorTag = document.createElement("a");
    anchorTag.download = fileName;
    anchorTag.href = window.URL.createObjectURL(downloadFileBlob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    anchorTag.dispatchEvent(clickEvt);
    anchorTag.remove();
  };

  useEffect(() => {
    setLoading(() => true);
    const getData = () => {
      const fullAPIData = getFormData(); // put it in async await when doing api call
      // console.log(fullAPIData);
      if (fullAPIData && fullAPIData.length >= 0) {
        const currentSectionData = fullAPIData[0];
        const data =
          currentSectionData && currentSectionData.length > 0
            ? currentSectionData[0].questions
            : [];
        // console.log(`${renderCount} with useeffect 1`,data);
        setSections(fullAPIData);
        setDForm(data);
        // setLoading(() => false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    setLoading(() => true);
    const getData = () => {
      // console.log(sections);
      if (sections && sections.length > 0) {
        const currentSectionData = sections[currSection]
          ? [sections[currSection]]
          : [];
        const data =
          currentSectionData && currentSectionData.length > 0
            ? currentSectionData[0].questions
            : [];
        // console.log(`${renderCount} with useeffect 2`,data);
        // setSections(() => sections);
        setDForm(() => data);
        // setLoading(() => false);
      }
    };

    getData();
  }, [currSection, sections]);

  const navigate = useNavigate();

  const handleFormPreview = (e) => {
    e.preventDefault();
    let isInputPropsOpen = false;

    if (formData && Object.keys(formData).length > 0) {
      isInputPropsOpen = true;
    } else {
      isInputPropsOpen = false;
    }
    if (isInputPropsOpen === true && editFormProps.isFieldsModified === true) {
      handleClickOpen(e);
    } else {
      handleFormSubmit(e);
      navigate(`/AdminDashboard/PreviewHRAForm`);
    }
  };

  const handleSectionAdd = (e, propsSectionCard) => {
    e.preventDefault();

    let isInputPropsOpen = false;

    if (formData && Object.keys(formData).length > 0) {
      isInputPropsOpen = true;
    } else {
      isInputPropsOpen = false;
    }

    if (isInputPropsOpen === true && editFormProps.isFieldsModified === true) {
      handleClickOpen(e);
    } else {
      // console.log('here');
      const oldSections = sections;
      const sectionCount = oldSections ? oldSections.length : 0;
      // console.log(sectionCount);
      const newSections = [
        ...oldSections,
        {
          sectionId: sectionCount + 1,
          sectionName: `section ${sectionCount + 1}`,
          isEditable: false,
          questions: [],
        },
      ];

      // console.log(newSections);
      setSections(() => newSections);
    }
  };

  // const handleDialogBox = (e, routedFrom) => {
  //   e.preventDefault();
  //   // if(routedFrom==="dialogBox"){
  //   let isInputPropsOpen = false;

  //   if (formData && Object.keys(formData).length > 0) {
  //     isInputPropsOpen = true;
  //   } else {
  //     isInputPropsOpen = false;
  //   }

  //   if (isInputPropsOpen === false && editFormProps.isFieldsModified === true) {
  //     handleClickOpen(e);
  //   }

  //   //  }
  // };

  const handleSectionClick = (e, index, multiVal) => {
    e.preventDefault();

    let isInputPropsOpen = false,
      inputNotSavedLabel = "";

    if (formData && Object.keys(formData).length > 0) {
      isInputPropsOpen = true;
      inputNotSavedLabel = formData.label;
    } else {
      isInputPropsOpen = false;
    }

    isInputPropsOpen === true && editFormProps.isFieldsModified === true
      ? setOpen(() => true)
      : setOpen(() => false);

    if (editFormProps.isFieldsModified === false) {
      handleFormSubmit(e);

      // console.log(index);
      if (index !== currSection) {
        // inputid = 0;
        setCurrSection(() => index);
        setDForm(() => []);
      }
    }
  };

  const handleSectionNameEdit = (e, index, isEditable, newSectionName) => {
    e.preventDefault();
    if (isEditable === false) {
      const oldSections = sections;
      const newSections = oldSections.map((section) => {
        // console.log(newSectionName);
        if (section.sectionId === index + 1) {
          return { ...section, isEditable: true };
        }
        return section;
      });
      setSections(() => newSections);
    }
    if (isEditable === true) {
      // const { value } = e.target;
      const oldSections = sections;
      const newSections = oldSections.map((section) => {
        if (section.sectionId === index + 1) {
          return { ...section, sectionName: newSectionName, isEditable: false };
        }
        return section;
      });

      // console.log(newSections);
      setSections(() => newSections);
    }
  };

  const handleSectionLock = (e, sectionId, prevLocked) => {
    e.preventDefault();
    // console.log(sectionId, prevLocked);
    const newSections = sections.map((section, index) => {
      if (section.sectionId === sectionId) {
        return {
          ...section,
          isLocked: !prevLocked,
          lockedAuditInfo: {
            user: "John/Jane Doe",
            lockedAt: new Date(),
          },
        };
      }

      return section;
    });

    // console.log(newSections);
    setSections(() => newSections);
  };

  const handleSectionDelete = (e, index) => {
    e.preventDefault();

    // setCurrSection(() => index);
    let newSections = sections.filter(
      (section) => index + 1 !== section.sectionId,
    );
    newSections = newSections.map((el, index) => ({
      ...el,
      sectionId: index + 1,
    }));
    setSections(() => newSections);
    let newIndex = currSection;
    if (index === currSection) {
      newIndex = index - 1 > 0 ? index - 1 : 0;
    }
    setCurrSection(() => newIndex);
  };

  const handleChange = (
    e,
    multiVal = null,
    inputProps = null,
    subques = null,
  ) => {
    // console.log(e,multiVal);
    if (multiVal !== null) {
      if (multiVal[0] === "toggleInput") {
        const { name, checked } = e.target;
        // console.log(name, checked);
        setFormData(() => ({ ...formData, [name]: checked }));
      }

      if (multiVal[0] === "rangeInput") {
        setFormData(() => ({ ...formData, [multiVal[1]]: multiVal[2] }));
      }

      if (multiVal[0] === "dropDownInput") {
        const { value } = e.target;
        const [inputType, raisedFrom] = multiVal;
        let tempData = { ...formData };
        if (raisedFrom === "dataTableList") {
          // console.log(value);
          const newTableListData = tempData.tablelist.map((tableData) => {
            if (tableData[0].label === value) {
              return [tableData[0], true];
            }
            return [tableData[0], false];
          });
          // console.log(newTableListData);
          setFormData(() => ({ ...formData, tablelist: newTableListData }));
        }
        // else if (raisedFrom === "clients") {
        //   let newClientsData = tempData.clients.map((el) => {
        //     if (value.includes(el[0])) {
        //       return [el[0], true];
        //     }
        //     return [el[0], false];
        //   });
        //   // console.log(newClientsData);
        //   setFormData(() => ({ ...formData, [raisedFrom]: newClientsData }));
        // } else if (raisedFrom === "plans") {
        //   let newPlansData = tempData.plans.map((el) => {
        //     if (value.includes(el[0])) {
        //       return [el[0], true];
        //     }
        //     return [el[0], false];
        //   });
        //   // console.log(newClientsData);
        //   setFormData(() => ({ ...formData, [raisedFrom]: newPlansData }));
        // } else if (raisedFrom === "ageGroup") {
        //   let newAgeData = tempData.ageGroup.map((el) => {
        //     if (value.includes(el[0])) {
        //       return [el[0], true];
        //     }
        //     return [el[0], false];
        //   });
        //   // console.log(newClientsData);
        //   setFormData(() => ({ ...formData, [raisedFrom]: newAgeData }));
        // }
      }
    } else {
      e.preventDefault();
      const { name, value } = e.target;

      setFormData(() => ({ ...formData, [name]: value }));
    }

    const data = { ...editFormProps, isFieldsModified: true };
    setEditFormProps(() => data);
    // console.log(data.isFieldsModified, "ldnnc", formData);
  };

  const handleClick = (e, id) => {
    let isInputPropsOpen = false,
      inputNotSavedLabel = "";

    if (formData && Object.keys(formData).length > 0) {
      isInputPropsOpen = true;
      inputNotSavedLabel = formData.label;
    } else {
      isInputPropsOpen = false;
    }

    isInputPropsOpen === true && editFormProps.isFieldsModified === true
      ? setOpen(() => true)
      : setOpen(() => false);

    // console.log(isInputPropsOpen, editFormProps.isFieldsModified, "jiokli");
    if (editFormProps.isFieldsModified === false) {
      // e.stopPropagation();
      const data = { isMinimized: false, isFieldsModified: false };
      // console.log(`${renderCount} with dropped`, dForm);
      setEditFormProps(() => data);

      if (formData && Object.keys(formData).length > 0) {
        if (id === formData.questionId) {
          // console.log("same");
          const tempObj = dForm.map((el) => {
            if (el.questionId === id) {
              return { ...el, editToggle: false };
            }
            return el;
          });
          setDForm(() => tempObj);
          setFormData(() => {});
          const data = { ...editFormProps, isMinimized: true };
          // console.log(data);
          setEditFormProps(() => data);
        } else {
          // console.log("different");
          const tempObj = dForm.map((el) => {
            if (el.questionId === id) {
              return {
                ...el,
                editToggle: true,
                // required: el.required === true ? "yes" : "no",
              };
            }
            return el;
          });
          let inputData = tempObj.filter((input) => id === input.questionId);
          inputData = { ...inputData[0], editToggle: true };
          // console.log(inputData);
          setFormData(inputData);
        }
      } else {
        // console.log("first time");
        const tempObj = dForm.map((el) => {
          if (el.questionId === id) {
            return {
              ...el,
              editToggle: true,
              // required: el.required === true ? "yes" : "no",
            };
          }
          return el;
        });
        let inputData = tempObj.filter((input) => id === input.questionId);
        inputData = { ...inputData[0], editToggle: true };
        // console.log(inputData);
        setFormData(() => inputData);
      }
    }
  };

  const handleDelete = (id) => {
    // console.log(id, dForm);
    let inputData = dForm.filter((input) => id !== input.questionId)
      ? dForm.filter((input) => id !== input.questionId)
      : [];
    // console.log(inputData);
    setFormData({});
    setDForm(() => inputData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // handleFieldConfigCheck();
    const tempData = sections.map((section, secindex) => {
      if (secindex === currSection) {
        let formData = dForm.map((questions, quesindex) => {
          // questions =
          //   questions.required === "yes"
          //     ? { ...questions, required: true }
          //     : { ...questions, required: false };

          // questions.name = populateFieldName(questions.label);
          questions.questionId = quesindex + 1;
          questions.isModified = false;
          return questions;
        });

        const depthFormData = addMetaData(formData);

        return { ...section, sectionId: secindex + 1, questions: formData };
      }

      return { ...section, sectionId: secindex + 1 };
    });
    saveFormData(tempData);
    setSections(() => tempData);
    setCurrSection((currSection) => currSection);
  };

  const handleInputSubmit = (e) => {
    handleEditSubmit(e);
    handleFormSubmit(e);
    // handleEditClose(e)
    handleClose(e);
  };

  const handleClose = (e) => {
    setOpen(() => false);
    handleEditClose();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    // console.log(editFormProps);
    const isSubquestion = Object.keys(formData).includes("subQuestionId");
    // console.log(isSubquestion);
    if (isSubquestion) {
      // let { questionId, subQuestionId, optionIndex, depth } = editFormProps;
      // console.log(questionId, subQuestionId, optionIndex, depth);
      // let { uuid,questionId } = editFormProps;
      const { uuid } = editFormProps;
      const quesIdTraverse = uuid.split("_");
      const questionId = quesIdTraverse[0];
      quesIdTraverse.shift();
      let newDForm = [...dForm];
      let newQuesData = newDForm.filter(
        (el) => el.questionId === Number(questionId),
      )[0];
      // console.log(newQuesData);
      let tempData = newQuesData.subQuestions;
      function updateData(nestedArray, treedepth, quesIdArray) {
        // console.log(nestedArray, treedepth,quesIdArray,);
        // debugger
        if (quesIdArray.length > 1) {
          return nestedArray.map((obj) => {
            // console.log(obj, quesIdArray);
            // obj.depth = treedepth;
            const subQuestionId = quesIdTraverse[0];
            // console.log(Number(subQuestionId), obj.subQuestionId);
            if (Number(subQuestionId) === obj.subQuestionId) {
              quesIdTraverse.shift();
              // console.log(subQuestionId, obj.subQuestionId, obj.label);
              if (obj.hasSubQuestions || quesIdArray.length > 0) {
                let temp1 = updateData(
                  obj.subQuestions,
                  treedepth + 1,
                  quesIdArray,
                );
                // console.log(temp1);
                obj.subQuestions = temp1;
              }
            }
            return obj;
          });
        } else {
          // console.log(nestedArray, quesIdArray);
          const subQuestionId = Number(quesIdArray[0]) - 1;
          let newSubQuesData = nestedArray[subQuestionId];
          // console.log(newSubQuesData);
          let newLabel = formData.label;
          let newName = populateFieldName(newLabel);
          newSubQuesData = {
            ...formData,
            editToggle: false,
            name: newName,
            isConfigured: true,
            isModified: true,
          };
          nestedArray[subQuestionId] = newSubQuesData;
          return nestedArray;
        }
      }

      let newSubQuesData = updateData(tempData, 1, quesIdTraverse);
      // console.log(newSubQuesData);

      newQuesData = { ...newQuesData, subQuestions: newSubQuesData };

      newDForm[questionId - 1] = newQuesData;

      setDForm(() => newDForm);

      handleEditClose();

      setFormData(() => {});

      const data = { isMinimized: true, isFieldsModified: false };

      setEditFormProps(() => data);
    } else {
      const tempId = formData ? formData.questionId : 0;
      if (tempId > 0) {
        const tempObj = dForm.map((el) => {
          if (el.questionId === tempId) {
            Object.keys(el).forEach((key) => (el[key] = formData[key]));
            el = {
              ...el,
              editToggle: false,
              isConfigured: true,
              isModified: true,
            };
          }

          return el;
        });
        // console.log(formData);
        setDForm(() => tempObj);
        // console.log(tempObj);
        setFormData(() => {});
        const data = {
          ...editFormProps,
          isMinimized: true,
          isFieldsModified: false,
        };
        setEditFormProps(() => data);
      }
    }
  };

  const handleEditClose = () => {
    // e.preventDefault();
    const data = {
      ...editFormProps,
      isMinimized: true,
      isFieldsModified: false,
    };
    // console.log(data);
    setEditFormProps(() => data);
  };

  const handleChangeRadio = (e, inputProps) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (
      inputProps.pattern === "text" &&
      value.match(
        /^[a-zA-Z ]+$/,
        // /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/
      )
    ) {
      setFormData(() => ({ ...formData, [name]: value }));
    } else if (inputProps.pattern === "alpha" && value.match(/^[a-zA-Z ]+$/)) {
      setFormData(() => ({ ...formData, [name]: value }));
    } else if (
      inputProps.pattern === "alphanumeric" &&
      value.match(/^[a-zA-Z ]+$^(0|[1-9][0-9]*)$/)
    ) {
      setFormData(() => ({ ...formData, [name]: value }));
    } else if (
      inputProps.pattern === "numeric" &&
      value.match(/^(0|[1-9][0-9]*)$/)
    ) {
      setFormData(() => ({ ...formData, [name]: value }));
    } else if (
      inputProps.pattern === "alphanumericspecial" &&
      value.match(
        /^[a-zA-Z ]+$^(0|[1-9][0-9]*)$^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g,
      )
    ) {
      setFormData(() => ({ ...formData, [name]: value }));
    } else {
    }
  };

  const handleAddOption = () => {
    const options = formData.options;
    const newOptions = [...options, ["", ""]];
    const temp = { ...formData, options: newOptions };
    setFormData(temp);
  };

  const handleToggleOptionChange = (event, rowIndex) => {
    setSelectedToggles((prevToggles) => {
      const updatedToggles = [...prevToggles];
      updatedToggles[rowIndex] = event.target.value;
      return updatedToggles;
    });
  };

  const handleOptionChange = (e, index) => {
    const options = formData.options;
    let newOptions = [...options];
    newOptions[index] = [e.target.value, populateFieldName(e.target.value)];
    // console.log(newOptions[index]);
    const temp = { ...formData, options: newOptions };
    setFormData(temp);
  };

  const handleDeleteOption = (index) => {
    const options = formData.options;
    const newOptions = [...options];
    newOptions.splice(index, 1);
    const temp = { ...formData, options: newOptions };
    setFormData(temp);
  };

  const addInputTypeToBoard = (data) => {
    // console.log(data);
    // console.log(`${renderCount} with dropped`, data,dForm);
    let inputData = inputTypes.filter((input) => data.id === input.id)[0]
      ? inputTypes.filter((input) => data.id === input.id)[0]
      : [];
    inputid = data && data?.oldDataLength ? data?.oldDataLength + 1 : 1;

    inputData = {
      ...inputData,
      questionId: inputid,
      name: `${inputData.name}_${inputid}`,
    };

    // console.log(dForm, inputData);
    setDForm(
      (prev) => [...prev, { ...inputData }],
      // console.log("updated Dform", dForm)
    );
  };

  const handleSectionRearrange = (array, oldIndex, newIndex) => {
    let oldValue = array.splice(oldIndex, 1)[0];
    array.splice(newIndex, 0, oldValue);
    let newArray = array.map((el, index) => ({ ...el, sectionid: index + 1 }));
    // console.log(newArray);
    setSections(newArray);
  };

  const handleQuestionRearrange = (array, oldIndex, newIndex) => {
    let oldValue = array.splice(oldIndex, 1)[0];
    array.splice(newIndex, 0, oldValue);
    let newArray = array.map((el, index) => ({ ...el, questionId: index + 1 }));
    setDForm(() => newArray);
  };

  const addInputOptions = () => {
    let newFormData = { ...formData };
    const options = formData.subQuestions;
    let newSubQuesID = options.length ? options.length + 1 : 0;
    const newOptions = [
      ...options,
      {
        subQuestionId: newSubQuesID,
        hasSubQuestions: false,
        subQuestions: [],
        editToggle: false,
        isCommentEnabled: false,
        isConfigured: false,
        isModified: false,
      },
    ];
    newFormData = { ...formData, subQuestions: newOptions };
    // console.log(newFormData);
    setFormData(() => newFormData);
  };

  const deleteInputOptions = (index) => {
    let newFormData = { ...formData };
    const options = formData.subQuestions;
    const newOptions = [...options];
    newOptions.splice(index, 1);
    newFormData = { ...formData, subQuestions: newOptions };
    // console.log(newFormData);
    setFormData(() => newFormData);
  };

  const addSubQuestions = (e, multiVal) => {
    // console.log(multiVal);
    let [questionIndex, subQuesTypeIndex, depth] = multiVal;
    let tempOptionsArray = formData.subQuestions;
    let tempOption = tempOptionsArray[questionIndex];
    // console.log(tempOption);
    let newSubQuestionsArray = tempOption.subQuestions;
    let newSubQuestion = {
      editToggle: false,
      isCommentEnabled: false,
      isConfigured: false,
      isModified: false,
    };
    newSubQuestionsArray = [...newSubQuestionsArray, newSubQuestion];
    tempOption = { ...tempOption, subQuestions: newSubQuestionsArray };
    tempOptionsArray[questionIndex] = tempOption;
    let newFormData = {
      ...formData,
      subQuestions: tempOptionsArray,
      isModified: true,
    };
    // console.log(newFormData);
    setFormData(() => newFormData);
  };

  const deleteSubQuestions = (e, multiVal) => {
    let [questionIndex, subQuesTypeIndex, depth] = multiVal;
    // console.log(questionIndex, subQuesTypeIndex, depth);
    let tempOptionsArray = formData.subQuestions;
    let tempOption = tempOptionsArray[questionIndex];
    // console.log(tempOption);
    let newSubQuestionsArray = tempOption.subQuestions;
    newSubQuestionsArray.splice(subQuesTypeIndex, 1);
    // console.log(newSubQuestionsArray);
    tempOption = { ...tempOption, subQuestions: newSubQuestionsArray };
    tempOptionsArray[questionIndex] = tempOption;
    let newFormData = {
      ...formData,
      subQuestions: tempOptionsArray,
      isModified: true,
    };
    // console.log(newFormData);
    setFormData(() => newFormData);
  };

  const changeSubQuestions = (e, multiVal) => {
    let [questionIndex, subQuesTypeIndex, depth] = multiVal;
    // console.log(questionIndex, subQuesTypeIndex, depth);
    let tempOptionsArray = formData.subQuestions;
    let tempOption = tempOptionsArray[questionIndex];
    // console.log(tempOption);
    let newSubQuestionsArray = tempOption.subQuestions;
    newSubQuestionsArray.splice(subQuesTypeIndex, 1);
    tempOption = { ...tempOption, subQuestions: newSubQuestionsArray };
    tempOptionsArray[questionIndex] = tempOption;
    let newFormData = {
      ...formData,
      subQuestions: tempOptionsArray,
      isModified: true,
    };
    // console.log(newFormData);
    setFormData(() => newFormData);
  };

  const handleOptionsTreeChange = (e, multiVal = null) => {
    // e.preventDefault();
    // console.log(e,multiVal);
    if (multiVal !== null) {
      const type = multiVal[0];
      if (type === "textbox") {
        // console.log(multiVal);
        const itemIndex = multiVal[1];
        const options = formData.subQuestions;
        let newOptions = [...options];
        let newOption = newOptions[itemIndex];
        newOption = {
          ...newOption,
          label: e.target.value,
          value: populateFieldName(e.target.value),
          isConfigured: true,
          type: "toggle_button",
        };
        // console.log(newOption);
        newOptions[itemIndex] = newOption;
        const newFormData = { ...formData, subQuestions: newOptions };
        setFormData(() => newFormData);
      } else if (type === "checkbox") {
        // console.log(e.target.checked);
        const itemIndex = multiVal[1];
        let newFormData = { ...formData };
        const options = newFormData.subQuestions;
        let newOptions = [...options];
        let newOption = newOptions[itemIndex];
        let hasSubQues = newOption.hasSubQuestions;
        if (hasSubQues) {
          newOption = {
            ...newOption,
            hasSubQuestions: false,
            subQuestions: [{}],
          };
        } else {
          newOption = {
            ...newOption,
            hasSubQuestions: true,
            subQuestions: [{}],
          };
        }

        // console.log(newOption);
        newOptions[itemIndex] = newOption;
        newFormData = {
          ...newFormData,
          subQuestions: newOptions,
          isModified: true,
        };
        // console.log(newFormData);
        setFormData(() => newFormData);
      } else if (type === "suboptionstree") {
        // console.log(e.target.value);
        const [type, mainIndex, itemIndex, depth] = multiVal;
        // console.log(type, mainIndex, itemIndex, depth);
        let newData = { ...formData };
        let newSubQuestionsData = newData.subQuestions[mainIndex];
        let tempData = inputTypes.filter((el) => el.type === e.target.value)[0];
        tempData = { ...tempData, subQuestionId: itemIndex + 1 };
        newSubQuestionsData.subQuestions[itemIndex] = tempData;
        newData.subQuestions[mainIndex] = newSubQuestionsData;
        // console.log(newData);
        setFormData(() => newData);
      } else {
        const { value } = e.target;
        // console.log(value);
        const [type, itemDepth, itemIndex] = multiVal;
        // console.log(itemDepth, itemIndex);
        let optionsData = { ...formData };
        let tempData = inputTypes.filter((el) => el.name === value)[0];
        tempData = { ...tempData, subQuestionId: itemIndex + 1 };
        // console.log(tempData);
        optionsData.options[itemIndex] = tempData;
        // console.log(optionsData);
        setFormData(optionsData);
      }
    }
  };

  const handleOptionsTreeClick = (e, multiVal = null) => {
    // e.preventDefault();
    e.stopPropagation();

    if (multiVal !== null) {
      // const [quesid, optionIndex, subQuesData, depth] = multiVal;
      // const [uuid,quesid, subQuesData] = multiVal;
      const [uuid, subQuesData] = multiVal;
      // console.log(subQuesData.type);
      if (subQuesData.type !== "toggle_button") {
        // console.log(subQuesData);
        const data = {
          isMinimized: false,
          uuid,
          // questionId: quesid,
          // optionIndex,
          // subQuestionId: subQuesData.subQuestionId,
          // depth,
        };

        // console.log(data);

        setEditFormProps(() => data);

        let tempData = subQuesData;

        tempData = { ...tempData, editToggle: true };

        setFormData(() => tempData);
      }
    }
  };

  const value = {
    inputTypes,
    userCategories,
    loading,
    setLoading,
    sections,
    setSections,
    currSection,
    setCurrSection,
    dForm,
    setDForm,
    formData,
    setFormData,
    editFormProps,
    setEditFormProps,
    clientData,
    setClientData,
    handleClientDataChange,
    handleClientDataSubmit,
    addInputTypeToBoard,
    handleSectionAdd,
    handleSectionClick,
    handleSectionNameEdit,
    // handleSectionNameChange,
    handleSectionLock,
    handleSectionDelete,
    handleClick,
    handleDelete,
    opens,
    setOpens,
    suspectOpens,
    setSuspectOpens,
    handleFormSubmit,
    handleEditSubmit,
    handleEditClose,
    handleAddOption,
    handleOptionChange,
    handleDeleteOption,
    handleQuestionRearrange,
    handleSectionRearrange,
    addInputOptions,
    deleteInputOptions,
    addSubQuestions,
    deleteSubQuestions,
    changeSubQuestions,
    handleOptionsTreeChange,
    handleOptionsTreeClick,
    handleFormPreview,
    handleClickOpen,
    handleTableDataChange,
    open,
    setOpen,
    handleClose,
    commentIndex,
    setCommentIndex,
    selectedDate,
    setSelectedDate,
    selectedOption,
    setSelectedOption,
    handleInputSubmit,
    toggleindex,
    setToggleIndex,
    handleSubQuestionDateChange,
    handleSubSubQuestionChange,
    handleDateChange,
    handleSubQuestionChange,
    selectIndex,
    setSelectIndex,
    subSelectedValue,
    setSubSelectedValue,
    subSelectIndex,
    SetSubSelectIndex,
    subSubSelectIndex,
    SetSubSubSelectIndex,
    tableRowData,
    setTableRowData,
    selectedToggles,
    setSelectedToggles,
    handleChange,
    handleChangeRadio,
    handleInputChange,
    handleButtonAction,
    handleChangeCheckbox,
    handleCloses,
    handleToggleOptionChange,
    openSignSubmitDialog,
    setSignSubmitDialog,
    padEligibilityDialog,
    setPadEligibilityDialog,
    // boardRearrangeRef,
    // boardDrag,
    // boardDrop,
    // inputTypeDrag,
    // inputTypeDrop,
    // isDragging,
  };

  return (
    <>
      <DragNDropContext.Provider value={value}>
        {children}
      </DragNDropContext.Provider>

      {isLoading && <Loader />}
    </>
  );
};
