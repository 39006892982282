import {
  getObjectData,
  setObjectData,
} from "../../../../utils/helpers.DynamicForm";

import {
  ros_hashmap,
  ros_OptionsTreeWrappers,
  ros_optionsTreeMapObject,
} from "./reviewOfSystemData";

export const getRosData = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  ros_hashmap?.forEach((questionPair, index) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  ros_OptionsTreeWrappers.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  const ros_eyes = getOptionsSelectedYesNo(tempDbData["ros_eyesVal"]);
  const ros_eyesComment = tempDbData["ros_eyesCommentVal"];
  const ros_eyesDiagnosesComment = tempDbData["ros_eyesDiagnosesCommentVal"];
  const ros_eyesDiagCataractSubWhEyeComment =
    tempDbData["ros_eyesDiagCataractSubWhEyeCommentVal"];
  const ros_eyesDiagCataractSubDescComment =
    tempDbData["ros_eyesDiagCataractSubDescCommentVal"];
  const ros_eyesDiagCataractSubSuppComment =
    tempDbData["ros_eyesDiagCataractSubSuppCommentVal"];
  const ros_eyesDiagCataractSubSuppSympSubComment =
    tempDbData["ros_eyesDiagCataractSubSuppSympSubCommentVal"];
  const ros_eyesDiagCataractSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagCataractSubSuppOtherSubDescVal"];
  const ros_eyesDiagCataractSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagCataractSubDiabetesVal"],
  );
  const ros_eyesDiagCataractSubDiabetesComment =
    tempDbData["ros_eyesDiagCataractSubSuppCommentVal"];

  const ros_eyesDiagGlaucomaSubWhEyeComment =
    tempDbData["ros_eyesDiagGlaucomaSubWhEyeCommentVal"];
  const ros_eyesDiagGlaucomaSubDescComment =
    tempDbData["ros_eyesDiagGlaucomaSubDescCommentVal"];
  const ros_eyesDiagGlaucomaSubSuppComment =
    tempDbData["ros_eyesDiagGlaucomaSubSuppCommentVal"];
  const ros_eyesDiagGlaucomaSubSuppSympSubComment =
    tempDbData["ros_eyesDiagGlaucomaSubSuppSympSubCommentVal"];
  const ros_eyesDiagGlaucomaSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagGlaucomaSubSuppOtherSubDescVal"];
  const ros_eyesDiagGlaucomaSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagGlaucomaSubDiabetesVal"],
  );
  const ros_eyesDiagGlaucomaSubDiabetesComment =
    tempDbData["ros_eyesDiagGlaucomaSubSuppCommentVal"];
  const ros_eyesDiagGlaucomaSubDiabetesSubComment =
    tempDbData["ros_eyesDiagGlaucomaSubDiabetesSubCommentVal"];

  const ros_eyesDiagHyperopiaSubWhEyeComment =
    tempDbData["ros_eyesDiagHyperopiaSubWhEyeCommentVal"];
  const ros_eyesDiagHyperopiaSubDescComment =
    tempDbData["ros_eyesDiagHyperopiaSubDescCommentVal"];
  const ros_eyesDiagHyperopiaSubSuppComment =
    tempDbData["ros_eyesDiagHyperopiaSubSuppCommentVal"];
  const ros_eyesDiagHyperopiaSubSuppSympSubComment =
    tempDbData["ros_eyesDiagHyperopiaSubSuppSympSubCommentVal"];
  const ros_eyesDiagHyperopiaSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagHyperopiaSubSuppOtherSubDescVal"];
  const ros_eyesDiagHyperopiaSubDesc =
    tempDbData["ros_eyesDiagHyperopiaSubDescVal"];

  const ros_eyesDiagLegalBlindSubWhEyeComment =
    tempDbData["ros_eyesDiagLegalBlindSubWhEyeCommentVal"];
  const ros_eyesDiagLegalBlindSubDescComment =
    tempDbData["ros_eyesDiagLegalBlindSubDescCommentVal"];
  const ros_eyesDiagLegalBlindSubSuppComment =
    tempDbData["ros_eyesDiagLegalBlindSubSuppCommentVal"];
  const ros_eyesDiagLegalBlindSubSuppSympSubComment =
    tempDbData["ros_eyesDiagLegalBlindSubSuppSympSubCommentVal"];
  const ros_eyesDiagLegalBlindSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagLegalBlindSubSuppOtherSubDescVal"];
  const ros_eyesDiagLegalBlindSubComment =
    tempDbData["ros_eyesDiagLegalBlindSubSubCommentVal"];
  const ros_eyesDiagLegalBlindSubDesc =
    tempDbData["ros_eyesDiagLegalBlindSubDescVal"];

  const ros_eyesDiagMascDegenSubWhEyeComment =
    tempDbData["ros_eyesDiagMascDegenSubWhEyeCommentVal"];
  const ros_eyesDiagMascDegenSubDescComment =
    tempDbData["ros_eyesDiagMascDegenSubDescCommentVal"];
  const ros_eyesDiagMascDegenSubSuppComment =
    tempDbData["ros_eyesDiagMascDegenSubSuppCommentVal"];
  const ros_eyesDiagMascDegenSubSuppSympSubComment =
    tempDbData["ros_eyesDiagMascDegenSubSuppSympSubCommentVal"];
  const ros_eyesDiagMascDegenSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagMascDegenSubSuppOtherSubDescVal"];
  const ros_eyesDiagMascDegenSubComment =
    tempDbData["ros_eyesDiagMascDegenSubCommentVal"];
  const ros_eyesDiagMascDegenSubDesc =
    tempDbData["ros_eyesDiagMascDegenSubDescVal"];

  const ros_eyesDiagMyopiaSubWhEyeComment =
    tempDbData["ros_eyesDiagMyopiaSubWhEyeCommentVal"];
  const ros_eyesDiagMyopiaSubDescComment =
    tempDbData["ros_eyesDiagMyopiaSubDescCommentVal"];
  const ros_eyesDiagMyopiaSubSuppComment =
    tempDbData["ros_eyesDiagMyopiaSubSuppCommentVal"];
  const ros_eyesDiagMyopiaSubSuppSympSubComment =
    tempDbData["ros_eyesDiagMyopiaSubSuppSympSubCommentVal"];
  const ros_eyesDiagMyopiaSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagMyopiaSubSuppOtherSubDescVal"];
  const ros_eyesDiagMyopiaSubDesc = tempDbData["ros_eyesDiagMyopiaSubDescVal"];

  const ros_eyesDiagRetinalSubWhEyeComment =
    tempDbData["ros_eyesDiagRetinalSubWhEyeCommentVal"];
  const ros_eyesDiagRetinalSubDescComment =
    tempDbData["ros_eyesDiagRetinalSubDescCommentVal"];
  const ros_eyesDiagRetinalSubSuppComment =
    tempDbData["ros_eyesDiagRetinalSubSuppCommentVal"];
  const ros_eyesDiagRetinalSubSuppSympSubComment =
    tempDbData["ros_eyesDiagRetinalSubSuppSympSubCommentVal"];
  const ros_eyesDiagRetinalSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagRetinalSubSuppOtherSubDescVal"];
  const ros_eyesDiagRetinalSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagRetinalSubDiabetesVal"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubIsVitHemm = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubComment =
    tempDbData["ros_eyesDiagRetinalSubDiabetesSubCommentVal"];
  const ros_eyesDiagRetinalSubDiabetesComment =
    tempDbData["ros_eyesDiagRetinalSubDiabetesCommentVal"];

  const ros_eyesDiagOtherSubWhEyeComment =
    tempDbData["ros_eyesDiagOtherSubWhEyeCommentVal"];
  const ros_eyesDiagOtherSubDescComment =
    tempDbData["ros_eyesDiagOtherSubDescCommentVal"];
  const ros_eyesDiagOtherSubSuppComment =
    tempDbData["ros_eyesDiagOtherSubSuppCommentVal"];
  const ros_eyesDiagOtherSubSuppSympSubComment =
    tempDbData["ros_eyesDiagOtherSubSuppSympSubCommentVal"];
  const ros_eyesDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagOtherSubSuppOtherSubDescVal"];
  const ros_eyesDiagOtherSubOther = tempDbData["ros_eyesDiagOtherSubOtherVal"];

  const ros_ears = getOptionsSelectedYesNo(tempDbData["ros_earsVal"]);
  const ros_earsComment = tempDbData["ros_earsCommentVal"];
  const ros_earsDiagnosesComment = tempDbData["ros_earsDiagnosesCommentVal"];
  const ros_earsDiagDiffHearSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagDiffHearSubSuppOtherSubDescVal"];
  const ros_earsDiagDiffHearSubSuppComment =
    tempDbData["ros_earsDiagDiffHearSubSuppCommentVal"];

  const ros_earsDiagLegalDeafSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagLegalDeafSubSuppOtherSubDescVal"];
  const ros_earsDiagLegalDeafSubSuppComment =
    tempDbData["ros_earsDiagLegalDeafSubSuppCommentVal"];

  const ros_earsDiagTinnitusSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagTinnitusSubSuppOtherSubDescVal"];
  const ros_earsDiagTinnitusSubSuppComment =
    tempDbData["ros_earsDiagTinnitusSubSuppCommentVal"];

  const ros_earsDiagVertigoSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagVertigoSubSuppOtherSubDescVal"];
  const ros_earsDiagVertigoSubSuppComment =
    tempDbData["ros_earsDiagVertigoSubSuppCommentVal"];
  const ros_earsDiagVertigoSubLoseBal = getOptionsSelectedYesNo(
    tempDbData["ros_earsDiagVertigoSubLoseBalVal"],
  );
  const ros_earsDiagVertigoSubLoseBalComment =
    tempDbData["ros_earsDiagVertigoSubLoseBalCommentVal"];

  const ros_earsDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagOtherSubSuppOtherSubDescVal"];
  const ros_earsDiagOtherSubSuppComment =
    tempDbData["ros_earsDiagOtherSubSuppCommentVal"];

  const ros_earsDiagOtherSubOther = tempDbData["ros_earsDiagOtherSubOtherVal"];

  const ros_nose = getOptionsSelectedYesNo(tempDbData["ros_noseVal"]);

  const ros_noseDiagAllergySubSuppOtherSubDesc =
    tempDbData["ros_noseDiagAllergySubSuppOtherSubDescVal"];
  const ros_noseDiagAllergySubSuppComment =
    tempDbData["ros_noseDiagAllergySubSuppCommentVal"];
  const ros_noseDiagChronicNasalSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagChronicNasalSubSuppOtherSubDescVal"];
  const ros_noseDiagChronicNasalSubSuppComment =
    tempDbData["ros_noseDiagChronicNasalSubSuppCommentVal"];
  const ros_noseDiagNoseBleedSubComment =
    tempDbData["ros_noseDiagNoseBleedSubCommentVal"];
  const ros_noseDiagSeasonAllergySubSuppOtherSubDesc =
    tempDbData["ros_noseDiagSeasonAllergySubSuppOtherSubDescVal"];
  const ros_noseDiagSeasonAllergySubSuppComment =
    tempDbData["ros_noseDiagSeasonAllergySubSuppCommentVal"];
  const ros_noseDiagSinusInfectSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagSinusInfectSubSuppOtherSubDescVal"];
  const ros_noseDiagSinusInfectSubSuppComment =
    tempDbData["ros_noseDiagSinusInfectSubSuppCommentVal"];
  const ros_noseDiagSinusInfectSubExuComment =
    tempDbData["ros_noseDiagSinusInfectSubExuCommentVal"];

  const ros_noseDiagSinustisSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagSinustisSubSuppOtherSubDescVal"];
  const ros_noseDiagSinustisSubSuppComment =
    tempDbData["ros_noseDiagSinustisSubSuppCommentVal"];

  const ros_noseDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagOtherSubSuppOtherSubDescVal"];
  const ros_noseDiagOtherSubSuppComment =
    tempDbData["ros_noseDiagOtherSubSuppCommentVal"];
  const ros_noseDiagOtherSubOther = tempDbData["ros_noseDiagOtherSubOtherVal"];

  const ros_noseDiagComment = tempDbData["ros_noseDiagCommentVal"];
  const ros_noseComment = tempDbData["ros_noseCommentVal"];

  const ros_mouth = getOptionsSelectedYesNo(tempDbData["ros_mouthVal"]);
  const ros_mouthDiagComment = tempDbData["ros_mouthDiagCommentVal"];
  const ros_mouthComment = tempDbData["ros_mouthCommentVal"];
  const ros_mouthDiagBleedGumsSubComment =
    tempDbData["ros_mouthDiagBleedGumsSubCommentVal"];
  const ros_mouthDiagDiffChewSubDescComment =
    tempDbData["ros_mouthDiagDiffChewSubDescCommentVal"];
  const ros_mouthDiagDiffChewSubBcsPain = getOptionsSelectedYesNo(
    tempDbData["ros_mouthDiagDiffChewSubBcsPainVal"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSub = getOptionsSelectedYesNo(
    tempDbData["ros_mouthDiagDiffChewSubBcsPainSubVal"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSubComment =
    tempDbData["ros_mouthDiagDiffChewSubBcsPainSubCommentVal"];
  const ros_mouthDiagDiffChewSubBcsPainComment =
    tempDbData["ros_mouthDiagDiffChewSubBcsPainCommentVal"];

  const ros_mouthDiagDiffSwallowSubHadStroke = getOptionsSelectedYesNo(
    tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeVal"],
  );
  const ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub =
    getOptionsSelectedYesNo(
      tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal"],
    );
  const ros_mouthDiagDiffSwallowSubHadStrokeSubComment =
    tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeSubCommentVal"];
  const ros_mouthDiagDiffSwallowSubHadStrokeComment =
    tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeCommentVal"];

  const ros_mouthDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_mouthDiagOtherSubSuppOtherSubDescVal"];
  const ros_mouthDiagOtherSubSuppComment =
    tempDbData["ros_mouthDiagOtherSubSuppCommentVal"];
  const ros_mouthDiagOtherSubOther =
    tempDbData["ros_mouthDiagOtherSubOtherVal"];

  const ros_neck = getOptionsSelectedYesNo(tempDbData["ros_neckVal"]);
  const ros_neckDiagComment = tempDbData["ros_neckDiagCommentVal"];
  const ros_neckComment = tempDbData["ros_neckCommentVal"];
  const ros_neckDiagCarotidSubDescComment =
    tempDbData["ros_neckDiagCarotidSubDescCommentVal"];
  const ros_neckDiagCarotidSubSuppOtherSubDesc =
    tempDbData["ros_neckDiagCarotidSubSuppOtherSubDescVal"];
  const ros_neckDiagCarotidSubSuppComment =
    tempDbData["ros_neckDiagCarotidSubSuppCommentVal"];
  const ros_neckDiagCarotidCsDescComment =
    tempDbData["ros_neckDiagCarotidCsDescCommentVal"];

  const ros_neckDiagParotidSubSuppOtherSubDesc =
    tempDbData["ros_neckDiagParotidSubSuppOtherSubDescVal"];
  const ros_neckDiagParotidSubSuppComment =
    tempDbData["ros_neckDiagParotidSubSuppCommentVal"];
  const ros_neckDiagParotidSubDescComment =
    tempDbData["ros_neckDiagParotidSubDescCommentVal"];

  const ros_neckDiagOtherSubDescComment =
    tempDbData["ros_neckDiagOtherSubDescCommentVal"];
  const ros_neckDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_neckDiagOtherSubSuppOtherSubDescVal"];
  const ros_neckDiagOtherSubSuppComment =
    tempDbData["ros_neckDiagOtherSubSuppCommentVal"];
  const ros_neckDiagOtherSubOther = tempDbData["ros_neckDiagOtherSubOtherVal"];

  const ros_eyeENTNeckRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_recoHearEvalVal"],
      tempDbData["ros_recoDentalExamVal"],
      tempDbData["ros_recoEyeExamVal"],
      tempDbData["ros_recoSwalEvalyVal"],
      tempDbData["ros_recoTakeMedVal"],
      tempDbData["ros_recoFoodDispVal"],
      tempDbData["ros_recoOtherVal"],
    ],
    [
      "hearingEvaluation",
      "dentalExam",
      "eyeExam",
      "swallowingEvaluation",
      "takeMedications",
      "other",
    ],
  );

  const ros_eyeENTRecoHearEvalComment =
    tempDbData["ros_eyeENTRecoHearEvalCommentVal"];
  const ros_eyeENTRecoDentalExamComment =
    tempDbData["ros_eyeENTRecoDentalExamCommentVal"];
  const ros_eyeENTRecoEyeExamComment =
    tempDbData["ros_eyeENTRecoEyeExamCommentVal"];
  const ros_eyeENTRecoSwalEvalyComment =
    tempDbData["ros_eyeENTRecoSwalEvalyCommentVal"];
  const ros_eyeENTRecoTakeMedComment =
    tempDbData["ros_eyeENTRecoTakeMedCommentVal"];
  const ros_eyeENTRecoFoodDispComment =
    tempDbData["ros_eyeENTRecoFoodDispCommentVal"];
  const ros_eyeENTRecoOtherComment =
    tempDbData["ros_eyeENTRecoOtherCommentVal"];

  const ros_bowelMoveAbnormDiarrIshis = getOptionsSelectedYesNo(
    tempDbData["ros_bowelMoveAbnormDiarrIshisVal"],
  );
  const ros_bowelMoveAbnormDiarrIshisComment =
    tempDbData["ros_bowelMoveAbnormDiarrIshisCommentVal"];

  const ros_bowelMoveComment = tempDbData["ros_bowelMoveCommentVal"];
  const ros_bowelMoveAbnormComment =
    tempDbData["ros_bowelMoveAbnormCommentVal"];
  const ros_bowelMoveAbnormConstComment =
    tempDbData["ros_bowelMoveAbnormConstCommentVal"];
  const ros_bowelMoveAbnormDiarrIsdiaComment =
    tempDbData["ros_bowelMoveAbnormDiarrIsdiaCommentVal"];

  const ros_abdominalIsabd = getOptionsSelectedYesNo(
    tempDbData["ros_abdominalIsabdVal"],
  );
  const ros_abdominalIsyesComment = tempDbData["ros_abdominalIsyesCommentVal"];
  const ros_abdominalComment = tempDbData["ros_abdominalCommentVal"];

  const ros_rectalIsrec = getOptionsSelectedYesNo(
    tempDbData["ros_rectalIsrecVal"],
  );
  const ros_rectalIsrecIsyesmalComment =
    tempDbData["ros_rectalIsrecIsyesmalCommentVal"];
  const ros_rectalIsrecIsyesfemComment =
    tempDbData["ros_rectalIsrecIsyesfemCommentVal"];
  const ros_rectalComment = tempDbData["ros_rectalCommentVal"];

  const ros_lastBowelComment = tempDbData["ros_lastBowelCommentVal"];

  const ros_meetWithComment = tempDbData["ros_meetWithCommentVal"];

  const ros_gpcogScoIsgpcogs = tempDbData["ros_gpcogScoIsgpcogsVal"];
  const ros_gpcogScoIsmmsesc = tempDbData["ros_gpcogScoIsmmsescVal"];
  const ros_gpcogScoComment = tempDbData["ros_gpcogScoCommentVal"];

  const ros_noGpcogMmsePatientOrientPerson = getOptionsSelectedYesNo(
    tempDbData["ros_noGpcogMmsePatientOrientPersonVal"],
  );
  const ros_noGpcogMmsePatientOrientPlace = getOptionsSelectedYesNo(
    tempDbData["ros_noGpcogMmsePatientOrientPlaceVal"],
  );
  const ros_noGpcogMmsePatientOrientTime = getOptionsSelectedYesNo(
    tempDbData["ros_noGpcogMmsePatientOrientTimeVal"],
  );
  const ros_noGpcogMmsePatientDescribeNews = getOptionsSelectedYesNoPartially(
    tempDbData["ros_noGpcogMmsePatientDescribeNewsVal"],
  );
  const ros_noGpcogMmseRecallComment =
    tempDbData["ros_noGpcogMmseRecallCommentVal"];
  const ros_noGpcogMmsePatientDescribeNewsComment =
    tempDbData["ros_noGpcogMmsePatientDescribeNewsCommentVal"];
  const ros_noGpcogMmsePatientOrientPersonComment =
    tempDbData["ros_noGpcogMmsePatientOrientPersonCommentVal"];
  const ros_noGpcogMmsePatientOrientPlaceComment =
    tempDbData["ros_noGpcogMmsePatientOrientPlaceCommentVal"];
  const ros_noGpcogMmsePatientOrientTimeComment =
    tempDbData["ros_noGpcogMmsePatientOrientTimeCommentVal"];

  const ros_speechComment = tempDbData["ros_speechCommentVal"];

  const ros_sitToStandComment = tempDbData["ros_sitToStandCommentVal"];

  const ros_fingerToNoseAbnormalSubComment =
    tempDbData["ros_fingerToNoseAbnormalSubCommentVal"];
  const ros_fingerToNoseComment = tempDbData["ros_fingerToNoseCommentVal"];

  const ros_heelToToeAbnormalSubComment =
    tempDbData["ros_heelToToeAbnormalSubCommentVal"];
  const ros_heelToToeComment = tempDbData["ros_heelToToeCommentVal"];

  const ros_thumbToFingerAbnormalSubComment =
    tempDbData["ros_thumbToFingerAbnormalSubCommentVal"];
  const ros_thumbToFingerComment = tempDbData["ros_thumbToFingerCommentVal"];

  const ros_extremeMoveComment = tempDbData["ros_extremeMoveCommentVal"];

  const ros_affectComment = tempDbData["ros_affectCommentVal"];
  const ros_affectSubComment = tempDbData["ros_affectSubCommentVal"];

  const ros_over2WeeksLittleIntrstComment =
    tempDbData["ros_over2WeeksLittleIntrstCommentVal"];
  const ros_over2WeeksFeelDepressedComment =
    tempDbData["ros_over2WeeksFeelDepressedCommentVal"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstComment =
    tempDbData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubCommentVal"
    ];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressComment =
    tempDbData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubCommentVal"
    ];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal"];

  const ros_neuroPsych = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychVal"],
  );
  const ros_neuroPsychComment = tempDbData["ros_neuroPsychCommentVal"];

  const ros_neuroPsychNerv = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychNervVal"],
  );
  const ros_neuroPsychNervComment = tempDbData["ros_neuroPsychNervCommentVal"];

  const ros_neuroPsychWorry = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychWorryVal"],
  );
  const ros_neuroPsychWorryComment =
    tempDbData["ros_neuroPsychWorryCommentVal"];

  const ros_neuroPsychAfraid = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychAfraidVal"],
  );
  const ros_neuroPsychAfraidComment =
    tempDbData["ros_neuroPsychAfraidCommentVal"];

  const ros_neuroPsychSubAuditory = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubAuditoryVal"],
  );
  const ros_neuroPsychSubAuditoryComment =
    tempDbData["ros_neuroPsychSubAuditoryCommentVal"];

  const ros_neuroPsychSubVisual = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubVisualVal"],
  );
  const ros_neuroPsychSubVisualComment =
    tempDbData["ros_neuroPsychSubVisualCommentVal"];

  const ros_neuroPsychSubEpisodesBehav = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubEpisodesBehavVal"],
  );
  const ros_neuroPsychSubEpisodesBehavComment =
    tempDbData["ros_neuroPsychSubEpisodesBehavCommentVal"];

  const ros_neuroPsychSubEpisodesDelirium = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubEpisodesDeliriumVal"],
  );
  const ros_neuroPsychSubEpisodesDeliriumComment =
    tempDbData["ros_neuroPsychSubEpisodesDeliriumCommentVal"];

  const ros_neuroPsychSubHearVoices = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubHearVoicesVal"],
  );
  const ros_neuroPsychSubHearVoicesComment =
    tempDbData["ros_neuroPsychSubHearVoicesCommentVal"];

  const ros_neuroPsychSubHighLows = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubHighLowsVal"],
  );
  const ros_neuroPsychSubHighLowsComment =
    tempDbData["ros_neuroPsychSubHighLowsCommentVal"];

  const ros_neuroPsychSubEverFeel = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubEverFeelVal"],
  );
  const ros_neuroPsychSubEverFeelComment =
    tempDbData["ros_neuroPsychSubEverFeelCommentVal"];

  const ros_neuroPsychSubDiagComment =
    tempDbData["ros_neuroPsychSubDiagCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubDescComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubDescCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubTypeCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal"];

  const ros_neuroPsychSubDiagAlcoholSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubSuppCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubDelirium = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagAlcoholSubDeliriumVal"],
  );
  const ros_neuroPsychSubDiagAlcoholSubDeliriumComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubPsych = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagAlcoholSubPsychVal"],
  );
  const ros_neuroPsychSubDiagAlcoholSubPsychComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubPsychCommentVal"];

  const ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal"];

  const ros_neuroPsychSubDiagAmyotropSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagAmyotropSubSuppCommentVal"];

  const ros_neuroPsychSubDiagAnxietySubDescComment =
    tempDbData["ros_neuroPsychSubDiagAnxietySubDescCommentVal"];
  const ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal"];
  const ros_neuroPsychSubDiagAnxietySubTypeComment =
    tempDbData["ros_neuroPsychSubDiagAnxietySubTypeCommentVal"];
  const ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagAnxietySubSuppComment =
    tempDbData["ros_neuroPsychSubDiagAnxietySubSuppCommentVal"];

  const ros_neuroPsychSubDiagBipolarSubDescComment =
    tempDbData["ros_neuroPsychSubDiagBipolarSubDescCommentVal"];
  const ros_neuroPsychSubDiagBipolarSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagBipolarSubTypeCommentVal"];
  const ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagBipolarSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagBipolarSubSuppCommentVal"];

  const ros_neuroPsychSubDiagCerebHemmSubDescComment =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubDescCommentVal"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal"];

  const ros_neuroPsychSubDiagDementiaSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubDescCommentVal"];
  const ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDementiaSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubSuppCommentVal"];
  const ros_neuroPsychSubDiagDementiaSubTypeVascComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubTypeVascCommentVal"];
  const ros_neuroPsychSubDiagDementiaSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubTypeCommentVal"];

  const ros_neuroPsychSubDiagDrugsSubHistoryPsych = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagDrugsSubHistoryPsychVal"],
  );
  const ros_neuroPsychSubDiagDrugsSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubDescCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubTypeCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDrugsSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubSuppCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubHistoryPsychComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubWhatDrugs =
    tempDbData["ros_neuroPsychSubDiagDrugsSubWhatDrugsVal"];

  const ros_neuroPsychSubDiagDepressSubMajor = getOptionsSelectedYesNoUnknown(
    tempDbData["ros_neuroPsychSubDiagDepressSubMajorVal"],
  );
  const ros_neuroPsychSubDiagDepressSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubDescCommentVal"];
  const ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDepressSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubSuppCommentVal"];
  const ros_neuroPsychSubDiagDepressSubMajorComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubMajorCommentVal"];
  const ros_neuroPsychSubDiagDepressSubEpisodeComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubEpisodeCommentVal"];

  const ros_neuroPsychSubDiagHemiSubDescComment =
    tempDbData["ros_neuroPsychSubDiagHemiSubDescCommentVal"];
  const ros_neuroPsychSubDiagHemiSubHemiDescComment =
    tempDbData["ros_neuroPsychSubDiagHemiSubHemiDescCommentVal"];
  const ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagHemiSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagHemiSubSuppCommentVal"];

  const ros_neuroPsychSubDiagIntelSubDescComment =
    tempDbData["ros_neuroPsychSubDiagIntelSubDescCommentVal"];
  const ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagIntelSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagIntelSubSuppCommentVal"];
  const ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal"];
  const ros_neuroPsychSubDiagIntelSubIntelDescComment =
    tempDbData["ros_neuroPsychSubDiagIntelSubIntelDescCommentVal"];

  const ros_neuroPsychSubDiagSeizureSubTreatment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubTreatmentVal"];
  const ros_neuroPsychSubDiagSeizureSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubDescCommentVal"];
  const ros_neuroPsychSubDiagSeizureSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubTypeCommentVal"];
  const ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSeizureSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSpinalCordSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubDescCommentVal"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal"];
  const ros_neuroPsychSubDiagSpinalCordSubSecToComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal"];

  const ros_neuroPsychSubDiagStrokeSubDescComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubDescCommentVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSubduralSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubDescCommentVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppCommentVal"];

  const ros_neuroPsychSubDiagTraumaBrainSubDescComment =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal"];

  const ros_neuroPsychSubDiagCerebPalsySubDescComment =
    tempDbData["ros_neuroPsychSubDiagCerebPalsySubDescCommentVal"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppComment =
    tempDbData["ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal"];

  const ros_neuroPsychSubDiagDelusionSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDelusionSubDescCommentVal"];
  const ros_neuroPsychSubDiagDelusionSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDelusionSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDelusionSubSuppCommentVal"];

  const ros_neuroPsychSubDiagFibroSubDescComment =
    tempDbData["ros_neuroPsychSubDiagFibroSubDescCommentVal"];
  const ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagFibroSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagFibroSubSuppCommentVal"];

  const ros_neuroPsychSubDiagGuillSubDescComment =
    tempDbData["ros_neuroPsychSubDiagGuillSubDescCommentVal"];
  const ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagGuillSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagGuillSubSuppCommentVal"];

  const ros_neuroPsychSubDiagHuntingSubDescComment =
    tempDbData["ros_neuroPsychSubDiagHuntingSubDescCommentVal"];
  const ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagHuntingSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagHuntingSubSuppCommentVal"];

  const ros_neuroPsychSubDiagInsomniaSubDescComment =
    tempDbData["ros_neuroPsychSubDiagInsomniaSubDescCommentVal"];
  const ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagInsomniaSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagInsomniaSubSuppCommentVal"];

  const ros_neuroPsychSubDiagMigraineSubDescComment =
    tempDbData["ros_neuroPsychSubDiagMigraineSubDescCommentVal"];
  const ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagMigraineSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagMigraineSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSclerosisSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSclerosisSubDescCommentVal"];
  const ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSclerosisSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSclerosisSubSuppCommentVal"];

  const ros_neuroPsychSubDiagDystrophySubDescComment =
    tempDbData["ros_neuroPsychSubDiagDystrophySubDescCommentVal"];
  const ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDystrophySubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDystrophySubSuppCommentVal"];

  const ros_neuroPsychSubDiagGravisSubDescComment =
    tempDbData["ros_neuroPsychSubDiagGravisSubDescCommentVal"];
  const ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagGravisSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagGravisSubSuppCommentVal"];

  const ros_neuroPsychSubDiagParkinsonSubDescComment =
    tempDbData["ros_neuroPsychSubDiagParkinsonSubDescCommentVal"];
  const ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagParkinsonSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagParkinsonSubSuppCommentVal"];

  const ros_neuroPsychSubDiagPeriNeuroSubDescComment =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal"];
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal"],
  );
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetesComment =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal"];

  const ros_neuroPsychSubDiagRestlessLegSubDescComment =
    tempDbData["ros_neuroPsychSubDiagRestlessLegSubDescCommentVal"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSchizoSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSchizoSubDescCommentVal"];
  const ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSchizoSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSchizoSubSuppCommentVal"];

  const ros_neuroPsychSubDiagTiaSubDescComment =
    tempDbData["ros_neuroPsychSubDiagTiaSubDescCommentVal"];
  const ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagTiaSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagTiaSubSuppCommentVal"];

  const ros_neuroPsychSubDiagOtherSubDescComment =
    tempDbData["ros_neuroPsychSubDiagOtherSubDescCommentVal"];
  const ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagOtherSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagOtherSubSuppCommentVal"];
  const ros_neuroPsychSubDiagOtherSubOther =
    tempDbData["ros_neuroPsychSubDiagOtherSubOtherVal"];

  ///GAIT CHECKBOX//
  const gait_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["gait_recoTakeMedicationsAsPescribedVal"],
      tempDbData["gait_recoOtherVal"],
    ],
    ["takeMedicationsAsPescribed", "other"],
  );

  const gait_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["gait_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const gait_RecommendationsotherComment =
    tempDbData["gait_RecommendationsotherCommentVal"];

  //--respi checkbox
  const respiratory_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["respiratory_recoTakeMedicationsAsPescribedVal"],
      tempDbData["respiratory_recoOtherVal"],
    ],
    ["takeMedicationsAsPescribed", "other"],
  );

  const respiratory_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData[
      "respiratory_RecommendationstakeMedicationsAsPescribedCommentVal"
    ];

  const respiratory_RecommendationsotherComment =
    tempDbData["respiratory_RecommendationsotherCommentVal"];

  ///INTEGUMENT CHECKBOX//
  const integument_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["integument_recoTakeMedicationsAsPescribedVal"],
      tempDbData["integument_recoOtherVal"],
    ],
    ["takeMedicationsAsPescribed", "other"],
  );

  const integument_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["gait_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const integument_RecommendationsotherComment =
    tempDbData["integument_RecommendationsotherCommentVal"];

  /////////MUSKO CHECKBOX//
  const musko_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["musko_recoDiscussPtOtVal"],
      tempDbData["musko_recoTakeMedicationsAsPescribedVal"],
      tempDbData["musko_recoOtherVal"],
    ],
    ["discussPtOt", "takeMedicationsAsPescribed", "other"],
  );
  const musko_RecommendationsdiscussPtOtComment =
    tempDbData["musko_RecommendationsdiscussPtOtCommentVal"];

  const musko_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["musko_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const musko_RecommendationsotherComment =
    tempDbData["musko_RecommendationsotherCommentVal"];

  ////////HEMA CHECKBOX//////
  const hema_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["hema_recoTakeMedicationsAsPescribedVal"],
      tempDbData["hema_recoReportAbnormalBruisingVal"],
      tempDbData["hema_recoFollowUpWithDoctorVal"],
      tempDbData["hema_recoOtherVal"],
    ],
    [
      "takeMedicationsAsPescribed",
      "reportAbnormalBruising",
      "followUpWithDoctor",
      "other",
    ],
  );
  const hema_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["musko_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const hema_RecommendationsreportAbnormalBruisingComment =
    tempDbData["hema_RecommendationsreportAbnormalBruisingCommentVal"];

  const hema_RecommendationsfollowUpWithDoctorComment =
    tempDbData["hema_RecommendationsfollowUpWithDoctorCommentVal"];

  const hema_RecommendationsotherComment =
    tempDbData["hema_RecommendationsotherCommentVal"];

  //console.log("tempDbData after ros_hashmap:", tempDbData);

  ros_OptionsTreeWrappers?.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  //console.log("tempData after ros_OptionsTreeWrappers:", tempData);

  // const ros_respiratoryDiagnoses = getObjectData(
  //   apiData,
  //   "respiratory.isRespiratorySubAnswer.diagnoses",
  // );
  // const ros_respiratoryDiagnosesKeys = Object.keys(ros_respiratoryDiagnoses).filter(
  //   (key) => ros_respiratoryDiagnoses[key] === "1",
  // );

  const ros_respiratory = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryVal"],
  );

  const ros_integument = getOptionsSelectedYesNo(
    tempDbData["ros_integumentVal"],
  );

  const ros_musculoskeletal = getOptionsSelectedYesNo(
    tempDbData["ros_musculoskeletalVal"],
  );

  const ros_hematology = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyVal"],
  );

  // const ros_integumentDiagnoses = getObjectData(
  //   apiData,
  //   "integument.isIntegumentSubAnswer.diagnoses",
  // );
  // const ros_integumentDiagnosesKeys = Object.keys(ros_integumentDiagnoses).filter(
  //   (key) => ros_integumentDiagnoses[key] === "1",
  // );

  // const ros_musculoskeletalDiagnoses = getObjectData(
  //   apiData,
  //   "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses",
  // );
  // const ros_musculoskeletalDiagnosesKeys = Object.keys(
  //   ros_musculoskeletalDiagnoses,
  // ).filter((key) => ros_musculoskeletalDiagnoses[key] === "1");

  // const ros_hematologyDiagnoses = getObjectData(
  //   apiData,
  //   "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses",
  // );
  // const ros_hematologyDiagnosesKeys = Object.keys(ros_hematologyDiagnoses).filter(
  //   (key) => ros_hematologyDiagnoses[key] === "1",
  // );

  // Access acutePulmonarySubAnswerDescribe from the correct path in apiData

  const ros_asthmaPatientController = getOptionsSelectedYesNo(
    tempDbData["ros_asthmaPatientControllerVal"],
  );
  const ros_asthmaRescueMedications = getOptionsSelectedYesNo(
    tempDbData["ros_asthmaRescueMedicationsVal"],
  );
  const ros_asthmaCurrentExacerbation = getOptionsSelectedYesNo(
    tempDbData["ros_asthmaCurrentExacerbationVal"],
  );

  const ros_patientChronicBronchitis = getOptionsSelectedYesNo(
    tempDbData["ros_patientChronicBronchitisVal"],
  );
  const ros_patientHaveEmphysema = getOptionsSelectedYesNo(
    tempDbData["ros_patientHaveEmphysemaVal"],
  );
  const ros_patientBronchodilator = getOptionsSelectedYesNo(
    tempDbData["ros_patientBronchodilatorVal"],
  );
  const ros_patientSteroids = getOptionsSelectedYesNo(
    tempDbData["ros_patientSteroidsVal"],
  );
  const ros_patientExacerbation = getOptionsSelectedYesNo(
    tempDbData["ros_patientExacerbationVal"],
  );

  const ros_historyOfLungAbscess = getOptionsSelectedYesNo(
    tempDbData["ros_historyOfLungAbscessVal"],
  );
  const ros_historyOfEmpyema = getOptionsSelectedYesNo(
    tempDbData["ros_historyOfEmpyemaVal"],
  );

  const ros_psoriasisSubAnswerPsoriaticArthritis = getOptionsSelectedYesNo(
    tempDbData["ros_psoriasisSubAnswerPsoriaticArthritisVal"],
  );

  const ros_degenerativeDiscDiseaseSubAnswerNormalBladder =
    getOptionsSelectedYesNo(
      tempDbData["ros_degenerativeDiscDiseaseSubAnswerNormalBladderVal"],
    );

  const ros_extremityFractureCurrentWithinTwelveWeeksSubAnswer =
    getOptionsSelectedYesNo(
      tempDbData["ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerVal"],
    );

  const ros_spinalStenosisSubAnswerNormalBladder = getOptionsSelectedYesNo(
    tempDbData["ros_spinalStenosisSubAnswerNormalBladderVal"],
  );

  const ros_muskoHadAnAmputation = getOptionsSelectedYesNo(
    tempDbData["ros_muskoHadAnAmputationVal"],
  );

  const ros_aidsSubAnswerPatientUnderTreatment = getOptionsSelectedYesNo(
    tempDbData["ros_aidsSubAnswerPatientUnderTreatmentVal"],
  );

  const ros_hivSubAnswerPatientSymptomatic = getOptionsSelectedYesNo(
    tempDbData["ros_hivSubAnswerPatientSymptomaticVal"],
  );

  const ros_hivSubAnswerActiveTreatment = getOptionsSelectedYesNo(
    tempDbData["ros_hivSubAnswerActiveTreatmentVal"],
  );

  const ros_immuneDeficiencySubAnswerHistoryOfOpportunistic =
    getOptionsSelectedYesNo(
      tempDbData["ros_immuneDeficiencySubAnswerHistoryOfOpportunisticVal"],
    );

  const ros_tuberculosisSubAnswerGivenBCG = getOptionsSelectedYesNo(
    tempDbData["ros_tuberculosisSubAnswerGivenBCGVal"],
  );

  const ros_tuberculosisSubAnswerActiveTuberculosis = getOptionsSelectedYesNo(
    tempDbData["ros_tuberculosisSubAnswerActiveTuberculosisVal"],
  );

  const ros_tuberculosisSubAnswerTbInfection = getOptionsSelectedYesNo(
    tempDbData["ros_tuberculosisSubAnswerTbInfectionVal"],
  );

  //GAIT COMMENT==================;
  const ros_gaitComment = tempDbData["ros_gaitComment"];

  //RESPIRATORY COMMENT---------------------------
  const ros_respiratoryComment = tempDbData["ros_respiratoryComment"];
  const ros_acutePulmonarySubAnswerDescribeComment =
    tempDbData["ros_acutePulmonarySubAnswerDescribeComment"];
  const ros_respiratoryDiagnosesComment =
    tempDbData["ros_respiratoryDiagnosesComment"];
  const ros_acutePulmonarySubAnswerSupportedByComment =
    tempDbData["ros_acutePulmonarySubAnswerSupportedByComment"];
  const ros_acuteUpperSubAnswerDescribeComment =
    tempDbData["ros_acuteUpperSubAnswerDescribeComment"];
  const ros_acuteUpperSubAnswerSupportedByComment =
    tempDbData["ros_acuteUpperSubAnswerSupportedByComment"];
  const ros_asthmaSubAnswerDescribeComment =
    tempDbData["ros_asthmaSubAnswerDescribeComment"];
  const ros_asthmaSubAnswerSupportedByComment =
    tempDbData["ros_asthmaSubAnswerSupportedByComment"];
  const ros_asthmaPatientControllerComment =
    tempDbData["ros_asthmaPatientControllerComment"];
  const ros_asthmaRescueMedicationsComment =
    tempDbData["ros_asthmaRescueMedicationsComment"];
  const ros_asthmaCurrentExacerbationComment =
    tempDbData["ros_asthmaCurrentExacerbationComment"];
  const ros_chronicPulmonaryEmbolismSubAnswerDescribeComment =
    tempDbData["ros_chronicPulmonaryEmbolismSubAnswerDescribeComment"];
  const ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment =
    tempDbData["ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment"];
  const ros_chronicRespiratoryFailureSubAnswerDescribeComment =
    tempDbData["ros_chronicRespiratoryFailureSubAnswerDescribeComment"];
  const ros_chronicRespiratoryFailureSubAnswerSupportedByComment =
    tempDbData["ros_chronicRespiratoryFailureSubAnswerSupportedByComment"];
  const ros_chronicSputumFailureSubAnswerDescribeComment =
    tempDbData["ros_chronicSputumFailureSubAnswerDescribeComment"];
  const ros_chronicSputumFailureSubAnswerSupportedByComment =
    tempDbData["ros_chronicSputumFailureSubAnswerSupportedByComment"];
  const ros_copdSubAnswerDescribeComment =
    tempDbData["ros_copdSubAnswerDescribeComment"];
  const ros_copdSubAnswerSupportedByComment =
    tempDbData["ros_copdSubAnswerSupportedByComment"];
  const ros_patientChronicBronchitisComment =
    tempDbData["ros_patientChronicBronchitisComment"];
  const ros_patientHaveEmphysemaComment =
    tempDbData["ros_patientHaveEmphysemaComment"];
  const ros_patientBronchodilatorComment =
    tempDbData["ros_patientBronchodilatorComment"];
  const ros_patientSteroidsComment = tempDbData["ros_patientSteroidsComment"];
  const ros_patientExacerbationComment =
    tempDbData["ros_patientExacerbationComment"];
  const ros_cysticFibrosisSubAnswerDescribeComment =
    tempDbData["ros_cysticFibrosisSubAnswerDescribeComment"];
  const ros_cysticFibrosisSubAnswerSupportedByComment =
    tempDbData["ros_cysticFibrosisSubAnswerSupportedByComment"];
  const ros_hypoventilationSubAnswerDescribeComment =
    tempDbData["ros_hypoventilationSubAnswerDescribeComment"];
  const ros_hypoventilationSubAnswerSupportedByComment =
    tempDbData["ros_hypoventilationSubAnswerSupportedByComment"];
  const ros_hypoxemiaSubAnswerDescribeComment =
    tempDbData["ros_hypoxemiaSubAnswerDescribeComment"];
  const ros_hypoxemiaSubAnswerSupportedByComment =
    tempDbData["ros_hypoxemiaSubAnswerSupportedByComment"];
  const ros_pneumoniaSubAnswerDescribeComment =
    tempDbData["ros_pneumoniaSubAnswerDescribeComment"];
  const ros_pneumoniaSubAnswerSupportedByComment =
    tempDbData["ros_pneumoniaSubAnswerSupportedByComment"];
  const ros_pneumoniaSubAnswerEtiologyComment =
    tempDbData["ros_pneumoniaSubAnswerEtiologyComment"];
  const ros_historyOfLungAbscessComment =
    tempDbData["ros_historyOfLungAbscessComment"];
  const ros_historyOfEmpyemaComment = tempDbData["ros_historyOfEmpyemaComment"];
  const ros_pulmonaryFibrosisSubAnswerDescribeComment =
    tempDbData["ros_pulmonaryFibrosisSubAnswerDescribeComment"];
  const ros_pulmonaryFibrosisSubAnswerSupportedByComment =
    tempDbData["ros_pulmonaryFibrosisSubAnswerSupportedByComment"];
  const ros_respiratorDependenceSubAnswerDescribeComment =
    tempDbData["ros_respiratorDependenceSubAnswerDescribeComment"];
  const ros_respiratorDependenceSubAnswerSupportedByComment =
    tempDbData["ros_respiratorDependenceSubAnswerSupportedByComment"];
  const ros_respiratoryArrestSubAnswerDescribeComment =
    tempDbData["ros_respiratoryArrestSubAnswerDescribeComment"];
  const ros_respiratoryArrestSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryArrestSubAnswerSupportedByComment"];
  const ros_sarcoidosisSubAnswerDescribeComment =
    tempDbData["ros_sarcoidosisSubAnswerDescribeComment"];
  const ros_sarcoidosisSubAnswerSupportedByComment =
    tempDbData["ros_sarcoidosisSubAnswerSupportedByComment"];
  const ros_sarcoidosisSubAnswerSpecifyComment =
    tempDbData["ros_sarcoidosisSubAnswerSpecifyComment"];
  const ros_sleepApneaSubAnswerDescribeComment =
    tempDbData["ros_sleepApneaSubAnswerDescribeComment"];
  const ros_sleepApneaSubAnswerSupportedByComment =
    tempDbData["ros_sleepApneaSubAnswerSupportedByComment"];
  const ros_otherSubAnswerDescribeComment =
    tempDbData["ros_otherSubAnswerDescribeComment"];
  const ros_otherSubAnswerSupportedByComment =
    tempDbData["ros_otherSubAnswerSupportedByComment"];
  const ros_otherSubAnswerOtherSubText =
    tempDbData["ros_otherSubAnswerOtherSubText"];

  const ros_integumentComment = tempDbData["ros_integumentComment"];
  const ros_integumentDiagnosesComment =
    tempDbData["ros_integumentDiagnosesComment"];
  const ros_basilCellCarcinomaSubAnswerDescribeComment =
    tempDbData["ros_basilCellCarcinomaSubAnswerDescribeComment"];
  const ros_basilCellCarcinomaSubAnswerSupportedByComment =
    tempDbData["ros_basilCellCarcinomaSubAnswerSupportedByComment"];
  const ros_dermatitisSubAnswerDescribeComment =
    tempDbData["ros_dermatitisSubAnswerDescribeComment"];
  const ros_dermatitisSubAnswerSupportedByComment =
    tempDbData["ros_dermatitisSubAnswerSupportedByComment"];
  const ros_dermatitisSubAnswerWhatTypeComment =
    tempDbData["ros_dermatitisSubAnswerWhatTypeComment"];
  const ros_eczemaSubAnswerDescribeComment =
    tempDbData["ros_eczemaSubAnswerDescribeComment"];
  const ros_eczemaSubAnswerSupportedByComment =
    tempDbData["ros_eczemaSubAnswerSupportedByComment"];
  const ros_onychomycosisSubAnswerDescribeComment =
    tempDbData["ros_onychomycosisSubAnswerDescribeComment"];
  const ros_onychomycosisSubAnswerSupportedByComment =
    tempDbData["ros_onychomycosisSubAnswerSupportedByComment"];
  const ros_psoriasisSubAnswerDescribeComment =
    tempDbData["ros_psoriasisSubAnswerDescribeComment"];
  const ros_psoriasisSubAnswerSupportedByComment =
    tempDbData["ros_psoriasisSubAnswerSupportedByComment"];
  const ros_psoriasisSubAnswerPsoriaticArthritisComment =
    tempDbData["ros_psoriasisSubAnswerPsoriaticArthritisComment"];
  const ros_skinUlcerSubAnswerDescribeComment =
    tempDbData["ros_skinUlcerSubAnswerDescribeComment"];
  const ros_skinUlcerSubAnswerSupportedByComment =
    tempDbData["ros_skinUlcerSubAnswerSupportedByComment"];
  const ros_skinUlcerSubAnswerEtiologyComment =
    tempDbData["ros_skinUlcerSubAnswerEtiologyComment"];
  const ros_tineaPedisSubAnswerDescribeComment =
    tempDbData["ros_tineaPedisSubAnswerDescribeComment"];
  const ros_tineaPedisSubAnswerSupportedByComment =
    tempDbData["ros_tineaPedisSubAnswerSupportedByComment"];
  const ros_urticarialDiseaseSubAnswerDescribeComment =
    tempDbData["ros_urticarialDiseaseSubAnswerDescribeComment"];
  const ros_urticarialDiseaseSubAnswerSupportedByComment =
    tempDbData["ros_urticarialDiseaseSubAnswerSupportedByComment"];
  const ros_urticarialDiseaseSubAnswerEtiologySubText =
    tempDbData["ros_urticarialDiseaseSubAnswerEtiologySubText"];
  const ros_urticarialDiseaseSubAnswerTypeComment =
    tempDbData["ros_urticarialDiseaseSubAnswerTypeComment"];
  const ros_woundSubAnswerDescribeComment =
    tempDbData["ros_woundSubAnswerDescribeComment"];
  const ros_woundSubAnswerSupportedByComment =
    tempDbData["ros_woundSubAnswerSupportedByComment"];
  const ros_woundSubAnswerEtiologyComment =
    tempDbData["ros_woundSubAnswerEtiologyComment"];
  const ros_othersSubAnswerDescribeComment =
    tempDbData["ros_othersSubAnswerDescribeComment"];
  const ros_othersSubAnswerSupportedByComment =
    tempDbData["ros_othersSubAnswerSupportedByComment"];
  const ros_integumentOthersSubAnswerOtherSubText =
    tempDbData["ros_integumentOthersSubAnswerOtherSubText"];

  const ros_musculoskeletalComment = tempDbData["ros_musculoskeletalComment"];
  const ros_musculoskeletalDiagnosesComment =
    tempDbData["ros_musculoskeletalDiagnosesComment"];
  const ros_collagenSubAnswerDescribeComment =
    tempDbData["ros_collagenSubAnswerDescribeComment"];
  const ros_collagenSubAnswerSupportedByComment =
    tempDbData["ros_collagenSubAnswerSupportedByComment"];
  const ros_collagenSubAnswercollagenDescribeComment =
    tempDbData["ros_collagenSubAnswercollagenDescribeComment"];
  const ros_degenerativeDiscDiseaseSubAnswerDescribeComment =
    tempDbData["ros_degenerativeDiscDiseaseSubAnswerDescribeComment"];
  const ros_degenerativeDiscDiseaseSubAnswerSupportedByComment =
    tempDbData["ros_degenerativeDiscDiseaseSubAnswerSupportedByComment"];
  const ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment =
    tempDbData["ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment"];
  const ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
    tempDbData["ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment"];
  const ros_extremityFractureSubAnswerDescribeComment =
    tempDbData["ros_extremityFractureSubAnswerDescribeComment"];
  const ros_extremityFractureSubAnswerSupportedByComment =
    tempDbData["ros_extremityFractureSubAnswerSupportedByComment"];
  const ros_extremityFractureSubAnswerExtremityComment =
    tempDbData["ros_extremityFractureSubAnswerExtremityComment"];
  const ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
    tempDbData["ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment"];
  const ros_goutSubAnswerDescribeComment =
    tempDbData["ros_goutSubAnswerDescribeComment"];
  const ros_goutSubAnswerSupportedByComment =
    tempDbData["ros_goutSubAnswerSupportedByComment"];
  const ros_halluxValgusSubAnswerDescribeComment =
    tempDbData["ros_halluxValgusSubAnswerDescribeComment"];
  const ros_halluxValgusSubAnswerSupportedByComment =
    tempDbData["ros_halluxValgusSubAnswerSupportedByComment"];
  const ros_hammerToesSubAnswerDescribeComment =
    tempDbData["ros_hammerToesSubAnswerDescribeComment"];
  const ros_hammerToesSubAnswerSupportedByComment =
    tempDbData["ros_hammerToesSubAnswerSupportedByComment"];
  const ros_osteoarthritisSubAnswerDescribeComment =
    tempDbData["ros_osteoarthritisSubAnswerDescribeComment"];
  const ros_osteoarthritisSubAnswerSupportedByComment =
    tempDbData["ros_osteoarthritisSubAnswerSupportedByComment"];
  const ros_osteoarthritisSubAnswerWhichJointsSubText =
    tempDbData["ros_osteoarthritisSubAnswerWhichJointsSubText"];
  const ros_osteomyelitisSubAnswerDescribeComment =
    tempDbData["ros_osteomyelitisSubAnswerDescribeComment"];
  const ros_osteomyelitisSubAnswerSupportedByComment =
    tempDbData["ros_osteomyelitisSubAnswerSupportedByComment"];
  const ros_osteoporosisSubAnswerDescribeComment =
    tempDbData["ros_osteoporosisSubAnswerDescribeComment"];
  const ros_osteoporosisSubAnswerSupportedByComment =
    tempDbData["ros_osteoporosisSubAnswerSupportedByComment"];
  const ros_pyogenicArthritisSubAnswerDescribeComment =
    tempDbData["ros_pyogenicArthritisSubAnswerDescribeComment"];
  const ros_pyogenicArthritisSubAnswerSupportedByComment =
    tempDbData["ros_pyogenicArthritisSubAnswerSupportedByComment"];
  const ros_pyogenicArthritisSubAnswerWhichJointsSubText =
    tempDbData["ros_pyogenicArthritisSubAnswerWhichJointsSubText"];
  const ros_rheumatoidArthritisSubAnswerDescribeComment =
    tempDbData["ros_rheumatoidArthritisSubAnswerDescribeComment"];
  const ros_rheumatoidArthritisSubAnswerWhichJointsSubText =
    tempDbData["ros_rheumatoidArthritisSubAnswerWhichJointsSubText"];
  const ros_rheumatoidArthritisSubAnswerSupportedByComment =
    tempDbData["ros_rheumatoidArthritisSubAnswerSupportedByComment"];
  const ros_spinalStenosisSubAnswerDescribeComment =
    tempDbData["ros_spinalStenosisSubAnswerDescribeComment"];
  const ros_spinalStenosisSubAnswerSupportedByComment =
    tempDbData["ros_spinalStenosisSubAnswerSupportedByComment"];
  const ros_spinalStenosisSubAnswerNormalBladderComment =
    tempDbData["ros_spinalStenosisSubAnswerNormalBladderComment"];
  const ros_systemicLupusSubAnswerDescribeComment =
    tempDbData["ros_systemicLupusSubAnswerDescribeComment"];
  const ros_systemicLupusSubAnswerSupportedByComment =
    tempDbData["ros_systemicLupusSubAnswerSupportedByComment"];
  const ros_muskoOtherSubAnswerDescribeComment =
    tempDbData["ros_muskoOtherSubAnswerDescribeComment"];
  const ros_muskoOtherSubAnswerSupportedByComment =
    tempDbData["ros_muskoOtherSubAnswerSupportedByComment"];
  const ros_muskoOtherSubAnswerotherSubText =
    tempDbData["ros_muskoOtherSubAnswerotherSubText"];
  const ros_hadAnAmputationSubAnswerSubText =
    tempDbData["ros_hadAnAmputationSubAnswerSubText"];

  const ros_hematologyComment = tempDbData["ros_hematologyComment"];
  const ros_hematologyDiagnosesComment =
    tempDbData["ros_hematologyDiagnosesComment"];
  const ros_aidsSubAnswerDescribeComment =
    tempDbData["ros_aidsSubAnswerDescribeComment"];
  const ros_aidsSubAnswerSupportedByComment =
    tempDbData["ros_aidsSubAnswerSupportedByComment"];
  const ros_aidsSubAnswerPatientUnderTreatmentComment =
    tempDbData["ros_aidsSubAnswerPatientUnderTreatmentComment"];
  const ros_anemiaSubAnswerDescribeComment =
    tempDbData["ros_anemiaSubAnswerDescribeComment"];
  const ros_anemiaSubAnswerSupportedByComment =
    tempDbData["ros_anemiaSubAnswerSupportedByComment"];
  const ros_anemiaSubAnswerEtiologyComment =
    tempDbData["ros_anemiaSubAnswerEtiologyComment"];
  const ros_anemiaSubAnswerYesPatientOnComment =
    tempDbData["ros_anemiaSubAnswerYesPatientOnComment"];
  const ros_cDifficileSubAnswerDescribeComment =
    tempDbData["ros_cDifficileSubAnswerDescribeComment"];
  const ros_cDifficileSubAnswerSupportedByComment =
    tempDbData["ros_cDifficileSubAnswerSupportedByComment"];
  const ros_communityAcquiredSubAnswerDescribeComment =
    tempDbData["ros_communityAcquiredSubAnswerDescribeComment"];
  const ros_communityAcquiredSubAnswerSupportedByComment =
    tempDbData["ros_communityAcquiredSubAnswerSupportedByComment"];
  const ros_hivSubAnswerDescribeComment =
    tempDbData["ros_hivSubAnswerDescribeComment"];
  const ros_hivSubAnswerViralLoadSubText =
    tempDbData["ros_hivSubAnswerViralLoadSubText"];
  const ros_hivSubAnswerCFourSubText =
    tempDbData["ros_hivSubAnswerCFourSubText"];
  const ros_hivSubAnswerSupportedByComment =
    tempDbData["ros_hivSubAnswerSupportedByComment"];
  const ros_hivSubAnswerPatientSymptomaticComment =
    tempDbData["ros_hivSubAnswerPatientSymptomaticComment"];
  const ros_hivSubAnswerActiveTreatmentComment =
    tempDbData["ros_hivSubAnswerActiveTreatmentComment"];
  const ros_herpesZosterSubAnswerDescribeComment =
    tempDbData["ros_herpesZosterSubAnswerDescribeComment"];
  const ros_herpesZosterSubAnswerSupportedByComment =
    tempDbData["ros_herpesZosterSubAnswerSupportedByComment"];
  const ros_mrsaInfectionSubAnswerDescribeComment =
    tempDbData["ros_mrsaInfectionSubAnswerDescribeComment"];
  const ros_mrsaInfectionSubAnswerSupportedByComment =
    tempDbData["ros_mrsaInfectionSubAnswerSupportedByComment"];
  const ros_immuneDeficiencySubAnswerDescribeComment =
    tempDbData["ros_immuneDeficiencySubAnswerDescribeComment"];
  const ros_immuneDeficiencySubAnswerEtiologySubText =
    tempDbData["ros_immuneDeficiencySubAnswerEtiologySubText"];
  const ros_immuneDeficiencySubAnswerSupportedByComment =
    tempDbData["ros_immuneDeficiencySubAnswerSupportedByComment"];
  const ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment =
    tempDbData["ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment"];
  const ros_sepsisSubAnswerDescribeComment =
    tempDbData["ros_sepsisSubAnswerDescribeComment"];
  const ros_sepsisSubAnswerSupportedByComment =
    tempDbData["ros_sepsisSubAnswerSupportedByComment"];
  const ros_sickleCellDiseaseTwoSubAnswerDescribeComment =
    tempDbData["ros_sickleCellDiseaseTwoSubAnswerDescribeComment"];
  const ros_sickleCellDiseaseTwoSubAnswerSupportedByComment =
    tempDbData["ros_sickleCellDiseaseTwoSubAnswerSupportedByComment"];
  const ros_sickleCellTraitSubAnswerDescribeComment =
    tempDbData["ros_sickleCellTraitSubAnswerDescribeComment"];
  const ros_sickleCellTraitSubAnswerSupportedByComment =
    tempDbData["ros_sickleCellTraitSubAnswerSupportedByComment"];
  const ros_thalassemiaSubAnswerDescribeComment =
    tempDbData["ros_thalassemiaSubAnswerDescribeComment"];
  const ros_thalassemiaSubAnswerSupportedByComment =
    tempDbData["ros_thalassemiaSubAnswerSupportedByComment"];
  const ros_thrombocytopeniaSubAnswerDescribeComment =
    tempDbData["ros_thrombocytopeniaSubAnswerDescribeComment"];
  const ros_thrombocytopeniaSubAnswerEtiologySubText =
    tempDbData["ros_thrombocytopeniaSubAnswerEtiologySubText"];
  const ros_thrombocytopeniaSubAnswerSupportedByComment =
    tempDbData["ros_thrombocytopeniaSubAnswerSupportedByComment"];
  const ros_tuberculosisSubAnswerDescribeComment =
    tempDbData["ros_tuberculosisSubAnswerDescribeComment"];
  const ros_tuberculosisSubAnswerSupportedByComment =
    tempDbData["ros_tuberculosisSubAnswerSupportedByComment"];
  const ros_tuberculosisSubAnswerGivenBCGComment =
    tempDbData["ros_tuberculosisSubAnswerGivenBCGComment"];
  const ros_tuberculosisSubAnswerActiveTuberculosisComment =
    tempDbData["ros_tuberculosisSubAnswerActiveTuberculosisComment"];
  const ros_tuberculosisSubAnswerTbInfectionComment =
    tempDbData["ros_tuberculosisSubAnswerTbInfectionComment"];
  const ros_vitaminDDeficiencySubAnswerSupportedByComment =
    tempDbData["ros_vitaminDDeficiencySubAnswerSupportedByComment"];
  const ros_hemaOtherSubAnswerDescribeComment =
    tempDbData["ros_hemaOtherSubAnswerDescribeComment"];
  const ros_hemaOtherSubAnswerSupportedByComment =
    tempDbData["ros_hemaOtherSubAnswerSupportedByComment"];
  const ros_hemaOtherSubAnswerOtherSubText =
    tempDbData["ros_hemaOtherSubAnswerOtherSubText"];

  const ros_diagnosisOfCancer = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerVal"],
  );

  const ros_diagnosisOfCancerSubMetastasisCachexiaVal = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubMetastasisCachexiaVal"],
  );

  const ros_diagnosisOfCancerComment =
    tempDbData["ros_diagnosisOfCancerCommentVal"];

  const ros_cancerRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedCancerVal"],
      tempDbData["ros_otherCancerVal"],
    ],
    ["takemedicationsasprescribedCancer", "otherCancer"],
  );

  const ros_genitoRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takeMedicationsAsPescribedGenitoVal"],
      tempDbData["ros_otherGenitoVal"],
    ],
    ["takeMedicationsAsPrescribedGenito", "otherGenito"],
  );

  const ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment =
    tempDbData["genito_RecommendationstakeMedicationsAsPescribedCommentVal"];
  const ros_genitoRecommendationsotherGenitoComment =
    tempDbData["genito_RecommendationsotherCommentVal"];

  const ros_genitoSubDiagnosesComment =
    tempDbData["ros_genitoSubDiagnosesComment"];

  const ros_genitoSubDiagnosesacuteRenalFailureEti =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureEti"];

  const gastro_recommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takeMedicationsAsPescribedGastroVal"],
      tempDbData["ros_otherGastroVal"],
    ],
    ["takemedicationsasprescribed", "other"],
  );

  const gastro_recommendationstakemedicationsasprescribedComment =
    tempDbData["gastro_RecommendationstakeMedicationsAsPescribedCommentVal"];
  const gastro_recommendationsotherComment =
    tempDbData["gastro_otherCommentVal"];

  const ros_gastroSubDiagnosesComment =
    tempDbData["ros_gastroSubDiagnosesComment"];

  const endocrine_recommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takeMedicationsAsPescribedEndocrineVal"],
      tempDbData["ros_checkBloodSugarEndorineVal"],
      tempDbData["ros_otherEndocrineVal"],
    ],
    ["takeMedicationsAsPescribed", "checkBloodSugar", "other"],
  );
  const ros_endoSubDiagnosesComment =
    tempDbData["ros_gastroSubDiagnosesComment"];

  const endocrine_recommendationstakeMedicationsAsPescribedComment =
    tempDbData["endocrine_RecommendationstakeMedicationsAsPescribedCommentVal"];
  const endocrine_recommendationscheckBloodSugarComment =
    tempDbData["endocrine_RecommendationsCheckBloodSugarCommentVal"];

  const endocrine_recommendationsotherComment =
    tempDbData["endocrine_RecommendationsotherCommentVal"];

  const ros_cancerRecommendationstakemedicationsasprescribedCancerComment =
    tempDbData["ros_takemedicationsasprescribedCancerCommentVal"];

  const ros_cancerRecommendationsotherCancerComment =
    tempDbData["ros_otherCancerCommentVal"];

  const specialTypes = tempDbData["ros_diagnosisOfCancerSubSpecTyVal"];

  const stageSpecificToTheCancer =
    tempDbData["ros_diagnosisOfCancerSubStageSpecCanVal"];

  const ros_activeTreatment = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubActiveTreatVal"],
  );

  const ros_Metastasis = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubMetastasisVal"],
  );

  const ros_SeeASpec = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubSeeASpecVal"],
  );
  const ros_diagnosisOfCancerSubSeeASpecProVal =
    tempDbData["ros_diagnosisOfCancerSubSeeASpecProVal"];

  const ros_diagnosisOfCancerSubDescComment =
    tempDbData["ros_diagnosisOfCancerSubDescComment"];

  const ros_diagnosisOfCancerSubSupComment =
    tempDbData["ros_diagnosisOfCancerSubSupComment"];

  const ros_diagnosisOfCancerSubTypeComment =
    tempDbData["ros_diagnosisOfCancerSubTypeComment"];

  const ros_diagnosisOfCancerSubSideEffComment =
    tempDbData["ros_diagnosisOfCancerSubSideEffComment"];
  const ros_diagnosisOfCancerSubActiveTreatComment =
    tempDbData["ros_diagnosisOfCancerSubActiveTreatComment"];

  const ros_SeeASpecComment = tempDbData["ros_SeeASpecComment"];

  const ros_diagnosisOfCancerSubMetastasisCachexiaValComment =
    tempDbData["ros_diagnosisOfCancerSubMetastasisCachexiaValComment"];
  const ros_MetastasisComment = tempDbData["ros_MetastasisComment"];

  const ros_activeTreatmentComment = tempDbData["ros_activeTreatmentComment"];

  const ros_diagnosisOfCancerSubMetastasisLocVal =
    tempDbData["ros_diagnosisOfCancerSubMetastasisLocVal"];

  const ros_cardio = getOptionsSelectedYesNo(tempDbData["ros_cardioVal"]);

  const ros_cardioComment = tempDbData["ros_cardioComment"];

  const ros_cardiovascularRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedCardioVal"],
      tempDbData["ros_otherCardioVal"],
      tempDbData["ros_ThirtyCardioVal"],
      tempDbData["ros_bloodPreCardioVal"],
      tempDbData["ros_heartHeCardioVal"],
    ],
    [
      "takeMedicationsAsPescribed",
      "other",
      "exerciseThirtyMinADay",
      "bloodPressureChecks",
      "heartHealthyDiet",
    ],
  );

  const cardio_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["cardio_RecommendationstakeMedicationsAsPescribedCommentVal"];
  const cardio_RecommendationsotherComment =
    tempDbData["cardio_RecommendationsotherCommentVal"];
  const cardio_RecommendationsexerciseThirtyMinADayComment =
    tempDbData["cardio_RecommendationsexerciseThirtyMinADayCommentVal"];
  const cardio_RecommendationsbloodPressureChecksComment =
    tempDbData["cardio_RecommendationsbloodPressureChecksCommentVal"];
  const cardio_RecommendationsheartHealthyDietComment =
    tempDbData["cardio_RecommendationsheartHealthyDietCommentVal"];

  // const ros_cardioSubDiagnoses =tempDbData["ros_cardioSubDiagnoses"]
  const ros_cardioSubDiagnosesComment =
    tempDbData["ros_cardioSubDiagnosesComment"];

  const ros_cardioSubDiagnosesabnormalCardiacRhythmDesComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmDesComment"];

  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment"];

  const ros_cardioSubDiagnosesAneurysmCrDesComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmCrDesComment"];
  const ros_cardioSubDiagnosesAneurysmSupComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmSupComment"];

  const ros_cardioSubDiagnosesAnginaCrDesComment =
    tempDbData["ros_cardioSubDiagnosesAnginaCrDesComment"];
  const ros_cardioSubDiagnosesAnginaSupComment =
    tempDbData["ros_cardioSubDiagnosesAnginaSupComment"];
  const ros_cardioSubDiagnosesAnginaDesComment =
    tempDbData["ros_cardioSubDiagnosesAnginaDesComment"];

  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest"],
    );
  const ros_endoVal = getOptionsSelectedYesNo(tempDbData["ros_endoVal"]);
  const ros_gastroVal = getOptionsSelectedYesNo(tempDbData["ros_gastroVal"]);
  const ros_genitoVal = getOptionsSelectedYesNo(tempDbData["ros_genitoVal"]);

  const ros_endoValComment = tempDbData["ros_endoValComment"];
  const ros_gastroValComment = tempDbData["ros_gastroValComment"];
  const ros_genitoValComment = tempDbData["ros_genitoValComment"];

  const ros_genitoSubDiagnosesacuteRenalFailuredesComment =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailuredesComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupComment =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureSupComment"];

  const ros_gastroSubDiagnosesbowelObstructionSupComment =
    tempDbData["ros_gastroSubDiagnosesbowelObstructionSupComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionComment =
    tempDbData["ros_gastroSubDiagnosesdesbowelObstructionComment"];

  const ros_gastroSubDiagnosescachexiadesComment =
    tempDbData["ros_gastroSubDiagnosescachexiadesComment"];

  const ros_gastroSubDiagnosescachexiaSupComment =
    tempDbData["ros_gastroSubDiagnosescachexiaSupComment"];

  const ros_gastroSubDiagnosesceliacDiseaseGlueten = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseGlueten"],
  );

  const ros_cardioSubDiagnosesAneurysmDesComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmDesComment"];

  const ros_gastroSubDiagnosesceliacDiseaseGluetenComment =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseGluetenComment"];
  const ros_gastroSubDiagnosesceliacDiseaseSupComment =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseComment =
    tempDbData["ros_gastroSubDiagnosesdesceliacDiseaseComment"];
  const ros_gastroSubDiagnosescirrhosisEndStage = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStage"],
  );
  const ros_gastroSubDiagnosescirrhosisEndStageComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStageComment"];

  const ros_gastroSubDiagnosescirrhosisSupComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisSupComment"];
  const ros_gastroSubDiagnosesdescirrhosisComment =
    tempDbData["ros_gastroSubDiagnosesdescirrhosisComment"];
  const ros_gastroSubDiagnosescolonCrDesComment =
    tempDbData["ros_gastroSubDiagnosescolonCrDesComment"];
  const ros_gastroSubDiagnosescolonPolypsDesComment =
    tempDbData["ros_gastroSubDiagnosescolonPolypsDesComment"];
  const ros_gastroSubDiagnosescolonSupComment =
    tempDbData["ros_gastroSubDiagnosescolonSupComment"];

  const ros_gastroSubDiagnosesdiverticulitisabscessVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitisabscessVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisabscessCommentVal =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisabscessCommentVal"];

  const ros_gastroSubDiagnosesdiverticulitisperforationVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitisperforationVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisperforationCommentVal =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisperforationCommentVal"];

  const ros_gastroSubDiagnosesdiverticulitishighFiberDietVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitishighFiberDietVal"],
    );
  const ros_gastroSubDiagnosesdiverticulitishighFiberDietCommentVal =
    tempDbData["ros_gastroSubDiagnosesdiverticulitishighFiberDietCommentVal"];

  const ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationSupComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationTypeComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationTypeComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationDesComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationDesComment"];

  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension"
      ],
    );

  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment"
    ];

  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE"],
    );

  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment"
    ];

  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker"
      ],
    );

  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment"
    ];

  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistent =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistent"],
    );

  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment"];

  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin"],
    );

  const ros_cardioSubDiagnosesHypertensionadequatelyControlled =
    getOptionsSelectedYesNoPartially(
      tempDbData["ros_cardioSubDiagnosesHypertensionadequatelyControlled"],
    );

  tempDbData = {
    ...tempData,
    ros_eyes,
    ros_eyesComment,
    ros_eyesDiagnosesComment,
    ros_eyesDiagCataractSubWhEyeComment,
    ros_eyesDiagCataractSubDescComment,
    ros_eyesDiagCataractSubSuppComment,
    ros_eyesDiagCataractSubSuppSympSubComment,
    ros_eyesDiagCataractSubSuppOtherSubDesc,
    ros_eyesDiagCataractSubDiabetes,
    ros_eyesDiagCataractSubDiabetesComment,
    ros_eyesDiagGlaucomaSubWhEyeComment,
    ros_eyesDiagGlaucomaSubDescComment,
    ros_eyesDiagGlaucomaSubSuppComment,
    ros_eyesDiagGlaucomaSubSuppSympSubComment,
    ros_eyesDiagGlaucomaSubSuppOtherSubDesc,
    ros_eyesDiagGlaucomaSubDiabetes,
    ros_eyesDiagGlaucomaSubDiabetesComment,
    ros_eyesDiagGlaucomaSubDiabetesSubComment,
    ros_eyesDiagHyperopiaSubWhEyeComment,
    ros_eyesDiagHyperopiaSubDescComment,
    ros_eyesDiagHyperopiaSubSuppComment,
    ros_eyesDiagHyperopiaSubSuppSympSubComment,
    ros_eyesDiagHyperopiaSubSuppOtherSubDesc,
    ros_eyesDiagHyperopiaSubDesc,
    ros_eyesDiagLegalBlindSubWhEyeComment,
    ros_eyesDiagLegalBlindSubDescComment,
    ros_eyesDiagLegalBlindSubSuppComment,
    ros_eyesDiagLegalBlindSubSuppSympSubComment,
    ros_eyesDiagLegalBlindSubSuppOtherSubDesc,
    ros_eyesDiagLegalBlindSubComment,
    ros_eyesDiagLegalBlindSubDesc,
    ros_eyesDiagMascDegenSubWhEyeComment,
    ros_eyesDiagMascDegenSubDescComment,
    ros_eyesDiagMascDegenSubSuppComment,
    ros_eyesDiagMascDegenSubSuppSympSubComment,
    ros_eyesDiagMascDegenSubSuppOtherSubDesc,
    ros_eyesDiagMascDegenSubComment,
    ros_eyesDiagMascDegenSubDesc,
    ros_eyesDiagMyopiaSubWhEyeComment,
    ros_eyesDiagMyopiaSubDescComment,
    ros_eyesDiagMyopiaSubSuppComment,
    ros_eyesDiagMyopiaSubSuppSympSubComment,
    ros_eyesDiagMyopiaSubSuppOtherSubDesc,
    ros_eyesDiagMyopiaSubDesc,
    ros_eyesDiagRetinalSubWhEyeComment,
    ros_eyesDiagRetinalSubDescComment,
    ros_eyesDiagRetinalSubSuppComment,
    ros_eyesDiagRetinalSubSuppSympSubComment,
    ros_eyesDiagRetinalSubSuppOtherSubDesc,
    ros_eyesDiagRetinalSubDiabetes,
    ros_eyesDiagRetinalSubDiabetesSubIsVitHemm,
    ros_eyesDiagRetinalSubDiabetesSubComment,
    ros_eyesDiagRetinalSubDiabetesComment,
    ros_eyesDiagOtherSubWhEyeComment,
    ros_eyesDiagOtherSubDescComment,
    ros_eyesDiagOtherSubSuppComment,
    ros_eyesDiagOtherSubSuppSympSubComment,
    ros_eyesDiagOtherSubSuppOtherSubDesc,
    ros_eyesDiagOtherSubOther,
    ros_ears,
    ros_earsComment,
    ros_earsDiagnosesComment,
    ros_earsDiagDiffHearSubSuppOtherSubDesc,
    ros_earsDiagDiffHearSubSuppComment,
    ros_earsDiagLegalDeafSubSuppOtherSubDesc,
    ros_earsDiagLegalDeafSubSuppComment,
    ros_earsDiagTinnitusSubSuppOtherSubDesc,
    ros_earsDiagTinnitusSubSuppComment,
    ros_earsDiagVertigoSubSuppOtherSubDesc,
    ros_earsDiagVertigoSubSuppComment,
    ros_earsDiagVertigoSubLoseBal,
    ros_earsDiagVertigoSubLoseBalComment,
    ros_earsDiagOtherSubSuppOtherSubDesc,
    ros_earsDiagOtherSubSuppComment,
    ros_earsDiagOtherSubOther,
    ros_nose,
    ros_noseDiagComment,
    ros_noseComment,
    ros_noseDiagAllergySubSuppOtherSubDesc,
    ros_noseDiagAllergySubSuppComment,
    ros_noseDiagChronicNasalSubSuppOtherSubDesc,
    ros_noseDiagChronicNasalSubSuppComment,
    ros_noseDiagNoseBleedSubComment,
    ros_noseDiagSeasonAllergySubSuppOtherSubDesc,
    ros_noseDiagSeasonAllergySubSuppComment,
    ros_noseDiagSinusInfectSubSuppOtherSubDesc,
    ros_noseDiagSinusInfectSubSuppComment,
    ros_noseDiagSinusInfectSubExuComment,
    ros_noseDiagSinustisSubSuppOtherSubDesc,
    ros_noseDiagSinustisSubSuppComment,
    ros_noseDiagOtherSubSuppOtherSubDesc,
    ros_noseDiagOtherSubSuppComment,
    ros_noseDiagOtherSubOther,
    ros_mouth,
    ros_mouthDiagComment,
    ros_mouthComment,
    ros_mouthDiagBleedGumsSubComment,
    ros_mouthDiagDiffChewSubDescComment,
    ros_mouthDiagDiffChewSubBcsPain,
    ros_mouthDiagDiffChewSubBcsPainSub,
    ros_mouthDiagDiffChewSubBcsPainSubComment,
    ros_mouthDiagDiffChewSubBcsPainComment,
    ros_mouthDiagDiffSwallowSubHadStroke,
    ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub,
    ros_mouthDiagDiffSwallowSubHadStrokeSubComment,
    ros_mouthDiagDiffSwallowSubHadStrokeComment,
    ros_mouthDiagOtherSubSuppOtherSubDesc,
    ros_mouthDiagOtherSubSuppComment,
    ros_mouthDiagOtherSubOther,
    ros_neck,
    ros_neckDiagComment,
    ros_neckComment,
    ros_neckDiagCarotidSubDescComment,
    ros_neckDiagCarotidSubSuppOtherSubDesc,
    ros_neckDiagCarotidSubSuppComment,
    ros_neckDiagCarotidCsDescComment,
    ros_neckDiagParotidSubSuppOtherSubDesc,
    ros_neckDiagParotidSubSuppComment,
    ros_neckDiagParotidSubDescComment,
    ros_neckDiagOtherSubDescComment,
    ros_neckDiagOtherSubSuppOtherSubDesc,
    ros_neckDiagOtherSubSuppComment,
    ros_neckDiagOtherSubOther,
    ros_eyeENTNeckRecommendations,
    ros_eyeENTRecoHearEvalComment,
    ros_eyeENTRecoDentalExamComment,
    ros_eyeENTRecoEyeExamComment,
    ros_eyeENTRecoSwalEvalyComment,
    ros_eyeENTRecoTakeMedComment,
    ros_eyeENTRecoFoodDispComment,
    ros_eyeENTRecoOtherComment,
    ros_bowelMoveAbnormDiarrIshis,
    ros_bowelMoveAbnormDiarrIshisComment,
    ros_bowelMoveComment,
    ros_bowelMoveAbnormComment,
    ros_bowelMoveAbnormConstComment,
    ros_bowelMoveAbnormDiarrIsdiaComment,
    ros_abdominalIsabd,
    ros_abdominalIsyesComment,
    ros_abdominalComment,
    ros_rectalIsrec,
    ros_rectalIsrecIsyesmalComment,
    ros_rectalIsrecIsyesfemComment,
    ros_rectalComment,
    ros_lastBowelComment,
    ros_meetWithComment,
    ros_gpcogScoIsgpcogs,
    ros_gpcogScoIsmmsesc,
    ros_gpcogScoComment,
    ros_noGpcogMmsePatientOrientPerson,
    ros_noGpcogMmsePatientOrientPlace,
    ros_noGpcogMmsePatientOrientTime,
    ros_noGpcogMmsePatientDescribeNews,
    ros_noGpcogMmseRecallComment,
    ros_noGpcogMmsePatientDescribeNewsComment,
    ros_noGpcogMmsePatientOrientPersonComment,
    ros_noGpcogMmsePatientOrientPlaceComment,
    ros_noGpcogMmsePatientOrientTimeComment,
    ros_speechComment,
    ros_fingerToNoseAbnormalSubComment,
    ros_fingerToNoseComment,
    ros_heelToToeAbnormalSubComment,
    ros_heelToToeComment,
    ros_thumbToFingerAbnormalSubComment,
    ros_thumbToFingerComment,
    ros_sitToStandComment,
    ros_extremeMoveComment,
    ros_affectComment,
    ros_affectSubComment,
    ros_over2WeeksLittleIntrstComment,
    ros_over2WeeksFeelDepressedComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment,
    ros_neuroPsych,
    ros_neuroPsychComment,
    ros_neuroPsychNerv,
    ros_neuroPsychNervComment,
    ros_neuroPsychWorry,
    ros_neuroPsychWorryComment,
    ros_neuroPsychAfraid,
    ros_neuroPsychAfraidComment,
    ros_neuroPsychSubAuditory,
    ros_neuroPsychSubAuditoryComment,
    ros_neuroPsychSubVisual,
    ros_neuroPsychSubVisualComment,
    ros_neuroPsychSubEpisodesBehav,
    ros_neuroPsychSubEpisodesBehavComment,
    ros_neuroPsychSubEpisodesDelirium,
    ros_neuroPsychSubEpisodesDeliriumComment,
    ros_neuroPsychSubHearVoices,
    ros_neuroPsychSubHearVoicesComment,
    ros_neuroPsychSubHighLows,
    ros_neuroPsychSubHighLowsComment,
    ros_neuroPsychSubEverFeel,
    ros_neuroPsychSubEverFeelComment,
    ros_neuroPsychSubDiagComment,
    ros_neuroPsychSubDiagAlcoholSubDescComment,
    ros_neuroPsychSubDiagAlcoholSubTypeComment,
    ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagAlcoholSubSuppComment,
    ros_neuroPsychSubDiagAlcoholSubDelirium,
    ros_neuroPsychSubDiagAlcoholSubDeliriumComment,
    ros_neuroPsychSubDiagAlcoholSubPsych,
    ros_neuroPsychSubDiagAlcoholSubPsychComment,
    ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagAmyotropSubSuppComment,
    ros_neuroPsychSubDiagAnxietySubDescComment,
    ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc,
    ros_neuroPsychSubDiagAnxietySubTypeComment,
    ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc,
    ros_neuroPsychSubDiagAnxietySubSuppComment,
    ros_neuroPsychSubDiagBipolarSubDescComment,
    ros_neuroPsychSubDiagBipolarSubTypeComment,
    ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagBipolarSubSuppComment,
    ros_neuroPsychSubDiagCerebHemmSubDescComment,
    ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagCerebHemmSubSuppComment,
    ros_neuroPsychSubDiagDementiaSubDescComment,
    ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDementiaSubSuppComment,
    ros_neuroPsychSubDiagDementiaSubTypeVascComment,
    ros_neuroPsychSubDiagDementiaSubTypeComment,
    ros_neuroPsychSubDiagDrugsSubHistoryPsych,
    ros_neuroPsychSubDiagDrugsSubDescComment,
    ros_neuroPsychSubDiagDrugsSubTypeComment,
    ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDrugsSubSuppComment,
    ros_neuroPsychSubDiagDrugsSubHistoryPsychComment,
    ros_neuroPsychSubDiagDrugsSubWhatDrugs,
    ros_neuroPsychSubDiagDepressSubMajor,
    ros_neuroPsychSubDiagDepressSubDescComment,
    ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDepressSubSuppComment,
    ros_neuroPsychSubDiagDepressSubMajorComment,
    ros_neuroPsychSubDiagDepressSubEpisodeComment,
    ros_neuroPsychSubDiagHemiSubDescComment,
    ros_neuroPsychSubDiagHemiSubHemiDescComment,
    ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagHemiSubSuppComment,
    ros_neuroPsychSubDiagIntelSubDescComment,
    ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagIntelSubSuppComment,
    ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc,
    ros_neuroPsychSubDiagIntelSubIntelDescComment,
    ros_neuroPsychSubDiagSeizureSubTreatment,
    ros_neuroPsychSubDiagSeizureSubDescComment,
    ros_neuroPsychSubDiagSeizureSubTypeComment,
    ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSeizureSubSuppComment,
    ros_neuroPsychSubDiagSpinalCordSubDescComment,
    ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSpinalCordSubSuppComment,
    ros_neuroPsychSubDiagSpinalCordSubSecToComment,
    ros_neuroPsychSubDiagStrokeSubDescComment,
    ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubComment,
    ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagStrokeSubSuppComment,
    ros_neuroPsychSubDiagSubduralSubDescComment,
    ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubComment,
    ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSubduralSubSuppComment,
    ros_neuroPsychSubDiagTraumaBrainSubDescComment,
    ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagTraumaBrainSubSuppComment,
    ros_neuroPsychSubDiagCerebPalsySubDescComment,
    ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc,
    ros_neuroPsychSubDiagCerebPalsySubSuppComment,
    ros_neuroPsychSubDiagDelusionSubDescComment,
    ros_neuroPsychSubDiagDelusionSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDelusionSubSuppComment,
    ros_neuroPsychSubDiagFibroSubDescComment,
    ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagFibroSubSuppComment,
    ros_neuroPsychSubDiagGuillSubDescComment,
    ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagGuillSubSuppComment,
    ros_neuroPsychSubDiagHuntingSubDescComment,
    ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagHuntingSubSuppComment,
    ros_neuroPsychSubDiagInsomniaSubDescComment,
    ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagInsomniaSubSuppComment,
    ros_neuroPsychSubDiagMigraineSubDescComment,
    ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagMigraineSubSuppComment,
    ros_neuroPsychSubDiagSclerosisSubDescComment,
    ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSclerosisSubSuppComment,
    ros_neuroPsychSubDiagDystrophySubDescComment,
    ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDystrophySubSuppComment,
    ros_neuroPsychSubDiagGravisSubDescComment,
    ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagGravisSubSuppComment,
    ros_neuroPsychSubDiagParkinsonSubDescComment,
    ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagParkinsonSubSuppComment,
    ros_neuroPsychSubDiagPeriNeuroSubDescComment,
    ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagPeriNeuroSubSuppComment,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetes,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetesComment,
    ros_neuroPsychSubDiagRestlessLegSubDescComment,
    ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagRestlessLegSubSuppComment,
    ros_neuroPsychSubDiagSchizoSubDescComment,
    ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSchizoSubSuppComment,
    ros_neuroPsychSubDiagTiaSubDescComment,
    ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagTiaSubSuppComment,
    ros_neuroPsychSubDiagOtherSubDescComment,
    ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagOtherSubSuppComment,
    ros_neuroPsychSubDiagOtherSubOther,

    gait_Recommendations,
    gait_RecommendationstakeMedicationsAsPescribedComment,
    gait_RecommendationsotherComment,
    respiratory_Recommendations,
    respiratory_RecommendationstakeMedicationsAsPescribedComment,
    respiratory_RecommendationsotherComment,

    integument_Recommendations,
    integument_RecommendationstakeMedicationsAsPescribedComment,
    integument_RecommendationsotherComment,

    musko_Recommendations,
    musko_RecommendationsdiscussPtOtComment,
    musko_RecommendationstakeMedicationsAsPescribedComment,
    musko_RecommendationsotherComment,

    hema_Recommendations,
    hema_RecommendationstakeMedicationsAsPescribedComment,
    hema_RecommendationsreportAbnormalBruisingComment,
    hema_RecommendationsfollowUpWithDoctorComment,
    hema_RecommendationsotherComment,

    ros_gaitComment: ros_gaitComment || "",
    ros_respiratoryComment: ros_respiratoryComment || "",
    ros_acutePulmonarySubAnswerDescribeComment:
      ros_acutePulmonarySubAnswerDescribeComment || "",
    ros_respiratoryDiagnosesComment,
    ros_acutePulmonarySubAnswerSupportedByComment,
    ros_acuteUpperSubAnswerDescribeComment,
    ros_acuteUpperSubAnswerSupportedByComment,
    ros_asthmaSubAnswerDescribeComment,
    ros_asthmaSubAnswerSupportedByComment,
    ros_asthmaPatientControllerComment,
    ros_asthmaRescueMedicationsComment,
    ros_asthmaCurrentExacerbationComment,
    ros_chronicPulmonaryEmbolismSubAnswerDescribeComment,
    ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment,
    ros_chronicRespiratoryFailureSubAnswerDescribeComment,
    ros_chronicRespiratoryFailureSubAnswerSupportedByComment,
    ros_chronicSputumFailureSubAnswerDescribeComment,
    ros_chronicSputumFailureSubAnswerSupportedByComment,
    ros_copdSubAnswerDescribeComment,
    ros_copdSubAnswerSupportedByComment,
    ros_patientChronicBronchitisComment,
    ros_patientHaveEmphysemaComment,
    ros_patientBronchodilatorComment,
    ros_patientSteroidsComment,
    ros_patientExacerbationComment,
    ros_cysticFibrosisSubAnswerDescribeComment,
    ros_cysticFibrosisSubAnswerSupportedByComment,
    ros_hypoventilationSubAnswerDescribeComment,
    ros_hypoventilationSubAnswerSupportedByComment,
    ros_hypoxemiaSubAnswerDescribeComment,
    ros_hypoxemiaSubAnswerSupportedByComment,
    ros_pneumoniaSubAnswerDescribeComment,
    ros_pneumoniaSubAnswerSupportedByComment,
    ros_pneumoniaSubAnswerEtiologyComment,
    ros_historyOfLungAbscessComment,
    ros_historyOfEmpyemaComment,
    ros_pulmonaryFibrosisSubAnswerDescribeComment,
    ros_pulmonaryFibrosisSubAnswerSupportedByComment,
    ros_respiratorDependenceSubAnswerDescribeComment,
    ros_respiratorDependenceSubAnswerSupportedByComment,
    ros_respiratoryArrestSubAnswerDescribeComment,
    ros_respiratoryArrestSubAnswerSupportedByComment,
    ros_sarcoidosisSubAnswerDescribeComment,
    ros_sarcoidosisSubAnswerSupportedByComment,
    ros_sarcoidosisSubAnswerSpecifyComment,
    ros_sleepApneaSubAnswerDescribeComment,
    ros_sleepApneaSubAnswerSupportedByComment,
    ros_otherSubAnswerDescribeComment,
    ros_otherSubAnswerSupportedByComment,
    ros_otherSubAnswerOtherSubText,

    ros_integumentComment,
    ros_integumentDiagnosesComment,
    ros_basilCellCarcinomaSubAnswerDescribeComment,
    ros_basilCellCarcinomaSubAnswerSupportedByComment,
    ros_dermatitisSubAnswerDescribeComment,
    ros_dermatitisSubAnswerSupportedByComment,
    ros_dermatitisSubAnswerWhatTypeComment,
    ros_eczemaSubAnswerDescribeComment,
    ros_eczemaSubAnswerSupportedByComment,
    ros_onychomycosisSubAnswerDescribeComment,
    ros_onychomycosisSubAnswerSupportedByComment,
    ros_psoriasisSubAnswerDescribeComment,
    ros_psoriasisSubAnswerSupportedByComment,
    ros_psoriasisSubAnswerPsoriaticArthritisComment,
    ros_skinUlcerSubAnswerDescribeComment,
    ros_skinUlcerSubAnswerSupportedByComment,
    ros_skinUlcerSubAnswerEtiologyComment,
    ros_tineaPedisSubAnswerDescribeComment,
    ros_tineaPedisSubAnswerSupportedByComment,
    ros_urticarialDiseaseSubAnswerDescribeComment,
    ros_urticarialDiseaseSubAnswerSupportedByComment,
    ros_urticarialDiseaseSubAnswerEtiologySubText,
    ros_urticarialDiseaseSubAnswerTypeComment,
    ros_woundSubAnswerDescribeComment,
    ros_woundSubAnswerSupportedByComment,
    ros_woundSubAnswerEtiologyComment,
    ros_othersSubAnswerDescribeComment,
    ros_othersSubAnswerSupportedByComment,
    ros_integumentOthersSubAnswerOtherSubText,

    ros_musculoskeletalComment,
    ros_musculoskeletalDiagnosesComment,
    ros_collagenSubAnswerDescribeComment:
      ros_collagenSubAnswerDescribeComment || "",
    ros_collagenSubAnswerSupportedByComment:
      ros_collagenSubAnswerSupportedByComment || "",
    ros_collagenSubAnswercollagenDescribeComment:
      ros_collagenSubAnswercollagenDescribeComment || "",
    ros_degenerativeDiscDiseaseSubAnswerDescribeComment:
      ros_degenerativeDiscDiseaseSubAnswerDescribeComment || "",
    ros_degenerativeDiscDiseaseSubAnswerSupportedByComment:
      ros_degenerativeDiscDiseaseSubAnswerSupportedByComment || "",
    ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment,
    ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment,
    ros_extremityFractureSubAnswerDescribeComment,
    ros_extremityFractureSubAnswerSupportedByComment,
    ros_extremityFractureSubAnswerExtremityComment,
    ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment,
    ros_goutSubAnswerDescribeComment,
    ros_goutSubAnswerSupportedByComment,
    ros_halluxValgusSubAnswerDescribeComment,
    ros_halluxValgusSubAnswerSupportedByComment,
    ros_hammerToesSubAnswerDescribeComment,
    ros_hammerToesSubAnswerSupportedByComment,
    ros_osteoarthritisSubAnswerDescribeComment,
    ros_osteoarthritisSubAnswerSupportedByComment,
    ros_osteoarthritisSubAnswerWhichJointsSubText,
    ros_osteomyelitisSubAnswerDescribeComment,
    ros_osteomyelitisSubAnswerSupportedByComment,
    ros_osteoporosisSubAnswerDescribeComment,
    ros_osteoporosisSubAnswerSupportedByComment,
    ros_pyogenicArthritisSubAnswerDescribeComment,
    ros_pyogenicArthritisSubAnswerWhichJointsSubText,
    ros_pyogenicArthritisSubAnswerSupportedByComment,
    ros_rheumatoidArthritisSubAnswerDescribeComment,
    ros_rheumatoidArthritisSubAnswerWhichJointsSubText,
    ros_rheumatoidArthritisSubAnswerSupportedByComment,
    ros_spinalStenosisSubAnswerDescribeComment,
    ros_spinalStenosisSubAnswerSupportedByComment,
    ros_spinalStenosisSubAnswerNormalBladderComment,
    ros_systemicLupusSubAnswerDescribeComment,
    ros_systemicLupusSubAnswerSupportedByComment,
    ros_muskoOtherSubAnswerDescribeComment,
    ros_muskoOtherSubAnswerSupportedByComment,
    ros_muskoOtherSubAnswerotherSubText,
    ros_hadAnAmputationSubAnswerSubText,

    ros_hematologyComment,
    ros_hematologyDiagnosesComment,
    ros_aidsSubAnswerDescribeComment,
    ros_aidsSubAnswerSupportedByComment,
    ros_aidsSubAnswerPatientUnderTreatmentComment,
    ros_anemiaSubAnswerDescribeComment,
    ros_anemiaSubAnswerSupportedByComment,
    ros_anemiaSubAnswerEtiologyComment,
    ros_anemiaSubAnswerYesPatientOnComment,
    ros_cDifficileSubAnswerDescribeComment,
    ros_cDifficileSubAnswerSupportedByComment,
    ros_communityAcquiredSubAnswerDescribeComment,
    ros_communityAcquiredSubAnswerSupportedByComment,
    ros_hivSubAnswerDescribeComment,
    ros_hivSubAnswerSupportedByComment,
    ros_hivSubAnswerViralLoadSubText,
    ros_hivSubAnswerCFourSubText,
    ros_hivSubAnswerPatientSymptomaticComment,
    ros_hivSubAnswerActiveTreatmentComment,
    ros_herpesZosterSubAnswerDescribeComment,
    ros_herpesZosterSubAnswerSupportedByComment,
    ros_mrsaInfectionSubAnswerDescribeComment,
    ros_mrsaInfectionSubAnswerSupportedByComment,
    ros_immuneDeficiencySubAnswerDescribeComment,
    ros_immuneDeficiencySubAnswerEtiologySubText,
    ros_immuneDeficiencySubAnswerSupportedByComment,
    ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment,
    ros_sepsisSubAnswerDescribeComment,
    ros_sepsisSubAnswerSupportedByComment,
    ros_sickleCellDiseaseTwoSubAnswerDescribeComment,
    ros_sickleCellDiseaseTwoSubAnswerSupportedByComment,
    ros_sickleCellTraitSubAnswerDescribeComment,
    ros_sickleCellTraitSubAnswerSupportedByComment,
    ros_thalassemiaSubAnswerDescribeComment,
    ros_thalassemiaSubAnswerSupportedByComment,
    ros_thrombocytopeniaSubAnswerDescribeComment,
    ros_thrombocytopeniaSubAnswerEtiologySubText,
    ros_thrombocytopeniaSubAnswerSupportedByComment,
    ros_tuberculosisSubAnswerDescribeComment,
    ros_tuberculosisSubAnswerSupportedByComment,
    ros_tuberculosisSubAnswerGivenBCGComment,
    ros_tuberculosisSubAnswerActiveTuberculosisComment,
    ros_tuberculosisSubAnswerTbInfectionComment,
    ros_vitaminDDeficiencySubAnswerSupportedByComment,
    ros_hemaOtherSubAnswerDescribeComment,
    ros_hemaOtherSubAnswerSupportedByComment,
    ros_hemaOtherSubAnswerOtherSubText,
    //-------------RESPIRATORY COMMENT--------

    //----------------------------------------------------------------//
    ros_respiratory,
    ros_musculoskeletal,
    ros_hematology,

    ros_asthmaPatientController,
    ros_asthmaRescueMedications,
    ros_asthmaCurrentExacerbation,

    ros_patientChronicBronchitis,
    ros_patientHaveEmphysema,
    ros_patientBronchodilator,
    ros_patientSteroids,
    ros_patientExacerbation,

    ros_historyOfLungAbscess,
    ros_historyOfEmpyema,

    ros_integument,

    ros_psoriasisSubAnswerPsoriaticArthritis,

    ros_degenerativeDiscDiseaseSubAnswerNormalBladder,

    ros_extremityFractureCurrentWithinTwelveWeeksSubAnswer,

    ros_spinalStenosisSubAnswerNormalBladder,

    ros_muskoHadAnAmputation,

    ros_aidsSubAnswerPatientUnderTreatment,

    ros_hivSubAnswerPatientSymptomatic,
    ros_hivSubAnswerActiveTreatment,

    ros_immuneDeficiencySubAnswerHistoryOfOpportunistic,

    ros_tuberculosisSubAnswerGivenBCG,
    ros_tuberculosisSubAnswerActiveTuberculosis,
    ros_tuberculosisSubAnswerTbInfection,

    ros_diagnosisOfCancer,
    ros_diagnosisOfCancerComment,
    ros_gastroSubDiagnosesbowelObstructionSupComment,
    ros_gastroSubDiagnosesdesbowelObstructionComment,
    ros_endoValComment,
    ros_gastroValComment,
    ros_genitoValComment,

    ros_cardio,
    ros_endoVal,
    ros_gastroVal,
    ros_genitoVal,

    // ros_cardioSubDiagnoses,

    ros_cardioComment,
    ros_cardioSubDiagnosesComment,

    ros_cardiovascularRecommendations: ros_cardiovascularRecommendations,
    ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment:
      cardio_RecommendationstakeMedicationsAsPescribedComment,
    ros_cardiovascularRecommendationsotherComment:
      cardio_RecommendationsotherComment,
    ros_cardiovascularRecommendationsexerciseThirtyMinADayComment:
      cardio_RecommendationsexerciseThirtyMinADayComment,
    ros_cardiovascularRecommendationsbloodPressureChecksComment:
      cardio_RecommendationsbloodPressureChecksComment,
    ros_cardiovascularRecommendationsheartHealthyDietComment:
      cardio_RecommendationsheartHealthyDietComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest,
    ros_cancerRecommendations: ros_cancerRecommendations,
    ros_cancerRecommendationstakemedicationsasprescribedCancerComment,
    ros_cancerRecommendationsotherCancerComment,
    specialTypes: specialTypes,
    stageSpecificToTheCancer: stageSpecificToTheCancer,
    ros_activeTreatment,
    ros_Metastasis,
    ros_SeeASpec,
    ros_diagnosisOfCancerSubSeeASpecProVal,
    ros_diagnosisOfCancerSubMetastasisLocVal,
    ros_diagnosisOfCancerSubMetastasisCachexiaVal,
    ros_diagnosisOfCancerSubDescComment,
    ros_diagnosisOfCancerSubSupComment,
    ros_diagnosisOfCancerSubTypeComment,
    ros_diagnosisOfCancerSubSideEffComment,
    ros_diagnosisOfCancerSubActiveTreatComment,
    ros_activeTreatmentComment,
    ros_SeeASpecComment,
    ros_diagnosisOfCancerSubMetastasisCachexiaValComment,
    ros_MetastasisComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDesComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment,

    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistent,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment,

    endocrine_recommendations: endocrine_recommendations,
    ros_genitoRecommendations: ros_genitoRecommendations,
    gastro_recommendations: gastro_recommendations,
    ros_gastroSubDiagnosesceliacDiseaseGlueten,

    ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment,
    ros_genitoRecommendationsotherGenitoComment,

    endocrine_recommendationstakeMedicationsAsPescribedComment,
    endocrine_recommendationscheckBloodSugarComment,
    endocrine_recommendationsotherComment,

    gastro_recommendationstakemedicationsasprescribedComment,
    gastro_recommendationsotherComment,
    ros_gastroSubDiagnosescachexiaSupComment,

    // ros_gastroSubDiagnosesComment
    ros_gastroSubDiagnosesComment,
    ros_gastroSubDiagnosescirrhosisEndStage,
    ros_gastroSubDiagnosescirrhosisEndStageComment,
    ros_gastroSubDiagnosesdescirrhosisComment,
    ros_gastroSubDiagnosescirrhosisSupComment,
    ros_genitoSubDiagnosesComment,
    ros_endoSubDiagnosesComment,
    ros_gastroSubDiagnosescachexiadesComment,
    ros_gastroSubDiagnosesceliacDiseaseGluetenComment,
    ros_gastroSubDiagnosesceliacDiseaseSupComment,
    ros_gastroSubDiagnosesdesceliacDiseaseComment,

    ros_gastroSubDiagnosescolonCrDesComment,
    ros_gastroSubDiagnosescolonPolypsDesComment,

    ros_gastroSubDiagnosescolonSupComment,

    ros_genitoSubDiagnosesacuteRenalFailureEti,

    ros_genitoSubDiagnosesacuteRenalFailuredesComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupComment,
    ros_cardioSubDiagnosesAneurysmDesComment,
    ros_cardioSubDiagnosesAneurysmCrDesComment,
    ros_cardioSubDiagnosesAneurysmSupComment,
    ros_cardioSubDiagnosesAnginaCrDesComment,
    ros_cardioSubDiagnosesAnginaSupComment,
    ros_cardioSubDiagnosesAnginaDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationDesComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin,
    ros_cardioSubDiagnosesHypertensionadequatelyControlled,
  };

  return tempDbData;
};

export const setRosData = (formData) => {
  let tempData = {};

  ros_OptionsTreeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1]),
    };
  });

  const ros_eyesVal = setOptionsSelectedYesNo(formData["ros_eyes"]);
  const ros_eyesCommentVal = formData["ros_eyesComment"];
  const ros_eyesDiagnosesCommentVal = formData["ros_eyesDiagnosesComment"];
  const ros_eyesDiagCataractSubWhEyeCommentVal =
    formData["ros_eyesDiagCataractSubWhEyeComment"];
  const ros_eyesDiagCataractSubDescCommentVal =
    formData["ros_eyesDiagCataractSubDescComment"];
  const ros_eyesDiagCataractSubSuppCommentVal =
    formData["ros_eyesDiagCataractSubSuppComment"];
  const ros_eyesDiagCataractSubSuppSympSubCommentVal =
    formData["ros_eyesDiagCataractSubSuppSympSubComment"];
  const ros_eyesDiagCataractSubSuppOtherSubDescVal =
    formData["ros_eyesDiagCataractSubSuppOtherSubDesc"];
  const ros_eyesDiagCataractSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagCataractSubDiabetes"],
  );
  const ros_eyesDiagCataractSubDiabetesCommentVal =
    formData["ros_eyesDiagCataractSubSuppComment"];

  const ros_eyesDiagGlaucomaSubWhEyeCommentVal =
    formData["ros_eyesDiagGlaucomaSubWhEyeComment"];
  const ros_eyesDiagGlaucomaSubDescCommentVal =
    formData["ros_eyesDiagGlaucomaSubDescComment"];
  const ros_eyesDiagGlaucomaSubSuppCommentVal =
    formData["ros_eyesDiagGlaucomaSubSuppComment"];
  const ros_eyesDiagGlaucomaSubSuppSympSubCommentVal =
    formData["ros_eyesDiagGlaucomaSubSuppSympSubComment"];
  const ros_eyesDiagGlaucomaSubSuppOtherSubDescVal =
    formData["ros_eyesDiagGlaucomaSubSuppOtherSubDesc"];
  const ros_eyesDiagGlaucomaSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagGlaucomaSubDiabetes"],
  );
  const ros_eyesDiagGlaucomaSubDiabetesCommentVal =
    formData["ros_eyesDiagGlaucomaSubSuppComment"];
  const ros_eyesDiagGlaucomaSubDiabetesSubCommentVal =
    formData["ros_eyesDiagGlaucomaSubDiabetesSubComment"];

  const ros_eyesDiagHyperopiaSubWhEyeCommentVal =
    formData["ros_eyesDiagHyperopiaSubWhEyeComment"];
  const ros_eyesDiagHyperopiaSubDescCommentVal =
    formData["ros_eyesDiagHyperopiaSubDescComment"];
  const ros_eyesDiagHyperopiaSubSuppCommentVal =
    formData["ros_eyesDiagHyperopiaSubSuppComment"];
  const ros_eyesDiagHyperopiaSubSuppSympSubCommentVal =
    formData["ros_eyesDiagHyperopiaSubSuppSympSubComment"];
  const ros_eyesDiagHyperopiaSubSuppOtherSubDescVal =
    formData["ros_eyesDiagHyperopiaSubSuppOtherSubDesc"];
  const ros_eyesDiagHyperopiaSubDescVal =
    formData["ros_eyesDiagHyperopiaSubDesc"];

  const ros_eyesDiagLegalBlindSubWhEyeCommentVal =
    formData["ros_eyesDiagLegalBlindSubWhEyeComment"];
  const ros_eyesDiagLegalBlindSubDescCommentVal =
    formData["ros_eyesDiagLegalBlindSubDescComment"];
  const ros_eyesDiagLegalBlindSubSuppCommentVal =
    formData["ros_eyesDiagLegalBlindSubSuppComment"];
  const ros_eyesDiagLegalBlindSubSuppSympSubCommentVal =
    formData["ros_eyesDiagLegalBlindSubSuppSympSubComment"];
  const ros_eyesDiagLegalBlindSubSuppOtherSubDescVal =
    formData["ros_eyesDiagLegalBlindSubSuppOtherSubDesc"];
  const ros_eyesDiagLegalBlindSubCommentVal =
    formData["ros_eyesDiagLegalBlindSubSubComment"];
  const ros_eyesDiagLegalBlindSubDescVal =
    formData["ros_eyesDiagLegalBlindSubDesc"];

  const ros_eyesDiagMascDegenSubWhEyeCommentVal =
    formData["ros_eyesDiagMascDegenSubWhEyeComment"];
  const ros_eyesDiagMascDegenSubDescCommentVal =
    formData["ros_eyesDiagMascDegenSubDescComment"];
  const ros_eyesDiagMascDegenSubSuppCommentVal =
    formData["ros_eyesDiagMascDegenSubSuppComment"];
  const ros_eyesDiagMascDegenSubSuppSympSubCommentVal =
    formData["ros_eyesDiagMascDegenSubSuppSympSubComment"];
  const ros_eyesDiagMascDegenSubSuppOtherSubDescVal =
    formData["ros_eyesDiagMascDegenSubSuppOtherSubDesc"];
  const ros_eyesDiagMascDegenSubCommentVal =
    formData["ros_eyesDiagMascDegenSubComment"];
  const ros_eyesDiagMascDegenSubDescVal =
    formData["ros_eyesDiagMascDegenSubDesc"];

  const ros_eyesDiagMyopiaSubWhEyeCommentVal =
    formData["ros_eyesDiagMyopiaSubWhEyeComment"];
  const ros_eyesDiagMyopiaSubDescCommentVal =
    formData["ros_eyesDiagMyopiaSubDescComment"];
  const ros_eyesDiagMyopiaSubSuppCommentVal =
    formData["ros_eyesDiagMyopiaSubSuppComment"];
  const ros_eyesDiagMyopiaSubSuppSympSubCommentVal =
    formData["ros_eyesDiagMyopiaSubSuppSympSubComment"];
  const ros_eyesDiagMyopiaSubSuppOtherSubDescVal =
    formData["ros_eyesDiagMyopiaSubSuppOtherSubDesc"];
  const ros_eyesDiagMyopiaSubDescVal = formData["ros_eyesDiagMyopiaSubDesc"];

  const ros_eyesDiagRetinalSubWhEyeCommentVal =
    formData["ros_eyesDiagRetinalSubWhEyeComment"];
  const ros_eyesDiagRetinalSubDescCommentVal =
    formData["ros_eyesDiagRetinalSubDescComment"];
  const ros_eyesDiagRetinalSubSuppCommentVal =
    formData["ros_eyesDiagRetinalSubSuppComment"];
  const ros_eyesDiagRetinalSubSuppSympSubCommentVal =
    formData["ros_eyesDiagRetinalSubSuppSympSubComment"];
  const ros_eyesDiagRetinalSubSuppOtherSubDescVal =
    formData["ros_eyesDiagRetinalSubSuppOtherSubDesc"];
  const ros_eyesDiagRetinalSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagRetinalSubDiabetes"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagRetinalSubDiabetesSubIsVitHemm"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubCommentVal =
    formData["ros_eyesDiagRetinalSubDiabetesSubComment"];
  const ros_eyesDiagRetinalSubDiabetesCommentVal =
    formData["ros_eyesDiagRetinalSubDiabetesComment"];

  const ros_eyesDiagOtherSubWhEyeCommentVal =
    formData["ros_eyesDiagOtherSubWhEyeComment"];
  const ros_eyesDiagOtherSubDescCommentVal =
    formData["ros_eyesDiagOtherSubDescComment"];
  const ros_eyesDiagOtherSubSuppCommentVal =
    formData["ros_eyesDiagOtherSubSuppComment"];
  const ros_eyesDiagOtherSubSuppSympSubCommentVal =
    formData["ros_eyesDiagOtherSubSuppSympSubComment"];
  const ros_eyesDiagOtherSubSuppOtherSubDescVal =
    formData["ros_eyesDiagOtherSubSuppOtherSubDesc"];
  const ros_eyesDiagOtherSubOtherVal = formData["ros_eyesDiagOtherSubOther"];

  const ros_earsVal = setOptionsSelectedYesNo(formData["ros_ears"]);
  const ros_earsCommentVal = formData["ros_earsComment"];
  const ros_earsDiagnosesCommentVal = formData["ros_earsDiagnosesComment"];
  const ros_earsDiagDiffHearSubSuppOtherSubDescVal =
    formData["ros_earsDiagDiffHearSubSuppOtherSubDesc"];
  const ros_earsDiagDiffHearSubSuppCommentVal =
    formData["ros_earsDiagDiffHearSubSuppComment"];

  const ros_earsDiagLegalDeafSubSuppOtherSubDescVal =
    formData["ros_earsDiagLegalDeafSubSuppOtherSubDesc"];
  const ros_earsDiagLegalDeafSubSuppCommentVal =
    formData["ros_earsDiagLegalDeafSubSuppComment"];

  const ros_earsDiagTinnitusSubSuppOtherSubDescVal =
    formData["ros_earsDiagTinnitusSubSuppOtherSubDesc"];
  const ros_earsDiagTinnitusSubSuppCommentVal =
    formData["ros_earsDiagTinnitusSubSuppComment"];

  const ros_earsDiagVertigoSubSuppOtherSubDescVal =
    formData["ros_earsDiagVertigoSubSuppOtherSubDesc"];
  const ros_earsDiagVertigoSubSuppCommentVal =
    formData["ros_earsDiagVertigoSubSuppComment"];
  const ros_earsDiagVertigoSubLoseBalVal = setOptionsSelectedYesNo(
    formData["ros_earsDiagVertigoSubLoseBal"],
  );
  const ros_earsDiagVertigoSubLoseBalCommentVal =
    formData["ros_earsDiagVertigoSubLoseBalComment"];

  const ros_earsDiagOtherSubSuppOtherSubDescVal =
    formData["ros_earsDiagOtherSubSuppOtherSubDesc"];
  const ros_earsDiagOtherSubSuppCommentVal =
    formData["ros_earsDiagOtherSubSuppComment"];

  const ros_earsDiagOtherSubOtherVal = formData["ros_earsDiagOtherSubOther"];

  const ros_noseVal = setOptionsSelectedYesNo(formData["ros_nose"]);

  const ros_noseDiagAllergySubSuppOtherSubDescVal =
    formData["ros_noseDiagAllergySubSuppOtherSubDesc"];
  const ros_noseDiagAllergySubSuppCommentVal =
    formData["ros_noseDiagAllergySubSuppComment"];
  const ros_noseDiagChronicNasalSubSuppOtherSubDescVal =
    formData["ros_noseDiagChronicNasalSubSuppOtherSubDesc"];
  const ros_noseDiagChronicNasalSubSuppCommentVal =
    formData["ros_noseDiagChronicNasalSubSuppComment"];
  const ros_noseDiagNoseBleedSubCommentVal =
    formData["ros_noseDiagNoseBleedSubComment"];
  const ros_noseDiagSeasonAllergySubSuppOtherSubDescVal =
    formData["ros_noseDiagSeasonAllergySubSuppOtherSubDesc"];
  const ros_noseDiagSeasonAllergySubSuppCommentVal =
    formData["ros_noseDiagSeasonAllergySubSuppComment"];
  const ros_noseDiagSinusInfectSubSuppOtherSubDescVal =
    formData["ros_noseDiagSinusInfectSubSuppOtherSubDesc"];
  const ros_noseDiagSinusInfectSubSuppCommentVal =
    formData["ros_noseDiagSinusInfectSubSuppComment"];
  const ros_noseDiagSinusInfectSubExuCommentVal =
    formData["ros_noseDiagSinusInfectSubExuComment"];

  const ros_noseDiagSinustisSubSuppOtherSubDescVal =
    formData["ros_noseDiagSinustisSubSuppOtherSubDesc"];
  const ros_noseDiagSinustisSubSuppCommentVal =
    formData["ros_noseDiagSinustisSubSuppComment"];

  const ros_noseDiagOtherSubSuppOtherSubDescVal =
    formData["ros_noseDiagOtherSubSuppOtherSubDesc"];
  const ros_noseDiagOtherSubSuppCommentVal =
    formData["ros_noseDiagOtherSubSuppComment"];
  const ros_noseDiagOtherSubOtherVal = formData["ros_noseDiagOtherSubOther"];

  const ros_noseDiagCommentVal = formData["ros_noseDiagComment"];
  const ros_noseCommentVal = formData["ros_noseComment"];

  const ros_mouthVal = setOptionsSelectedYesNo(formData["ros_mouth"]);
  const ros_mouthDiagCommentVal = formData["ros_mouthDiagComment"];
  const ros_mouthCommentVal = formData["ros_mouthComment"];
  const ros_mouthDiagBleedGumsSubCommentVal =
    formData["ros_mouthDiagBleedGumsSubComment"];
  const ros_mouthDiagDiffChewSubDescCommentVal =
    formData["ros_mouthDiagDiffChewSubDescComment"];
  const ros_mouthDiagDiffChewSubBcsPainVal = setOptionsSelectedYesNo(
    formData["ros_mouthDiagDiffChewSubBcsPain"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSubVal = setOptionsSelectedYesNo(
    formData["ros_mouthDiagDiffChewSubBcsPainSub"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSubCommentVal =
    formData["ros_mouthDiagDiffChewSubBcsPainSubComment"];
  const ros_mouthDiagDiffChewSubBcsPainCommentVal =
    formData["ros_mouthDiagDiffChewSubBcsPainComment"];

  const ros_mouthDiagDiffSwallowSubHadStrokeVal = setOptionsSelectedYesNo(
    formData["ros_mouthDiagDiffSwallowSubHadStroke"],
  );
  const ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal =
    setOptionsSelectedYesNo(
      formData["ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub"],
    );
  const ros_mouthDiagDiffSwallowSubHadStrokeSubCommentVal =
    formData["ros_mouthDiagDiffSwallowSubHadStrokeSubComment"];
  const ros_mouthDiagDiffSwallowSubHadStrokeCommentVal =
    formData["ros_mouthDiagDiffSwallowSubHadStrokeComment"];

  const ros_mouthDiagOtherSubSuppOtherSubDescVal =
    formData["ros_mouthDiagOtherSubSuppOtherSubDesc"];
  const ros_mouthDiagOtherSubSuppCommentVal =
    formData["ros_mouthDiagOtherSubSuppComment"];
  const ros_mouthDiagOtherSubOtherVal = formData["ros_mouthDiagOtherSubOther"];

  const ros_neckVal = setOptionsSelectedYesNo(formData["ros_neck"]);
  const ros_neckDiagCommentVal = formData["ros_neckDiagComment"];
  const ros_neckCommentVal = formData["ros_neckComment"];
  const ros_neckDiagCarotidSubDescCommentVal =
    formData["ros_neckDiagCarotidSubDescComment"];
  const ros_neckDiagCarotidSubSuppOtherSubDescVal =
    formData["ros_neckDiagCarotidSubSuppOtherSubDesc"];
  const ros_neckDiagCarotidSubSuppCommentVal =
    formData["ros_neckDiagCarotidSubSuppComment"];
  const ros_neckDiagCarotidCsDescCommentVal =
    formData["ros_neckDiagCarotidCsDescComment"];

  const ros_neckDiagParotidSubSuppOtherSubDescVal =
    formData["ros_neckDiagParotidSubSuppOtherSubDesc"];
  const ros_neckDiagParotidSubSuppCommentVal =
    formData["ros_neckDiagParotidSubSuppComment"];
  const ros_neckDiagParotidSubDescCommentVal =
    formData["ros_neckDiagParotidSubDescComment"];

  const ros_neckDiagOtherSubDescCommentVal =
    formData["ros_neckDiagOtherSubDescComment"];
  const ros_neckDiagOtherSubSuppOtherSubDescVal =
    formData["ros_neckDiagOtherSubSuppOtherSubDesc"];
  const ros_neckDiagOtherSubSuppCommentVal =
    formData["ros_neckDiagOtherSubSuppComment"];
  const ros_neckDiagOtherSubOtherVal = formData["ros_neckDiagOtherSubOther"];

  const ros_eyeENTNeckRecommendationsVal = setSelectedCheckboxes(
    [
      formData["ros_recoHearEval"],
      formData["ros_recoDentalExam"],
      formData["ros_recoEyeExam"],
      formData["ros_recoSwalEvaly"],
      formData["ros_recoTakeMed"],
      formData["ros_recoFoodDisp"],
      formData["ros_recoOther"],
    ],
    [
      "hearingEvaluation",
      "dentalExam",
      "eyeExam",
      "swallowingEvaluation",
      "takeMedications",
      "other",
    ],
  );

  const ros_eyeENTRecoHearEvalCommentVal =
    formData["ros_eyeENTRecoHearEvalComment"];
  const ros_eyeENTRecoDentalExamCommentVal =
    formData["ros_eyeENTRecoDentalExamComment"];
  const ros_eyeENTRecoEyeExamCommentVal =
    formData["ros_eyeENTRecoEyeExamComment"];
  const ros_eyeENTRecoSwalEvalyCommentVal =
    formData["ros_eyeENTRecoSwalEvalyComment"];
  const ros_eyeENTRecoTakeMedCommentVal =
    formData["ros_eyeENTRecoTakeMedComment"];
  const ros_eyeENTRecoFoodDispCommentVal =
    formData["ros_eyeENTRecoFoodDispComment"];
  const ros_eyeENTRecoOtherCommentVal = formData["ros_eyeENTRecoOtherComment"];

  const ros_bowelMoveAbnormDiarrIshisVal = setOptionsSelectedYesNo(
    formData["ros_bowelMoveAbnormDiarrIshis"],
  );
  const ros_bowelMoveAbnormDiarrIshisCommentVal =
    formData["ros_bowelMoveAbnormDiarrIshisComment"];

  const ros_bowelMoveCommentVal = formData["ros_bowelMoveComment"];
  const ros_bowelMoveAbnormCommentVal = formData["ros_bowelMoveAbnormComment"];
  const ros_bowelMoveAbnormConstCommentVal =
    formData["ros_bowelMoveAbnormConstComment"];
  const ros_bowelMoveAbnormDiarrIsdiaCommentVal =
    formData["ros_bowelMoveAbnormDiarrIsdiaComment"];

  const ros_abdominalIsabdVal = setOptionsSelectedYesNo(
    formData["ros_abdominalIsabd"],
  );
  const ros_abdominalIsyesCommentVal = formData["ros_abdominalIsyesComment"];
  const ros_abdominalCommentVal = formData["ros_abdominalComment"];

  const ros_rectalIsrecVal = setOptionsSelectedYesNo(
    formData["ros_rectalIsrec"],
  );
  const ros_rectalIsrecIsyesmalCommentVal =
    formData["ros_rectalIsrecIsyesmalComment"];
  const ros_rectalIsrecIsyesfemCommentVal =
    formData["ros_rectalIsrecIsyesfemComment"];
  const ros_rectalCommentVal = formData["ros_rectalComment"];

  const ros_lastBowelCommentVal = formData["ros_lastBowelComment"];

  const ros_meetWithCommentVal = formData["ros_meetWithComment"];

  const ros_gpcogScoIsgpcogsVal = formData["ros_gpcogScoIsgpcogs"];
  const ros_gpcogScoIsmmsescVal = formData["ros_gpcogScoIsmmsesc"];
  const ros_gpcogScoCommentVal = formData["ros_gpcogScoComment"];

  const ros_noGpcogMmsePatientOrientPersonVal = setOptionsSelectedYesNo(
    formData["ros_noGpcogMmsePatientOrientPerson"],
  );
  const ros_noGpcogMmsePatientOrientPlaceVal = setOptionsSelectedYesNo(
    formData["ros_noGpcogMmsePatientOrientPlace"],
  );
  const ros_noGpcogMmsePatientOrientTimeVal = setOptionsSelectedYesNo(
    formData["ros_noGpcogMmsePatientOrientTime"],
  );
  const ros_noGpcogMmsePatientDescribeNewsVal =
    setOptionsSelectedYesNoPartially(
      formData["ros_noGpcogMmsePatientDescribeNews"],
    );
  const ros_noGpcogMmseRecallCommentVal =
    formData["ros_noGpcogMmseRecallComment"];
  const ros_noGpcogMmsePatientDescribeNewsCommentVal =
    formData["ros_noGpcogMmsePatientDescribeNewsComment"];
  const ros_noGpcogMmsePatientOrientPersonCommentVal =
    formData["ros_noGpcogMmsePatientOrientPersonComment"];
  const ros_noGpcogMmsePatientOrientPlaceCommentVal =
    formData["ros_noGpcogMmsePatientOrientPlaceComment"];
  const ros_noGpcogMmsePatientOrientTimeCommentVal =
    formData["ros_noGpcogMmsePatientOrientTimeComment"];

  const ros_speechCommentVal = formData["ros_speechComment"];

  const ros_sitToStandCommentVal = formData["ros_sitToStandComment"];

  const ros_fingerToNoseAbnormalSubCommentVal =
    formData["ros_fingerToNoseAbnormalSubComment"];
  const ros_fingerToNoseCommentVal = formData["ros_fingerToNoseComment"];

  const ros_heelToToeAbnormalSubCommentVal =
    formData["ros_heelToToeAbnormalSubComment"];
  const ros_heelToToeCommentVal = formData["ros_heelToToeComment"];

  const ros_thumbToFingerAbnormalSubCommentVal =
    formData["ros_thumbToFingerAbnormalSubComment"];
  const ros_thumbToFingerCommentVal = formData["ros_thumbToFingerComment"];

  const ros_extremeMoveCommentVal = formData["ros_extremeMoveComment"];

  const ros_affectCommentVal = formData["ros_affectComment"];
  const ros_affectSubCommentVal = formData["ros_affectSubComment"];

  const ros_over2WeeksLittleIntrstCommentVal =
    formData["ros_over2WeeksLittleIntrstComment"];
  const ros_over2WeeksFeelDepressedCommentVal =
    formData["ros_over2WeeksFeelDepressedComment"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstCommentVal =
    formData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubComment"
    ];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment"];

  const ros_neuroPsychVal = setOptionsSelectedYesNo(formData["ros_neuroPsych"]);
  const ros_neuroPsychCommentVal = formData["ros_neuroPsychComment"];

  const ros_neuroPsychNervVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychNerv"],
  );
  const ros_neuroPsychNervCommentVal = formData["ros_neuroPsychNervComment"];

  const ros_neuroPsychWorryVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychWorry"],
  );
  const ros_neuroPsychWorryCommentVal = formData["ros_neuroPsychWorryComment"];

  const ros_neuroPsychAfraidVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychAfraid"],
  );
  const ros_neuroPsychAfraidCommentVal =
    formData["ros_neuroPsychAfraidComment"];

  const ros_neuroPsychSubAuditoryVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubAuditory"],
  );
  const ros_neuroPsychSubAuditoryCommentVal =
    formData["ros_neuroPsychSubAuditoryComment"];

  const ros_neuroPsychSubVisualVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubVisual"],
  );
  const ros_neuroPsychSubVisualCommentVal =
    formData["ros_neuroPsychSubVisualComment"];

  const ros_neuroPsychSubEpisodesBehavVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubEpisodesBehav"],
  );
  const ros_neuroPsychSubEpisodesBehavCommentVal =
    formData["ros_neuroPsychSubEpisodesBehavComment"];

  const ros_neuroPsychSubEpisodesDeliriumVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubEpisodesDelirium"],
  );
  const ros_neuroPsychSubEpisodesDeliriumCommentVal =
    formData["ros_neuroPsychSubEpisodesDeliriumComment"];

  const ros_neuroPsychSubHearVoicesVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubHearVoices"],
  );
  const ros_neuroPsychSubHearVoicesCommentVal =
    formData["ros_neuroPsychSubHearVoicesComment"];

  const ros_neuroPsychSubHighLowsVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubHighLows"],
  );
  const ros_neuroPsychSubHighLowsCommentVal =
    formData["ros_neuroPsychSubHighLowsComment"];

  const ros_neuroPsychSubEverFeelVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubEverFeel"],
  );
  const ros_neuroPsychSubEverFeelCommentVal =
    formData["ros_neuroPsychSubEverFeelComment"];

  const ros_neuroPsychSubDiagCommentVal =
    formData["ros_neuroPsychSubDiagComment"];

  const ros_neuroPsychSubDiagAlcoholSubDescCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubDescComment"];

  const ros_neuroPsychSubDiagAlcoholSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubTypeComment"];

  const ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc"];

  const ros_neuroPsychSubDiagAlcoholSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubSuppComment"];

  const ros_neuroPsychSubDiagAlcoholSubDeliriumVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagAlcoholSubDelirium"],
  );
  const ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubDeliriumComment"];

  const ros_neuroPsychSubDiagAlcoholSubPsychVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagAlcoholSubPsych"],
  );
  const ros_neuroPsychSubDiagAlcoholSubPsychCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubPsychComment"];

  const ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc"];

  const ros_neuroPsychSubDiagAmyotropSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagAmyotropSubSuppComment"];

  const ros_neuroPsychSubDiagAnxietySubDescCommentVal =
    formData["ros_neuroPsychSubDiagAnxietySubDescComment"];
  const ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc"];
  const ros_neuroPsychSubDiagAnxietySubTypeCommentVal =
    formData["ros_neuroPsychSubDiagAnxietySubTypeComment"];
  const ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagAnxietySubSuppCommentVal =
    formData["ros_neuroPsychSubDiagAnxietySubSuppComment"];

  const ros_neuroPsychSubDiagBipolarSubDescCommentVal =
    formData["ros_neuroPsychSubDiagBipolarSubDescComment"];
  const ros_neuroPsychSubDiagBipolarSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagBipolarSubTypeComment"];
  const ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagBipolarSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagBipolarSubSuppComment"];

  const ros_neuroPsychSubDiagCerebHemmSubDescCommentVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubDescComment"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubSuppComment"];

  const ros_neuroPsychSubDiagDementiaSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubDescComment"];
  const ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDementiaSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubSuppComment"];
  const ros_neuroPsychSubDiagDementiaSubTypeVascCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubTypeVascComment"];
  const ros_neuroPsychSubDiagDementiaSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubTypeComment"];

  const ros_neuroPsychSubDiagDrugsSubHistoryPsychVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagDrugsSubHistoryPsych"],
  );
  const ros_neuroPsychSubDiagDrugsSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubDescComment"];
  const ros_neuroPsychSubDiagDrugsSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubTypeComment"];
  const ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDrugsSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubSuppComment"];
  const ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubHistoryPsychComment"];
  const ros_neuroPsychSubDiagDrugsSubWhatDrugsVal =
    formData["ros_neuroPsychSubDiagDrugsSubWhatDrugs"];

  const ros_neuroPsychSubDiagDepressSubMajorVal =
    setOptionsSelectedYesNoUnknown(
      formData["ros_neuroPsychSubDiagDepressSubMajor"],
    );
  const ros_neuroPsychSubDiagDepressSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubDescComment"];
  const ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDepressSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubSuppComment"];
  const ros_neuroPsychSubDiagDepressSubMajorCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubMajorComment"];
  const ros_neuroPsychSubDiagDepressSubEpisodeCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubEpisodeComment"];

  const ros_neuroPsychSubDiagHemiSubDescCommentVal =
    formData["ros_neuroPsychSubDiagHemiSubDescComment"];
  const ros_neuroPsychSubDiagHemiSubHemiDescCommentVal =
    formData["ros_neuroPsychSubDiagHemiSubHemiDescComment"];
  const ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagHemiSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagHemiSubSuppComment"];

  const ros_neuroPsychSubDiagIntelSubDescCommentVal =
    formData["ros_neuroPsychSubDiagIntelSubDescComment"];
  const ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagIntelSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagIntelSubSuppComment"];
  const ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal =
    formData["ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc"];
  const ros_neuroPsychSubDiagIntelSubIntelDescCommentVal =
    formData["ros_neuroPsychSubDiagIntelSubIntelDescComment"];

  const ros_neuroPsychSubDiagSeizureSubTreatmentVal =
    formData["ros_neuroPsychSubDiagSeizureSubTreatment"];
  const ros_neuroPsychSubDiagSeizureSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSeizureSubDescComment"];
  const ros_neuroPsychSubDiagSeizureSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagSeizureSubTypeComment"];
  const ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSeizureSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSeizureSubSuppComment"];

  const ros_neuroPsychSubDiagSpinalCordSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubDescComment"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSuppComment"];
  const ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSecToComment"];

  const ros_neuroPsychSubDiagStrokeSubDescCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubDescComment"];
  const ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubComment"];
  const ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagStrokeSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppComment"];

  const ros_neuroPsychSubDiagSubduralSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubDescComment"];
  const ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubComment"];
  const ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSubduralSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppComment"];

  const ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubDescComment"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubSuppComment"];

  const ros_neuroPsychSubDiagCerebPalsySubDescCommentVal =
    formData["ros_neuroPsychSubDiagCerebPalsySubDescComment"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal =
    formData["ros_neuroPsychSubDiagCerebPalsySubSuppComment"];

  const ros_neuroPsychSubDiagDelusionSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDelusionSubDescComment"];
  const ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDelusionSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDelusionSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDelusionSubSuppComment"];

  const ros_neuroPsychSubDiagFibroSubDescCommentVal =
    formData["ros_neuroPsychSubDiagFibroSubDescComment"];
  const ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagFibroSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagFibroSubSuppComment"];

  const ros_neuroPsychSubDiagGuillSubDescCommentVal =
    formData["ros_neuroPsychSubDiagGuillSubDescComment"];
  const ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagGuillSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagGuillSubSuppComment"];

  const ros_neuroPsychSubDiagHuntingSubDescCommentVal =
    formData["ros_neuroPsychSubDiagHuntingSubDescComment"];
  const ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagHuntingSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagHuntingSubSuppComment"];

  const ros_neuroPsychSubDiagInsomniaSubDescCommentVal =
    formData["ros_neuroPsychSubDiagInsomniaSubDescComment"];
  const ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagInsomniaSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagInsomniaSubSuppComment"];

  const ros_neuroPsychSubDiagMigraineSubDescCommentVal =
    formData["ros_neuroPsychSubDiagMigraineSubDescComment"];
  const ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagMigraineSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagMigraineSubSuppComment"];

  const ros_neuroPsychSubDiagSclerosisSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSclerosisSubDescComment"];
  const ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSclerosisSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSclerosisSubSuppComment"];

  const ros_neuroPsychSubDiagDystrophySubDescCommentVal =
    formData["ros_neuroPsychSubDiagDystrophySubDescComment"];
  const ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDystrophySubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDystrophySubSuppComment"];

  const ros_neuroPsychSubDiagGravisSubDescCommentVal =
    formData["ros_neuroPsychSubDiagGravisSubDescComment"];
  const ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagGravisSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagGravisSubSuppComment"];

  const ros_neuroPsychSubDiagParkinsonSubDescCommentVal =
    formData["ros_neuroPsychSubDiagParkinsonSubDescComment"];
  const ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagParkinsonSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagParkinsonSubSuppComment"];

  const ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubDescComment"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubSuppComment"];
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagPeriNeuroSubDiabetes"],
  );
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubDiabetesComment"];

  const ros_neuroPsychSubDiagRestlessLegSubDescCommentVal =
    formData["ros_neuroPsychSubDiagRestlessLegSubDescComment"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagRestlessLegSubSuppComment"];

  const ros_neuroPsychSubDiagSchizoSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSchizoSubDescComment"];
  const ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSchizoSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSchizoSubSuppComment"];

  const ros_neuroPsychSubDiagTiaSubDescCommentVal =
    formData["ros_neuroPsychSubDiagTiaSubDescComment"];
  const ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagTiaSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagTiaSubSuppComment"];

  const ros_neuroPsychSubDiagOtherSubDescCommentVal =
    formData["ros_neuroPsychSubDiagOtherSubDescComment"];
  const ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagOtherSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagOtherSubSuppComment"];
  const ros_neuroPsychSubDiagOtherSubOtherVal =
    formData["ros_neuroPsychSubDiagOtherSubOther"];

  const gait_RecommendationSelected = formData["gait_Recommendations"];

  const gait_RecommendationsVal = setSelectedCheckboxes(
    gait_RecommendationSelected,
    ["gait_recoTakeMedicationsAsPescribedVal", "gait_recoOtherVal"],
    ["takeMedicationsAsPescribed", "other"],
  );

  const gait_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["gait_RecommendationstakeMedicationsAsPescribedComment"];

  const gait_RecommendationsotherCommentVal =
    formData["gait_RecommendationsotherComment"];

  //respi checkbox
  const respiratory_RecommendationSelected =
    formData["respiratory_Recommendations"];

  const respiratory_RecommendationsVal = setSelectedCheckboxes(
    respiratory_RecommendationSelected,
    [
      "respiratory_recoTakeMedicationsAsPescribedVal",
      "respiratory_recoOtherVal",
    ],
    ["takeMedicationsAsPescribed", "other"],
  );

  const respiratory_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["respiratory_RecommendationstakeMedicationsAsPescribedComment"];

  const respiratory_RecommendationsotherCommentVal =
    formData["respiratory_RecommendationsotherComment"];

  ///Integument checkbox//
  const integument_RecommendationSelected =
    formData["integument_Recommendations"];

  const integument_RecommendationsVal = setSelectedCheckboxes(
    integument_RecommendationSelected,
    ["integument_recoTakeMedicationsAsPescribedVal", "integument_recoOtherVal"],
    ["takeMedicationsAsPescribed", "other"],
  );

  const integument_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["integument_RecommendationstakeMedicationsAsPescribedComment"];

  const integument_RecommendationsotherCommentVal =
    formData["integument_RecommendationsotherComment"];

  //////Musko checkbox
  const musko_RecommendationSelected = formData["musko_Recommendations"];

  const musko_RecommendationsVal = setSelectedCheckboxes(
    musko_RecommendationSelected,
    [
      "musko_recoDiscussPtOtVal",
      "musko_recoTakeMedicationsAsPescribedVal",
      "musko_recoOtherVal",
    ],
    ["discussPtOt", "takeMedicationsAsPescribed", "other"],
  );
  const musko_RecommendationsdiscussPtOtCommentVal =
    formData["musko_RecommendationsdiscussPtOtComment"];

  const musko_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["musko_RecommendationstakeMedicationsAsPescribedComment"];

  const musko_RecommendationsotherCommentVal =
    formData["musko_RecommendationsotherComment"];

  ////////////HEMA CHECKBOX///
  const hema_RecommendationSelected = formData["hema_Recommendations"];

  const hema_RecommendationsVal = setSelectedCheckboxes(
    hema_RecommendationSelected,
    [
      "hema_recoTakeMedicationsAsPescribedVal",
      "hema_recoReportAbnormalBruisingVal",
      "hema_recoFollowUpWithDoctorVal",
      "hema_recoOtherVal",
    ],
    [
      "takeMedicationsAsPescribed",
      "reportAbnormalBruising",
      "followUpWithDoctor",
      "other",
    ],
  );
  const hema_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["hema_RecommendationstakeMedicationsAsPescribedComment"];

  const hema_RecommendationsreportAbnormalBruisingCommentVal =
    formData["hema_RecommendationsreportAbnormalBruisingComment"];

  const hema_RecommendationsfollowUpWithDoctorCommentVal =
    formData["hema_RecommendationsfollowUpWithDoctorComment"];

  const hema_RecommendationsotherCommentVal =
    formData["hema_RecommendationsotherComment"];

  //============================COMMMMMMM====================
  //GAIT================
  const ros_gaitComment = formData["ros_gaitComment"];

  //RESPIR=============
  const ros_respiratoryComment = formData["ros_respiratoryComment"];
  const ros_acutePulmonarySubAnswerDescribeComment =
    formData["ros_acutePulmonarySubAnswerDescribeComment"];
  const ros_respiratoryDiagnosesComment =
    formData["ros_respiratoryDiagnosesComment"];
  const ros_acutePulmonarySubAnswerSupportedByComment =
    formData["ros_acutePulmonarySubAnswerSupportedByComment"];
  const ros_acuteUpperSubAnswerDescribeComment =
    formData["ros_acuteUpperSubAnswerDescribeComment"];
  const ros_acuteUpperSubAnswerSupportedByComment =
    formData["ros_acuteUpperSubAnswerSupportedByComment"];
  const ros_asthmaSubAnswerDescribeComment =
    formData["ros_asthmaSubAnswerDescribeComment"];
  const ros_asthmaSubAnswerSupportedByComment =
    formData["ros_asthmaSubAnswerSupportedByComment"];
  const ros_asthmaPatientControllerComment =
    formData["ros_asthmaPatientControllerComment"];
  const ros_asthmaRescueMedicationsComment =
    formData["ros_asthmaRescueMedicationsComment"];
  const ros_asthmaCurrentExacerbationComment =
    formData["ros_asthmaCurrentExacerbationComment"];
  const ros_chronicPulmonaryEmbolismSubAnswerDescribeComment =
    formData["ros_chronicPulmonaryEmbolismSubAnswerDescribeComment"];
  const ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment =
    formData["ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment"];
  const ros_chronicRespiratoryFailureSubAnswerDescribeComment =
    formData["ros_chronicRespiratoryFailureSubAnswerDescribeComment"];
  const ros_chronicRespiratoryFailureSubAnswerSupportedByComment =
    formData["ros_chronicRespiratoryFailureSubAnswerSupportedByComment"];
  const ros_chronicSputumFailureSubAnswerDescribeComment =
    formData["ros_chronicSputumFailureSubAnswerDescribeComment"];
  const ros_chronicSputumFailureSubAnswerSupportedByComment =
    formData["ros_chronicSputumFailureSubAnswerSupportedByComment"];
  const ros_copdSubAnswerDescribeComment =
    formData["ros_copdSubAnswerDescribeComment"];
  const ros_copdSubAnswerSupportedByComment =
    formData["ros_copdSubAnswerSupportedByComment"];
  const ros_patientChronicBronchitisComment =
    formData["ros_patientChronicBronchitisComment"];
  const ros_patientHaveEmphysemaComment =
    formData["ros_patientHaveEmphysemaComment"];
  const ros_patientBronchodilatorComment =
    formData["ros_patientBronchodilatorComment"];
  const ros_patientSteroidsComment = formData["ros_patientSteroidsComment"];
  const ros_patientExacerbationComment =
    formData["ros_patientExacerbationComment"];
  const ros_cysticFibrosisSubAnswerDescribeComment =
    formData["ros_cysticFibrosisSubAnswerDescribeComment"];
  const ros_cysticFibrosisSubAnswerSupportedByComment =
    formData["ros_cysticFibrosisSubAnswerSupportedByComment"];
  const ros_hypoventilationSubAnswerDescribeComment =
    formData["ros_hypoventilationSubAnswerDescribeComment"];
  const ros_hypoventilationSubAnswerSupportedByComment =
    formData["ros_hypoventilationSubAnswerSupportedByComment"];
  const ros_hypoxemiaSubAnswerDescribeComment =
    formData["ros_hypoxemiaSubAnswerDescribeComment"];
  const ros_hypoxemiaSubAnswerSupportedByComment =
    formData["ros_hypoxemiaSubAnswerSupportedByComment"];
  const ros_pneumoniaSubAnswerDescribeComment =
    formData["ros_pneumoniaSubAnswerDescribeComment"];
  const pneumoniaSubAnswerSupportedByComment =
    formData["pneumoniaSubAnswerSupportedByComment"];
  const ros_pneumoniaSubAnswerEtiologyComment =
    formData["ros_pneumoniaSubAnswerEtiologyComment"];
  const ros_historyOfLungAbscessComment =
    formData["ros_historyOfLungAbscessComment"];
  const ros_historyOfEmpyemaComment = formData["ros_historyOfEmpyemaComment"];
  const ros_pulmonaryFibrosisSubAnswerDescribeComment =
    formData["ros_pulmonaryFibrosisSubAnswerDescribeComment"];
  const ros_pulmonaryFibrosisSubAnswerSupportedByComment =
    formData["ros_pulmonaryFibrosisSubAnswerSupportedByComment"];
  const ros_respiratorDependenceSubAnswerDescribeComment =
    formData["ros_respiratorDependenceSubAnswerDescribeComment"];
  const ros_respiratorDependenceSubAnswerSupportedByComment =
    formData["ros_respiratorDependenceSubAnswerSupportedByComment"];
  const ros_respiratoryArrestSubAnswerDescribeComment =
    formData["ros_respiratoryArrestSubAnswerDescribeComment"];
  const ros_respiratoryArrestSubAnswerSupportedByComment =
    formData["ros_respiratoryArrestSubAnswerSupportedByComment"];
  const ros_sarcoidosisSubAnswerDescribeComment =
    formData["ros_sarcoidosisSubAnswerDescribeComment"];
  const ros_sarcoidosisSubAnswerSupportedByComment =
    formData["ros_sarcoidosisSubAnswerSupportedByComment"];
  const ros_sarcoidosisSubAnswerSpecifyComment =
    formData["ros_sarcoidosisSubAnswerSpecifyComment"];
  const ros_sleepApneaSubAnswerDescribeComment =
    formData["ros_sleepApneaSubAnswerDescribeComment"];
  const ros_sleepApneaSubAnswerSupportedByComment =
    formData["ros_sleepApneaSubAnswerSupportedByComment"];
  const ros_otherSubAnswerDescribeComment =
    formData["ros_otherSubAnswerDescribeComment"];
  const ros_otherSubAnswerSupportedByComment =
    formData["ros_otherSubAnswerSupportedByComment"];
  const ros_otherSubAnswerOtherSubText =
    formData["ros_otherSubAnswerOtherSubText"];

  const ros_integumentComment = formData["ros_integumentComment"];
  const ros_integumentDiagnosesComment =
    formData["ros_integumentDiagnosesComment"];
  const ros_basilCellCarcinomaSubAnswerDescribeComment =
    formData["ros_basilCellCarcinomaSubAnswerDescribeComment"];
  const ros_basilCellCarcinomaSubAnswerSupportedByComment =
    formData["ros_basilCellCarcinomaSubAnswerSupportedByComment"];
  const ros_dermatitisSubAnswerDescribeComment =
    formData["ros_dermatitisSubAnswerDescribeComment"];
  const ros_dermatitisSubAnswerSupportedByComment =
    formData["ros_dermatitisSubAnswerSupportedByComment"];
  const ros_dermatitisSubAnswerWhatTypeComment =
    formData["ros_dermatitisSubAnswerWhatTypeComment"];
  const ros_eczemaSubAnswerDescribeComment =
    formData["ros_eczemaSubAnswerDescribeComment"];
  const ros_eczemaSubAnswerSupportedByComment =
    formData["ros_eczemaSubAnswerSupportedByComment"];
  const ros_onychomycosisSubAnswerDescribeComment =
    formData["ros_onychomycosisSubAnswerDescribeComment"];
  const ros_onychomycosisSubAnswerSupportedByComment =
    formData["ros_onychomycosisSubAnswerSupportedByComment"];
  const ros_psoriasisSubAnswerDescribeComment =
    formData["ros_psoriasisSubAnswerDescribeComment"];
  const ros_psoriasisSubAnswerSupportedByComment =
    formData["ros_psoriasisSubAnswerSupportedByComment"];
  const ros_psoriasisSubAnswerPsoriaticArthritisComment =
    formData["ros_psoriasisSubAnswerPsoriaticArthritisComment"];
  const ros_skinUlcerSubAnswerDescribeComment =
    formData["ros_skinUlcerSubAnswerDescribeComment"];
  const ros_skinUlcerSubAnswerSupportedByComment =
    formData["ros_skinUlcerSubAnswerSupportedByComment"];
  const ros_skinUlcerSubAnswerEtiologyComment =
    formData["ros_skinUlcerSubAnswerEtiologyComment"];
  const ros_tineaPedisSubAnswerDescribeComment =
    formData["ros_tineaPedisSubAnswerDescribeComment"];
  const ros_tineaPedisSubAnswerSupportedByComment =
    formData["ros_tineaPedisSubAnswerSupportedByComment"];
  const ros_urticarialDiseaseSubAnswerDescribeComment =
    formData["ros_urticarialDiseaseSubAnswerDescribeComment"];
  const ros_urticarialDiseaseSubAnswerEtiologySubText =
    formData["ros_urticarialDiseaseSubAnswerEtiologySubText"];
  const ros_urticarialDiseaseSubAnswerSupportedByComment =
    formData["ros_urticarialDiseaseSubAnswerSupportedByComment"];
  const ros_urticarialDiseaseSubAnswerTypeComment =
    formData["ros_urticarialDiseaseSubAnswerTypeComment"];
  const ros_woundSubAnswerDescribeComment =
    formData["ros_woundSubAnswerDescribeComment"];
  const ros_woundSubAnswerSupportedByComment =
    formData["ros_woundSubAnswerSupportedByComment"];
  const ros_woundSubAnswerEtiologyComment =
    formData["ros_woundSubAnswerEtiologyComment"];
  const ros_othersSubAnswerDescribeComment =
    formData["ros_othersSubAnswerDescribeComment"];
  const ros_othersSubAnswerSupportedByComment =
    formData["ros_othersSubAnswerSupportedByComment"];
  const ros_integumentOthersSubAnswerOtherSubText =
    formData["ros_integumentOthersSubAnswerOtherSubText"];

  const ros_musculoskeletalComment = formData["ros_musculoskeletalComment"];
  const ros_musculoskeletalDiagnosesComment =
    formData["ros_musculoskeletalDiagnosesComment"];
  const ros_collagenSubAnswerDescribeComment =
    formData["ros_collagenSubAnswerDescribeComment"];
  const ros_collagenSubAnswerSupportedByComment =
    formData["ros_collagenSubAnswerSupportedByComment"];
  const ros_collagenSubAnswercollagenDescribeComment =
    formData["ros_collagenSubAnswercollagenDescribeComment"];
  const ros_degenerativeDiscDiseaseSubAnswerDescribeComment =
    formData["ros_degenerativeDiscDiseaseSubAnswerDescribeComment"];
  const ros_degenerativeDiscDiseaseSubAnswerSupportedByComment =
    formData["ros_degenerativeDiscDiseaseSubAnswerSupportedByComment"];
  const ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment =
    formData["ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment"];
  const ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
    formData["ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment"];
  const ros_extremityFractureSubAnswerDescribeComment =
    formData["ros_extremityFractureSubAnswerDescribeComment"];
  const ros_extremityFractureSubAnswerSupportedByComment =
    formData["ros_extremityFractureSubAnswerSupportedByComment"];
  const ros_extremityFractureSubAnswerExtremityComment =
    formData["ros_extremityFractureSubAnswerExtremityComment"];
  const ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
    formData["ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment"];
  const ros_goutSubAnswerDescribeComment =
    formData["ros_goutSubAnswerDescribeComment"];
  const ros_goutSubAnswerSupportedByComment =
    formData["ros_goutSubAnswerSupportedByComment"];
  const ros_halluxValgusSubAnswerDescribeComment =
    formData["ros_halluxValgusSubAnswerDescribeComment"];
  const ros_halluxValgusSubAnswerSupportedByComment =
    formData["ros_halluxValgusSubAnswerSupportedByComment"];
  const ros_hammerToesSubAnswerDescribeComment =
    formData["ros_hammerToesSubAnswerDescribeComment"];
  const ros_hammerToesSubAnswerSupportedByComment =
    formData["ros_hammerToesSubAnswerSupportedByComment"];
  const ros_osteoarthritisSubAnswerDescribeComment =
    formData["ros_osteoarthritisSubAnswerDescribeComment"];
  const ros_osteoarthritisSubAnswerWhichJointsSubText =
    formData["ros_osteoarthritisSubAnswerWhichJointsSubText"];
  const ros_osteoarthritisSubAnswerSupportedByComment =
    formData["ros_osteoarthritisSubAnswerSupportedByComment"];
  const ros_osteomyelitisSubAnswerDescribeComment =
    formData["ros_osteomyelitisSubAnswerDescribeComment"];
  const ros_osteomyelitisSubAnswerSupportedByComment =
    formData["ros_osteomyelitisSubAnswerSupportedByComment"];
  const ros_osteoporosisSubAnswerDescribeComment =
    formData["ros_osteoporosisSubAnswerDescribeComment"];
  const ros_osteoporosisSubAnswerSupportedByComment =
    formData["ros_osteoporosisSubAnswerSupportedByComment"];
  const ros_pyogenicArthritisSubAnswerDescribeComment =
    formData["ros_pyogenicArthritisSubAnswerDescribeComment"];
  const ros_pyogenicArthritisSubAnswerWhichJointsSubText =
    formData["ros_pyogenicArthritisSubAnswerWhichJointsSubText"];
  const ros_pyogenicArthritisSubAnswerSupportedByComment =
    formData["ros_pyogenicArthritisSubAnswerSupportedByComment"];
  const ros_rheumatoidArthritisSubAnswerDescribeComment =
    formData["ros_rheumatoidArthritisSubAnswerDescribeComment"];
  const ros_rheumatoidArthritisSubAnswerWhichJointsSubText =
    formData["ros_rheumatoidArthritisSubAnswerWhichJointsSubText"];
  const ros_rheumatoidArthritisSubAnswerSupportedByComment =
    formData["ros_rheumatoidArthritisSubAnswerSupportedByComment"];
  const ros_spinalStenosisSubAnswerDescribeComment =
    formData["ros_spinalStenosisSubAnswerDescribeComment"];
  const ros_spinalStenosisSubAnswerSupportedByComment =
    formData["ros_spinalStenosisSubAnswerSupportedByComment"];
  const ros_spinalStenosisSubAnswerNormalBladderComment =
    formData["ros_spinalStenosisSubAnswerNormalBladderComment"];
  const ros_systemicLupusSubAnswerDescribeComment =
    formData["ros_systemicLupusSubAnswerDescribeComment"];
  const ros_systemicLupusSubAnswerSupportedByComment =
    formData["ros_systemicLupusSubAnswerSupportedByComment"];
  const ros_muskoOtherSubAnswerDescribeComment =
    formData["ros_muskoOtherSubAnswerDescribeComment"];
  const ros_muskoOtherSubAnswerSupportedByComment =
    formData["ros_muskoOtherSubAnswerSupportedByComment"];
  const ros_muskoOtherSubAnswerotherSubText =
    formData["ros_muskoOtherSubAnswerotherSubText"];
  const ros_hadAnAmputationSubAnswerSubText =
    formData["ros_hadAnAmputationSubAnswerSubText"];

  const ros_hematologyComment = formData["ros_hematologyComment"];
  const ros_hematologyDiagnosesComment =
    formData["ros_hematologyDiagnosesComment"];
  const ros_aidsSubAnswerDescribeComment =
    formData["ros_aidsSubAnswerDescribeComment"];
  const ros_aidsSubAnswerSupportedByComment =
    formData["ros_aidsSubAnswerSupportedByComment"];
  const ros_aidsSubAnswerPatientUnderTreatmentComment =
    formData["ros_aidsSubAnswerPatientUnderTreatmentComment"];
  const ros_anemiaSubAnswerDescribeComment =
    formData["ros_anemiaSubAnswerDescribeComment"];
  const ros_anemiaSubAnswerSupportedByComment =
    formData["ros_anemiaSubAnswerSupportedByComment"];
  const ros_anemiaSubAnswerEtiologyComment =
    formData["ros_anemiaSubAnswerEtiologyComment"];
  const ros_anemiaSubAnswerYesPatientOnComment =
    formData["ros_anemiaSubAnswerYesPatientOnComment"];
  const ros_cDifficileSubAnswerDescribeComment =
    formData["ros_cDifficileSubAnswerDescribeComment"];
  const ros_cDifficileSubAnswerSupportedByComment =
    formData["ros_cDifficileSubAnswerSupportedByComment"];
  const ros_communityAcquiredSubAnswerDescribeComment =
    formData["ros_communityAcquiredSubAnswerDescribeComment"];
  const ros_communityAcquiredSubAnswerSupportedByComment =
    formData["ros_communityAcquiredSubAnswerSupportedByComment"];
  const ros_hivSubAnswerDescribeComment =
    formData["ros_hivSubAnswerDescribeComment"];
  const ros_hivSubAnswerViralLoadSubText =
    formData["ros_hivSubAnswerViralLoadSubText"];
  const ros_hivSubAnswerCFourSubText = formData["ros_hivSubAnswerCFourSubText"];
  const ros_hivSubAnswerSupportedByComment =
    formData["ros_hivSubAnswerSupportedByComment"];
  const ros_hivSubAnswerPatientSymptomaticComment =
    formData["ros_hivSubAnswerPatientSymptomaticComment"];
  const ros_hivSubAnswerActiveTreatmentComment =
    formData["ros_hivSubAnswerActiveTreatmentComment"];
  const ros_herpesZosterSubAnswerDescribeComment =
    formData["ros_herpesZosterSubAnswerDescribeComment"];
  const ros_herpesZosterSubAnswerSupportedByComment =
    formData["ros_herpesZosterSubAnswerSupportedByComment"];
  const ros_mrsaInfectionSubAnswerDescribeComment =
    formData["ros_mrsaInfectionSubAnswerDescribeComment"];
  const ros_mrsaInfectionSubAnswerSupportedByComment =
    formData["ros_mrsaInfectionSubAnswerSupportedByComment"];
  const ros_immuneDeficiencySubAnswerDescribeComment =
    formData["ros_immuneDeficiencySubAnswerDescribeComment"];
  const ros_immuneDeficiencySubAnswerEtiologySubText =
    formData["ros_immuneDeficiencySubAnswerEtiologySubText"];
  const ros_immuneDeficiencySubAnswerSupportedByComment =
    formData["ros_immuneDeficiencySubAnswerSupportedByComment"];
  const ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment =
    formData["ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment"];
  const ros_sepsisSubAnswerDescribeComment =
    formData["ros_sepsisSubAnswerDescribeComment"];
  const ros_sepsisSubAnswerSupportedByComment =
    formData["ros_sepsisSubAnswerSupportedByComment"];
  const ros_sickleCellDiseaseTwoSubAnswerDescribeComment =
    formData["ros_sickleCellDiseaseTwoSubAnswerDescribeComment"];
  const ros_sickleCellDiseaseTwoSubAnswerSupportedByComment =
    formData["ros_sickleCellDiseaseTwoSubAnswerSupportedByComment"];
  const ros_sickleCellTraitSubAnswerDescribeComment =
    formData["ros_sickleCellTraitSubAnswerDescribeComment"];
  const ros_sickleCellTraitSubAnswerSupportedByComment =
    formData["ros_sickleCellTraitSubAnswerSupportedByComment"];
  const ros_thalassemiaSubAnswerDescribeComment =
    formData["ros_thalassemiaSubAnswerDescribeComment"];
  const ros_thalassemiaSubAnswerSupportedByComment =
    formData["ros_thalassemiaSubAnswerSupportedByComment"];
  const ros_thrombocytopeniaSubAnswerDescribeComment =
    formData["ros_thrombocytopeniaSubAnswerDescribeComment"];
  const ros_thrombocytopeniaSubAnswerEtiologySubText =
    formData["ros_thrombocytopeniaSubAnswerEtiologySubText"];
  const ros_thrombocytopeniaSubAnswerSupportedByComment =
    formData["ros_thrombocytopeniaSubAnswerSupportedByComment"];
  const ros_tuberculosisSubAnswerDescribeComment =
    formData["ros_tuberculosisSubAnswerDescribeComment"];
  const ros_tuberculosisSubAnswerSupportedByComment =
    formData["ros_tuberculosisSubAnswerSupportedByComment"];
  const ros_tuberculosisSubAnswerGivenBCGComment =
    formData["ros_tuberculosisSubAnswerGivenBCGComment"];
  const ros_tuberculosisSubAnswerActiveTuberculosisComment =
    formData["ros_tuberculosisSubAnswerActiveTuberculosisComment"];
  const ros_tuberculosisSubAnswerTbInfectionComment =
    formData["ros_tuberculosisSubAnswerTbInfectionComment"];
  const ros_vitaminDDeficiencySubAnswerSupportedByComment =
    formData["ros_vitaminDDeficiencySubAnswerSupportedByComment"];
  const ros_hemaOtherSubAnswerDescribeComment =
    formData["ros_hemaOtherSubAnswerDescribeComment"];
  const ros_hemaOtherSubAnswerSupportedByComment =
    formData["ros_hemaOtherSubAnswerSupportedByComment"];
  const ros_hemaOtherSubAnswerOtherSubText =
    formData["ros_hemaOtherSubAnswerOtherSubText"];

  const ros_muskoHadAnAmputationVal = setOptionsSelectedYesNo(
    formData["ros_muskoHadAnAmputation"],
  );
  const ros_asthmaPatientControllerVal = setOptionsSelectedYesNo(
    formData["ros_asthmaPatientController"],
  );
  const ros_asthmaRescueMedicationsVal = setOptionsSelectedYesNo(
    formData["ros_asthmaRescueMedications"],
  );
  const ros_asthmaCurrentExacerbationVal = setOptionsSelectedYesNo(
    formData["ros_asthmaCurrentExacerbation"],
  );
  const ros_patientChronicBronchitisVal = setOptionsSelectedYesNo(
    formData["ros_patientChronicBronchitis"],
  );
  const ros_patientHaveEmphysemaVal = setOptionsSelectedYesNo(
    formData["ros_patientHaveEmphysema"],
  );
  const ros_patientBronchodilatorVal = setOptionsSelectedYesNo(
    formData["ros_patientBronchodilator"],
  );
  const ros_patientSteroidsVal = setOptionsSelectedYesNo(
    formData["ros_patientSteroids"],
  );
  const ros_patientExacerbationVal = setOptionsSelectedYesNo(
    formData["ros_patientExacerbation"],
  );
  const ros_historyOfLungAbscessVal = setOptionsSelectedYesNo(
    formData["ros_historyOfLungAbscess"],
  );
  const ros_historyOfEmpyemaVal = setOptionsSelectedYesNo(
    formData["ros_historyOfEmpyema"],
  );
  const ros_psoriasisSubAnswerPsoriaticArthritisVal = setOptionsSelectedYesNo(
    formData["ros_psoriasisSubAnswerPsoriaticArthritis"],
  );
  const ros_aidsSubAnswerPatientUnderTreatmentVal = setOptionsSelectedYesNo(
    formData["ros_aidsSubAnswerPatientUnderTreatment"],
  );
  const ros_hivSubAnswerPatientSymptomaticVal = setOptionsSelectedYesNo(
    formData["ros_hivSubAnswerPatientSymptomatic"],
  );
  const ros_hivSubAnswerActiveTreatmentVal = setOptionsSelectedYesNo(
    formData["ros_hivSubAnswerActiveTreatment"],
  );
  const ros_immuneDeficiencySubAnswerHistoryOfOpportunisticVal =
    setOptionsSelectedYesNo(
      formData["ros_immuneDeficiencySubAnswerHistoryOfOpportunistic"],
    );
  const ros_tuberculosisSubAnswerGivenBCGVal = setOptionsSelectedYesNo(
    formData["ros_tuberculosisSubAnswerGivenBCG"],
  );
  const ros_tuberculosisSubAnswerActiveTuberculosisVal =
    setOptionsSelectedYesNo(
      formData["ros_tuberculosisSubAnswerActiveTuberculosis"],
    );
  const ros_tuberculosisSubAnswerTbInfectionVal = setOptionsSelectedYesNo(
    formData["ros_tuberculosisSubAnswerTbInfection"],
  );
  const ros_degenerativeDiscDiseaseSubAnswerNormalBladderVal =
    setOptionsSelectedYesNo(
      formData["ros_degenerativeDiscDiseaseSubAnswerNormalBladder"],
    );
  const ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerVal =
    setOptionsSelectedYesNo(
      formData["ros_extremityFractureCurrentWithinTwelveWeeksSubAnswer"],
    );
  const ros_spinalStenosisSubAnswerNormalBladderVal = setOptionsSelectedYesNo(
    formData["ros_spinalStenosisSubAnswerNormalBladder"],
  );
  const ros_respiratoryVal = setOptionsSelectedYesNo(
    formData["ros_respiratory"],
  );
  const ros_integumentVal = setOptionsSelectedYesNo(formData["ros_integument"]);
  const ros_musculoskeletalVal = setOptionsSelectedYesNo(
    formData["ros_musculoskeletal"],
  );
  const ros_hematologyVal = setOptionsSelectedYesNo(formData["ros_hematology"]);

  const ros_cancerRecommendationsSelected =
    formData["ros_cancerRecommendations"];

  const ros_cancerRecommendations = setSelectedCheckboxes(
    ros_cancerRecommendationsSelected,
    ["ros_takemedicationsasprescribedCancerVal", "ros_otherCancerVal"],
    ["takemedicationsasprescribedCancer", "otherCancer"],
  );

  const gastro_recommendationsSelected = formData["gastro_recommendations"];

  const ros_gastroSubDiagnosesceliacDiseaseGlueten = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnosesceliacDiseaseGlueten"],
  );

  const gastro_recommendations = setSelectedCheckboxes(
    gastro_recommendationsSelected,
    ["ros_takeMedicationsAsPescribedGastroVal", "ros_otherGastroVal"],
    ["takemedicationsasprescribed", "other"],
  );

  const endocrine_recommendationsSelected =
    formData["endocrine_recommendations"];

  const endocrine_recommendations = setSelectedCheckboxes(
    endocrine_recommendationsSelected,
    [
      "ros_takeMedicationsAsPescribedEndocrineVal",
      "ros_checkBloodSugarEndorineVal",
      "ros_otherEndocrineVal",
    ],
    ["takeMedicationsAsPescribed", "checkBloodSugar", "other"],
  );

  const genito_recommendationsSelected = formData["ros_genitoRecommendations"];

  const ros_genitoRecommendations = setSelectedCheckboxes(
    genito_recommendationsSelected,
    ["ros_takeMedicationsAsPescribedGenitoVal", "ros_otherGenitoVal"],
    ["takeMedicationsAsPrescribedGenito", "otherGenito"],
  );

  const ros_diagnosisOfCancerSubDescComment =
    formData["ros_diagnosisOfCancerSubDescComment"];
  const ros_diagnosisOfCancerSubSupComment =
    formData["ros_diagnosisOfCancerSubSupComment"];
  const ros_diagnosisOfCancerSubTypeComment =
    formData["ros_diagnosisOfCancerSubTypeComment"];

  const ros_diagnosisOfCancerSubActiveTreatComment =
    formData["ros_diagnosisOfCancerSubActiveTreatComment"];
  const ros_diagnosisOfCancerSubSideEffComment =
    formData["ros_diagnosisOfCancerSubSideEffComment"];

  const ros_activeTreatmentComment = formData["ros_activeTreatmentComment"];

  const ros_SeeASpecComment = formData["ros_SeeASpecComment"];

  const ros_diagnosisOfCancerSubMetastasisCachexiaValComment =
    formData["ros_diagnosisOfCancerSubMetastasisCachexiaValComment"];
  const ros_MetastasisComment = formData["ros_MetastasisComment"];

  const ros_cancerRecommendationstakemedicationsasprescribedCancerComment =
    formData[
      "ros_cancerRecommendationstakemedicationsasprescribedCancerComment"
    ];

  const ros_cancerRecommendationsotherCancerComment =
    formData["ros_cancerRecommendationsotherCancerComment"];

  const ros_diagnosisOfCancerVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancer"],
  );

  const ros_diagnosisOfCancerSubActiveTreatVal = setOptionsSelectedYesNo(
    formData["ros_activeTreatment"],
  );

  const ros_MetastasisVal = setOptionsSelectedYesNo(formData["ros_Metastasis"]);

  const ros_SeeASpecVal = setOptionsSelectedYesNo(formData["ros_SeeASpec"]);

  const ros_MetastasisCachexiaVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerSubMetastasisCachexiaVal"],
  );

  const ros_MetastasisLocVal =
    formData["ros_diagnosisOfCancerSubMetastasisLocVal"];

  const ros_SpecProVal = formData["ros_diagnosisOfCancerSubSeeASpecProVal"];

  const ros_diagnosisOfCancerCommentVal =
    formData["ros_diagnosisOfCancerComment"];

  const specialTypes = formData["specialTypes"];

  const stageSpecificToTheCancer = formData["stageSpecificToTheCancer"];

  const ros_cardioVal = setOptionsSelectedYesNo(formData["ros_cardio"]);
  const ros_cardioComment = formData["ros_cardioComment"];

  const ros_cardiovascularRecommendationsSelected =
    formData["ros_cardiovascularRecommendations"];

  const ros_gastroSubDiagnosescolonCrDesComment =
    formData["ros_gastroSubDiagnosescolonCrDesComment"];
  const ros_gastroSubDiagnosesdescolonComment =
    formData["ros_gastroSubDiagnosesdescolonComment"];
  const ros_gastroSubDiagnosescolonSupComment =
    formData["ros_gastroSubDiagnosescolonSupComment"];

  const ros_cardiovascularRecommendations = setSelectedCheckboxes(
    ros_cardiovascularRecommendationsSelected,
    [
      "ros_takemedicationsasprescribedCardioVal",
      "ros_otherCardioVal",
      "ros_ThirtyCardioVal",
      "ros_bloodPreCardioVal",
      "ros_heartHeCardioVal",
    ],
    [
      "takeMedicationsAsPescribed",
      "other",
      "exerciseThirtyMinADay",
      "bloodPressureChecks",
      "heartHealthyDiet",
    ],
  );

  const ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment =
    formData[
      "ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment"
    ];

  const ros_cardiovascularRecommendationsotherComment =
    formData["ros_cardiovascularRecommendationsotherComment"];
  const ros_cardiovascularRecommendationsexerciseThirtyMinADayComment =
    formData["ros_cardiovascularRecommendationsexerciseThirtyMinADayComment"];
  const ros_cardiovascularRecommendationsbloodPressureChecksComment =
    formData["ros_cardiovascularRecommendationsbloodPressureChecksComment"];
  const ros_cardiovascularRecommendationsheartHealthyDietComment =
    formData["ros_cardiovascularRecommendationsheartHealthyDietComment"];

  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDesComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmDesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest"],
    );

  const ros_endoVal = setOptionsSelectedYesNo(formData["ros_endoVal"]);
  const ros_gastroVal = setOptionsSelectedYesNo(formData["ros_gastroVal"]);
  const ros_genitoVal = setOptionsSelectedYesNo(formData["ros_genitoVal"]);

  const ros_endoValComment = formData["ros_endoValComment"];
  const ros_gastroValComment = formData["ros_gastroValComment"];
  const ros_genitoValComment = formData["ros_genitoValComment"];

  const ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment =
    formData[
      "ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment"
    ];

  const ros_genitoRecommendationsotherGenitoComment =
    formData["ros_genitoRecommendationsotherGenitoComment"];

  const endocrine_recommendationstakeMedicationsAsPescribedComment =
    formData["endocrine_recommendationstakeMedicationsAsPescribedComment"];

  const endocrine_recommendationscheckBloodSugarComment =
    formData["endocrine_recommendationscheckBloodSugarComment"];

  const endocrine_recommendationsotherComment =
    formData["endocrine_recommendationsotherComment"];

  const gastro_recommendationstakemedicationsasprescribedComment =
    formData["gastro_recommendationstakemedicationsasprescribedComment"];

  const gastro_recommendationsotherComment =
    formData["gastro_recommendationsotherComment"];

  const ros_gastroSubDiagnosesComment =
    formData["ros_gastroSubDiagnosesComment"];
  const ros_gastroSubDiagnosescachexiadesComment =
    formData["ros_gastroSubDiagnosescachexiadesComment"];
  const ros_gastroSubDiagnosescachexiaSupComment =
    formData["ros_gastroSubDiagnosescachexiaSupComment"];

  const ros_genitoSubDiagnosesComment =
    formData["ros_genitoSubDiagnosesComment"];
  const ros_endoSubDiagnosesComment = formData["ros_endoSubDiagnosesComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureEti =
    formData["ros_genitoSubDiagnosesacuteRenalFailureEti"];

  const ros_genitoSubDiagnosesacuteRenalFailuredesComment =
    formData["ros_genitoSubDiagnosesacuteRenalFailuredesComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupComment =
    formData["ros_genitoSubDiagnosesacuteRenalFailureSupComment"];

  const ros_gastroSubDiagnosesbowelObstructionSupComment =
    formData["ros_gastroSubDiagnosesbowelObstructionSupComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionComment =
    formData["ros_gastroSubDiagnosesdesbowelObstructionComment"];

  const ros_cardioSubDiagnosesAneurysmDesComment =
    formData["ros_cardioSubDiagnosesAneurysmDesComment"];

  const ros_cardioSubDiagnosesComment =
    formData["ros_cardioSubDiagnosesComment"];

  const ros_gastroSubDiagnosesceliacDiseaseGluetenComment =
    formData["ros_gastroSubDiagnosesceliacDiseaseGluetenComment"];
  const ros_gastroSubDiagnosesceliacDiseaseSupComment =
    formData["ros_gastroSubDiagnosesceliacDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseComment =
    formData["ros_gastroSubDiagnosesdesceliacDiseaseComment"];

  const ros_gastroSubDiagnosescirrhosisEndStage = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnosescirrhosisEndStage"],
  );
  const ros_gastroSubDiagnosescirrhosisEndStageComment =
    formData["ros_gastroSubDiagnosescirrhosisEndStageComment"];

  const ros_gastroSubDiagnosesdescirrhosisComment =
    formData["ros_gastroSubDiagnosesdescirrhosisComment"];
  const ros_gastroSubDiagnosescirrhosisSupComment =
    formData["ros_gastroSubDiagnosescirrhosisSupComment"];
  const ros_gastroSubDiagnosescolonPolypsDesComment =
    formData["ros_gastroSubDiagnosescolonPolypsDesComment"];

  const ros_cardioSubDiagnosesAneurysmCrDesComment =
    formData["ros_cardioSubDiagnosesAneurysmCrDesComment"];
  const ros_cardioSubDiagnosesAneurysmSupComment =
    formData["ros_cardioSubDiagnosesAneurysmSupComment"];

  const ros_cardioSubDiagnosesAnginaCrDesComment =
    formData["ros_cardioSubDiagnosesAnginaCrDesComment"];
  const ros_cardioSubDiagnosesAnginaSupComment =
    formData["ros_cardioSubDiagnosesAnginaSupComment"];
  const ros_cardioSubDiagnosesAnginaDesComment =
    formData["ros_cardioSubDiagnosesAnginaDesComment"];

  const ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationTypeComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationTypeComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationDesComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationDesComment"];

  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension"
    ];

  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension"
    ];

  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment"];

  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker"];

  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment"
    ];

  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistent =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistent"],
    );

  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment"];

  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin"],
    );

  const ros_cardioSubDiagnosesHypertensionadequatelyControlled =
    setOptionsSelectedYesNoPartially(
      formData["ros_cardioSubDiagnosesHypertensionadequatelyControlled"],
    );
  tempData = {
    ...tempData,
    ros_eyesVal,
    ros_eyesCommentVal,
    ros_eyesDiagnosesCommentVal,
    ros_eyesDiagCataractSubWhEyeCommentVal,
    ros_eyesDiagCataractSubDescCommentVal,
    ros_eyesDiagCataractSubSuppCommentVal,
    ros_eyesDiagCataractSubSuppSympSubCommentVal,
    ros_eyesDiagCataractSubSuppOtherSubDescVal,
    ros_eyesDiagCataractSubDiabetesVal,
    ros_eyesDiagCataractSubDiabetesCommentVal,
    ros_eyesDiagGlaucomaSubWhEyeCommentVal,
    ros_eyesDiagGlaucomaSubDescCommentVal,
    ros_eyesDiagGlaucomaSubSuppCommentVal,
    ros_eyesDiagGlaucomaSubSuppSympSubCommentVal,
    ros_eyesDiagGlaucomaSubSuppOtherSubDescVal,
    ros_eyesDiagGlaucomaSubDiabetesVal,
    ros_eyesDiagGlaucomaSubDiabetesCommentVal,
    ros_eyesDiagGlaucomaSubDiabetesSubCommentVal,
    ros_eyesDiagHyperopiaSubWhEyeCommentVal,
    ros_eyesDiagHyperopiaSubDescCommentVal,
    ros_eyesDiagHyperopiaSubSuppCommentVal,
    ros_eyesDiagHyperopiaSubSuppSympSubCommentVal,
    ros_eyesDiagHyperopiaSubSuppOtherSubDescVal,
    ros_eyesDiagHyperopiaSubDescVal,
    ros_eyesDiagLegalBlindSubWhEyeCommentVal,
    ros_eyesDiagLegalBlindSubDescCommentVal,
    ros_eyesDiagLegalBlindSubSuppCommentVal,
    ros_eyesDiagLegalBlindSubSuppSympSubCommentVal,
    ros_eyesDiagLegalBlindSubSuppOtherSubDescVal,
    ros_eyesDiagLegalBlindSubCommentVal,
    ros_eyesDiagLegalBlindSubDescVal,
    ros_eyesDiagMascDegenSubWhEyeCommentVal,
    ros_eyesDiagMascDegenSubDescCommentVal,
    ros_eyesDiagMascDegenSubSuppCommentVal,
    ros_eyesDiagMascDegenSubSuppSympSubCommentVal,
    ros_eyesDiagMascDegenSubSuppOtherSubDescVal,
    ros_eyesDiagMascDegenSubCommentVal,
    ros_eyesDiagMascDegenSubDescVal,
    ros_eyesDiagMyopiaSubWhEyeCommentVal,
    ros_eyesDiagMyopiaSubDescCommentVal,
    ros_eyesDiagMyopiaSubSuppCommentVal,
    ros_eyesDiagMyopiaSubSuppSympSubCommentVal,
    ros_eyesDiagMyopiaSubSuppOtherSubDescVal,
    ros_eyesDiagMyopiaSubDescVal,
    ros_eyesDiagRetinalSubWhEyeCommentVal,
    ros_eyesDiagRetinalSubDescCommentVal,
    ros_eyesDiagRetinalSubSuppCommentVal,
    ros_eyesDiagRetinalSubSuppSympSubCommentVal,
    ros_eyesDiagRetinalSubSuppOtherSubDescVal,
    ros_eyesDiagRetinalSubDiabetesVal,
    ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal,
    ros_eyesDiagRetinalSubDiabetesSubCommentVal,
    ros_eyesDiagRetinalSubDiabetesCommentVal,
    ros_eyesDiagOtherSubWhEyeCommentVal,
    ros_eyesDiagOtherSubDescCommentVal,
    ros_eyesDiagOtherSubSuppCommentVal,
    ros_eyesDiagOtherSubSuppSympSubCommentVal,
    ros_eyesDiagOtherSubSuppOtherSubDescVal,
    ros_eyesDiagOtherSubOtherVal,
    ros_earsVal,
    ros_earsCommentVal,
    ros_earsDiagnosesCommentVal,
    ros_earsDiagDiffHearSubSuppOtherSubDescVal,
    ros_earsDiagDiffHearSubSuppCommentVal,
    ros_earsDiagLegalDeafSubSuppOtherSubDescVal,
    ros_earsDiagLegalDeafSubSuppCommentVal,
    ros_earsDiagTinnitusSubSuppOtherSubDescVal,
    ros_earsDiagTinnitusSubSuppCommentVal,
    ros_earsDiagVertigoSubSuppOtherSubDescVal,
    ros_earsDiagVertigoSubSuppCommentVal,
    ros_earsDiagVertigoSubLoseBalVal,
    ros_earsDiagVertigoSubLoseBalCommentVal,
    ros_earsDiagOtherSubSuppOtherSubDescVal,
    ros_earsDiagOtherSubSuppCommentVal,
    ros_earsDiagOtherSubOtherVal,
    ros_noseVal,
    ros_noseDiagCommentVal,
    ros_noseCommentVal,
    ros_noseDiagAllergySubSuppOtherSubDescVal,
    ros_noseDiagAllergySubSuppCommentVal,
    ros_noseDiagChronicNasalSubSuppOtherSubDescVal,
    ros_noseDiagChronicNasalSubSuppCommentVal,
    ros_noseDiagNoseBleedSubCommentVal,
    ros_noseDiagSeasonAllergySubSuppOtherSubDescVal,
    ros_noseDiagSeasonAllergySubSuppCommentVal,
    ros_noseDiagSinusInfectSubSuppOtherSubDescVal,
    ros_noseDiagSinusInfectSubSuppCommentVal,
    ros_noseDiagSinusInfectSubExuCommentVal,
    ros_noseDiagSinustisSubSuppOtherSubDescVal,
    ros_noseDiagSinustisSubSuppCommentVal,
    ros_noseDiagOtherSubSuppOtherSubDescVal,
    ros_noseDiagOtherSubSuppCommentVal,
    ros_noseDiagOtherSubOtherVal,
    ros_mouthVal,
    ros_mouthDiagCommentVal,
    ros_mouthCommentVal,
    ros_mouthDiagBleedGumsSubCommentVal,
    ros_mouthDiagDiffChewSubDescCommentVal,
    ros_mouthDiagDiffChewSubBcsPainVal,
    ros_mouthDiagDiffChewSubBcsPainSubVal,
    ros_mouthDiagDiffChewSubBcsPainSubCommentVal,
    ros_mouthDiagDiffChewSubBcsPainCommentVal,
    ros_mouthDiagDiffSwallowSubHadStrokeVal,
    ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal,
    ros_mouthDiagDiffSwallowSubHadStrokeSubCommentVal,
    ros_mouthDiagDiffSwallowSubHadStrokeCommentVal,
    ros_mouthDiagOtherSubSuppOtherSubDescVal,
    ros_mouthDiagOtherSubSuppCommentVal,
    ros_mouthDiagOtherSubOtherVal,
    ros_neckVal,
    ros_neckDiagCommentVal,
    ros_neckCommentVal,
    ros_neckDiagCarotidSubDescCommentVal,
    ros_neckDiagCarotidSubSuppOtherSubDescVal,
    ros_neckDiagCarotidSubSuppCommentVal,
    ros_neckDiagCarotidCsDescCommentVal,
    ros_neckDiagParotidSubSuppOtherSubDescVal,
    ros_neckDiagParotidSubSuppCommentVal,
    ros_neckDiagParotidSubDescCommentVal,
    ros_neckDiagOtherSubDescCommentVal,
    ros_neckDiagOtherSubSuppOtherSubDescVal,
    ros_neckDiagOtherSubSuppCommentVal,
    ros_neckDiagOtherSubOtherVal,
    ros_eyeENTNeckRecommendationsVal,
    ros_eyeENTRecoHearEvalCommentVal,
    ros_eyeENTRecoDentalExamCommentVal,
    ros_eyeENTRecoEyeExamCommentVal,
    ros_eyeENTRecoSwalEvalyCommentVal,
    ros_eyeENTRecoTakeMedCommentVal,
    ros_eyeENTRecoFoodDispCommentVal,
    ros_eyeENTRecoOtherCommentVal,
    ros_bowelMoveAbnormDiarrIshisVal,
    ros_bowelMoveAbnormDiarrIshisCommentVal,
    ros_bowelMoveCommentVal,
    ros_bowelMoveAbnormCommentVal,
    ros_bowelMoveAbnormConstCommentVal,
    ros_bowelMoveAbnormDiarrIsdiaCommentVal,
    ros_abdominalIsabdVal,
    ros_abdominalIsyesCommentVal,
    ros_abdominalCommentVal,
    ros_rectalIsrecVal,
    ros_rectalIsrecIsyesmalCommentVal,
    ros_rectalIsrecIsyesfemCommentVal,
    ros_rectalCommentVal,
    ros_lastBowelCommentVal,
    ros_meetWithCommentVal,
    ros_gpcogScoIsgpcogsVal,
    ros_gpcogScoIsmmsescVal,
    ros_gpcogScoCommentVal,
    ros_noGpcogMmsePatientOrientPersonVal,
    ros_noGpcogMmsePatientOrientPlaceVal,
    ros_noGpcogMmsePatientOrientTimeVal,
    ros_noGpcogMmsePatientDescribeNewsVal,
    ros_noGpcogMmseRecallCommentVal,
    ros_noGpcogMmsePatientDescribeNewsCommentVal,
    ros_noGpcogMmsePatientOrientPersonCommentVal,
    ros_noGpcogMmsePatientOrientPlaceCommentVal,
    ros_noGpcogMmsePatientOrientTimeCommentVal,
    ros_speechCommentVal,
    ros_fingerToNoseAbnormalSubCommentVal,
    ros_fingerToNoseCommentVal,
    ros_heelToToeAbnormalSubCommentVal,
    ros_heelToToeCommentVal,
    ros_thumbToFingerAbnormalSubCommentVal,
    ros_thumbToFingerCommentVal,
    ros_sitToStandCommentVal,
    ros_extremeMoveCommentVal,
    ros_affectCommentVal,
    ros_affectSubCommentVal,
    ros_over2WeeksLittleIntrstCommentVal,
    ros_over2WeeksFeelDepressedCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal,
    ros_neuroPsychVal,
    ros_neuroPsychCommentVal,
    ros_neuroPsychNervVal,
    ros_neuroPsychNervCommentVal,
    ros_neuroPsychWorryVal,
    ros_neuroPsychWorryCommentVal,
    ros_neuroPsychAfraidVal,
    ros_neuroPsychAfraidCommentVal,
    ros_neuroPsychSubAuditoryVal,
    ros_neuroPsychSubAuditoryCommentVal,
    ros_neuroPsychSubVisualVal,
    ros_neuroPsychSubVisualCommentVal,
    ros_neuroPsychSubEpisodesBehavVal,
    ros_neuroPsychSubEpisodesBehavCommentVal,
    ros_neuroPsychSubEpisodesDeliriumVal,
    ros_neuroPsychSubEpisodesDeliriumCommentVal,
    ros_neuroPsychSubHearVoicesVal,
    ros_neuroPsychSubHearVoicesCommentVal,
    ros_neuroPsychSubHighLowsVal,
    ros_neuroPsychSubHighLowsCommentVal,
    ros_neuroPsychSubEverFeelVal,
    ros_neuroPsychSubEverFeelCommentVal,
    ros_neuroPsychSubDiagCommentVal,
    ros_neuroPsychSubDiagAlcoholSubDescCommentVal,
    ros_neuroPsychSubDiagAlcoholSubTypeCommentVal,
    ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagAlcoholSubSuppCommentVal,
    ros_neuroPsychSubDiagAlcoholSubDeliriumVal,
    ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal,
    ros_neuroPsychSubDiagAlcoholSubPsychVal,
    ros_neuroPsychSubDiagAlcoholSubPsychCommentVal,
    ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagAmyotropSubSuppCommentVal,
    ros_neuroPsychSubDiagAnxietySubDescCommentVal,
    ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal,
    ros_neuroPsychSubDiagAnxietySubTypeCommentVal,
    ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagAnxietySubSuppCommentVal,
    ros_neuroPsychSubDiagBipolarSubDescCommentVal,
    ros_neuroPsychSubDiagBipolarSubTypeCommentVal,
    ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagBipolarSubSuppCommentVal,
    ros_neuroPsychSubDiagCerebHemmSubDescCommentVal,
    ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal,
    ros_neuroPsychSubDiagDementiaSubDescCommentVal,
    ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDementiaSubSuppCommentVal,
    ros_neuroPsychSubDiagDementiaSubTypeVascCommentVal,
    ros_neuroPsychSubDiagDementiaSubTypeCommentVal,
    ros_neuroPsychSubDiagDrugsSubHistoryPsychVal,
    ros_neuroPsychSubDiagDrugsSubDescCommentVal,
    ros_neuroPsychSubDiagDrugsSubTypeCommentVal,
    ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDrugsSubSuppCommentVal,
    ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal,
    ros_neuroPsychSubDiagDrugsSubWhatDrugsVal,
    ros_neuroPsychSubDiagDepressSubMajorVal,
    ros_neuroPsychSubDiagDepressSubDescCommentVal,
    ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDepressSubSuppCommentVal,
    ros_neuroPsychSubDiagDepressSubMajorCommentVal,
    ros_neuroPsychSubDiagDepressSubEpisodeCommentVal,
    ros_neuroPsychSubDiagHemiSubDescCommentVal,
    ros_neuroPsychSubDiagHemiSubHemiDescCommentVal,
    ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagHemiSubSuppCommentVal,
    ros_neuroPsychSubDiagIntelSubDescCommentVal,
    ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagIntelSubSuppCommentVal,
    ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal,
    ros_neuroPsychSubDiagIntelSubIntelDescCommentVal,
    ros_neuroPsychSubDiagSeizureSubTreatmentVal,
    ros_neuroPsychSubDiagSeizureSubDescCommentVal,
    ros_neuroPsychSubDiagSeizureSubTypeCommentVal,
    ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSeizureSubSuppCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubDescCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal,
    ros_neuroPsychSubDiagStrokeSubDescCommentVal,
    ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal,
    ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagStrokeSubSuppCommentVal,
    ros_neuroPsychSubDiagSubduralSubDescCommentVal,
    ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal,
    ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSubduralSubSuppCommentVal,
    ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal,
    ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal,
    ros_neuroPsychSubDiagCerebPalsySubDescCommentVal,
    ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal,
    ros_neuroPsychSubDiagDelusionSubDescCommentVal,
    ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDelusionSubSuppCommentVal,
    ros_neuroPsychSubDiagFibroSubDescCommentVal,
    ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagFibroSubSuppCommentVal,
    ros_neuroPsychSubDiagGuillSubDescCommentVal,
    ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagGuillSubSuppCommentVal,
    ros_neuroPsychSubDiagHuntingSubDescCommentVal,
    ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagHuntingSubSuppCommentVal,
    ros_neuroPsychSubDiagInsomniaSubDescCommentVal,
    ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagInsomniaSubSuppCommentVal,
    ros_neuroPsychSubDiagMigraineSubDescCommentVal,
    ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagMigraineSubSuppCommentVal,
    ros_neuroPsychSubDiagSclerosisSubDescCommentVal,
    ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSclerosisSubSuppCommentVal,
    ros_neuroPsychSubDiagDystrophySubDescCommentVal,
    ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDystrophySubSuppCommentVal,
    ros_neuroPsychSubDiagGravisSubDescCommentVal,
    ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagGravisSubSuppCommentVal,
    ros_neuroPsychSubDiagParkinsonSubDescCommentVal,
    ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagParkinsonSubSuppCommentVal,
    ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal,
    ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal,
    ros_neuroPsychSubDiagRestlessLegSubDescCommentVal,
    ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal,
    ros_neuroPsychSubDiagSchizoSubDescCommentVal,
    ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSchizoSubSuppCommentVal,
    ros_neuroPsychSubDiagTiaSubDescCommentVal,
    ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagTiaSubSuppCommentVal,
    ros_neuroPsychSubDiagOtherSubDescCommentVal,
    ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagOtherSubSuppCommentVal,
    ros_neuroPsychSubDiagOtherSubOtherVal,
    ros_muskoHadAnAmputationVal,
    ros_musculoskeletalVal,
    ros_hematologyVal,
    ros_integumentVal,
    ros_asthmaPatientControllerVal,
    ros_asthmaRescueMedicationsVal,
    ros_asthmaCurrentExacerbationVal,
    ros_patientChronicBronchitisVal,
    ros_patientHaveEmphysemaVal,
    ros_patientBronchodilatorVal,
    ros_patientSteroidsVal,
    ros_patientExacerbationVal,
    ros_historyOfLungAbscessVal,
    ros_historyOfEmpyemaVal,
    ros_psoriasisSubAnswerPsoriaticArthritisVal,
    ros_aidsSubAnswerPatientUnderTreatmentVal,
    ros_hivSubAnswerPatientSymptomaticVal,
    ros_hivSubAnswerActiveTreatmentVal,
    ros_immuneDeficiencySubAnswerHistoryOfOpportunisticVal,
    ros_tuberculosisSubAnswerGivenBCGVal,
    ros_tuberculosisSubAnswerActiveTuberculosisVal,
    ros_tuberculosisSubAnswerTbInfectionVal,
    ros_degenerativeDiscDiseaseSubAnswerNormalBladderVal,
    ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerVal,
    ros_spinalStenosisSubAnswerNormalBladderVal,
    ros_respiratoryVal,
    ...gait_RecommendationsVal,
    gait_RecommendationstakeMedicationsAsPescribedCommentVal,
    gait_RecommendationsotherCommentVal,

    ...respiratory_RecommendationsVal,
    respiratory_RecommendationstakeMedicationsAsPescribedCommentVal,
    respiratory_RecommendationsotherCommentVal,

    ...integument_RecommendationsVal,
    integument_RecommendationstakeMedicationsAsPescribedCommentVal,
    integument_RecommendationsotherCommentVal,

    ...musko_RecommendationsVal,
    musko_RecommendationsdiscussPtOtCommentVal,
    musko_RecommendationstakeMedicationsAsPescribedCommentVal,
    musko_RecommendationsotherCommentVal,

    ...hema_RecommendationsVal,
    hema_RecommendationstakeMedicationsAsPescribedCommentVal,
    hema_RecommendationsreportAbnormalBruisingCommentVal,
    hema_RecommendationsfollowUpWithDoctorCommentVal,
    hema_RecommendationsotherCommentVal,

    ros_gaitComment,
    ros_respiratoryComment,
    ros_acutePulmonarySubAnswerDescribeComment,
    ros_respiratoryDiagnosesComment,
    ros_acutePulmonarySubAnswerSupportedByComment,
    ros_acuteUpperSubAnswerDescribeComment,
    ros_acuteUpperSubAnswerSupportedByComment,
    ros_asthmaSubAnswerDescribeComment,
    ros_asthmaSubAnswerSupportedByComment,
    ros_asthmaPatientControllerComment,
    ros_asthmaRescueMedicationsComment,
    ros_asthmaCurrentExacerbationComment,
    ros_chronicPulmonaryEmbolismSubAnswerDescribeComment,
    ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment,
    ros_chronicRespiratoryFailureSubAnswerDescribeComment,
    ros_chronicRespiratoryFailureSubAnswerSupportedByComment,
    ros_chronicSputumFailureSubAnswerDescribeComment,
    ros_chronicSputumFailureSubAnswerSupportedByComment,
    ros_copdSubAnswerDescribeComment,
    ros_copdSubAnswerSupportedByComment,
    ros_patientChronicBronchitisComment,
    ros_patientHaveEmphysemaComment,
    ros_patientBronchodilatorComment,
    ros_patientSteroidsComment,
    ros_patientExacerbationComment,
    ros_cysticFibrosisSubAnswerDescribeComment,
    ros_cysticFibrosisSubAnswerSupportedByComment,
    ros_hypoventilationSubAnswerDescribeComment,
    ros_hypoventilationSubAnswerSupportedByComment,
    ros_hypoxemiaSubAnswerDescribeComment,
    ros_hypoxemiaSubAnswerSupportedByComment,
    ros_pneumoniaSubAnswerDescribeComment,
    pneumoniaSubAnswerSupportedByComment,
    ros_pneumoniaSubAnswerEtiologyComment,
    ros_historyOfLungAbscessComment,
    ros_historyOfEmpyemaComment,
    ros_pulmonaryFibrosisSubAnswerDescribeComment,
    ros_pulmonaryFibrosisSubAnswerSupportedByComment,
    ros_respiratorDependenceSubAnswerDescribeComment,
    ros_respiratorDependenceSubAnswerSupportedByComment,
    ros_respiratoryArrestSubAnswerDescribeComment,
    ros_respiratoryArrestSubAnswerSupportedByComment,
    ros_sarcoidosisSubAnswerDescribeComment,
    ros_sarcoidosisSubAnswerSupportedByComment,
    ros_sarcoidosisSubAnswerSpecifyComment,
    ros_sleepApneaSubAnswerDescribeComment,
    ros_sleepApneaSubAnswerSupportedByComment,
    ros_otherSubAnswerDescribeComment,
    ros_otherSubAnswerSupportedByComment,
    ros_otherSubAnswerOtherSubText,

    ros_integumentComment,
    ros_integumentDiagnosesComment,
    ros_basilCellCarcinomaSubAnswerDescribeComment,
    ros_basilCellCarcinomaSubAnswerSupportedByComment,
    ros_dermatitisSubAnswerDescribeComment,
    ros_dermatitisSubAnswerSupportedByComment,
    ros_dermatitisSubAnswerWhatTypeComment,
    ros_eczemaSubAnswerDescribeComment,
    ros_eczemaSubAnswerSupportedByComment,
    ros_onychomycosisSubAnswerDescribeComment,
    ros_onychomycosisSubAnswerSupportedByComment,
    ros_psoriasisSubAnswerDescribeComment,
    ros_psoriasisSubAnswerSupportedByComment,
    ros_psoriasisSubAnswerPsoriaticArthritisComment,
    ros_skinUlcerSubAnswerDescribeComment,
    ros_skinUlcerSubAnswerSupportedByComment,
    ros_skinUlcerSubAnswerEtiologyComment,
    ros_tineaPedisSubAnswerDescribeComment,
    ros_tineaPedisSubAnswerSupportedByComment,
    ros_urticarialDiseaseSubAnswerDescribeComment,
    ros_urticarialDiseaseSubAnswerEtiologySubText,
    ros_urticarialDiseaseSubAnswerSupportedByComment,
    ros_urticarialDiseaseSubAnswerTypeComment,
    ros_woundSubAnswerDescribeComment,
    ros_woundSubAnswerSupportedByComment,
    ros_woundSubAnswerEtiologyComment,
    ros_othersSubAnswerDescribeComment,
    ros_othersSubAnswerSupportedByComment,
    ros_integumentOthersSubAnswerOtherSubText,

    ros_musculoskeletalComment,
    ros_musculoskeletalDiagnosesComment,
    ros_collagenSubAnswerDescribeComment,
    ros_collagenSubAnswerSupportedByComment,
    ros_collagenSubAnswercollagenDescribeComment,
    ros_degenerativeDiscDiseaseSubAnswerDescribeComment,
    ros_degenerativeDiscDiseaseSubAnswerSupportedByComment,
    ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment,
    ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment,
    ros_extremityFractureSubAnswerDescribeComment,
    ros_extremityFractureSubAnswerSupportedByComment,
    ros_extremityFractureSubAnswerExtremityComment,
    ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment,
    ros_goutSubAnswerDescribeComment,
    ros_goutSubAnswerSupportedByComment,
    ros_halluxValgusSubAnswerDescribeComment,
    ros_halluxValgusSubAnswerSupportedByComment,
    ros_hammerToesSubAnswerDescribeComment,
    ros_hammerToesSubAnswerSupportedByComment,
    ros_osteoarthritisSubAnswerDescribeComment,
    ros_osteoarthritisSubAnswerWhichJointsSubText,
    ros_osteoarthritisSubAnswerSupportedByComment,
    ros_osteomyelitisSubAnswerDescribeComment,
    ros_osteomyelitisSubAnswerSupportedByComment,
    ros_osteoporosisSubAnswerDescribeComment,
    ros_osteoporosisSubAnswerSupportedByComment,
    ros_pyogenicArthritisSubAnswerDescribeComment,
    ros_pyogenicArthritisSubAnswerWhichJointsSubText,
    ros_pyogenicArthritisSubAnswerSupportedByComment,
    ros_rheumatoidArthritisSubAnswerDescribeComment,
    ros_rheumatoidArthritisSubAnswerWhichJointsSubText,
    ros_rheumatoidArthritisSubAnswerSupportedByComment,
    ros_spinalStenosisSubAnswerDescribeComment,
    ros_spinalStenosisSubAnswerSupportedByComment,
    ros_spinalStenosisSubAnswerNormalBladderComment,
    ros_systemicLupusSubAnswerDescribeComment,
    ros_systemicLupusSubAnswerSupportedByComment,
    ros_muskoOtherSubAnswerDescribeComment,
    ros_muskoOtherSubAnswerSupportedByComment,
    ros_muskoOtherSubAnswerotherSubText,
    ros_hadAnAmputationSubAnswerSubText,

    ros_hematologyComment,
    ros_hematologyDiagnosesComment,
    ros_aidsSubAnswerDescribeComment,
    ros_aidsSubAnswerSupportedByComment,
    ros_aidsSubAnswerPatientUnderTreatmentComment,
    ros_anemiaSubAnswerDescribeComment,
    ros_anemiaSubAnswerSupportedByComment,
    ros_anemiaSubAnswerEtiologyComment,
    ros_anemiaSubAnswerYesPatientOnComment,
    ros_cDifficileSubAnswerDescribeComment,
    ros_cDifficileSubAnswerSupportedByComment,
    ros_communityAcquiredSubAnswerDescribeComment,
    ros_communityAcquiredSubAnswerSupportedByComment,
    ros_hivSubAnswerDescribeComment,
    ros_hivSubAnswerSupportedByComment,
    ros_hivSubAnswerViralLoadSubText,
    ros_hivSubAnswerCFourSubText,
    ros_hivSubAnswerPatientSymptomaticComment,
    ros_hivSubAnswerActiveTreatmentComment,
    ros_herpesZosterSubAnswerDescribeComment,
    ros_herpesZosterSubAnswerSupportedByComment,
    ros_mrsaInfectionSubAnswerDescribeComment,
    ros_mrsaInfectionSubAnswerSupportedByComment,
    ros_immuneDeficiencySubAnswerDescribeComment,
    ros_immuneDeficiencySubAnswerEtiologySubText,
    ros_immuneDeficiencySubAnswerSupportedByComment,
    ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment,
    ros_sepsisSubAnswerDescribeComment,
    ros_sepsisSubAnswerSupportedByComment,
    ros_sickleCellDiseaseTwoSubAnswerDescribeComment,
    ros_sickleCellDiseaseTwoSubAnswerSupportedByComment,
    ros_sickleCellTraitSubAnswerDescribeComment,
    ros_sickleCellTraitSubAnswerSupportedByComment,
    ros_thalassemiaSubAnswerDescribeComment,
    ros_thalassemiaSubAnswerSupportedByComment,
    ros_thrombocytopeniaSubAnswerDescribeComment,
    ros_thrombocytopeniaSubAnswerEtiologySubText,
    ros_thrombocytopeniaSubAnswerSupportedByComment,
    ros_tuberculosisSubAnswerDescribeComment,
    ros_tuberculosisSubAnswerSupportedByComment,
    ros_tuberculosisSubAnswerGivenBCGComment,
    ros_tuberculosisSubAnswerActiveTuberculosisComment,
    ros_tuberculosisSubAnswerTbInfectionComment,
    ros_vitaminDDeficiencySubAnswerSupportedByComment,
    ros_hemaOtherSubAnswerDescribeComment,
    ros_hemaOtherSubAnswerSupportedByComment,
    ros_hemaOtherSubAnswerOtherSubText,

    ros_diagnosisOfCancerVal,
    ros_gastroSubDiagnosesbowelObstructionSupComment,
    ros_gastroSubDiagnosesdesbowelObstructionComment,
    ros_endoVal,
    ros_gastroVal,
    ros_genitoVal,
    ros_genitoSubDiagnosesacuteRenalFailuredesComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupComment,
    ros_diagnosisOfCancerSubActiveTreatVal:
      ros_diagnosisOfCancerSubActiveTreatVal,
    ros_diagnosisOfCancerSubMetastasisVal: ros_MetastasisVal,
    ros_diagnosisOfCancerSubSeeASpecVal: ros_SeeASpecVal,
    ros_diagnosisOfCancerSubSpecTyVal: specialTypes,
    ros_diagnosisOfCancerSubMetastasisLocVal: ros_MetastasisLocVal,
    ros_diagnosisOfCancerSubStageSpecCanVal: stageSpecificToTheCancer,
    ros_diagnosisOfCancerCommentVal: ros_diagnosisOfCancerCommentVal,

    ...ros_cancerRecommendations,
    ...gastro_recommendations,
    ...ros_cardiovascularRecommendations,
    ...ros_genitoRecommendations,
    ...endocrine_recommendations,
    ros_diagnosisOfCancerSubDescComment,
    ros_diagnosisOfCancerSubSupComment,
    ros_diagnosisOfCancerSubTypeComment,
    ros_diagnosisOfCancerSubActiveTreatComment,
    ros_diagnosisOfCancerSubSideEffComment,
    ros_activeTreatmentComment,
    ros_SeeASpecComment,
    ros_diagnosisOfCancerSubMetastasisCachexiaValComment,
    ros_MetastasisComment,
    ros_takemedicationsasprescribedCancerCommentVal:
      ros_cancerRecommendationstakemedicationsasprescribedCancerComment,

    ros_otherCancerCommentVal: ros_cancerRecommendationsotherCancerComment,

    ros_diagnosisOfCancerSubSeeASpecProVal: ros_SpecProVal,
    ros_diagnosisOfCancerSubMetastasisCachexiaVal: ros_MetastasisCachexiaVal,

    ros_cardioVal,
    ros_cardioComment,
    cardio_RecommendationstakeMedicationsAsPescribedCommentVal:
      ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment,

    cardio_RecommendationsotherCommentVal:
      ros_cardiovascularRecommendationsotherComment,
    cardio_RecommendationsexerciseThirtyMinADayCommentVal:
      ros_cardiovascularRecommendationsexerciseThirtyMinADayComment,
    cardio_RecommendationsbloodPressureChecksCommentVal:
      ros_cardiovascularRecommendationsbloodPressureChecksComment,
    cardio_RecommendationsheartHealthyDietCommentVal:
      ros_cardiovascularRecommendationsheartHealthyDietComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDesComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment,

    ros_cardioSubDiagnosesComment,

    ros_cardioSubDiagnosesAneurysmDesComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest,

    ros_endoValComment,
    ros_gastroValComment,
    ros_genitoValComment,

    ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment,
    ros_genitoRecommendationsotherGenitoComment,
    endocrine_recommendationstakeMedicationsAsPescribedComment,
    endocrine_recommendationscheckBloodSugarComment,
    endocrine_recommendationsotherComment,
    gastro_recommendationstakemedicationsasprescribedComment,
    gastro_recommendationsotherComment,
    ros_gastroSubDiagnosesComment: ros_gastroSubDiagnosesComment,
    ros_gastroSubDiagnosesdescirrhosisComment,
    ros_gastroSubDiagnosescirrhosisSupComment,
    ros_gastroSubDiagnosescachexiadesComment,
    ros_gastroSubDiagnosescachexiaSupComment,
    ros_gastroSubDiagnosescolonPolypsDesComment,
    ros_gastroSubDiagnosesceliacDiseaseGlueten,
    ros_genitoSubDiagnosesComment,
    ros_endoSubDiagnosesComment,
    ros_genitoSubDiagnosesacuteRenalFailureEti,
    ros_gastroSubDiagnosesceliacDiseaseGluetenComment,
    ros_gastroSubDiagnosesceliacDiseaseSupComment,
    ros_gastroSubDiagnosesdesceliacDiseaseComment,
    ros_gastroSubDiagnosescirrhosisEndStage,
    ros_gastroSubDiagnosesdescirrhosisComment,
    ros_gastroSubDiagnosescirrhosisSupComment,
    ros_gastroSubDiagnosescirrhosisEndStageComment,
    ros_gastroSubDiagnosescolonCrDesComment,
    ros_gastroSubDiagnosesdescolonComment,
    ros_gastroSubDiagnosescolonSupComment,
    ros_cardioSubDiagnosesAneurysmCrDesComment,
    ros_cardioSubDiagnosesAneurysmSupComment,
    ros_cardioSubDiagnosesAnginaCrDesComment,
    ros_cardioSubDiagnosesAnginaSupComment,
    ros_cardioSubDiagnosesAnginaDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistent,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin,
    ros_cardioSubDiagnosesHypertensionadequatelyControlled,
  };

  let payloadData = {};

  ros_hashmap?.forEach((questionPair) => {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
  });

  return payloadData;
};

const getWrapper = (tempData, keyArray) => {
  let temp = getOptionsSelected(tempData, keyArray);
  return temp;
};

const setWrapper = (tempData, keyArray) => {
  let temp = setOptionsSelected(tempData, keyArray);
  return temp;
};

const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

const setSelectedCheckboxes = (optionArr, optionKeyArr, optionValueArr) => {
  let temp = {};

  optionValueArr?.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};

const getOptionsSelected = (optionObject, optionArray, multiSelect = false) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption;
  if (multiSelect) {
    selectedOption = selectedVariable.map((variable) => {
      return ros_optionsTreeMapObject[variable];
    });
  } else {
    // console.log(examReview_optionsTreeMapObject[selectedVariable[0]], "exam");
    selectedOption = ros_optionsTreeMapObject[selectedVariable[0]];
  }
  return selectedOption;
};

// Modify the setOptionsSelected function to correctly handle multi-select
const setOptionsSelected = (
  optionsSelected,
  optionArray,
  multiSelect = false,
) => {
  let temp = {};

  if (multiSelect) {
    optionArray.forEach((option) => {
      temp[option] = optionsSelected.includes(ros_optionsTreeMapObject[option])
        ? "1"
        : "2";
    });
  } else {
    optionArray.forEach((option) => {
      // console.log(option,optionsSelected,ros_optionsTreeMapObject[option],ros_optionsTreeMapObject[option]===optionsSelected,);
      temp[option] =
        ros_optionsTreeMapObject[option] === optionsSelected ? "1" : "2";
    });
  }
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoPartially = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else if (optionValue === "3") {
    selectedValue = "partially";
  } else {
    selectedValue = "";
  }
  return selectedValue;
};

const setOptionsSelectedYesNoPartially = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else if (optionValue === "partially") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoUnknown = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else if (optionValue === "3") {
    selectedValue = "unknown";
  } else {
    selectedValue = "";
  }
  return selectedValue;
};

const setOptionsSelectedYesNoUnknown = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else if (optionValue === "unknown") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};
