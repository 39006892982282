import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { zcs } from "zcs";
import {
  emailRegex,
  maxDOB,
  minDOB,
  populateYears,
  genderOptions,
  maritalOptions,
  getCityStateFromZip,
  preferredContactMethodOptions,
  memberAssessmentOptions,
  membersEligibleOptions,
} from "../../../utils/appConstants";
import { getStateName, stateArray } from "../../../utils/getUSStateName";
import { changeDateToUtcIsoString } from "../../utils";
import { addNewMembers } from "../../../api/membersApi";
import { getAllClients } from "../../../api/clientsApi";
import Loader from "../../Loader/Loader";

import "./AddMembers.css";
import dayjs from "dayjs";
import { useUserContext } from "../../../contexts/UserContext";

const ZCS = zcs();

export default function ViewMembers({ membersDetails, memberYear }) {
  // const [membersDetails, setMembersDetails] = useState([]);
  // const [memberYear, setMemberYear] = useState("");
  const { lobOptionData } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [memberAge, setMemberAge] = useState();
  const [clientOptions, setClientOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [allMappingData, setAllMappingData] = useState([]);
  const [planValue, setPlanValue] = useState();
  const [programValue, setProgramValue] = useState();
  const [clientValue, setClientValue] = useState();
  const [yearValue, setYearValue] = useState();
  const { control, handleSubmit, reset } = useForm(
    {
      mode: "all",
    },
    {
      defaultValues: {
        age: 0,
        // state: "",
      },
    },
  );

  const getMemberApiData = async () => {
    try {
      const response = await getAllClients();
      if (response?.data?.data) {
        setAllMappingData(response.data.data);

        const initialPlan = localStorage.getItem("clientPlan");

        const selectedClient = response.data.data.find(
          (client) => client.plan === initialPlan,
        );

        if (selectedClient) {
          setProgramOptions(selectedClient.program || []);

          const initialProgram = selectedClient.program[0]?.name || "";
          setProgramValue(initialProgram);

          const yearList = selectedClient.program.find(
            (program) => program.name === initialProgram,
          );
          setYearOptions((yearList?.yearList || []).reverse());
        }
      }
    } catch (error) {
      console.error("Error fetching member data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(planValue)) {
      let selectedClientBasedObjectValue = _.find(allMappingData, {
        plan: planValue,
      });
      setProgramOptions(selectedClientBasedObjectValue?.program || []);
    } else {
      setProgramOptions([]);
    }
    getMemberApiData();
    let selectedClientBasedObjectValue = _.find(allMappingData, {
      plan: planValue,
    });
  }, [planValue]);

  const memberDOB = membersDetails?.dob;
  const memberGender =
    membersDetails?.genderAtBirth || membersDetails?.gender || "";
  const memberMarital = membersDetails?.mStatus || "";
  const preferredContactMethod = membersDetails?.preferredContactMethod || "";

  const memberAddressOne = membersDetails?.address || "";
  const memberAddressTwo = membersDetails?.addressTwo || "";
  const mCounty = membersDetails?.county || "";
  const memberZip = membersDetails?.zip || "";
  const memberCity = membersDetails?.city || "";
  const memberState = membersDetails?.state || "";
  const memberAltNo = membersDetails?.aContact || "";
  const memberCellNo = membersDetails?.secondary || "";
  const memberPhoneNumber = membersDetails?.primary || memberCellNo || "";
  const memberEmerName = membersDetails?.ename || "";
  const memberEmerNumber = membersDetails?.eNo || "";
  const memberEmail = membersDetails?.email || "";
  //pcp
  const memberpcpcounty = membersDetails?.pcpcounty || "";
  const memberPAdd = membersDetails?.pAddOne || "";
  const memberpCity = membersDetails?.pCity || "";
  const memberpFax = membersDetails?.pFax || "";
  const memberpId = membersDetails?.pId || "";
  const memberpName = membersDetails?.pName || "";
  const nameParts = memberpName.split(" ");
  const pcpfirstName = nameParts[0] || "";
  const pcplastName = nameParts[nameParts.length - 1] || "";
  const memberpOfcName = membersDetails?.pOfcName || "";
  const memberpPhone = membersDetails?.pPhone || "";
  const memberpState = membersDetails?.pState || "";
  const memberpZip = membersDetails?.pZip || "";
  //insurance
  const memberMemID = membersDetails?.memberId || "";
  const memberIdTwo = membersDetails?.memberIdTwo || "";
  const memberMedcaidID = membersDetails?.medicaidId || "";
  const memberMedcairID = membersDetails?.medicareId || "";
  const uniqueId = membersDetails?.uniqueId || "";
  const hicn = membersDetails?.hicn || "";
  const plan = membersDetails?.plan || "";
  const program = membersDetails?.program || "";
  const mlob = membersDetails?.lob || "";
  const mlanguage = membersDetails?.language || "";
  const mAssessment = membersDetails?.memberAssessment || "";
  const mEligible = membersDetails?.membersEligible || "";
  const memYear = memberYear || "";
  const memEffDate = membersDetails?.effectiveDate
    ? dayjs(membersDetails?.effectiveDate).format("YYYY-MM-DD")
    : null;
  const memTermDate = membersDetails?.termDate
    ? dayjs(membersDetails?.termDate).format("YYYY-MM-DD")
    : null;

  useEffect(() => {
    if (memberDOB) {
      const birthDate = new Date(memberDOB);
      const today = new Date();

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      setMemberAge(age);
    }
  }, [memberDOB]);

  return (
    <>
      <form>
        <div className="add-form-content">
          <div className="form-content-left">
            <div className="demographics-section">
              <div className="demo-header">Demographics</div>
              <div className="demo-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member First Name"
                      className="inputAddMember"
                      fullWidth
                      value={membersDetails?.firstName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member Middle Name"
                      className="inputAddMember"
                      fullWidth
                      value={membersDetails?.middleName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Last Name"
                      className="inputAddMember"
                      fullWidth
                      value={membersDetails?.lastName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth>
                      <Controller
                        name={"dob"}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DesktopDatePicker
                            label="DOB"
                            className="inputAddMember"
                            // value={value}
                            value={
                              memberDOB && memberDOB !== ""
                                ? dayjs(memberDOB)
                                : null
                            }
                            // onChange={onChange}
                            disableFuture
                            minDate={minDOB}
                            maxDate={maxDOB}
                            views={["year", "month", "day"]}
                            disabled
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                                error: !!error,
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      label="Age"
                      className="inputAddMember"
                      readOnly
                      fullWidth
                      defaultValue={memberAge}
                      value={memberAge !== undefined ? memberAge : ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Gender (Female, Male, Unknown)</InputLabel>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue={memberGender}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                            value={memberGender || ""}
                          >
                            {genderOptions?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Marital Status</InputLabel>
                      <Controller
                        control={control}
                        name={"mStatus"}
                        defaultValue={memberMarital}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                            value={memberMarital || ""}
                          >
                            {maritalOptions?.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      label="Language Spoken"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={mlanguage}
                      value={mlanguage || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Preferred Contact Method</InputLabel>
                      <Controller
                        control={control}
                        name={"preferredContactMethod"}
                        rules={{
                          required: "Preferred Contact Method is Required",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            defaultValue={preferredContactMethod || ""}
                            value={preferredContactMethod || ""}
                            disabled
                          >
                            {preferredContactMethodOptions?.map((option) => {
                              return (
                                <MenuItem
                                  key={option.id}
                                  value={String(option.id)}
                                >
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      value={memberAddressOne || ""}
                      defaultValue={memberAddressOne}
                      label="Member Address 1"
                      className="inputAddMember"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Address 2"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberAddressTwo}
                      value={memberAddressTwo || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member County"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={mCounty}
                      value={mCounty || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="number"
                      required
                      label="Member Zip Code"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberZip}
                      value={memberZip || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        // shrink: !!watch("zipCode"),
                      }}
                      type="text"
                      required
                      label="Member City"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberCity}
                      value={memberCity || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member State"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberState}
                      value={memberState || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Phone Number"
                      className="inputAddMember"
                      fullWidth
                      value={memberPhoneNumber || ""}
                      defaultValue={memberPhoneNumber}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Alternate Number 1"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberAltNo}
                      value={memberAltNo || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="email"
                      // required
                      label="Member Email Address"
                      className="inputAddMember"
                      fullWidth
                      value={memberEmail || ""}
                      defaultValue={memberEmail}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Emergency Contact - Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberEmerName}
                      value={memberEmerName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Emergency Contact - Phone Number"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberEmerNumber}
                      value={memberEmerNumber || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="pcp-details-section">
              <div className="pcp-details-header">
                Primary Care Physician Details
              </div>
              <div className="pcp-details-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP First Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={pcpfirstName}
                      value={pcpfirstName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Last Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={pcplastName}
                      value={pcplastName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Phone Number"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpPhone}
                      value={memberpPhone || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Address"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberPAdd}
                      value={memberPAdd || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP County"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpcpcounty}
                      value={memberpcpcounty || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="number"
                      required
                      label="Member PCP Zip Code"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpZip}
                      value={memberpZip || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member PCP City"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpCity}
                      value={memberpCity || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member PCP State"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpState}
                      value={memberpState || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member PCP Fax Number"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpFax}
                      value={memberpFax || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Office ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpId}
                      value={memberpId || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member PCP Office Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpOfcName}
                      value={memberpOfcName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="form-content-right">
            <div className="insurance-details-section">
              <div className="insurance-header">Insurance Details</div>
              <div className="insurance-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberMemID}
                      value={memberMemID || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member Identification Number Two"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberIdTwo}
                      value={memberIdTwo || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Medicare ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberMedcairID}
                      value={memberMedcairID || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Medicaid ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberMedcaidID}
                      value={memberMedcaidID || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Unique ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={uniqueId}
                      value={uniqueId || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="number"
                      label="HICN"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={hicn}
                      value={hicn || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Plan</InputLabel>
                      <Controller
                        name="plan"
                        control={control}
                        defaultValue={plan || ""}
                        value={plan || ""}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                            defaultValue={plan || ""}
                            value={plan || ""}
                          >
                            <MenuItem value="">Select Plan</MenuItem>
                            {allMappingData?.map((option) => {
                              return (
                                <MenuItem
                                  key={option?._id}
                                  value={option?.plan}
                                >
                                  {_.toUpper(option?.plan)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl required fullWidth>
                      <InputLabel>Member Program</InputLabel>
                      <Controller
                        name="program"
                        control={control}
                        // defaultValue={program}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                            value={program || ""}
                          >
                            <MenuItem value="">Select Program</MenuItem>
                            {programOptions?.map((option) => (
                              <MenuItem key={option?._id} value={option?.name}>
                                {option?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ padding: "10px" }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="lob-select-label">Member LOB</InputLabel>
                      <Controller
                        name="lob"
                        control={control}
                        defaultValue={mlob}
                        render={({ field }) => (
                          <Select
                            className="inputAddMember"
                            labelId="lob-select-label"
                            {...field}
                            value={field.value || mlob}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 250,
                                },
                              },
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled
                          >
                            {lobOptionData?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Assessment</InputLabel>
                      <Controller
                        control={control}
                        name={"memberAssessment"}
                        // defaultValue=""
                        rules={{ required: "Member Assessment is Required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            value={mAssessment || ""}
                            disabled
                          >
                            {memberAssessmentOptions?.map((option) => {
                              return (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                >
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl required fullWidth>
                      <Tooltip
                        title="Members eligible HEDIS Measure Y/N: A1C, FIT, DEXA, LDL, PAD, Retinal Eye Exams, Urine Microalbumin, Urine KED"
                        arrow
                      >
                        <InputLabel>
                          Members eligible HEDIS Measure Y/N?
                        </InputLabel>
                      </Tooltip>
                      <Controller
                        control={control}
                        name={"membersEligible"}
                        defaultValue=""
                        rules={{ required: "Member eligible is Required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            value={mEligible || ""}
                            disabled
                          >
                            {membersEligibleOptions?.map((option) => {
                              return (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                >
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel>Year List</InputLabel>
                      <Controller
                        name="year"
                        control={control}
                        rules={{ required: "Year is Required" }}
                        // defaultValue={memYear || ""}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                            value={memYear || ""}
                            // defaultValue={memYear || ""}
                          >
                            <MenuItem value="">Select Year</MenuItem>
                            {yearOptions?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl required fullWidth>
                      <Controller
                        name={"effectiveDate"}
                        control={control}
                        render={({ field: { value } }) => (
                          <DesktopDatePicker
                            label="Member Enrollment Date"
                            className="inputAddMember"
                            value={
                              memEffDate && memEffDate !== ""
                                ? dayjs(memEffDate)
                                : null
                            }
                            onChange={() => {}}
                            disablePast
                            views={["year", "month", "day"]}
                            disabled
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: "Effective Date is Required",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl required fullWidth>
                      <Controller
                        name={"termDate"}
                        control={control}
                        render={({ field: { value } }) => (
                          <DesktopDatePicker
                            label="Member Termed Date"
                            className="inputAddMember"
                            value={memTermDate ? dayjs(memTermDate) : null}
                            onChange={() => {}}
                            disablePast
                            views={["year", "month", "day"]}
                            disabled
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: "Term Date is Required",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loading && <Loader />}
    </>
  );
}
