import { useEffect, useState, useContext, useRef } from "react";
import toast from "react-hot-toast";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useImperativeHandle, forwardRef } from "react";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../../../../assets/focuscares_logo.png";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

import {
  generateRTTReport,
  getRTTByAssessmentId,
  updateRTTReport,
} from "../../../../../api/realTimeScriptsApi"; // Ensure this import is correct
import { SaveOutlined } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import MicNoneIcon from "@mui/icons-material/MicNone";
import { TextField } from "@mui/material";
import { motion } from "framer-motion";
import { useSpeech } from "../../../../../contexts/SpeechContext";
import Loader from "../../../../Loader/Loader";
import StopIcon from "@mui/icons-material/Stop";
import IconButton from "@mui/material/IconButton";
import CustomAudioPlayer from "./CustomAudioPlayer";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { postApi } from "../../../../../utils/axiosApiWrapper";
import Audio from "./Audio";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  accordion: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 10px",
    borderRadius: "20px",
    marginBottom: "10px",
    // transition: "transform 0.2s ease-in-out",
    // "&:hover": {
    //   transform: "scale(1.05)",
    // },
  },
});

// Check if browser is Edge
const isEdge = /Edg/.test(navigator.userAgent);

const FillHRASummary = ({ apiData, setApiData, uploadedAudioUrl }) => {
  const [accordExpand, setAccordExpand] = useState("panel_Chief");
  const [recText, setRecText] = useState("");
  //const [apiData, setApiData] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [expandedSection, setExpandedSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false); // State to toggle the player
  // const [contentValue, setContentValue] = useState("");
  const assessmentId = sessionStorage?.getItem("assessmentId");
  const [prevDictatedTextBySection, setPrevDictatedTextBySection] = useState(
    {},
  );

  const { setAzureBlobUrl } = useSpeech();
  const classes = useStyles();
  const recognizerRef = useRef(null); // Declare the recognizerRef

  const transcriptRef = useRef(null);
  const recTextRef = useRef(null);

  // Speech recognition setup
  // const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  const [isMicActive, setIsMicActive] = useState(false); // Local state for mic functionality
  const [dictatedTextBySection, setDictatedTextBySection] = useState({});
  const [dictatedText, setDictatedText] = useState(""); // Separate state for live dictation
  let recognition; // Declare recognition here

  // Feature detection and polyfill logic for cross-browser compatibility
  const getSpeechRecognition = () => {
    // Check if the browser supports the standard SpeechRecognition or its webkit prefixed version
    if ("SpeechRecognition" in window) {
      return window.SpeechRecognition;
    } else if ("webkitSpeechRecognition" in window) {
      return window.webkitSpeechRecognition;
    } else {
      console.log("there");
      // toast.error("Your browser does not support speech recognition.");
      return null; // No support
    }
  };

  useEffect(() => {
    // Scroll to the bottom when the transcript changes
    if (recTextRef.current) {
      recTextRef.current.scrollTop = recTextRef.current.scrollHeight;
    }
  }, [recText]); // Runs every time the transcript is updated

  // console.log(contentValue,"contentValuecontentValue")

  // Get the correct SpeechRecognition class for the current browser
  const SpeechRecognitionClass = getSpeechRecognition();
  // Only proceed if SpeechRecognitionClass is not null (meaning the browser supports it)

  if (SpeechRecognitionClass) {
    recognition = new SpeechRecognitionClass(); // Instantiate the class

    recognition.continuous = true;
    recognition.interimResults = true;

    // You can add additional event handlers, settings, etc., as needed here
  } else {
    // If it's null, handle the case (for example, showing an error message)
    console.error("Speech recognition is not supported in this browser.");
  }

  // const handleStartDictation = () => {
  //   setIsMicActive(true);
  //   recognition.start();
  // };

  const handleStopDictation = () => {
    console.log(`Stopping dictation`); // Console log for stopping dictation

    setIsMicActive(false);
    let recognition;
    if (SpeechRecognitionClass) {
      recognition = new SpeechRecognitionClass(); // Instantiate the class

      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.stop();
    } else {
      // If it's null, handle the case (for example, showing an error message)
      console.error("Speech recognition is not supported in this browser.");
    }
  };

  useEffect(() => {
    // Get the correct SpeechRecognition class for the current browser
    const SpeechRecognitionClass = getSpeechRecognition();
    let recognition;
    if (SpeechRecognitionClass) {
      recognition = new SpeechRecognitionClass(); // Instantiate the class

      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let interimTranscript = "";
        let finalTranscript = dictatedText; // Using dictated text instead of existing state

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        setDictatedText(finalTranscript + interimTranscript); // Update dictated text live
      };

      // You can add additional event handlers, settings, etc., as needed here
    } else {
      // If it's null, handle the case (for example, showing an error message)
      console.error("Speech recognition is not supported in this browser.");
    }

    return () => {
      if (recognition) {
        recognition.onend = null;
        recognition.onresult = null;
      }
    };
  }, [dictatedText]);

  useEffect(() => {
    const handleUndo = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "z") {
        // Restore the previous text for the currently selected section
        const selectedCategory = selectedSection;

        if (prevDictatedTextBySection[selectedCategory]) {
          setDictatedTextBySection((prevState) => ({
            ...prevState,
            [selectedCategory]: prevDictatedTextBySection[selectedCategory],
          }));

          // Update apiData with the restored text
          setApiData((prevData) => {
            const newData = prevData.map((section) => {
              if (section.category === selectedCategory) {
                return {
                  ...section,
                  content: prevDictatedTextBySection[selectedCategory],
                };
              }
              return section;
            });
            return newData;
          });
        }
      }
    };

    window.addEventListener("keydown", handleUndo);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleUndo);
    };
  }, [prevDictatedTextBySection, selectedSection]);

  // Set recognition properties here
  useEffect(() => {
    const recognizer = recognizerRef.current;
    if (recognizer) {
      recognizer.continuous = false;
      recognizer.interimResults = false;
      recognizer.maxAlternatives = 1;
    }
  }, [recognizerRef]);

  const {
    isRecognise,
    textRecogRef,
    startRecogniser,
    stopRecogniser,
    setRecognisedText,
  } = useSpeech();

  const commands = [
    {
      command: "Replace * with *",
      callback: (word1, word2) => handleReplaceCommand(word1, word2),
    },
    {
      command: "Select * section",
      callback: (sectionName) => handleSelectCommand(sectionName),
    },
    {
      command: "Clear * section",
      callback: (sectionName) => handleClearCommand(sectionName),
    },
    {
      command: "Add text to * section *",
      callback: (sectionName, newText) =>
        handleAddTextCommand(sectionName, newText),
    },
  ];

  // Custom command handlers
  const handleReplaceCommand = (word1, word2) => {
    const sectionToModify = selectedSection || expandedSection;

    // Check if sectionToModify exists and is a string
    if (!sectionToModify || typeof sectionToModify !== "string") {
      toast.error("No section selected.");
      return;
    }

    // Normalize the section name for ROS
    let normalizedSectionToModify = sectionToModify?.toLowerCase();
    if (
      normalizedSectionToModify === "ros" ||
      normalizedSectionToModify === "review of systems" ||
      normalizedSectionToModify === "reviewofsystems"
    ) {
      normalizedSectionToModify = "ros"; // Normalize to a simpler version
    }

    if (sectionToModify) {
      const updatedData = apiData.map((section) => {
        const sectionNameLower = section.category.toLowerCase();

        if (sectionNameLower === normalizedSectionToModify) {
          const updatedContent = section.content.replace(
            new RegExp(`\\b${word1}\\b`, "gi"),
            word2,
          );
          return { ...section, content: updatedContent };
        }
        return section;
      });

      setApiData(updatedData);
      toast.success(
        `Replaced "${word1}" with "${word2}" in section "${sectionToModify}"`,
      );
    } else {
      toast.error("No section selected or expanded.");
    }
  };

  const handleSelectCommand = (sectionName) => {
    const cleanedSectionName = sectionName.trim().toLowerCase();

    // Handle synonyms for the "ros" section
    let normalizedSectionName = cleanedSectionName;

    if (
      cleanedSectionName === "r o s" ||
      cleanedSectionName === "ros" ||
      cleanedSectionName === "review of systems" ||
      cleanedSectionName === "reviewofsystems" ||
      cleanedSectionName === "reviewofsystem"
    ) {
      normalizedSectionName = "ros"; // Normalize to a simpler version
    }

    console.log(normalizedSectionName, "normalizedSectionName");

    // Match section based on the normalized name
    const matchedSection = apiData.find((section) =>
      section.category.toLowerCase().includes(normalizedSectionName),
    );

    if (matchedSection) {
      setSelectedSection(matchedSection.category);
      setExpandedSection(matchedSection.category);
      toast.success(`Section "${matchedSection.category}" selected.`);
    } else {
      toast.error("Section not found.");
    }
  };
  const handleClearCommand = (sectionName) => {
    const cleanedSectionName = sectionName.trim().toLowerCase();

    // Normalize the section name for ROS
    let normalizedSectionName = cleanedSectionName;

    if (
      cleanedSectionName === "r o s" ||
      cleanedSectionName === "ros" ||
      cleanedSectionName === "review of systems" ||
      cleanedSectionName === "reviewofsystems" ||
      cleanedSectionName === "reviewofsystem"
    ) {
      normalizedSectionName = "ros"; // Normalize to a simpler version
    }

    const matchedSection = apiData.find((section) =>
      section.category.toLowerCase().includes(normalizedSectionName),
    );

    if (matchedSection) {
      const updatedData = apiData.map((section) =>
        section.category.toLowerCase().includes(normalizedSectionName)
          ? { ...section, content: "" }
          : section,
      );

      setApiData(updatedData);

      // Uncomment if you want to update the RTT report after clearing
      /*
      try {
        await updateRTTReport({
          assessmentId,
          rttData: updatedData,
        });
        toast.success("RTT data updated automatically after clearing.");
      } catch (error) {
        console.error("Failed to update RTT report after clearing", error);
        toast.error("Failed to update RTT report.");
      }
      */

      toast.success(`Cleared content from "${sectionName}" section.`);
    } else {
      toast.error("Section not found.");
    }
  };

  const handleAddTextCommand = (sectionName, newText) => {
    const cleanedSectionName = sectionName.trim().toLowerCase();

    // Normalize the section name for ROS
    let normalizedSectionName = cleanedSectionName;
    if (
      cleanedSectionName === "r o s" ||
      cleanedSectionName === "ros" ||
      cleanedSectionName === "review of systems" ||
      cleanedSectionName === "reviewofsystems" ||
      cleanedSectionName === "reviewofsystem"
    ) {
      normalizedSectionName = "ros"; // Normalize to a simpler version
    }

    const matchedSection = apiData.find((section) =>
      section.category.toLowerCase().includes(normalizedSectionName),
    );

    if (matchedSection) {
      const updatedData = apiData.map((section) => {
        if (section.category.toLowerCase().includes(normalizedSectionName)) {
          return {
            ...section,
            content: `${section.content} ${newText}`.trim(),
          }; // Add new text
        }
        return section;
      });

      console.log("Updated API data:", updatedData);
      setApiData(updatedData);
      toast.success(`Added new text to section "${matchedSection.category}".`);
    } else {
      console.log("Section not found");
      toast.error("Section not found.");
    }
  };

  const { transcript, resetTranscript } = useSpeechRecognition({ commands });

  // useEffect(() => {
  //   if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
  //     console.log("MM")
  //     toast.error("Your browser does not support speech recognition.");
  //     return;
  //   }

  //   //SpeechRecognition.startListening({ continuous: true });

  //   // return () => {
  //   //   SpeechRecognition.stopListening();
  //   // };
  // }, [assessmentId]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    // Scroll to the bottom when the transcript changes
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [transcript]); // Runs every time the transcript is updated

  // Fallback for Edge using native Web Speech API
  useEffect(() => {
    if (isEdge) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let newTranscript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        console.log("Dictated text: ", newTranscript);

        // Optionally update the recognized text into your state or context
        setRecText(newTranscript); // Storing the transcript in the state
        console.log("Transcript stored in state: ", newTranscript);
        // If you want to process commands, use the last result
        const lastResult = event.results[event.resultIndex];
        let commandText = lastResult[0].transcript.trim().toLowerCase();
        console.log("Processing command text before cleanup: ", commandText);

        // Use the debounce function to process commands
        // Remove trailing punctuation (full stop, exclamation mark, question mark, etc.)
        commandText = commandText.replace(/[.,!?]$/, "");
        const processCommand = debounce(processCommandForEdge, 500);
        processCommand(commandText); // Process the command
      };

      if (isListening) {
        recognition.start();
      } else {
        recognition.stop();
        resetTranscript();
        setRecText("");
      }

      return () => recognition.stop();
    }
  }, [isListening]);

  const processCommandForEdge = (commandText) => {
    // Handle the command recognition in Edge
    if (!commandText) {
      console.error("Received invalid commandText:", commandText);
      return; // Exit the function if commandText is invalid
    }

    if (commandText.includes("replace")) {
      const match = commandText.match(/replace (.*) with (.*)/);
      if (match) {
        const [_, word1, word2] = match;

        handleReplaceCommand(word1.trim(), word2.trim());
      } else {
        console.error("Failed to parse replace command:", commandText);
      }
    } else if (commandText.includes("select")) {
      const match = commandText.match(/select (.*) section/);
      if (match) {
        const [_, sectionName] = match;
        handleSelectCommand(sectionName.trim());
      } else {
        console.error("Failed to parse select command:", commandText);
      }
    } else if (commandText.includes("clear")) {
      const match = commandText.match(/clear (.*) section/);
      if (match) {
        const [_, sectionName] = match;
        handleClearCommand(sectionName.trim());
        // setRecText("");
      } else {
        console.error("Failed to parse clear command:", commandText);
      }
    } else if (commandText.includes("add text to")) {
      console.log("Add command detected:", commandText); // Log the full command text

      const match = commandText.match(/add text to (.*) section (.*)/);

      if (match) {
        const [_, sectionName, newText] = match;

        // console.log("Parsed section name:", sectionName.trim()); // Log the parsed section name
        // console.log("Parsed new text:", newText.trim()); // Log the parsed new text
        // console.log(
        //   `Adding text to section "${sectionName.trim()}": "${newText.trim()}"`,
        // );

        handleAddTextCommand(sectionName.trim(), newText.trim());
        // setRecText(newText.trim());
      } else {
        console.error("Failed to parse add text command:", commandText);
      }
    } else {
      console.log("Unrecognized command:", commandText);
    }
  };

  //autosave

  //   useEffect(() => {
  //   // If apiData has been updated, automatically update RTT report
  //   if (apiData.length > 0) {
  //     const updateData = async () => {
  //       try {
  //         await updateRTTReport({
  //           assessmentId,
  //           rttData: apiData,
  //         });
  //         toast.success("RTT data updated automatically.");
  //       } catch (error) {
  //         console.error("Failed to update RTT report automatically", error);
  //         toast.error("Failed to update RTT report.");
  //       }
  //     };

  //     updateData();
  //   }
  // }, [apiData, assessmentId]); // This will trigger whenever apiData changes
  // console.log(recText,"recText")

  const handleReset = () => {
    resetTranscript(); // Reset speech recognition transcript
    setRecText(""); // Reset recognized text
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getRTTByAssessmentId(assessmentId); // Replace with your actual API call
      const audioUrl = data?.data?.data?.audioUrls?.[0];

      // console.log(audioUrl,"audioUrl")
      // Set the Azure Blob URL in context
      setAzureBlobUrl(audioUrl);

      const rttData = data?.data?.data?.rttData;

      if (Array.isArray(rttData)) {
        setApiData(rttData); // Store the rttData in your state
      } else {
        throw new Error("Invalid API response format");
      }
    } catch (error) {
      //toast.error("Failed to fetch data.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [assessmentId]);

  useEffect(() => {
    const SpeechRecognitionClass = getSpeechRecognition();
    if (!SpeechRecognitionClass) {
      console.log("Speech recognition is not supported in this browser.");
      return;
    }
  }, []);

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
  };

  //   setApiData(newData);
  // };

  //   setApiData(newData);
  // };
  const handleTextChange = (event, category) => {
    const { value } = event.target;
    console.log(value, "value");
    // setContentValue(value);
    setDictatedTextBySection((prevState) => ({
      ...prevState,
      [category]: value,
    }));

    // Update apiData with the latest text
    setApiData((prevData) => {
      const newData = prevData.map((section) => {
        if (section.category === category) {
          return { ...section, content: value }; // Update the content
        }
        return section;
      });
      return newData;
    });
  };

  //   const handleStartDictation = (category) => {
  //     console.log(`Starting dictation for category: ${category}`);

  //     setIsMicActive(true);

  //     const SpeechRecognitionClass = getSpeechRecognition();
  //     let recognition;
  //     if (SpeechRecognitionClass) {
  //       recognition = new SpeechRecognitionClass();

  //       recognition.continuous = true;
  //       recognition.interimResults = true;

  //       recognition.start();
  //       recognition.onresult = (event) => {
  //         let interimTranscript = "";
  //         let finalTranscript = "";

  //         // Create updatedApiData to get existing content for the specified category
  //         const updatedApiData = apiData.map((section) => {
  //           if (section.category === category) {
  //             return section; // Keep the section as is
  //           }
  //           return section;
  //         });

  //         console.log(updatedApiData, "updatedApiData"); // Log the updatedApiData

  //         // Get the existing content for the specified category
  //         const existingApiText =
  //           updatedApiData?.find((section) => section?.category === category)
  //             ?.content || "";
  //         const existingDictatedText = dictatedTextBySection[category] || "";

  //         // Log existing texts for debugging
  //         console.log(existingApiText, "existingApiText");
  //         console.log(existingDictatedText, "existingDictatedText");

  //         for (let i = event.resultIndex; i < event.results.length; i++) {
  //           const transcript = event.results[i][0].transcript;
  //           if (event.results[i].isFinal) {
  //             finalTranscript += `${transcript.trim()} `;
  //           }
  //           else {
  //             interimTranscript +=  `${transcript}`; // Collect interim transcripts
  //           }
  //         }

  //         // Combine existing texts with the new dictated text
  //         const newDictatedText =
  //          `${existingApiText} ${finalTranscript.trim()} ${interimTranscript.trim()}`.trim();

  //         // Update the state with combined text
  //         setDictatedTextBySection((prevState) => ({
  //           ...prevState,
  //           [category]: newDictatedText,
  //         }));
  // console.log(existingApiText,"existingApiText")
  // console.log(existingDictatedText,"existingDictatedText")
  // console.log(finalTranscript,"finalTranscript")
  // console.log(interimTranscript,"interimTranscript")
  //         console.log(`Dictated text for ${category}: `, newDictatedText); // Log dictated text for the specific section
  //       };
  //     } else {
  //       // If it's null, handle the case (for example, showing an error message)
  //       console.error("Speech recognition is not supported in this browser.");
  //     }
  //   };

  const handleStartDictation = (category) => {
    console.log(`Starting dictation for category: ${category}`);

    setIsMicActive(true);

    const SpeechRecognitionClass = getSpeechRecognition();
    let recognition;
    if (SpeechRecognitionClass) {
      recognition = new SpeechRecognitionClass();

      recognition.continuous = true; // Allow continuous speech recognition
      recognition.interimResults = true; // Enable interim results

      let finalTranscript = ""; // Moved finalTranscript declaration here
      let inactivityTimer; // Timer to track inactivity
      const INACTIVITY_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

      recognition.start();

      recognition.onresult = (event) => {
        let interimTranscript = ""; // For real-time feedback

        // Create updatedApiData to get existing content for the specified category
        const updatedApiData = apiData.map((section) => {
          if (section.category === category) {
            return section; // Keep the section as is
          }
          return section;
        });

        // Get the existing content for the specified category
        const existingApiText =
          updatedApiData?.find((section) => section?.category === category)
            ?.content || "";
        const existingDictatedText = dictatedTextBySection[category] || "";

        // Process the results from the speech recognition event
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;

          if (event.results[i].isFinal) {
            // If it is a final result, append to the existing finalTranscript
            finalTranscript += `${transcript.trim()} `;
          } else {
            // Collect interim transcripts for real-time display
            interimTranscript += `${transcript.trim()} `;
            resetInactivityTimer(); // Reset inactivity timer on interim results
          }
        }

        // Combine existing texts with the new dictated text
        const newDictatedText =
          `${existingApiText} ${existingDictatedText} ${finalTranscript.trim()} ${interimTranscript.trim()}`.trim();

        // Update the state with combined text
        setDictatedTextBySection((prevState) => ({
          ...prevState,
          [category]: newDictatedText,
        }));

        // Logging for debugging purposes
        console.log(existingApiText, "existingApiText");
        console.log(existingDictatedText, "existingDictatedText");
        console.log(finalTranscript, "finalTranscript");
        console.log(interimTranscript, "interimTranscript");
        console.log(`Dictated text for ${category}: `, newDictatedText); // Log dictated text for the specific section
      };

      // Function to reset the inactivity timer
      const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer); // Clear previous timer
        inactivityTimer = setTimeout(() => {
          // After 30 minutes, allow replacing the text
          replaceFinalTranscript();
        }, INACTIVITY_DURATION);
      };

      // Function to handle replacing the final transcript
      const replaceFinalTranscript = () => {
        // Create updatedApiData to get existing content for the specified category
        const updatedApiData = apiData.map((section) => {
          if (section.category === category) {
            return section; // Keep the section as is
          }
          return section;
        });
        const existingApiText =
          updatedApiData?.find((section) => section?.category === category)
            ?.content || "";
        const existingDictatedText = dictatedTextBySection[category] || "";

        const newDictatedText = `${existingApiText} ${finalTranscript.trim()}`; // Use existing text with finalTranscript
        setDictatedTextBySection((prevState) => ({
          ...prevState,
          [category]: newDictatedText,
        }));
        console.log(
          `Final transcript replaced for ${category}: `,
          newDictatedText,
        );
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
      };

      recognition.onend = () => {
        console.log("Speech recognition service has stopped.");
        setIsMicActive(false);
        clearTimeout(inactivityTimer); // Clear the timer on end
      };
    } else {
      // If it's null, handle the case (for example, showing an error message)
      console.error("Speech recognition is not supported in this browser.");
    }
  };

  const handleSectionReset = (name) => {
    // Store the current dictated text before resetting it
    setPrevDictatedTextBySection((prevState) => ({
      ...prevState,
      [name]:
        dictatedTextBySection[name] ||
        apiData.find((section) => section.category === name)?.content ||
        "",
    }));

    // Reset the dictated text state for the specific section
    setDictatedTextBySection((prevState) => ({
      ...prevState,
      [name]: "", // Clear the dictated text for the section being reset
    }));

    // Reset the content in apiData for the specific section
    const newData = apiData.map((section) => {
      if (section?.category === name) {
        return { ...section, content: "" }; // Update the content to be empty
      }
      return section;
    });

    setApiData(newData); // Update the apiData state
  };

  const handleSectionTranscribe = (name) => {
    const newData = apiData.map((section) => {
      if (section.category === name) {
        const newText = textRecogRef.current.join("") || recText;
        return { ...section, content: section.content + newText };
      }
      return section;
    });

    textRecogRef.current = []; // Clear recognized text
    setApiData(newData);
  };

  const handleSectionSave = (category) => {
    // Get the current text for the specified category
    const currentText = dictatedTextBySection[category] || "";

    // Update apiData with the current dictated text
    const updatedApiData = apiData.map((section) => {
      if (section.category === category) {
        return { ...section, content: currentText || section.content }; // Update the content with the dictated text
      }
      return section;
    });

    // Call your update function with the updated apiData
    updateRTTReport({
      assessmentId,
      rttData: updatedApiData,
    })
      .then((response) => {
        toast.success("RTT data updated successfully");
        // Update the state with the new data to ensure the UI is in sync
        setApiData(updatedApiData);
      })
      .catch((error) => {
        console.error("Error updating RTT data:", error);
        toast.error("Failed to update RTT data");
      });
  };

  const miniButtonStyle = {
    margin: "8px",
    padding: "5px",
    borderRadius: "5px",
    background: "var(--mainTheme250)",
    color: "#514E4E",
    display: "flex",
    alignItems: "center",
  };

  const micButtonStyle = {
    margin: "8px",
    padding: "5px",
    borderRadius: "5px !important",
    background: "var(--mainTheme250)",
    color: "#514E4E",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const divStyle =
    apiData && apiData.length < 1
      ? {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }
      : {};

  const handleToggleListening = () => {
    setIsListening(!isListening);
    if (!isEdge) {
      if (!isListening) {
        SpeechRecognition.startListening({
          continuous: true,
          interimResults: true,
        });
      } else {
        SpeechRecognition.stopListening();
        resetTranscript();
        setRecText("");
      }
    }
  };

  const handleTogglePlayer = () => {
    setIsPlayerVisible(!isPlayerVisible); // Toggle the player visibility
  };

  return (
    <div style={{ marginTop: "-5%" }}>
      <div>
        {transcript ? (
          <div
            ref={transcriptRef}
            style={{
              position: "absolute",
              // top: "-10%",
              right: "35%",
              backgroundColor: transcript
                ? "rgba(0, 0, 0, 0.5)"
                : "transparent",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              width: "22%",
              height: "64px",
              overflowY: "scroll",
            }}
          >
            {transcript}
          </div>
        ) : (
          <div
            ref={recTextRef}
            style={{
              position: "absolute",
              // top: "-10%",
              right: "35%",
              backgroundColor: recText ? "rgba(0, 0, 0, 0.5)" : "transparent",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              width: "22%",
              height: "64px",
              overflowY: "scroll",
            }}
          >
            {recText}
          </div>
        )}
        {apiData?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "100px",
              bottom: "78px",
              top: "17%",

              //  position:"relative"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                bottom: "56px",
                top: "17%",
                // borderRadius: "52px",
                width: "16%",
                // padding: "0px 20px",
                // border: "1px solid transparent",
                // backgroundColor: "deeff1",
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div style={micButtonStyle}>
                <IconButton
                  onClick={handleToggleListening}
                  sx={{
                    borderRadius: "0px",
                    fontSize: "28px",
                  }}
                  aria-label={
                    isListening ? "stop listening" : "start listening"
                  }
                >
                  {isListening ? (
                    <StopIcon style={{ fontSize: "inherit" }} />
                  ) : (
                    <MicNoneIcon style={{ fontSize: "inherit" }} />
                  )}
                </IconButton>
              </div>
              <Box margin={1}>
                <Button
                  sx={{
                    backgroundColor: "var(--mainTheme900)",
                    color: "white",
                    width: "100px",
                    padding: "19px 8px !important",
                    "&:hover": {
                      backgroundColor: "var(--mainTheme500)",
                    },
                  }}
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </Button>
              </Box>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            padding: "20px 10px",
            border: "1px solid transparent",
            backgroundColor: apiData?.length > 0 ? "#cae6f9" : "transparent",
            marginTop: "14px",
            // height: "495px",
            // overflowY: "scroll",
            marginRight: "10px",
          }}
        >
          {apiData.length > 0 ? (
            apiData.map((section, index) => (
              <Accordion
                key={index}
                expanded={expandedSection === section.category}
                onChange={() => {
                  const newExpandedSection =
                    expandedSection === section.category
                      ? false
                      : section.category;
                  setExpandedSection(newExpandedSection);
                  if (newExpandedSection) {
                    setSelectedSection(newExpandedSection);
                  }
                }}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 10px",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  style={{
                    margin: "0px",
                    minHeight: "50px",
                    "&.MuiAccordionSummary-content.Mui-expanded": {
                      margin: "5px 0px 10px 0",
                    },
                  }}
                >
                  <Typography variant="h6">{section.category}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px 14px 3px" }}>
                  <TextField
                    name={section.category}
                    onChange={(event) => {
                      console.log("Current value:", event.target.value); // Log the value to the console
                      handleTextChange(event, section.category);
                    }}
                    fullWidth
                    multiline
                    value={
                      dictatedTextBySection[section.category] ||
                      section.content ||
                      ""
                    }
                    // value={section.content || ""}
                    variant="outlined"
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100px",
                      overflowY: "scroll",
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: "var(--mainTheme)",
                      },
                      border: "1.5px solid var(--mainTheme)",
                      borderRadius: "5px",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "var(--mainTheme)",
                      },
                      "&:focus-visible": {
                        outline: "none",
                        borderColor: "var(--mainTheme)",
                      },
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      style={miniButtonStyle}
                      onClick={() => handleSectionReset(section.category)}
                    >
                      <CachedIcon />
                    </div>
                    <div
                      style={miniButtonStyle}
                      onClick={
                        isMicActive
                          ? () => handleStopDictation(section.category)
                          : () => handleStartDictation(section.category)
                      }
                    >
                      {isMicActive ? <StopIcon /> : <MicNoneIcon />}
                    </div>
                    {/* //   onClick={
                    //     isRecognise
                    //       ? () => {
                    //           stopRecogniser();
                    //           setRecognisedText(setRecText);
                    //           handleSectionTranscribe(section.category);
                    //         }
                    //       : () => {
                    //           startRecogniser();
                    //         }
                    //   }
                    // >
                    //   {isRecognise ? <StopIcon /> : <MicNoneIcon />}
                    // </div> */}
                    <div
                      style={miniButtonStyle}
                      onClick={() => handleSectionSave(section.category)}
                    >
                      <SaveOutlined />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: 200, opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography style={{ margin: "100px" }} variant="h5">
                There is no summary yet.
              </Typography>
            </motion.div>
          )}
        </div>
      )}

      <div
      // style={{display: "inline-block"}}
      >
        {/* Icon to toggle the audio player */}
        {/* <div
          style={{
            border: "1px solid transparent",
            borderRadius: "42px",
            width: "100px",
            backgroundColor: "#aad8dd",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            marginLeft: "90px",
          }}
        >
          <PlayCircleFilledIcon
            onClick={handleTogglePlayer}
            style={{ fontSize: "40px", cursor: "pointer" }}
          />
          <AudioFileIcon
            onClick={handleTogglePlayer}
            style={{ fontSize: "40px", cursor: "pointer" }}
          />
        </div> */}
        {/* Conditionally render the audio player based on isPlayerVisible state */}
        {/* {isPlayerVisible && uploadedAudioUrl && <CustomAudioPlayer audioUrl={uploadedAudioUrl}  />} */}
        {/* <Audio /> */}
      </div>
    </div>
  );
};

export default FillHRASummary;
