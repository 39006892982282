import ReactGA from "react-analytics-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_TAG);

export const Send2GA_PageView = (path, title) => {
  ReactGA.send({ hitType: "pageview", page: path, title: title });
};

export const Send2GA_TriggerEvent = (type, action) => {
  ReactGA.event({
    category: type,
    action: action,
    // to-do: add other params like nonInteraction,transport etc.,
  });
};
