const ros_hashmap_mehak = [
  //gait
  ["ros_gaitNormal", "gait.normal"],
  ["ros_gaitLimp", "gait.limp"],
  ["ros_gaitWideBased", "gait.wideBased"],
  ["ros_gaitAbductorLurch", "gait.abductorLurch"],
  ["ros_gaitParetic", "gait.paretic"],
  ["ros_gaitShuffling", "gait.shuffling"],
  ["ros_gaitAtaxic", "gait.ataxic"],
  ["ros_gaitOther", "gait.other"],
  ["ros_gaitComment", "gait.comment"],
  [
    "gait_recoTakeMedicationsAsPescribedVal",
    "recommendationsGait.recommendationsGaitSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "gait_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsGait.recommendationsGaitSubAnswers[0].comment",
  ],
  [
    "gait_recoOtherVal",
    "recommendationsGait.recommendationsGaitSubAnswers[1].other",
  ],
  [
    "gait_RecommendationsotherCommentVal",
    "recommendationsGait.recommendationsGaitSubAnswers[1].comment",
  ],

  //respiratory
  ["ros_respiratoryVal", "respiratory.isRespiratory"],
  //["respiratoryNo","respiratory.isRespiratory"],
  ["ros_respiratoryComment", "respiratory.comment"],
  [
    "ros_respiratoryDiagnosesComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.comment",
  ],
  //

  [
    "ros_diagnosesAcutePulmonary",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonary",
  ],
  [
    "ros_acutePulmonaryActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.active",
  ],
  [
    "ros_acutePulmonarySubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.comment",
  ],
  [
    "ros_acutePulmonaryHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.historyOf",
  ],
  [
    "ros_acutePulmonaryruleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.ruleOut",
  ],
  [
    "ros_acutePulmonaryChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_acutePulmonarySubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.comment",
  ],
  [
    "ros_acutePulmonaryCtAngiogram",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.ctAngiogram",
  ],
  [
    "ros_acutePulmonaryDDimer",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.dDimer",
  ],
  [
    "ros_acutePulmonaryHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_acutePulmonaryShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_acutePulmonaryUseOfAnticoagulation",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.useOfAnticoagulation",
  ],
  [
    "ros_acutePulmonaryVenousDoppler",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.venousDoppler",
  ],
  [
    "ros_acutePulmonaryVqScan",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.vqScan",
  ],
  [
    "ros_acutePulmonaryWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.wheezing",
  ],

  [
    "ros_diagnosesAcuteUpper",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpper",
  ],
  [
    "ros_acuteUpperActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.active",
  ],
  [
    "ros_acuteUpperSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.comment",
  ],
  [
    "ros_acuteUpperHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.historyOf",
  ],
  [
    "ros_acuteUpperRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.ruleOut",
  ],
  [
    "ros_acuteUpperChestXRay",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.chestXRay",
  ],
  [
    "ros_acuteUpperSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.comment",
  ],
  [
    "ros_acuteUpperChills",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.chills",
  ],
  [
    "ros_acuteUpperChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_acuteUpperCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.cough",
  ],
  [
    "ros_acuteUpperDecreasedBreathe",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.decreasedBreathe",
  ],
  [
    "ros_acuteUpperFever",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.fever",
  ],
  [
    "ros_acuteUpperRales",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.rales",
  ],
  [
    "ros_acuteUpperShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_acuteUpperWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_acuteUpperOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesAsthma",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthma",
  ],
  [
    "ros_asthmaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.active",
  ],
  [
    "ros_asthmaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.comment",
  ],
  [
    "ros_asthmaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.historyOf",
  ],
  [
    "ros_asthmaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.ruleOut",
  ],

  [
    "ros_asthmaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_asthmaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_asthmaCyanosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.cyanosis",
  ],
  [
    "ros_asthmaUseOfBronchodilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.useOfBronchodilator",
  ],
  [
    "ros_asthmaUseOfInhaled",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.useOfInhaled",
  ],
  [
    "ros_asthmaUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.useOfVentilator",
  ],
  [
    "ros_asthmaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_asthmaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_asthmaOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.other",
  ],

  [
    "ros_asthmaPatientControllerVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.patientController.isPatientController",
  ],
  [
    "ros_asthmaPatientControllerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.patientController.comment",
  ],
  [
    "ros_asthmaRescueMedicationsVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.rescueMedications.isRescueMedications",
  ],
  [
    "ros_asthmaRescueMedicationsComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.rescueMedications.comment",
  ],
  [
    "ros_asthmaCurrentExacerbationVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.CurrentExacerbation.isCurrentExacerbation",
  ],
  [
    "ros_asthmaCurrentExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.comment",
  ],

  [
    "ros_diagnosesChronicPulmonaryEmbolism",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolism",
  ],
  [
    "ros_chronicPulmonaryEmbolismSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.comment",
  ],
  [
    "ros_chronicPulmonaryEmbolismActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.active",
  ],
  [
    "ros_chronicPulmonaryEmbolismHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.historyOf",
  ],
  [
    "ros_chronicPulmonaryEmbolismRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.ruleOut",
  ],

  [
    "ros_chronicPulmonaryEmbolismHistoryOfPul",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.historyOfPul",
  ],
  [
    "ros_chronicPulmonaryEmbolismSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.comment",
  ],
  [
    "ros_chronicPulmonaryEmbolismInsersionOfVena",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.insersionOfVena",
  ],
  [
    "ros_chronicPulmonaryEmbolismAnticoagulation",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_chronicPulmonaryEmbolismShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_chronicPulmonaryEmbolismWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_chronicPulmonaryEmbolismOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesChronicRespiratoryFailure",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailure",
  ],
  [
    "ros_chronicRespiratoryFailureSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.comment",
  ],
  [
    "ros_chronicRespiratoryFailureActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.active",
  ],
  [
    "ros_chronicRespiratoryFailureHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_chronicRespiratoryFailureRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.ruleOut",
  ],

  [
    "ros_chronicRespiratoryFailureHistoryOfHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.historyOfHospitalization",
  ],
  [
    "ros_chronicRespiratoryFailureSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.comment",
  ],
  [
    "ros_chronicRespiratoryFailureChronicUseOfO2",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.chronicUseOfO2",
  ],
  [
    "ros_chronicRespiratoryFailureCarbonDioxideRetention",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.carbonDioxideRetention",
  ],
  [
    "ros_chronicRespiratoryFailureShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_chronicRespiratoryFailureChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_chronicRespiratoryFailureUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.useOfVentilator",
  ],
  [
    "ros_chronicRespiratoryFailureWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_chronicRespiratoryFailureOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesChronicSputumFailure",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailure",
  ],
  [
    "ros_chronicSputumFailureSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.comment",
  ],
  [
    "ros_chronicSputumFailureActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.active",
  ],
  [
    "ros_chronicSputumFailureHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_chronicSputumFailureRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.ruleOut",
  ],

  [
    "ros_chronicSputumFailureBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_chronicSputumFailureSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.comment",
  ],
  [
    "ros_chronicSputumFailureChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_chronicSputumFailureDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.dme",
  ],
  [
    "ros_chronicSputumFailureHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.history",
  ],
  [
    "ros_chronicSputumFailureImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_chronicSputumFailureMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.medications",
  ],
  [
    "ros_chronicSputumFailurePhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_chronicSputumFailureShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_chronicSputumFailureOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.other",
  ],
  [
    "ros_chronicSputumFailureSymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_chronicSputumFailureTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_chronicSputumFailureWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.wheezing",
  ],

  ["ros_diagnosesCopd", "respiratory.isRespiratorySubAnswer.diagnoses.copd"],
  [
    "ros_copdActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.active",
  ],
  [
    "ros_copdSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.comment",
  ],
  [
    "ros_copdHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.historyOf",
  ],
  [
    "ros_copdRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.ruleOut",
  ],

  [
    "ros_copdXuseOfAccessaryMuscles",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.xuseOfAccessaryMuscles",
  ],
  [
    "ros_copdSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.comment",
  ],
  [
    "ros_copdBarrelChest",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.barrelChest",
  ],
  [
    "ros_copdXrResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.xrResults",
  ],
  [
    "ros_copdWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_copdClubbing",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.clubbing",
  ],
  [
    "ros_copdDecreasedOrProlongedSounds",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.decreasedOrProlongedSounds",
  ],
  [
    "ros_copdDyspneaOnExertion",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.dyspneaOnExertion",
  ],
  [
    "ros_copdOxygenUse",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.oxygenUse",
  ],
  [
    "ros_copdBronchodilatorMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.bronchodilatorMedication",
  ],
  [
    "ros_copdShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_copdChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_copdOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.other",
  ],

  [
    "ros_patientChronicBronchitisVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientChronicBronchitis.ispatientChronicBronchitis",
  ],
  [
    "ros_patientChronicBronchitisComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientChronicBronchitis.comment",
  ],
  [
    "ros_patientHaveEmphysemaVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientHaveEmphysema.isPatientHaveEmphysema",
  ],
  [
    "ros_patientHaveEmphysemaComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientHaveEmphysema.comment",
  ],
  [
    "ros_patientBronchodilatorVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.ispatientBronchodilator",
  ],
  [
    "ros_patientBronchodilatorComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.comment",
  ],
  [
    "ros_patientSteroidsVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.isPatientSteroids",
  ],
  [
    "ros_patientSteroidsComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.comment",
  ],
  [
    "ros_patientExacerbation",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.isPatientExacerbation",
  ],
  [
    "ros_patientExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.comment",
  ],

  [
    "ros_diagnosesCysticFibrosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosis",
  ],
  [
    "ros_cysticFibrosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.active",
  ],
  [
    "ros_cysticFibrosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.comment",
  ],
  [
    "ros_cysticFibrosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_cysticFibrosisRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.ruleOut",
  ],

  [
    "ros_cysticFibrosisBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_cysticFibrosisSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_cysticFibrosisChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cysticFibrosisDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_cysticFibrosisHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.history",
  ],
  [
    "ros_cysticFibrosisImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_cysticFibrosisMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cysticFibrosisPhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_cysticFibrosisShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cysticFibrosisOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_cysticFibrosisSymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_cysticFibrosisTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_cysticFibrosisWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.wheezing",
  ],

  [
    "ros_diagnosesHypoventilation",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilation",
  ],
  [
    "ros_hypoventilationActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.active",
  ],
  [
    "ros_hypoventilationSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.comment",
  ],
  [
    "ros_hypoventilationHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.historyOf",
  ],
  [
    "ros_hypoventilationRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.ruleOut",
  ],

  [
    "ros_hypoventilationMorbidObesity",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.morbidObesity",
  ],
  [
    "ros_hypoventilationSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hypoventilationUseOfOxygen",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.useOfOxygen",
  ],
  [
    "ros_hypoventilationCarbonDioxideRetention",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.carbonDioxideRetention",
  ],
  [
    "ros_hypoventilationShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_hypoventilationWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_hypoventilationChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_hypoventilationOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHypoxemia",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemia",
  ],
  [
    "ros_hypoxemiaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.active",
  ],
  [
    "ros_hypoxemiaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.comment",
  ],
  [
    "ros_hypoxemiaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_hypoxemiaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.ruleOut",
  ],

  [
    "ros_hypoxemiaOxygenSaturation",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.oxygenSaturation",
  ],
  [
    "ros_hypoxemiaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hypoxemiaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_hypoxemiaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_hypoxemiaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_hypoxemiaOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.other",
  ],

  ["ros_pneumonia", "respiratory.isRespiratorySubAnswer.diagnoses.pneumonia"],
  [
    "ros_pneumoniaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.active",
  ],
  [
    "ros_pneumoniaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.comment",
  ],
  [
    "ros_pneumoniaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_pneumoniaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.ruleOut",
  ],

  [
    "ros_diagnosesPneumoniaHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_pneumoniaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_pneumoniaPhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_pneumoniaImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_pneumoniaLabStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.labStudies",
  ],
  [
    "ros_pneumoniaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_pneumoniaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_pneumoniaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_pneumoniaOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.other",
  ],

  [
    "ros_pneumoniaViral",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.viral",
  ],
  [
    "ros_pneumoniaSubAnswerEtiologyComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.comment",
  ],
  [
    "ros_pneumoniaPneumococcal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.pneumococcal",
  ],
  [
    "ros_pneumoniaStaph",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.staph",
  ],
  [
    "ros_pneumoniaOtherBacterial",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.otherBacterial",
  ],
  [
    "ros_historyOfLungAbscessVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfLungAbscess.isHistoryOfLungAbscess",
  ],
  [
    "ros_historyOfLungAbscessComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfLungAbscess.comment",
  ],
  [
    "ros_historyOfEmpyemaVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfEmpyema.isHistoryOfEmpyema",
  ],
  [
    "ros_historyOfEmpyemaComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfEmpyema.comment",
  ],

  [
    "ros_diagnosesPulmonaryFibrosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosis",
  ],
  [
    "ros_pulmonaryFibrosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.active",
  ],
  [
    "ros_pulmonaryFibrosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.comment",
  ],
  [
    "ros_pulmonaryFibrosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_pulmonaryFibrosisRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.ruleOut",
  ],

  [
    "ros_pulmonaryFibrosisXRay",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.xRay",
  ],
  [
    "ros_pulmonaryFibrosisSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_pulmonaryFibrosisPft",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.pft",
  ],
  [
    "ros_pulmonaryFibrosisBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_pulmonaryFibrosisMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_pulmonaryFibrosisShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_pulmonaryFibrosisWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_pulmonaryFibrosisChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_pulmonaryFibrosisOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesRespiratorDependence",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependence",
  ],
  [
    "ros_respiratorDependenceActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.active",
  ],
  [
    "ros_respiratorDependenceSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.comment",
  ],
  [
    "ros_respiratorDependenceHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratorDependenceRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.ruleOut",
  ],

  [
    "ros_respiratorDependenceHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.history",
  ],
  [
    "ros_respiratorDependenceSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.comment",
  ],
  [
    "ros_respiratorDependenceSymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_respiratorDependencePhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_respiratorDependenceMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.medications",
  ],
  [
    "ros_respiratorDependenceTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_respiratorDependenceImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_respiratorDependenceBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_respiratorDependenceDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.dme",
  ],
  [
    "ros_respiratorDependenceShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratorDependenceWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratorDependenceChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratorDependenceOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesRespiratoryArrest",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrest",
  ],
  [
    "ros_respiratoryArrestSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryArrestActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryArrestHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryArrestRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.ruleOut",
  ],

  [
    "ros_respiratoryArrestHistoryOfHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.historyOfHospitalization",
  ],
  [
    "ros_respiratoryArrestSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.comment",
  ],
  [
    "ros_respiratoryArrestUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.useOfVentilator",
  ],
  [
    "ros_respiratoryArrestCarbonDioxideRetention",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.carbonDioxideRetention",
  ],
  [
    "ros_respiratoryArrestShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryArrestTestWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryArrestChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryArrestOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesSarcoidosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosis",
  ],
  [
    "ros_sarcoidosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.active",
  ],
  [
    "ros_sarcoidosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.comment",
  ],
  [
    "ros_sarcoidosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_sarcoidosisRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.ruleOut",
  ],

  [
    "ros_sarcoidosisLung",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.lung",
  ],
  [
    "ros_sarcoidosisSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_sarcoidosisLymphNodes",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.lymphNodes",
  ],
  [
    "ros_sarcoidosisSkin",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.skin",
  ],
  [
    "ros_sarcoidosisCranialNerves",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.cranialNerves",
  ],
  [
    "ros_sarcoidosisTestUnKnown",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.unKnown",
  ],

  [
    "ros_sarcoidosisDiagnosticResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.diagnosticResults",
  ],
  [
    "ros_sarcoidosisSubAnswerSpecifyComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.comment",
  ],
  [
    "ros_sarcoidosisCutaneousLesions",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.cutaneousLesions",
  ],
  [
    "ros_sarcoidosisShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_sarcoidosisWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_sarcoidosisTestChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_sarcoidosisOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesSleepApnea",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApnea",
  ],
  [
    "ros_sleepApneaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.active",
  ],
  [
    "ros_sleepApneaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.comment",
  ],
  [
    "ros_sleepApneaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.historyOf",
  ],
  [
    "ros_sleepApneaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.ruleOut",
  ],

  [
    "ros_sleepApneaUseOfCpap",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.useOfCpap",
  ],
  [
    "ros_sleepApneaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_sleepApneaPositiveSleepStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.positiveSleepStudies",
  ],
  [
    "ros_sleepApneaHistoryOfSleepiness",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.historyOfSleepiness",
  ],
  [
    "ros_sleepApneaHeavySnoring",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.heavySnoring",
  ],
  [
    "ros_sleepApneaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_sleepApneaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_sleepApneaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_sleepApneaOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.other",
  ],

  ["ros_diagnosesOther", "respiratory.isRespiratorySubAnswer.diagnoses.other"],
  [
    "ros_otherActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_otherSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_otherHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_otherRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],

  [
    "ros_otherBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_otherSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_otherChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_otherDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_otherHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_otherImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_otherMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_otherPhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_otherShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_otherSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_otherSymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_otherTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_otherWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_otherSubAnswerOtherSubText",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "respiratory_recoTakeMedicationsAsPescribedVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "respiratory_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[0].comment",
  ],
  [
    "respiratory_recoOtherVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[1].other",
  ],
  [
    "respiratory_RecommendationsotherCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[1].comment",
  ],
  //integument

  ["ros_integumentVal", "integument.isIntegument"],
  ["ros_integumentComment", "integument.comment"],
  [
    "ros_integumentDiagnosesComment",
    "integument.isIntegumentSubAnswer.diagnoses.comment",
  ],

  [
    "ros_diagnosesIntegumentBasilCellCarcinoma",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinoma",
  ],
  [
    "ros_basilCellCarcinomaActive",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.active",
  ],
  [
    "ros_basilCellCarcinomaSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.comment",
  ],
  [
    "ros_basilCellCarcinomaHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.historyOf",
  ],
  [
    "ros_basilCellCarcinomaRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_basilCellCarcinomaHistory",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.history",
  ],
  [
    "ros_basilCellCarcinomaSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_basilCellCarcinomaSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_basilCellCarcinomaPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_basilCellCarcinomaMedications",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_basilCellCarcinomaTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_basilCellCarcinomaImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_basilCellCarcinomaBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_basilCellCarcinomaDme",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_basilCellCarcinomaOther",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesIntegumentDermatitis",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitis",
  ],
  [
    "ros_dermatitisActive",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.active",
  ],
  [
    "ros_dermatitisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.comment",
  ],
  [
    "ros_dermatitisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_dermatitisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_dermatitisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.history",
  ],
  [
    "ros_dermatitisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_dermatitisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_dermatitisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_dermatitisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_dermatitisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_dermatitisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_dermatitisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_dermatitisDme",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_dermatitisOther",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_dermatitisContact",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.contact",
  ],
  [
    "ros_dermatitisSubAnswerWhatTypeComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.comment",
  ],
  [
    "ros_dermatitisStasis",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.stasis",
  ],
  [
    "ros_dermatitisDrugInduced",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.drugInduced",
  ],
  [
    "ros_dermatitisDiseaseInduced",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.diseaseInduced",
  ],
  [
    "ros_dermatitisUnspecified",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.unspecified",
  ],

  [
    "ros_diagnosesIntegumentEczema",
    "integument.isIntegumentSubAnswer.diagnoses.eczema",
  ],
  [
    "ros_eczemaActive",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.active",
  ],
  [
    "ros_eczemaSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.comment",
  ],
  [
    "ros_eczemaHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.historyOf",
  ],
  [
    "ros_eczemaRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eczemaHistory",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.history",
  ],
  [
    "ros_eczemaSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eczemaSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_eczemaPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eczemaMedications",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eczemaTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eczemaImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eczemaBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eczemaDme",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eczemaOther",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesIntegumentOnychomysosis",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosis",
  ],
  [
    "ros_onychomycosisActive",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.active",
  ],
  [
    "ros_onychomycosisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.comment",
  ],
  [
    "ros_onychomycosisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_onychomycosisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_onychomycosisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.history",
  ],
  [
    "ros_onychomycosisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_onychomycosisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_onychomycosisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_onychomycosisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_onychomycosisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_onychomycosisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_onychomycosisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_onychomycosisDme",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_onychomycosisOther",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesIntegumentPsoriasis",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasis",
  ],
  [
    "ros_psoriasisActive",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.active",
  ],
  [
    "ros_psoriasisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.comment",
  ],
  [
    "ros_psoriasisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.historyOf",
  ],
  [
    "ros_psoriasisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_psoriasisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.history",
  ],
  [
    "ros_psoriasisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_psoriasisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_psoriasisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_psoriasisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_psoriasisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_psoriasisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_psoriasisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_psoriasisDme",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_psoriasisOther",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.other",
  ],
  [
    "ros_psoriasisSubAnswerPsoriaticArthritisVal",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.psoriaticArthritis.isPsoriaticArthritis",
  ],
  [
    "ros_psoriasisSubAnswerPsoriaticArthritisComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.psoriaticArthritis.comment",
  ],

  [
    "ros_diagnosesIntegumentSkinUlcer",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcer",
  ],
  [
    "ros_skinUlcerSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.comment",
  ],
  [
    "ros_skinUlcerActive",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.active",
  ],
  [
    "ros_skinUlcerHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.historyOf",
  ],
  [
    "ros_skinUlcerRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.ruleOut",
  ],
  [
    "ros_skinUlcerHistory",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.history",
  ],
  [
    "ros_skinUlcerSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_skinUlcerSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.comment",
  ],
  [
    "ros_skinUlcerPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_skinUlcerMedications",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.medications",
  ],
  [
    "ros_skinUlcerTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_skinUlcerImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_skinUlcerBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_skinUlcerDme",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.dme",
  ],
  [
    "ros_skinUlcerOther",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.other",
  ],
  [
    "ros_skinUlcerPressure",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.pressure",
  ],
  [
    "ros_skinUlcerVenousStatis",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.venousStatis",
  ],
  [
    "ros_skinUlcerSubAnswerEtiologyComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.comment",
  ],
  [
    "ros_skinUlcerPeripheralVascular",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.peripheralVascular",
  ],
  [
    "ros_skinUlcerDiseaseInduced",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diseaseInduced",
  ],
  [
    "ros_skinUlcerDiabeticVasculitis",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diabeticVasculitis",
  ],
  [
    "ros_skinUlcerDiabeticNeuropathy",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diabeticNeuropathy",
  ],

  [
    "ros_diagnosesIntegumentTineaPedis",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedis",
  ],
  [
    "ros_tineaPedisActive",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.active",
  ],
  [
    "ros_tineaPedisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.historyOf",
  ],
  [
    "ros_tineaPedisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.comment",
  ],
  [
    "ros_tineaPedisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_tineaPedisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.history",
  ],
  [
    "ros_tineaPedisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_tineaPedisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_tineaPedisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_tineaPedisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_tineaPedisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_tineaPedisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_tineaPedisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_tineaPedisDme",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_tineaPedisOther",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesIntegumentUrticarialDisease",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDisease",
  ],
  [
    "ros_urticarialDiseaseActive",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_urticarialDiseaseSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_urticarialDiseaseHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_urticarialDiseaseRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_urticarialDiseaseHistory",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.history",
  ],
  [
    "ros_urticarialDiseaseSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_urticarialDiseaseSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_urticarialDiseasePhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_urticarialDiseaseMedications",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_urticarialDiseaseTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_urticarialDiseaseImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_urticarialDiseaseBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_urticarialDiseaseDme",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.dme",
  ],
  [
    "ros_urticarialDiseaseOther",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_urticarialDiseaseAcute",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.acute",
  ],
  [
    "ros_urticarialDiseaseSubAnswerTypeComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.comment",
  ],
  [
    "ros_urticarialDiseaseChronic",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.chronic",
  ],
  [
    "ros_urticarialDiseaseSubAnswerEtiologySubText",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.etiology",
  ],

  [
    "ros_diagnosesIntegumentWound",
    "integument.isIntegumentSubAnswer.diagnoses.wound",
  ],
  [
    "ros_woundActive",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.active",
  ],
  [
    "ros_woundHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.historyOf",
  ],
  [
    "ros_woundSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.comment",
  ],
  [
    "ros_woundRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.ruleOut",
  ],
  [
    "ros_woundHistory",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.history",
  ],
  [
    "ros_woundSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_woundSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.comment",
  ],
  [
    "ros_woundPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_woundMedications",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.medications",
  ],
  [
    "ros_woundTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_woundImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_woundBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_woundDme",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.dme",
  ],
  [
    "ros_woundOther",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.other",
  ],
  [
    "ros_woundSurgical",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.surgical",
  ],
  [
    "ros_woundSubAnswerEtiologyComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.comment",
  ],
  [
    "ros_woundTraumatic",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.traumatic",
  ],
  [
    "ros_woundBurn",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.burn",
  ],

  [
    "ros_diagnosesIntegumentOther",
    "integument.isIntegumentSubAnswer.diagnoses.other",
  ],
  [
    "ros_otherActive",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_othersSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_otherHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_otherRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_otherHistory",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_otherSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_otherPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_othersSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_otherMedications",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_otherTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_otherImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_otherBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_otherDme",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_IntegumentOtherSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentOthersSubAnswerOtherSubText",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "integument_recoTakeMedicationsAsPescribedVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "integument_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[0].comment",
  ],
  [
    "integument_recoOtherVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[1].other",
  ],
  [
    "integument_RecommendationsotherCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[1].comment",
  ],

  //musko
  // ["musculoskeletalYes", "musculoskeletal.isMusculoskeletal"],
  // ["musculoskeletalNo", "musculoskeletal.isMusculoskeletal"],
  ["ros_musculoskeletalVal", "musculoskeletal.isMusculoskeletal"],
  ["ros_musculoskeletalComment", "musculoskeletal.comment"],
  [
    "ros_musculoskeletalDiagnosesComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.comment",
  ],

  [
    "ros_diagnosesMuskoCollagen",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagen",
  ],
  [
    "ros_collagenActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.active",
  ],
  [
    "ros_collagenSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.comment",
  ],
  [
    "ros_collagenHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.historyOf",
  ],
  [
    "ros_collagenRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.ruleOut",
  ],
  [
    "ros_collagenPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_collagenSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.comment",
  ],
  [
    "ros_collagenLabTests",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_collagenBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_collagenImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_collagenOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.other",
  ],
  [
    "ros_collagenSleLupus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.sleLupus",
  ],
  [
    "ros_collagenSubAnswercollagenDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.collagenDescribe.comment",
  ],
  [
    "ros_collagenScleroderma",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.scleroderma",
  ],
  [
    "ros_collagenDermatomyositis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.dermatomyositis",
  ],

  [
    "ros_diagnosesMuskoDegenerativeDiscDisease",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDisease",
  ],
  [
    "ros_degenerativeDiscDiseaseActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_degenerativeDiscDiseaseHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_degenerativeDiscDiseaseRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_degenerativeDiscDiseaseSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_degenerativeDiscDiseasePhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_degenerativeDiscDiseaseImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_degenerativeDiscDiseaseMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_degenerativeDiscDiseaseOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerNormalBladderVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.normalBladder.isNormalBladder",
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerNormalBladderComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.normalBladder.comment",
  ],
  [
    "ros_siteOfDiseaseCervical",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.cervical",
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.comment",
  ],
  [
    "ros_siteOfDiseaseThoracic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.thoracic",
  ],
  [
    "ros_siteOfDiseaseLumbar",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.lumbar",
  ],
  [
    "ros_siteOfDiseaseLumbosacral",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.lumbosacral",
  ],
  [
    "ros_siteOfDiseaseOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.other",
  ],

  [
    "ros_diagnosesMuskoExtremityFracture",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFracture",
  ],
  [
    "ros_extremityFractureActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.active",
  ],
  [
    "ros_extremityFractureSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.comment",
  ],
  [
    "ros_extremityFractureHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOf",
  ],
  [
    "ros_extremityFractureRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_extremityFractureActiveTraumatic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeTypeSubAnswer.traumatic",
  ],
  [
    "ros_extremityFractureActivePathological",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeTypeSubAnswer.pathological",
  ],
  [
    "ros_extremityFractureHistoryOfTraumatic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.historyOf.historyOfSubAnswer.traumatic",
  ],
  [
    "ros_extremityFractureHistoryOfPathological",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.historyOf.historyOfSubAnswer.pathological",
  ],
  [
    "ros_extremityFractureHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.history",
  ],
  [
    "ros_extremityFractureSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_extremityFracturePhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_extremityFractureSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.comment",
  ],
  [
    "ros_extremityFractureMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.medications",
  ],
  [
    "ros_extremityFractureTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_extremityFractureImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_extremityFractureBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_extremityFractureDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.dme",
  ],
  [
    "ros_extremityFractureOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.other",
  ],
  [
    "ros_extremityFractureSubAnswerExtremityComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.comment",
  ],

  [
    "ros_extremityFractureShoulderLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.left",
  ],
  [
    "ros_extremityFractureShoulderRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.right",
  ],
  [
    "ros_extremityFractureArmLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.left",
  ],
  [
    "ros_extremityFractureArmRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.right",
  ],
  [
    "ros_extremityFractureForearmLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.left",
  ],
  [
    "ros_extremityFractureForearmRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.right",
  ],
  [
    "ros_extremityFractureWristLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.left",
  ],
  [
    "ros_extremityFractureWristRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.right",
  ],
  [
    "ros_extremityFractureHandLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.left",
  ],
  [
    "ros_extremityFractureHandRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.right",
  ],
  [
    "ros_extremityFractureFemoralShaftLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.left",
  ],
  [
    "ros_extremityFractureFemoralShaftRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.right",
  ],
  [
    "ros_extremityFractureTibiaLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.left",
  ],
  [
    "ros_extremityFractureTibiaRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.right",
  ],
  [
    "ros_extremityFractureFibulaLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.left",
  ],
  [
    "ros_extremityFractureFibulaRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.right",
  ],
  [
    "ros_extremityFractureAnkleLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.left",
  ],
  [
    "ros_extremityFractureAnkleRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.right",
  ],
  [
    "ros_extremityFractureFootLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.left",
  ],
  [
    "ros_extremityFractureFootRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.right",
  ],
  [
    "ros_extremityFractureHipLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.left",
  ],
  [
    "ros_extremityFractureHipRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.right",
  ],
  [
    "ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.currentWithinTwelveWeeks.isCurrentWithinTwelveWeeks",
  ],
  [
    "ros_extremityFractureCurrentWithinTwelveWeeksSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.currentWithinTwelveWeeks.comment",
  ],

  [
    "ros_diagnosesMuskoGout",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.gout",
  ],
  [
    "ros_goutActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.active",
  ],
  [
    "ros_goutSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.comment",
  ],
  [
    "ros_goutHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.historyOf",
  ],
  [
    "ros_goutRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.ruleOut",
  ],
  [
    "ros_goutSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.comment",
  ],
  [
    "ros_goutHistoryOfAttacks",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.historyOfAttacks",
  ],
  [
    "ros_goutLabTests",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_goutMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.medications",
  ],
  [
    "ros_goutOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesMuskoHalluxValgus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgus",
  ],
  [
    "ros_halluxValgusActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.active",
  ],
  [
    "ros_halluxValgusSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.comment",
  ],
  [
    "ros_halluxValgusHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.historyOf",
  ],
  [
    "ros_halluxValgusRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.ruleOut",
  ],
  [
    "ros_halluxValgusHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.history",
  ],
  [
    "ros_halluxValgusSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_halluxValgusSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.comment",
  ],
  [
    "ros_halluxValgusPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_halluxValgusMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.medications",
  ],
  [
    "ros_halluxValgusTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_halluxValgusImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_halluxValgusBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_halluxValgusDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.dme",
  ],
  [
    "ros_halluxValgusOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesMuskoHammerToes",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToes",
  ],
  [
    "ros_hammerToesActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.active",
  ],
  [
    "ros_hammerToesSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.comment",
  ],
  [
    "ros_hammerToesHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.historyOf",
  ],
  [
    "ros_hammerToesRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hammerToesHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.history",
  ],
  [
    "ros_hammerToesSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hammerToesSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hammerToesPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_hammerToesMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hammerToesTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_hammerToesImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_hammerToesBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_hammerToesDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.dme",
  ],
  [
    "ros_hammerToesOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesMuskoOsteoarthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritis",
  ],
  [
    "ros_osteoarthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.active",
  ],
  [
    "ros_osteoarthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_osteoarthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.comment",
  ],
  [
    "ros_osteoarthritisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_osteoarthritisHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.history",
  ],
  [
    "ros_osteoarthritisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_osteoarthritisPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_osteoarthritisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_osteoarthritisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_osteoarthritisOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.other",
  ],
  [
    "ros_osteoarthritisJointSwelling",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.jointSwelling",
  ],
  [
    "ros_osteoarthritisJointStiffness",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.jointStiffness",
  ],
  [
    "ros_osteoarthritisPain",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_osteoarthritisLimitedRom",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.limitedRom",
  ],
  [
    "ros_osteoarthritisSubAnswerWhichJointsSubText",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.whichJoints",
  ],

  [
    "ros_diagnosesMuskoOsteomyelitis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitis",
  ],
  [
    "ros_osteomyelitisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.active",
  ],
  [
    "ros_osteomyelitisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.comment",
  ],
  [
    "ros_osteomyelitisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_osteomyelitisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_osteomyelitisHospitalization",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_osteomyelitisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_osteomyelitisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_osteomyelitisCultures",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_osteomyelitisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_osteomyelitisOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesMuskoOsteoporosis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosis",
  ],
  [
    "ros_osteoporosisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.active",
  ],
  [
    "ros_osteoporosisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.comment",
  ],
  [
    "ros_osteoporosisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_osteoporosisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_osteoporosisDexaScan",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.dexaScan",
  ],
  [
    "ros_osteoporosisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_osteoporosisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_osteoporosisImagingStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.imagingStudies",
  ],
  [
    "ros_osteoporosisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_osteoporosisFractureHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.fractureHistory",
  ],
  [
    "ros_osteoporosisOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesMuskoPyogenicArthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritis",
  ],
  [
    "ros_pyogenicArthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.active",
  ],
  [
    "ros_pyogenicArthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.comment",
  ],
  [
    "ros_pyogenicArthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_pyogenicArthritisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_pyogenicArthritisHospitalization",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_pyogenicArthritisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_pyogenicArthritisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_pyogenicArthritisCultures",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_pyogenicArthritisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_pyogenicArthritisOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.other",
  ],
  [
    "ros_pyogenicArthritisSubAnswerWhichJointsSubText",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.whichJoints",
  ],

  [
    "ros_diagnosesMuskoRheumatoidArthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritis",
  ],
  [
    "ros_rheumatoidArthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.active",
  ],
  [
    "ros_rheumatoidArthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.comment",
  ],
  [
    "ros_rheumatoidArthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_rheumatoidArthritisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_rheumatoidArthritisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_rheumatoidArthritisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_rheumatoidArthritisPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_rheumatoidArthritisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_rheumatoidArthritisLabTests",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_rheumatoidArthritisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_rheumatoidArthritisOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.other",
  ],
  [
    "ros_rheumatoidArthritisJointSwelling",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.jointSwelling",
  ],
  [
    "ros_rheumatoidArthritisJointStiffness",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.jointStiffness",
  ],
  [
    "ros_rheumatoidArthritisPain",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.pain",
  ],
  [
    "ros_rheumatoidArthritisLimitedRom",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.limitedRom",
  ],
  [
    "ros_rheumatoidArthritisSubAnswerWhichJointsSubText",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.whichJoints",
  ],

  [
    "ros_diagnosesMuskoSpinalStenosis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosis",
  ],
  [
    "ros_spinalStenosisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.active",
  ],
  [
    "ros_spinalStenosisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.comment",
  ],
  [
    "ros_spinalStenosisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_spinalStenosisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_spinalStenosisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_spinalStenosisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_spinalStenosisPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_spinalStenosisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_spinalStenosisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_spinalStenosisOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_spinalStenosisSubAnswerNormalBladderVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.normalBladder.isNormalBladder",
  ],
  [
    "ros_spinalStenosisSubAnswerNormalBladderComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.normalBladder.comment",
  ],

  [
    "ros_diagnosesMuskoSystemicLupus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupus",
  ],
  [
    "ros_systemicLupusActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.active",
  ],
  [
    "ros_systemicLupusSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.comment",
  ],
  [
    "ros_systemicLupusHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.historyOf",
  ],
  [
    "ros_systemicLupusRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.ruleOut",
  ],
  [
    "ros_systemicLupusLabs",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.labs",
  ],
  [
    "ros_systemicLupusSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.comment",
  ],
  [
    "ros_systemicLupusMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.medications",
  ],
  [
    "ros_systemicLupusHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.history",
  ],
  [
    "ros_systemicLupusOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesMuskoOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.other",
  ],
  [
    "ros_otherActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_muskoOtherSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_otherHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_otherRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_otherHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_otherSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_otherPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_otherMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_otherTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_muskoOtherSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_otherImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_otherBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_otherDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_muskoSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_muskoOtherSubAnswerotherSubText",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "ros_hadAnAmputationSubAnswerSubText",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.isHadAnAmputationSubAnswer.describe",
  ],
  [
    "ros_muskoHadAnAmputationVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.isHadAnAmputation",
  ],

  [
    "musko_recoDiscussPtOtVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[0].discussPtOt",
  ],
  [
    "musko_RecommendationsdiscussPtOtCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[0].comment",
  ],
  [
    "musko_recoTakeMedicationsAsPescribedVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[1].takeMedicationsAsPescribed",
  ],
  [
    "musko_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[1].comment",
  ],

  [
    "musko_recoOtherVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[2].other",
  ],
  [
    "musko_RecommendationsotherCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[2].comment",
  ],

  //hematology

  [
    "ros_hematologyVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDisease",
  ],
  ["ros_hematologyComment", "hematologyImmunologyInfectiousDisease.comment"],

  [
    "ros_hematologyDiagnosesComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.comment",
  ],
  [
    "ros_diagnosesHemaAids",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aids",
  ],
  [
    "ros_aidsActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.active",
  ],
  [
    "ros_aidsSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.comment",
  ],
  [
    "ros_aidsHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.historyOf",
  ],
  [
    "ros_aidsRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.ruleOut",
  ],
  [
    "ros_aidsSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_aidsSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.comment",
  ],
  [
    "ros_aidsPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_aidsHistoryOfOpportunistic",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.historyOfOpportunistic",
  ],
  [
    "ros_aidsMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.medications",
  ],
  [
    "ros_aidsOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.other",
  ],
  [
    "ros_aidsSubAnswerPatientUnderTreatmentVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.patientUnderTreatment.isPatientUnderTreatment",
  ],
  [
    "ros_aidsSubAnswerPatientUnderTreatmentComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.patientUnderTreatment.comment",
  ],

  [
    "ros_diagnosesHemaAnemia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemia",
  ],
  [
    "ros_anemiaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.active",
  ],
  [
    "ros_anemiaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.comment",
  ],
  [
    "ros_anemiaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_anemiaRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_anemiaLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_anemiaSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_anemiaSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_anemiaHistoryOfBloodTransfusion",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.historyOfBloodTransfusion",
  ],
  [
    "ros_anemiaOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_anemiaIronDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.ironDeficiency",
  ],
  [
    "ros_anemiaPernicious",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.pernicious",
  ],
  [
    "ros_anemiaSubAnswerEtiologyComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.comment",
  ],
  [
    "ros_anemiaKidneyDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.kidneyDisease",
  ],
  [
    "ros_anemiaHemolysis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.hemolysis",
  ],
  [
    "ros_anemiaAplastic",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.aplastic",
  ],
  [
    "ros_anemiaChemotherapy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.chemotherapy",
  ],
  [
    "ros_anemiaBloodLoss",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.bloodLoss",
  ],
  [
    "ros_anemiaChronicDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.chronicDisease",
  ],
  [
    "ros_anemiaFolateDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.folateDeficiency",
  ],
  [
    "ros_anemiaOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.other",
  ],
  [
    "ros_anemiaIron",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.iron",
  ],
  [
    "ros_anemiaBTwelve",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.bTwelve",
  ],
  [
    "ros_anemiaSubAnswerYesPatientOnComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.comment",
  ],
  [
    "ros_anemiaFolicAcid",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.folicAcid",
  ],
  [
    "ros_anemiaBloodTransfusions",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.bloodTransfusions",
  ],
  [
    "ros_anemiaOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.other",
  ],

  [
    "ros_diagnosesHemaCDifficile",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficile",
  ],
  [
    "ros_cDifficileActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.active",
  ],
  [
    "ros_cDifficileSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.comment",
  ],
  [
    "ros_cDifficileHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.historyOf",
  ],
  [
    "ros_cDifficileRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cDifficilePositiveLabTest",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.positiveLabTest",
  ],
  [
    "ros_cDifficileSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.comment",
  ],
  [
    "ros_cDifficileSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_cDifficileHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_cDifficileMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.medication",
  ],
  [
    "ros_cDifficileOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaCommunityAcquired",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquired",
  ],
  [
    "ros_communityAcquiredActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.active",
  ],
  [
    "ros_communityAcquiredSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.comment",
  ],
  [
    "ros_communityAcquiredHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.historyOf",
  ],
  [
    "ros_communityAcquiredRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.ruleOut",
  ],
  [
    "ros_communityAcquiredSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.comment",
  ],
  [
    "ros_communityAcquiredCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_communityAcquiredHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_communityAcquiredMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.medications",
  ],
  [
    "ros_communityAcquiredPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_communityAcquiredOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaHiv",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hiv",
  ],
  [
    "ros_hivActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.active",
  ],
  [
    "ros_hivSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.comment",
  ],
  [
    "ros_hivHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.historyOf",
  ],
  [
    "ros_hivRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hivLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_hivSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hivSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hivdMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hivOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.other",
  ],
  [
    "ros_hivSubAnswerViralLoadSubText",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.viralLoad",
  ],
  [
    "ros_hivSubAnswerCFourSubText",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.cFour",
  ],
  [
    "ros_hivSubAnswerPatientSymptomaticVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.patientSymptomatic.isPatientSymptomatic",
  ],
  [
    "ros_hivSubAnswerPatientSymptomaticComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.patientSymptomatic.comment",
  ],
  [
    "ros_hivSubAnswerActiveTreatmentVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.activeTreatment.isActiveTreatment",
  ],
  [
    "ros_hivSubAnswerActiveTreatmentComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_diagnosesHemaHerpesZoster",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZoster",
  ],
  [
    "ros_herpesZosterActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.active",
  ],
  [
    "ros_herpesZosterSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.comment",
  ],
  [
    "ros_herpesZosterHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOf",
  ],
  [
    "ros_herpesZosterRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.ruleOut",
  ],
  [
    "ros_herpesZosterRash",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.rash",
  ],
  [
    "ros_herpesZosterSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.comment",
  ],
  [
    "ros_herpesZosterSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_herpesZosterMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.medications",
  ],
  [
    "ros_herpesZosterOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaMrsaInfection",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfection",
  ],
  [
    "ros_mrsaInfectionActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.active",
  ],
  [
    "ros_mrsaInfectionSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.comment",
  ],
  [
    "ros_mrsaInfectionHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.historyOf",
  ],
  [
    "ros_mrsaInfectionRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_mrsaInfectionCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_mrsaInfectionSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.comment",
  ],
  [
    "ros_mrsaInfectionHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_mrsaInfectionMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.medications",
  ],
  [
    "ros_mrsaInfectionPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_mrsaInfectionOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaImmuneDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiency",
  ],
  [
    "ros_immuneDeficiencyActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.active",
  ],
  [
    "ros_immuneDeficiencySubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.comment",
  ],

  [
    "ros_immuneDeficiencyHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.historyOf",
  ],
  [
    "ros_immuneDeficiencyRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.ruleOut",
  ],
  [
    "ros_immuneDeficiencyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.history",
  ],
  [
    "ros_immuneDeficiencySymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_immuneDeficiencyPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_immuneDeficiencySubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.comment",
  ],
  [
    "ros_immuneDeficiencyMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.medications",
  ],
  [
    "ros_immuneDeficiencyImageStudies",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_immuneDeficiencyOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.other",
  ],
  [
    "ros_immuneDeficiencySubAnswerEtiologySubText",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.etiology",
  ],
  [
    "ros_immuneDeficiencySubAnswerHistoryOfOpportunisticVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.historyOfOpportunistic.isHistoryOfOpportunistic",
  ],
  [
    "ros_immuneDeficiencySubAnswerHistoryOfOpportunisticComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.historyOfOpportunistic.comment",
  ],

  [
    "ros_diagnosesHemaSepsis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsis",
  ],
  [
    "ros_sepsisActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.active",
  ],
  [
    "ros_sepsisSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.comment",
  ],
  [
    "ros_sepsisHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.historyOf",
  ],
  [
    "ros_sepsisRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_sepsisBloodCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.bloodCultures",
  ],
  [
    "ros_sepsisSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_sepsisOtherCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.otherCultures",
  ],
  [
    "ros_sepsisSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_sepsisHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_sepsisUnstableVitalSigns",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.unstableVitalSigns",
  ],
  [
    "ros_sepsisOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaSickleCellDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDisease",
  ],
  [
    "ros_sickleCellDiseaseActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.describe.active",
  ],
  [
    "ros_sickleCellDiseaseTwoSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.describe.comment",
  ],
  [
    "ros_sickleCellDiseaseHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.describe.historyOf",
  ],
  [
    "ros_sickleCellDiseaseRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.describe.ruleOut",
  ],
  [
    "ros_sickleCellDiseaseFamilyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_sickleCellDiseaseTwoSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.supportedBy.comment",
  ],
  [
    "ros_sickleCellDiseaseSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_sickleCellDiseaseHistoryOfInfections",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.supportedBy.historyOfInfections",
  ],
  [
    "ros_sickleCellDiseaseHospitalizations",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.supportedBy.hospitalizations",
  ],
  [
    "ros_sickleCellDiseaseOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseTwoSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaSickleCellTrait",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTrait",
  ],
  [
    "ros_sickleCellTraitActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.active",
  ],
  [
    "ros_sickleCellTraitSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.comment",
  ],
  [
    "ros_sickleCellTraitHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.historyOf",
  ],
  [
    "ros_sickleCellTraitRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.ruleOut",
  ],
  [
    "ros_sickleCellTraitSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.comment",
  ],
  [
    "ros_sickleCellTraitFamilyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_sickleCellTraitLabTest",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.labTest",
  ],
  [
    "ros_sickleCellTraitOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaThalassemia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemia",
  ],
  [
    "ros_thalassemiaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.active",
  ],
  [
    "ros_thalassemiaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.comment",
  ],
  [
    "ros_thalassemiaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_thalassemiaRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_thalassemiaFamilyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_thalassemiaSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_thalassemiaSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_thalassemiaLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_thalassemiaHistoryOfInfections",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.historyOfInfections",
  ],
  [
    "ros_thalassemiaOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaThrombocytopenia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopenia",
  ],
  [
    "ros_thrombocytopeniaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.active",
  ],
  [
    "ros_thrombocytopeniaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.comment",
  ],
  [
    "ros_thrombocytopeniaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_thrombocytopeniaRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_thrombocytopeniaLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_thrombocytopeniaSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_thrombocytopeniaPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_thrombocytopeniaHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.history",
  ],
  [
    "ros_thrombocytopeniaOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_thrombocytopeniaSubAnswerEtiologySubText",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.etiology",
  ],

  [
    "ros_diagnosesHemaTuberculosis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosis",
  ],
  [
    "ros_tuberculosisActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.active",
  ],
  [
    "ros_tuberculosishistoryOfActiveTb",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.historyOfActiveTb",
  ],
  [
    "ros_tuberculosisSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.comment",
  ],
  [
    "ros_tuberculosistbInfection",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.tbInfection",
  ],
  [
    "ros_tuberculosisruleOutActiveTb",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.ruleOutActiveTb",
  ],
  [
    "ros_tuberculosisHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.history",
  ],
  [
    "ros_tuberculosisMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_tuberculosisImaginingStudy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.imaginingStudy",
  ],
  [
    "ros_tuberculosisSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_tuberculosisSkinTest",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.skinTest",
  ],
  [
    "ros_tuberculosisSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_tuberculosisPositiveCulture",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.positiveCulture",
  ],
  [
    "ros_tuberculosisOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_tuberculosisSubAnswerGivenBCGVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.givenBCG.isGivenBCG",
  ],
  [
    "ros_tuberculosisSubAnswerGivenBCGComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.givenBCG.comment",
  ],
  [
    "ros_tuberculosisSubAnswerActiveTuberculosisVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.activeTuberculosis.isActiveTuberculosis",
  ],
  [
    "ros_tuberculosisSubAnswerActiveTuberculosisComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.activeTuberculosis.comment",
  ],
  [
    "ros_tuberculosisSubAnswerTbInfectionVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.tbInfection.isTbInfection",
  ],
  [
    "ros_tuberculosisSubAnswerTbInfectionComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.tbInfection.comment",
  ],

  [
    "ros_diagnosesHemaVitaminDDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiency",
  ],
  [
    "ros_vitaminDDeficiencyActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.active",
  ],
  [
    "vitaminDDeficiencySubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.comment",
  ],
  [
    "ros_vitaminDDeficiencyHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.historyOf",
  ],
  [
    "ros_vitaminDDeficiencyRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.ruleOut",
  ],
  [
    "ros_vitaminDDeficiencyLabs",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.labs",
  ],
  [
    "ros_vitaminDDeficiencySubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.comment",
  ],
  [
    "ros_vitaminDDeficiencyMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.medications",
  ],
  [
    "ros_vitaminDDeficiencyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.history",
  ],
  [
    "ros_vitaminDDeficiencyOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.other",
  ],

  [
    "ros_diagnosesHemaOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.other",
  ],
  [
    "ros_otherActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_hemaOtherSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_otherHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_otherRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_otherHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_otherSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_otherPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_otherMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hemaOtherSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_otherTestResults",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_otherImageStudies",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_otherBiopsy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_otherDme",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_hemaOtherSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_hemaOtherSubAnswerOtherSubText",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  [
    "hema_recoTakeMedicationsAsPescribedVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "hema_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[0].comment",
  ],
  [
    "hema_recoReportAbnormalBruisingVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[1].reportAbnormalBruising",
  ],
  [
    "hema_RecommendationsreportAbnormalBruisingCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[1].comment",
  ],
  [
    "hema_recoFollowUpWithDoctorVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[2].followUpWithDoctor",
  ],
  [
    "hema_RecommendationsfollowUpWithDoctorCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[2].comment",
  ],

  [
    "hema_recoOtherVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[3].other",
  ],
  [
    "hema_RecommendationsotherCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[3].comment",
  ],
];

const ros_optionsTreeMapObject_mehak = {
  //gait
  ros_gaitNormal: "normal",
  ros_gaitLimp: "limp",
  ros_gaitWideBased: "wideBased",
  ros_gaitAbductorLurch: "abductorLurch",
  ros_gaitParetic: "paretic",
  ros_gaitShuffling: "shuffling",
  ros_gaitAtaxic: "ataxic",
  ros_gaitOther: "other",

  //respiratory
  // respiratoryYes: "yes",
  // respiratoryNo: "no",

  ros_diagnosesAcutePulmonary: "acutePulmonary",
  ros_acutePulmonaryActive: "active",
  ros_acutePulmonaryHistoryOf: "historyOf",
  ros_acutePulmonaryruleOut: "ruleOut",
  ros_acutePulmonaryChronicCough: "chronicCough",
  ros_acutePulmonaryCtAngiogram: "ctAngiogram",
  ros_acutePulmonaryDDimer: "dDimer",
  ros_acutePulmonaryHospitalization: "hospitalization",
  ros_acutePulmonaryShortnessOfBreath: "shortnessOfBreath",
  ros_acutePulmonaryUseOfAnticoagulation: "useOfAnticoagulation",
  ros_acutePulmonaryVenousDoppler: "venousDoppler",
  ros_acutePulmonaryVqScan: "vqScan",
  ros_acutePulmonaryWheezing: "wheezing",

  ros_diagnosesAcuteUpper: "acuteUpper",
  ros_acuteUpperActive: "active",
  ros_acuteUpperHistoryOf: "historyOf",
  ros_acuteUpperRuleOut: "ruleOut",
  ros_acuteUpperChestXRay: "chestXRay",
  ros_acuteUpperChills: "chills",
  ros_acuteUpperChronicCough: "chronicCough",
  ros_acuteUpperCough: "cough",
  ros_acuteUpperDecreasedBreathe: "decreasedBreathe",
  ros_acuteUpperFever: "fever",
  ros_acuteUpperRales: "rales",
  ros_acuteUpperShortnessOfBreath: "shortnessOfBreath",
  ros_acuteUpperWheezing: "wheezing",
  ros_acuteUpperOther: "other",

  ros_diagnosesAsthma: "asthma",
  ros_asthmaActive: "active",
  ros_asthmaHistoryOf: "historyOf",
  ros_asthmaRuleOut: "ruleOut",
  ros_asthmaChronicCough: "chronicCough",
  ros_asthmaCyanosis: "cyanosis",
  ros_asthmaUseOfBronchodilator: "useOfBronchodilator",
  ros_asthmaUseOfInhaled: "useOfInhaled",
  ros_asthmaUseOfVentilator: "useOfVentilator",
  ros_asthmaShortnessOfBreath: "shortnessOfBreath",
  ros_asthmaWheezing: "wheezing",
  ros_asthmaOther: "other",

  ros_asthmaPatientControllerYes: "yes",
  ros_asthmaPatientControllerNo: "no",
  ros_asthmaRescueMedicationsYes: "yes",
  ros_asthmaRescueMedicationsNo: "no",
  ros_asthmaCurrentExacerbationYes: "yes",
  ros_asthmaCurrentExacerbationNo: "no",

  ros_diagnosesChronicPulmonaryEmbolism: "chronicPulmonaryEmbolism",
  ros_chronicPulmonaryEmbolismActive: "active",
  ros_chronicPulmonaryEmbolismHistoryOf: "historyOf",
  ros_chronicPulmonaryEmbolismRuleOut: "ruleOut",
  ros_chronicPulmonaryEmbolismHistoryOfPul: "historyOfPul",
  ros_chronicPulmonaryEmbolismInsersionOfVena: "insersionOfVena",
  ros_chronicPulmonaryEmbolismAnticoagulation: "anticoagulation",
  ros_chronicPulmonaryEmbolismShortnessOfBreath: "shortnessOfBreath",
  ros_chronicPulmonaryEmbolismWheezing: "wheezing",
  ros_chronicPulmonaryEmbolismOther: "other",

  ros_diagnosesChronicRespiratoryFailure: "chronicRespiratoryFailure",
  ros_chronicRespiratoryFailureActive: "active",
  ros_chronicRespiratoryFailureHistoryOf: "historyOf",
  ros_chronicRespiratoryFailureRuleOut: "ruleOut",
  ros_chronicRespiratoryFailureHistoryOfHospitalization:
    "historyOfHospitalization",
  ros_chronicRespiratoryFailureChronicUseOfO2: "chronicUseOfO2",
  ros_chronicRespiratoryFailureCarbonDioxideRetention: "carbonDioxideRetention",
  ros_chronicRespiratoryFailureShortnessOfBreath: "shortnessOfBreath",
  ros_chronicRespiratoryFailureWheezing: "wheezing",
  ros_chronicRespiratoryFailureChronicCough: "chronicCough",
  ros_chronicRespiratoryFailureUseOfVentilator: "useOfVentilator",
  ros_chronicRespiratoryFailureOther: "other",

  ros_diagnosesChronicSputumFailure: "chronicSputumFailure",
  ros_chronicSputumFailureActive: "active",
  ros_chronicSputumFailureHistoryOf: "historyOf",
  ros_chronicSputumFailureRuleOut: "ruleOut",
  ros_chronicSputumFailureHistory: "history",
  ros_chronicSputumFailureSymptoms: "symptoms",
  ros_chronicSputumFailurePhysicalFindings: "physicalFindings",
  ros_chronicSputumFailureMedications: "medications",
  ros_chronicSputumFailureTestResults: "testResults",
  ros_chronicSputumFailureImageStudies: "imageStudies",
  ros_chronicSputumFailureBiopsy: "biopsy",
  ros_chronicSputumFailureDme: "dme",
  ros_chronicSputumFailureShortnessOfBreath: "shortnessOfBreath",
  ros_chronicSputumFailureChronicCough: "chronicCough",
  ros_chronicSputumFailureWheezing: "wheezing",
  ros_chronicSputumFailureOther: "other",

  ros_diagnosesCopd: "copd",
  ros_copdActive: "active",
  ros_copdHistoryOf: "historyOf",
  ros_copdRuleOut: "ruleOut",
  ros_copdXuseOfAccessaryMuscles: "xuseOfAccessaryMuscles",
  ros_copdBarrelChest: "barrelChest",
  ros_copdXrResults: "xrResults",
  ros_copdWheezing: "wheezing",
  ros_copdClubbing: "clubbing",
  ros_copdDecreasedOrProlongedSounds: "decreasedOrProlongedSounds",
  ros_copdDyspneaOnExertion: "dyspneaOnExertion",
  ros_copdOxygenUse: "oxygenUse",
  ros_copdBronchodilatorMedication: "bronchodilatorMedication",
  ros_copdShortnessOfBreath: "shortnessOfBreath",
  ros_copdChronicCough: "chronicCough",
  ros_copdOther: "other",
  ros_patientChronicBronchitisYes: "yes",
  ros_patientChronicBronchitisNo: "no",
  ros_patientHaveEmphysemaYes: "yes",
  ros_patientHaveEmphysemaNo: "no",
  ros_patientBronchodilatorYes: "yes",
  ros_patientBronchodilatorNo: "no",
  ros_patientSteroidsYes: "yes",
  ros_patientSteroidsNo: "no",
  ros_patientExacerbationYes: "yes",
  ros_patientExacerbationNo: "no",

  ros_diagnosesCysticFibrosis: "cysticFibrosis",
  ros_cysticFibrosisActive: "active",
  ros_cysticFibrosisHistoryOf: "historyOf",
  ros_cysticFibrosisRuleOut: "ruleOut",
  ros_cysticFibrosisHistory: "history",
  ros_cysticFibrosisSymptoms: "symptoms",
  ros_cysticFibrosisPhysicalFindings: "physicalFindings",
  ros_cysticFibrosisMedications: "medications",
  ros_cysticFibrosisTestResults: "testResults",
  ros_cysticFibrosisImageStudies: "imageStudies",
  ros_cysticFibrosisBiopsy: "biopsy",
  ros_cysticFibrosiseDme: "dme",
  ros_cysticFibrosisShortnessOfBreath: "shortnessOfBreath",
  ros_cysticFibrosisChronicCough: "chronicCough",
  ros_cysticFibrosisWheezing: "wheezing",
  ros_cysticFibrosisOther: "other",

  ros_diagnosesHypoventilation: "hypoventilation",
  ros_hypoventilationActive: "active",
  ros_hypoventilationHistoryOf: "historyOf",
  ros_hypoventilationRuleOut: "ruleOut",
  ros_hypoventilationMorbidObesity: "morbidObesity",
  ros_hypoventilationUseOfOxygen: "useOfOxygen",
  ros_hypoventilationCarbonDioxideRetention: "carbonDioxideRetention",
  ros_hypoventilationShortnessOfBreath: "shortnessOfBreath",
  ros_hypoventilationWheezing: "wheezing",
  ros_hypoventilationChronicCough: "chronicCough",
  ros_hypoventilationOther: "other",

  ros_diagnosesHypoxemia: "hypoxemia",
  ros_hypoxemiaActive: "active",
  ros_hypoxemiaHistoryOf: "historyOf",
  ros_hypoxemiaRuleOut: "ruleOut",
  ros_hypoxemiaOxygenSaturation: "morbidObesity",
  ros_hypoxemiaShortnessOfBreath: "useOfOxygen",
  ros_hypoxemiaWheezing: "carbonDioxideRetention",
  ros_hypoxemiaChronicCough: "shortnessOfBreath",
  ros_hypoxemiaOther: "other",

  ros_diagnosesPneumonia: "pneumonia",
  ros_pneumoniaActive: "active",
  ros_pneumoniaHistoryOf: "historyOf",
  ros_pneumoniaRuleOut: "ruleOut",
  pneumoniaHospitalization: "hospitalization",
  ros_pneumoniaPhysicalFindings: "physicalFindings",
  ros_pneumoniaImageStudies: "imageStudies",
  ros_pneumoniaLabStudies: "labStudies",
  ros_pneumoniaShortnessOfBreath: "shortnessOfBreath",
  ros_pneumoniaWheezing: "wheezing",
  ros_pneumoniaChronicCough: "chronicCough",
  ros_pneumoniaOther: "other",
  ros_pneumoniaViral: "viral",
  ros_pneumoniaPneumococcal: "pneumococcal",
  ros_pneumoniaStaph: "staph",
  ros_pneumoniaOtherBacterial: "otherBacterial",
  ros_historyOfLungAbscessYes: "yes",
  ros_historyOfLungAbscessNo: "no",
  ros_historyOfEmpyemaYes: "yes",
  ros_historyOfEmpyemaNo: "no",

  ros_diagnosesPulmonaryFibrosis: "pulmonaryFibrosis",
  ros_pulmonaryFibrosisActive: "active",
  ros_pulmonaryFibrosisHistoryOf: "historyOf",
  ros_pulmonaryFibrosisRuleOut: "ruleOut",
  ros_pulmonaryFibrosisXRay: "xRay",
  ros_pulmonaryFibrosisPft: "pft",
  ros_pulmonaryFibrosisBiopsy: "biopsy",
  ros_pulmonaryFibrosisMedications: "medications",
  ros_pulmonaryFibrosisShortnessOfBreath: "shortnessOfBreath",
  ros_pulmonaryFibrosisWheezing: "wheezing",
  ros_pulmonaryFibrosisChronicCough: "chronicCough",
  ros_pulmonaryFibrosisOther: "other",

  ros_diagnosesRespiratorDependence: "respiratorDependence",
  ros_respiratorDependenceActive: "active",
  ros_respiratorDependenceHistoryOf: "historyOf",
  ros_respiratorDependenceRuleOut: "ruleOut",
  ros_respiratorDependenceHistory: "history",
  ros_respiratorDependenceSymptoms: "symptoms",
  ros_respiratorDependencePhysicalFindings: "physicalFindings",
  ros_respiratorDependenceMedications: "medications",
  ros_respiratorDependenceTestResults: "testResults",
  ros_respiratorDependenceImageStudies: "imageStudies",
  ros_respiratorDependenceBiopsy: "biopsy",
  ros_respiratorDependenceDme: "dme",
  ros_respiratorDependenceShortnessOfBreath: "shortnessOfBreath",
  ros_respiratorDependenceWheezing: "wheezing",
  ros_respiratorDependenceChronicCough: "chronicCough",
  ros_respiratorDependenceOther: "other",

  ros_diagnosesRespiratoryArrest: "respiratoryArrest",
  ros_respiratoryArrestActive: "active",
  ros_respiratoryArrestHistoryOf: "historyOf",
  ros_respiratoryArrestRuleOut: "ruleOut",
  ros_respiratoryArrestHistoryOfHospitalization: "historyOfHospitalization",
  ros_respiratoryArrestUseOfVentilator: "useOfVentilator",
  ros_respiratoryArrestCarbonDioxideRetention: "carbonDioxideRetention",
  ros_respiratoryArrestShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryArrestTestWheezing: "wheezing",
  ros_respiratoryArrestChronicCough: "chronicCough",
  ros_respiratoryArrestOther: "other",

  ros_diagnosesSarcoidosis: "sarcoidosis",
  ros_sarcoidosisActive: "active",
  ros_sarcoidosisHistoryOf: "historyOf",
  ros_sarcoidosisRuleOut: "ruleOut",
  ros_sarcoidosisLung: "lung",
  ros_sarcoidosisLymphNodes: "lymphNodes",
  ros_sarcoidosisSkin: "skin",
  ros_sarcoidosisCranialNerves: "cranialNerves",
  ros_sarcoidosisTestUnKnown: "unKnown",
  ros_sarcoidosisDiagnosticResults: "diagnosticResults",
  ros_sarcoidosisCutaneousLesions: "cutaneousLesions",
  ros_sarcoidosisShortnessOfBreath: "shortnessOfBreath",
  ros_sarcoidosisWheezing: "wheezing",
  ros_sarcoidosisTestChronicCough: "chronicCough",
  ros_sarcoidosisOther: "other",

  ros_diagnosesSleepApnea: "sleepApnea",
  ros_sleepApneaActive: "active",
  ros_sleepApneaHistoryOf: "historyOf",
  ros_sleepApneaRuleOut: "ruleOut",
  ros_sleepApneaUseOfCpap: "useOfCpap",
  ros_sleepApneaPositiveSleepStudies: "positiveSleepStudies",
  ros_sleepApneaHistoryOfSleepiness: "historyOfSleepiness",
  ros_sleepApneaHeavySnoring: "heavySnoring",
  ros_sleepApneaShortnessOfBreath: "shortnessOfBreath",
  ros_sleepApneaWheezing: "wheezing",
  ros_sleepApneaChronicCough: "chronicCough",
  ros_sleepApneaOther: "other",

  ros_diagnosesOther: "other",
  ros_otherShortnessOfBreath: "shortnessOfBreath",
  ros_otherChronicCough: "chronicCough",
  ros_otherWheezing: "wheezing",

  //integument
  ros_integumentYes: "yes",
  ros_integumentNo: "no",

  ros_diagnosesIntegumentBasilCellCarcinoma: "basilCellCarcinoma",
  ros_basilCellCarcinomaActive: "active",
  ros_basilCellCarcinomaHistoryOf: "historyOf",
  ros_basilCellCarcinomaRuleOut: "ruleOut",
  ros_basilCellCarcinomaHistory: "history",
  ros_basilCellCarcinomaSymptoms: "symptoms",
  ros_basilCellCarcinomaPhysicalFindings: "physicalFindings",
  ros_basilCellCarcinomaMedications: "medications",
  ros_basilCellCarcinomaTestResults: "testResults",
  ros_basilCellCarcinomaImageStudies: "imageStudies",
  ros_basilCellCarcinomaBiopsy: "biopsy",
  ros_basilCellCarcinomaDme: "dme",
  ros_basilCellCarcinomaOther: "other",

  ros_diagnosesIntegumentDermatitis: "dermatitis",
  ros_dermatitisActive: "active",
  ros_dermatitisHistoryOf: "historyOf",
  ros_dermatitisRuleOut: "ruleOut",
  ros_dermatitisHistory: "history",
  ros_dermatitisSymptoms: "symptoms",
  ros_dermatitisPhysicalFindings: "physicalFindings",
  ros_dermatitisMedications: "medications",
  ros_dermatitisTestResults: "testResults",
  ros_dermatitisImageStudies: "imageStudies",
  ros_dermatitisBiopsy: "biopsy",
  ros_dermatitisDme: "dme",
  ros_dermatitisOther: "other",
  ros_dermatitisContact: "contact",
  ros_dermatitisStasis: "stasis",
  ros_dermatitisDrugInduced: "drugInduced",
  ros_dermatitisDiseaseInduced: "diseaseInduced",
  ros_dermatitisUnspecified: "unspecified",

  ros_diagnosesIntegumentEczema: "eczema",
  ros_eczemaActive: "active",
  ros_eczemaHistoryOf: "historyOf",
  ros_eczemaRuleOut: "ruleOut",
  ros_eczemaHistory: "history",
  ros_eczemaSymptoms: "symptoms",
  ros_eczemaPhysicalFindings: "physicalFindings",
  ros_eczemaMedications: "medications",
  ros_eczemaTestResults: "testResults",
  ros_eczemaImageStudies: "imageStudies",
  ros_eczemaBiopsy: "biopsy",
  ros_eczemaDme: "dme",
  ros_eczemaOther: "other",

  ros_diagnosesIntegumentOnychomysosis: "onychomycosis",
  ros_onychomycosisActive: "active",
  ros_onychomycosisHistoryOf: "historyOf",
  ros_onychomycosisRuleOut: "ruleOut",
  ros_onychomycosisHistory: "history",
  ros_onychomycosisSymptoms: "symptoms",
  ros_onychomycosisPhysicalFindings: "physicalFindings",
  ros_onychomycosisMedications: "medications",
  ros_onychomycosisTestResults: "testResults",
  ros_onychomycosisImageStudies: "imageStudies",
  ros_onychomycosisBiopsy: "biopsy",
  ros_onychomycosisDme: "dme",
  ros_onychomycosisOther: "other",

  ros_diagnosesIntegumentPsoriasis: "psoriasis",
  ros_psoriasisActive: "active",
  ros_psoriasisHistoryOf: "historyOf",
  ros_psoriasisRuleOut: "ruleOut",
  ros_psoriasisHistory: "history",
  ros_psoriasisSymptoms: "symptoms",
  ros_psoriasisPhysicalFindings: "physicalFindings",
  ros_psoriasisMedications: "medications",
  ros_psoriasisTestResults: "testResults",
  ros_psoriasisImageStudies: "imageStudies",
  ros_psoriasisBiopsy: "biopsy",
  ros_psoriasisDme: "dme",
  ros_psoriasisOther: "other",
  ros_psoriaticArthritisYes: "yes",
  ros_psoriaticArthritisNo: "no",

  ros_diagnosesIntegumentSkinUlcer: "skinUlcer",
  ros_skinUlcerActive: "active",
  ros_skinUlcerHistoryOf: "historyOf",
  ros_skinUlcerRuleOut: "ruleOut",
  ros_skinUlcerHistory: "history",
  ros_skinUlcerSymptoms: "symptoms",
  ros_skinUlcerPhysicalFindings: "physicalFindings",
  ros_skinUlcerMedications: "medications",
  ros_skinUlcerTestResults: "testResults",
  ros_skinUlcerImageStudies: "imageStudies",
  ros_skinUlcerBiopsy: "biopsy",
  ros_skinUlcerDme: "dme",
  ros_skinUlcerOther: "other",
  ros_skinUlcerPressure: "pressure",
  ros_skinUlcerVenousStatis: "venousStatis",
  ros_skinUlcerPeripheralVascular: "peripheralVascular",
  ros_skinUlcerDiseaseInduced: "diseaseInduced",
  ros_skinUlcerDiabeticVasculitis: "diabeticVasculitis",
  ros_skinUlcerDiabeticNeuropathy: "diabeticNeuropathy",

  ros_diagnosesIntegumentTineaPedis: "tineaPedis",
  ros_tineaPedisActive: "active",
  ros_tineaPedisHistoryOf: "historyOf",
  ros_tineaPedisRuleOut: "ruleOut",
  ros_tineaPedisHistory: "history",
  ros_tineaPedisSymptoms: "symptoms",
  ros_tineaPedisPhysicalFindings: "physicalFindings",
  ros_tineaPedisMedications: "medications",
  ros_tineaPedisTestResults: "testResults",
  ros_tineaPedisImageStudies: "imageStudies",
  ros_tineaPedisBiopsy: "biopsy",
  ros_tineaPedisDme: "dme",
  ros_tineaPedisOther: "other",

  ros_diagnosesIntegumentUrticarialDisease: "urticarialDisease",
  ros_urticarialDiseaseActive: "active",
  ros_urticarialDiseaseHistoryOf: "historyOf",
  ros_urticarialDiseaseRuleOut: "ruleOut",
  ros_urticarialDiseaseHistory: "history",
  ros_urticarialDiseaseSymptoms: "symptoms",
  ros_urticarialDiseasePhysicalFindings: "physicalFindings",
  ros_urticarialDiseaseMedications: "medications",
  ros_urticarialDiseaseTestResults: "testResults",
  ros_urticarialDiseaseImageStudies: "imageStudies",
  ros_urticarialDiseaseBiopsy: "biopsy",
  ros_urticarialDiseaseDme: "dme",
  ros_urticarialDiseaseOther: "other",
  ros_urticarialDiseaseAcute: "acute",
  ros_urticarialDiseaseChronic: "chronic",

  ros_diagnosesIntegumentWound: "wound",
  ros_woundActive: "active",
  ros_woundHistoryOf: "historyOf",
  ros_woundRuleOut: "ruleOut",
  ros_woundHistory: "history",
  ros_woundSymptoms: "symptoms",
  ros_woundPhysicalFindings: "physicalFindings",
  ros_woundMedications: "medications",
  ros_woundTestResults: "testResults",
  ros_woundImageStudies: "imageStudies",
  ros_woundBiopsy: "biopsy",
  ros_woundDme: "dme",
  ros_woundOther: "other",
  ros_woundSurgical: "surgical",
  ros_woundTraumatic: "traumatic",
  ros_woundBurn: "burn",

  ros_diagnosesIntegumentOther: "other",
  //musko
  ros_musculoskeletalYes: "yes",
  ros_musculoskeletalNo: "no",

  ros_diagnosesMuskoCollagen: "collagen",
  ros_collagenActive: "active",
  ros_collagenHistoryOf: "historyOf",
  ros_collagenRuleOut: "ruleOut",
  ros_collagenPhysicalFindings: "physicalFindings",
  ros_collagenLabTests: "labTests",
  ros_collagenBiopsy: "biopsy",
  ros_collagenImageStudies: "imageStudies",
  ros_collagenOther: "other",
  ros_collagenSleLupus: "sleLupus",
  ros_collagenScleroderma: "scleroderma",
  ros_collagenDermatomyositis: "dermatomyositis",

  ros_diagnosesMuskoDegenerativeDiscDisease: "degenerativeDiscDisease",
  ros_degenerativeDiscDiseaseActive: "active",
  ros_degenerativeDiscDiseaseHistoryOf: "historyOf",
  ros_degenerativeDiscDiseaseRuleOut: "ruleOut",
  ros_degenerativeDiscDiseaseSymptoms: "symptoms",
  ros_degenerativeDiscDiseasePhysicalFindings: "physicalFindings",
  ros_degenerativeDiscDiseaseImageStudies: "imageStudies",
  ros_degenerativeDiscDiseaseMedications: "medications",
  ros_degenerativeDiscDiseaseOther: "other",
  ros_normalBladderYes: "yes",
  ros_normalBladderNo: "no",
  ros_siteOfDiseaseCervical: "cervical",
  ros_siteOfDiseaseThoracic: "thoracic",
  ros_siteOfDiseaseLumbar: "lumbar",
  ros_siteOfDiseaseLumbosacral: "lumbosacral",
  ros_siteOfDiseaseOther: "other",

  ros_diagnosesMuskoExtremityFracture: "extremityFracture",
  ros_extremityFractureActive: "active",
  ros_extremityFractureHistoryOf: "historyOf",
  ros_extremityFractureRuleOut: "ruleOut",
  ros_extremityFractureActiveTraumatic: "traumatic",
  ros_extremityFractureActivePathological: "pathological",
  ros_extremityFractureHistoryOfTraumatic: "traumatic",
  ros_extremityFractureHistoryOfPathological: "pathological",
  ros_extremityFractureHistory: "history",
  ros_extremityFractureSymptoms: "symptoms",
  ros_extremityFracturePhysicalFindings: "physicalFindings",
  ros_extremityFractureMedications: "medications",
  ros_extremityFractureTestResults: "testResults",
  ros_extremityFractureImageStudies: "imageStudies",
  ros_extremityFractureBiopsy: "biopsy",
  ros_extremityFractureDme: "dme",
  ros_extremityFractureOther: "other",
  ros_extremityFractureShoulderLeft: "left",
  ros_extremityFractureShoulderRight: "right",
  ros_extremityFractureArmLeft: "left",
  ros_extremityFractureArmRight: "right",
  ros_extremityFractureForearmLeft: "left",
  ros_extremityFractureForearmRight: "right",
  ros_extremityFractureWristLeft: "left",
  ros_extremityFractureWristRight: "right",
  ros_extremityFractureHandLeft: "left",
  ros_extremityFractureHandRight: "right",
  ros_extremityFractureFemoralShaftLeft: "left",
  ros_extremityFractureFemoralShaftRight: "right",
  ros_extremityFractureTibiaLeft: "left",
  ros_extremityFractureTibiaRight: "right",
  ros_extremityFractureFibulaLeft: "left",
  ros_extremityFractureFibulaRight: "right",
  ros_extremityFractureAnkleLeft: "left",
  ros_extremityFractureAnkleRight: "right",
  ros_extremityFractureFootLeft: "left",
  ros_extremityFractureFootRight: "right",
  ros_extremityFractureHipLeft: "left",
  ros_extremityFractureHipRight: "right",
  ros_currentWithinTwelveWeeksYes: "yes",
  ros_currentWithinTwelveWeeksNo: "no",

  ros_diagnosesMuskoGout: "gout",
  ros_goutActive: "active",
  ros_goutHistoryOf: "historyOf",
  ros_goutRuleOut: "ruleOut",
  ros_goutHistoryOfAttacks: "historyOfAttacks",
  ros_goutLabTests: "labTests",
  ros_goutMedications: "medications",
  ros_goutOther: "other",

  ros_diagnosesMuskoHalluxValgus: "halluxValgus",
  ros_halluxValgusActive: "active",
  ros_halluxValgusHistoryOf: "historyOf",
  ros_halluxValgusRuleOut: "ruleOut",
  ros_halluxValgusHistory: "history",
  ros_halluxValgusSymptoms: "symptoms",
  ros_halluxValgusPhysicalFindings: "physicalFindings",
  ros_halluxValgusMedications: "medications",
  ros_halluxValgusTestResults: "testResults",
  ros_halluxValgusImageStudies: "imageStudies",
  ros_halluxValgusBiopsy: "biopsy",
  ros_halluxValgusDme: "dme",
  ros_halluxValgusOther: "other",

  ros_diagnosesMuskoHammerToes: "hammerToes",
  ros_hammerToesActive: "active",
  ros_hammerToesHistoryOf: "historyOf",
  ros_hammerToesRuleOut: "ruleOut",
  ros_hammerToesHistory: "history",
  ros_hammerToesSymptoms: "symptoms",
  ros_hammerToesPhysicalFindings: "physicalFindings",
  ros_hammerToesMedications: "medications",
  ros_hammerToesTestResults: "testResults",
  ros_hammerToesImageStudies: "imageStudies",
  ros_hammerToesBiopsy: "biopsy",
  ros_hammerToesDme: "dme",
  ros_hammerToesOther: "other",

  ros_diagnosesMuskoOsteoarthritis: "osteoarthritis",
  ros_osteoarthritisActive: "active",
  ros_osteoarthritisHistoryOf: "historyOf",
  ros_osteoarthritisRuleOut: "ruleOut",
  ros_osteoarthritisHistory: "history",
  ros_osteoarthritisSymptoms: "symptoms",
  ros_osteoarthritisPhysicalFindings: "physicalFindings",
  ros_osteoarthritisImageStudies: "imageStudies",
  ros_osteoarthritisOther: "other",
  ros_osteoarthritisJointSwelling: "jointSwelling",
  ros_osteoarthritisJointStiffness: "jointStiffness",
  ros_osteoarthritisPain: "pain",
  ros_osteoarthritisLimitedRom: "limitedRom",

  ros_diagnosesMuskoOsteomyelitis: "osteomyelitis",
  ros_osteomyelitisActive: "active",
  ros_osteomyelitisHistoryOf: "historyOf",
  ros_osteomyelitisRuleOut: "ruleOut",
  ros_osteomyelitisHospitalization: "hospitalization",
  ros_osteomyelitisImageStudies: "imageStudies",
  ros_osteomyelitisCultures: "cultures",
  ros_osteomyelitisMedications: "medications",
  ros_osteomyelitisOther: "other",

  ros_diagnosesMuskoOsteoporosis: "osteoporosis",
  ros_osteoporosisActive: "active",
  ros_osteoporosisHistoryOf: "historyOf",
  ros_osteoporosisRuleOut: "ruleOut",
  ros_osteoporosisDexaScan: "dexaScan",
  ros_osteoporosisMedications: "medications",
  ros_osteoporosisImagingStudies: "imagingStudies",
  ros_osteoporosisSymptoms: "symptoms",
  ros_osteoporosisFractureHistory: "fractureHistory",
  ros_osteoporosisOther: "other",

  ros_diagnosesMuskoPyogenicArthritis: "pyogenicArthritis",
  ros_pyogenicArthritisActive: "active",
  ros_pyogenicArthritisHistoryOf: "historyOf",
  ros_pyogenicArthritisRuleOut: "ruleOut",
  ros_pyogenicArthritisHospitalization: "hospitalization",
  ros_pyogenicArthritisImageStudies: "imageStudies",
  ros_pyogenicArthritisCultures: "cultures",
  ros_pyogenicArthritisMedications: "medications",
  ros_pyogenicArthritisOther: "other",
  ros_pyogenicArthritisWhichJoints: "whichJoints",

  ros_diagnosesMuskoRheumatoidArthritis: "rheumatoidArthritis",
  ros_rheumatoidArthritisActive: "active",
  ros_rheumatoidArthritisHistoryOf: "historyOf",
  ros_rheumatoidArthritisRuleOut: "ruleOut",
  ros_rheumatoidArthritisSymptoms: "symptoms",
  ros_rheumatoidArthritisPhysicalFindings: "physicalFindings",
  ros_rheumatoidArthritisMedications: "medications",
  ros_rheumatoidArthritisLabTests: "labTests",
  ros_rheumatoidArthritisImageStudies: "imageStudies",
  ros_rheumatoidArthritisOther: "other",
  ros_rheumatoidArthritisJointSwelling: "jointSwelling",
  ros_rheumatoidArthritisJointStiffness: "jointStiffness",
  ros_rheumatoidArthritisPain: "pain",
  ros_rheumatoidArthritisLimitedRom: "limitedRom",

  ros_diagnosesMuskoSpinalStenosis: "spinalStenosis",
  ros_spinalStenosisActive: "active",
  ros_spinalStenosisHistoryOf: "historyOf",
  ros_spinalStenosisRuleOut: "ruleOut",
  ros_spinalStenosisSymptoms: "symptoms",
  ros_spinalStenosisPhysicalFindings: "physicalFindings",
  ros_spinalStenosisImageStudies: "imageStudies",
  ros_spinalStenosisMedications: "medications",
  ros_spinalStenosisOther: "other",
  ros_spinalStenosisNormalBladderYes: "yes",
  ros_spinalStenosisNormalBladderNo: "no",

  ros_diagnosesMuskoSystemicLupus: "systemicLupus",
  ros_systemicLupusActive: "active",
  ros_systemicLupusHistoryOf: "historyOf",
  ros_systemicLupusRuleOut: "ruleOut",
  ros_systemicLupusLabs: "labs",
  ros_systemicLupusMedications: "medications",
  ros_systemicLupusHistory: "history",
  ros_systemicLupusOther: "other",

  ros_diagnosesMuskoOther: "other",
  ros_hadAnAmputationYes: "yes",
  ros_hadAnAmputationNo: "no",

  //hematology
  ros_hematologyYes: "yes",
  ros_hematologyNo: "no",

  ros_diagnosesHemaAids: "aids",
  ros_aidsActive: "active",
  ros_aidsHistoryOf: "historyOf",
  ros_aidsRuleOut: "ruleOut",
  ros_aidsSymptoms: "symptoms",
  ros_aidsPhysicalFindings: "physicalFindings",
  ros_aidsHistoryOfOpportunistic: "historyOfOpportunistic",
  ros_aidsMedications: "medications",
  ros_aidsOther: "other",
  ros_aidsPatientUnderTreatmentYes: "yes",
  ros_aidsPatientUnderTreatmentNo: "no",

  ros_diagnosesHemaAnemia: "anemia",
  ros_anemiaActive: "active",
  ros_anemiaHistoryOf: "historyOf",
  ros_anemiaRuleOut: "ruleOut",
  ros_anemiaLabTests: "labTests",
  ros_anemiaSymptoms: "symptoms",
  ros_anemiaHistoryOfBloodTransfusion: "historyOfBloodTransfusion",
  ros_anemiaOther: "other",
  ros_anemiaIronDeficiency: "ironDeficiency",
  ros_anemiaPernicious: "pernicious",
  ros_anemiaKidneyDisease: "kidneyDisease",
  ros_anemiaHemolysis: "hemolysis",
  ros_anemiaAplastic: "aplastic",
  ros_anemiaChemotherapy: "chemotherapy",
  ros_anemiaBloodLoss: "chronicDisease",
  ros_anemiaChronicDisease: "physicalFindings",
  ros_anemiaFolateDeficiency: "folateDeficiency",
  ros_anemiaIron: "iron",
  ros_anemiaBTwelve: "bTwelve",
  ros_anemiaFolicAcid: "folicAcid",
  ros_anemiaBloodTransfusions: "bloodTransfusions",

  ros_diagnosesHemaCDifficile: "cDifficile",
  ros_cDifficileActive: "active",
  ros_cDifficileHistoryOf: "historyOf",
  ros_cDifficileRuleOut: "ruleOut",
  ros_cDifficilePositiveLabTest: "positiveLabTest",
  ros_cDifficileSymptoms: "sympytoms",
  ros_cDifficileHospitalization: "hospitalization",
  ros_cDifficileMedication: "medication",
  ros_cDifficileOther: "other",

  ros_diagnosesHemaCommunityAcquired: "communityAcquired",
  ros_communityAcquiredActive: "active",
  ros_communityAcquiredHistoryOf: "historyOf",
  ros_communityAcquiredRuleOut: "ruleOut",
  ros_communityAcquiredCultures: "cultures",
  ros_communityAcquiredHospitalization: "hospitalization",
  ros_communityAcquiredMedications: "medications",
  ros_communityAcquiredPhysicalFindings: "physicalFindings",
  ros_communityAcquiredOther: "other",

  ros_diagnosesHemaHiv: "hiv",
  ros_hivActive: "active",
  ros_hivHistoryOf: "historyOf",
  ros_hivRuleOut: "ruleOut",
  ros_hivLabTests: "labTests",
  ros_hivSymptoms: "symptoms",
  ros_hivdMedications: "medications",
  ros_hivOther: "other",
  //  hivViralLoad: "viralLoad",
  //  hivdC4: "C4",
  ros_hivPatientSymptomaticYes: "yes",
  ros_hivPatientSymptomaticNo: "no",
  ros_hivActiveTreatmentYes: "yes",
  ros_hivActiveTreatmentNo: "no",

  ros_diagnosesHemaHerpesZoster: "herpesZoster",
  ros_herpesZosterActive: "active",
  ros_herpesZosterHistoryOf: "historyOf",
  ros_herpesZosterRuleOut: "ruleOut",
  ros_herpesZosterRash: "rash",
  ros_herpesZosterSymptoms: "symptoms",
  ros_herpesZosterMedications: "medications",
  ros_herpesZosterOther: "other",

  ros_diagnosesHemaMrsaInfection: "mrsaInfection",
  ros_mrsaInfectionActive: "active",
  ros_mrsaInfectionHistoryOf: "historyOf",
  ros_mrsaInfectionRuleOut: "ruleOut",
  ros_mrsaInfectionCultures: "cultures",
  ros_mrsaInfectionHospitalization: "hospitalization",
  ros_mrsaInfectionMedications: "medications",
  ros_mrsaInfectionPhysicalFindings: "physicalFindings",
  ros_mrsaInfectionOther: "other",

  ros_diagnosesHemaImmuneDeficiency: "immuneDeficiency",
  ros_immuneDeficiencyActive: "active",
  ros_immuneDeficiencyHistoryOf: "historyOf",
  ros_immuneDeficiencyRuleOut: "ruleOut",
  ros_immuneDeficiencyHistory: "history",
  ros_immuneDeficiencySymptoms: "symptoms",
  ros_immuneDeficiencyPhysicalFindings: "physicalFindings",
  ros_immuneDeficiencyMedications: "medications",
  ros_immuneDeficiencyImageStudies: "imageStudies",
  ros_immuneDeficiencyOther: "other",
  //  immuneDeficiencyEtiology: "etiology",
  ros_immuneDeficiencyHistoryOfOpportunisticYes: "yes",
  ros_immuneDeficiencyHistoryOfOpportunisticNo: "no",

  ros_diagnosesHemaSepsis: "sepsis",
  ros_sepsisActive: "active",
  ros_sepsisHistoryOf: "historyOf",
  ros_sepsisRuleOut: "ruleOut",
  ros_sepsisBloodCultures: "bloodCultures",
  ros_sepsisOtherCultures: "otherCultures",
  ros_sepsisSymptoms: "symptoms",
  ros_sepsisHospitalization: "hospitalization",
  ros_sepsisUnstableVitalSigns: "unstableVitalSigns",
  ros_sepsisOther: "other",

  ros_diagnosesHemaSickleCellDisease: "sickleCellDisease",
  ros_sickleCellDiseaseActive: "active",
  ros_sickleCellDiseaseHistoryOf: "historyOf",
  ros_sickleCellDiseaseRuleOut: "ruleOut",
  ros_sickleCellDiseaseFamilyHistory: "familyHistory",
  ros_sickleCellDiseaseSymptoms: "symptoms",
  ros_sickleCellDiseaseHistoryOfInfections: "historyOfInfections",
  ros_sickleCellDiseaseHospitalizations: "hospitalizations",
  ros_sickleCellDiseaseOther: "other",

  ros_diagnosesHemaSickleCellTrait: "sickleCellTrait",
  ros_sickleCellTraitActive: "active",
  ros_sickleCellTraitHistoryOf: "historyOf",
  ros_sickleCellTraitRuleOut: "ruleOut",
  ros_sickleCellTraitFamilyHistory: "familyHistory",
  ros_sickleCellTraitLabTest: "labTest",
  ros_sickleCellTraitOther: "other",

  ros_diagnosesHemaThalassemia: "thalassemia",
  ros_thalassemiaActive: "active",
  ros_thalassemiaHistoryOf: "historyOf",
  ros_thalassemiaRuleOut: "ruleOut",
  ros_thalassemiaFamilyHistory: "familyHistory",
  ros_thalassemiaSymptoms: "symptoms",
  ros_thalassemiaLabTests: "labTests",
  ros_thalassemiaHistoryOfInfections: "historyOfInfections",
  ros_thalassemiaOther: "other",

  ros_diagnosesHemaThrombocytopenia: "thrombocytopenia",
  ros_thrombocytopeniaActive: "active",
  ros_thrombocytopeniaHistoryOf: "historyOf",
  ros_thrombocytopeniaRuleOut: "ruleOut",
  ros_thrombocytopeniaLabTests: "labTests",
  ros_thrombocytopeniaPhysicalFindings: "physicalFindings",
  ros_thrombocytopeniaHistory: "history",
  ros_thrombocytopeniaOther: "other",
  //  thrombocytopeniaEtiology: "etiology",

  ros_diagnosesHemaTuberculosis: "tuberculosis",
  ros_tuberculosisActive: "active",
  ros_tuberculosishistoryOfActiveTb: "historyOfActiveTb",
  ros_tuberculosistbInfection: "tbInfection",
  ros_tuberculosisruleOutActiveTb: "ruleOutActiveTb",
  ros_tuberculosisHistory: "history",
  ros_tuberculosisMedications: "medications",
  ros_tuberculosisImaginingStudy: "imaginingStudy",
  ros_tuberculosisSkinTest: "skinTest",
  ros_tuberculosisSymptoms: "symptoms",
  ros_tuberculosisPositiveCulture: "positiveCulture",
  ros_tuberculosisOther: "other",
  ros_tuberculosisGivenBCGYes: "yes",
  ros_tuberculosisGivenBCGNo: "no",
  ros_tuberculosisActiveTuberculosisYes: "yes",
  ros_tuberculosisActiveTuberculosisNo: "no",
  ros_tuberculosisTbInfectionYes: "yes",
  ros_tuberculosisTbInfectionNo: "no",

  ros_diagnosesHemaVitaminDDeficiency: "vitaminDDeficiency",
  ros_vitaminDDeficiencyActive: "active",
  ros_vitaminDDeficiencyHistoryOf: "historyOf",
  ros_vitaminDDeficiencyRuleOut: "ruleOut",
  ros_vitaminDDeficiencyLabs: "labs",
  ros_vitaminDDeficiencyMedications: "medications",
  ros_vitaminDDeficiencyHistory: "history",
  ros_vitaminDDeficiencyOther: "other",

  ros_diagnosesHemaOther: "other",
  ros_otherActive: "active",
  ros_otherHistoryOf: "historyOf",
  ros_otherRuleOut: "ruleOut",
  ros_otherHistory: "history",
  ros_otherSymptoms: "symptoms",
  ros_otherPhysicalFindings: "physicalFindings",
  ros_otherMedications: "medications",
  ros_otherTestResults: "testResults",
  ros_otherImageStudies: "imageStudies",
  ros_otherBiopsy: "biopsy",
  ros_otherDme: "dme",
  ros_otherOther: "other",
};

const ros_OptionsTreeWrappers_mehak = [
  //gait
  [
    "ros_gait",
    [
      "ros_gaitNormal",
      "ros_gaitLimp",
      "ros_gaitWideBased",
      "ros_gaitAbductorLurch",
      "ros_gaitParetic",
      "ros_gaitShuffling",
      "ros_gaitAtaxic",
      "ros_gaitOther",
    ],
  ],

  //respiratory
  ["ros_respiratory", ["ros_respiratoryYes", "ros_respiratoryNo"]],

  [
    "ros_respiratoryDiagnoses",
    [
      "ros_diagnosesAcutePulmonary",
      "ros_diagnosesAcuteUpper",
      "ros_diagnosesAsthma",
      "ros_diagnosesChronicPulmonaryEmbolism",
      "ros_diagnosesChronicRespiratoryFailure",
      "ros_diagnosesChronicSputumFailure",
      "ros_diagnosesCopd",
      "ros_diagnosesCysticFibrosis",
      "ros_diagnosesHypoventilation",
      "ros_diagnosesHypoxemia",
      "ros_diagnosesPneumonia",
      "ros_diagnosesPulmonaryFibrosis",
      "ros_diagnosesRespiratorDependence",
      "ros_diagnosesRespiratoryArrest",
      "ros_diagnosesSarcoidosis",
      "ros_diagnosesSleepApnea",
      "ros_diagnosesOther",
    ],
  ],

  [
    "ros_acutePulmonarySubAnswerDescribe",
    [
      "ros_acutePulmonaryActive",
      "ros_acutePulmonaryHistoryOf",
      "ros_acutePulmonaryruleOut",
    ],
  ],
  [
    "ros_acutePulmonarySubAnswerSupportedBy",
    [
      "ros_acutePulmonaryChronicCough",
      "ros_acutePulmonaryCtAngiogram",
      "ros_acutePulmonaryDDimer",
      "ros_acutePulmonaryHospitalization",
      "ros_acutePulmonaryShortnessOfBreath",
      "ros_acutePulmonaryUseOfAnticoagulation",
      "ros_acutePulmonaryVenousDoppler",
      "ros_acutePulmonaryVqScan",
      "ros_acutePulmonaryWheezing",
    ],
  ],

  [
    "ros_acuteUpperSubAnswerDescribe",
    [
      "ros_acuteUpperActive",
      "ros_acuteUpperHistoryOf",
      "ros_acuteUpperRuleOut",
    ],
  ],
  [
    "ros_acuteUpperSubAnswerSupportedBy",
    [
      "ros_acuteUpperChestXRay",
      "ros_acuteUpperChills",
      "ros_acuteUpperChronicCough",
      "ros_acuteUpperCough",
      "ros_acuteUpperDecreasedBreathe",
      "ros_acuteUpperFever",
      "ros_acuteUpperRales",
      "ros_acuteUpperShortnessOfBreath",
      "ros_acuteUpperWheezing",
      "ros_acuteUpperOther",
    ],
  ],

  [
    "ros_asthmaSubAnswerDescribe",
    ["ros_asthmaActive", "ros_asthmaHistoryOf", "ros_asthmaRuleOut"],
  ],
  [
    "ros_asthmaSubAnswerSupportedBy",
    [
      "ros_asthmaChronicCough",
      "ros_asthmaCyanosis",
      "ros_asthmaUseOfBronchodilator",
      "ros_asthmaUseOfInhaled",
      "ros_asthmaUseOfVentilator",
      "ros_asthmaShortnessOfBreath",
      "ros_asthmaWheezing",
      "ros_asthmaOther",
    ],
  ],
  [
    "ros_asthmaPatientController",
    ["ros_asthmaPatientControllerYes", "ros_asthmaPatientControllerNo"],
  ],
  [
    "ros_asthmaRescueMedications",
    ["ros_asthmaRescueMedicationsYes", "ros_asthmaRescueMedicationsNo"],
  ],
  [
    "ros_asthmaCurrentExacerbation",
    ["ros_asthmaCurrentExacerbationYes", "ros_asthmaCurrentExacerbationNo"],
  ],

  [
    "ros_chronicPulmonaryEmbolismSubAnswerDescribe",
    [
      "ros_chronicPulmonaryEmbolismActive",
      "ros_chronicPulmonaryEmbolismHistoryOf",
      "ros_chronicPulmonaryEmbolismRuleOut",
    ],
  ],
  [
    "ros_chronicPulmonaryEmbolismSubAnswerSupportedBy",
    [
      "ros_chronicPulmonaryEmbolismHistoryOfPul",
      "ros_chronicPulmonaryEmbolismInsersionOfVena",
      "ros_chronicPulmonaryEmbolismAnticoagulation",
      "ros_chronicPulmonaryEmbolismShortnessOfBreath",
      "ros_chronicPulmonaryEmbolismWheezing",
      "ros_chronicPulmonaryEmbolismOther",
    ],
  ],

  [
    "ros_chronicRespiratoryFailureSubAnswerDescribe",
    [
      "ros_chronicRespiratoryFailureActive",
      "ros_chronicRespiratoryFailureHistoryOf",
      "ros_chronicRespiratoryFailureRuleOut",
    ],
  ],
  [
    "ros_chronicRespiratoryFailureSubAnswerSupportedBy",
    [
      "ros_chronicRespiratoryFailureHistoryOfHospitalization",
      "ros_chronicRespiratoryFailureChronicUseOfO2",
      "ros_chronicRespiratoryFailureCarbonDioxideRetention",
      "ros_chronicRespiratoryFailureShortnessOfBreath",
      "ros_chronicRespiratoryFailureWheezing",
      "ros_chronicRespiratoryFailureChronicCough",
      "ros_chronicRespiratoryFailureUseOfVentilator",
      "ros_chronicRespiratoryFailureOther",
    ],
  ],

  [
    "ros_chronicSputumFailureSubAnswerDescribe",
    [
      "ros_chronicSputumFailureActive",
      "ros_chronicSputumFailureHistoryOf",
      "ros_chronicSputumFailureRuleOut",
    ],
  ],
  [
    "ros_chronicSputumFailureSubAnswerSupportedBy",
    [
      "ros_chronicSputumFailureHistory",
      "ros_chronicSputumFailureSymptoms",
      "ros_chronicSputumFailurePhysicalFindings",
      "ros_chronicSputumFailureMedications",
      "ros_chronicSputumFailureTestResults",
      "ros_chronicSputumFailureImageStudies",
      "ros_chronicSputumFailureBiopsy",
      "ros_chronicSputumFailureDme",
      "ros_chronicSputumFailureShortnessOfBreath",
      "ros_chronicSputumFailureChronicCough",
      "ros_chronicSputumFailureWheezing",
      "ros_chronicSputumFailureOther",
    ],
  ],

  [
    "ros_copdSubAnswerDescribe",
    ["ros_copdActive", "ros_copdHistoryOf", "ros_copdRuleOut"],
  ],
  [
    "ros_copdSubAnswerSupportedBy",
    [
      "ros_copdXuseOfAccessaryMuscles",
      "ros_copdBarrelChest",
      "ros_copdXrResults",
      "ros_copdWheezing",
      "ros_copdClubbing",
      "ros_copdDecreasedOrProlongedSounds",
      "ros_copdDyspneaOnExertion",
      "ros_copdOxygenUse",
      "ros_copdBronchodilatorMedication",
      "ros_copdShortnessOfBreath",
      "ros_copdChronicCough",
      "ros_copdOther",
    ],
  ],
  [
    "ros_patientChronicBronchitis",
    ["ros_patientChronicBronchitisYes", "ros_patientChronicBronchitisrNo"],
  ],
  [
    "ros_patientHaveEmphysema",
    ["ros_patientHaveEmphysemaYes", "ros_patientHaveEmphysemaNo"],
  ],
  [
    "ros_patientBronchodilator",
    ["ros_patientBronchodilatorYes", "ros_patientBronchodilatorNo"],
  ],
  ["ros_patientSteroids", ["ros_patientSteroidsYes", "ros_patientSteroidsNo"]],
  [
    "ros_patientExacerbation",
    ["ros_patientExacerbationYes", "ros_patientExacerbationNo"],
  ],

  [
    "ros_cysticFibrosisSubAnswerDescribe",
    [
      "ros_cysticFibrosisActive",
      "ros_cysticFibrosisHistoryOf",
      "ros_cysticFibrosisRuleOut",
    ],
  ],
  [
    "ros_cysticFibrosisSubAnswerSupportedBy",
    [
      "ros_cysticFibrosisHistory",
      "ros_cysticFibrosisSymptoms",
      "ros_cysticFibrosisPhysicalFindings",
      "ros_cysticFibrosisMedications",
      "ros_cysticFibrosisTestResults",
      "ros_cysticFibrosisImageStudies",
      "ros_cysticFibrosisBiopsy",
      "ros_cysticFibrosiseDme",
      "ros_cysticFibrosisShortnessOfBreath",
      "ros_cysticFibrosisChronicCough",
      "ros_cysticFibrosisWheezing",
      "ros_cysticFibrosisOther",
    ],
  ],

  [
    "ros_hypoventilationSubAnswerDescribe",
    [
      "ros_hypoventilationActive",
      "ros_hypoventilationHistoryOf",
      "ros_hypoventilationRuleOut",
    ],
  ],
  [
    "ros_hypoventilationSubAnswerSupportedBy",
    [
      "ros_hypoventilationMorbidObesity",
      "ros_hypoventilationUseOfOxygen",
      "ros_hypoventilationCarbonDioxideRetention",
      "ros_hypoventilationShortnessOfBreath",
      "ros_hypoventilationWheezing",
      "ros_hypoventilationChronicCough",
      "ros_hypoventilationOther",
    ],
  ],

  [
    "ros_hypoxemiaSubAnswerDescribe",
    ["ros_hypoxemiaActive", "ros_hypoxemiaHistoryOf", "ros_hypoxemiaRuleOut"],
  ],
  [
    "ros_hypoxemiaSubAnswerSupportedBy",
    [
      "ros_hypoxemiaOxygenSaturation",
      "ros_hypoxemiaShortnessOfBreath",
      "ros_hypoxemiaWheezing",
      "ros_hypoxemiaChronicCough",
      "ros_hypoxemiaOther",
    ],
  ],

  [
    "ros_pneumoniaSubAnswerDescribe",
    ["ros_pneumoniaActive", "ros_pneumoniaHistoryOf", "ros_pneumoniaRuleOut"],
  ],
  [
    "ros_pneumoniaSubAnswerSupportedBy",
    [
      "ros_pneumoniaHospitalization",
      "ros_pneumoniaPhysicalFindings",
      "ros_pneumoniaImageStudies",
      "ros_pneumoniaLabStudies",
      "ros_pneumoniaShortnessOfBreath",
      "ros_pneumoniaWheezing",
      "ros_pneumoniaChronicCough",
      "ros_pneumoniaOther",
    ],
  ],
  [
    "ros_pneumoniaSubAnswerEtiology",
    [
      "ros_pneumoniaViral",
      "ros_pneumoniaPneumococcal",
      "ros_pneumoniaStaph",
      "ros_pneumoniaOtherBacterial",
    ],
  ],
  [
    "ros_historyOfLungAbscess",
    ["ros_historyOfLungAbscessYes", "ros_historyOfLungAbscessNo"],
  ],
  [
    "ros_historyOfEmpyema",
    ["ros_historyOfEmpyemaYes", "ros_historyOfEmpyemaNo"],
  ],

  [
    "ros_pulmonaryFibrosisSubAnswerDescribe",
    [
      "ros_pulmonaryFibrosisActive",
      "ros_pulmonaryFibrosisHistoryOf",
      "ros_pulmonaryFibrosisRuleOut",
    ],
  ],
  [
    "ros_pulmonaryFibrosisSubAnswerSupportedBy",
    [
      "ros_pulmonaryFibrosisXRay",
      "ros_pulmonaryFibrosisPft",
      "ros_pulmonaryFibrosisBiopsy",
      "ros_pulmonaryFibrosisMedications",
      "ros_pulmonaryFibrosisShortnessOfBreath",
      "ros_pulmonaryFibrosisWheezing",
      "ros_pulmonaryFibrosisChronicCough",
      "ros_pulmonaryFibrosisOther",
    ],
  ],

  [
    "ros_respiratorDependenceSubAnswerDescribe",
    [
      "ros_respiratorDependenceActive",
      "ros_respiratorDependenceHistoryOf",
      "ros_respiratorDependenceRuleOut",
    ],
  ],
  [
    "ros_respiratorDependenceSubAnswerSupportedBy",
    [
      "ros_respiratorDependenceHistory",
      "ros_respiratorDependenceSymptoms",
      "ros_respiratorDependencePhysicalFindings",
      "ros_respiratorDependenceMedications",
      "ros_respiratorDependenceTestResults",
      "ros_respiratorDependenceImageStudies",
      "ros_respiratorDependenceBiopsy",
      "ros_respiratorDependenceDme",
      "ros_respiratorDependenceShortnessOfBreath",
      "ros_respiratorDependenceWheezing",
      "ros_respiratorDependenceChronicCough",
      "ros_respiratorDependenceOther",
    ],
  ],

  [
    "ros_respiratoryArrestSubAnswerDescribe",
    [
      "ros_respiratoryArrestActive",
      "ros_respiratoryArrestHistoryOf",
      "ros_respiratoryArrestRuleOut",
    ],
  ],
  [
    "ros_respiratoryArrestSubAnswerSupportedBy",
    [
      "ros_respiratoryArrestHistoryOfHospitalization",
      "ros_respiratoryArrestUseOfVentilator",
      "ros_respiratoryArrestCarbonDioxideRetention",
      "ros_respiratoryArrestShortnessOfBreath",
      "ros_respiratoryArrestTestWheezing",
      "ros_respiratoryArrestChronicCough",
      "ros_respiratoryArrestOther",
    ],
  ],

  [
    "ros_sarcoidosisSubAnswerDescribe",
    [
      "ros_sarcoidosisActive",
      "ros_sarcoidosisHistoryOf",
      "ros_sarcoidosisRuleOut",
    ],
  ],
  [
    "ros_sarcoidosisSubAnswerSpecify",
    [
      "ros_sarcoidosisLung",
      "ros_sarcoidosisLymphNodes",
      "ros_sarcoidosisSkin",
      "ros_sarcoidosisCranialNerves",
      "ros_sarcoidosisTestUnKnown",
    ],
  ],
  [
    "ros_sarcoidosisSubAnswerSupportedBy",
    [
      "ros_sarcoidosisDiagnosticResults",
      "ros_sarcoidosisCutaneousLesions",
      "ros_sarcoidosisShortnessOfBreath",
      "ros_sarcoidosisWheezing",
      "ros_sarcoidosisTestChronicCough",
      "ros_sarcoidosisOther",
    ],
  ],

  [
    "ros_sleepApneaSubAnswerDescribe",
    [
      "ros_sleepApneaActive",
      "ros_sleepApneaHistoryOf",
      "ros_sleepApneaRuleOut",
    ],
  ],
  [
    "ros_sleepApneaSubAnswerSupportedBy",
    [
      "ros_sleepApneaUseOfCpap",
      "ros_sleepApneaPositiveSleepStudies",
      "ros_sleepApneaHistoryOfSleepiness",
      "ros_sleepApneaHeavySnoring",
      "ros_sleepApneaShortnessOfBreath",
      "ros_sleepApneaWheezing",
      "ros_sleepApneaChronicCough",
      "ros_sleepApneaOther",
    ],
  ],

  [
    "ros_otherSubAnswerDescribe",
    ["ros_otherActive", "ros_otherHistoryOf", "ros_otherRuleOut"],
  ],
  [
    "ros_otherSubAnswerSupportedBy",
    [
      "ros_otherHistory",
      "ros_otherSymptoms",
      "ros_otherPhysicalFindings",
      "ros_otherMedications",
      "ros_otherTestResults",
      "ros_otherImageStudies",
      "ros_otherBiopsy",
      "ros_otherDme",
      "ros_otherShortnessOfBreath",
      "ros_otherChronicCough",
      "ros_otherWheezing",
      "ros_otherOther",
    ],
  ],

  //integument
  ["ros_integument", ["ros_integumentYes", "ros_integumentNo"]],

  [
    "ros_integumentDiagnoses",
    [
      "ros_diagnosesIntegumentBasilCellCarcinoma",
      "ros_diagnosesIntegumentDermatitis",
      "ros_diagnosesIntegumentEczema",
      "ros_diagnosesIntegumentOnychomysosis",
      "ros_diagnosesIntegumentPsoriasis",
      "ros_diagnosesIntegumentSkinUlcer",
      "ros_diagnosesIntegumentTineaPedis",
      "ros_diagnosesIntegumentUrticarialDisease",
      "ros_diagnosesIntegumentWound",
      "ros_diagnosesIntegumentOther",
    ],
  ],

  [
    "ros_basilCellCarcinomaSubAnswerDescribe",
    [
      "ros_basilCellCarcinomaActive",
      "ros_basilCellCarcinomaHistoryOf",
      "ros_basilCellCarcinomaRuleOut",
    ],
  ],
  [
    "ros_basilCellCarcinomaSubAnswerSupportedBy",
    [
      "ros_basilCellCarcinomaHistory",
      "ros_basilCellCarcinomaSymptoms",
      "ros_basilCellCarcinomaPhysicalFindings",
      "ros_basilCellCarcinomaMedications",
      "ros_basilCellCarcinomaTestResults",
      "ros_basilCellCarcinomaImageStudies",
      "ros_basilCellCarcinomaBiopsy",
      "ros_basilCellCarcinomaDme",
      "ros_basilCellCarcinomaOther",
    ],
  ],

  [
    "ros_dermatitisSubAnswerDescribe",
    [
      "ros_dermatitisActive",
      "ros_dermatitisHistoryOf",
      "ros_dermatitisRuleOut",
    ],
  ],
  [
    "ros_dermatitisSubAnswerSupportedBy",
    [
      "ros_dermatitisHistory",
      "ros_dermatitisSymptoms",
      "ros_dermatitisPhysicalFindings",
      "ros_dermatitisMedications",
      "ros_dermatitisTestResults",
      "ros_dermatitisImageStudies",
      "ros_dermatitisBiopsy",
      "ros_dermatitisDme",
      "ros_dermatitisOther",
    ],
  ],
  [
    "ros_dermatitisSubAnswerWhatType",
    [
      "ros_dermatitisContact",
      "ros_dermatitisStasis",
      "ros_dermatitisDrugInduced",
      "ros_dermatitisDiseaseInduced",
      "ros_dermatitisUnspecified",
    ],
  ],

  [
    "ros_eczemaSubAnswerDescribe",
    ["ros_eczemaActive", "ros_eczemaHistoryOf", "ros_eczemaRuleOut"],
  ],
  [
    "ros_eczemaSubAnswerSupportedBy",
    [
      "ros_eczemaHistory",
      "ros_eczemaSymptoms",
      "ros_eczemaPhysicalFindings",
      "ros_eczemaMedications",
      "ros_eczemaTestResults",
      "ros_eczemaImageStudies",
      "ros_eczemaBiopsy",
      "ros_eczemaDme",
      "ros_eczemaOther",
    ],
  ],

  [
    "ros_onychomycosisSubAnswerDescribe",
    [
      "ros_onychomycosisActive",
      "ros_onychomycosisHistoryOf",
      "ros_onychomycosisRuleOut",
    ],
  ],
  [
    "ros_onychomycosisSubAnswerSupportedBy",
    [
      "ros_onychomycosisHistory",
      "ros_onychomycosisSymptoms",
      "ros_onychomycosisPhysicalFindings",
      "ros_onychomycosisMedications",
      "ros_onychomycosisTestResults",
      "ros_onychomycosisImageStudies",
      "ros_onychomycosisBiopsy",
      "ros_onychomycosisDme",
      "ros_onychomycosisOther",
    ],
  ],

  [
    "ros_psoriasisSubAnswerDescribe",
    ["ros_psoriasisActive", "ros_psoriasisHistoryOf", "ros_psoriasisRuleOut"],
  ],
  [
    "ros_psoriasisSubAnswerSupportedBy",
    [
      "ros_psoriasisHistory",
      "ros_psoriasisSymptoms",
      "ros_psoriasisPhysicalFindings",
      "ros_psoriasisMedications",
      "ros_psoriasisTestResults",
      "ros_psoriasisImageStudies",
      "ros_psoriasisBiopsy",
      "ros_psoriasisDme",
      "ros_psoriasisOther",
    ],
  ],
  [
    "ros_psoriasisSubAnswerPsoriaticArthritis",
    ["ros_psoriaticArthritisYes", "ros_psoriaticArthritisNo"],
  ],

  [
    "ros_skinUlcerSubAnswerDescribe",
    ["ros_skinUlcerActive", "ros_skinUlcerHistoryOf", "ros_skinUlcerRuleOut"],
  ],
  [
    "ros_skinUlcerSubAnswerSupportedBy",
    [
      "ros_skinUlcerHistory",
      "ros_skinUlcerSymptoms",
      "ros_skinUlcerPhysicalFindings",
      "ros_skinUlcerMedications",
      "ros_skinUlcerTestResults",
      "ros_skinUlcerImageStudies",
      "ros_skinUlcerBiopsy",
      "ros_skinUlcerDme",
      "ros_skinUlcerOther",
    ],
  ],
  [
    "ros_skinUlcerSubAnswerEtiology",
    [
      "ros_skinUlcerPressure",
      "ros_skinUlcerVenousStatis",
      "ros_skinUlcerPeripheralVascular",
      "ros_skinUlcerDiseaseInduced",
      "ros_skinUlcerDiabeticVasculitis",
      "ros_skinUlcerDiabeticNeuropathy",
    ],
  ],

  [
    "ros_tineaPedisSubAnswerDescribe",
    [
      "ros_tineaPedisActive",
      "ros_tineaPedisHistoryOf",
      "ros_tineaPedisRuleOut",
    ],
  ],
  [
    "ros_tineaPedisSubAnswerSupportedBy",
    [
      "ros_tineaPedisHistory",
      "ros_tineaPedisSymptoms",
      "ros_tineaPedisPhysicalFindings",
      "ros_tineaPedisMedications",
      "ros_tineaPedisTestResults",
      "ros_tineaPedisImageStudies",
      "ros_tineaPedisBiopsy",
      "ros_tineaPedisDme",
      "ros_tineaPedisOther",
    ],
  ],

  [
    "ros_urticarialDiseaseSubAnswerDescribe",
    [
      "ros_urticarialDiseaseActive",
      "ros_urticarialDiseaseHistoryOf",
      "ros_urticarialDiseaseRuleOut",
    ],
  ],
  [
    "ros_urticarialDiseaseSubAnswerSupportedBy",
    [
      "ros_urticarialDiseaseHistory",
      "ros_urticarialDiseaseSymptoms",
      "ros_urticarialDiseasePhysicalFindings",
      "ros_urticarialDiseaseMedications",
      "ros_urticarialDiseaseTestResults",
      "ros_urticarialDiseaseImageStudies",
      "ros_tineaPedisBiopsy",
      "ros_urticarialDiseaseDme",
      "ros_urticarialDiseaseOther",
    ],
  ],
  [
    "ros_urticarialDiseaseSubAnswerType",
    ["ros_urticarialDiseaseAcute", "ros_urticarialDiseaseChronic"],
  ],

  [
    "ros_woundSubAnswerDescribe",
    ["ros_woundActive", "ros_woundHistoryOf", "ros_woundRuleOut"],
  ],
  [
    "ros_woundSubAnswerSupportedBy",
    [
      "ros_woundHistory",
      "ros_woundSymptoms",
      "ros_woundPhysicalFindings",
      "ros_woundMedications",
      "ros_woundTestResults",
      "ros_woundImageStudies",
      "ros_woundBiopsy",
      "ros_woundDme",
      "ros_woundOther",
    ],
  ],
  [
    "ros_woundSubAnswerEtiology",
    ["ros_woundSurgical", "ros_woundTraumatic", "ros_woundBurn"],
  ],

  [
    "ros_othersSubAnswerDescribe",
    ["ros_otherActive", "ros_otherHistoryOf", "ros_otherRuleOut"],
  ],
  [
    "ros_othersSubAnswerSupportedBy",
    [
      "ros_otherHistory",
      "ros_otherSymptoms",
      "ros_otherPhysicalFindings",
      "ros_otherMedications",
      "ros_otherTestResults",
      "ros_otherImageStudies",
      "ros_otherBiopsy",
      "ros_otherDme",
      "ros_otherOther",
    ],
  ],

  //musko
  ["ros_musculoskeletal", ["ros_musculoskeletalYes", "ros_musculoskeletalNo"]],

  [
    "ros_musculoskeletalDiagnoses",
    [
      "ros_diagnosesMuskoCollagen",
      "ros_diagnosesMuskoDegenerativeDiscDisease",
      "ros_diagnosesMuskoExtremityFracture",
      "ros_diagnosesMuskoGout",
      "ros_diagnosesMuskoHalluxValgus",
      "ros_diagnosesMuskoHammerToes",
      "ros_diagnosesMuskoOsteoarthritis",
      "ros_diagnosesMuskoOsteomyelitis",
      "ros_diagnosesMuskoOsteoporosis",
      "ros_diagnosesMuskoPyogenicArthritis",
      "ros_diagnosesMuskoRheumatoidArthritis",
      "ros_diagnosesMuskoSpinalStenosis",
      "ros_diagnosesMuskoSystemicLupus",
      "ros_diagnosesMuskoOther",
    ],
  ],

  [
    "ros_collagenSubAnswerDescribe",
    ["ros_collagenActive", "ros_collagenHistoryOf", "ros_collagenRuleOut"],
  ],
  [
    "ros_collagenSubAnswerSupportedBy",
    [
      "ros_collagenPhysicalFindings",
      "ros_collagenLabTests",
      "ros_collagenBiopsy",
      "ros_collagenImageStudies",
      "ros_collagenOther",
    ],
  ],
  [
    "ros_collagenSubAnswercollagenDescribe",
    [
      "ros_collagenSleLupus",
      "ros_collagenScleroderma",
      "ros_otherPhysicalFindings",
      "ros_collagenDermatomyositis",
    ],
  ],

  [
    "ros_degenerativeDiscDiseaseSubAnswerDescribe",
    [
      "ros_degenerativeDiscDiseaseActive",
      "ros_degenerativeDiscDiseaseHistoryOf",
      "ros_degenerativeDiscDiseaseRuleOut",
    ],
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerSupportedBy",
    [
      "ros_degenerativeDiscDiseaseSymptoms",
      "ros_degenerativeDiscDiseasePhysicalFindings",
      "ros_degenerativeDiscDiseaseImageStudies",
      "ros_degenerativeDiscDiseaseMedications",
      "ros_degenerativeDiscDiseaseOther",
    ],
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerNormalBladder",
    ["ros_normalBladderYes", "ros_normalBladderNo"],
  ],
  [
    "ros_degenerativeDiscDiseaseSubAnswerSiteOfDisease",
    [
      "ros_siteOfDiseaseCervical",
      "ros_siteOfDiseaseThoracic",
      "ros_siteOfDiseaseLumbar",
      "ros_siteOfDiseaseLumbosacral",
      "ros_siteOfDiseaseOther",
    ],
  ],

  [
    "ros_extremityFractureSubAnswerDescribe",
    [
      "ros_extremityFractureActive",
      [
        "ros_extremityFractureActiveTypeSubAnswer",
        [
          "ros_extremityFractureActiveTraumatic",
          "ros_extremityFractureActivePathological",
        ],
      ],
      [
        "ros_extremityFractureHistoryOf",
        [
          "ros_extremityFractureHistoryOfTypeSubAnswer",
          [
            "ros_extremityFractureHistoryOfTraumatic",
            "ros_extremityFractureHistoryOfPathological",
          ],
        ],
        "ros_extremityFractureRuleOut",
      ],
    ],
  ],

  [
    "ros_extremityFractureSubAnswerSupportedBy",
    [
      "ros_extremityFractureHistory",
      "ros_extremityFractureSymptoms",
      "ros_extremityFracturePhysicalFindings",
      "ros_extremityFractureMedications",
      "ros_extremityFractureTestResults",
      "ros_extremityFractureImageStudies",
      "ros_extremityFractureBiopsy",
      "ros_extremityFractureDme",
      "ros_extremityFractureOther",
    ],
  ],

  [
    "ros_extremityFractureCurrentWithinTwelveWeeksSubAnswer",
    ["ros_currentWithinTwelveWeeksYes", "ros_currentWithinTwelveWeeksNo"],
  ],
  [
    "ros_extremityFractureSubAnswerExtremity",
    [
      "ros_shoulder",
      [
        "ros_extremityFractureShoulderLeft",
        "ros_extremityFractureShoulderRight",
      ],
    ],
    [
      "ros_arm",
      ["ros_extremityFractureArmLeft", "ros_extremityFractureArmRight"],
    ],
    [
      "ros_forearm",
      ["ros_extremityFractureForearmLeft", "ros_extremityFractureForearmRight"],
    ],
    [
      "ros_wrist",
      ["ros_extremityFractureWristLeft", "ros_extremityFractureWristRight"],
    ],
    [
      "ros_hand",
      ["ros_extremityFractureHandLeft", "ros_extremityFractureHandRight"],
    ],
    [
      "ros_femoralShaft",
      [
        "ros_extremityFractureFemoralShaftLeft",
        "ros_extremityFractureFemoralShaftRight",
      ],
    ],
    [
      "ros_tibia",
      ["ros_extremityFractureTibiaLeft", "ros_extremityFractureTibiaRight"],
    ],
    [
      "fibula",
      ["ros_extremityFractureFibulaLeft", "ros_extremityFractureFibulaRight"],
    ],
    [
      "ankle",
      ["ros_extremityFractureAnkleLeft", "ros_extremityFractureAnkleRight"],
    ],
    [
      "foot",
      ["ros_extremityFractureFootLeft", "ros_extremityFractureFootRight"],
    ],
    ["hip", ["ros_extremityFractureHipLeft", "ros_extremityFractureHipRight"]],
  ],

  [
    "ros_goutSubAnswerDescribe",
    ["ros_goutActive", "ros_goutHistoryOf", "ros_goutRuleOut"],
  ],
  [
    "ros_goutSubAnswerSupportedBy",
    [
      "ros_goutHistoryOfAttacks",
      "ros_goutLabTests",
      "ros_goutMedications",
      "ros_goutOther",
    ],
  ],

  [
    "ros_halluxValgusSubAnswerDescribe",
    [
      "ros_halluxValgusActive",
      "ros_halluxValgusHistoryOf",
      "ros_halluxValgusRuleOut",
    ],
  ],
  [
    "ros_halluxValgusSubAnswerSupportedBy",
    [
      "ros_halluxValgusHistory",
      "ros_halluxValgusSymptoms",
      "ros_halluxValgusPhysicalFindings",
      "ros_halluxValgusMedications",
      "ros_halluxValgusTestResults",
      "ros_halluxValgusImageStudies",
      "ros_halluxValgusBiopsy",
      "ros_halluxValgusDme",
      "ros_halluxValgusOther",
    ],
  ],

  [
    "ros_hammerToesSubAnswerDescribe",
    [
      "ros_halluxValgusActive",
      "ros_halluxValgusHistoryOf",
      "ros_halluxValgusRuleOut",
    ],
  ],
  [
    "ros_hammerToesSubAnswerSupportedBy",
    [
      "ros_hammerToesHistory",
      "ros_hammerToesSymptoms",
      "ros_hammerToesPhysicalFindings",
      "ros_hammerToesMedications",
      "ros_hammerToesTestResults",
      "ros_hammerToesImageStudies",
      "ros_hammerToesBiopsy",
      "ros_hammerToesDme",
      "ros_hammerToesOther",
    ],
  ],

  [
    "ros_osteoarthritisSubAnswerDescribe",
    [
      "ros_osteoarthritisActive",
      "ros_osteoarthritisHistoryOf",
      "ros_osteoarthritisRuleOut",
    ],
  ],
  [
    "ros_osteoarthritisSubAnswerSupportedBy",
    [
      "ros_osteoarthritisHistory",
      "ros_osteoarthritisSymptoms"[
        ("ros_symptomsSubAnswer",
        [
          "ros_osteoarthritisJointSwelling",
          "ros_osteoarthritisJointStiffness",
          "ros_osteoarthritisPain",
          "ros_osteoarthritisLimitedRom",
        ])
      ],
      "ros_osteoarthritisPhysicalFindings",
      "ros_osteoarthritisImageStudies",
      "ros_osteoarthritisOther",
    ],
  ],

  [
    "ros_osteomyelitisSubAnswerDescribe",
    [
      "ros_osteomyelitisActive",
      "ros_osteomyelitisHistoryOf",
      "ros_osteomyelitisRuleOut",
    ],
  ],
  [
    "ros_osteomyelitisSubAnswerSupportedBy",
    [
      "ros_osteomyelitisHospitalization",
      "ros_osteomyelitisImageStudies",
      "ros_osteomyelitisCultures",
      "ros_osteomyelitisMedications",
      "ros_osteomyelitisOther",
    ],
  ],

  [
    "osteoporosisSubAnswerDescribe",
    [
      "ros_osteoporosisActive",
      "ros_osteoporosisHistoryOf",
      "ros_osteoporosisRuleOut",
    ],
  ],
  [
    "osteoporosisSubAnswerSupportedBy",
    [
      "ros_osteoporosisDexaScan",
      "ros_osteoporosisMedications",
      "ros_osteoporosisImagingStudies",
      "ros_osteoporosisSymptoms",
      "ros_osteoporosisFractureHistory",
      "ros_osteoporosisOther",
    ],
  ],

  [
    "ros_pyogenicArthritisSubAnswerDescribe",
    [
      "ros_pyogenicArthritisActive",
      "ros_pyogenicArthritisHistoryOf",
      "ros_pyogenicArthritisRuleOut",
    ],
  ],
  [
    "ros_pyogenicArthritisSubAnswerSupportedBy",
    [
      "ros_pyogenicArthritisHospitalization",
      "ros_pyogenicArthritisImageStudies",
      "ros_pyogenicArthritisCultures",
      "ros_pyogenicArthritisMedications",
      "ros_pyogenicArthritisOther",
      "pyogenicArthritisWhichJoints",
    ],
  ],

  [
    "ros_rheumatoidArthritisSubAnswerDescribe",
    [
      "ros_rheumatoidArthritisActive",
      "ros_rheumatoidArthritisHistoryOf",
      "ros_rheumatoidArthritisRuleOut",
    ],
  ],
  [
    "ros_rheumatoidArthritisSubAnswerSupportedBy",
    [
      "ros_rheumatoidArthritisSymptoms",
      "ros_osteoarthritisSymptoms"[
        ("ros_isSymptomsSubAnswer",
        [
          "ros_rheumatoidArthritisJointSwelling",
          "ros_rheumatoidArthritisJointStiffness",
          "ros_rheumatoidArthritisPain",
          "ros_rheumatoidArthritisLimitedRom",
        ])
      ],
      "ros_rheumatoidArthritisPhysicalFindings",
      "ros_rheumatoidArthritisMedications",
      "ros_rheumatoidArthritisLabTests",
      "ros_rheumatoidArthritisImageStudies",
      "ros_rheumatoidArthritisOther",
    ],
  ],

  [
    "ros_spinalStenosisSubAnswerDescribe",
    [
      "ros_spinalStenosisActive",
      "ros_spinalStenosisHistoryOf",
      "ros_spinalStenosisRuleOut",
    ],
  ],
  [
    "ros_spinalStenosisSubAnswerSupportedBy",
    [
      "ros_spinalStenosisSymptoms",
      "ros_spinalStenosisPhysicalFindings",
      "ros_spinalStenosisImageStudies",
      "ros_spinalStenosisMedications",
      "ros_spinalStenosisOther",
    ],
  ],
  [
    "ros_spinalStenosisSubAnswerNormalBladder",
    ["ros_spinalStenosisNormalBladderYes", "ros_pinalStenosisNormalBladderNo"],
  ],

  [
    "ros_systemicLupusSubAnswerDescribe",
    [
      "ros_systemicLupusActive",
      "ros_systemicLupusHistoryOf",
      "ros_systemicLupusRuleOut",
    ],
  ],
  [
    "ros_systemicLupusSubAnswerSupportedBy",
    [
      "ros_systemicLupusLabs",
      "ros_systemicLupusMedications",
      "ros_systemicLupusHistory",
      "ros_systemicLupusOther",
    ],
  ],

  [
    "ros_muskoOtherSubAnswerDescribe",
    ["ros_otherActive", "ros_otherHistoryOf", "ros_otherRuleOut"],
  ],
  [
    "ros_muskoOtherSubAnswerSupportedBy",
    [
      "ros_otherHistory",
      "ros_otherSymptoms",
      "ros_otherPhysicalFindings",
      "ros_otherMedications",
      "ros_otherTestResults",
      "ros_otherImageStudies",
      "ros_otherBiopsy",
      "ros_otherDme",
      "ros_otherOther",
    ],
  ],

  [
    "ros_muskoHadAnAmputation",
    ["ros_hadAnAmputationYes", "ros_hadAnAmputationNo"],
  ],

  //hematology
  ["ros_hematology", ["ros_ematologyYes", "ros_hematologyNo"]],

  [
    "ros_hematologyDiagnoses",
    [
      "ros_diagnosesHemaAids",
      "ros_diagnosesHemaAnemia",
      "ros_diagnosesHemaCDifficile",
      "ros_diagnosesHemaCommunityAcquired",
      "ros_diagnosesHemaHiv",
      "ros_diagnosesHemaHerpesZoster",
      "ros_diagnosesHemaMrsaInfection",
      "ros_diagnosesHemaImmuneDeficiency",
      "ros_diagnosesHemaSepsis",
      "ros_diagnosesHemaSickleCellDisease",
      "ros_diagnosesHemaSickleCellTrait",
      "ros_diagnosesHemaThalassemia",
      "ros_diagnosesHemaThrombocytopenia",
      "ros_diagnosesHemaTuberculosis",
      "ros_diagnosesHemaVitaminDDeficiency",
      "ros_diagnosesHemaOther",
    ],
  ],

  [
    "ros_aidsSubAnswerDescribe",
    ["ros_aidsActive", "ros_aidsHistoryOf", "ros_aidsRuleOut"],
  ],
  [
    "ros_aidsSubAnswerSupportedBy",
    [
      "ros_aidsSymptoms",
      "ros_aidsPhysicalFindings",
      "ros_aidsHistoryOfOpportunistic",
      "ros_aidsMedications",
      "ros_aidsOther",
    ],
  ],
  [
    "ros_aidsSubAnswerPatientUnderTreatment",
    ["ros_aidsPatientUnderTreatmentYes", "ros_aidsPatientUnderTreatmentNo"],
  ],

  [
    "ros_anemiaSubAnswerDescribe",
    ["ros_anemiaActive", "ros_anemiaHistoryOf", "ros_anemiaRuleOut"],
  ],
  [
    "ros_anemiaSubAnswerSupportedBy",
    [
      "ros_anemiaLabTests",
      "ros_anemiaSymptoms",
      "ros_anemiaHistoryOfBloodTransfusion",
      "ros_anemiaOther",
    ],
  ],
  [
    "ros_anemiaSubAnswerEtiology",
    [
      "ros_anemiaIronDeficiency",
      "ros_anemiaPernicious",
      "ros_anemiaKidneyDisease",
      "ros_anemiaHemolysis",
      "ros_anemiaAplastic",
      "ros_anemiaChemotherapy",
      "ros_anemiaBloodLoss",
      "ros_anemiaChronicDisease",
      "ros_anemiaFolateDeficiency",
      "ros_anemiaOther",
    ],
  ],
  [
    "ros_anemiaSubAnswerYesPatientOn",
    [
      "ros_anemiaIron",
      "ros_anemiaBTwelve",
      "ros_anemiaFolicAcid",
      "ros_anemiaBloodTransfusions",
      "ros_anemiaOther",
    ],
  ],

  [
    "ros_cDifficileSubAnswerDescribe",
    [
      "ros_cDifficileActive",
      "ros_cDifficileHistoryOf",
      "ros_cDifficileRuleOut",
    ],
  ],
  [
    "ros_cDifficileSubAnswerSupportedBy",
    [
      "ros_cDifficilePositiveLabTest",
      "ros_cDifficileSymptoms",
      "ros_cDifficileHospitalization",
      "ros_cDifficileMedication",
      "ros_cDifficileOther",
    ],
  ],

  [
    "ros_communityAcquiredSubAnswerDescribe",
    [
      "ros_communityAcquiredActive",
      "ros_communityAcquiredHistoryOf",
      "ros_communityAcquiredRuleOut",
    ],
  ],
  [
    "ros_communityAcquiredSubAnswerSupportedBy",
    [
      "ros_communityAcquiredCultures",
      "ros_communityAcquiredHospitalization",
      "ros_communityAcquiredMedications",
      "ros_communityAcquiredPhysicalFindings",
      "ros_communityAcquiredOther",
    ],
  ],

  [
    "ros_hivSubAnswerDescribe",
    ["ros_hivActive", "ros_hivHistoryOf", "ros_hivRuleOut"],
  ],
  [
    "ros_hivSubAnswerSupportedBy",
    ["ros_hivLabTests", "ros_hivdMedications", "ros_hivOther"],
  ],
  [
    "ros_hivSubAnswerPatientSymptomatic",
    ["ros_hivPatientSymptomaticYes", "ros_hivPatientSymptomaticNo"],
  ],
  [
    "ros_hivSubAnswerActiveTreatment",
    ["ros_hivActiveTreatmentYes", "ros_hivActiveTreatmentNo"],
  ],

  [
    "ros_herpesZosterSubAnswerDescribe",
    [
      "ros_herpesZosterActive",
      "ros_herpesZosterHistoryOf",
      "ros_herpesZosterRuleOut",
    ],
  ],
  [
    "ros_herpesZosterSubAnswerSupportedBy",
    [
      "ros_herpesZosterRash",
      "ros_herpesZosterSymptoms",
      "ros_herpesZosterMedications",
      "ros_herpesZosterOther",
    ],
  ],

  [
    "ros_mrsaInfectionSubAnswerDescribe",
    [
      "ros_mrsaInfectionActive",
      "ros_mrsaInfectionHistoryOf",
      "ros_mrsaInfectionRuleOut",
    ],
  ],
  [
    "ros_herpesZosterSubAnswerSupportedBy",
    [
      "ros_mrsaInfectionCultures",
      "ros_mrsaInfectionHospitalization",
      "ros_mrsaInfectionMedications",
      "ros_mrsaInfectionPhysicalFindings",
      "ros_mrsaInfectionOther",
    ],
  ],

  [
    "ros_immuneDeficiencySubAnswerDescribe",
    [
      "ros_immuneDeficiencyActive",
      "ros_immuneDeficiencyHistoryOf",
      "ros_immuneDeficiencyRuleOut",
    ],
  ],
  [
    "ros_immuneDeficiencySubAnswerSupportedBy",
    [
      "ros_immuneDeficiencyHistory",
      "ros_immuneDeficiencySymptoms",
      "ros_immuneDeficiencyPhysicalFindings",
      "ros_immuneDeficiencyMedications",
      "ros_immuneDeficiencyImageStudies",
      "ros_immuneDeficiencyOther",
    ],
  ],
  [
    "ros_immuneDeficiencySubAnswerHistoryOfOpportunistic",
    [
      "ros_immuneDeficiencyHistoryOfOpportunisticYes",
      "ros_immuneDeficiencyHistoryOfOpportunisticNo",
    ],
  ],

  [
    "ros_sepsisSubAnswerDescribe",
    ["ros_ros_sepsisActive", "sepsisHistoryOf", "ros_sepsisRuleOut"],
  ],
  [
    "ros_sepsisSubAnswerSupportedBy",
    [
      "ros_sepsisBloodCultures",
      "ros_sepsisOtherCultures",
      "ros_sepsisSymptoms",
      "ros_sepsisHospitalization",
      "ros_sepsisUnstableVitalSigns",
      "ros_sepsisOther",
    ],
  ],

  [
    "ros_sickleCellDiseaseTwoSubAnswerDescribe",
    [
      "ros_sickleCellDiseaseActive",
      "ros_sickleCellDiseaseHistoryOf",
      "ros_sickleCellDiseaseRuleOut",
    ],
  ],
  [
    "ros_sickleCellDiseaseTwoSubAnswerSupportedBy",
    [
      "ros_sickleCellDiseaseFamilyHistory",
      "ros_sickleCellDiseaseSymptoms",
      "ros_sickleCellDiseaseHistoryOfInfections",
      "ros_sickleCellDiseaseHospitalizations",
      "ros_sickleCellDiseaseOther",
    ],
  ],

  [
    "sickleCellTraitSubAnswerDescribe",
    [
      "ros_sickleCellTraitActive",
      "ros_sickleCellTraitHistoryOf",
      "ros_sickleCellTraitRuleOut",
    ],
  ],
  [
    "ros_sickleCellTraitSubAnswerSupportedBy",
    [
      "ros_sickleCellTraitFamilyHistory",
      "ros_sickleCellTraitLabTest",
      "ros_sickleCellTraitOther",
    ],
  ],

  [
    "ros_thalassemiaSubAnswerDescribe",
    [
      "ros_thalassemiaActive",
      "ros_thalassemiaHistoryOf",
      "ros_thalassemiaRuleOut",
    ],
  ],
  [
    "ros_thalassemiaSubAnswerSupportedBy",
    [
      "ros_thalassemiaFamilyHistory",
      "ros_thalassemiaSymptoms",
      "ros_thalassemiaLabTests",
      "ros_thalassemiaHistoryOfInfections",
      "ros_thalassemiaOther",
    ],
  ],

  [
    "ros_thrombocytopeniaSubAnswerDescribe",
    [
      "ros_thrombocytopeniaActive",
      "ros_thrombocytopeniaHistoryOf",
      "ros_thrombocytopeniaRuleOut",
    ],
  ],
  [
    "ros_immuneDeficiencySubAnswerSupportedBy",
    [
      "ros_thrombocytopeniaLabTests",
      "ros_thrombocytopeniaPhysicalFindings",
      "ros_thrombocytopeniaHistory",
      "ros_thrombocytopeniaOther",
    ],
  ],

  [
    "ros_tuberculosisSubAnswerDescribe",
    [
      "ros_tuberculosisActive",
      "ros_tuberculosishistoryOfActiveTb",
      "ros_tuberculosistbInfection",
      "ros_tuberculosisruleOutActiveTb",
    ],
  ],
  [
    "ros_tuberculosisSubAnswerSupportedBy",
    [
      "ros_tuberculosisHistory",
      "ros_tuberculosisMedications",
      "ros_tuberculosisImaginingStudy",
      "ros_tuberculosisSkinTest",
      "ros_tuberculosisSymptoms",
      "ros_tuberculosisPositiveCulture",
      "ros_tuberculosisOther",
    ],
  ],
  [
    "ros_tuberculosisSubAnswerGivenBCG",
    ["ros_tuberculosisGivenBCGYes", "ros_tuberculosisGivenBCGNo"],
  ],
  [
    "ros_tuberculosisSubAnswerActiveTuberculosis",
    [
      "ros_tuberculosisActiveTuberculosisYes",
      "ros_tuberculosisActiveTuberculosisNo",
    ],
  ],
  [
    "ros_tuberculosisSubAnswerTbInfection",
    ["ros_tuberculosisTbInfectionYes", "ros_tuberculosisTbInfectionNo"],
  ],

  [
    "ros_vitaminDDeficiencySubAnswerDescribe",
    [
      "ros_vitaminDDeficiencyActive",
      "ros_vitaminDDeficiencyHistoryOf",
      "ros_vitaminDDeficiencyRuleOut",
    ],
  ],
  [
    "ros_vitaminDDeficiencySubAnswerSupportedBy",
    [
      "ros_vitaminDDeficiencyLabs",
      "ros_vitaminDDeficiencyMedications",
      "ros_vitaminDDeficiencyHistory",
      "ros_vitaminDDeficiencyOther",
    ],
  ],

  [
    "ros_hemaOtherSubAnswerDescribe",
    ["ros_otherActive", "ros_otherHistoryOf", "ros_otherRuleOut"],
  ],
  [
    "ros_hemaOtherSubAnswerSupportedBy",
    [
      "ros_otherHistory",
      "ros_otherSymptoms",
      "ros_otherPhysicalFindings",
      "ros_otherMedications",
      "ros_otherTestResults",
      "ros_otherImageStudies",
      "ros_otherBiopsy",
      "ros_otherDme",
      "ros_otherOther",
    ],
  ],
];

const ros_hashmap_srilatha = [
  //cancer
  ["ros_diagnosisOfCancerVal", "diagnosisOfCancer.isDiagnosisOfCancer"],
  ["ros_diagnosisOfCancerCommentVal", "diagnosisOfCancer.comment"],

  [
    "ros_takemedicationsasprescribedCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[0].takeMedicationsAsPescribed",
  ],

  [
    "ros_otherCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[1].other",
  ],
  [
    "ros_takemedicationsasprescribedCancerCommentVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[0].comment",
  ],
  [
    "ros_otherCancerCommentVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[1].comment",
  ],

  [
    "ros_diagnosisOfCancerSubDescActiveVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.active",
  ],
  [
    "ros_diagnosisOfCancerSubDescHistoryOfVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.historyOf",
  ],
  [
    "ros_diagnosisOfCancerSubDescRuleOutVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.ruleOut",
  ],

  [
    "ros_diagnosisOfCancerSubDescComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.comment",
  ],
  [
    "ros_diagnosisOfCancerSubSupPhyFinVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_diagnosisOfCancerSubSuphospVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.hospitalization",
  ],

  [
    "ros_diagnosisOfCancerSubSuptreatVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.treatments",
  ],
  [
    "ros_diagnosisOfCancerSubSuplabTestsVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_diagnosisOfCancerSubSupimagVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.imagingStudies",
  ],
  [
    "ros_diagnosisOfCancerSubSupSurVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.surgery",
  ],
  [
    "ros_diagnosisOfCancerSubSupBioVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_diagnosisOfCancerSubSupOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_diagnosisOfCancerSubSupComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.comment",
  ],

  [
    "ros_diagnosisOfCancerSubTypeBrainVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.brain",
  ],
  [
    "ros_diagnosisOfCancerSubTypeHeadVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.head",
  ],
  [
    "ros_diagnosisOfCancerSubTypeNeckVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.neck",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBreastVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.breast",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLungVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.lung",
  ],
  [
    "ros_diagnosisOfCancerSubTypeEsophagusVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.esophagus",
  ],
  [
    "ros_diagnosisOfCancerSubTypeStomachVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.stomach",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLiverVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.liver",
  ],
  [
    "ros_diagnosisOfCancerSubTypePancreasVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.pancreas",
  ],
  [
    "ros_diagnosisOfCancerSubTypeColonVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.colon",
  ],
  [
    "ros_diagnosisOfCancerSubTypeRectumVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.rectum",
  ],
  [
    "ros_diagnosisOfCancerSubTypeKidyneyVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.kidney",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBladderVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.bladder",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOvariesVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.ovaries",
  ],
  [
    "ros_diagnosisOfCancerSubTypeUterusVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.uterus",
  ],
  [
    "ros_diagnosisOfCancerSubTypeProstateVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.prostate",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBoneVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.bone",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBloodVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.blood",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLymphNodesVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.lymphNodes",
  ],

  [
    "ros_diagnosisOfCancerSubTypeSkinVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.skin",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.other",
  ],

  [
    "ros_diagnosisOfCancerSubTypeComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.comment",
  ],

  [
    "ros_diagnosisOfCancerSubSpecTyVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.specialTypes",
  ],

  [
    "ros_diagnosisOfCancerSubStageSpecCanVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.stageSpecificToTheCancer",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatment",
  ],
  [
    "ros_activeTreatmentComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatChemoVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.chemotherapy",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatRadiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.radiation",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatStemVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.stemCell",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatBoneVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.boneMarrow",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSurgVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.surgery",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatImmuVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.immuneSystem",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.other",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.nausea",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.vomiting",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.diarrhea",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.anemia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.neutropenia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.thrombocytopenia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.weakness",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.lossOfAppetite",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.other",
  ],

  [
    "ros_diagnosisOfCancerSubSideEffComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.comment",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasis",
  ],

  [
    "ros_MetastasisComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.comment",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisCachexiaValComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.cachexia.comment",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisLocVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.location",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisCachexiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.cachexia.isCachexia",
  ],

  [
    "ros_diagnosisOfCancerSubSeeASpecVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.isSeeASpecialist",
  ],
  [
    "ros_diagnosisOfCancerSubSeeASpecProVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.isSeeASpecialistSubAnswer.Provider",
  ],
  [
    "ros_SeeASpecComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.comment",
  ],

  //cardio

  ["ros_cardioVal", "cardiovascular.isCardiovascular"],
  ["ros_cardioComment", "cardiovascular.comment"],

  [
    "ros_cardioSubDiagnoses",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses",
  ],

  // [
  //   "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe",
  //   "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe",
  // ],

  [
    "ros_cardioSubDiagnosesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.comment",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythm",
  ],
  [
    "ros_cardioSubDiagnosesaneurysmVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysm",
  ],
  [
    "ros_cardioSubDiagnosesanginaVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.angina",
  ],
  [
    "ros_cardioSubDiagnosesatrialFibrillationVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillation",
  ],
  [
    "ros_cardioSubDiagnosescardioRespiratoryShockVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShock",
  ],
  [
    "ros_cardioSubDiagnosescardiomyopathyVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathy",
  ],
  [
    "ros_cardioSubDiagnosescongestiveHeartFailureVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailure",
  ],
  [
    "ros_cardioSubDiagnosesdeepVeinThrombosisVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosis",
  ],
  [
    "ros_cardioSubDiagnoseshyperlipidemiaVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemia",
  ],
  [
    "ros_cardioSubDiagnoseshypertensionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertension",
  ],
  [
    "ros_cardioSubDiagnosesischemicHeartDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDisease",
  ],
  [
    "ros_cardioSubDiagnosesmyocardialInfarctionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarction",
  ],
  [
    "ros_cardioSubDiagnosesperipheralVascularDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDisease",
  ],
  [
    "ros_cardioSubDiagnosespulmonaryHypertensionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertension",
  ],
  [
    "ros_cardioSubDiagnosesvalvularDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDisease",
  ],
  [
    "ros_cardioSubDiagnosesotherVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.other",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescriberuleOutVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSuprateControllingDrug",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.rateControllingDrug",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupelectrophysiology",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.electrophysiology",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedPacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedPacemaker",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedDefibrillato",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.bradycardia",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.tachycardia",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregular",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesirregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.irregularlyIrregular",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesprematureContractures",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.prematureContractures",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.comment",
  ],

  //aneurysm

  [
    "ros_cardioSubDiagnosesAneurysmDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.historyOf",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.ruleOut",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSupPhyExam",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupultra",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.ultraSoundLastStudy",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupImage",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.ImageStudies",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmCrDesAbdominal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.abdominal",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmCrDesThoracic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.thoracic",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDesPeripheral",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.peripheral",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.comment",
  ],

  //

  //angina

  [
    "ros_cardioSubDiagnosesAnginaDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupMedi",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSuphistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.historyCharacterizingChestPain",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupstressTest",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.stressTest",
  ],

  [
    "ros_cardioSubDiagnosesAnginaSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesAnginaSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesAnginaCrDesStable",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.anginaDescribe.stable",
  ],

  [
    "ros_cardioSubDiagnosesAnginaCrDesUnstable",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.anginaDescribe.unstable",
  ],

  [
    "ros_cardioSubDiagnosesAnginaCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.anginaDescribe.comment",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupMedi",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupSymptoms",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.history",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupelectroCardio",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.electricCardioversion",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeParoxysmal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.paroxysmal",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.chronic",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.unknown",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.comment",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingAnti",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.anticoagulant",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingRate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.rateControllingMedication",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.other",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.comment",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.history",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupImplant",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.implantedDefibrillator",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.cardiacArrest.isCardiacArrest",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.cardiacArrest.comment",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.ruleOut",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySupEcho",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.echo",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupCardiacCath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.cardiacCath",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySupChestPain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupLightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeDilated",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.dilated",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.hypertrophic",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.restrictive",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.unknown",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.comment",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupEjection",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.ejectionFraction",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupCardio",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.cardiomegaly",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupOrtho",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.orthopnea",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupDOE",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.doe",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupPnd",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.pnd",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupS3",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.sthree",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupPeripheralEdema",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.peripheralEdema",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupChestPain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupLightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.acuteOnChronic",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.acute",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.chronic",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesDias",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.diastolic",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesSys",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.systolic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.unknown",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.comment",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.secondaryToHypertension.isSecondaryToHypertension",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.secondaryToHypertension.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnAce.ispatientOnAce",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnAce.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnBetaBlocker.isPatientOnBetaBlocker",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnBetaBlocker.comment",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.active",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupPhysicalFindings",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubprophylactic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulationSubAnswer.prophylactic",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubtherapeutic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulationSubAnswer.therapeutic",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulationSubAnswer.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupVascularStudies",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.vascularStudies",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupVenaFilter",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.venaCavaFilter",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupEdema",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.edema",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesAcute",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.acute",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.chronic",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisPersistent",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.persistentForThreeMonths.isPersistentForThreeMonths",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.persistentForThreeMonths.comment",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupLabResults",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.labResults",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.medication",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.chestPain",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.patientOnStatin.isPatientOnStatin",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.patientOnStatin.comment",
  ],

  //hypertension
  [
    "ros_cardioSubDiagnosesHypertensionDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupPhysicalExam",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupSymptoms",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_cardioSubDiagnosesHypertensionSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesHypertensionadequatelyControlled",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.adequatelyControlled.isAdequatelyControlled",
  ],

  [
    "ros_cardioSubDiagnosesHypertensionadequatelyControlledComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.adequatelyControlled.comment",
  ],

  //ischemicheartdisease
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSuprateControllingDrug",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.rateControllingDrug",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupelectrophysiology",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.electrophysiology",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupimplantedPacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedPacemaker",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedDefibrillato",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.bradycardia",
  ],

  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.tachycardia",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseCrDesregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregular",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseCrDesirregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.irregularlyIrregular",
  ],

  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseCrDesprematureContractures",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.prematureContractures",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.comment",
  ],

  //peripheralvasculardisease
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSuprateControllingDrug",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.rateControllingDrug",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupelectrophysiology",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.electrophysiology",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupimplantedPacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedPacemaker",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedDefibrillato",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.bradycardia",
  ],

  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.tachycardia",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregular",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesirregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.irregularlyIrregular",
  ],

  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesprematureContractures",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.prematureContractures",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.comment",
  ],

  //pulmonaryhypertension

  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSuprateControllingDrug",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.rateControllingDrug",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupelectrophysiology",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.electrophysiology",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupimplantedPacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedPacemaker",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedDefibrillato",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.bradycardia",
  ],

  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.tachycardia",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionCrDesregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregular",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionCrDesirregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.irregularlyIrregular",
  ],

  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionCrDesprematureContractures",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.prematureContractures",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.comment",
  ],

  //valvulardisease
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSuprateControllingDrug",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.rateControllingDrug",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupelectrophysiology",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.electrophysiology",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupimplantedPacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedPacemaker",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedDefibrillato",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.bradycardia",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.tachycardia",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregular",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesirregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.irregularlyIrregular",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesprematureContractures",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.prematureContractures",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.comment",
  ],
  //other
  [
    "ros_cardioSubDiagnosesOtherDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesOtherSuprateControllingDrug",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.rateControllingDrug",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupelectrophysiology",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.electrophysiology",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesOtherSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupimplantedPacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedPacemaker",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedDefibrillato",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesOtherCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.bradycardia",
  ],

  [
    "ros_cardioSubDiagnosesOtherCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.tachycardia",
  ],
  [
    "ros_cardioSubDiagnosesOtherCrDesregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregular",
  ],
  [
    "ros_cardioSubDiagnosesOtherCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesOtherCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesOtherCrDesirregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.irregularlyIrregular",
  ],

  [
    "ros_cardioSubDiagnosesOtherCrDesprematureContractures",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.prematureContractures",
  ],
  [
    "ros_cardioSubDiagnosesOtherCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.comment",
  ],
  //

  [
    "ros_takemedicationsasprescribedCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[3].takeMedicationsAsPescribed",
  ],
  [
    "ros_otherCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[4].other",
  ],
  [
    "ros_ThirtyCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[2].exerciseThirtyMinADay",
  ],
  [
    "ros_bloodPreCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[0].bloodPressureChecks",
  ],
  [
    "ros_heartHeCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[1].heartHealthyDiet",
  ],
  [
    "cardio_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[3].comment",
  ],

  [
    "cardio_RecommendationsotherCommentVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[4].comment",
  ],

  [
    "cardio_RecommendationsexerciseThirtyMinADayCommentVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[2].comment",
  ],
  [
    "cardio_RecommendationsbloodPressureChecksCommentVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[0].comment",
  ],
  [
    "cardio_RecommendationsheartHealthyDietCommentVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[1].comment",
  ],

  //endocrine

  ["ros_endoVal", "endocrine.isEndocrine"],
  ["ros_endoValComment", "endocrine.comment"],

  [
    "ros_takeMedicationsAsPescribedEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[0].takeMedicationsAsPescribed",
  ],

  [
    "endocrine_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[0].comment",
  ],
  [
    "ros_otherEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[2].other",
  ],
  [
    "endocrine_RecommendationsotherCommentVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[2].comment",
  ],

  [
    "ros_checkBloodSugarEndorineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[1].checkBloodSugar",
  ],
  [
    "endocrine_RecommendationsCheckBloodSugarCommentVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[1].comment",
  ],
  [
    "ros_endoSubDiagnosesComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.comment",
  ],

  ["ros_endoSubDiagnoses", "endocrine.isEndocrineSubAnswer.diagnoses"],

  [
    "ros_endoSubDiagnoseschronicKidneyVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidney",
  ],
  [
    "ros_endoSubDiagnosescoronaryArteryVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArtery",
  ],
  [
    "ros_endoSubDiagnosescushingsDiseaseVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDisease",
  ],
  [
    "ros_endoSubDiagnosesdiabetesVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetes",
  ],
  [
    "ros_endoSubDiagnosesdiabeticRetinopathyVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathy",
  ],
  [
    "ros_endoSubDiagnosessecondaryHyperparathyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidism",
  ],
  [
    "ros_endoSubDiagnoseshypertensionDiabetesVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetes",
  ],
  [
    "ros_endoSubDiagnoseshyperthyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidism",
  ],

  [
    "ros_endoSubDiagnoseshypothyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidism",
  ],
  [
    "ros_endoSubDiagnosesperipheralNeuropathyVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathy",
  ],
  [
    "ros_endoSubDiagnosesperipheralVascularVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascular",
  ],
  [
    "ros_endoSubDiagnoseshyperparathyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidism",
  ],
  [
    "ros_endoSubDiagnosesotherVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.other",
  ],

  [
    "ros_endoSubDiagnosesotherSubAnsVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe",
  ],

  [
    "ros_endoSubDiagnoseschronicKidneydesActiveVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.active",
  ],
  [
    "ros_endoSubDiagnosesdeschronicKidneydesHistoryOfVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.historyOf",
  ],
  [
    "ros_endoSubDiagnosesdeschronicKidneydesRuleOutVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.ruleOut",
  ],
  [
    "ros_endoSubDiagnosesdeschronicKidneydesComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.comment",
  ],

  [
    "ros_endoSubDiagnoseschronicKidneySupdecreasedGFRVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.decreasedGFR",
  ],
  [
    "ros_endoSubDiagnoschronicKidneySupalbuminuriaVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.albuminuria",
  ],

  [
    "ros_endoSubDiagnoseschronicKidneySupelevatedBunVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.elevatedBun",
  ],
  [
    "ros_endoSubDiagnoseschronicKidneySupdialysisVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.dialysis",
  ],

  [
    "ros_endoSubDiagnoseschronicKidneySupotherVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.other",
  ],
  [
    "ros_endoSubDiagnoseschronicKidneySupotherSubAnswerVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_endoSubDiagnoseschronicKidneySupComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.comment",
  ],

  [
    "ros_endoSubDiagnoseschronicKidneypatientOnAceVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.patientOnAce.isPatientOnAce",
  ],
  [
    "ros_endoSubDiagnoseschronicKidneypatientOnAceComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.patientOnAce.comment",
  ],

  ["ros_gastroVal", "gastrointestinal.isGastrointestinal"],
  ["ros_gastroValComment", "gastrointestinal.comment"],

  [
    "ros_takeMedicationsAsPescribedGastroVal",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[0].takeMedicationsAsPescribed",
  ],

  [
    "gastro_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[0].comment",
  ],
  [
    "ros_otherGastroVal",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[1].other",
  ],
  [
    "gastro_otherCommentVal",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[1].comment",
  ],

  [
    "ros_gastroSubDiagnoses",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses",
  ],
  [
    "ros_gastroSubDiagnosesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.comment",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstruction",
  ],
  [
    "ros_gastroSubDiagnosescachexiaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexia",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDisease",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosis",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolyps",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitis",
  ],
  [
    "ros_gastroSubDiagnosesgallBladderDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDisease",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesis",
  ],
  [
    "ros_gastroSubDiagnosesgerdVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerd",
  ],
  [
    "ros_gastroSubDiagnosesglBleedVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleed",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitis",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDisease",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitis",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDisease",
  ],
  [
    "ros_gastroSubDiagnosesotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.other",
  ],

  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionsubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.comment",
  ],

  //cachexia

  [
    "ros_gastroSubDiagnosescachexiadesactive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosescachexiadeshistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiasubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosescachexiadesruleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosescachexiadesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosescachexiaSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.albumin",
  ],
  [
    "ros_gastroSubDiagnoscachexiaSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.muscleWasting",
  ],

  [
    "ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.historyOfSevere",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosescachexiaSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.comment",
  ],
  //celiac
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.weightLoss",
  ],
  [
    "ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.anemia",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.changeInBowelMovements",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.osteoporosis",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupDietVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.diet",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseGlueten",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.glutenFreeDiet.isGlutenFreeDiet",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseGluetenComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.glutenFreeDiet.comment",
  ],

  //cirhosis

  [
    "ros_gastroSubDiagnosesdescirrhosisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisSupsymptomsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisSupLabStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupMriVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupBoipsyVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupOtherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisEndStage",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDisease",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisEndStageComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.comment",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.comment",
  ],

  //colon
  [
    "ros_gastroSubDiagnosescolonPolypsDesActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosescolonSuphistoryVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.history",
  ],
  [
    "ros_gastroSubDiagnosescolonSupsymptomsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosescolonSupPhysicalfindingsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosescolonSupmedicationsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.medications",
  ],
  [
    "ros_gastroSubDiagnosescolonSupTestResultsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_gastroSubDiagnosescolonSupImageStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_gastroSubDiagnosescolonSupBiopsyVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.biopsy",
  ],

  [
    "ros_gastroSubDiagnosescolonDmeVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.dme",
  ],

  [
    "ros_gastroSubDiagnosescolonAbdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosescolonNausaVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosescolonOtherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescolonOtherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.otherSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosescolonSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosescolonCrDesBengirVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.benign",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesCarcinomaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.carcinomaInSitu",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesPolyposisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.familialPolyposis",
  ],

  [
    "ros_gastroSubDiagnosescolonCrDesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.comment",
  ],

  //diverticultis
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupDietVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesdiverticulitisSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosesdiverticulitisabscessVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.abscess.isAbscess",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisabscessCommentVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.abscess.comment",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisperforationVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.perforation.isPerforation",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisperforationCommentVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.perforation.comment",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitishighFiberDietVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.highFiberDiet.ishighFiberDiet",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitishighFiberDietCommentVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.highFiberDiet.comment",
  ],

  //gallbladder
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosgallBladderDiseaseSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.comment",
  ],

  //gastroparesis
  [
    "ros_gastroSubDiagnosesdesgastroparesisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesgastroparesisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosgastroparesisSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.comment",
  ],

  //gerd
  [
    "ros_gastroSubDiagnosesdesgerdActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesgerdComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosgerdSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesgerdSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesgerdSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesgerdSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesgerdSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.comment",
  ],

  //gibleed
  [
    "ros_gastroSubDiagnosesdesgibleedActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesgibleedHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesgibleedRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesgibleedComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesgibleedSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosgibleedSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesgibleedSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesgibleedSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesgibleedSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesgibleedSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesgibleedSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.comment",
  ],

  //hepatitis
  [
    "ros_gastroSubDiagnosesdeshepatitisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdeshepatitisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnoshepatitisSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.comment",
  ],

  //inflammatory
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosinflammatoryBowelDiseaseSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.comment",
  ],

  //pancreatitis
  [
    "ros_gastroSubDiagnosesdespancreatitisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdespancreatitisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnospancreatitisSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.comment",
  ],

  //ulcer disease
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesulcerDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosulcerDiseaseSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.comment",
  ],

  //other
  [
    "ros_gastroSubDiagnosesdesotherActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesotherHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesotherRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesotherComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesotherSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosotherSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesotherSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesotherSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesotherSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesotherSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesotherSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesotherSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],

  ["ros_genitoVal", "genitourinary.isGenitourinary"],
  ["ros_genitoValComment", "genitourinary.comment"],

  [
    "ros_takeMedicationsAsPescribedGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "genito_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[0].comment",
  ],
  [
    "ros_otherGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].other",
  ],
  [
    "genito_RecommendationsotherCommentVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].comment",
  ],

  [
    "ros_genitoSubDiagnosesComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.comment",
  ],

  [
    "ros_genitoSubDiagnoses",
    "genitourinary.isGenitourinarySubAnswer.diagnoses",
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailure",
  ],
  [
    "ros_genitoSubDiagnosesbphVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bph",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDisease",
  ],
  [
    "ros_genitoSubDiagnosesesrdVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrd",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunction",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUti",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecological",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStones",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosis",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinence",
  ],

  [
    "ros_genitoSubDiagnosesotherVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.other",
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesActiveVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesHistoryOfVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesRuleOutVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.comment",
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupLabtestVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupcalculatedGFRVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.calculatedGFR",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSuphospitalizationVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupotherVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.comment",
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureEti",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.etiology",
  ],
];

const ros_optionsTreeMapObject_srilatha = {
  ros_diagnosisOfCancerSubDescActiveVal: "active",
  ros_diagnosisOfCancerSubDescHistoryOfVal: "historyOf",
  ros_diagnosisOfCancerSubDescRuleOutVal: "ruleOut",

  ros_diagnosisOfCancerSubSupPhyFinVal: "physicalFindings",
  ros_diagnosisOfCancerSubSuphospVal: "hospitalization",
  ros_diagnosisOfCancerSubSuptreatVal: "treatments",
  ros_diagnosisOfCancerSubSuplabTestsVal: "labTests",
  ros_diagnosisOfCancerSubSupimagVal: "imagingStudies",
  ros_diagnosisOfCancerSubSupSurVal: "surgery",
  ros_diagnosisOfCancerSubSupBioVal: "biopsy",
  ros_diagnosisOfCancerSubSupOtherVal: "other",

  ros_diagnosisOfCancerSubTypeBrainVal: "brain",
  ros_diagnosisOfCancerSubTypeHeadVal: "head",
  ros_diagnosisOfCancerSubTypeNeckVal: "neck",
  ros_diagnosisOfCancerSubTypeBreastVal: "breast",
  ros_diagnosisOfCancerSubTypeLungVal: "lung",
  ros_diagnosisOfCancerSubTypeEsophagusVal: "esophagus",
  ros_diagnosisOfCancerSubTypeStomachVal: "stomach",
  ros_diagnosisOfCancerSubTypeLiverVal: "liver",
  ros_diagnosisOfCancerSubTypePancreasVal: "pancreas",
  ros_diagnosisOfCancerSubTypeColonVal: "colon",
  ros_diagnosisOfCancerSubTypeRectumVal: "rectum",
  ros_diagnosisOfCancerSubTypeKidyneyVal: "kidney",
  ros_diagnosisOfCancerSubTypeBladderVal: "bladder",
  ros_diagnosisOfCancerSubTypeOvariesVal: "ovaries",
  ros_diagnosisOfCancerSubTypeUterusVal: "uterus",
  ros_diagnosisOfCancerSubTypeProstateVal: "prostate",
  ros_diagnosisOfCancerSubTypeBoneVal: "bone",
  ros_diagnosisOfCancerSubTypeBloodVal: "blood",
  ros_diagnosisOfCancerSubTypeLymphNodesVal: "lymphNodes",
  ros_diagnosisOfCancerSubTypeSkinVal: "skin",
  ros_diagnosisOfCancerSubTypeOtherVal: "other",

  ros_diagnosisOfCancerSubActiveTreatChemoVal: "chemotherapy",
  ros_diagnosisOfCancerSubActiveTreatRadiaVal: "radiation",
  ros_diagnosisOfCancerSubActiveTreatStemVal: "stemCell",
  ros_diagnosisOfCancerSubActiveTreatBoneVal: "boneMarrow",
  ros_diagnosisOfCancerSubActiveTreatSurgVal: "surgery",
  ros_diagnosisOfCancerSubActiveTreatImmuVal: "immuneSystem",
  ros_diagnosisOfCancerSubActiveTreatOtherVal: "other",
  ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal: "nausea",
  ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal: "vomiting",
  ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal: "diarrhea",
  ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal: "anemia",
  ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal: "neutropenia",
  ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal:
    "thrombocytopenia",
  ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal: "weakness",
  ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal: "lossOfAppetite",
  ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal: "other",

  ros_cardioSubDiagnosesabnormalCardiacRhythmVal: "abnormalCardiacRhythm",
  ros_cardioSubDiagnosesaneurysmVal: "aneursym",
  ros_cardioSubDiagnosesanginaVal: "angina",
  ros_cardioSubDiagnosesatrialFibrillationVal: "atrialFibrillation",
  ros_cardioSubDiagnosescardioRespiratoryShockVal: "cardioRespiratoryShock",
  ros_cardioSubDiagnosescardiomyopathyVal: "cardiomyopathy",
  ros_cardioSubDiagnosescongestiveHeartFailureVal: "congestiveHeartFailure",
  ros_cardioSubDiagnosesdeepVeinThrombosisVal: "deepVeinThrombosis",
  ros_cardioSubDiagnoseshyperlipidemiaVal: "hyperlipidemia",
  ros_cardioSubDiagnoseshypertensionVal: "hypertension",
  ros_cardioSubDiagnosesischemicHeartDiseaseVal: "ischemicHeartDisease",
  ros_cardioSubDiagnosesmyocardialInfarctionVal: "myocardialInfarction",
  ros_cardioSubDiagnosesperipheralVascularDiseaseVal:
    "peripheralVascularDisease",
  ros_cardioSubDiagnosespulmonaryHypertensionVal: "pulmonaryHypertension",
  ros_cardioSubDiagnosesvalvularDiseaseVal: "valvularDisease",
  ros_cardioSubDiagnosesotherVal: "other",

  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal: "active",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal: "historyOf",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescriberuleOutVal: "ruleOut",

  ros_cardioSubDiagnosesabnormalCardiacRhythmSupECG: "ecg",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSuprateControllingDrug:
    "useofratecontrollingdrug",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupanticoagulation:
    "useofanticoagulation",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupelectrophysiology:
    "electrophysiologyprocedure/cardioversion",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupchestpain: "chestpain",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSuplightHeadedness:
    "lightheadedness",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedPacemaker:
    "implantedpacemaker",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedDefibrillator:
    "implanteddefibrillator",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupshortnessOfBreath:
    "shortnessofbreath",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupwheezing: "wheezing",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupchronicCough: "chroniccough",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupother: "other",

  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia: "bradycardia",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia: "tachycardia",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregular:
    "regularlyirregular",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesirregularlyIrregular:
    "irregularlyirregular",

  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesprematureContractures:
    "prematurecontractures",

  ros_cardioSubDiagnosesAneurysmDesActive: "active",
  ros_cardioSubDiagnosesAneurysmDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAneurysmDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesAneurysmSupPhyExam: "physicalExam",
  ros_cardioSubDiagnosesAneurysmSupultra: "ultrasoundlaststudydate&size",
  ros_cardioSubDiagnosesAneurysmSupImage: "imageStudies",
  ros_cardioSubDiagnosesAneurysmSupchestpain: "chestPain",
  ros_cardioSubDiagnosesAneurysmSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesAneurysmSupshortnessOfBreath: "shortnessofbreath",
  ros_cardioSubDiagnosesAneurysmSupwheezing: "wheezing",
  ros_cardioSubDiagnosesAneurysmSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesAneurysmSupother: "other",

  ros_cardioSubDiagnosesAneurysmCrDesAbdominal: "abdominal",
  ros_cardioSubDiagnosesAneurysmCrDesThoracic: "thoracic",
  ros_cardioSubDiagnosesAneurysmCrDesPeripheral: "peripheral",

  ros_cardioSubDiagnosesAnginaDesActive: "active",
  ros_cardioSubDiagnosesAnginaDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAnginaDesruleOut: "ruleOut",

  //sup

  ros_cardioSubDiagnosesAnginaSupMedi: "medications",
  ros_cardioSubDiagnosesAnginaSupHitory: "history",
  ros_cardioSubDiagnosesAnginaSupstressTest: "stressTest",
  ros_cardioSubDiagnosesAnginaSupchestpain: "chestPain",
  ros_cardioSubDiagnosesAnginaSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesAnginaSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesAnginaSupwheezing: "wheezing",
  ros_cardioSubDiagnosesAnginaSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesAnginaSupother: "other",

  //cr
  ros_cardioSubDiagnosesAnginaCrDesStable: "stable",
  ros_cardioSubDiagnosesAnginaCrDesUnstable: "unstable",

  ros_cardioSubDiagnosesAtrialFibrillationDesActive: "active",
  ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAtrialFibrillationDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesAtrialFibrillationSupMedi: "medications",
  ros_cardioSubDiagnosesAtrialFibrillationSupECG: "ecg",
  ros_cardioSubDiagnosesAtrialFibrillationSupSymptoms: "symptoms",
  ros_cardioSubDiagnosesAtrialFibrillationSupHistory: "history",
  ros_cardioSubDiagnosesAtrialFibrillationSupelectricCardio: "electricCardio",
  ros_cardioSubDiagnosesAtrialFibrillationSupchestpain: "chestPain",
  ros_cardioSubDiagnosesAtrialFibrillationSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesAtrialFibrillationSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesAtrialFibrillationSupwheezing: "wheezing",
  ros_cardioSubDiagnosesAtrialFibrillationSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesAtrialFibrillationSupother: "other",

  //type
  ros_cardioSubDiagnosesAtrialFibrillationTypeParoxysmal: "paroxysmal",
  ros_cardioSubDiagnosesAtrialFibrillationTypeChronic: "chronic",
  ros_cardioSubDiagnosesAtrialFibrillationTypeUnknown: "Unknown",

  //ispatient taking
  ros_cardioSubDiagnosesAtrialFibrillationPatientTakingAnti: "anticoagulant",

  ros_cardioSubDiagnosesAtrialFibrillationPatientTakingRate:
    "rateControllingMedication",
  ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOther: "other",

  ros_cardioSubDiagnosesCardiorespiratoryShockDesActive: "active",
  ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCardiorespiratoryShockDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesCardiorespiratoryShockSupHistory: "history",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupImplant:
    "implantedDefibrillator",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupChestPain: "chestPain",
  ros_cardioSubDiagnosesCardiorespiratoryShockSuplightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupwheezing: "wheezing",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupother: "other",

  ros_cardioSubDiagnosesCardiomyopathyDesActive: "active",
  ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCardiomyopathyDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesCardiomyopathySupEcho: "echo",
  ros_cardioSubDiagnosesCardiomyopathySupCardiacCath: "cardiacCath",
  ros_cardioSubDiagnosesCardiomyopathySupChestPain: "chestPain",
  ros_cardioSubDiagnosesCardiomyopathySupLightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesCardiomyopathySupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesCardiomyopathySupwheezing: "wheezing",
  ros_cardioSubDiagnosesCardiomyopathySupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesCardiomyopathySupother: "other",

  ros_cardioSubDiagnosesCardiomyopathyTypeDilated: "dilated",
  ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic: "hypertrophic",
  ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive: "restrictive",
  ros_cardioSubDiagnosesCardiomyopathyTypeUnknown: "unknown",

  ros_cardioSubDiagnosesCongestiveHeartFailureDesActive: "active",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesCongestiveHeartFailureSupEjection: "ejectionFraction",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupCardio: "cardiomegaly",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupOrtho: "orthopnea",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupDOE: "doe",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupPnd: "pnd",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupS3: "s3",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupMedications: "medications",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupPeripheralEdema:
    "peripheralEdema",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupChestPain: "chestPain",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupLightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupwheezing: "wheezing",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupother: "other",

  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute: "acute",
  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic: "chronic",
  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic:
    "acuteOnChronic",

  ros_cardioSubDiagnosesCongestiveHeartFailureCrDesDias: "diastolic",
  ros_cardioSubDiagnosesCongestiveHeartFailureCrDesSys: "systolic",
  ros_cardioSubDiagnosesCongestiveHeartFailureCrDesUnknown: "unknown",

  ros_cardioSubDiagnosesDeepVeinThrombosisDesActive: "active",
  ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesDeepVeinThrombosisDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesDeepVeinThrombosisCrDesAcute: "acute",
  ros_cardioSubDiagnosesDeepVeinThrombosisCrDesChronic: "chronic",

  ros_cardioSubDiagnosesDeepVeinThrombosisSupPhysicalFindings:
    "physicalFindings",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulation:
    "useOfAnticoagulation",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupVascularStudies: "vascularStudies",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupVenaFilter: "venaCavaFilter",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupEdema: "edema",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupchestpain: "chestPain",
  ros_cardioSubDiagnosesDeepVeinThrombosisSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupwheezing: "wheezing",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupother: "other",

  ros_cardioSubDiagnosesHyperlipidemiaDesActive: "active",
  ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesHyperlipidemiaDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesHyperlipidemiaSupLabResults: "labResults",
  ros_cardioSubDiagnosesHyperlipidemiaSupMedications: "medication",
  ros_cardioSubDiagnosesHyperlipidemiaSupchestpain: "chestPain",
  ros_cardioSubDiagnosesHyperlipidemiaSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesHyperlipidemiaSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesHyperlipidemiaSupwheezing: "wheezing",
  ros_cardioSubDiagnosesHyperlipidemiaSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesHyperlipidemiaSupother: "other",

  ros_cardioSubDiagnosesHypertensionDesActive: "active",
  ros_cardioSubDiagnosesHypertensionDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesHypertensionDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesHypertensionSupPhysicalExam: "physicalExam",
  ros_cardioSubDiagnosesHypertensionSupMedications: "medications",
  ros_cardioSubDiagnosesHypertensionSupSymptoms: "symptoms",
  ros_cardioSubDiagnosesHypertensionSupchestpain: "chestPain",
  ros_cardioSubDiagnosesHypertensionSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesHypertensionSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesHypertensionSupwheezing: "wheezing",
  ros_cardioSubDiagnosesHypertensionSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesHypertensionSupother: "other",

  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive: "active",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesIschemicHeartDiseaseSupCardiacCath: "cardiacCath",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupHistory: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupDiagnosis: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupMedications: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupCabgs: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupEcg: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupchestpain: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSuplightHeadedness: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupshortnessOfBreath: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupwheezing: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupchronicCough: "",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupother: "",

  ros_cardioSubDiagnosesMyocardialDesActive: "active",
  ros_cardioSubDiagnosesMyocardialDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesMyocardialDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesMyocardialSupECG: "",
  ros_cardioSubDiagnosesMyocardialSupLabResults: "",
  ros_cardioSubDiagnosesMyocardialSupHistory: "",
  ros_cardioSubDiagnosesMyocardialSupMedications: "",
  ros_cardioSubDiagnosesMyocardialSupchestpain: "",
  ros_cardioSubDiagnosesMyocardialSuplightHeadedness: "",
  ros_cardioSubDiagnosesMyocardialSupshortnessOfBreath: "",
  ros_cardioSubDiagnosesMyocardialSupwheezing: "",
  ros_cardioSubDiagnosesMyocardialSupchronicCough: "",
  ros_cardioSubDiagnosesMyocardialSupother: "",

  ros_cardioSubDiagnosesMyocardialIspateintAspirin: "",
  ros_cardioSubDiagnosesMyocardialIspateintPlavix: "",
  ros_cardioSubDiagnosesMyocardialIspateintNitrate: "",
  ros_cardioSubDiagnosesMyocardialIspateintOther: "",

  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive: "active",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupVascular: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupClaudication: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupExtremity: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupDiminished: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupAmputation: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchestpain: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSuplightHeadedness: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupshortnessOfBreath: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupwheezing: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchronicCough: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupother: "",

  ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesUlceration: "",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesGangerene: "",

  ros_cardioSubDiagnosesPulmonaryHypertensionDesActive: "active",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesPulmonaryHypertensionSupCardiacCath: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupO2: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupEdema: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupMedication: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupchestpain: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSuplightHeadedness: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupshortnessOfBreath: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupwheezing: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupchronicCough: "",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupother: "",

  ros_cardioSubDiagnosesvalvulardiseaseDesActive: "active",
  ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesvalvulardiseaseDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesvalvulardiseaseSupHistory: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupSymptoms: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupPhysicalFindings: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupMedications: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupTestResults: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupImageStudies: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupBiopsy: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupDME: "",

  ros_cardioSubDiagnosesvalvulardiseaseSupchestpain: "",
  ros_cardioSubDiagnosesvalvulardiseaseSuplightHeadedness: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupshortnessOfBreath: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupwheezing: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupchronicCough: "",
  ros_cardioSubDiagnosesvalvulardiseaseSupother: "",

  ros_cardioSubDiagnosesvalvulardiseaseCrDesMitral: "",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesAortic: "",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspid: "",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryInsuff: "",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryStenosis: "",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesMitralInsuff: "",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesAorticInsuff: "",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspidInsuff: "",

  // //   //other

  ros_cardioSubDiagnosesOtherDesActive: "active",
  ros_cardioSubDiagnosesOtherDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesOtherDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesOtherSupHistory: "history",
  ros_cardioSubDiagnosesOtherSupSymptoms: "symptoms",
  ros_cardioSubDiagnosesOtherSupPhysicalFindings: "physicalFindings",
  ros_cardioSubDiagnosesOtherSupMedications: "medications",
  ros_cardioSubDiagnosesOtherSupTestResults: "testResults",
  ros_cardioSubDiagnosesOtherSupImageStudies: "imageStudies",
  ros_cardioSubDiagnosesOtherSupBiopsy: "biopsy",
  ros_cardioSubDiagnosesOtherSupDME: "dme",
  ros_cardioSubDiagnosesOtherSupChestPain: "chestPain",
  ros_cardioSubDiagnosesOtherSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesOtherSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesOtherSupwheezing: "wheezing",
  ros_cardioSubDiagnosesOtherSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesOtherSupother: "other",

  ros_genitoSubDiagnosesacuteRenalFailureVal: "acuteRenalFailure",
  ros_genitoSubDiagnosesbphVal: "bhp",
  ros_genitoSubDiagnoseschronicKidneyDiseaseVal: "chronicKidneyDisease",
  ros_genitoSubDiagnosesesrdVal: "esrd",
  ros_genitoSubDiagnoseserectileDysfunctionVal: "erectileDysfunction",
  ros_genitoSubDiagnosesfrequentUtiVal: "frequentUti",
  ros_genitoSubDiagnosesgynecologicalVal: "gynecological",
  ros_genitoSubDiagnoseskidneyStonesVal: "kidyneyStones",
  ros_genitoSubDiagnosesnephritisOrNephrosisVal: "nephritisOrNephrosis",
  ros_genitoSubDiagnosesurinaryIncontinenceVal: "urinaryIncontinence",
  ros_genitoSubDiagnosesotherVal: "other",

  ros_genitoSubDiagnosesacuteRenalFailuredesActiveVal: "active",
  ros_genitoSubDiagnosesacuteRenalFailuredesHistoryOfVal: "historyOf",
  ros_genitoSubDiagnosesacuteRenalFailuredesRuleOutVal: "ruleOut",

  ros_genitoSubDiagnosesacuteRenalFailureSupLabtestVal: "labTests",
  ros_genitoSubDiagnosesacuteRenalFailureSupcalculatedGFRVal: "calculatedGfr",
  ros_genitoSubDiagnosesacuteRenalFailureSuphospitalizationVal:
    "hospitalization",
  ros_genitoSubDiagnosesacuteRenalFailureSupotherVal: "other",

  ros_gastroSubDiagnosesbowelObstructionVal: "bowelobstruction",
  ros_gastroSubDiagnosescachexiaVal: "cachexia",
  ros_gastroSubDiagnosesceliacDiseaseVal: "celiacdisease",
  ros_gastroSubDiagnosescirrhosisVal: "cirrhosis",
  ros_gastroSubDiagnosescolonPolypsVal: "colonpolyps",
  ros_gastroSubDiagnosesdiverticulitisVal: "diverticultis",
  ros_gastroSubDiagnosesgallBladderDiseaseVal: "gallbladderdisease",
  ros_gastroSubDiagnosesgastroparesisVal: "gastroparesis",
  ros_gastroSubDiagnosesgerdVal: "gerd",
  ros_gastroSubDiagnosesglBleedVal: "gibleed",
  ros_gastroSubDiagnoseshepatitisVal: "hepatitis",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal: "inflammatoryboweldisease",
  ros_gastroSubDiagnosespancreatitisVal: "pancreatitis",
  ros_gastroSubDiagnosesulcerDiseaseVal: "ulcerdisease",
  ros_gastroSubDiagnosesotherVal: "other",

  ros_gastroSubDiagnosesdesbowelObstructionActiveVal: "active",
  ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosescachexiadesactive: "active",
  ros_gastroSubDiagnosescachexiadeshistoryOf: "historyOf",
  ros_gastroSubDiagnosescachexiadesruleOut: "ruleOut",

  ros_gastroSubDiagnosescachexiaSupalbuminVal: "albumin<3.5g/dl",
  ros_gastroSubDiagnoscachexiaSupmuscleWastingVal: "muscleWasting",
  ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal:
    "historyofsevereweightloss",
  ros_gastroSubDiagnosescachexiaSupabdominalPainVal: "abdominalpain",
  ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescachexiaSupotherVal: "other",

  ros_gastroSubDiagnosesdesceliacDiseaseActiveVal: "active",
  ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesceliacDiseaseRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal: "weightLoss",
  ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal: "anemia",
  ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal:
    "changeinbowelmovements",
  ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal: "osteoporosis",
  ros_gastroSubDiagnosesceliacDiseaseSupDietVal: "diet",
  ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesceliacDiseaseSupotherVal: "other",

  ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal:
    "hospitalization",
  ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal:
    "physicalFinding",
  ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal:
    "nauseandvomiting",
  ros_gastroSubDiagnosesbowelObstructionSupotherVal: "other",

  ros_gastroSubDiagnosesdescirrhosisActiveVal: "active",
  ros_gastroSubDiagnosesdescirrhosisHistoryOfVal: "history",
  ros_gastroSubDiagnosesdescirrhosisRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosescirrhosisSupsymptomsVal: "symptoms",
  ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal: "physicalFindings",
  ros_gastroSubDiagnosescirrhosisSupLabStudiesVal: "labStudies",
  ros_gastroSubDiagnosescirrhosisSupMriVal: "mri",
  ros_gastroSubDiagnosescirrhosisSupBoipsyVal: "biopsy",
  ros_gastroSubDiagnosescirrhosisSupabdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescirrhosisSupOtherVal: "other",

  ros_gastroSubDiagnosescolonPolypsDesActiveVal: "active",
  ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosescolonPolypsDesRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosescolonSuphistoryVal: "history",
  ros_gastroSubDiagnosescolonSupsymptomsVal: "symptoms",
  ros_gastroSubDiagnosescolonSupPhysicalfindingsVal: "physicalFindings",
  ros_gastroSubDiagnosescolonSupmedicationsVal: "medications",
  ros_gastroSubDiagnosescolonSupTestResultsVal: "testResults",
  ros_gastroSubDiagnosescolonSupImageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosescolonSupBiopsyVal: "biopsy",
  ros_gastroSubDiagnosescolonDmeVal: "dme",
  ros_gastroSubDiagnosescolonAbdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosescolonNausaVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescolonOtherVal: "other",

  ros_gastroSubDiagnosescolonCrDesBengirVal: "bengir",

  ros_gastroSubDiagnosescolonCrDesCarcinomaVal: "carcinomainsitu",

  ros_gastroSubDiagnosescolonCrDesPolyposisVal: "fmilialpolyposis",

  ros_gastroSubDiagnosesdesdiverticulitisActiveVal: "active",
  ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesdiverticulitisRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal: "colonoscopy",
  ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal: "symptoms",
  ros_gastroSubDiagnosesdiverticulitisSupDietVal: "diet",
  ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal: "abdominalPain",
  ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesdiverticulitisSupotherVal: "other",

  ros_endoSubDiagnoseschronicKidneyVal: "ckdstDiabetes",
  ros_endoSubDiagnosescoronaryArteryVal: "coronaryArteryDiseaseAndDiabet",
  ros_endoSubDiagnosescushingsDiseaseVal: "cushingsDisease",
  ros_endoSubDiagnosesdiabetesVal: "diabetes",
  os_endoSubDiagnosesdiabeticRetinopathyVal: "diabeticRetinopathy",
  ros_endoSubDiagnosessecondaryHyperparathyroidismVal:
    "secondaryHyperparathyroidism",
  ros_endoSubDiagnoseshypertensionDiabetesVal: "hypertensionAndDiabetes",
  ros_endoSubDiagnoseshyperthyroidismVal: "hyperthyroidism",
  ros_endoSubDiagnoseshypothyroidismVal: "hypothyroidism",
  ros_endoSubDiagnosesperipheralNeuropathyVal: "peripheralNeuropathySecondaryT",
  ros_endoSubDiagnosesperipheralVascularVal: "pvdstDiabetes",
  ros_endoSubDiagnoseshyperparathyroidismVal: "hyperparathyroidism",
  ros_endoSubDiagnosesotherVal: "other",
};

const ros_OptionsTreeWrappers_srilatha = [
  [
    "ros_diagnosisOfCancerSubDesc",
    [
      "ros_diagnosisOfCancerSubDescActiveVal",
      "ros_diagnosisOfCancerSubDescHistoryOfVal",
      "ros_diagnosisOfCancerSubDescRuleOutVal",
    ],
  ],
  [
    "ros_diagnosisOfCancerSubSup",
    [
      "ros_diagnosisOfCancerSubSupPhyFinVal",
      "ros_diagnosisOfCancerSubSuphospVal",
      "ros_diagnosisOfCancerSubSuptreatVal",
      "ros_diagnosisOfCancerSubSuplabTestsVal",
      "ros_diagnosisOfCancerSubSupimagVal",

      "ros_diagnosisOfCancerSubSupSurVal",

      "ros_diagnosisOfCancerSubSupBioVal",

      "ros_diagnosisOfCancerSubSupOtherVal",
    ],
  ],
  [
    "ros_diagnosisOfCancerSubType",
    [
      "ros_diagnosisOfCancerSubTypeBrainVal",
      "ros_diagnosisOfCancerSubTypeHeadVal",
      "ros_diagnosisOfCancerSubTypeNeckVal",
      "ros_diagnosisOfCancerSubTypeBreastVal",
      "ros_diagnosisOfCancerSubTypeLungVal",
      "ros_diagnosisOfCancerSubTypeEsophagusVal",
      "ros_diagnosisOfCancerSubTypeStomachVal",
      "ros_diagnosisOfCancerSubTypeLiverVal",
      "ros_diagnosisOfCancerSubTypePancreasVal",
      "ros_diagnosisOfCancerSubTypeColonVal",
      "ros_diagnosisOfCancerSubTypeRectumVal",
      "ros_diagnosisOfCancerSubTypeKidyneyVal",
      "ros_diagnosisOfCancerSubTypeBladderVal",
      "ros_diagnosisOfCancerSubTypeOvariesVal",
      "ros_diagnosisOfCancerSubTypeUterusVal",
      "ros_diagnosisOfCancerSubTypeProstateVal",
      "ros_diagnosisOfCancerSubTypeBoneVal",
      "ros_diagnosisOfCancerSubTypeBloodVal",
      "ros_diagnosisOfCancerSubTypeLymphNodesVal",
      "ros_diagnosisOfCancerSubTypeSkinVal",
      "ros_diagnosisOfCancerSubTypeOtherVal",
    ],
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreat",
    [
      "ros_diagnosisOfCancerSubActiveTreatChemoVal",
      "ros_diagnosisOfCancerSubActiveTreatRadiaVal",
      "ros_diagnosisOfCancerSubActiveTreatStemVal",
      "ros_diagnosisOfCancerSubActiveTreatBoneVal",
      "ros_diagnosisOfCancerSubActiveTreatSurgVal",
      "ros_diagnosisOfCancerSubActiveTreatImmuVal",
      "ros_diagnosisOfCancerSubActiveTreatOtherVal",
    ],
  ],

  [
    "ros_diagnosisOfCancerSubSideEff",
    [
      "ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal",
    ],
  ],

  [
    "ros_cardioSubDiagnoses",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmVal",
      "ros_cardioSubDiagnosesaneurysmVal",
      "ros_cardioSubDiagnosesanginaVal",
      "ros_cardioSubDiagnosesatrialFibrillationVal",
      "ros_cardioSubDiagnosescardioRespiratoryShockVal",
      "ros_cardioSubDiagnosescardiomyopathyVal",
      "ros_cardioSubDiagnosescongestiveHeartFailureVal",
      "ros_cardioSubDiagnosesdeepVeinThrombosisVal",
      "ros_cardioSubDiagnoseshyperlipidemiaVal",
      "ros_cardioSubDiagnoseshypertensionVal",
      "ros_cardioSubDiagnosesischemicHeartDiseaseVal",
      "ros_cardioSubDiagnosesmyocardialInfarctionVal",
      "ros_cardioSubDiagnosesperipheralVascularDiseaseVal",
      "ros_cardioSubDiagnosespulmonaryHypertensionVal",
      "ros_cardioSubDiagnosesvalvularDiseaseVal",
      "ros_cardioSubDiagnosesotherVal",
    ],
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDes",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescriberuleOutVal",
    ],
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSup",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupECG",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSuprateControllingDrug",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupanticoagulation",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupelectrophysiology",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchestpain",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSuplightHeadedness",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedPacemaker",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedDefibrillator",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupshortnessOfBreath",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupwheezing",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchronicCough",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes",

    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregular",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesirregularlyIrregular",

      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesprematureContractures",
    ],
  ],

  [
    "ros_genitoSubDiagnoses",
    [
      "ros_genitoSubDiagnosesacuteRenalFailureVal",
      "ros_genitoSubDiagnosesbphVal",
      "ros_genitoSubDiagnoseschronicKidneyDiseaseVal",
      "ros_genitoSubDiagnosesesrdVal",
      "ros_genitoSubDiagnoseserectileDysfunctionVal",
      "ros_genitoSubDiagnosesfrequentUtiVal",
      "ros_genitoSubDiagnosesgynecologicalVal",
      "ros_genitoSubDiagnoseskidneyStonesVal",
      "ros_genitoSubDiagnosesnephritisOrNephrosisVal",
      "ros_genitoSubDiagnosesurinaryIncontinenceVal",
      "ros_genitoSubDiagnosesotherVal",
    ],
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredes",
    [
      "ros_genitoSubDiagnosesacuteRenalFailuredesActiveVal",
      "ros_genitoSubDiagnosesacuteRenalFailuredesHistoryOfVal",
      "ros_genitoSubDiagnosesacuteRenalFailuredesRuleOutVal",
    ],
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureSup",
    [
      "ros_genitoSubDiagnosesacuteRenalFailureSupLabtestVal",
      "ros_genitoSubDiagnosesacuteRenalFailureSupcalculatedGFRVal",
      "ros_genitoSubDiagnosesacuteRenalFailureSuphospitalizationVal",
      "ros_genitoSubDiagnosesacuteRenalFailureSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnoses",
    [
      "ros_gastroSubDiagnoses",
      "ros_gastroSubDiagnosesbowelObstructionVal",
      "ros_gastroSubDiagnosescachexiaVal",
      "ros_gastroSubDiagnosesceliacDiseaseVal",
      "ros_gastroSubDiagnosescirrhosisVal",
      "ros_gastroSubDiagnosescolonPolypsVal",
      "ros_gastroSubDiagnosesdiverticulitisVal",
      "ros_gastroSubDiagnosesgallBladderDiseaseVal",
      "ros_gastroSubDiagnosesgastroparesisVal",
      "ros_gastroSubDiagnosesgerdVal",
      "ros_gastroSubDiagnosesglBleedVal",
      "ros_gastroSubDiagnoseshepatitisVal",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal",
      "ros_gastroSubDiagnosespancreatitisVal",
      "ros_gastroSubDiagnosesulcerDiseaseVal",
      "ros_gastroSubDiagnosesotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesbowelObstruction",
    [
      "ros_gastroSubDiagnosesdesbowelObstructionActiveVal",
      "ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal",
      "ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionSup",
    [
      "ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal",
      "ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal",
      "ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal",
      "ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal",
      "ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesbowelObstructionSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescachexiaSup",
    [
      "ros_gastroSubDiagnosescachexiaSupalbuminVal",
      "ros_gastroSubDiagnoscachexiaSupmuscleWastingVal",
      "ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal",
      "ros_gastroSubDiagnosescachexiaSupabdominalPainVal",
      "ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosescachexiaSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescachexiades",
    [
      "ros_gastroSubDiagnosescachexiadesactive",
      "ros_gastroSubDiagnosescachexiadeshistoryOf",
      "ros_gastroSubDiagnosescachexiadesruleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesceliacDisease",
    [
      "ros_gastroSubDiagnosesdesceliacDiseaseActiveVal",
      "ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal",
      "ros_gastroSubDiagnosesdesceliacDiseaseRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSup",
    [
      "ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal",
      "ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupDietVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdescirrhosis",
    [
      "ros_gastroSubDiagnosesdescirrhosisActiveVal",
      "ros_gastroSubDiagnosesdescirrhosisHistoryOfVal",
      "ros_gastroSubDiagnosesdescirrhosisRuleOutVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSup",

    [
      "ros_gastroSubDiagnosescirrhosisSupsymptomsVal",
      "ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal",
      "ros_gastroSubDiagnosescirrhosisSupLabStudiesVal",
      "ros_gastroSubDiagnosescirrhosisSupMriVal",
      "ros_gastroSubDiagnosescirrhosisSupBoipsyVal",
      "ros_gastroSubDiagnosescirrhosisSupabdominalPainVal",
      "ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal",
      "ros_gastroSubDiagnosescirrhosisSupOtherVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDes",
    [
      "ros_gastroSubDiagnosescolonPolypsDesActiveVal",
      "ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal",
      "ros_gastroSubDiagnosescolonPolypsDesRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescolonSup",
    [
      "ros_gastroSubDiagnosescolonSuphistoryVal",
      "ros_gastroSubDiagnosescolonSupsymptomsVal",
      "ros_gastroSubDiagnosescolonSupPhysicalfindingsVal",
      "ros_gastroSubDiagnosescolonSupmedicationsVal",
      "ros_gastroSubDiagnosescolonSupTestResultsVal",
      "ros_gastroSubDiagnosescolonSupImageStudiesVal",
      "ros_gastroSubDiagnosescolonSupBiopsyVal",
      "ros_gastroSubDiagnosescolonDmeVal",
      "ros_gastroSubDiagnosescolonAbdominalPainVal",
      "ros_gastroSubDiagnosescolonNausaVomitingVal",
      "ros_gastroSubDiagnosescolonOtherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescolonCrDes",
    [
      "ros_gastroSubDiagnosescolonCrDesBengirVal",
      "ros_gastroSubDiagnosescolonCrDesCarcinomaVal",
      "ros_gastroSubDiagnosescolonCrDesPolyposisVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesdiverticulitis",
    [
      "ros_gastroSubDiagnosesdesdiverticulitisActiveVal",
      "ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal",
      "ros_gastroSubDiagnosesdesdiverticulitisRuleOutVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSup",
    [
      "ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal",
      "ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal",
      "ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal",
      "ros_gastroSubDiagnosesdiverticulitisSupDietVal",
      "ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal",
      "ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesdiverticulitisSupotherVal",
    ],
  ],

  [
    "ros_endoSubDiagnoses",
    [
      "ros_endoSubDiagnoseschronicKidneyVal",
      "ros_endoSubDiagnosescoronaryArteryVal",
      "ros_endoSubDiagnosescushingsDiseaseVal",
      "ros_endoSubDiagnosesdiabetesVal",
      "ros_endoSubDiagnosesdiabeticRetinopathyVal",
      "ros_endoSubDiagnosessecondaryHyperparathyroidismVal",
      "ros_endoSubDiagnoseshypertensionDiabetesVal",
      "ros_endoSubDiagnoseshyperthyroidismVal",
      "ros_endoSubDiagnoseshypothyroidismVal",
      "ros_endoSubDiagnosesperipheralNeuropathyVal",
      "ros_endoSubDiagnosesperipheralVascularVal",
      "ros_endoSubDiagnoseshyperparathyroidismVal",
      "ros_endoSubDiagnosesotherVal",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAneurysmDes",
    [
      "ros_cardioSubDiagnosesAneurysmDesActive",
      "ros_cardioSubDiagnosesAneurysmDesHistoryOf",
      "ros_cardioSubDiagnosesAneurysmDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSup",

    [
      "ros_cardioSubDiagnosesAneurysmSupPhyExam",
      "ros_cardioSubDiagnosesAneurysmSupultra",
      "ros_cardioSubDiagnosesAneurysmSupImage",
      "ros_cardioSubDiagnosesAneurysmSupchestpain",
      "ros_cardioSubDiagnosesAneurysmSuplightHeadedness",
      "ros_cardioSubDiagnosesAneurysmSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAneurysmSupwheezing",
      "ros_cardioSubDiagnosesAneurysmSupchronicCough",
      "ros_cardioSubDiagnosesAneurysmSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDes",
    [
      "ros_cardioSubDiagnosesAneurysmCrDesAbdominal",
      "ros_cardioSubDiagnosesAneurysmCrDesThoracic",
      "ros_cardioSubDiagnosesAneurysmCrDesPeripheral",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAnginaDes",
    [
      "ros_cardioSubDiagnosesAnginaDesActive",
      "ros_cardioSubDiagnosesAnginaDesHistoryOf",
      "ros_cardioSubDiagnosesAnginaDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAnginaSup",
    [
      "ros_cardioSubDiagnosesAnginaSupMedi",
      "ros_cardioSubDiagnosesAnginaSupHitory",
      "ros_cardioSubDiagnosesAnginaSupstressTest",
      "ros_cardioSubDiagnosesAnginaSupchestpain",
      "ros_cardioSubDiagnosesAnginaSuplightHeadedness",
      "ros_cardioSubDiagnosesAnginaSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAnginaSupwheezing",
      "ros_cardioSubDiagnosesAnginaSupchronicCough",
      "ros_cardioSubDiagnosesAnginaSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAnginaCrDes",
    [
      "ros_cardioSubDiagnosesAnginaCrDesStable",
      "ros_cardioSubDiagnosesAnginaCrDesUnstable",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationDes",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationDesActive",
      "ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf",
      "ros_cardioSubDiagnosesAtrialFibrillationDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationType",

    [
      "ros_cardioSubDiagnosesAtrialFibrillationTypeParoxysmal",
      "ros_cardioSubDiagnosesAtrialFibrillationTypeChronic",
      "ros_cardioSubDiagnosesAtrialFibrillationTypeUnknown",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSup",

    [
      "ros_cardioSubDiagnosesAtrialFibrillationSupMedi",
      "ros_cardioSubDiagnosesAtrialFibrillationSupECG",
      "ros_cardioSubDiagnosesAtrialFibrillationSupSymptoms",
      "ros_cardioSubDiagnosesAtrialFibrillationSupHistory",
      "ros_cardioSubDiagnosesAtrialFibrillationSupelectricCardio",
      "ros_cardioSubDiagnosesAtrialFibrillationSupchestpain",
      "ros_cardioSubDiagnosesAtrialFibrillationSuplightHeadedness",
      "ros_cardioSubDiagnosesAtrialFibrillationSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAtrialFibrillationSupwheezing",
      "ros_cardioSubDiagnosesAtrialFibrillationSupchronicCough",
      "ros_cardioSubDiagnosesAtrialFibrillationSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingAnti",
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingRate",
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOther",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDes",
    [
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesActive",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSup",
    [
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupHistory",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupImplant",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupChestPain",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSuplightHeadedness",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupshortnessOfBreath",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupwheezing",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupchronicCough",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyDes",
    [
      "ros_cardioSubDiagnosesCardiomyopathyDesActive",
      "ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf",
      "ros_cardioSubDiagnosesCardiomyopathyDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyType",
    [
      "ros_cardioSubDiagnosesCardiomyopathyTypeDilated",
      "ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic",
      "ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive",
      "ros_cardioSubDiagnosesCardiomyopathyTypeUnknown",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySup",
    [
      "ros_cardioSubDiagnosesCardiomyopathySupEcho",
      "ros_cardioSubDiagnosesCardiomyopathySupCardiacCath",
      "ros_cardioSubDiagnosesCardiomyopathySupChestPain",
      "ros_cardioSubDiagnosesCardiomyopathySupLightHeadedness",
      "ros_cardioSubDiagnosesCardiomyopathySupshortnessOfBreath",
      "ros_cardioSubDiagnosesCardiomyopathySupwheezing",
      "ros_cardioSubDiagnosesCardiomyopathySupchronicCough",
      "ros_cardioSubDiagnosesCardiomyopathySupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDes",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActive",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSup",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupEjection",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupCardio",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupOrtho",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupDOE",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupPnd",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupS3",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupMedications",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupPeripheralEdema",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupChestPain",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupLightHeadedness",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupshortnessOfBreath",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupwheezing",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupchronicCough",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecify",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDes",

    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesDias",
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesSys",
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesUnknown",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDes",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesActive",
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf",
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDes",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesAcute",
      "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesChronic",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSup",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupPhysicalFindings",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulation",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupVascularStudies",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupVenaFilter",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupEdema",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupchestpain",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSuplightHeadedness",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupshortnessOfBreath",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupwheezing",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupchronicCough",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaDes",

    [
      "ros_cardioSubDiagnosesHyperlipidemiaDesActive",
      "ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf",
      "ros_cardioSubDiagnosesHyperlipidemiaDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSup",
    [
      "ros_cardioSubDiagnosesHyperlipidemiaSupLabResults",
      "ros_cardioSubDiagnosesHyperlipidemiaSupMedications",
      "ros_cardioSubDiagnosesHyperlipidemiaSupchestpain",
      "ros_cardioSubDiagnosesHyperlipidemiaSuplightHeadedness",
      "ros_cardioSubDiagnosesHyperlipidemiaSupshortnessOfBreath",
      "ros_cardioSubDiagnosesHyperlipidemiaSupwheezing",
      "ros_cardioSubDiagnosesHyperlipidemiaSupchronicCough",
      "ros_cardioSubDiagnosesHyperlipidemiaSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDes",
    [
      "ros_cardioSubDiagnosesHypertensionDesActive",
      "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
      "ros_cardioSubDiagnosesHypertensionDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHypertensionDes",
    [
      "ros_cardioSubDiagnosesHypertensionDesActive",
      "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
      "ros_cardioSubDiagnosesHypertensionDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSup",
    [
      "ros_cardioSubDiagnosesHypertensionSupPhysicalExam",
      "ros_cardioSubDiagnosesHypertensionSupMedications",
      "ros_cardioSubDiagnosesHypertensionSupSymptoms",
      "ros_cardioSubDiagnosesHypertensionSupchestpain",
      "ros_cardioSubDiagnosesHypertensionSuplightHeadedness",
      "ros_cardioSubDiagnosesHypertensionSupshortnessOfBreath",
      "ros_cardioSubDiagnosesHypertensionSupwheezing",
      "ros_cardioSubDiagnosesHypertensionSupchronicCough",
      "ros_cardioSubDiagnosesHypertensionSupother",
    ],
  ],
];

export const ros_hashmap_Eyes = [
  ["ros_eyesVal", "eyes.isEyes"],

  ["ros_eyesDiagCataractVal", "eyes.isEyesSubAnswer.diagnoses.cataracts"],

  [
    "ros_eyesDiagCataractSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagCataractSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagCataractSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagCataractSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagCataractSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagCataractSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagCataractSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagCataractSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagCataractSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagCataractSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagCataractSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagCataractSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagCataractSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagCataractSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagCataractSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagCataractSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagCataractSubDiabetesVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.secondaryDiabetes.isSeondaryDiabetes",
  ],
  [
    "ros_eyesDiagCataractSubDiabetesCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.secondaryDiabetes.comment",
  ],

  ["ros_eyesDiagGlaucomaVal", "eyes.isEyesSubAnswer.diagnoses.glaucoma"],

  [
    "ros_eyesDiagGlaucomaSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagGlaucomaSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagGlaucomaSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagGlaucomaSubDiabetesVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetes",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSubOpenVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetes.isSecondaryDiabetesSubAnswer.openWideAngle",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSubNarrowVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetes.isSecondaryDiabetesSubAnswer.closedNarrowAngle",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetes.isSecondaryDiabetesSubAnswer.comment",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.comment",
  ],

  ["ros_eyesDiagHyperopiaVal", "eyes.isEyesSubAnswer.diagnoses.hyperopia"],

  [
    "ros_eyesDiagHyperopiaSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagHyperopiaSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagHyperopiaSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.comment",
  ],

  ["ros_eyesDiagLegalBlindVal", "eyes.isEyesSubAnswer.diagnoses.legallyBlind"],

  [
    "ros_eyesDiagLegalBlindSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagLegalBlindSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagLegalBlindSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagLegalBlindDescVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagLegalBlindSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.lbDescribe",
  ],

  [
    "ros_eyesDiagMascDegenVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegeneration",
  ],

  [
    "ros_eyesDiagMascDegenSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagMascDegenSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagMascDegenSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagMascDegenSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagMascDegenSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagMascDegenSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagMascDegenDescVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagMascDegenSubDescWetVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.wet",
  ],
  [
    "ros_eyesDiagMascDegenSubDescDryVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.dry",
  ],
  [
    "ros_eyesDiagMascDegenSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.comment",
  ],

  ["ros_eyesDiagMyopiaVal", "eyes.isEyesSubAnswer.diagnoses.myopia"],
  [
    "ros_eyesDiagMyopiaSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagMyopiaSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagMyopiaSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagMyopiaSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagMyopiaSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagMyopiaSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.comment",
  ],
  ["ros_eyesDiagRetinalVal", "eyes.isEyesSubAnswer.diagnoses.retinalDisease"],
  [
    "ros_eyesDiagRetinalSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagRetinalSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagRetinalSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagRetinalSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagRetinalSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagRetinalSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagRetinalSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagRetinalSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagRetinalSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagRetinalSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagRetinalSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagRetinalSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagRetinalSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagRetinalSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagRetinalSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagRetinalSubDiabetesVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryDiabetes.isSeondaryDiabetes",
  ],
  [
    "ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryDiabetes.isSeondaryDiabetes.isSecondaryToDiabetesSubAnswer.isVitreousHemorrhage",
  ],
  [
    "ros_eyesDiagRetinalSubDiabetesSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryDiabetes.isSeondaryDiabetes.isSecondaryToDiabetesSubAnswer.comment",
  ],
  [
    "ros_eyesDiagRetinalSubDiabetesCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryDiabetes.comment",
  ],
  ["ros_eyesDiagOtherVal", "eyes.isEyesSubAnswer.diagnoses.others"],

  [
    "ros_eyesDiagOtherSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagOtherSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagOtherSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagOtherSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagOtherSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagOtherSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagOtherSubSuppSymptomsVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagOtherSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagOtherSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagOtherSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagOtherSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagOtherSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagOtherSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagOtherSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagOtherSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagOtherSubSuppDescVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagOtherSubOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.other",
  ],

  ["ros_eyesDiagnosesCommentVal", "eyes.isEyesSubAnswer.diagnoses.comment"],

  ["ros_eyesCommentVal", "eyes.comment"],
];

export const ros_optionsTreeMapObject_Eyes = {
  ros_eyesDiagCataractVal: "cataracts",

  ros_eyesDiagCataractSubWhEyeRightVal: "rightEye",
  ros_eyesDiagCataractSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagCataractSubWhEyeBothVal: "both",

  ros_eyesDiagCataractSubDescActiveVal: "active",
  ros_eyesDiagCataractSubDescHistoryVal: "historyOf",
  ros_eyesDiagCataractSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagCataractSubSuppHistoryVal: "history",
  ros_eyesDiagCataractSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagCataractSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagCataractSubSuppMedicationsVal: "medications",
  ros_eyesDiagCataractSubSuppTestResultVal: "testResults",
  ros_eyesDiagCataractSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagCataractSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagCataractSubSuppDmeVal: "dme",
  ros_eyesDiagCataractSubSuppOtherVal: "other",

  ros_eyesDiagCataractSubSuppSympSubPainVal: "pain",
  ros_eyesDiagCataractSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagCataractSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagCataractSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagCataractSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagGlaucomaVal: "glaucoma",

  ros_eyesDiagGlaucomaSubWhEyeRightVal: "rightEye",
  ros_eyesDiagGlaucomaSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagGlaucomaSubWhEyeBothVal: "both",

  ros_eyesDiagGlaucomaSubDescActiveVal: "active",
  ros_eyesDiagGlaucomaSubDescHistoryVal: "historyOf",
  ros_eyesDiagGlaucomaSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagGlaucomaSubSuppHistoryVal: "history",
  ros_eyesDiagGlaucomaSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagGlaucomaSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagGlaucomaSubSuppMedicationsVal: "medications",
  ros_eyesDiagGlaucomaSubSuppTestResultVal: "testResults",
  ros_eyesDiagGlaucomaSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagGlaucomaSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagGlaucomaSubSuppDmeVal: "dme",
  ros_eyesDiagGlaucomaSubSuppOtherVal: "other",

  ros_eyesDiagGlaucomaSubSuppSympSubPainVal: "pain",
  ros_eyesDiagGlaucomaSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagGlaucomaSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagGlaucomaSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagGlaucomaSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagGlaucomaSubDiabetesSubOpenVal: "openWideAngle",
  ros_eyesDiagGlaucomaSubDiabetesSubNarrowVal: "closedNarrowAngle",

  ros_eyesDiagHyperopiaVal: "hyperopia",

  ros_eyesDiagHyperopiaSubWhEyeRightVal: "rightEye",
  ros_eyesDiagHyperopiaSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagHyperopiaSubWhEyeBothVal: "both",

  ros_eyesDiagHyperopiaSubDescActiveVal: "active",
  ros_eyesDiagHyperopiaSubDescHistoryVal: "historyOf",
  ros_eyesDiagHyperopiaSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagHyperopiaSubSuppHistoryVal: "history",
  ros_eyesDiagHyperopiaSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagHyperopiaSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagHyperopiaSubSuppMedicationsVal: "medications",
  ros_eyesDiagHyperopiaSubSuppTestResultVal: "testResults",
  ros_eyesDiagHyperopiaSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagHyperopiaSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagHyperopiaSubSuppDmeVal: "dme",
  ros_eyesDiagHyperopiaSubSuppOtherVal: "other",

  ros_eyesDiagHyperopiaSubSuppSympSubPainVal: "pain",
  ros_eyesDiagHyperopiaSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagHyperopiaSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagHyperopiaSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagHyperopiaSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagLegalBlindVal: "legallyBlind",
  ros_eyesDiagLegalBlindSubWhEyeRightVal: "rightEye",
  ros_eyesDiagLegalBlindSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagLegalBlindSubWhEyeBothVal: "both",

  ros_eyesDiagLegalBlindSubDescActiveVal: "active",
  ros_eyesDiagLegalBlindSubDescHistoryVal: "historyOf",
  ros_eyesDiagLegalBlindSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagLegalBlindSubSuppHistoryVal: "history",
  ros_eyesDiagLegalBlindSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagLegalBlindSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagLegalBlindSubSuppMedicationsVal: "medications",
  ros_eyesDiagLegalBlindSubSuppTestResultVal: "testResults",
  ros_eyesDiagLegalBlindSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagLegalBlindSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagLegalBlindSubSuppDmeVal: "dme",
  ros_eyesDiagLegalBlindSubSuppOtherVal: "other",

  ros_eyesDiagLegalBlindSubSuppSympSubPainVal: "pain",
  ros_eyesDiagLegalBlindSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagLegalBlindSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagLegalBlindSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagLegalBlindSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagMascDegenVal: "mascularDegeneration",
  ros_eyesDiagMascDegenSubWhEyeRightVal: "rightEye",
  ros_eyesDiagMascDegenSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagMascDegenSubWhEyeBothVal: "both",

  ros_eyesDiagMascDegenSubDescActiveVal: "active",
  ros_eyesDiagMascDegenSubDescHistoryVal: "historyOf",
  ros_eyesDiagMascDegenSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagMascDegenSubSuppHistoryVal: "history",
  ros_eyesDiagMascDegenSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagMascDegenSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagMascDegenSubSuppMedicationsVal: "medications",
  ros_eyesDiagMascDegenSubSuppTestResultVal: "testResults",
  ros_eyesDiagMascDegenSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagMascDegenSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagMascDegenSubSuppDmeVal: "dme",
  ros_eyesDiagMascDegenSubSuppOtherVal: "other",

  ros_eyesDiagMascDegenSubSuppSympSubPainVal: "pain",
  ros_eyesDiagMascDegenSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagMascDegenSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagMascDegenSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagMascDegenSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagMascDegenSubDescWetVal: "wet",
  ros_eyesDiagMascDegenSubDescDryVal: "dry",
  ros_eyesDiagMascDegenSubDescCommentVal: "comment",

  ros_eyesDiagMyopiaVal: "myopia",

  ros_eyesDiagMyopiaSubWhEyeRightVal: "rightEye",
  ros_eyesDiagMyopiaSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagMyopiaSubWhEyeBothVal: "both",

  ros_eyesDiagMyopiaSubDescActiveVal: "active",
  ros_eyesDiagMyopiaSubDescHistoryVal: "historyOf",
  ros_eyesDiagMyopiaSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagMyopiaSubSuppHistoryVal: "history",
  ros_eyesDiagMyopiaSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagMyopiaSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagMyopiaSubSuppMedicationsVal: "medications",
  ros_eyesDiagMyopiaSubSuppTestResultVal: "testResults",
  ros_eyesDiagMyopiaSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagMyopiaSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagMyopiaSubSuppDmeVal: "dme",
  ros_eyesDiagMyopiaSubSuppOtherVal: "other",

  ros_eyesDiagMyopiaSubSuppSympSubPainVal: "pain",
  ros_eyesDiagMyopiaSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagMyopiaSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagMyopiaSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagMyopiaSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagRetinalVal: "retinalDisease",

  ros_eyesDiagRetinalSubWhEyeRightVal: "rightEye",
  ros_eyesDiagRetinalSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagRetinalSubWhEyeBothVal: "both",

  ros_eyesDiagRetinalSubDescActiveVal: "active",
  ros_eyesDiagRetinalSubDescHistoryVal: "historyOf",
  ros_eyesDiagRetinalSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagRetinalSubSuppHistoryVal: "history",
  ros_eyesDiagRetinalSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagRetinalSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagRetinalSubSuppMedicationsVal: "medications",
  ros_eyesDiagRetinalSubSuppTestResultVal: "testResults",
  ros_eyesDiagRetinalSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagRetinalSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagRetinalSubSuppDmeVal: "dme",
  ros_eyesDiagRetinalSubSuppOtherVal: "other",

  ros_eyesDiagRetinalSubSuppSympSubPainVal: "pain",
  ros_eyesDiagRetinalSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagRetinalSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagRetinalSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagRetinalSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagOtherVal: "others",
  ros_eyesDiagOtherSubWhEyeRightVal: "rightEye",
  ros_eyesDiagOtherSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagOtherSubWhEyeBothVal: "both",

  ros_eyesDiagOtherSubDescActiveVal: "active",
  ros_eyesDiagOtherSubDescHistoryVal: "historyOf",
  ros_eyesDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagOtherSubSuppHistoryVal: "history",
  ros_eyesDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_eyesDiagOtherSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagOtherSubSuppMedicationsVal: "medications",
  ros_eyesDiagOtherSubSuppTestResultVal: "testResults",
  ros_eyesDiagOtherSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagOtherSubSuppDmeVal: "dme",
  ros_eyesDiagOtherSubSuppOtherVal: "other",

  ros_eyesDiagOtherSubSuppSympSubPainVal: "pain",
  ros_eyesDiagOtherSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagOtherSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagOtherSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagOtherSubSuppSympSubFloatVal: "floaters",
};

export const ros_OptionsTreeWrappers_Eyes = [
  [
    "ros_eyesDiagnoses",
    [
      "ros_eyesDiagCataractVal",
      "ros_eyesDiagGlaucomaVal",
      "ros_eyesDiagHyperopiaVal",
      "ros_eyesDiagLegalBlindVal",
      "ros_eyesDiagMascDegenVal",
      "ros_eyesDiagMyopiaVal",
      "ros_eyesDiagRetinalVal",
      "ros_eyesDiagOtherVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubWhEye",
    [
      "ros_eyesDiagCataractSubWhEyeRightVal",
      "ros_eyesDiagCataractSubWhEyeLeftVal",
      "ros_eyesDiagCataractSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubDesc",
    [
      "ros_eyesDiagCataractSubDescActiveVal",
      "ros_eyesDiagCataractSubDescHistoryVal",
      "ros_eyesDiagCataractSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubSupp",
    [
      "ros_eyesDiagCataractSubSuppHistoryVal",
      "ros_eyesDiagCataractSubSuppSymptomsVal",
      "ros_eyesDiagCataractSubSuppPhysicalFindingsVal",
      "ros_eyesDiagCataractSubSuppMedicationsVal",
      "ros_eyesDiagCataractSubSuppTestResultVal",
      "ros_eyesDiagCataractSubSuppImageStudiesVal",
      "ros_eyesDiagCataractSubSuppBiopsyVal",
      "ros_eyesDiagCataractSubSuppDmeVal",
      "ros_eyesDiagCataractSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSub",
    [
      "ros_eyesDiagCataractSubSuppSympSubPainVal",
      "ros_eyesDiagCataractSubSuppSympSubDryEyeVal",
      "ros_eyesDiagCataractSubSuppSympSubTearVal",
      "ros_eyesDiagCataractSubSuppSympSubProbSeeVal",
      "ros_eyesDiagCataractSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEye",
    [
      "ros_eyesDiagGlaucomaSubWhEyeRightVal",
      "ros_eyesDiagGlaucomaSubWhEyeLeftVal",
      "ros_eyesDiagGlaucomaSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubDesc",
    [
      "ros_eyesDiagGlaucomaSubDescActiveVal",
      "ros_eyesDiagGlaucomaSubDescHistoryVal",
      "ros_eyesDiagGlaucomaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubSupp",
    [
      "ros_eyesDiagGlaucomaSubSuppHistoryVal",
      "ros_eyesDiagGlaucomaSubSuppSymptomsVal",
      "ros_eyesDiagGlaucomaSubSuppPhysicalFindingsVal",
      "ros_eyesDiagGlaucomaSubSuppMedicationsVal",
      "ros_eyesDiagGlaucomaSubSuppTestResultVal",
      "ros_eyesDiagGlaucomaSubSuppImageStudiesVal",
      "ros_eyesDiagGlaucomaSubSuppBiopsyVal",
      "ros_eyesDiagGlaucomaSubSuppDmeVal",
      "ros_eyesDiagGlaucomaSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSub",
    [
      "ros_eyesDiagGlaucomaSubSuppSympSubPainVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubDryEyeVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubTearVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubProbSeeVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSub",
    [
      "ros_eyesDiagGlaucomaSubDiabetesSubOpenVal",
      "ros_eyesDiagGlaucomaSubDiabetesSubNarrowVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEye",
    [
      "ros_eyesDiagHyperopiaSubWhEyeRightVal",
      "ros_eyesDiagHyperopiaSubWhEyeLeftVal",
      "ros_eyesDiagHyperopiaSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubDesc",
    [
      "ros_eyesDiagHyperopiaSubDescActiveVal",
      "ros_eyesDiagHyperopiaSubDescHistoryVal",
      "ros_eyesDiagHyperopiaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubSupp",
    [
      "ros_eyesDiagHyperopiaSubSuppHistoryVal",
      "ros_eyesDiagHyperopiaSubSuppSymptomsVal",
      "ros_eyesDiagHyperopiaSubSuppPhysicalFindingsVal",
      "ros_eyesDiagHyperopiaSubSuppMedicationsVal",
      "ros_eyesDiagHyperopiaSubSuppTestResultVal",
      "ros_eyesDiagHyperopiaSubSuppImageStudiesVal",
      "ros_eyesDiagHyperopiaSubSuppBiopsyVal",
      "ros_eyesDiagHyperopiaSubSuppDmeVal",
      "ros_eyesDiagHyperopiaSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSub",
    [
      "ros_eyesDiagHyperopiaSubSuppSympSubPainVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubDryEyeVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubTearVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubProbSeeVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubFloatVal",
    ],
  ],

  [
    "ros_eyesDiagLegalBlindSubWhEye",
    [
      "ros_eyesDiagLegalBlindSubWhEyeRightVal",
      "ros_eyesDiagLegalBlindSubWhEyeLeftVal",
      "ros_eyesDiagLegalBlindSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubDesc",
    [
      "ros_eyesDiagLegalBlindSubDescActiveVal",
      "ros_eyesDiagLegalBlindSubDescHistoryVal",
      "ros_eyesDiagLegalBlindSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubSupp",
    [
      "ros_eyesDiagLegalBlindSubSuppHistoryVal",
      "ros_eyesDiagLegalBlindSubSuppSymptomsVal",
      "ros_eyesDiagLegalBlindSubSuppPhysicalFindingsVal",
      "ros_eyesDiagLegalBlindSubSuppMedicationsVal",
      "ros_eyesDiagLegalBlindSubSuppTestResultVal",
      "ros_eyesDiagLegalBlindSubSuppImageStudiesVal",
      "ros_eyesDiagLegalBlindSubSuppBiopsyVal",
      "ros_eyesDiagLegalBlindSubSuppDmeVal",
      "ros_eyesDiagLegalBlindSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSub",
    [
      "ros_eyesDiagLegalBlindSubSuppSympSubPainVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubDryEyeVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubTearVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubProbSeeVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubWhEye",
    [
      "ros_eyesDiagMascDegenSubWhEyeRightVal",
      "ros_eyesDiagMascDegenSubWhEyeLeftVal",
      "ros_eyesDiagMascDegenSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubDesc",
    [
      "ros_eyesDiagMascDegenSubDescActiveVal",
      "ros_eyesDiagMascDegenSubDescHistoryVal",
      "ros_eyesDiagMascDegenSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubSupp",
    [
      "ros_eyesDiagMascDegenSubSuppHistoryVal",
      "ros_eyesDiagMascDegenSubSuppSymptomsVal",
      "ros_eyesDiagMascDegenSubSuppPhysicalFindingsVal",
      "ros_eyesDiagMascDegenSubSuppMedicationsVal",
      "ros_eyesDiagMascDegenSubSuppTestResultVal",
      "ros_eyesDiagMascDegenSubSuppImageStudiesVal",
      "ros_eyesDiagMascDegenSubSuppBiopsyVal",
      "ros_eyesDiagMascDegenSubSuppDmeVal",
      "ros_eyesDiagMascDegenSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSub",
    [
      "ros_eyesDiagMascDegenSubSuppSympSubPainVal",
      "ros_eyesDiagMascDegenSubSuppSympSubDryEyeVal",
      "ros_eyesDiagMascDegenSubSuppSympSubTearVal",
      "ros_eyesDiagMascDegenSubSuppSympSubProbSeeVal",
      "ros_eyesDiagMascDegenSubSuppSympSubFloatVal",
    ],
  ],

  [
    "ros_eyesDiagMascDegenSubDesc",
    [
      "ros_eyesDiagMascDegenSubDescWetVal",
      "ros_eyesDiagMascDegenSubDescDryVal",
    ],
  ],

  [
    "ros_eyesDiagMyopiaSubWhEye",
    [
      "ros_eyesDiagMyopiaSubWhEyeRightVal",
      "ros_eyesDiagMyopiaSubWhEyeLeftVal",
      "ros_eyesDiagMyopiaSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagMyopiaSubDesc",
    [
      "ros_eyesDiagMyopiaSubDescActiveVal",
      "ros_eyesDiagMyopiaSubDescHistoryVal",
      "ros_eyesDiagMyopiaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagMyopiaSubSupp",
    [
      "ros_eyesDiagMyopiaSubSuppHistoryVal",
      "ros_eyesDiagMyopiaSubSuppSymptomsVal",
      "ros_eyesDiagMyopiaSubSuppPhysicalFindingsVal",
      "ros_eyesDiagMyopiaSubSuppMedicationsVal",
      "ros_eyesDiagMyopiaSubSuppTestResultVal",
      "ros_eyesDiagMyopiaSubSuppImageStudiesVal",
      "ros_eyesDiagMyopiaSubSuppBiopsyVal",
      "ros_eyesDiagMyopiaSubSuppDmeVal",
      "ros_eyesDiagMyopiaSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSub",
    [
      "ros_eyesDiagMyopiaSubSuppSympSubPainVal",
      "ros_eyesDiagMyopiaSubSuppSympSubDryEyeVal",
      "ros_eyesDiagMyopiaSubSuppSympSubTearVal",
      "ros_eyesDiagMyopiaSubSuppSympSubProbSeeVal",
      "ros_eyesDiagMyopiaSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubWhEye",
    [
      "ros_eyesDiagRetinalSubWhEyeRightVal",
      "ros_eyesDiagRetinalSubWhEyeLeftVal",
      "ros_eyesDiagRetinalSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubDesc",
    [
      "ros_eyesDiagRetinalSubDescActiveVal",
      "ros_eyesDiagRetinalSubDescHistoryVal",
      "ros_eyesDiagRetinalSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubSupp",
    [
      "ros_eyesDiagRetinalSubSuppHistoryVal",
      "ros_eyesDiagRetinalSubSuppSymptomsVal",
      "ros_eyesDiagRetinalSubSuppPhysicalFindingsVal",
      "ros_eyesDiagRetinalSubSuppMedicationsVal",
      "ros_eyesDiagRetinalSubSuppTestResultVal",
      "ros_eyesDiagRetinalSubSuppImageStudiesVal",
      "ros_eyesDiagRetinalSubSuppBiopsyVal",
      "ros_eyesDiagRetinalSubSuppDmeVal",
      "ros_eyesDiagRetinalSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSub",
    [
      "ros_eyesDiagRetinalSubSuppSympSubPainVal",
      "ros_eyesDiagRetinalSubSuppSympSubDryEyeVal",
      "ros_eyesDiagRetinalSubSuppSympSubTearVal",
      "ros_eyesDiagRetinalSubSuppSympSubProbSeeVal",
      "ros_eyesDiagRetinalSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubWhEye",
    [
      "ros_eyesDiagOtherSubWhEyeRightVal",
      "ros_eyesDiagOtherSubWhEyeLeftVal",
      "ros_eyesDiagOtherSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubDesc",
    [
      "ros_eyesDiagOtherSubDescActiveVal",
      "ros_eyesDiagOtherSubDescHistoryVal",
      "ros_eyesDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubSupp",
    [
      "ros_eyesDiagOtherSubSuppHistoryVal",
      "ros_eyesDiagOtherSubSuppSymptomsVal",
      "ros_eyesDiagOtherSubSuppPhysicalFindingsVal",
      "ros_eyesDiagOtherSubSuppMedicationsVal",
      "ros_eyesDiagOtherSubSuppTestResultVal",
      "ros_eyesDiagOtherSubSuppImageStudiesVal",
      "ros_eyesDiagOtherSubSuppBiopsyVal",
      "ros_eyesDiagOtherSubSuppDmeVal",
      "ros_eyesDiagOtherSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSub",
    [
      "ros_eyesDiagOtherSubSuppSympSubPainVal",
      "ros_eyesDiagOtherSubSuppSympSubDryEyeVal",
      "ros_eyesDiagOtherSubSuppSympSubTearVal",
      "ros_eyesDiagOtherSubSuppSympSubProbSeeVal",
      "ros_eyesDiagOtherSubSuppSympSubFloatVal",
    ],
  ],
];

export const ros_hashmap_Ears = [
  ["ros_earsVal", "ears.isEars"],

  [
    "ros_earsDiagDiffHearVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearing",
  ],

  [
    "ros_earsDiagDiffHearSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagDiffHearSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagDiffHearSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagDiffHearSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagDiffHearSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagDiffHearSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagDiffHearSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagDiffHearSubSuppHearAidsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.hearingAids",
  ],
  [
    "ros_earsDiagDiffHearSubSuppMedicVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.medication",
  ],
  [
    "ros_earsDiagDiffHearSubSuppReadLipsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.readingLips",
  ],
  [
    "ros_earsDiagDiffHearSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagDiffHearSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagDiffHearSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.comment",
  ],

  ["ros_earsDiagLegalDeafVal", "ears.isEarsSubAnswer.diagnoses.legallyDeaf"],

  [
    "ros_earsDiagLegalDeafSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagLegalDeafSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagLegalDeafSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagLegalDeafSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagLegalDeafSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagLegalDeafSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.comment",
  ],

  ["ros_earsDiagTinnitusVal", "ears.isEarsSubAnswer.diagnoses.tinnitus"],

  [
    "ros_earsDiagTinnitusSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagTinnitusSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagTinnitusSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagTinnitusSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagTinnitusSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagTinnitusSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagTinnitusSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagTinnitusSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagTinnitusSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagTinnitusSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagTinnitusSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagTinnitusSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagTinnitusSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagTinnitusSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagTinnitusSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.comment",
  ],

  ["ros_earsDiagVertigoVal", "ears.isEarsSubAnswer.diagnoses.vertigo"],

  [
    "ros_earsDiagVertigoSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagVertigoSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagVertigoSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagVertigoSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagVertigoSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagVertigoSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagVertigoSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagVertigoSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagVertigoSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagVertigoSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagVertigoSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagVertigoSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagVertigoSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagVertigoSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagVertigoSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.comment",
  ],

  [
    "ros_earsDiagVertigoSubLoseBalVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.loseYourBalance.isLoseYourBalance",
  ],
  [
    "ros_earsDiagVertigoSubLoseBalCommentVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.loseYourBalance.comment",
  ],

  ["ros_earsDiagOtherVal", "ears.isEarsSubAnswer.diagnoses.other"],
  [
    "ros_earsDiagOtherSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagOtherSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagOtherSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagOtherSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagOtherSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagOtherSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagOtherSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagOtherSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagOtherSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagOtherSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagOtherSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagOtherSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagOtherSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagOtherSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagOtherSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_earsDiagOtherSubOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  ["ros_earsDiagCommentVal", "ears.isEarsSubAnswer.diagnoses.comment"],
  ["ros_earsCommentVal", "ears.comment"],
];

export const ros_optionsTreeMapObject_Ears = {
  ros_earsDiagDiffHearVal: "difficultyHearing",

  ros_earsDiagDiffHearSubDescActiveVal: "active",
  ros_earsDiagDiffHearSubDescHistoryVal: "historyOf",
  ros_earsDiagDiffHearSubDescRuleOutVal: "ruleOut",

  ros_earsDiagDiffHearSubSuppHistoryVal: "history",
  ros_earsDiagDiffHearSubSuppSymptomsVal: "symptoms",
  ros_earsDiagDiffHearSubSuppTestResultsVal: "testResults",
  ros_earsDiagDiffHearSubSuppHearAidsVal: "hearingAids",
  ros_earsDiagDiffHearSubSuppMedicVal: "medication",
  ros_earsDiagDiffHearSubSuppReadLipsVal: "readingLips",
  ros_earsDiagDiffHearSubSuppOtherVal: "other",

  ros_earsDiagLegalDeafVal: "legallyDeaf",

  ros_earsDiagLegalDeafSubDescActiveVal: "active",
  ros_earsDiagLegalDeafSubDescHistoryVal: "historyOf",
  ros_earsDiagLegalDeafSubDescRuleOutVal: "ruleOut",

  ros_earsDiagLegalDeafSubSuppHistoryVal: "history",
  ros_earsDiagLegalDeafSubSuppSymptomsVal: "symptoms",
  ros_earsDiagLegalDeafSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagLegalDeafSubSuppMedicsVal: "medications",
  ros_earsDiagLegalDeafSubSuppTestResultsVal: "testResults",
  ros_earsDiagLegalDeafSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagLegalDeafSubSuppBiopsyVal: "biopsy",
  ros_earsDiagLegalDeafSubSuppDmeVal: "dme",
  ros_earsDiagLegalDeafSubSuppOtherVal: "other",

  ros_earsDiagTinnitusVal: "tinnitus",

  ros_earsDiagTinnitusSubDescActiveVal: "active",
  ros_earsDiagTinnitusSubDescHistoryVal: "historyOf",
  ros_earsDiagTinnitusSubDescRuleOutVal: "ruleOut",

  ros_earsDiagTinnitusSubSuppHistoryVal: "history",
  ros_earsDiagTinnitusSubSuppSymptomsVal: "symptoms",
  ros_earsDiagTinnitusSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagTinnitusSubSuppMedicsVal: "medications",
  ros_earsDiagTinnitusSubSuppTestResultsVal: "testResults",
  ros_earsDiagTinnitusSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagTinnitusSubSuppBiopsyVal: "biopsy",
  ros_earsDiagTinnitusSubSuppDmeVal: "dme",
  ros_earsDiagTinnitusSubSuppOtherVal: "other",

  ros_earsDiagVertigoVal: "vertigo",

  ros_earsDiagVertigoSubDescActiveVal: "active",
  ros_earsDiagVertigoSubDescHistoryVal: "historyOf",
  ros_earsDiagVertigoSubDescRuleOutVal: "ruleOut",

  ros_earsDiagVertigoSubSuppHistoryVal: "history",
  ros_earsDiagVertigoSubSuppSymptomsVal: "symptoms",
  ros_earsDiagVertigoSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagVertigoSubSuppMedicsVal: "medications",
  ros_earsDiagVertigoSubSuppTestResultsVal: "testResults",
  ros_earsDiagVertigoSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagVertigoSubSuppBiopsyVal: "biopsy",
  ros_earsDiagVertigoSubSuppDmeVal: "dme",
  ros_earsDiagVertigoSubSuppOtherVal: "other",

  ros_earsDiagOtherVal: "other",

  ros_earsDiagOtherSubDescActiveVal: "active",
  ros_earsDiagOtherSubDescHistoryVal: "historyOf",
  ros_earsDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_earsDiagOtherSubSuppHistoryVal: "history",
  ros_earsDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_earsDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagOtherSubSuppMedicsVal: "medications",
  ros_earsDiagOtherSubSuppTestResultsVal: "testResults",
  ros_earsDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_earsDiagOtherSubSuppDmeVal: "dme",
  ros_earsDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Ears = [
  [
    "ros_earsDiagnoses",
    [
      "ros_earsDiagDiffHearVal",
      "ros_earsDiagLegalDeafVal",
      "ros_earsDiagTinnitusVal",
      "ros_earsDiagVertigoVal",
      "ros_earsDiagOtherVal",
    ],
  ],
  [
    "ros_earsDiagDiffHearSubDesc",
    [
      "ros_earsDiagDiffHearSubDescActiveVal",
      "ros_earsDiagDiffHearSubDescHistoryVal",
      "ros_earsDiagDiffHearSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagDiffHearSubSupp",
    [
      "ros_earsDiagDiffHearSubSuppHistoryVal",
      "ros_earsDiagDiffHearSubSuppSymptomsVal",
      "ros_earsDiagDiffHearSubSuppTestResultsVal",
      "ros_earsDiagDiffHearSubSuppHearAidsVal",
      "ros_earsDiagDiffHearSubSuppMedicVal",
      "ros_earsDiagDiffHearSubSuppReadLipsVal",
      "ros_earsDiagDiffHearSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagLegalDeafSubDesc",
    [
      "ros_earsDiagLegalDeafSubDescActiveVal",
      "ros_earsDiagLegalDeafSubDescHistoryVal",
      "ros_earsDiagLegalDeafSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagLegalDeafSubSupp",
    [
      "ros_earsDiagLegalDeafSubSuppHistoryVal",
      "ros_earsDiagLegalDeafSubSuppSymptomsVal",
      "ros_earsDiagLegalDeafSubSuppPhysFindVal",
      "ros_earsDiagLegalDeafSubSuppMedicsVal",
      "ros_earsDiagLegalDeafSubSuppTestResultsVal",
      "ros_earsDiagLegalDeafSubSuppImageStudyVal",
      "ros_earsDiagLegalDeafSubSuppBiopsyVal",
      "ros_earsDiagLegalDeafSubSuppDmeVal",
      "ros_earsDiagLegalDeafSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagTinnitusSubDesc",
    [
      "ros_earsDiagTinnitusSubDescActiveVal",
      "ros_earsDiagTinnitusSubDescHistoryVal",
      "ros_earsDiagTinnitusSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagTinnitusSubSupp",
    [
      "ros_earsDiagTinnitusSubSuppHistoryVal",
      "ros_earsDiagTinnitusSubSuppSymptomsVal",
      "ros_earsDiagTinnitusSubSuppPhysFindVal",
      "ros_earsDiagTinnitusSubSuppMedicsVal",
      "ros_earsDiagTinnitusSubSuppTestResultsVal",
      "ros_earsDiagTinnitusSubSuppImageStudyVal",
      "ros_earsDiagTinnitusSubSuppBiopsyVal",
      "ros_earsDiagTinnitusSubSuppDmeVal",
      "ros_earsDiagTinnitusSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagVertigoSubDesc",
    [
      "ros_earsDiagVertigoSubDescActiveVal",
      "ros_earsDiagVertigoSubDescHistoryVal",
      "ros_earsDiagVertigoSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagVertigoSubSupp",
    [
      "ros_earsDiagVertigoSubSuppHistoryVal",
      "ros_earsDiagVertigoSubSuppSymptomsVal",
      "ros_earsDiagVertigoSubSuppPhysFindVal",
      "ros_earsDiagVertigoSubSuppMedicsVal",
      "ros_earsDiagVertigoSubSuppTestResultsVal",
      "ros_earsDiagVertigoSubSuppImageStudyVal",
      "ros_earsDiagVertigoSubSuppBiopsyVal",
      "ros_earsDiagVertigoSubSuppDmeVal",
      "ros_earsDiagVertigoSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagOtherSubDesc",
    [
      "ros_earsDiagOtherSubDescActiveVal",
      "ros_earsDiagOtherSubDescHistoryVal",
      "ros_earsDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagOtherSubSupp",
    [
      "ros_earsDiagOtherSubSuppHistoryVal",
      "ros_earsDiagOtherSubSuppSymptomsVal",
      "ros_earsDiagOtherSubSuppPhysFindVal",
      "ros_earsDiagOtherSubSuppMedicsVal",
      "ros_earsDiagOtherSubSuppTestResultsVal",
      "ros_earsDiagOtherSubSuppImageStudyVal",
      "ros_earsDiagOtherSubSuppBiopsyVal",
      "ros_earsDiagOtherSubSuppDmeVal",
      "ros_earsDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_Nose = [
  ["ros_noseVal", "nose.isNose"],

  ["ros_noseDiagAllergyVal", "nose.isNoseSubAnswer.diagnoses.allergicRhinitis"],

  [
    "ros_noseDiagAllergySubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagAllergySubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagAllergySubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagAllergySubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagAllergySubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagAllergySubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagAllergySubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagAllergySubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagAllergySubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagAllergySubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagAllergySubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagAllergySubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagAllergySubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagAllergySubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagAllergySubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_noseDiagChronicNasalVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDrip",
  ],

  [
    "ros_noseDiagChronicNasalSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagChronicNasalSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagChronicNasalSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagChronicNasalSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagChronicNasalSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagChronicNasalSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.comment",
  ],

  ["ros_noseDiagNoseBleedVal", "nose.isNoseSubAnswer.diagnoses.noseBleeds"],

  [
    "ros_noseDiagNoseBleedSubActiveVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.active",
  ],
  [
    "ros_noseDiagNoseBleedSubHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.historyOf",
  ],
  [
    "ros_noseDiagNoseBleedSubRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.ruleOut",
  ],
  [
    "ros_noseDiagNoseBleedSubCommentVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.comment",
  ],

  [
    "ros_noseDiagSeasonAllergyVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergies",
  ],

  [
    "ros_noseDiagSeasonAllergySubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagSeasonAllergySubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagSeasonAllergySubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.comment",
  ],

  [
    "ros_noseDiagSinusInfectVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfections",
  ],

  [
    "ros_noseDiagSinusInfectSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagSinusInfectSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagSinusInfectSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagSinusInfectSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagSinusInfectSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagSinusInfectSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.comment",
  ],
  [
    "ros_noseDiagSinusInfectSubExuClearVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.clear",
  ],
  [
    "ros_noseDiagSinusInfectSubExuPurulentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.purulent",
  ],
  [
    "ros_noseDiagSinusInfectSubExuCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.comment",
  ],

  ["ros_noseDiagSinustisVal", "nose.isNoseSubAnswer.diagnoses.sinusitis"],

  [
    "ros_noseDiagSinustisSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagSinustisSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagSinustisSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagSinustisSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagSinustisSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagSinustisSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagSinustisSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagSinustisSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagSinustisSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagSinustisSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagSinustisSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagSinustisSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagSinustisSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagSinustisSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagSinustisSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.comment",
  ],

  ["ros_noseDiagOtherVal", "nose.isNoseSubAnswer.diagnoses.other"],

  [
    "ros_noseDiagOtherSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagOtherSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagOtherSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagOtherSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagOtherSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagOtherSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagOtherSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagOtherSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagOtherSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagOtherSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagOtherSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagOtherSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagOtherSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagOtherSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagOtherSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_noseDiagOtherSubOtherVal",
    "nose.isNoseSubAnswer.diagnoses.othersSubAnswer.other",
  ],

  ["ros_noseDiagCommentVal", "nose.isNoseSubAnswer.diagnoses.comment"],
  ["ros_noseCommentVal", "nose.comment"],
];

export const ros_optionsTreeMapObject_Nose = {
  ros_noseDiagAllergyVal: "allergicRhinitis",

  ros_noseDiagAllergySubDescActiveVal: "active",
  ros_noseDiagAllergySubDescHistoryVal: "historyOf",
  ros_noseDiagAllergySubDescRuleOutVal: "ruleOut",

  ros_noseDiagAllergySubSuppHistoryVal: "history",
  ros_noseDiagAllergySubSuppSymptomsVal: "symptoms",
  ros_noseDiagAllergySubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagAllergySubSuppMedicsVal: "medications",
  ros_noseDiagAllergySubSuppTestResultsVal: "testResults",
  ros_noseDiagAllergySubSuppImageStudyVal: "imageStudies",
  ros_noseDiagAllergySubSuppBiopsyVal: "biopsy",
  ros_noseDiagAllergySubSuppDmeVal: "dme",
  ros_noseDiagAllergySubSuppOtherVal: "other",

  ros_noseDiagChronicNasalVal: "chronicNasalDrip",

  ros_noseDiagChronicNasalSubDescActiveVal: "active",
  ros_noseDiagChronicNasalSubDescHistoryVal: "historyOf",
  ros_noseDiagChronicNasalSubDescRuleOutVal: "ruleOut",

  ros_noseDiagChronicNasalSubSuppHistoryVal: "history",
  ros_noseDiagChronicNasalSubSuppSymptomsVal: "symptoms",
  ros_noseDiagChronicNasalSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagChronicNasalSubSuppMedicsVal: "medications",
  ros_noseDiagChronicNasalSubSuppTestResultsVal: "testResults",
  ros_noseDiagChronicNasalSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagChronicNasalSubSuppBiopsyVal: "biopsy",
  ros_noseDiagChronicNasalSubSuppDmeVal: "dme",
  ros_noseDiagChronicNasalSubSuppOtherVal: "other",

  ros_noseDiagNoseBleedVal: "noseBleeds",

  ros_noseDiagNoseBleedSubActiveVal: "active",
  ros_noseDiagNoseBleedSubHistoryVal: "historyOf",
  ros_noseDiagNoseBleedSubRuleOutVal: "ruleOut",

  ros_noseDiagSeasonAllergyVal: "seasonalAllergies",

  ros_noseDiagSeasonAllergySubDescActiveVal: "active",
  ros_noseDiagSeasonAllergySubDescHistoryVal: "historyOf",
  ros_noseDiagSeasonAllergySubDescRuleOutVal: "ruleOut",

  ros_noseDiagSeasonAllergySubSuppHistoryVal: "history",
  ros_noseDiagSeasonAllergySubSuppSymptomsVal: "symptoms",
  ros_noseDiagSeasonAllergySubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagSeasonAllergySubSuppMedicsVal: "medications",
  ros_noseDiagSeasonAllergySubSuppTestResultsVal: "testResults",
  ros_noseDiagSeasonAllergySubSuppImageStudyVal: "imageStudies",
  ros_noseDiagSeasonAllergySubSuppBiopsyVal: "biopsy",
  ros_noseDiagSeasonAllergySubSuppDmeVal: "dme",
  ros_noseDiagSeasonAllergySubSuppOtherVal: "other",

  ros_noseDiagSinusInfectVal: "sinusInfections",

  ros_noseDiagSinusInfectSubDescActiveVal: "active",
  ros_noseDiagSinusInfectSubDescHistoryVal: "historyOf",
  ros_noseDiagSinusInfectSubDescRuleOutVal: "ruleOut",
  ros_noseDiagSinusInfectSubDescCommentVal: "comment",

  ros_noseDiagSinusInfectSubSuppHistoryVal: "history",
  ros_noseDiagSinusInfectSubSuppSymptomsVal: "symptoms",
  ros_noseDiagSinusInfectSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagSinusInfectSubSuppMedicsVal: "medications",
  ros_noseDiagSinusInfectSubSuppTestResultsVal: "testResults",
  ros_noseDiagSinusInfectSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagSinusInfectSubSuppBiopsyVal: "biopsy",
  ros_noseDiagSinusInfectSubSuppDmeVal: "dme",
  ros_noseDiagSinusInfectSubSuppOtherVal: "other",

  ros_noseDiagSinusInfectSubExuClearVal: "clear",
  ros_noseDiagSinusInfectSubExuPurulentVal: "purulent",
  ros_noseDiagSinusInfectSubExuCommentVal: "comment",

  ros_noseDiagSinustisVal: "sinusitis",

  ros_noseDiagSinustisSubDescActiveVal: "active",
  ros_noseDiagSinustisSubDescHistoryVal: "historyOf",
  ros_noseDiagSinustisSubDescRuleOutVal: "ruleOut",

  ros_noseDiagSinustisSubSuppHistoryVal: "history",
  ros_noseDiagSinustisSubSuppSymptomsVal: "symptoms",
  ros_noseDiagSinustisSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagSinustisSubSuppMedicsVal: "medications",
  ros_noseDiagSinustisSubSuppTestResultsVal: "testResults",
  ros_noseDiagSinustisSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagSinustisSubSuppBiopsyVal: "biopsy",
  ros_noseDiagSinustisSubSuppDmeVal: "dme",
  ros_noseDiagSinustisSubSuppOtherVal: "other",

  ros_noseDiagOtherVal: "other",

  ros_noseDiagOtherSubDescActiveVal: "active",
  ros_noseDiagOtherSubDescHistoryVal: "historyOf",
  ros_noseDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_noseDiagOtherSubSuppHistoryVal: "history",
  ros_noseDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_noseDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagOtherSubSuppMedicsVal: "medications",
  ros_noseDiagOtherSubSuppTestResultsVal: "testResults",
  ros_noseDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_noseDiagOtherSubSuppDmeVal: "dme",
  ros_noseDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Nose = [
  [
    "ros_noseDiagnoses",
    [
      "ros_noseDiagAllergyVal",
      "ros_noseDiagChronicNasalVal",
      "ros_noseDiagNoseBleedVal",
      "ros_noseDiagSeasonAllergyVal",
      "ros_noseDiagSinusInfectVal",
      "ros_noseDiagSinustisVal",
      "ros_noseDiagOtherVal",
    ],
  ],
  [
    "ros_noseDiagAllergySubDesc",
    [
      "ros_noseDiagAllergySubDescActiveVal",
      "ros_noseDiagAllergySubDescHistoryVal",
      "ros_noseDiagAllergySubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagAllergySubSupp",
    [
      "ros_noseDiagAllergySubSuppHistoryVal",
      "ros_noseDiagAllergySubSuppSymptomsVal",
      "ros_noseDiagAllergySubSuppPhysFindVal",
      "ros_noseDiagAllergySubSuppMedicsVal",
      "ros_noseDiagAllergySubSuppTestResultsVal",
      "ros_noseDiagAllergySubSuppImageStudyVal",
      "ros_noseDiagAllergySubSuppBiopsyVal",
      "ros_noseDiagAllergySubSuppDmeVal",
      "ros_noseDiagAllergySubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagChronicNasalSubDesc",
    [
      "ros_noseDiagChronicNasalSubDescActiveVal",
      "ros_noseDiagChronicNasalSubDescHistoryVal",
      "ros_noseDiagChronicNasalSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagChronicNasalSubSupp",
    [
      "ros_noseDiagChronicNasalSubSuppHistoryVal",
      "ros_noseDiagChronicNasalSubSuppSymptomsVal",
      "ros_noseDiagChronicNasalSubSuppPhysFindVal",
      "ros_noseDiagChronicNasalSubSuppMedicsVal",
      "ros_noseDiagChronicNasalSubSuppTestResultsVal",
      "ros_noseDiagChronicNasalSubSuppImageStudyVal",
      "ros_noseDiagChronicNasalSubSuppBiopsyVal",
      "ros_noseDiagChronicNasalSubSuppDmeVal",
      "ros_noseDiagChronicNasalSubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagNoseBleedSub",
    [
      "ros_noseDiagNoseBleedSubActiveVal",
      "ros_noseDiagNoseBleedSubHistoryVal",
      "ros_noseDiagNoseBleedSubRuleOutVal",
    ],
  ],

  [
    "ros_noseDiagSeasonAllergySubDesc",
    [
      "ros_noseDiagSeasonAllergySubDescActiveVal",
      "ros_noseDiagSeasonAllergySubDescHistoryVal",
      "ros_noseDiagSeasonAllergySubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagSeasonAllergySubSupp",
    [
      "ros_noseDiagSeasonAllergySubSuppHistoryVal",
      "ros_noseDiagSeasonAllergySubSuppSymptomsVal",
      "ros_noseDiagSeasonAllergySubSuppPhysFindVal",
      "ros_noseDiagSeasonAllergySubSuppMedicsVal",
      "ros_noseDiagSeasonAllergySubSuppTestResultsVal",
      "ros_noseDiagSeasonAllergySubSuppImageStudyVal",
      "ros_noseDiagSeasonAllergySubSuppBiopsyVal",
      "ros_noseDiagSeasonAllergySubSuppDmeVal",
      "ros_noseDiagSeasonAllergySubSuppOtherVal",
    ],
  ],

  [
    "ros_noseDiagSinusInfectSubDesc",
    [
      "ros_noseDiagSinusInfectSubDescActiveVal",
      "ros_noseDiagSinusInfectSubDescHistoryVal",
      "ros_noseDiagSinusInfectSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagSinusInfectSubSupp",
    [
      "ros_noseDiagSinusInfectSubSuppHistoryVal",
      "ros_noseDiagSinusInfectSubSuppSymptomsVal",
      "ros_noseDiagSinusInfectSubSuppPhysFindVal",
      "ros_noseDiagSinusInfectSubSuppMedicsVal",
      "ros_noseDiagSinusInfectSubSuppTestResultsVal",
      "ros_noseDiagSinusInfectSubSuppImageStudyVal",
      "ros_noseDiagSinusInfectSubSuppBiopsyVal",
      "ros_noseDiagSinusInfectSubSuppDmeVal",
      "ros_noseDiagSinusInfectSubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagSinusInfectSubExu",
    [
      "ros_noseDiagSinusInfectSubExuClearVal",
      "ros_noseDiagSinusInfectSubExuPurulentVal",
    ],
  ],

  [
    "ros_noseDiagSinustisSubDesc",
    [
      "ros_noseDiagSinustisSubDescActiveVal",
      "ros_noseDiagSinustisSubDescHistoryVal",
      "ros_noseDiagSinustisSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagSinustisSubSupp",
    [
      "ros_noseDiagSinustisSubSuppHistoryVal",
      "ros_noseDiagSinustisSubSuppSymptomsVal",
      "ros_noseDiagSinustisSubSuppPhysFindVal",
      "ros_noseDiagSinustisSubSuppMedicsVal",
      "ros_noseDiagSinustisSubSuppTestResultsVal",
      "ros_noseDiagSinustisSubSuppImageStudyVal",
      "ros_noseDiagSinustisSubSuppBiopsyVal",
      "ros_noseDiagSinustisSubSuppDmeVal",
      "ros_noseDiagSinustisSubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagOtherSubDesc",
    [
      "ros_noseDiagOtherSubDescActiveVal",
      "ros_noseDiagOtherSubDescHistoryVal",
      "ros_noseDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagOtherSubSupp",
    [
      "ros_noseDiagOtherSubSuppHistoryVal",
      "ros_noseDiagOtherSubSuppSymptomsVal",
      "ros_noseDiagOtherSubSuppPhysFindVal",
      "ros_noseDiagOtherSubSuppMedicsVal",
      "ros_noseDiagOtherSubSuppTestResultsVal",
      "ros_noseDiagOtherSubSuppImageStudyVal",
      "ros_noseDiagOtherSubSuppBiopsyVal",
      "ros_noseDiagOtherSubSuppDmeVal",
      "ros_noseDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_Mouth = [
  ["ros_mouthVal", "mouthAndThroat.isMouthAndThroat"],

  [
    "ros_mouthDiagBleedGumsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGums",
  ],
  [
    "ros_mouthDiagBleedGumsSubActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.active",
  ],
  [
    "ros_mouthDiagBleedGumsSubHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.historyOf",
  ],
  [
    "ros_mouthDiagBleedGumsSubRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.ruleOut",
  ],
  [
    "ros_mouthDiagBleedGumsSubCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.comment",
  ],

  [
    "ros_mouthDiagDiffChewVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewing",
  ],

  [
    "ros_mouthDiagDiffChewSubDescActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.active",
  ],
  [
    "ros_mouthDiagDiffChewSubDescHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.historyOf",
  ],
  [
    "ros_mouthDiagDiffChewSubDescRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.ruleOut",
  ],
  [
    "ros_mouthDiagDiffChewSubDescCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.comment",
  ],

  [
    "ros_mouthDiagDiffChewSubBcsPainVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPain",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainSubVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPainSubAnswer.isDentures",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainSubCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPainSubAnswer.comment",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.comment",
  ],

  [
    "ros_mouthDiagDiffSwallowVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowing",
  ],

  [
    "ros_mouthDiagDiffSwallowSubDescActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.active",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.historyOf",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.ruleOut",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.comment",
  ],

  [
    "ros_mouthDiagDiffSwallowSubHadStrokeVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStroke",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStrokeSubAnswer.eatSpecialDiet",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeSubCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStrokeSubAnswer.comment",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.comment",
  ],

  [
    "ros_mouthDiagOtherVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.other",
  ],
  [
    "ros_mouthDiagOtherSubDescActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_mouthDiagOtherSubDescHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_mouthDiagOtherSubDescRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_mouthDiagOtherSubDescCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_mouthDiagOtherSubSuppHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_mouthDiagOtherSubSuppSymptomsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_mouthDiagOtherSubSuppPhysFindVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_mouthDiagOtherSubSuppMedicsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_mouthDiagOtherSubSuppTestResultsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_mouthDiagOtherSubSuppImageStudyVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_mouthDiagOtherSubSuppBiopsyVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_mouthDiagOtherSubSuppDmeVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_mouthDiagOtherSubSuppOtherVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_mouthDiagOtherSubSuppOtherSubDescVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_mouthDiagOtherSubSuppCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_mouthDiagOtherSubOtherVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.other",
  ],
];

export const ros_optionsTreeMapObject_Mouth = {
  ros_mouthDiagBleedGumsVal: "bleedingGums",
  ros_mouthDiagBleedGumsSubActiveVal: "active",
  ros_mouthDiagBleedGumsSubHistoryVal: "historyOf",
  ros_mouthDiagBleedGumsSubRuleOutVal: "ruleOut",

  ros_mouthDiagDiffChewVal: "difficultyChewing",

  ros_mouthDiagDiffChewSubDescActiveVal: "active",
  ros_mouthDiagDiffChewSubDescHistoryVal: "historyOf",
  ros_mouthDiagDiffChewSubDescRuleOutVal: "ruleOut",

  ros_mouthDiagDiffSwallowVal: "difficultySwallowing",

  ros_mouthDiagDiffSwallowSubDescActiveVal: "active",
  ros_mouthDiagDiffSwallowSubDescHistoryVal: "historyOf",
  ros_mouthDiagDiffSwallowSubDescRuleOutVal: "ruleOut",

  ros_mouthDiagOtherVal: "other",

  ros_mouthDiagOtherSubDescActiveVal: "active",
  ros_mouthDiagOtherSubDescHistoryVal: "historyOf",
  ros_mouthDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_mouthDiagOtherSubSuppHistoryVal: "history",
  ros_mouthDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_mouthDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_mouthDiagOtherSubSuppMedicsVal: "medications",
  ros_mouthDiagOtherSubSuppTestResultsVal: "testResults",
  ros_mouthDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_mouthDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_mouthDiagOtherSubSuppDmeVal: "dme",
  ros_mouthDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Mouth = [
  [
    "ros_mouthDiag",
    [
      "ros_mouthDiagBleedGumsVal",
      "ros_mouthDiagDiffChewVal",
      "ros_mouthDiagDiffSwallowVal",
      "ros_mouthDiagOtherVal",
    ],
  ],
  [
    "ros_mouthDiagBleedGumsSub",
    [
      "ros_mouthDiagBleedGumsSubActiveVal",
      "ros_mouthDiagBleedGumsSubHistoryVal",
      "ros_mouthDiagBleedGumsSubRuleOutVal",
    ],
  ],
  [
    "ros_mouthDiagDiffChewSubDesc",
    [
      "ros_mouthDiagDiffChewSubDescActiveVal",
      "ros_mouthDiagDiffChewSubDescHistoryVal",
      "ros_mouthDiagDiffChewSubDescRuleOutVal",
    ],
  ],

  [
    "ros_mouthDiagDiffSwallowSubDesc",
    [
      "ros_mouthDiagDiffSwallowSubDescActiveVal",
      "ros_mouthDiagDiffSwallowSubDescHistoryVal",
      "ros_mouthDiagDiffSwallowSubDescRuleOutVal",
    ],
  ],

  [
    "ros_mouthDiagOtherSubDesc",
    [
      "ros_mouthDiagOtherSubDescActiveVal",
      "ros_mouthDiagOtherSubDescHistoryVal",
      "ros_mouthDiagOtherSubDescRuleOutVal",
    ],
  ],

  [
    "ros_mouthDiagOtherSubSupp",
    [
      "ros_mouthDiagOtherSubSuppHistoryVal",
      "ros_mouthDiagOtherSubSuppSymptomsVal",
      "ros_mouthDiagOtherSubSuppPhysFindVal",
      "ros_mouthDiagOtherSubSuppMedicsVal",
      "ros_mouthDiagOtherSubSuppTestResultsVal",
      "ros_mouthDiagOtherSubSuppImageStudyVal",
      "ros_mouthDiagOtherSubSuppBiopsyVal",
      "ros_mouthDiagOtherSubSuppDmeVal",
      "ros_mouthDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_Neck = [
  ["ros_neckVal", "neck.isNeck"],

  ["ros_neckDiagCarotidVal", "neck.isNeckSubAnswer.diagnoses.carotidStenosis"],

  [
    "ros_neckDiagCarotidSubDescActiveVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.active",
  ],
  [
    "ros_neckDiagCarotidSubDescHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neckDiagCarotidSubDescRuleOutVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neckDiagCarotidSubDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.comment",
  ],

  [
    "ros_neckDiagCarotidSubSuppBruitVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.bruits",
  ],
  [
    "ros_neckDiagCarotidSubSuppTiaHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.historyOfTia",
  ],
  [
    "ros_neckDiagCarotidSubSuppLabStudyVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.laboratoryStudies",
  ],
  [
    "ros_neckDiagCarotidSubSuppOtherVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neckDiagCarotidSubSuppOtherSubDescVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neckDiagCarotidSubSuppCommentVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_neckDiagCarotidCsDescRightVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.right",
  ],
  [
    "ros_neckDiagCarotidCsDescLeftVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.left",
  ],
  [
    "ros_neckDiagCarotidCsDescBilatVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.bilateral",
  ],
  [
    "ros_neckDiagCarotidCsDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.comment",
  ],

  ["ros_neckDiagParotidVal", "neck.isNeckSubAnswer.diagnoses.parotidDisease"],

  [
    "ros_neckDiagParotidSubDescActiveVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_neckDiagParotidSubDescHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_neckDiagParotidSubDescRuleOutVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neckDiagParotidSubDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_neckDiagParotidSubSuppPhysFindVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neckDiagParotidSubSuppHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.history",
  ],
  [
    "ros_neckDiagParotidSubSuppOtherVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_neckDiagParotidSubSuppOtherSubDescVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neckDiagParotidSubSuppCommentVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.comment",
  ],

  ["ros_neckDiagOtherVal", "neck.isNeckSubAnswer.diagnoses.other"],

  [
    "ros_neckDiagOtherSubDescActiveVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_neckDiagOtherSubDescHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_neckDiagOtherSubDescRuleOutVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neckDiagOtherSubDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_neckDiagOtherSubSuppHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_neckDiagOtherSubSuppSymptomsVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neckDiagOtherSubSuppPhysFindVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neckDiagOtherSubSuppMedicsVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neckDiagOtherSubSuppTestResultsVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_neckDiagOtherSubSuppImageStudyVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_neckDiagOtherSubSuppBiopsyVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neckDiagOtherSubSuppDmeVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_neckDiagOtherSubSuppOtherVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_neckDiagOtherSubSuppOtherSubDescVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neckDiagOtherSubSuppCommentVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neckDiagOtherSubOtherVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  ["ros_neckDiagCommentVal", "neck.isNeckSubAnswer.diagnoses.comment"],
  ["ros_neckCommentVal", "neck.comment"],
];

export const ros_optionsTreeMapObject_Neck = {
  ros_neckDiagCarotidVal: "carotidStenosis",
  ros_neckDiagParotidVal: "parotidDisease",
  ros_neckDiagOtherVal: "other",

  ros_neckDiagCarotidSubDescActiveVal: "active",
  ros_neckDiagCarotidSubDescHistoryVal: "historyOf",
  ros_neckDiagCarotidSubDescRuleOutVal: "ruleOut",

  ros_neckDiagCarotidSubSuppBruitVal: "bruits",
  ros_neckDiagCarotidSubSuppTiaHistoryVal: "historyOfTia",
  ros_neckDiagCarotidSubSuppLabStudyVal: "laboratoryStudies",
  ros_neckDiagCarotidSubSuppOtherVal: "other",

  ros_neckDiagCarotidCsDescRightVal: "right",
  ros_neckDiagCarotidCsDescLeftVal: "left",
  ros_neckDiagCarotidCsDescBilatVal: "bilateral",

  ros_neckDiagParotidSubDescActiveVal: "active",
  ros_neckDiagParotidSubDescHistoryVal: "historyOf",
  ros_neckDiagParotidSubDescRuleOutVal: "ruleOut",

  ros_neckDiagParotidSubSuppPhysFindVal: "physicalFindings",
  ros_neckDiagParotidSubSuppHistoryVal: "history",
  ros_neckDiagParotidSubSuppOtherVal: "other",

  ros_neckDiagOtherSubDescActiveVal: "active",
  ros_neckDiagOtherSubDescHistoryVal: "historyOf",
  ros_neckDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_neckDiagOtherSubSuppHistoryVal: "history",
  ros_neckDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_neckDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_neckDiagOtherSubSuppMedicsVal: "medications",
  ros_neckDiagOtherSubSuppTestResultsVal: "testResults",
  ros_neckDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_neckDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_neckDiagOtherSubSuppDmeVal: "dme",
  ros_neckDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Neck = [
  [
    "ros_neckDiag",
    [
      "ros_neckDiagCarotidVal",
      "ros_neckDiagParotidVal",
      "ros_neckDiagOtherVal",
    ],
  ],
  [
    "ros_neckDiagCarotidSubDesc",
    [
      "ros_neckDiagCarotidSubDescActiveVal",
      "ros_neckDiagCarotidSubDescHistoryVal",
      "ros_neckDiagCarotidSubDescRuleOutVal",
      "ros_neckDiagCarotidSubDescCommentVal",
    ],
  ],
  [
    "ros_neckDiagCarotidSubSupp",
    [
      "ros_neckDiagCarotidSubSuppBruitVal",
      "ros_neckDiagCarotidSubSuppTiaHistoryVal",
      "ros_neckDiagCarotidSubSuppLabStudyVal",
      "ros_neckDiagCarotidSubSuppOtherVal",
    ],
  ],
  [
    "ros_neckDiagCarotidCsDesc",
    [
      "ros_neckDiagCarotidCsDescRightVal",
      "ros_neckDiagCarotidCsDescLeftVal",
      "ros_neckDiagCarotidCsDescBilatVal",
    ],
  ],
  [
    "ros_neckDiagParotidSubDesc",
    [
      "ros_neckDiagParotidSubDescActiveVal",
      "ros_neckDiagParotidSubDescHistoryVal",
      "ros_neckDiagParotidSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neckDiagParotidSubSupp",
    [
      "ros_neckDiagParotidSubSuppPhysFindVal",
      "ros_neckDiagParotidSubSuppHistoryVal",
      "ros_neckDiagParotidSubSuppOtherVal",
    ],
  ],
  [
    "ros_neckDiagOtherSubDesc",
    [
      "ros_neckDiagOtherSubDescActiveVal",
      "ros_neckDiagOtherSubDescHistoryVal",
      "ros_neckDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neckDiagOtherSubSupp",
    [
      "ros_neckDiagOtherSubSuppHistoryVal",
      "ros_neckDiagOtherSubSuppSymptomsVal",
      "ros_neckDiagOtherSubSuppPhysFindVal",
      "ros_neckDiagOtherSubSuppMedicsVal",
      "ros_neckDiagOtherSubSuppTestResultsVal",
      "ros_neckDiagOtherSubSuppImageStudyVal",
      "ros_neckDiagOtherSubSuppBiopsyVal",
      "ros_neckDiagOtherSubSuppDmeVal",
      "ros_neckDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_eyeENTNeckRecommendations = [
  [
    "ros_recoHearEvalVal",
    "recommendations.recommendationsSubAnswers[0].hearingEvaluation",
  ],
  [
    "ros_recoDentalExamVal",
    "recommendations.recommendationsSubAnswers[1].dentalExam",
  ],
  [
    "ros_recoEyeExamVal",
    "recommendations.recommendationsSubAnswers[2].eyeExam",
  ],
  [
    "ros_recoSwalEvalyVal",
    "recommendations.recommendationsSubAnswers[3].swallowingEvaluation",
  ],
  [
    "ros_recoTakeMedVal",
    "recommendations.recommendationsSubAnswers[4].takeMedications",
  ],
  [
    "ros_recoFoodDispVal",
    "recommendations.recommendationsSubAnswers[5].foodDisparity",
  ],
  ["ros_recoOtherVal", "recommendations.recommendationsSubAnswers[6].other"],

  [
    "ros_eyeENTRecoHearEvalCommentVal",
    "recommendations.recommendationsSubAnswers[0].comment",
  ],
  [
    "ros_eyeENTRecoDentalExamCommentVal",
    "recommendations.recommendationsSubAnswers[1].comment",
  ],
  [
    "ros_eyeENTRecoEyeExamCommentVal",
    "recommendations.recommendationsSubAnswers[2].comment",
  ],
  [
    "ros_eyeENTRecoSwalEvalyCommentVal",
    "recommendations.recommendationsSubAnswers[3].comment",
  ],
  [
    "ros_eyeENTRecoTakeMedCommentVal",
    "recommendations.recommendationsSubAnswers[4].comment",
  ],
  [
    "ros_eyeENTRecoFoodDispCommentVal",
    "recommendations.recommendationsSubAnswers[5].comment",
  ],
  [
    "ros_eyeENTRecoOtherCommentVal",
    "recommendations.recommendationsSubAnswers[6].comment",
  ],
];

export const ros_hashmap_BowelMovement = [
  ["ros_bowelMoveNormalVal", "bowelMovements.normal"],
  ["ros_bowelMoveAbnormVal", "bowelMovements.abnormal"],
  ["ros_bowelMoveCommentVal", "bowelMovements.comment"],
  ["ros_bowelMoveAbnormConstVal", "bowelMovements.abnormal.constipation"],
  ["ros_bowelMoveAbnormDiarrVal", "bowelMovements.abnormal.diarrhea"],
  ["ros_bowelMoveAbnormBowelVal", "bowelMovements.abnormal.bowelIncontinence"],
  ["ros_bowelMoveAbnormCommentVal", "bowelMovements.abnormal.comment"],
  [
    "ros_bowelMoveAbnormConstAcuteVal",
    "bowelMovements.abnormal.constipationSubAnswer.acute",
  ],
  [
    "ros_bowelMoveAbnormConstChronVal",
    "bowelMovements.abnormal.constipationSubAnswer.chronic",
  ],
  [
    "ros_bowelMoveAbnormConstCommentVal",
    "bowelMovements.abnormal.constipationSubAnswer.comment",
  ],
  [
    "ros_bowelMoveAbnormDiarrIsdiaAcuteVal",
    "bowelMovements.abnormal.diarrheaSubAnswer.isDiarrhea.acute",
  ],
  [
    "ros_bowelMoveAbnormDiarrIsdiaChronVal",
    "bowelMovements.abnormal.diarrheaSubAnswer.isDiarrhea.chronic",
  ],
  [
    "ros_bowelMoveAbnormDiarrIsdiaCommentVal",
    "bowelMovements.abnormal.diarrheaSubAnswer.isDiarrhea.comment",
  ],
  [
    "ros_bowelMoveAbnormDiarrIshisVal",
    "bowelMovements.abnormal.diarrheaSubAnswer.isHistoryOfCDifficile.isHistoryOfCDifficile",
  ],
  [
    "ros_bowelMoveAbnormDiarrIshisCommentVal",
    "bowelMovements.abnormal.diarrheaSubAnswer.isHistoryOfCDifficile.comment",
  ],
];

export const ros_optionsTreeMapObject_BowelMovement = {
  ros_bowelMoveNormalVal: "normal",
  ros_bowelMoveAbnormVal: "abnormal",

  ros_bowelMoveAbnormConstVal: "constipation",
  ros_bowelMoveAbnormDiarrVal: "diarrhea",
  ros_bowelMoveAbnormBowelVal: "bowelIncontinence",

  ros_bowelMoveAbnormConstAcuteVal: "acute",
  ros_bowelMoveAbnormConstChronVal: "chronic",

  ros_bowelMoveAbnormDiarrIsdiaAcuteVal: "acute",
  ros_bowelMoveAbnormDiarrIsdiaChronVal: "chronic",
};

export const ros_OptionsTreeWrappers_BowelMovement = [
  ["ros_bowelMove", ["ros_bowelMoveNormalVal", "ros_bowelMoveAbnormVal"]],
  [
    "ros_bowelMoveAbnorm",
    [
      "ros_bowelMoveAbnormConstVal",
      "ros_bowelMoveAbnormDiarrVal",
      "ros_bowelMoveAbnormBowelVal",
    ],
  ],
  [
    "ros_bowelMoveAbnormConst",
    ["ros_bowelMoveAbnormConstAcuteVal", "ros_bowelMoveAbnormConstChronVal"],
  ],
  [
    "ros_bowelMoveAbnormDiarrIsdia",
    [
      "ros_bowelMoveAbnormDiarrIsdiaAcuteVal",
      "ros_bowelMoveAbnormDiarrIsdiaChronVal",
    ],
  ],
];

export const ros_hashmap_AbdominalOpening = [
  ["ros_abdominalIsabdVal", "abdominalOpenings.isAbdominalOpenings"],
  [
    "ros_abdominalIsyesIleostoVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.ileostomy",
  ],
  [
    "ros_abdominalIsyesColostoVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.colostomy",
  ],
  [
    "ros_abdominalIsyesUrostomVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.urostomy",
  ],
  [
    "ros_abdominalIsyesPegVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.peg",
  ],
  [
    "ros_abdominalIsyesCrytostVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.crytostomy",
  ],
  [
    "ros_abdominalIsyesCommentVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.comment",
  ],
  ["ros_abdominalCommentVal", "abdominalOpenings.comment"],
];

export const ros_optionsTreeMapObject_AbdominalOpening = {
  ros_abdominalIsyesIleostoVal: "ileostomy",
  ros_abdominalIsyesColostoVal: "colostomy",
  ros_abdominalIsyesUrostomVal: "urostomy",
  ros_abdominalIsyesPegVal: "peg",
  ros_abdominalIsyesCrytostVal: "crytostomy",
};

export const ros_OptionsTreeWrappers_AbdominalOpening = [
  [
    "ros_abdominalIsyes",
    [
      "ros_abdominalIsyesIleostoVal",
      "ros_abdominalIsyesColostoVal",
      "ros_abdominalIsyesUrostomVal",
      "ros_abdominalIsyesPegVal",
      "ros_abdominalIsyesCrytostVal",
    ],
  ],
];

export const ros_hashmap_rectalProblems = [
  ["ros_rectalIsrecVal", "ros_rectalProblems.isRectalProblems"],
  [
    "ros_rectalIsrecIsyesfemHemorVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesFemale.hemorrhoids",
  ],
  [
    "ros_rectalIsrecIsyesfemFissuVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesFemale.fissure",
  ],
  [
    "ros_rectalIsrecIsyesfemMassVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesFemale.mass",
  ],
  [
    "ros_rectalIsrecIsyesmalHemorVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesMale.hemorrhoids",
  ],
  [
    "ros_rectalIsrecIsyesmalFissuVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesMale.fissure",
  ],
  [
    "ros_rectalIsrecIsyesmalMassVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesMale.mass",
  ],
  [
    "ros_rectalIsrecIsyesmalBphVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesMale.bph",
  ],
  [
    "ros_rectalIsrecIsyesmalProstVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesMale.prostateMass",
  ],
  [
    "ros_rectalIsrecIsyesmalCommentVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesMale.comment",
  ],
  [
    "ros_rectalIsrecIsyesfemCommentVal",
    "ros_rectalProblems.isRectalProblemsSubAnswer.isYesFemale.comment",
  ],
  ["ros_rectalCommentVal", "ros_rectalProblems.comment"],
];

export const ros_optionsTreeMapObject_rectalProblems = {
  ros_rectalIsrecIsyesfemHemorVal: "hemorrhoids",
  ros_rectalIsrecIsyesfemFissuVal: "fissure",
  ros_rectalIsrecIsyesfemMassVal: "mass",

  ros_rectalIsrecIsyesmalHemorVal: "hemorrhoids",
  ros_rectalIsrecIsyesmalFissuVal: "fissure",
  ros_rectalIsrecIsyesmalMassVal: "mass",
  ros_rectalIsrecIsyesmalBphVal: "bph",
  ros_rectalIsrecIsyesmalProstVal: "prostateMass",
};

export const ros_OptionsTreeWrappers_rectalProblems = [
  [
    "ros_rectalIsrecIsyesfem",
    [
      "ros_rectalIsrecIsyesfemHemorVal",
      "ros_rectalIsrecIsyesfemFissuVal",
      "ros_rectalIsrecIsyesfemMassVal",
    ],
  ],
  [
    "ros_rectalIsrecIsyesmal",
    [
      "ros_rectalIsrecIsyesmalHemorVal",
      "ros_rectalIsrecIsyesmalFissuVal",
      "ros_rectalIsrecIsyesmalMassVal",
      "ros_rectalIsrecIsyesmalBphVal",
      "ros_rectalIsrecIsyesmalProstVal",
    ],
  ],
];

export const ros_hashmap_lastBowel = [
  ["ros_lastBowelTodayVal", "ros_lastBowelMovement.today"],
  ["ros_lastBowelOnetoVal", "ros_lastBowelMovement.oneToThreeDaysAgo"],
  ["ros_lastBowelGreatVal", "ros_lastBowelMovement.greaterThanThreeDaysAgo"],
  ["ros_lastBowelCommentVal", "ros_lastBowelMovement.comment"],
];

export const ros_optionsTreeMapObject_lastBowel = {
  ros_lastBowelTodayVal: "today",
  ros_lastBowelOnetoVal: "oneToThreeDaysAgo",
  ros_lastBowelGreatVal: "greaterThanThreeDaysAgo",
};

export const ros_OptionsTreeWrappers_lastBowel = [
  [
    "ros_lastBowel",
    ["ros_lastBowelTodayVal", "ros_lastBowelOnetoVal", "ros_lastBowelGreatVal"],
  ],
];

export const ros_hashmap_meetFriends = [
  ["ros_meetWithOftenVal", "meetWithFamilyOrFriends.often"],
  ["ros_meetWithSometimeVal", "meetWithFamilyOrFriends.sometimes"],
  ["ros_meetWithNeverVal", "meetWithFamilyOrFriends.never"],
  ["ros_meetWithCommentVal", "meetWithFamilyOrFriends.comment"],
];

export const ros_optionsTreeMapObject_meetFriends = {
  ros_meetWithOftenVal: "often",
  ros_meetWithSometimeVal: "sometimes",
  ros_meetWithNeverVal: "never",
};

export const ros_OptionsTreeWrappers_meetFriends = [
  [
    "ros_meetWith",
    ["ros_meetWithTodayVal", "ros_meetWithOnetoVal", "ros_meetWithGreatVal"],
  ],
];

// no options tree in this section
export const ros_hashmap_gpCog = [
  ["ros_gpcogScoIsgpcogsVal", "gpcogScoreOrMmseScore.isGpcogScore"],
  ["ros_gpcogScoIsmmsescVal", "gpcogScoreOrMmseScore.isMmseScore"],
  ["ros_gpcogScoCommentVal", "gpcogScoreOrMmseScore.comment"],
];

export const ros_hashmap_gpCogNoScore = [
  [
    "ros_noGpcogMmsePatientOrientPersonVal",
    "gpcogOrMmseNotDone.patientOrientedToPerson.patientOrientedToPerson",
  ],
  [
    "ros_noGpcogMmsePatientOrientPlaceVal",
    "gpcogOrMmseNotDone.patientOrientedToPlace.patientOrientedToPerson",
  ],
  [
    "ros_noGpcogMmsePatientOrientTimeVal",
    "gpcogOrMmseNotDone.patientOrientedToTime.patientOrientedToPerson",
  ],
  ["ros_noGpcogMmseRecallGoodVal", "gpcogOrMmseNotDone.recall.good"],
  ["ros_noGpcogMmseRecallPoorVal", "gpcogOrMmseNotDone.recall.poor"],
  [
    "ros_noGpcogMmsePatientDescribeNewsVal",
    "gpcogOrMmseNotDone.patientDescribesRecentNews.patientDescribesRecentNews",
  ],
  ["ros_noGpcogMmseRecallCommentVal", "gpcogOrMmseNotDone.recall.comment"],
  [
    "ros_noGpcogMmsePatientdCommentVal",
    "gpcogOrMmseNotDone.patientDescribesRecentNews.comment",
  ],
  [
    "ros_noGpcogMmsePatientOrientPersonCommentVal",
    "gpcogOrMmseNotDone.patientOrientedToPerson.comment",
  ],
  [
    "ros_noGpcogMmsePatientOrientPlaceCommentVal",
    "gpcogOrMmseNotDone.patientOrientedToPlace.comment",
  ],
  [
    "ros_noGpcogMmsePatientOrientTimeCommentVal",
    "gpcogOrMmseNotDone.patientOrientedToTime.comment",
  ],
];

export const ros_optionsTreeMapObject_gpCogNoScore = {
  ros_noGpcogMmseRecallGoodVal: "good",
  ros_noGpcogMmseRecallPoorVal: "poor",
};

export const ros_OptionsTreeWrappers_gpCogNoScore = [
  [
    "ros_noGpcogMmseRecall",
    ["ros_noGpcogMmseRecallGoodVal", "ros_noGpcogMmseRecallPoorVal"],
  ],
];

export const ros_hashmap_speech = [
  ["ros_speechNormalVal", "speech.normal"],
  ["ros_speechSlurredVal", "speech.slurred"],
  ["ros_speechAphasicVal", "speech.aphasic"],
  ["ros_speechApraxiaVal", "speech.apraxia"],
  ["ros_speechCommentVal", "speech.comment"],
];

export const ros_optionsTreeMapObject_speech = {
  ros_speechNormalVal: "normal",
  ros_speechSlurredVal: "slurred",
  ros_speechAphasicVal: "aphasic",
  ros_speechApraxiaVal: "apraxia",
};

export const ros_OptionsTreeWrappers_speech = [
  [
    "ros_speech",
    [
      "ros_speechNormalVal",
      "ros_speechSlurredVal",
      "ros_speechAphasicVal",
      "ros_speechApraxiaVal",
    ],
  ],
];

export const ros_hashmap_sitToStand = [
  ["ros_sitToStandNormalVal", "sittingToStanding.normal"],
  ["ros_sitToStandNeedAssistVal", "sittingToStanding.needsAssistance"],
  ["ros_sitToStandUnableVal", "sittingToStanding.unable"],
  ["ros_sitToStandCommentVal", "sittingToStanding.comment"],
];

export const ros_optionsTreeMapObject_sitToStand = {
  ros_sitToStandNormalVal: "normal",
  ros_sitToStandNeedAssistVal: "needsAssistance",
  ros_sitToStandUnableVal: "unable",
};

export const ros_OptionsTreeWrappers_sitToStand = [
  [
    "ros_sitToStand",
    [
      "ros_sitToStandNormalVal",
      "ros_sitToStandNeedAssistVal",
      "ros_sitToStandUnableVal",
    ],
  ],
];

export const ros_hashmap_fingerToNose = [
  ["ros_fingerToNoseNormalVal", "fingerToNose.normal"],
  ["ros_fingerToNoseAbnormalVal", "fingerToNose.abnormal"],
  ["ros_fingerToNoseAbnormalSubLeftVal", "fingerToNose.abnormalSubAnswer.left"],
  [
    "ros_fingerToNoseAbnormalSubRightVal",
    "fingerToNose.abnormalSubAnswer.right",
  ],
  ["ros_fingerToNoseAbnormalBothVal", "fingerToNose.abnormalSubAnswer.both"],
  [
    "ros_fingerToNoseAbnormalSubCommentVal",
    "fingerToNose.abnormalSubAnswer.comment",
  ],
  ["ros_fingerToNoseCommentVal", "sittingToStanding.comment"],
];

export const ros_optionsTreeMapObject_fingerToNose = {
  ros_fingerToNoseNormalVal: "normal",
  ros_fingerToNoseAbnormalVal: "abnormal",

  ros_fingerToNoseAbnormalSubLeftVal: "left",
  ros_fingerToNoseAbnormalSubRightVal: "right",
  ros_fingerToNoseAbnormalBothVal: "both",
};

export const ros_OptionsTreeWrappers_fingerToNose = [
  [
    "ros_fingerToNose",
    ["ros_fingerToNoseNormalVal", "ros_fingerToNoseAbnormalVal"],
  ],
  [
    "ros_fingerToNoseAbnormalSub",
    [
      "ros_fingerToNoseAbnormalSubLeftVal",
      "ros_fingerToNoseAbnormalSubRightVal",
      "ros_fingerToNoseAbnormalBothVal",
    ],
  ],
];

export const ros_hashmap_heelToToe = [
  ["ros_heelToToeNormalVal", "heelToToe.normal"],
  ["ros_heelToToeAbnormalVal", "heelToToe.abnormal"],
  ["ros_heelToToeAbnormalSubLeftVal", "heelToToe.abnormalSubAnswer.left"],
  ["ros_heelToToeAbnormalSubRightVal", "heelToToe.abnormalSubAnswer.right"],
  ["ros_heelToToeAbnormalBothVal", "heelToToe.abnormalSubAnswer.both"],
  ["ros_heelToToeAbnormalSubCommentVal", "heelToToe.abnormalSubAnswer.comment"],
  ["ros_heelToToeCommentVal", "sittingToStanding.comment"],
];

export const ros_optionsTreeMapObject_heelToToe = {
  ros_heelToToeNormalVal: "normal",
  ros_heelToToeAbnormalVal: "abnormal",

  ros_heelToToeAbnormalSubLeftVal: "left",
  ros_heelToToeAbnormalSubRightVal: "right",
  ros_heelToToeAbnormalBothVal: "both",
};

export const ros_OptionsTreeWrappers_heelToToe = [
  ["ros_heelToToe", ["ros_heelToToeNormalVal", "ros_heelToToeAbnormalVal"]],
  [
    "ros_heelToToeAbnormalSub",
    [
      "ros_heelToToeAbnormalSubLeftVal",
      "ros_heelToToeAbnormalSubRightVal",
      "ros_heelToToeAbnormalBothVal",
    ],
  ],
];

export const ros_hashmap_thumbToFinger = [
  ["ros_thumbToFingerNormalVal", "thumbToFingerTips.normal"],
  ["ros_thumbToFingerAbnormalVal", "thumbToFingerTips.abnormal"],
  [
    "ros_thumbToFingerAbnormalSubLeftVal",
    "thumbToFingerTips.abnormalSubAnswer.left",
  ],
  [
    "ros_thumbToFingerAbnormalSubRightVal",
    "thumbToFingerTips.abnormalSubAnswer.right",
  ],
  [
    "ros_thumbToFingerAbnormalBothVal",
    "thumbToFingerTips.abnormalSubAnswer.both",
  ],
  [
    "ros_thumbToFingerAbnormalSubCommentVal",
    "thumbToFingerTips.abnormalSubAnswer.comment",
  ],
  ["ros_thumbToFingerCommentVal", "sittingToStanding.comment"],
];

export const ros_optionsTreeMapObject_thumbToFinger = {
  ros_thumbToFingerNormalVal: "normal",
  ros_thumbToFingerAbnormalVal: "abnormal",

  ros_thumbToFingerAbnormalSubLeftVal: "left",
  ros_thumbToFingerAbnormalSubRightVal: "right",
  ros_thumbToFingerAbnormalBothVal: "both",
};

export const ros_OptionsTreeWrappers_thumbToFinger = [
  [
    "ros_thumbToFinger",
    ["ros_thumbToFingerNormalVal", "ros_thumbToFingerAbnormalVal"],
  ],
  [
    "ros_thumbToFingerAbnormalSub",
    [
      "ros_thumbToFingerAbnormalSubLeftVal",
      "ros_thumbToFingerAbnormalSubRightVal",
      "ros_thumbToFingerAbnormalBothVal",
    ],
  ],
];

export const ros_hashmap_extremeMove = [
  ["ros_extremeMoveMotorTicVal", "extremityMovement.motorTic"],
  ["ros_extremeMoveVocalTicVal", "extremityMovement.vocalTic"],
  ["ros_extremeMoveBenignVal", "extremityMovement.benign"],
  ["ros_extremeMoveIntenTremorVal", "extremityMovement.intentionTremor"],
  ["ros_extremeMoveNonIntentionVal", "extremityMovement.nonIntention"],
  ["ros_extremeMoveRigidityVal", "extremityMovement.rigidity"],
  ["ros_extremeMoveSpasticityVal", "extremityMovement.spasticity"],
  ["ros_extremeMoveChoreaMoveVal", "extremityMovement.choreaMovement"],
  ["ros_extremeMoveCogWheelingVal", "extremityMovement.cogWheeling"],
  ["ros_extremeMoveNormalVal", "extremityMovement.normal"],

  ["ros_extremeMoveCommentVal", "extremityMovement.comment"],
];

export const ros_optionsTreeMapObject_extremeMove = {
  ros_extremeMoveMotorTicVal: "motorTic",
  ros_extremeMoveVocalTicVal: "vocalTic",
  ros_extremeMoveBenignVal: "benign",
  ros_extremeMoveIntenTremorVal: "intentionTremor",
  ros_extremeMoveNonIntentionVal: "nonIntention",
  ros_extremeMoveRigidityVal: "rigidity",
  ros_extremeMoveSpasticityVal: "spasticity",
  ros_extremeMoveChoreaMoveVal: "choreaMovement",
  ros_extremeMoveCogWheelingVal: "cogWheeling",
  ros_extremeMoveNormalVal: "normal",
};

export const ros_OptionsTreeWrappers_extremeMove = [
  [
    "ros_extremeMove",
    [
      "ros_extremeMoveMotorTicVal",
      "ros_extremeMoveVocalTicVal",
      "ros_extremeMoveBenignVal",
      "ros_extremeMoveIntenTremorVal",
      "ros_extremeMoveNonIntentionVal",
      "ros_extremeMoveRigidityVal",
      "ros_extremeMoveSpasticityVal",
      "ros_extremeMoveChoreaMoveVal",
      "ros_extremeMoveCogWheelingVal",
      "ros_extremeMoveNormalVal",
    ],
  ],
];

export const ros_hashmap_affect = [
  ["ros_affectNormalVal", "affect.normal"],
  ["ros_affectAbnormalVal", "affect.abnormal"],

  ["ros_affectSubParanoiaVal", "affect.abnormalSubAnswer.paranoia"],
  ["ros_affectSubDelusionalVal", "affect.abnormalSubAnswer.delusional"],
  [
    "ros_affectSubDisOrgThoughtVal",
    "affect.abnormalSubAnswer.disorganizedThought",
  ],
  ["ros_affectSubFlatVal", "affect.abnormalSubAnswer.flat"],
  ["ros_affectSubManicVal", "affect.abnormalSubAnswer.manic"],
  ["ros_affectSubDepressedVal", "affect.abnormalSubAnswer.depressed"],
  ["ros_affectSubOtherVal", "affect.abnormalSubAnswer.other"],

  ["ros_affectSubCommentVal", "affect.abnormalSubAnswer.comment"],
  ["ros_affectCommentVal", "affect.comment"],
];

export const ros_optionsTreeMapObject_affect = {
  ros_affectNormalVal: "normal",
  ros_affectAbnormalVal: "abnormal",

  ros_affectSubParanoiaVal: "paranoia",
  ros_affectSubDelusionalVal: "delusional",
  ros_affectSubDisOrgThoughtVal: "disorganizedThought",
  ros_affectSubFlatVal: "flat",
  ros_affectSubManicVal: "manic",
  ros_affectSubDepressedVal: "depressed",
  ros_affectSubOtherVal: "other",
};

export const ros_OptionsTreeWrappers_affect = [
  ["ros_affect", ["ros_affectNormalVal", "ros_affectAbnormalVal"]],
  [
    "ros_affectSub",
    [
      "ros_affectSubParanoiaVal",
      "ros_affectSubDelusionalVal",
      "ros_affectSubDisOrgThoughtVal",
      "ros_affectSubFlatVal",
      "ros_affectSubManicVal",
      "ros_affectSubDepressedVal",
      "ros_affectSubOtherVal",
    ],
  ],
];

export const ros_hashmap_over2Weeks = [
  [
    "ros_over2WeeksLittleIntrstNotAtAllVal",
    "overPastTwoWeeks.littleInterestOrPleasure.notAtAll",
  ],
  [
    "ros_over2WeeksLittleIntrstSeveralDaysVal",
    "overPastTwoWeeks.littleInterestOrPleasure.severalDays",
  ],
  [
    "ros_over2WeeksLittleIntrstMoreHalfDaysVal",
    "overPastTwoWeeks.littleInterestOrPleasure.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksLittleIntrstNearlyEveryDayVal",
    "overPastTwoWeeks.littleInterestOrPleasure.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksLittleIntrstCommentVal",
    "overPastTwoWeeks.littleInterestOrPleasure.comment",
  ],

  [
    "ros_over2WeeksFeelDepressedNotAtAllVal",
    "overPastTwoWeeks.feelingDownDepressed.notAtAll",
  ],
  [
    "ros_over2WeeksFeelDepressedSeveralDaysVal",
    "overPastTwoWeeks.feelingDownDepressed.severalDays",
  ],
  [
    "ros_over2WeeksFeelDepressedMoreHalfDaysVal",
    "overPastTwoWeeks.feelingDownDepressed.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksFeelDepressedNearlyEveryDayVal",
    "overPastTwoWeeks.feelingDownDepressed.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksFeelDepressedCommentVal",
    "overPastTwoWeeks.feelingDownDepressed.comment",
  ],

  [
    "ros_over2WeeksPhq2LessThreeVal",
    "overPastTwoWeeks.phq2score.lessThanThree",
  ],
  ["ros_over2WeeksPhq2MoreThreeVal", "overPastTwoWeeks.phq2score.threeOrMore"],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.minimalDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.mildDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.moderateDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.moderatelySevereDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.SevereDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.comment",
  ],
];

export const ros_optionsTreeMapObject_over2Weeks = {
  ros_over2WeeksLittleIntrstNotAtAllVal: "notAtAll",
  ros_over2WeeksLittleIntrstSeveralDaysVal: "severalDays",
  ros_over2WeeksLittleIntrstMoreHalfDaysVal: "moreThanHalfTheDays",
  ros_over2WeeksLittleIntrstNearlyEveryDayVal: "nearlyEveryDay",

  ros_over2WeeksFeelDepressedNotAtAllVal: "notAtAll",
  ros_over2WeeksFeelDepressedSeveralDaysVal: "severalDays",
  ros_over2WeeksFeelDepressedMoreHalfDaysVal: "moreThanHalfTheDays",
  ros_over2WeeksFeelDepressedNearlyEveryDayVal: "nearlyEveryDay",

  ros_over2WeeksPhqLessThreeVal: "lessThanThree",
  ros_over2WeeksPhq2MoreThreeVal: "threeOrMore",

  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAllVal:
    "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal:
    "minimalDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal:
    "mildDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal:
    "moderateDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal:
    "moderatelySevereDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal:
    "SevereDepression",
};

export const ros_OptionsTreeWrappers_over2Weeks = [
  [
    "ros_over2WeeksLittleIntrst",
    [
      "ros_over2WeeksLittleIntrstNotAtAllVal",
      "ros_over2WeeksLittleIntrstSeveralDaysVal",
      "ros_over2WeeksLittleIntrstMoreHalfDaysVal",
      "ros_over2WeeksLittleIntrstNearlyEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksFeelDepressed",
    [
      "ros_over2WeeksFeelDepressedNotAtAllVal",
      "ros_over2WeeksFeelDepressedSeveralDaysVal",
      "ros_over2WeeksFeelDepressedMoreHalfDaysVal",
      "ros_over2WeeksFeelDepressedNearlyEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq",
    ["ros_over2WeeksPhqLessThreeVal", "ros_over2WeeksPhq2MoreThreeVal"],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverity",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagAlcohol = [
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeEpisodicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.episodic",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeContinuousVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.continuous",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeRemissionVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.remission",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppDrinkHistVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.drinkingHistory",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.hospitalizations",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppPhysicFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppLabResultVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.labResults",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDeliriumVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfDelirium.isHistoryOfDelirium",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfDelirium.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubPsychVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfPsychosis.isHistoryOfPsychosis",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubPsychCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfPsychosis.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagAlcohol = {
  ros_neuroPsychSubDiagAlcoholSubDescActiveVal: "active",
  ros_neuroPsychSubDiagAlcoholSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagAlcoholSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagAlcoholSubTypeEpisodicVal: "episodic",
  ros_neuroPsychSubDiagAlcoholSubTypeContinuousVal: "continuous",
  ros_neuroPsychSubDiagAlcoholSubTypeRemissionVal: "remission",
  ros_neuroPsychSubDiagAlcoholSubSuppDrinkHistVal: "drinkingHistory",
  ros_neuroPsychSubDiagAlcoholSubSuppHospitalVal: "hospitalizations",
  ros_neuroPsychSubDiagAlcoholSubSuppPhysicFindVal: "physicalFindings",
  ros_neuroPsychSubDiagAlcoholSubSuppLabResultVal: "labResults",
  ros_neuroPsychSubDiagAlcoholSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagAlcohol = [
  [
    "ros_neuroPsychSubDiagAlcoholSubDesc",
    [
      "ros_neuroPsychSubDiagAlcoholSubDescActiveVal",
      "ros_neuroPsychSubDiagAlcoholSubDescHistoryVal",
      "ros_neuroPsychSubDiagAlcoholSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubType",
    [
      "ros_neuroPsychSubDiagAlcoholSubTypeEpisodicVal",
      "ros_neuroPsychSubDiagAlcoholSubTypeContinuousVal",
      "ros_neuroPsychSubDiagAlcoholSubTypeRemissionVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSupp",
    [
      "ros_neuroPsychSubDiagAlcoholSubSuppDrinkHistVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppHospitalVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppPhysicFindVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppLabResultVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagAmyotrop = [
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppProgWeakVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.progressiveWeekness",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppSpeechSlurVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.slurringOfSpeech",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppAbnormalGaitVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.abnormalGait",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppDiffSwallowVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.difficultySwallowing",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.emg",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagAmyotrop = {
  ros_neuroPsychSubDiagAmyotropSubDescActiveVal: "active",
  ros_neuroPsychSubDiagAmyotropSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagAmyotropSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagAmyotropSubDescCommentVal: "comment",
  ros_neuroPsychSubDiagAmyotropSubSuppProgWeakVal: "progressiveWeekness",
  ros_neuroPsychSubDiagAmyotropSubSuppSpeechSlurVal: "slurringOfSpeech",
  ros_neuroPsychSubDiagAmyotropSubSuppAbnormalGaitVal: "abnormalGait",
  ros_neuroPsychSubDiagAmyotropSubSuppDiffSwallowVal: "difficultySwallowing",
  ros_neuroPsychSubDiagAmyotropSubSuppEmgVal: "emg",
  ros_neuroPsychSubDiagAmyotropSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagAmyotrop = [
  [
    "ros_neuroPsychSubDiagAmyotropSubDesc",
    [
      "ros_neuroPsychSubDiagAmyotropSubDescActiveVal",
      "ros_neuroPsychSubDiagAmyotropSubDescHistoryVal",
      "ros_neuroPsychSubDiagAmyotropSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSupp",
    [
      "ros_neuroPsychSubDiagAmyotropSubSuppProgWeakVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppSpeechSlurVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppAbnormalGaitVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppDiffSwallowVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppEmgVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagAnxiety = [
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeGenAnxietyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.generalizedAnxietyDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypePanicDisorderVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.panicDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeOCDVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.obsessiveCompulsiveDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypePTSDVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.postTraumaticStressDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeSocialPhobiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.socialPhobia",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.other",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppSymptomVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppGadSevenVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.gadSeven",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppAntiAnxiMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.antianxietyMedication",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagAnxiety = {
  ros_neuroPsychSubDiagAnxietySubDescActiveVal: "active",
  ros_neuroPsychSubDiagAnxietySubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagAnxietySubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagAnxietySubTypeGenAnxietyVal:
    "generalizedAnxietyDisorder",
  ros_neuroPsychSubDiagAnxietySubTypePanicDisorderVal: "panicDisorder",
  ros_neuroPsychSubDiagAnxietySubTypeOCDVal: "obsessiveCompulsiveDisorder",
  ros_neuroPsychSubDiagAnxietySubTypePTSDVal: "postTraumaticStressDisorder",
  ros_neuroPsychSubDiagAnxietySubTypeSocialPhobiaVal: "socialPhobia",
  ros_neuroPsychSubDiagAnxietySubTypeOtherVal: "other",
  ros_neuroPsychSubDiagAnxietySubSuppSymptomVal: "symptoms",
  ros_neuroPsychSubDiagAnxietySubSuppGadSevenVal: "gadSeven",
  ros_neuroPsychSubDiagAnxietySubSuppAntiAnxiMedicVal: "antianxietyMedication",
  ros_neuroPsychSubDiagAnxietySubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagAnxiety = [
  [
    "ros_neuroPsychSubDiagAnxietySubDesc",
    [
      "ros_neuroPsychSubDiagAnxietySubDescActiveVal",
      "ros_neuroPsychSubDiagAnxietySubDescHistoryVal",
      "ros_neuroPsychSubDiagAnxietySubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubType",
    [
      "ros_neuroPsychSubDiagAnxietySubTypeGenAnxietyVal",
      "ros_neuroPsychSubDiagAnxietySubTypePanicDisorderVal",
      "ros_neuroPsychSubDiagAnxietySubTypeOCDVal",
      "ros_neuroPsychSubDiagAnxietySubTypePTSDVal",
      "ros_neuroPsychSubDiagAnxietySubTypeSocialPhobiaVal",
      "ros_neuroPsychSubDiagAnxietySubTypeOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSupp",
    [
      "ros_neuroPsychSubDiagAnxietySubSuppSymptomVal",
      "ros_neuroPsychSubDiagAnxietySubSuppGadSevenVal",
      "ros_neuroPsychSubDiagAnxietySubSuppAntiAnxiMedicVal",
      "ros_neuroPsychSubDiagAnxietySubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagBipolar = [
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeManicDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.manicDepression",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeBipolarDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.bipolarDepression",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeMixedVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.mixed",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeUnknownVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.unknown",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppMoodSwingVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.historyOfMoodSwings",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagBipolar = {
  ros_neuroPsychSubDiagBipolarSubDescActiveVal: "active",
  ros_neuroPsychSubDiagBipolarSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagBipolarSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagBipolarSubTypeManicDepressVal: "manicDepression",
  ros_neuroPsychSubDiagBipolarSubTypeBipolarDepressVal: "bipolarDepression",
  ros_neuroPsychSubDiagBipolarSubTypeMixedVal: "mixed",
  ros_neuroPsychSubDiagBipolarSubTypeUnknownVal: "unknown",
  ros_neuroPsychSubDiagBipolarSubSuppMoodSwingVal: "historyOfMoodSwings",
  ros_neuroPsychSubDiagBipolarSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagBipolarSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagBipolar = [
  [
    "ros_neuroPsychSubDiagBipolarSubDesc",
    [
      "ros_neuroPsychSubDiagBipolarSubDescActiveVal",
      "ros_neuroPsychSubDiagBipolarSubDescHistoryVal",
      "ros_neuroPsychSubDiagBipolarSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubType",
    [
      "ros_neuroPsychSubDiagBipolarSubTypeManicDepressVal",
      "ros_neuroPsychSubDiagBipolarSubTypeBipolarDepressVal",
      "ros_neuroPsychSubDiagBipolarSubTypeMixedVal",
      "ros_neuroPsychSubDiagBipolarSubTypeUnknownVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSupp",
    [
      "ros_neuroPsychSubDiagBipolarSubSuppMoodSwingVal",
      "ros_neuroPsychSubDiagBipolarSubSuppMedicVal",
      "ros_neuroPsychSubDiagBipolarSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagCerebHemm = [
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.isPhysicalFindingsSubAnswer.none",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.isPhysicalFindingsSubAnswer.rightArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.isPhysicalFindingsSubAnswer.leftArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.rightLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.leftLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.rightHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.leftHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubAphasiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.aphasia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubApraxiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.apraxia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCranNerveParalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.cranialNerveParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubParaplegiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubQuadriplegiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.coma",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.comment",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sensoryFindings",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.none",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessRightArm",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessRightLeg",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessLeftArm",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessLeftLeg",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubFacialNumbVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.facialNumbness",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubParesthesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.paresthesias",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagCerebHemm = {
  ros_neuroPsychSubDiagCerebHemmSubDescActiveVal: "active",
  ros_neuroPsychSubDiagCerebHemmSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagCerebHemmSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagCerebHemmSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagCerebHemmSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightArmParaVal:
    "rightArmParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftArmParaVal:
    "leftArmParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightLegParaVal:
    "rightLegParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftLegParaVal:
    "leftLegParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightHemiVal:
    "rightHemiparesis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftHemiVal:
    "leftHemiparesis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubAphasiaVal: "aphasia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubApraxiaVal: "apraxia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCranNerveParalVal:
    "cranialNerveParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubParaplegiaVal: "paraplegia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubQuadriplegiaVal:
    "quadriplegia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComaVal: "coma",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindVal: "sensoryFindings",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNoneVal: "none",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightArmVal:
    "numbnessRightArm",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightLegVal:
    "numbnessRightLeg",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftArmVal:
    "numbnessLeftArm",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftLegVal:
    "numbnessLeftLeg",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubFacialNumbVal:
    "facialNumbness",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubParesthesVal: "paresthesias",
  ros_neuroPsychSubDiagCerebHemmSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagCerebHemm = [
  [
    "ros_neuroPsychSubDiagCerebHemmSubDesc",
    [
      "ros_neuroPsychSubDiagCerebHemmSubDescActiveVal",
      "ros_neuroPsychSubDiagCerebHemmSubDescHistoryVal",
      "ros_neuroPsychSubDiagCerebHemmSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSupp",
    [
      "ros_neuroPsychSubDiagCerebHemmSubSuppHospitalVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightArmParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftArmParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightLegParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftLegParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightHemiVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftHemiVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubAphasiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubApraxiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCranNerveParalVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubParaplegiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubQuadriplegiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComaVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSub",
    [
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNoneVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightArmVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightLegVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftArmVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftLegVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubFacialNumbVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubParesthesVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagCerebPalsy = [
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppPhysExamVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppLabTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.laboratoryTesting",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagCerebPalsy = {
  ros_neuroPsychSubDiagCerebPalsySubDescActiveVal: "active",
  ros_neuroPsychSubDiagCerebPalsySubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagCerebPalsySubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagCerebPalsySubSuppPhysExamVal: "physicalExam",
  ros_neuroPsychSubDiagCerebPalsySubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagCerebPalsySubSuppLabTestVal: "laboratoryTesting",
  ros_neuroPsychSubDiagCerebPalsySubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagCerebPalsy = [
  [
    "ros_neuroPsychSubDiagCerebPalsySubDesc",
    [
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveVal",
      "ros_neuroPsychSubDiagCerebPalsySubDescHistoryVal",
      "ros_neuroPsychSubDiagCerebPalsySubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSupp",
    [
      "ros_neuroPsychSubDiagCerebPalsySubSuppPhysExamVal",
      "ros_neuroPsychSubDiagCerebPalsySubSuppHistoryVal",
      "ros_neuroPsychSubDiagCerebPalsySubSuppLabTestVal",
      "ros_neuroPsychSubDiagCerebPalsySubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDelusion = [
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppAffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.affect",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppSpecSymptomVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.specificSymptoms",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDelusion = {
  ros_neuroPsychSubDiagDelusionSubDescActiveVal: "active",
  ros_neuroPsychSubDiagDelusionSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDelusionSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDelusionSubSuppAffectVal: "affect",
  ros_neuroPsychSubDiagDelusionSubSuppSpecSymptomVal: "specificSymptoms",
  ros_neuroPsychSubDiagDelusionSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagDelusionSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagDelusionSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDelusion = [
  [
    "ros_neuroPsychSubDiagDelusionSubDesc",
    [
      "ros_neuroPsychSubDiagDelusionSubDescActiveVal",
      "ros_neuroPsychSubDiagDelusionSubDescHistoryVal",
      "ros_neuroPsychSubDiagDelusionSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSupp",
    [
      "ros_neuroPsychSubDiagDelusionSubSuppAffectVal",
      "ros_neuroPsychSubDiagDelusionSubSuppSpecSymptomVal",
      "ros_neuroPsychSubDiagDelusionSubSuppMedicVal",
      "ros_neuroPsychSubDiagDelusionSubSuppHospitalVal",
      "ros_neuroPsychSubDiagDelusionSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDementia = [
  [
    "ros_neuroPsychSubDiagDementiaSubDescAcuteVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.acute",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescChronicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.chronic",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppBehavChangesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.behavioralChanges",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppMentalTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.mentalTesting",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppMriVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.mri",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppFuncChangesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.functionalChanges",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascular",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascSubHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascularSubAnswer.historyOfStrokes",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascSubRiskVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascularSubAnswer.riskFactors",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascularSubAnswer.comment",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeAlzheiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.alzheimersDisease",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeEtiologyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.etiologyUnknown",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDementia = {
  ros_neuroPsychSubDiagDementiaSubDescAcuteVal: "acute",
  ros_neuroPsychSubDiagDementiaSubDescChronicVal: "chronic",
  ros_neuroPsychSubDiagDementiaSubSuppBehavChangesVal: "behavioralChanges",
  ros_neuroPsychSubDiagDementiaSubSuppMentalTestVal: "mentalTesting",
  ros_neuroPsychSubDiagDementiaSubSuppMriVal: "mri",
  ros_neuroPsychSubDiagDementiaSubSuppFuncChangesVal: "functionalChanges",
  ros_neuroPsychSubDiagDementiaSubSuppOtherVal: "other",
  ros_neuroPsychSubDiagDementiaSubTypeVascVal: "vascular",
  ros_neuroPsychSubDiagDementiaSubTypeVascSubHistoryVal:
    "vascularSubAnswer.historyOfStrokes",
  ros_neuroPsychSubDiagDementiaSubTypeVascSubRiskVal:
    "vascularSubAnswer.riskFactors",
  ros_neuroPsychSubDiagDementiaSubTypeAlzheiVal: "alzheimersDisease",
  ros_neuroPsychSubDiagDementiaSubTypeEtiologyVal: "etiologyUnknown",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDementia = [
  [
    "ros_neuroPsychSubDiagDementiaSubDesc",
    [
      "ros_neuroPsychSubDiagDementiaSubDescAcuteVal",
      "ros_neuroPsychSubDiagDementiaSubDescChronicVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSupp",
    [
      "ros_neuroPsychSubDiagDementiaSubSuppBehavChangesVal",
      "ros_neuroPsychSubDiagDementiaSubSuppMentalTestVal",
      "ros_neuroPsychSubDiagDementiaSubSuppMriVal",
      "ros_neuroPsychSubDiagDementiaSubSuppFuncChangesVal",
      "ros_neuroPsychSubDiagDementiaSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubType",
    [
      "ros_neuroPsychSubDiagDementiaSubTypeVascVal",
      "ros_neuroPsychSubDiagDementiaSubTypeAlzheiVal",
      "ros_neuroPsychSubDiagDementiaSubTypeEtiologyVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascSub",
    [
      "ros_neuroPsychSubDiagDementiaSubTypeVascSubHistoryVal",
      "ros_neuroPsychSubDiagDementiaSubTypeVascSubRiskVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDepress = [
  [
    "ros_neuroPsychSubDiagDepressSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.comment",
  ],

  [
    "ros_neuroPsychSubDiagDepressSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppPhqTwoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.phqTwoByNine",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppAntiMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.antidepressantMedication",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.comment",
  ],

  [
    "ros_neuroPsychSubDiagDepressSubMajorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajor",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubPhqVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.phqnine",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubAntiDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.chronicUseOfAntipressant",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubEctVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.useOfEct",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.comment",
  ],

  [
    "ros_neuroPsychSubDiagDepressSubEpisodeSingleVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.single",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeRecurVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.recurrent",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeRemissVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.inRemission",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeUnknownVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.unknown",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDepress = {
  ros_neuroPsychSubDiagDepressSubDescActiveVal: "active",
  ros_neuroPsychSubDiagDepressSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDepressSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDepressSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagDepressSubSuppPhqTwoVal: "phqTwoByNine",
  ros_neuroPsychSubDiagDepressSubSuppAntiMedicVal: "antidepressantMedication",
  ros_neuroPsychSubDiagDepressSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagDepressSubMajorSubPhqVal: "isMajorSubAnswer.phqnine",
  ros_neuroPsychSubDiagDepressSubMajorSubHospitalVal:
    "isMajorSubAnswer.hospitalization",
  ros_neuroPsychSubDiagDepressSubMajorSubAntiDepressVal:
    "isMajorSubAnswer.chronicUseOfAntipressant",
  ros_neuroPsychSubDiagDepressSubMajorSubEctVal: "isMajorSubAnswer.useOfEct",

  ros_neuroPsychSubDiagDepressSubEpisodeSingleVal: "single",
  ros_neuroPsychSubDiagDepressSubEpisodeRecurVal: "recurrent",
  ros_neuroPsychSubDiagDepressSubEpisodeRemissVal: "inRemission",
  ros_neuroPsychSubDiagDepressSubEpisodeUnknownVal: "unknown",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDepress = [
  [
    "ros_neuroPsychSubDiagDepressSubDesc",
    [
      "ros_neuroPsychSubDiagDepressSubDescActiveVal",
      "ros_neuroPsychSubDiagDepressSubDescHistoryVal",
      "ros_neuroPsychSubDiagDepressSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSupp",
    [
      "ros_neuroPsychSubDiagDepressSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagDepressSubSuppPhqTwoVal",
      "ros_neuroPsychSubDiagDepressSubSuppAntiMedicVal",
      "ros_neuroPsychSubDiagDepressSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSub",
    [
      "ros_neuroPsychSubDiagDepressSubMajorSubPhqVal",
      "ros_neuroPsychSubDiagDepressSubMajorSubHospitalVal",
      "ros_neuroPsychSubDiagDepressSubMajorSubAntiDepressVal",
      "ros_neuroPsychSubDiagDepressSubMajorSubEctVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisode",
    [
      "ros_neuroPsychSubDiagDepressSubEpisodeSingleVal",
      "ros_neuroPsychSubDiagDepressSubEpisodeRecurVal",
      "ros_neuroPsychSubDiagDepressSubEpisodeRemissVal",
      "ros_neuroPsychSubDiagDepressSubEpisodeUnknownVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDrugs = [
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.comment",
  ],

  [
    "ros_neuroPsychSubDiagDrugsSubTypeEpisodicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.episodic",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubTypeContinousVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.continuous",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubTypeRemissionVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.remission",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.comment",
  ],

  [
    "ros_neuroPsychSubDiagDrugsSubSuppRecreaDrugsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.recreationalDrugs",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppPainMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.chronicUseOfPainMedication",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.historyOfHospitalization",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppOPTVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.historyOutpatientTreatment",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppWithdrawalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.withdrawalSymptoms",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppAbnormalEffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.abnormalAffect",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubHistoryPsychVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.historyOfPsychosis.isHistoryOfPsychosis",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.historyOfPsychosis.comment",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubWhatDrugsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.whatDrugs",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDrugs = {
  ros_neuroPsychSubDiagDrugsSubDescActiveVal: "active",
  ros_neuroPsychSubDiagDrugsSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDrugsSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDrugsSubTypeEpisodicVal: "episodic",
  ros_neuroPsychSubDiagDrugsSubTypeContinousVal: "continuous",
  ros_neuroPsychSubDiagDrugsSubTypeRemissionVal: "remission",

  ros_neuroPsychSubDiagDrugsSubSuppRecreaDrugsVal: "recreationalDrugs",
  ros_neuroPsychSubDiagDrugsSubSuppPainMedicVal: "chronicUseOfPainMedication",
  ros_neuroPsychSubDiagDrugsSubSuppHospitalVal: "historyOfHospitalization",
  ros_neuroPsychSubDiagDrugsSubSuppOPTVal: "historyOutpatientTreatment",
  ros_neuroPsychSubDiagDrugsSubSuppWithdrawalVal: "withdrawalSymptoms",
  ros_neuroPsychSubDiagDrugsSubSuppAbnormalEffectVal: "abnormalAffect",
  ros_neuroPsychSubDiagDrugsSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDrugs = [
  [
    "ros_neuroPsychSubDiagDrugsSubDesc",
    [
      "ros_neuroPsychSubDiagDrugsSubDescActiveVal",
      "ros_neuroPsychSubDiagDrugsSubDescHistoryVal",
      "ros_neuroPsychSubDiagDrugsSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubType",
    [
      "ros_neuroPsychSubDiagDrugsSubTypeEpisodicVal",
      "ros_neuroPsychSubDiagDrugsSubTypeContinousVal",
      "ros_neuroPsychSubDiagDrugsSubTypeRemissionVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSupp",
    [
      "ros_neuroPsychSubDiagDrugsSubSuppRecreaDrugsVal",
      "ros_neuroPsychSubDiagDrugsSubSuppPainMedicVal",
      "ros_neuroPsychSubDiagDrugsSubSuppHospitalVal",
      "ros_neuroPsychSubDiagDrugsSubSuppOPTVal",
      "ros_neuroPsychSubDiagDrugsSubSuppWithdrawalVal",
      "ros_neuroPsychSubDiagDrugsSubSuppAbnormalEffectVal",
      "ros_neuroPsychSubDiagDrugsSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagFibro = [
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagFibro = {
  ros_neuroPsychSubDiagFibroSubDescActiveVal: "active",
  ros_neuroPsychSubDiagFibroSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagFibroSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagFibroSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagFibroSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagFibroSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagFibroSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagFibroSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagFibro = [
  [
    "ros_neuroPsychSubDiagFibroSubDesc",
    [
      "ros_neuroPsychSubDiagFibroSubDescActiveVal",
      "ros_neuroPsychSubDiagFibroSubDescHistoryVal",
      "ros_neuroPsychSubDiagFibroSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSupp",
    [
      "ros_neuroPsychSubDiagFibroSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagFibroSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagFibroSubSuppHistoryVal",
      "ros_neuroPsychSubDiagFibroSubSuppMedicVal",
      "ros_neuroPsychSubDiagFibroSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagGuill = [
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.emg",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagGuill = {
  ros_neuroPsychSubDiagGuillSubDescActiveVal: "active",
  ros_neuroPsychSubDiagGuillSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagGuillSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagGuillSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagGuillSubSuppEmgVal: "emg",
  ros_neuroPsychSubDiagGuillSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagGuillSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagGuill = [
  [
    "ros_neuroPsychSubDiagGuillSubDesc",
    [
      "ros_neuroPsychSubDiagGuillSubDescActiveVal",
      "ros_neuroPsychSubDiagGuillSubDescHistoryVal",
      "ros_neuroPsychSubDiagGuillSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSupp",
    [
      "ros_neuroPsychSubDiagGuillSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagGuillSubSuppEmgVal",
      "ros_neuroPsychSubDiagGuillSubSuppHospitalVal",
      "ros_neuroPsychSubDiagGuillSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagHemi = [
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDescLeftVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.hemiparesisDescribe.leftSided",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDescRightVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.hemiparesisDescribe.rightSided",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.hemiparesisDescribe.comment",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppPhysicFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagHemi = {
  ros_neuroPsychSubDiagHemiSubDescActiveVal: "active",
  ros_neuroPsychSubDiagHemiSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagHemiSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagHemiSubHemiDescLeftVal: "leftSided",
  ros_neuroPsychSubDiagHemiSubHemiDescRightVal: "rightSided",
  ros_neuroPsychSubDiagHemiSubSuppPhysicFindVal: "physicalFindings",
  ros_neuroPsychSubDiagHemiSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagHemiSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagHemi = [
  [
    "ros_neuroPsychSubDiagHemiSubDesc",
    [
      "ros_neuroPsychSubDiagHemiSubDescActiveVal",
      "ros_neuroPsychSubDiagHemiSubDescHistoryVal",
      "ros_neuroPsychSubDiagHemiSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDesc",
    [
      "ros_neuroPsychSubDiagHemiSubHemiDescLeftVal",
      "ros_neuroPsychSubDiagHemiSubHemiDescRightVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSupp",
    [
      "ros_neuroPsychSubDiagHemiSubSuppPhysicFindVal",
      "ros_neuroPsychSubDiagHemiSubSuppHistoryVal",
      "ros_neuroPsychSubDiagHemiSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagHunting = [
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppFamilyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppChoreaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.choreaMovement",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagHunting = {
  ros_neuroPsychSubDiagHuntingSubDescActiveVal: "active",
  ros_neuroPsychSubDiagHuntingSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagHuntingSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagHuntingSubSuppFamilyVal: "familyHistory",
  ros_neuroPsychSubDiagHuntingSubSuppChoreaVal: "choreaMovement",
  ros_neuroPsychSubDiagHuntingSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagHuntingSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagHunting = [
  [
    "ros_neuroPsychSubDiagHuntingSubDesc",
    [
      "ros_neuroPsychSubDiagHuntingSubDescActiveVal",
      "ros_neuroPsychSubDiagHuntingSubDescHistoryVal",
      "ros_neuroPsychSubDiagHuntingSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSupp",
    [
      "ros_neuroPsychSubDiagHuntingSubSuppFamilyVal",
      "ros_neuroPsychSubDiagHuntingSubSuppChoreaVal",
      "ros_neuroPsychSubDiagHuntingSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagHuntingSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagInsomnia = [
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagInsomnia = {
  ros_neuroPsychSubDiagInsomniaSubDescActiveVal: "active",
  ros_neuroPsychSubDiagInsomniaSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagInsomniaSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagInsomniaSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagInsomniaSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagInsomniaSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagInsomniaSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagInsomnia = [
  [
    "ros_neuroPsychSubDiagInsomniaSubDesc",
    [
      "ros_neuroPsychSubDiagInsomniaSubDescActiveVal",
      "ros_neuroPsychSubDiagInsomniaSubDescHistoryVal",
      "ros_neuroPsychSubDiagInsomniaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSupp",
    [
      "ros_neuroPsychSubDiagInsomniaSubSuppMedicVal",
      "ros_neuroPsychSubDiagInsomniaSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagInsomniaSubSuppHistoryVal",
      "ros_neuroPsychSubDiagInsomniaSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagIntel = [
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppPhysicalFindingsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppMedicationsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppTestResultVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.testResults",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppImageStudiesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppBiopsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppDmeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.dme",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescDownSyndromeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.downsSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescPhychoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.phychomotorRetardation",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.other",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagIntel = {
  ros_neuroPsychSubDiagIntelSubDescActiveVal: "active",
  ros_neuroPsychSubDiagIntelSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagIntelSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagIntelSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagIntelSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagIntelSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_neuroPsychSubDiagIntelSubSuppMedicationsVal: "medications",
  ros_neuroPsychSubDiagIntelSubSuppTestResultVal: "testResults",
  ros_neuroPsychSubDiagIntelSubSuppImageStudiesVal: "imageStudies",
  ros_neuroPsychSubDiagIntelSubSuppBiopsyVal: "biopsy",
  ros_neuroPsychSubDiagIntelSubSuppDmeVal: "dme",
  ros_neuroPsychSubDiagIntelSubSuppOtherVal: "other",
  ros_neuroPsychSubDiagIntelSubIntelDescDownSyndromeVal: "downsSyndrome",
  ros_neuroPsychSubDiagIntelSubIntelDescPhychoVal: "phychomotorRetardation",
  ros_neuroPsychSubDiagIntelSubIntelDescOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagIntel = [
  [
    "ros_neuroPsychSubDiagIntelSubDesc",
    [
      "ros_neuroPsychSubDiagIntelSubDescActiveVal",
      "ros_neuroPsychSubDiagIntelSubDescHistoryVal",
      "ros_neuroPsychSubDiagIntelSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSupp",
    [
      "ros_neuroPsychSubDiagIntelSubSuppHistoryVal",
      "ros_neuroPsychSubDiagIntelSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagIntelSubSuppPhysicalFindingsVal",
      "ros_neuroPsychSubDiagIntelSubSuppMedicationsVal",
      "ros_neuroPsychSubDiagIntelSubSuppTestResultVal",
      "ros_neuroPsychSubDiagIntelSubSuppImageStudiesVal",
      "ros_neuroPsychSubDiagIntelSubSuppBiopsyVal",
      "ros_neuroPsychSubDiagIntelSubSuppDmeVal",
      "ros_neuroPsychSubDiagIntelSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDesc",
    [
      "ros_neuroPsychSubDiagIntelSubIntelDescDownSyndromeVal",
      "ros_neuroPsychSubDiagIntelSubIntelDescPhychoVal",
      "ros_neuroPsychSubDiagIntelSubIntelDescOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagMigraine = [
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagMigraine = {
  ros_neuroPsychSubDiagMigraineSubDescActiveVal: "active",
  ros_neuroPsychSubDiagMigraineSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagMigraineSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagMigraineSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagMigraineSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagMigraineSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagMigraineSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagMigraine = [
  [
    "ros_neuroPsychSubDiagMigraineSubDesc",
    [
      "ros_neuroPsychSubDiagMigraineSubDescActiveVal",
      "ros_neuroPsychSubDiagMigraineSubDescHistoryVal",
      "ros_neuroPsychSubDiagMigraineSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSupp",
    [
      "ros_neuroPsychSubDiagMigraineSubSuppHistoryVal",
      "ros_neuroPsychSubDiagMigraineSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagMigraineSubSuppMedicVal",
      "ros_neuroPsychSubDiagMigraineSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSclerosis = [
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppLabTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.laboratoryTesting",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppBowelVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.bowel",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSclerosis = {
  ros_neuroPsychSubDiagSclerosisSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSclerosisSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSclerosisSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagSclerosisSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagSclerosisSubSuppLabTestVal: "laboratoryTesting",
  ros_neuroPsychSubDiagSclerosisSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagSclerosisSubSuppBowelVal: "bowel",
  ros_neuroPsychSubDiagSclerosisSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSclerosisSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSclerosis = [
  [
    "ros_neuroPsychSubDiagSclerosisSubDesc",
    [
      "ros_neuroPsychSubDiagSclerosisSubDescActiveVal",
      "ros_neuroPsychSubDiagSclerosisSubDescHistoryVal",
      "ros_neuroPsychSubDiagSclerosisSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSupp",
    [
      "ros_neuroPsychSubDiagSclerosisSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppLabTestVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppMedicVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppBowelVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDystrophy = [
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.emgs",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppHistoryMuscleVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.historyOfProgressiveMuscle",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppFamilyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDystrophy = {
  ros_neuroPsychSubDiagDystrophySubDescActiveVal: "active",
  ros_neuroPsychSubDiagDystrophySubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDystrophySubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDystrophySubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagDystrophySubSuppEmgVal: "emgs",
  ros_neuroPsychSubDiagDystrophySubSuppHistoryMuscleVal:
    "historyOfProgressiveMuscle",
  ros_neuroPsychSubDiagDystrophySubSuppFamilyVal: "familyHistory",
  ros_neuroPsychSubDiagDystrophySubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDystrophy = [
  [
    "ros_neuroPsychSubDiagDystrophySubDesc",
    [
      "ros_neuroPsychSubDiagDystrophySubDescActiveVal",
      "ros_neuroPsychSubDiagDystrophySubDescHistoryVal",
      "ros_neuroPsychSubDiagDystrophySubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSupp",
    [
      "ros_neuroPsychSubDiagDystrophySubSuppPhysFindVal",
      "ros_neuroPsychSubDiagDystrophySubSuppEmgVal",
      "ros_neuroPsychSubDiagDystrophySubSuppHistoryMuscleVal",
      "ros_neuroPsychSubDiagDystrophySubSuppFamilyVal",
      "ros_neuroPsychSubDiagDystrophySubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagGravis = [
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppPtosisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.ptosis",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppDoubleVisionVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.doubleVision",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppDiffChewVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.difficultyChewing",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppDiffSwallowVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.difficultySwallowing",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppTensTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.tensilonTest",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagGravis = {
  ros_neuroPsychSubDiagGravisSubDescActiveVal: "active",
  ros_neuroPsychSubDiagGravisSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagGravisSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagGravisSubSuppPtosisVal: "ptosis",
  ros_neuroPsychSubDiagGravisSubSuppDoubleVisionVal: "doubleVision",
  ros_neuroPsychSubDiagGravisSubSuppDiffChewVal: "difficultyChewing",
  ros_neuroPsychSubDiagGravisSubSuppDiffSwallowVal: "difficultySwallowing",
  ros_neuroPsychSubDiagGravisSubSuppTensTestVal: "tensilonTest",
  ros_neuroPsychSubDiagGravisSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagGravisSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagGravis = [
  [
    "ros_neuroPsychSubDiagGravisSubDesc",
    [
      "ros_neuroPsychSubDiagGravisSubDescActiveVal",
      "ros_neuroPsychSubDiagGravisSubDescHistoryVal",
      "ros_neuroPsychSubDiagGravisSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSupp",
    [
      "ros_neuroPsychSubDiagGravisSubSuppPtosisVal",
      "ros_neuroPsychSubDiagGravisSubSuppDoubleVisionVal",
      "ros_neuroPsychSubDiagGravisSubSuppDiffChewVal",
      "ros_neuroPsychSubDiagGravisSubSuppDiffSwallowVal",
      "ros_neuroPsychSubDiagGravisSubSuppTensTestVal",
      "ros_neuroPsychSubDiagGravisSubSuppMedicVal",
      "ros_neuroPsychSubDiagGravisSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagParkinson = [
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppGaitVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.gait",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppDementiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.dementia",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppAffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.affect",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagParkinson = {
  ros_neuroPsychSubDiagParkinsonSubDescActiveVal: "active",
  ros_neuroPsychSubDiagParkinsonSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagParkinsonSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagParkinsonSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagParkinsonSubSuppGaitVal: "gait",
  ros_neuroPsychSubDiagParkinsonSubSuppDementiaVal: "dementia",
  ros_neuroPsychSubDiagParkinsonSubSuppAffectVal: "affect",
  ros_neuroPsychSubDiagParkinsonSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagParkinson = [
  [
    "ros_neuroPsychSubDiagParkinsonSubDesc",
    [
      "ros_neuroPsychSubDiagParkinsonSubDescActiveVal",
      "ros_neuroPsychSubDiagParkinsonSubDescHistoryVal",
      "ros_neuroPsychSubDiagParkinsonSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSupp",
    [
      "ros_neuroPsychSubDiagParkinsonSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppGaitVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppDementiaVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppAffectVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagPeriNeuro = [
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.emg",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppBiopsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.secondaryToDiabetes.isSecondaryToDiabetes",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.secondaryToDiabetes.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagPeriNeuro = {
  ros_neuroPsychSubDiagPeriNeuroSubDescActiveVal: "active",
  ros_neuroPsychSubDiagPeriNeuroSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagPeriNeuroSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagPeriNeuroSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagPeriNeuroSubSuppEmgVal: "emg",
  ros_neuroPsychSubDiagPeriNeuroSubSuppBiopsyVal: "biopsy",
  ros_neuroPsychSubDiagPeriNeuroSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagPeriNeuro = [
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDesc",
    [
      "ros_neuroPsychSubDiagPeriNeuroSubDescActiveVal",
      "ros_neuroPsychSubDiagPeriNeuroSubDescHistoryVal",
      "ros_neuroPsychSubDiagPeriNeuroSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSupp",
    [
      "ros_neuroPsychSubDiagPeriNeuroSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagPeriNeuroSubSuppEmgVal",
      "ros_neuroPsychSubDiagPeriNeuroSubSuppBiopsyVal",
      "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagRestlessLeg = [
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagRestlessLeg = {
  ros_neuroPsychSubDiagRestlessLegSubDescActiveVal: "active",
  ros_neuroPsychSubDiagRestlessLegSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagRestlessLegSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagRestlessLegSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagRestlessLegSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagRestlessLegSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagRestlessLegSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagRestlessLeg = [
  [
    "ros_neuroPsychSubDiagRestlessLegSubDesc",
    [
      "ros_neuroPsychSubDiagRestlessLegSubDescActiveVal",
      "ros_neuroPsychSubDiagRestlessLegSubDescHistoryVal",
      "ros_neuroPsychSubDiagRestlessLegSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSupp",
    [
      "ros_neuroPsychSubDiagRestlessLegSubSuppMedicVal",
      "ros_neuroPsychSubDiagRestlessLegSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagRestlessLegSubSuppHistoryVal",
      "ros_neuroPsychSubDiagRestlessLegSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSchizo = [
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppAffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.affect",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppSymptomSixMonthVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.specificSymptomsForSixMonths",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppPsychosisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.psychosis",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSchizo = {
  ros_neuroPsychSubDiagSchizoSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSchizoSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSchizoSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagSchizoSubSuppAffectVal: "affect",
  ros_neuroPsychSubDiagSchizoSubSuppSymptomSixMonthVal:
    "specificSymptomsForSixMonths",
  ros_neuroPsychSubDiagSchizoSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagSchizoSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSchizoSubSuppPsychosisVal: "psychosis",
  ros_neuroPsychSubDiagSchizoSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSchizo = [
  [
    "ros_neuroPsychSubDiagSchizoSubDesc",
    [
      "ros_neuroPsychSubDiagSchizoSubDescActiveVal",
      "ros_neuroPsychSubDiagSchizoSubDescHistoryVal",
      "ros_neuroPsychSubDiagSchizoSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSupp",
    [
      "ros_neuroPsychSubDiagSchizoSubSuppAffectVal",
      "ros_neuroPsychSubDiagSchizoSubSuppSymptomSixMonthVal",
      "ros_neuroPsychSubDiagSchizoSubSuppMedicVal",
      "ros_neuroPsychSubDiagSchizoSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSchizoSubSuppPsychosisVal",
      "ros_neuroPsychSubDiagSchizoSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSeizure = [
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTypeDisorderVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.type.seizureDisorder",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTypeUnspecifiedVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.type.seizureUnspecified",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTreatmentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.treatment",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.historyOfRecurrentSeizures",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppLabTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.laboratoryTesting",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSeizure = {
  ros_neuroPsychSubDiagSeizureSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSeizureSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSeizureSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagSeizureSubTypeDisorderVal: "seizureDisorder",
  ros_neuroPsychSubDiagSeizureSubTypeUnspecifiedVal: "seizureUnspecified",
  ros_neuroPsychSubDiagSeizureSubTreatmentVal: "treatment",
  ros_neuroPsychSubDiagSeizureSubSuppHistoryVal: "historyOfRecurrentSeizures",
  ros_neuroPsychSubDiagSeizureSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagSeizureSubSuppLabTestVal: "laboratoryTesting",
  ros_neuroPsychSubDiagSeizureSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSeizure = [
  [
    "ros_neuroPsychSubDiagSeizureSubDesc",
    [
      "ros_neuroPsychSubDiagSeizureSubDescActiveVal",
      "ros_neuroPsychSubDiagSeizureSubDescHistoryVal",
      "ros_neuroPsychSubDiagSeizureSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubType",
    [
      "ros_neuroPsychSubDiagSeizureSubTypeDisorderVal",
      "ros_neuroPsychSubDiagSeizureSubTypeUnspecifiedVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSupp",
    [
      "ros_neuroPsychSubDiagSeizureSubSuppHistoryVal",
      "ros_neuroPsychSubDiagSeizureSubSuppMedicVal",
      "ros_neuroPsychSubDiagSeizureSubSuppLabTestVal",
      "ros_neuroPsychSubDiagSeizureSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSpinalCord = [
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.paresis",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.none",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubAnteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.anteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubPosteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.posteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCentralVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.centralCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.lossOfSensation",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.none",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubAnteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.anteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubPosteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.posteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCentralVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.centralCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppBowelVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.bowel",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToFractureVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.fracture",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToDislocateVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.dislocation",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToCompLessonVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.compressiveLesion",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSpinalCord = {
  ros_neuroPsychSubDiagSpinalCordSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSpinalCordSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSpinalCordSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisVal: "paresis",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubNoneVal: "none",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubParaVal: "paraplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubAnteriorVal:
    "anteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubPosteriorVal:
    "posteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCentralVal:
    "centralCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseVal: "lossOfSensation",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubNoneVal: "none",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubParaVal: "paraplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubAnteriorVal:
    "anteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubPosteriorVal:
    "posteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCentralVal:
    "centralCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppBowelVal: "bowel",
  ros_neuroPsychSubDiagSpinalCordSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSpinalCordSubSuppOtherVal: "other",
  ros_neuroPsychSubDiagSpinalCordSubSecToFractureVal: "fracture",
  ros_neuroPsychSubDiagSpinalCordSubSecToDislocateVal: "dislocation",
  ros_neuroPsychSubDiagSpinalCordSubSecToCompLessonVal: "compressiveLesion",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSpinalCord = [
  [
    "ros_neuroPsychSubDiagSpinalCordSubDesc",
    [
      "ros_neuroPsychSubDiagSpinalCordSubDescActiveVal",
      "ros_neuroPsychSubDiagSpinalCordSubDescHistoryVal",
      "ros_neuroPsychSubDiagSpinalCordSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSupp",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppBowelVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSub",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubNoneVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubParaVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubQuadVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubAnteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubPosteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCentralVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSub",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubNoneVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubParaVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubQuadVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubAnteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubPosteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCentralVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecTo",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSecToFractureVal",
      "ros_neuroPsychSubDiagSpinalCordSubSecToDislocateVal",
      "ros_neuroPsychSubDiagSpinalCordSubSecToCompLessonVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagStroke = [
  [
    "ros_neuroPsychSubDiagStrokeSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.rightArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.leftArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.rightLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.leftLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.rightHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.leftHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubAphasiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.aphasia",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubApraxiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.apraxia",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCranNerveParalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.cranialNerveParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubFuncQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.functionalQuadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.sensoryFindings",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessRightArm",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessRightLeg",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessLeftArm",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessLeftLeg",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubFacialNumbVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.facialNumbness",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubParesthesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.paresthesias",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagStroke = {
  ros_neuroPsychSubDiagStrokeSubDescActiveVal: "active",
  ros_neuroPsychSubDiagStrokeSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagStrokeSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagStrokeSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagStrokeSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindVal: "sensoryFindings",
  ros_neuroPsychSubDiagStrokeSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightArmParaVal:
    "rightArmParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftArmParaVal:
    "leftArmParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightLegParaVal:
    "rightLegParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftLegParaVal:
    "leftLegParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightHemiVal: "rightHemiparesis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftHemiVal: "leftHemiparesis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubAphasiaVal: "aphasia",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubApraxiaVal: "apraxia",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCranNerveParalVal:
    "cranialNerveParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubFuncQuadVal:
    "functionalQuadriplegia",

  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNoneVal: "none",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightArmVal:
    "numbnessRightArm",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightLegVal:
    "numbnessRightLeg",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftArmVal:
    "numbnessLeftArm",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftLegVal:
    "numbnessLeftLeg",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubFacialNumbVal: "facialNumbness",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubParesthesVal: "paresthesias",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagStroke = [
  [
    "ros_neuroPsychSubDiagStrokeSubDesc",
    [
      "ros_neuroPsychSubDiagStrokeSubDescActiveVal",
      "ros_neuroPsychSubDiagStrokeSubDescHistoryVal",
      "ros_neuroPsychSubDiagStrokeSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSupp",
    [
      "ros_neuroPsychSubDiagStrokeSubSuppHospitalVal",
      "ros_neuroPsychSubDiagStrokeSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindVal",
      "ros_neuroPsychSubDiagStrokeSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightArmParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftArmParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightLegParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftLegParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightHemiVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftHemiVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubAphasiaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubApraxiaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCranNerveParalVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubFuncQuadVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSub",
    [
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNoneVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightArmVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightLegVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftArmVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftLegVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubFacialNumbVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubParesthesVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSubdural = [
  [
    "ros_neuroPsychSubDiagSubduralSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.rightArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.leftArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.rightLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.leftLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.rightHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.leftHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubAphasiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.aphasia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubApraxiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.apraxia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCranNerveParalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.cranialNerveParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubParapVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.coma",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.sensoryFindings",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessRightArm",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessRightLeg",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessLeftArm",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessLeftLeg",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubFacialNumbVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.facialNumbness",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubParesthesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.paresthesias",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSubdural = {
  ros_neuroPsychSubDiagSubduralSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSubduralSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSubduralSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagSubduralSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSubduralSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindVal: "sensoryFindings",
  ros_neuroPsychSubDiagSubduralSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightArmParaVal:
    "rightArmParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftArmParaVal:
    "leftArmParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightLegParaVal:
    "rightLegParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftLegParaVal:
    "leftLegParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightHemiVal:
    "rightHemiparesis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftHemiVal: "leftHemiparesis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubAphasiaVal: "aphasia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubApraxiaVal: "apraxia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCranNerveParalVal:
    "cranialNerveParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubParapVal: "paraplegia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComaVal: "coma",

  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNoneVal: "none",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightArmVal:
    "numbnessRightArm",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightLegVal:
    "numbnessRightLeg",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftArmVal:
    "numbnessLeftArm",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftLegVal:
    "numbnessLeftLeg",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubFacialNumbVal:
    "facialNumbness",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubParesthesVal: "paresthesias",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSubdural = [
  [
    "ros_neuroPsychSubDiagSubduralSubDesc",
    [
      "ros_neuroPsychSubDiagSubduralSubDescActiveVal",
      "ros_neuroPsychSubDiagSubduralSubDescHistoryVal",
      "ros_neuroPsychSubDiagSubduralSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSupp",
    [
      "ros_neuroPsychSubDiagSubduralSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSubduralSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindVal",
      "ros_neuroPsychSubDiagSubduralSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightArmParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftArmParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightLegParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftLegParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightHemiVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftHemiVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubAphasiaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubApraxiaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCranNerveParalVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubParapVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubQuadVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComaVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSub",
    [
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNoneVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightArmVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightLegVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftArmVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftLegVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubFacialNumbVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubParesthesVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagTia = [
  [
    "ros_neuroPsychSubDiagTiaSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppPhysExamVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagTia = {
  ros_neuroPsychSubDiagTiaSubDescActiveVal: "active",
  ros_neuroPsychSubDiagTiaSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagTiaSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagTiaSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagTiaSubSuppPhysExamVal: "physicalExam",
  ros_neuroPsychSubDiagTiaSubSuppImageStudyVal: "imageStudies",
  ros_neuroPsychSubDiagTiaSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagTia = [
  [
    "ros_neuroPsychSubDiagTiaSubDesc",
    [
      "ros_neuroPsychSubDiagTiaSubDescActiveVal",
      "ros_neuroPsychSubDiagTiaSubDescHistoryVal",
      "ros_neuroPsychSubDiagTiaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSupp",
    [
      "ros_neuroPsychSubDiagTiaSubSuppHistoryVal",
      "ros_neuroPsychSubDiagTiaSubSuppPhysExamVal",
      "ros_neuroPsychSubDiagTiaSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagTiaSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagTraumaBrain = [
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.coma",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.hemiplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubMonoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.monoplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubPtVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.pt",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubDmeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.dme",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagTraumaBrain = {
  ros_neuroPsychSubDiagTraumaBrainSubDescActiveVal: "active",
  ros_neuroPsychSubDiagTraumaBrainSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagTraumaBrainSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagTraumaBrainSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagTraumaBrainSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagTraumaBrainSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComaVal: "coma",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubParaVal: "paraplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubHemiVal: "hemiplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubMonoVal: "monoplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubPtVal: "pt",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubDmeVal: "dme",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagTraumaBrain = [
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDesc",
    [
      "ros_neuroPsychSubDiagTraumaBrainSubDescActiveVal",
      "ros_neuroPsychSubDiagTraumaBrainSubDescHistoryVal",
      "ros_neuroPsychSubDiagTraumaBrainSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSupp",
    [
      "ros_neuroPsychSubDiagTraumaBrainSubSuppHospitalVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComaVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubQuadVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubParaVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubHemiVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubMonoVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubPtVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubDmeVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagOther = [
  [
    "ros_neuroPsychSubDiagOtherSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppTestResultVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppBiopsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppDmeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.other",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagOther = {
  ros_neuroPsychSubDiagOtherSubDescActiveVal: "active",
  ros_neuroPsychSubDiagOtherSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagOtherSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagOtherSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagOtherSubSuppTestResultVal: "testResults",
  ros_neuroPsychSubDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_neuroPsychSubDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_neuroPsychSubDiagOtherSubSuppDmeVal: "dme",
  ros_neuroPsychSubDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagOther = [
  [
    "ros_neuroPsychSubDiagOtherSubDesc",
    [
      "ros_neuroPsychSubDiagOtherSubDescActiveVal",
      "ros_neuroPsychSubDiagOtherSubDescHistoryVal",
      "ros_neuroPsychSubDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSupp",
    [
      "ros_neuroPsychSubDiagOtherSubSuppHistoryVal",
      "ros_neuroPsychSubDiagOtherSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagOtherSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagOtherSubSuppMedicVal",
      "ros_neuroPsychSubDiagOtherSubSuppTestResultVal",
      "ros_neuroPsychSubDiagOtherSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagOtherSubSuppBiopsyVal",
      "ros_neuroPsychSubDiagOtherSubSuppDmeVal",
      "ros_neuroPsychSubDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiag = [
  [
    "ros_neuroPsychSubDiagAlcoholVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenc:]amyotrophicLateral",
  ],
  [
    "ros_neuroPsychSubDiagAnxietyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxiety",
  ],
  [
    "ros_neuroPsychSubDiagBipolarVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorder",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhage",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsy",
  ],
  [
    "ros_neuroPsychSubDiagDementiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementia",
  ],
  [
    "ros_neuroPsychSubDiagDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depression",
  ],
  [
    "ros_neuroPsychSubDiagDrugDepVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependence",
  ],
  [
    "ros_neuroPsychSubDiagFibroVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgia",
  ],
  [
    "ros_neuroPsychSubDiagGuillainVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDisease",
  ],
  [
    "ros_neuroPsychSubDiagHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagHuntingVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChorea",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomnia",
  ],
  [
    "ros_neuroPsychSubDiagDevDisableVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisability",
  ],
  [
    "ros_neuroPsychSubDiagMigraineVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadaches",
  ],
  [
    "ros_neuroPsychSubDiagMultiSclerVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosis",
  ],
  [
    "ros_neuroPsychSubDiagMuscDysVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophy",
  ],
  [
    "ros_neuroPsychSubDiagMyasGravisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravis",
  ],
  [
    "ros_neuroPsychSubDiagParkinVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDisease",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathy",
  ],
  [
    "ros_neuroPsychSubDiagRestLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSchizoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophrenia",
  ],
  [
    "ros_neuroPsychSubDiagSeizureVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizures",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjury",
  ],
  [
    "ros_neuroPsychSubDiagStrokeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.stroke",
  ],
  [
    "ros_neuroPsychSubDiagSubduralVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematoma",
  ],
  [
    "ros_neuroPsychSubDiagTiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tia",
  ],
  [
    "ros_neuroPsychSubDiagTraumticVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjury",
  ],
  [
    "ros_neuroPsychSubDiagOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.other",
  ],
  [
    "ros_neuroPsychSubDiagCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.comment",
  ],
  ...ros_hashmap_neuroPsychSubDiagAlcohol,
  ...ros_hashmap_neuroPsychSubDiagAmyotrop,
  ...ros_hashmap_neuroPsychSubDiagAnxiety,
  ...ros_hashmap_neuroPsychSubDiagBipolar,
  ...ros_hashmap_neuroPsychSubDiagCerebHemm,
  ...ros_hashmap_neuroPsychSubDiagCerebPalsy,
  ...ros_hashmap_neuroPsychSubDiagDelusion,
  ...ros_hashmap_neuroPsychSubDiagDementia,
  ...ros_hashmap_neuroPsychSubDiagDepress,
  ...ros_hashmap_neuroPsychSubDiagDrugs,
  ...ros_hashmap_neuroPsychSubDiagFibro,
  ...ros_hashmap_neuroPsychSubDiagGuill,
  ...ros_hashmap_neuroPsychSubDiagHemi,
  ...ros_hashmap_neuroPsychSubDiagHunting,
  ...ros_hashmap_neuroPsychSubDiagInsomnia,
  ...ros_hashmap_neuroPsychSubDiagIntel,
  ...ros_hashmap_neuroPsychSubDiagMigraine,
  ...ros_hashmap_neuroPsychSubDiagSclerosis,
  ...ros_hashmap_neuroPsychSubDiagDystrophy,
  ...ros_hashmap_neuroPsychSubDiagGravis,
  ...ros_hashmap_neuroPsychSubDiagParkinson,
  ...ros_hashmap_neuroPsychSubDiagPeriNeuro,
  ...ros_hashmap_neuroPsychSubDiagRestlessLeg,
  ...ros_hashmap_neuroPsychSubDiagSchizo,
  ...ros_hashmap_neuroPsychSubDiagSeizure,
  ...ros_hashmap_neuroPsychSubDiagSpinalCord,
  ...ros_hashmap_neuroPsychSubDiagStroke,
  ...ros_hashmap_neuroPsychSubDiagSubdural,
  ...ros_hashmap_neuroPsychSubDiagTia,
  ...ros_hashmap_neuroPsychSubDiagTraumaBrain,
  ...ros_hashmap_neuroPsychSubDiagOther,
];

export const ros_optionsTreeMapObject_neuroPsychSubDiag = {
  ros_neuroPsychSubDiagAlcoholVal: "alcoholDependence",
  ros_neuroPsychSubDiagAmyotropVal: "amyotrophicLateral",
  ros_neuroPsychSubDiagAnxietyVal: "anxiety",
  ros_neuroPsychSubDiagBipolarVal: "bipolarDisorder",
  ros_neuroPsychSubDiagCerebHemmVal: "cerebralHemorrhage",
  ros_neuroPsychSubDiagCerebPalsyVal: "cerebralPalsy",
  ros_neuroPsychSubDiagDementiaVal: "dementia",
  ros_neuroPsychSubDiagDepressVal: "depression",
  ros_neuroPsychSubDiagDrugDepVal: "drugDependence",
  ros_neuroPsychSubDiagFibroVal: "fibromyalgia",
  ros_neuroPsychSubDiagGuillainVal: "guillainBarreDisease",
  ros_neuroPsychSubDiagHemiVal: "hemiparesis",
  ros_neuroPsychSubDiagHuntingVal: "huntingtonsChorea",
  ros_neuroPsychSubDiagInsomniaVal: "insomnia",
  ros_neuroPsychSubDiagDevDisableVal: "developmentalDisability",
  ros_neuroPsychSubDiagMigraineVal: "migraineHeadaches",
  ros_neuroPsychSubDiagMultiSclerVal: "multipleSclerosis",
  ros_neuroPsychSubDiagMuscDysVal: "muscularDystrophy",
  ros_neuroPsychSubDiagMyasGravisVal: "myastheniaGravis",
  ros_neuroPsychSubDiagParkinVal: "parkinsonsDisease",
  ros_neuroPsychSubDiagPeriNeuroVal: "peripheralNeuropathy",
  ros_neuroPsychSubDiagRestLegVal: "restlessLegSyndrome",
  ros_neuroPsychSubDiagSchizoVal: "schizophrenia",
  ros_neuroPsychSubDiagSeizureVal: "seizures",
  ros_neuroPsychSubDiagSpinalCordVal: "spinalCordInjury",
  ros_neuroPsychSubDiagStrokeVal: "stroke",
  ros_neuroPsychSubDiagSubduralVal: "subduralHematoma",
  ros_neuroPsychSubDiagTiaVal: "tia",
  ros_neuroPsychSubDiagTraumticVal: "traumaticBrainInjury",
  ros_neuroPsychSubDiagOtherVal: "other",
  ...ros_optionsTreeMapObject_neuroPsychSubDiagAlcohol,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagAmyotrop,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagAnxiety,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagBipolar,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagCerebHemm,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagCerebPalsy,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDelusion,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDementia,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDepress,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDrugs,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagFibro,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagGuill,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagHemi,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagHunting,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagInsomnia,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagIntel,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagMigraine,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSclerosis,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDystrophy,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagGravis,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagParkinson,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagPeriNeuro,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagRestlessLeg,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSchizo,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSeizure,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSpinalCord,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagStroke,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSubdural,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagTia,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagTraumaBrain,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagOther,
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiag = [
  [
    "ros_neuroPsychSubDiag",
    [
      "ros_neuroPsychSubDiagAlcoholVal",
      "ros_neuroPsychSubDiagAmyotropVal",
      "ros_neuroPsychSubDiagAnxietyVal",
      "ros_neuroPsychSubDiagBipolarVal",
      "ros_neuroPsychSubDiagCerebHemmVal",
      "ros_neuroPsychSubDiagCerebPalsyVal",
      "ros_neuroPsychSubDiagDementiaVal",
      "ros_neuroPsychSubDiagDepressVal",
      "ros_neuroPsychSubDiagDrugDepVal",
      "ros_neuroPsychSubDiagFibroVal",
      "ros_neuroPsychSubDiagGuillainVal",
      "ros_neuroPsychSubDiagHemiVal",
      "ros_neuroPsychSubDiagHuntingVal",
      "ros_neuroPsychSubDiagInsomniaVal",
      "ros_neuroPsychSubDiagDevDisableVal",
      "ros_neuroPsychSubDiagMigraineVal",
      "ros_neuroPsychSubDiagMultiSclerVal",
      "ros_neuroPsychSubDiagMuscDysVal",
      "ros_neuroPsychSubDiagMyasGravisVal",
      "ros_neuroPsychSubDiagParkinVal",
      "ros_neuroPsychSubDiagPeriNeuroVal",
      "ros_neuroPsychSubDiagRestLegVal",
      "ros_neuroPsychSubDiagSchizoVal",
      "ros_neuroPsychSubDiagSeizureVal",
      "ros_neuroPsychSubDiagSpinalCordVal",
      "ros_neuroPsychSubDiagStrokeVal",
      "ros_neuroPsychSubDiagSubduralVal",
      "ros_neuroPsychSubDiagTiaVal",
      "ros_neuroPsychSubDiagTraumticVal",
      "ros_neuroPsychSubDiagOtherVal",
    ],
  ],
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagAlcohol,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagAmyotrop,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagAnxiety,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagBipolar,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagCerebHemm,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagCerebPalsy,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDelusion,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDementia,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDepress,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDrugs,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagFibro,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagGuill,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagHemi,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagHunting,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagInsomnia,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagIntel,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagMigraine,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSclerosis,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDystrophy,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagGravis,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagParkinson,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagPeriNeuro,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagRestlessLeg,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSchizo,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSeizure,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSpinalCord,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagStroke,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSubdural,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagTia,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagTraumaBrain,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagOther,
];

export const ros_hashmap_neuroPsych = [
  ["ros_neuroPsychVal", "neuroPsych.isNeuroPsych"],
  ["ros_neuroPsychCommentVal", "neuroPsych.comment"],
  ["ros_neuroPsychNervCommentVal", "neuroPsych.nervousFeel.comment"],
  ["ros_neuroPsychNervVal", "neuroPsych.nervousFeel.isNervousFeel"],
  ["ros_neuroPsychNervCommentVal", "neuroPsych.nervousFeel.comment"],
  ["ros_neuroPsychWorryVal", "neuroPsych.worryTooMuch.isWorryTooMuch"],
  ["ros_neuroPsychWorryCommentVal", "neuroPsych.worryTooMuch.comment"],
  ["ros_neuroPsychAfraidVal", "neuroPsych.feelAfraid.isFeelAfraid"],
  ["ros_neuroPsychAfraidCommentVal", "neuroPsych.feelAfraid.comment"],
  [
    "ros_neuroPsychSubAuditoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfAuditory.isHistoryOfAuditory",
  ],
  [
    "ros_neuroPsychSubAuditoryCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfAuditory.comment",
  ],
  [
    "ros_neuroPsychSubVisualVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfVisual.isHistoryOfVisual",
  ],
  [
    "ros_neuroPsychSubVisualCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfVisual.comment",
  ],
  [
    "ros_neuroPsychSubEpisodesBehavVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesBehavior.isHistoryOfEpisodesBehavior",
  ],
  [
    "ros_neuroPsychSubEpisodesBehavCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesBehavior.comment",
  ],
  [
    "ros_neuroPsychSubEpisodesDeliriumVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesDelirium.isHistoryOfEpisodesDelirium",
  ],
  [
    "ros_neuroPsychSubEpisodesDeliriumCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesDelirium.comment",
  ],
  [
    "ros_neuroPsychSubHearVoicesVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHearVoices.isDoYouHearVoices",
  ],
  [
    "ros_neuroPsychSubHearVoicesCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHearVoices.comment",
  ],
  [
    "ros_neuroPsychSubHighLowsVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHaveHighsAndLows.isDoYouHaveHighsAndLows",
  ],
  [
    "ros_neuroPsychSubHighLowsCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHaveHighsAndLows.comment",
  ],
  [
    "ros_neuroPsychSubEverFeelVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouEverFeel.isDoYouEverFeel",
  ],
  [
    "ros_neuroPsychSubEverFeelCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouEverFeel.comment",
  ],
  ...ros_hashmap_neuroPsychSubDiag,
];

export const ros_optionsTreeMapObject_neuroPsych = {
  ...ros_optionsTreeMapObject_neuroPsychSubDiag,
};

export const ros_OptionsTreeWrappers_neuroPsych = [
  ...ros_OptionsTreeWrappers_neuroPsychSubDiag,
];

export const ros_hashmap = [
  ...ros_hashmap_srilatha,
  ...ros_hashmap_mehak,
  ...ros_hashmap_Eyes,
  ...ros_hashmap_Ears,
  ...ros_hashmap_Nose,
  ...ros_hashmap_Mouth,
  ...ros_hashmap_Neck,
  ...ros_hashmap_eyeENTNeckRecommendations,
  ...ros_hashmap_BowelMovement,
  ...ros_hashmap_AbdominalOpening,
  ...ros_hashmap_rectalProblems,
  ...ros_hashmap_lastBowel,
  ...ros_hashmap_meetFriends,
  ...ros_hashmap_gpCog,
  ...ros_hashmap_gpCogNoScore,
  ...ros_hashmap_speech,
  ...ros_hashmap_sitToStand,
  ...ros_hashmap_fingerToNose,
  ...ros_hashmap_heelToToe,
  ...ros_hashmap_thumbToFinger,
  ...ros_hashmap_thumbToFinger,
];

export const ros_optionsTreeMapObject = {
  ...ros_optionsTreeMapObject_mehak,
  ...ros_optionsTreeMapObject_srilatha,
  ...ros_optionsTreeMapObject_Eyes,
  ...ros_optionsTreeMapObject_Ears,
  ...ros_optionsTreeMapObject_Nose,
  ...ros_optionsTreeMapObject_Mouth,
  ...ros_optionsTreeMapObject_Neck,
  ...ros_optionsTreeMapObject_BowelMovement,
  ...ros_optionsTreeMapObject_AbdominalOpening,
  ...ros_optionsTreeMapObject_rectalProblems,
  ...ros_optionsTreeMapObject_lastBowel,
  ...ros_optionsTreeMapObject_meetFriends,
  ...ros_optionsTreeMapObject_gpCogNoScore,
  ...ros_optionsTreeMapObject_speech,
  ...ros_optionsTreeMapObject_sitToStand,
  ...ros_optionsTreeMapObject_fingerToNose,
  ...ros_optionsTreeMapObject_heelToToe,
  ...ros_optionsTreeMapObject_thumbToFinger,
  ...ros_optionsTreeMapObject_thumbToFinger,
};

export const ros_OptionsTreeWrappers = [
  ...ros_OptionsTreeWrappers_mehak,
  ...ros_OptionsTreeWrappers_srilatha,
  ...ros_OptionsTreeWrappers_Eyes,
  ...ros_OptionsTreeWrappers_Ears,
  ...ros_OptionsTreeWrappers_Nose,
  ...ros_OptionsTreeWrappers_Mouth,
  ...ros_OptionsTreeWrappers_Neck,
  ...ros_OptionsTreeWrappers_BowelMovement,
  ...ros_OptionsTreeWrappers_AbdominalOpening,
  ...ros_OptionsTreeWrappers_rectalProblems,
  ...ros_OptionsTreeWrappers_lastBowel,
  ...ros_OptionsTreeWrappers_meetFriends,
  ...ros_OptionsTreeWrappers_gpCogNoScore,
  ...ros_OptionsTreeWrappers_speech,
  ...ros_OptionsTreeWrappers_sitToStand,
  ...ros_OptionsTreeWrappers_fingerToNose,
  ...ros_OptionsTreeWrappers_heelToToe,
  ...ros_OptionsTreeWrappers_thumbToFinger,
  ...ros_OptionsTreeWrappers_thumbToFinger,
];
